import {
    ACTION_TABLEADPROPOSALSVIEW_SUCCESS
} from './action';
import createReducer from '../../../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const TabLeadProposalsViewReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_TABLEADPROPOSALSVIEW_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_TABLEADPROPOSALSVIEW_SUCCESS,
                loading: false
            });
        },
    }
);
export default TabLeadProposalsViewReducer;