import React, { useState, useEffect } from "react"
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { checkFileExtension, downloadFile } from '../../../utils';
import { MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from '../../../constants';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { actionOtherClaim, actionOtherClaimEdit, ACTION_OTHER_CLAIM_SUCCESS, ACTION_OTHER_CLAIM_EDIT_SUCCESS } from './action';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import DocumentIframe from "../../popups/document_iframe";

const OtherClaim = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, reset } = useForm()
    const [remItemId, setRemItemId] = useState(null);
    const validExt = ['jpg', 'jpeg', 'png', 'docx', 'doc', 'pdf', 'PDF', 'xlsx', 'csv', 'zip'];
    const [uploadDocument, setUploadDocument] = useState(null);
    const [remMonth, setRemMonth] = useState(null);
    const [remYear, setRemYear] = useState(null);
    const [remMinAmount, setRemMinAmount] = useState(null);
    const [maxMonth, setMaxMonth] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [popup, setPopup] = useState(null)

    const initialRender = () => {
        if (props.show === true) {
            setRemItemId(props.data != null ? props.data.details_id : '')
            console.log("data:==", props.reimbursementTypeDetails);
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            if (props?.otherData === true) {
                setValue('bill_date', props.data != null ? props.data?.bill_date : '')
                setValue('claim_amount', props.data != null ? props.data.claim_amount : '');
                setValue('description', props.data != null ? props.data.description : '');
            }
            if (props.month != null && props.year != null) {
                setRemMonth(Number(props.month) === 1 ? 12 : Number(props.month) - 1);
                setRemYear(Number(props.month) === 1 ? Number(props.year) - 1 : Number(props.year));
                setMaxMonth(Number(props.month) < 10 ? '0' + props.month : props.month);
            }

            if (props.claimDetail != null && props.claimDetail.reimbursement_min_amount != null) {
                setRemMinAmount(props.claimDetail.reimbursement_min_amount);
            }
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setUploadDocument(null)
            setValue('reimbursement_type_id', '');
            setValue('bill_date', '');
            setValue('claim_amount', '');
            setValue('description', '');
            reset()
        }
    }
    useEffect(initialRender, [props.show, props?.dataa])

    const reimbursmentClaimLimit = () => {
        if (props.reimbursementTypeDetails != null) {
            let totalClaimAmount = props.reimbursementTypeDetails.total_claim_amount != null ? props.reimbursementTypeDetails.total_claim_amount : 0
            if (remItemId > 0 && props?.otherData === true) {
                totalClaimAmount = totalClaimAmount - Number(props.data.claim_amount)
            }
            var remTypeLimit = props.reimbursementTypeDetails.type_limit - totalClaimAmount
            return remTypeLimit
        }

        return 0
    }

    const onOtherClaimUpdate = () => {
        const { otherClaim } = props;
        var ReimbursementOtherClaimResponse = Object.assign({}, otherClaim);
        $("#other_request").prop("disabled", false);
        if (ReimbursementOtherClaimResponse.result === true) {
            $("#add_other_loader").addClass("hidden");
            props.handleClose(ReimbursementOtherClaimResponse.response);
        } else {
            $("#add_other_loader").addClass("hidden");
            switch (ReimbursementOtherClaimResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onOtherClaimUpdate, [props.otherClaim]);

    const onOtherClaimEditUpdate = () => {
        const { otherClaimEdit } = props;
        var otherClaimEditResponse = Object.assign({}, otherClaimEdit);
        $("#other_request").prop("disabled", false);
        if (otherClaimEditResponse.result === true) {
            $("#add_other_loader").addClass("hidden");
            props.handleClose(otherClaimEditResponse.response);
        } else {
            $("#add_other_loader").addClass("hidden");
            switch (otherClaimEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onOtherClaimEditUpdate, [props.otherClaimEdit]);

    const onSubmit = (data) => {
        if (remItemId > 0 && props?.otherData === true) {
            $("#other_request").prop("disabled", true);
            $("#add_other_loader").removeClass("hidden");
            data.id = remItemId;
            data.reimbursement_id = props.reimbursementId;
            data.reimbursement_type_id = props.reimbursementTypeId;
            data.bill_date = data.bill_date;
            data.claim_amount = data.claim_amount;
            data.description = data.description;
            var files = [];
            if (uploadDocument != null) {
                files.push({ 'name': 'upload_document', 'value': uploadDocument });
            }
            delete data.upload_document;
            props.actionOtherClaimEdit(data, files);
        } else {
            data.reimbursement_id = props.reimbursementId;
            data.reimbursement_type_id = props.reimbursementTypeId;
            data.bill_date = data.bill_date;
            data.claim_amount = data.claim_amount;
            data.description = data.description;
            var files = [];
            if (uploadDocument != null) {
                files.push({ 'name': 'upload_document', 'value': uploadDocument });
                $("#other_request").prop("disabled", true);
                $("#add_other_loader").removeClass("hidden");
                props.actionOtherClaim(data, files);
            } else {
                $('#error-doc-not-found').removeClass('d-none')
            }
            delete data.upload_document;
        }
    }
    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="myModalLabel1"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="myModalLabel1">
                                    {remItemId && props?.otherData === true ? 'Edit' : 'Add New'} {props?.remTitle != null ? props?.remTitle : ''} Reimbursement
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                    $("#warning").addClass('d-none')
                                    $("#warning_edit").addClass('d-none')
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <h4 class="d-flex form-section my-0 black">
                        </h4>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">
                                                            Date&nbsp;<span className="danger">*</span>
                                                        </label>
                                                        <Controller
                                                            name={'bill_date'}
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DateBox
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    id="bill_date"
                                                                    name="bill_date"
                                                                    stylingMode="underlined"
                                                                    className="form-control"
                                                                    pickerType="calendar"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    value={field.value}
                                                                    min={moment(`${remYear}-${remMonth}-01`).format('YYYY-MM-DD')}
                                                                    max={Number(remMonth) === (moment().month()) ? moment().format('YYYY-MM-DD') :
                                                                        Number(remMonth) === 12 ? moment(`${remYear + 1}-01-01`).endOf('month').format('YYYY-MM-DD') : moment(`${remYear}-${maxMonth}-01`).endOf('month').format('YYYY-MM-DD')}
                                                                    acceptCustomValue={false}
                                                                    onValueChange={(value) => {
                                                                        field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Please Select Date'
                                                            }}
                                                        />
                                                        {errors.bill_date && <ErrorLabel message={errors.bill_date?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">
                                                            Amount to Reimbursement&nbsp;<span className="danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            id="claim_amount"
                                                            name="claim_amount"
                                                            placeholder="Enter Amount to Reimbursement"
                                                            onKeyPress={(event) => {
                                                                if (!/^\d{0,2}(\.\d{0,1}){0,1}$/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            {...register('claim_amount',
                                                                {
                                                                    required: 'Amount to Reimbursement is required',
                                                                    validate: (value) => {
                                                                        if (Number(value) < remMinAmount) {
                                                                            return `Amount should be greater than or equal to ${remMinAmount}`
                                                                        }
                                                                        let claimLimit = reimbursmentClaimLimit()
                                                                        if (Number(value) > claimLimit) {
                                                                            return `Only ${claimLimit} claim amount is pending`
                                                                        }
                                                                    }
                                                                })}
                                                        />
                                                        {errors.claim_amount && <ErrorLabel message={errors.claim_amount?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">
                                                            Description&nbsp;<span className="danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            id="description"
                                                            name="description"
                                                            placeholder="Enter Comments about this bill"
                                                            {...register('description', { required: 'Comments about this bill is required' })}
                                                        />
                                                        {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Attachment&nbsp; <span class={`text-danger ${remItemId !== null && remItemId !== undefined && remItemId !== '' && props.otherData === true ? "hidden" : ''}`}>*</span></label>
                                                        <div class="custom-file">
                                                            <input
                                                                type="file"
                                                                class="custom-file-input"
                                                                id="upload_document"
                                                                accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .PDF, .xlsx, .csv, .zip"
                                                                name="upload_document"
                                                                onChange={(event) => {
                                                                    $('#error-doc-not-found').addClass('d-none')
                                                                    if (event.target.files != null) {

                                                                        var fileSize = event.target.files[0]?.size;

                                                                        var ext = (event.target.files[0].name).split('.').pop();

                                                                        if (checkFileExtension(ext, validExt)) {

                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                setUploadDocument(event.target.files[0]);
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }
                                                                        }
                                                                        else {
                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'You can only upload jpg, jpeg, png, docx, doc, pdf, PDF, xlsx, csv, zip files',
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        }
                                                                    } else {
                                                                        $('#error-doc-not-found').removeClass('d-none')
                                                                    }
                                                                }}
                                                            />
                                                            <small className="error-msg d-none text-danger" id="error-doc-not-found">Document is required</small>
                                                            <label class="custom-file-label" for="upload_document">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument.name : "Choose file"
                                                                }
                                                            </label>
                                                            {
                                                                props.data != null && props.data?.reimbursement_document != null && props?.otherData === true ?
                                                                    <div id="view_document mt-1" >
                                                                        {/* <a className='primary' target="_blank" rel="noreferrer" href={props.data?.reimbursement_document}>View Document</a> */}
                                                                        <a className="primary"
                                                                            onClick={() => {
                                                                                if (props.data?.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                                    props.data?.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                                    props.data?.reimbursement_document?.split('.').pop() == 'png' ||
                                                                                    props.data?.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                                    props.data?.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                                    props.data?.reimbursement_document?.split('.').pop() == 'gif'
                                                                                ) {
                                                                                    props.handleClose();
                                                                                    setSelectedDocumentIndex(props.data?.reimbursement_document)
                                                                                    setShowDocumentDetails(true);
                                                                                    setExtension(props.data?.reimbursement_document?.split('.').pop())
                                                                                    setDocumentDetails(props.data)
                                                                                    setPopup(true)
                                                                                } else {
                                                                                    downloadFile(props.data?.reimbursement_document);
                                                                                }
                                                                            }}
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                        <a id="downloadLink" className="d-none"></a>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                id="other_request"
                                                name="other_request"
                                                className="btn btn-primary btn-block mr-1 shadow text-center text-uppercase"
                                                type="submit"
                                            >
                                                {remItemId && props?.otherData === true ? 'Update' : 'Add'}
                                                <span id="add_other_loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                popup={popup}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var otherClaim = null;
    var otherClaimEdit = null;

    if (
        state.OtherClaimReducer != null &&
        Object.keys(state.OtherClaimReducer).length > 0 &&
        state.OtherClaimReducer.constructor === Object
    ) {
        switch (state.OtherClaimReducer.tag) {
            case ACTION_OTHER_CLAIM_SUCCESS:
                otherClaim = Object.assign({}, state.OtherClaimReducer.data);
                delete state.OtherClaimReducer;
                return { otherClaim };
            case ACTION_OTHER_CLAIM_EDIT_SUCCESS:
                otherClaimEdit = Object.assign({}, state.OtherClaimReducer.data);
                delete state.OtherClaimReducer;
                return { otherClaimEdit };
            default:
        }
    }

    return {
        otherClaim,
        otherClaimEdit
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionOtherClaim, actionOtherClaimEdit }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(OtherClaim))

