import {
    API_INSURER_STATUS,
    API_OPPORTUNITY_PLACEMENT_ADD,
    API_OPPORTUNITY_PLACEMENT_EDIT,
    API_DEPARTMENT_WISE_GET_INSURER,
    API_MASTER_GET_INSURER_BRANCH,
    API_MASTER_INSURER_STATES,
    API_MASTER_INSURER_CITIES
} from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_INSURER_STATUS_LOADING = 'ACTION_INSURER_STATUS_LOADING';
export const ACTION_INSURER_STATUS_SUCCESS = 'ACTION_INSURER_STATUS_SUCCESS';

export const ACTION_OPPORTUNITY_PLACEMENT_ADD_LOADING = 'ACTION_OPPORTUNITY_PLACEMENT_ADD_LOADING';
export const ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS = 'ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS';

export const ACTION_OPPORTUNITY_PLACEMENT_EDIT_LOADING = 'ACTION_OPPORTUNITY_PLACEMENT_EDIT_LOADING';
export const ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS = 'ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS';

export const ACTION_PLACEMENT_GET_INSURER_LOADING = 'ACTION_PLACEMENT_GET_INSURER_LOADING';
export const ACTION_PLACEMENT_GET_INSURER_SUCCESS = 'ACTION_PLACEMENT_GET_INSURER_SUCCESS';

export const ACTION_PLACEMENT_GET_INSURER_BRANCH_LOADING = 'ACTION_PLACEMENT_GET_INSURER_BRANCH_LOADING';
export const ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS = 'ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS';

export const ACTION_PLACEMENT_GET_INSURER_STATE_LOADING = 'ACTION_PLACEMENT_GET_INSURER_STATE_LOADING';
export const ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS = 'ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS';

export const ACTION_PLACEMENT_GET_INSURER_CITY_LOADING = 'ACTION_PLACEMENT_GET_INSURER_CITY_LOADING';
export const ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS = 'ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS';


export function actionInsurerStatus() {
    return (dispatch, getState) =>
        Api.get(API_INSURER_STATUS).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_INSURER_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURER_STATUS_LOADING, { loading: false, data }));
        });
}

export function actionOpportunityPlacementAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_OPPORTUNITY_PLACEMENT_ADD, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_PLACEMENT_ADD_LOADING, { loading: false, data }));
        });
}

export function actionOpportunityPlacementEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_OPPORTUNITY_PLACEMENT_EDIT, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_PLACEMENT_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionPlacementGetInsurer(params) {
    return (dispatch, getState) =>
        Api.post(API_DEPARTMENT_WISE_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_PLACEMENT_GET_INSURER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PLACEMENT_GET_INSURER_LOADING, { loading: false, data }));
        });
}

export function actionPlacementGetInsurerBranch(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_INSURER_BRANCH, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PLACEMENT_GET_INSURER_BRANCH_LOADING, { loading: false, data }));
        });
}

export function actionPlacementGetInsurerState(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_INSURER_STATES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PLACEMENT_GET_INSURER_STATE_LOADING, { loading: false, data }));
        });
}

export function actionPlacementGetInsurerCity(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_INSURER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PLACEMENT_GET_INSURER_CITY_LOADING, { loading: false, data }));
        });
}
