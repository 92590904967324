import React, { useEffect, useState } from 'react';
import {  useForm } from 'react-hook-form';
import { FiUser } from 'react-icons/fi';
import ErrorLabel from '../../../../../../component/form/error_label';
import ScreenTitle from '../../../../../../component/screen_title';
import { ERROR, UNAUTHORIZED, characterLimit } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionContentEdit, ACTION_CONTENT_EDIT_SUCCESS } from './action';
import { actionContentView, ACTION_CONTENT_VIEW_SUCCESS } from './action';
import { toast } from 'react-toastify';

const ContentEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm({})

    const [contentDetails, setContentDetails] = useState(null);
    var DisplayContentDescriptionWatcher = watch('display_content_description', '')

    const initialRender = () => {
        props.actionContentView({ id: props.location.state.content_id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (contentDetails != null) {
            setValue('screen_name', contentDetails.screen_name);
            setValue('section_name', contentDetails.section_name);
            setValue('display_content_title', contentDetails.display_content_title);
            setValue('display_content_description', contentDetails.display_content_description);
        }
    }, [contentDetails])

    const onContentViewUpdate = () => {
        const { ContentView } = props;
        var ContentViewResponse = Object.assign({}, ContentView);
        if (ContentViewResponse.result === true) {
            setContentDetails(ContentViewResponse.response);

        } else {
            switch (ContentViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onContentViewUpdate, [props.ContentView]);

    const onContentEditsUpdate = () => {
        const { ContentEdits } = props;
        var ContentEditsResponse = Object.assign({}, ContentEdits);
        if (ContentEditsResponse.result === true) {
            props.history.goBack();
        } else {
            switch (ContentEditsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(ContentEditsResponse.message);
                default:
            }
        }
    }
    useEffect(onContentEditsUpdate, [props.ContentEdits]);

    const onSubmit = (data) => {
        data.id = contentDetails.id;
        props.actionContentEdit(data);
        console.log(JSON.stringify(data));
    }

    return (
        <>
            <ScreenTitle title="Edit Content" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Content
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Screen Name</label>
                                                    <input
                                                        className="form-control"
                                                        id="screen_name"
                                                        name="screen_name"
                                                        placeholder="Enter Screen Name"
                                                        disabled={true}
                                                        {...register('screen_name')}
                                                    />
                                                    {errors.screen_name && <ErrorLabel message={errors.screen_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Section Name</label>
                                                    <input
                                                        className="form-control"
                                                        id="section_name"
                                                        name="section_name"
                                                        disabled={true}
                                                        placeholder="Enter Section Name"
                                                        {...register('section_name')}
                                                    />
                                                    {errors.section_name && <ErrorLabel message={errors.section_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control "> Display Content Title</label>
                                                    <input
                                                        className="form-control"
                                                        id="display_content_title"
                                                        name="display_content_title"
                                                        maxLength={250}
                                                        placeholder="Enter Display Content Title"
                                                        {...register('display_content_title')}
                                                    />
                                                    {errors.display_content_title && <ErrorLabel message={errors.display_content_title?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control "> Display Content Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="display_content_description"
                                                        row={5}
                                                        maxLength={characterLimit}
                                                        name="display_content_description"
                                                        placeholder="Enter Display Content Description"
                                                        {...register('display_content_description')}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{DisplayContentDescriptionWatcher != null ? DisplayContentDescriptionWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.display_content_description && <ErrorLabel message={errors.display_content_description?.message} />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        > Cancel</button>

                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            &nbsp;Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ContentEdits = null;
    var ContentView = null;

    if (
        state.EditPermissionContentReducer != null &&
        Object.keys(state.EditPermissionContentReducer).length > 0 &&
        state.EditPermissionContentReducer.constructor === Object
    ) {
        switch (state.EditPermissionContentReducer.tag) {
            case ACTION_CONTENT_EDIT_SUCCESS:
                ContentEdits = Object.assign({}, state.EditPermissionContentReducer.data);
                delete state.EditPermissionContentReducer
                return { ContentEdits };
            case ACTION_CONTENT_VIEW_SUCCESS:
                ContentView = Object.assign({}, state.EditPermissionContentReducer.data);
                delete state.EditPermissionContentReducer
                return { ContentView };
            default:
        }
    }

    return {
        ContentEdits,
        ContentView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionContentEdit, actionContentView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit)