import createReducer from "../../../../../reducers/createReducer";
import { ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS,ACTION_MASTER_ASSIGN_RM_SUCCESS ,ACTION_CLAIMS_POLICY_TYPE_SUCCESS,ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS} from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ProbabilityReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS,
                loading: false,
            }
        );
    } ,   
    [ACTION_MASTER_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
})
export default ProbabilityReducer