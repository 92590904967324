import React from 'react';
import { Route, Switch } from 'react-router';
import InsurerMasterList from './list';
import InsurerMasterAdd from './add';
import InsurerMasterEdit from './edit';
import InsurerMasterView from './view';
import BranchList from './branch/list';
import BranchMasterAdd from './branch/add';
import BranchMasterEdit from './branch/edit';
import BranchMasterView from './branch/view';
import ContactList from './contacts/list';

const InsurerMasterRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/branch/contacts`} component={ContactList} />
                <Route path={`${match.url}/branch/view`} component={BranchMasterView} />
                <Route path={`${match.url}/branch/edit`} component={BranchMasterEdit} />
                <Route path={`${match.url}/branch/add`} component={BranchMasterAdd} />
                <Route path={`${match.url}/branch`} component={BranchList} />
                <Route path={`${match.url}/view`} component={InsurerMasterView} />
                <Route path={`${match.url}/edit`} component={InsurerMasterEdit} />
                <Route path={`${match.url}/add`} component={InsurerMasterAdd} />
                <Route path={`${match.url}`} component={InsurerMasterList} />
            </Switch>
        </>
    )
}

export default InsurerMasterRoute