import React, { useState, useEffect } from 'react';
import Bar from "../../chart_details";
import { DateBox } from 'devextreme-react/date-box';
import { useForm, Controller } from "react-hook-form";
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../../constants';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FaRegHandshake } from 'react-icons/fa';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { FiShield } from 'react-icons/fi';
import { actionClientBusinessSummary, ACTION_CLIENT_BUSINESS_SUMMARY_SUCCESS } from './action';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withAuth } from 'react-auth-guard';

const BusinessSummary = (props) => {

    const { control } = useForm({});

    const [businessSummaryDetails, setBusinessSummaryDetails] = useState(null);

    const [fromDate, setFromDate] = useState(moment().month() < 3
        ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("yyyy-MM-DD")
        : moment()
            .month(3)
            .startOf("month")
            .format("yyyy-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));

    const initialRender = () => {

        props.actionClientBusinessSummary({ client_id: props.clientId });
    }
    useEffect(initialRender, [props.clientId]);

    const onBusinessSummaryUpdate = () => {
        const { BusinessSummary } = props;
        if (BusinessSummary != null) {
            var BusinessSummaryResponse = Object.assign({}, BusinessSummary);
            if (BusinessSummaryResponse.result === true) {
                setBusinessSummaryDetails(BusinessSummaryResponse.response);
            } else {
                switch (BusinessSummaryResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:

                }
            }
        }
    }
    useEffect(onBusinessSummaryUpdate, [props.BusinessSummary]);

    return (
        <>
            <div className="tab-pane active" id="client-details" aria-labelledby="client-details-tab" role="tabpanel">
                <div className="card mb-0">
                    <div className="card-body">
                        <div class="row mt-1 ml-1">
                            <div className="col-12 col-md-5 col-lg-5">
                                <div className="form-group">
                                    <Controller
                                        name="from_date"
                                        control={control}
                                        render={({ field }) =>
                                            <DateBox
                                                calendarOptions={{
                                                    showTodayButton: true,
                                                }}
                                                acceptCustomValue={false}
                                                stylingMode="underlined"
                                                pickerType="calendar"
                                                placeholder="From Date"
                                                type="date"
                                                displayFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                value={fromDate}
                                                onValueChange={(value) => {
                                                    field.onChange(moment(value).format('yyyy-MM-DD'));
                                                    setFromDate(moment(value).format('yyyy-MM-DD'));
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5">
                                <div className="form-group">
                                    <Controller
                                        name="to_date"
                                        control={control}
                                        render={({ field }) =>
                                            <DateBox
                                                calendarOptions={{
                                                    showTodayButton: true,
                                                }}
                                                acceptCustomValue={false}
                                                stylingMode="underlined"
                                                pickerType="calendar"
                                                placeholder="To Date"
                                                type="date"
                                                displayFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                value={toDate}
                                                onValueChange={(value) => {
                                                    field.onChange(moment(value).format('yyyy-MM-DD'));
                                                    setToDate(moment(value).format('yyyy-MM-DD'));
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <button
                                    className="btn btn-primary py-half mr-1 shadow round text-uppercase"
                                    type="submit"
                                    onClick={() => {
                                        if (fromDate.length > 0 && toDate.length > 0) {
                                            props.actionClientBusinessSummary({
                                                client_id: props.clientId,
                                                from_date: moment(fromDate).format('yyyy-MM-DD'),
                                                to_date: moment(toDate).format('yyyy-MM-DD')
                                            });
                                        } else {
                                            Swal.fire({
                                                title: 'Error',
                                                icon: 'error',
                                                html: 'Please Select From Date & To Date',
                                                confirmButtonColor: PRIMARY_COLOR
                                            }).then((result) => {

                                            });
                                        }
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mt-1 mx-half">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        businessSummaryDetails.no_of_policies : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Number Of Policies</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        Number(businessSummaryDetails.brokerage_details.current).toFixed(2) : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Current Year Brokerage {
                                                businessSummaryDetails != null && businessSummaryDetails.brokerage_details.current_unit != null ?
                                                    '(In ' + businessSummaryDetails.brokerage_details.current_unit + ')' : ''
                                            }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        Number(businessSummaryDetails.brokerage_details.previous).toFixed(2) : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Previous Year Brokerage {
                                                businessSummaryDetails != null && businessSummaryDetails.brokerage_details.previous_unit != null ?
                                                    '(In ' + businessSummaryDetails.brokerage_details.previous_unit + ')' : ''
                                            }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mt-2 mx-half">
                            <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                    <div className="flex-column">
                                        <span class="primary d-flex align-items-center justify-content-center p-1">
                                            <FiShield size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        businessSummaryDetails.no_of_proposal : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Number Of Opportunity</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column">
                                        <span class="primary d-flex align-items-center justify-content-center p-1">
                                            <FaRegHandshake size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        businessSummaryDetails.no_of_meetings : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Number Of Meeting</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                    <div className="flex-column">
                                        <span class="primary d-flex align-items-center justify-content-center p-1">
                                            <IoDocumentAttachOutline size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        businessSummaryDetails.no_of_claims : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Number Of Claims</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mt-2 mx-half">
                            <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                    <div className="flex-column">
                                        <span class="primary d-flex align-items-center justify-content-center p-1">
                                            <HiOutlineCurrencyRupee size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        businessSummaryDetails.total_brokerage : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Brokerage {
                                                businessSummaryDetails != null && businessSummaryDetails.total_brokerage_unit != null ?
                                                    '(In ' + businessSummaryDetails.total_brokerage_unit + ')' : ''
                                            }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column">
                                        <span class="primary d-flex align-items-center justify-content-center p-1">
                                            <HiOutlineCurrencyRupee size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        businessSummaryDetails.total_premium : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Premium {
                                                businessSummaryDetails != null && businessSummaryDetails.total_premium_unit != null ?
                                                    '(In ' + businessSummaryDetails.total_premium_unit + ')' : ''
                                            }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                    <div className="flex-column">
                                        <span class="primary d-flex align-items-center justify-content-center p-1">
                                            <HiOutlineCurrencyRupee size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div class="stats-amount">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    businessSummaryDetails != null ?
                                                        businessSummaryDetails.total_claim : '0'
                                                }
                                            </h3>
                                            <p class="sub-heading mb-0 font-small-3">Claim Amount {
                                                businessSummaryDetails != null && businessSummaryDetails.total_claim_unit != null ?
                                                    '(In ' + businessSummaryDetails.total_claim_unit + ')' : ''
                                            }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-0">
                    <div className="card-body pt-0">
                        <Bar details={businessSummaryDetails} />
                    </div>
                </div>
            </div>
        </>
    )

}

function mapStateToProps(state) {
    var BusinessSummary = null;

    if (
        state.ClientTabBusinessSummaryReducer != null &&
        Object.keys(state.ClientTabBusinessSummaryReducer).length > 0 &&
        state.ClientTabBusinessSummaryReducer.constructor === Object
    ) {
        switch (state.ClientTabBusinessSummaryReducer.tag) {
            case ACTION_CLIENT_BUSINESS_SUMMARY_SUCCESS:
                BusinessSummary = Object.assign({}, state.ClientTabBusinessSummaryReducer.data);
                delete state.ClientTabBusinessSummaryReducer;
                return { BusinessSummary };
            default:
        }
    }
    return {
        BusinessSummary
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionClientBusinessSummary }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(BusinessSummary));