import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import $ from 'jquery';
import { toast } from "react-toastify";
import { verifyPermission } from '../../../../../../utils';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { actionMyLead, ACTION_API_MY_LEAD_SUCCESS } from './action';
import { actionMyTeamLead, ACTION_MY_TEAM_LEAD_SUCCESS } from './action';
import { actionProfileLeadTeamMember, ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS } from './action';
import TeamMembers from '../../../../../../component/popups/team_details';
import { FiFilter } from "react-icons/fi";

const TabLeads = (props) => {
    const [myLeadCount, setMyLeadCount] = useState(0);
    const [clientDetails, setClientDetails] = useState([]);
    const [myTeamLeadCount, setMyTeamLeadCount] = useState(0);
    const [showRMName, setShowRMName] = useState(false);
    const [showTeamMeeting, setShowTeamMeeting] = useState(true);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [myTeamVertical, setMyTeamVertical] = useState([]);
    const [type, setType] = useState("my");
    const [teamStatus, setTeamStatus] = useState();
    const [userIds, setUserIds] = useState([]);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: "Created By" },
        { dataField: "name", caption: "Customer Name" },
        { dataField: "company_group_name", caption: "Group Name" },
        { dataField: "email", caption: "Email" },
        { dataField: "mobile", caption: "Mobile" },
    ]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        props.actionProfileLeadTeamMember({ user_id: props.userId });
        props.actionMyLead({
            clientType: "my",
            leadOrClient: "lead",
            user_id: props.userId,
            vertical: "corporate",
            clientOf: "insurance"
        });

    }
    useEffect(initialRender, [props.userId]);

    const onMyLeadsUpdate = () => {
        const { Lead } = props;
        if (Lead != null) {
            var MyLeadsResponse = Object.assign({}, Lead);
            if (MyLeadsResponse.result === true) {
                setShowRMName(false);
                setShowTeamMeeting(true);
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "full_name", caption: "Created By" },
                    { dataField: "name", caption: "Customer Name" },
                    { dataField: "company_group_name", caption: "Group Name" },
                    { dataField: "email", caption: "Email" },
                    { dataField: "mobile", caption: "Mobile" },
                ]);
                setClientDetails(MyLeadsResponse.response);
                setMyLeadCount(MyLeadsResponse.response.length);
                setMyTeamLeadCount(0);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                setClientDetails([]);
                setShowTeamMeeting(true);
                switch (MyLeadsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setClientDetails([]);
                        setMyLeadCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMyLeadsUpdate, [props.Lead]);

    const onMyTeamLeadUpdate = () => {
        const { MyTeamLead } = props;
        if (MyTeamLead != null) {
            var MyTeamLeadResponse = Object.assign({}, MyTeamLead);
            if (MyTeamLeadResponse.result === true) {
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "full_name", caption: "Created By" },
                    { dataField: "name", caption: "Customer Name" },
                    { dataField: "company_group_name", caption: "Group Name" },
                    { dataField: "email", caption: "Email" },
                    { dataField: "mobile", caption: "Mobile" },
                ]);
                setClientDetails(MyTeamLeadResponse.response);
                setMyLeadCount(0);
                setShowRMName(false);
                setShowTeamMeeting(false);
                setMyTeamLeadCount(MyTeamLeadResponse.response.length);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                setClientDetails([]);
                setShowTeamMeeting(true);
                switch (MyTeamLeadResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setClientDetails([]);
                        setMyTeamLeadCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMyTeamLeadUpdate, [props.MyTeamLead]);

    const onProfileLeadTeamMemberUpdate = () => {
        const { ProfileLeadTeamMember } = props;
        if (ProfileLeadTeamMember != null) {
            var ProfileLeadTeamMemberResponse = Object.assign({}, ProfileLeadTeamMember);
            setTeamStatus(ProfileLeadTeamMemberResponse.status);
            if (ProfileLeadTeamMemberResponse.result === true) {
                setShowTeamMeeting(true)
                var myTeam = []
                var myTeamMember = []
                ProfileLeadTeamMemberResponse.response.map((item) => {
                    if (item.id != props.userId) {
                        myTeam.push(item);
                        myTeamMember.push(item.id);
                        setMyTeamVertical(myTeam);
                        setMyTeamMembers(myTeamMember);
                    }
                })

            } else {
                setShowTeamMeeting(true)
                switch (ProfileLeadTeamMemberResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onProfileLeadTeamMemberUpdate, [props.ProfileLeadTeamMember]);

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        {
                            verifyPermission("VIEW_MY_LEADS") ?
                                <li class="nav-item">
                                    <button
                                        id="btn-my-lead"
                                        className={`btn ${type === "my" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setType("my");
                                            setShowTeamMeeting(false);
                                            setShowRMName(false);
                                            props.actionMyLead({
                                                clientType: "my",
                                                leadOrClient: "lead",
                                                user_id: props.userId,
                                                vertical: "corporate",
                                                clientOf: "insurance"
                                            });
                                            props.actionProfileLeadTeamMember({ user_id: props.userId });
                                        }}
                                    >
                                        My Leads {myLeadCount > 0 ? `(` + myLeadCount + `)` : ``}
                                    </button>
                                </li>
                                :
                                <></>
                        }
                        {
                            verifyPermission("VIEW_MY_TEAM_LEADS") && verifyPermission("SHOW_TEAM") && teamStatus !== 402 ?

                                <li class="nav-item ml-1">
                                    <button
                                        id="btn-my-team-lead"
                                        className={`btn ${type === "team" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            setType("team");
                                            if (showTeamMeeting != true) {
                                                if (showRMName === true) {
                                                    setShowRMName(false);
                                                } else {
                                                    setShowRMName(true);
                                                }
                                            }
                                            if (myTeamMembers != null && myTeamMembers.length > 0 && showTeamMeeting === true) {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionMyTeamLead({
                                                    leadOrClient: "lead",
                                                    user_id: myTeamMembers,
                                                    vertical: "corporate",
                                                    clientOf: "insurance"
                                                });
                                            }
                                        }}
                                    >
                                        Team Leads <span className='mr-half'>{myTeamLeadCount > 0 ? `(` + myTeamLeadCount + `)` : ``}</span>
                                        <FiFilter size={16} />
                                    </button>
                                </li>
                                : <> </>
                        }
                    </ul>
                </div>
            </>
        )
    }

    const renderGroupNameCell = (cell) => {
        if (cell?.data?.company_group_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/client/group-level-view',
                        state: { client_group_id: cell?.data?.client_group_id }
                    });
                }}
            ><u>{cell?.data?.company_group_name}</u></a>
        }
    }

    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                    if (cell?.data?.client_type.toLowerCase() === 'lead') {
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    } else if (cell?.data?.client_type.toLowerCase() === 'client') {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    }
                })}
            ><u>{cell?.data?.name}</u></a>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm float-left"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: '/leads/view',
                                            state: {
                                                client_id: params.data.id,
                                                type: 'Lead'
                                            }
                                        });
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        {
                            verifyPermission("UPDATE_LEADS") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/leads/edit',
                                                    state: {
                                                        client_id: params.data.id,
                                                        type: 'Lead',
                                                        visible: props.auth.userId === params.data.created_by_id ? true : false
                                                    }
                                                });
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "company_group_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderGroupNameCell}
                    ></Column>
                );
            }else if (objColumn.dataField === "name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompanyNameCell}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    useEffect(() => {
        return () => {
            $("#progress-loader").addClass("hidden");
        }
    }, [])

    return (

        <>
            <div className="content-header row mb-1">
                {renderHeaderButtonUI()}
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">

                            <div className="col-12 col-lg-12 col-md-12">
                                {
                                    clientDetails != null && clientDetails.length > 0 ?
                                        <DataGrid
                                            dataSource={clientDetails}
                                            columnAutoWidth={true}
                                            showBorders={true}
                                            export={{
                                                fileName: "leads",
                                                enabled: clientDetails != null && clientDetails.length > 0 ? true : false,
                                                allowExportSelectedData: false,
                                                excelFilterEnabled: true,
                                            }}
                                            onToolbarPreparing={(e) => {
                                                e.toolbarOptions.items.unshift({
                                                    location: 'before'

                                                })
                                            }}

                                        >
                                            <SearchPanel visible={true} />
                                            <FilterRow visible={true} />
                                            <HeaderFilter visible={true} />
                                            {renderColumns()}
                                            <Pager
                                                visible={true}
                                                showPageSizeSelector={false}
                                                showNavigationButtons={true}
                                                allowedPageSizes={[4, 6, 10]}
                                            />
                                            <Paging
                                                defaultPageIndex={0}
                                                defaultPageSize={10}
                                                enabled={true}
                                            />

                                        </DataGrid>
                                        :
                                        <h6 className="text-bold-500 text-center">No Leads Found</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TeamMembers
                {...props}
                show={showRMName}
                myTeamVertical={myTeamVertical}
                myTeamMembers={userIds != null && userIds.length > 0 ? userIds : myTeamMembers}
                handleClose={(teamMember) => {
                    if (teamMember != null) {
                        setUserIds(teamMember);
                        props.actionMyTeamLead({
                            leadOrClient: "lead",
                            user_id: teamMember,
                            vertical: "corporate",
                            clientOf: "insurance"
                        });
                    } else {
                        $("#progress-loader").addClass("hidden");
                    }
                    setShowRMName(false);
                }}
            />
        </>
    )
}

function mapStateToProps(state) {
    var Lead = null;
    var MyTeamLead = null;
    var ProfileLeadTeamMember = null;

    if (
        state.LeadListReducer != null &&
        Object.keys(state.LeadListReducer).length > 0 &&
        state.LeadListReducer.constructor === Object
    ) {
        switch (state.LeadListReducer.tag) {
            case ACTION_API_MY_LEAD_SUCCESS:
                Lead = Object.assign({}, state.LeadListReducer.data);
                delete state.LeadListReducer;
                return { Lead };
            case ACTION_MY_TEAM_LEAD_SUCCESS:
                MyTeamLead = Object.assign({}, state.LeadListReducer.data);
                delete state.LeadListReducer;
                return { MyTeamLead };
            case ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS:
                ProfileLeadTeamMember = Object.assign({}, state.LeadListReducer.data);
                delete state.LeadListReducer;
                return { ProfileLeadTeamMember };
            default:
        }
    }

    return {
        Lead,
        MyTeamLead,
        ProfileLeadTeamMember
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionMyLead, actionMyTeamLead, actionProfileLeadTeamMember }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TabLeads))
