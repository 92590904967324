import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { API_UNATTEND_CLIENT_LEAD_REPORT } from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";

export const ACTION_UNATTEND_CLIENT_LEAD_REPORT_LOADING ="ACTION_UNATTEND_CLIENT_LEAD_REPORT_LOADING";
export const ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS ="ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS";

export function actionUnattendClientLeadReport(params) {
    return (dispatch, getState) =>
        Api.post(API_UNATTEND_CLIENT_LEAD_REPORT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_UNATTEND_CLIENT_LEAD_REPORT_LOADING, { loading: false, data }));
        });
}