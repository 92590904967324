import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { UNAUTHORIZED } from "../../../../../../constants";
import $ from 'jquery';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { actionReinsuranceClient, ACTION_REINSURANCE_CLIENT_SUCCESS } from "./action";

const ClientList = (props) => {
    const { match } = props;
    const [reinsuranceClient, setReinsuranceClient] = useState([]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionReinsuranceClient({});
    }
    useEffect(initialRender, []);

    const onReinsuranceClientUpdate = () => {
        const { ReinsuranceClient } = props
        var ReinsuranceClientResponse = Object.assign({}, ReinsuranceClient);
        if (ReinsuranceClientResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setColumns([
                { dataField: "Action", caption: "Action", type: "button" },
                { dataField: "id", caption: "ID" },
                { dataField: "name", caption: "Customer Name" },
                { dataField: "client_type", caption: "Client Type" },
                { dataField: "country", caption: "Country" },
                { dataField: "status", caption: "Status" },
            ]);

            setReinsuranceClient(ReinsuranceClientResponse.response);

        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReinsuranceClientResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReinsuranceClientUpdate, [props.ReinsuranceClient])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "id", caption: "ID" },
        { dataField: "name", caption: "Customer Name" },
        { dataField: "client_type", caption: "Client Type" },
        { dataField: "country", caption: "Country" },
        { dataField: "status", caption: "Status" },
    ]);

    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                })}
            ><u>{cell?.data?.name}</u></a>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            }else if (objColumn.dataField === "name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompanyNameCell}
                    ></Column>
                );
            }else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Reinsurance Clients List</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button className="btn btn-primary btn-md px-1 font-small-3 round"
                                onClick={() => {
                                    props.history.push(`${match.url}/client`);
                                }}
                            >
                                Import Client Data
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={reinsuranceClient}
                                showBorders={true}
                                columnAutoWidth={true}
                                export={{
                                    fileName: "Reinsurance Client Data",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                            >
                                <LoadPanel enabled={true} />
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    var ReinsuranceClient = null;
    if (
        state.ReinsuranceClientReducer != null &&
        Object.keys(state.ReinsuranceClientReducer).length > 0 &&
        state.ReinsuranceClientReducer.constructor === Object
    ) {
        switch (state.ReinsuranceClientReducer.tag) {
            case ACTION_REINSURANCE_CLIENT_SUCCESS:
                ReinsuranceClient = Object.assign({}, state.ReinsuranceClientReducer.data);
                delete state.ReinsuranceClientReducer;
                return { ReinsuranceClient };
            default:
        }
    }
    return {
        ReinsuranceClient
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReinsuranceClient }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientList))

