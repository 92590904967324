import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_EMPLOYEE_VIEW, API_EMPLOYEE_VISITING_CARD } from '../../../api/constants';
import { PRINT } from '../../../utils';
export const ACTION_EMPLOYEE_VIEW_LOADING = 'ACTION_EMPLOYEE_VIEW_LOADING';
export const ACTION_EMPLOYEE_VIEW_SUCCESS = 'ACTION_EMPLOYEE_VIEW_SUCCESS';

export const ACTION_EMPLOYEE_VISITING_CARD_LOADING = 'ACTION_EMPLOYEE_VISITING_CARD_LOADING';
export const ACTION_EMPLOYEE_VISITING_CARD_SUCCESS = 'ACTION_EMPLOYEE_VISITING_CARD_SUCCESS';

export function actionEmployeeView(params) {
    return (dispatch, getState) =>
        Api.post(API_EMPLOYEE_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_VIEW_LOADING, { loading: false, data }));
        });
}
export function actionEmployeeVisitingCard(params) {
    return (dispatch, getState) =>
        Api.post(API_EMPLOYEE_VISITING_CARD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_VISITING_CARD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_VISITING_CARD_LOADING, { loading: false, data }));
        });
}