import React from 'react';
import { Route, Switch } from "react-router";
import ProbabilityList from './list';

const ProbabilityRoute = (props) => {
    const { match } = props

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}`} component={ProbabilityList} />
            </Switch>
        </>
    )
}
export default ProbabilityRoute