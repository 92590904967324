import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { DateBox } from 'devextreme-react/date-box';
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from '../../../../../../component/form/error_label';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import moment from 'moment';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { actionItDeclarationEdit, ACTION_IT_DECLARATION_EDIT_SUCCESS } from './action';
import { actionFinancialYear, ACTION_IT_DECLARATION_FINANCIAL_YEAR_SUCCESS } from './action';
import { actionDeclarationList, ACTION_IT_DECLARATION_LIST_SUCCESS } from './action';

const DeclarationEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [declaration, setDeclaration] = useState(null)
    const [endDate, setEndDate] = useState()
    const [startDate, setStartDate] = useState()
    const [windowPeriod, setWindowPeriod] = useState(0)
    const [financialYears, setFinancialYears] = useState([]);
    const [windowType, setWindowType] = useState('')
    const [declarationLists, setDeclarationLists] = useState([]);
    const [financialYear, setFinancialYear] = useState('');
    const [declarationListDropdown, setDeclarationListDropdown] = useState([]);

    const initialRender = () => {
        props.actionFinancialYear();
        props.actionDeclarationList();
        if (props.location.state != null && props.location.state.detail != null) {
            setDeclaration(props.location.state.detail);
        }
    }
    useEffect(initialRender, []);

    useEffect(() => {
        setEndDate(declaration != null && declaration.end_date != null ? dateFormat(declaration.end_date, 'yyyy-mm-dd') : '');
        setStartDate(declaration != null && declaration.start_date != null ? dateFormat(declaration.start_date, 'yyyy-mm-dd') : '');
        setValue('start_date', declaration != null && declaration.start_date != null ? dateFormat(declaration.start_date, 'yyyy-mm-dd') : '');
        setValue('end_date', declaration != null && declaration.end_date != null ? dateFormat(declaration.end_date, 'yyyy-mm-dd') : '');
        setValue('type', declaration != null && declaration.type != null ? declaration?.type : '');
        setWindowType(declaration != null && declaration.type != null ? declaration?.type : '')
        setValue('declaration_id', declaration != null && declaration.declaration_id != null ? declaration?.declaration_id : '')
    }, [declaration])

    useEffect(() => {
        var days = 0;
        if (startDate != null && endDate != null) {
            var endDateValue = moment(endDate, "YYYY/MM/DD");
            var startDateValue = moment(startDate, "YYYY/MM/DD");
            days = Number(moment(endDateValue).diff(startDateValue, 'days')) + 1
        }
        setWindowPeriod(days)
    }, [startDate, endDate])

    const onFinancialYearUpdate = () => {
        const { FinancialYear } = props;
        var FinancialYearResponse = Object.assign({}, FinancialYear);
        if (FinancialYearResponse.result === true) {
            setFinancialYears(FinancialYearResponse.response);
        } else {
            switch (FinancialYearResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setFinancialYears([]);
                default:
            }
        }
    }
    useEffect(onFinancialYearUpdate, [props.FinancialYear]);

    const onDeclarationListUpdate = () => {
        const { DeclarationList } = props;
        var DeclarationListResponse = Object.assign({}, DeclarationList);
        if (DeclarationListResponse.result === true) {
            setDeclarationLists(DeclarationListResponse.response);
        } else {
            switch (DeclarationListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setDeclarationLists([]);
                default:
            }
        }
    }
    useEffect(onDeclarationListUpdate, [props.DeclarationList]);

    useEffect(() => {
        setValue('fy', declaration?.fy);
    }, [financialYears])

    useEffect(() => {
        setValue('declaration_id', declaration?.declaration_id);
    }, [declarationLists])

    useEffect(() => {
        props.actionDeclarationList({ type: 'Declaration', fy: financialYear });
    }, [financialYear])


    useEffect(() => {
        const transformedArray = declarationLists.map(item => ({
            title: item.name,
            id: item.id
        }));

        setDeclarationListDropdown(transformedArray);
    }, [declarationLists])

    const onItDeclarationEditUpdate = () => {
        const { ItDeclarationEdit } = props;
        var ItDeclarationEditResponse = Object.assign({}, ItDeclarationEdit);
        $("#edit_declaration").prop("disabled", false);
        if (ItDeclarationEditResponse.result === true) {
            $("#edit_declaration_loader").addClass("hidden");
            props.history.goBack();
            toast.success('It Declaration Update successfully')
        } else {
            $("#edit_declaration_loader").addClass("hidden");
            switch (ItDeclarationEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(ItDeclarationEditResponse.message);
                default:
            }
        }
    }
    useEffect(onItDeclarationEditUpdate, [props.ItDeclarationEdit]);


    const onSubmit = (data) => {
        $("#edit_declaration_loader").removeClass("hidden");
        $("#edit_declaration").prop("disabled", true);
        data.id = props.location.state.id;
        props.actionItDeclarationEdit(data);
    }

    return (
        <>
            <ScreenTitle title="Declaration Edit" />
            <section>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-12'>
                                <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                                    <div className='form-body'>
                                        <div className='row'>
                                            <div className='col-12 col-md-6 col-lg-6'>
                                                <div className='form-group'>
                                                    <label className='label-control'>Type of Window&nbsp;<span className='danger'>*</span></label>
                                                    <Controller
                                                        name="type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    { 'title': 'Submission', 'id': 'Submission' },
                                                                    { 'title': 'Declaration', 'id': 'Declaration' }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Type of Window"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    field.onChange(event.itemData.id);
                                                                    setWindowType(event.itemData.id)
                                                                }
                                                                }
                                                            />
                                                        }
                                                        rule={{
                                                            required: 'Please select Type of Window '
                                                        }}
                                                    />
                                                    {errors.type && <ErrorLabel message={errors.type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Financial Year&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        id="fy"
                                                        name="fy"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id='fy'
                                                                name='fy'
                                                                dataSource={financialYears}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Financial Year"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    field.onChange(event.itemData.id)
                                                                    setFinancialYear(event.itemData.id)
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please select Financial Year'
                                                        }}
                                                    />
                                                    {errors.fy && <ErrorLabel message={errors.fy?.message} />}
                                                </div>
                                            </div>
                                            {windowType === 'Submission' ?
                                                <div className='col-12 col-md-6 col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='label-control'>
                                                            List of Declaration&nbsp; <span className='danger'>*</span>
                                                        </label>
                                                        <Controller
                                                            name="declaration_id"
                                                            control={control}
                                                            // defaultValue={}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={
                                                                        declarationListDropdown
                                                                    }
                                                                    placeholder='Select Declaration'
                                                                    fields={{ text: 'title', value: 'id' }}
                                                                    value={field.value}
                                                                    allowFiltering={false}
                                                                    change={(event) => field.onChange(event.itemData.id)}
                                                                />
                                                            }
                                                            rules={{ required: "Please select declaration" }}
                                                        />
                                                        {errors.declaration_id && <ErrorLabel message={errors.declaration_id?.message} />}
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="start_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                min={moment(new Date()).subtract(1, 'week').format('YYYY-MM-DD')}
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                acceptCustomValue={false}
                                                                onValueChange={(value) => {
                                                                    var objStartDate = new Date(value);
                                                                    var objEndDate = new Date(endDate);

                                                                    if (objEndDate.getTime() < objStartDate.getTime()) {
                                                                        Swal.fire({
                                                                            title: 'Error',
                                                                            text: "Start date can't be greater than End date",
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    } else {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                        setStartDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Start Date is required'
                                                        }}
                                                    />
                                                    {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">End Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="end_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                min={moment(new Date())}
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                acceptCustomValue={false}
                                                                onValueChange={(value) => {
                                                                    var objStartDate = new Date(startDate);
                                                                    var objEndDate = new Date(value);
                                                                    if (objEndDate.getTime() < objStartDate.getTime()) {
                                                                        Swal.fire({
                                                                            title: 'Error',
                                                                            text: "End date must be greater than from date",
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    } else {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                        setEndDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'End Date is required'
                                                        }}
                                                    />
                                                    {errors.end_date && <ErrorLabel message={errors.end_date?.message} />}
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6 col-lg-6'>
                                                <div className='form-group'>
                                                    <label className='label-control'>Window&nbsp;<span className='danger'>*</span></label>
                                                    <input
                                                        className='form-control'
                                                        id='window_period'
                                                        name='window_period'
                                                        type='text'
                                                        placeholder='Window'
                                                        value={windowPeriod}
                                                        disabled={true}
                                                        {...register('window_period',
                                                            // { required: 'Window is required' }
                                                        )}
                                                    />
                                                    {/* {errors.window_period && <ErrorLabel message={errors.window_period?.message} />} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="edit_declaration"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="edit_declaration_loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                props.history.push('/settings/declaration')
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var ItDeclarationEdit = null;
    var FinancialYear = null;
    var DeclarationList = null;

    if (
        state.ItDeclarationEditReducer != null &&
        Object.keys(state.ItDeclarationEditReducer).length > 0 &&
        state.ItDeclarationEditReducer.constructor === Object
    ) {
        switch (state.ItDeclarationEditReducer.tag) {
            case ACTION_IT_DECLARATION_EDIT_SUCCESS:
                ItDeclarationEdit = Object.assign({}, state.ItDeclarationEditReducer.data);
                delete state.ItDeclarationEditReducer
                return { ItDeclarationEdit };
            case ACTION_IT_DECLARATION_FINANCIAL_YEAR_SUCCESS:
                FinancialYear = Object.assign({}, state.ItDeclarationEditReducer.data);
                delete state.ItDeclarationEditReducer
                return { FinancialYear };
            case ACTION_IT_DECLARATION_LIST_SUCCESS:
                DeclarationList = Object.assign({}, state.ItDeclarationEditReducer.data);
                delete state.ItDeclarationEditReducer
                return { DeclarationList };
            default:
        }
    }

    return {
        ItDeclarationEdit,
        FinancialYear,
        DeclarationList
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItDeclarationEdit, actionFinancialYear, actionDeclarationList }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DeclarationEdit)