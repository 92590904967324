
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_RETENTION_LIST } from '../../../../../api/constants';
import { API_RETENTION_ASSIGN_LIST } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_RETENTION_LIST_LOADING = 'ACTION_RETENTION_LIST_LOADING';
export const ACTION_RETENTION_LIST_SUCCESS = 'ACTION_RETENTION_LIST_SUCCESS';

export const ACTION_RETENTION_ASSIGN_LIST_LOADING = 'ACTION_RETENTION_ASSIGN_LIST_LOADING';
export const ACTION_RETENTION_ASSIGN_LIST_SUCCESS = 'ACTION_RETENTION_ASSIGN_LIST_SUCCESS';

export function actionRetentionList(params) {
    return (dispatch, getState) =>
        Api.post(API_RETENTION_LIST, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RETENTION_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RETENTION_LIST_LOADING, { loading: false, data }));
        });
}
export function actionAssignRetentionList(params) {
    return (dispatch, getState) =>
        Api.post(API_RETENTION_ASSIGN_LIST, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RETENTION_ASSIGN_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RETENTION_ASSIGN_LIST_LOADING, { loading: false, data }));
        });
}