import React, { useState, useEffect } from "react";
import moment from "moment";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import AddDeclaration from "../add_declaration";
import AddFlexibleForm from "../add_flexible";
import EditDeclaration from "../edit_declaration";
import EditSubmission from "../edit_submission";
import AddSubmission from "../add_submission"
import EditFlexibleForm from "../edit_flexible";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import { actionItDeclarationList, ACTION_IT_DECLARATION_LIST_SUCCESS } from './action';
import { actionItDeclarationSettingActive, ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS } from './action';
import { KEY_TOAST_MESSAGE, getData, removeData } from "../../../../../../utils/preferences";
import { verifyPermission } from "../../../../../../utils";
import { CalculateFinancialYear } from "../../../../../../utils";

const DeclarationsList = (props) => {
    const { match } = props;
    const [type, setType] = useState(props?.location.state?.tab != null && props?.location.state?.from == "Notification" ? props.location.state?.tab : 'Declaration');
    const [getDeclarationList, setGetDeclarationList] = useState([]);
    const [showDeclarationForm, setShowDeclarationForm] = useState(false);
    const [showFlexibleForm, setShowFlexibleForm] = useState(false);
    const [showEditDeclaration, setShowEditDeclaration] = useState(false);
    const [showEditSubmission, setShowEditSubmission] = useState(false);
    const [showAddSubmission, setShowAddSubmission] = useState(false);
    const [dataSubmission, setDataSubmission] = useState(null);
    const [showEditFlexibleForm, setShowEditFlexibleForm] = useState(false);
    const [ItDeclarationId, setItDeclarationId] = useState(null);
    const [ItDeclarationActive, setItDeclarationActive] = useState(null);

    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: "Employee Name", visible: true },
        { dataField: "fy", caption: "FY Year", visible: true },
        { dataField: "status", caption: "Status of IT Declaration", visible: true },
        { dataField: "submit_on", caption: "Final Submission on", visible: false },
        { dataField: "end_date", caption: "Submission Due date", visible: false },
        { dataField: "submit_on_formated", caption: "Final Submission on", visible: true },
        { dataField: "end_date_formated", caption: "Submission Due date", visible: true },
        { dataField: "finance_remark", caption: "Finance Remarks", visible: true },
        { dataField: "finance_full_name", caption: "Remark By", visible: true },
    ];

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
        props.actionItDeclarationList({ type: type });
        props.actionItDeclarationSettingActive({ type: type });
    }
    useEffect(initialRender, []);

    const onItDeclarationListUpdate = () => {
        const { ItDeclarationList } = props;
        if (ItDeclarationList != null) {
            var ItDeclarationListResponse = Object.assign({}, ItDeclarationList);
            if (ItDeclarationListResponse.result === true) {
                setGetDeclarationList(ItDeclarationListResponse.response);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ItDeclarationListResponse.status) {
                    case ERROR:
                        break;
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onItDeclarationListUpdate, [props.ItDeclarationList]);

    const onItDeclarationSettingActiveUpdate = () => {
        const { ItDeclarationSettingActive } = props;
        if (ItDeclarationSettingActive != null) {
            var ItDeclarationSettingActiveResponse = Object.assign({}, ItDeclarationSettingActive);
            if (ItDeclarationSettingActiveResponse.result === true) {
                setItDeclarationActive(ItDeclarationSettingActiveResponse.response);
            } else {
                switch (ItDeclarationSettingActiveResponse.status) {
                    case ERROR:
                        break;
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onItDeclarationSettingActiveUpdate, [props.ItDeclarationSettingActive]);

    const renderSubmitDate = (cell) => {
        return <div>{cell?.data?.submit_on_formated != null ? moment(cell?.data?.submit_on_formated).format('DD-MMM-YYYY') : ''}</div>
    }

    const renderEndDate = (cell) => {
        return <div>{cell?.data?.end_date_formated != null ? moment(cell?.data?.end_date_formated).format('DD-MMM-YYYY') : ''}</div>
    }

    const renderEmployeeNameCell = (cell) => {
        if (cell?.data?.full_name != null) {
            return <a
            className='text-primary'
            onClick={() => {
                props.history.push({
                    pathname: '/profile',
                    state: {
                        user_id: cell?.data?.user_id,
                    }
                })
            }}
            ><u>{cell?.data?.full_name}</u></a>
        }
    }

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                id="btn-my-lead"
                                className={`btn ${type === 'Declaration' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    setType('Declaration')
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionItDeclarationList({ type: "Declaration" });
                                    props.actionItDeclarationSettingActive({ type: "Declaration" });
                                }}
                            >
                                Declaration
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                id="btn-open-lead"
                                className={`btn ${type == 'Submission' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    setType('Submission')
                                    // setGetDeclarationPoolList([])
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionItDeclarationList({ type: "Submission" });
                                    props.actionItDeclarationSettingActive({ type: "Submission" });
                                    // props.actionItDeclarationPoolList({
                                    //     status: 'PENDING',
                                    // });
                                }}
                            >
                                Submission
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("IT_DECLARATION_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                if (type === 'Declaration') {
                                                    props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                                } else if (type === 'Submission') {
                                                    props.history.push({ pathname: `submissions/view`, state: { id: params.data.id, window_id: params.data.window_id } })
                                                }
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        <Button
                            render={(params) => (
                                <button
                                    className={`btn btn-icon btn-sm ${!(['SUBMITTED', 'ACCEPTED'].includes(params.data.status)) && ItDeclarationActive != null && !(params.data.window_id != ItDeclarationActive?.id) ? '' : 'hidden'}`}
                                    onClick={() => {
                                        setItDeclarationId(params.data.id);
                                        if (type === 'Declaration') {
                                            setShowEditDeclaration(true);
                                        } else if (type === 'Submission') {
                                            setShowEditSubmission(true);
                                            setDataSubmission(params.data)
                                        }
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else if (objColumn.dataField === "submit_on_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSubmitDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "full_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEmployeeNameCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            }else if (objColumn.dataField === "end_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEndDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };
    return (
        <>
            <div className="content-header row mb-2">
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <h3 className="content-header-title text-capitalize mb-0">
                        {`Income Tax Declaration ${CalculateFinancialYear()}`}
                    </h3>
                </div>
                {
                    type == "Declaration" && verifyPermission("IT_DECLARATION_ADD") && ItDeclarationActive != null && !(getDeclarationList?.some(obj => obj.window_id == ItDeclarationActive?.id)) ?
                        <div className="content-header-right col-12 col-md-6 col-lg-6">
                            <ul class="nav nav-pills float-right">
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            setShowDeclarationForm(true);
                                        }}
                                    >
                                        Add
                                    </button>
                                </li>
                            </ul>
                        </div>
                        :
                        <></>
                }
                {
                    type == "Submission" && ItDeclarationActive != null && !(getDeclarationList?.some(obj => obj.window_id == ItDeclarationActive?.id)) ?
                        <div className="content-header-right col-12 col-md-6 col-lg-6">
                            <ul class="nav nav-pills float-right">
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            setShowAddSubmission(true)
                                        }}
                                    >
                                        Add
                                    </button>
                                </li>
                            </ul>
                        </div>
                        :
                        <></>
                }
            </div>
            <div className="content-header row mb-1">
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={getDeclarationList}
                                showBorders={true}
                                columnAutoWidth={true}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[5, 10, 20, 50]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
            <AddDeclaration
                {...props}
                show={showDeclarationForm}
                windowId={ItDeclarationActive?.id}
                handleClose={(data) => {
                    if (data != null && data.from === 'declaration') {
                        setShowFlexibleForm(true);
                    }
                    setShowDeclarationForm(false);
                }}
            />
            <AddFlexibleForm
                {...props}
                show={showFlexibleForm}
                windowId={ItDeclarationActive?.id}
                handleClose={(data) => {
                    if (data != null && data.from === 'flexible') {
                        toast.success('ITR Submitted Successfully');
                    }
                    props.actionItDeclarationList({ type: type });
                    setShowFlexibleForm(false);
                }}
            />
            <EditDeclaration
                {...props}
                show={showEditDeclaration}
                ItDeclarationId={ItDeclarationId}
                windowId={ItDeclarationActive?.id}
                handleClose={(data) => {
                    if (data != null && data.from === 'declaration') {
                        setShowEditFlexibleForm(true);
                    }
                    setShowEditDeclaration(false);
                }}
            />
            <EditSubmission
                {...props}
                show={showEditSubmission}
                ItDeclarationId={ItDeclarationId}
                data={dataSubmission}
                windowId={ItDeclarationActive?.id}
                handleClose={(data) => {
                    if (data != null && data.from === 'submission') {
                        props.actionItDeclarationList({ type: "Submission" });
                        props.actionItDeclarationSettingActive({ type: "Submission" });
                    }
                    setShowEditSubmission(false);
                }}
            />
            <AddSubmission
                {...props}
                show={showAddSubmission}
                ItDeclarationId={ItDeclarationId}
                windowId={ItDeclarationActive?.id}
                handleClose={(data) => {
                    if (data != null && data.from === 'submission') {
                        props.actionItDeclarationList({ type: "Submission" });
                        props.actionItDeclarationSettingActive({ type: "Submission" });
                    }
                    setShowAddSubmission(false);
                }}
            />
            <EditFlexibleForm
                {...props}
                show={showEditFlexibleForm}
                ItDeclarationId={ItDeclarationId}
                windowId={ItDeclarationActive?.id}
                handleClose={(data) => {
                    if (data != null && data === 'flexible') {
                        toast.success('ITR Updated Successfully');
                    }
                    props.actionItDeclarationList({ type: type });
                    setShowEditFlexibleForm(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var ItDeclarationList = null;
    var ItDeclarationSettingActive = null;

    if (
        state.ItDeclarationListReducer != null &&
        Object.keys(state.ItDeclarationListReducer).length > 0 &&
        state.ItDeclarationListReducer.constructor === Object
    ) {
        switch (state.ItDeclarationListReducer.tag) {
            case ACTION_IT_DECLARATION_LIST_SUCCESS:
                ItDeclarationList = Object.assign({}, state.ItDeclarationListReducer.data);
                delete state.ItDeclarationListReducer;
                return { ItDeclarationList };
            case ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS:
                ItDeclarationSettingActive = Object.assign({}, state.ItDeclarationListReducer.data);
                delete state.ItDeclarationListReducer;
                return { ItDeclarationSettingActive };
            default:
        }
    }

    return {
        ItDeclarationList,
        ItDeclarationSettingActive
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItDeclarationList, actionItDeclarationSettingActive }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DeclarationsList))