import { ACTION_API_MASTER_ENTITY_SUCCESS, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from "./action";
import { ACTION_EMPLOYEE_DETAIL_SUCCESS } from "./action";
import { ACTION_EMPLOYEE_EDIT_SUCCESS } from "./action";
import { ACTION_API_MASTER_SUB_ENTITY_SUCCESS } from "./action";
import { ACTION_API_MASTER_BRANCH_CITY_SUCCESS } from "./action";
import { ACTION_API_MASTER_DEPARTMENT_SUCCESS } from "./action";
import { ACTION_API_MASTER_GRADE_SUCCESS } from "./action";
import { ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS } from "./action";
import { ACTION_API_MASTER_GET_REPORTING_SUCCESS } from "./action";
import { ACTION_API_MASTER_JOB_ROLE_SUCCESS } from './action';
import { ACTION_API_MASTER_DESIGNATION_SUCCESS } from './action';
import { ACTION_API_MASTER_TEAM_HEAD_SUCCESS } from "./action";
import { ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS } from "./action";
import { ACTION_MASTER_ASSET_APPROVAL_MANAGER_SUCCESS } from "./action";
import { ACTION_MASTER_FINANCE_APPROVAL_MANAGER_SUCCESS } from "./action";
import { ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_SUCCESS } from "./action";
import { ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_SUCCESS } from "./action";
import { ACTION_MASTER_MEETING_APPROVAL_MANAGER_SUCCESS } from "./action";
import { ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_SUCCESS } from "./action";
import { ACTION_GETROLESDROPDOWNLIST_SUCCESS } from './action';
import { ACTION_DOCUMENT_DELETE_SUCCESS } from "./action";
import { EMPLOYEE_SHIFT_TIMINGS_SUCCESS } from './action';
import { ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS } from './action';

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EmployeeEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_CLAIMS_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EMPLOYEE_DETAIL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EMPLOYEE_DETAIL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EMPLOYEE_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EMPLOYEE_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_ENTITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_ENTITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_SUB_ENTITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_SUB_ENTITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_BRANCH_CITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_BRANCH_CITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_DEPARTMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_DEPARTMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_GRADE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_GRADE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_GET_REPORTING_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_GET_REPORTING_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_DESIGNATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_DESIGNATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_JOB_ROLE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_JOB_ROLE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_TEAM_HEAD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_TEAM_HEAD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_ASSET_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSET_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_FINANCE_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_FINANCE_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_MEETING_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_MEETING_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GETROLESDROPDOWNLIST_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_GETROLESDROPDOWNLIST_SUCCESS,
            loading: false
        });
    },
    [ACTION_DOCUMENT_DELETE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_DOCUMENT_DELETE_SUCCESS,
            loading: false
        });
    },
    [EMPLOYEE_SHIFT_TIMINGS_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: EMPLOYEE_SHIFT_TIMINGS_SUCCESS,
            loading: false
        });
    },
    [ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS,
            loading: false
        });
    }
});

export default EmployeeEditReducer;