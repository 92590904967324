import React from 'react';
import { Route, Switch } from "react-router";
import OpportunityQuestionsList from './list';
import OpportunityQuestionsAdd from './add';

const OpportunityQuestionsRoute = (props) => {
    const { match } = props

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`} component={OpportunityQuestionsAdd} />
                <Route exact path={`${match.url}`} component={OpportunityQuestionsList} />
            </Switch>
        </>
    )
}
export default OpportunityQuestionsRoute