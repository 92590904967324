
import Api from '../../../api';
import { API_MASTER_POLICY } from '../../../api/constants';
import { API_MASTER_INSURANCE_COMPANY } from '../../../api/constants';
import { API_SUM_INSURED } from '../../../api/constants';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_POLICY_PREMIUM } from '../../../api/constants';

export const ACTION_MASTER_POLICY_TYPE_LOADING = 'ACTION_MASTER_POLICY_TYPE_LOADING';
export const ACTION_MASTER_POLICY_TYPE_SUCCESS = 'ACTION_MASTER_POLICY_TYPE_SUCCESS';

export const ACTION_CLAIMS_INSURANCE_COMPANY_LOADING = 'ACTION_CLAIMS_INSURANCE_COMPANY_LOADING';
export const ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS = 'ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS';

export const ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW = 'ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW';
export const ACTION_SUM_INSURED_POLICY_LOADING_VIEW = 'ACTION_SUM_INSURED_POLICY_LOADING_VIEW';

export const ACTION_PREMIUM_SUCCESS = 'ACTION_PREMIUM_SUCCESS';
export const ACTION_PREMIUM_LOADING = 'ACTION_PREMIUM_LOADING';

export function actionPremiumPolicy(params) {
    return (dispatch, getState) =>
        Api.post(API_POLICY_PREMIUM, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PREMIUM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PREMIUM_LOADING, { loading: false, data }));
        });
}

export function actionPolicyType(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_POLICY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}
export function actionInsuredCompany() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_INSURANCE_COMPANY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}
export function actionSumInsuredPolicy(params) {
    return (dispatch, getState) =>
        Api.post(API_SUM_INSURED, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SUM_INSURED_POLICY_LOADING_VIEW, { loading: false, data }));
        });
}
