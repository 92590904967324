import React from 'react';
import { Route, Switch } from 'react-router';
import AllProposalEdit from './edit';
import ProposalPoolList from './list';
import ProposalSettingRoute from './opportunity_settings';
import ProposalSettingsView from './view';

const ProposalPoolRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/view`} component={ProposalSettingsView} />
                <Route path={`${match.url}/settings`} component={ProposalSettingRoute} />
                <Route path={`${match.url}/edit`} component={AllProposalEdit} />
                <Route path={`${match.url}`} component={ProposalPoolList} />
            </Switch>
        </>
    )
}

export default ProposalPoolRoute