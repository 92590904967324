import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiPhone, FiPlus, FiUser } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from "jquery";
import { toast } from 'react-toastify';
import { ERROR, UNAUTHORIZED, characterLimit, meetingCharacterLimit } from '../../../../../../constants';
import { actionAccompainedBy, ACTION_ACCOMPAINED_BY_SUCCESS } from './action';
import { actionMasterInsuranceCompanyName, ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS } from './action';
import { actionMasterStateByInsuranceCompany, ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS } from './action';
import { actionMasterCityByInsuranceCompany, ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS } from './action';
import { actionMasterBranchByInsuranceCompany, ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS } from './action';
import { actionInsuranceMeetingSave, ACTION_INSURANCE_MEETING_SAVE_SUCCESS } from './action';
import { actionGetCompanyContacts, ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS } from './action';
import { actionInsuranceMeetingAddContact, ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS } from './action';
import { actionGetBranchWiseContact, ACTION_GET_BRANCH_WISE_CONTACT_SUCCESS } from './action';
import InsuranceCompanyContactDetails from '../../../../../../component/form/insurance_company_contact_details';
import { getAccompainedByIds } from '../../../../../../utils';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';

const InsuranceCompanyMeetingAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors }, getValues, watch, setValue } = useForm({
        defaultValues: {
            accompained_by: []
        }
    })

    var meetingAgendaWatcher = watch('agenda', '')

    const [insuranceCompanyId, setInsuranceCompanyId] = useState(0)
    const [accompainedBy, setAccompainedBy] = useState([]);
    const [masterInsuranceCompanyName, setMasterInsuranceCompanyName] = useState([]);
    const [masterStateByInsuranceCompany, setMasterStateByInsuranceCompany] = useState([]);
    const [masterCityByInsuranceCompany, setMasterCityByInsuranceCompany] = useState([]);
    const [masterBranchByInsuranceCompany, setMasterBranchByInsuranceCompany] = useState([]);

    // Add Contact Variables
    const [companyContacts, setCompanyContacts] = useState([])
    const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([])
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [status, setStatus] = useState('close');
    const [existingDate, setExistingDate] = useState(false);
    const [branchId, setBranchId] = useState(null);

    const initialRender = () => {
        props.actionAccompainedBy({});
        props.actionMasterInsuranceCompanyName({});
    }
    useEffect(initialRender, []);

    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    const onMasterInsuranceCompanyNameUpdate = () => {
        const { MasterInsuranceCompanyName } = props;
        var MasterInsuranceCompanyNameResponse = Object.assign({}, MasterInsuranceCompanyName);
        if (MasterInsuranceCompanyNameResponse.result === true) {
            setMasterInsuranceCompanyName(MasterInsuranceCompanyNameResponse.response);
        } else {
            switch (MasterInsuranceCompanyNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterInsuranceCompanyNameUpdate, [props.MasterInsuranceCompanyName]);

    const onMasterStateByInsuranceCompanyUpdate = () => {
        const { MasterStateByInsuranceCompany } = props;
        var MasterStateByInsuranceCompanyResponse = Object.assign({}, MasterStateByInsuranceCompany);
        if (MasterStateByInsuranceCompanyResponse.result === true) {
            setMasterStateByInsuranceCompany(MasterStateByInsuranceCompanyResponse.response);
        } else {
            switch (MasterStateByInsuranceCompanyResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterStateByInsuranceCompanyUpdate, [props.MasterStateByInsuranceCompany]);

    const onMasterCityByInsuranceCompanyUpdate = () => {
        const { MasterCityByInsuranceCompany } = props;
        var MasterCityByInsuranceCompanyResponse = Object.assign({}, MasterCityByInsuranceCompany);
        if (MasterCityByInsuranceCompanyResponse.result === true) {
            setMasterCityByInsuranceCompany(MasterCityByInsuranceCompanyResponse.response);
        } else {
            switch (MasterCityByInsuranceCompanyResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCityByInsuranceCompanyUpdate, [props.MasterCityByInsuranceCompany]);

    const onMasterBranchByInsuranceCompanyUpdate = () => {
        const { MasterBranchByInsuranceCompany } = props;
        var MasterBranchByInsuranceCompanyResponse = Object.assign({}, MasterBranchByInsuranceCompany);
        if (MasterBranchByInsuranceCompanyResponse.result === true) {
            setMasterBranchByInsuranceCompany(MasterBranchByInsuranceCompanyResponse.response);
        } else {
            switch (MasterBranchByInsuranceCompanyResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterBranchByInsuranceCompanyUpdate, [props.MasterBranchByInsuranceCompany]);

    const onInsuranceMeetingSaveUpdate = () => {
        const { InsuranceMeeting } = props;
        var InsuranceMeetingResponse = Object.assign({}, InsuranceMeeting);
        $("#save-insurance-company").prop("disabled", false);
        if (InsuranceMeetingResponse.result === true) {
            $("#insurance-company-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Insurance Meeting Saved Successfully");
            props.history.goBack();
        } else {
            $("#insurance-company-form-loader").addClass("hidden");
            switch (InsuranceMeetingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    break;
                default:
            }
        }
    }
    useEffect(onInsuranceMeetingSaveUpdate, [props.InsuranceMeeting]);

    const onInsuranceMeetingAddContactUpdate = () => {

        const { InsuranceMeetingAddContact } = props;

        if (InsuranceMeetingAddContact != null) {
            var InsuranceMeetingAddContactResponse = Object.assign({}, InsuranceMeetingAddContact);
            if (InsuranceMeetingAddContactResponse.result === true) {
                var newContact = Object.assign({}, InsuranceMeetingAddContactResponse.response);
                var arrContacts = Object.assign([], companyContacts);
                arrContacts.push(newContact);
                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                arrSelectedCompanyContact.push(newContact.id);
                setCompanyContacts(arrContacts);
                setSelectedCompanyContacts(arrSelectedCompanyContact);
            } else {
                switch (InsuranceMeetingAddContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onInsuranceMeetingAddContactUpdate, [props.InsuranceMeetingAddContact]);

    const onCompanyContactsUpdate = () => {
        const { MeetingContact } = props
        var MeetingContactsResponse = Object.assign({}, MeetingContact)
        if (MeetingContactsResponse.result === true) {
            setCompanyContacts(MeetingContactsResponse.response)
            setSelectedCompanyContacts([])
        } else {
            switch (MeetingContactsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setCompanyContacts([])
                    setSelectedCompanyContacts([])
                    break;
                default:
            }
        }
    }
    useEffect(onCompanyContactsUpdate, [props.MeetingContact])


    const onGetBranchWiseContactUpdate = () => {
        const { GetBranchWiseContact } = props
        var GetBranchWiseContactResponse = Object.assign({}, GetBranchWiseContact)
        if (GetBranchWiseContactResponse.result === true) {
            setCompanyContacts(GetBranchWiseContactResponse.response)
        } else {
            switch (GetBranchWiseContactResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setCompanyContacts([])
                    break;
                default:
            }
        }
    }
    useEffect(onGetBranchWiseContactUpdate, [props.GetBranchWiseContact])

    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (selectedCompanyContacts.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Please select at least one Contact',
                icon: 'error'
            }).then(success => {

            })
        } else {
            if (fromTimeDate >= toTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: 'From Time cannot be greater than or equal to To Time',
                    icon: 'error'
                }).then(success => {

                })
            } else {
                data.created_by = props.auth.getUser().user_id;
                data.start_date_time = data.start_date + ' ' + data.from_time;
                data.end_date_time = data.start_date + ' ' + data.to_time;
                data.accompained_by = getAccompainedByIds(data.accompained_by);
                data.client_contact_ids = selectedCompanyContacts;
                data.meeting_type = 'insurer';
                if (existingDate === true) {
                    data.status = status
                }

                delete data.from_time;
                delete data.to_time;
                delete data.start_date;

                $("#insurance-company-form-loader").removeClass("hidden");
                $("#save-insurance-company").prop("disabled", true);
                props.actionInsuranceMeetingSave(data);
            }
        }
    }
    return (
        <>

            <ScreenTitle title="Add Insurance Company Meeting" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Insurance Company Meeting Information
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Company Name</label>
                                            <span className="danger">*</span>
                                            <Controller
                                                name="insurer_id"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        dataSource={masterInsuranceCompanyName}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        placeholder="Select Company Name"
                                                        value={field.value}
                                                        allowFiltering={true}
                                                        filterType='contains'
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                                setInsuranceCompanyId(event.itemData.id)
                                                                // props.actionGetCompanyContacts({ insurer_id: event.itemData.id })
                                                                props.actionMasterStateByInsuranceCompany({ insurer_company_id: event.itemData.id })
                                                                setMasterStateByInsuranceCompany([]);
                                                                setMasterCityByInsuranceCompany([]);
                                                                setMasterBranchByInsuranceCompany([]);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Company Name is required'
                                                }}
                                            />
                                            {errors.insurer_id && <ErrorLabel message={errors.insurer_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Organization Objective</label>
                                            <span className="danger">*</span>
                                            <Controller
                                                name="business_type"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        dataSource={[
                                                            {
                                                                name: 'Renewal',
                                                                id: 'Renewal'
                                                            },
                                                            {
                                                                name: 'New Business',
                                                                id: 'New Business'
                                                            },
                                                            {
                                                                name: 'Claim',
                                                                id: 'Claim'
                                                            },
                                                            {
                                                                name: 'Insurance Marketing',
                                                                id: 'Insurance Marketing'
                                                            },
                                                            {
                                                                name: 'Others',
                                                                id: 'Others'
                                                            }
                                                        ]}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={false}
                                                        placeholder="Select Organization Objective"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Organization Objective is required'
                                                }}
                                            />
                                            {errors.business_type && <ErrorLabel message={errors.business_type?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label className="label-control ">State</label>
                                            <span className="danger">*</span>
                                            <Controller
                                                name="state"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        dataSource={masterStateByInsuranceCompany}
                                                        fields={{ text: 'name', value: 'name' }}
                                                        allowFiltering={false}
                                                        placeholder="Select State"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.name)
                                                                props.actionMasterCityByInsuranceCompany({ insurer_company_id: insuranceCompanyId, state_id: event.itemData.id })
                                                                setMasterCityByInsuranceCompany([]);
                                                                setMasterBranchByInsuranceCompany([]);
                                                                setCompanyContacts([]);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'State is required'
                                                }}
                                            />
                                            {errors.state && <ErrorLabel message={errors.state?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label className="label-control ">City</label>
                                            <span className="danger">*</span>
                                            <Controller
                                                name="city_id"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        dataSource={masterCityByInsuranceCompany}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={false}
                                                        placeholder="Select City"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                                props.actionMasterBranchByInsuranceCompany({ insurer_company_id: insuranceCompanyId, city_id: event.itemData.id })
                                                                setMasterBranchByInsuranceCompany([]);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'City is required'
                                                }}
                                            />
                                            {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <div className="form-group">
                                            <label className="label-control ">Branch</label>
                                            <span className="danger">*</span>
                                            <Controller
                                                name="saiba_branch_id"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => {
                                                    const selectedBranch = masterBranchByInsuranceCompany.find(
                                                        branch => branch.saiba_branch_id === field.value
                                                    );

                                                    const branchName = selectedBranch ? selectedBranch.address : '';

                                                    return (
                                                        <div title={`Branch Name: ${branchName}`}>
                                                            <DropDownListComponent
                                                                dataSource={masterBranchByInsuranceCompany}
                                                                fields={{ text: 'address', value: 'saiba_branch_id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Branch"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.saiba_branch_id);
                                                                        setBranchId(event.itemData.saiba_branch_id);
                                                                        props.actionGetBranchWiseContact({ insurer_id: insuranceCompanyId, saiba_branch_id: event.itemData.saiba_branch_id });
                                                                        setSelectedCompanyContacts([]);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }}
                                                rules={{
                                                    required: 'Branch is required'
                                                }}
                                            />
                                            {errors.saiba_branch_id && <ErrorLabel message={errors.saiba_branch_id?.message} />}
                                        </div>
                                    </div>
                                    {
                                        insuranceCompanyId > 0 ?
                                            [<div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setShowContactDetails(true)
                                                        }}
                                                    >
                                                        <FiPlus
                                                            cursor="pointer"
                                                            size={20}
                                                        />
                                                    </button>
                                                </h4>
                                            </div>,
                                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                {
                                                    companyContacts != null && companyContacts.length > 0 ?
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Contact Name</th>
                                                                    <th>Contact Designation</th>
                                                                    <th>Contact Phone</th>
                                                                    <th>Contact Email</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    companyContacts.map((contact, indexContact) => (
                                                                        <tr key={contact.id}>
                                                                            <th>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`${contact.id}`}
                                                                                    name=""
                                                                                    defaultChecked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                    checked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                    onChange={(event) => {
                                                                                        var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                                                                                        if (!arrSelectedCompanyContact.includes(contact.id)) {
                                                                                            arrSelectedCompanyContact.push(contact.id)
                                                                                            setSelectedCompanyContacts(arrSelectedCompanyContact)
                                                                                        } else {
                                                                                            var contactIndex = arrSelectedCompanyContact.findIndex((objContact, index) => objContact == contact.id);
                                                                                            if (contactIndex >= 0) {
                                                                                                arrSelectedCompanyContact.splice(contactIndex, 1);
                                                                                                setSelectedCompanyContacts(arrSelectedCompanyContact);
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </th>
                                                                            <td>{contact.contact_name}</td>
                                                                            <td>{contact.contact_designation}</td>
                                                                            <td>{contact.contact_phone}</td>
                                                                            <td>{contact.contact_email}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <></>
                                                }
                                                <ErrorLabel message={insuranceCompanyId > 0 && companyContacts.length === 0 ? 'No contacts found' : ''} />
                                            </div>
                                            ]
                                            :
                                            <></>
                                    }
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="start_date"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        min={moment(new Date()).subtract(60, 'days').calendar()}
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={true}
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                            if (moment(value).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                                                                setExistingDate(true);
                                                            } else {
                                                                setExistingDate(false);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Start Date is required'
                                                }}
                                            />
                                            {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">From Time</label>
                                            <input
                                                type="time"
                                                defaultValue={moment().format('HH:mm')}
                                                className="form-control"
                                                id="from_time"
                                                name="from_time"
                                                placeholder="--:-- --"
                                                {...register('from_time', { required: 'From Time is required' })}
                                            />
                                            {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">To Time</label>
                                            <input
                                                type="time"
                                                defaultValue={moment().add(1, 'hours').format('HH:mm')}
                                                className="form-control"
                                                id="to_time"
                                                name="to_time"
                                                placeholder="--:-- --"
                                                {...register('to_time', { required: 'To Time is required' })}
                                            />
                                            {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="agenda"
                                                name="agenda"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Agenda"
                                                {...register('agenda', { required: 'Meeting Agenda is required' })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                <div className="flex-column"><small>{meetingAgendaWatcher.length}/{characterLimit}</small></div>
                                            </div>
                                            {errors.agenda && <ErrorLabel message={errors.agenda?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Description&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Description"
                                                {...register('description', { required: 'Meeting Description is required' })}
                                            />
                                            {errors.description && <ErrorLabel message={errors.description?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Accompanied By</label>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="accompained_by"
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            placeholder="Select"
                                                            menuPlacement={"top"}
                                                            isMulti={true}
                                                            hideSelectedOptions={false}
                                                            maxMenuHeight={200}
                                                            name="name"
                                                            options={accompainedBy}
                                                            value={field.value}
                                                            onChange={(selectedOptions) => {
                                                                field.onChange(selectedOptions);
                                                            }}
                                                        />
                                                    )
                                                }}
                                            />
                                            {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                        </div>
                                    </div>
                                    {
                                        existingDate === true ?
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={
                                                                    [
                                                                        {
                                                                            name: 'None of Above',
                                                                            id: ''
                                                                        },
                                                                        {
                                                                            name: 'Cancel',
                                                                            id: 'cancel'
                                                                        },
                                                                        {
                                                                            name: 'Close',
                                                                            id: 'close'
                                                                        }
                                                                    ]
                                                                }
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select status"
                                                                value={status}
                                                                enabled={false}
                                                                change={(event) => {

                                                                    if (event.itemData != null) {

                                                                        field.onChange(event.itemData.id);
                                                                        setStatus(event.itemData.id);

                                                                        if (event.itemData.id !== 'close') {
                                                                            setValue('minutes_of_meeting', '');
                                                                            setValue('remark', '');
                                                                        }

                                                                    }

                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                    {
                                        existingDate === true && status === 'close' ?
                                            [<div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Minutes Of Meeting</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="minutes_of_meeting"
                                                        name="minutes_of_meeting"
                                                        rows={5}
                                                        maxLength={meetingCharacterLimit}
                                                        placeholder="Enter Minutes Of Meeting"
                                                        {...register('minutes_of_meeting')}
                                                    />
                                                    {errors.minutes_of_meeting && <ErrorLabel message={errors.minutes_of_meeting?.message} />}
                                                </div>
                                            </div>,
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remarks</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        rows={5}
                                                        placeholder="Enter Remarks"
                                                        {...register('remark')}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>]
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none">
                                <button
                                    id="save-insurance-company"
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        Submit
                                        <span id="insurance-company-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                                <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.goBack();
                                    }}
                                > Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <InsuranceCompanyContactDetails
                show={showContactDetails}
                insuranceCompanyId={insuranceCompanyId}
                branchId={branchId}
                handleClose={(newContact) => {
                    if (newContact != null) {
                        props.actionInsuranceMeetingAddContact({
                            insurer_id: getValues("insurer_id"),
                            client_type: "insurer",
                            contact_designation: newContact.contact_designation,
                            contact_email: newContact.contact_email,
                            contact_name: newContact.contact_name,
                            contact_phone: newContact.contact_phone,
                            department: newContact.department,
                            office_number: newContact.office_number,
                            saiba_branch_id: newContact.saiba_branch_id
                        });
                    }
                    setShowContactDetails(false)
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {

    var AccompainedBy = null;
    var MasterInsuranceCompanyName = null;
    var MasterStateByInsuranceCompany = null;
    var MasterCityByInsuranceCompany = null;
    var MasterBranchByInsuranceCompany = null;
    var InsuranceMeeting = null;
    var MeetingContact = null;
    var InsuranceMeetingAddContact = null;
    var GetBranchWiseContact = null;

    if (
        state.AddInsuranceMeetingReducer != null &&
        Object.keys(state.AddInsuranceMeetingReducer).length > 0 &&
        state.AddInsuranceMeetingReducer.constructor === Object
    ) {
        switch (state.AddInsuranceMeetingReducer.tag) {
            case ACTION_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { AccompainedBy };
            case ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS:
                MasterInsuranceCompanyName = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { MasterInsuranceCompanyName };
            case ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS:
                MasterStateByInsuranceCompany = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { MasterStateByInsuranceCompany };
            case ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS:
                MasterCityByInsuranceCompany = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { MasterCityByInsuranceCompany };
            case ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS:
                MasterBranchByInsuranceCompany = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { MasterBranchByInsuranceCompany };
            case ACTION_INSURANCE_MEETING_SAVE_SUCCESS:
                InsuranceMeeting = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { InsuranceMeeting };
            case ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS:
                MeetingContact = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { MeetingContact };
            case ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS:
                InsuranceMeetingAddContact = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { InsuranceMeetingAddContact };
            case ACTION_GET_BRANCH_WISE_CONTACT_SUCCESS:
                GetBranchWiseContact = Object.assign({}, state.AddInsuranceMeetingReducer.data);
                delete state.AddInsuranceMeetingReducer;
                return { GetBranchWiseContact };
            default:
        }
    }
    return {
        AccompainedBy,
        MasterInsuranceCompanyName,
        MasterStateByInsuranceCompany,
        MasterCityByInsuranceCompany,
        MasterBranchByInsuranceCompany,
        InsuranceMeeting,
        MeetingContact,
        InsuranceMeetingAddContact,
        GetBranchWiseContact
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionAccompainedBy,
        actionMasterInsuranceCompanyName,
        actionMasterStateByInsuranceCompany,
        actionMasterCityByInsuranceCompany,
        actionMasterBranchByInsuranceCompany,
        actionInsuranceMeetingSave,
        actionGetCompanyContacts,
        actionInsuranceMeetingAddContact,
        actionGetBranchWiseContact
    }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(InsuranceCompanyMeetingAdd))