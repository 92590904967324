import { ACTION_APP_SETTING_ADD_SUCCESS } from './action';

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddAppSettingsReducer = createReducer(INITIAL_STATE, {

    [ACTION_APP_SETTING_ADD_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_APP_SETTING_ADD_SUCCESS,
            loading: false
        });
    },
});

export default AddAppSettingsReducer;
