import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MYTEAMVERTICAL } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const MYTEAMVERTICAL_LOADING = 'MYTEAMVERTICAL_LOADING';
export const ACTION_MYTEAMVERTICAL_SUCCESS = 'ACTION_MYTEAMVERTICAL_SUCCESS';

export function actionMyTeamVertical(params) {
        return (dispatch, getState) => Api.post(API_MYTEAMVERTICAL,params).then((res) => {
            const data = res;
            PRINT('MYTEAMVERTICAL ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_MYTEAMVERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(MYTEAMVERTICAL_LOADING, { loading: false, data }));
        });
    }
