import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UNAUTHORIZED } from '../../../constants';
import { actionOpportunityPoolQuestion, ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS, } from "./action";

const OpportunityPoolDetails = (props) => {
    const [showOpportunityPoolQuestions, setShowOpportunityPoolQuestions] = useState([]);
    const initialRender = () => {
        if (props.show === true) {
            props.actionOpportunityPoolQuestion({ id: props.details.id });
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onOpportunityPoolQuestionUpdate = () => {
        const { OpportunityPoolQuestion } = props
        var OpportunityPoolQuestionResponse = Object.assign({}, OpportunityPoolQuestion);
        if (OpportunityPoolQuestionResponse.result === true) {
            setShowOpportunityPoolQuestions(OpportunityPoolQuestionResponse.response)
        } else {
            switch (OpportunityPoolQuestionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onOpportunityPoolQuestionUpdate, [props.OpportunityPoolQuestion])

    return (
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Opportunity Pool Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Assigned RM</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.details != null && props.details.assigned_rm_name != null ? props.details.assigned_rm_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Opportunity Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.details != null && props.details.oppurtunity_type != null ? props.details.oppurtunity_type : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1 text-primary cursor-pointer">Customer</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify"
                                onClick={(() => {
                                    if (props.details.client_type === 'lead') {
                                        props.history.push({
                                            pathname: '/leads/view',
                                            state: {
                                                client_id: props.details?.client_id,
                                            }
                                        });
                                    } else if (props.details.client_type === 'client') {
                                        props.history.push({
                                            pathname: '/client/view',
                                            state: {
                                                client_id: props.details?.client_id,
                                            }
                                        });
                                    }
                                })}
                                >
                                    {props.details != null && props.details.customer_name != null ? props.details.customer_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Group</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.details != null && props.details.company_group_name != null ? props.details.company_group_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.details != null && props.details.policy_type != null ? props.details.policy_type : '-'}
                                </h4>
                            </div>
                            {
                                props.details != null && props.details.oppurtunity_type != null && props.details.oppurtunity_type != 'Fresh' ?
                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurance Company Name</h6>
                                        <h4 className="font-medium-1 text-bold-700 text-justify">
                                            {props.details != null && props.details.insurance_name != null ? props.details.insurance_name : '-'}
                                        </h4>
                                    </div>
                                    :
                                    <></>
                            }
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Department</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.details != null && props.details.department_name != null ? props.details.department_name : '-'}
                                </h4>
                            </div>
                            {
                                props.details != null && props.details.oppurtunity_type != null && props.details.oppurtunity_type != 'Fresh' ?
                                    <>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Intermediary Type</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {props.details != null && props.details.intermediary_type != null ? props.details.intermediary_type : '-'}
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">{`${props?.details?.intermediary_type != 'Broker' ? 'Intermediary Details' : 'Broker Name'}`}</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {props.details != null && props.details.intermediary_value != null ? props.details.intermediary_value : '-'}
                                            </h4>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                props.proposalType === 'assigned' ?
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Assigned Specialist</h6>
                                        <h4 className="font-medium-1 text-bold-700 text-justify">
                                            {props.details != null && props.details.product_specialist_name != null ? props.details.product_specialist_name : '-'}
                                        </h4>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                props.details != null && props.details.oppurtunity_type != null && props.details.oppurtunity_type != 'Fresh' ?
                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Renewal Date</h6>
                                        <h4 className="font-medium-1 text-bold-700 text-justify">
                                            {props.details != null && props.details.expiring_date != null ? props.details.expiring_date : '-'}
                                        </h4>
                                    </div>
                                    :
                                    <></>
                            }

                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Expected Premium</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.details != null && props.details.expected_premium != null ?
                                        <div><span>&#8377;</span> {props.details.expected_premium} </div> : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Probability</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.details != null && props.details.probability != null ? props.details.probability + '%' : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Created By</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {props.details != null && props.details.created_by != null ? props.details.created_by : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Create Date</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {props?.details != null && props?.details?.created_at != null ? props?.details?.created_at : '-'}
                                </h4>
                            </div>

                        </div>
                        {
                            showOpportunityPoolQuestions.opportunity_questions != null && showOpportunityPoolQuestions.opportunity_questions.length > 0 ?
                                <>
                                    <div className="col-12 col-md-12 col-lg-12 pl-0">
                                        <h4 className="modal-title" id="myModalLabel1">
                                            More Information
                                        </h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1 d-flex">System Probability</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify d-flex">
                                                {showOpportunityPoolQuestions.total_probability != null ? showOpportunityPoolQuestions.total_probability : '-'}
                                            </h4>
                                        </div>
                                        {
                                            showOpportunityPoolQuestions.opportunity_questions != null && showOpportunityPoolQuestions.opportunity_questions.length > 0 ?
                                                showOpportunityPoolQuestions.opportunity_questions.map((item, index) => (
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1 d-flex">{item.question}</h6>
                                                        <h4 className="font-small-2 text-bold-700 text-justify mt-1 d-flex">{item.question_value}
                                                        </h4>
                                                    </div>
                                                ))

                                                :
                                                <></>

                                        }

                                    </div>
                                </>
                                :
                                <></>

                        }
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.handleClose();
                                }}
                            >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};
const mapStateToProps = (state) => {
    var OpportunityPoolQuestion = null;
    if (
        state.OpportunityPoolReducer != null &&
        Object.keys(state.OpportunityPoolReducer).length > 0 &&
        state.OpportunityPoolReducer.constructor === Object
    ) {
        switch (state.OpportunityPoolReducer.tag) {
            case ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS:
                OpportunityPoolQuestion = Object.assign({}, state.OpportunityPoolReducer.data);
                delete state.OpportunityPoolReducer;
                return { OpportunityPoolQuestion };
            default:

        }
    }
    return {
        OpportunityPoolQuestion,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionOpportunityPoolQuestion }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(OpportunityPoolDetails))