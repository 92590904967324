import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_SUPPORT_VIEW, API_IT_CHANGE_STATUS } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_IT_SUPPORT_VIEW_LOADING = 'ACTION_IT_SUPPORT_VIEW_LOADING';
export const ACTION_IT_SUPPORT_VIEW_SUCCESS = 'ACTION_IT_SUPPORT_VIEW_SUCCESS';

export const ACTION_CHANGE_STATUS_LOADING = 'ACTION_CHANGE_STATUS_LOADING';
export const ACTION_CHANGE_STATUS_SUCCESS = 'ACTION_CHANGE_STATUS_SUCCESS';

export function actionItSupportView(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_SUPPORT_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_IT_SUPPORT_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_SUPPORT_VIEW_LOADING, { loading: false, data }));
        });
}
export function actionChangeStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_CHANGE_STATUS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CHANGE_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CHANGE_STATUS_SUCCESS, { loading: false, data }));
        });
}