import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_ATTENDANCE_LEAVE } from "../../../api/constants";
import { PRINT } from "../../../utils";

export const ACTION_ATTENDANCE_LEAVE_LOADING = "ACTION_ATTENDANCE_LEAVE_LOADING";
export const ACTION_ATTENDANCE_LEAVE_SUCCESS = "ACTION_ATTENDANCE_LEAVE_SUCCESS";

export function actionAttendanceLeave() {
    return (dispatch, getState) =>
        Api.get(API_ATTENDANCE_LEAVE)
            .then((res) => {
                const data = res;
                PRINT("ACTION RESPONSE : ", data);
                dispatch(fetchSuccess(ACTION_ATTENDANCE_LEAVE_SUCCESS, { data }));
            })
            .catch((reason) => {
                /** Handle Error */
                var data = {
                    message: "",
                };
                dispatch(loading(ACTION_ATTENDANCE_LEAVE_LOADING, { loading: false, data }));
            });
}