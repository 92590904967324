import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import dateFormat from 'dateformat';
import { UNAUTHORIZED, characterLimit, ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import 'react-day-picker/lib/style.css';
import { getDayDifference, checkFileExtension } from '../../../../../../utils';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionLeaveRequestAdd, ACTION_LEAVE_REQUEST_ADD_SUCCESS } from './action';
import { actionLeaveRequestApprovalManager, ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS } from './action';
import { actionTotalLeave, ACTION_TOTAL_LEAVE_SUCCESS } from './action';
import { actionHolidayList, ACTION_HOLIDAY_LIST_SUCCESS } from './action';
import Swal from 'sweetalert2';
import moment from 'moment';

const RequestAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors }, watch, setValue, getValues } = useForm()

    const [minFromDate, setMinFromDate] = useState(new Date());
    const [minToDate, setMinToDate] = useState(new Date());
    const [toDateDisable, setToDateDisable] = useState(true);
    const [dayCount, setDayCount] = useState(0);
    const [leaveRequestApprovalManager, setLeaveRequestApprovalManager] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [totalLeave, setTotalLeave] = useState(null);
    const [leaveType, setLeaveType] = useState([]);
    const [leaveTypes, setLeaveTypes] = useState(null);
    const validExt = ['jpg', 'jpeg', 'png', 'docx', 'doc', 'pdf', 'PDF'];
    const [uploadDocument, setUploadDocument] = useState(null);
    const [holidayDetails, setHolidayDetails] = useState([]);
    const [weekenddays, setWeekendDays] = useState(null);


    var watchReason = watch('reason');

    const initialRender = () => {
        props.actionLeaveRequestApprovalManager({});
        props.actionTotalLeave({});
        props.actionHolidayList();
        setWeekendDays(props.location.state.working_days)
    }
    useEffect(initialRender, []);

    const onLeaveRequestAddUpdate = () => {
        const { LeaveRequestAdd } = props;
        var LeaveRequestAddResponse = Object.assign({}, LeaveRequestAdd);
        $("#leave-request").prop("disabled", false);
        if (LeaveRequestAddResponse.result === true) {
            $("#leave-request-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Leave Request Added Successfully");
            props.history.goBack();
        } else {
            $("#leave-request-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (LeaveRequestAddResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Leave request error',
                        text: LeaveRequestAddResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onLeaveRequestAddUpdate, [props.LeaveRequestAdd]);

    const onLeaveRequestApprovalManagerUpdate = () => {
        const { LeaveRequestApprovalManager } = props;
        var LeaveRequestApprovalManagerResponse = Object.assign({}, LeaveRequestApprovalManager);
        if (LeaveRequestApprovalManagerResponse.result === true) {
            setLeaveRequestApprovalManager(LeaveRequestApprovalManagerResponse.response.managers);
        } else {
            switch (LeaveRequestApprovalManagerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onLeaveRequestApprovalManagerUpdate, [props.LeaveRequestApprovalManager]);

    const onTotalLeaveUpdate = () => {
        const { TotalLeave } = props;
        var TotalLeaveResponse = Object.assign({}, TotalLeave);
        if (TotalLeaveResponse.result === true) {
            setTotalLeave(TotalLeaveResponse.response);
            setLeaveType(TotalLeaveResponse.response.leave_types)
            setWeekendDays(TotalLeaveResponse.response.working_days)
        } else {
            switch (TotalLeaveResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onTotalLeaveUpdate, [props.TotalLeave]);

    const onHolidayListUpdate = () => {
        const { HolidayList } = props;
        var HolidayListResponse = Object.assign({}, HolidayList);
        if (HolidayListResponse.result === true) {
            setHolidayDetails(HolidayListResponse.response);
        } else {
            switch (HolidayListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onHolidayListUpdate, [props.HolidayList]);

    const devXDisableWeekend = (args) => {
        const dayOfWeek = args.date.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6 && weekenddays === 5) {
            return true
        } else {
            if (holidayDetails != null && holidayDetails.length > 0) {
                var found = holidayDetails.find(item => item.date === moment(args.date).format('YYYY-MM-DD') ? true : false)
                return found
            }
        }
        return false
    }

    const getDayDifference = (fromDate, toDate) => {

        var startDate = new moment(fromDate);
        var endDate = new moment(toDate);

        // Get Total Days Difference 
        var duration = moment.duration(endDate.diff(startDate)).days();

        var start = new Date(startDate);
        var weekEndCount = 0;

        // Calcuate Weekend Days
        while (start <= endDate) {
            if (start.getDay() === 0 || start.getDay() === 6 && weekenddays === 5) {
                weekEndCount++;
            }
            var found = holidayDetails.find(item => item.date === moment(start).format('YYYY-MM-DD') ? true : false)
            if (found) {
                weekEndCount++;
            }
            var newDate = start.setDate(start.getDate() + 1);
            start = new Date(newDate);
        }

        if (duration < 0) {
            return 0;
        }
        return ((duration - weekEndCount) + 1);
    }

    const onSubmit = (data) => {
        if (uploadDocument === null && leaveTypes != null && leaveTypes === 'medical_leave') {
            $('#error-pdf-doc-not-found').removeClass('d-none')
            return;

        }
        data.user_id = props.auth.getUser().user_id;
        data.approval_manager_id = leaveRequestApprovalManager.attendance_manager_id;
        data.from_date = fromDate;
        data.to_date = toDate;
        data.day_count = dayCount;
        var files = [];
        if (uploadDocument != null) {
            files.push({ 'name': 'upload_document', 'value': uploadDocument });
        }
        delete data.upload_document;
        // $("#progress-loader").removeClass("hidden");
        $("#leave-request-loader").removeClass("hidden");
        $("#leave-request").prop("disabled", true);
        props.actionLeaveRequestAdd(data, files);
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6">
                        <h3 className="content-header-title text-capitalize mb-0">Add New Leave Request </h3>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Available Leaves&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="available_leaves"
                                                        name="available_leaves"
                                                        readOnly={true}
                                                        value={(totalLeave != null && totalLeave.total_leaves != null) ? totalLeave.total_leaves : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Attendance Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="approval_manager"
                                                        name="approval_manager"
                                                        readOnly={true}
                                                        value={(leaveRequestApprovalManager != null && leaveRequestApprovalManager.attendance_manager_name != null) ? leaveRequestApprovalManager.attendance_manager_name : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"> From Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="from_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                id="from_date"
                                                                name="from_date"
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                value={field.value}
                                                                min={minFromDate}
                                                                acceptCustomValue={false}
                                                                disabledDates={devXDisableWeekend}
                                                                onValueChange={(value) => {
                                                                    var objFromDate = new Date(value);
                                                                    var objToDate = new Date(toDate);

                                                                    if (objToDate.getTime() < objFromDate.getTime()) {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                        setValue('to_date', null);
                                                                        setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                        setMinToDate(dateFormat(value, 'yyyy-mm-dd'))
                                                                        setDayCount(0)
                                                                    } else {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                        setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                        setToDateDisable(false)
                                                                        setMinToDate(dateFormat(value, 'yyyy-mm-dd'))
                                                                        if (getValues('to_date')) {
                                                                            setDayCount(getDayDifference(value, toDate))
                                                                        }

                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{

                                                            required: 'From Date is required'
                                                        }}
                                                    />
                                                    {errors.from_date && <ErrorLabel message={errors.from_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"> To Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="to_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                id="to_date"
                                                                name="to_date"
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                disabled={toDateDisable}
                                                                value={field.value}
                                                                min={minToDate}
                                                                acceptCustomValue={false}
                                                                disabledDates={devXDisableWeekend}
                                                                onValueChange={(value) => {
                                                                    setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));

                                                                    setDayCount(getDayDifference(fromDate, value))
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'To Date is required'
                                                        }}
                                                    />
                                                    {errors.to_date && <ErrorLabel message={errors.to_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Leave Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="leave_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="leave_type"
                                                                name="leave_type"
                                                                dataSource={leaveType}
                                                                allowFiltering={false}
                                                                placeholder="Select Leave Type"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setLeaveTypes(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Leave Type is required'
                                                        }}
                                                    />

                                                    {errors.leave_type && <ErrorLabel message={errors.leave_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Days Count</label>
                                                    <input
                                                        className="form-control"
                                                        id="days_count"
                                                        name="days_count"
                                                        placeholder="Enter Days Count"
                                                        disabled={true}
                                                        value={dayCount}
                                                    // {...register('days_count')}
                                                    />
                                                    {/* {errors.days_count?.type === 'required' && <ErrorLabel message={errors.days_count?.message} />} */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Reason&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="reason"
                                                        name="reason"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Add Reason"
                                                        {...register('reason', { required: 'Reason is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{watchReason != null ? watchReason.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.reason && <ErrorLabel message={errors.reason?.message} />}
                                                </div>
                                            </div>
                                            {
                                                leaveTypes != null && leaveTypes === 'medical_leave' ?
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <fieldset class="form-group">
                                                            <label for="basicInputFile">Upload Document&nbsp;<span className="danger">*</span></label>
                                                            <div class="custom-file">
                                                                <input
                                                                    type="file"
                                                                    class="custom-file-input"
                                                                    id="upload_document"
                                                                    accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .PDF"
                                                                    name="upload_document"
                                                                    onChange={(event) => {
                                                                        $('#error-pdf-doc-not-found').addClass('d-none')
                                                                        if (event.target.files != null) {

                                                                            var fileSize = event.target.files[0].size;

                                                                            var ext = (event.target.files[0].name).split('.').pop();

                                                                            if (checkFileExtension(ext, validExt)) {

                                                                                if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                    setUploadDocument(event.target.files[0]);
                                                                                } else {
                                                                                    Swal.fire({
                                                                                        title: 'Error',
                                                                                        icon: 'error',
                                                                                        html: MEETING_FILE_ERROR_MESSAGE,
                                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                                    }).then((result) => {

                                                                                    });
                                                                                }
                                                                            }
                                                                            else {
                                                                                Swal.fire({
                                                                                    title: 'Upload Error',
                                                                                    text: 'You can only upload jpg, jpeg & png files',
                                                                                    icon: 'error'
                                                                                }).then(success => {

                                                                                })
                                                                            }
                                                                        } else {
                                                                            $('#error-pdf-doc-not-found').removeClass('d-none')
                                                                        }
                                                                    }}
                                                                />
                                                                <p className="error-msg d-none text-danger" id="error-pdf-doc-not-found">Document is required</p>
                                                                <label class="custom-file-label" for="upload_document">
                                                                    {
                                                                        uploadDocument != null ?
                                                                            uploadDocument.name : "Choose file"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="leave-request"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="leave-request-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/hrms/request');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var LeaveRequestAdd = null;
    var LeaveRequestApprovalManager = null;
    var TotalLeave = null;
    var HolidayList = null;

    if (
        state.LeaveRequestAddReducer != null &&
        Object.keys(state.LeaveRequestAddReducer).length > 0 &&
        state.LeaveRequestAddReducer.constructor === Object
    ) {
        switch (state.LeaveRequestAddReducer.tag) {
            case ACTION_LEAVE_REQUEST_ADD_SUCCESS:
                LeaveRequestAdd = Object.assign({}, state.LeaveRequestAddReducer.data);
                delete state.LeaveRequestAddReducer;
                return { LeaveRequestAdd };
            case ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS:
                LeaveRequestApprovalManager = Object.assign({}, state.LeaveRequestAddReducer.data);
                delete state.LeaveRequestAddReducer;
                return { LeaveRequestApprovalManager };
            case ACTION_TOTAL_LEAVE_SUCCESS:
                TotalLeave = Object.assign({}, state.LeaveRequestAddReducer.data);
                delete state.LeaveRequestAddReducer;
                return { TotalLeave };
            case ACTION_HOLIDAY_LIST_SUCCESS:
                HolidayList = Object.assign({}, state.LeaveRequestAddReducer.data);
                delete state.LeaveRequestAddReducer;
                return { HolidayList };
            default:
        }
    }

    return {
        LeaveRequestAdd,
        LeaveRequestApprovalManager,
        TotalLeave,
        HolidayList
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionLeaveRequestAdd, actionLeaveRequestApprovalManager, actionTotalLeave, actionHolidayList }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RequestAdd))
