import createReducer from "../../../../../reducers/createReducer";
import { ACTION_DOCUMENT_ADD_SUCCESS } from './action';
import { ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS } from './action';
import { ACTION_MASTER_DOCUMENT_TYPE_SUCCESS } from './action';
import { ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS } from './action';

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddDocumentReducer = createReducer(INITIAL_STATE, {
    [ACTION_DOCUMENT_ADD_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_DOCUMENT_ADD_SUCCESS,
            loading: false
        });
    },
    [ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS,
            loading: false
        });
    },
    [ACTION_MASTER_DOCUMENT_TYPE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_MASTER_DOCUMENT_TYPE_SUCCESS,
            loading: false
        });
    },
    [ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS,
            loading: false
        });
    },
});
export default AddDocumentReducer;
