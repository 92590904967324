import {
    ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS
    } from './action';
    import createReducer from '../../../../../../../reducers/createReducer';
    
    const INITIAL_STATE = {
            data: [],
            loading: false
        };
    
    const ClientTapProfileDetailsReducer = createReducer(
        INITIAL_STATE,
        {
            [ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS](state, action) {
                            return Object.assign({}, {
                                ...state,
                                data: action.payload.data,
                                tag: ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS,
                                loading: false
                            });
                        }
        }
    );
    export default ClientTapProfileDetailsReducer;