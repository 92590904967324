import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser } from 'react-icons/fi';
import { DateBox } from 'devextreme-react/date-box';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { ERROR, UNAUTHORIZED, characterLimit } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { actionInternalMeetingsEdit, ACTION_INTERNAL_MEETING_EDIT_SUCCESS } from './action';
import { actionAccompainedBy, ACTION_ACCOMPAINED_BY_SUCCESS } from './action';
import { actionInternalMeetingsView, ACTION_INTERNAL_MEETING_VIEW_SUCCESS } from './action';
import Select from 'react-select';
import { getAccompainedByIds } from '../../../../../../utils';
import $ from "jquery";
import moment from 'moment';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { toast } from 'react-toastify';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import Swal from 'sweetalert2';

const InternalMeetingEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            status: 'open'
        }
    })

    var meetingAgendaWatcher = watch('agenda', '')
    var meetingLocationWatcher = watch('location', '')
    var meetingDescriptionWatcher = watch('description', '')

    const [internalMeetingsDetails, setInternalMeetingsDetails] = useState(null);
    const [accompainedBy, setAccompainedBy] = useState();
    const [status, setStatus] = useState('open');
    const [existingDate, setExistingDate] = useState(false);

    const initialRender = (data) => {
        props.actionInternalMeetingsView({ id: props.location.state.internal_meeting_id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (internalMeetingsDetails != null) {
            props.actionAccompainedBy();
        }
    }, [internalMeetingsDetails]);

    const onInternalMeetingsEditUpdate = () => {
        const { InternalMeetingsEdit } = props;
        var InternalMeetingsEditResponse = Object.assign({}, InternalMeetingsEdit);
        $("#edit-internal").prop("disabled", false);
        if (InternalMeetingsEditResponse.result === true) {
            $("#internal-edit-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Internal Meeting Updated Successfully");
            props.history.goBack();
        } else {
            $("#internal-edit-form-loader").addClass("hidden");
            switch (InternalMeetingsEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(InternalMeetingsEditResponse.message);
                default:
            }
        }
    }
    useEffect(onInternalMeetingsEditUpdate, [props.InternalMeetingsEdit]);

    const onInternalMeetingsViewUpdate = () => {
        const { InternalMeetingsView } = props;
        var InternalMeetingsViewResponse = Object.assign({}, InternalMeetingsView);
        if (InternalMeetingsViewResponse.result === true) {

            setInternalMeetingsDetails(InternalMeetingsViewResponse.response);

            setValue('meeting_date', dateFormat(new Date(InternalMeetingsViewResponse.response.start_date_time), "yyyy-mm-dd"));
            if (moment(InternalMeetingsViewResponse.response.start_date_time).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                setExistingDate(true);
            } else {
                setExistingDate(false);
            }
            setValue('from_time', dateFormat(InternalMeetingsViewResponse.response.start_date_time, 'HH:MM'));
            setValue('to_time', dateFormat(InternalMeetingsViewResponse.response.end_date_time, 'HH:MM'));
            setValue('agenda', InternalMeetingsViewResponse.response.agenda);
            setValue('location', (InternalMeetingsViewResponse.response.location === null) ? '' : InternalMeetingsViewResponse.response.location);
            setValue('description', (InternalMeetingsViewResponse.response.description === null) ? '' : InternalMeetingsViewResponse.response.description);
            setValue('created_by', InternalMeetingsViewResponse.response.created_by);

        } else {
            switch (InternalMeetingsViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onInternalMeetingsViewUpdate, [props.InternalMeetingsView]);

    useEffect(() => {
        if (accompainedBy != null && accompainedBy.length > 0) {
            if (internalMeetingsDetails != null && internalMeetingsDetails.accompany_by != null) {
                setValue('accompained_by', internalMeetingsDetails.accompany_by);
            }
        }
    }, [accompainedBy])

    const onAccompainedByUpdate = () => {

        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);



    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (fromTimeDate >= toTimeDate) {
            Swal.fire({
                title: 'Error',
                text: 'From Time cannot be greater than or equal to To Time',
                icon: 'error'
            }).then(success => {

            })
        } else {
            data.id = internalMeetingsDetails.id;
            data.start_date_time = data.meeting_date + ' ' + data.from_time;
            data.end_date_time = data.meeting_date + ' ' + data.to_time;
            data.accompained_by = getAccompainedByIds(data.accompained_by);

            delete data.from_time;
            delete data.to_time;
            delete data.meeting_date;

            $("#internal-edit-form-loader").removeClass("hidden");
            $("#edit-internal").prop("disabled", true);
            props.actionInternalMeetingsEdit(data);
        }
    }
    return (
        <>
            <ScreenTitle title="Edit Internal Meeting" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Internal Meeting Information
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">Meeting Date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="meeting_date"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={true}
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                            if (moment(value).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                                                                setExistingDate(true);
                                                            } else {
                                                                setExistingDate(false);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Meeting Date is required'
                                                }}
                                            />
                                            {errors.meeting_date && <ErrorLabel message={errors.meeting_date?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">From Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                id="from_time"
                                                name="from_time"
                                                placeholder="--:--"
                                                {...register('from_time', { required: 'From Time is required' })}
                                            />
                                            {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">To Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                id="to_time"
                                                name="to_time"
                                                placeholder="--:--"
                                                {...register('to_time', { required: 'To Time is required' })}
                                            />
                                            {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="agenda"
                                                name="agenda"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Agenda"
                                                {...register('agenda', { required: 'Meeting Agenda is required' })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                <div className="flex-column"><small>{meetingAgendaWatcher != null ? meetingAgendaWatcher.length : 0}/{characterLimit}</small></div>
                                            </div>
                                            {errors.agenda && <ErrorLabel message={errors.agenda?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Location&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="location"
                                                name="location"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Location"
                                                {...register('location', { required: 'Meeting Location is required' })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                <div className="flex-column"><small>{meetingLocationWatcher != null ? meetingLocationWatcher.length : 0}/{characterLimit}</small></div>
                                            </div>
                                            {errors.location && <ErrorLabel message={errors.location?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label-control">Accompanied By</label>
                                            <Controller
                                                control={control}
                                                name="accompained_by"
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            placeholder="Select"
                                                            menuPlacement={"top"}
                                                            isMulti={true}
                                                            hideSelectedOptions={false}
                                                            maxMenuHeight={200}
                                                            options={accompainedBy}
                                                            value={field.value}
                                                            onChange={(selectedOptions) => {
                                                                if (selectedOptions != null) {
                                                                    field.onChange(selectedOptions);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }}
                                                rules={{
                                                    required: 'Please Select Accompanied By'
                                                }}
                                            />
                                            {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Minutes of Meetings&nbsp;<span className={`danger ${status === 'close' ? '' : 'hidden'}`} >*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Minutes of Meetings"
                                                {...register('description', { required: status === 'close' ? 'Minutes of Meetings is required' : '' })}
                                            />
                                            {errors.description && <ErrorLabel message={errors.description?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Status</label>
                                            <Controller
                                                name="status"
                                                control={control}

                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={
                                                            existingDate != true
                                                                ? [
                                                                    {
                                                                        name: 'Update',
                                                                        id: 'open'
                                                                    },
                                                                    {
                                                                        name: 'Cancel',
                                                                        id: 'cancel'
                                                                    }
                                                                ]
                                                                : [
                                                                    {
                                                                        name: 'Update',
                                                                        id: 'open'
                                                                    },
                                                                    {
                                                                        name: 'Cancel',
                                                                        id: 'cancel'
                                                                    },
                                                                    {
                                                                        name: 'Close',
                                                                        id: 'close'
                                                                    }
                                                                ]
                                                        }
                                                        fields={{ text: 'name', value: 'id' }}
                                                        placeholder="Select status"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                                setStatus(event.itemData.id)
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none">
                                <button
                                    id="edit-internal"
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        Submit
                                        <span id="internal-edit-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                                <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.goBack();
                                    }}
                                > Cancel</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var AccompainedBy = null;
    var InternalMeetingsEdit = null;
    var InternalMeetingsView = null;

    if (
        state.InternalMeetingsEditReducer != null &&
        Object.keys(state.InternalMeetingsEditReducer).length > 0 &&
        state.InternalMeetingsEditReducer.constructor === Object
    ) {
        switch (state.InternalMeetingsEditReducer.tag) {
            case ACTION_INTERNAL_MEETING_EDIT_SUCCESS:
                InternalMeetingsEdit = Object.assign({}, state.InternalMeetingsEditReducer.data);
                delete state.InternalMeetingsEditReducer;
                return { InternalMeetingsEdit };
            case ACTION_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.InternalMeetingsEditReducer.data);
                delete state.InternalMeetingsEditReducer;
                return { AccompainedBy };
            case ACTION_INTERNAL_MEETING_VIEW_SUCCESS:
                InternalMeetingsView = Object.assign({}, state.InternalMeetingsEditReducer.data);
                delete state.InternalMeetingsEditReducer;
                return { InternalMeetingsView };
            default:

        }
    }
    return {
        AccompainedBy,
        InternalMeetingsEdit,
        InternalMeetingsView,
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAccompainedBy, actionInternalMeetingsEdit, actionInternalMeetingsView }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(InternalMeetingEdit))