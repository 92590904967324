import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { withAuth } from 'react-auth-guard';
import { FiEdit, FiEye, FiLock } from 'react-icons/fi';
import ChangePassword from './change_password'
import { actionEmployeeList, ACTION_EMPLOYEE_LIST_SUCCESS } from './action';
import { actionEmployeeListActive, ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS } from './action';
import { actionEmployeeListInactive, ACTION_EMPLOYEE_INACTIVE_LIST_SUCCESS } from './action';
import { actionEmployeeStatus, ACTION_EMPLOYEE_STATUS_SUCCESS } from './action';
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../constants';
import { verifyPermission } from '../../../../../utils';
import Swal from 'sweetalert2';
import $ from "jquery";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import _ from "lodash";
import { Break } from 'devextreme-react/chart';

const EmployeeList = (props) => {

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [changePasswordName, setChangePasswordName] = useState('');
    const [changePasswordUserId, setChangePasswordUserId] = useState(0);
    const [employee, setEmployee] = useState([]);
    const [allEmployeeCount, setAllEmployeeCount] = useState(0);
    const [activeEmployeeCount, setActiveEmployeeCount] = useState(0);
    const [inactiveEmployeeCount, setInactiveEmployeeCount] = useState(0);
    const [employeeType, setEmployeeType] = useState('active');

    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "saiba_rm_id", caption: "Saiba ID", visible: false },
        { dataField: "pinc_employee_id", caption: "Employee Id", visible: true },
        { dataField: "full_name", caption: "Name", visible: true },
        { dataField: "user_functions", caption: "Function", visible: true },
        { dataField: "user_departments", caption: "Department", visible: false },
        { dataField: "name", caption: "Designation", visible: true },
        { dataField: "working_days", caption: "Working Days", visible: true },
        { dataField: "email", caption: "Email Id", visible: true },
        { dataField: "status", caption: "Status", visible: true },
        { dataField: "date_of_birth", caption: "Birthday", visible: false },
        { dataField: "city", caption: "City", visible: false },
        { dataField: "gender", caption: "Gender", visible: false },
        { dataField: "grade_name", caption: "Grade", visible: false },
        { dataField: "joining_date", caption: "Joining Date", visible: false },
        { dataField: "payroll_entity", caption: "Payroll Entity", visible: false },
        { dataField: "mobile_no", caption: "Mobile No", visible: false },
        { dataField: "reporting_person_name", caption: "Reporting Person Name", visible: false },
        { dataField: "client_facing_designation", caption: "Client Facing Designation", visible: false },
        { dataField: "role_name", caption: "Permission Role", visible: false },
        { dataField: "total_leaves", caption: "Total Leaves", visible: false },
        { dataField: "leave_kitty", caption: "Leave Kitty", visible: false },
        { dataField: "shift_name", caption: "Shift Name", visible: false },
        { dataField: "probation_duration", caption: "Probation Applicable", visible: false },
        { dataField: "user_companies", caption: "Entity", visible: false },
        { dataField: "reimursment_primary_entity", caption: "Reimbursement Primary Entity", visible: false },
        { dataField: "approval_manager_name", caption: "Approval Manager Name", visible: false },
        { dataField: "asset_manager_name", caption: "Asset Manager Name", visible: false },
        { dataField: "finance_manager_name", caption: "Finance Manager Name", visible: false },
        { dataField: "travel_manager_name", caption: "Travel Manager Name", visible: false },
        { dataField: "attendance_manager_name", caption: "Attendance Manager Name", visible: false },
        { dataField: "meeting_manager_name", caption: "Meeting Manager Name", visible: false },
        { dataField: "performance_manager_name", caption: "Performance Manager Name", visible: false },
        { dataField: "reimburstment_approval_manager_name", caption: "Reimburstment Approval Manager Name", visible: false },
        { dataField: "finance_reimburstment_approval_manager_name", caption: "Finance Reimburstment Approval Manager Name", visible: false },
        { dataField: "product_specialist_departments", caption: "Product", visible: false },
        { dataField: "is_product_specialist", caption: "User is product Specialist?", visible: false },
        { dataField: "region", caption: "Region", visible: false },
        { dataField: "segment", caption: "Segment", visible: false },
        { dataField: "vertical", caption: "vertical", visible: false },
        { dataField: "bqp_certified", caption: "BQP Certified", visible: false },
        { dataField: "certificate_number", caption: "Certificate Number", visible: false },
        { dataField: "bqp_start_date", caption: "Start Date", visible: false },
        { dataField: "bqp_end_date", caption: "End Date", visible: false },
        { dataField: "brokering_entity", caption: "Brokering Entity", visible: false },
        { dataField: "noc_type", caption: "NOC", visible: false },
        { dataField: "employee_bqp_document", caption: "BQP Document", visible: false },
        { dataField: "employee_noc_document", caption: "NOC Document", visible: false },
        { dataField: "bqp_status", caption: "BQP Status", visible: false }
    ];

    const initialRender = () => {

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
        // props.actionEmployeeList();
        props.actionEmployeeListActive();
    }
    useEffect(initialRender, []);

    const onEmployeeListUpdate = () => {
        const { EmployeeList } = props;
        var EmployeeResponse = Object.assign({}, EmployeeList);
        if (EmployeeResponse.result === true) {
            setAllEmployeeCount(EmployeeResponse.response.length);
            setActiveEmployeeCount(0);
            setInactiveEmployeeCount(0)
            setEmployee(EmployeeResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            switch (EmployeeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onEmployeeListUpdate, [props.EmployeeList])

    const onEmployeeActiveListUpdate = () => {
        const { EmployeeActiveList } = props;
        var EmployeeResponse = Object.assign({}, EmployeeActiveList);
        if (EmployeeResponse.result === true) {
            setActiveEmployeeCount(EmployeeResponse.response.length);
            setInactiveEmployeeCount(0);
            setAllEmployeeCount(0)
            setEmployee(EmployeeResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            switch (EmployeeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onEmployeeActiveListUpdate, [props.EmployeeActiveList])

    const onEmployeeInactiveListUpdate = () => {
        const { EmployeeInactiveList } = props;
        var EmployeeResponse = Object.assign({}, EmployeeInactiveList);
        if (EmployeeResponse.result === true) {
            setInactiveEmployeeCount(EmployeeResponse.response.length);
            setActiveEmployeeCount(0);
            setAllEmployeeCount(0)
            setEmployee(EmployeeResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            switch (EmployeeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onEmployeeInactiveListUpdate, [props.EmployeeInactiveList])

    const onEmployeeStatusUpdate = () => {
        const { EmployeeStatus } = props;
        var EmployeeStatusResponse = Object.assign({}, EmployeeStatus);
        if (EmployeeStatusResponse.result === true) {
            // props.actionEmployeeList();
            props.actionEmployeeListActive();
            setEmployeeType("active")
            $("#progress-loader").addClass("hidden");
        } else {
            switch (EmployeeStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    $("#progress-loader").addClass("hidden");
                    Swal.fire({
                        title: `Info`,
                        text: EmployeeStatusResponse.message,
                        confirmButtonText: 'Ok',
                        confirmButtonColor: PRIMARY_COLOR,
                    }).then(success => {
                    });
                    break;
                default:
            }
        }
    }
    useEffect(onEmployeeStatusUpdate, [props.EmployeeStatus])

    const renderFunction = (cell) => {
        return <div>{_.truncate(cell.data.user_functions)}</div>
    }

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'Active':
                return <div className={"badge badge-success round px-1 cursor-pointer"} onClick={() => {
                    Swal.fire({
                        title: `Inactivate User`,
                        text: `Are you sure, you want to inactivate ${cell.data.full_name} ?`,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: PRIMARY_COLOR,
                    }).then(success => {
                        if (success.isConfirmed) {
                            $("#progress-loader").removeClass("hidden");
                            props.actionEmployeeStatus({
                                id: cell.data.user_id,
                                status: false
                            })
                        }
                    });
                }}>Active</div>;
            case 'Inactive':
                return <div className={"badge badge-danger round px-1 cursor-pointer"} onClick={() => {
                    Swal.fire({
                        title: `Activate User`,
                        text: `Are you sure, you want to activate ${cell.data.full_name} ?`,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: PRIMARY_COLOR,
                    }).then(success => {
                        if (success.isConfirmed) {
                            props.actionEmployeeStatus({
                                id: cell.data.user_id,
                                status: true
                            })
                        }
                    });
                }}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">

                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: '/hrms/employee/view',
                                            state: {
                                                pinc_employee_id: params.data.pinc_employee_id,
                                                full_name: params.data.full_name,
                                                designation: params.data.name,
                                            }
                                        });
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />

                        {
                            verifyPermission("UPDATE_EMPLOYEE") === false ?

                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/hrms/employee/edit',
                                                    state: {
                                                        pinc_employee_id: params.data.pinc_employee_id
                                                    }
                                                });
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                /> : ''}
                        {
                            verifyPermission("CHANGE_PASSWORD") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                setShowChangePassword(true);
                                                setChangePasswordName(params.data.full_name);
                                                setChangePasswordUserId(params.data.user_id);
                                            }}
                                        >
                                            <FiLock size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : ''
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "status") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStatusCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "user_functions") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderFunction}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">
                        Employee List {employee.length === 0 ? '' : `(${employee.length})`}
                    </h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="content-header-left col-12 col-md-12 col-lg-12 d-flex align-items-center">
                            <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                                <li class="nav-item">
                                    <button
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setEmployeeType("active");
                                            props.actionEmployeeListActive();
                                        }}
                                        className={`btn ${employeeType === 'active' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 round `}
                                    >
                                        Active
                                        <span className="ml-half">{activeEmployeeCount > 0 ? `(` + activeEmployeeCount + `)` : ``}</span>
                                    </button>
                                </li>
                                <li class="nav-item ml-1">
                                    <button
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setEmployeeType("inactive");
                                            props.actionEmployeeListInactive();
                                        }}
                                        className={`btn ${employeeType === 'inactive' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 round`}
                                    >
                                        Inactive
                                        <span className="ml-half">{inactiveEmployeeCount > 0 ? `(` + inactiveEmployeeCount + `)` : ``}</span>
                                    </button>
                                </li>
                                <li class="nav-item ml-1">
                                    <button
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setEmployeeType("all");
                                            props.actionEmployeeList();
                                        }}
                                        className={`btn ${employeeType === 'all' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 round`}
                                    >
                                        All
                                        <span className="ml-half">{allEmployeeCount > 0 ? `(` + allEmployeeCount + `)` : ``}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={employee}
                                showBorders={true}
                                columnAutoWidth={true}
                                export={{
                                    fileName: "Employee List",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("date_of_birth", "visible", true)
                                    event.component.columnOption("city", "visible", true)
                                    event.component.columnOption("gender", "visible", true)
                                    event.component.columnOption("grade_name", "visible", true)
                                    event.component.columnOption("joining_date", "visible", true)
                                    event.component.columnOption("payroll_entity", "visible", true)
                                    event.component.columnOption("mobile_no", "visible", true)
                                    event.component.columnOption("reporting_person_name", "visible", true)
                                    event.component.columnOption("client_facing_designation", "visible", true)
                                    event.component.columnOption("role_name", "visible", true)
                                    event.component.columnOption("working_days", "visible", true)
                                    event.component.columnOption("total_leaves", "visible", true)
                                    event.component.columnOption("leave_kitty", "visible", true)
                                    event.component.columnOption("shift_name", "visible", true)
                                    event.component.columnOption("approval_manager_name", "visible", true)
                                    event.component.columnOption("asset_manager_name", "visible", true)
                                    event.component.columnOption("finance_manager_name", "visible", true)
                                    event.component.columnOption("travel_manager_name", "visible", true)
                                    event.component.columnOption("attendance_manager_name", "visible", true)
                                    event.component.columnOption("meeting_manager_name", "visible", true)
                                    event.component.columnOption("performance_manager_name", "visible", true)
                                    event.component.columnOption("reimburstment_approval_manager_name", "visible", true)
                                    event.component.columnOption("finance_reimburstment_approval_manager_name", "visible", true)
                                    event.component.columnOption("vertical", "visible", true)
                                    event.component.columnOption("segment", "visible", true)
                                    event.component.columnOption("region", "visible", true)
                                    event.component.columnOption("product_specialist_departments", "visible", true)
                                    event.component.columnOption("is_product_specialist", "visible", true)
                                    event.component.columnOption("user_departments", "visible", true)
                                    event.component.columnOption("saiba_rm_id", "visible", true)
                                    event.component.columnOption("probation_duration", "visible", true)
                                    event.component.columnOption("user_companies", "visible", true)
                                    event.component.columnOption("reimursment_primary_entity", "visible", true)
                                    event.component.columnOption("bqp_certified", "visible", true)
                                    event.component.columnOption("certificate_number", "visible", true)
                                    event.component.columnOption("bqp_start_date", "visible", true)
                                    event.component.columnOption("bqp_end_date", "visible", true)
                                    event.component.columnOption("brokering_entity", "visible", true)
                                    event.component.columnOption("noc_type", "visible", true)
                                    event.component.columnOption("employee_bqp_document", "visible", true)
                                    event.component.columnOption("employee_noc_document", "visible", true)
                                    event.component.columnOption("bqp_status", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("date_of_birth", "visible", false)
                                    event.component.columnOption("city", "visible", false)
                                    event.component.columnOption("gender", "visible", false)
                                    event.component.columnOption("grade_name", "visible", false)
                                    event.component.columnOption("joining_date", "visible", false)
                                    event.component.columnOption("payroll_entity", "visible", false)
                                    event.component.columnOption("mobile_no", "visible", false)
                                    event.component.columnOption("reporting_person_name", "visible", false)
                                    event.component.columnOption("client_facing_designation", "visible", false)
                                    event.component.columnOption("role_name", "visible", false)
                                    event.component.columnOption("total_leaves", "visible", false)
                                    event.component.columnOption("leave_kitty", "visible", false)
                                    event.component.columnOption("shift_name", "visible", false)
                                    event.component.columnOption("approval_manager_name", "visible", false)
                                    event.component.columnOption("asset_manager_name", "visible", false)
                                    event.component.columnOption("finance_manager_name", "visible", false)
                                    event.component.columnOption("travel_manager_name", "visible", false)
                                    event.component.columnOption("attendance_manager_name", "visible", false)
                                    event.component.columnOption("meeting_manager_name", "visible", false)
                                    event.component.columnOption("performance_manager_name", "visible", false)
                                    event.component.columnOption("reimburstment_approval_manager_name", "visible", false)
                                    event.component.columnOption("finance_reimburstment_approval_manager_name", "visible", false)
                                    event.component.columnOption("vertical", "visible", false)
                                    event.component.columnOption("segment", "visible", false)
                                    event.component.columnOption("region", "visible", false)
                                    event.component.columnOption("product_specialist_departments", "visible", false)
                                    event.component.columnOption("is_product_specialist", "visible", false)
                                    event.component.columnOption("user_departments", "visible", false)
                                    event.component.columnOption("saiba_rm_id", "visible", false)
                                    event.component.columnOption("probation_duration", "visible", false)
                                    event.component.columnOption("user_companies", "visible", false)
                                    event.component.columnOption("reimursment_primary_entity", "visible", false)
                                    event.component.columnOption("bqp_certified", "visible", false)
                                    event.component.columnOption("certificate_number", "visible", false)
                                    event.component.columnOption("bqp_start_date", "visible", false)
                                    event.component.columnOption("bqp_end_date", "visible", false)
                                    event.component.columnOption("brokering_entity", "visible", false)
                                    event.component.columnOption("noc_type", "visible", false)
                                    event.component.columnOption("employee_bqp_document", "visible", false)
                                    event.component.columnOption("employee_noc_document", "visible", false)
                                    event.component.columnOption("bqp_status", "visible", false)
                                    event.component.endUpdate();
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[5, 10, 20, 50]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
            <ChangePassword
                show={showChangePassword}
                name={changePasswordName}
                user_id={changePasswordUserId}
                handleClose={() => {
                    setShowChangePassword(false)
                }}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    var EmployeeList = null;
    var EmployeeStatus = null;
    var EmployeeInactiveList = null;
    var EmployeeActiveList = null;

    if (
        state.EmployeeListReducer != null &&
        Object.keys(state.EmployeeListReducer).length > 0 &&
        state.EmployeeListReducer.constructor === Object
    ) {
        switch (state.EmployeeListReducer.tag) {
            case ACTION_EMPLOYEE_LIST_SUCCESS:
                EmployeeList = Object.assign({}, state.EmployeeListReducer.data);
                delete state.EmployeeListReducer;
                return { EmployeeList };
            case ACTION_EMPLOYEE_STATUS_SUCCESS:
                EmployeeStatus = Object.assign({}, state.EmployeeListReducer.data);
                delete state.EmployeeListReducer;
                return { EmployeeStatus };
            case ACTION_EMPLOYEE_INACTIVE_LIST_SUCCESS:
                EmployeeInactiveList = Object.assign({}, state.EmployeeListReducer.data);
                delete state.EmployeeListReducer;
                return { EmployeeInactiveList };
            case ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS:
                EmployeeActiveList = Object.assign({}, state.EmployeeListReducer.data);
                delete state.EmployeeListReducer;
                return { EmployeeActiveList };
            default:
        }
    }

    return {
        EmployeeList,
        EmployeeStatus,
        EmployeeInactiveList,
        EmployeeActiveList
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionEmployeeList, actionEmployeeStatus, actionEmployeeListActive, actionEmployeeListInactive }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EmployeeList));