import React, { useEffect } from 'react';
import $ from "jquery";

const ClaimRegisterLossSite = (props) => {

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    return (
        <>
            <div
                className="modal fade show "
                id="default"
                role="dialog"
                aria-labelledby="visiting-card"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="myModalLabel1">
                                    Loss Site Visit
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row ">
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Pinc Claim Ref No</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.pinc_claim_ref_no != null ? props.clientPolicyDetails.pinc_claim_ref_no : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Employee Name</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.employee_name != null ? props.clientPolicyDetails.employee_name : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Name of Insurer</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.insurer_name != null ? props.clientPolicyDetails.insurer_name : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Type</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.policy_type != null ? props.clientPolicyDetails.policy_type : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Number</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.policy_no != null ? props.clientPolicyDetails.policy_no : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Loss Description</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.loss_description != null ? props.clientPolicyDetails.loss_description : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Estimated Loss Amount</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.estimated_loss_amount != null ? props.clientPolicyDetails.estimated_loss_amount : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Loss Site Contact Person Name</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.loss_site_contact_person_name != null ? props.clientPolicyDetails.loss_site_contact_person_name : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Loss Site Contact Person Mobile Number</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.loss_site_contact_person_mobile != null ? props.clientPolicyDetails.loss_site_contact_person_mobile : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Loss Site Contact Person Email ID</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.loss_site_contact_person_email != null ? props.clientPolicyDetails.loss_site_contact_person_email : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Alternate Contact Person Name</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.alternate_contact_person_name != null ? props.clientPolicyDetails.alternate_contact_person_name : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Alternate Contact Person Mobile Number</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.alternate_contact_person_mobile != null ? props.clientPolicyDetails.alternate_contact_person_mobile : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Alternate Contact Person Email ID</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.alternate_contact_person_email != null ? props.clientPolicyDetails.alternate_contact_person_email : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Date of Loss</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.date_of_loss != null ? props.clientPolicyDetails.date_of_loss : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Survey Location</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.survey_location != null ? props.clientPolicyDetails.survey_location : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Cause Of Loss</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.cause_of_loss != null ? props.clientPolicyDetails.cause_of_loss : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Surveyor Contact Details</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.surveyor_contact_details != null ? props.clientPolicyDetails.surveyor_contact_details : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Date Of Loss Site Visit</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.date_of_loss_site_visit != null ? props.clientPolicyDetails.date_of_loss_site_visit : '-'}
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Remarks</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {props.clientPolicyDetails != null && props.clientPolicyDetails.remarks != null ? props.clientPolicyDetails.remarks : '-'}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ClaimRegisterLossSite

