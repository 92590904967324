import {
    ACTION_UPDATE_MASTER_TRAINING_SUCCESS,
    ACTION_UPDATE_MASTER_BROKER_ENTITY_SUCCESS,
    ACTION_UPDATE_MASTER_BROKER_SUB_ENTITY_SUCCESS,
    ACTION_UPDATE_MASTER_BROKER_DEPARTMENT_SUCCESS,
    ACTION_UPDATE_MASTER_TRAINING_BRANCH_SUCCESS,
    ACTION_BROKER_TRAINING_DETAIL_SUCCESS,
    ACTION_BROKER_TRAINING_EDIT_SUCCESS,
    ACTION_EDIT_BRANCH_CITY_SUCCESS,
    ACTION_EDIT_EMPLOYEE_NAMES_SUCCESS,
    ACTION_EDIT_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS
} from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const BrokerTrainingEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_UPDATE_MASTER_TRAINING_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_UPDATE_MASTER_TRAINING_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_UPDATE_MASTER_BROKER_ENTITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_UPDATE_MASTER_BROKER_ENTITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_UPDATE_MASTER_BROKER_SUB_ENTITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_UPDATE_MASTER_BROKER_SUB_ENTITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_UPDATE_MASTER_BROKER_DEPARTMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_UPDATE_MASTER_BROKER_DEPARTMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_UPDATE_MASTER_TRAINING_BRANCH_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_UPDATE_MASTER_TRAINING_BRANCH_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_BROKER_TRAINING_DETAIL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_BROKER_TRAINING_DETAIL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_BROKER_TRAINING_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_BROKER_TRAINING_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_BRANCH_CITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_BRANCH_CITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_EMPLOYEE_NAMES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_EMPLOYEE_NAMES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS,
                loading: false,
            }
        );
    }
});

export default BrokerTrainingEditReducer;