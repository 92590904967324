import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import ParticipationTraining from "../edit";
import { actionBrokerTrainingList, ACTION_BROKER_TRAINING_LIST_SUCCESS, actionTrainingEmployeeParticipateAdd, ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_SUCCESS } from '../../../../../../routes/app/auth/permission/hrms_setting/list/action';
import { verifyPermission } from "../../../../../../utils";
import moment from 'moment';

const MeetingTrainingList = (props) => {
    const { match } = props;
    const [brokerTrainingDetail, setBrokerTrainingDetail] = useState([]);
    const [showParticipationTraining, setShowParticipationTraining] = useState(false);
    const [statusName, setStatusName] = useState(null);
    const [trainingId, setTrainingId] = useState(null);

    const initialRender = () => {
        const idValue = new URLSearchParams(props?.history?.location?.search).get('id');
        const type = new URLSearchParams(props?.history?.location?.search).get('type');
        if (props?.location?.state?.type === 'NEW_TRAINING_INCLUDE_BQP_FOR_EMPLOYEE_CERTIFICATE_EXPIRY_60_DAYS' || props?.location?.state?.type === 'NEW_TRAINING_EXCLUDE_BQP' || type === 'email') {
            setShowParticipationTraining(true);
            if (idValue != null && type != null) {
                setTrainingId(idValue);
            } else {
                setTrainingId(props?.location?.state?.id);
            }
            setStatusName('Pending');
            props.actionBrokerTrainingList({ "type": "user" });
        } else {
            $("#progress-loader").removeClass("hidden");
            props.actionBrokerTrainingList({ "type": "user" });
        }
    }
    useEffect(initialRender, []);

    const onBrokerTrainingListUpdate = () => {
        const { BrokerTrainingList } = props
        if (BrokerTrainingList != null) {
            var BrokerTrainingListResponse = Object.assign({}, BrokerTrainingList);
            if (BrokerTrainingListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setBrokerTrainingDetail(BrokerTrainingListResponse.response);
                if (getData(KEY_TOAST_MESSAGE) != null) {
                    toast.success(getData(KEY_TOAST_MESSAGE));
                    removeData(KEY_TOAST_MESSAGE);
                }
            } else {
                $("#progress-loader").addClass("hidden");
                switch (BrokerTrainingListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onBrokerTrainingListUpdate, [props.BrokerTrainingList])

    const onTrainingEmployeeParticipateAddUpdate = () => {
        const { TrainingEmployeeParticipateAdd } = props
        if (TrainingEmployeeParticipateAdd != null) {
            var TrainingEmployeeParticipateAddResponse = Object.assign({}, TrainingEmployeeParticipateAdd);
            if (TrainingEmployeeParticipateAddResponse.result === true) {
                props.actionBrokerTrainingList({ "type": "user" });
            } else {
                switch (TrainingEmployeeParticipateAddResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onTrainingEmployeeParticipateAddUpdate, [props.TrainingEmployeeParticipateAdd])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "training_type", caption: "Training Type", visible: true },
        { dataField: "training_name", caption: "Training Name", visible: true },
        { dataField: "start_date", caption: "Start Date", visible: false },
        { dataField: "start_date_formated", caption: "Start Date", visible: true },
        { dataField: "end_date", caption: "End Date", visible: false },
        { dataField: "end_date_formated", caption: "End Date", visible: true },
        { dataField: "training_mode", caption: "Training Mode", visible: true },
        { dataField: "participation_status", caption: "Participation Status", visible: true },
    ]);

    const renderStartDate = (cell) => {
        return <div>{cell?.data?.start_date_formated != null ? moment(cell?.data?.start_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderEndDate = (cell) => {
        return <div>{cell?.data?.end_date_formated != null ? moment(cell?.data?.end_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("MEETING_TRAINING_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("MEETING_TRAINING_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${params.data.status === 'Active' ? '' : 'hidden'}`}
                                            onClick={() => {
                                                setShowParticipationTraining(true);
                                                setStatusName(params?.data?.participation_status != null ? params?.data?.participation_status : null);
                                                setTrainingId(params?.data?.id != null ? params?.data?.id : null);
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            }
            else if (objColumn.dataField === "start_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStartDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "end_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEndDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Hall of IVY </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={brokerTrainingDetail}
                                    showBorders={true}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ParticipationTraining
                show={showParticipationTraining}
                statusName={statusName}
                handleClose={(newDetail) => {
                    if (newDetail != null && newDetail?.status != null) {
                        props.actionTrainingEmployeeParticipateAdd({
                            training_id: trainingId,
                            status: newDetail?.status
                        });
                        toast.success("Participant Add or Update Successfully");
                    }
                    setShowParticipationTraining(false)
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    var BrokerTrainingList = null;
    var TrainingEmployeeParticipateAdd = null;

    if (
        state.BrokerTrainingListReducer != null &&
        Object.keys(state.BrokerTrainingListReducer).length > 0 &&
        state.BrokerTrainingListReducer.constructor === Object
    ) {
        switch (state.BrokerTrainingListReducer.tag) {
            case ACTION_BROKER_TRAINING_LIST_SUCCESS:
                BrokerTrainingList = Object.assign({}, state.BrokerTrainingListReducer.data);
                delete state.BrokerTrainingListReducer;
                return { BrokerTrainingList };
            case ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_SUCCESS:
                TrainingEmployeeParticipateAdd = Object.assign({}, state.BrokerTrainingListReducer.data);
                delete state.BrokerTrainingListReducer;
                return { TrainingEmployeeParticipateAdd };
            default:

        }
    }
    return {
        BrokerTrainingList,
        TrainingEmployeeParticipateAdd
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionBrokerTrainingList, actionTrainingEmployeeParticipateAdd }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MeetingTrainingList))