import { ACTION_NEW_PASSWORD_SUCCESS } from './action';
import createReducer from '../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const NewPasswordReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_NEW_PASSWORD_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_NEW_PASSWORD_SUCCESS,
                loading: false
            });
        },
    }
);
export default NewPasswordReducer;