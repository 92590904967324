import { API_CREATE_CORPORATE_CLIENT, API_GET_OPPORTUNITY_QUESTION, API_MASTER_COMPANY_GROUP } from '../../../../../../../api/constants';
import { API_MASTER_CUSTOMER_CATEGORY } from '../../../../../../../api/constants';
import { API_MASTER_INDUSTRIAL_SEGMENT } from '../../../../../../../api/constants';
import { API_MASTER_FORM_OF_ORGANIZATION } from '../../../../../../../api/constants';
import { API_MASTER_LEAD_SOURCE } from '../../../../../../../api/constants';
import { API_MASTER_ASSIGN_RM } from '../../../../../../../api/constants';
import { API_MASTER_ASSIGN_RETENTION } from '../../../../../../../api/constants';
import { API_MASTER_STATES } from '../../../../../../../api/constants';
import { API_MASTER_CITIES } from '../../../../../../../api/constants';
import { API_GET_SEARCH_LIST } from '../../../../../../../api/constants';
import { API_GROUP_COMPANIES } from '../../../../../../../api/constants';
import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';

export const ACTION_MASTER_COMPANY_GROUP_LOADING = 'ACTION_MASTER_COMPANY_GROUP_LOADING';
export const ACTION_MASTER_COMPANY_GROUP_SUCCESS = 'ACTION_MASTER_COMPANY_GROUP_SUCCESS';

export const ACTION_COMPANY_LOADING = 'ACTION_COMPANY_LOADING';
export const ACTION_COMPANY_SUCCESS = 'ACTION_COMPANY_SUCCESS';

export const ACTION_MASTER_CUSTOMER_CATEGORY_LOADING = 'ACTION_MASTER_CUSTOMER_CATEGORY_LOADING';
export const ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS = 'ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS';

export const ACTION_MASTER_INDUSTRIAL_SEGMENT_LOADING = 'ACTION_MASTER_INDUSTRIAL_SEGMENT_LOADING';
export const ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS = 'ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS';

export const ACTION_MASTER_FORM_OF_ORGANIZATION_LOADING = 'ACTION_MASTER_FORM_OF_ORGANIZATION_LOADING';
export const ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS = 'ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS';

export const ACTION_MASTER_LEAD_SOURCE_LOADING = 'ACTION_MASTER_LEAD_SOURCE_LOADING';
export const ACTION_MASTER_LEAD_SOURCE_SUCCESS = 'ACTION_MASTER_LEAD_SOURCE_SUCCESS';

export const ACTION_MASTER_ASSIGN_RM_LOADING = 'ACTION_MASTER_ASSIGN_RM_LOADING';
export const ACTION_MASTER_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_ASSIGN_RM_SUCCESS';

export const ACTION_MASTER_ASSIGN_RETENTION_LOADING = 'ACTION_MASTER_ASSIGN_RETENTION_LOADING';
export const ACTION_MASTER_ASSIGN_RETENTION_SUCCESS = 'ACTION_MASTER_ASSIGN_RETENTION_SUCCESS';


export const ACTION_MASTER_LEAD_STATES_LOADING = 'ACTION_MASTER_LEAD_STATES_LOADING';
export const ACTION_MASTER_LEAD_STATES_SUCCESS = 'ACTION_MASTER_LEAD_STATES_SUCCESS';

export const ACTION_MASTER_LEAD_CITIES_LOADING = 'ACTION_MASTER_LEAD_CITIES_LOADING';
export const ACTION_MASTER_LEAD_CITIES_SUCCESS = 'ACTION_MASTER_LEAD_CITIES_SUCCESS';

export const ACTION_CORPORATE_CUSTOMER_SAVE_LOADING = 'ACTION_CORPORATE_CUSTOMER_SAVE_LOADING';
export const ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS = 'ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS';

export const ACTION_GET_CLIENT_SEARCH_LIST_LOADING = 'ACTION_GET_CLIENT_SEARCH_LIST_LOADING';
export const ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS = 'ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS';

export const ACTION_GET_OPPORTUNITY_QUESTION_LOADING = 'ACTION_GET_OPPORTUNITY_QUESTION_LOADING';
export const ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS = 'ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS';

export function actionCompanyGroup() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_COMPANY_GROUP).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_COMPANY_GROUP_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_COMPANY_GROUP_LOADING, { loading: false, data }));
        });
}

export function actionCustomerCategory() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_CUSTOMER_CATEGORY).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CUSTOMER_CATEGORY_LOADING, { loading: false, data }));
        });
}

export function actionIndustrialSegment() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_INDUSTRIAL_SEGMENT).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_INDUSTRIAL_SEGMENT_LOADING, { loading: false, data }));
        });
}

export function actionFormOfOrganization() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_FORM_OF_ORGANIZATION).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_FORM_OF_ORGANIZATION_LOADING, { loading: false, data }));
        });
}

export function actionLeadSource() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_LEAD_SOURCE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_LEAD_SOURCE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_LEAD_SOURCE_LOADING, { loading: false, data }));
        });
}

export function actionAssignRM(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ASSIGN_RM, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}

export function actionStates() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_STATES).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_LEAD_STATES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_LEAD_STATES_LOADING, { loading: false, data }));
        });
}

export function actionCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_LEAD_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_LEAD_CITIES_LOADING, { loading: false, data }));
        });
}

export function actionCorporateCustomerSave(params) {
    return (dispatch, getState) =>
        Api.post(API_CREATE_CORPORATE_CLIENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CORPORATE_CUSTOMER_SAVE_LOADING, { loading: false, data }));
        });
}
export function actionAssignRetention() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_ASSIGN_RETENTION).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RETENTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RETENTION_LOADING, { loading: false, data }));
        });
}

export function actionGetClientSearchList() {
    return (dispatch, getState) =>
        Api.get(API_GET_SEARCH_LIST).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_CLIENT_SEARCH_LIST_LOADING, { loading: false, data }));
        });
}

export function actionGetOpportunityQuestion(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_OPPORTUNITY_QUESTION, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }

            dispatch(loading(ACTION_GET_OPPORTUNITY_QUESTION_LOADING, { loading: false, data }));
        });
}

export function actionGetGroupCompanies(params) {
    return (dispatch, getState) =>
        Api.post(API_GROUP_COMPANIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }

            dispatch(loading(ACTION_COMPANY_LOADING, { loading: false, data }));
        });
}