import React from 'react'
import { withAuth } from 'react-auth-guard'

const ScreenTitle = (props) => {
    return (
        <div className="content-header row mb-1">
            <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                {props.icon != null ? <>{props.icon}&nbsp;</> : <></>}
                <h3 className="content-header-title text-capitalize mb-0">
                    {props.title != null && props.title.length > 0 ? props.title : ''}
                </h3>
            </div>
        </div>
    )
}

export default withAuth(ScreenTitle)