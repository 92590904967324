import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CLAIMS_DASHBOARD, API_CLAIM_DOWNLOAD } from '../../../../../api/constants';

import { PRINT } from '../../../../../utils';

export const ACTION_CLAIMS_DASHBOARD_LOADING = 'ACTION_CLAIMS_DASHBOARD_LOADING';
export const ACTION_CLAIMS_DASHBOARD_SUCCESS = 'ACTION_CLAIMS_DASHBOARD_SUCCESS';

export const ACTION_CLAIM_DOWNLOAD_LOADING = 'ACTION_CLAIM_DOWNLOAD_LOADING';
export const ACTION_CLAIM_DOWNLOAD_SUCCESS = 'ACTION_CLAIM_DOWNLOAD_SUCCESS';

export function actionClaimDashboard(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_DASHBOARD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_DASHBOARD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_DASHBOARD_LOADING, { loading: false, data }));
        });
}

export function actionClaimDownload(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIM_DOWNLOAD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_DOWNLOAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_DOWNLOAD_LOADING, { loading: false, data }));
        });
}
