import { API_ACCOMPAINED_BY, API_MEETING_LIST_CONTACT } from '../../../../../../api/constants';
import { API_MASTER_INSURANCE_COMPANY_NAME } from '../../../../../../api/constants';
import { API_MASTER_STATE_BY_INSURANCE_COMPANY } from '../../../../../../api/constants';
import { API_MASTER_CITY_BY_INSURANCE_COMPANY } from '../../../../../../api/constants';
import { API_MASTER_BRANCH_BY_INSURANCE_COMPANY } from '../../../../../../api/constants';
import { API_INSURANCE_MEETING_EDIT } from '../../../../../../api/constants';
import { API_INSURANCE_MEETING_VIEW } from '../../../../../../api/constants';
import { API_INSURANCE_MEETING_ADD_CONTACT, API_GET_BRANCH_WISE_CONTACT } from '../../../../../../api/constants';

import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_ACCOMPAINED_BY_LOADING = 'ACTION_ACCOMPAINED_BY_LOADING';
export const ACTION_ACCOMPAINED_BY_SUCCESS = 'ACTION_ACCOMPAINED_BY_SUCCESS';

export const ACTION_MASTER_INSURANCE_COMPANY_NAME_LOADING = 'ACTION_MASTER_INSURANCE_COMPANY_NAME_LOADING';
export const ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS = 'ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS';

export const ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_LOADING = 'ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_LOADING';
export const ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS = 'ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS';

export const ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_LOADING = 'ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_LOADING';
export const ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS = 'ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS';

export const ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_LOADING = 'ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_LOADING';
export const ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS = 'ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS';

export const ACTION_INSURANCE_MEETING_EDIT_LOADING = 'ACTION_INSURANCE_MEETING_EDIT_LOADING';
export const ACTION_INSURANCE_MEETING_EDIT_SUCCESS = 'ACTION_INSURANCE_MEETING_EDIT_SUCCESS';

export const ACTION_INSURANCE_MEETING_VIEW_LOADING = 'ACTION_INSURANCE_MEETING_VIEW_LOADING';
export const ACTION_INSURANCE_MEETING_VIEW_SUCCESS = 'ACTION_INSURANCE_MEETING_VIEW_SUCCESS';

export const ACTION_INSURANCE_MEETING_CONTACT_LIST_LOADING = 'ACTION_INSURANCE_MEETING_CONTACT_LIST_LOADING';
export const ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS = 'ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS';

export const ACTION_INSURANCE_MEETING_ADD_CONTACT_LOADING = 'ACTION_INSURANCE_MEETING_ADD_CONTACT_LOADING';
export const ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS = 'ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS';

export const ACTION_EDIT_GET_BRANCH_WISE_CONTACT_LOADING = 'ACTION_EDIT_GET_BRANCH_WISE_CONTACT_LOADING';
export const ACTION_EDIT_GET_BRANCH_WISE_CONTACT_SUCCESS = 'ACTION_EDIT_GET_BRANCH_WISE_CONTACT_SUCCESS';

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionMasterInsuranceCompanyName(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_INSURANCE_COMPANY_NAME).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_INSURANCE_COMPANY_NAME_LOADING, { loading: false, data }));
        });
}

export function actionMasterStateByInsuranceCompany(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_STATE_BY_INSURANCE_COMPANY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionMasterCityByInsuranceCompany(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITY_BY_INSURANCE_COMPANY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionMasterBranchByInsuranceCompany(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_BRANCH_BY_INSURANCE_COMPANY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceMeetingEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_INSURANCE_MEETING_EDIT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_INSURANCE_MEETING_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURANCE_MEETING_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceMeetingsView(params) {
    return (dispatch, getState) =>
        Api.post(API_INSURANCE_MEETING_VIEW, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_INSURANCE_MEETING_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURANCE_MEETING_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionGetCompanyContacts(params) {
    return (dispatch, getState) =>
        Api.post(API_MEETING_LIST_CONTACT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURANCE_MEETING_CONTACT_LIST_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceMeetingAddContact(params) {
    return (dispatch, getState) =>
        Api.post(API_INSURANCE_MEETING_ADD_CONTACT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURANCE_MEETING_ADD_CONTACT_LOADING, { loading: false, data }));
        });
}

export function actionGetBranchWiseContact(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_BRANCH_WISE_CONTACT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_GET_BRANCH_WISE_CONTACT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_GET_BRANCH_WISE_CONTACT_LOADING, { loading: false, data }));
        });
}