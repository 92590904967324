import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withAuth } from "react-auth-guard";
import NotFound from "./not_found";
import Login from "./login";
import forgot_password from "./forgot_password";
import ChangePassword from "./change_password";
import NewPassword from "./new_password"
import _ from "lodash";

const PublicRoute = (props) => {
    const { match, location, history } = props;

    const routes = [
        {
            exact: true,
            path: 'login',
            component: Login
        },
        {
            exact: true,
            path: 'reset-password/:token/:email',
            component: ChangePassword
        },
        {
            exact: false,
            path: '404',
        }
    ]

    if (location.pathname === "/" || String(location.pathname).includes('dashboard')) {
        window.location.replace("/login");
    } 
    else {
        if (
            (
                // location.pathname !== '/' &&
                _.findIndex(routes, ['path', String(location.pathname).substring(1, location.pathname.length)]) === -1
            ) 
            &&
            !String(location.pathname).includes('#logout')&&
            !String(location.pathname).includes('reset-password')
        ) {
            // alert('404')
            window.location.replace("/login");
        }
    }

    return (
        <Router history={history}>
            <>
                {/* <div className="container"> */}
                <Switch>
                    <Route
                        exact
                        path={`${match.url}login`}
                        component={Login}
                        {...props}
                    />
                    <Route
                        exact
                        path={`${match.url}forgot-password`}
                        component={forgot_password}
                        {...props}
                    />
                    <Route
                        exact
                        path={`${match.url}new-password`}
                        component={NewPassword}
                        {...props}
                    />
                    <Route
                        exact
                        path={`${match.url}reset-password/:token/:email`}
                        component={ChangePassword}
                        {...props}
                    />
                    <Route
                        path={`${match.url}`}
                        component={NotFound}
                    />
                </Switch>
                {/* </div> */}
            </>
        </Router>
    );
};
export default withAuth(PublicRoute);
