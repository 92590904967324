import { API_GET_OPPORTUNITY_QUESTION, API_PROPOSALPOOL_VIEW } from '../../../../../api/constants';
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';

export const ACTION_PROPOSALPOOL_VIEW_LOADING = 'ACTION_PROPOSALPOOL_VIEW_LOADING';
export const ACTION_PROPOSALPOOL_VIEW_SUCCESS = 'ACTION_PROPOSALPOOL_VIEW_SUCCESS';

export const ACTION_OPPORTUNITY_POOL_QUESTION_LOADING = 'ACTION_OPPORTUNITY_POOL_QUESTION_LOADING';
export const ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS = 'ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS';

export function actionProposalPoolView(params) {
    return (dispatch, getState) =>
        Api.post(API_PROPOSALPOOL_VIEW, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROPOSALPOOL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROPOSALPOOL_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionOpportunityPoolQuestion(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_OPPORTUNITY_QUESTION, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_POOL_QUESTION_LOADING, { loading: false, data }));
        });
}