import { ACTION_GET_MANAGER_APPROVAL_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const DashboardManagerApprovalReducer = createReducer(INITIAL_STATE, {
    [ACTION_GET_MANAGER_APPROVAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_MANAGER_APPROVAL_SUCCESS,
                loading: false,
            }
        );
    },
});
export default DashboardManagerApprovalReducer;