import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser, FiPhone, FiPlus, FiTrash } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import ReinsuranceNewCustomer from '../../../../../../component/form/reinsurance_new_customer/index'
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { characterLimit, ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, minCharacters, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import Select from 'react-select';
import $ from "jquery";
import ContactDetails from '../../../../../../component/form/contact_details';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { checkFileExtension, getAccompainedByIds } from '../../../../../../utils';
import Swal from 'sweetalert2';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import { actionRiMeetingsEdit, ACTION_RI_MEETING_EDIT_SUCCESS } from './action';
import { actionCountry, ACTION_MASTER_COUNTRY_SUCCESS } from "./action";
import { actionClientType, ACTION_CLIENT_TYPE_SUCCESS } from "./action";
import { actionReinsuranceClient, ACTION_REINSURANCE_CLIENT_SUCCESS } from "./action";
import { actionRIGetContacts, ACTION_RI_GET_CONTACTS_SUCCESS } from "./action";
import { actionGetRiName, ACTION_ADD_GET_RI_NAME_SUCCESS } from "./action";
import { actionRIMeetingAddContact, ACTION_RI_MEETING_ADD_CONTACT_SUCCESS } from "./action";
import { actionRiMeetingsView, ACTION_RI_MEETING_VIEW_SUCCESS } from "./action";
import { actionAccompainedBy, ACTION_RI_ACCOMPAINED_BY_SUCCESS } from "./action";
import { actionPreviousMeeting, ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS } from "./action";
import { actionDeleteDocument, ACTION_DOCUMENT_DELETE_SUCCESS } from "./action";
import { actionRIMeetingSave, ACTION_RI_MEETING_SAVE_SUCCESS } from "./action";
import { toast } from 'react-toastify';
import moment from 'moment';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const RiMeetingEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues, watch } = useForm({
        defaultValues: {
            status: 'open'
        }
    })

    var meetingAgendaWatcher = watch('agenda', '')
    var meetingDescriptionWatcher = watch('description', '')
    var minuteOfMeetingWatcher = watch('minutes_of_meeting', '')
    var remarkWatcher = watch('remarks', '')

    const [showNewClientEdit, setShowNewClientEdit] = useState(false)
    const [accompainedBy, setAccompainedBy] = useState();
    const [status, setStatus] = useState('open')
    const [country, setCountry] = useState(null);
    const [clientType, setClientType] = useState(null);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [reinsuranceClient, setReinsuranceClient] = useState([]);
    const [companyContacts, setCompanyContacts] = useState([]);
    const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([])
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [companyId, setCompanyId] = useState(0)
    const [riNames, setRiNames] = useState([]);
    const [clientContactIds, setClientContactIds] = useState(0)
    const [riMeetingsDetails, setRiMeetingsDetails] = useState(null);
    const [previousMeeting, setPreviousMeeting] = useState();
    const [nextMeeting, setNextMeeting] = useState(false);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [existingDate, setExistingDate] = useState(false);

    const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

    const initialRender = () => {
        if (props.location.state !== undefined) {
            props.actionRiMeetingsView({ id: props.location.state.ri_meeting });
        } else {
            props.history.goBack();
        }
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (riMeetingsDetails != null) {

            props.actionClientType({});
            props.actionCountry({});
            props.actionAccompainedBy({});
            props.actionGetRiName({ clientOf: "reinsurance" });
        }
    }, [riMeetingsDetails])

    const onRiMeetingsViewUpdate = () => {
        const { RiMeetingsView } = props
        if (RiMeetingsView != null) {
            var RiMeetingsViewResponse = Object.assign({}, RiMeetingsView);
            if (RiMeetingsViewResponse.result === true) {
                setRiMeetingsDetails(RiMeetingsViewResponse.response);
                setAccompainedBy(RiMeetingsViewResponse.response.accompany_by);

                setValue('start_date', dateFormat(new Date(RiMeetingsViewResponse.response.start_date_time), "yyyy-mm-dd"));
                if (moment(RiMeetingsViewResponse.response.start_date_time).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                    setExistingDate(true);
                } else {
                    setExistingDate(false);
                }
                setValue('from_time', dateFormat(RiMeetingsViewResponse.response.start_date_time, 'HH:MM:ss'));
                setValue('to_time', dateFormat(RiMeetingsViewResponse.response.end_date_time, 'HH:MM:ss'));
                setValue('agenda', RiMeetingsViewResponse.response.agenda);
                setValue('meeting_type', RiMeetingsViewResponse.response.meeting_type);
                setValue('location', (RiMeetingsViewResponse.response.location === null) ? '' : RiMeetingsViewResponse.response.location);
                setValue('description', (RiMeetingsViewResponse.response.description === null) ? '' : RiMeetingsViewResponse.response.description);
                setValue('created_by', RiMeetingsViewResponse.response.created_by);
                setValue('type_of_business', JSON.parse(RiMeetingsViewResponse.response.type_of_business));
            } else {
                switch (RiMeetingsViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onRiMeetingsViewUpdate, [props.RiMeetingsView])

    useEffect(() => {
        if (riMeetingsDetails != null && riMeetingsDetails.accompany_by != null) {
            if (accompainedBy != null && accompainedBy.length > 0) {
                setValue('accompained_by', riMeetingsDetails.accompany_by);
            }
        }
    }, [accompainedBy])

    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);



    useEffect(() => {
        if (riMeetingsDetails != null) {
            if (country != null && country.length > 0) {
                setValue('country_id', riMeetingsDetails.country_id);
            }
        }
    }, [country])

    const onCountryUpdate = () => {
        const { CountryUpdate } = props;
        if (CountryUpdate != null) {
            var CountryUpdateResponse = Object.assign({}, CountryUpdate);
            if (CountryUpdateResponse.result === true) {
                setCountry(CountryUpdateResponse.response);
            } else {
                switch (CountryUpdateResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onCountryUpdate, [props.CountryUpdate]);

    useEffect(() => {
        if (riMeetingsDetails != null && riMeetingsDetails.client_type != null) {
            if (clientType != null && clientType.length > 0) {
                setValue('client_type', riMeetingsDetails.client_type);
            }
        }
    }, [clientType])

    const onClientTypeUpdate = () => {

        const { ClientType } = props;
        var ClientTypeUpdateResponse = Object.assign({}, ClientType);
        if (ClientTypeUpdateResponse.result === true) {
            setClientType(ClientTypeUpdateResponse.response);

        } else {
            switch (ClientTypeUpdateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    useEffect(() => {
        if (riMeetingsDetails != null && riMeetingsDetails.client_id != null) {
            if (reinsuranceClient != null && reinsuranceClient.length > 0) {
                setValue('client_id', riMeetingsDetails.client_id);
            }
        }
    }, [reinsuranceClient])

    const onReinsuranceClientUpdate = () => {
        const { ReinsuranceClient } = props
        if (ReinsuranceClient != null) {
            var ReinsuranceClientResponse = Object.assign({}, ReinsuranceClient);
            if (ReinsuranceClientResponse.result === true) {
                setReinsuranceClient(ReinsuranceClientResponse.response);
            } else {
                switch (ReinsuranceClientResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onReinsuranceClientUpdate, [props.ReinsuranceClient])

    const onGetRiNameUpdate = () => {
        const { GetRiName } = props;
        var GetRiNameResponse = Object.assign({}, GetRiName);
        if (GetRiNameResponse.result === true) {
            setRiNames(GetRiNameResponse.response);
        } else {
            switch (GetRiNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetRiNameUpdate, [props.GetRiName]);

    const onRIMeetingAddContactUpdate = () => {

        const { RIMeetingAddContact } = props;

        if (RIMeetingAddContact != null) {
            var RIMeetingAddContactResponse = Object.assign({}, RIMeetingAddContact);
            if (RIMeetingAddContactResponse.result === true) {
                var newContact = Object.assign({}, RIMeetingAddContactResponse.response);
                var arrContacts = Object.assign([], companyContacts);
                arrContacts.push(newContact)
                var arrSelectedCompanyContacts = Object.assign([], selectedCompanyContacts);
                arrSelectedCompanyContacts.push(newContact.id)
                setCompanyContacts(arrContacts)
                setSelectedCompanyContacts(arrSelectedCompanyContacts)
            } else {
                switch (RIMeetingAddContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onRIMeetingAddContactUpdate, [props.RIMeetingAddContact]);

    const onRIGetContactsUpdate = () => {
        const { RIGetContacts } = props
        var RIGetContactsResponse = Object.assign({}, RIGetContacts)
        if (RIGetContactsResponse.result === true) {
            setCompanyContacts(RIGetContactsResponse.response);
            setSelectedCompanyContacts([]);
            if (riMeetingsDetails != null) {
                setSelectedCompanyContacts(riMeetingsDetails.contact_ids);
            }
        } else {
            switch (RIGetContactsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onRIGetContactsUpdate, [props.RIGetContacts])

    useEffect(() => {
        if (riMeetingsDetails != null) {
            if (previousMeeting != null && previousMeeting.length > 0) {
                setValue('prev_meeting', riMeetingsDetails.prev_meeting);
            }
        }
    }, [previousMeeting])

    // Call When Select Customer Value Change To Get Previous Meetings **Previous Meeting**
    const onPreviousMeetingUpdate = () => {
        const { PreviousMeeting } = props;
        if (PreviousMeeting != null) {
            var PreviousMeetingResponse = Object.assign({}, PreviousMeeting);
            if (PreviousMeetingResponse.result === true) {
                setPreviousMeeting(PreviousMeetingResponse.response);
            } else {
                setPreviousMeeting([]);
                switch (PreviousMeetingResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onPreviousMeetingUpdate, [props.PreviousMeeting]);

    const onTPADocumentDeleteUpdate = () => {
        const { DocumentDelete } = props
        var DocumentDeleteResponse = Object.assign({}, DocumentDelete)
        if (DocumentDeleteResponse.result === true) {
            $('#view_document').addClass('hidden')
        } else {
            switch (DocumentDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onTPADocumentDeleteUpdate, [props.DocumentDelete])

    const onRIMeetingSaveUpdate = () => {
        const { RIMeetingSave } = props;
        var RIMeetingSaveResponse = Object.assign({}, RIMeetingSave);
        if (RIMeetingSaveResponse.result === true) {
            // setData(KEY_TOAST_MESSAGE, "RI Meeting Updated Successfully");
            toast.success("RI Meeting Updated Successfully");
            props.history.goBack();
        } else {
            switch (RIMeetingSaveResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(RIMeetingSaveResponse.message);
                default:
            }
        }
    }
    useEffect(onRIMeetingSaveUpdate, [props.RIMeetingSave]);

    const onRiMeetingsEditUpdate = () => {

        const { RiMeetingsEdit } = props;
        var RiMeetingsEditResponse = Object.assign({}, RiMeetingsEdit);
        $("#edit-ri").prop("disabled", false);
        if (RiMeetingsEditResponse.result === true) {

            if (nextMeeting === true) {

                var data = {
                    meeting_type: 'reinsurance',
                    type_of_business: riMeetingsDetails.type_of_business,
                    client_id: riMeetingsDetails.client_id,
                    prev_meeting: riMeetingsDetails.id,
                    start_date_time: getValues('next_start_date') + ' ' + getValues('next_from_time'),
                    end_date_time: getValues('next_start_date') + ' ' + getValues('next_to_time'),
                    created_by: props.auth.getUser().id,
                }

                props.actionRIMeetingSave(data);

            } else {
                $("#ri-edit-form-loader").addClass("hidden");
                // setData(KEY_TOAST_MESSAGE, "RI Meeting Updated Successfully");
                toast.success("RI Meeting Updated Successfully");
                props.history.goBack();
            }
        } else {
            $("#ri-edit-form-loader").addClass("hidden");
            switch (RiMeetingsEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(RiMeetingsEditResponse.message);
                default:
            }
        }
    }
    useEffect(onRiMeetingsEditUpdate, [props.RiMeetingsEdit]);

    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (fromTimeDate >= toTimeDate) {
            Swal.fire({
                title: 'Error',
                text: 'From Time cannot be greater than or equal to To Time',
                icon: 'error'
            }).then(success => {

            })
        } else {
            data.id = riMeetingsDetails.id;

            data.created_by = props.auth.getUser().user_id;
            data.start_date_time = data.start_date + ' ' + data.from_time;
            data.end_date_time = data.start_date + ' ' + data.to_time;

            data.accompained_by = JSON.stringify(getAccompainedByIds(data.accompained_by));
            data.contact_ids = JSON.stringify(selectedCompanyContacts);
            data.type_of_business = JSON.stringify(data.type_of_business);
            data.meeting_type = 'reinsurance';

            var files = [];
            if (uploadDocument != null) {
                files.push({ 'name': 'upload_document', 'value': uploadDocument });
            }

            if (data.prev_meeting === null) {
                delete data.prev_meeting;
            }

            if (data.status.length === 0) {
                delete data.status;
            }

            delete data.from_time;
            delete data.to_time;
            delete data.start_date;
            delete data.upload_document;

            $("#ri-edit-form-loader").removeClass("hidden");
            $("#edit-ri").prop("disabled", true);

            props.actionRiMeetingsEdit(data, files);
        }
    }
    return (
        <>
            <ScreenTitle title="Edit RI Meeting" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;RI Meeting Information
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="meeting_type"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                dataSource={
                                                                    [{
                                                                        value: 'existing',
                                                                        title: 'Existing',
                                                                    },
                                                                    {
                                                                        value: 'new',
                                                                        title: 'New',
                                                                    }
                                                                    ]
                                                                }
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Meeting Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Meeting Type is required'
                                                        }}

                                                    />
                                                    {errors.meeting_type && <ErrorLabel message={errors.meeting_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6" id="client-field">
                                                <div className="form-group">
                                                    <label className="label-control ">Client Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_type"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={clientType}
                                                                fields={{ text: "title", value: "value" }}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    field.onChange(event.itemData.value)
                                                                    if (event.itemData != null) {
                                                                        props.actionReinsuranceClient({ client_type: event.itemData.value })
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Type is required'
                                                        }}
                                                    />
                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2 col-lg-2 hidden" id="client-new-button">
                                                <div className="form-group">
                                                    <label></label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-block btn-primary shadow round text-uppercase font-small-2"
                                                        onClick={(event) => {
                                                            setShowNewClientEdit(true);
                                                        }}
                                                    > Add New Client</button>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Client&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={reinsuranceClient}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client"
                                                                value={field.value}
                                                                change={(event) => {

                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        props.actionRIGetContacts({ client_id: event.itemData.id })
                                                                        props.actionPreviousMeeting({ client_id: event.itemData.id });
                                                                        setCompanyId(event.itemData.id);
                                                                    }

                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client is required'
                                                        }}
                                                    />
                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Select Country&nbsp;
                                                    </label>
                                                    <Controller
                                                        name="country_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={country}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Country"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Business Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type_of_business"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: [
                                                                            { id: 'Facultative', name: 'Facultative' },
                                                                            { id: 'Treaty', name: 'Treaty' },
                                                                            { id: 'Retro', name: 'Retro' },
                                                                            { id: 'Others', name: 'Others' }
                                                                        ],
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                placeholder="Select Business Type"
                                                                valueExpr="id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={4}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value);
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Business Type is required'
                                                        }}
                                                    />
                                                    {errors.business_type && <ErrorLabel message={errors.business_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Previous Meeting</label>
                                                    <Controller
                                                        name="prev_meeting"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={previousMeeting}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Previous Meeting"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="start_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                min={moment(new Date()).subtract(60, 'days').calendar()}
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                acceptCustomValue={false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    if (moment(value).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                                                                        setExistingDate(true);
                                                                    } else {
                                                                        setExistingDate(false);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Start Date is required'
                                                        }}
                                                    />
                                                    {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">From Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="from_time"
                                                        name="from_time"
                                                        placeholder="--:-- --"
                                                        {...register('from_time', { required: 'From Time is required' })}
                                                    />
                                                    {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">To Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="to_time"
                                                        name="to_time"
                                                        placeholder="--:-- --"
                                                        {...register('to_time', { required: 'To Time is required' })}
                                                    />
                                                    {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                                </div>
                                            </div>
                                            {
                                                companyId > 0 ?
                                                    [<div className="col-12 col-md-12 col-lg-12" key="contact_details">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={() => {
                                                                    setShowContactDetails(true)
                                                                }}
                                                            >
                                                                <FiPlus
                                                                    cursor="pointer"
                                                                    size={20}
                                                                />
                                                            </button>
                                                        </h4>
                                                    </div>,
                                                    <div className="col-12 col-md-12 col-lg-12 mb-1" key="contact_details_list">
                                                        {
                                                            companyContacts != null && companyContacts.length > 0 ?
                                                                <table className="table table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th className="px-1">Contact Name</th>
                                                                            <th className="px-1">Contact Designation</th>
                                                                            <th className="px-1">Contact Phone</th>
                                                                            <th className="px-1">Contact Email</th>
                                                                            <th className="px-1">Department</th>
                                                                            <th className="px-1">Phone Number</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            companyContacts.map((contact, indexContact) => (
                                                                                <tr key={contact.id} id={contact.id}>
                                                                                    <th>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            id={`${contact.id}`}
                                                                                            defaultChecked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                            checked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                            onChange={(event) => {
                                                                                                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                                                                                                if (!arrSelectedCompanyContact.includes(contact.id)) {
                                                                                                    arrSelectedCompanyContact.push(contact.id)
                                                                                                    setSelectedCompanyContacts(arrSelectedCompanyContact)
                                                                                                } else {
                                                                                                    var contactIndex = arrSelectedCompanyContact.findIndex((objContact, index) => objContact == contact.id);
                                                                                                    if (contactIndex >= 0) {
                                                                                                        arrSelectedCompanyContact.splice(contactIndex, 1);
                                                                                                        setSelectedCompanyContacts(arrSelectedCompanyContact);
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </th>
                                                                                    <td className="px-1">{contact.contact_name}</td>
                                                                                    <td className="px-1">{contact.contact_designation}</td>
                                                                                    <td className="px-1">{contact.contact_phone}</td>
                                                                                    <td className="px-1">{contact.contact_email}</td>
                                                                                    <td className="px-1">{contact.department}</td>
                                                                                    <td className="px-1">{contact.office_number}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <></>
                                                        }
                                                        <ErrorLabel message={companyId > 0 && companyContacts.length === 0 ? 'No contacts found' : ''} />
                                                    </div>
                                                    ]
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="agenda"
                                                        name="agenda"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Meeting Agenda"
                                                        {...register('agenda', { required: 'Meeting Agenda is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingAgendaWatcher != null ? meetingAgendaWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.agenda && <ErrorLabel message={errors.agenda?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Descrption</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Meeting Descrption"
                                                        {...register('description')}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingDescriptionWatcher != null ? meetingDescriptionWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control ">Accompanied By</label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="accompained_by"
                                                        render={({ field }) => {
                                                            return (
                                                                <Select
                                                                    {...field}
                                                                    placeholder="Select"
                                                                    menuPlacement={"top"}
                                                                    isMulti={true}
                                                                    hideSelectedOptions={false}
                                                                    maxMenuHeight={200}
                                                                    name="name"
                                                                    options={accompainedBy}
                                                                    value={field.value}
                                                                    onChange={(selectedOptions) => {
                                                                        if (selectedOptions != null) {
                                                                            field.onChange(selectedOptions);
                                                                        }
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Upload Document</label>
                                                        <div class="custom-file">
                                                            <input type="file"
                                                                class="custom-file-input"
                                                                id="upload_document"
                                                                name="upload_document"
                                                                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx"
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.files
                                                                    ) {

                                                                        var fileSize = event.target.files[0].size;

                                                                        var ext = (event.target.files[0].name).split('.').pop();

                                                                        if (checkFileExtension(ext, validExt)) {

                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                setUploadDocument(event.target.files[0]);
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }
                                                                        } else {

                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'You can only upload jpg, jpeg, png, pdf, doc, docx, xlsx, xls, ppt & pptx files',
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })

                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label class="custom-file-label" for="upload_document">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument.name : "Choose file"
                                                                }
                                                            </label>
                                                            <div className="mt-1">
                                                                {
                                                                    (riMeetingsDetails != null && riMeetingsDetails.meeting_document != null)
                                                                        ?
                                                                        <div id="view_document">
                                                                            {/* <a target="_blank" href={riMeetingsDetails.meeting_document}>View Document</a> */}
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (riMeetingsDetails.meeting_document?.split('.').pop() == 'pdf' ||
                                                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'PDF' ||
                                                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'png' ||
                                                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'jpg' ||
                                                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'jpeg' ||
                                                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(riMeetingsDetails.meeting_document)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(riMeetingsDetails.meeting_document?.split('.').pop())
                                                                                        setDocumentDetails(riMeetingsDetails)
                                                                                    } else {
                                                                                        downloadFile(riMeetingsDetails.meeting_document);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                            <FiTrash
                                                                                onClick={() => {
                                                                                    Swal.fire({
                                                                                        icon: 'warning',
                                                                                        text: 'Are you sure, you want to delete this document?',
                                                                                        cancelButtonText: "Cancel",
                                                                                        showCancelButton: true,
                                                                                    }).then(({ isConfirmed }) => {
                                                                                        if (isConfirmed) {
                                                                                            props.actionDeleteDocument({
                                                                                                "dms_id": riMeetingsDetails.document_dms_id,
                                                                                                "type": "meeting",
                                                                                                "id": riMeetingsDetails.id
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}
                                                                                size={"16"}
                                                                                cursor={"pointer"}
                                                                                className="ml-half"
                                                                            ></FiTrash>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={
                                                                    existingDate != true
                                                                        ? [
                                                                            {
                                                                                name: 'Update',
                                                                                id: 'open'
                                                                            },
                                                                            {
                                                                                name: 'Cancel',
                                                                                id: 'cancel'
                                                                            }
                                                                        ]
                                                                        : [
                                                                            {
                                                                                name: 'Update',
                                                                                id: 'open'
                                                                            },
                                                                            {
                                                                                name: 'Cancel',
                                                                                id: 'cancel'
                                                                            },
                                                                            {
                                                                                name: 'Close',
                                                                                id: 'close'
                                                                            }
                                                                        ]
                                                                }
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select status"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {

                                                                        field.onChange(event.itemData.id);
                                                                        setStatus(event.itemData.id);

                                                                        if (event.itemData.id === 'cancel') {
                                                                            setNextMeeting(false)
                                                                        }

                                                                        if (event.itemData.id !== 'close') {
                                                                            setValue('minutes_of_meeting', '');
                                                                            setValue('remark', '');
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            {
                                                status === 'close' ?
                                                    [<div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Pinc Minutes&nbsp;<span className="danger">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                id="minutes_of_meeting"
                                                                name="minutes_of_meeting"
                                                                placeholder="Enter Pinc Minutes"
                                                                maxLength={characterLimit}
                                                                rows={5}
                                                                {...register('minutes_of_meeting', { required: 'Pinc Minutes is required' })}
                                                            />
                                                            <div className="d-flex flex-row justify-content-between w-100">
                                                                <div className="flex-column"><small>{minuteOfMeetingWatcher != null && minuteOfMeetingWatcher != undefined ? minuteOfMeetingWatcher?.length : 0}/{characterLimit}</small></div>
                                                            </div>
                                                            {errors.minutes_of_meeting && <ErrorLabel message={errors.minutes_of_meeting?.message} />}
                                                        </div>
                                                    </div>,
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Minutes</label>
                                                            <textarea
                                                                className="form-control"
                                                                id="remark"
                                                                name="remark"
                                                                placeholder="Enter Remarks"
                                                                rows={5}
                                                                maxLength={characterLimit}
                                                                {...register('remark')}
                                                            />
                                                            {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                        </div>
                                                    </div>,
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="next_meeting"
                                                                    id="next_meeting"
                                                                    {...register('next_meeting')}
                                                                    onChange={(event) => { setNextMeeting(event.target.checked); }}
                                                                />
                                                                {errors.next_meeting && <ErrorLabel message={errors.next_meeting?.message} />}
                                                                <label class="custom-control-label" for="next_meeting">Create Next Meeting</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ]
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    {/* call when **Create Next Meeting** checkBox click */}
                                    {
                                        nextMeeting ?
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h6 className="mb-2 font-medium-1 text-bold-600">Next Meeting Details</h6>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="next_start_date"
                                                            className="form-control"
                                                            control={control}

                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    className="form-control"
                                                                    pickerType="calendar"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    acceptCustomValue={false}
                                                                    min={dateFormat(new Date)}
                                                                    showClearButton={true}
                                                                    value={field.value}
                                                                    onValueChange={(value) => {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Next Meeting Start Date is required'
                                                            }}
                                                        />
                                                        {errors.next_start_date && <ErrorLabel message={errors.next_start_date?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="label-control">From Time&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_from_time"
                                                            name="next_from_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_from_time', { required: 'From Time is required' })}
                                                        />
                                                        {errors.next_from_time && <ErrorLabel message={errors.next_from_time?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">To Time&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_to_time"
                                                            name="next_to_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_to_time', { required: 'To Time is required' })}
                                                        />
                                                        {errors.next_to_time && <ErrorLabel message={errors.next_to_time?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit-ri"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="ri-edit-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        > Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ReinsuranceNewCustomer
                    show={showNewClientEdit}
                    handleClose={(ri) => {
                        if (ri != null) {
                            setCompanyId(ri.id)
                            props.actionGetRiName({ clientOf: "reinsurance" })
                        }
                        setShowNewClientEdit(false)
                    }}
                />
                <ContactDetails
                    show={showContactDetails}
                    isRi={true}
                    handleClose={(newContact) => {
                        if (newContact != null) {
                            setClientContactIds(newContact.id)
                            props.actionRIMeetingAddContact({
                                client_id: getValues("client_id"),
                                client_type: "client",
                                contact_designation: newContact.contact_designation,
                                contact_email: newContact.contact_email,
                                contact_name: newContact.contact_name,
                                contact_phone: newContact.contact_phone,
                                department: newContact.department,
                                office_number: newContact.office_number,
                            })
                        }
                        setShowContactDetails(false)
                    }}
                />
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}



const mapStateToProps = (state) => {
    var RiMeetingsEdit = null;
    var CountryUpdate = null;
    var ClientType = null;
    var ReinsuranceClient = null;
    var RIGetContacts = null;
    var GetRiName = null;
    var RIMeetingAddContact = null;
    var RiMeetingsView = null;
    var AccompainedBy = null;
    var PreviousMeeting = null;
    var DocumentDelete = null;
    var RIMeetingSave = null;

    if (
        state.RiMeetingsEditReducer != null &&
        Object.keys(state.RiMeetingsEditReducer).length > 0 &&
        state.RiMeetingsEditReducer.constructor === Object
    ) {
        switch (state.RiMeetingsEditReducer.tag) {
            case ACTION_RI_MEETING_EDIT_SUCCESS:
                RiMeetingsEdit = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { RiMeetingsEdit };
            case ACTION_MASTER_COUNTRY_SUCCESS:
                CountryUpdate = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { CountryUpdate };
            case ACTION_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { ClientType };
            case ACTION_REINSURANCE_CLIENT_SUCCESS:
                ReinsuranceClient = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { ReinsuranceClient };
            case ACTION_RI_GET_CONTACTS_SUCCESS:
                RIGetContacts = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { RIGetContacts };
            case ACTION_ADD_GET_RI_NAME_SUCCESS:
                GetRiName = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { GetRiName };
            case ACTION_RI_MEETING_ADD_CONTACT_SUCCESS:
                RIMeetingAddContact = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { RIMeetingAddContact };
            case ACTION_RI_MEETING_VIEW_SUCCESS:
                RiMeetingsView = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { RiMeetingsView };
            case ACTION_RI_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { AccompainedBy };
            case ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS:
                PreviousMeeting = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { PreviousMeeting };
            case ACTION_DOCUMENT_DELETE_SUCCESS:
                DocumentDelete = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { DocumentDelete };
            case ACTION_RI_MEETING_SAVE_SUCCESS:
                RIMeetingSave = Object.assign({}, state.RiMeetingsEditReducer.data);
                delete state.RiMeetingsEditReducer;
                return { RIMeetingSave };
            default:

        }
    }
    return {
        RiMeetingsEdit,
        CountryUpdate,
        ClientType,
        ReinsuranceClient,
        RIGetContacts,
        GetRiName,
        RIMeetingAddContact,
        RiMeetingsView,
        AccompainedBy,
        PreviousMeeting,
        DocumentDelete,
        RIMeetingSave

    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionRiMeetingsEdit,
        actionCountry,
        actionClientType,
        actionReinsuranceClient,
        actionRIGetContacts,
        actionGetRiName,
        actionRIMeetingAddContact,
        actionRiMeetingsView,
        actionAccompainedBy,
        actionPreviousMeeting,
        actionDeleteDocument,
        actionRIMeetingSave
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiMeetingEdit))