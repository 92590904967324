/* eslint-disable import/prefer-default-export */

export const fetchSuccess = (action, payload) => ({
    type: action,
    payload
});

export const loading = (action, payload) => ({
    type: action,
    payload
});