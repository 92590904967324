import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_REIMBURSEMENT_CLAIM } from '../../../api/constants';
import {API_REIMBURSEMENT_CLAIM_EDIT} from '../../../api/constants'
import { PRINT } from '../../../utils';

export const ACTION_OTHER_CLAIM_LOADING = 'ACTION_OTHER_CLAIM_LOADING';
export const ACTION_OTHER_CLAIM_SUCCESS = 'ACTION_OTHER_CLAIM_SUCCESS';

export const ACTION_OTHER_CLAIM_EDIT_LOADING ='ACTION_OTHER_CLAIM_EDIT_LOADING';
export const ACTION_OTHER_CLAIM_EDIT_SUCCESS ='ACTION_OTHER_CLAIM_EDIT_SUCCESS';

export function actionOtherClaim(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_REIMBURSEMENT_CLAIM,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OTHER_CLAIM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OTHER_CLAIM_LOADING, { loading: false, data }));
        });
}
export function actionOtherClaimEdit(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_REIMBURSEMENT_CLAIM_EDIT,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OTHER_CLAIM_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OTHER_CLAIM_EDIT_LOADING, { loading: false, data }));
        });
}