import React from 'react';
import { Route, Switch } from 'react-router';
import DepartmentMasterRoute from './department';
import PolicyMasterRoute from './policy';
import InsurerMasterRoute from './insurer'
import AdminRightList from './admin_right/list';
import PlacementList from './placement/list';
import PlacementSpecificList from './placement_specific/list';
import TypeOfTrainingList from './type_of_training/list';

const MastersRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/placement/specific`} component={PlacementSpecificList} />
                <Route path={`${match.url}/placement/general`} component={PlacementList} />
                <Route path={`${match.url}/admin_right`} component={AdminRightList} />
                <Route path={`${match.url}/training-type`} component={TypeOfTrainingList} />
                <Route path={`${match.url}/insurer`} component={InsurerMasterRoute} />
                <Route path={`${match.url}/department`} component={DepartmentMasterRoute} />
                <Route path={`${match.url}/policy`} component={PolicyMasterRoute} />
            </Switch>
        </>
    )
}

export default MastersRoute