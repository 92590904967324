import { API_ADD_FEEDBACK } from '../../../api/constants';
import Api from '../../../api';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_ADD_FEEDBACK_LOADING = 'ACTION_ADD_FEEDBACK_LOADING';
export const ACTION_ADD_FEEDBACK_SUCCESS = 'ACTION_ADD_FEEDBACK_SUCCESS';

export function actionAddFeedback(params) {
    return (dispatch, getState) =>
        Api.post(API_ADD_FEEDBACK,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ADD_FEEDBACK_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ADD_FEEDBACK_LOADING, { loading: false, data }));
        });
}