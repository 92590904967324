import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_CLIENT_POLICIES } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const CLIENT_ALL_POLICIES_LOADING = 'CLIENT_ALL_POLICIES_LOADING';
export const CLIENT_ALL_POLICIES_SUCCESS = 'CLIENT_ALL_POLICIES_SUCCESS';

export function actionClientPolicies(params) {
    return (dispatch, getState) => Api.post(API_CLIENT_POLICIES, params).then((res) => {
        const data = res;
        PRINT('CLIENTTABPOLICIESVIEW ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(CLIENT_ALL_POLICIES_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(CLIENT_ALL_POLICIES_LOADING, { loading: false, data }));
    });
}
