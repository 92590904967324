import { API_MY_LEAD } from '../../../../../../api/constants';
import { API_MY_TEAM_LEAD, API_MYTEAMVERTICAL } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_API_MY_LEAD_LOADING = 'ACTION_API_MY_LEAD_LOADING';
export const ACTION_API_MY_LEAD_SUCCESS = 'ACTION_API_MY_LEAD_SUCCESS';

export const ACTION_MY_TEAM_LEAD_LOADING = 'ACTION_MY_TEAM_LEAD_LOADING';
export const ACTION_MY_TEAM_LEAD_SUCCESS = 'ACTION_MY_TEAM_LEAD_SUCCESS';

export const ACTION_PROFILE_LEAD_TEAM_VERTICAL_LOADING = 'ACTION_PROFILE_LEAD_TEAM_VERTICAL_LOADING';
export const ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS = 'ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS';

export function actionMyLead(params) {
    return (dispatch, getState) =>
        Api.post(API_MY_LEAD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MY_LEAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MY_LEAD_LOADING, { loading: false, data }));
        });
}

export function actionMyTeamLead(params) {
    return (dispatch, getState) =>
        Api.post(API_MY_TEAM_LEAD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MY_TEAM_LEAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MY_TEAM_LEAD_LOADING, { loading: false, data }));
        });
}

export function actionProfileLeadTeamMember(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROFILE_LEAD_TEAM_VERTICAL_LOADING, { loading: false, data }));
        });
}