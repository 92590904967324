import { API_MASTER_POLICY, API_MASTER_PROBABILITY } from '../../../../../api/constants';
import { API_MASTER_INSURANCE_COMPANY } from '../../../../../api/constants';
import { API_SUM_INSURED } from '../../../../../api/constants';
import { API_MYTEAMVERTICAL } from '../../../../../api/constants';
import { API_POLICY_DASHBOARD } from '../../../../../api/constants';
import { API_GET_DEPARTMENT } from '../../../../../api/constants';
import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';


export const ACTION_MASTER_POLICY_TYPE_LOADING = 'ACTION_MASTER_POLICY_TYPE_LOADING';
export const ACTION_MASTER_POLICY_TYPE_SUCCESS = 'ACTION_MASTER_POLICY_TYPE_SUCCESS';

export const ACTION_MASTER_ASSIGN_RM_LOADING = 'ACTION_MASTER_ASSIGN_RM_LOADING';
export const ACTION_MASTER_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_ASSIGN_RM_SUCCESS';

export const ACTION_CLAIMS_POLICY_TYPE_LOADING = 'ACTION_CLAIMS_POLICY_TYPE_LOADING';
export const ACTION_CLAIMS_POLICY_TYPE_SUCCESS = 'ACTION_CLAIMS_POLICY_TYPE_SUCCESS';

export const ACTION_CLAIMS_INSURANCE_COMPANY_LOADING = 'ACTION_CLAIMS_INSURANCE_COMPANY_LOADING';
export const ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS = 'ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS';

export const ACTION_POLICY_DASHBOARD_SUCCESS = 'ACTION_POLICY_DASHBOARD_SUCCESS';
export const ACTION_POLICY_DASHBOARD_LOADING = 'ACTION_POLICY_DASHBOARD_LOADING';

export const ACTION_SUM_INSURED_POLICY_SUCCESS = 'ACTION_SUM_INSURED_POLICY_SUCCESS';
export const ACTION_SUM_INSURED_POLICY_LOADING = 'ACTION_SUM_INSURED_POLICY_LOADING';



export function actionPolicyType(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_POLICY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}
export function actionDepartmentPolicyType() {
    return (dispatch, getState) =>
        Api.get(API_GET_DEPARTMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}
export function actionProposalRM(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}


export function actionInsuredCompany() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_INSURANCE_COMPANY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionDashboardPolicy(params) {
    return (dispatch, getState) =>
        Api.post(API_POLICY_DASHBOARD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_POLICY_DASHBOARD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_POLICY_DASHBOARD_LOADING, { loading: false, data }));
        });
}

export function actionSumInsuredPolicy() {
    return (dispatch, getState) =>
        Api.get(API_SUM_INSURED).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_SUM_INSURED_POLICY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SUM_INSURED_POLICY_LOADING, { loading: false, data }));
        });
}
