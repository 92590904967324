import { ACTION_ADD_MANDATE_POLICY_SUCCESS } from "./action";
import { ACTION_CLAIMS_COMPANY_GROUP_SUCCESS } from "./action";
import { ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS } from "./action";
import { ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS } from "./action";
import { ACTION_CLAIMS_CUSTOMER_SUCCESS } from "./action";
import { ACTION_CLAIMS_RM_SUCCESS } from "./action";
import { ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const MandateAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_ADD_MANDATE_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_MANDATE_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_COMPANY_GROUP_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_COMPANY_GROUP_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_CUSTOMER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_CUSTOMER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
});

export default MandateAddReducer;    