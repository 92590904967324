import React from 'react';
import { Route, Switch } from 'react-router';
import HolidayCalendar from './calendar';
import HolidayList from './list';
import HolidayAdd from './add';
import HolidayEdit from './edit';
import HolidayView from './view';

const HolidayRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/calendar`}  component={HolidayCalendar}/> 
                <Route path={`${match.url}/add`}  component={HolidayAdd}/>
                <Route path={`${match.url}/edit`}  component={HolidayEdit}/>
                <Route path={`${match.url}/view`}  component={HolidayView}/>
                <Route path={`${match.url}`}  component={HolidayList}/>
            </Switch>
        </>
    )
}

export default HolidayRoute