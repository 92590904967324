import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { FiPlus, FiTrash2, FiEdit3 } from 'react-icons/fi';
import MobileClaim from '../../../../../../component/form/mobile_claim';
import ConveyanceClaim from '../../../../../../component/form/conveyance_claim';
import OtherClaim from '../../../../../../component/form/other_claim';
import Swal from 'sweetalert2';
import _ from "lodash";
import { UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { actionReimbursementView, ACTION_REIMBURSEMENT_VIEW_SUCCESS, ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS, actionPrintReimbursementRequest } from './action';
import { actionReimbursementChangeStatus, ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS } from './action';
import { actionReimbursementDelete, ACTION_REIMBURSEMENT_DELETE_SUCCESS } from './action';
import { actionGetReimbursementType, ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS } from './action';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { downloadFile, verifyPermission } from '../../../../../../utils';
import { withAuth } from 'react-auth-guard';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { FaFilePdf } from 'react-icons/fa';

const ReimbursementAdd = (props) => {

    const { handleSubmit, control } = useForm();
    const [isactive, setIsActive] = useState('SUMMARY');
    const [showTypeDetails, setShowTypeDetails] = useState(false);
    const [remTypeDetails, setRemTypeDetails] = useState([]);
    const [showmobileclaim, setShowMobileClaim] = useState(false);
    const [showconveyanceclaim, setShowConveyanceClaim] = useState(false);
    const [showotherclaim, setShowOtherClaim] = useState(false)
    const [selectedMobileIndex, setSelectedMobileIndex] = useState(null);
    const [selectedOtherIndex, setSelectedOtherIndex] = useState(null);
    const [selectedConveyanceIndex, setSelectedConveyanceIndex] = useState(null);
    const [reimbursementId, setReimbursementId] = useState();
    const [reimbursementDetail, setReimbursementDetail] = useState(null);
    const [pendingAmount, setPendingAmount] = useState(null)
    const [data, setData] = useState(false)
    const [reimbursementTypeId, setReimbursementTypeId] = useState(null);
    const [reimbursementTypeDetails, setReimbursementTypeDetails] = useState(null);
    const [reimbursementType, setReimbursementType] = useState([]);
    const [remTitle, setRemTitle] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [typeLimit, setTypeLimit] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [printReimbursementRequest, setPrintReimbursementRequest] = useState(null)

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        setReimbursementId(props.location.state?.id);
        if (props.location.state?.month != null && props.location.state?.month != undefined && props.location?.state?.rem_year != undefined && props.location?.state?.rem_year != null) {
            setMonth(props.location.state?.month);
            setYear(props.location.state?.rem_year);
        }
        if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
            props.actionReimbursementView({
                id: props.location.state.id
            });
            props.actionPrintReimbursementRequest({
                id: props.location.state.id
            })
        } else {
            props.actionReimbursementView({
                user_id: props.auth.getUser().user_id,
                month: props.location.state?.month,
                year: props.location.state?.rem_year
            });
            props.actionPrintReimbursementRequest({
                user_id: props.auth.getUser().user_id,
                month: props.location.state?.month,
                year: props.location.state?.rem_year
            })
        }

        props.actionGetReimbursementType();
    }
    useEffect(initialRender, []);

    const foundMode = (mode_id, returnKey) => {
        var arrReimb = Object.assign([], reimbursementDetail?.reimbursement_transport_type);
        var obj = arrReimb.find(obj => obj.id === mode_id)
        if (obj != null) {
            switch (returnKey) {
                case "name": return obj.title
                case "km": return obj.is_km
                case "rate": return obj.km_rate
                default: return "--"
            }
        } else {
            return "--"
        }
    }

    function base64toPDF(base64, fileName) {
        const binaryString = atob(base64);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }

    const onPrintReimbursementRequestUpdate = () => {
        const { PrintReimbursementRequest } = props;
        var PrintReimbursementRequestResponse = Object.assign({}, PrintReimbursementRequest);
        if (PrintReimbursementRequestResponse.result === true) {
            setPrintReimbursementRequest(PrintReimbursementRequestResponse.response.reimbursement)
        } else {
            switch (PrintReimbursementRequestResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onPrintReimbursementRequestUpdate, [props.PrintReimbursementRequest]);

    const onReimbursementRequestViewUpdate = () => {
        const { ReimbursementRequestView } = props;
        var ReimbursementRequestViewResponse = Object.assign({}, ReimbursementRequestView);
        if (ReimbursementRequestViewResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setReimbursementDetail(ReimbursementRequestViewResponse.response);
            if (showTypeDetails === true && remTitle != null) {
                var obj = ReimbursementRequestViewResponse.response?.all_details.find((obj) => obj?.title === remTitle);
                if (obj != null && obj.list != null && obj.list.length > 0) {
                    setRemTypeDetails(obj.list);
                    setReimbursementTypeDetails(obj);
                    setPendingAmount(Number((obj.type_limit) - (obj.total_claim_amount)).toFixed(2));
                }
            }
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReimbursementRequestViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break
                default:
            }
        }
    }
    useEffect(onReimbursementRequestViewUpdate, [props.ReimbursementRequestView]);

    const onReimbursementChangeStatusUpdate = () => {
        const { ReimbursementChangeStatus } = props;
        var ReimbursementChangeStatusResponse = Object.assign({}, ReimbursementChangeStatus);
        if (ReimbursementChangeStatusResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.history.goBack();
            }
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReimbursementChangeStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break
                default:
            }
        }
    }
    useEffect(onReimbursementChangeStatusUpdate, [props.ReimbursementChangeStatus]);

    const onReimbursementDeleteUpdate = () => {
        const { ReimbursementDelete } = props;
        var ReimbursementDeleteResponse = Object.assign({}, ReimbursementDelete);
        if (ReimbursementDeleteResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            // setReimbursementDetail(ReimbursementDeleteResponse.response)
            if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
                props.actionReimbursementView({
                    id: props.location.state.id
                });
                props.actionPrintReimbursementRequest({
                    id: props.location.state.id
                })
            } else {
                props.actionReimbursementView({
                    user_id: props.auth.getUser().user_id,
                    month: props.location.state.month,
                    year: props.location.state?.rem_year
                });
                props.actionPrintReimbursementRequest({
                    user_id: props.auth.getUser().user_id,
                    month: props.location.state.month,
                    year: props.location.state?.rem_year
                })
            }
            setRemTypeDetails([]);
            setReimbursementTypeDetails(null);
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReimbursementDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break
                default:
            }
        }
    }
    useEffect(onReimbursementDeleteUpdate, [props.ReimbursementDelete]);

    const onGetReimbursementTypeUpdate = () => {
        const { GetReimbursementType } = props;
        var GetReimbursementTypeResponse = Object.assign({}, GetReimbursementType);
        if (GetReimbursementTypeResponse.result === true) {
            setReimbursementType(GetReimbursementTypeResponse.response);
        } else {
            switch (GetReimbursementTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break
                default:
            }
        }
    }
    useEffect(onGetReimbursementTypeUpdate, [props.GetReimbursementType]);

    const renderMobileDetails = () => {
        var arrMobileDetails = []
        if (remTypeDetails != null && remTypeDetails.length > 0) {
            remTypeDetails.map((row, index) => {
                arrMobileDetails.push(
                    <>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className='row mt-1'>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Amount of Bill</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700">{<div><span>&#8377;</span> {row.bill_amount} </div>}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Amount to Reimbursement</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700">{<div><span>&#8377;</span> {row.claim_amount} </div>}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control">Description</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700">{row.description}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control">Attachment</label>
                                        <div className="form-group">
                                            {
                                                (row != null && row.reimbursement_document != null) ?
                                                    // <a className='primary' target="_blank" href={row.reimbursement_document}>{row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}</a> : <>-</>
                                                    <>
                                                        <a className="primary"
                                                            onClick={() => {
                                                                if (row.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'png' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'gif'
                                                                ) {
                                                                    setSelectedDocumentIndex(row.reimbursement_document)
                                                                    setShowDocumentDetails(true);
                                                                    setExtension(row.reimbursement_document?.split('.').pop())
                                                                    setDocumentDetails(row)
                                                                } else {
                                                                    downloadFile(row.reimbursement_document);
                                                                }
                                                            }}
                                                        >
                                                            {row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}
                                                        </a>
                                                        <a id="downloadLink" className="d-none"></a>
                                                    </>
                                                    : <>-</>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' && verifyPermission('EDIT_NEW_REIMBURSEMENT') ?
                                        <div className="col-12 col-md-2 col-lg-2">
                                            <button
                                                type="button"
                                                className="btn px-0 primary cursor-pointer"
                                                onClick={() => {
                                                    setSelectedMobileIndex(index)
                                                    setShowMobileClaim(true)
                                                    setData(true)
                                                }}
                                            >
                                                <FiEdit3 size={20} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn ml-1 px-0 primary cursor-pointer"
                                                onClick={() => {
                                                    Swal.fire({
                                                        icon: 'warning',
                                                        text: 'Are you sure, you want to Delete this Reimbnursement?',
                                                        cancelButtonText: "Cancel",
                                                        showCancelButton: true,
                                                    }).then(({ isConfirmed }) => {
                                                        if (isConfirmed) {
                                                            $("#progress-loader").removeClass("hidden");
                                                            props.actionReimbursementDelete({
                                                                id: row.details_id,
                                                                reimbursement_id: reimbursementDetail.id
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                <FiTrash2 size={20} />
                                            </button>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>

                        </div>
                        <h4 class="align-items-center form-section px-1 black"></h4>
                    </>
                )
            })
        }
        return arrMobileDetails
    }

    const renderConveyanceDetails = () => {
        var arrConveyanceDetails = []
        if (remTypeDetails != null && remTypeDetails.length > 0) {
            remTypeDetails.map((row, index) => {
                arrConveyanceDetails.push(
                    <>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className='row'>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Date</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700 mb-0">{moment(row.bill_date).format("DD-MM-YYYY")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Mode</label>
                                        <div className="form-group">
                                            <b> <label className="font-medium-1 text-bold-700 mb-0">{row.transport_type_title}</label></b>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">From</label>
                                        <div className="form-group">
                                            <b><label className="font-medium-1 text-bold-700 mb-0">{row.from_location}</label></b>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">To</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700 mb-0">{row.to_location}</label>
                                        </div>
                                    </div>
                                </div>
                                {foundMode(row.mode_id, 'km') === 'Y' ?
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label className="label-control">Kilometer</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{row.km}</label>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Amount</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700 mb-0">{<div><span>&#8377;</span> {row.claim_amount} </div>}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Meeting ID</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700 mb-0 text-primary cursor-pointer"
                                                onClick={() => {
                                                    switch (row.meeting_type) {
                                                        case 'reinsurance':
                                                            window.open(`/meetings/ri-meetings/view?id=${row.meeting_id}`, '_blank');
                                                            break;
                                                        case 'internal':
                                                            window.open(`/meetings/internal-meetings/view?id=${row.meeting_id}`, '_blank');
                                                            break;
                                                        case 'tpa':
                                                            window.open(`/meetings/tpa-meetings/view?id=${row.meeting_id}`, '_blank');
                                                            break;
                                                        case 'surveyor':
                                                            window.open(`/meetings/serveyor-meetings/view?id=${row.meeting_id}`, '_blank');
                                                            break;
                                                        case 'lead':
                                                        case 'client':
                                                            window.open(`/meetings/customer-meetings/view?id=${row.meeting_id}`, '_blank');
                                                            break;
                                                        case 'insurer':
                                                            window.open(`/meetings/insurance-company-meetings/view?id=${row.meeting_id}`, '_blank');
                                                            break;
                                                    }
                                                }}
                                            >{row.agenda != null ? row.agenda : '--'}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Purpose</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700 mb-0">{row.purpose}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Attachment</label>
                                        <div className="form-group">
                                            {
                                                (row != null && row.reimbursement_document != null) ?
                                                    // <a className='primary' target="_blank" href={row.reimbursement_document}>{row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}</a> : <>-</>
                                                    <>
                                                        <a className="primary"
                                                            onClick={() => {
                                                                if (row.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'png' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'gif'
                                                                ) {
                                                                    setSelectedDocumentIndex(row.reimbursement_document)
                                                                    setShowDocumentDetails(true);
                                                                    setExtension(row.reimbursement_document?.split('.').pop())
                                                                    setDocumentDetails(row)
                                                                } else {
                                                                    downloadFile(row.reimbursement_document);
                                                                }
                                                            }}
                                                        >
                                                            {row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}
                                                        </a>
                                                        <a id="downloadLink" className="d-none"></a>
                                                    </>
                                                    : <>-</>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' ?
                                        <div className="col-12 col-md-2 col-lg-2">
                                            <button
                                                type="button"
                                                className="btn px-0 primary cursor-pointer"
                                                onClick={() => {
                                                    setSelectedConveyanceIndex(index)
                                                    setShowConveyanceClaim(true)
                                                    setData(true)
                                                }}
                                            >
                                                <FiEdit3 size={20} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn ml-1 px-0 primary cursor-pointer"
                                                onClick={() => {
                                                    Swal.fire({
                                                        icon: 'warning',
                                                        text: 'Are you sure, you want to Delete this Reimbnursement?',
                                                        cancelButtonText: "Cancel",
                                                        showCancelButton: true,
                                                    }).then(({ isConfirmed }) => {
                                                        if (isConfirmed) {
                                                            $("#progress-loader").removeClass("hidden");
                                                            props.actionReimbursementDelete({
                                                                id: row.details_id,
                                                                reimbursement_id: reimbursementDetail.id
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                <FiTrash2 size={20} />
                                            </button>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                        <h4 class="align-items-center form-section px-1 black"></h4>
                    </>

                )
            })
            return arrConveyanceDetails
        }
    }

    const renderOtherDetails = () => {
        var arrOtherDetails = []
        if (remTypeDetails != null && remTypeDetails.length > 0) {
            remTypeDetails.map((row, index) => {
                arrOtherDetails.push(
                    <>

                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className='row mt-1'>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Date</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700">{moment(row.bill_date).format("DD-MM-YYYY")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label className="label-control">Amount to Reimbursement</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700">{<div><span>&#8377;</span> {row.claim_amount} </div>}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control">Description</label>
                                        <div className="form-group">
                                            <label className="font-medium-1 text-bold-700 ">{row.description}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control">Attachment</label>
                                        <div className="form-group">
                                            {
                                                (row != null && row.reimbursement_document != null) ?
                                                    // <a className='primary' target="_blank" href={row.reimbursement_document}>{row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}</a> : <>-</>
                                                    <>
                                                        <a className="primary"
                                                            onClick={() => {
                                                                if (row.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'png' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                    row.reimbursement_document?.split('.').pop() == 'gif'
                                                                ) {
                                                                    setSelectedDocumentIndex(row.reimbursement_document)
                                                                    setShowDocumentDetails(true);
                                                                    setExtension(row.reimbursement_document?.split('.').pop())
                                                                    setDocumentDetails(row)
                                                                } else {
                                                                    downloadFile(row.reimbursement_document);
                                                                }
                                                            }}
                                                        >
                                                            {row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}
                                                        </a>
                                                        <a id="downloadLink" className="d-none"></a>
                                                    </>
                                                    : <>-</>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' && verifyPermission('EDIT_NEW_REIMBURSEMENT') ?
                                        <div className="col-12 col-md-2 col-lg-2">
                                            <button
                                                type="button"
                                                class="btn px-0 primary"
                                                onClick={() => {
                                                    setSelectedOtherIndex(index)
                                                    setShowOtherClaim(true)
                                                    setData(true)
                                                }}
                                            >
                                                <FiEdit3 size={20} />
                                            </button>
                                            <button type="button" class="btn pt-1 primary"
                                                onClick={() => {
                                                    Swal.fire({
                                                        icon: 'warning',
                                                        text: 'Are you sure, you want to delete this Reimbursement ?',
                                                        cancelButtonText: "Cancel",
                                                        showCancelButton: true,

                                                    }).then(({ isConfirmed }) => {
                                                        if (isConfirmed) {
                                                            $("#progress-loader").removeClass("hidden");
                                                            props.actionReimbursementDelete({
                                                                id: row.details_id,
                                                                reimbursement_id: reimbursementDetail.id
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                <FiTrash2 size={20} />
                                            </button>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>

                        <h4 class="align-items-center form-section px-1 black"></h4>
                    </>
                )
            })
        }
        return arrOtherDetails
    }

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Reimbursement
                    </h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            {reimbursementDetail?.status === 'approved by manager' || reimbursementDetail?.status === 'reject by finance manager' || reimbursementDetail?.status === 'approved by finance manager' ?
                                <button className="btn btn-secondary mr-1 shadow round text-uppercase"
                                    type="button"
                                    onClick={() => {
                                        base64toPDF(`${printReimbursementRequest}`, reimbursementDetail?.creator_name + ' Reimbursement Summary.pdf')
                                    }}
                                >
                                    {/* <FaDownload size={20} /> */}
                                    Download < FaFilePdf size={17} color='white' />
                                </button>
                                :
                                <></>
                            }
                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            > Back</button>
                        </li>
                    </ul>
                </div>
            </div >
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form>
                                    <div className="row">
                                        <div className="col-12 col-md-3 col-lg-3 mb-1">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Employee Name</label>
                                                <div>
                                                    <label className="label-control  font-weight-bold mr-1">{reimbursementDetail?.creator_name != null ? reimbursementDetail?.creator_name : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3 mb-1">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Employee ID</label>
                                                <div>
                                                    <label className="label-control  font-weight-bold mr-1">{reimbursementDetail?.pinc_employee_id != null ? reimbursementDetail?.pinc_employee_id : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3 mb-1">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Payroll Entity</label>
                                                <div>
                                                    <label className="label-control  font-weight-bold mr-1">{reimbursementDetail?.payroll_entity != null ? reimbursementDetail?.payroll_entity : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3 mb-1">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Month of Reimbursement</label>
                                                <div>
                                                    <label className="label-control  font-weight-bold mr-1">{
                                                        props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE' ?
                                                            moment(reimbursementDetail?.rem_month, 'M').format('MMMM') + ' ' + reimbursementDetail?.rem_year :
                                                            props.location.state?.rem_month != null && props.location.state?.rem_month != undefined ? props.location.state?.rem_month : '-'
                                                    }</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Approval Manager</label>
                                                <div>
                                                    <label className="label-control font-weight-bold mr-1">{reimbursementDetail != null ? reimbursementDetail?.reim_approval_manager_name : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Finance Approval Manager</label>
                                                <div>
                                                    <label className="label-control  font-weight-bold mr-1">{reimbursementDetail?.fin_approval_manager_name != null ? reimbursementDetail?.fin_approval_manager_name : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Manager Approved Date</label>
                                                <div>
                                                    <label className="label-control  font-weight-bold mr-1">{reimbursementDetail?.manager_action_date != null ? moment(reimbursementDetail?.manager_action_date, "DD-MM-YYYY").format("DD/MM/YYYY") : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Status</label>
                                                <div>
                                                    <label className={`label-control font-weight-bold mr-1 text-primary`}>{reimbursementDetail?.status_fomated != null ? reimbursementDetail?.status_fomated : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Remark</label>
                                                <div>
                                                    <label className={`label-control font-weight-bold mr-1`}>{reimbursementDetail?.remark != null ? reimbursementDetail?.remark : '-'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 mt-1">
                                            <div className="form-group mb-0">
                                                <label className="label-control mr-1 font-small-3"><b>Note</b> - You can attach bills of upto 2 months only (Including current month)</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-header">
                    <h3 className="content-header-title text-capitalize mb-1"> Reimbursement Details</h3>
                </div>
                <div className='row justify-content-between mb-2 px-3'>
                    <div className="col-md-6 my-auto">
                        <span className='text-primary cursor-pointer font-weight-bold'
                            onClick={() => {
                                setIsActive('SUMMARY');
                                setShowTypeDetails(false);
                                setReimbursementTypeId(null);
                            }}><u>SUMMARY</u></span>
                    </div>
                    {/* <div className="col-2 col-md-2 col-lg-2"></div> */}
                    {
                        reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' ?
                            <div className="col-md-3 ">
                                <Controller
                                    name="reimbursement_type"
                                    className="form-control"
                                    control={control}
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            id="reimbursement_type"
                                            name="reimbursement_type"
                                            dataSource={reimbursementType}
                                            fields={{ text: 'title', value: 'id' }}
                                            allowFiltering={true}
                                            filterType={"Contains"}
                                            placeholder="Select Reimbursement Type"
                                            stylingMode="outlined"
                                            value={field.value}
                                            change={(event) => {
                                                if (event.itemData != null) {
                                                    field.onChange(event.itemData.id);
                                                    setViewType(event.itemData.view_type);
                                                    setRemTitle(event.itemData.title);
                                                    setReimbursementTypeId(event.itemData.id);
                                                    setShowTypeDetails(true);
                                                    setTypeLimit(event.itemData.type_limit);
                                                    var obj = reimbursementDetail?.all_details.find((obj) => obj.title === event.itemData.title);
                                                    if (obj != null && obj != undefined) {
                                                        setReimbursementTypeDetails(obj);

                                                        setRemTypeDetails(obj.list);
                                                        setPendingAmount(Number((event.itemData.type_limit) - (obj.total_claim_amount)).toFixed(2));
                                                    } else {
                                                        setReimbursementTypeDetails(event.itemData);

                                                        setRemTypeDetails(null);
                                                        setPendingAmount(Number(event.itemData.type_limit));
                                                    }
                                                }
                                            }}

                                        />
                                    }
                                />
                            </div>
                            :
                            <></>
                    }
                </div>
                {
                    (viewType === "MOBILE" && showTypeDetails === true) ?
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                        <form noValidate autoComplete="Off">
                                            <div className='row'>
                                                <div class="col-12 col-md-12 col-lg-12">
                                                    <h4 class="align-items-center form-section  black text-bold-700">
                                                        {remTitle != null ? remTitle : ''} Expences
                                                    </h4>
                                                    {
                                                        reimbursementDetail != null && reimbursementDetail.all_details != null ?
                                                            renderMobileDetails()
                                                            :
                                                            <></>
                                                    }
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-0">
                                                    {
                                                        reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' && verifyPermission('NEW_REIMBURSEMENT') ?
                                                            <button
                                                                type="button"
                                                                class="btn pt-0 primary float-sm-left  float-md-right float-lg-right"
                                                                onClick={() => {
                                                                    setShowMobileClaim(true)
                                                                    setData(false)
                                                                }}
                                                            >
                                                                <FiPlus size={16} />
                                                                ADD NEW BILL

                                                            </button>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Total amount of Bill</label>
                                                        <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null && reimbursementTypeDetails.total_claim_amount != null ?
                                                            <div><span>&#8377;</span> {Number(reimbursementTypeDetails.total_claim_amount).toFixed(2)} </div> : 0.0}</div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Total Limit Amount</label>
                                                        <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null && reimbursementTypeDetails.type_limit != null ?
                                                            <div><span>&#8377;</span> {Number(reimbursementTypeDetails.type_limit).toFixed(2)} </div> : 0.0}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Remaining Limit Amount</label>
                                                        <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null && reimbursementTypeDetails.total_claim_amount != null && reimbursementTypeDetails.type_limit != null ?
                                                            <div><span>&#8377;</span> {Number((reimbursementTypeDetails.type_limit) - (reimbursementTypeDetails.total_claim_amount)).toFixed(2)} </div> : 0.0}</div>
                                                    </div>
                                                </div> */}

                                            </div>
                                            <h4 class="align-items-center form-section px-1 black"></h4>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div >
                        :
                        <></>
                }

                {
                    (viewType === "CONVENIENCE" && showTypeDetails === true) ?
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                        <form noValidate autoComplete="Off">
                                            <div className='row'>
                                                <div class="col-12 col-md-12 col-lg-12">
                                                    <h4 class="form-section my-1 black text-bold-700">
                                                        {remTitle != null ? remTitle : ''} Expences
                                                    </h4>
                                                    {
                                                        reimbursementDetail != null && reimbursementDetail.all_details != null ?
                                                            renderConveyanceDetails()
                                                            :
                                                            <></>

                                                    }
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-0">
                                                    {
                                                        reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' ?
                                                            <button
                                                                type="button"
                                                                class="btn pt-0 primary float-sm-left  float-md-right float-lg-right"
                                                                onClick={() => {
                                                                    setShowConveyanceClaim(true)
                                                                    setData(false)
                                                                }}
                                                            >
                                                                <FiPlus size={16} />
                                                                ADD NEW BILL
                                                            </button>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Total Amount of Bill</label>
                                                        <div className='form-group'>
                                                            <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null && reimbursementTypeDetails.total_claim_amount != null ?
                                                                <div><span>&#8377;</span> {Number(reimbursementTypeDetails.total_claim_amount).toFixed(2)} </div> : 0.0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Total Limit Amount</label>
                                                        <div className='form-group'>
                                                            <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null ?
                                                                <div><span>&#8377;</span> {Number(reimbursementTypeDetails.type_limit).toFixed(2)} </div> : 0.0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Remaining Limit Amount</label>
                                                        <div className='form-group'>
                                                            <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null && reimbursementTypeDetails.type_limit != null && reimbursementTypeDetails.total_claim_amount != null ?
                                                                <div><span>&#8377;</span> {Number((reimbursementTypeDetails.type_limit) - (reimbursementTypeDetails.total_claim_amount)).toFixed(2)} </div> : 0.0}</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <h4 class="align-items-center form-section px-1 black"></h4>
                                        </form>
                                    </div>
                                </div>
                            </div >
                        </div >
                        :
                        <></>
                }
                {
                    (viewType === "OTHERS" && showTypeDetails === true) ?
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                        <form>
                                            <div className='row'>
                                                <div class="col-12 col-md-12 col-lg-12">
                                                    <h4 class="align-items-center form-section my-1 black text-bold-700">
                                                        {remTitle != null ? remTitle : ''} Expences
                                                    </h4>
                                                    {
                                                        reimbursementDetail != null && reimbursementDetail.all_details != null ?
                                                            renderOtherDetails()
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-0">
                                                    {
                                                        reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' ?
                                                            <button
                                                                type="button"
                                                                class="btn pt-0 primary float-sm-left  float-md-right float-lg-right"
                                                                onClick={() => {
                                                                    setShowOtherClaim(true)
                                                                    setData(false)
                                                                }}
                                                            >
                                                                <FiPlus size={16} />
                                                                ADD NEW BILL
                                                            </button>
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Total amount of Bill</label>
                                                        <div className='form-group'>
                                                            <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null && reimbursementTypeDetails.total_claim_amount != null ?
                                                                <div><span>&#8377;</span> {Number(reimbursementTypeDetails.total_claim_amount).toFixed(2)} </div> : 0.0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Total Limit Amount</label>
                                                        <div className='form-group'>
                                                            <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null ?
                                                                <div><span>&#8377;</span> {Number(reimbursementTypeDetails.type_limit).toFixed(2)} </div> : 0.0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="label-control">Remaining Limit Amount</label>
                                                        <div className='form-group'>
                                                            <div class="text-dark font-weight-bold ml-half">{reimbursementTypeDetails != null && reimbursementTypeDetails.type_limit != null && reimbursementTypeDetails.total_claim_amount != null ?
                                                                <div><span>&#8377;</span> {Number((reimbursementTypeDetails.type_limit) - (reimbursementTypeDetails.total_claim_amount)).toFixed(2)} </div> : 0.0}</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <h4 class="align-items-center form-section px-1 black"></h4>
                                        </form>
                                    </div>
                                </div>
                            </div >
                        </div >
                        :
                        <></>
                }

                {
                    isactive === 'SUMMARY' && showTypeDetails === false ?
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                        <form>
                                            <div className='row'>
                                                <div class="col-12 col-md-12 col-lg-12 ">
                                                    <h4 class="align-items-center form-section my-1 black text-bold-700">
                                                        {`${isactive == 'MOBILE' ? 'Mobile Expense' : isactive == 'CONVEYANCE' ? 'Conveyance Expences' : isactive == 'OTHERS' ? 'Other Expen' : isactive == 'SUMMARY' ? ' Reimbursement Summary' : ''}`}
                                                    </h4>
                                                    <>
                                                        {
                                                            reimbursementDetail != null && reimbursementDetail.all_details != null && reimbursementDetail.all_details.length > 0 ?
                                                                reimbursementDetail.all_details.map((item) => {
                                                                    return (
                                                                        <>
                                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                                <div className='row mt-1'>

                                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                                        <div className="form-group">
                                                                                            <label className="label-control">Reimbursement for</label>
                                                                                            <div className="form-group"
                                                                                                onClick={() => {
                                                                                                    setViewType(item.view_type);
                                                                                                    setRemTitle(item.title);
                                                                                                    setShowTypeDetails(true);
                                                                                                    setRemTypeDetails(item.list);
                                                                                                    setReimbursementTypeId(item.reimbursement_type_id);
                                                                                                    setReimbursementTypeDetails(item);
                                                                                                }}
                                                                                            >
                                                                                                <label className="label-control text-uppercase font-weight-bold text-primary cursor-pointer"><u>{item.title}</u></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                                        <div className="form-group">
                                                                                            <label className="label-control">Reimbursement Amount</label>
                                                                                            <div className="form-group">
                                                                                                <label className="label-control"><b>{item.total_claim_amount != null ?
                                                                                                    <div><span>&#8377;</span> {Number(item.total_claim_amount).toFixed(2)} </div> : 0.0}</b></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                                        <div className="form-group">
                                                                                            <label className="label-control">Manager Approved Amount</label>
                                                                                            <div className="form-group">
                                                                                                <label className="label-control"><b>{item.total_approved_amount != null ?
                                                                                                    <div><span>&#8377;</span> {Number(item.total_approved_amount).toFixed(2)} </div> : 0.0}</b></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                                        <div className="form-group">
                                                                                            <label className="label-control">Finance Manager Approved Amount</label>
                                                                                            <div className="form-group">
                                                                                                <label className="label-control"><b>{item.total_finance_approved_amount != null ?
                                                                                                    <div><span>&#8377;</span> {Number(item.total_finance_approved_amount).toFixed(2)} </div> : 0.0}</b></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div >
                                                                            <h4 class="align-items-center form-section px-1 black"></h4>
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                                    <div className="form-group">
                                                        <label className="label-control"> Total amount <b>{reimbursementDetail != null && reimbursementDetail.claim_amount != null && reimbursementDetail.claim_amount != 0 ?
                                                            <span><span>&#8377;</span> {Number(reimbursementDetail.claim_amount).toFixed(2)} </span> : 0}</b></label>
                                                    </div>
                                                    <div className="form-group py-0 px-0">
                                                        <label className="label-control"> Manager Approved Amount <b>{reimbursementDetail != null && reimbursementDetail.approval_amount != null && reimbursementDetail.approval_amount != 0 ?
                                                            <span><span>&#8377;</span> {Number(reimbursementDetail.approval_amount).toFixed(2)} </span> : 0}</b></label>
                                                    </div>
                                                    <div className="form-group py-0 px-0">
                                                        <label className="label-control"> Finance Manager Approved Amount <b>{reimbursementDetail != null && reimbursementDetail.finance_approval_amount != null ?
                                                            <span><span>&#8377;</span> {Number(reimbursementDetail.finance_approval_amount).toFixed(2)} </span> : 0}</b></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <h6 class="d-flex form-section black">
                                            </h6>

                                            <div className="form-actions border-0 float-none">
                                                {
                                                    reimbursementDetail != null && reimbursementDetail.status != 'pending' && reimbursementDetail.status != 'approved by manager' && reimbursementDetail.status != 'approved by finance manager' ?
                                                        <button
                                                            className="btn btn-primary mr-1 mt-0 shadow text-bold-300 text-uppercase"
                                                            type="button"
                                                            id="reimbursement_request"
                                                            name="reimbursement_request"
                                                            onClick={() => {
                                                                if (reimbursementDetail != null && (reimbursementDetail.claim_amount === null || Number(reimbursementDetail.claim_amount) === 0)) {
                                                                    Swal.fire({
                                                                        icon: 'info',
                                                                        text: 'No Entries Entered in the Form?',
                                                                        cancelButtonText: "Cancel",
                                                                        showCancelButton: false,
                                                                    }).then(({ isConfirmed }) => {

                                                                    })
                                                                } else {
                                                                    Swal.fire({
                                                                        icon: 'info',
                                                                        text: 'Are you sure,Do you really want to send this request for approval?',
                                                                        cancelButtonText: "Cancel",
                                                                        showCancelButton: true,
                                                                    }).then(({ isConfirmed }) => {
                                                                        if (isConfirmed) {
                                                                            $("#progress-loader").removeClass("hidden");
                                                                            props.actionReimbursementChangeStatus({
                                                                                reimbursement_id: props.location.state.id,
                                                                                status: 'pending'
                                                                            })
                                                                        }
                                                                    })
                                                                }

                                                            }}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                SEND FOR APPROVAL
                                                            </div>
                                                        </button>
                                                        :
                                                        <></>
                                                }
                                                <button className="btn btn-secondary mr-1 mt-0 shadow  text-uppercase"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
                                                            props.history.push({
                                                                pathname: '/notification'
                                                            })
                                                        } else {
                                                            props.history.push({
                                                                pathname: '/hrms/reimbursement_request'
                                                            })
                                                        }
                                                    }}
                                                >Back To List</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div >
                        </div >
                        :
                        <></>
                }
            </section >
            <MobileClaim
                show={showmobileclaim}
                claimDetail={reimbursementDetail}
                pendingamount={pendingAmount}
                reimbursementTypeDetails={reimbursementTypeDetails}
                modileData={data}
                reimbursementTypeId={reimbursementTypeId}
                typeLimit={typeLimit}
                handleClose={(newMobileClaim) => {
                    setShowMobileClaim(false)
                    if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
                        props.actionReimbursementView({
                            id: props.location.state.id
                        });
                    } else {
                        props.actionReimbursementView({
                            user_id: props.auth.getUser().user_id,
                            month: props.location.state.month,
                            year: props.location.state.rem_year
                        });
                    }
                }}
                reimbursementId={reimbursementId}
                data={selectedMobileIndex != null && remTypeDetails != null ? remTypeDetails[selectedMobileIndex] : null}
            />
            <ConveyanceClaim
                show={showconveyanceclaim}
                claimDetail={reimbursementDetail}
                conveyancePendingAmount={pendingAmount}
                reimbursementTypeDetails={reimbursementTypeDetails}
                conveyanceData={data}
                month={month}
                year={year}
                remTitle={remTitle}
                typeLimit={typeLimit}
                reimbursementTypeId={reimbursementTypeId}
                handleClose={(newConyenace) => {
                    setShowConveyanceClaim(false)
                    if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
                        props.actionReimbursementView({
                            id: props.location.state?.id
                        });
                    } else {
                        props.actionReimbursementView({
                            user_id: props.auth.getUser()?.user_id,
                            month: props.location?.state?.month,
                            year: props.location?.state?.rem_year
                        });
                    }
                }}
                reimbursementId={reimbursementId}
                data={selectedConveyanceIndex != null && remTypeDetails != null ? remTypeDetails[selectedConveyanceIndex] : null}
            />
            <OtherClaim
                show={showotherclaim}
                claimDetail={reimbursementDetail}
                otherPendingAmount={pendingAmount}
                reimbursementTypeDetails={reimbursementTypeDetails}
                otherData={data}
                month={month}
                year={year}
                remTitle={remTitle}
                typeLimit={typeLimit}
                reimbursementTypeId={reimbursementTypeId}
                handleClose={(newOtherClaim) => {
                    setShowOtherClaim(false)
                    if (props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE' || props?.location?.state?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE') {
                        props.actionReimbursementView({
                            id: props.location.state.id
                        });
                    } else {
                        props.actionReimbursementView({
                            user_id: props.auth.getUser().user_id,
                            month: props.location.state.month,
                            year: props.location.state.rem_year
                        });
                    }
                }}
                reimbursementId={reimbursementId}
                data={selectedOtherIndex != null && remTypeDetails != null ? remTypeDetails[selectedOtherIndex] : null}
            />
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var ReimbursementRequestView = null;
    var ReimbursementChangeStatus = null;
    var ReimbursementDelete = null;
    var GetReimbursementType = null;
    var PrintReimbursementRequest = null;

    if (
        state.AddReimbursementReducer != null &&
        Object.keys(state.AddReimbursementReducer).length > 0 &&
        state.AddReimbursementReducer.constructor === Object
    ) {
        switch (state.AddReimbursementReducer.tag) {
            case ACTION_REIMBURSEMENT_VIEW_SUCCESS:
                ReimbursementRequestView = Object.assign({}, state.AddReimbursementReducer.data);
                delete state.AddReimbursementReducer
                return { ReimbursementRequestView };
            case ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS:
                ReimbursementChangeStatus = Object.assign({}, state.AddReimbursementReducer.data);
                delete state.AddReimbursementReducer
                return { ReimbursementChangeStatus };
            case ACTION_REIMBURSEMENT_DELETE_SUCCESS:
                ReimbursementDelete = Object.assign({}, state.AddReimbursementReducer.data);
                delete state.AddReimbursementReducer
                return { ReimbursementDelete };
            case ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS:
                GetReimbursementType = Object.assign({}, state.AddReimbursementReducer.data);
                delete state.AddReimbursementReducer
                return { GetReimbursementType };
            case ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS:
                PrintReimbursementRequest = Object.assign({}, state.AddReimbursementReducer.data);
                delete state.AddReimbursementReducer
                return { PrintReimbursementRequest };
            default:
        }
    }

    return {
        ReimbursementRequestView,
        ReimbursementChangeStatus,
        ReimbursementDelete,
        GetReimbursementType,
        PrintReimbursementRequest
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReimbursementView, actionReimbursementChangeStatus, actionReimbursementDelete, actionGetReimbursementType, actionPrintReimbursementRequest }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReimbursementAdd))
