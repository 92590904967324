import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser, FiPlus, FiPhone } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import ReinsuranceNewCustomer from '../../../../../../component/form/reinsurance_new_customer/index'
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import moment from 'moment';

import { UNAUTHORIZED, characterLimit, MEETING_FILE_SIZE, MEETING_FILE_ERROR_MESSAGE, PRIMARY_COLOR, ERROR } from '../../../../../../constants';

import { actionAccompainedBy, ACTION_API_ACCOMPAINED_BY_SUCCESS } from './action';
import { actionAddCountry, ACTION_MASTER_COUNTRY_ADD_SUCCESS } from './action';
import { actionAddClientType, ACTION_CLIENT_TYPE_ADD_SUCCESS } from './action';
import { actionRIGetContacts, ACTION_RI_GET_CONTACTS_SUCCESS } from "./action";
import { actionGetRiName, ACTION_ADD_GET_RI_NAME_SUCCESS } from "./action";
import { actionRIMeetingAddContact, ACTION_RI_MEETING_ADD_CONTACT_SUCCESS } from "./action";
import { actionPreviousMeeting, ACTION_RI_PREVIOUS_MEETING_SUCCESS } from './action';
import { actionReinsuranceClient, ACTION_REINSURANCE_CLIENT_SUCCESS } from "./action";
import { actionRiMeetingAdd, ACTION_API_RI_MEETING_ADD_SUCCESS } from './action';
import { actionRIMeetingNext, ACTION_RI_MEETING_NEXT_SUCCESS } from './action';


import $ from "jquery";

import { checkFileExtension, getAccompainedByIds, PRINT } from '../../../../../../utils';
import Swal from 'sweetalert2';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';

import ContactDetails from '../../../../../../component/form/contact_details';
import { toast } from 'react-toastify';

const RiMeetingAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors }, watch, getValues, setValue } = useForm()

    var meetingAgendaWatcher = watch('agenda', '')
    var meetingDescriptionWatcher = watch('description', '')
    var minuteOfMeetingWatcher = watch('minutes_of_meeting', '')

    const [showNewClientAdd, setShowNewClientAdd,] = useState(false);
    const [newClient, setNewClient,] = useState(false);
    const [accompainedBy, setAccompainedBy] = useState();
    const [country, setCountry] = useState(null);
    const [clientType, setClientType] = useState(null);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [reinsuranceClient, setReinsuranceClient] = useState([]);
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [companyId, setCompanyId] = useState(0)
    const [companyContacts, setCompanyContacts] = useState([]);
    const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([])
    const [riNames, setRiNames] = useState([]);
    const [previousMeeting, setPreviousMeeting] = useState(null);
    const [nextMeeting, setNextMeeting] = useState(false);
    const status = 'close';
    const [existingDate, setExistingDate] = useState(false);
    const [typeOfBusiness, setTypeOfBusiness] = useState(null);

    const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

    const initialRender = () => {
        props.actionAccompainedBy({});
        props.actionAddCountry({});
        props.actionAddClientType({});
    }
    useEffect(initialRender, []);


    useEffect(() => {
        setValue('client_id', companyId)
    }, [companyId])

    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    const onCountryUpdate = () => {

        const { CountryUpdate } = props;
        var CountryUpdateResponse = Object.assign({}, CountryUpdate);
        if (CountryUpdateResponse.result === true) {
            setCountry(CountryUpdateResponse.response);
        } else {
            switch (CountryUpdateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onCountryUpdate, [props.CountryUpdate]);

    const onClientTypeUpdate = () => {

        const { ClientType } = props;
        var ClientTypeUpdateResponse = Object.assign({}, ClientType);
        if (ClientTypeUpdateResponse.result === true) {
            setClientType(ClientTypeUpdateResponse.response);
        } else {
            switch (ClientTypeUpdateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    useEffect(() => {
        if (newClient != null) {
            setTimeout(() => {
                setValue('client_id', newClient.id);
                setValue('country', newClient.country_id);
                setNewClient(null);
            }, 300)
        }
    }, [reinsuranceClient])


    const onReinsuranceAddClientUpdate = () => {
        const { ReinsuranceAddClient } = props
        if (ReinsuranceAddClient != null) {
            var ReinsuranceAddClientResponse = Object.assign({}, ReinsuranceAddClient);
            if (ReinsuranceAddClientResponse.result === true) {
                setReinsuranceClient(ReinsuranceAddClientResponse.response);
            } else {
                switch (ReinsuranceAddClientResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(ReinsuranceAddClientResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onReinsuranceAddClientUpdate, [props.ReinsuranceAddClient])

    const onGetRiNameUpdate = () => {
        const { GetRiName } = props;
        var GetRiNameResponse = Object.assign({}, GetRiName);
        if (GetRiNameResponse.result === true) {
            setRiNames(GetRiNameResponse.response);
        } else {
            switch (GetRiNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetRiNameUpdate, [props.GetRiName]);

    useEffect(() => {
        if (riNames != null && riNames.length > 0) {
            if (companyId > 0) {
                setValue('client_id', companyId)
            }
        }
    }, [riNames])

    const onRIMeetingAddContactUpdate = () => {

        const { RIMeetingAddContact } = props;

        if (RIMeetingAddContact != null) {
            var RIMeetingAddContactResponse = Object.assign({}, RIMeetingAddContact);
            if (RIMeetingAddContactResponse.result === true) {
                var newContact = Object.assign({}, RIMeetingAddContactResponse.response)
                var arrContacts = Object.assign([], companyContacts)
                arrContacts.push(newContact)
                var arrSelectedCompanyContacts = Object.assign([], selectedCompanyContacts)
                arrSelectedCompanyContacts.push(newContact.id)
                setCompanyContacts(arrContacts)
                setSelectedCompanyContacts(arrSelectedCompanyContacts)
            } else {
                switch (RIMeetingAddContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(RIMeetingAddContactResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onRIMeetingAddContactUpdate, [props.RIMeetingAddContact]);

    const onRIGetContactsUpdate = () => {
        const { RIGetContacts } = props;
        if (RIGetContacts != null) {
            var RIGetContactsResponse = Object.assign({}, RIGetContacts)
            if (RIGetContactsResponse.result === true) {
                setCompanyContacts(RIGetContactsResponse.response)
                setSelectedCompanyContacts([]);
            } else {
                switch (RIGetContactsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onRIGetContactsUpdate, [props.RIGetContacts])

    // Call When Select Customer Value Change To Get Previous Meetings **Previous Meeting**
    const onPreviousMeetingUpdate = () => {
        const { PreviousMeeting } = props;
        if (PreviousMeeting != null) {
            var PreviousMeetingResponse = Object.assign({}, PreviousMeeting);
            if (PreviousMeetingResponse.result === true) {
                setPreviousMeeting(PreviousMeetingResponse.response);
            } else {
                setPreviousMeeting([]);
                switch (PreviousMeetingResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onPreviousMeetingUpdate, [props.PreviousMeeting]);


    const onRIMeetingNextUpdate = () => {
        const { RIMeetingNext } = props;
        var RIMeetingNextResponse = Object.assign({}, RIMeetingNext);
        if (RIMeetingNextResponse.result === true) {
            $("#add_customer-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            // setData(KEY_TOAST_MESSAGE, "RI Meeting Saved Successfully");
            toast.success("RI Meeting Saved Successfully");
            props.history.goBack();
        } else {
            switch (RIMeetingNextResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onRIMeetingNextUpdate, [props.RIMeetingNext]);

    const onRiMeetingAddUpdate = () => {
        const { RiMeetingAdd } = props;
        if (RiMeetingAdd != null) {
            var RiMeetingAddResponse = Object.assign({}, RiMeetingAdd);
            $("#add-ri-meeting").prop("disabled", false);
            if (RiMeetingAddResponse.result === true) {
                if (nextMeeting === true) {
                    var data = {
                        meeting_type: 'reinsurance',
                        type_of_business: JSON.stringify(typeOfBusiness),
                        client_id: companyId,
                        prev_meeting: RiMeetingAddResponse.response.id,
                        start_date_time: getValues('next_start_date') + ' ' + getValues('next_from_time'),
                        end_date_time: getValues('next_start_date') + ' ' + getValues('next_to_time'),
                        created_by: props.auth.getUser().id,
                    }
                    props.actionRIMeetingNext(data);

                } else {
                    $("#add-ri-meeting-loader").addClass("hidden");
                    // setData(KEY_TOAST_MESSAGE, "RI Meeting Saved Successfully");
                    toast.success("RI Meeting Saved Successfully");
                    props.history.goBack();
                }

            } else {
                $("#add-ri-meeting-loader").addClass("hidden");
                switch (RiMeetingAddResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(RiMeetingAddResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onRiMeetingAddUpdate, [props.RiMeetingAdd]);

    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (selectedCompanyContacts.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Please select at least one Contact',
                icon: 'error'
            }).then(success => {

            })
        } else {
            if (fromTimeDate >= toTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: 'From Time cannot be greater than or equal to To Time',
                    icon: 'error'
                }).then(success => {

                })
            } else {
                data.created_by = props.auth.getUser().user_id;
                data.start_date_time = data.start_date + ' ' + data.from_time;
                data.end_date_time = data.start_date + ' ' + data.to_time;

                data.accompained_by = JSON.stringify(getAccompainedByIds(data.accompained_by));
                data.contact_ids = JSON.stringify(selectedCompanyContacts);
                data.type_of_business = JSON.stringify(data.type_of_business);
                data.meeting_type = 'reinsurance';
                if (existingDate === true) {
                    data.status = status
                }
                var files = [];
                if (uploadDocument != null) {
                    files.push({ 'name': 'upload_document', 'value': uploadDocument });
                }
                if (data.prev_meeting === null) {
                    delete data.prev_meeting;
                }

                delete data.from_time;
                delete data.to_time;
                delete data.start_date;

                $("#add-ri-meeting-loader").removeClass("hidden");
                $("#add-ri-meeting").prop("disabled", true);
                props.actionRiMeetingAdd(data, files);
            }
        }
    }

    return (
        <>
            <ScreenTitle title="Add RI Meeting" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;RI Meeting Information
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="meeting_type"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                dataSource={
                                                                    [{
                                                                        value: 'existing',
                                                                        title: 'Existing',
                                                                    },
                                                                    {
                                                                        value: 'new',
                                                                        title: 'New',
                                                                    }
                                                                    ]
                                                                }
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Meeting Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    field.onChange(event.itemData.value)
                                                                    // Reset Customer 
                                                                    setValue('client_type', null);
                                                                    setValue('client_id', null);
                                                                    setValue('country', null);
                                                                    setValue('type_of_business', null);
                                                                    setValue('start_date', null);
                                                                    setValue('prev_meeting', null);
                                                                    setValue('agenda', '');
                                                                    setValue('description', '');
                                                                    setCompanyId(0);
                                                                    setExistingDate(false);
                                                                    // EOC : Reset Customer 

                                                                    if (event.itemData.value === "new") {
                                                                        $("#client-field").removeClass("col-12 col-md-6 col-lg-6");
                                                                        $("#client-field").addClass("col-12 col-md-4 col-lg-4");
                                                                        $("#client-new-button").removeClass("hidden");
                                                                    } else {
                                                                        $("#client-field").addClass("col-12 col-md-6 col-lg-6");
                                                                        $("#client-field").removeClass("col-12 col-md-4 col-lg-4");
                                                                        $("#client-new-button").addClass("hidden");
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Meeting Type is required'
                                                        }}

                                                    />
                                                    {errors.meeting_type && <ErrorLabel message={errors.meeting_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6" id="client-field">
                                                <div className="form-group">
                                                    <label className="label-control ">Client Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_type"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={clientType}
                                                                fields={{ text: "title", value: "value" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        props.actionReinsuranceClient({ client_type: event.itemData.value })
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Type is required'
                                                        }}
                                                    />
                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2 col-lg-2 hidden" id="client-new-button">
                                                <div className="form-group">
                                                    <label></label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-block btn-primary shadow round text-uppercase font-small-2"
                                                        onClick={(event) => {
                                                            setShowNewClientAdd(true);
                                                        }}
                                                    > Add New Client</button>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Client&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_id"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={reinsuranceClient}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={true}
                                                                placeholder="Select Client"
                                                                sortOrder={'Ascending'}
                                                                value={field.value}
                                                                change={(event) => {

                                                                    if (event.itemData != null) {

                                                                        var clientIndex = reinsuranceClient.findIndex((client, index) => event.itemData.id === client.id);

                                                                        if (clientIndex > -1) {
                                                                            setValue('country', reinsuranceClient[clientIndex].country_id);
                                                                        }

                                                                        field.onChange(event.itemData.id);
                                                                        props.actionRIGetContacts({ client_id: event.itemData.id });
                                                                        props.actionPreviousMeeting({ client_id: event.itemData.id });
                                                                        setCompanyId(event.itemData.id)
                                                                    }

                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client is required'
                                                        }}
                                                    />
                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Select Country&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="country"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={country}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Country"
                                                                enabled={false}
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Country is required'
                                                        }}
                                                    />
                                                    {errors.country && <ErrorLabel message={errors.country?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Business Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type_of_business"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: [{ id: 'Facultative', name: 'Facultative' }, { id: 'Treaty', name: 'Treaty' }, { id: 'Retro', name: 'Retro' }, { id: 'Others', name: 'Others' }],
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                placeholder="Select Business Type"
                                                                valueExpr="id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={4}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value);
                                                                        setTypeOfBusiness(value);
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Business Type is required'
                                                        }}
                                                    />
                                                    {errors.type_of_business && <ErrorLabel message={errors.type_of_business?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Previous Meeting</label>
                                                    <Controller
                                                        name="prev_meeting"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={previousMeeting}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Previous Meeting"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="start_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                min={moment(new Date()).subtract(60, 'days').calendar()}
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                acceptCustomValue={false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    if (moment(value).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                                                                        setExistingDate(true);
                                                                    } else {
                                                                        setExistingDate(false);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Start Date is required'
                                                        }}
                                                    />
                                                    {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">From Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        defaultValue={moment().format('HH:mm')}
                                                        id="from_time"
                                                        name="from_time"
                                                        placeholder="--:-- --"
                                                        {...register('from_time', { required: 'From Time is required' })}
                                                    />
                                                    {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">To Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        defaultValue={moment().add(1, 'hours').format('HH:mm')}
                                                        id="to_time"
                                                        name="to_time"
                                                        placeholder="--:-- --"
                                                        {...register('to_time', { required: 'To Time is required' })}
                                                    />
                                                    {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                                </div>
                                            </div>


                                            {
                                                companyId > 0 ?
                                                    [<div className="col-12 col-md-12 col-lg-12" key="contact_details">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={() => {
                                                                    setShowContactDetails(true)
                                                                }}
                                                            >
                                                                <FiPlus
                                                                    cursor="pointer"
                                                                    size={20}
                                                                />
                                                            </button>
                                                        </h4>
                                                    </div>,
                                                    <div className="col-12 col-md-12 col-lg-12 mb-1 " key="contact_details_list">
                                                        {
                                                            companyContacts != null && companyContacts.length > 0 ?
                                                                <table className="table table-striped table-bordered ">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th className="px-1">Contact Name</th>
                                                                            <th className="px-1">Contact Designation</th>
                                                                            <th className="px-1">Contact Phone</th>
                                                                            <th className="px-1">Contact Email</th>
                                                                            <th className="px-1">Department</th>
                                                                            <th className="px-1">Phone Number</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            companyContacts.map((contact, indexContact) => (
                                                                                <tr key={contact.id}>
                                                                                    <th>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            id={`${contact.id}`}
                                                                                            name=""
                                                                                            defaultChecked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                            checked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                            onChange={(event) => {
                                                                                                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                                                                                                if (!arrSelectedCompanyContact.includes(contact.id)) {
                                                                                                    arrSelectedCompanyContact.push(contact.id)
                                                                                                    setSelectedCompanyContacts(arrSelectedCompanyContact)
                                                                                                } else {
                                                                                                    var contactIndex = arrSelectedCompanyContact.findIndex((objContact, index) => objContact == contact.id);
                                                                                                    if (contactIndex >= 0) {
                                                                                                        arrSelectedCompanyContact.splice(contactIndex, 1);
                                                                                                        setSelectedCompanyContacts(arrSelectedCompanyContact);
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </th>
                                                                                    <td className="px-1">{contact.contact_name}</td>
                                                                                    <td className="px-1">{contact.contact_designation}</td>
                                                                                    <td className="px-1">{contact.contact_phone}</td>
                                                                                    <td className="px-1">{contact.contact_email}</td>
                                                                                    <td className="px-1">{contact.department}</td>
                                                                                    <td className="px-1">{contact.office_number}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <></>
                                                        }
                                                        <ErrorLabel message={companyId > 0 && companyContacts.length === 0 ? 'No contacts found' : ''} />
                                                    </div>
                                                    ]
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="agenda"
                                                        name="agenda"
                                                        placeholder="Enter Meeting Agenda"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        {...register('agenda', { required: 'Meeting Agenda is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingAgendaWatcher != null ? meetingAgendaWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.agenda && <ErrorLabel message={errors.agenda?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Descrption</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        placeholder="Enter Meeting Descrption"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        {...register('description')}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingDescriptionWatcher != null ? meetingDescriptionWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Accompanied By</label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="accompained_by"
                                                        render={({ field }) => {
                                                            return (
                                                                <Select
                                                                    {...field}
                                                                    placeholder="Select"
                                                                    menuPlacement={"top"}
                                                                    isMulti={true}
                                                                    hideSelectedOptions={false}
                                                                    maxMenuHeight={200}
                                                                    name="name"
                                                                    options={accompainedBy}
                                                                    value={field.value}
                                                                    onChange={(selectedOptions) => {
                                                                        field.onChange(selectedOptions);
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Upload Document</label>
                                                        <div class="custom-file">
                                                            <input type="file"
                                                                class="custom-file-input"
                                                                id="upload_document"
                                                                name="upload_document"
                                                                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx"
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.files
                                                                    ) {

                                                                        var fileSize = event.target.files[0].size;

                                                                        var ext = (event.target.files[0].name).split('.').pop();

                                                                        if (checkFileExtension(ext, validExt)) {

                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                setUploadDocument(event.target.files[0]);
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }
                                                                        } else {

                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'You can only upload jpg, jpeg, png, pdf, doc, docx, xlsx, xls, ppt & pptx files',
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })

                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label class="custom-file-label" for="upload_document">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument.name : "Choose file"
                                                                }
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            {
                                                existingDate === true ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Status</label>
                                                            <Controller
                                                                name="status"
                                                                control={control}

                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={
                                                                            [
                                                                                {
                                                                                    name: 'Close',
                                                                                    id: 'close'
                                                                                }
                                                                            ]
                                                                        }
                                                                        fields={{ text: 'name', value: 'id' }}
                                                                        placeholder="Select status"
                                                                        value={status}
                                                                        enabled={false}
                                                                    />
                                                                }
                                                            />
                                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                existingDate === true && status === 'close' ?
                                                    [<div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Pinc Minutes&nbsp;<span className="danger">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                id="minutes_of_meeting"
                                                                name="minutes_of_meeting"
                                                                placeholder="Enter Pinc Minutes"
                                                                maxLength={characterLimit}
                                                                rows={5}
                                                                {...register('minutes_of_meeting', { required: 'Pinc Minutes is required' })}
                                                            />
                                                            <div className="d-flex flex-row justify-content-between w-100">
                                                                <div className="flex-column"><small>{minuteOfMeetingWatcher != null && minuteOfMeetingWatcher != undefined ? minuteOfMeetingWatcher?.length : 0}/{characterLimit}</small></div>
                                                            </div>
                                                            {errors.minutes_of_meeting && <ErrorLabel message={errors.minutes_of_meeting?.message} />}
                                                        </div>
                                                    </div>,
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Minutes</label>
                                                            <textarea
                                                                className="form-control"
                                                                id="remark"
                                                                name="remark"
                                                                placeholder="Enter Remarks"
                                                                rows={5}
                                                                maxLength={characterLimit}
                                                                {...register('remark')}
                                                            />
                                                            {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                        </div>
                                                    </div>,
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="next_meeting"
                                                                    id="next_meeting"
                                                                    {...register('next_meeting')}
                                                                    onChange={(event) => { setNextMeeting(event.target.checked); }}
                                                                />
                                                                {errors.next_meeting && <ErrorLabel message={errors.next_meeting?.message} />}
                                                                <label class="custom-control-label" for="next_meeting">Create Next Meeting</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ]
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    {
                                        nextMeeting ?
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h6 className="mb-2 font-medium-1 text-bold-600">Next Meeting Details</h6>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="next_start_date"
                                                            className="form-control"
                                                            control={control}

                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    className="form-control"
                                                                    pickerType="calendar"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    min={dateFormat(new Date)}
                                                                    showClearButton={true}
                                                                    acceptCustomValue={false}
                                                                    value={field.value}
                                                                    onValueChange={(value) => {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Next Meeting Start Date is required'
                                                            }}
                                                        />
                                                        {errors.next_start_date && <ErrorLabel message={errors.next_start_date?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="label-control">From Time&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_from_time"
                                                            name="next_from_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_from_time', { required: 'From Time is required' })}
                                                        />
                                                        {errors.next_from_time && <ErrorLabel message={errors.next_from_time?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">To Time&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_to_time"
                                                            name="next_to_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_to_time', { required: 'To Time is required' })}
                                                        />
                                                        {errors.next_to_time && <ErrorLabel message={errors.next_to_time?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="add-ri-meeting"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add-ri-meeting-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        > Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <ReinsuranceNewCustomer
                    show={showNewClientAdd}
                    handleClose={(ri) => {
                        if (ri != null) {
                            setValue('client_type', ri.client_type);
                            // Set Newly Added Client On This Screen
                            setNewClient(ri);
                        }
                        setShowNewClientAdd(false)
                    }}
                />
                <ContactDetails
                    show={showContactDetails}
                    isRi={true}
                    handleClose={(newContact) => {
                        if (newContact != null) {
                            props.actionRIMeetingAddContact({
                                client_id: getValues("client_id"),
                                client_type: "client",
                                contact_designation: newContact.contact_designation,
                                contact_email: newContact.contact_email,
                                contact_name: newContact.contact_name,
                                contact_phone: newContact.contact_phone,
                                department: newContact.department,
                                office_number: newContact.office_number,
                            })
                        }
                        setShowContactDetails(false)
                    }}
                />
            </section>
        </>
    )
}

const mapStateToProps = (state) => {

    var AccompainedBy = null;
    var RiMeetingAdd = null
    var CountryUpdate = null;
    var ClientType = null;
    var ReinsuranceAddClient = null;
    var RIGetContacts = null;
    var GetRiName = null;
    var RIMeetingAddContact = null;
    var PreviousMeeting = null;
    var RIMeetingNext = null;

    if (
        state.AddRiMeetingReducer != null &&
        Object.keys(state.AddRiMeetingReducer).length > 0 &&
        state.AddRiMeetingReducer.constructor === Object
    ) {
        switch (state.AddRiMeetingReducer.tag) {
            case ACTION_API_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { AccompainedBy };
            case ACTION_API_RI_MEETING_ADD_SUCCESS:
                RiMeetingAdd = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { RiMeetingAdd };
            case ACTION_MASTER_COUNTRY_ADD_SUCCESS:
                CountryUpdate = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { CountryUpdate };
            case ACTION_CLIENT_TYPE_ADD_SUCCESS:
                ClientType = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { ClientType };
            case ACTION_REINSURANCE_CLIENT_SUCCESS:
                ReinsuranceAddClient = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { ReinsuranceAddClient };
            case ACTION_RI_GET_CONTACTS_SUCCESS:
                RIGetContacts = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { RIGetContacts };
            case ACTION_ADD_GET_RI_NAME_SUCCESS:
                GetRiName = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { GetRiName };
            case ACTION_RI_MEETING_ADD_CONTACT_SUCCESS:
                RIMeetingAddContact = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { RIMeetingAddContact };
            case ACTION_RI_PREVIOUS_MEETING_SUCCESS:
                PreviousMeeting = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { PreviousMeeting };
            case ACTION_RI_MEETING_NEXT_SUCCESS:
                RIMeetingNext = Object.assign({}, state.AddRiMeetingReducer.data);
                delete state.AddRiMeetingReducer;
                return { RIMeetingNext };
            default:
        }
    }
    return {
        AccompainedBy,
        RiMeetingAdd,
        CountryUpdate,
        ClientType,
        ReinsuranceAddClient,
        RIGetContacts,
        GetRiName,
        RIMeetingAddContact,
        PreviousMeeting,
        RIMeetingNext
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionAccompainedBy,
        actionRiMeetingAdd,
        actionReinsuranceClient,
        actionAddClientType,
        actionAddCountry,
        actionRIGetContacts,
        actionGetRiName,
        actionRIMeetingAddContact,
        actionPreviousMeeting,
        actionRIMeetingNext
    }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiMeetingAdd))