import { ACTION_TRANSPORT_EDIT_SUCCESS } from "./action";
import { ACTION_TRANSPORT_VIEW_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditTransportReducer = createReducer(INITIAL_STATE, {
    [ACTION_TRANSPORT_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TRANSPORT_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TRANSPORT_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TRANSPORT_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
});

export default EditTransportReducer;    