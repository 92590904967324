import { ACTION_REIMBURSEMENT_VIEW_SUCCESS, ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS, ACTION_REIMBURSEMENT_DELETE_SUCCESS, ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS, ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS } from './action';

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddReimbursementReducer = createReducer(INITIAL_STATE, {

    [ACTION_REIMBURSEMENT_VIEW_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_VIEW_SUCCESS,
            loading: false
        });
    },
    [ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS,
            loading: false
        });
    },
    [ACTION_REIMBURSEMENT_DELETE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_DELETE_SUCCESS,
            loading: false
        });
    },
    [ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS,
            loading: false
        });
    },
    [ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS,
            loading: false
        });
    }
});

export default AddReimbursementReducer;
