import React, { useEffect, useState } from "react"
import Chart from "react-apexcharts";

const Bar = (props) => {

    const [details, setDetails] = useState(null);

    const initialRender = () => {
        if (props != null) {
            setDetails(props.details)
        }
    }
    useEffect(initialRender, []);

    return (
        <div>
            {
                props.details != null ?
                    <div class="row bg-primary-shadow rounded bg-accent-2  mx-half">
                        {
                            Number(props.details.brokerage) + Number(props.details.net_premium) !== 0 ?
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="card">
                                        <div class="card-content">
                                            <div class="top-row statistics-card-title">
                                                <div class="statistics-chart d-flex justify-content-center align-self-center">
                                                    <Chart
                                                        type="donut"
                                                        height={350}
                                                        width={550}
                                                        options={{
                                                            dataLabels: {
                                                                enabled: true,
                                                                style: {
                                                                    fontSize: "10px",
                                                                    fontWeight: 400,
                                                                },
                                                                textAnchor: "middle",
                                                            },

                                                            plotOptions: {
                                                                pie: {
                                                                    expandOnClick: true,
                                                                    customScale: 1,
                                                                    donut: {
                                                                        size: "60%",
                                                                    },
                                                                },
                                                            },
                                                            legend: {
                                                                fontSize: "10px",
                                                                fontWeight: 600,
                                                                position: "bottom",
                                                                markers: {
                                                                    width: 5,
                                                                    height: 5,
                                                                    radius: 5,
                                                                },
                                                                itemMargin: {
                                                                    vertical: 0,
                                                                },
                                                            },
                                                            labels: [
                                                                "Brokerage",
                                                                "Premium",
                                                            ],
                                                        }}
                                                        series={[
                                                            props.details.brokerage_amount,
                                                            props.details.net_premium_amount,
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div class="col-12 col-md-6 col-lg-6">
                            <div class="card">
                                <div class="card-content">
                                    <div class="top-row statistics-card-title">
                                        <div class="statistics-chart d-flex justify-content-center align-self-center">
                                            <Chart
                                                type="donut"
                                                height={350}
                                                width={550}
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "60%",
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "10px",
                                                        fontWeight: 600,
                                                        position: "bottom",
                                                        markers: {
                                                            width: 5,
                                                            height: 5,
                                                            radius: 5,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: [
                                                        "Total Clients",
                                                        "Total Leads",
                                                        "Closed Client Meeting",
                                                        "Closed Lead Meeting",
                                                        "Closed Insurance Meeting",
                                                    ],
                                                }}
                                                series={[
                                                    props.details.client_count,
                                                    props.details.lead_count,
                                                    props.details.closed_client_meetings,
                                                    props.details.closed_lead_meetings,
                                                    props.details.closed_insurance_meetings,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

export default Bar
