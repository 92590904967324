import { ACTION_INSURER_VIEW_TAB_BUSINESS_SUMMARY_SUCCESS } from './action';
import createReducer from '../../../../../reducers/createReducer';
    
const INITIAL_STATE = {
    data: [],
    loading: false
};
    
const InsurerBusinessSummaryReducer = createReducer(INITIAL_STATE,{
    [ACTION_INSURER_VIEW_TAB_BUSINESS_SUMMARY_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_INSURER_VIEW_TAB_BUSINESS_SUMMARY_SUCCESS,
            loading: false
        });
    },    
});
export default InsurerBusinessSummaryReducer;
    