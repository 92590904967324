import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionTabLeadMeetingView, ACTION_TABLEADMEETINGVIEW_SUCCESS } from './action';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { toast } from 'react-toastify';
import $ from "jquery";
import CustomerMeetingsView from '../../../../../../../component/popups/meetings/customer';
import { withAuth } from 'react-auth-guard';

const TabLeadMeetingView = (props) => {

    const [clientMeetingData, setClientMeetingData] = useState([]);
    const [showCustomerMeetingView, setShowCustomerMeetingView] = useState(false);
    const [customerMeetingId, setCustomerMeetingId] = useState(null);
    const [meetingColumn, setMeetingColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "created_by_name", caption: "Created By" },
        { dataField: "meeting_date", caption: "Meeting Date" },
        { dataField: "business_type", caption: "Meeting Type" },
        { dataField: "location", caption: "Location" },
        { dataField: "status", caption: "Status" },
    ]);

    const initialRender = () => {
        props.actionTabLeadMeetingView({ client_id: props.clientId })
    }

    useEffect(initialRender, []);


    const onTabLeadMeetingViewUpdate = () => {

        const { TabLeadMeetingView } = props;

        if (TabLeadMeetingView != null) {
            var TabLeadMeetingViewResponse = Object.assign({}, TabLeadMeetingView);
            if (TabLeadMeetingViewResponse.result === true) {

                $("#progress-loader").addClass("hidden");
                setMeetingColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "created_by_name", caption: "Created By" },
                    { dataField: "meeting_date", caption: "Meeting Date" },
                    { dataField: "business_type", caption: "Meeting Type" },
                    { dataField: "location", caption: "Location" },
                    { dataField: "status", caption: "Status" },
                ])
                setClientMeetingData(TabLeadMeetingViewResponse.response);
            } else {
                setClientMeetingData([])
                $("#progress-loader").addClass("hidden");
                switch (TabLeadMeetingViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                }
            }
        }
    }


    useEffect(onTabLeadMeetingViewUpdate, [props.TabLeadMeetingView]);
    /** Setup The Status Column */
    const renderStatusCell = (cell) => {
        console.log(cell);
        switch (cell.data.status) {
            case 'Open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>Open</div>;
            case 'Close':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>Close</div>;
            case 'Cancel':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>Cancel</div>;
            default:
                return <></>
        }
    }
    /** EOC : Setup The Status Column */
    const renderMeetingColumn = () => {
        var arrColumns = [];
        meetingColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1"
                                    onClick={() => {
                                        setShowCustomerMeetingView(true)
                                        setCustomerMeetingId(params.data.meeting_id);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };



    const renderMeetingTableView = () => {
        return (
            <DataGrid
                dataSource={clientMeetingData}
                columnHidingEnabled={false}
                showBorders={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderMeetingColumn()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />

            </DataGrid>
        )
    }
    /** EOC : Meeting Module Render */

    return (
        <>
            <div className="tab-pane" id="meetings" aria-labelledby="meetings-tab" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    clientMeetingData != null && clientMeetingData.length > 0 ?
                                        renderMeetingTableView() :
                                        <h6 className="text-bold-500 text-center">No Meetings Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomerMeetingsView
                show={showCustomerMeetingView}
                customerMeetingId={customerMeetingId}
                handleClose={() => {
                    setShowCustomerMeetingView(false);
                }}
            />
        </>
    );

}

function mapStateToProps(state) {

    var TabLeadMeetingView = null;

    if (
        state.TabLeadMeetingViewReducer != null &&
        Object.keys(state.TabLeadMeetingViewReducer).length > 0 &&
        state.TabLeadMeetingViewReducer.constructor === Object
    ) {
        switch (state.TabLeadMeetingViewReducer.tag) {

            case ACTION_TABLEADMEETINGVIEW_SUCCESS:
                TabLeadMeetingView = Object.assign({}, state.TabLeadMeetingViewReducer.data);
                delete state.TabLeadMeetingViewReducer;
                return { TabLeadMeetingView };
            default:
                return { TabLeadMeetingView }
        }
    }
    return { TabLeadMeetingView }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionTabLeadMeetingView }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TabLeadMeetingView));