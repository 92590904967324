import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_DECLARATION_SUBMIT } from '../../../../../../api/constants';

export const ACTION_IT_DECLARATION_SUBMIT_LOADING = 'ACTION_IT_DECLARATION_SUBMIT_LOADING';
export const ACTION_IT_DECLARATION_SUBMIT_SUCCESS = 'ACTION_IT_DECLARATION_SUBMIT_SUCCESS';

export function actionItDeclarationSubmit(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_SUBMIT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_SUBMIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_SUBMIT_LOADING, { loading: false, data }));
        });
}