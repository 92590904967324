
import createReducer from "../../../../../reducers/createReducer";
import { ACTION_GET_GENERAL_DEPARTMENT_SUCCESS, ACTION_GET_GENERAL_INSURER_SUCCESS, ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS, ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS } from "./action";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const MasterGetGeneralPreferenceReducer = createReducer(INITIAL_STATE, {
    [ACTION_GET_GENERAL_DEPARTMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_GENERAL_DEPARTMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_GENERAL_INSURER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_GENERAL_INSURER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS,
                loading: false,
            }
        );
    },

});
export default MasterGetGeneralPreferenceReducer;