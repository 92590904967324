import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_GENERAL_PREFERENCE_LIST } from '../../../../../../api/constants';
import { API_GENERAL_PREFERENCE_DELETE } from '../../../../../../api/constants';

export const ACTION_GENERAL_PREFERENCE_LIST_LOADING = 'ACTION_GENERAL_PREFERENCE_LIST_LOADING';
export const ACTION_GENERAL_PREFERENCE_LIST_SUCCESS = 'ACTION_GENERAL_PREFERENCE_LIST_SUCCESS';

export const ACTION_GENERAL_PREFERENCE_DELETE_LOADING = 'ACTION_GENERAL_PREFERENCE_DELETE_LOADING';
export const ACTION_GENERAL_PREFERENCE_DELETE_SUCCESS = 'ACTION_GENERAL_PREFERENCE_DELETE_SUCCESS';

export function actionGeneralPlacementList() {
    return (dispatch, getState) =>
        Api.post(API_GENERAL_PREFERENCE_LIST).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GENERAL_PREFERENCE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GENERAL_PREFERENCE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionGeneralPlacementDelete(params) {
    return (dispatch, getState) =>
        Api.post(API_GENERAL_PREFERENCE_DELETE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GENERAL_PREFERENCE_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GENERAL_PREFERENCE_DELETE_LOADING, { loading: false, data }));
        });
}