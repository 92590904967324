import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_GETMEETINGLIST } from '../../../../../../api/constants';
import { API_MY_TEAM_MEETING, API_MYTEAMVERTICAL } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const GETMEETINGSLIST_LOADING = 'GETMEETINGSLIST_LOADING';
export const ACTION_GETMEETINGSLIST_SUCCESS = 'ACTION_GETMEETINGSLIST_SUCCESS';

export const ACTION_MY_TEAM_MEETING_LOADING = 'ACTION_MY_TEAM_MEETING_LOADING';
export const ACTION_MY_TEAM_MEETING_SUCCESS = 'ACTION_MY_TEAM_MEETING_SUCCESS';

export const ACTION_PROFILE_TEAM_MEETING_LOADING = 'ACTION_PROFILE_TEAM_MEETING_LOADING';
export const ACTION_PROFILE_TEAM_MEETING_SUCCESS = 'ACTION_PROFILE_TEAM_MEETING_SUCCESS';

export function actiongetMeetings(params) {
    return (dispatch, getState) => Api.post(API_GETMEETINGLIST, params).then((res) => {
        const data = res;
        PRINT('ACTION_GETMEETINGSLIST_SUCCESS : ', data);
        dispatch(fetchSuccess(ACTION_GETMEETINGSLIST_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(GETMEETINGSLIST_LOADING, { loading: false, data }));
    });
}

export function actionMyTeamMeeting(params) {
    return (dispatch, getState) =>
        Api.post(API_MY_TEAM_MEETING, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MY_TEAM_MEETING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MY_TEAM_MEETING_LOADING, { loading: false, data }));
        });
}

export function actionProfileTeamMeetingMember(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROFILE_TEAM_MEETING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROFILE_TEAM_MEETING_LOADING, { loading: false, data }));
        });
}
