import { API_ACCOMPAINED_BY, API_INTERNAL_MEETING_ADD } from '../../../../../../api/constants';

import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_ACCOMPAINED_BY_LOADING = 'ACTION_ACCOMPAINED_BY_LOADING';
export const ACTION_ACCOMPAINED_BY_SUCCESS = 'ACTION_ACCOMPAINED_BY_SUCCESS';

export const ACTION_INTERNAL_MEETING_SAVE_LOADING = 'ACTION_INTERNAL_MEETING_SAVE_LOADING';
export const ACTION_INTERNAL_MEETING_SAVE_SUCCESS = 'ACTION_INTERNAL_MEETING_SAVE_SUCCESS';

export function actionAccompainedBy() {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionInternalMeetingSave(params) {
    return (dispatch, getState) =>
        Api.post(API_INTERNAL_MEETING_ADD,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_INTERNAL_MEETING_SAVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INTERNAL_MEETING_SAVE_LOADING, { loading: false, data }));
        });
}