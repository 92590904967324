import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScreenTitle from "../../../../../component/screen_title";
import { FiUser } from 'react-icons/fi';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';
import { actionMasterEntity, actionProposalPolicyType, ACTION_API_MASTER_ENTITY_SUCCESS, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from './action';
import { actionEmployeeAdd, ACTION_EMPLOYEE_ADD_SUCCESS } from './action';
import { actionMasterSubEntity, ACTION_API_MASTER_SUB_ENTITY_SUCCESS } from './action';
import { actionMasterBranchCity, ACTION_API_MASTER_BRANCH_CITY_SUCCESS } from './action';
import { actionMasterDepartment, ACTION_API_MASTER_DEPARTMENT_SUCCESS } from './action';
import { actionMasterGrade, ACTION_API_MASTER_GRADE_SUCCESS } from './action';
import { actionMasterBranchAddress, ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS } from './action';
import { actionMasterGetReporting, ACTION_API_MASTER_GET_REPORTING_SUCCESS } from './action';
import { actionMasterDesignation, ACTION_API_MASTER_DESIGNATION_SUCCESS } from './action';
import { actionMasterJobRole, ACTION_API_MASTER_JOB_ROLE_SUCCESS } from './action';
import { actionMasterTeamHead, ACTION_API_MASTER_TEAM_HEAD_SUCCESS } from './action';
import { actionMasterApprovalManager, ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS } from './action';
import { actionGetRolesDropdownList, ACTION_GETROLESDROPDOWNLIST_SUCCESS } from './action';
import { actionGetEmployeeShifts, EMPLOYEE_SHIFT_TIMINGS_SUCCESS } from './action';
import { actionGetReimbursementFinanceApprovalManager, ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS } from './action';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED, VALIDATION_ERROR } from '../../../../../constants';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { checkFileExtension } from '../../../../../utils';
import moment from 'moment';

const Registration = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues, watch } = useForm({
        defaultValues: {
        }
    });

    const [masterEntity, setMasterEntity] = useState([]);
    const [masterPayrollEntity, setMasterPayrollEntity] = useState({});
    const [masterSubEntity, setMasterSubEntity] = useState([]);
    const [selectedMasterSubEntity, setSelectedMasterSubEntity] = useState([]);
    const [masterBranchCity, setMasterBranchCity] = useState([]);
    const [masterDepartment, setMasterDepartment] = useState([]);
    const [masterGrade, setMasterGrade] = useState({})
    const [masterBranchAddress, setMasterBranchAddress] = useState([]);
    const [masterGetReporting, setMasterGetReporting] = useState({});
    const [masterDesignation, setMasterDesignation] = useState({});
    const [masterJobRole, setMasterJobRole] = useState({});
    const [masterTeamHead, setMasterTeamHead] = useState([]);
    const [masterApprovalManager, setMasterApprovalManager] = useState([]);
    const [masterAssetApprovalManager, setMasterAssetApprovalManager] = useState([]);
    const [masterFinanceApprovalManager, setMasterFinanceApprovalManager] = useState([]);
    const [masterTravelApprovalManager, setMasterTravelApprovalManager] = useState([]);
    const [masterAttendanceApprovalManager, setMasterAttendanceApprovalManager] = useState([]);
    const [masterMeetingApprovalManager, setMasterMeetingApprovalManager] = useState([]);
    const [masterPerformanceObjectiveApprovalManager, setMasterPerformanceObjectiveApprovalManager] = useState([]);
    const [roleListDropDown, setRoleListDropDown] = useState([]);
    const [employeeShifts, setEmployeeShifts] = useState([]);
    const [masterreimburstmentapprovalmanager, setMasterReimburstmentApprovalmanager] = useState([]);
    const [masterfinancereimburstmentapprovalmanager, setMasterfinanceReimburstmentApprovalManager] = useState([]);
    var watchVisitingCardFront = watch('visiting_card_front', 'Choose file');
    const [proposalPolicy, setProposalPolicy] = useState(null);
    const [visitingCardFront, setVisitingCardFront] = useState(null);
    const [uploadCertificateCopy, setUploadCertificateCopy] = useState(null);
    const [visitingCardBack, setVisitingCardBack] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [clientVisibleImage, setClientVisibleImage] = useState(null);
    const showWorkingDays = 5;
    const validExt = ['jpg', 'jpeg', 'png'];
    const [isProduct, setIsProduct] = useState(false);
    const [productSpecialist, setProductSpecialist] = useState(null);
    const [bqpCertified, setBQPCertified] = useState(null);
    const [nocValue, setNOCValue] = useState(null);
    const [brokeringEntity, setBrokeringEntity] = useState(null);
    const [functions, setFunctions] = useState([]);
    const [bqpStatus, setBqpStatus] = useState(null);
    const [uploadNocDocument, setUploadNocDocument] = useState(null);

    const initialRender = () => {

        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
        props.actionProposalPolicyType();
        props.actionMasterEntity();
        props.actionMasterDepartment();
        props.actionMasterGrade();
        props.actionMasterTeamHead();
        props.actionGetRolesDropdownList();
        props.actionMasterDesignation();
        props.actionGetEmployeeShifts();
        props.actionGetReimbursementFinanceApprovalManager();
    }
    useEffect(initialRender, [props.show]);

    const onProposalPolicyTypeUpdate = () => {
        const { ProposalPolicyType } = props;
        if (ProposalPolicyType != null) {
            var ProposalPolicyTypeResponse = Object.assign({}, ProposalPolicyType);
            if (ProposalPolicyTypeResponse.result === true) {
                setProposalPolicy(ProposalPolicyTypeResponse.response);
                // props.actionProbability();
            } else {
                switch (ProposalPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onProposalPolicyTypeUpdate, [props.ProposalPolicyType]);

    const onMasterEntityUpdate = () => {
        const { Entity } = props;
        var EntityResponse = Object.assign({}, Entity);
        if (EntityResponse.result === true) {
            setMasterEntity(EntityResponse.response);
            setMasterPayrollEntity(EntityResponse.response);
        } else {
            switch (EntityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterEntityUpdate, [props.Entity]);

    const onMasterSubEntityUpdate = () => {
        const { SubEntity } = props;
        var SubEntityResponse = Object.assign({}, SubEntity);
        if (SubEntityResponse.result === true) {
            setMasterSubEntity(SubEntityResponse.response);
        } else {
            switch (SubEntityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterSubEntityUpdate, [props.SubEntity]);

    const onMasterBranchCityUpdate = () => {
        const { BranchCity } = props;
        var BranchCityResponse = Object.assign({}, BranchCity);
        if (BranchCityResponse.result === true) {
            setMasterBranchCity(BranchCityResponse.response);
        } else {
            switch (BranchCityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterBranchCityUpdate, [props.BranchCity]);

    const onMasterBranchAddressUpdate = () => {
        const { BranchAddress } = props;
        var BranchAddressResponse = Object.assign({}, BranchAddress);
        if (BranchAddressResponse.result === true) {
            setMasterBranchAddress(BranchAddressResponse.response);
        } else {
            switch (BranchAddressResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterBranchAddressUpdate, [props.BranchAddress]);

    const onMasterDepartmentUpdate = () => {
        const { Department } = props;
        var DepartmentResponse = Object.assign({}, Department);
        if (DepartmentResponse.result === true) {
            setMasterDepartment(DepartmentResponse.response);
        } else {
            switch (DepartmentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterDepartmentUpdate, [props.Department]);

    const onMasterGradeUpdate = () => {
        const { Grade } = props;
        var GradeResponse = Object.assign({}, Grade);
        if (GradeResponse.result === true) {
            setMasterGrade(GradeResponse.response);
        } else {
            switch (GradeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterGradeUpdate, [props.Grade]);

    const onMasterGetReportingUpdate = () => {
        const { GetReporting } = props;
        var GetReportingResponse = Object.assign({}, GetReporting);
        if (GetReportingResponse.result === true) {
            setMasterGetReporting(GetReportingResponse.response);
        } else {
            switch (GetReportingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterGetReportingUpdate, [props.GetReporting]);

    const onMasterDesignationUpdate = () => {
        const { Designation } = props;
        var DesignationResponse = Object.assign({}, Designation);
        if (DesignationResponse.result === true) {
            setMasterDesignation(DesignationResponse.response);
        } else {
            switch (DesignationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterDesignationUpdate, [props.Designation]);

    const onMasterJobRoleUpdate = () => {
        const { JobRole } = props;
        var JobRoleResponse = Object.assign({}, JobRole);
        if (JobRoleResponse.result === true) {
            setMasterJobRole(JobRoleResponse.response);
        } else {
            switch (JobRoleResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterJobRoleUpdate, [props.JobRole]);

    const onMasterTeamHeadUpdate = () => {
        const { TeamHead } = props;
        var TeamHeadResponse = Object.assign({}, TeamHead);
        if (TeamHeadResponse.result === true) {
            setMasterTeamHead(TeamHeadResponse.response);
        } else {
            switch (TeamHeadResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }

    useEffect(onMasterTeamHeadUpdate, [props.TeamHead]);

    const ongetEmployeeShiftUpdate = () => {

        const { EmployeeShift } = props;

        if (EmployeeShift != null) {
            var EmployeeShiftResponse = Object.assign({}, EmployeeShift);
            if (EmployeeShiftResponse.result === true) {
                setEmployeeShifts(EmployeeShiftResponse.response);
            } else {
                switch (EmployeeShiftResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(ongetEmployeeShiftUpdate, [props.EmployeeShift]);

    useEffect(() => {
        if (bqpCertified != null) {
            setValue('brokering_entity', null);
            setValue('certificate_number', null);
            setValue('start_date', null);
            setValue('end_date', null);
            setBrokeringEntity(null);
            setNOCValue(null);
        }
    }, [bqpCertified])

    const ongetRolesDropdownListUpdate = () => {

        const { getRolesDropdownList } = props;

        if (getRolesDropdownList != null) {
            var getRolesDropdownListResponse = Object.assign({}, getRolesDropdownList);
            if (getRolesDropdownListResponse.result === true) {
                setRoleListDropDown(getRolesDropdownListResponse.response)
            } else {
                switch (getRolesDropdownListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }


    useEffect(ongetRolesDropdownListUpdate, [props.getRolesDropdownList]);

    const onMasterApprovalManagerUpdate = () => {
        const { ApprovalManager } = props;
        var ApprovalManagerResponse = Object.assign({}, ApprovalManager);
        if (ApprovalManagerResponse.result === true) {
            setMasterApprovalManager(ApprovalManagerResponse.response);
            setMasterAssetApprovalManager(ApprovalManagerResponse.response);
            setMasterFinanceApprovalManager(ApprovalManagerResponse.response);
            setMasterTravelApprovalManager(ApprovalManagerResponse.response);
            setMasterAttendanceApprovalManager(ApprovalManagerResponse.response);
            setMasterMeetingApprovalManager(ApprovalManagerResponse.response);
            setMasterPerformanceObjectiveApprovalManager(ApprovalManagerResponse.response);
            setMasterReimburstmentApprovalmanager(ApprovalManagerResponse.response);
        } else {
            switch (ApprovalManagerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterApprovalManagerUpdate, [props.ApprovalManager]);

    const onGetReimbursementFinanceApprovalManagerUpdate = () => {
        const { GetReimbursementFinanceApprovalManager } = props;
        if (GetReimbursementFinanceApprovalManager != null) {
            var GetReimbursementFinanceApprovalManagerResponse = Object.assign({}, GetReimbursementFinanceApprovalManager);
            if (GetReimbursementFinanceApprovalManagerResponse.result === true) {
                setMasterfinanceReimburstmentApprovalManager(GetReimbursementFinanceApprovalManagerResponse.response);
            } else {
                switch (GetReimbursementFinanceApprovalManagerResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGetReimbursementFinanceApprovalManagerUpdate, [props.GetReimbursementFinanceApprovalManager]);

    const onEmployeeAddUpdate = () => {
        const { Employee } = props;
        var EmployeeResponse = Object.assign({}, Employee);
        $("#add_employee").prop("disabled", false);
        if (EmployeeResponse.result === true) {
            $("#add_employee-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Employee Added Successfully");
            props.history.push('/hrms');
        } else {
            // $("#progress-loader").addClass("hidden");
            $("#add_employee-loader").addClass("hidden");
            $("#add_employee").prop("disabled", false);
            switch (EmployeeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case VALIDATION_ERROR:
                    var strMessage = '';

                    if (EmployeeResponse.message.hasOwnProperty('pinc_employee_id')) {
                        strMessage += EmployeeResponse.message.pinc_employee_id;
                    }
                    if (EmployeeResponse.message.hasOwnProperty('email')) {
                        strMessage += "<br>" + EmployeeResponse.message.email;
                    }

                    if (strMessage.length > 0) {
                        Swal.fire({
                            title: 'Error',
                            icon: 'error',
                            html: strMessage,
                            confirmButtonColor: PRIMARY_COLOR,

                        }).then((result) => {

                        });
                    }

                    break;
                default:
            }
        }
    }
    useEffect(onEmployeeAddUpdate, [props.Employee]);


    const returnEntity = () => {
        var arrPrimary = [];
        var arrCompany = Object.assign([], getValues('companies'));
        arrCompany.map((item) => {
            var obj = masterEntity.find(obj => obj.id === item)
            arrPrimary.push(obj);
        })
        return arrPrimary;
    }

    const returnDepartment = () => {
        var arrPrimary = [];
        var arrSubCompany = Object.assign([], getValues('sub_companies'));
        arrSubCompany.map((item) => {
            var obj = masterSubEntity.find(obj => obj.id === item)
            arrPrimary.push(obj);
        })
        return arrPrimary;
    }

    const onSubmit = (data) => {
        var files = [];

        if (visitingCardFront != null) {
            files.push({ 'name': 'visiting_card_front', 'value': visitingCardFront });
        }

        if (visitingCardBack != null) {
            files.push({ 'name': 'visiting_card_back', 'value': visitingCardBack });
        }
        if (profilePhoto != null) {
            files.push({ 'name': 'profile_photo', 'value': profilePhoto });
        }
        if (clientVisibleImage != null) {
            files.push({ 'name': 'client_visible_image', 'value': clientVisibleImage });
        }
        if (data.product_ids === null || data.product_ids === 'undefined' || data.product_ids === undefined) {
            data.product_ids = [];
        }
        data.request_view = data.request_view === true ? 1 : 0;
        data.request_approval = data.request_approval === true ? 1 : 0;
        data.task_operation = data.task_operation === true ? 1 : 0;
        data.is_on_probation = data.is_on_probation === true ? 1 : 0;
        data.is_product_specialist = isProduct === true ? 1 : 0;
        data.auto_attendance = data.auto_attendance === true ? 1 : 0;
        data.total_leaves = Number(data.total_leaves)
        data.companies = JSON.stringify(data.companies);
        data.product_ids = JSON.stringify(data.product_ids);
        data.sub_companies = JSON.stringify(data.sub_companies);
        data.department = JSON.stringify(data.department);
        data.reimursment_primary_entity_id = JSON.stringify(data.reimursment_primary_entity_id);
        data.reimursment_primary_department_id = JSON.stringify(data.reimursment_primary_department_id);
        data.bqp_status = data.bqp_status != null ? data.bqp_status : '';
        data.bqp_certified = data.bqp_certified != null ? data.bqp_certified : '';
        data.certificate_number = data.certificate_number != null ? data.certificate_number : '';
        data.start_date = data.start_date != null ? data.start_date : '';
        data.end_date = data.end_date != null ? data.end_date : '';
        data.brokering_entity = data.brokering_entity != null ? data.brokering_entity : '';
        data.noc_type = data.noc_type != null ? data.noc_type : '';

        /**
         * API : /api/employee/add
         * METHOD : POST 
         * Description : Add Employee Record 
         * INPUT : Employee Object 
         */
        // $("#progress-loader").removeClass("hidden");
        if (!(functions?.length > 0 && (functions.includes(9) || functions.includes(7) || functions.includes(8) || functions.includes(13) || functions.includes(16)))) {
            $("#add_employee-loader").removeClass("hidden");
            $("#add_employee").prop("disabled", true);
            props.actionEmployeeAdd(data);
        }else if (uploadNocDocument != null && uploadCertificateCopy != null && (data.bqp_certified === 'Valid' || data.bqp_certified === 'Expired') && nocValue === 'NOC Received') {
            files.push({ 'name': 'noc_document', 'value': uploadNocDocument });
            files.push({ 'name': 'bqp_certificate', 'value': uploadCertificateCopy });
            $("#add_employee-loader").removeClass("hidden");
            $("#add_employee").prop("disabled", true);
            props.actionEmployeeAdd(data, files);
        } else if (uploadCertificateCopy != null && (data.bqp_certified === 'Valid' || data.bqp_certified === 'Expired')) {
            files.push({ 'name': 'bqp_certificate', 'value': uploadCertificateCopy });
            $("#add_employee-loader").removeClass("hidden");
            $("#add_employee").prop("disabled", true);
            props.actionEmployeeAdd(data, files);
        } else if (data.bqp_certified === 'Not Available' || data.bqp_certified === 'Not Applicable') {
            $("#add_employee-loader").removeClass("hidden");
            $("#add_employee").prop("disabled", true);
            props.actionEmployeeAdd(data, files);
        }
        else if (data.bqp_status === 'training') {
            $("#add_employee-loader").removeClass("hidden");
            $("#add_employee").prop("disabled", true);
            props.actionEmployeeAdd(data);
        } else {
            $('#error-bqp-certificate-not-found').removeClass('d-none')
            $('#error-noc-document-not-found').removeClass('d-none')
        }
        delete data.bqp_certificate;
        delete data.noc_document;
    }

    return (
        <>
            <ScreenTitle title="Employee Registration" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">First Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="first_name"
                                                        name="first_name"
                                                        placeholder="Employee First Name"
                                                        {...register('first_name', { required: 'First Name is required' })}
                                                    />
                                                    {errors.first_name && <ErrorLabel message={errors.first_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Last Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="last_name"
                                                        name="last_name"
                                                        placeholder="Employee Last Name"
                                                        {...register('last_name', { required: 'Last Name is required' })}
                                                    />
                                                    {errors.last_name && <ErrorLabel message={errors.last_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Pinc Employee Id&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="pinc_employee_id"
                                                        name="pinc_employee_id"
                                                        placeholder="Employee Id"
                                                        {...register('pinc_employee_id', { required: 'Employee Id is required' })}
                                                    />
                                                    {errors.pinc_employee_id && <ErrorLabel message={errors.pinc_employee_id?.message} />}
                                                </div>
                                                <div class="form-check cust-form-check mt-0 mb-1">
                                                    <input
                                                        name="is_on_probation"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="is_on_probation"
                                                        {...register("is_on_probation")}
                                                    />
                                                    <label class="form-check-label my-auto" for="is_on_probation">
                                                        <span className="cust-fs-12">Probation Applicable</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Email Id&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Employee Email Id"
                                                        {...register('email', {
                                                            required: 'Email Id is required',
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                message: 'Please enter valid Email Id'
                                                            },
                                                        })}
                                                    />
                                                    {errors.email && <ErrorLabel message={errors.email?.message} />}
                                                </div>
                                                <div class="form-check cust-form-check mt-0 mb-1">
                                                    <input
                                                        name="auto_attendance"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="auto_attendance"
                                                        {...register("auto_attendance")}
                                                    />
                                                    <label class="form-check-label my-auto" for="auto_attendance">
                                                        <span className="cust-fs-12">Auto Attendance</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Employee Mobile Number&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="mobile_no"
                                                        name="mobile_no"
                                                        maxLength={10}
                                                        placeholder="Employee Mobile Number"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();

                                                            }
                                                        }}
                                                        {...register('mobile_no', {
                                                            required: 'Employee Mobile Number is required',
                                                            pattern: {
                                                                value: /^[6789]\d{9}$/,
                                                                message: 'Please enter 10 digit valid Mobile Number'
                                                            }
                                                        })}
                                                    />
                                                    {errors.mobile_no && <ErrorLabel message={errors.mobile_no?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Gender&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="gender"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="gender"
                                                                name="gender"
                                                                dataSource={[
                                                                    {
                                                                        title: "Male",
                                                                        id: 'male'
                                                                    },
                                                                    {
                                                                        title: "Female",
                                                                        id: 'female'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Gender"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Gender'
                                                        }}
                                                    />
                                                    {errors.gender && <ErrorLabel message={errors.gender?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date Of Birth&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="date_of_birth"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                {...field}
                                                                id="date_of_birth"
                                                                name="date_of_birth"
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                max={new Date()}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                displayFormat="dd/MM/yyyy"
                                                                placeholder="Employee Date Of Birth"
                                                                showClearButton={true}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Employee Date Of Birth is required'
                                                        }}
                                                    />
                                                    {errors.date_of_birth && <ErrorLabel message={errors.date_of_birth?.message} />}

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Joining&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="joining_date"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                {...field}
                                                                id="joining_date"
                                                                name="joining_date"
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                value={field.value}
                                                                acceptCustomValue={false}
                                                                displayFormat="dd/MM/yyyy"
                                                                placeholder="Employee Date Of Joining"
                                                                showClearButton={true}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'))
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Employee Joining Date is required'
                                                        }}
                                                    />
                                                    {errors.joining_date && <ErrorLabel message={errors.joining_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="companies"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: masterEntity,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                id="companies"
                                                                name="companies"
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                placeholder="Select Entity"
                                                                valueExpr="id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={4}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {

                                                                        field.onChange(value);
                                                                        setValue('reimursment_primary_entity_id', '');
                                                                        // Call Sub entity API
                                                                        props.actionMasterSubEntity({ 'company_ids': value });

                                                                        setMasterSubEntity([]);
                                                                        setMasterBranchCity({});
                                                                        setMasterBranchAddress({});
                                                                        setValue('sub_companies', []);
                                                                        setValue('city', '');
                                                                        setValue('branch', '');
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select entity'
                                                        }}
                                                    />
                                                    {errors.companies && <ErrorLabel message={errors.companies?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Department&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="sub_companies"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: masterSubEntity,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                id="sub_companies"
                                                                name="sub_companies"
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                placeholder="Select Department"
                                                                valueExpr="id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={2}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value);
                                                                        setValue('reimursment_primary_department_id', '');
                                                                        // Call Branch City API
                                                                        setSelectedMasterSubEntity(value)
                                                                        props.actionMasterBranchCity({ 'company_ids': value });
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Department'
                                                        }}
                                                    />
                                                    {errors.sub_companies && <ErrorLabel message={errors.sub_companies?.message} />}


                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reimbursement Primary Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reimursment_primary_entity_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="reimursment_primary_entity_id"
                                                                name="reimursment_primary_entity_id"
                                                                dataSource={returnEntity()}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Reimbursement Primary Entity"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reimbursement Primary Entity'
                                                        }}
                                                    />
                                                    {errors.reimursment_primary_entity_id && <ErrorLabel message={errors.reimursment_primary_entity_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reimbursement Primary Department&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reimursment_primary_department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="reimursment_primary_department_id"
                                                                name="reimursment_primary_department_id"
                                                                dataSource={returnDepartment()}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Reimbursement Primary Department"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reimbursement Primary Department'
                                                        }}
                                                    />
                                                    {errors.reimursment_primary_department_id && <ErrorLabel message={errors.reimursment_primary_department_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Branch City&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="city"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="city"
                                                                name="city"
                                                                dataSource={masterBranchCity}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Branch City"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)

                                                                        // Call Branch Address API

                                                                        props.actionMasterBranchAddress({ 'city_ids': event.itemData.id });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Branch City'
                                                        }}
                                                    />
                                                    {errors.city && <ErrorLabel message={errors.city?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Branch Address&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="branch"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="branch"
                                                                name="branch"
                                                                fields={{ text: "address1", value: "id" }}
                                                                dataSource={masterBranchAddress}
                                                                allowFiltering={false}
                                                                placeholder="Select Branch Address"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Branch Address'
                                                        }}
                                                    />
                                                    {errors.branch && <ErrorLabel message={errors.branch?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Payroll Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="payroll_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="payroll_id"
                                                                name="payroll_id"
                                                                dataSource={masterPayrollEntity}
                                                                allowFiltering={false}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                placeholder="Select Payroll Entity"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Payroll Entity'
                                                        }}
                                                    />
                                                    {errors.payroll_id && <ErrorLabel message={errors.payroll_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Function&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="department"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: masterDepartment,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                id="department"
                                                                name="department"
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                valueExpr="id"
                                                                placeholder="Select Function"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={4}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value)
                                                                        setFunctions(value)
                                                                        props.actionMasterGetReporting({
                                                                            'companies_ids': selectedMasterSubEntity,
                                                                            'department_ids': value
                                                                        })

                                                                        props.actionMasterApprovalManager({
                                                                            'department_ids': value
                                                                        })

                                                                        setMasterGetReporting([]);
                                                                        setValue('reporting_to_id', '');
                                                                        setBqpStatus(null)
                                                                        setValue('bqp_status', '');
                                                                        
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Function'
                                                        }}
                                                    />
                                                    {errors.department && <ErrorLabel message={errors.department?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reporting To&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reporting_to_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="reporting_to_id"
                                                                name="reporting_to_id"
                                                                dataSource={masterGetReporting}
                                                                allowFiltering={true}
                                                                fields={{ text: 'name', value: 'user_id' }}
                                                                placeholder="Select Reporting To"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                        $("#same_as_approval_manager").attr('disabled', true);
                                                                        $("#same_as_approval_manager").prop('checked', false);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reporting To'
                                                        }}
                                                    />
                                                    {errors.reporting_to_id && <ErrorLabel message={errors.reporting_to_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Grade&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="grade_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="grade_id"
                                                                name="grade_id"
                                                                dataSource={masterGrade}
                                                                allowFiltering={false}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                placeholder="Select Grade"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id);

                                                                        var arrGradeIds = [];

                                                                        arrGradeIds.push(event.itemData.id);

                                                                        // props.actionMasterDesignation({
                                                                        //     'grade_ids': arrGradeIds
                                                                        // });

                                                                        // setMasterDesignation([]);
                                                                        // setMasterJobRole([]);
                                                                        // setValue('designation_id', '');
                                                                        setValue('job_role', '');
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Grade'
                                                        }}
                                                    />
                                                    {errors.grade_id && <ErrorLabel message={errors.grade_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Designation&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="designation_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="designation_id"
                                                                name="designation_id"
                                                                dataSource={masterDesignation}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Designation"
                                                                value={field.value}
                                                                sortOrder={"Ascending"}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)

                                                                        var arrDesignationIds = [];

                                                                        arrDesignationIds.push(event.itemData.id);

                                                                        // props.actionMasterJobRole({
                                                                        //     'designation_ids': arrDesignationIds
                                                                        // })
                                                                        // setMasterJobRole([]);
                                                                        // setValue('job_role', '');
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Designation'
                                                        }}
                                                    />
                                                    {errors.designation_id && <ErrorLabel message={errors.designation_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Facing Designation&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="job_role"
                                                        name="job_role"
                                                        placeholder="Client Facing Designation"
                                                        {...register('job_role', { required: 'Client Facing Designation is required' })}
                                                    />
                                                    {errors.job_role && <ErrorLabel message={errors.job_role?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Permission Role&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="permission_role_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="permission_role_id"
                                                                name="permission_role_id"
                                                                dataSource={roleListDropDown}
                                                                allowFiltering={false}
                                                                fields={{ text: 'title', value: 'id' }}
                                                                placeholder="Select Permission Role"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Permission Role'
                                                        }}
                                                    />
                                                    {errors.permission_role_id && <ErrorLabel message={errors.permission_role_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Total Leave Balance&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="total_leaves"
                                                        name="total_leaves"
                                                        maxLength={6}
                                                        placeholder="Enter Total leave balance"
                                                        // onKeyPress={(event) => {
                                                        //     if (!/^\d{0,2}(\.\d{0,1}){0,1}$/.test(event.key)) {
                                                        //         event.preventDefault();
                                                        //     }
                                                        // }}
                                                        {...register('total_leaves', {
                                                            required: 'Total leaves required',
                                                            // pattern: {
                                                            //     value: /^\d{0,2}(\.\d{0,1}){0,1}$/,
                                                            //     message: 'Please enter valid leaves',
                                                            // }
                                                        }
                                                        )}
                                                    />
                                                    {errors.total_leaves && <ErrorLabel message={errors.total_leaves?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Leave Kitty</label>
                                                    <input
                                                        className="form-control"
                                                        id="leave_kitty"
                                                        name="leave_kitty"
                                                        maxLength={6}
                                                        placeholder="Leave kitty"
                                                        {...register('leave_kitty')}
                                                    />
                                                    {errors.leave_kitty && <ErrorLabel message={errors.leave_kitty?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Employee Shift &nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="shift_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="shift_id"
                                                                name="shift_id"
                                                                dataSource={employeeShifts}
                                                                fields={{ text: "shift_name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Employee Shift"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Employee Shift'
                                                        }}
                                                    />
                                                    {errors.shift_id && <ErrorLabel message={errors.shift_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Working Days&nbsp;</label>
                                                    <Controller
                                                        name="working_days"
                                                        control={control}
                                                        defaultValue={showWorkingDays}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="working_days"
                                                                name="working_days"
                                                                dataSource={[
                                                                    {
                                                                        id: 5,
                                                                        name: '5 days'
                                                                    },
                                                                    {
                                                                        id: 6,
                                                                        name: '6 days'
                                                                    }
                                                                ]}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Working Days"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {
                                                functions?.length > 0 && (functions.includes(9) || functions.includes(7) || functions.includes(8) || functions.includes(13) || functions.includes(16)) ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">BQP Status&nbsp;<span className="danger">*</span></label>
                                                            <Controller
                                                                name="bqp_status"
                                                                control={control}
                                                                defaultValue={showWorkingDays}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="bqp_status"
                                                                        name="bqp_status"
                                                                        dataSource={[
                                                                            {
                                                                                id: 'training',
                                                                                name: 'Training Completed'
                                                                            },
                                                                            {
                                                                                id: 'exam',
                                                                                name: 'Exam Completed'
                                                                            }
                                                                        ]}
                                                                        fields={{ text: "name", value: "id" }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select BQP Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.id)
                                                                                setBqpStatus(event.itemData.id)
                                                                                setValue('bqp_certified', null)
                                                                                setValue('certificate_number', '')
                                                                                setValue('bqp_certificate', null)
                                                                                setValue('noc_document', null)
                                                                                setValue('end_date', '')
                                                                                setValue('start_date', '')
                                                                                setUploadCertificateCopy(null)
                                                                                setValue('brokering_entity', null)
                                                                                setValue('noc_type', null)
                                                                                setUploadNocDocument(null)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select BQP Status'
                                                                }}
                                                            />
                                                            {errors.bqp_status && <ErrorLabel message={errors.bqp_status?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {bqpStatus != null && bqpStatus === 'exam' ?
                                                <>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">BQP Certified&nbsp;<span className="danger">*</span></label>
                                                            <Controller
                                                                name="bqp_certified"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="bqp_certified"
                                                                        name="bqp_certified"
                                                                        dataSource={[
                                                                            {
                                                                                value: 'Valid',
                                                                                name: 'Valid'
                                                                            },
                                                                            {
                                                                                value: 'Expired',
                                                                                name: 'Expired'
                                                                            },
                                                                            {
                                                                                value: 'Not Available',
                                                                                name: 'Not Available'
                                                                            },
                                                                            {
                                                                                value: 'Not Applicable',
                                                                                name: 'Not Applicable'
                                                                            }
                                                                        ]}
                                                                        fields={{ text: "name", value: "value" }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select BQP Certified"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.value)
                                                                                setBQPCertified(event.itemData.value)
                                                                                setValue('bqp_certificate', null)
                                                                                setValue('noc_document', null)
                                                                                setValue('certificate_number', '')
                                                                                setValue('end_date', '')
                                                                                setValue('start_date', '')
                                                                                setUploadCertificateCopy(null)
                                                                                setValue('brokering_entity', null)
                                                                                setValue('noc_type', null)
                                                                                setUploadNocDocument(null)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select BQP Certified'
                                                                }}
                                                            />
                                                            {errors.bqp_certified && <ErrorLabel message={errors.bqp_certified?.message} />}
                                                        </div>
                                                    </div>
                                                    {bqpCertified != null && bqpCertified === 'Valid' || bqpCertified === 'Expired'
                                                        ?
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label className="label-control">Certificate Number&nbsp;<span className="danger">*</span></label>
                                                                <input
                                                                    className="form-control"
                                                                    id="certificate_number"
                                                                    name="certificate_number"
                                                                    placeholder="Certificate Number"
                                                                    {...register('certificate_number', { required: "Please Enter Certificate Number" })}
                                                                />
                                                                {errors.certificate_number && <ErrorLabel message={errors.certificate_number?.message} />}
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    {
                                                        bqpCertified != null && bqpCertified === 'Expired' ?
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">Expiry Date&nbsp;<span className="danger">*</span></label>
                                                                    <Controller
                                                                        name="end_date"
                                                                        control={control}
                                                                        render={({ field }) =>
                                                                            <DateBox
                                                                                calendarOptions={{
                                                                                    showTodayButton: true,
                                                                                }}
                                                                                {...field}
                                                                                id="end_date"
                                                                                name="end_date"
                                                                                stylingMode="underlined"
                                                                                className="form-control"
                                                                                pickerType="calendar"
                                                                                type="date"
                                                                                max={new Date()}
                                                                                acceptCustomValue={false}
                                                                                value={field.value}
                                                                                displayFormat="dd/MM/yyyy"
                                                                                placeholder="Expiry Date"
                                                                                showClearButton={true}
                                                                                onValueChange={(value) => {
                                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                }}
                                                                            />
                                                                        }
                                                                        rules={{
                                                                            required: 'Expiry date is required'
                                                                        }}
                                                                    />
                                                                    {errors.end_date && <ErrorLabel message={errors.end_date?.message} />}
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {bqpCertified != null && bqpCertified === 'Valid' ?
                                                        <>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                                    <Controller
                                                                        name="start_date"
                                                                        control={control}
                                                                        render={({ field }) =>
                                                                            <DateBox
                                                                                calendarOptions={{
                                                                                    showTodayButton: true,
                                                                                }}
                                                                                {...field}
                                                                                id="start_date"
                                                                                name="start_date"
                                                                                stylingMode="underlined"
                                                                                className="form-control"
                                                                                pickerType="calendar"
                                                                                type="date"
                                                                                min={moment(new Date()).subtract(3, 'year').format('YYYY-MM-DD')}
                                                                                // max={moment(new Date())}
                                                                                acceptCustomValue={false}
                                                                                value={field.value}
                                                                                displayFormat="dd/MM/yyyy"
                                                                                placeholder="Start Date"
                                                                                showClearButton={true}
                                                                                onValueChange={(value) => {
                                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                }}
                                                                            />
                                                                        }
                                                                        rules={{
                                                                            required: 'Start Date is Required'
                                                                        }}
                                                                    />
                                                                    {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">End date&nbsp;<span className="danger">*</span></label>
                                                                    <Controller
                                                                        name="end_date"
                                                                        control={control}
                                                                        render={({ field }) =>
                                                                            <DateBox
                                                                                calendarOptions={{
                                                                                    showTodayButton: true,
                                                                                }}
                                                                                {...field}
                                                                                id="end_date"
                                                                                name="end_date"
                                                                                stylingMode="underlined"
                                                                                className="form-control"
                                                                                pickerType="calendar"
                                                                                type="date"
                                                                                min={moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}
                                                                                max={moment(new Date()).add(3, 'year').format('YYYY-MM-DD')}
                                                                                acceptCustomValue={false}
                                                                                value={field.value}
                                                                                displayFormat="dd/MM/yyyy"
                                                                                placeholder="End Date"
                                                                                showClearButton={true}
                                                                                onValueChange={(value) => {
                                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                }}
                                                                            />
                                                                        }
                                                                        rules={{
                                                                            required: 'End Date is Required'
                                                                        }}
                                                                    />
                                                                    {errors.end_date && <ErrorLabel message={errors.end_date?.message} />}
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    {bqpCertified != null && bqpCertified === 'Valid' || bqpCertified === 'Expired'
                                                        ?
                                                        <>
                                                            {
                                                                // nocValue != null && nocValue === 'NOC Received' ?
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <fieldset class="form-group">
                                                                        <label for="basicInputFile">Upload Certificate Copy&nbsp;<span className="danger">*</span></label>
                                                                        <div class="custom-file">
                                                                            <input
                                                                                type="file"
                                                                                class="custom-file-input"
                                                                                accept=".jpg, .jpeg, .png"
                                                                                id="bqp_certificate"
                                                                                name="bqp_certificate"
                                                                                onChange={(event) => {
                                                                                    $('#error-bqp-certificate-not-found').addClass('d-none')
                                                                                    if (event.target.files != null) {

                                                                                        var fileSize = event.target.files[0].size;

                                                                                        var ext = (event.target.files[0].name).split('.').pop();

                                                                                        if (checkFileExtension(ext, validExt)) {

                                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                                setUploadCertificateCopy(event.target.files[0]);
                                                                                            } else {
                                                                                                Swal.fire({
                                                                                                    title: 'Error',
                                                                                                    icon: 'error',
                                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                                }).then((result) => {

                                                                                                });
                                                                                            }
                                                                                        } else {
                                                                                            Swal.fire({
                                                                                                title: 'Upload Error',
                                                                                                text: 'You can only upload jpg, jpeg & png files',
                                                                                                icon: 'error'
                                                                                            }).then(success => {

                                                                                            })
                                                                                        }
                                                                                    } else {
                                                                                        $('#error-bqp-certificate-not-found').removeClass('d-none')
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <small className="error-msg d-none text-danger" id="error-bqp-certificate-not-found">Document is required</small>
                                                                            <label class="custom-file-label" for="bqp_certificate">
                                                                                {
                                                                                    uploadCertificateCopy != null ?
                                                                                        uploadCertificateCopy.name : "Choose file"
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                                // :
                                                                // <></>
                                                            }
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">Brokering Entity&nbsp;<span className="danger">*</span></label>
                                                                    <Controller
                                                                        name="brokering_entity"
                                                                        control={control}
                                                                        render={({ field }) =>
                                                                            <DropDownListComponent
                                                                                {...field}
                                                                                id="brokering_entity"
                                                                                name="brokering_entity"
                                                                                dataSource={[
                                                                                    {
                                                                                        value: 'Previous Organization',
                                                                                        name: 'Previous Organization'
                                                                                    },
                                                                                    {
                                                                                        value: 'PINC',
                                                                                        name: 'PINC'
                                                                                    },
                                                                                ]}
                                                                                fields={{ text: "name", value: "value" }}
                                                                                allowFiltering={false}
                                                                                placeholder="Select Brokering Entity"
                                                                                value={field.value}
                                                                                change={(event) => {
                                                                                    if (event.itemData != null) {
                                                                                        field.onChange(event.itemData.value)
                                                                                        setBrokeringEntity(event.itemData.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        rules={{
                                                                            required: "Please Select Brokering Entity"
                                                                        }}
                                                                    />
                                                                    {errors.brokering_entity && <ErrorLabel message={errors.brokering_entity?.message} />}
                                                                </div>
                                                            </div>
                                                            {
                                                                brokeringEntity != null && brokeringEntity === 'Previous Organization' ?
                                                                    <div className="col-12 col-md-6 col-lg-6">
                                                                        <div className="form-group">
                                                                            <label className="label-control">NOC&nbsp;<span className="danger">*</span></label>
                                                                            <Controller
                                                                                name="noc_type"
                                                                                control={control}
                                                                                render={({ field }) =>
                                                                                    <DropDownListComponent
                                                                                        {...field}
                                                                                        id="noc_type"
                                                                                        name="noc_type"
                                                                                        dataSource={[
                                                                                            {
                                                                                                value: 'NOC Received',
                                                                                                name: 'NOC Received'
                                                                                            },
                                                                                            {
                                                                                                value: 'NOC Pending',
                                                                                                name: 'NOC Pending'
                                                                                            },
                                                                                        ]}
                                                                                        fields={{ text: "name", value: "value" }}
                                                                                        allowFiltering={false}
                                                                                        placeholder="Select NOC"
                                                                                        value={field.value}
                                                                                        change={(event) => {
                                                                                            if (event.itemData != null) {
                                                                                                field.onChange(event.itemData.value)
                                                                                                setNOCValue(event.itemData.value)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                rules={{
                                                                                    required: "Please Select NOC"
                                                                                }}
                                                                            />
                                                                            {errors.noc_type && <ErrorLabel message={errors.noc_type?.message} />}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }
                                                            {
                                                                nocValue != null && nocValue === 'NOC Received' ?
                                                                    <div className="col-12 col-md-6 col-lg-6">
                                                                        <fieldset class="form-group">
                                                                            <label for="basicInputFile">NOC Document&nbsp;<span className="danger">*</span></label>
                                                                            <div class="custom-file">
                                                                                <input
                                                                                    type="file"
                                                                                    class="custom-file-input"
                                                                                    accept=".jpg, .jpeg, .png"
                                                                                    id="noc_document"
                                                                                    name="noc_document"
                                                                                    onChange={(event) => {
                                                                                        $('#error-noc-document-not-found').addClass('d-none')
                                                                                        if (event.target.files != null) {

                                                                                            var fileSize = event.target.files[0].size;

                                                                                            var ext = (event.target.files[0].name).split('.').pop();

                                                                                            if (checkFileExtension(ext, validExt)) {

                                                                                                if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                                    setUploadNocDocument(event.target.files[0]);
                                                                                                } else {
                                                                                                    Swal.fire({
                                                                                                        title: 'Error',
                                                                                                        icon: 'error',
                                                                                                        html: MEETING_FILE_ERROR_MESSAGE,
                                                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                                                    }).then((result) => {

                                                                                                    });
                                                                                                }
                                                                                            } else {
                                                                                                Swal.fire({
                                                                                                    title: 'Upload Error',
                                                                                                    text: 'You can only upload jpg, jpeg & png files',
                                                                                                    icon: 'error'
                                                                                                }).then(success => {

                                                                                                })
                                                                                            }
                                                                                        } else {
                                                                                            $('#error-noc-document-not-found').removeClass('d-none')
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <small className="error-msg d-none text-danger" id="error-noc-document-not-found">Document is required</small>
                                                                                <label class="custom-file-label" for="noc_document">
                                                                                    {
                                                                                        uploadNocDocument != null ?
                                                                                            uploadNocDocument.name : "Choose file"
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        </fieldset>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Team
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Team Name</label>
                                                    <Controller
                                                        name="team_master_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="team_master_id"
                                                                name="team_master_id"
                                                                dataSource={masterTeamHead}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Team Name"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.team_master_id && <ErrorLabel message={errors.team_master_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Approval Manager
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="approval_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="approval_manager_id"
                                                                name="approval_manager_id"
                                                                dataSource={masterApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        $("#same_as_approval_manager").removeAttr('disabled');
                                                                        field.onChange(event.itemData.user_id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Approval Manager'
                                                        }}
                                                    />
                                                    {errors.approval_manager_id && <ErrorLabel message={errors.approval_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="same_as_approval_manager"
                                                            id="same_as_approval_manager"
                                                            disabled={true}
                                                            onChange={() => {
                                                                setValue('asset_manager_id', getValues('approval_manager_id'));
                                                                setValue('finance_manager_id', getValues('approval_manager_id'));
                                                                setValue('travel_manager_id', getValues('approval_manager_id'));
                                                                setValue('attendance_manager_id', getValues('approval_manager_id'));
                                                                setValue('meeting_manager_id', getValues('approval_manager_id'));
                                                                setValue('performance_manager_id', getValues('approval_manager_id'));
                                                                setValue('reimburstment_approval_manager_id', getValues('approval_manager_id'));
                                                            }}
                                                        />

                                                        <label class="custom-control-label" for="same_as_approval_manager">Same As Approval Manager</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Other Approval Manager
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Asset Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="asset_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="asset_manager_id"
                                                                name="asset_manager_id"
                                                                dataSource={masterAssetApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Asset Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Asset Approval Manager'
                                                        }}
                                                    />
                                                    {errors.asset_manager_id && <ErrorLabel message={errors.asset_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Finance Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="finance_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="finance_manager_id"
                                                                name="finance_manager_id"
                                                                dataSource={masterFinanceApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Finance Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Finance Approval Manager'
                                                        }}
                                                    />
                                                    {errors.finance_manager_id && <ErrorLabel message={errors.finance_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Travel Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="travel_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="travel_manager_id"
                                                                name="travel_manager_id"
                                                                dataSource={masterTravelApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Travel Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Travel Approval Manager'
                                                        }}
                                                    />
                                                    {errors.travel_manager_id && <ErrorLabel message={errors.travel_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Attendance Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="attendance_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="attendance_manager_id"
                                                                name="attendance_manager_id"
                                                                dataSource={masterAttendanceApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Attendance Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Attendance Approval Manager'
                                                        }}
                                                    />
                                                    {errors.attendance_manager_id && <ErrorLabel message={errors.attendance_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Meeting Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="meeting_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="meeting_manager_id"
                                                                name="meeting_manager_id"
                                                                dataSource={masterMeetingApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Meeting Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Meeting Approval Manager'
                                                        }}
                                                    />
                                                    {errors.meeting_manager_id && <ErrorLabel message={errors.meeting_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Performance Objective Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="performance_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="performance_manager_id"
                                                                name="performance_manager_id"
                                                                dataSource={masterPerformanceObjectiveApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Performance Objective Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Performance Objective Approval Manager'
                                                        }}
                                                    />
                                                    {errors.performance_manager_id && <ErrorLabel message={errors.performance_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reimbursement Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reimburstment_approval_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="reimburstment_approval_manager_id"
                                                                name="reimburstment_approval_manager_id"
                                                                dataSource={masterreimburstmentapprovalmanager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Reimbursement Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reimbursement Approval Manager'
                                                        }}
                                                    />
                                                    {errors.reimburstment_approval_manager_id && <ErrorLabel message={errors.reimburstment_approval_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Finance Reimbursement Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="finance_reimburstment_approval_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="finance_reimburstment_approval_manager_id"
                                                                name="finance_reimburstment_approval_manager_id"
                                                                dataSource={masterfinancereimburstmentapprovalmanager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Finance Reimbursement Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Finance Reimbursement Approval Manager'
                                                        }}
                                                    />
                                                    {errors.finance_reimburstment_approval_manager_id && <ErrorLabel message={errors.finance_reimburstment_approval_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Other Information
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Vertical</label>
                                                    <input
                                                        className="form-control"
                                                        id="vertical"
                                                        name="vertical"
                                                        placeholder="Employee Vertical"
                                                        {...register('vertical')}
                                                    />
                                                    {errors.vertical && <ErrorLabel message={errors.vertical?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Segment</label>
                                                    <input
                                                        className="form-control"
                                                        id="segment"
                                                        name="segment"
                                                        placeholder="Employee Segment"
                                                        {...register('segment')}
                                                    />
                                                    {errors.segment && <ErrorLabel message={errors.segment?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Region</label>
                                                    <input
                                                        className="form-control"
                                                        id="region"
                                                        name="region"
                                                        placeholder="Employee Region"
                                                        {...register('region')}
                                                    />
                                                    {errors.region && <ErrorLabel message={errors.region?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Product Specialist
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Product&nbsp;</label>
                                                    <Controller
                                                        name="product_ids"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                id="product_ids"
                                                                name="product_ids"
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: proposalPolicy,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        ('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        ('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        ('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        ('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                valueExpr="id"
                                                                placeholder="Select Product"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={2}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value);
                                                                        setProductSpecialist(value);
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: isProduct === true ? 'Please Select Product' : false
                                                        }}
                                                    />
                                                    {errors.product_ids && <ErrorLabel message={errors.product_ids?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div class="form-check cust-form-check mt-0 mb-1 ml-1">
                                                    <input
                                                        name="is_product_specialist"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="is_product_specialist"
                                                        disabled={productSpecialist === null ? true : false}
                                                        onClick={(event) => {
                                                            setIsProduct(event.target.checked);
                                                        }}
                                                        {...register("is_product_specialist")}
                                                    />
                                                    <label class="form-check-label my-auto" for="is_product_specialist">
                                                        <span className="cust-fs-12">User is product Specialist?</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Assign Rights For
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="request_view"
                                                            id="request_view"
                                                            {...register('request_view')}
                                                        />
                                                        {errors.request_view && <ErrorLabel message={errors.request_view?.message} />}
                                                        <label class="custom-control-label" for="request_view">Request View</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="request_approval"
                                                            id="request_approval"
                                                            {...register('request_approval')}
                                                        />
                                                        {errors.request_approval && <ErrorLabel message={errors.request_approval?.message} />}
                                                        <label class="custom-control-label" for="request_approval">Request Approval</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="task_operation"
                                                            id="task_operation"
                                                            {...register('task_operation')}
                                                        />
                                                        {errors.task_operation && <ErrorLabel message={errors.task_operation?.message} />}
                                                        <label class="custom-control-label" for="task_operation">Task Operation</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Upload Visiting Card
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Visiting Card Front</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            accept=".jpg, .jpeg, .png"
                                                            id="visiting_card_front"
                                                            name="visiting_card_front"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setVisitingCardFront(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="visiting_card_front">
                                                            {
                                                                visitingCardFront != null ?
                                                                    visitingCardFront.name : "Choose file"
                                                            }
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Visiting Card Back</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="visiting_card_back"
                                                            accept=".jpg, .jpeg, .png"
                                                            name="visiting_card_back"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setVisitingCardBack(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="visiting_card_back">
                                                            {
                                                                visitingCardBack != null ?
                                                                    visitingCardBack.name : "Choose file"
                                                            }
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Profile Photo
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Profile Photo</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="profile_photo"
                                                            accept=".jpg, .jpeg, .png"
                                                            name="profile_photo"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setProfilePhoto(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="profile_photo">
                                                            {
                                                                profilePhoto != null ?
                                                                    profilePhoto.name : "Choose file"
                                                            }
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Client Visible Image</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="client_visible_image"
                                                            accept=".jpg, .jpeg, .png"
                                                            name="client_visible_image"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setClientVisibleImage(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="client_visible_image">
                                                            {
                                                                clientVisibleImage != null ?
                                                                    clientVisibleImage.name : "Choose file"
                                                            }
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="add_employee"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add_employee-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/hrms');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </>
    );
}

const mapStateToProps = (state) => {
    var ProposalPolicyType = null;
    var Entity = null;
    var SubEntity = null;
    var BranchCity = null;
    var Department = null;
    var Grade = null;
    var BranchAddress = null;
    var GetReporting = null;
    var JobRole = null;
    var Designation = null;
    var TeamHead = null;
    var ApprovalManager = null;
    var AssetApprovalManager = null;
    var FinanceApprovalManager = null;
    var TravelApprovalManager = null;
    var AttendanceApprovalManager = null;
    var MeetingApprovalManager = null;
    var PerformanceObjectiveApprovalManager = null;
    var Employee = null;
    var getRolesDropdownList = null;
    var EmployeeShift = null;
    var GetReimbursementFinanceApprovalManager = null;

    if (
        state.RegistrationReducer != null &&
        Object.keys(state.RegistrationReducer).length > 0 &&
        state.RegistrationReducer.constructor === Object
    ) {
        switch (state.RegistrationReducer.tag) {
            case ACTION_CLAIMS_POLICY_TYPE_SUCCESS:
                ProposalPolicyType = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { ProposalPolicyType };
            case ACTION_API_MASTER_ENTITY_SUCCESS:
                Entity = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { Entity };
            case ACTION_API_MASTER_SUB_ENTITY_SUCCESS:
                SubEntity = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { SubEntity };
            case ACTION_API_MASTER_BRANCH_CITY_SUCCESS:
                BranchCity = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { BranchCity };
            case ACTION_API_MASTER_DEPARTMENT_SUCCESS:
                Department = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { Department };
            case ACTION_API_MASTER_GRADE_SUCCESS:
                Grade = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { Grade };
            case ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS:
                BranchAddress = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { BranchAddress };
            case ACTION_API_MASTER_GET_REPORTING_SUCCESS:
                GetReporting = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { GetReporting };
            case ACTION_API_MASTER_DESIGNATION_SUCCESS:
                Designation = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { Designation };
            case ACTION_API_MASTER_JOB_ROLE_SUCCESS:
                JobRole = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { JobRole };
            case ACTION_API_MASTER_TEAM_HEAD_SUCCESS:
                TeamHead = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { TeamHead };
            case ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS:
                ApprovalManager = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { ApprovalManager };
            case ACTION_EMPLOYEE_ADD_SUCCESS:
                Employee = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { Employee };
            case ACTION_GETROLESDROPDOWNLIST_SUCCESS:
                getRolesDropdownList = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { getRolesDropdownList };
            case EMPLOYEE_SHIFT_TIMINGS_SUCCESS:
                EmployeeShift = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { EmployeeShift };
            case ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS:
                GetReimbursementFinanceApprovalManager = Object.assign({}, state.RegistrationReducer.data);
                delete state.RegistrationReducer;
                return { GetReimbursementFinanceApprovalManager };
            default:
        }
    }

    return {
        ProposalPolicyType,
        Entity,
        SubEntity,
        BranchCity,
        Department,
        Grade,
        BranchAddress,
        GetReporting,
        Designation,
        JobRole,
        TeamHead,
        ApprovalManager,
        AssetApprovalManager,
        FinanceApprovalManager,
        TravelApprovalManager,
        AttendanceApprovalManager,
        MeetingApprovalManager,
        PerformanceObjectiveApprovalManager,
        Employee,
        getRolesDropdownList,
        EmployeeShift,
        GetReimbursementFinanceApprovalManager
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionProposalPolicyType,
        actionEmployeeAdd,
        actionMasterEntity,
        actionMasterSubEntity,
        actionMasterBranchCity,
        actionMasterBranchAddress,
        actionMasterDepartment,
        actionMasterGetReporting,
        actionMasterGrade,
        actionMasterDesignation,
        actionMasterJobRole,
        actionMasterTeamHead,
        actionMasterApprovalManager,
        actionGetRolesDropdownList,
        actionGetEmployeeShifts,
        actionGetReimbursementFinanceApprovalManager
    }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Registration));