import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_PERMISSION_CONTENT, API_CONTENT_STATUS } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_PERMISSION_CONTENT_LOADING = 'ACTION_PERMISSION_CONTENT_LOADING';
export const ACTION_PERMISSION_CONTENT_SUCCESS = 'ACTION_PERMISSION_CONTENT_SUCCESS';

export const ACTION_CONTENT_STATUS_LOADING = 'ACTION_CONTENT_STATUS_LOADING';
export const ACTION_CONTENT_STATUS_SUCCESS = 'ACTION_CONTENT_STATUS_SUCCESS';

export function actionPermissionContent() {
    return (dispatch, getState) =>
        Api.get(API_PERMISSION_CONTENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PERMISSION_CONTENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PERMISSION_CONTENT_LOADING, { loading: false, data }));
        });
}

export function actionContentStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_CONTENT_STATUS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CONTENT_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CONTENT_STATUS_LOADING, { loading: false, data }));
        });
}