import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { withAuth } from 'react-auth-guard';
import { actionChangePassword, ACTION_EMPLOYEE_CHANGE_PASSWORD_SUCCESS } from './action';
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const ChangePassword = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            password: ''
        }
    });

    const initialRender = () => {
        setValue('password', '');
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onEmployeeChangePassword = () => {
        const { EmployeeChangePassword } = props;
        var EmployeeChangePasswordResponse = Object.assign({}, EmployeeChangePassword);

        $("#save_change_password").prop("disabled", false);

        if (EmployeeChangePasswordResponse.result === true) {
            $("#change-password-form-loader").addClass("hidden");
            Swal.fire({
                icon: 'success',
                title: `Password Changed`,
                text: `Password for ${props.name} is changed Successfully.`,
                confirmButtonText: 'Ok',
                confirmButtonColor: PRIMARY_COLOR,
            }).then(success => {
                if (success.isConfirmed) {
                    props.handleClose();
                }
            });
        } else {
            $("#change-password-form-loader").addClass("hidden");
            switch (EmployeeChangePasswordResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onEmployeeChangePassword, [props.EmployeeChangePassword])

    const onSubmit = (data) => {

        $("#change-password-form-loader").removeClass("hidden");
        $("#save_change_password").prop("disabled", true);
        props.actionChangePassword({
            id: props.user_id,
            password: data.password
        })
    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="change-password"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="change-password">
                                    Change Password for <span className="text-bold-600">{props.name}</span>
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control primary">Enter Password&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="password"
                                                            name="password"
                                                            placeholder="Enter Your New Password"
                                                            {...register('password', { required: 'Password is required' })}
                                                        />
                                                        {errors.password && <ErrorLabel message={errors.password?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                id="save_change_password"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    Submit
                                                    <span id="change-password-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    props.handleClose();
                                                    event.preventDefault();
                                                }}
                                            > Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var EmployeeChangePassword = null;

    if (
        state.EmployeeChangePasswordReducer != null &&
        Object.keys(state.EmployeeChangePasswordReducer).length > 0 &&
        state.EmployeeChangePasswordReducer.constructor === Object
    ) {
        switch (state.EmployeeChangePasswordReducer.tag) {
            case ACTION_EMPLOYEE_CHANGE_PASSWORD_SUCCESS:
                EmployeeChangePassword = Object.assign({}, state.EmployeeChangePasswordReducer.data);
                delete state.EmployeeChangePasswordReducer;
                return { EmployeeChangePassword };
            default:
        }
    }

    return {
        EmployeeChangePassword
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionChangePassword }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));