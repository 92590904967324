import createReducer from "../../../../../../reducers/createReducer";

import {ACTION_EDIT_EMPLOYMENT_SUCCESS} from './action'
import { ACTION_GET_EMPLOYMENT_DETAIL_SUCCESS } from "./action";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditEmploymentReducer = createReducer(INITIAL_STATE, {
    [ACTION_EDIT_EMPLOYMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_EMPLOYMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_EMPLOYMENT_DETAIL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_EMPLOYMENT_DETAIL_SUCCESS,
                loading: false,
            }
        );
    },
});
export default EditEmploymentReducer;