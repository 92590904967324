import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionMasterGetDepartment, ACTION_MASTER_DEPARTMENT_SUCCESS } from './action';
import { actionMasterGetCategory, ACTION_MASTER_CATEGORY_SUCCESS } from './action';
import { actionAddMasterPolicyType, ACTION_MASTER_ADD_POLICY_TYPE_SUCCESS } from './action';

const PolicyMasterAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            status: "ACTIVE"
        }
    });
    const [masterDepartment, setMasterDepartment] = useState([]);
    const [masterCategory, setMasterCategory] = useState([]);

    const initialRender = () => {
        props.actionMasterGetDepartment({ status: "ACTIVE" });
        // props.actionMasterGetCategory();
    }
    useEffect(initialRender, []);

    const onMasterGetDepartmentUpdate = () => {
        const { MasterGetDepartment } = props
        if (MasterGetDepartment != null) {
            var MasterGetDepartmentResponse = Object.assign({}, MasterGetDepartment);
            if (MasterGetDepartmentResponse.result === true) {
                setMasterDepartment(MasterGetDepartmentResponse.response);
            } else {
                switch (MasterGetDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetDepartmentUpdate, [props.MasterGetDepartment])

    const onMasterGetCategoryUpdate = () => {
        const { MasterGetCategory } = props
        if (MasterGetCategory != null) {
            var MasterGetCategoryResponse = Object.assign({}, MasterGetCategory);
            if (MasterGetCategoryResponse.result === true) {
                setMasterCategory(MasterGetCategoryResponse.response);
            } else {
                switch (MasterGetCategoryResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetCategoryUpdate, [props.MasterGetCategory])

    const onAddMasterPolicyTypeUpdate = () => {
        const { AddMasterPolicyType } = props
        if (AddMasterPolicyType != null) {
            var AddMasterPolicyTypeResponse = Object.assign({}, AddMasterPolicyType);
            $("#save_policy").prop("disabled", false);
            if (AddMasterPolicyTypeResponse.result === true) {
                $("#policy-form-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Policy Type Saved Successfully");
                props.history.goBack();
            } else {
                $("#policy-form-loader").addClass("hidden");
                switch (AddMasterPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAddMasterPolicyTypeUpdate, [props.AddMasterPolicyType])

    const onSubmit = (data) => {
        $("#policy-form-loader").removeClass("hidden");
        $("#save_policy").prop("disabled", true);
        props.actionAddMasterPolicyType(data);
    }

    return (
        <>
            <ScreenTitle title="Add Policy" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Policy Type&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Policy Type"
                                                        {...register('name', { required: 'Policy Type is required' })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Department Name&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="department_id"
                                                                name="department_id"
                                                                dataSource={masterDepartment}
                                                                allowFiltering={false}
                                                                placeholder="Select Department Name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Department Name is required'
                                                        }}
                                                    />

                                                    {errors.department_id && <ErrorLabel message={errors.department_id?.message} />}
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Category&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="category"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="category"
                                                                name="category"
                                                                dataSource={masterCategory}
                                                                allowFiltering={false}
                                                                placeholder="Select Category"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Category is required'
                                                        }}
                                                    />

                                                    {errors.category && <ErrorLabel message={errors.category?.message} />}
                                                </div>
                                            </div> */}

                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        value: 'ACTIVE',
                                                                        text: 'ACTIVE'

                                                                    },
                                                                    {
                                                                        value: 'INACTIVE',
                                                                        text: 'INACTIVE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Status is required'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_policy"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="policy-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterGetDepartment = null;
    var MasterGetCategory = null;
    var AddMasterPolicyType = null;

    if (
        state.MasterAddPolicyTypeReducer != null &&
        Object.keys(state.MasterAddPolicyTypeReducer).length > 0 &&
        state.MasterAddPolicyTypeReducer.constructor === Object
    ) {
        switch (state.MasterAddPolicyTypeReducer.tag) {
            case ACTION_MASTER_DEPARTMENT_SUCCESS:
                MasterGetDepartment = Object.assign({}, state.MasterAddPolicyTypeReducer.data);
                delete state.MasterAddPolicyTypeReducer
                return { MasterGetDepartment };
            case ACTION_MASTER_CATEGORY_SUCCESS:
                MasterGetCategory = Object.assign({}, state.MasterAddPolicyTypeReducer.data);
                delete state.MasterAddPolicyTypeReducer
                return { MasterGetCategory };
            case ACTION_MASTER_ADD_POLICY_TYPE_SUCCESS:
                AddMasterPolicyType = Object.assign({}, state.MasterAddPolicyTypeReducer.data);
                delete state.MasterAddPolicyTypeReducer
                return { AddMasterPolicyType };
            default:
        }
    }

    return {
        MasterGetDepartment,
        MasterGetCategory,
        AddMasterPolicyType
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterGetDepartment, actionMasterGetCategory, actionAddMasterPolicyType }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PolicyMasterAdd)) 