import {
    ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
    ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS,
    ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS,
    ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS,
    ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
    ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
    ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS,
    ACTION_HOME_GET_TICKER_SUCCESS
  } from "./action";
  import createReducer from "../../../../../reducers/createReducer";
  
  const INITIAL_STATE = {
    data: [],
    loading: false,
  };
  
  const HomeReducer = createReducer(INITIAL_STATE, {
    [ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_HOME_GET_TICKER_SUCCESS](state, action) {
      return Object.assign(
          {},
          {
              ...state,
              data: action.payload.data,
              tag: ACTION_HOME_GET_TICKER_SUCCESS,
              loading: false,
          }
      );
    },
  });
  export default HomeReducer;
  