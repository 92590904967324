import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label";
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import Select from 'react-select';
import { actionAccompainedBy, ACTION_ACCOMPAINED_BY_SUCCESS } from './action';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../constants";
import { getAccompainedByIds } from "../../../utils";

const TripDetails = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, reset } = useForm({});
    const [accompainedBy, setAccompainedBy] = useState([]);
    const [returnDate, setReturnDate] = useState();
    const minTravelDate = new Date();
    const [minReturnDate, setMinReturnDate] = useState(new Date());
    const [returnDateDisable, setReturnDateDisable] = useState(true);

    const initialRender = () => {
        $("#save-trip").prop("disabled", false);

        if (props.show === true) {
            reset({
                arrival: '',
                departure: '',
                travel_date: '',
                return_date: '',
                flight: 0,
                hotel: 0,
                car: 0,
                train_bus: 0,
                visa: 0,
                insurance: 0,
                travel_with: ''
            });

            if (props.data != null) {
                if (props.showDetail === true) {
                    setValue('arrival', props.data.arrival);
                    setValue('departure', props.data.departure);
                    setValue('travel_date', props.data.travel_date);
                    setValue('return_date', props.data.return_date);
                    setValue('flight', props.data.flight === 0 ? false : true);
                    setValue('hotel', props.data.hotel === 0 ? false : true);
                    setValue('car', props.data.car === 0 ? false : true);
                    setValue('train_bus', props.data.train_bus === 0 ? false : true);
                    setValue('visa', props.data.visa === 0 ? false : true);
                    setValue('insurance', props.data.insurance === 0 ? false : true);
                    setValue('travel_with', props.data.travel_with_object)
                }
            }
            props.actionAccompainedBy({});
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            setReturnDateDisable(true);
        } else {

            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();

        }
    };
    useEffect(initialRender, [props.show]);

    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    const onSubmit = (data) => {
        data.flight = data.flight == false ? 0 : 1;
        data.hotel = data.hotel == false ? 0 : 1;
        data.car = data.car == false ? 0 : 1;
        data.train_bus = data.train_bus == false ? 0 : 1;
        data.visa = data.visa == false ? 0 : 1;
        data.insurance = data.insurance == false ? 0 : 1;
        data.travel_with_object = data.travel_with;
        data.travel_with = JSON.stringify(getAccompainedByIds(data.travel_with));
        props.handleClose(data);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Add Trip Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="row rounded  py-1">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">
                                                                {(props.travelType === "local" || props.travelType === null) ? "Pick Up Location" : "Departure City"}&nbsp;<span className="danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                placeholder={(props.travelType === "local" || props.travelType === null) ? "Enter Pick Up Location" : "Enter Departure City"}
                                                                {...register('arrival', { required: (props.travelType === "local" || props.travelType === null) ? "Pick Up Location is required" : "Departure City is required" })}
                                                            />
                                                            {errors.arrival?.type === 'required' && <ErrorLabel message={errors.arrival?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">
                                                                {(props.travelType === "local" || props.travelType === null) ? "Drop Location" : "Travelling to City"}&nbsp;<span className="danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                placeholder={(props.travelType === "local" || props.travelType === null) ? "Drop Location" : "Enter Travelling to City"}
                                                                {...register('departure', { required: (props.travelType === "local" || props.travelType === null) ? "Drop Location" : "Enter Travelling to City" })}
                                                            />
                                                            {errors.departure?.type === 'required' && <ErrorLabel message={errors.departure?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Travel Date&nbsp;<span className="danger">*</span></label>
                                                            <Controller
                                                                name={'travel_date'}
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DateBox
                                                                        defaultValue={new Date()}
                                                                        calendarOptions={{
                                                                            showTodayButton: true,
                                                                        }}
                                                                        id="travel_date"
                                                                        name="travel_date"
                                                                        stylingMode="underlined"
                                                                        className="form-control"
                                                                        pickerType="calendar"
                                                                        type="date"
                                                                        displayFormat="dd/MM/yyyy"
                                                                        value={field.value}
                                                                        min={minTravelDate}
                                                                        acceptCustomValue={false}
                                                                        onValueChange={(value) => {
                                                                            var objTravelDate = new Date(value);
                                                                            var objReturnDate = new Date(returnDate);

                                                                            if (objReturnDate.getTime() < objTravelDate.getTime()) {
                                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                setValue('return_date', null);
                                                                                setMinReturnDate(dateFormat(value, 'yyyy-mm-dd'))

                                                                            } else {
                                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                setReturnDateDisable(false)
                                                                                setMinReturnDate(dateFormat(value, 'yyyy-mm-dd'))
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Travel Date is required'
                                                                }}
                                                            />
                                                            {errors.travel_date && <ErrorLabel message={errors.travel_date?.message} />}

                                                        </div>
                                                    </div>
                                                    {
                                                        props.tripType === 'round_trip' && props.travelType !== 'local' ?
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">Return Date&nbsp;<span className="danger">*</span></label>
                                                                    <Controller
                                                                        name={'return_date'}
                                                                        control={control}
                                                                        render={({ field }) =>
                                                                            <DateBox
                                                                                defaultValue={new Date()}
                                                                                calendarOptions={{
                                                                                    showTodayButton: true,
                                                                                }}
                                                                                id="return_date"
                                                                                name="return_date"
                                                                                stylingMode="underlined"
                                                                                className="form-control"
                                                                                pickerType="calendar"
                                                                                type="date"
                                                                                displayFormat="dd/MM/yyyy"
                                                                                disabled={returnDateDisable}
                                                                                value={field.value}
                                                                                min={minReturnDate}
                                                                                acceptCustomValue={false}
                                                                                onValueChange={(value) => {
                                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                    setReturnDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                                }}
                                                                            />
                                                                        }
                                                                        rules={{
                                                                            required: 'Travel Date is required'
                                                                        }}
                                                                    />
                                                                    {errors.return_date && <ErrorLabel message={errors.return_date?.message} />}

                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h6 className="d-flex text-bold-700 black mb-1 ml-0">
                                                            Select needed travel arrangements
                                                        </h6>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="row d-flex">
                                                            <div className="form-group">
                                                                <div className="col-12 col-md-2 col-lg-2">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="flight"
                                                                            id="flight"
                                                                            class="custom-control-input"
                                                                            {...register('flight')}
                                                                        />
                                                                        {errors.flight && <ErrorLabel message={errors.flight?.message} />}
                                                                        <label class="custom-control-label" for="flight">Flight</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="custom-control-input"
                                                                        name="hotel"
                                                                        id="hotel"
                                                                        {...register('hotel')}
                                                                    />
                                                                    {errors.hotel && <ErrorLabel message={errors.hotel?.message} />}
                                                                    <label class="custom-control-label" for="hotel">Hotel</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="custom-control-input"
                                                                        name="car"
                                                                        id="car"
                                                                        {...register('car')}
                                                                    />
                                                                    {errors.car && <ErrorLabel message={errors.car?.message} />}
                                                                    <label class="custom-control-label" for="car">Rental&nbsp;Car</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="custom-control-input"
                                                                        name="train_bus"
                                                                        id="train_bus"
                                                                        {...register('train_bus')}
                                                                    />
                                                                    {errors.train_bus && <ErrorLabel message={errors.train_bus?.message} />}
                                                                    <label class="custom-control-label" for="train_bus">Train&nbsp;Bus</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className={`custom-control custom-checkbox ${props.travelType === 'international' ? '' : 'hidden'}`}>
                                                                    <input
                                                                        type="checkbox"
                                                                        class="custom-control-input"
                                                                        name="visa"
                                                                        id="visa"
                                                                        {...register('visa')}
                                                                    />
                                                                    <label class="custom-control-label" for="visa">Visa</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className={`custom-control custom-checkbox ${props.travelType === 'international' ? '' : 'hidden'}`}>
                                                                    <input
                                                                        type="checkbox"
                                                                        class="custom-control-input"
                                                                        name="insurance"
                                                                        id="insurance"
                                                                        {...register('insurance')}
                                                                    />
                                                                    <label class="custom-control-label" for="insurance">Travel Insurance</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                        <div className="form-group">
                                                            <label className="label-control">Accompanied By</label>
                                                            <Controller
                                                                control={control}
                                                                name="travel_with"
                                                                render={({ field }) => {
                                                                    return (
                                                                        <Select
                                                                            {...field}
                                                                            id="travel_with"
                                                                            name="travel_with"
                                                                            placeholder="Select"
                                                                            menuPlacement={"top"}
                                                                            isMulti={true}
                                                                            hideSelectedOptions={false}
                                                                            maxMenuHeight={200}
                                                                            options={accompainedBy}
                                                                            value={field.value}
                                                                            onChange={(selectedOptions) => {
                                                                                field.onChange(selectedOptions);
                                                                            }}
                                                                        />
                                                                    )
                                                                }}
                                                            />
                                                            {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-2 pt-0 float-none">
                                        <button
                                            id="save-trip"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="trip-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    var AccompainedBy = null;

    if (
        state.TripDetailsReducer != null &&
        Object.keys(state.TripDetailsReducer).length > 0 &&
        state.TripDetailsReducer.constructor === Object
    ) {
        switch (state.TripDetailsReducer.tag) {
            case ACTION_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.TripDetailsReducer.data);
                delete state.TripDetailsReducer;
                return { AccompainedBy };
            default:
        }
    }

    return {
        AccompainedBy,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAccompainedBy }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TripDetails))

