import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { AutoCompleteComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import Swal from 'sweetalert2';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import $ from 'jquery';
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionMasterStates, ACTION_EDIT_MASTER_STATES_SUCCESS } from "./action";
import { actionMasterCities, ACTION_EDIT_MASTER_CITIES_SUCCESS } from "./action";
import { actionMasterEditInsurer, ACTION_MASTER_EDIT_INSURER_SUCCESS } from "./action";

const InsurerMasterEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [insureDetails, setInsurerDetails] = useState(null);
    const [masterState, setMasterState] = useState([]);
    const [masterCity, setMasterCity] = useState([]);

    const initialRender = () => {
        if (props?.location?.state?.detail != null) {
            setInsurerDetails(props?.location?.state?.detail);
        }
        props.actionMasterStates();
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (insureDetails != null) {
            setValue('type', insureDetails.type)
            setValue('name', insureDetails.name)
            setValue('category', insureDetails.category)
            setValue('status', insureDetails.status)
            setValue('short_name', insureDetails.short_name)
            setValue('head_office_state_id', insureDetails.head_office_state_id)
            setValue('head_office_city_id', insureDetails.head_office_city_id)
            setValue('head_office_address', insureDetails.head_office_address)
            setValue('website', insureDetails.website)
            setValue('insurer_id', insureDetails.insurer_id)
        }
    }, [insureDetails])

    useEffect(() => {
        if (masterState != null && masterState.length > 0) {
            if (insureDetails != null && insureDetails.head_office_state_id != null) {
                setValue('head_office_state_id', insureDetails.head_office_state_id)
            }
        }
    }, [masterState])

    const onMasterStatesUpdate = () => {
        const { MasterStates } = props
        if (MasterStates != null) {
            var MasterStatesResponse = Object.assign({}, MasterStates);
            if (MasterStatesResponse.result === true) {
                setMasterState(MasterStatesResponse.response);
            } else {
                switch (MasterStatesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterState([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterStatesUpdate, [props.MasterStates])

    useEffect(() => {
        if (masterCity != null && masterCity.length > 0) {
            if (insureDetails != null && insureDetails.head_office_city_id != null) {
                setValue('head_office_city_id', insureDetails.head_office_city_id)
            }
        }
    }, [masterCity])

    const onMasterCitiesUpdate = () => {
        const { MasterCities } = props
        if (MasterCities != null) {
            var MasterCitiesResponse = Object.assign({}, MasterCities);
            if (MasterCitiesResponse.result === true) {
                setMasterCity(MasterCitiesResponse.response);
            } else {
                switch (MasterCitiesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterCity([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.MasterCities])

    const onMasterEditInsurerUpdate = () => {
        const { MasterEditInsurer } = props
        if (MasterEditInsurer != null) {
            var MasterEditInsurerResponse = Object.assign({}, MasterEditInsurer);
            $("#edit_insurer").prop("disabled", false);
            if (MasterEditInsurerResponse.result === true) {
                $("#insurer-edit-form-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Insurer Created Successfully");
                props.history.goBack();
            } else {
                $("#insurer-edit-form-loader").addClass("hidden");
                switch (MasterEditInsurerResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterEditInsurerUpdate, [props.MasterEditInsurer])

    const onSubmit = (data) => {
        if (selectedStatus === 'INACTIVE') {
            Swal.fire({
                icon: 'warning',
                text: 'Are you sure, you want to Disable the Insurer?. Any future entries against the Insurance company will be restricted',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Ok',
                confirmButtonColor: PRIMARY_COLOR,
            }).then(success => {
                if (success.isConfirmed) {
                    $("#insurer-edit-form-loader").removeClass("hidden");
                    $("#edit_insurer").prop("disabled", true);
                    data.id = insureDetails?.id;
                    props.actionMasterEditInsurer(data);
                }
            });
        } else {
            $("#insurer-edit-form-loader").removeClass("hidden");
            $("#edit_insurer").prop("disabled", true);
            data.id = insureDetails?.id;
            props.actionMasterEditInsurer(data);
        }


    }

    return (
        <>
            <ScreenTitle title="Edit Insurer" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="type"
                                                                name="type"
                                                                dataSource={[
                                                                    {
                                                                        value: 'PRIVATE',
                                                                        text: 'PRIVATE'
                                                                    },
                                                                    {
                                                                        value: 'PUBLIC',
                                                                        text: 'PUBLIC'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type is required'
                                                        }}
                                                    />

                                                    {errors.type && <ErrorLabel message={errors.type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Category&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="category"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="category"
                                                                name="category"
                                                                dataSource={[
                                                                    {
                                                                        value: 'GENERAL INSURANCE',
                                                                        title: 'GENERAL INSURANCE'

                                                                    },
                                                                    {
                                                                        value: 'LIFE INSURANCE',
                                                                        title: 'LIFE INSURANCE'
                                                                    },
                                                                    {
                                                                        value: 'STANDALONE HEALTH INSURANCE',
                                                                        title: 'STANDALONE HEALTH INSURANCE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Category"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Category is required'
                                                        }}
                                                    />

                                                    {errors.category && <ErrorLabel message={errors.category?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Insurer Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        maxLength={200}
                                                        placeholder="Complete Name register with IRDAI"
                                                        {...register('name', {
                                                            required: 'Insurer Name is required'
                                                        })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Insurer Short Name&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="short_name"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <AutoCompleteComponent
                                                                {...field}
                                                                dataSource={insureDetails}
                                                                fields={{ title: 'short_name', value: 'short_name' }}
                                                                value={field.value}
                                                                placeholder="Select Insurer Short Name"
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData?.short_name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Company Group Name'
                                                        }}
                                                    />
                                                    {errors.short_name && <ErrorLabel message={errors.short_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Head Office State&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="head_office_state_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="head_office_state_id"
                                                                name="head_office_state_id"
                                                                dataSource={masterState}
                                                                allowFiltering={true}
                                                                placeholder="Select Head Office State"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        props.actionMasterCities({ state_ids: [event.itemData.id] })
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Head Office State is required'
                                                        }}
                                                    />

                                                    {errors.head_office_state_id && <ErrorLabel message={errors.head_office_state_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Head Office City&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="head_office_city_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="head_office_city_id"
                                                                name="head_office_city_id"
                                                                dataSource={masterCity}
                                                                allowFiltering={true}
                                                                placeholder="Select Head Office City"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Head Office City is required'
                                                        }}
                                                    />

                                                    {errors.head_office_city_id && <ErrorLabel message={errors.head_office_city_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Head Office Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="head_office_address"
                                                        name="head_office_address"
                                                        maxLength={300}
                                                        placeholder="Address with pincode"
                                                        {...register('head_office_address', {
                                                            required: 'Head Office Address is required'
                                                        })}
                                                    />
                                                    {errors.head_office_address && <ErrorLabel message={errors.head_office_address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Insurer Website&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="website"
                                                        name="website"
                                                        placeholder="Enter Insurer Website"
                                                        {...register('website', {
                                                            required: 'Insurer Website is required'
                                                        })}
                                                    />
                                                    {errors.website && <ErrorLabel message={errors.website?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Insurer Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        value: 'ACTIVE',
                                                                        title: 'ACTIVE'

                                                                    },
                                                                    {
                                                                        value: 'INACTIVE',
                                                                        title: 'INACTIVE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setSelectedStatus(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Insure Id</label>
                                                    <input
                                                        className="form-control"
                                                        id="insurer_id"
                                                        name="insurer_id"
                                                        disabled={true}
                                                        {...register('insurer_id')}
                                                    />
                                                    {errors.insurer_id && <ErrorLabel message={errors.insurer_id?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_insurer"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="insurer-edit-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterStates = null;
    var MasterCities = null;
    var MasterEditInsurer = null;

    if (
        state.MasterEditInsurerReducer != null &&
        Object.keys(state.MasterEditInsurerReducer).length > 0 &&
        state.MasterEditInsurerReducer.constructor === Object
    ) {
        switch (state.MasterEditInsurerReducer.tag) {
            case ACTION_EDIT_MASTER_STATES_SUCCESS:
                MasterStates = Object.assign({}, state.MasterEditInsurerReducer.data);
                delete state.MasterEditInsurerReducer;
                return { MasterStates };
            case ACTION_EDIT_MASTER_CITIES_SUCCESS:
                MasterCities = Object.assign({}, state.MasterEditInsurerReducer.data);
                delete state.MasterEditInsurerReducer;
                return { MasterCities };
            case ACTION_MASTER_EDIT_INSURER_SUCCESS:
                MasterEditInsurer = Object.assign({}, state.MasterEditInsurerReducer.data);
                delete state.MasterEditInsurerReducer;
                return { MasterEditInsurer };
            default:

        }
    }
    return {
        MasterStates,
        MasterCities,
        MasterEditInsurer
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterStates, actionMasterCities, actionMasterEditInsurer }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(InsurerMasterEdit))