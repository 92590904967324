import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import Swal from "sweetalert2";
import { FiEye } from 'react-icons/fi';
import $ from "jquery";
import { UNAUTHORIZED } from "../../../../../../../constants";
import { actionInsurerViewTabClients, ACTION_INSURER_VIEW_TAB_CLIENTS_SUCCESS } from "./action";

const TabClients = (props) => {

    const [clients, setclients] = useState();

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "client_saiba_id", caption: "Cust Code" },
        { dataField: "customer_name", caption: "Customer Name" },
        { dataField: "group_name", caption: "Group Name" },
        { dataField: "email", caption: "Email" },
        { dataField: "mobile", caption: "Mobile" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionInsurerViewTabClients({
            insurer_id: props.insurerId
        })
    }
    useEffect(initialRender, [props.tab]);

    const onInsurerViewTabClients = () => {
        const { TabClients } = props;
        if (TabClients != null) {

            var TabClientsResponse = Object.assign({}, TabClients);

            if (TabClientsResponse.result === true) {

                $("#progress-loader").addClass("hidden");

                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "client_saiba_id", caption: "Cust Code" },
                    { dataField: "customer_name", caption: "Customer Name" },
                    { dataField: "group_name", caption: "Group Name" },
                    { dataField: "email", caption: "Email" },
                    { dataField: "mobile", caption: "Mobile" },
                ]);

                setclients(TabClientsResponse.response);

            } else {

                $("#progress-loader").addClass("hidden");

                switch (TabClientsResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout();
                        })
                        break;

                    default:
                }
            }
        }
    }
    useEffect(onInsurerViewTabClients, [props.TabClients]);

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>Open</div>;
            case 'close':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>Close</div>;
            case 'cancel':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>Cancel</div>;
            default:
                return <></>
        }
    }

    const renderGroupNameCell = (cell) => {
        if (cell?.data?.group_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/client/group-level-view',
                        state: { client_group_id: cell?.data?.client_group_id }
                    });
                }}
            ><u>{cell?.data?.group_name}</u></a>
        }
    }

    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.customer_name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                    if (cell?.data?.client_type === 'lead') {
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                    } else if (cell?.data?.client_type === 'client') {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                    }
                })}
            ><u>{cell?.data?.customer_name}</u></a>
        }
    }

    const renderColumns = () => {

        var arrColumns = [];

        columns.forEach((objColumn, indexColumn) => {

            if (objColumn.dataField === "Action") {

                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm float-left"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: '/client/view',
                                            state: {
                                                client_id: params.data.id,
                                            }
                                        });
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else if (objColumn.dataField === "group_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderGroupNameCell}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "customer_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderCompanyNameCell}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    const renderListUI = () => {
        return (
            <DataGrid
                dataSource={clients}
                showBorders={true}
                export={{
                    fileName: "clients",
                    enabled: clients != null && clients.length > 0 ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: 'before'

                    })
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    return (
        <div className="tab-pane active" id="clients" aria-labelledby="clients-tab" role="tabpanel">
            <div className="card">
                <div className="card-body">
                    <div className="row my-1">
                        <div className="col-12 col-md-12 col-lg-12">
                            {
                                clients != null && clients.length > 0 ?
                                    renderListUI()
                                    :
                                    <h6 className="text-bold-500 text-center">No Client Details</h6>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {

    var TabClients = null;

    if (
        state.InsurerViewTabClientsReducer != null &&
        Object.keys(state.InsurerViewTabClientsReducer).length > 0 &&
        state.InsurerViewTabClientsReducer.constructor === Object
    ) {
        switch (state.InsurerViewTabClientsReducer.tag) {
            case ACTION_INSURER_VIEW_TAB_CLIENTS_SUCCESS:
                TabClients = Object.assign({}, state.InsurerViewTabClientsReducer.data);
                delete state.InsurerViewTabClientsReducer;
                return { TabClients };
            default:
        }
    }
    return {
        TabClients

    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionInsurerViewTabClients }, dispatch);
}
export default (connect(mapStateToProps, mapDispatchToProps)(TabClients));