import createReducer from "../../../../../../../reducers/createReducer";
import {ACTION_INSURER_VIEW_TAB_CLIENTS_SUCCESS} from './action';

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const InsurerViewTabClientsReducer = createReducer(INITIAL_STATE, {
    [ACTION_INSURER_VIEW_TAB_CLIENTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INSURER_VIEW_TAB_CLIENTS_SUCCESS,
                loading: false,
            }
        );
    },
});
export default InsurerViewTabClientsReducer;
