import createReducer from "../../../../../reducers/createReducer";
import {ACTION_DELETE_NOTIFICATIONS_SUCCESS} from './action'
import {ACTION_USER_NOTIFICATIONS_SUCCESS} from './action'
import {ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS} from "./action";
import {ACTION_READ_NOTIFICATIONS_SUCCESS} from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const UserNotificationReducer = createReducer(INITIAL_STATE, {
    [ACTION_USER_NOTIFICATIONS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_USER_NOTIFICATIONS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DELETE_NOTIFICATIONS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DELETE_NOTIFICATIONS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_READ_NOTIFICATIONS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_READ_NOTIFICATIONS_SUCCESS,
                loading: false,
            }
        );
    }
});
export default UserNotificationReducer;