import {
    ACTION_MYPROFILE_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
    ACTION_MYPROFILE_ACTIVITYFEEDDELETE_SUCCESS,
    ACTION_MYPROFILE_ACTIVITYFEEDLIKE_SUCCESS,
    ACTION_MYPROFILE_ACTIVITYFEEDLIST_SUCCESS,
    ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
    ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
    ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTEDIT_SUCCESS
  } from "./action";
  import createReducer from "../../../../../../reducers/createReducer";
  
  const INITIAL_STATE = {
    data: [],
    loading: false,
  };
  
  const MyprofilePostReducer = createReducer(INITIAL_STATE, {
    [ACTION_MYPROFILE_ACTIVITYFEEDLIST_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_MYPROFILE_ACTIVITYFEEDLIST_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_MYPROFILE_ACTIVITYFEEDLIKE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_MYPROFILE_ACTIVITYFEEDLIKE_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_MYPROFILE_ACTIVITYFEEDDELETE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_MYPROFILE_ACTIVITYFEEDDELETE_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_MYPROFILE_ACTIVITYFEEDCREATECOMMENT_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_MYPROFILE_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTLIKE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTEDIT_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTEDIT_SUCCESS,
          loading: false,
        }
      );
    },
    [ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTDELETE_SUCCESS](state, action) {
      return Object.assign(
        {},
        {
          ...state,
          data: action.payload.data,
          tag: ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
          loading: false,
        }
      );
    },
  });
  export default MyprofilePostReducer;
  