import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REIMBURSEMENT_POOL_VIEW } from '../../../../../../api/constants';
import { API_REIMBURSEMENT_CHANGE_STATUS, API_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT } from '../../../../../../api/constants';

export const ACTION_REIMBURSEMENT_POOL_VIEW_LOADING = 'ACTION_REIMBURSEMENT_POOL_VIEW_LOADING';
export const ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS = 'ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS';

export const ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_LOADING = 'ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_LOADING';
export const ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS = 'ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS';

export const ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_LOADING = 'ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_LOADING';
export const ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS = 'ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS';

export function actionReimbursementView(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_POOL_VIEW, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_POOL_VIEW_LOADING, { loading: false, data }));
        });
}
export function actionReimbursementPoolViewChangeStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_CHANGE_STATUS, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_LOADING, { loading: false, data }));
        });
}
export function actionReimbursementPoolPhysicalDocument() {
    return (dispatch, getState) =>
        Api.get(API_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_LOADING, { loading: false, data }));
        });
}