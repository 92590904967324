import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_FINANCE_LIMIT_EDIT } from '../../../../../../api/constants';
import { API_MASTER_ENTITY } from '../../../../../../api/constants';
import { API_MASTER_SUB_ENTITY } from '../../../../../../api/constants';
import { API_MASTER_DESIGNATION, API_NATURE_EXPENCES_LIST } from '../../../../../../api/constants';
import { API_FINANCE_LIMIT_VIEW } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_FINANCE_LIMIT_EDIT_LOADING = 'ACTION_FINANCE_LIMIT_EDIT_LOADING';
export const ACTION_FINANCE_LIMIT_EDIT_SUCCESS = 'ACTION_FINANCE_LIMIT_EDIT_SUCCESS';

export const ACTION_FINANCE_LIMIT_ENTITY_EDIT_LOADING = 'ACTION_FINANCE_LIMIT_ENTITY_EDIT_LOADING';
export const ACTION_FINANCE_LIMIT_ENTITY_EDIT_SUCCESS = 'ACTION_FINANCE_LIMIT_ENTITY_EDIT_SUCCESS';

export const ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_LOADING = 'ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_LOADING';
export const ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_SUCCESS = 'ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_SUCCESS';

export const ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_LOADING = 'ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_LOADING';
export const ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_SUCCESS = 'ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_SUCCESS';

export const ACTION_REIMBURSEMENT_TYPE_EDIT_LOADING = 'ACTION_REIMBURSEMENT_TYPE_EDIT_LOADING';
export const ACTION_REIMBURSEMENT_TYPE_EDIT_SUCCESS = 'ACTION_REIMBURSEMENT_TYPE_EDIT_SUCCESS';

export const ACTION_FINANCE_LIMIT_VIEW_LOADING = 'ACTION_FINANCE_LIMIT_VIEW_LOADING';
export const ACTION_FINANCE_LIMIT_VIEW_SUCCESS = 'ACTION_FINANCE_LIMIT_VIEW_SUCCESS';

export function actionFinanceLimitEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_FINANCE_LIMIT_EDIT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionMasterEntityEdit(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_ENTITY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_ENTITY_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_ENTITY_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionFinanceMasterDepartmentEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_SUB_ENTITY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionFinanceMasterDesignationEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_DESIGNATION, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionFinanceLimitView(params) {
    return (dispatch, getState) =>
        Api.post(API_FINANCE_LIMIT_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionGetReimbursementType() {
    return (dispatch, getState) =>
        Api.get(API_NATURE_EXPENCES_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_TYPE_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_TYPE_EDIT_LOADING, { loading: false, data }));
        });
}