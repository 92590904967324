
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_MASTER_DESIGNATION, API_MASTER_ENTITY } from '../../../../../api/constants';
import { API_EMPLOYEE_EDIT } from '../../../../../api/constants';
import { API_EMPLOYEE_DETAILS } from '../../../../../api/constants';
import { API_MASTER_SUB_ENTITY } from '../../../../../api/constants';
import { API_MASTER_BRANCH_CITY } from '../../../../../api/constants';
import { API_MASTER_DEPARTMENT } from '../../../../../api/constants';
import { API_MASTER_GRADE } from '../../../../../api/constants';
import { API_MASTER_BRANCH_ADDRESS } from '../../../../../api/constants';
import { API_MASTER_GET_REPORTING } from '../../../../../api/constants';
import { API_MASTER_JOB_ROLE } from '../../../../../api/constants';
import { API_MASTER_TEAM_HEAD } from '../../../../../api/constants';
import { API_MASTER_APPROVAL_MANAGER } from '../../../../../api/constants';
import { API_MASTER_ASSET_APPROVAL_MANAGER } from '../../../../../api/constants';
import { API_MASTER_FINANCE_APPROVAL_MANAGER } from '../../../../../api/constants';
import { API_MASTER_TRAVEL_APPROVAL_MANAGER } from '../../../../../api/constants';
import { API_MASTER_ATTENDANCE_APPROVAL_MANAGER } from '../../../../../api/constants';
import { API_MASTER_MEETING_APPROVAL_MANAGER } from '../../../../../api/constants';
import { API_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER } from '../../../../../api/constants';
import { API_DELETE_DOCUMENT } from '../../../../../api/constants';
import { API_GETROLESDROPDOWNLIST } from '../../../../../api/constants';
import { API_EMPLOYEE_SHIFT_TIMINGS } from '../../../../../api/constants';
import { API_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';
import { API_GET_DEPARTMENT } from '../../../../../api/constants';

export const ACTION_CLAIMS_POLICY_TYPE_LOADING = 'ACTION_CLAIMS_POLICY_TYPE_LOADING';
export const ACTION_CLAIMS_POLICY_TYPE_SUCCESS = 'ACTION_CLAIMS_POLICY_TYPE_SUCCESS';

export const ACTION_EMPLOYEE_DETAIL_LOADING = 'ACTION_EMPLOYEE_DETAIL_LOADING';
export const ACTION_EMPLOYEE_DETAIL_SUCCESS = 'ACTION_EMPLOYEE_DETAIL_SUCCESS';

export const ACTION_EMPLOYEE_EDIT_LOADING = 'ACTION_EMPLOYEE_EDIT_LOADING';
export const ACTION_EMPLOYEE_EDIT_SUCCESS = 'ACTION_EMPLOYEE_EDIT_SUCCESS';

export const ACTION_API_MASTER_ENTITY_LOADING = 'ACTION_API_MASTER_ENTITY_LOADING';
export const ACTION_API_MASTER_ENTITY_SUCCESS = 'ACTION_API_MASTER_ENTITY_SUCCESS';

export const ACTION_API_MASTER_SUB_ENTITY_LOADING = 'ACTION_API_MASTER_SUB_ENTITY_LOADING';
export const ACTION_API_MASTER_SUB_ENTITY_SUCCESS = 'ACTION_API_MASTER_SUB_ENTITY_SUCCESS';

export const ACTION_API_MASTER_BRANCH_CITY_LOADING = 'ACTION_API_MASTER_BRANCH_CITY_LOADING';
export const ACTION_API_MASTER_BRANCH_CITY_SUCCESS = 'ACTION_API_MASTER_BRANCH_CITY_SUCCESS';

export const ACTION_API_MASTER_DEPARTMENT_LOADING = 'ACTION_API_MASTER_DEPARTMENT_LOADING';
export const ACTION_API_MASTER_DEPARTMENT_SUCCESS = 'ACTION_API_MASTER_DEPARTMENT_SUCCESS';

export const ACTION_API_MASTER_GRADE_LOADING = 'ACTION_API_MASTER_GRADE_LOADING';
export const ACTION_API_MASTER_GRADE_SUCCESS = 'ACTION_API_MASTER_GRADE_SUCCESS';

export const ACTION_API_MASTER_BRANCH_ADDRESS_LOADING = 'ACTION_API_MASTER_BRANCH_ADDRESS_LOADING';
export const ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS = 'ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS';

export const ACTION_API_MASTER_GET_REPORTING_LOADING = 'ACTION_API_MASTER_GET_REPORTING_LOADING';
export const ACTION_API_MASTER_GET_REPORTING_SUCCESS = 'ACTION_API_MASTER_GET_REPORTING_SUCCESS';

export const ACTION_API_MASTER_DESIGNATION_LOADING = 'ACTION_API_MASTER_DESIGNATION_LOADING';
export const ACTION_API_MASTER_DESIGNATION_SUCCESS = 'ACTION_API_MASTER_DESIGNATION_SUCCESS';

export const ACTION_API_MASTER_JOB_ROLE_LOADING = 'ACTION_API_MASTER_JOB_ROLE_LOADING';
export const ACTION_API_MASTER_JOB_ROLE_SUCCESS = 'ACTION_API_MASTER_JOB_ROLE_SUCCESS';

export const ACTION_API_MASTER_TEAM_HEAD_LOADING = 'ACTION_API_MASTER_TEAM_HEAD_LOADING';
export const ACTION_API_MASTER_TEAM_HEAD_SUCCESS = 'ACTION_API_MASTER_TEAM_HEAD_SUCCESS';

export const ACTION_API_MASTER_APPROVAL_MANAGER_LOADING = 'ACTION_API_MASTER_APPROVAL_MANAGER_LOADING';
export const ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS = 'ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS';

export const ACTION_MASTER_ASSET_APPROVAL_MANAGER_LOADING = 'ACTION_MASTER_ASSET_APPROVAL_MANAGER_LOADING';
export const ACTION_MASTER_ASSET_APPROVAL_MANAGER_SUCCESS = 'ACTION_MASTER_ASSET_APPROVAL_MANAGER_SUCCESS';

export const ACTION_MASTER_FINANCE_APPROVAL_MANAGER_LOADING = 'ACTION_MASTER_FINANCE_APPROVAL_MANAGER_LOADING';
export const ACTION_MASTER_FINANCE_APPROVAL_MANAGER_SUCCESS = 'ACTION_MASTER_FINANCE_APPROVAL_MANAGER_SUCCESS';

export const ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_LOADING = 'ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_LOADING';
export const ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_SUCCESS = 'ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_SUCCESS';

export const ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_LOADING = 'ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_LOADING';
export const ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_SUCCESS = 'ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_SUCCESS';

export const ACTION_MASTER_MEETING_APPROVAL_MANAGER_LOADING = 'ACTION_MASTER_MEETING_APPROVAL_MANAGER_LOADING';
export const ACTION_MASTER_MEETING_APPROVAL_MANAGER_SUCCESS = 'ACTION_MASTER_MEETING_APPROVAL_MANAGER_SUCCESS';

export const ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_LOADING = 'ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_LOADING';
export const ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_SUCCESS = 'ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_SUCCESS';

export const GETROLESDROPDOWNLIST_LOADING = 'GETROLESDROPDOWNLIST_LOADING';
export const ACTION_GETROLESDROPDOWNLIST_SUCCESS = 'ACTION_GETROLESDROPDOWNLIST_SUCCESS';

export const ACTION_DOCUMENT_DELETE_LOADING = 'ACTION_DOCUMENT_DELETE_LOADING';
export const ACTION_DOCUMENT_DELETE_SUCCESS = 'ACTION_DOCUMENT_DELETE_SUCCESS';

export const EMPLOYEE_SHIFT_TIMINGS_LOADING = 'EMPLOYEE_SHIFT_TIMINGS_LOADING';
export const EMPLOYEE_SHIFT_TIMINGS_SUCCESS = 'EMPLOYEE_SHIFT_TIMINGS_SUCCESS';

export const ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_LOADING = 'ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_LOADING';
export const ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS = 'ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS';
export function actionProposalPolicyType() {
    return (dispatch, getState) =>
        Api.get(API_GET_DEPARTMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_EMPLOYEE_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION EMPLOYEE DETAILS RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_DETAIL_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeEdit(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_EMPLOYEE_EDIT, params, files).then((res) => {
            const data = res;
            PRINT('ACTION EMPLOYEE ADD RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionMasterEntity(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_ENTITY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_ENTITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_ENTITY_LOADING, { loading: false, data }));
        });
}

export function actionMasterSubEntity(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_SUB_ENTITY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_SUB_ENTITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_SUB_ENTITY_LOADING, { loading: false, data }));
        });
}
export function actionMasterBranchCity(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_BRANCH_CITY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_BRANCH_CITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_BRANCH_CITY_LOADING, { loading: false, data }));
        });
}
export function actionMasterDepartment(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_DEPARTMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_DEPARTMENT_LOADING, { loading: false, data }));
        });
}
export function actionMasterGrade(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_GRADE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_GRADE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_GRADE_LOADING, { loading: false, data }));
        });
}
export function actionMasterBranchAddress(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_BRANCH_ADDRESS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_BRANCH_ADDRESS_LOADING, { loading: false, data }));
        });
}
export function actionMasterGetReporting(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_REPORTING, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_GET_REPORTING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_GET_REPORTING_LOADING, { loading: false, data }));
        });
}

export function actionMasterDesignation(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_DESIGNATION, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_DESIGNATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_DESIGNATION_LOADING, { loading: false, data }));
        });
}

export function actionMasterJobRole(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_JOB_ROLE, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_JOB_ROLE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_JOB_ROLE_LOADING, { loading: false, data }));
        });
}

export function actionMasterTeamHead(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_TEAM_HEAD).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_TEAM_HEAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_TEAM_HEAD_LOADING, { loading: false, data }));
        });
}

export function actionMasterApprovalManager(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_APPROVAL_MANAGER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MASTER_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}

export function actionMasterAssetApprovalManager(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ASSET_APPROVAL_MANAGER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSET_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSET_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}

export function actionMasterFinanceApprovalManager(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_FINANCE_APPROVAL_MANAGER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_FINANCE_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_FINANCE_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}

export function actionMasterTravelApprovalManager(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_TRAVEL_APPROVAL_MANAGER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_TRAVEL_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}

export function actionMasterAttendanceApprovalManager(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ATTENDANCE_APPROVAL_MANAGER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ATTENDANCE_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}

export function actionMasterMeetingApprovalManager(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_MEETING_APPROVAL_MANAGER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_MEETING_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_MEETING_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}

export function actionMasterPerformanceObjectiveApprovalManager(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}
export function actionGetRolesDropdownList() {
    return (dispatch, getState) => Api.get(API_GETROLESDROPDOWNLIST).then((res) => {
        const data = res;
        PRINT('GETROLESDROPDOWNLIST ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_GETROLESDROPDOWNLIST_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(GETROLESDROPDOWNLIST_LOADING, { loading: false, data }));
    });
}

export function actionDeleteDocument(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_DOCUMENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOCUMENT_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_DELETE_LOADING, { loading: false, data }));
        });
}

export function actionGetEmployeeShifts() {
    return (dispatch, getState) => Api.get(API_EMPLOYEE_SHIFT_TIMINGS).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(EMPLOYEE_SHIFT_TIMINGS_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(EMPLOYEE_SHIFT_TIMINGS_LOADING, { loading: false, data }));
    });
}

export function actionGetReimbursementFinanceApprovalManager() {
    return (dispatch, getState) => Api.get(API_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_LOADING, { loading: false, data }));
    });
}
