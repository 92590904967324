import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_CLIENT_BUSINESS_SUMMARY } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_CLIENT_BUSINESS_SUMMARY_LOADING = 'ACTION_CLIENT_BUSINESS_SUMMARY_LOADING';
export const ACTION_CLIENT_BUSINESS_SUMMARY_SUCCESS = 'ACTION_CLIENT_BUSINESS_SUMMARY_SUCCESS';

export function actionClientBusinessSummary(params) {
    return (dispatch, getState) => 
    Api.post(API_CLIENT_BUSINESS_SUMMARY, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_CLIENT_BUSINESS_SUMMARY_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_CLIENT_BUSINESS_SUMMARY_LOADING, { loading: false, data }));
    });
}
