import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import $ from "jquery";
import ErrorLabel from '../../../../../../component/form/error_label';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_SUCCESS, actionOpportunityQuestionAddEdit, ACTION_OPPORTUNITY_QUESTION_ADD_LIST_SUCCESS, actionOpportunityQuestionAddList } from './action';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const OpportunityQuestionsAdd = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const addQuestion = false;
    const [questionList, setQuestionList] = useState([]);

    const initialRender = () => {
        props.actionOpportunityQuestionAddList();
    }
    useEffect(initialRender, []);

    const onOpportunityQuestionAddListUpdate = () => {
        const { OpportunityQuestionList } = props
        var OpportunityQuestionListResponse = Object.assign({}, OpportunityQuestionList);
        if (OpportunityQuestionListResponse.result === true) {
            setQuestionList(OpportunityQuestionListResponse.response);
        } else {
            switch (OpportunityQuestionListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onOpportunityQuestionAddListUpdate, [props.OpportunityQuestionList])

    const onOpportunityQuestionAddEditUpdate = () => {
        const { OpportunityQuestionAddEdit } = props
        var OpportunityQuestionAddEditResponse = Object.assign({}, OpportunityQuestionAddEdit);
        if (OpportunityQuestionAddEditResponse.result === true) {
            props.history.goBack();
        } else {
            switch (OpportunityQuestionAddEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.success(OpportunityQuestionAddEditResponse.message)
                default:
            }
        }
    }
    useEffect(onOpportunityQuestionAddEditUpdate, [props.OpportunityQuestionAddEdit])

    const onSubmit = (data) => {
        const arrQuestion = questionList.map(item => {
            const { id, question, yes_value, no_value } = item;
            return { id, question, yes_value, no_value };
        });

        const arrQuestionDetail = arrQuestion.map(item => {
            const newItem = { ...item };
            if (newItem.id === "") {
                delete newItem.id;
            }
            return newItem;
        });

        arrQuestionDetail.map((obj) => {
            if (Number(obj.yes_value) + Number(obj.no_value) > 100) {
                Swal.fire({
                    title: 'Error',
                    text: 'Please enter value below 100',
                    icon: 'error'
                }).then(success => {

                })
            }
            if (obj.yes_value === '' || obj.no_value === '' || obj.question === '') {
                Swal.fire({
                    title: 'Error',
                    text: 'Please enter value',
                    icon: 'error'
                }).then(success => {

                })
            }
        })

        const sumOfYesValue = arrQuestionDetail.reduce((accumulator, currentValue) => {
            const yesValue = Number(currentValue.yes_value);
            if (!isNaN(yesValue)) {
                return accumulator + yesValue;
            }
            return accumulator;
        }, 0);

        const sumOfNoValue = arrQuestionDetail.reduce((accumulator, currentValue) => {
            const noValue = Number(currentValue.no_value);
            if (!isNaN(noValue)) {
                return accumulator + noValue;
            }
            return accumulator;
        }, 0);

        if (sumOfYesValue > 100 || sumOfNoValue > 100) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter value below 100',
                icon: 'error'
            }).then(success => {

            })
        } else {
            data.questions = arrQuestionDetail;
            props.actionOpportunityQuestionAddEdit(data);
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-body">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                            <span>Opportunity Questions Add</span>

                                        </h4>
                                        {
                                            questionList != null && questionList.length > 0 ?
                                                questionList.map((item, index) => (
                                                    <>
                                                        <div className='row'>
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="question"
                                                                        name="question"
                                                                        value={item.question}
                                                                        placeholder='Enter New Question'
                                                                        maxLength={150}
                                                                        onChange={(event) => {
                                                                            var tempMobileArray = Object.assign([], questionList)
                                                                            var objMobile = Object.assign({}, tempMobileArray[index])
                                                                            objMobile.question = event.target.value
                                                                            tempMobileArray[index] = objMobile
                                                                            setQuestionList(tempMobileArray);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">Yes Probability %</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`yes_value${index}`}
                                                                        name="yes_value"
                                                                        placeholder="Enter Yes Probability Value"
                                                                        maxLength={2}
                                                                        value={item.yes_value != null ? String(item.yes_value) : '--'}
                                                                        onChange={(event) => {
                                                                            if (event.target.value === null && index.yes_value === null) {
                                                                                $(`#err-approved-amount-${`yes_value${index}`}`).removeClass('d-none')
                                                                            } else {
                                                                                $(`#err-approved-amount-${`yes_value${index}`}`).addClass('d-none')
                                                                            }
                                                                            var tempMobileArray = Object.assign([], questionList)
                                                                            var objMobile = Object.assign({}, tempMobileArray[index])
                                                                            objMobile.yes_value = event.target.value
                                                                            tempMobileArray[index] = objMobile
                                                                            setQuestionList(tempMobileArray);
                                                                        }}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label id={`err-approved-amount-${`yes_value${index}`}`} className="font-check-label font-small-2 text-danger error-msg d-none">Enter Yes Probability Value</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">No Probability %</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`no_value${index}`}
                                                                        name="no_value"
                                                                        placeholder="Enter No Probability Value"
                                                                        maxLength={2}
                                                                        value={item.no_value != null ? String(item.no_value) : '--'}
                                                                        onChange={(event) => {
                                                                            if (event.target.value > index.no_value) {
                                                                                $(`#err-approved-amount-${`no_value${index}`}`).removeClass('d-none')
                                                                            } else {
                                                                                $(`#err-approved-amount-${`no_value${index}`}`).addClass('d-none')
                                                                            }
                                                                            var tempMobileArray = Object.assign([], questionList)
                                                                            var objMobile = Object.assign({}, tempMobileArray[index])
                                                                            objMobile.no_value = event.target.value
                                                                            tempMobileArray[index] = objMobile
                                                                            setQuestionList(tempMobileArray);
                                                                        }}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label id={`err-approved-amount-${`no_value${index}`}`} className="font-check-label font-small-2 text-danger error-msg d-none">Enter No Probability Value</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                ))
                                                :
                                                <></>

                                        }
                                        {
                                            addQuestion === true ?
                                                <>
                                                    <div className='row'>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="label-control font-medium-1 text-bold-600 mb-0">
                                                                    Enter New Question
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="question"
                                                                    name="question"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    placeholder="Enter Question"
                                                                    {...register('question', {
                                                                        required: "Please enter question"
                                                                    })}
                                                                />
                                                                {errors.question && <ErrorLabel message={errors.question?.message} />}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label className="label-control">Yes Probability %</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`yes_value`}
                                                                    name="yes_value"
                                                                    placeholder="Enter Yes Probability Value"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    {...register('yes_value', {
                                                                        required: "Please enter value"
                                                                    })}
                                                                />
                                                                {errors.yes_value && <ErrorLabel message={errors.yes_value?.message} />}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label className="label-control">No Probability %</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={`no_value`}
                                                                    name="no_value"
                                                                    placeholder="Enter No Probability Value"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    {...register('no_value', {
                                                                        required: "Please enter value"
                                                                    })}
                                                                />
                                                                {errors.no_value && <ErrorLabel message={errors.no_value?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        <div className="content-header-right col-12 col-md-12 col-lg-12">
                                            <ul class="nav nav-pills float-right">
                                                <li class="nav-item">
                                                    <button
                                                        id='add_question'
                                                        name='add_question'
                                                        type='button'
                                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                                        onClick={() => {
                                                            var arrQuestionList = Object.assign([], questionList);
                                                            arrQuestionList.push({
                                                                id: '',
                                                                question: "",
                                                                yes_value: '',
                                                                no_value: '',
                                                            });
                                                            setQuestionList(arrQuestionList);
                                                        }}
                                                    >
                                                        Add
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-actions border-0  float-left">
                                            <button
                                                type="submit"
                                                id="opportunity_question"
                                                name="opportunity_question"
                                                className="btn btn-primary mr-1 mt-0 shadow  text-uppercase"
                                            >Submit
                                                <span id="opportunity_question_loader" className="spinner-border spinner-border-sm ml-half hidden"></span></button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary mr-1 mt-0 shadow  text-uppercase"
                                                data-dismiss="modal"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    props.history.goBack();
                                                }}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div >
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
const mapStateToProps = (state) => {
    var OpportunityQuestionList = null;
    var OpportunityQuestionAddEdit = null
    if (
        state.OpportunityQuestionAddReducer != null &&
        Object.keys(state.OpportunityQuestionAddReducer).length > 0 &&
        state.OpportunityQuestionAddReducer.constructor === Object
    ) {
        switch (state.OpportunityQuestionAddReducer.tag) {
            case ACTION_OPPORTUNITY_QUESTION_ADD_LIST_SUCCESS:
                OpportunityQuestionList = Object.assign({}, state.OpportunityQuestionAddReducer.data);
                delete state.OpportunityQuestionAddReducer;
                return { OpportunityQuestionList };
            case ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_SUCCESS:
                OpportunityQuestionAddEdit = Object.assign({}, state.OpportunityQuestionAddReducer.data);
                delete state.OpportunityQuestionAddReducer;
                return { OpportunityQuestionAddEdit };
            default:

        }
    }
    return {
        OpportunityQuestionList,
        OpportunityQuestionAddEdit
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionOpportunityQuestionAddList, actionOpportunityQuestionAddEdit }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(OpportunityQuestionsAdd))