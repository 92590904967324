import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../../component/form/error_label";
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useForm, Controller } from "react-hook-form";
import dateFormat from 'dateformat';
import { UNAUTHORIZED, characterLimit, ERROR } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionTravelRequestAdd, ACTION_TRAVEL_REQUEST_ADD_SUCCESS } from './action';
import { actionTravelRequestApprovalManager, ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_SUCCESS } from './action';
import { actionTravelRequestUpcomingMeetings, ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_SUCCESS } from './action';
import TripDetails from '../../../../../../component/form/trip_details'
import Swal from 'sweetalert2';
import { FiPlus } from 'react-icons/fi';
import { FaCarSide } from 'react-icons/fa';
import _ from 'lodash';
import { toast } from 'react-toastify';

const TravelRequestAdd = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm();
    const [traveRequestApprovalManager, setTravelRequestApprovalManager] = useState();
    const [upcomingMeetings, setUpcomingMeetings] = useState();
    const [showTripDetails, setShowTripDetails] = useState(false);
    const [showTripDetail, setShowTripDetail] = useState(false);
    const [editLocationIndex, setEditLocationIndex] = useState(-1);
    const [tripTypes, setTripTypes] = useState([{
        value: 'one_way',
        text: 'One Way'
    }]);
    const [selectedTripType, setSelectedTripType] = useState(null);
    const [selectedTravelType, setSelectedTravelType] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    var watchDescription = watch('description');

    const initialRender = () => {
        props.actionTravelRequestApprovalManager({});
        props.actionTravelRequestUpcomingMeetings({});
        setValue('travel_type', 'local');
    }
    useEffect(initialRender, []);

    const onTravelRequestApprovalManagerUpdate = () => {
        const { TravelRequestApprovalManager } = props;
        var TravelRequestApprovalManagerResponse = Object.assign({}, TravelRequestApprovalManager);
        if (TravelRequestApprovalManagerResponse.result === true) {
            setTravelRequestApprovalManager(TravelRequestApprovalManagerResponse.response.managers);
        } else {
            switch (TravelRequestApprovalManagerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onTravelRequestApprovalManagerUpdate, [props.TravelRequestApprovalManager]);

    const onUpcomingMeetingsUpdate = () => {
        const { UpcomingMeetings } = props;
        var UpcomingMeetingsResponse = Object.assign({}, UpcomingMeetings);
        if (UpcomingMeetingsResponse.result === true) {
            setUpcomingMeetings(UpcomingMeetingsResponse.response);
        } else {
            switch (UpcomingMeetingsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onUpcomingMeetingsUpdate, [props.UpcomingMeetings]);


    const setTripType = (trip_type) => {
        switch (trip_type) {
            case 'local':
                setTripTypes([{
                    value: 'one_way',
                    text: 'One Way'
                }]);
                break;
            case 'domestic':
                setTripTypes([{
                    value: 'one_way',
                    text: 'One Way'

                },
                {
                    value: 'round_trip',
                    text: 'Round Trip'
                },
                {
                    value: 'multi_stop',
                    text: 'Multi Stop'
                }]);
                break;
            case 'international':
                setTripTypes([{
                    value: 'one_way',
                    text: 'One Way'

                },
                {
                    value: 'round_trip',
                    text: 'Round Trip'
                },
                {
                    value: 'multi_stop',
                    text: 'Multi Stop'
                }]);
                break;

        }
    }

    const onTravelRequestAddUpdate = () => {
        const { TravelRequestAdd } = props;
        var TravelRequestAddResponse = Object.assign({}, TravelRequestAdd);
        $("#travel-request").prop("disabled", false);
        if (TravelRequestAddResponse.result === true) {
            $("#travel-request-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Travel Request Added Successfully");
            props.history.goBack();
        } else {
            $("#Travel-request-loader").addClass("hidden");
            switch (TravelRequestAddResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Upload Error',
                        text: TravelRequestAddResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onTravelRequestAddUpdate, [props.TravelRequestAdd]);

    const onSubmit = (data) => {

        if (selectedLocations.length > 0) {

            data.user_id = props.auth.getUser().user_id;

            $("#travel-request-loader").removeClass("hidden");
            $("#travel-request").prop("disabled", true);

            data.approval_manager_id = traveRequestApprovalManager.travel_manager_id;
            data.description = data.description;
            data.meeting_id = data.meeting_id;
            data.request_type = data.request_type;
            data.trip = selectedLocations;

            props.actionTravelRequestAdd(data);

        } else {
            Swal.fire({
                title: 'Error',
                text: 'Please add at least one Trip Detail',
                icon: 'error'
            }).then(success => {

            })
        }
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Add New Travel</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Travel Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="travel_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="travel_type"
                                                                name="travel_type"
                                                                dataSource={[
                                                                    {
                                                                        value: 'local',
                                                                        text: 'Local'

                                                                    },
                                                                    {
                                                                        value: 'domestic',
                                                                        text: 'Domestic'
                                                                    },
                                                                    {
                                                                        value: 'international',
                                                                        text: 'International'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Travel Type"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        setTripType(event.itemData.value);
                                                                        setSelectedTravelType(event.itemData.value);
                                                                        field.onChange(event.itemData.value)
                                                                        setSelectedLocations([]);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type is required'
                                                        }}
                                                    />

                                                    {errors.travel_type && <ErrorLabel message={errors.travel_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Travel Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="approval_manager"
                                                        name="approval_manager"
                                                        readOnly={true}
                                                        value={(traveRequestApprovalManager != null && traveRequestApprovalManager.travel_manager_name != null) ? traveRequestApprovalManager.travel_manager_name : ''}
                                                    />
                                                    {errors.approval_manager?.type === 'required' && <ErrorLabel message={errors.approval_manager?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Meeting ID</label>
                                                    <Controller
                                                        name="meeting_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="meeting_id"
                                                                name="meeting_id"
                                                                dataSource={upcomingMeetings}
                                                                allowFiltering={false}
                                                                placeholder="Select Meeting Id"
                                                                value={field.value}
                                                                fields={{ text: 'meeting_details', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Trip Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="trip_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="trip_type"
                                                                name="trip_type"
                                                                dataSource={tripTypes}
                                                                allowFiltering={false}
                                                                placeholder="Select Trip Type"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setSelectedTripType(event.itemData.value);
                                                                        setSelectedLocations([]);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Trip Type is required'
                                                        }}
                                                    />

                                                    {errors.trip_type && <ErrorLabel message={errors.trip_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Travel Reason&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="trip_reason"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="trip_reason"
                                                                name="trip_reason"
                                                                dataSource={[
                                                                    {
                                                                        value: 'client-meeting',
                                                                        text: 'Client Meeting'

                                                                    },
                                                                    {
                                                                        value: 'marketing-visit',
                                                                        text: 'Marketing Visit'
                                                                    },
                                                                    {
                                                                        value: 'branch-visit',
                                                                        text: 'Branch Visit'
                                                                    },
                                                                    {
                                                                        value: "client-travel-arrangements",
                                                                        text: "Client's Travel Arrangement"
                                                                    },
                                                                    {
                                                                        value: 'other',
                                                                        text: 'Other'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Travel Reason is required'
                                                        }}
                                                    />

                                                    {errors.trip_reason && <ErrorLabel message={errors.trip_reason?.message} />}
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <span><FaCarSide size={16} />&nbsp;Trip Details</span>
                                                    {
                                                        (selectedTripType == 'one_way' || selectedTripType == 'round_trip') && selectedLocations.length > 0 ?
                                                            <></>
                                                            :
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={() => {
                                                                    if (selectedTripType != null && selectedTravelType != null) {
                                                                        setShowTripDetails(true)
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Information',
                                                                            text: 'Please Select Travel Type & Trip Type',
                                                                            icon: 'info'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <FiPlus
                                                                    cursor="pointer"
                                                                    size={20}
                                                                />
                                                            </button>
                                                    }

                                                </h4>
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                {
                                                    selectedLocations != null && selectedLocations.length > 0 ?
                                                        <table className={`table table-striped table-bordered  ${selectedTravelType === 'international' || selectedTravelType === 'domestic' ? 'table-responsive' : ''}`}>
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    {
                                                                        selectedTravelType === 'local' ?
                                                                            <>
                                                                                <th>Pick Up Location</th>
                                                                                <th>Drop Location</th>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        selectedTravelType === 'domestic' || selectedTravelType === 'international' ?
                                                                            <>
                                                                                <th>Departure City</th>
                                                                                <th>Travelling to City</th>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    <th>Travel Date</th>
                                                                    {
                                                                        selectedTripType === 'round_trip' ?
                                                                            <th>Return Date</th> :
                                                                            <></>
                                                                    }
                                                                    <th>Flight</th>
                                                                    <th>Hotel</th>
                                                                    <th>Rental Car</th>
                                                                    <th>Train Bus</th>
                                                                    {
                                                                        selectedTravelType === 'international' ?
                                                                            <>
                                                                                <th>Visa</th>
                                                                                <th>Travel Insurance</th>
                                                                            </> :
                                                                            <></>
                                                                    }
                                                                    <th>Accompanied By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    selectedLocations.map((location, indexLocation) => (
                                                                        <tr key={location.id}>
                                                                            <td className="px-0">
                                                                                <FiEdit
                                                                                    size={16}
                                                                                    className="primary cursor-pointer ml-half mr-1"
                                                                                    onClick={() => {
                                                                                        setEditLocationIndex(indexLocation)
                                                                                        setShowTripDetails(true)
                                                                                        setShowTripDetail(true)
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    selectedTripType == 'multi_stop' ?
                                                                                        <FiTrash
                                                                                            size={16}
                                                                                            className="primary cursor-pointer"
                                                                                            onClick={() => {
                                                                                                var locations = Object.assign([], selectedLocations);
                                                                                                locations.splice(indexLocation, 1);
                                                                                                setSelectedLocations(locations);
                                                                                            }}
                                                                                        />
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </td>
                                                                            <td>{location.arrival}</td>
                                                                            <td>{location.departure}</td>
                                                                            <td>{dateFormat(location.travel_date, 'dd/mm/yyyy')}</td>
                                                                            {
                                                                                selectedTripType === 'round_trip' ?
                                                                                    <td>{dateFormat(location.return_date, 'dd/mm/yyyy')}</td> :
                                                                                    <></>
                                                                            }
                                                                            <td>{location.flight == true ? 'Yes' : 'No'}</td>
                                                                            <td>{location.hotel == true ? 'Yes' : 'No'}</td>
                                                                            <td>{location.car == true ? 'Yes' : 'No'}</td>
                                                                            <td>{location.train_bus == true ? 'Yes' : 'No'}</td>
                                                                            {
                                                                                selectedTravelType === 'international' ?
                                                                                    <>
                                                                                        <td>{location.visa == true ? 'Yes' : 'No'}</td>
                                                                                        <td>{location.insurance == true ? 'Yes' : 'No'}</td>
                                                                                    </> :
                                                                                    <></>
                                                                            }
                                                                            <td>
                                                                                {
                                                                                    location.travel_with_object != null ?
                                                                                        _.map(location.travel_with_object, 'full_name').join(", ") : '-'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <></>
                                                }
                                                <ErrorLabel message={selectedLocations.length === 0 ? 'No Trip Details found' : ''} />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mt-1">
                                                <div className="form-group">
                                                    <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Description"
                                                        {...register('description', { required: "Description is Required" })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{watchDescription != null ? watchDescription.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="travel-request"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="travel-request-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/hrms/travel-request');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <TripDetails
                    show={showTripDetails}
                    showDetail={showTripDetail}
                    travelType={selectedTravelType}
                    tripType={selectedTripType}
                    handleClose={(trip) => {
                        if (trip != null) {
                            var arrLocations = Object.assign([], selectedLocations);
                            if (editLocationIndex >= 0) {
                                arrLocations[editLocationIndex] = trip;
                                setEditLocationIndex(-1);
                            } else {
                                arrLocations.push(trip);
                            }
                            setSelectedLocations(arrLocations);
                        } else {
                            setEditLocationIndex(-1);
                        }
                        setShowTripDetails(false);
                    }}
                    data={editLocationIndex > -1 ? selectedLocations[editLocationIndex] : null}
                />
            </section>
        </>
    )

}


const mapStateToProps = (state) => {
    var TravelRequestAdd = null;
    var UpcomingMeetings = null;

    var TravelRequestApprovalManager = null;

    if (
        state.TravelRequestAddReducer != null &&
        Object.keys(state.TravelRequestAddReducer).length > 0 &&
        state.TravelRequestAddReducer.constructor === Object
    ) {
        switch (state.TravelRequestAddReducer.tag) {
            case ACTION_TRAVEL_REQUEST_ADD_SUCCESS:
                TravelRequestAdd = Object.assign({}, state.TravelRequestAddReducer.data);
                delete state.TravelRequestAddReducer;
                return { TravelRequestAdd };
            case ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_SUCCESS:
                TravelRequestApprovalManager = Object.assign({}, state.TravelRequestAddReducer.data);
                delete state.TravelRequestAddReducer;
                return { TravelRequestApprovalManager };
            case ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_SUCCESS:
                UpcomingMeetings = Object.assign({}, state.TravelRequestAddReducer.data);
                delete state.TravelRequestAddReducer;
                return { UpcomingMeetings };
            default:
        }
    }

    return {
        TravelRequestAdd,
        TravelRequestApprovalManager,
        UpcomingMeetings,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTravelRequestAdd, actionTravelRequestApprovalManager, actionTravelRequestUpcomingMeetings }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TravelRequestAdd))
































