import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_DOCUMENT_ADD } from '../../../../../api/constants';
import { API_MASTER_DOCUMENT_CATEGORY } from '../../../../../api/constants';
import { API_MASTER_DOCUMENT_TYPE } from '../../../../../api/constants';
import { API_MASTER_DOCUMENT_APPROVED_BY } from '../../../../../api/constants';

export const ACTION_DOCUMENT_ADD_LOADING = 'ACTION_DOCUMENT_ADD_LOADING';
export const ACTION_DOCUMENT_ADD_SUCCESS = 'ACTION_DOCUMENT_ADD_SUCCESS';

export const ACTION_MASTER_DOCUMENT_CATEGORY_LOADING = 'ACTION_MASTER_DOCUMENT_CATEGORY_LOADING';
export const ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS = 'ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS';

export const ACTION_MASTER_DOCUMENT_TYPE_LOADING = 'ACTION_MASTER_DOCUMENT_TYPE_LOADING';
export const ACTION_MASTER_DOCUMENT_TYPE_SUCCESS = 'ACTION_MASTER_DOCUMENT_TYPE_SUCCESS';

export const ACTION_MASTER_DOCUMENT_APPROVED_BY_LOADING = 'ACTION_MASTER_DOCUMENT_APPROVED_BY_LOADING';
export const ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS = 'ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS';

export function actionDocumentAdd(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_DOCUMENT_ADD,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOCUMENT_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_ADD_LOADING, { loading: false, data }));
        });
}

export function actionDocumentCategory() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_DOCUMENT_CATEGORY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_DOCUMENT_CATEGORY_LOADING, { loading: false, data }));
        });
}

export function actionDocumentApprovedBy() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_DOCUMENT_APPROVED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_DOCUMENT_APPROVED_BY_LOADING, { loading: false, data }));
        });
}

export function actionDocumentType() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_DOCUMENT_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_DOCUMENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_DOCUMENT_TYPE_LOADING, { loading: false, data }));
        });
}