import { API_MEETING_TYPE, API_TPAMEETINGADDCONTACT, API_DELETE_DOCUMENT } from '../../../../../../api/constants';
import { API_SELECT_CUSTOMER } from '../../../../../../api/constants';
import { API_ADDPOLICY } from '../../../../../../api/constants';
import { API_EDITPOLICY } from '../../../../../../api/constants';
import { API_CUSTOMER } from '../../../../../../api/constants';
import { API_PREVIOUS_MEETING } from '../../../../../../api/constants';
import { API_ACCOMPAINED_BY } from '../../../../../../api/constants';
// import { API_MASTER_GET_INSURER } from '../../../../../../api/constants';
import { API_MASTER_INSURANCE_COMPANY_NAME } from '../../../../../../api/constants';
import { API_CORPORATE_CLIENT_DETAILS } from '../../../../../../api/constants';
import { API_CUSTOMER_MEETING_CONTACT } from '../../../../../../api/constants';
import { API_CUSTOMER_MEETING_POLICY } from '../../../../../../api/constants';
import { API_BUSINESS_TYPE } from '../../../../../../api/constants';
import { API_CUSTOMER_MEETING_VIEW } from '../../../../../../api/constants';
import { API_CUSTOMER_MEETING_EDIT } from '../../../../../../api/constants';
import { API_CUSTOMER_MEETING_NEXT } from '../../../../../../api/constants';
import { API_DELETE_POLICY } from '../../../../../../api/constants';
import Api from '../../../../../../api';
// import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_API_MEETING_TYPE_LOADING = 'ACTION_API_MEETING_TYPE_LOADING';
export const ACTION_API_MEETING_TYPE_SUCCESS = 'ACTION_API_MEETING_TYPE_SUCCESS';

export const ACTION_SELECT_CUSTOMER_LOADING = 'ACTION_SELECT_CUSTOMER_LOADING';
export const ACTION_SELECT_CUSTOMER_SUCCESS = 'ACTION_SELECT_CUSTOMER_SUCCESS';

export const ACTION_MASTER_INSURANCE_COMPANY_NAME_LOADING = 'ACTION_MASTER_CUSTOMER_EDIT_INSURANCE_COMPANY_NAME_LOADING';
export const ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS = 'ACTION_MASTER_CUSTOMER_EDIT_INSURANCE_COMPANY_NAME_SUCCESS';

export const ACTION_API_CUSTOMER_LOADING = 'ACTION_API_CUSTOMER_LOADING';
export const ACTION_API_CUSTOMER_SUCCESS = 'ACTION_API_CUSTOMER_SUCCESS';

export const ACTION_API_BUSINESS_TYPE_LOADING = 'ACTION_API_BUSINESS_TYPE_LOADING';
export const ACTION_API_BUSINESS_TYPE_SUCCESS = 'ACTION_API_BUSINESS_TYPE_SUCCESS';

export const ACTION_API_PREVIOUS_MEETING_LOADING = 'ACTION_API_PREVIOUS_MEETING_LOADING';
export const ACTION_API_PREVIOUS_MEETING_SUCCESS = 'ACTION_API_PREVIOUS_MEETING_SUCCESS';

export const ACTION_API_ACCOMPAINED_BY_LOADING = 'ACTION_API_ACCOMPAINED_BY_LOADING';
export const ACTION_API_ACCOMPAINED_BY_SUCCESS = 'ACTION_API_ACCOMPAINED_BY_SUCCESS';

export const ACTION_MASTER_CLIENT_DETAILS_LOADING = 'ACTION_MASTER_CLIENT_DETAILS_LOADING';
export const ACTION_MASTER_CLIENT_DETAILS_SUCCESS = 'ACTION_MASTER_CLIENT_DETAILS_SUCCESS';

export const ACTION_CUSTOMER_MEETING_CONTACT_LOADING = 'ACTION_CUSTOMER_MEETING_CONTACT_LOADING';
export const ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS = 'ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS';

export const ACTION_CUSTOMER_MEETING_POLICY_LOADING = 'ACTION_CUSTOMER_MEETING_POLICY_LOADING';
export const ACTION_CUSTOMER_MEETING_POLICY_SUCCESS = 'ACTION_CUSTOMER_MEETING_POLICY_SUCCESS';

export const ACTION_CUSTOMER_MEETING_VIEW_LOADING = 'ACTION_CUSTOMER_MEETING_VIEW_LOADING';
export const ACTION_CUSTOMER_MEETING_VIEW_SUCCESS = 'ACTION_CUSTOMER_MEETING_VIEW_SUCCESS';

export const ACTION_CUSTOMER_MEETING_EDIT_LOADING = 'ACTION_CUSTOMER_MEETING_EDIT_LOADING';
export const ACTION_CUSTOMER_MEETING_EDIT_SUCCESS = 'ACTION_CUSTOMER_MEETING_EDIT_SUCCESS';

export const ADD_POLICY_LOADING = 'ADD_POLICY_LOADING';
export const ACTION_ADD_POLICY_SUCCESS = 'ACTION_ADD_POLICY_SUCCESS';

export const EDIT_POLICY_LOADING = 'EDIT_POLICY_LOADING';
export const ACTION_EDIT_POLICY_SUCCESS = 'ACTION_EDIT_POLICY_SUCCESS';

export const CUSTOMER_MEETING_ADD_CONTACT_LOADING = 'CUSTOMER_MEETING_ADD_CONTACT_LOADING';
export const ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS = 'ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS';

export const ACTION_CUSTOMER_MEETING_SAVE_LOADING = 'ACTION_CUSTOMER_MEETING_SAVE_LOADING';
export const ACTION_CUSTOMER_MEETING_SAVE_SUCCESS = 'ACTION_CUSTOMER_MEETING_SAVE_SUCCESS';

export const ACTION_DOCUMENT_DELETE_LOADING = 'ACTION_DOCUMENT_DELETE_LOADING';
export const ACTION_DOCUMENT_DELETE_SUCCESS = 'ACTION_DOCUMENT_DELETE_SUCCESS';

export const ACTION_DELETE_POLICY_CUSTOMER_MEETING_LOADING = 'ACTION_DELETE_POLICY_CUSTOMER_MEETING_LOADING';
export const ACTION_DELETE_POLICY_CUSTOMER_MEETING_SUCCESS = 'ACTION_DELETE_POLICY_CUSTOMER_MEETING_SUCCESS';

export function actionMeetingType() {
    return (dispatch, getState) =>
        Api.get(API_MEETING_TYPE).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_MEETING_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_MEETING_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionSelectCustomer(params) {
    return (dispatch, getState) =>
        Api.post(API_SELECT_CUSTOMER, params).then((res) => {
            const data = res;
            // PRINT('ACTION SELECT CUSTOMER RESPONSE', data)
            dispatch(fetchSuccess(ACTION_SELECT_CUSTOMER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SELECT_CUSTOMER_LOADING, { loading: false, data }));
        });
}

export function actionCustomer(params) {
    return (dispatch, getState) =>
        Api.post(API_CUSTOMER, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_CUSTOMER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_CUSTOMER_LOADING, { loading: false, data }));
        });
}

export function actionBusinessType() {
    return (dispatch, getState) =>
        Api.get(API_BUSINESS_TYPE).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_BUSINESS_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_BUSINESS_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionPreviousMeeting(params) {
    return (dispatch, getState) =>
        Api.post(API_PREVIOUS_MEETING, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_PREVIOUS_MEETING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_PREVIOUS_MEETING_LOADING, { loading: false, data }));
        });
}

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionGetClientDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CORPORATE_CLIENT_DETAILS, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_CLIENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CLIENT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionCustomerMeetingContact(params) {
    return (dispatch, getState) =>
        Api.post(API_CUSTOMER_MEETING_CONTACT, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CUSTOMER_MEETING_CONTACT_LOADING, { loading: false, data }));
        });
}
export function actionCustomerMeetingPolicy(params) {
    return (dispatch, getState) =>
        Api.post(API_CUSTOMER_MEETING_POLICY, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CUSTOMER_MEETING_POLICY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CUSTOMER_MEETING_POLICY_LOADING, { loading: false, data }));
        });
}

export function actionCustomerMeetingsView(params) {
    return (dispatch, getState) =>
        Api.post(API_CUSTOMER_MEETING_VIEW, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CUSTOMER_MEETING_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CUSTOMER_MEETING_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionCustomerMeetingsEdit(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_CUSTOMER_MEETING_EDIT, params, files).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CUSTOMER_MEETING_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CUSTOMER_MEETING_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionMasterInsuranceCompanyName(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_INSURANCE_COMPANY_NAME).then((res) => {
            // Api.post(API_MASTER_GET_INSURER, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_INSURANCE_COMPANY_NAME_LOADING, { loading: false, data }));
        });
}

export function actionAddPolicy(params) {
    return (dispatch, getState) => Api.post(API_ADDPOLICY, params).then((res) => {
        const data = res;
        // PRINT('ADDPOLICY ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_ADD_POLICY_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ADD_POLICY_LOADING, { loading: false, data }));
    });
}

export function actionEditPolicy(params) {
    return (dispatch, getState) => Api.post(API_EDITPOLICY, params).then((res) => {
        const data = res;
        // PRINT('EDIT POLICY ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_EDIT_POLICY_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(EDIT_POLICY_LOADING, { loading: false, data }));
    });
}

export function actionCustomerMeetingAddContact(params) {
    return (dispatch, getState) => Api.post(API_TPAMEETINGADDCONTACT, params).then((res) => {
        const data = res;
        // PRINT('ADD CONTACT ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(CUSTOMER_MEETING_ADD_CONTACT_LOADING, { loading: false, data }));
    });
}

export function actionCustomerMeetingSave(params) {
    return (dispatch, getState) =>
        Api.post(API_CUSTOMER_MEETING_NEXT, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CUSTOMER_MEETING_SAVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CUSTOMER_MEETING_SAVE_LOADING, { loading: false, data }));
        });
}

export function actionDeleteDocument(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_DOCUMENT, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOCUMENT_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_DELETE_LOADING, { loading: false, data }));
        });
}

export function actionPolicyDelete(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_POLICY, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DELETE_POLICY_CUSTOMER_MEETING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DELETE_POLICY_CUSTOMER_MEETING_LOADING, { loading: false, data }));
        });
}