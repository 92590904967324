import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { actionGroupClaims, GROUP_ALL_CLAIMS_SUCCESS } from './action';
import ClientPolicyDetails from '../../../../../../../component/popups/policies';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { verifyPermission } from '../../../../../../../utils';

const GroupClaims = (props) => {

    const [showClaimView, setShowClaimView] = useState(false);
    const [claimData, setClaimData] = useState([]);
    const [clientPolicyDetails, setClientPolicyDetails] = useState(null);

    const [type, setType] = useState('all');
    const [totalCount, setTotalCount] = useState(0);

    const [claimColumn, setClaimColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "policy_no", caption: "Policy No" },
        { dataField: "policy", caption: "Policy" },
        { dataField: "claim_no", caption: "Claim No" },
        { dataField: "status", caption: "Claim Status" },
        { dataField: "date_of_loss", caption: "Date Of Loss" },
        { dataField: "amount", caption: "Claim Amount" },
    ]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        props.actionGroupClaims({
            client_group_id: props.clientGroupId,
            type: 'all'
        })

    }
    useEffect(initialRender, [props.clientGroupId]);

    useEffect(() => {
        setTotalCount(claimData.length);
    }, [claimData])

    const onGroupClaimsUpdate = () => {

        const { GroupClaims } = props;
        if (GroupClaims != null) {
            var GroupClaimsResponse = Object.assign({}, GroupClaims);
            if (GroupClaimsResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setClaimColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "policy_no", caption: "Policy No" },
                    { dataField: "policy", caption: "Policy" },
                    { dataField: "claim_no", caption: "Claim No" },
                    { dataField: "status", caption: "Claim Status" },
                    { dataField: "date_of_loss", caption: "Date Of Loss" },
                    { dataField: "amount", caption: "Claim Amount" },
                ])
                setClaimData(GroupClaimsResponse.response);
                setTotalCount(GroupClaimsResponse.response.length);
            } else {
                $("#progress-loader").addClass("hidden");
                setClaimData([])
                switch (GroupClaimsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGroupClaimsUpdate, [props.GroupClaims]);

    const renderClaimAmount = (cell) => {
        return cell.data.amount != null ? '₹ ' + cell.data.amount : '';
    }

    const renderClaimColumns = () => {
        var arrColumns = [];
        claimColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        setShowClaimView(true);
                                        setClientPolicyDetails(params.data)
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else if (objColumn.dataField === "amount") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderClaimAmount}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderClaimsTableView = () => {
        return (
            <DataGrid
                dataSource={claimData}
                columnHidingEnabled={false}
                showBorders={true}
                export={{
                    fileName: "claims",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderClaimColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <ul class="nav nav-pills d-md-flex d-lg-flex">
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                setClaimData([])
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionGroupClaims({
                                                    client_group_id: props.clientGroupId,
                                                    type: 'all'
                                                });
                                                setTotalCount(0)
                                                setType('all');
                                            }}
                                            className={`btn btn-md ${type === 'all' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round`}>
                                            All {type === 'all' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                setClaimData([])
                                                $("#progress-loader").removeClass("hidden");
                                                setType('settled');
                                                setTotalCount(0)
                                                props.actionGroupClaims({
                                                    client_group_id: props.clientGroupId,
                                                    type: 'settled'
                                                });

                                            }}
                                            className={`btn btn-md ${type === 'settled' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Settled  {type === 'settled' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                setClaimData([])
                                                $("#progress-loader").removeClass("hidden");
                                                setType('pending');
                                                setTotalCount(0)
                                                props.actionGroupClaims({
                                                    client_group_id: props.clientGroupId,
                                                    type: 'pending'
                                                });
                                            }}
                                            className={`btn btn-md ${type === 'pending' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Pending {type === 'pending' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>

                                </ul>
                                {
                                    claimData != null && claimData.length > 0 ?
                                        renderClaimsTableView() :
                                        <h6 className="text-bold-500 text-center">No Claim Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClientPolicyDetails
                show={showClaimView}
                clientPolicyDetails={clientPolicyDetails}
                handleClose={() => {
                    setShowClaimView(false);
                }}
                tab={3}
            />
        </>
    )

}

function mapStateToProps(state) {

    var GroupClaims = null;

    if (
        state.TabGroupClaimsReducer != null &&
        Object.keys(state.TabGroupClaimsReducer).length > 0 &&
        state.TabGroupClaimsReducer.constructor === Object
    ) {
        switch (state.TabGroupClaimsReducer.tag) {

            case GROUP_ALL_CLAIMS_SUCCESS:
                GroupClaims = Object.assign({}, state.TabGroupClaimsReducer.data);
                delete state.TabGroupClaimsReducer;
                return { GroupClaims };
            default:
                return {
                    GroupClaims
                }
        }
    }
    return {
        GroupClaims
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupClaims }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupClaims));