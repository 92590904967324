import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from "react-icons/fi";
import {
  actionLeaveApprovalList,
  ACTION_LEAVE_APPROVAL_LIST_SUCCESS,
} from "./action";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
} from "../../../../../../utils/preferences";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../../../../constants";
import { Template } from "devextreme-react";
import { useForm, Controller } from "react-hook-form";
import { DateBox } from "devextreme-react/date-box";
import $ from "jquery";
import { TagBox } from "devextreme-react/tag-box";
import ArrayStore from "devextreme/data/array_store";
import { withAuth } from "react-auth-guard";
import moment from "moment";
import { getDateFilter } from "../../../../../../utils";
import dateFormat from "dateformat";
import FilterComponent from "../../../../../../component/hrms_smart_filters";
const LeaveApprovalsList = (props) => {
  const { match } = props;

  const [fromDate, setFromDate] = useState(
    dateFormat(
      moment().subtract(1, "year").date(new Date().getDate()),
      "yyyy-mm-dd"
    )
  );
  const [toDate, setToDate] = useState(
    dateFormat(moment().add(1, "year").date(new Date().getDate()), "yyyy-mm-dd")
  );

  const { control, setValue } = useForm({});
  const [policyTypeValue, setPolicyTypeValue] = useState(["pending"]);
  let current_fy = Object.assign({}, getDateFilter("CURRENT_FY"));
  let previous_fy = Object.assign({}, getDateFilter("PREVIOUS_FY"));
  const [selectedOption, setSelectedOption] = useState(null);
  const [policyTypeDropdown, setPolicyTypeDropdown] = useState([
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Approved",
      value: "approved",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Cancelled",
      value: "canceled",
    },
  ]);
  const [leaveApprovalsList, setLeaveApprovalsList] = useState([]);

  const handleFilterChange = (option) => {
    setSelectedOption(option);
    switch (option) {
      case "current_fy":
        setFromDate(current_fy.start);
        setToDate(current_fy.end);
        props.actionLeaveApprovalList({
          from_date: current_fy.start,
          to_date: current_fy.end,
          status: policyTypeValue,
        });
        break;
      case "previous_fy":
        setFromDate(previous_fy.start);
        setToDate(previous_fy.end);
        props.actionLeaveApprovalList({
          from_date: previous_fy.start,
          to_date: previous_fy.end,
          status: policyTypeValue,
        });
        break;
      case "all":
        setFromDate();
        setToDate();
        props.actionLeaveApprovalList({
          status: policyTypeValue,
        });
        break;
      case "reset":
        $("#progress-loader").removeClass("hidden");
        setSelectedOption(null);
        setPolicyTypeValue(["pending"]);
        setFromDate(
          dateFormat(
            moment().subtract(1, "year").date(new Date().getDate()),
            "yyyy-mm-dd"
          )
        );
        setToDate(
          dateFormat(
            moment().add(1, "year").date(new Date().getDate()),
            "yyyy-mm-dd"
          )
        );
        setValue("status", ["pending"]);
        props.actionLeaveApprovalList({
          from_date: fromDate,
          to_date: toDate,
          status: ["pending"],
        });
        break;
      default:
    }
  };
  useEffect(() => {
    $("#progress-loader").removeClass("hidden");
    props.actionLeaveApprovalList({
      from_date: fromDate,
      to_date: toDate,
      status: policyTypeValue,
    });
  }, [policyTypeValue]);

  const columns = [
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "full_name", caption: "Employee", visible: true },
    { dataField: "from_date", caption: "Date", visible: false },
    { dataField: "from_date_formated", caption: "Date", visible: true },
    { dataField: "leave_type_name", caption: "Type", visible: true },
    { dataField: "reason", caption: "Reason", visible: true },
    { dataField: "status", caption: "Status", visible: true },
    { dataField: "remark", caption: "Remark", visible: true },
  ];

  const initialRender = () => {
    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }
    $("#progress-loader").removeClass("hidden");
    props.actionLeaveApprovalList({
      from_date: fromDate,
      to_date: toDate,
      status: ["Pending"],
    });
  };
  useEffect(initialRender, []);

  const onLeaveApprovalListUpdate = () => {
    const { LeaveApprovalList } = props;
    if (LeaveApprovalList != null) {
      setLeaveApprovalsList([]);
      var LeaveApprovalListResponse = Object.assign({}, LeaveApprovalList);
      if (LeaveApprovalListResponse.result === true) {
        setLeaveApprovalsList(LeaveApprovalListResponse.response);
        $("#progress-loader").addClass("hidden");
      } else {
        $("#progress-loader").addClass("hidden");
        switch (LeaveApprovalListResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            toast.success(
              "Your Login session has expired, Please try to Login again!"
            );
            break;
          default:
        }
      }
    }
  };
  useEffect(onLeaveApprovalListUpdate, [props.LeaveApprovalList]);

  const renderFromDate = (cell) => {
    return (
      <div>
        {cell?.data?.from_date_formated != null
          ? moment(cell?.data?.from_date_formated).format("DD/MM/YYYY")
          : ""}
      </div>
    );
  };

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="Action" type="buttons">
            <Button
              render={(params) => (
                <button
                  className="btn btn-icon btn-sm"
                  onClick={() => {
                    localStorage.setItem(
                      "detailsLeaveData",
                      JSON.stringify(params.data)
                    );

                    const url = `${match.url}/view`;
                    const win = window.open(url, "_blank");
                    if (win) {
                      win.focus();
                    } else {
                      console.log(
                        "Pop-up blocked. Please allow pop-ups for this site."
                      );
                    }
                  }}
                >
                  <FiEye size={16} className="primary" />
                </button>
              )}
            />
          </Column>
        );
      } else if (objColumn.dataField === "from_date_formated") {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            cellRender={renderFromDate}
            visible={objColumn.visible}
          ></Column>
        );
      } else {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            visible={objColumn.visible}
          ></Column>
        );
      }
    });
    return arrColumns;
  };

  return (
    <>
      <div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-0">
              Leave Approvals{" "}
              {leaveApprovalsList.length === 0
                ? ""
                : `(${leaveApprovalsList.length})`}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-5">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                      <li class="nav-item">
                        <Controller
                          name="from_date"
                          control={control}
                          defaultValue={moment()
                            .subtract(2, "months")
                            .date(new Date().getDate())}
                          render={({ field }) => (
                            <DateBox
                              defaultValue={new Date()}
                              calendarOptions={{
                                showTodayButton: true,
                              }}
                              stylingMode="underlined"
                              pickerType="calendar"
                              placeholder="From Date"
                              type="date"
                              displayFormat="dd/MM/yyyy"
                              showClearButton={false}
                              value={fromDate}
                              acceptCustomValue={false}
                              onValueChange={(value) => {
                                field.onChange(value);

                                var strFromDate = dateFormat(
                                  value,
                                  "yyyy-mm-dd"
                                );
                                setFromDate(strFromDate);

                                props.actionLeaveApprovalList({
                                  from_date: strFromDate,
                                  to_date: toDate,
                                  status: policyTypeValue,
                                });
                              }}
                            />
                          )}
                        />
                      </li>
                      <li class="nav-item ml-1">
                        <Controller
                          name="to_date"
                          control={control}
                          defaultValue={moment()
                            .add(3, "months")
                            .date(new Date().getDate())}
                          render={({ field }) => (
                            <DateBox
                              defaultValue={new Date()}
                              calendarOptions={{
                                showTodayButton: true,
                              }}
                              stylingMode="underlined"
                              pickerType="calendar"
                              placeholder="To Date"
                              type="date"
                              displayFormat="dd/MM/yyyy"
                              showClearButton={false}
                              value={toDate}
                              acceptCustomValue={false}
                              onValueChange={(value) => {
                                field.onChange(value);

                                var strToDate = dateFormat(value, "yyyy-mm-dd");
                                setToDate(strToDate);

                                props.actionLeaveApprovalList({
                                  from_date: fromDate,
                                  to_date: strToDate,
                                  status: policyTypeValue,
                                });
                              }}
                            />
                          )}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="form-group cust-tagbox">
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <TagBox
                            {...field}
                            dataSource={
                              new ArrayStore({
                                data: policyTypeDropdown,
                                key: "id",
                              })
                            }
                            dropDownOptions={{
                              onInitialized: () => {
                                console.log("initial");
                              },
                              onContentReady: () => {
                                console.log("content ready");
                              },
                              onShowing: () => {
                                console.log("showing");
                              },
                              onShown: () => {
                                console.log("shown");
                              },
                            }}
                            deferRendering={true}
                            displayExpr="name"
                            valueExpr="value"
                            name="status"
                            id="status"
                            placeholder="Select Status"
                            searchEnabled={true}
                            searchMode="contains"
                            maxDisplayedTags={2}
                            showMultiTagOnly={false}
                            stylingMode="underlined"
                            value={field.value ? field.value : policyTypeValue}
                            onValueChanged={({ value }) => {
                              if (value != null || value !== undefined) {
                                setPolicyTypeValue(value);
                                field.onChange(value);
                              }
                            }}
                            showClearButton={true}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 text-right">
                    <FilterComponent
                      selectedOption={selectedOption}
                      onFilterChange={handleFilterChange}
                    />
                  </div>
                </div>
                <div>
                <DataGrid
                  dataSource={leaveApprovalsList}
                  showBorders={true}
                  export={{
                    fileName: "Leave Approvals List",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                  }}
                  onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption(
                      "from_date_formated",
                      "visible",
                      false
                    );
                    event.component.columnOption("from_date", "visible", true);
                  }}
                  onExported={(event) => {
                    event.component.columnOption(
                      "from_date_formated",
                      "visible",
                      true
                    );
                    event.component.columnOption("from_date", "visible", false);
                    event.component.endUpdate();
                  }}
                  onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                      location: "before",
                      template: "totalGroupCount",
                    });
                  }}
                >
                  <SearchPanel visible={true} />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  {renderColumns()}
                  <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                  />
                  <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                  />
                  <Template
                    name="totalGroupCount"
                    render={() => {
                      return <></>;
                    }}
                  />
                </DataGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  var LeaveApprovalList = null;

  if (
    state.LeaveApprovalListReducer != null &&
    Object.keys(state.LeaveApprovalListReducer).length > 0 &&
    state.LeaveApprovalListReducer.constructor === Object
  ) {
    switch (state.LeaveApprovalListReducer.tag) {
      case ACTION_LEAVE_APPROVAL_LIST_SUCCESS:
        LeaveApprovalList = Object.assign(
          {},
          state.LeaveApprovalListReducer.data
        );
        delete state.LeaveApprovalListReducer;
        return { LeaveApprovalList };
      default:
    }
  }

  return {
    LeaveApprovalList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionLeaveApprovalList }, dispatch);
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(LeaveApprovalsList)
);
