/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useEffect } from 'react';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AiFillFileText } from 'react-icons/ai';
import { ERROR, UNAUTHORIZED } from '../../../../../constants';
import { actionDocumentList, ACTION_DOCUMENT_LIST_SUCCESS } from './action';
import { useState } from 'react';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import Swal from 'sweetalert2';
import { FiTrash } from 'react-icons/fi';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import { actionDeleteDocument, ACTION_DELETE_DOCUMENT_SUCCESS } from './action'
import { verifyPermission } from '../../../../../utils';
import DocumentIframe from '../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../utils';

const DocumentList = (props) => {
    const { match } = props;
    const [documents, setDocuments] = useState([])
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionDocumentList();
    }
    useEffect(initialRender, []);

    const onDocumentListUpdate = () => {
        const { DocumentList } = props;
        var DocumentListResponse = Object.assign({}, DocumentList);
        if (DocumentListResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setDocuments(DocumentListResponse.response)
        } else {
            switch (DocumentListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    $("#progress-loader").addClass("hidden");
                    setDocuments([]);
                    break;
                default:
            }
        }
    }
    useEffect(onDocumentListUpdate, [props.DocumentList]);

    const onDeleteDocumentUpdate = () => {
        const { DeleteDocument } = props;
        var DeleteDocumentResponse = Object.assign({}, DeleteDocument);
        if (DeleteDocumentResponse.result === true) {
            props.actionDocumentList();
            toast.success('Document deleted successfully')
        } else {
            switch (DeleteDocumentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onDeleteDocumentUpdate, [props.DeleteDocument]);

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Documents</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    {
                        verifyPermission("UPLOAD_DOCUMENT") ?
                            <ul class="nav nav-pills float-right">
                                <li class="nav-item">
                                    <button className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push(`${match.url}/create`);
                                        }}
                                    >
                                        Upload Document
                                    </button>
                                </li>
                            </ul>
                            :
                            <></>
                    }

                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 col-md-12">
                    <div id="accordionWrap1" role="tablist" aria-multiselectable="true">
                        {
                            documents != null && documents.length > 0 ?
                                documents.map((category, indexCategory) => (
                                    <div class={`card bg-transparent accordion collapse-icon accordion-icon-rotate ${category.documents.length > 0 ? '' : 'hidden'}`} style={{ boxShadow: 'none' }} key={`category-${category?.id}`}>
                                        <div id={`category${category?.id}`} class="card-header primary collapsed" data-toggle="collapse" href={`#accordian${category?.id}`} aria-expanded="false" aria-controls="accordion11">
                                            <a class="card-title lead" href="#">{category.category_name}</a>
                                        </div>
                                        <div id={`accordian${category?.id}`} role="tabpanel" data-parent="#accordionWrap1" aria-labelledby={`category${category?.id}`} class={`collapse px-0 ${ props?.location?.state?.id === category?.id ? 'show' :''} `}>
                                            <div className='card-body'>
                                                <div className="row mr-2">
                                                    {
                                                        category.documents != null && category.documents.length > 0 ?
                                                            category.documents.map((document, indexDocument) => (
                                                                <div className="col-12 col-lg-4 col-md-4" key={`document-${indexDocument}`}>
                                                                    <div className="card rounded shadow">
                                                                        {
                                                                            verifyPermission("DELETE_DOCUMENT") ?
                                                                                <FiTrash
                                                                                    onClick={() => {
                                                                                        Swal.fire({
                                                                                            icon: 'warning',
                                                                                            text: 'Are you sure, you want to delete this document?',
                                                                                            cancelButtonText: "Cancel",
                                                                                            showCancelButton: true,
                                                                                        }).then(({ isConfirmed }) => {
                                                                                            if (isConfirmed) {
                                                                                                // props.actionDeleteDocument({})
                                                                                                props.actionDeleteDocument({
                                                                                                    "id": document.document_id
                                                                                                })
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                    size={"16"}
                                                                                    cursor={"pointer"}
                                                                                    className="mt-1 mr-1 ml-half ml-auto"
                                                                                >
                                                                                </FiTrash>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        <div className="card-body">
                                                                            <div className="d-flex flex-row align-items-center">
                                                                                <div className="flex-column">
                                                                                    <h1 className="font-medium-1 text-black card-text-esc">{document.title}</h1>
                                                                                    <p className="font-small-3 mb-0">{dateFormat(document.created_at, 'mmmm dd yyyy')}</p>
                                                                                    <p className="font-small-3 mb-0">Uploaded By {document.full_name}</p>
                                                                                    <p className="font-small-3 mb-0">Approved By {document.approved_by_user}</p>
                                                                                    <p className="font-small-3 mb-0 card-text-esc">{document.description}</p>
                                                                                </div>
                                                                                <div className="d-flex align-items-center flex-column ml-auto">
                                                                                    <p className="mb-0"><AiFillFileText size={60} className="primary" /></p>
                                                                                    {
                                                                                        document.document_path != null ?
                                                                                            <>
                                                                                                <a className="primary"
                                                                                                    onClick={() => {

                                                                                                        if (document?.document_path?.split('.').pop() == 'pdf' ||
                                                                                                            document?.document_path?.split('.').pop() == 'PDF' ||
                                                                                                            document?.document_path?.split('.').pop() == 'png' ||
                                                                                                            document?.document_path?.split('.').pop() == 'jpg' ||
                                                                                                            document?.document_path?.split('.').pop() == 'jpeg' ||
                                                                                                            document?.document_path?.split('.').pop() == 'gif'
                                                                                                        ) {
                                                                                                            setSelectedDocumentIndex(document?.document_path)
                                                                                                            setShowDocumentDetails(true);
                                                                                                            setExtension(document?.document_path.split('.').pop())
                                                                                                            setDocumentDetails(document)
                                                                                                        } else {
                                                                                                            downloadFile(document?.document_path);
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    Open
                                                                                                </a>
                                                                                                <a id="downloadLink" className="d-none"></a>
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <></>
                        }
                    </div>
                </div>
            </div >
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {

    var DocumentList = null;
    var DeleteDocument = null;

    if (
        state.DocumentListReducer != null &&
        Object.keys(state.DocumentListReducer).length > 0 &&
        state.DocumentListReducer.constructor === Object
    ) {
        switch (state.DocumentListReducer.tag) {
            case ACTION_DOCUMENT_LIST_SUCCESS:
                DocumentList = Object.assign({}, state.DocumentListReducer.data);
                delete state.DocumentListReducer;
                return { DocumentList };
            case ACTION_DELETE_DOCUMENT_SUCCESS:
                DeleteDocument = Object.assign({}, state.DocumentListReducer.data);
                delete state.DocumentListReducer;
                return { DeleteDocument };

            default:
        }
    }
    return {
        DocumentList,
        DeleteDocument
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionDocumentList, actionDeleteDocument }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DocumentList))
