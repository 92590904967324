import { REIMBURSEMENT_LIST_SUCCESS, REIMBURSEMENT_DETAIL_LIST_SUCCESS, REIMBURSEMENT_REQUEST_STATUS_SUCCESS, REIMBURSEMENT_ADD_SUCCESS, ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ReimbursementListReducer = createReducer(INITIAL_STATE, {
    [REIMBURSEMENT_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: REIMBURSEMENT_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [REIMBURSEMENT_REQUEST_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: REIMBURSEMENT_REQUEST_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [REIMBURSEMENT_ADD_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: REIMBURSEMENT_ADD_SUCCESS,
            loading: false
        });
    },
    [ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS,
            loading: false
        });
    },
});
export default ReimbursementListReducer;