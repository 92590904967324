import Api from "../../../api";
import { API_ASSIGN_ENGINEER } from '../../../api/constants';
import { API_EDIT_ASSIGN_ENGINEER } from '../../../api/constants';
import { PRINT } from "../../../utils";
import { fetchSuccess, loading } from "../../../utils/action.util";

export const ACTION_MASTER_ASSIGN_ENGINEER_LOADING = 'ACTION_MASTER_ASSIGN_ENGINEER_LOADING';
export const ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS = 'ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS';

export const ACTION_MASTER_EDIT_ASSIGN_ENGINEER_LOADING = 'ACTION_MASTER_EDIT_ASSIGN_ENGINEER_LOADING';
export const ACTION_MASTER_EDIT_ASSIGN_ENGINEER_SUCCESS = 'ACTION_MASTER_EDIT_ASSIGN_ENGINEER_SUCCESS';

export function actionAssignEngineerPool() {
    return (dispatch, getState) =>
        Api.get(API_ASSIGN_ENGINEER).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_ENGINEER_LOADING, { loading: false, data }));
        });
}
export function actionEditAssignEngineerPool(params) {
    return (dispatch, getState) =>
        Api.post(API_EDIT_ASSIGN_ENGINEER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_ASSIGN_ENGINEER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_ASSIGN_ENGINEER_LOADING, { loading: false, data }));
        });
}