import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_GET_SPECIFIC_PREFERENCE } from '../../../../../../api/constants';
import { API_MASTER_GET_DEPARTMENT } from '../../../../../../api/constants';
import { API_MASTER_GET_POLICY_TYPE } from '../../../../../../api/constants';
import { API_MASTER_GET_INSURER } from '../../../../../../api/constants';
import { API_SPECIFIC_PREFERENCE_DELETE } from '../../../../../../api/constants';

export const ACTION_MASTER_GET_SPECIFIC_PREFERENCE_LOADING = 'ACTION_MASTER_GET_SPECIFIC_PREFERENCE_LOADING';
export const ACTION_MASTER_GET_SPECIFIC_PREFERENCE_SUCCESS = 'ACTION_MASTER_GET_SPECIFIC_PREFERENCE_SUCCESS';

export const ACTION_GET_DEPARTMENT_LOADING = 'ACTION_GET_DEPARTMENT_LOADING';
export const ACTION_GET_DEPARTMENT_SUCCESS = 'ACTION_GET_DEPARTMENT_SUCCESS';

export const ACTION_GET_POLICY_TYPE_LOADING = 'ACTION_GET_POLICY_TYPE_LOADING';
export const ACTION_GET_POLICY_TYPE_SUCCESS = 'ACTION_GET_POLICY_TYPE_SUCCESS';

export const ACTION_GET_INSURER_LOADING = 'ACTION_GET_INSURER_LOADING';
export const ACTION_GET_INSURER_SUCCESS = 'ACTION_GET_INSURER_SUCCESS';

export const ACTION_PREFERENCE_DELETE_LOADING = 'ACTION_PREFERENCE_DELETE_LOADING';
export const ACTION_PREFERENCE_DELETE_SUCCESS = 'ACTION_PREFERENCE_DELETE_SUCCESS';

export function actionMasterSpecificPreference(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_SPECIFIC_PREFERENCE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_SPECIFIC_PREFERENCE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_GET_SPECIFIC_PREFERENCE_LOADING, { loading: false, data }));
        });
}

export function actionGetDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_DEPARTMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionGetPolicyType(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionGetCompanyList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_INSURER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_INSURER_LOADING, { loading: false, data }));
        });
}
export function actionPreferenceDelete(params) {
    return (dispatch, getState) =>
        Api.post(API_SPECIFIC_PREFERENCE_DELETE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_PREFERENCE_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PREFERENCE_DELETE_LOADING, { loading: false, data }));
        });
}