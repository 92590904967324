
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_TRAVEL_LIST, API_TRAVEL_DELETE, API_TRAVEL_CALENDAR_LIST, API_TRAVEL_TAB_PERMISSION } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_CALENDAR_TRAVEL_LOADING = 'ACTION_CALENDAR_TRAVEL_LOADING';
export const ACTION_CALENDAR_TRAVEL_SUCCESS = 'ACTION_CALENDAR_TRAVEL_SUCCESS';

export const ACTION_TRAVEL_DELETE_LOADING = 'ACTION_TRAVEL_DELETE_LOADING';
export const ACTION_TRAVEL_DELETE_SUCCESS = 'ACTION_TRAVEL_DELETE_SUCCESS';

export const ACTION_TRAVEL_RI_CALENDAR_LIST_LOADING = 'ACTION_TRAVEL_RI_CALENDAR_LIST_LOADING';
export const ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS = 'ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS';

export const ACTION_TRAVEL_RI_CALENDAR_EVENT_LOADING = 'ACTION_TRAVEL_RI_CALENDAR_EVENT_LOADING';
export const ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS = 'ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS';

export const ACTION_TRAVEL_DIRECT_CALENDAR_LIST_LOADING = 'ACTION_TRAVEL_DIRECT_CALENDAR_LIST_LOADING';
export const ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS = 'ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS';

export const ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_LOADING = 'ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_LOADING';
export const ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS = 'ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS';

export const ACTION_TRAVEL_TAB_PERMISSION_LOADING = 'ACTION_TRAVEL_TAB_PERMISSION_LOADING';
export const ACTION_TRAVEL_TAB_PERMISSION_SUCCESS = 'ACTION_TRAVEL_TAB_PERMISSION_SUCCESS';

export function actionTravelCalendar() {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CALENDAR_TRAVEL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CALENDAR_TRAVEL_LOADING, { loading: false, data }));
        });
}

export function actionTravelDelete(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_DELETE, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_DELETE_LOADING, { loading: false, data }));
        });
}

export function actionTravelRICalendarList(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_CALENDAR_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_RI_CALENDAR_LIST_LOADING, { loading: false, data }));
        });
}

export function actionTravelRICalendarEvent(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_CALENDAR_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_RI_CALENDAR_EVENT_LOADING, { loading: false, data }));
        });
}

export function actionTravelDirectCalendarList(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_CALENDAR_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_DIRECT_CALENDAR_LIST_LOADING, { loading: false, data }));
        });
}

export function actionTravelDirectCalendarEvent(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_CALENDAR_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_LOADING, { loading: false, data }));
        });
}

export function actionTravelTabPermissions(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_TAB_PERMISSION, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_TAB_PERMISSION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_TAB_PERMISSION_LOADING, { loading: false, data }));
        });
}