import Api from "../../../../../api";
import { fetchSuccess, loading } from "../../../../../utils/action.util";
import { API_DASHBOARD_GET_POST, API_DASHBOARD_BUSINESS_SUMMARY, API_MARK_STATUS, API_GET_SEARCH_LIST } from "../../../../../api/constants";
import { API_GET_TICKER } from "../../../../../api/constants";
import { API_MARK_IN } from '../../../../../api/constants';
import { API_MARK_OUT } from '../../../../../api/constants';
// import { PRINT } from "../../../../../utils";
import { API_HEADER_USER_NOTIFICATION } from '../../../../../api/constants';

export const ACTION_GET_POST_LOADING = "ACTION_DASHBOARD_GET_POST_LOADING";
export const ACTION_GET_POST_SUCCESS = "ACTION_DASHBOARD_GET_POST_SUCCESS";

export const ACTION_DASHBOARD_BUSINESS_SUMMARY_LOADING = "ACTION_DASHBOARD_BUSINESS_SUMMARY_LOADING";
export const ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS = "ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS";

export const ACTION_GET_TICKER_LOADING = "ACTION_GET_TICKER_LOADING";
export const ACTION_GET_TICKER_SUCCESS = "ACTION_GET_TICKER_SUCCESS";

export const ACTION_MARK_IN_LOADING = 'ACTION_MARK_IN_LOADING';
export const ACTION_MARK_IN_SUCCESS = 'ACTION_MARK_IN_SUCCESS';

export const ACTION_MARK_OUT_LOADING = 'ACTION_MARK_OUT_LOADING';
export const ACTION_MARK_OUT_SUCCESS = 'ACTION_MARK_OUT_SUCCESS';

export const ACTION_MARK_STATUS_LOADING = 'ACTION_MARK_STATUS_LOADING';
export const ACTION_MARK_STATUS_SUCCESS = 'ACTION_MARK_STATUS_SUCCESS';

export const ACTION_DASHBOARD_USER_NOTIFICATION_LOADING = 'ACTION_DASHBOARD_USER_NOTIFICATION_LOADING';
export const ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS = 'ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS';

export const ACTION_GET_SEARCH_LIST_DASHBOARD_LOADING = 'ACTION_GET_SEARCH_LIST_DASHBOARD_LOADING';
export const ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS = 'ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS';

export function actionGetPost(params) {
  return (dispatch, getState) =>
    Api.post(API_DASHBOARD_GET_POST, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_GET_POST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_GET_POST_LOADING, { loading: false, data }));
      });
}

export function actionDashboardBusinessSummary(params) {
  return (dispatch, getState) =>
    Api.post(API_DASHBOARD_BUSINESS_SUMMARY, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_DASHBOARD_BUSINESS_SUMMARY_LOADING, { loading: false, data }));
      });
}
export function actionGetTicker(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_TICKER, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_GET_TICKER_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_GET_TICKER_LOADING, { loading: false, data }));
      });
}
export function actionMarkIn(params) {
  return (dispatch, getState) =>
    Api.post(API_MARK_IN, params).then((res) => {
      const data = res;
      // PRINT('ACTION RESPONSE', data)
      dispatch(fetchSuccess(ACTION_MARK_IN_SUCCESS, { data }));
    }).catch((reason) => {
      /** Handle Error */
      var data = {
        message: ''
      }
      dispatch(loading(ACTION_MARK_IN_LOADING, { loading: false, data }));
    });
}
export function actionMarkOut(params) {
  return (dispatch, getState) =>
    Api.post(API_MARK_OUT, params).then((res) => {
      const data = res;
      // PRINT('ACTION RESPONSE', data)
      dispatch(fetchSuccess(ACTION_MARK_OUT_SUCCESS, { data }));
    }).catch((reason) => {
      /** Handle Error */
      var data = {
        message: ''
      }
      dispatch(loading(ACTION_MARK_OUT_LOADING, { loading: false, data }));
    });
}
export function actionMarkStatus() {
  return (dispatch, getState) =>
    Api.get(API_MARK_STATUS).then((res) => {
      const data = res;
      // PRINT('MARK STATUS ACTION RESPONSE', data)
      dispatch(fetchSuccess(ACTION_MARK_STATUS_SUCCESS, { data }));
    }).catch((reason) => {
      /** Handle Error */
      var data = {
        message: ''
      }
      dispatch(loading(ACTION_MARK_STATUS_LOADING, { loading: false, data }));
    });
}
export function actionHeaderUserNotification() {
  return (dispatch, getState) =>
    Api.get(API_HEADER_USER_NOTIFICATION).then((res) => {
      const data = res;
      // PRINT('ACTION RESPONSE', data)
      dispatch(fetchSuccess(ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS, { data }));
    }).catch((reason) => {
      /** Handle Error */
      var data = {
        message: ''
      }
      dispatch(loading(ACTION_DASHBOARD_USER_NOTIFICATION_LOADING, { loading: false, data }));
    });
}
export function actionGetGlobalSearchList() {
  return (dispatch, getState) =>
    Api.get(API_GET_SEARCH_LIST).then((res) => {
      const data = res;
      // PRINT('ACTION RESPONSE', data)
      dispatch(fetchSuccess(ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS, { data }));
    }).catch((reason) => {
      /** Handle Error */
      var data = {
        message: ''
      }
      dispatch(loading(ACTION_GET_SEARCH_LIST_DASHBOARD_LOADING, { loading: false, data }));
    });
}