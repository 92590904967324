export default function createReducer(initialState, handlers) {
	return function reducer(state = initialState, action) {
		// console.log('HANDLERAS : ' + JSON.stringify(handlers))
		if (handlers.hasOwnProperty(action.type)) {
			// console.log('ACTIONSSSSS : ' + JSON.stringify(action))
			return handlers[action.type](state, action)
		} else {
			return state
		}
	}
}
