import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye, FiEdit } from 'react-icons/fi';
import { UNAUTHORIZED } from "../../../../../../constants";
import $ from 'jquery';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { actionReinsuranceList, ACTION_REINSURANCE_LIST_SUCCESS } from './action';
import { verifyPermission } from "../../../../../../utils";
import moment from "moment";

const ReinsuranceList = (props) => {
    const [reinsurance, setReinsurance] = useState([]);
    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "created_at", caption: "Created On", visible: false },
        { dataField: "created_at_formated", caption: "Created On", visible: true },
        { dataField: "pinc_ref_no", caption: "PINC Reference No.", visible: true },
        { dataField: "full_name", caption: "Operation Person", visible: false },
        { dataField: "branch", caption: "Branch", visible: false },
        { dataField: "business_type", caption: "Business Type", visible: false },
        { dataField: "cedant", caption: "Cedant/Broker", visible: true },
        { dataField: "insured_treaty_name", caption: "Insured / Treaty Name", visible: false },
        { dataField: "endorsement_type", caption: "Type of Endorsement", visible: false },
        { dataField: "endorsement_no", caption: "Endorsement Control No.", visible: false },
        { dataField: "cover_bind", caption: "Cover Bind", visible: true },
        { dataField: "approved", caption: "Approved Status", visible: true },
        { dataField: "sarb_enquiry_code", caption: "Sarb Enquiry Code", visible: true },
        { dataField: "control_no", caption: "Control No.", visible: true },
        { dataField: "document_saved", caption: "Deal Documents Saved", visible: false },
        { dataField: "type", caption: "Entry Processed In", visible: false },
        { dataField: "signed_document_received", caption: "Signed Document Received", visible: false },
        { dataField: "ri_remark", caption: "Ri Remark", visible: false },
        { dataField: "finance_remark", caption: "Finance Remark", visible: false },
    ];

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionReinsuranceList();
    }
    useEffect(initialRender, []);

    const onReinsuranceListsUpdate = () => {
        const { ReinsuranceLists } = props
        var ReinsuranceListsResponse = Object.assign({}, ReinsuranceLists);
        if (ReinsuranceListsResponse.result === true) {
            setReinsurance(ReinsuranceListsResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReinsuranceListsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReinsuranceListsUpdate, [props.ReinsuranceLists])

    const renderFormatedDate = (cell) => {
        return <div>{cell?.data?.created_at_formated != null ? moment(cell?.data?.created_at_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("VIEW_REINSURANCE_ENQUIRY") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `/reinsurance/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("UPDATE_REINSURANCE_ENQUIRY") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `/reinsurance/enquiries-edit`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "created_at_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderFormatedDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Enquiry List</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            {
                                verifyPermission("CREATE_REINSURANCE_ENQUIRY") ?
                                    <button className="btn btn-primary btn-md px-1 font-small-3 round"
                                        onClick={() => {
                                            props.history.push(`/reinsurance/add`);
                                        }}
                                    >
                                        Add Enquiry
                                    </button>
                                    :
                                    <></>
                            }
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={reinsurance}
                                showBorders={true}
                                columnAutoWidth={true}
                                export={{
                                    fileName: `Reinsurance Enquiry ${moment().format("DD/MM/YYYY")}`,
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("created_at_formated", "visible", false)
                                    event.component.columnOption("created_at", "visible", true)
                                    event.component.columnOption("full_name", "visible", true)
                                    event.component.columnOption("type", "visible", true)
                                    event.component.columnOption("document_saved", "visible", true)
                                    event.component.columnOption("signed_document_received", "visible", true)
                                    event.component.columnOption("ri_remark", "visible", true)
                                    event.component.columnOption("finance_remark", "visible", true)
                                    event.component.columnOption("branch", "visible", true)
                                    event.component.columnOption("business_type", "visible", true)
                                    event.component.columnOption("insured_treaty_name", "visible", true)
                                    event.component.columnOption("endorsement_type", "visible", true)
                                    event.component.columnOption("endorsement_no", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("created_at_formated", "visible", true)
                                    event.component.columnOption("created_at", "visible", false)
                                    event.component.columnOption("full_name", "visible", false)
                                    event.component.columnOption("type", "visible", false)
                                    event.component.columnOption("document_saved", "visible", false)
                                    event.component.columnOption("signed_document_received", "visible", false)
                                    event.component.columnOption("ri_remark", "visible", false)
                                    event.component.columnOption("finance_remark", "visible", false)
                                    event.component.columnOption("branch", "visible", false)
                                    event.component.columnOption("business_type", "visible", false)
                                    event.component.columnOption("insured_treaty_name", "visible", false)
                                    event.component.columnOption("endorsement_type", "visible", false)
                                    event.component.columnOption("endorsement_no", "visible", false)
                                    event.component.endUpdate();
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[5, 10, 20, 50]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    var ReinsuranceLists = null;
    if (
        state.ReinsuranceListReducer != null &&
        Object.keys(state.ReinsuranceListReducer).length > 0 &&
        state.ReinsuranceListReducer.constructor === Object
    ) {
        switch (state.ReinsuranceListReducer.tag) {
            case ACTION_REINSURANCE_LIST_SUCCESS:
                ReinsuranceLists = Object.assign({}, state.ReinsuranceListReducer.data);
                delete state.ReinsuranceListReducer;
                return { ReinsuranceLists };
            default:
        }
    }
    return {
        ReinsuranceLists
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReinsuranceList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReinsuranceList))