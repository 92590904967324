import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TagBox } from "devextreme-react";
import ArrayStore from 'devextreme/data/array_store';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { UNAUTHORIZED, VALIDATION_ERROR } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { actionMasterEditTrainingType, ACTION_MASTER_EDIT_TRAINING_TYPE_SUCCESS } from "./action";

const TrainingTypeEdit = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm();

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            setValue('name', props?.data?.name);
            setValue('status', props?.data?.status);
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            $("#placement-form-loader").addClass("hidden");

        }
    };
    useEffect(initialRender, [props.show]);

    const onMasterTrainingTypeEditUpdate = () => {
        const { MasterTrainingTypeEdit } = props
        if (MasterTrainingTypeEdit != null) {
            var MasterTrainingTypeEditResponse = Object.assign({}, MasterTrainingTypeEdit);
            $("#edit_training_type").prop("disabled", false);
            if (MasterTrainingTypeEditResponse.result === true) {
                $("#training_type_edit-form-loader").addClass("hidden");
                $("#edit_training_type").prop("disabled", false);
                toast.success(MasterTrainingTypeEditResponse.message);
                props.handleClose(true);
            } else {
                $("#training_type_edit-form-loader").addClass("hidden");
                $("#edit_training_type").prop("disabled", false);
                switch (MasterTrainingTypeEditResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case VALIDATION_ERROR:
                        toast.error(MasterTrainingTypeEditResponse.message);
                        props.handleClose(true);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterTrainingTypeEditUpdate, [props.MasterTrainingTypeEdit])

    const onSubmit = (data) => {
        $("#training_type_edit-form-loader").removeClass("hidden");
        $("#edit_training_type").prop("disabled", true);
        data.id = props.data?.id
        props.actionMasterEditTrainingType(data);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Edit Training Type
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Type of Training&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        maxLength={50}
                                                        placeholder="Enter Type of Training"
                                                        {...register('name', {
                                                            required: 'Type of Training is required'
                                                        })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        value: 'ACTIVE',
                                                                        text: 'ACTIVE'

                                                                    },
                                                                    {
                                                                        value: 'INACTIVE',
                                                                        text: 'INACTIVE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Status is required'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="edit_training_type"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="training_type_edit-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>

                                        <button
                                            id="cancel-placement"
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            type="button"
                                            onClick={(event) => {
                                                props.handleClose();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    var MasterTrainingTypeEdit = null;

    if (
        state.MasterEditTrainingTypeReducer != null &&
        Object.keys(state.MasterEditTrainingTypeReducer).length > 0 &&
        state.MasterEditTrainingTypeReducer.constructor === Object
    ) {
        switch (state.MasterEditTrainingTypeReducer.tag) {
            case ACTION_MASTER_EDIT_TRAINING_TYPE_SUCCESS:
                MasterTrainingTypeEdit = Object.assign({}, state.MasterEditTrainingTypeReducer.data);
                delete state.MasterEditTrainingTypeReducer;
                return { MasterTrainingTypeEdit };
            default:

        }
    }
    return {
        MasterTrainingTypeEdit,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterEditTrainingType }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TrainingTypeEdit))