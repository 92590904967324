import Api from '../../../../api';
import { fetchSuccess, loading } from '../../../../utils/action.util';
import { API_RESET_PASSWORD, API_VALIDATE_PASSWORD_TOKEN } from '../../../../api/constants';
import { PRINT } from '../../../../utils';

export const ACTION_RESET_PASSWORD_LOADING = 'ACTION_RESET_PASSWORD_LOADING';
export const ACTION_RESET_PASSWORD_SUCCESS = 'ACTION_RESET_PASSWORD_SUCCESS';

export const ACTION_VALIDATE_PASSWORD_TOKEN_LOADING = 'ACTION_VALIDATE_PASSWORD_TOKEN_LOADING';
export const ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS = 'ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS';

export function actionChangePassword(params) {
    return (dispatch, getState) => 
    Api.post(API_RESET_PASSWORD , params).then((res) => {
        const data = res;
        PRINT('RESET_PASSWORD RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_RESET_PASSWORD_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_RESET_PASSWORD_LOADING, { loading: false, data }));
    });
}


export function actionValidateToken(params) {
    return (dispatch, getState) => 
    Api.post(API_VALIDATE_PASSWORD_TOKEN , params).then((res) => {
        const data = res;
        PRINT('RESET_PASSWORD RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_VALIDATE_PASSWORD_TOKEN_LOADING, { loading: false, data }));
    });
}

