import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import $ from "jquery";
import { verifyPermission } from '../../../../../../utils';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from "moment";
import { withAuth } from "react-auth-guard";
import EditAssignEngineer from "../../../../../../component/popups/edit_risk_assign_engineer";
import { actionRiskPoolList, ACTION_RISK_POOL_LIST_SUCCESS } from "./action"

const RiskPool = (props) => {
    const { match } = props;
    const [assignPoolCount, setAssignPoolCount] = useState(0);
    const [unassignPoolCount, setUnassignPoolCount] = useState(0);
    const [requestType, setRequestType] = useState('assigned');
    const [riskRequest, setRiskRequest] = useState('Assigned')
    const [riskPool, setRiskPool] = useState([])
    const [showAssignEngineer, setShowAssignEngineer] = useState(false)
    const [id, setId] = useState(null)
    const [assignEngineer, setAssignEngineer] = useState(null)


    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "id", caption: "ID", visible: true },
        { dataField: "inspection_date", caption: "Date of Inspection", visible: false },
        { dataField: "inspection_date_formated", caption: "Date of Inspection", visible: true },
        { dataField: "client_name", caption: "Client Name", visible: true },
        { dataField: "address", caption: "Location Address", visible: true },
        { dataField: "occupancy", caption: "Occupancy", visible: true },
        { dataField: "assign_engineer", caption: "Assigned Engineer", visible: true },
        { dataField: "inspection_type", caption: "Type of Inspection", visible: true },
        { dataField: "sum_insured", caption: "Sum Insured", visible: true },
        { dataField: "rm_first_name", caption: "RM Name", visible: true },
        { dataField: "rm_city_name", caption: "RM Location", visible: true },
        { dataField: "risk_management_document", caption: "Document", visible: true },
        { dataField: "actual_date_inspection_formated", caption: "Actual Date of Inspection", visible: true },
        { dataField: "actual_date_inspection", caption: "Actual Date of Inspection", visible: false },
        { dataField: "raised_by", caption: "Created By", visible: true },
        { dataField: "created_date", caption: "Created Date", visible: false },
        { dataField: "created_date_formated", caption: "Created Date", visible: true },
        { dataField: "approved_by", caption: "Approved By", visible: true },
        // { dataField: "remarks", caption: "Remark", visible: true },
        // { dataField: "description", caption: "Description", visible: true },
        // { dataField: "financial_year", caption: "Financial Year", visible: true },

    ]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        if (props.location?.state?.from === 'notification') {
            setRequestType('un-assigned')
            setRiskRequest('Unassigned')
            props.actionRiskPoolList({ user_type: 'un-assigned' })
        } else {
            setRequestType('assigned')
            setRiskRequest('Assigned')
            props.actionRiskPoolList({ user_type: 'assigned' })
        }
    }
    useEffect(initialRender, []);

    const onRiskPoolListUpdate = () => {
        const { RiskPoolList } = props;
        if (RiskPoolList != null) {
            setRiskPool([]);
            var RiskPoolListResponse = Object.assign({}, RiskPoolList);
            if (RiskPoolListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                if (requestType == "un-assigned") {
                    setUnassignPoolCount(RiskPoolListResponse.response.length);
                    setAssignPoolCount(0)
                    setColumns([
                        { dataField: "Action", caption: "Action", type: "button" },
                        { dataField: "id", caption: "ID", visible: true },
                        { dataField: "inspection_date", caption: "Date of Inspection", visible: false },
                        { dataField: "inspection_date_formated", caption: "Date of Inspection", visible: true },
                        { dataField: "client_name", caption: "Client Name", visible: true },
                        { dataField: "address", caption: "Location Address", visible: true },
                        { dataField: "occupancy", caption: "Occupancy", visible: true },
                        { dataField: "inspection_type", caption: "Type of Inspection", visible: true },
                        { dataField: "sum_insured", caption: "Sum Insured", visible: true },
                        { dataField: "rm_first_name", caption: "RM Name", visible: true },
                        { dataField: "rm_city_name", caption: "RM Location", visible: true },
                        { dataField: "risk_management_document", caption: "Document", visible: true },
                        { dataField: "actual_date_inspection", caption: "Actual Date of Inspection", visible: false },
                        { dataField: "actual_date_inspection_formated", caption: "Actual Date of Inspection", visible: true },
                        { dataField: "raised_by", caption: "Created By", visible: true },
                        { dataField: "created_date", caption: "Created Date", visible: false },
                        { dataField: "created_date_formated", caption: "Created Date", visible: true },
                        { dataField: "approved_by", caption: "Approved By", visible: true },
                        // { dataField: "remarks", caption: "Remark", visible: true },
                        // { dataField: "description", caption: "Description", visible: true },
                        // { dataField: "financial_year", caption: "Financial Year", visible: true },

                    ]);
                } else {
                    setColumns([
                        { dataField: "Action", caption: "Action", type: "button" },
                        { dataField: "id", caption: "ID", visible: true },
                        { dataField: "inspection_date", caption: "Date of Inspection", visible: false },
                        { dataField: "inspection_date_formated", caption: "Date of Inspection", visible: true },
                        { dataField: "client_name", caption: "Client Name", visible: true },
                        { dataField: "address", caption: "Location Address", visible: true },
                        { dataField: "occupancy", caption: "Occupancy", visible: true },
                        { dataField: "assign_engineer", caption: "Assigned Engineer", visible: true },
                        { dataField: "inspection_type", caption: "Type of Inspection", visible: true },
                        { dataField: "sum_insured", caption: "Sum Insured", visible: true },
                        { dataField: "rm_first_name", caption: "RM Name", visible: true },
                        { dataField: "rm_city_name", caption: "RM Location", visible: true },
                        { dataField: "risk_management_document", caption: "Document", visible: true },
                        { dataField: "actual_date_inspection", caption: "Actual Date of Inspection", visible: false },
                        { dataField: "actual_date_inspection_formated", caption: "Actual Date of Inspection", visible: true },
                        { dataField: "raised_by", caption: "Created By", visible: true },
                        { dataField: "created_date", caption: "Created Date", visible: false },
                        { dataField: "created_date_formated", caption: "Created Date", visible: true },
                        { dataField: "approved_by", caption: "Approved By", visible: true },
                        // { dataField: "remarks", caption: "Remark", visible: true },
                        // { dataField: "description", caption: "Description", visible: true },
                        // { dataField: "financial_year", caption: "Financial Year", visible: true },
                        // { dataField: "status_name", caption: "Status", visible: true },
                        // { dataField: "engineer_status_name", caption: "Engineer Status", visible: true },
                    ]);
                    setAssignPoolCount(RiskPoolListResponse.response.length)
                    setUnassignPoolCount(0)
                }
                setRiskPool(RiskPoolListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (RiskPoolListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setAssignPoolCount(0);
                        setUnassignPoolCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onRiskPoolListUpdate, [props.RiskPoolList])

    const renderDocumentCell = (cell) => {
        return <div>{cell.data.risk_management_document != null && cell.data.risk_management_document != '' ? 'Yes' : 'No'}</div>;
    }

    const renderEngineerStatus = (cell) => {
        return <div>{cell.data.engineer_status_name != null && cell.data.engineer_status_name != "Not Defined" ? cell.data.engineer_status_name : ''}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderInspectionDate = (cell) => {
        return <div>{cell?.data?.inspection_date_formated != null ? moment(cell?.data?.inspection_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActualInspectionDate = (cell) => {
        return <div>{cell?.data?.actual_date_inspection_formated != null ? moment(cell?.data?.actual_date_inspection_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        {
                            verifyPermission("RISK_POOL_ASSIGNED_REQUEST") ?
                                <li class="nav-item">
                                    <button
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setRequestType('assigned')
                                            setRiskRequest('Assigned')
                                            props.actionRiskPoolList({ user_type: 'assigned' })
                                        }}
                                        className={`btn ${requestType === 'assigned' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        Assigned Requests <span className='mr-half'>{assignPoolCount > 0 ? `(` + assignPoolCount + `)` : ``}</span>
                                    </button>
                                </li>
                                :
                                <></>
                        }
                        {
                            verifyPermission("RISK_POOL_UNASSIGNED_REQUEST") ?
                                <li class="nav-items ml-1">
                                    <button
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setRequestType('un-assigned')
                                            setRiskRequest('Unassigned')
                                            props.actionRiskPoolList({ user_type: 'un-assigned' })
                                        }}
                                        className={`btn ${requestType === 'un-assigned' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        Unassigned Requests <span className='mr-half'>{unassignPoolCount > 0 ? `(` + unassignPoolCount + `)` : ``}</span>
                                    </button>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </div>

            </>
        )

    }
    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("RISK_MANAGEMNT_POOL_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id, request_type: riskRequest, status_name: params?.data?.status } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("RISK_MANAGEMNT_POOL_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${params.data.engineer_status === null || params.data.engineer_status === 'update' ? '' : 'hidden'}`}
                                            onClick={() => {
                                                if (requestType === 'assigned') {
                                                    props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                                } else {
                                                    setShowAssignEngineer(true)
                                                    setId(params.data.id)
                                                    setAssignEngineer(params.data?.assign_engineer_id)
                                                }
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "risk_management_document") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                        cellRender={renderDocumentCell}
                    ></Column>
                );
            } else if (objColumn.dataField === "engineer_status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                        cellRender={renderEngineerStatus}
                    ></Column>
                );
            } else if (objColumn.dataField === "created_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "inspection_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderInspectionDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "actual_date_inspection_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActualInspectionDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };


    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={riskPool}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Risk Management Pool",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("created_date_formated", "visible", false)
                                        event.component.columnOption("created_date", "visible", true)
                                        event.component.columnOption("inspection_date_formated", "visible", false)
                                        event.component.columnOption("inspection_date", "visible", true)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", false)
                                        event.component.columnOption("actual_date_inspection", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("created_date_formated", "visible", true)
                                        event.component.columnOption("created_date", "visible", false)
                                        event.component.columnOption("inspection_date_formated", "visible", true)
                                        event.component.columnOption("inspection_date", "visible", false)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", true)
                                        event.component.columnOption("actual_date_inspection", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditAssignEngineer
                show={showAssignEngineer}
                id={id}
                assign_engineers={assignEngineer}
                handleClose={(riskPool) => {
                    if (riskPool != null && riskPool.updated == true) {
                        toast.success("Engineer Assigned Successfully");
                        props.actionRiskPoolList({
                            user_type: requestType
                        })
                    }
                    setShowAssignEngineer(false)
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {

    var RiskPoolList = null;

    if (
        state.RiskPoolListReducer != null &&
        Object.keys(state.RiskPoolListReducer).length > 0 &&
        state.RiskPoolListReducer.constructor === Object
    ) {
        switch (state.RiskPoolListReducer.tag) {
            case ACTION_RISK_POOL_LIST_SUCCESS:
                RiskPoolList = Object.assign({}, state.RiskPoolListReducer.data);
                delete state.RiskPoolListReducer;
                return { RiskPoolList };
            default:
        }
    }
    return {
        RiskPoolList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionRiskPoolList
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskPool))
