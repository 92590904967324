import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { API_RISK_MANAGEMENT_CLIENT_TYPE, API_ASSIGN_ENGINEER, API_RISK_MANAGEMENT_TYPE_OF_INSPECTION, API_RISK_MANAGEMENT_FINANCIAL_YEAR, API_RISK_MANAGEMENT_REQUEST_VIEW, API_RISK_MANAGEMENT_EDIT, API_MASTER_CITIES, API_RISK_MASTER_SALES_RM } from "../../../../../../api/constants";

export const ACTION_RISK_POOL_CLIENT_TYPE_LOADING = 'ACTION_RISK_POOL_CLIENT_TYPE_LOADING';
export const ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS = 'ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS';

export const ACTION_RISK_POOL_TYPE_OF_INSPECTION_LOADING = 'ACTION_RISK_POOL_TYPE_OF_INSPECTION_LOADING';
export const ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS = 'ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS';

export const ACTION_RISK_POOL_FINANCIAL_YEAR_LOADING = 'ACTION_RISK_POOL_FINANCIAL_YEAR_LOADING';
export const ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS = 'ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS';

export const ACTION_RISK_POOL_VIEW_LOADING = 'ACTION_RISK_POOL_VIEW_LOADING';
export const ACTION_RISK_POOL_VIEW_SUCCESS = 'ACTION_RISK_POOL_VIEW_SUCCESS';

export const ACTION_RISK_POOL_EDIT_LOADING = 'ACTION_RISK_POOL_EDIT_LOADING';
export const ACTION_RISK_POOL_EDIT_SUCCESS = 'ACTION_RISK_POOL_EDIT_SUCCESS';

export const ACTION_MASTER_ASSIGN_ENGINEER_LOADING = 'ACTION_MASTER_ASSIGN_ENGINEER_LOADING';
export const ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS = 'ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS';

export const ACTION_MASTER_SALES_RM_SUCCESS = "ACTION_MASTER_SALES_RM_SUCCESS";
export const ACTION_MASTER_SALES_RM_LOADING = "ACTION_MASTER_SALES_RM_LOADING";

export const ACTION_MASTER_CITIES_SUCCESS = "ACTION_MASTER_CITIES_SUCCESS";
export const ACTION_MASTER_CITIES_LOADING = "ACTION_MASTER_CITIES_LOADING";

export function actionRiskManagementClientType() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_CLIENT_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_POOL_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementTypeOfInspection() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_TYPE_OF_INSPECTION).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_POOL_TYPE_OF_INSPECTION_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementFinancialYear() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_FINANCIAL_YEAR).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_POOL_FINANCIAL_YEAR_LOADING, { loading: false, data }));
        });
}

export function actionViewRiskManagmentPool(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_MANAGEMENT_REQUEST_VIEW, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_POOL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_POOL_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionAssignEngineer() {
    return (dispatch, getState) =>
        Api.get(API_ASSIGN_ENGINEER).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_ENGINEER_LOADING, { loading: false, data }));
        });
}

export function actionEditRiskManagmentPool(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_RISK_MANAGEMENT_EDIT, params, files).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_POOL_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_POOL_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionSalesRM(params) {
    return (dispatch, getState) =>
        Api.get(API_RISK_MASTER_SALES_RM).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_SALES_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_SALES_RM_LOADING, { loading: false, data }));
        });
}

export function actionMasterCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CITIES_LOADING, { loading: false, data }));
        });
}