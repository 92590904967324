import React, { useEffect } from 'react';
import _ from 'lodash';
import $ from "jquery";
import dateFormat from 'dateformat';

const ViewProposalDetails = (props) => {

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    return (

        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                            Opportunity Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">RM Name</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.rm_name ? props.proposalListSelectedData.rm_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Client Name</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.client_name ? props.proposalListSelectedData.client_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Department</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.policy_type ? props.proposalListSelectedData.policy_type : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurance Company</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.insurance_company ? props.proposalListSelectedData.insurance_company : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Brokerage Amount</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.brokerage_amount && Number(props.proposalListSelectedData.brokerage_amount) > 0 ? "Rs. " + Number(props.proposalListSelectedData.brokerage_amount).toFixed(2) : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Brokerage Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.broaker_name ? props.proposalListSelectedData.broaker_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Expected Premium</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.expected_premium && Number(props.proposalListSelectedData.expected_premium) > 0 ? "Rs. " + Number(props.proposalListSelectedData.expected_premium).toFixed(2) : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Renewal Date</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.renewal_date != null ? dateFormat(props.proposalListSelectedData.renewal_date, "dd/mm/yyyy") : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Probability%</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.proposalListSelectedData != null && props.proposalListSelectedData.probability != null ? props.proposalListSelectedData.probability : '-'}
                                </h4>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.handleClose();
                                }}
                            >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ViewProposalDetails