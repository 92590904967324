import {ACTION_REIMBURSEMENT_APPROVAL_VIEW_SUCCESS,ACTION_REIMBURSEMENT_APPROVE_REJECT_SUCCESS} from './action';
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ViewReimbursementReducer = createReducer(INITIAL_STATE, {

    [ACTION_REIMBURSEMENT_APPROVAL_VIEW_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_APPROVAL_VIEW_SUCCESS,
            loading: false
        });
    },
    [ACTION_REIMBURSEMENT_APPROVE_REJECT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_APPROVE_REJECT_SUCCESS,
            loading: false
        });
    },
})
    export default ViewReimbursementReducer