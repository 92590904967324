import { ACTION_UPCOMING_MEETING_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
  };
  
const UpcomingMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_UPCOMING_MEETING_SUCCESS](state, action) {
        return Object.assign(
          {},
          {
            ...state,
            data: action.payload.data,
            tag: ACTION_UPCOMING_MEETING_SUCCESS,
            loading: false,
          }
        );
      },
});
export default UpcomingMeetingReducer;