import Api from "../../../../api"
import { fetchSuccess, loading } from "../../../../utils/action.util";
import { API_RISK_MANAGEMENT_REQUEST_VIEW } from "../../../../api/constants";
import { PRINT } from "../../../../utils";

export const ACTION_RISK_MANAGEMENT_VIEW_LOADING = 'ACTION_RISK_MANAGEMENT_VIEW_LOADING';
export const ACTION_RISK_MANAGEMENT_VIEW_SUCCESS = 'ACTION_RISK_MANAGEMENT_VIEW_SUCCESS';

export function actionViewRiskManagmentRequest(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_MANAGEMENT_REQUEST_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION CHECK VIEW RISK MANAGEMENT RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RISK_MANAGEMENT_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_MANAGEMENT_VIEW_LOADING, { loading: false, data }));
        });
}

