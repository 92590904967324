import React, { useState, useEffect } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionTransportEdit, ACTION_TRANSPORT_EDIT_SUCCESS } from './action';
import { actionTransportView, ACTION_TRANSPORT_VIEW_SUCCESS } from './action';
import { toast } from "react-toastify";
const Edit = (props) => {
    const { match } = props;
    const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm()
    const [is_km, setIs_Km] = useState(null);
    const [Transport, setTransport] = useState(null);
    const onSubmit = (data) => {
    }
    const onTransportViewUpdate = () => {
        const { TransportView } = props;
        var TransportViewResponse = Object.assign({}, TransportView);
        if (TransportViewResponse.result === true) {
            setTransport(TransportViewResponse.response);
            setValue('name', TransportViewResponse.response.name);
            setValue('is_km', TransportViewResponse.response.is_km);
            setValue('km_rate', TransportViewResponse.response.km_rate);
            setValue('status', TransportViewResponse.response.status);
        } else {
            switch (TransportViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onTransportViewUpdate, [props.TransportView]);

    const onTransportEditUpdate = () => {
        const { TransportEdit } = props;
        var TransportEditResponse = Object.assign({}, TransportEdit);
        if (TransportEditResponse.result === true) {
            props.history.goBack();
        } else {
            switch (TransportEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(TransportEditResponse.message);
                default:
            }
        }
    }
    useEffect(onTransportEditUpdate, [props.TransportEdit]);
    return (
        <>
            <ScreenTitle title="Edit Transport" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control"> Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter  Name"
                                                        {...register('name', { required: ' Name is required' })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">is_km&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="is_km"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[{
                                                                    'text': 'Yes',
                                                                    'value': 'yes'
                                                                },
                                                                {
                                                                    'text': 'No',
                                                                    'value': 'no'
                                                                }
                                                                ]}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select is_km"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData.value != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setIs_Km(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select is_km'
                                                        }}
                                                    />
                                                    {errors.is_km && <ErrorLabel message={errors.is_km?.message} />}
                                                </div>
                                            </div>
                                            {
                                                is_km != null && is_km === 'yes' ?
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="label-control">Km_rate&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="km_rate"
                                                                name="km_rate"
                                                                placeholder="Enter km_rate"
                                                                onKeyPress={(event) => {
                                                                    if (!/^\d{0,2}(\.\d{0,1}){0,1}$/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('km_rate', { required: ' Rate is required' })}
                                                            />
                                                            {errors.km_rate && <ErrorLabel message={errors.km_rate?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        title: "Active",
                                                                        id: 'active'
                                                                    },
                                                                    {
                                                                        title: "Inactive",
                                                                        id: 'inactive'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var TransportEdit = null;
    var TransportView = null;

    if (
        state.EditTransportReducer != null &&
        Object.keys(state.EditTransportReducer).length > 0 &&
        state.EditTransportReducer.constructor === Object
    ) {
        switch (state.EditTransportReducer.tag) {
            case ACTION_TRANSPORT_EDIT_SUCCESS:
                TransportEdit = Object.assign({}, state.EditTransportReducer.data);
                delete state.EditTransportReducer
                return { TransportEdit };
            case ACTION_TRANSPORT_VIEW_SUCCESS:
                TransportView = Object.assign({}, state.EditTransportReducer.data);
                delete state.EditTransportReducer
                return { TransportView };
            default:
        }
    }

    return {
        TransportEdit,
        TransportView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTransportEdit, actionTransportView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)

