import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { UNAUTHORIZED } from "../../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { actionMasterEditInsurerBranchContact, ACTION_MASTER_EDIT_BRANCH_CONTACT_SUCCESS } from "./action";

const ContactEdit = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm({
        defaultValues: {
            status: "ACTIVE"
        }
    });

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            // $("#contact-form-loader").addClass("hidden");
            if (props.data != null) {
                setValue('name', props.data != null && props?.data?.name != null ? props.data.name : '');
                setValue('designation', props.data != null && props?.data?.designation != null ? props.data.designation : '');
                setValue('mobile', props.data != null && props?.data?.mobile != null ? props.data.mobile : '');
                setValue('email', props.data != null && props?.data?.email != null ? props.data.email : '');
                setValue('status', props.data != null && props?.data?.status != null ? props.data.status : '');
            }
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            // $("#contact-form-loader").addClass("hidden");
            reset();
            // setValue('name', '');
            // setValue('designation', '');
            // setValue('mobile', '');
            // setValue('email', '');
            // setValue('status', '');
        }
    };
    useEffect(initialRender, [props.show]);

    const onMasterEditInsurerBranchContactUpdate = () => {
        const { MasterEditInsurerBranchContact } = props
        if (MasterEditInsurerBranchContact != null) {
            var MasterEditInsurerBranchContactResponse = Object.assign({}, MasterEditInsurerBranchContact);
            $("#edit-contact").prop("disabled", false);
            if (MasterEditInsurerBranchContactResponse.result === true) {
                $("#edit-contact-form-loader").addClass("hidden");
                toast.success(MasterEditInsurerBranchContactResponse.message);
                props.handleClose(true);
            } else {
                $("#edit-contact-form-loader").addClass("hidden");
                switch (MasterEditInsurerBranchContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterEditInsurerBranchContactUpdate, [props.MasterEditInsurerBranchContact])

    const onSubmit = (data) => {
        $("#edit-contact-form-loader").removeClass("hidden");
        $("#edit-contact").prop("disabled", true);
        data.id = props?.data?.id;
        data.branch_id = props?.branchId;
        props.actionMasterEditInsurerBranchContact(data);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Edit Contact Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Name&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Contact Name"
                                                        {...register('name', { required: 'Contact Name is required' })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Mobile Number&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="mobile"
                                                        name="mobile"
                                                        maxLength={10}
                                                        placeholder="Enter Number"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register('mobile', {
                                                            required: 'Mobile Number is required',
                                                            pattern: {
                                                                value: /^[6789]\d{9}$/,
                                                                message: 'Please enter 10 digit valid Mobile Number'
                                                            },
                                                        })}
                                                    />
                                                    {errors.mobile && <ErrorLabel message={errors.mobile?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Email&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Enter Contact Email"
                                                        {...register('email', {
                                                            required: 'Contact Email is required',
                                                            pattern: {
                                                                value: /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/i,
                                                                message: 'Please enter valid Email Id'
                                                            },
                                                        })}
                                                    />
                                                    {errors.email && <ErrorLabel message={errors.email?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Designation&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="designation"
                                                        name="designation"
                                                        placeholder="Enter Contact Designation"
                                                        {...register('designation', { required: 'Contact Designation is required', })}
                                                    />
                                                    {errors.designation && <ErrorLabel message={errors.designation?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        value: 'ACTIVE',
                                                                        text: 'ACTIVE'

                                                                    },
                                                                    {
                                                                        value: 'INACTIVE',
                                                                        text: 'INACTIVE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="edit-contact"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="edit-contact-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>

                                        <button
                                            id="cancel-contact"
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            type="button"
                                            onClick={(event) => {
                                                props.handleClose();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    var MasterEditInsurerBranchContact = null;

    if (
        state.MasterEditInsurerBranchContactReducer != null &&
        Object.keys(state.MasterEditInsurerBranchContactReducer).length > 0 &&
        state.MasterEditInsurerBranchContactReducer.constructor === Object
    ) {
        switch (state.MasterEditInsurerBranchContactReducer.tag) {
            case ACTION_MASTER_EDIT_BRANCH_CONTACT_SUCCESS:
                MasterEditInsurerBranchContact = Object.assign({}, state.MasterEditInsurerBranchContactReducer.data);
                delete state.MasterEditInsurerBranchContactReducer;
                return { MasterEditInsurerBranchContact };
            default:

        }
    }
    return {
        MasterEditInsurerBranchContact,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterEditInsurerBranchContact }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ContactEdit))