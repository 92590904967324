import { ACTION_GET_ATTENDANCE_SUCCESS, ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS, ACTION_REGULARIZE_REQUEST_ADD_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddRegularizeReducer = createReducer(INITIAL_STATE, {
    [ACTION_GET_ATTENDANCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_ATTENDANCE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REGULARIZE_REQUEST_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REGULARIZE_REQUEST_ADD_SUCCESS,
                loading: false,
            }
        );
    },
});

export default AddRegularizeReducer;    