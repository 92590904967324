import { ACTION_TEAM_MASTER_EDIT_SUCCESS } from "./action";
import { ACTION_TEAM_HEAD_EDIT_SUCCESS } from "./action";
import { ACTION_BRANCH_EDIT_SUCCESS } from "./action";
import { ACTION_GET_TEAM_MASTER_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditTeamMasterReducer = createReducer(INITIAL_STATE, {
    [ACTION_TEAM_MASTER_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TEAM_MASTER_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TEAM_HEAD_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TEAM_HEAD_EDIT_SUCCESS,
                loading: false
            }
        );
    },
    [ACTION_BRANCH_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_BRANCH_EDIT_SUCCESS,
                loading: false
            }
        );
    },
    [ACTION_GET_TEAM_MASTER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_TEAM_MASTER_SUCCESS,
                loading: false
            }
        );
    },
});

export default EditTeamMasterReducer;