import { API_ACCOMPAINED_BY } from '../../../../../../api/constants';
import { API_GET_TPA_NAME } from '../../../../../../api/constants';
import { API_TPA_MEETING_ADD } from '../../../../../../api/constants';
import { API_TPAMEETINGADDCONTACT } from '../../../../../../api/constants';
import { API_MEETING_LIST_CONTACT } from '../../../../../../api/constants';

import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_API_ACCOMPAINED_BY_LOADING = 'ACTION_API_ACCOMPAINED_BY_LOADING';
export const ACTION_API_ACCOMPAINED_BY_SUCCESS = 'ACTION_API_ACCOMPAINED_BY_SUCCESS';

export const ACTION_TPA_MEETING_SAVE_LOADING = 'ACTION_TPA_MEETING_SAVE_LOADING';
export const ACTION_TPA_MEETING_SAVE_SUCCESS = 'ACTION_TPA_MEETING_SAVE_SUCCESS';

export const ACTION_GET_TPA_NAME_LOADING = 'ACTION_GET_TPA_NAME_LOADING';
export const ACTION_GET_TPA_NAME_SUCCESS = 'ACTION_GET_TPA_NAME_SUCCESS';

export const TPAMEETINGADDCONTACT_LOADING = 'TPAMEETINGADDCONTACT_LOADING';
export const ACTION_TPAMEETINGADDCONTACT_SUCCESS = 'ACTION_TPAMEETINGADDCONTACT_SUCCESS';

export const ACTION_TPA_GET_CONTACT_LOADING = 'ACTION_TPA_GET_CONTACT_LOADING';
export const ACTION_TPA_GET_CONTACT_SUCCESS = 'ACTION_TPA_GET_CONTACT_SUCCESS';

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionTpaMeetingSave(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_TPA_MEETING_ADD,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TPA_MEETING_SAVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TPA_MEETING_SAVE_LOADING, { loading: false, data }));
        });
}

export function actionGetTpaName(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_TPA_NAME,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_TPA_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_TPA_NAME_LOADING, { loading: false, data }));
        });
}

export function actionTPAMeetingAddContact(params) {
    return (dispatch, getState) => Api.post(API_TPAMEETINGADDCONTACT, params).then((res) => {
        const data = res;
        PRINT('TPAMEETINGADDCONTACT ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_TPAMEETINGADDCONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(TPAMEETINGADDCONTACT_LOADING, { loading: false, data }));
    });
}

export function actionGetCompanyContacts(params) {
    return (dispatch, getState) => Api.post(API_MEETING_LIST_CONTACT, params).then((res) => {
        const data = res;
        PRINT('TPAMEETINGADDCONTACT ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_TPA_GET_CONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_TPA_GET_CONTACT_LOADING, { loading: false, data }));
    });
}
