import {ACTION_RESET_PASSWORD_SUCCESS} from './action';
import {ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS} from './action';
import createReducer from '../../../../reducers/createReducer';
    
const INITIAL_STATE = {
        data: [],
        loading: false
    };

const ChangePasswordReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_RESET_PASSWORD_SUCCESS](state, action) {
                        return Object.assign({}, {
                            ...state,
                            data: action.payload.data,
                            tag: ACTION_RESET_PASSWORD_SUCCESS,
                            loading: false
                        });
                    },
        [ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS,
                loading: false
            });
        }
    }
);
export default ChangePasswordReducer;