import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiTrash2 } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PlacementSpecificAdd from "../add";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { actionMasterGetSpecificPlacementRight, ACTION_MASTER_GET_SPECIFIC_PLACEMENT_RIGHTS_SUCCESS } from "./action";
import { actionMasterDeleteSpecificPlacementRight, ACTION_MASTER_DELETE_SPECIFIC_PLACEMENT_RIGHTS_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../utils";
import Swal from "sweetalert2";
import moment from 'moment';

const PlacementSpecificList = (props) => {
    const [showAddPlacements, setShowAddPlacements] = useState(false)
    const [placementsRight, setPlacementsRight] = useState(null)

    const [placements, setPlacements] = useState([]);
    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "user_name", caption: "Selected Employee", visible: true },
        { dataField: "branch", caption: "Branch", visible: true },
        { dataField: "updated_at", caption: "Created On", visible: false },
        { dataField: "updated_at_formated", caption: "Created On", visible: true },
        { dataField: "modified_by_name", caption: "Created By", visible: true },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionMasterGetSpecificPlacementRight();
    }
    useEffect(initialRender, []);

    const onMasterGetSpecificPlacementRightUpdate = () => {
        const { MasterGetSpecificPlacementRight } = props
        if (MasterGetSpecificPlacementRight != null) {
            var MasterGetSpecificPlacementRightResponse = Object.assign({}, MasterGetSpecificPlacementRight);
            if (MasterGetSpecificPlacementRightResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPlacementsRight(MasterGetSpecificPlacementRightResponse.response.authorized);
                setPlacements(MasterGetSpecificPlacementRightResponse.response.data);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetSpecificPlacementRightResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetSpecificPlacementRightUpdate, [props.MasterGetSpecificPlacementRight])

    const onMasterDeleteSpecificPlacementRightUpdate = () => {
        const { MasterDeleteSpecificPlacementRight } = props
        if (MasterDeleteSpecificPlacementRight != null) {
            var MasterDeleteSpecificPlacementRightResponse = Object.assign({}, MasterDeleteSpecificPlacementRight);
            if (MasterDeleteSpecificPlacementRightResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                toast.success(MasterDeleteSpecificPlacementRightResponse.message);
                props.actionMasterGetSpecificPlacementRight();
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterDeleteSpecificPlacementRightResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterDeleteSpecificPlacementRightUpdate, [props.MasterDeleteSpecificPlacementRight])

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.updated_at_formated != null ? moment(cell?.data?.updated_at_formated).format('DD-MMM-YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("MASTER_PLACEMENT_SPECIFIC_DELETE") && placementsRight === true ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: `Delete`,
                                                    text: `Are you sure, you want to delete ${params.data.user_name} ?`,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Cancel',
                                                    confirmButtonText: 'Yes',
                                                    confirmButtonColor: PRIMARY_COLOR,
                                                }).then(success => {
                                                    if (success.isConfirmed) {
                                                        $("#progress-loader").removeClass("hidden");
                                                        props.actionMasterDeleteSpecificPlacementRight({ user_id: params.data.user_id })
                                                    }
                                                });
                                            }}
                                        >
                                            <FiTrash2 size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }

                    </Column>
                );
            } else if (objColumn.dataField === "updated_at_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        width={140}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                )
            } else if (objColumn.dataField === "modified_by") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        width={140}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            } else if (objColumn.dataField === "status") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        width={80}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Specific Placements Rights</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {
                                verifyPermission("MASTER_PLACEMENT_SPECIFIC_ADD") && placementsRight === true ?
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                // props.history.push(`${match.url}/add`);/
                                                setShowAddPlacements(true)
                                            }}
                                        >
                                            Add Specific Placements
                                        </button>
                                    </li>
                                    :
                                    <></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={placements}
                                    showBorders={true}
                                    export={{
                                        fileName: "Specific Placements",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("updated_at_formated", "visible", false)
                                        event.component.columnOption("updated_at", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("updated_at_formated", "visible", true)
                                        event.component.columnOption("updated_at", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PlacementSpecificAdd
                show={showAddPlacements}
                handleClose={(newPlacement) => {
                    if (newPlacement) {
                        props.actionMasterGetSpecificPlacementRight();
                    }
                    setShowAddPlacements(false)
                }}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    var MasterGetSpecificPlacementRight = null;
    var MasterDeleteSpecificPlacementRight = null;

    if (
        state.MasterGetPlacementSpecificRightReducer != null &&
        Object.keys(state.MasterGetPlacementSpecificRightReducer).length > 0 &&
        state.MasterGetPlacementSpecificRightReducer.constructor === Object
    ) {
        switch (state.MasterGetPlacementSpecificRightReducer.tag) {
            case ACTION_MASTER_GET_SPECIFIC_PLACEMENT_RIGHTS_SUCCESS:
                MasterGetSpecificPlacementRight = Object.assign({}, state.MasterGetPlacementSpecificRightReducer.data);
                delete state.MasterGetPlacementSpecificRightReducer;
                return { MasterGetSpecificPlacementRight };
            case ACTION_MASTER_DELETE_SPECIFIC_PLACEMENT_RIGHTS_SUCCESS:
                MasterDeleteSpecificPlacementRight = Object.assign({}, state.MasterGetPlacementSpecificRightReducer.data);
                delete state.MasterGetPlacementSpecificRightReducer;
                return { MasterDeleteSpecificPlacementRight };
            default:

        }
    }
    return {
        MasterGetSpecificPlacementRight,
        MasterDeleteSpecificPlacementRight
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterGetSpecificPlacementRight, actionMasterDeleteSpecificPlacementRight }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PlacementSpecificList))
