import React, { useState, useEffect } from "react";
import { withAuth } from "react-auth-guard";
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DateBox } from 'devextreme-react/date-box';
import moment from "moment";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import Swal from 'sweetalert2';
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, MEETING_TRAINING_FILE_ERROR_MESSAGE, MEETING_TRAINING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import $ from 'jquery';
import { actionMasterTraining, ACTION_MASTER_TRAINING_SUCCESS } from "./action";
import { actionMasterBrokerEntity, ACTION_MASTER_BROKER_ENTITY_SUCCESS } from "./action";
import { actionMasterBrokerSubEntity, ACTION_MASTER_BROKER_SUB_ENTITY_SUCCESS } from "./action";
import { actionMasterBrokerDepartment, ACTION_MASTER_BROKER_DEPARTMENT_SUCCESS } from "./action";
import { actionMasterBrokerBranch, ACTION_MASTER_TRAINING_BRANCH_SUCCESS } from "./action";
import { actionBrokerTrainingAdd, ACTION_BROKER_TRAINING_ADD_SUCCESS } from "./action";
import { actionBranchCity, ACTION_BRANCH_CITY_SUCCESS } from "./action";
import { actionEmployeeNames, ACTION_EMPLOYEE_NAMES_SUCCESS } from "./action";
import { checkFileExtension } from "../../../../../../utils";
import { actionExpiredCertificateEmployee, ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS } from "./action";

const AddFunction = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            status: 'Active'
        }
    });
    var watchTrainingDetails = watch('training_details');
    var watchSpeakerName = watch('speaker_name');
    const [typeOfTraining, setTypeOfTraining] = useState([]);
    const [masterEntity, setMasterEntity] = useState([]);
    const [masterSubEntity, setMasterSubEntity] = useState([]);
    const [masterDepartment, setMasterDepartment] = useState([]);
    const [masterBranchCity, setMasterBranchCity] = useState([]);
    const [employeeNames, setEmployeeNames] = useState([]);
    const [masterBranch, setMasterBranch] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [uploadCertificateCopy, setUploadCertificateCopy] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [employeeExpiredCertificate, setEmployeeExpiredCertificate] = useState([]);
    const validExt = ['jpg', 'jpeg', 'png'];
    const initialRender = () => {
        props.actionMasterTraining({ "status": "ACTIVE" });
        props.actionMasterBrokerEntity();
        props.actionMasterBrokerDepartment();
    }
    useEffect(initialRender, []);

    const onMasterTrainingUpdate = () => {
        const { MasterTraining } = props
        if (MasterTraining != null) {
            var MasterTrainingResponse = Object.assign({}, MasterTraining);
            if (MasterTrainingResponse.result === true) {
                setTypeOfTraining(MasterTrainingResponse.response);
            } else {
                switch (MasterTrainingResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterTrainingUpdate, [props.MasterTraining])

    const onMasterBrokerEntityUpdate = () => {
        const { MasterBrokerEntity } = props
        if (MasterBrokerEntity != null) {
            var MasterBrokerEntityResponse = Object.assign({}, MasterBrokerEntity);
            if (MasterBrokerEntityResponse.result === true) {
                setMasterEntity(MasterBrokerEntityResponse.response);
            } else {
                switch (MasterBrokerEntityResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerEntityUpdate, [props.MasterBrokerEntity])

    const onMasterBrokerSubEntityUpdate = () => {
        const { MasterBrokerSubEntity } = props
        if (MasterBrokerSubEntity != null) {
            var MasterBrokerSubEntityResponse = Object.assign({}, MasterBrokerSubEntity);
            if (MasterBrokerSubEntityResponse.result === true) {
                setMasterSubEntity(MasterBrokerSubEntityResponse.response);
            } else {
                switch (MasterBrokerSubEntityResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerSubEntityUpdate, [props.MasterBrokerSubEntity])

    const onMasterBrokerDepartmentUpdate = () => {
        const { MasterBrokerDepartment } = props
        if (MasterBrokerDepartment != null) {
            var MasterBrokerDepartmentResponse = Object.assign({}, MasterBrokerDepartment);
            if (MasterBrokerDepartmentResponse.result === true) {
                setMasterDepartment(MasterBrokerDepartmentResponse.response);
            } else {
                switch (MasterBrokerDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerDepartmentUpdate, [props.MasterBrokerDepartment])

    const onMasterBranchCityUpdate = () => {
        const { MasterBranchCity } = props
        if (MasterBranchCity != null) {
            var MasterBranchCityResponse = Object.assign({}, MasterBranchCity);
            if (MasterBranchCityResponse.result === true) {
                setMasterBranchCity(MasterBranchCityResponse.response);
                // const idSet = new Set(MasterBranchCityResponse.response.map(item => item.id));
                // setValue('branch_city', Array.from(idSet));
            } else {
                switch (MasterBranchCityResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBranchCityUpdate, [props.MasterBranchCity])

    const onMasterBrokerBranchUpdate = () => {
        const { MasterBrokerBranch } = props
        if (MasterBrokerBranch != null) {
            var MasterBrokerBranchResponse = Object.assign({}, MasterBrokerBranch);
            if (MasterBrokerBranchResponse.result === true) {
                setMasterBranch(MasterBrokerBranchResponse.response);
                // const idSet = new Set(MasterBrokerBranchResponse.response.map(item => item.id));
                // setValue('branch', Array.from(idSet));
            } else {
                switch (MasterBrokerBranchResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerBranchUpdate, [props.MasterBrokerBranch])

    const onEmployeeExpiredCertificateUpdate = () => {
        const { EmployeeExpiredCertificate } = props
        if (EmployeeExpiredCertificate != null) {
            var EmployeeExpiredCertificateResponse = Object.assign({}, EmployeeExpiredCertificate);
            if (EmployeeExpiredCertificateResponse.result === true) {
                setEmployeeExpiredCertificate(EmployeeExpiredCertificateResponse.response.data);
                // const idSet = new Set(EmployeeExpiredCertificateResponse.response.map(item => item.user_id));
                // setValue('emp_ids', Array.from(idSet));
            } else {
                switch (EmployeeExpiredCertificateResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEmployeeExpiredCertificateUpdate, [props.EmployeeExpiredCertificate])

    const onEmployeeNamesUpdate = () => {
        const { EmployeeNames } = props
        if (EmployeeNames != null) {
            var EmployeeNamesResponse = Object.assign({}, EmployeeNames);
            if (EmployeeNamesResponse.result === true) {
                setEmployeeNames(EmployeeNamesResponse.response);
                const ExpiredCertificationId = new Set()
                EmployeeNamesResponse.response.forEach(item => {
                    if (employeeExpiredCertificate.includes(item.user_id)) {
                        ExpiredCertificationId.add(item.user_id);
                    }
                });
                setValue('emp_ids', Array.from(ExpiredCertificationId));
            } else {
                switch (EmployeeNamesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEmployeeNamesUpdate, [props.EmployeeNames])

    const onBrokerTrainingAddUpdate = () => {
        const { BrokerTrainingAdd } = props
        if (BrokerTrainingAdd != null) {
            var BrokerTrainingAddResponse = Object.assign({}, BrokerTrainingAdd);
            $("#add-function").prop("disabled", false);
            if (BrokerTrainingAddResponse.result === true) {
                $("#add-function-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, BrokerTrainingAddResponse.message);
                props.history.goBack();
            } else {
                $("#add-function-loader").addClass("hidden");
                switch (BrokerTrainingAddResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setData(KEY_TOAST_MESSAGE, BrokerTrainingAddResponse.message);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onBrokerTrainingAddUpdate, [props.BrokerTrainingAdd])

    const onSubmit = (data) => {
        const { start_time, end_time } = data;
        const startTimeDate = new Date(`2000-01-01T${start_time}`);
        const endTimeDate = new Date(`2000-01-01T${end_time}`);
        data.companies = JSON.stringify(data.companies);
        data.product_ids = JSON.stringify(data.product_ids);
        data.sub_companies = JSON.stringify(data.sub_companies);
        data.department = JSON.stringify(data.department);
        data.branch_city = JSON.stringify(data.branch_city);
        data.branch = JSON.stringify(data.branch);
        data.emp_ids = JSON.stringify(data.emp_ids);
        if (fromDate > toDate) {
            Swal.fire({
                title: 'Error',
                text: "Start date can't be greater than End date",
                icon: 'error',
            }).then(success => {

            });
        } else {
            if (startTimeDate >= endTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: "Start time cannot be greater than or equal to end time",
                    icon: 'error',
                }).then(success => {

                });
            } else {
                var files = [];
                if (uploadCertificateCopy != null) {
                    files.push({ 'name': 'speaker_photo', 'value': uploadCertificateCopy });
                    $("#add-function-loader").removeClass("hidden");
                    $("#add-function").prop("disabled", true);
                    props.actionBrokerTrainingAdd(data, files);
                } else {
                    $('#error-pdf-doc-not-found').removeClass('d-none')
                }
                delete data.speaker_photo;
            }
        }

    }

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <h3 className="content-header-title text-capitalize mb-0 ">Add Training Setting</h3>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Type of Training&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="training_type_id"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="training_type_id"
                                                        name="training_type_id"
                                                        dataSource={typeOfTraining}
                                                        fields={{ text: "name", value: "id" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Type of Training"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                                if (event.itemData.name === 'BQP Training') {
                                                                    props.actionExpiredCertificateEmployee()
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Type of Training'
                                                }}
                                            />
                                            {errors.training_type_id && <ErrorLabel message={errors.training_type_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Name&nbsp;<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                id="training_name"
                                                name="training_name"
                                                placeholder="Enter Training Name"
                                                {...register('training_name', { required: 'Training Name is required' })}
                                            />
                                            {errors.training_name && <ErrorLabel message={errors.training_name?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Start date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="start_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        {...field}
                                                        id="start_date"
                                                        name="start_date"
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        displayFormat="dd/MM/yyyy"
                                                        placeholder="Start date"
                                                        showClearButton={false}
                                                        min={moment().subtract(30, 'days').toDate()}
                                                        max={moment().add(180, 'days').toDate()}
                                                        onValueChange={(value) => {
                                                            const selectedDate = moment(value);
                                                            if (toDate && selectedDate.isAfter(toDate)) {
                                                                setToDate(null);
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "Start date can't be greater than End date",
                                                                    icon: 'error',
                                                                }).then(success => { });
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setFromDate(selectedDate);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please select start date'
                                                }}
                                            />
                                            {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">End date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="end_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        {...field}
                                                        id="end_date"
                                                        name="end_date"
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        displayFormat="dd/MM/yyyy"
                                                        placeholder="End date"
                                                        showClearButton={false}
                                                        min={fromDate != null ? moment(fromDate).format("YYYY-MM-DD") : moment().toDate()}
                                                        max={fromDate != null ? moment(fromDate).add(180, 'days').format("YYYY-MM-DD") : moment().add(180, 'days').toDate()}
                                                        onValueChange={(value) => {
                                                            const selectedDate = moment(value);
                                                            if (fromDate && selectedDate.isBefore(fromDate)) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: 'End date must be greater than Start date',
                                                                    icon: 'error',
                                                                }).then(success => { });
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setToDate(selectedDate);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please select end date'
                                                }}
                                            />
                                            {errors.end_date && <ErrorLabel message={errors.end_date?.message} />}

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Timing&nbsp;<span className="danger">*</span></label>
                                            <div className="d-flex flex-row">
                                                <div className="col-6">
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="start_time"
                                                        name="start_time"
                                                        placeholder="--:-- --"
                                                        {...register('start_time', { required: 'Start time is required' })}
                                                    />
                                                    {errors.start_time && <ErrorLabel message={errors.start_time?.message} />}
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="end_time"
                                                        name="end_time"
                                                        placeholder="--:-- --"
                                                        {...register('end_time', { required: 'End time is required' })}
                                                    />
                                                    {errors.end_time && <ErrorLabel message={errors.end_time?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Entity&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="companies"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterEntity,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="companies"
                                                        name="companies"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Entity"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={4}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                props.actionMasterBrokerSubEntity({ 'company_ids': value });
                                                                setValue('sub_companies', '');
                                                                setSelectedEntity(value)
                                                                setMasterSubEntity([])
                                                                // props.actionEmployeeNames({
                                                                //     "entity_id": value,
                                                                //     "department_id": selectedDepartment,
                                                                //     "branch_id": selectedBranch,
                                                                //     "function_id": selectedFunction,
                                                                // });
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Entity'
                                                }}
                                            />
                                            {errors.companies && <ErrorLabel message={errors.companies?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Department&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="sub_companies"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterSubEntity,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="sub_companies"
                                                        name="sub_companies"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Department"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={5}
                                                        showMultiTagOnly={false}
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                // props.actionMasterBrokerBranch({ 'company_ids': value });
                                                                props.actionBranchCity({ 'company_ids': value });
                                                                setSelectedDepartment(value)
                                                                setValue('branch_city', '');
                                                                setMasterBranchCity([])
                                                                // props.actionEmployeeNames({
                                                                //     "entity_id": selectedEntity,
                                                                //     "department_id": value,
                                                                //     "branch_id": selectedBranch,
                                                                //     "function_id": selectedFunction,
                                                                // });
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Department'
                                                }}
                                            />
                                            {errors.sub_companies && <ErrorLabel message={errors.sub_companies?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Function&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="department"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterDepartment,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="department"
                                                        name="department"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Function"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={5}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                setSelectedFunction(value)
                                                                props.actionEmployeeNames({
                                                                    "entity_id": selectedEntity,
                                                                    "department_id": selectedDepartment,
                                                                    "branch_id": selectedBranch,
                                                                    "function_id": value,
                                                                });
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Function'
                                                }}
                                            />
                                            {errors.department && <ErrorLabel message={errors.department?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Mode&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="training_mode"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="training_mode"
                                                        name="training_mode"
                                                        dataSource={[
                                                            {
                                                                id: "Physical",
                                                                name: "Physical"
                                                            },
                                                            {
                                                                id: "Online",
                                                                name: "Online"
                                                            },
                                                            {
                                                                id: "Online+Offline",
                                                                name: "Online+Offline"
                                                            }
                                                        ]}
                                                        fields={{ text: "name", value: "id" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Training Mode"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Training Mode'
                                                }}
                                            />
                                            {errors.training_mode && <ErrorLabel message={errors.training_mode?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Branch City&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="branch_city"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterBranchCity,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="branch_city"
                                                        name="branch_city"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Branch City"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        maxDisplayedTags={2}
                                                        height={"30%"}
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                console.log("value", value);
                                                                setValue('branch', null)
                                                                setMasterBranch([])
                                                                props.actionMasterBrokerBranch({ 'city_ids': value });
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Branch City'
                                                }}
                                            />
                                            {errors.branch_city && <ErrorLabel message={errors.branch_city?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Branch Address&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="branch"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterBranch,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="branch"
                                                        name="branch"
                                                        deferRendering={true}
                                                        displayExpr="address1"
                                                        placeholder="Select Branch Address"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        maxDisplayedTags={2}
                                                        height={"30%"}
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                console.log("value", value);
                                                                setSelectedBranch(value)
                                                                setEmployeeNames([])
                                                                setSelectedEmployee([])
                                                                setValue('emp_ids', '')
                                                                props.actionEmployeeNames({
                                                                    "entity_id": selectedEntity,
                                                                    "department_id": selectedDepartment,
                                                                    "branch_id": value,
                                                                    "function_id": selectedFunction,
                                                                });
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Branch Address'
                                                }}
                                            />
                                            {errors.branch && <ErrorLabel message={errors.branch?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Employee List&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="emp_ids"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: employeeNames,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="emp_ids"
                                                        name="emp_ids"
                                                        deferRendering={true}
                                                        displayExpr="full_name"
                                                        valueExpr="user_id"
                                                        placeholder="Select Employees"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        maxDisplayedTags={2}
                                                        height={"30%"}
                                                        value={field.value != null && field.value.length > 0 ? field.value : selectedEmployee}
                                                        onValueChanged={({ value, event }) => {
                                                            if ((value != null || value !== undefined) && value.length > 0) {
                                                                setSelectedEmployee(value);
                                                                field.onChange(value);
                                                            } else {
                                                                if (selectedEmployee != null && selectedEmployee.length > 0) {
                                                                    field.onChange("");
                                                                    setSelectedEmployee([])
                                                                }
                                                            }
                                                            if (event != null) {
                                                                if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                    field.onChange("");
                                                                    setSelectedEmployee([])
                                                                }
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Employee Names'
                                                }}
                                            />
                                            {errors.emp_ids && <ErrorLabel message={errors.emp_ids?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Details&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="training_details"
                                                name="training_details"
                                                rows={5}
                                                maxLength={200}
                                                minLength={10}
                                                placeholder="Enter Short Description"
                                                {...register('training_details', {
                                                    required: 'Short Description is required',
                                                    minLength: {
                                                        value: 10,
                                                        message: "Please enter minimum 10 Characters"
                                                    }
                                                })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                {/* <div className="flex-column"><small className="text-muted">{`Max ${200} characters`}</small></div> */}
                                                <div className="flex-column"><small>{watchTrainingDetails != null ? watchTrainingDetails.length : 0}/{200}</small></div>
                                            </div>
                                            {errors.training_details && <ErrorLabel message={errors.training_details?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Speaker Name&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="speaker_name"
                                                name="speaker_name"
                                                rows={5}
                                                maxLength={200}
                                                placeholder="Enter Speaker Name"
                                                {...register('speaker_name', {
                                                    required: 'Speaker Name is required',
                                                })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                {/* <div className="flex-column"><small className="text-muted">{`Max ${200} characters`}</small></div> */}
                                                <div className="flex-column"><small>{watchSpeakerName != null ? watchSpeakerName.length : 0}/{200}</small></div>
                                            </div>
                                            {errors.speaker_name && <ErrorLabel message={errors.speaker_name?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <fieldset class="form-group">
                                            <label for="basicInputFile">Speaker Photo&nbsp;<span className="danger">*</span></label>
                                            <div class="custom-file">
                                                <input
                                                    type="file"
                                                    class="custom-file-input"
                                                    accept=".jpg, .jpeg, .png"
                                                    id="speaker_photo"
                                                    name="speaker_photo"
                                                    onChange={(event) => {
                                                        $('#error-pdf-doc-not-found').addClass('d-none')
                                                        if (event.target.files != null) {

                                                            var fileSize = event.target.files[0]?.size;

                                                            var ext = (event.target.files[0]?.name)?.split('.').pop();

                                                            if (checkFileExtension(ext, validExt)) {

                                                                if (Math.round(fileSize / 1024) <= MEETING_TRAINING_FILE_SIZE) {
                                                                    setUploadCertificateCopy(event.target.files[0]);
                                                                } else {
                                                                    Swal.fire({
                                                                        title: 'Error',
                                                                        icon: 'error',
                                                                        html: MEETING_TRAINING_FILE_ERROR_MESSAGE,
                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                    }).then((result) => {

                                                                    });
                                                                }
                                                            } else {
                                                                Swal.fire({
                                                                    title: 'Upload Error',
                                                                    text: 'You can only upload jpg, jpeg & png files',
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            }
                                                        } else {
                                                            $('#error-pdf-doc-not-found').removeClass('d-none')
                                                        }
                                                    }}
                                                />
                                                <small className="error-msg d-none text-danger" id="error-pdf-doc-not-found">Document is required</small>
                                                <label class="custom-file-label" for="speaker_photo">
                                                    {
                                                        uploadCertificateCopy != null ?
                                                            uploadCertificateCopy.name : "Choose file"
                                                    }
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="status"
                                                        name="status"
                                                        dataSource={[
                                                            {
                                                                id: "Active",
                                                                name: "Active"
                                                            },
                                                            {
                                                                id: 'Inactive',
                                                                name: 'Inactive'
                                                            },
                                                        ]}
                                                        fields={{ text: "name", value: "id" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Status"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Status'
                                                }}
                                            />
                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions border-0 float-none">
                                    <button
                                        id="add-function"
                                        className="btn btn-primary mr-1 shadow round text-uppercase"
                                        type="submit"
                                    >
                                        <div className="d-flex align-items-center">
                                            Submit
                                            <span id="add-function-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </div>
                                    </button>
                                    <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            props.history.goBack();
                                        }}
                                    >Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterTraining = null;
    var MasterBrokerEntity = null;
    var MasterBrokerSubEntity = null;
    var MasterBrokerDepartment = null;
    var MasterBrokerBranch = null;
    var BrokerTrainingAdd = null;
    var MasterBranchCity = null;
    var EmployeeNames = null;
    var EmployeeExpiredCertificate = null;

    if (
        state.BrokerTrainingAddReducer != null &&
        Object.keys(state.BrokerTrainingAddReducer).length > 0 &&
        state.BrokerTrainingAddReducer.constructor === Object
    ) {
        switch (state.BrokerTrainingAddReducer.tag) {
            case ACTION_MASTER_TRAINING_SUCCESS:
                MasterTraining = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { MasterTraining };
            case ACTION_MASTER_BROKER_ENTITY_SUCCESS:
                MasterBrokerEntity = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { MasterBrokerEntity };
            case ACTION_MASTER_BROKER_SUB_ENTITY_SUCCESS:
                MasterBrokerSubEntity = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { MasterBrokerSubEntity };
            case ACTION_MASTER_BROKER_DEPARTMENT_SUCCESS:
                MasterBrokerDepartment = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { MasterBrokerDepartment };
            case ACTION_MASTER_TRAINING_BRANCH_SUCCESS:
                MasterBrokerBranch = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { MasterBrokerBranch };
            case ACTION_BROKER_TRAINING_ADD_SUCCESS:
                BrokerTrainingAdd = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { BrokerTrainingAdd };
            case ACTION_BRANCH_CITY_SUCCESS:
                MasterBranchCity = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { MasterBranchCity };
            case ACTION_EMPLOYEE_NAMES_SUCCESS:
                EmployeeNames = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { EmployeeNames };
            case ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS:
                EmployeeExpiredCertificate = Object.assign({}, state.BrokerTrainingAddReducer.data);
                delete state.BrokerTrainingAddReducer;
                return { EmployeeExpiredCertificate };
            default:

        }
    }
    return {
        MasterTraining,
        MasterBrokerEntity,
        MasterBrokerSubEntity,
        MasterBrokerDepartment,
        MasterBrokerBranch,
        BrokerTrainingAdd,
        MasterBranchCity,
        EmployeeNames,
        EmployeeExpiredCertificate,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterTraining, actionMasterBrokerEntity, actionMasterBrokerSubEntity, actionMasterBrokerDepartment, actionMasterBrokerBranch, actionBrokerTrainingAdd, actionBranchCity, actionEmployeeNames, actionExpiredCertificateEmployee }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AddFunction))