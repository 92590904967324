import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CLAIMS_VIEW, API_CLAIMS_DOCUMENT_LIST, API_GET_CLAIMS_DOCUMENT_UPLOAD } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';
import { API_GET_CLAIM_EDIT_EVENT_DETAILS } from '../../../../../api/constants';


export const ACTION_CLAIMS_VIEW_LOADING = 'ACTION_CLAIMS_VIEW_LOADING';
export const ACTION_CLAIMS_VIEW_SUCCESS = 'ACTION_CLAIMS_VIEW_SUCCESS';

export const ACTION_CLAIMS_DOCUMENT_VIEW_LOADING = 'ACTION_CLAIMS_DOCUMENT_VIEW_LOADING';
export const ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS = 'ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS';

export const ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_LOADING = 'ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_LOADING';
export const ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS = 'ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS';

export const ACTION_VIEW_GET_CLAIMS_DOCUMENT_LOADING = 'ACTION_VIEW_GET_CLAIMS_DOCUMENT_LOADING';
export const ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS = 'ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS';

export function actionClaimsView(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionClaimsDocumentList(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_DOCUMENT_LIST,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_DOCUMENT_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionViewClaimEditEventDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_CLAIM_EDIT_EVENT_DETAILS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_LOADING, { loading: false, data }));
        });
}
export function actionClaimsDocumentURL(params) {
    return (dispatch, getState) =>
        Api.postData(API_GET_CLAIMS_DOCUMENT_UPLOAD,params).then((res) => {
            const data = {};
            data.url = res;

            console.log('URL ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_VIEW_GET_CLAIMS_DOCUMENT_LOADING, { loading: false, data }));
        });
}