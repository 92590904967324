import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_PERMISSION_ROLES_CREATE } from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';

export const ACTION_ROLL_CREATE_LOADING = 'ACTION_ROLL_CREATE_LOADING';
export const ACTION_ROLL_CREATE_SUCCESS = 'ACTION_ROLL_CREATE_SUCCESS';

export function actionRollCreate(params) {
    return (dispatch, getState) =>
        Api.post(API_PERMISSION_ROLES_CREATE,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ROLL_CREATE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ROLL_CREATE_LOADING, { loading: false, data }));
        });
}