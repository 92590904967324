import { ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ItDeclarationPoolDetailReducer = createReducer(INITIAL_STATE, {
    [ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS,
                loading: false,
            }
        );
    }
});

export default ItDeclarationPoolDetailReducer;