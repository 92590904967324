import { ACTION_EDIT_IT_DECLARATION_DETAIL_SUCCESS, ACTION_EDIT_IT_DECLARATION_EDIT_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const HRMSItDeclarationEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_EDIT_IT_DECLARATION_DETAIL_SUCCESS](state, action) {
        return Object.assign(
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_IT_DECLARATION_DETAIL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_IT_DECLARATION_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_IT_DECLARATION_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
});

export default HRMSItDeclarationEditReducer;