import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_MASTER_ASSIGN_RM_SUCCESS, ACTION_RM_GET_LEADS_SUCCESS, ACTION_RM_GET_CLIENTS_SUCCESS, ACTION_RM_TRANSFER_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const RMTransferReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RM_GET_LEADS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RM_GET_LEADS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RM_GET_CLIENTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RM_GET_CLIENTS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RM_TRANSFER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RM_TRANSFER_SUCCESS,
                loading: false,
            }
        );
    }
});
export default RMTransferReducer;