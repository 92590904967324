import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_SURVEYOR_MEETING_VIEW } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_SURVEYOR_MEETING_VIEW_LOADING = 'ACTION_SURVEYOR_MEETING_VIEW_LOADING';
export const ACTION_SURVEYOR_MEETING_VIEW_SUCCESS = 'ACTION_SURVEYOR_MEETING_VIEW_SUCCESS';

export function actionServeyorMeetingsView(params) {
    return (dispatch, getState) =>
        Api.post(API_SURVEYOR_MEETING_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_SURVEYOR_MEETING_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SURVEYOR_MEETING_VIEW_LOADING, { loading: false, data }));
        });
}