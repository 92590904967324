import React, { useEffect } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser } from 'react-icons/fi';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionShiftAdd, EMPLOYEE_SHIFT_ADD_SUCCESS } from './action';
import { useState } from 'react';
import { toast } from 'react-toastify';
var hours = 1;
const Add = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm()

    const watchStartTime = watch('start_time')
    const watchEndTime = watch('end_time')
    const [workingHours, setWorkingHours] = useState(null);
    const initialRender = () => {
    }
    useEffect(initialRender, [props.show]);

    const onShiftTimingUpdate = () => {
        const { ShiftTimingAdd } = props;
        var ShiftTimingResponse = Object.assign({}, ShiftTimingAdd);
        $("#add_shift").prop("disabled", false);
        if (ShiftTimingResponse.result === true) {
            $("#add_shift-loader").addClass("hidden");
            props.history.push('/settings/shift-timing');
        } else {
            $("#add_shift-loader").addClass("hidden");
            switch (ShiftTimingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(ShiftTimingResponse.message);
                default:
            }
        }
    }
    useEffect(onShiftTimingUpdate, [props.ShiftTimingAdd]);

    useEffect(() => {
        /**
         * set working_hour value as difference of startTime and endTime
         */

        var minutes = 0;
        if (watchStartTime != null && watchEndTime != null) {
            var endTIme = moment(watchEndTime, "HH:mm:ss a");
            var startTime = moment(watchStartTime, "HH:mm:ss a");
            hours = moment(endTIme).diff(startTime, 'hours', false);
            minutes = moment(endTIme).diff(startTime, 'minutes', false);
            minutes = minutes % 60;
        }
        setValue('working_hour', `${hours + ':' + (minutes >= 10 ? minutes : '0' + minutes)}`)
        setWorkingHours(`${hours + ':' + (minutes >= 10 ? minutes : '0' + minutes)}`)
        if (hours > 0 && hours !== null && hours !== undefined && hours <= 12 && hours >= 8) {
            $("#error-working-hours").addClass('d-none')
        }
    }, [watchStartTime, watchEndTime])

    const onSubmit = (data) => {
        data.working_hour = workingHours
        if (hours > 0 && hours !== null && hours !== undefined && hours <= 12 && hours >= 8) {
            $("#error-working-hours").addClass('d-none')
            $("#add_shift-loader").removeClass("hidden");
            $("#add_shift").prop("disabled", true);
            props.actionShiftAdd(data);
        }
    }
    return (

        <>
            <ScreenTitle title="Add Shift Timing" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Shift-Timing Information
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Shift Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="shift_name"
                                                        name="shift_name"
                                                        placeholder="Enter Shift Name"
                                                        {...register('shift_name', { required: 'Shift Name is required' })}
                                                    />
                                                    {errors.shift_name && <ErrorLabel message={errors.shift_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Start Time</label>&nbsp;
                                                    <span className="danger">*</span>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="start_time"
                                                        name="start_time"
                                                        defaultValue={moment().format('HH:mm')}
                                                        placeholder="--:-- --"
                                                        {...register('start_time', { required: 'Start Time is required' })}
                                                    />
                                                    {errors.start_time && <ErrorLabel message={errors.start_time?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">End Time</label>&nbsp;
                                                    <span className="danger">*</span>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="end_time"
                                                        name="end_time"
                                                        defaultValue={moment().add(1, 'hours').format('HH:mm')}
                                                        placeholder="--:-- --"
                                                        {...register('end_time', { required: 'End Time is required' })}
                                                    />
                                                    {errors.end_time && <ErrorLabel message={errors.end_time?.message} />}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Working Hours&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control pl-1"
                                                        id="working_hour"
                                                        name="working_hour"
                                                        placeholder="Enter Woking Hour"
                                                        defaultValue={1}
                                                        disabled={true}
                                                        {...register('working_hour',
                                                            {
                                                                validate: (value) => {
                                                                    return value > 0 && value !== null && value !== undefined && value <= 12 && value >= 8 ? true : $("#error-working-hours").removeClass('d-none')
                                                                }
                                                            }
                                                        )}
                                                    />
                                                    <small className="d-none error-msg text-danger" id="error-working-hours">Working hours should be 8-12 Hours</small>
                                                    {errors.working_hour && <ErrorLabel message={errors.working_hour?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Grace Period (Mins)&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="grace_period"
                                                        name="grace_period"
                                                        maxLength={2}
                                                        placeholder="Enter Grace Period"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();

                                                            }

                                                        }}
                                                        {...register("grace_period", {
                                                            required: "Grace Period is required",
                                                            validate: (value) => {
                                                                return value != null && value >= 61 ? 'Please enter maximum 60 Grace Period' : true
                                                            },
                                                        })}

                                                    >
                                                    </input>
                                                    {errors.grace_period && <ErrorLabel message={errors.grace_period?.message} />}

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        title: "Active",
                                                                        id: 'active'
                                                                    },
                                                                    {
                                                                        title: "Inactive",
                                                                        id: 'inactive'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="add_shift"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add_shift-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ShiftTimingAdd = null;

    if (
        state.AddShiftTimingReducer != null &&
        Object.keys(state.AddShiftTimingReducer).length > 0 &&
        state.AddShiftTimingReducer.constructor === Object
    ) {
        switch (state.AddShiftTimingReducer.tag) {
            case EMPLOYEE_SHIFT_ADD_SUCCESS:
                ShiftTimingAdd = Object.assign({}, state.AddShiftTimingReducer.data);
                delete state.AddShiftTimingReducer
                return { ShiftTimingAdd };
            default:
        }
    }

    return {
        ShiftTimingAdd,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionShiftAdd }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Add)
