import {API_MASTER_PROBABILITY } from '../../../../../api/constants';
import { API_MASTER_ASSIGN_RM } from '../../../../../api/constants';
import { API_GET_DEPARTMENT } from '../../../../../api/constants';
import {API_PROPOSAL_DASHBOARD_VIEW} from '../../../../../api/constants';
import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';

export const ACTION_MASTER_PROBABILITY_PROPOSAL_LOADING = 'ACTION_MASTER_PROBABILITY_PROPOSAL_LOADING';
export const ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS = 'ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS';

export const ACTION_MASTER_ASSIGN_RM_LOADING = 'ACTION_MASTER_ASSIGN_RM_LOADING';
export const ACTION_MASTER_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_ASSIGN_RM_SUCCESS';

export const ACTION_CLAIMS_POLICY_TYPE_LOADING = 'ACTION_CLAIMS_POLICY_TYPE_LOADING';
export const ACTION_CLAIMS_POLICY_TYPE_SUCCESS = 'ACTION_CLAIMS_POLICY_TYPE_SUCCESS';

export const ACTION_PROPOSAL_DASHBOARD_VIEW_LOADING ='ACTION_PROPOSAL_DASHBOARD_VIEW_LOADING';
export const ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS='ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS';

export function actionProbability(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_PROBABILITY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_PROBABILITY_PROPOSAL_LOADING, { loading: false, data }));
        });
}

export function actionProposalRM(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ASSIGN_RM, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}

export function actionProposalPolicyType() {
    return (dispatch, getState) =>
        Api.get(API_GET_DEPARTMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionProposalDashboard(params) {
    return (dispatch, getState) =>
        Api.post(API_PROPOSAL_DASHBOARD_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROPOSAL_DASHBOARD_VIEW_LOADING, { loading: false, data }));
        });
}