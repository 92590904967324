import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_DECLARATION_EDIT, API_IT_DECLARATION_FINANCIAL_YEAR, API_IT_DECLARATION_SETTINGS_LIST } from '../../../../../../api/constants';

export const ACTION_IT_DECLARATION_EDIT_LOADING = 'ACTION_IT_DECLARATION_EDIT_LOADING';
export const ACTION_IT_DECLARATION_EDIT_SUCCESS = 'ACTION_IT_DECLARATION_EDIT_SUCCESS';

export const ACTION_IT_DECLARATION_FINANCIAL_YEAR_LOADING = 'ACTION_IT_DECLARATION_FINANCIAL_YEAR_LOADING';
export const ACTION_IT_DECLARATION_FINANCIAL_YEAR_SUCCESS = 'ACTION_IT_DECLARATION_FINANCIAL_YEAR_SUCCESS';

export const ACTION_IT_DECLARATION_LIST_LOADING = 'ACTION_IT_DECLARATION_LIST_LOADING';
export const ACTION_IT_DECLARATION_LIST_SUCCESS = 'ACTION_IT_DECLARATION_LIST_SUCCESS';

export function actionItDeclarationEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_EDIT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionFinancialYear() {
    return (dispatch, getState) =>
        Api.get(API_IT_DECLARATION_FINANCIAL_YEAR).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_FINANCIAL_YEAR_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_FINANCIAL_YEAR_LOADING, { loading: false, data }));
        });
}

export function actionDeclarationList(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_SETTINGS_LIST, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_LIST_LOADING, { loading: false, data }));
        });
}
