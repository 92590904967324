import React from 'react';
import { Route, Switch } from 'react-router';
import InternalMeetingAdd from './add';
import InternalMeetingEdit from './edit';
import InternalMeeting from './list';
import InternalMeetingView from './view';

const InternalRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={InternalMeetingAdd}/>
                <Route path={`${match.url}/edit`}  component={InternalMeetingEdit}/>
                <Route path={`${match.url}/view`}  component={InternalMeetingView}/>
                <Route exact path={`${match.url}`}  component={InternalMeeting}/>
            </Switch>
        </>
    )
}

export default InternalRoute