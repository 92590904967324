import React, { useEffect, useState } from "react";
import ErrorLabel from "../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import $ from 'jquery';

const ReimbursementAdd = (props) => {
    const { handleSubmit, control, formState: { errors } } = useForm()
    const [monthYear, setMonthYear] = useState(null);
    const [year, setYear] = useState(null);

    const initialRender = (data) => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show])

    const onSubmit = (data) => {
        var newReimbursement = { month_year: monthYear, rem_year: year };
        $("#contact-form-loader").removeClass("hidden");
        $("#save-contact").prop("disabled", true);
        props.handleClose(newReimbursement);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                New Reimbursement Request
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <h4 class="d-flex form-section my-0 black">
                    </h4>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label-control">
                                                Select the Month&nbsp;
                                                <span className="danger">
                                                    *
                                                </span>
                                            </label>
                                            <Controller
                                                name="rem_month"
                                                className="form-control"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="rem_month"
                                                        name="rem_month"
                                                        dataSource={props.months}
                                                        fields={{ text: 'rem_month_label', value: 'rem_month' }}
                                                        allowFiltering={true}
                                                        filterType={"Contains"}
                                                        placeholder="Select the month"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.rem_month)
                                                                setMonthYear(event.itemData)
                                                                setYear(event.itemData.rem_year)
                                                            }
                                                        }}

                                                    />
                                                }
                                                rules={{
                                                    required: 'Month is required'
                                                }}
                                            />
                                            {errors.rem_month && <ErrorLabel message={errors.rem_month?.message} />}
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-2 float-none">
                                        <button
                                            id="add_reimbursement"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"

                                        >
                                            Submit
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReimbursementAdd
