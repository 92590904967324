import createReducer from '../../../../../../reducers/createReducer';
import { ACTION_BUSINESS_SUMMARY_SUCCESS } from './action';
import { ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS, ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS } from './action';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const BusinessSummaryReducer = createReducer(INITIAL_STATE, {
    [ACTION_BUSINESS_SUMMARY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_BUSINESS_SUMMARY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS,
                loading: false,
            }
        );
    },
});
export default BusinessSummaryReducer;