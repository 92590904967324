import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_TRAINING, API_MASTER_ENTITY, API_MASTER_SUB_ENTITY, API_MASTER_DEPARTMENT, API_BROKER_TRAINING_ADD, API_MASTER_BRANCH_CITY, API_EMPLOYEE_NAMES, API_MASTER_TRAINING_BRANCH, API_EMPLOYEE_EXPIRED_CERTIFICATE } from '../../../../../../api/constants';

export const ACTION_MASTER_TRAINING_LOADING = 'ACTION_MASTER_TRAINING_LOADING';
export const ACTION_MASTER_TRAINING_SUCCESS = 'ACTION_MASTER_TRAINING_SUCCESS';

export const ACTION_MASTER_BROKER_ENTITY_LOADING = 'ACTION_MASTER_BROKER_ENTITY_LOADING';
export const ACTION_MASTER_BROKER_ENTITY_SUCCESS = 'ACTION_MASTER_BROKER_ENTITY_SUCCESS';

export const ACTION_MASTER_BROKER_SUB_ENTITY_LOADING = 'ACTION_MASTER_BROKER_SUB_ENTITY_LOADING';
export const ACTION_MASTER_BROKER_SUB_ENTITY_SUCCESS = 'ACTION_MASTER_BROKER_SUB_ENTITY_SUCCESS';

export const ACTION_MASTER_BROKER_DEPARTMENT_LOADING = 'ACTION_MASTER_BROKER_DEPARTMENT_LOADING';
export const ACTION_MASTER_BROKER_DEPARTMENT_SUCCESS = 'ACTION_MASTER_BROKER_DEPARTMENT_SUCCESS';

export const ACTION_MASTER_TRAINING_BRANCH_LOADING = 'ACTION_MASTER_TRAINING_BRANCH_LOADING';
export const ACTION_MASTER_TRAINING_BRANCH_SUCCESS = 'ACTION_MASTER_TRAINING_BRANCH_SUCCESS';

export const ACTION_BROKER_TRAINING_ADD_LOADING = 'ACTION_BROKER_TRAINING_ADD_LOADING';
export const ACTION_BROKER_TRAINING_ADD_SUCCESS = 'ACTION_BROKER_TRAINING_ADD_SUCCESS';

export const ACTION_BRANCH_CITY_LOADING = 'ACTION_BRANCH_CITY_LOADING';
export const ACTION_BRANCH_CITY_SUCCESS = 'ACTION_BRANCH_CITY_SUCCESS';

export const ACTION_EMPLOYEE_NAMES_LOADING = 'ACTION_EMPLOYEE_NAMES_LOADING';
export const ACTION_EMPLOYEE_NAMES_SUCCESS = 'ACTION_EMPLOYEE_NAMES_SUCCESS';

export const ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_LOADING = 'ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_LOADING';
export const ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS = 'ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS';

export function actionMasterTraining(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_TRAINING, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_TRAINING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_TRAINING_LOADING, { loading: false, data }));
        });
}

export function actionMasterBrokerEntity() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_ENTITY).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_BROKER_ENTITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_BROKER_ENTITY_LOADING, { loading: false, data }));
        });
}

export function actionMasterBrokerSubEntity(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_SUB_ENTITY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_BROKER_SUB_ENTITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_BROKER_SUB_ENTITY_LOADING, { loading: false, data }));
        });
}

export function actionMasterBrokerDepartment() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_DEPARTMENT).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_BROKER_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_BROKER_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionMasterBrokerBranch(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_TRAINING_BRANCH, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_TRAINING_BRANCH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_TRAINING_BRANCH_LOADING, { loading: false, data }));
        });
}

export function actionBrokerTrainingAdd(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_BROKER_TRAINING_ADD, params, files).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_BROKER_TRAINING_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BROKER_TRAINING_ADD_LOADING, { loading: false, data }));
        });
}

export function actionBranchCity(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_BRANCH_CITY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_BRANCH_CITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BRANCH_CITY_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeNames(params) {
    return (dispatch, getState) =>
        Api.post(API_EMPLOYEE_NAMES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EMPLOYEE_NAMES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_NAMES_LOADING, { loading: false, data }));
        });
}

export function actionExpiredCertificateEmployee() {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_EXPIRED_CERTIFICATE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EXPIRED_CERTIFICATE_EMPLOYEE_LOADING, { loading: false, data }));
        });
}