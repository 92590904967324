import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TagBox } from "devextreme-react";
import ArrayStore from 'devextreme/data/array_store';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { UNAUTHORIZED, VALIDATION_ERROR } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { actionMasterEmployeeList, ACTION_MASTER_GET_SPECIFIC_EMPLOYEE_LIST_SUCCESS } from "./action";
import { actionMasterAddSpecificPlacementRights, ACTION_MASTER_ADD_SPECIFIC_PLACEMENT_RIGHTS_SUCCESS } from "./action";

const PlacementSpecificAdd = (props) => {
    const { handleSubmit, formState: { errors }, setValue, reset, control } = useForm();
    const [teamMembers, setTeamMembers] = useState([]);
    const [mySelectedTeam, setMySelectedTeam] = useState(null)
    const initialRender = () => {
        if (props.show === true) {
            props.actionMasterEmployeeList();
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setValue('department_id', null);
            setTeamMembers([]);
            setMySelectedTeam([])
            reset();

        }
    };
    useEffect(initialRender, [props.show]);


    const onMasterEmployeeListUpdate = () => {
        const { MasterEmployeeList } = props
        if (MasterEmployeeList != null) {
            var MasterEmployeeListResponse = Object.assign({}, MasterEmployeeList);
            if (MasterEmployeeListResponse.result === true) {
                setTeamMembers(MasterEmployeeListResponse.response);
            } else {
                switch (MasterEmployeeListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterEmployeeListUpdate, [props.MasterEmployeeList])

    const onMasterAddPlacementRightsUpdate = () => {
        const { MasterAddPlacementRights } = props
        if (MasterAddPlacementRights != null) {
            var MasterAddPlacementRightsResponse = Object.assign({}, MasterAddPlacementRights);
            $("#save_specific_placement").prop("disabled", false);
            if (MasterAddPlacementRightsResponse.result === true) {
                $("#specific_placement-form-loader").addClass("hidden");
                $("#save_specific_placement").prop("disabled", false);
                toast.success(MasterAddPlacementRightsResponse.message);
                props.handleClose(true);
            } else {
                $("#specific_placement-form-loader").addClass("hidden");
                $("#save_specific_placement").prop("disabled", false);
                switch (MasterAddPlacementRightsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case VALIDATION_ERROR:
                        toast.error(MasterAddPlacementRightsResponse.message);
                        props.handleClose(true);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterAddPlacementRightsUpdate, [props.MasterAddPlacementRights])

    const onSubmit = (data) => {
        $("#specific_placement-form-loader").removeClass("hidden");
        $("#save_specific_placement").prop("disabled", true);
        props.actionMasterAddSpecificPlacementRights(data);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Add Specific Placements
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                                reset();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="label-control">Select Team Member&nbsp;</label>
                                                    <Controller
                                                        name="user_ids"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                id="user_ids"
                                                                name="user_ids"
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: teamMembers,
                                                                        key: 'user_id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        ('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        ('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        ('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        ('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="full_name"
                                                                valueExpr="user_id"
                                                                placeholder="Select Team Member"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={5}
                                                                selectAllMode={true}
                                                                showSelectionControls={true}
                                                                acceptCustomValue={false}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value != null && field.value.length > 0 ? field.value : mySelectedTeam}
                                                                onValueChanged={({ value, event }) => {
                                                                    if ((value != null || value !== undefined) && value.length > 0) {
                                                                        setMySelectedTeam(value)
                                                                        field.onChange(value);
                                                                    } else {
                                                                        if (mySelectedTeam != null && mySelectedTeam.length > 0) {
                                                                            field.onChange("");
                                                                            setMySelectedTeam([])
                                                                        }
                                                                    }
                                                                    if (event != null) {
                                                                        if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                            field.onChange("");
                                                                            setMySelectedTeam([])
                                                                        }
                                                                    }
                                                                }}
                                                                onSelectionChanged={(e) => {
                                                                    if (e.addedItems.length > 0) {
                                                                        e.component.field().value = "";
                                                                        e.component.close();
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Team Member'
                                                        }}
                                                    />
                                                    {errors.user_ids && <ErrorLabel message={errors.user_ids?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="save_specific_placement"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="specific_placement-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>

                                        <button
                                            id="cancel-placement"
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            type="button"
                                            onClick={(event) => {
                                                props.handleClose();
                                                reset();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    var MasterEmployeeList = null;
    var MasterAddPlacementRights = null;

    if (
        state.MasterAddSpecificPlacementRightReducer != null &&
        Object.keys(state.MasterAddSpecificPlacementRightReducer).length > 0 &&
        state.MasterAddSpecificPlacementRightReducer.constructor === Object
    ) {
        switch (state.MasterAddSpecificPlacementRightReducer.tag) {
            case ACTION_MASTER_GET_SPECIFIC_EMPLOYEE_LIST_SUCCESS:
                MasterEmployeeList = Object.assign({}, state.MasterAddSpecificPlacementRightReducer.data);
                delete state.MasterAddSpecificPlacementRightReducer;
                return { MasterEmployeeList };
            case ACTION_MASTER_ADD_SPECIFIC_PLACEMENT_RIGHTS_SUCCESS:
                MasterAddPlacementRights = Object.assign({}, state.MasterAddSpecificPlacementRightReducer.data);
                delete state.MasterAddSpecificPlacementRightReducer;
                return { MasterAddPlacementRights };
            default:

        }
    }
    return {
        MasterEmployeeList,
        MasterAddPlacementRights
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterEmployeeList, actionMasterAddSpecificPlacementRights }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PlacementSpecificAdd))
