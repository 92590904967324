import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import dateFormat from 'dateformat';
import { UNAUTHORIZED, ERROR } from '../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { actionCreateTask, ACTION_CREATE_TASK_SUCCESS } from './action';
import Swal from 'sweetalert2';
import { actionCreateTaskAccompainedBy, ACTION_CREATE_TASK_ACCOMPAINED_BY_SUCCESS } from './action';
import { getAccompainedByIds } from "../../../../../utils";

const CreateTask = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch, setValue, getValues } = useForm()
    const minDateValue = new Date()
    const [assignTo, setAssignTo] = useState();
    var watchDescription = watch('description');

    const initialRender = () => {
        props.actionCreateTaskAccompainedBy({})
    }
    useEffect(initialRender, []);

    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            var arrAccompainedBy = Object.assign([], AccompainedByResponse.response);

            var user = {
                user_id: props.auth.getUser().user_id,
                pinc_employee_id: props.auth.getUser().pinc_employee_id,
                full_name: props.auth.getUser().first_name + " " + props.auth.getUser().last_name,
                label: props.auth.getUser().first_name + " " + props.auth.getUser().last_name,
                value: props.auth.getUser().user_id,
                profile_photo: null
            };

            arrAccompainedBy.unshift(user);

            setAssignTo(arrAccompainedBy);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    const onCreateTaskUpdate = () => {
        const { CreateTask } = props;
        var CreateTaskResponse = Object.assign({}, CreateTask);
        $("#create-task").prop("disabled", false);
        if (CreateTaskResponse.result === true) {
            $("#create-task-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Task Created Successfully");
            props.history.push('/tasks');
        } else {
            $("#create-task-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (CreateTaskResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Upload Error',
                        text: CreateTaskResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCreateTaskUpdate, [props.CreateTask]);


    const onSubmit = (data) => {
        data.user_id = props.auth.getUser().user_id;
        data.travel_with = JSON.stringify(getAccompainedByIds(data.travel_with));
        // $("#progress-loader").removeClass("hidden");
        $("#create-task-loader").removeClass("hidden");
        $("#create-task").prop("disabled", true);
        props.actionCreateTask(data);
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Create Task</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Task Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="task"
                                                        name="task"
                                                        placeholder="Enter Task Name"
                                                        {...register('task', { required: "Task Name is Required" })}
                                                    />
                                                    {errors.task?.type === 'required' && <ErrorLabel message={errors.task?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"> Delivery Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="delivery_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                acceptCustomValue={false}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                min={minDateValue}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Delivery Date is required'
                                                        }}
                                                    />
                                                    {errors.delivery_date && <ErrorLabel message={errors.delivery_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        placeholder="Description"
                                                        {...register('description', { required: 'Description is required' })}
                                                    />
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Assign to&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="assign_to"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={assignTo}
                                                                allowFiltering={true}
                                                                placeholder="Select Assign to"
                                                                value={field.value}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Assign to is required'
                                                        }}
                                                    />

                                                    {errors.assign_to && <ErrorLabel message={errors.assign_to?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="create-task"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="create-task-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/tasks');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}


const mapStateToProps = (state) => {
    var CreateTask = null;
    var AccompainedBy = null;


    if (
        state.CreateTaskReducer != null &&
        Object.keys(state.CreateTaskReducer).length > 0 &&
        state.CreateTaskReducer.constructor === Object
    ) {
        switch (state.CreateTaskReducer.tag) {
            case ACTION_CREATE_TASK_SUCCESS:
                CreateTask = Object.assign({}, state.CreateTaskReducer.data);
                delete state.CreateTaskReducer;
                return { CreateTask };
            case ACTION_CREATE_TASK_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.CreateTaskReducer.data);
                delete state.CreateTaskReducer;
                return { AccompainedBy };
            default:
        }
    }

    return {
        CreateTask,
        AccompainedBy,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionCreateTask, actionCreateTaskAccompainedBy }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CreateTask))
































