import createReducer from "../../../../../../reducers/createReducer";
import {ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS} from './action'
import { ACTION_DOCUMENT_DELETE_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditPersonalInformationReducer = createReducer(INITIAL_STATE, {
    [ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DOCUMENT_DELETE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_DOCUMENT_DELETE_SUCCESS,
            loading: false
        });
    }, 
});
export default EditPersonalInformationReducer;