import {API_CHANGE_STATUS} from '../../../api/constants';
import Api from '../../../api';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_CHANGE_STATUS_LOADING = 'ACTION_CHANGE_STATUS_LOADING';
export const ACTION_CHANGE_STATUS_SUCCESS = 'ACTION_CHANGE_STATUS_SUCCESS';

export function actionChangeStatus(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_CHANGE_STATUS,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CHANGE_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CHANGE_STATUS_LOADING, { loading: false, data }));
        });
}