import React, { useState, useEffect } from 'react';
import { verifyPermission } from '../../../../../../utils';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import moment from "moment";
import { FiEdit, FiEye } from 'react-icons/fi';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, getData, removeData } from "../../../../../../utils/preferences";
import { actionItSubmissionPoolList, ACTION_IT_SUBMISSION_POOL_LIST_SUCCESS } from './action';

const SubmissionPoolList = (props) => {

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionItSubmissionPoolList({
            "type": "Submission",
            status: 'DECLARED',
        });
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
    }
    useEffect(initialRender, []);

    const [type, setType] = useState('DECLARED');

    const [getDeclarationPoolList, setGetDeclarationPoolList] = useState([])

    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: "Employee Name", visible: true },
        { dataField: "pinc_employee_id", caption: "Employee Id", visible: true },
        { dataField: "payroll_entity", caption: "Payroll Entity", visible: true },
        { dataField: "primary_department", caption: "Primary Department", visible: true },
        { dataField: "reporting_to_full_name", caption: "Reporting Manager", visible: true },
        { dataField: "regime", caption: "Tax Regime", visible: true },
        { dataField: "submit_on", caption: "Submission Date", visible: false },
        { dataField: "submit_on_formated", caption: "Submission Date", visible: true },
        { dataField: "status", caption: "Status", visible: type != 'PENDING' ? true : false },
        { dataField: "joining_date", caption: "Joining Date", visible: false },
        { dataField: "mobile_no", caption: "Employee Mobile", visible: false },
        { dataField: "email", caption: "Employee Email Id", visible: false },
        { dataField: "life_insurance_premium_or_pension_schemes_approved", caption: "LIP", visible: false },
        { dataField: "ppf_approved", caption: "PPF", visible: false },
        { dataField: "ulip_or_elss_or_tax_saving_mutual_funds_approved", caption: "ULIP/ELSS", visible: false },
        { dataField: "nsc_approved", caption: "NSC", visible: false },
        { dataField: "repayment_of_housing_loan_approved", caption: "Housing Loan (PA)", visible: false },
        { dataField: "tuition_fees_for_children_approved", caption: "Tuition Fees", visible: false },
        { dataField: "five_year_fd_approved", caption: "Fixed Deposit", visible: false },
        { dataField: "it_submission_80c_total_approved", caption: "Section 80 C Total", visible: false },
        { dataField: "pf_contribution", caption: "PF Opted", visible: false },
        { dataField: "nps_approved", caption: "NPS", visible: false },
        { dataField: "family_mediclaim_approved", caption: "Family Mediclaim", visible: false },
        { dataField: "parents_mediclaim_approved", caption: "Parents Mediclaim", visible: false },
        { dataField: "preventive_health_check_up_approved", caption: "Health Check", visible: false },
        { dataField: "it_submission_80d_total_approved", caption: "Section 80 D Total", visible: false },
        { dataField: "interest_paid_on_loan_for_self_education_approved", caption: "Education Loan", visible: false },
        { dataField: "interest_paid_on_housing_loan_approved", caption: "Housing Loan", visible: false },
        { dataField: "loss_from_house_property_approved", caption: "Property Loss", visible: false },
        { dataField: "it_submission_others_total_approved", caption: "Section Total", visible: false },
        { dataField: "hra_approved", caption: "HRA", visible: false },
        { dataField: "lta", caption: "LTA", visible: false },
        { dataField: "hdfc_food_card", caption: "Meal Card", visible: false },
        { dataField: "special_allowance", caption: "Special Allowance", visible: false },
    ];

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'ACCEPTED':
                return <div className="badge badge-success round px-1 cursor-pointer">Accepted</div>;
            case 'SUBMITTED':
                return <div className='badge badge-warning round px-1 cursor-pointer'>Submitted</div>
        }
    }

    const renderDeclarationDate = (cell) => {
        return <div>{cell?.data?.submit_on_formated != null ? moment(cell?.data?.submit_on_formated).format('DD-MMM-YYYY') : ''}</div>
    }

    const renderEmployeeNameCell = (cell) => {
        if (cell?.data?.full_name != null) {
            return <a
            className='text-primary'
            onClick={() => {
                props.history.push({
                    pathname: '/profile',
                    state: {
                        user_id: cell?.data?.user_id,
                    }
                })
            }}
            ><u>{cell?.data?.full_name}</u></a>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission('IT_DECLARATION_POOL_VIEW') ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${type != 'PENDING' ? 'float-left' : ''}`}
                                            onClick={() => {

                                                localStorage.setItem('detailsData', JSON.stringify(params.data));

                                                const url = `/hrms/submission-pool/view`;
                                                const win = window.open(url, '_blank');
                                                if (win) {
                                                    win.focus();
                                                } else {
                                                    console.log('Pop-up blocked. Please allow pop-ups for this site.');
                                                }
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("IT_DECLARATION_POOL_EDIT") && type != 'PENDING' ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${!(['ACCEPTED'].includes(params.data.status)) ? '' : 'hidden'}`}
                                            onClick={() => {
                                                localStorage.setItem('detailsData', JSON.stringify(params.data));

                                                const url = `/hrms/submission-pool/edit`;
                                                const win = window.open(url, '_blank');
                                                if (win) {
                                                    win.focus();
                                                } else {
                                                    console.log('Pop-up blocked. Please allow pop-ups for this site.');
                                                }
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }

                    </Column>
                );
            }
            else if (objColumn.dataField === "status") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStatusCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "submit_on_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderDeclarationDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "full_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEmployeeNameCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            }else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });

        return arrColumns;
    };


    const onItSubmissionPoolListUpdate = () => {
        const { ItSubmissionPoolList } = props;
        var ItSubmissionPoolListResponse = Object.assign({}, ItSubmissionPoolList);
        if (ItSubmissionPoolListResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setGetDeclarationPoolList(ItSubmissionPoolListResponse.response);
        } else {
            switch (ItSubmissionPoolListResponse.status) {
                case ERROR:
                    $("#progress-loader").addClass("hidden");
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onItSubmissionPoolListUpdate, [props.ItSubmissionPoolList]);

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                id="btn-my-lead"
                                className={`btn ${type === 'DECLARED' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    setType('DECLARED')
                                    $("#progress-loader").removeClass("hidden");
                                    // setGetDeclarationPoolList([])
                                    props.actionItSubmissionPoolList({
                                        "type": "Submission",
                                        status: 'DECLARED',
                                    });
                                }}
                            >
                                Submitted
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                id="btn-open-lead"
                                className={`btn ${type == 'PENDING' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    setType('PENDING')
                                    // setGetDeclarationPoolList([])
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionItSubmissionPoolList({
                                        status: 'PENDING',
                                        "type": "Submission",
                                    });
                                }}
                            >
                                Pending
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <DataGrid
                            dataSource={getDeclarationPoolList}
                            showBorders={true}
                            columnAutoWidth={true}
                            export={{
                                fileName: "Submission Pool",
                                enabled: true,
                                allowExportSelectedData: false,
                                excelFilterEnabled: true,
                            }}

                            onExporting={(event) => {
                                event.component.beginUpdate();
                                event.component.columnOption("joining_date", "visible", true)
                                event.component.columnOption("mobile_no", "visible", true)
                                event.component.columnOption("email", "visible", true)
                                event.component.columnOption("life_insurance_premium_or_pension_schemes_approved", "visible", true)
                                event.component.columnOption("ppf_approved", "visible", true)
                                event.component.columnOption("ulip_or_elss_or_tax_saving_mutual_funds_approved", "visible", true)
                                event.component.columnOption("nsc_approved", "visible", true)
                                event.component.columnOption("repayment_of_housing_loan_approved", "visible", true)
                                event.component.columnOption("tuition_fees_for_children_approved", "visible", true)
                                event.component.columnOption("five_year_fd_approved", "visible", true)
                                event.component.columnOption("it_submission_80c_total_approved", "visible", true)
                                event.component.columnOption("pf_contribution", "visible", true)
                                event.component.columnOption("nps_approved", "visible", true)
                                event.component.columnOption("family_mediclaim_approved", "visible", true)
                                event.component.columnOption("parents_mediclaim_approved", "visible", true)
                                event.component.columnOption("preventive_health_check_up_approved", "visible", true)
                                event.component.columnOption("it_submission_80d_total_approved", "visible", true)
                                event.component.columnOption("interest_paid_on_loan_for_self_education_approved", "visible", true)
                                event.component.columnOption("interest_paid_on_housing_loan_approved", "visible", true)
                                event.component.columnOption("loss_from_house_property_approved", "visible", true)
                                event.component.columnOption("it_submission_others_total_approved", "visible", true)
                                event.component.columnOption("hra_approved", "visible", true)
                                event.component.columnOption("lta", "visible", true)
                                event.component.columnOption("hdfc_food_card", "visible", true)
                                event.component.columnOption("special_allowance", "visible", true)
                                event.component.columnOption("submit_on_formated", "visible", false)
                                event.component.columnOption("submit_on", "visible", true)
                            }}
                            onExported={(event) => {
                                event.component.columnOption("joining_date", "visible", false)
                                event.component.columnOption("mobile_no", "visible", false)
                                event.component.columnOption("email", "visible", false)
                                event.component.columnOption("life_insurance_premium_or_pension_schemes_approved", "visible", false)
                                event.component.columnOption("ppf_approved", "visible", false)
                                event.component.columnOption("ulip_or_elss_or_tax_saving_mutual_funds_approved", "visible", false)
                                event.component.columnOption("nsc_approved", "visible", false)
                                event.component.columnOption("repayment_of_housing_loan_approved", "visible", false)
                                event.component.columnOption("tuition_fees_for_children_approved", "visible", false)
                                event.component.columnOption("five_year_fd_approved", "visible", false)
                                event.component.columnOption("it_submission_80c_total_approved", "visible", false)
                                event.component.columnOption("pf_contribution", "visible", false)
                                event.component.columnOption("nps_approved", "visible", false)
                                event.component.columnOption("family_mediclaim_approved", "visible", false)
                                event.component.columnOption("parents_mediclaim_approved", "visible", false)
                                event.component.columnOption("preventive_health_check_up_approved", "visible", false)
                                event.component.columnOption("it_submission_80d_total_approved", "visible", false)
                                event.component.columnOption("interest_paid_on_loan_for_self_education_approved", "visible", false)
                                event.component.columnOption("interest_paid_on_housing_loan_approved", "visible", false)
                                event.component.columnOption("loss_from_house_property_approved", "visible", false)
                                event.component.columnOption("it_submission_others_total_approved", "visible", false)
                                event.component.columnOption("hra_approved", "visible", false)
                                event.component.columnOption("lta", "visible", false)
                                event.component.columnOption("hdfc_food_card", "visible", false)
                                event.component.columnOption("special_allowance", "visible", false)
                                event.component.columnOption("submit_on_formated", "visible", true)
                                event.component.columnOption("submit_on", "visible", false)
                                event.component.endUpdate();
                            }}
                        >
                            <SearchPanel visible={true} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            {renderColumns()}

                            <Pager
                                visible={true}
                                showPageSizeSelector={false}
                                showNavigationButtons={true}
                                allowedPageSizes={[4, 6, 10]}
                            />
                            <Paging
                                defaultPageIndex={0}
                                defaultPageSize={10}
                                enabled={true}
                            />
                        </DataGrid>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ItSubmissionPoolList = null;

    if (
        state.ItSubmissionPoolListReducer != null &&
        Object.keys(state.ItSubmissionPoolListReducer).length > 0 &&
        state.ItSubmissionPoolListReducer.constructor === Object
    ) {
        switch (state.ItSubmissionPoolListReducer.tag) {
            case ACTION_IT_SUBMISSION_POOL_LIST_SUCCESS:
                ItSubmissionPoolList = Object.assign({}, state.ItSubmissionPoolListReducer.data);
                delete state.ItSubmissionPoolListReducer;
                return { ItSubmissionPoolList };
            default:
        }
    }

    return {
        ItSubmissionPoolList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItSubmissionPoolList }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(SubmissionPoolList))
