import { ACTION_BIRTHDAY_WISH_ADD_SUCCESS, ACTION_MASTER_SALES_RM_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const BirthdayWishesAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_BIRTHDAY_WISH_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_BIRTHDAY_WISH_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_SALES_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_SALES_RM_SUCCESS,
                loading: false,
            }
        );
    },
});
export default BirthdayWishesAddReducer;