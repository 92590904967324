import Api from '../../../../api';
import { fetchSuccess, loading } from '../../../../utils/action.util';
import { API_FORGOTPASSWORD } from '../../../../api/constants';
import { PRINT } from '../../../../utils';

export const ACTION_FORGOTPASSWORD_LOADING = 'ACTION_FORGOTPASSWORD_LOADING';
export const ACTION_FORGOTPASSWORD_SUCCESS = 'ACTION_FORGOTPASSWORD_SUCCESS';

export function actionForgotPassword(params) {
    return (dispatch, getState) => 
    Api.post(API_FORGOTPASSWORD , params).then((res) => {
        const data = res;
        PRINT('FORGOTPASSWORD RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_FORGOTPASSWORD_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_FORGOTPASSWORD_LOADING, { loading: false, data }));
    });
}

