// import React, { useEffect, useState } from 'react';
// import { FiEye } from 'react-icons/fi';
// import DataGrid, {
//     Button,
//     Column,
//     FilterRow,
//     HeaderFilter,
//     Pager,
//     Paging,
//     SearchPanel,
// } from "devextreme-react/data-grid";
// import { withAuth } from 'react-auth-guard';
// import $ from "jquery";
// import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../../utils/preferences';
// import { toast } from 'react-toastify';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import dateFormat from 'dateformat';
// import _ from "lodash";
// import Swal from 'sweetalert2';
// import { DateBox } from 'devextreme-react/date-box';
// import { verifyPermission } from '../../../../../../../utils';
// import { useForm, Controller } from "react-hook-form";
// import { UNAUTHORIZED } from '../../../../../../../constants';
// import { actionDownloadClaimsMISList, ACTION_DOWNLOAD_CLAIMS_MIS_SUCCESS } from './action';

// const ClaimsDownloadList = (props) => {

//     const { control } = useForm({});
//     const { match } = props;
//     const [fromDate, setFromDate] = useState(`04/01/${new Date().getFullYear()}`);
//     const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
//     const [downloadClaimsMISList, setDownloadClaimsMISList] = useState([]);
//     const [type, setType] = useState('all');
//     const [pageSize, setPageSize] = useState(10);
//     const [currentPage, setCurrentPage] = useState(0);

//     const initialRender = () => {
//         if (getData(KEY_TOAST_MESSAGE) != null) {
//             toast.success(getData(KEY_TOAST_MESSAGE));
//             removeData(KEY_TOAST_MESSAGE);
//         }
//     }
//     useEffect(initialRender, []);

//     useEffect(() => {
//         $("#progress-loader").removeClass("hidden");
//         props.actionDownloadClaimsMISList({
//             type: type,
//             page_size: pageSize,
//             offset: pageSize * currentPage
//         });
//     }, [currentPage]);

//     const [columns, setColumns] = useState([
//         // { dataField: "Action", caption: "Action", type: "button" },
//         { dataField: "claim_type", caption: "Claim Type" },
//         { dataField: "status", caption: "Current Status" },
//         { dataField: "csc_name", caption: "Claim Handler" },
//         { dataField: "insurer_branch", caption: "Branch" },
//         { dataField: "control_no", caption: "Policy Control No." },
//         { dataField: "pinc_claim_ref_no", caption: "PINC Claim Ref. No." },
//         { dataField: "customer_name", caption: "Customer Name" },
//         { dataField: "customer_group", caption: "Customer Group" },
//         { dataField: "insured_name", caption: "Insured Name" },
//         { dataField: "policy_no", caption: "Policy No." },
//         { dataField: "department", caption: "Department" },
//         { dataField: "policy_type", caption: "Product Type" },
//         { dataField: "policy_start_date", caption: "Policy Start Date" },
//         { dataField: "policy_expiry_date", caption: "Policy End Date" },
//         { dataField: "date_of_loss", caption: "Inci/Loss Date" },
//         { dataField: "insured_intimation_date", caption: "Insured Intimation Date" },
//         { dataField: "insurer_intimation_date", caption: "Insurer Intimation Date" },
//         { dataField: "surveyor_name", caption: "Surveyor" },
//         { dataField: "surveyor_contact_no", caption: "Surveyor Contact No." },
//         { dataField: "loss_site_contact_person_email", caption: "Surveyor Email" },
//         { dataField: "nature_of_claim", caption: "Nature of Loss" },
//         { dataField: "insured_claim_ref_no", caption: "Insured Claim Ref. No." },
//         { dataField: "surveyor_reference_no", caption: "Surveyor Ref. No." },
//         { dataField: "insurer_claim_no", caption: "Insurer Claim No." },
//         { dataField: "loss_amount", caption: "Loss Intimation Amount" },
//         { dataField: "gross_premium", caption: "Gross Loss Amount" },
//         { dataField: "claim_settled_policy_excess", caption: "Policy Excess" },
//         { dataField: "claim_settled_policy_excess_description", caption: "Policy Excess Description" },
//         { dataField: "rm_name", caption: "RM Name" },
//         { dataField: "insured_name", caption: "Insured Name" },
//         { dataField: "contact_details", caption: "Insured Contact Details" },
//         { dataField: "insured_email", caption: "Insured Email" },
//         { dataField: "insurer_name", caption: "Insurance Company" },
//         { dataField: "insurer_contact_name", caption: "Insurer Contact Name" },
//         { dataField: "insurer_contact_email", caption: "Insurer Contact Email" },
//         { dataField: "insurer_second_contact_name", caption: "Insurer Second Contact Name" },
//         { dataField: "insurer_second_contact", caption: "Insurer Second Contact No." },
//         { dataField: "insurer_second_contact_email", caption: "Insurer Second Contact Email" },
//         { dataField: "claim_settled_cheque_utr_amount", caption: "Cheque/UTR Amount" },
//         { dataField: "claim_settled_cheque_utr_no", caption: "Cheque UTR No." },
//         { dataField: "claim_settled_settled_date", caption: "Settled Date" },
//         { dataField: "intimate_date", caption: "Date of Intimation" },
//     ]);

//     const onDownloadClaimsMISListUpdate = () => {
//         const { DownloadClaimsMISList } = props;
//         if (DownloadClaimsMISList != null) {
//             var DownloadClaimsMISListResponse = Object.assign({}, DownloadClaimsMISList);
//             if (DownloadClaimsMISListResponse.result === true) {

//                 $("#progress-loader").addClass("hidden");
//                 setColumns([
//                     // { dataField: "Action", caption: "Action", type: "button" },
//                     { dataField: "claim_type", caption: "Claim Type" },
//                     { dataField: "status", caption: "Current Status" },
//                     { dataField: "csc_name", caption: "Claim Handler" },
//                     { dataField: "insurer_branch", caption: "Branch" },
//                     { dataField: "control_no", caption: "Policy Control No." },
//                     { dataField: "pinc_claim_ref_no", caption: "PINC Claim Ref. No." },
//                     { dataField: "customer_name", caption: "Customer Name" },
//                     { dataField: "customer_group", caption: "Customer Group" },
//                     { dataField: "insured_name", caption: "Insured Name" },
//                     { dataField: "policy_no", caption: "Policy No." },
//                     { dataField: "department", caption: "Department" },
//                     { dataField: "policy_type", caption: "Product Type" },
//                     { dataField: "policy_start_date", caption: "Policy Start Date" },
//                     { dataField: "policy_expiry_date", caption: "Policy End Date" },
//                     { dataField: "date_of_loss", caption: "Inci/Loss Date" },
//                     { dataField: "insured_intimation_date", caption: "Insured Intimation Date" },
//                     { dataField: "insurer_intimation_date", caption: "Insurer Intimation Date" },
//                     { dataField: "surveyor_name", caption: "Surveyor" },
//                     { dataField: "surveyor_contact_no", caption: "Surveyor Contact No." },
//                     { dataField: "loss_site_contact_person_email", caption: "Surveyor Email" },
//                     { dataField: "nature_of_claim", caption: "Nature of Loss" },
//                     { dataField: "insured_claim_ref_no", caption: "Insured Claim Ref. No." },
//                     { dataField: "surveyor_ref_no", caption: "Surveyor Ref. No." },
//                     { dataField: "insurer_claim_no", caption: "Insurer Claim No." },
//                     { dataField: "loss_amount", caption: "Loss Intimation Amount" },
//                     { dataField: "gross_premium", caption: "Gross Loss Amount" },
//                     { dataField: "policy_excess", caption: "Policy Excess" },
//                     { dataField: "policy_excess_description", caption: "Policy Excess Description" },
//                     { dataField: "rm_name", caption: "RM Name" },
//                     { dataField: "insured_name", caption: "Insured Name" },
//                     { dataField: "contact_details", caption: "Insured Contact Details" },
//                     { dataField: "insured_email", caption: "Insured Email" },
//                     { dataField: "insurer_name", caption: "Insurance Company" },
//                     { dataField: "insurer_contact_name", caption: "Insurer Contact Name" },
//                     { dataField: "insurer_contact_email", caption: "Insurer Contact Email" },
//                     { dataField: "insurer_second_contact_name", caption: "Insurer Second Contact Name" },
//                     { dataField: "insurer_second_contact", caption: "Insurer Second Contact No." },
//                     { dataField: "insurer_second_contact_email", caption: "Insurer Second Contact Email" },
//                     { dataField: "cheque_utr_amount", caption: "Cheque/UTR Amount" },
//                     { dataField: "cheque_utr_no", caption: "Cheque UTR No." },
//                     { dataField: "claim_settled_settled_date", caption: "Settled Date" },
//                     { dataField: "intimate_date", caption: "Date of Intimation" },
//                 ]);

//                 setDownloadClaimsMISList(DownloadClaimsMISListResponse.response);
//                 setType('all');
//             } else {
//                 $("#progress-loader").addClass("hidden");
//                 setDownloadClaimsMISList([]);
//                 switch (DownloadClaimsMISListResponse.status) {
//                     case UNAUTHORIZED:
//                         $("#progress-loader").addClass("hidden");
//                         break;
//                     default:
//                 }
//             }
//         }
//     }
//     useEffect(onDownloadClaimsMISListUpdate, [props.DownloadClaimsMISList]);

//     const renderInsurerBranch = (cell) => {
//         return <div>{_.truncate(cell.data.insurer_branch)}</div>
//     }

//     const renderInsuredIntimationDate = (cell) => {
//         return <div>{dateFormat(cell.data.insured_intimation_date, 'dd/mm/yyyy')}</div>
//     }

//     const renderInsurerIntimationDate = (cell) => {
//         return <div>{dateFormat(cell.data.insurer_intimation_date, 'dd/mm/yyyy')}</div>
//     }

//     const renderColumns = () => {
//         var arrColumns = [];
//         columns.forEach((objColumn, indexColumn) => {
//             if (objColumn.dataField === "Action") {
//                 arrColumns.push(
//                     <Column dataField="Action" type="buttons">
//                         <Button
//                             render={(params) => (
//                                 <button
//                                     className="btn btn-icon btn-sm"
//                                     title="View Claim"
//                                     onClick={() => {
//                                         props.history.push({
//                                             pathname: `/claims/view`,
//                                             state: {
//                                                 id: params.data.id
//                                             }
//                                         })
//                                     }}
//                                 >
//                                     {/* <FiEye size={16} className="primary" /> */}
//                                 </button>
//                             )}
//                         />
//                     </Column>
//                 );
//             }
//             else if (objColumn.dataField === "insurer_branch") {
//                 arrColumns.push(
//                     <Column
//                         dataField={objColumn.dataField}
//                         caption={objColumn.caption}
//                         cellRender={renderInsurerBranch}
//                     ></Column>
//                 );
//             }
//             else if (objColumn.dataField === "insured_intimation_date") {
//                 arrColumns.push(
//                     <Column
//                         dataField={objColumn.dataField}
//                         caption={objColumn.caption}
//                         cellRender={renderInsuredIntimationDate}
//                     ></Column>
//                 );
//             }
//             else if (objColumn.dataField === "insurer_intimation_date") {
//                 arrColumns.push(
//                     <Column
//                         dataField={objColumn.dataField}
//                         caption={objColumn.caption}
//                         cellRender={renderInsurerIntimationDate}
//                     ></Column>
//                 );
//             }
//             else {
//                 arrColumns.push(
//                     <Column
//                         dataField={objColumn.dataField}
//                         caption={objColumn.caption}
//                     ></Column>
//                 )
//             }
//         });
//         return arrColumns;
//     };

//     return (
//         <>
//             <section>
//                 <div className='row'>
//                     <div className="col-12 col-md-12 col-lg-12 pb-1">
//                         <div className="row d-flex align-items-center">
//                             <div className="col-12 col-md-6 col-lg-6">
//                                 <h4 class="text-black text-bold-600 mb-0">Download Claims MIS</h4>
//                             </div>
//                             <div className="col-12 col-md-6 col-lg-6">
//                                 <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
//                                     <div className="flex-column mr-1">
//                                         <Controller
//                                             name="from_date"
//                                             control={control}
//                                             defaultValue={fromDate}
//                                             render={({ field }) =>
//                                                 <DateBox
//                                                     defaultValue={new Date()}
//                                                     calendarOptions={{
//                                                         showTodayButton: true,
//                                                     }}
//                                                     stylingMode="underlined"
//                                                     pickerType="calendar"
//                                                     placeholder="From Date"
//                                                     type="date"
//                                                     displayFormat="dd/MM/yyyy"
//                                                     showClearButton={false}
//                                                     value={field.value}
//                                                     placeholder='DD/MM/YYYY'
//                                                     onValueChange={(value) => {
//                                                         var objFromDate = new Date(value);
//                                                         var objToDate = new Date(toDate);

//                                                         if (objToDate.getTime() < objFromDate.getTime()) {
//                                                             Swal.fire({
//                                                                 title: 'Error',
//                                                                 text: "From date can't be greater than To date",
//                                                                 icon: 'error'
//                                                             }).then(success => {

//                                                             })
//                                                         } else {
//                                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
//                                                             setFromDate(dateFormat(value, 'yyyy-mm-dd'));
//                                                             props.actionDownloadClaimsMISList({
//                                                                 from_date: dateFormat(value, 'yyyy-mm-dd'),
//                                                                 to_date: dateFormat(toDate, 'yyyy-mm-dd')
//                                                             });
//                                                         }
//                                                     }}
//                                                 />
//                                             }
//                                         />
//                                     </div>
//                                     <div className="flex-column mr-1">
//                                         <Controller
//                                             className="flex-column"
//                                             name="to_date"
//                                             control={control}
//                                             defaultValue={toDate}
//                                             render={({ field }) =>
//                                                 <DateBox
//                                                     defaultValue={new Date()}
//                                                     calendarOptions={{
//                                                         showTodayButton: true,
//                                                     }}
//                                                     stylingMode="underlined"
//                                                     pickerType="calendar"
//                                                     placeholder="To Date"
//                                                     type="date"
//                                                     displayFormat="dd/MM/yyyy"
//                                                     showClearButton={false}
//                                                     value={field.value}
//                                                     placeholder='DD/MM/YYYY'
//                                                     onValueChange={(value) => {
//                                                         var objFromDate = new Date(fromDate);
//                                                         var objToDate = new Date(value);

//                                                         if (objToDate.getTime() < objFromDate.getTime()) {
//                                                             Swal.fire({
//                                                                 title: 'Error',
//                                                                 text: "To date must be greater than from date",
//                                                                 icon: 'error'
//                                                             }).then(success => {

//                                                             })
//                                                         } else {
//                                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
//                                                             setToDate(dateFormat(value, 'yyyy-mm-dd'));
//                                                             props.actionDownloadClaimsMISList({
//                                                                 from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
//                                                                 to_date: dateFormat(value, 'yyyy-mm-dd')
//                                                             });
//                                                         }
//                                                     }}
//                                                 />
//                                             }
//                                         />
//                                     </div>
//                                     {/* <div className="flex-column">
//                                         <button
//                                             className="btn btn-primary btn-md px-1 font-large shadow round"
//                                             onClick={() => {
//                                                 // props.actionClaimDownload({
//                                                 //     from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
//                                                 //     to_date: dateFormat(toDate, 'yyyy-mm-dd')
//                                                 // });
//                                             }}
//                                         >
//                                             Download&nbsp;Claims&nbsp;MIS
//                                         </button>
//                                     </div> */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-12 col-lg-12 col-md-12">
//                         <div className="card">
//                             <div className="card-body">
//                                 <DataGrid
//                                     dataSource={downloadClaimsMISList}
//                                     showBorders={true}
//                                     columnAutoWidth={true}
//                                     export={{
//                                         fileName: `Claims MIS-${dateFormat(fromDate, 'dd-mm-yyyy')}-${dateFormat(toDate, 'dd-mm-yyyy')}`,
//                                         enabled: true,

//                                         excelFilterEnabled: true,
//                                     }}
//                                     remoteOperations={true}
//                                 >
//                                     <SearchPanel visible={true} />
//                                     <FilterRow visible={true} />
//                                     <HeaderFilter visible={true} />
//                                     {renderColumns()}
//                                     {/* {renderStatus()}
//                                     {renderTravelType()} */}
//                                     <Pager
//                                         visible={true}
//                                         showPageSizeSelector={false}
//                                         showNavigationButtons={true}
//                                         allowedPageSizes={[4, 6, 10]}
//                                     />
//                                     <Paging
//                                         defaultPageIndex={0}
//                                         defaultPageSize={10}
//                                         enabled={true}
//                                         onPageIndexChange={(value) => {
//                                             setCurrentPage(value + 1);
//                                         }}
//                                     />
//                                 </DataGrid>
//                                 <button class="btn btn-outline-secondary mr-1 shadow round text-uppercase"
//                                     onClick={() => { props.history.goBack() }}> Back
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//         </>
//     )
// }

// const mapStateToProps = (state) => {
//     var DownloadClaimsMISList = null;
//     if (
//         state.DownloadClaimsMISListReducer != null &&
//         Object.keys(state.DownloadClaimsMISListReducer).length > 0 &&
//         state.DownloadClaimsMISListReducer.constructor === Object
//     ) {
//         switch (state.DownloadClaimsMISListReducer.tag) {
//             case ACTION_DOWNLOAD_CLAIMS_MIS_SUCCESS:
//                 DownloadClaimsMISList = Object.assign({}, state.DownloadClaimsMISListReducer.data);
//                 delete state.DownloadClaimsMISListReducer;
//                 return { DownloadClaimsMISList };
//             default:
//         }
//     }
//     return {
//         DownloadClaimsMISList
//     };
// }

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({ actionDownloadClaimsMISList }, dispatch)
// }
// export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClaimsDownloadList))



import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../../component/screen_title';
import LossSiteVisitReport from '../../../../../../../component/popups/claim_mis/loss_site_visit_report';
import ClaimMISReport from '../../../../../../../component/popups/claim_mis/claim_mis_report';
const ClaimsDownloadReport = (props) => {
    const [showClaimMisReport, setShowClaimMISReport] = useState(null)
    const [showLossSiteVisitReport, setShowLossSiteVisitReport] = useState(null)
    return (
        <>
            <ScreenTitle title="Claim MIS Report" />
            <div className="row">
                <div className="col-12 col-lg-3 col-md-3">
                    <div className="card cursor-pointer rounded shadow zoom">
                        <div className="card-body">
                            <div className="d-flex"
                                onClick={() => {
                                    setShowClaimMISReport(true)
                                }}>
                                <span className="ml-1 my-auto font-small-3 text-bold-600">Claim MIS Report</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                    <div className="card cursor-pointer rounded shadow zoom">
                        <div className="card-body">
                            <div className="d-flex"
                                onClick={() => {
                                    setShowLossSiteVisitReport(true)
                                }}>
                                <span className="ml-1 my-auto font-small-3 text-bold-600">Loss Site Visit Report</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClaimMISReport
                show={showClaimMisReport}
                handleClose={() => {
                    setShowClaimMISReport(false)
                }}
            />
            <LossSiteVisitReport
                show={showLossSiteVisitReport}
                handleClose={() => {
                    setShowLossSiteVisitReport(false)
                }}
            />
        </>
    )
}
export default ClaimsDownloadReport


