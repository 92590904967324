
import Api from '../../../../api';
import { PRINT } from '../../../../utils';
import { fetchSuccess, loading } from '../../../../utils/action.util';

import { API_MY_PROFILE } from '../../../../api/constants';
import { API_DELETE_EMPLOYEE_EDUCATION } from '../../../../api/constants';
import { API_DELETE_EMPLOYEE_EMPLOYMENT } from '../../../../api/constants';
import { API_CHANGEPASSWORD } from '../../../../api/constants';

export const ACTION_MY_PROFILE_LOADING = 'ACTION_MY_PROFILE_LOADING';
export const ACTION_MY_PROFILE_SUCCESS = 'ACTION_MY_PROFILE_SUCCESS';

export const ACTION_DELETE_EMPLOYEE_EDUCATION_LOADING = 'ACTION_DELETE_EMPLOYEE_EDUCATION_LOADING';
export const ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS = 'ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS';

export const ACTION_DELETE_EMPLOYEE_EMPLOYMENT_LOADING = 'ACTION_DELETE_EMPLOYEE_EMPLOYMENT_LOADING';
export const ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS = 'ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS';

export const ACTION_CHANGE_PASSWORD_LOADING = 'ACTION_CHANGE_PASSWORD_LOADING';
export const ACTION_CHANGE_PASSWORD_SUCCESS = 'ACTION_CHANGE_PASSWORD_SUCCESS';

export function actionMyProfile(params) {
    return (dispatch, getState) =>
        Api.post(API_MY_PROFILE, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MY_PROFILE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MY_PROFILE_LOADING, { loading: false, data }));
        });
}
export function actionDeleteEmployeeEducation(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_EMPLOYEE_EDUCATION, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DELETE_EMPLOYEE_EDUCATION_LOADING, { loading: false, data }));
        });
}
export function actionDeleteEmployeeEmployment(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_EMPLOYEE_EMPLOYMENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DELETE_EMPLOYEE_EMPLOYMENT_LOADING, { loading: false, data }));
        });
}

export function actionChangePassword(params) {
    return (dispatch, getState) =>
        Api.post(API_CHANGEPASSWORD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CHANGE_PASSWORD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CHANGE_PASSWORD_LOADING, { loading: false, data }));
        });
}