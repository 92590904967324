// import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react"
import { UNAUTHORIZED } from "../../../../../constants";
import { useForm, Controller } from "react-hook-form";
import Chart from "react-apexcharts";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from "react-auth-guard";
import { actionPolicyType, actionInsuredCompany, actionDashboardPolicy, actionProposalRM, actionPremiumPolicy, actionDepartmentPolicyType, actionSumInsuredPolicy, ACTION_MASTER_POLICY_TYPE_SUCCESS, ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS, ACTION_POLICY_DASHBOARD_SUCCESS, ACTION_SUM_INSURED_POLICY_SUCCESS, ACTION_MASTER_ASSIGN_RM_SUCCESS, ACTION_CLAIMS_POLICY_TYPE_SUCCESS, ACTION_PREMIUM_SUCCESS } from "./action"
import ScreenTitle from '../../../../../component/screen_title';
import $ from "jquery";
import { verifyPermission } from '../../../../../utils';
import ArrayStore from 'devextreme/data/array_store';
import { TagBox } from 'devextreme-react/tag-box';
import PolicyFilter from "../../../../../component/popups/policy_filter";

const PolicyDashboard = (props) => {
    const { match } = props;
    const { control, formState: { errors }, setValue } = useForm({});
    const [policyTypeDropdown, setPolicyTypeDropdown] = useState(null);
    const [premium, setPremium] = useState(null)
    const [objPolicyDetails, setObjPolicyDetails] = useState(null)
    const [policyTypeValue, setPolicyTypeValue] = useState([])
    const [insuranceCompanyValue, setInsuranceCompanyValue] = useState([])
    const [policyValue, setPolicyValue] = useState([])
    const [min, setMin] = useState(null)
    const [max, setMax] = useState(null)
    const [minGross, setMinGross] = useState(null)
    const [maxGross, setMaxGross] = useState(null)
    const [proposalRMType, setProposalRMType] = useState([]);
    const [policyMappedCount, setPolicyMappedCount] = useState(0)
    const [suminsured, setSumInsured] = useState([])
    const [premiumDropdowm, setPremiumDropdown] = useState(false)
    const [showPolicyFilter, setShowPolicyFilter] = useState(false)
    const [policyTypeDropdownDefaultValue, setPolicyTypeDropdownDefaultValue] = useState()
    const [rmNameDropdownDefaultValue, setRmNameDropdownDefaultValue] = useState()
    const [proposalRm, setProposalRM] = useState(null);
    const [proposalRmName, setProposalRmName] = useState(null);
    const [proposalPolicy, setProposalPolicy] = useState(null);
    const [policy, setPolicy] = useState([]);
    const [rmName, setRmName] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionProposalRM({ user_id: props.auth.getUser().id });
        props.actionDashboardPolicy();
        props.actionDepartmentPolicyType({});
    }
    useEffect(initialRender, []);

    const onMasterProposalRMUpdate = () => {
        const { ProposalRM } = props;
        var ProposalRMResponse = Object.assign({}, ProposalRM);
        $("#policy-reset").prop("disabled", false);
        if (ProposalRMResponse.result === true) {
            $("#policy-reset-loader").addClass("hidden");
            setProposalRmName(ProposalRMResponse.response);
            var arrAssignRM = Object.assign([], ProposalRMResponse.response);
            setProposalRM(arrAssignRM);
            props.actionDepartmentPolicyType();
        } else {
            $("#policy-reset-loader").addClass("hidden");
            switch (ProposalRMResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onMasterProposalRMUpdate, [props.ProposalRM]);

    const onDepartmentTypeUpdate = () => {
        const { DepartmentType } = props;
        if (DepartmentType != null) {
            var ProposalPolicyTypeResponse = Object.assign({}, DepartmentType);
            $("#policy-reset").prop("disabled", false);
            if (ProposalPolicyTypeResponse.result === true) {
                $("#policy-reset-loader").addClass("hidden");
                setProposalPolicy(ProposalPolicyTypeResponse.response);
                // props.actionProbability();
            } else {
                $("#policy-reset-loader").addClass("hidden");
                switch (ProposalPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout()
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onDepartmentTypeUpdate, [props.DepartmentType]);

    const onPolicyDashboardUpdate = () => {
        const { PolicyDashboard } = props;
        var PolicyDashboardResponse = Object.assign({}, PolicyDashboard);
        $("#policy-create").prop("disabled", false);
        if (PolicyDashboardResponse.result === true) {
            $("#policy-create-loader").addClass("hidden");
            $("#policy-view-loader").addClass("hidden");
            $("#policy-view").prop("disabled", false);
            setPolicyMappedCount(PolicyDashboardResponse.response.summary.reduce((count, currentValue) => count + currentValue.policy_count, 0))
            setObjPolicyDetails(PolicyDashboardResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#policy-create-loader").addClass("hidden");
            $("#policy-view-loader").addClass("hidden");
            $("#policy-view").prop("disabled", false);
            switch (PolicyDashboardResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onPolicyDashboardUpdate, [props.PolicyDashboard]);

    return (
        <>
            <ScreenTitle title="Renewal Dashboard" />
            <section>
                <div className="pb-2">
                    <div className="card">
                        <div className="card-body">
                            <form noValidate autoComplete="Off" >
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <h4 className=" mb-0">Filters</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">RM Name&nbsp;</label>
                                                <Controller
                                                    name="rm_id"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: proposalRm,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    ('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    ('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    ('showing')
                                                                },
                                                                onShown: () => {
                                                                    ('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select RM Name"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setProposalRMType(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">Type of Policy&nbsp;</label>
                                                <Controller
                                                    name="policy_type"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: policyTypeDropdown,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="name"
                                                            placeholder="Select Type of Policy"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setPolicyTypeValue(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">Sum Insured&nbsp;</label>
                                                <Controller
                                                    name="sum_insured"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={objSumInsured}
                                                            fields={{ text: 'title', value: 'id' }}
                                                            allowFiltering={true}
                                                            placeholder="Select Sum Insured"
                                                            value={field.value}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    setMin(event.itemData.min)
                                                                    setMax(event.itemData.max)
                                                                    setSumInsured(event.itemData)
                                                                    field.onChange(event.itemData.id)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">Insurance Company&nbsp;</label>
                                                <Controller
                                                    name="insurance_company"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: insuredCompany,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="name"
                                                            placeholder="Select Insurance Company"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setInsuranceCompanyValue(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">Type of Department&nbsp;</label>
                                                <Controller
                                                    name="department_id"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            id="department_id"
                                                            name="department_id"
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: proposalPolicy,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    ('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    ('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    ('showing')
                                                                },
                                                                onShown: () => {
                                                                    ('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select Type of Department"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setPolicyTypeValue(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />

                                            </div>
                                        </div>

                                        <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                            <div className="form-actions mt-1 border-0 float-none">
                                                <button
                                                    id="policy-create"
                                                    type='submit'
                                                    className="btn btn-primary mr-8  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        $("#policy-create-loader").removeClass("hidden");
                                                        $("#policy-create").prop("disabled", true);
                                                        $("#progress-loader").removeClass("hidden");
                                                        props.actionDashboardPolicy({
                                                            "rm_id": proposalRMType,
                                                            // "insurance_company": insuranceCompanyValue,
                                                            // "department_id": policyTypeValue,
                                                            "department_id": policyTypeValue,
                                                            // "min_sum_insured": min,
                                                            // "max_sum_insured": max
                                                        })
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Apply
                                                        <span id="policy-create-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                            <div className="form-actions mt-1 border-0 float-none">
                                                <button
                                                    id="policy-reset"
                                                    type='button'
                                                    className="btn btn-primary  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        $("#policy-reset-loader").removeClass("hidden");
                                                        $("#policy-reset").prop("disabled", true);
                                                        $("#progress-loader").removeClass("hidden");
                                                        setProposalRM([]);
                                                        // setProposalPolicy([])
                                                        setProposalRMType(null)
                                                        // setSumInsuredDropdown(true)
                                                        // setInsuredCompany([]);
                                                        setPremiumDropdown(true)
                                                        setPolicyTypeDropdown([])
                                                        setRmNameDropdownDefaultValue()
                                                        setPolicyTypeDropdownDefaultValue()
                                                        // setObjSumInsured([])
                                                        setPolicyTypeValue(null)
                                                        setPolicy([]);
                                                        setRmName([]);
                                                        // setValue('department_id', null);
                                                        // setValue('insurance_company', null);
                                                        // setValue('sum_insured', null);
                                                        setValue('department_id', null);
                                                        setValue('rm_id', null);
                                                        setValue('premium', null);
                                                        // setMin('')
                                                        // setMax('')
                                                        // setSumInsured([])

                                                        props.actionDashboardPolicy({})
                                                        // props.actionSumInsuredPolicy({});
                                                        // props.actionPolicyType({});
                                                        // props.actionInsuredCompany({});
                                                        props.actionDepartmentPolicyType();
                                                        props.actionProposalRM({ user_id: props.auth.getUser().id });
                                                    }
                                                    }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Reset
                                                        <span id="policy-reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                            <div className="form-actions mt-1 border-0 float-none">
                                                <button
                                                    id="policy-filter"
                                                    type='button'
                                                    className="btn btn-primary  btn-md w-80 shadow round"
                                                    onClick={() => {
                                                        setShowPolicyFilter(true)
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Filter
                                                        <span id="policy-reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='row'>
                        {
                            objPolicyDetails != null && objPolicyDetails.summary != null && objPolicyDetails.summary.length != 0 && policyMappedCount != 0 ?
                                objPolicyDetails.summary.map((item, index) => (
                                    <>
                                        {
                                            Number(item.policy_count) > 0 ?
                                                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-2">
                                                    <div className="card shadow h-100">
                                                        <div className="card-header border-bottom px-half py-1">
                                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                                <div className="flex-column">
                                                                    <h4 className="font-medium-1 text-bold-600 mb-0 ml-1">
                                                                        {item.title}
                                                                    </h4>
                                                                </div>
                                                                <div className="font-medium-3 font-weight-bold mb-0 h2 mr-1 d-flex flex-column-reverse">
                                                                    {item.policy_count}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-0">
                                                            <div className='row'>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 statistics-chart mx-0 px-0 mt-2 ">
                                                                    <Chart
                                                                        type="donut"
                                                                        height={450}
                                                                        width={310}
                                                                        options={{
                                                                            dataLabels: {
                                                                                enabled: true,
                                                                                style: {
                                                                                    fontSize: "10px",
                                                                                    fontWeight: 400,
                                                                                },
                                                                                textAnchor: "middle",
                                                                            },
                                                                            plotOptions: {
                                                                                pie: {
                                                                                    expandOnClick: true,
                                                                                    customScale: 1,
                                                                                    donut: {
                                                                                        size: "55%",

                                                                                    },
                                                                                },
                                                                            },
                                                                            legend: {
                                                                                fontSize: "10px",
                                                                                fontWeight: 600,
                                                                                position: "right",
                                                                                horizontalAlign: 'center',
                                                                                markers: {
                                                                                    width: 5,
                                                                                    height: 5,
                                                                                    radius: 5,
                                                                                },
                                                                                itemMargin: {
                                                                                    vertical: 0,
                                                                                },
                                                                            },
                                                                            labels: item.policy_type_label,


                                                                        }}
                                                                        series={item.policy_type_series}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            verifyPermission("POLICY_VIEW_DETAILS") ?
                                                                <div className='card-footer py-0 mb-1'>
                                                                    <a className=' float-right mt-1 primary' href=""
                                                                        onClick={() => {
                                                                            props.history.push({
                                                                                pathname: `${match.url}/view`,
                                                                                state: { daysCount: Number(item.days_count), title: item.title, policyType: policyTypeValue != null && policyTypeValue != '' ? policyTypeValue : policyTypeDropdownDefaultValue, rmNameId: proposalRMType != null && proposalRMType != "" ? proposalRMType : rmNameDropdownDefaultValue, insuranceCompanyValue: insuranceCompanyValue, min: min, max: max, minGross: minGross, maxGross: maxGross, policyValue: policyValue, suminsured: suminsured, premium: premium }
                                                                            })
                                                                        }}
                                                                    ><u>View Details</u></a>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }

                                    </>
                                ))
                                :
                                <p className=" text-md-center font-medium-3 text-bold-600 px-1 mt-5 pt-1 mx-auto">No policies to show</p>
                        }
                    </div>
                </div>
            </section >
            <PolicyFilter
                {...props}
                type='create'
                department={policyTypeValue != null && policyTypeValue != '' ? policyTypeValue : policyTypeDropdownDefaultValue}
                rm_id={proposalRMType != null && proposalRMType != '' ? proposalRMType : rmNameDropdownDefaultValue}
                show={showPolicyFilter}
                handleClose={(Filter) => {
                    if (Filter != null) {
                        setInsuranceCompanyValue(Filter.insurance_company)
                        setMin(Filter.min_sum_insured)
                        setMax(Filter.max_sum_insured)
                        setPolicyValue(Filter.policy_type)
                        setMinGross(Filter.min_gross_premium)
                        setMaxGross(Filter.max_gross_premium)
                        setSumInsured(Filter.sumInsured)
                        setPremium(Filter.premium)
                    } else {
                    }
                    setShowPolicyFilter(false)
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var PolicyTypeDropdown = null;
    var InsuredCompany = null;
    var ProposalRM = null;
    var PolicyDashboard = null;
    var DepartmentType = null;
    var SumInsuredPolicy = null;

    if (
        state.PolicyReducer != null &&
        Object.keys(state.PolicyReducer).length > 0 &&
        state.PolicyReducer.constructor === Object
    ) {
        switch (state.PolicyReducer.tag) {
            case ACTION_MASTER_POLICY_TYPE_SUCCESS:
                PolicyTypeDropdown = Object.assign({}, state.PolicyReducer.data);
                delete state.PolicyReducer;
                return { PolicyTypeDropdown };
            case ACTION_MASTER_ASSIGN_RM_SUCCESS:
                ProposalRM = Object.assign({}, state.PolicyReducer.data);
                delete state.PolicyReducer;
                return { ProposalRM };
            case ACTION_CLAIMS_POLICY_TYPE_SUCCESS:
                DepartmentType = Object.assign({}, state.PolicyReducer.data);
                delete state.PolicyReducer;
                return { DepartmentType };
            case ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS:
                InsuredCompany = Object.assign({}, state.PolicyReducer.data);
                delete state.PolicyReducer;
                return { InsuredCompany };
            case ACTION_POLICY_DASHBOARD_SUCCESS:
                PolicyDashboard = Object.assign({}, state.PolicyReducer.data);
                delete state.PolicyReducer;
                return { PolicyDashboard };
            case ACTION_SUM_INSURED_POLICY_SUCCESS:
                SumInsuredPolicy = Object.assign({}, state.PolicyReducer.data);
                delete state.PolicyReducer;
                return { SumInsuredPolicy };
            default:
        }
    }
    return {
        PolicyTypeDropdown,
        InsuredCompany,
        PolicyDashboard,
        ProposalRM,
        DepartmentType,
        SumInsuredPolicy,

    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionPolicyType, actionInsuredCompany, actionDashboardPolicy, actionDepartmentPolicyType, actionProposalRM, actionSumInsuredPolicy,

        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PolicyDashboard))