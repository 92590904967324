import React from 'react';
import { Route, Switch } from 'react-router';
import CreateLead from '../lead/create';
import MyLeads from './list';
import LeadView from './view';
import LeadEdit from './edit';

const LeadRoute = (props) => {
    const {match} = props
    return (
        <Switch>
            <Route path={`${match.url}/add`}  component={CreateLead}/>
            <Route path={`${match.url}/view`}  component={LeadView}/>
            <Route path={`${match.url}/edit`}  component={LeadEdit}/>
            <Route exact path={`${match.url}`} component={MyLeads}/>
        </Switch>
    )
}

export default LeadRoute