import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CLAIMS_LIST } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';
import {API_REOPEN_CLAIM} from '../../../../../api/constants';

export const ACTION_CLAIMS_LOADING = 'ACTION_CLAIMS_LOADING';
export const ACTION_CLAIMS_LIST_SUCCESS = 'ACTION_CLAIMS_LIST_SUCCESS';

export const ACTION_REOPEN_CLAIM_LOADING = 'ACTION_REOPEN_CLAIM_LOADING';
export const ACTION_REOPEN_CLAIM_SUCCESS = 'ACTION_REOPEN_CLAIM_SUCCESS';

export function actionClaimsList(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_LIST,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_LOADING, { loading: false, data }));
        });
}
export function actionReopenClaim(params) {
    return (dispatch, getState) =>
        Api.post(API_REOPEN_CLAIM,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REOPEN_CLAIM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REOPEN_CLAIM_LOADING, { loading: false, data }));
        });
}