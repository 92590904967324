import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FaCircle, FaRegHandshake, FaShieldAlt } from 'react-icons/fa';
import { FiArrowLeft, FiEdit, FiGlobe, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import { BsBuilding } from 'react-icons/bs';
import { BiUserVoice } from 'react-icons/bi';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { GoLocation } from 'react-icons/go';
import { IoDocumentTextOutline, IoDocumentAttachOutline } from 'react-icons/io5';
import { actionGetClientDetails, ACTION_MASTER_CLIENT_DETAILS_SUCCESS } from "./action";
import $ from "jquery";
import { UNAUTHORIZED } from '../../../../../constants';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import BusinessSummary from './tabs/business_summary';
import ClientTapProfileDetails from './tabs/client_profile';
import ClientTabProposalsView from './tabs/proposals';
import ClientTabMeetingsView from './tabs/meetings';
import ClientTabPoliciesView from './tabs/policies';
import ClientTabClaims from './tabs/claims';
import { verifyPermission } from '../../../../../utils';


const ClientView = (props) => {

    const [clientDetails, setClientDetails] = useState(null);
    const [policyDetails, setPolicyDetails] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        if (clientDetails != null) {
            setSelectedTab(0);
        }
    }, [clientDetails])

    const initialRender = () => {
        if (props.location.state !== undefined || props.location.state != null) {
            $("#progress-loader").removeClass("hidden");
            props.actionGetClientDetails({ id: props?.location?.state?.client_id });
        } else {
            props.history.goBack();
        }

        // $(".auto-suggestion").remove();
    }
    useEffect(initialRender, [props.location.state]);

    // Get the Response of Client Details
    const onClientDetailUpdate = () => {
        const { ClientDetails } = props;
        if (ClientDetails != null) {
            var ClientDetailsResponse = Object.assign({}, ClientDetails);
            if (ClientDetailsResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setClientDetails(ClientDetailsResponse.response);
                setPolicyDetails(ClientDetailsResponse.response.policy_details);
                setSelectedTab(0);
                $(".client-tab").removeClass("active");
                $("#client-details-tab").addClass("active");
            } else {
                switch (ClientDetailsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClientDetailUpdate, [props.ClientDetails]);


    // const renderPolicyDetails = () => {
    //     var arrPolicyDetails = []
    //     if (policyDetails != null && policyDetails.length > 0) {
    //         policyDetails.forEach((policy, indexPolicy) => {
    //             arrPolicyDetails.push(
    //                 <div class="col-12 px-0 mb-1 border-bottom">
    //                     <div className="float-left">
    //                         <div className="d-flex flex-row pb-1">
    //                             <div className="flex-column mr-half">
    //                                 <img src={'/assets/images/insurance_company_logo.png'} className="rounded-circle" style={{ width: 50, height: 50 }} alt="" />
    //                             </div>
    //                             <div className="flex-column">
    //                                 <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{policy.insurance_company}<div className="badge badge-success bg-accent-1 round font-small-2 px-1 ml-half">{policy.policy}</div></p>
    //                                 {/* <div class="font-small-3 text-bold-700"><span className="text-secondary text-bold-500 mr-half">Next Renewal on</span>{dateFormat(policy.renewal_date, 'mmm, dd yyyy')}</div> */}
    //                                 <div class="font-small-3 text-bold-700"><span className="text-secondary text-bold-500 mr-half">Broker: </span>{policy.broker_name}</div>
    //                                 <div class="font-small-3"><FaCircle size={10} className="success mr-half" />Active</div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             )
    //         })
    //     }
    //     return arrPolicyDetails
    // }

    const renderTab = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <BusinessSummary
                        {...props}
                        clientId={props?.location?.state?.client_id}
                        tab={selectedTab}
                    />

                )
            case 1:
                return (
                    <ClientTapProfileDetails
                        {...props}
                        clientId={props?.location?.state?.client_id}
                        tab={selectedTab}
                    />
                )
            case 2:
                return (
                    <ClientTabProposalsView
                        {...props}
                        clientId={props?.location?.state?.client_id}
                        tab={selectedTab}
                    />
                )
            case 3:
                return (
                    <ClientTabMeetingsView
                        {...props}
                        tab={selectedTab}
                        clientId={props?.location?.state?.client_id}
                    />
                )
            case 4:
                return (
                    <ClientTabPoliciesView
                        {...props}
                        tab={selectedTab}
                        clientId={props?.location?.state?.client_id}
                    />
                )
            case 5:
                return (
                    <ClientTabClaims
                        {...props}
                        tab={selectedTab}
                        clientId={props?.location?.state?.client_id}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }
    useEffect(renderTab, [selectedTab])

    const renderTabTitle = () => {
        return (
            <>
                <ul className="nav nav-tabs nav-underline border-bottom" role="tablist" style={{ backgroundColor: 'transparent' }}>
                    <li className="nav-item">
                        <a className="nav-link client-tab d-flex align-items-center justify-content-center active" id="client-details-tab" data-toggle="tab" href="#client-details" aria-controls="client-details" role="tab" aria-selected="true"
                            onClick={(event) => {
                                setSelectedTab(0)
                            }}
                        >
                            <HiOutlineInformationCircle size={16} />
                            <span className="d-none d-sm-block">&nbsp;Business Summary</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link client-tab d-flex align-items-center justify-content-center" id="policies-tab" data-toggle="tab" href="#policies" aria-controls="policies" role="tab" aria-selected="false"
                            onClick={(event) => {
                                setSelectedTab(2)
                            }}
                        >
                            <IoDocumentTextOutline size={16} />
                            <span className="d-none d-sm-block">&nbsp;Opportunities</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link client-tab d-flex align-items-center justify-content-center" id="meetings-tab" data-toggle="tab" href="#meetings" aria-controls="meetings" role="tab" aria-selected="false"
                            onClick={(event) => {
                                setSelectedTab(3)
                            }}
                        >
                            <FaRegHandshake size={16} />
                            <span className="d-none d-sm-block">&nbsp;Meetings</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link client-tab d-flex align-items-center justify-content-center" id="saiba-policies-tab" data-toggle="tab" href="#saiba-policies" aria-controls="saiba-policies" role="tab" aria-selected="false"
                            onClick={(event) => {
                                setSelectedTab(4)
                            }}
                        >
                            <FaShieldAlt size={16} />
                            <span className="d-none d-sm-block">&nbsp;Policies</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link client-tab d-flex align-items-center justify-content-center" id="claims-tab" data-toggle="tab" href="#claims" aria-controls="claims" role="tab" aria-selected="false"
                            onClick={(event) => {
                                setSelectedTab(5)
                            }}
                        >
                            <IoDocumentAttachOutline size={18} />
                            <span className="d-none d-sm-block">&nbsp;Claims</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link client-tab d-flex align-items-center justify-content-center" id="contacts-tab" data-toggle="tab" href="#contacts" aria-controls="contacts" role="tab" aria-selected="false"
                            onClick={(event) => {
                                setSelectedTab(1)
                            }}
                        >
                            <BsBuilding size={16} />
                            <span className="d-none d-sm-block">&nbsp;Client Profile</span>
                        </a>
                    </li>
                </ul>
            </>
        )
    }

    const renderClientDetails = () => {
        return (
            <>
                <div className="d-flex flex-row justify-content-center w-100 mt-2">
                    <div className="flex-column text-center">
                        <div className="d-flex justify-content-center mb-1">
                            <div className="group-level-main-div d-flex justify-content-center align-items-center rounded-circle bg-blue-grey bg-lighten-4 ">
                                <BsBuilding className="blue-grey bg-lighten-2 _icon" />
                            </div>
                        </div>
                        <h4 className="group-level-company-name text-bold-600">{(clientDetails != null && clientDetails.company_name != null) ? clientDetails.company_name : '-'} ({(clientDetails != null && clientDetails.client_saiba_id != null) ? clientDetails.client_saiba_id : '-'})</h4>
                        <h4 className="font-medium-1 text-bold-500 text-primary cursor-pointer"
                            onClick={(() => {
                                props.history.push({
                                    pathname: '/client/group-level-view', state: { client_group_id: clientDetails?.client_group_id }
                                });
                            })}
                        >{(clientDetails != null && clientDetails.company_group_name != null) ? clientDetails.company_group_name : '-'}</h4>
                        <div className="d-flex flex-row justify-content-center w-100 mt-1">
                            <button className={`btn btn-sm btn-success font-small-2 text-bold-600 round no-hover ${(clientDetails != null && clientDetails.category != null) ? '' : 'hidden'}`}>{(clientDetails != null && clientDetails.category != null) ? _.capitalize(clientDetails.category) : '-'}</button>
                        </div>
                        <div className="d-flex flex-row justify-content-center w-100 mt-1">
                            {clientDetails != null && clientDetails?.is_active === 'yes' ? <button className={`btn btn-sm btn-success font-small-6 text-bold-300 round no-hover`}>Active</button> : <button className={`btn btn-sm btn-danger font-small-6 text-bold-300 round no-hover`}>Inactive</button>}
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-2 mb-0">
                    <div className="col-12 col-md-6 col-lg-2">
                        <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                            <a
                                className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                href={`${clientDetails != null && clientDetails.mobile != null ? `tel:` + clientDetails.mobile : '#'}`}
                            >
                                <FiPhone size={12} className="my-auto" />
                            </a>
                        </p>
                    </div>
                    <div className="col-12 col-md-12 col-lg-10">
                        <a
                            className="text-template"
                            href={`${clientDetails != null && clientDetails.mobile != null ? `tel:` + clientDetails.mobile : '#'}`}
                        >
                            <p className="font-small-3 text-bold-600 mb-half">{(clientDetails != null && clientDetails.mobile != null) ? clientDetails.mobile : '-'}</p>
                        </a>

                    </div>
                </div>
                <div className="row align-items-center mt-half">
                    <div className="col-12 col-md-6 col-lg-2">
                        <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                            <a
                                className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                href={`${clientDetails != null && clientDetails.email != null ? `mailto:` + clientDetails.email : '#'}`}
                            >
                                <FiMail size={12} className="my-auto" />
                            </a>
                        </p>
                    </div>
                    <div className="col-12 col-md-12 col-lg-10">
                        <a
                            className="text-template"
                            href={`${clientDetails != null && clientDetails.email != null ? `mailto:` + clientDetails.email : '#'}`}
                        >
                            <p className="font-small-3 text-bold-600 mb-half">{(clientDetails != null && clientDetails.email != null) ? clientDetails.email : '-'}</p>
                        </a>
                    </div>
                </div>
                <div className="row mt-half">
                    <div className="col-12 col-md-6 col-lg-2">
                        <a
                            className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                            target="_blank"
                            rel="noreferrer"
                            href={`${clientDetails != null && clientDetails.address != null ? `https://maps.google.com/maps?q=` + clientDetails.address : '#'}`}
                        >
                            <GoLocation size={12} className="my-auto" />
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-10">
                        <a
                            className="text-template"
                            target="_blank"
                            rel="noreferrer"
                            href={`${clientDetails != null && clientDetails.address != null ? `https://maps.google.com/maps?q=` + clientDetails.address : '#'}`}
                        >
                            <p className="font-small-3 text-bold-600 mb-half">{(clientDetails != null && clientDetails.address != null) ? clientDetails.address : '-'}</p>
                        </a>
                    </div>
                </div>
                <div className="row align-items-center mt-half">
                    <div className="col-12 col-md-6 col-lg-2">
                        <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                            <a
                                className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                    if (clientDetails != null && clientDetails.website != null) {
                                        if (clientDetails.website.includes("http")) {
                                            window.open(clientDetails.website, "_blank");
                                        } else {
                                            window.open("http://" + clientDetails.website, "_blank");
                                        }

                                    }
                                }}
                            >
                                <FiGlobe size={12} className="my-auto" />
                            </a>
                        </p>
                    </div>
                    <div className="col-12 col-md-12 col-lg-10">
                        <a
                            className="text-template"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                if (clientDetails != null && clientDetails.website != null) {
                                    if (clientDetails.website.includes("http")) {
                                        window.open(clientDetails.website, "_blank");
                                    } else {
                                        window.open("http://" + clientDetails.website, "_blank");
                                    }

                                }
                            }}
                        >
                            <p className="font-small-3 text-bold-600 mb-half">{(clientDetails != null && clientDetails.website != null) ? clientDetails.website : '-'}</p>
                        </a>
                    </div>
                </div>
                <div className="row align-items-center my-half">
                    <div className="col-12 col-md-12 col-lg-12 mt-3">
                        <p className="font-small-3 text-bold-600 blue-grey mb-half">
                            RM Details
                        </p>
                    </div>
                </div>
                {
                    clientDetails != null ?

                        <div className="row align-items-center mt-half">
                            <div className="col-12 col-md-6 col-lg-2">
                                <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                                    <button className="btn btn-float btn-primary btn-social-icon btn-xs rounded-circle">
                                        <FiUser size={12} className="my-auto" />
                                    </button>
                                </p>
                            </div>
                            <div className="col-12 col-md-12 col-lg-10">
                                <p className="font-small-3 text-bold-600 mb-half">
                                    {
                                        clientDetails != null && clientDetails.rm_details != null && clientDetails.rm_details.full_name != null ?
                                            clientDetails.rm_details.full_name : '-'
                                    }
                                </p>
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    clientDetails != null ?

                        <div className="row align-items-center mt-half">
                            <div className="col-12 col-md-6 col-lg-2">
                                <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                                    {
                                        clientDetails != null && clientDetails.rm_details != null && clientDetails.rm_details.mobile_no != null ?
                                            <a
                                                className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                                href={`${clientDetails != null && clientDetails.rm_details.mobile_no != null ? `tel:` + clientDetails.rm_details.mobile_no : '#'}`}
                                            >
                                                <FiPhone size={12} className="my-auto" />
                                            </a>
                                            :
                                            <button className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle">
                                                <FiPhone size={12} className="my-auto" />
                                            </button>
                                    }
                                </p>
                            </div>
                            <div className="col-12 col-md-12 col-lg-10">
                                {
                                    clientDetails != null && clientDetails.rm_details != null && clientDetails.rm_details.mobile_no != null ?
                                        <a
                                            className="text-template"
                                            href={`${clientDetails != null && clientDetails.rm_details.mobile_no != null ? `tel:` + clientDetails.rm_details.mobile_no : '#'}`}
                                        >
                                            <p className="font-small-3 text-bold-600 mb-half">
                                                {
                                                    clientDetails != null && clientDetails.rm_details.mobile_no != null ?
                                                        clientDetails.rm_details.mobile_no : '-'
                                                }
                                            </p>
                                        </a>
                                        :
                                        <>-</>
                                }
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    clientDetails != null && clientDetails.rm_details != null && clientDetails.rm_details.email != null ?
                        <div className="row align-items-center mt-half">
                            <div className="col-12 col-md-6 col-lg-2">
                                <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                                    <a
                                        className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                        href={`${clientDetails != null && clientDetails.rm_details.email != null ? `mailto:` + clientDetails.rm_details.email : '#'}`}
                                    >
                                        <FiMail size={12} className="my-auto" />
                                    </a>
                                </p>
                            </div>
                            <div className="col-12 col-md-12 col-lg-10">
                                <a
                                    className="text-template"
                                    href={`${clientDetails != null && clientDetails.rm_details.email != null ? `mailto:` + clientDetails.rm_details.email : '#'}`}
                                >
                                    <p className="font-small-3 text-bold-600 mb-half">
                                        {
                                            clientDetails != null && clientDetails.rm_details.email != null ?
                                                clientDetails.rm_details.email : '-'
                                        }
                                    </p>
                                </a>

                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    clientDetails != null && clientDetails.rm_details != null && clientDetails.rm_details.retainsion_person != null ?

                        <div className="row align-items-center mt-half">
                            <div className="col-12 col-md-6 col-lg-2">
                                <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                                    <button className="btn btn-float btn-primary btn-social-icon btn-xs rounded-circle">
                                        <BiUserVoice size={12} className="my-auto" />
                                    </button>
                                </p>
                            </div>
                            <div className="col-12 col-md-12 col-lg-10">
                                <p className="font-small-3 text-bold-600 mb-half">
                                    {
                                        clientDetails != null && clientDetails.rm_details.retainsion_person != null ?
                                            clientDetails.rm_details.retainsion_person : '-'
                                    }
                                </p>
                            </div>
                        </div>
                        :
                        <></>
                }

            </>
        )
    }
    return (
        <>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 border-right">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <span
                                        className="primary cursor-pointer"
                                        onClick={() => {
                                            if (props?.location?.state?.type === 'CUSTOMER_MAPPED_WITH_OTHER_RM' || props?.location?.state?.type === 'CUSTOMER_MAPPED_WITH_OTHER_RM_TO_MANAGER') {
                                                props.history.push({
                                                    pathname: "/client",
                                                })
                                            } else if (props?.location?.state?.from === 'client') {
                                                props.history.push({
                                                    pathname: "/profile",
                                                    state: {
                                                        user_id: clientDetails?.created_by,
                                                        tab: props?.location?.state?.selectedTab,
                                                        client_detail: "client-menu"
                                                    }
                                                })
                                            }
                                            else {
                                                props.history.goBack();
                                            }
                                        }}>
                                        <FiArrowLeft size={16} /> Back
                                    </span>
                                    {
                                        verifyPermission("UPDATE_CLIENT") ?
                                            <FiEdit
                                                size={16}
                                                className="float-right"
                                                cursor="pointer"
                                                onClick={() => {
                                                    props.history.push({
                                                        pathname: "/client/edit",
                                                        state: {
                                                            client_id: props?.location?.state?.client_id,
                                                            visible: (clientDetails !== null && (clientDetails.created_by === null || Number(clientDetails.created_by) === Number(props.auth.getUser().user_id))) ? true : false
                                                        }
                                                    })
                                                }}
                                            />
                                            :
                                            <></>
                                    }
                                </div>
                                {renderClientDetails()}
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 mt-2 mt-md-0 mt-lg-0 px-0">
                                {renderTabTitle()}
                                <div className="tab-content">
                                    {renderTab()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ClientDetails = null;


    if (
        state.ClientViewReducer != null &&
        Object.keys(state.ClientViewReducer).length > 0 &&
        state.ClientViewReducer.constructor === Object
    ) {
        switch (state.ClientViewReducer.tag) {
            case ACTION_MASTER_CLIENT_DETAILS_SUCCESS:
                ClientDetails = Object.assign({}, state.ClientViewReducer.data);
                delete state.ClientViewReducer;
                return { ClientDetails };
            default:

        }
    }
    return {
        ClientDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionGetClientDetails
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientView))