import { ACTION_API_CUSTOMER_MEETINGS_SUCCESS, ACTION_RETENTION_CLIENTS_MEETINGS_SUCCESS, ACTION_CUSTOMER_TEAM_VERTICAL_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const CustomerMeetingsReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_CUSTOMER_MEETINGS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_CUSTOMER_MEETINGS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RETENTION_CLIENTS_MEETINGS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RETENTION_CLIENTS_MEETINGS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CUSTOMER_TEAM_VERTICAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CUSTOMER_TEAM_VERTICAL_SUCCESS,
                loading: false,
            }
        );
    }
});
export default CustomerMeetingsReducer;