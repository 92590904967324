import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_RISK_MANAGEMENT_REPORT } from '../../../../../api/constants';

export const ACTION_RISK_MANAGEMENT_REPORT_LOADING = 'ACTION_RISK_MANAGEMENT_REPORT_LOADING';
export const ACTION_RISK_MANAGEMENT_REPORT_SUCCESS = 'ACTION_RISK_MANAGEMENT_REPORT_SUCCESS';

export function actionRiskManagementReport(params) {
    return (dispatch) =>
        Api.post(API_RISK_MANAGEMENT_REPORT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_MANAGEMENT_REPORT_SUCCESS, { data }));
        }).catch(() => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_MANAGEMENT_REPORT_LOADING, { loading: false, data }));
        });
}