import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_HOLIDAY_VIEW } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_HOLIDAY_VIEW_LOADING = 'ACTION_HOLIDAY_VIEW_LOADING';
export const ACTION_HOLIDAY_VIEW_SUCCESS = 'ACTION_HOLIDAY_VIEW_SUCCESS';

export function actionHolidayView(params) {
    return (dispatch, getState) =>
        Api.post(API_HOLIDAY_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_HOLIDAY_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_HOLIDAY_VIEW_LOADING, { loading: false, data }));
        });
}