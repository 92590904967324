import React, { useEffect, useState } from "react";
import $ from "jquery";
import dateFormat from "dateformat";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionPolicyDetails, ACTION_POLICY_SUCCESS } from './action';
import { actionEndorsements, ACTION_ENDORSEMENTS_SUCCESS } from './action';
import { actionClaims, ACTION_CLAIMS_SUCCESS } from './action';
import { UNAUTHORIZED } from "../../../constants";
import { convertAmount } from "../../../utils";

const ClientPolicyDetails = (props) => {

    const [clientPolicyDetails, setClientPolicyDetails] = useState([]);
    const [endroresments, setEndroresments] = useState([]);
    const [claims, setClaims] = useState([]);
    const [activeTab, setActiveTab] = useState(1);

    const initialRender = () => {

        console.log("Policy Popup" + props.show);
        if (props.show === true) {

            props.actionPolicyDetails({
                control_no: props.clientPolicyDetails.control_no
            })

            props.actionEndorsements({
                control_no: props.clientPolicyDetails.control_no
            })

            props.actionClaims({
                control_no: props.clientPolicyDetails.control_no
            })

            setActiveTab(props.tab);

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        }

        return () => {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setClientPolicyDetails(null)
            setActiveTab(0);
        }
    };
    useEffect(initialRender, [props.show]);

    const actionPolicyDetailsUpdate = () => {

        console.log("Calling actionPolicyDetailsUpdate");
        const { PolicyDetails } = props;
        if (PolicyDetails != null) {
            var PolicyDetailsResponse = Object.assign({}, PolicyDetails);

            if (PolicyDetailsResponse.result === true) {

                setClientPolicyDetails(PolicyDetailsResponse.response);

            } else {
                setClientPolicyDetails([]);
                switch (PolicyDetailsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(actionPolicyDetailsUpdate, [props.PolicyDetails]);

    const actionEndorsementsUpdate = () => {
        console.log("Calling actionEndorsementsUpdate");
        const { Endroresments } = props;
        if (Endroresments != null) {
            var EndroresmentsResponse = Object.assign({}, Endroresments);

            if (EndroresmentsResponse.result === true) {

                setEndroresments(EndroresmentsResponse.response);

            } else {
                setEndroresments([]);
                switch (EndroresmentsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(actionEndorsementsUpdate, [props.Endroresments]);

    const actionClaimsUpdate = () => {
        console.log("Calling actionClaimsUpdate");
        const { Claims } = props;
        if (Claims != null) {
            console.log("Calling Inside actionClaimsUpdate");
            var ClaimsResponse = Object.assign({}, Claims);

            if (ClaimsResponse.result === true) {

                console.log("Calling Getting Data actionClaimsUpdate");

                setClaims(ClaimsResponse.response);

            } else {
                console.log("Calling Not Getting Data actionClaimsUpdate");
                setClaims([]);
                switch (ClaimsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                    default:
                }
            }
        }
    }
    useEffect(actionClaimsUpdate, [props.Claims]);

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Policy Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                setActiveTab(1)
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            clientPolicyDetails != null ?
                                <>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="d-flex flex-row align-items-center w-100">
                                                <div className="flex-column">
                                                    <h1 className="font-medium-1 text-bold-700 my-0 mr-half">
                                                        {
                                                            clientPolicyDetails != null ?
                                                                clientPolicyDetails.insurer_name : ''
                                                        }
                                                    </h1>
                                                </div>

                                                <div className="d-flex d-md-none d-lg-none flex-column ml-auto">
                                                    <div className={`${props.hasOwnProperty('claim') && props.claim != null ? '' : 'hidden'}`}>
                                                        <button
                                                            id="btn-pinc-policies"
                                                            className={`btn btn-primary btn-lead btn-md px-1 font-small-3 shadow round float-right mt-half`}
                                                            onClick={() => {
                                                                $("#root").removeClass("modal-open");
                                                                $(".modal-backdrop").remove();
                                                                props.handleClose({
                                                                    register: true
                                                                });
                                                            }}
                                                        >
                                                            Register Claim
                                                        </button>
                                                    </div>
                                                    <div className="btn btn-outline-success btn-sm px-1">
                                                        <h6 className="font-small-3 text-bold-500 my-0">
                                                            {
                                                                clientPolicyDetails != null ?
                                                                    clientPolicyDetails.policy_status : ''
                                                            }
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 d-none d-md-flex d-lg-flex justify-content-start justify-content-md-end justify-content-lg-end align-items-center">
                                            <div className="btn btn-success btn-sm px-1">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        clientPolicyDetails != null ?
                                                            clientPolicyDetails.policy_status : ''
                                                    }
                                                </h6>
                                            </div>
                                            <div className={`${props.hasOwnProperty('claim') && props.claim != null ? '' : 'hidden'}`}>
                                                <button
                                                    id="btn-pinc-policies"
                                                    className={`btn btn-primary btn-lead btn-md px-1 font-small-3 shadow round float-right ml-1`}
                                                    onClick={() => {
                                                        $("#root").removeClass("modal-open");
                                                        $(".modal-backdrop").remove();
                                                        props.handleClose({
                                                            register: true
                                                        });
                                                    }}
                                                >
                                                    Register Claim
                                                </button>
                                            </div>

                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 mt-1">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                                    {
                                                        clientPolicyDetails != null && clientPolicyDetails.policy_start_date != null ?
                                                            dateFormat(clientPolicyDetails.policy_start_date, 'dd/mm/yyyy') : '-'
                                                        // clientPolicyDetails.policy_start_date : '-'
                                                    }
                                                </div>
                                                <div className="flex-column px-1">to</div>
                                                <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                                    {
                                                        clientPolicyDetails != null && clientPolicyDetails.policy_expiry_date != null ?
                                                            clientPolicyDetails.policy_expiry_date : '-'
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-1 border-top pt-2">
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Department</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {
                                                    clientPolicyDetails != null ?
                                                        clientPolicyDetails.department : ''
                                                }</h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Type</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {
                                                    clientPolicyDetails != null ?
                                                        clientPolicyDetails.policy_type : ''
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy No</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {
                                                    clientPolicyDetails != null ?
                                                        clientPolicyDetails.policy_no : ''
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Customer Name</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify"
                                            // onClick={(() => {
                                            //         props.history.replace({
                                            //             pathname: '/client/view',
                                            //             state: {
                                            //                 client_id: clientPolicyDetails?.client_id,
                                            //             }
                                            //         });
                                            // })}
                                            >
                                                {
                                                    clientPolicyDetails != null ?
                                                        clientPolicyDetails.customer_name : ''
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Customer Group</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {
                                                    clientPolicyDetails != null ?
                                                        clientPolicyDetails.customer_group : ''
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Control Number</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {
                                                    clientPolicyDetails != null ?
                                                        clientPolicyDetails.control_no : ''
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">RM Name</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {
                                                    clientPolicyDetails != null ?
                                                        clientPolicyDetails.rm_name : ''
                                                }
                                            </h4>
                                        </div>
                                    </div>
                                    <hr className={`my-0`} />
                                    <div>
                                        <ul className="nav nav-tabs nav-underline border-bottom" role="tablist" style={{ backgroundColor: 'transparent' }}>
                                            <li className="nav-item">
                                                <a
                                                    className={` ${activeTab === 1 ? 'active' : ''} nav-link d-flex align-items-center justify-content-center`}
                                                    onClick={() => {
                                                        setActiveTab(1);
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">&nbsp;Policy Details</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={` ${activeTab === 2 ? 'active' : ''} nav-link d-flex align-items-center justify-content-center`}
                                                    onClick={() => {
                                                        setActiveTab(2);
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">&nbsp;Endorsements</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={` ${activeTab === 3 ? 'active' : ''} nav-link d-flex align-items-center justify-content-center`}
                                                    onClick={() => {
                                                        setActiveTab(3);
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">&nbsp;Claims</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={`${activeTab === 1 ? '' : 'hidden'}`}>
                                        <div className="row mt-2">
                                            <div className="col-12 col-md-9 col-lg-9 mb-1">
                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch</h6>
                                                <h4 className="font-small-2 text-bold-700 text-justify">
                                                    {
                                                        clientPolicyDetails != null ?
                                                            clientPolicyDetails.insurer_branch : ''
                                                    }</h4>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3 mb-1">
                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Location</h6>
                                                <h4 className="font-small-2 text-bold-700 text-justify">
                                                    {
                                                        clientPolicyDetails != null ?
                                                            clientPolicyDetails.city : ''
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-3 col-lg-3 mb-1">
                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Sum Insured</h6>
                                                <h4 className="font-small-2 text-bold-700 text-justify">
                                                    {
                                                        clientPolicyDetails != null && clientPolicyDetails.sum_insured != null ?
                                                            <div><span>&#8377;</span> {convertAmount(clientPolicyDetails.sum_insured)} </div> : '0'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3 mb-1">
                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Net Premium</h6>
                                                <h4 className="font-small-2 text-bold-700 text-justify">
                                                    {
                                                        clientPolicyDetails != null && clientPolicyDetails.net_premium != null ?
                                                            <div><span>&#8377;</span> {convertAmount(clientPolicyDetails.net_premium)} </div> : '0'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3 mb-1">
                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">GST</h6>
                                                <h4 className="font-small-2 text-bold-700 text-justify">
                                                    {
                                                        clientPolicyDetails != null && clientPolicyDetails.service_tax != null ?
                                                            <div><span>&#8377;</span> {convertAmount(clientPolicyDetails.service_tax)} </div> : '0'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3 mb-1">
                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Gross Premium</h6>
                                                <h4 className="font-small-2 text-bold-700 text-justify">
                                                    {
                                                        clientPolicyDetails != null && clientPolicyDetails.gross_premium != null ?
                                                            <div><span>&#8377;</span> {convertAmount(clientPolicyDetails.gross_premium)} </div> : '0'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${activeTab == 2 ? '' : 'hidden'}`}>
                                        <div id="accordionWrap1" role="tablist" aria-multiselectable="true" className="mt-1">
                                            <div class="card accordion collapse-icon accordion-icon-rotate mb-1">
                                                {
                                                    endroresments != null && endroresments.length > 0 ?
                                                        endroresments.map((objEndroresment, index) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        id={`heading1${index}`}
                                                                        class="card-header border-bottom py-1 bg-blue-grey bg-lighten-5"
                                                                        data-toggle="collapse"
                                                                        href={`#accordion1${index}`}
                                                                        aria-expanded={`${index === 0 ? 'true' : ""}`}
                                                                        aria-controls={`#accordion1${index}`}
                                                                    >
                                                                        <a class="card-title lead">
                                                                            <div className="d-flex flex-row">
                                                                                <div className="flex-column">
                                                                                    <p className="mb-0">Endroresment No : <span className="text-bold-600">{objEndroresment.endo_no} ( {objEndroresment.business_book_date != null ? dateFormat(objEndroresment.business_book_date, 'dd/mm/yyyy') : '-'} )</span></p>
                                                                                    <p className="font-small-2 mb-0">Type : <span className="text-bold-600">{objEndroresment.policy_type}</span></p>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div
                                                                        id={`accordion1${index}`}
                                                                        role="tabpanel"
                                                                        data-parent="#accordionWrap1"
                                                                        aria-labelledby={`heading1${index}`}
                                                                        class={`${index === 0 ? 'show' : "collapse"}`}
                                                                    >
                                                                        <div class="card-content">
                                                                            <div class="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Endorsement Premium : <span className="text-bold-600">{(objEndroresment != null && objEndroresment.net_premium != null) ?
                                                                                            <div><span>&#8377;</span> {convertAmount(objEndroresment.net_premium)} </div> : '-'} </span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">GST : <span className="text-bold-600">{(objEndroresment != null && objEndroresment.service_tax != null) ? convertAmount(objEndroresment.service_tax) : ''}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Gross Premium : <span className="text-bold-600">{(objEndroresment != null && objEndroresment.gross_premium != null) ?
                                                                                            <div><span>&#8377;</span> {convertAmount(objEndroresment.gross_premium)} </div> : '-'} </span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Policy Expiry Date : <span className="text-bold-600">{(objEndroresment != null && objEndroresment.policy_expiry_date != null) ? objEndroresment.policy_expiry_date : '-'}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })

                                                        :
                                                        <>
                                                            <p className="text-center">No Data Found</p>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${activeTab === 3 ? '' : 'hidden'}`}>
                                        <div id="accordionWrap2" role="tablist" aria-multiselectable="true" className="mt-1">
                                            <div class="card accordion collapse-icon accordion-icon-rotate mb-1">
                                                {
                                                    claims != null && claims.length > 0 ?
                                                        claims.map((objClaim, index) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        id={`heading11${index}`}
                                                                        class="card-header border-bottom py-1 bg-blue-grey bg-lighten-5"
                                                                        data-toggle="collapse"
                                                                        href={`#accordion11${index}`}
                                                                        aria-expanded={`${index === 0 ? 'true' : ""}`}
                                                                        aria-controls={`#accordion11${index}`}
                                                                    >
                                                                        <a class="card-title lead">
                                                                            <div className="d-flex flex-row">
                                                                                <div className="flex-column">
                                                                                    <p className="mb-0">Claim No : <span className="text-bold-600">{objClaim.claim_no} ( {(objClaim != null && objClaim.date_of_loss != null && objClaim.date_of_loss !== "0000-00-00 00:00:00") ? objClaim.date_of_loss : "NA"} )</span></p>
                                                                                    <p className="font-small-2 mb-0">Status : <span className="text-bold-600">{objClaim.status}</span></p>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div
                                                                        id={`accordion11${index}`}
                                                                        role="tabpanel"
                                                                        data-parent="#accordionWrap2"
                                                                        aria-labelledby={`heading11${index}`}
                                                                        class={`${index === 0 ? 'show' : "collapse"}`}
                                                                    >
                                                                        <div class="card-content">
                                                                            <div class="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Claim Executive : <span className="text-bold-600">{objClaim.csc_name}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Customer Code : <span className="text-bold-600">{objClaim.client_saiba_id}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Claimed Amount :  <span className="text-bold-600">{`₹ ${convertAmount(objClaim.amount)}`}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Surveyor Name : <span className="text-bold-600">{objClaim.surveyor_name}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Nature Of Claim : <span className="text-bold-600">{objClaim.nature_of_claim}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Intimation Date : <span className="text-bold-600">{(objClaim.intimate_date != null && objClaim.intimate_date !== "0000-00-00 00:00:00") ? dateFormat(objClaim.intimate_date, 'dd/mm/yyyy') : "NA"}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Settled amount : <span className="text-bold-600">{`₹ ${convertAmount(objClaim.settle_amount)}`}</span></p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-md-6 col-lg-6 mb-half">
                                                                                        <p className="mb-0">Date of Loss : <span className="text-bold-600">{(objClaim.date_of_loss != null && objClaim.date_of_loss !== "0000-00-00 00:00:00") ? objClaim.date_of_loss : "NA"}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })

                                                        :
                                                        <p className="text-center">No Data Found</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div id="claim_loader" className="text-center">
                                    <div className="spinner-border spinner-border-sm ml-half text-primary"></div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {

    var PolicyDetails = null;
    var Endroresments = null;
    var Claims = null;

    if (
        state.ClientPolicyDetailReducer != null &&
        Object.keys(state.ClientPolicyDetailReducer).length > 0 &&
        state.ClientPolicyDetailReducer.constructor === Object
    ) {
        switch (state.ClientPolicyDetailReducer.tag) {
            case ACTION_POLICY_SUCCESS:
                PolicyDetails = Object.assign({}, state.ClientPolicyDetailReducer.data);
                delete state.ClientPolicyDetailReducer;
                return { PolicyDetails };
            case ACTION_ENDORSEMENTS_SUCCESS:
                Endroresments = Object.assign({}, state.ClientPolicyDetailReducer.data);
                delete state.ClientPolicyDetailReducer;
                return { Endroresments };
            case ACTION_CLAIMS_SUCCESS:
                Claims = Object.assign({}, state.ClientPolicyDetailReducer.data);
                delete state.ClientPolicyDetailReducer;
                return { Claims };
            default:
        }
    }
    return {
        PolicyDetails,
        Endroresments,
        Claims
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionPolicyDetails,
            actionClaims,
            actionEndorsements
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPolicyDetails);