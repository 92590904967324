import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_GROUP_PROFILE_DETAILS, } from '../../../../../api/constants';

export const ACTION_GROUP_PROFILE_DETAIL_LOADING = 'ACTION_GROUP_PROFILE_DETAIL_LOADING';
export const ACTION_GROUP_PROFILE_DETAIL_SUCCESS = 'ACTION_GROUP_PROFILE_DETAIL_SUCCESS';

export function actionGetGroupProfileDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_GROUP_PROFILE_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GROUP_PROFILE_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GROUP_PROFILE_DETAIL_LOADING, { loading: false, data }));
        });
}

