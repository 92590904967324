import { ACTION_PERMISSION_CONTENT_SUCCESS } from "./action";
import { ACTION_CONTENT_STATUS_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const PermissionContentReducer = createReducer(INITIAL_STATE, {
    [ACTION_PERMISSION_CONTENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PERMISSION_CONTENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CONTENT_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CONTENT_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
});
export default PermissionContentReducer;