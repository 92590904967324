import React, { useEffect, useState } from 'react';
import { FiEdit, FiUser, FiUserCheck } from 'react-icons/fi';
import { RiUser2Line } from "react-icons/ri"
import { actionEmployeeView, ACTION_EMPLOYEE_VIEW_SUCCESS } from './action';
import { UNAUTHORIZED } from '../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import dateFormat from 'dateformat';
import { generateInitialLettersString } from '../../../../../utils';
import { ToastContainer, toast } from 'react-toastify';
import { withAuth } from 'react-auth-guard';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import { IoMdRibbon } from 'react-icons/io';
import moment from 'moment';
import DocumentIframe from '../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../utils';

const EmployeeView = (props) => {

    const [employeeView, setEmployeeView] = useState([]);

    // certification
    const [certificationDetails, setCertificationDetails] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionEmployeeView({
            id: props.location.state.pinc_employee_id,
        });
    }
    useEffect(initialRender, []);

    const onEmployeeViewUpdate = () => {
        const { EmployeeView } = props;
        if (EmployeeView != null) {
            var EmployeeResponse = Object.assign({}, EmployeeView);
            if (EmployeeResponse.result === true) {
                setEmployeeView(EmployeeResponse.response);
                setCertificationDetails(EmployeeResponse.response.certificate);
            } else {
                switch (EmployeeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEmployeeViewUpdate, [props.EmployeeView])
    return (
        <>
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#AAAAAA99' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (employeeView != null && employeeView.profile_photo != null) ?
                                                <img
                                                    src={employeeView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(props.location.state.full_name))}

                                                    </div>
                                                </>
                                        }

                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {employeeView.full_name} <button className="btn btn-sm btn-success font-small-1 text-bold-500 round">{employeeView.designation_name}</button>
                                            </h3>
                                            <h4 class="mb-0 font-small-3 mt-half"><span className="text-bold-600">Reporting To : </span>{employeeView.reporting_person}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="users-edit">
                <div className="card">
                    <div className="card-content">
                        <div className="card-body">
                            <div className="tab-content">
                                <div className="tab-pane active" id="personal-information" aria-labelledby="personal-information-tab" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body px-0 py-0">
                                            <form noValidate autoComplete="Off">
                                                <div className="form-body">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                                <div><FiUser size={16} />&nbsp;Organizational Information</div>

                                                                <div className="float-right">
                                                                    <button type="button" class="btn pt-0"
                                                                        onClick={() => {
                                                                            props.history.push({
                                                                                pathname: '/hrms/employee/edit',
                                                                                state: {
                                                                                    pinc_employee_id: props.location.state.pinc_employee_id
                                                                                }
                                                                            });
                                                                        }}>
                                                                        <FiEdit size={16} />
                                                                    </button>

                                                                </div>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Saiba user ID</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                (employeeView.saiba_rm_id == null || employeeView.saiba_rm_id == "null") ? '' : employeeView.saiba_rm_id
                                                            }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Email</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.email}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Gender</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{_.capitalize(employeeView.gender)}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Date of Birth</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{dateFormat(employeeView.date_of_birth, 'dd/mm/yyyy')}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Date of Joining</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{dateFormat(employeeView.joining_date, 'dd/mm/yyyy')}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Entity</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-9 col-md-9 d-flex">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.companies}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Department</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-9 col-md-9 d-flex">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.sub_companies}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Branch City</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.city_name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Branch Address</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.branch_address}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Payroll Entity</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.payroll_entity}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Function</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.department}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Reporting To</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.reporting_person}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Grade</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.grade_name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Designation</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{props.location.state.designation}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Client Facing Designation</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.client_facing_designation}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Role</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.role_name}</h4>
                                                        </div>
                                                    </div>
                                                    {
                                                        certificationDetails != null && certificationDetails?.bqp_status === 'training' ?
                                                            <div className="row pt-2">
                                                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                    <h4 className="text-uppercase font-small-3 mb-0">BQP Status</h4>
                                                                </div>
                                                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                    <h4 className="font-medium-1 text-bold-700 mb-0">{certificationDetails?.bqp_status_label != null ? certificationDetails?.bqp_status_label : '-'}</h4>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        certificationDetails != null && certificationDetails?.bqp_status === 'exam' ?
                                                            <>
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                            <IoMdRibbon size={16} />&nbsp;Certification
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h4 className="text-uppercase font-small-3 mb-0">BQP Status</h4>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{certificationDetails?.bqp_status_label != null ? certificationDetails?.bqp_status_label : '-'}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">BQP Certified</h6>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                            certificationDetails != null && certificationDetails?.bqp_certified ?
                                                                                certificationDetails?.bqp_certified : "-"
                                                                        }</h4>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                    {
                                                        certificationDetails != null && (certificationDetails?.bqp_certified === 'Valid' || certificationDetails?.bqp_certified === 'Expired') && certificationDetails?.bqp_status === 'exam' ?
                                                            <div className="row pt-2">
                                                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Certificate Number </h6>
                                                                </div>
                                                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                    <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                        certificationDetails != null && certificationDetails?.certificate_number ?
                                                                            certificationDetails?.certificate_number : "-"
                                                                    }</h4>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        certificationDetails != null && certificationDetails?.bqp_certified === 'Expired' && certificationDetails?.bqp_status === 'exam' ?
                                                            <div className="row pt-2">
                                                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Expiry Date</h6>
                                                                </div>
                                                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                    <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                        certificationDetails != null && certificationDetails?.end_date ?
                                                                            moment(certificationDetails?.end_date).format("DD/MM/YYYY") : "-"
                                                                    }</h4>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        certificationDetails != null && certificationDetails?.bqp_certified === 'Valid' && certificationDetails?.bqp_status === 'exam' ?
                                                            <>
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Start Date</h6>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                            certificationDetails != null && certificationDetails?.start_date ?
                                                                                moment(certificationDetails?.start_date).format("DD/MM/YYYY") : "-"
                                                                        }</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">End Date</h6>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                            certificationDetails != null && certificationDetails?.end_date ?
                                                                                moment(certificationDetails?.end_date).format("DD/MM/YYYY") : "-"
                                                                        }</h4>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        certificationDetails != null && (certificationDetails?.bqp_certified === 'Valid' || certificationDetails?.bqp_certified === 'Expired') && certificationDetails?.bqp_status === 'exam' ?
                                                            <>
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Certificate Copy</h6>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                            (certificationDetails != null && certificationDetails?.employee_bqp_document != null) ?
                                                                                // && certificationDetails?.user_id === props.auth.getUser().user_id) ?
                                                                                <div id="view_document">
                                                                                    <a className="primary"
                                                                                        onClick={() => {
                                                                                            if (certificationDetails?.employee_bqp_document?.split('.').pop() == 'pdf' ||
                                                                                                certificationDetails?.employee_bqp_document?.split('.').pop() == 'PDF' ||
                                                                                                certificationDetails?.employee_bqp_document?.split('.').pop() == 'png' ||
                                                                                                certificationDetails?.employee_bqp_document?.split('.').pop() == 'jpg' ||
                                                                                                certificationDetails?.employee_bqp_document?.split('.').pop() == 'jpeg' ||
                                                                                                certificationDetails?.employee_bqp_document?.split('.').pop() == 'gif'
                                                                                            ) {
                                                                                                setSelectedDocumentIndex(certificationDetails?.employee_bqp_document)
                                                                                                setShowDocumentDetails(true);
                                                                                                setExtension(certificationDetails?.employee_bqp_document?.split('.').pop())
                                                                                                setDocumentDetails(certificationDetails)
                                                                                            } else {
                                                                                                downloadFile(certificationDetails?.employee_bqp_document);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        View Document
                                                                                    </a>
                                                                                    <a id="downloadLink" className="d-none"></a>
                                                                                </div>
                                                                                : <>-</>
                                                                        }</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Brokering Entity</h6>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                            certificationDetails != null && certificationDetails?.brokering_entity ?
                                                                                certificationDetails?.brokering_entity : "-"
                                                                        }</h4>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                    {certificationDetails != null && certificationDetails?.brokering_entity === 'Previous Organization' && certificationDetails?.bqp_status === 'exam' ?
                                                        <div className="row pt-2">
                                                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">NOC</h6>
                                                            </div>
                                                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                    certificationDetails != null && certificationDetails?.noc_type ?
                                                                        certificationDetails?.noc_type : "-"
                                                                }</h4>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    {certificationDetails != null && certificationDetails?.noc_type === 'NOC Received' ?
                                                        <div className="row pt-2">
                                                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">NOC Document</h6>
                                                            </div>
                                                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                                <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                    certificationDetails != null && certificationDetails?.employee_noc_document ?
                                                                        <div id="view_document">
                                                                            {/* <a target="_blank" href={certificationDetails?.employee_bqp_document}>View Document</a> */}
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (certificationDetails?.employee_noc_document?.split('.').pop() == 'pdf' ||
                                                                                        certificationDetails?.employee_noc_document?.split('.').pop() == 'PDF' ||
                                                                                        certificationDetails?.employee_noc_document?.split('.').pop() == 'png' ||
                                                                                        certificationDetails?.employee_noc_document?.split('.').pop() == 'jpg' ||
                                                                                        certificationDetails?.employee_noc_document?.split('.').pop() == 'jpeg' ||
                                                                                        certificationDetails?.employee_noc_document?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(certificationDetails?.employee_noc_document)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(certificationDetails?.employee_noc_document?.split('.').pop())
                                                                                        setDocumentDetails(certificationDetails)
                                                                                    } else {
                                                                                        downloadFile(certificationDetails?.employee_noc_document);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </div>
                                                                        : <>-</>
                                                                }</h4>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                                <div><RiUser2Line size={16} />&nbsp;Team Head</div>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Name</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.team_head}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-2">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                <FiUserCheck size={16} />&nbsp;Approval Managers
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.approval_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Asset Approval Manager</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.asset_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Finance Approval Manager</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.finance_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Travel Approval Manager</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.travel_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Attendance Approval Manager </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.attendance_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Meeting Approval Manager </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.approval_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Performance Objective Approval Manager </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.performance_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Reimbursement Approval Manager </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.reimbursement_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Reimbursement Finance Approval Manager </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.reimbursement_finance_manager}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                <FiUser size={16} />&nbsp;Other Information
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Vertical </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{(employeeView.vertical == null || employeeView.vertical == "null") ? '' : employeeView.vertical}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Segment </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{(employeeView.segment == null || employeeView.segment == "null") ? '' : employeeView.segment}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Region </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{(employeeView.region == null || employeeView.region == "null") ? '' : employeeView.region}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Product</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.product_specialist_departments}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">User Product Specialist?</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{employeeView.is_product_specialist}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-2">
                                                    <div className="col-12 col-lg-3 col-md-12">
                                                        <h6 className="text-uppercase font-small-3 mb-0">Client Visible Image</h6>
                                                        {
                                                            (employeeView != null && employeeView.client_visible_image != null) ?
                                                                // <a target="_blank" href={employeeView.client_visible_image}>Click Here</a> : <>-</>
                                                                <>
                                                                    <a className="primary"
                                                                        onClick={() => {
                                                                            if (employeeView.client_visible_image?.split('.').pop() == 'pdf' ||
                                                                                employeeView.client_visible_image?.split('.').pop() == 'PDF' ||
                                                                                employeeView.client_visible_image?.split('.').pop() == 'png' ||
                                                                                employeeView.client_visible_image?.split('.').pop() == 'jpg' ||
                                                                                employeeView.client_visible_image?.split('.').pop() == 'jpeg' ||
                                                                                employeeView.client_visible_image?.split('.').pop() == 'gif'
                                                                            ) {
                                                                                setSelectedDocumentIndex(employeeView.client_visible_image)
                                                                                setShowDocumentDetails(true);
                                                                                setExtension(employeeView.client_visible_image?.split('.').pop())
                                                                                setDocumentDetails(employeeView)
                                                                            } else {
                                                                                downloadFile(employeeView.client_visible_image);
                                                                            }
                                                                        }}
                                                                    >
                                                                        Click Here
                                                                    </a>
                                                                    <a id="downloadLink" className="d-none"></a>
                                                                </>
                                                                :
                                                                <>-</>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row pt-2">
                                                    <div className="col-12 col-lg-3 col-md-12">
                                                        <h6 className="text-uppercase font-small-3 mb-0">Visiting Card Front</h6>
                                                        {
                                                            (employeeView != null && employeeView.visiting_card_front != null) ?
                                                                // <a target="_blank" href={employeeView.visiting_card_front}>Click Here</a> : <>-</>
                                                                <>
                                                                    <a className="primary"
                                                                        onClick={() => {
                                                                            if (employeeView.visiting_card_front?.split('.').pop() == 'pdf' ||
                                                                                employeeView.visiting_card_front?.split('.').pop() == 'PDF' ||
                                                                                employeeView.visiting_card_front?.split('.').pop() == 'png' ||
                                                                                employeeView.visiting_card_front?.split('.').pop() == 'jpg' ||
                                                                                employeeView.visiting_card_front?.split('.').pop() == 'jpeg' ||
                                                                                employeeView.visiting_card_front?.split('.').pop() == 'gif'
                                                                            ) {
                                                                                setSelectedDocumentIndex(employeeView.visiting_card_front)
                                                                                setShowDocumentDetails(true);
                                                                                setExtension(employeeView.visiting_card_front?.split('.').pop())
                                                                                setDocumentDetails(employeeView)
                                                                            } else {
                                                                                downloadFile(employeeView.visiting_card_front);
                                                                            }
                                                                        }}
                                                                    >
                                                                        Click Here
                                                                    </a>
                                                                    <a id="downloadLink" className="d-none"></a>
                                                                </>
                                                                :
                                                                <>-</>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row pt-2">
                                                    <div className="col-12 col-lg-3 col-md-12">
                                                        <h6 className="text-uppercase font-small-3 mb-0">Visiting Card Back</h6>
                                                        {
                                                            (employeeView != null && employeeView.visiting_card_back != null) ?
                                                                // <a target="_blank" href={employeeView.visiting_card_back}>Click Here</a> : <>-</>
                                                                <>
                                                                    <a className="primary"
                                                                        onClick={() => {
                                                                            if (employeeView.visiting_card_back?.split('.').pop() == 'pdf' ||
                                                                                employeeView.visiting_card_back?.split('.').pop() == 'PDF' ||
                                                                                employeeView.visiting_card_back?.split('.').pop() == 'png' ||
                                                                                employeeView.visiting_card_back?.split('.').pop() == 'jpg' ||
                                                                                employeeView.visiting_card_back?.split('.').pop() == 'jpeg' ||
                                                                                employeeView.visiting_card_back?.split('.').pop() == 'gif'
                                                                            ) {
                                                                                setSelectedDocumentIndex(employeeView.visiting_card_back)
                                                                                setShowDocumentDetails(true);
                                                                                setExtension(employeeView.visiting_card_back?.split('.').pop())
                                                                                setDocumentDetails(employeeView)
                                                                            } else {
                                                                                downloadFile(employeeView.visiting_card_back);
                                                                            }
                                                                        }}
                                                                    >
                                                                        Click Here
                                                                    </a>
                                                                    <a id="downloadLink" className="d-none"></a>
                                                                </>
                                                                :
                                                                <>-</>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-actions border-0">
                                                    <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            props.history.goBack();
                                                        }}
                                                    > Back</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var EmployeeView = null;

    if (
        state.EmployeeViewReducer != null &&
        Object.keys(state.EmployeeViewReducer).length > 0 &&
        state.EmployeeViewReducer.constructor === Object
    ) {
        switch (state.EmployeeViewReducer.tag) {
            case ACTION_EMPLOYEE_VIEW_SUCCESS:
                EmployeeView = Object.assign({}, state.EmployeeViewReducer.data);
                delete state.EmployeeViewReducer;
                return { EmployeeView };
            default:
        }
    }

    return {
        EmployeeView
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionEmployeeView }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EmployeeView));





