import { API_ACCOMPAINED_BY } from '../../../../../../api/constants';
import { API_RI_MEETING_ADD } from '../../../../../../api/constants';
import { API_MASTER_COUNTRY } from '../../../../../../api/constants';
import { API_CLIENT_TYPE } from '../../../../../../api/constants';
import { API_PREVIOUS_MEETING } from '../../../../../../api/constants';
import { API_REINSURANCE, API_MEETING_LIST_CONTACT, API_GET_RI_NAME, API_RI_MEETING_ADD_CONTACT, API_RI_MEETING_NEXT } from '../../../../../../api/constants';
import Api from '../../../../../../api';
// import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_API_ACCOMPAINED_BY_LOADING = 'ACTION_API_ACCOMPAINED_BY_LOADING';
export const ACTION_API_ACCOMPAINED_BY_SUCCESS = 'ACTION_API_ACCOMPAINED_BY_SUCCESS';

export const ACTION_API_RI_MEETING_ADD_LOADING = 'ACTION_API_RI_MEETING_ADD_LOADING';
export const ACTION_API_RI_MEETING_ADD_SUCCESS = 'ACTION_API_RI_MEETING_ADD_SUCCESS';

export const ACTION_MASTER_COUNTRY_ADD_LOADING = 'ACTION_MASTER_COUNTRY_ADD_LOADING';
export const ACTION_MASTER_COUNTRY_ADD_SUCCESS = 'ACTION_MASTER_COUNTRY_ADD_SUCCESS';

export const ACTION_CLIENT_TYPE_ADD_LOADING = 'ACTION_CLIENT_TYPE_ADD_LOADING';
export const ACTION_CLIENT_TYPE_ADD_SUCCESS = 'ACTION_CLIENT_TYPE_ADD_SUCCESS';

export const ACTION_CLIENT_ADD_LOADING = 'ACTION_CLIENT_ADD_LOADING';
export const ACTION_CLIENT_ADD_SUCCESS = 'ACTION_CLIENT_ADD_SUCCESS';

export const ACTION_REINSURANCE_CLIENT_LOADING = 'ACTION_REINSURANCE_CLIENT_LOADING';
export const ACTION_REINSURANCE_CLIENT_SUCCESS = 'ACTION_REINSURANCE_CLIENT_SUCCESS';

export const ACTION_RI_GET_CONTACTS_LOADING = 'ACTION_RI_GET_CONTACTS_LOADING';
export const ACTION_RI_GET_CONTACTS_SUCCESS = 'ACTION_RI_GET_CONTACTS_SUCCESS';

export const ACTION_ADD_GET_RI_NAME_LOADING = 'ACTION_ADD_GET_RI_NAME_LOADING';
export const ACTION_ADD_GET_RI_NAME_SUCCESS = 'ACTION_ADD_GET_RI_NAME_SUCCESS';

export const ACTION_RI_MEETING_ADD_CONTACT_LOADING = 'ACTION_RI_MEETING_ADD_CONTACT_LOADING';
export const ACTION_RI_MEETING_ADD_CONTACT_SUCCESS = 'ACTION_RI_MEETING_ADD_CONTACT_SUCCESS';

export const ACTION_RI_PREVIOUS_MEETING_LOADING = 'ACTION_RI_PREVIOUS_MEETING_LOADING';
export const ACTION_RI_PREVIOUS_MEETING_SUCCESS = 'ACTION_RI_PREVIOUS_MEETING_SUCCESS';

export const ACTION_RI_MEETING_NEXT_LOADING = 'ACTION_RI_MEETING_NEXT_LOADING';
export const ACTION_RI_MEETING_NEXT_SUCCESS = 'ACTION_RI_MEETING_NEXT_SUCCESS';

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}
export function actionRiMeetingAdd(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_RI_MEETING_ADD, params, files).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_RI_MEETING_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_RI_MEETING_ADD_LOADING, { loading: false, data }));
        });
}
export function actionAddCountry() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_COUNTRY).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_COUNTRY_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_COUNTRY_ADD_LOADING, { loading: false, data }));
        });
}
export function actionAddClientType() {
    return (dispatch, getState) =>
        Api.get(API_CLIENT_TYPE,).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLIENT_TYPE_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLIENT_TYPE_ADD_LOADING, { loading: false, data }));
        });
}

export function actionReinsuranceClient(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_CLIENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_CLIENT_LOADING, { loading: false, data }));
        });
}

export function actionRIGetContacts(params) {
    return (dispatch, getState) =>
        Api.post(API_MEETING_LIST_CONTACT, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_RI_GET_CONTACTS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_GET_CONTACTS_LOADING, { loading: false, data }));
        });
}

export function actionGetRiName(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_RI_NAME, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_ADD_GET_RI_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ADD_GET_RI_NAME_LOADING, { loading: false, data }));
        });
}

export function actionRIMeetingAddContact(params) {
    return (dispatch, getState) =>
        Api.post(API_RI_MEETING_ADD_CONTACT, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_RI_MEETING_ADD_CONTACT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_MEETING_ADD_CONTACT_LOADING, { loading: false, data }));
        });
}

export function actionPreviousMeeting(params) {
    return (dispatch, getState) =>
        Api.post(API_PREVIOUS_MEETING, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_RI_PREVIOUS_MEETING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_PREVIOUS_MEETING_LOADING, { loading: false, data }));
        });
}

export function actionRIMeetingNext(params) {
    return (dispatch, getState) =>
        Api.post(API_RI_MEETING_NEXT, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RI_MEETING_NEXT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_MEETING_NEXT_LOADING, { loading: false, data }));
        });
}