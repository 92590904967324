import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import ArrayStore from 'devextreme/data/array_store';
import { ACTION_GET_DEPARTMENT_SUCCESS, actionGetDepartment } from "./action";
import { ACTION_GET_POLICY_TYPE_SUCCESS, actionGetPolicyType } from "./action";
import { ACTION_GET_INSURER_SUCCESS, actionGetCompanyList } from "./action";
import { ACTION_GENERAL_PREFERENCE_EDIT_SUCCESS, actionGeneralPlacementEdit } from './action';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const GeneralPlacementEdit = (props) => {

    const { handleSubmit, control, formState: { errors }, register, setValue } = useForm();
    const [selectedPolicyType, setSelectedPolicyType] = useState(null)
    const [selectedContactPerson, setSelectedContactPerson] = useState(null)
    const [insurerList, setInsurerList] = useState([])
    const [selectedDepartmentValue, setSelectedDepartmentValue] = useState(null)
    const [policyList, setPolicyList] = useState([])
    const [departmentList, setDepartmentList] = useState([]);
    const [generalPlacementDetails, setGeneralPlacementDetails] = useState(null)
    const [contactPerson, setContactPerson] = useState([
        {
            id: '1',
            name: 'Abbasali Dhoondia'
        },
        {
            id: '2',
            name: 'Ajay Gupta'
        }
    ])

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (props?.location?.state?.data != null) {
            setGeneralPlacementDetails(props?.location?.state?.data);
        }
        props.actionGetDepartment({ status: "ACTIVE", "eligible_only": "yes" });
        props.actionGetCompanyList({ status: "ACTIVE" })
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (generalPlacementDetails != null) {
            setValue('insurer_id', generalPlacementDetails.insurer_id)
            setValue('department_id', generalPlacementDetails.department_id)
            setValue('policy_type_id', generalPlacementDetails.policy_type_id)
            setValue('rating', generalPlacementDetails.rating)
        }
    }, [generalPlacementDetails])

    const onEditGeneralPreferenceUpdate = () => {
        const { EditGeneralPreference } = props
        if (EditGeneralPreference != null) {
            var EditGeneralPreferenceResponse = Object.assign({}, EditGeneralPreference);
            $("#save_general_placement").prop("disabled", false);
            if (EditGeneralPreferenceResponse.result === true) {
                $("#general_placement-form-loader").addClass("hidden");
                $("#progress-loader").addClass("hidden");
                props.history.goBack();
            } else {
                $("#general_placement-form-loader").addClass("hidden");
                toast.error(EditGeneralPreferenceResponse.message);
                $("#progress-loader").addClass("hidden");
                switch (EditGeneralPreferenceResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        toast.error(EditGeneralPreferenceResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onEditGeneralPreferenceUpdate, [props.EditGeneralPreference])

    const onMasterGetDepartmentUpdate = () => {
        const { MasterGetDepartment } = props
        if (MasterGetDepartment != null) {
            var MasterGetDepartmentResponse = Object.assign({}, MasterGetDepartment);
            if (MasterGetDepartmentResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setDepartmentList(MasterGetDepartmentResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetDepartmentUpdate, [props.MasterGetDepartment])

    useEffect(() => {
        if (departmentList != null && departmentList.length > 0) {
            if (generalPlacementDetails != null && generalPlacementDetails?.department_id != null) {
                setValue('department_id', generalPlacementDetails?.department_id)
            }
        }
    }, [departmentList])

    const onMasterGetPolicyListUpdate = () => {
        const { MasterGetPolicyList } = props
        if (MasterGetPolicyList != null) {
            var MasterGetPolicyListResponse = Object.assign({}, MasterGetPolicyList);
            if (MasterGetPolicyListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPolicyList(MasterGetPolicyListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetPolicyListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetPolicyListUpdate, [props.MasterGetPolicyList])

    useEffect(() => {
        if (policyList != null && policyList.length > 0) {
            if (generalPlacementDetails != null && generalPlacementDetails?.policy_type_id != null) {
                setValue('policy_type_id', generalPlacementDetails?.policy_type_id)
            }
        }
    }, [policyList])

    const onMasterGetInsurerListUpdate = () => {
        const { MasterGetInsurerList } = props
        if (MasterGetInsurerList != null) {
            var MasterGetInsurerListResponse = Object.assign({}, MasterGetInsurerList);
            if (MasterGetInsurerListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setInsurerList(MasterGetInsurerListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetInsurerListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetInsurerListUpdate, [props.MasterGetInsurerList])

    useEffect(() => {
        if (insurerList != null && insurerList.length > 0) {
            if (generalPlacementDetails != null && generalPlacementDetails?.insurer_id != null) {
                setValue('insurer_id', generalPlacementDetails?.insurer_id)
            }
        }
    }, [insurerList])


    const onSubmit = (data) => {
        $("#general_placement-form-loader").removeClass("hidden");
        $("#save_general_placement").prop("disabled", true);
        props.actionGeneralPlacementEdit({
            "id": props.location.state.data?.id,
            'insurer_id': data.insurer_id,
            'policy_type_id': selectedPolicyType != null ? selectedPolicyType : data.policy_type_id,
            'department_id': data.department_id,
            'rating': data.rating
        })
    }

    return (
        <>
            <ScreenTitle title="Edit General Preference" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Insurance Company&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="insurer_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="insurer_id"
                                                                name="insurer_id"
                                                                dataSource={insurerList}
                                                                allowFiltering={true}
                                                                filterType={"Contains"}
                                                                sortOrder={"Ascending"}
                                                                placeholder="Select Company name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Company name is required'
                                                        }}
                                                    />
                                                    {errors.insurer_id && <ErrorLabel message={errors.insurer_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Department</label>
                                                    <Controller
                                                        name="department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="department_id"
                                                                name="department_id"
                                                                dataSource={departmentList}
                                                                allowFiltering={false}
                                                                placeholder="Select Department"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectedDepartmentValue(event.itemData.id)
                                                                        setSelectedPolicyType([]);
                                                                        setValue('policy_type_id', null);
                                                                        props.actionGetPolicyType({ 'department_id': event.itemData.id, status: "ACTIVE", "eligible_only": "yes" });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Policy&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="policy_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="policy_type_id"
                                                                name="policy_type_id"
                                                                dataSource={policyList}
                                                                allowFiltering={false}
                                                                placeholder="Select Policy Type"
                                                                enabled={selectedDepartmentValue != null ? true : false}
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectedPolicyType(event.itemData.id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.policy_type_id && <ErrorLabel message={errors.policy_type_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Preference Rating</label>
                                                    <Controller
                                                        name="rating"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="rating"
                                                                name="rating"
                                                                dataSource={[
                                                                    {
                                                                        value: 1,
                                                                        text: '1'

                                                                    },
                                                                    {
                                                                        value: 2,
                                                                        text: '2'
                                                                    },
                                                                    {
                                                                        value: 3,
                                                                        text: '3'

                                                                    },
                                                                    {
                                                                        value: 4,
                                                                        text: '4'
                                                                    },
                                                                    {
                                                                        value: 5,
                                                                        text: '5'

                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Preference"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.rating && <ErrorLabel message={errors.rating?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_general_placement"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="general_placement-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var EditGeneralPreference = null;
    var MasterGetDepartment = null;
    var MasterGetPolicyList = null;
    var MasterGetInsurerList = null;
    if (
        state.GeneralPreferenceEditReducer != null &&
        Object.keys(state.GeneralPreferenceEditReducer).length > 0 &&
        state.GeneralPreferenceEditReducer.constructor === Object
    ) {
        switch (state.GeneralPreferenceEditReducer.tag) {
            case ACTION_GENERAL_PREFERENCE_EDIT_SUCCESS:
                EditGeneralPreference = Object.assign({}, state.GeneralPreferenceEditReducer.data);
                delete state.GeneralPreferenceEditReducer;
                return { EditGeneralPreference };
            case ACTION_GET_DEPARTMENT_SUCCESS:
                MasterGetDepartment = Object.assign({}, state.GeneralPreferenceEditReducer.data);
                delete state.GeneralPreferenceEditReducer;
                return { MasterGetDepartment };
            case ACTION_GET_POLICY_TYPE_SUCCESS:
                MasterGetPolicyList = Object.assign({}, state.GeneralPreferenceEditReducer.data);
                delete state.GeneralPreferenceEditReducer;
                return { MasterGetPolicyList };
            case ACTION_GET_INSURER_SUCCESS:
                MasterGetInsurerList = Object.assign({}, state.GeneralPreferenceEditReducer.data);
                delete state.GeneralPreferenceEditReducer;
                return { MasterGetInsurerList };
            default:

        }
    }
    return {
        EditGeneralPreference,
        MasterGetDepartment,
        MasterGetPolicyList,
        MasterGetInsurerList,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGeneralPlacementEdit, actionGetDepartment, actionGetPolicyType, actionGetCompanyList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GeneralPlacementEdit))
