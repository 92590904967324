import { ACTION_FINANCE_LIMIT_ADD_SUCCESS, ACTION_FINANCE_LIMIT_ENTITY_SUCCESS, ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS, ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS, ACTION_REIMBURSEMENT_TYPE_SUCCESS } from './action';

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddFinanceLimitReducer = createReducer(INITIAL_STATE, {

    [ACTION_FINANCE_LIMIT_ADD_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_FINANCE_LIMIT_ADD_SUCCESS,
            loading: false
        });
    },
    [ACTION_FINANCE_LIMIT_ENTITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_FINANCE_LIMIT_ENTITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REIMBURSEMENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REIMBURSEMENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
});

export default AddFinanceLimitReducer;
