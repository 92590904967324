import React, {useState} from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';

const BranchCities = (props) => {

    const [BranchCities, setBranchCities] = useState([
        {
            name: 'Mumbai',
            status: 'A'
        },
        {
            name: 'Chennai',
            status: 'A'
        },
        {
            name: 'Bangalore',
            status: 'A'
        },
        {
            name: 'Delhi',
            status: 'A'
        },
        {
            name: 'Coimbatore',
            status: 'A'
        },
        {
            name: 'Pune',
            status: 'A'
        }
    ]);

    const columns = [
        // { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "name", caption: "Cities Name" },
        { dataField: "status", caption: "Status" },
    ];

    const renderStatusCell = (cell) => { 
        switch( cell.data.status ) {
            case 'A': 
                return <div className={"badge badge-success round px-1"}>Active</div>;
            case 'I': 
                return <div className={"badge badge-danger round px-1"}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push('/settings/branch-cities/edit')
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if( objColumn.dataField === "status" ) {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );  
                }
                              
            }
        });
        return arrColumns;
    };


    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <h3 className="content-header-title text-capitalize mb-0 ">Branch Cities</h3>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button 
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round hidden"
                                onClick={() =>{
                                    props.history.push('/settings/branch-cities/add');
                                }}
                            >
                                Add City
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={BranchCities}
                                columnHidingEnabled={false}
                                showBorders={true}
                                columnAutoWidth={true}
                                export={{
                                    fileName: "roles",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e)=>{
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BranchCities