import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS, ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS, ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS, ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS, ACTION_RISK_APPROVAL_VIEW_SUCCESS, ACTION_RISK_APPROVAL_EDIT_SUCCESS, ACTION_RISK_APPROVAL_MASTER_CITIES_LOADING, ACTION_RISK_APPROVAL_MASTER_CITIES_SUCCESS, ACTION_RISK_APPROVAL_SALES_RM_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const EditRiskManagementApprovalReducer = createReducer(INITIAL_STATE, {
    [ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_APPROVAL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_APPROVAL_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_APPROVAL_SALES_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_SALES_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_APPROVAL_MASTER_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_MASTER_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
}
);
export default EditRiskManagementApprovalReducer;
