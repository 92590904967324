import { API_MASTER_STATES } from '../../../api/constants';
import { API_MASTER_CITIES } from '../../../api/constants';
import {API_CREATE_NEW_SURVEYOR } from '../../../api/constants';
import Api from '../../../api';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_MASTER_STATES_LOADING = 'ACTION_MASTER_STATES_LOADING';
export const ACTION_MASTER_STATES_SUCCESS = 'ACTION_MASTER_STATES_SUCCESS';

export const ACTION_MASTER_CITIES_LOADING = 'ACTION_MASTER_CITIES_LOADING';
export const ACTION_MASTER_CITIES_SUCCESS = 'ACTION_MASTER_CITIES_SUCCESS';

export const ACTION_CREATE_NEW_SURVEYOR_LOADING = 'ACTION_CREATE_NEW_SURVEYOR_LOADING';
export const ACTION_CREATE_NEW_SURVEYOR_SUCCESS = 'ACTION_CREATE_NEW_SURVEYOR_SUCCESS';

export function actionStates() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_STATES).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_STATES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_STATES_LOADING, { loading: false, data }));
        });
}

export function actionCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CITIES_LOADING, { loading: false, data }));
        });
}

export function actionCreateNewSurveyor(params) {
    return (dispatch, getState) =>
        Api.post(API_CREATE_NEW_SURVEYOR, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CREATE_NEW_SURVEYOR_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CREATE_NEW_SURVEYOR_LOADING, { loading: false, data }));
        });
}