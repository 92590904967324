import React from 'react';
import { Route, Switch } from 'react-router';
import InsuranceCompanyMeetingAdd from './add';
import InsuranceCompanyMeetingEdit from './edit';
import InsuranceCompanyMeetings from './list';
import InsuranceCompanyMeetingView from './view';

const InsuranceCompanyRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={InsuranceCompanyMeetingAdd}/>
                <Route path={`${match.url}/edit`}  component={InsuranceCompanyMeetingEdit}/>
                <Route path={`${match.url}/view`}  component={InsuranceCompanyMeetingView}/>
                <Route exact path={`${match.url}`}  component={InsuranceCompanyMeetings}/>
            </Switch>
        </>
    )
}

export default InsuranceCompanyRoute