import Api from "../../../api";
import { API_ASSIGN_RM, API_EDIT_ASSIGN_RM } from "../../../api/constants";
import { fetchSuccess, loading } from "../../../utils/action.util";

export const ACTION_MASTER_ASSIGN_RM_LOADING = 'ACTION_MASTER_ASSIGN_RM_LOADING';
export const ACTION_MASTER_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_ASSIGN_RM_SUCCESS';

export const ACTION_MASTER_EDIT_ASSIGN_RM_LOADING = 'ACTION_MASTER_EDIT_ASSIGN_RM_LOADING';
export const ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS';

export function actionAssignRMPool() {
    return (dispatch, getState) =>
        Api.get(API_ASSIGN_RM).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}
export function actionEditAssignRMPool(params) {
    return (dispatch, getState) =>
        Api.post(API_EDIT_ASSIGN_RM, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}