import React from 'react';
import { Route, Switch } from 'react-router';
import CustomerMeetingAdd from './add';
import CustomerMeetingEdit from './edit';
import CustomerMeetings from './list';
import CustomerMeetingView from './view';

const CustomerMeetingRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={CustomerMeetingAdd}/>
                <Route path={`${match.url}/edit`}  component={CustomerMeetingEdit}/>
                <Route path={`${match.url}/view`}  component={CustomerMeetingView}/>
                <Route exact path={`${match.url}`}  component={CustomerMeetings}/>
            </Switch>
        </>
    )
}

export default CustomerMeetingRoute