import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { RiLayoutGridFill } from "react-icons/ri";
import { FiEdit, FiEye } from 'react-icons/fi';
import { BsTable } from 'react-icons/bs'
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ERROR, UNAUTHORIZED } from '../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { verifyPermission } from '../../../../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import TeamMembers from '../../../../../component/popups/team_details';
import { FiFilter } from "react-icons/fi";
import { actionMyLead, ACTION_API_MY_LEAD_SUCCESS } from './action';
import { actionMyTeamLead, ACTION_API_MY_TEAM_LEAD_SUCCESS } from './action';
import { actionOpenLead, ACTION_API_OPEN_LEAD_SUCCESS } from './action';
import { actionLeadTeamMember, ACTION_LEAD_TEAM_VERTICAL_SUCCESS } from './action';
import Swal from 'sweetalert2';
import moment from "moment";

const MyLeads = (props) => {

    const [myLeadCount, setMyLeadCount] = useState(0);
    const [myTeamLeadCount, setMyTeamLeadCount] = useState(0);
    const [openLeadCount, setOpenLeadCount] = useState(0);
    const [leadDetails, setLeadDetails] = useState([]);
    const [selectedTemplate, setselectedTemplate] = useState('LIST')
    const [openleads, setOpenLeads] = useState("TeamLeads")
    const [downloadFileName, setDownloadFileName] = useState("My Lead");
    const [showRMName, setShowRMName] = useState(false);
    const [showTeamMeeting, setShowTeamMeeting] = useState(true);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [myTeamVertical, setMyTeamVertical] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [type, setType] = useState('myLead');

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: "Created By", visible: true },
        { dataField: "name", caption: "Customer Name", visible: true },
        { dataField: "company_group_name", caption: "Group Name", visible: true },
        { dataField: "email", caption: "Email", visible: true },
        { dataField: "mobile", caption: "Mobile", visible: true },
        { dataField: "created_at", caption: "Create Date", visible: false },
        { dataField: "updated_at", caption: "Modify Date", visible: false },
        { dataField: "created_at_formated", caption: "Create Date", visible: true },
        { dataField: "updated_at_formated", caption: "Modify Date", visible: true },
    ]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionLeadTeamMember({ user_id: props.auth.getUser().user_id });
        props.actionMyLead({
            clientType: "my",
            leadOrClient: "lead",
            user_id: props.auth.getUser().user_id,
            vertical: "corporate",
            clientOf: "insurance"
        });

    }
    useEffect(initialRender, []);

    const onMyLeadsUpdate = () => {
        const { Lead } = props;
        if (Lead != null) {
            var MyLeadsResponse = Object.assign({}, Lead);
            if (MyLeadsResponse.result === true) {
                setShowRMName(false);
                setShowTeamMeeting(true);
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "full_name", caption: "Created By", visible: true },
                    { dataField: "name", caption: "Customer Name", visible: true },
                    { dataField: "company_group_name", caption: "Group Name", visible: true },
                    { dataField: "email", caption: "Email", visible: true },
                    { dataField: "mobile", caption: "Mobile", visible: true },
                    { dataField: "created_at", caption: "Create Date", visible: false },
                    { dataField: "updated_at", caption: "Modify Date", visible: false },
                    { dataField: "created_at_formated", caption: "Create Date", visible: true },
                    { dataField: "updated_at_formated", caption: "Modify Date", visible: true },
                ]);

                setLeadDetails(MyLeadsResponse.response);
                setMyLeadCount(MyLeadsResponse.response.length);
                setMyTeamLeadCount(0);
                setOpenLeadCount(0);

                setDownloadFileName("My Lead");

                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                setLeadDetails([]);
                setShowTeamMeeting(true);
                switch (MyLeadsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setLeadDetails([]);
                        setMyLeadCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMyLeadsUpdate, [props.Lead]);

    const onMyTeamLeadUpdate = () => {
        const { MyTeamLead } = props;
        if (MyTeamLead != null) {
            var MyTeamLeadResponse = Object.assign({}, MyTeamLead);
            if (MyTeamLeadResponse.result === true) {
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "full_name", caption: "Created By", visible: true },
                    { dataField: "name", caption: "Customer Name", visible: true },
                    { dataField: "company_group_name", caption: "Group Name", visible: true },
                    { dataField: "email", caption: "Email", visible: true },
                    { dataField: "mobile", caption: "Mobile", visible: true },
                    { dataField: "created_at", caption: "Create Date", visible: false },
                    { dataField: "updated_at", caption: "Modify Date", visible: false },
                    { dataField: "created_at_formated", caption: "Create Date", visible: true },
                    { dataField: "updated_at_formated", caption: "Modify Date", visible: true },
                ]);

                setLeadDetails(MyTeamLeadResponse.response);
                $("#progress-loader").addClass("hidden");
                setShowRMName(false);
                setShowTeamMeeting(false);
                setMyLeadCount(0);
                setMyTeamLeadCount(MyTeamLeadResponse.response.length);
                setOpenLeadCount(0);

                setDownloadFileName("My Team Lead");

            } else {
                $("#progress-loader").addClass("hidden");
                setLeadDetails([]);
                setShowTeamMeeting(true);
                switch (MyTeamLeadResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setLeadDetails([]);
                        setMyTeamLeadCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMyTeamLeadUpdate, [props.MyTeamLead]);

    const onOpenLeadUpdate = () => {
        const { OpenLead } = props;
        if (OpenLead != null) {
            var OpenLeadResponse = Object.assign({}, OpenLead);
            if (OpenLeadResponse.result === true) {
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "name", caption: "Customer Name", visible: true },
                    { dataField: "company_group_name", caption: "Group Name", visible: true },
                    { dataField: "email", caption: "Email", visible: true },
                    { dataField: "mobile", caption: "Mobile", visible: true },
                    { dataField: "created_at", caption: "Create Date", visible: false },
                    { dataField: "updated_at", caption: "Modify Date", visible: false },
                    { dataField: "created_at_formated", caption: "Create Date", visible: true },
                    { dataField: "updated_at_formated", caption: "Modify Date", visible: true },
                ]);
                setLeadDetails(OpenLeadResponse.response);
                setMyLeadCount(0);
                setMyTeamLeadCount(0);
                setOpenLeadCount(OpenLeadResponse.response.length);
                $("#progress-loader").addClass("hidden");
                setShowRMName(false);
                setShowTeamMeeting(true);
                setDownloadFileName("Open Lead");

            } else {
                $("#progress-loader").addClass("hidden");
                setLeadDetails([]);
                setShowTeamMeeting(true);
                switch (OpenLeadResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setLeadDetails([]);
                        setOpenLeadCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onOpenLeadUpdate, [props.OpenLead]);

    const onLeadTeamMemberUpdate = () => {
        const { LeadTeamMember } = props;
        if (LeadTeamMember != null) {
            var LeadTeamMemberResponse = Object.assign({}, LeadTeamMember);
            if (LeadTeamMemberResponse.result === true) {
                setShowTeamMeeting(true)
                var myTeam = []
                var myTeamMember = []
                LeadTeamMemberResponse.response.map((item) => {
                    if (item.id != props.auth.getUser().user_id) {
                        myTeam.push(item);
                        myTeamMember.push(item.id);
                        setMyTeamVertical(myTeam);
                        setMyTeamMembers(myTeamMember);
                    }
                })

            } else {
                setShowTeamMeeting(true)
                switch (LeadTeamMemberResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onLeadTeamMemberUpdate, [props.LeadTeamMember]);

    const renderCreatedDate = (cell) => {
        return <div>{cell?.data?.created_at_formated != null ? moment(cell?.data?.created_at_formated).format('DD/MM/YYYY') : ''}</div>
    }
    const renderUpdatedDate = (cell) => {
        return <div>{cell?.data?.updated_at_formated != null ? moment(cell?.data?.updated_at_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                    if (cell?.data?.client_type.toLowerCase() === 'lead') {
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    } else if (cell?.data?.client_type.toLowerCase() === 'client') {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    }
                })}
            ><u>{cell?.data?.name}</u></a>
        }
    }
    
    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        {
                            verifyPermission("VIEW_MY_LEADS") ?
                                <li class="nav-item">
                                    <button
                                        id="btn-my-lead"
                                        className={`btn ${myLeadCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setOpenLeads("TeamLeads")
                                            setShowTeamMeeting(false);
                                            setShowRMName(false);
                                            setType('myLead');
                                            props.actionMyLead({
                                                "clientType": "my",
                                                "leadOrClient": "lead",
                                                "user_id": props.auth.getUser().user_id,
                                                "vertical": "corporate",
                                                "clientOf": "insurance"
                                            })
                                            props.actionLeadTeamMember({ user_id: props.auth.getUser().user_id });

                                        }}
                                    >
                                        My Leads {myLeadCount > 0 ? `(` + myLeadCount + `)` : ``}
                                    </button>
                                </li>
                                :

                                <></>

                        }
                        {
                            verifyPermission("VIEW_MY_TEAM_LEADS") && verifyPermission("SHOW_TEAM") ?

                                <li class="nav-item ml-1">
                                    <button
                                        id="btn-my-team-lead"
                                        className={`btn ${myTeamLeadCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            setOpenLeads("TeamLeads");
                                            setType('teamLead');
                                            if (showTeamMeeting != true) {
                                                if (showRMName === true) {
                                                    setShowRMName(false);
                                                } else {
                                                    setShowRMName(true);
                                                }
                                            }
                                            if (myTeamMembers != null && myTeamMembers.length > 0 && showTeamMeeting === true) {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionMyTeamLead({
                                                    leadOrClient: "lead",
                                                    user_id: myTeamMembers,
                                                    vertical: "corporate",
                                                    clientOf: "insurance"
                                                })
                                            }
                                        }}
                                    >
                                        Team Leads <span className='mr-half'>{myTeamLeadCount > 0 ? `(` + myTeamLeadCount + `)` : ``}</span>
                                        <FiFilter size={16} />
                                    </button>
                                </li>
                                : <> </>
                        }
                        {
                            verifyPermission("VIEW_OPEN_LEADS") ?
                                <li class="nav-item ml-1">
                                    <button
                                        id="btn-open-lead"
                                        className={`btn ${openLeadCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            setOpenLeads("OpenLeads");
                                            setType('openLead');
                                            $("#progress-loader").removeClass("hidden");
                                            setShowTeamMeeting(false);
                                            setShowRMName(false);
                                            props.actionOpenLead({
                                                "leadOrClient": "lead",
                                                "user_id": props.auth.getUser().user_id,
                                                "vertical": "corporate",
                                                "clientOf": "insurance"
                                            })
                                        }}
                                    >
                                        Open Leads {openLeadCount > 0 ? `(` + openLeadCount + `)` : ``}
                                    </button>
                                </li>
                                : <></>

                        }

                    </ul>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {/* {
                            leadDetails != null && leadDetails.length > 0 ?
                                <li class="nav-item mr-1">
                                    <button className="btn btn-primary btn-md font-small-3 shadow  round"
                                        onClick={(event) => {
                                            if (selectedTemplate === "LIST") {
                                                setselectedTemplate("GRID")
                                            } else {
                                                setselectedTemplate("LIST")
                                            }
                                        }}
                                    >{
                                            selectedTemplate === "LIST" ?
                                                <RiLayoutGridFill />
                                                : <BsTable />
                                        }
                                    </button>
                                </li>
                                : <></>
                        } */}
                        {
                            verifyPermission("CREATE_LEADS") ?
                                <li class="nav-item">
                                    <button className="btn btn-primary btn-md px-1 font-small-3 shadow  round"
                                        onClick={() => {
                                            props.history.push('/leads/add')
                                        }}
                                    >
                                        Create Lead
                                    </button>
                                </li>
                                : <> </>

                        }

                    </ul>
                </div>
            </>
        )
    }

    const renderGroupNameCell = (cell) => {
        if (cell?.data?.company_group_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/client/group-level-view',
                        state: { client_group_id: cell?.data?.client_group_id }
                    });
                }}
            ><u>{cell?.data?.company_group_name}</u></a>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: '/leads/view',
                                            state: {
                                                client_id: params.data.id,
                                                type: 'Lead'
                                            }
                                        });
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        {
                            verifyPermission("UPDATE_LEADS") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/leads/edit',
                                                    state: {
                                                        client_id: params.data.id,
                                                        visible: (props.auth.userId === params.data.created_by_id || props.auth.userId === 1) && type != "openLead" ? true : (type === "openLead" ? true : false),
                                                        type: 'Lead'
                                                    }
                                                });
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "company_group_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderGroupNameCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompanyNameCell}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "updated_at_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderUpdatedDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "created_at_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreatedDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };
    const renderListUI = () => {
        return (

            <DataGrid
                dataSource={leadDetails}
                columnAutoWidth={true}
                showBorders={true}
                export={{
                    fileName: downloadFileName,
                    enabled: leadDetails != null && leadDetails.length > 0 ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption("updated_at_formated", "visible", false)
                    event.component.columnOption("created_at", "visible", true)
                    event.component.columnOption("created_at_formated", "visible", false)
                    event.component.columnOption("updated_at", "visible", true)
                }}
                onExported={(event) => {
                    event.component.columnOption("updated_at_formated", "visible", true)
                    event.component.columnOption("created_at", "visible", false)
                    event.component.columnOption("created_at_formated", "visible", true)
                    event.component.columnOption("updated_at", "visible", false)
                    event.component.endUpdate();
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: 'before',
                        // template: 'totalGroupCount1',

                    })
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    const renderGridUI = () => {
        var arrGridColumns = []
        if (leadDetails != null && leadDetails.length > 0) {
            leadDetails.map((lead, index) => (
                arrGridColumns.push(
                    <div className="col-12 col-md-6 col-lg-6" key={index}>
                        <div className="card border">
                            <div className={`card-body px-0 py-1`}>
                                <div className="d-flex flex-row px-1">
                                    <div className="profile-img-container-sm bg-light rounded-circle mr-1 border">
                                        <h6 class="name-preffix-sm"> {String(lead.name).substring(0, 2).toUpperCase()}</h6>
                                    </div>
                                    <div>
                                        <p className={`card-text-esc font-medium-1 mb-0 text-capitalize`}>{lead.name}</p>
                                        <p className={`font-small-2 small mb-0`}>Group Name: {lead.company_group_name}</p>
                                    </div>
                                    <div className={`ml-auto font-small-2`}>
                                        <div>
                                            <span>Mobile:</span> {lead.mobile}
                                        </div>
                                        <div>
                                            <span>City: {lead.country_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className={`my-half`} />
                                <div className="d-flex flex-row px-1">
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Email ID:</p>
                                        <p className={`font-small-2 small mb-0`}>{lead.email}</p>
                                    </div>
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Address:</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>{lead.address}</p>
                                    </div>
                                    <div className={"align-self-end d-inline-flex"}>

                                        {
                                            openleads === "TeamLeads" ?
                                                props.auth.userId === lead.created_by_id ?
                                                    <FiEdit size={14} className={`primary mr-half`} cursor="pointer"
                                                        onClick={() => {
                                                            props.history.push({
                                                                pathname: '/leads/edit',
                                                                state: {
                                                                    client_id: lead.id,
                                                                    type: 'Lead',
                                                                    visible: true
                                                                }
                                                            });
                                                        }}
                                                    />
                                                    : <> </>
                                                : openleads === "OpenLeads" ?
                                                    <FiEdit size={14} className={`primary mr-half`} cursor="pointer"
                                                        onClick={() => {
                                                            props.history.push({
                                                                pathname: '/leads/edit',
                                                                state: {
                                                                    client_id: lead.id,
                                                                    type: 'Lead',
                                                                    visible: true
                                                                }
                                                            });
                                                        }}
                                                    />
                                                    : <> </>
                                        }

                                        <FiEye size={14} className={`primary`} cursor="pointer"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/leads/view',
                                                    state: {
                                                        client_id: lead.id,
                                                        type: 'Lead'
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            ))
        }
        return arrGridColumns
    }


    return (
        <>
            <div className="content-header row mb-1">
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            {
                                selectedTemplate === "LIST" ?
                                    renderListUI()
                                    : <></>
                            }
                        </div>
                        {
                            selectedTemplate === "GRID" ?
                                renderGridUI()
                                : <></>
                        }
                    </div>
                </div>
            </div>
            <TeamMembers
                {...props}
                show={showRMName}
                myTeamVertical={myTeamVertical}
                myTeamMembers={userIds != null && userIds.length > 0 ? userIds : myTeamMembers}
                handleClose={(teamMember) => {
                    if (teamMember != null) {
                        setUserIds(teamMember);
                        props.actionMyTeamLead({
                            leadOrClient: "lead",
                            user_id: teamMember,
                            vertical: "corporate",
                            clientOf: "insurance"
                        });
                    } else {
                        $("#progress-loader").addClass("hidden");
                    }
                    setShowRMName(false);
                }}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    var Lead = null;
    var MyTeamLead = null;
    var OpenLead = null;
    var LeadTeamMember = null;

    if (
        state.LeadReducer != null &&
        Object.keys(state.LeadReducer).length > 0 &&
        state.LeadReducer.constructor === Object
    ) {
        switch (state.LeadReducer.tag) {
            case ACTION_API_MY_LEAD_SUCCESS:
                Lead = Object.assign({}, state.LeadReducer.data);
                delete state.LeadReducer;
                return { Lead };
            case ACTION_API_MY_TEAM_LEAD_SUCCESS:
                MyTeamLead = Object.assign({}, state.LeadReducer.data);
                delete state.LeadReducer;
                return { MyTeamLead };
            case ACTION_API_OPEN_LEAD_SUCCESS:
                OpenLead = Object.assign({}, state.LeadReducer.data);
                delete state.LeadReducer;
                return { OpenLead };
            case ACTION_LEAD_TEAM_VERTICAL_SUCCESS:
                LeadTeamMember = Object.assign({}, state.LeadReducer.data);
                delete state.LeadReducer;
                return { LeadTeamMember };
            default:

        }
    }
    return {
        Lead,
        OpenLead,
        MyTeamLead,
        LeadTeamMember
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMyLead, actionMyTeamLead, actionOpenLead, actionLeadTeamMember }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MyLeads))