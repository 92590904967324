/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FiMail } from 'react-icons/fi';
import ErrorLabel from "../../../../component/form/error_label";
import { actionForgotPassword, ACTION_FORGOTPASSWORD_SUCCESS } from "./action";
import { UNAUTHORIZED, PRIMARY_COLOR, ERROR } from '../../../../constants';

const ForgotPassword = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm({});
    const [ForgotPassword, setForgotPassword] = useState(false)

    const initialRender = () => {

    }
    useEffect(initialRender, []);

    const handleForgotPasswordSuccess = () => {
        const { ForgotPassword } = props;
        var ForgotPasswordResponse = Object.assign({}, ForgotPassword);

        $("#forget_password_submit").prop("disabled", false);

        if (ForgotPasswordResponse.result === true) {
            $("#form-loader").addClass("hidden");
            Swal.fire({
                title: 'Success',
                icon: 'success',
                text: ForgotPasswordResponse.message,
                confirmButtonColor: PRIMARY_COLOR
            }).then((result) => {
                props.history.goBack();
            });


        } else {
            $("#form-loader").addClass("hidden");
            switch (ForgotPasswordResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: ForgotPasswordResponse.message,
                        confirmButtonColor: PRIMARY_COLOR
                    }).then((result) => {
                        props.auth.logout();
                    });
                    break;
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: ForgotPasswordResponse.message,
                        confirmButtonColor: PRIMARY_COLOR
                    }).then((result) => {
                    });
                    break;
                default:
            }
        }
    }
    useEffect(handleForgotPasswordSuccess, [props.ForgotPassword]);

    const onSubmit = (data) => {
        // alert(JSON.stringify(data));
        $("#form-loader").removeClass("hidden");
        $("#forget_password_submit").prop("disabled", true);
        props.actionForgotPassword(data);
    }

    return (
        <>
            <div className="container login-container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1 order-lg-1 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-none d-md-flex d-lg-flex flex-row mb-2">
                            <div className="flex-column">
                                <img src={'/assets/images/app-logo.jpg'} style={{ height: 30, objectFit: 'cover' }} alt="" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-2">
                            <div className="flex-column">
                                <h1 className="text-bold-500 text-black">
                                    <span className="d-block">Networking &amp; customer</span>
                                    <span className="d-block">management made easier,</span>
                                    <span className="d-block">just for you!</span>
                                </h1>
                            </div>
                        </div>
                        <div className="d-flex flex-row mt-2">
                            <div className="flex-column">
                                <p className="font-small-4 text-black lh-1">
                                    <span className="d-block text-justify">an interactive platform for PINC employees to help manage work on the go;</span>
                                    <span className="d-block text-justify">Log in meetings and leads with clients. Share news. Congratulate co-worker on</span>
                                    <span className="d-block text-justify">achievements and interact with co-workers by using the dashboard.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 order-1 order-md-2 order-lg-2 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-flex d-md-none d-lg-none flex-row mb-2 justify-content-center justify-content-lg-start justify-content-md-start">
                            <img src={'/assets/images/app-logo.jpg'} style={{ height: 30, objectFit: 'cover' }} alt="" />
                        </div>
                        <div className="card shadow rounded p-4 p-md-5 mb-0">
                            <div className="card-title text-center text-black mb-half" style={{ fontWeight: 600 }}>Forgot Your Password?
                            </div>
                            <span className="text-secondary text-center mt-0 mb-3">Enter your email Id to get the reset password link</span>
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <label>Email Id<span className="text-danger">*</span></label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        {...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,

                                                message: 'Please enter valid Email Address'
                                            }
                                        })}
                                    />
                                    {errors.email && <ErrorLabel message={errors.email?.message} />}
                                    <div className="form-control-position">
                                        <FiMail size={24} className="primary" />
                                    </div>
                                </fieldset>

                                <div className="d-flex flex-row justify-content-center">
                                    <button
                                        id="forget_password_submit"
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            Submit
                                            <span id="form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </div>
                                    </button>
                                </div>
                                <div className="d-flex flex-row justify-content-center mt-1 cursor-pointer">
                                    <div className="d-flex align-items-center justify-content-center text-primary"
                                        onClick={() => {
                                            props.history.replace("/login");
                                        }}
                                    >
                                        Login
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var ForgotPassword = null;

    if (
        state.ForgotPasswordReducer != null &&
        Object.keys(state.ForgotPasswordReducer).length > 0 &&
        state.ForgotPasswordReducer.constructor === Object
    ) {
        switch (state.ForgotPasswordReducer.tag) {
            case ACTION_FORGOTPASSWORD_SUCCESS:
                ForgotPassword = Object.assign({}, state.ForgotPasswordReducer.data);
                delete state.ForgotPasswordReducer;
                return { ForgotPassword };
            default:
        }

    }
    return {
        ForgotPassword
    }

}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionForgotPassword }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
