import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import { UNAUTHORIZED } from "../../../../../../../constants";
import { actionInsurerViewTabCompanyNews, ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_SUCCESS } from "./action";

const TabCompanyNews = (props) => { 

    const [companynews, setCompanyNews] = useState();

    const initialRender = () => { 
        props.actionInsurerViewTabCompanyNews({
            insurer_id: props.insurerId
        }) 
    }
    useEffect(initialRender,[props.tab]);

    const onInsurerViewTabCompanyNews = () => {
        const { TabCompanyNew } = props;
        if( TabCompanyNew != null ) {

            var TabCompanyNewResponse = Object.assign({}, TabCompanyNew);

            if (TabCompanyNewResponse.result === true) {
                setCompanyNews(TabCompanyNewResponse.response);
            } else {

                $("#progress-loader").addClass("hidden");

                switch (TabCompanyNewResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout();
                        })
                        break;

                    default:
                }
            }
        }
    }
    useEffect(onInsurerViewTabCompanyNews, [props.TabCompanyNew]);

    return (
        <div className="tab-pane active" id="company-news" aria-labelledby="company-news-tab" role="tabpanel">
            <div className="card">
                <div className="card-body">
                    <div className="row my-1">
                        <div className="col-12 col-md-12 col-lg-12">
                            {  
                                companynews != null && companynews.news_link != null ?
                                <>
                                    <p className="font-medium-2 text-bold-600">Click on below link to see the latest News</p>
                                    <a target="_blank" href={companynews.news_link}>{companynews.news_link}</a>
                                </>
                                :
                                <h6 className="text-bold-500 text-center">News link not found</h6>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {

    var TabCompanyNew = null;
    
    if (
        state.InsurerViewTabCompanyNewsReducer != null &&
        Object.keys(state.InsurerViewTabCompanyNewsReducer).length > 0 &&
        state.InsurerViewTabCompanyNewsReducer.constructor === Object
    ) {
        switch (state.InsurerViewTabCompanyNewsReducer.tag) {
            case ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_SUCCESS:
                TabCompanyNew = Object.assign({}, state.InsurerViewTabCompanyNewsReducer.data);
                delete state.InsurerViewTabCompanyNewsReducer;
                return { TabCompanyNew };
            default:
        }
    }
    return {
        TabCompanyNew
       
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({actionInsurerViewTabCompanyNews}, dispatch);
}
export default (connect(mapStateToProps, mapDispatchToProps)(TabCompanyNews));