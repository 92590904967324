import React from "react";

const ErrorLabel = (props) => { 
    if( props.message != null ) { 
        return (
            <label 
                className="form-check-label font-small-2 text-danger"
            >
                {props.message != null ? props.message : ''}
            </label>
        )
    }
    return <></>
    
}
export default ErrorLabel