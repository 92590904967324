import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_CLIENT_CLAIMS } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const CLIENT_ALL_CLAIMS_LOADING = 'CLIENT_ALL_CLAIMS_LOADING';
export const CLIENT_ALL_CLAIMS_SUCCESS = 'CLIENT_ALL_CLAIMS_SUCCESS';

export function actionClientClaims(params) {
    return (dispatch, getState) => Api.post(API_CLIENT_CLAIMS, params).then((res) => {
        const data = res;
        PRINT('CLIENT_ALL_CLAIMS_SUCCESS ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(CLIENT_ALL_CLAIMS_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(CLIENT_ALL_CLAIMS_LOADING, { loading: false, data }));
    });
}
