import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionClientTabProposalsView, ACTION_CLIENTTABPROPOSALSVIEW_SUCCESS } from './action';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { toast } from 'react-toastify';
import ProposalDetails from '../../../../../../../component/popups/proposal';
import moment from 'moment';
import { withAuth } from 'react-auth-guard';
import { verifyPermission } from '../../../../../../../utils';

const ClientTabProposalsView = (props) => {
    const [proposalsData, setProposalsData] = useState([]);
    const [proposalColumn, setProposalColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "id", caption: "Opportunity ID" },
        { dataField: "assigned_rm_name", caption: "Assign RM" },
        { dataField: "oppurtunity_type", caption: "Opportunity Type" },
        { dataField: "policy_type", caption: "Policy Type" },
        { dataField: "department_name", caption: "Department" },
        { dataField: "intermediary_value", caption: "Intermediary Details" },
        { dataField: "insurer_name", caption: "Insurer Company" },
        { dataField: "expiring_date", caption: "Next Renewal on" },
        { dataField: "created_at", caption: "Created At" },
        { dataField: "opportunity_status", caption: "Status" },
    ]);

    const [showProposalDetailsView, setShowProposalDetailsView] = useState(false);
    const [proposalDetails, setProposalDetails] = useState(null);
    const [type, setType] = useState('All');
    const [proposalType, setProposalType] = useState('all');
    const [proposalPoolListCount, setProposalPoolListCount] = useState(0);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionClientTabProposalsView({
            type: proposalType,
            user_ids: [props.auth.getUser().user_id],
            client_ids: [props.clientId],
            client_data: "all"
        })
    }
    useEffect(initialRender, [props.clientId]);

    const onClientTabProposalsViewUpdate = () => {

        const { ClientTabProposalsView } = props;

        if (ClientTabProposalsView != null) {
            var ClientTabProposalsViewResponse = Object.assign({}, ClientTabProposalsView);
            if (ClientTabProposalsViewResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setProposalColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "id", caption: "Opportunity ID" },
                    { dataField: "assigned_rm_name", caption: "Assign RM" },
                    { dataField: "oppurtunity_type", caption: "Opportunity Type" },
                    { dataField: "policy_type", caption: "Policy Type" },
                    { dataField: "department_name", caption: "Department" },
                    { dataField: "intermediary_value", caption: "Intermediary Details" },
                    { dataField: "insurer_name", caption: "Insurer Company" },
                    { dataField: "expiring_date", caption: "Next Renewal on" },
                    { dataField: "created_at", caption: "Created At" },
                    { dataField: "opportunity_status", caption: "Status" },
                ])
                setProposalsData(ClientTabProposalsViewResponse.response);
                setProposalPoolListCount(ClientTabProposalsViewResponse.response.length);

            } else {
                $("#progress-loader").addClass("hidden");
                setProposalsData([])
                switch (ClientTabProposalsViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClientTabProposalsViewUpdate, [props.ClientTabProposalsView]);

    const renderProposalColumns = () => {
        var arrColumns = [];
        proposalColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column
                        dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1"
                                    onClick={() => {
                                        setShowProposalDetailsView(true)
                                        setProposalDetails(params.data);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        {
                            verifyPermission("OPPORTUNITY_POOL_UPDATE") ?
                                <Button
                                    render={(params) => (
                                        params.data.editable === 'yes' ? (
                                            <button
                                                className="btn btn-icon btn-sm"
                                                onClick={() => {

                                                    localStorage.setItem('detailsData', JSON.stringify(params.data));

                                                    const url = `/proposal_pool/edit?id=${params.data.id}&type=${proposalType}`;
                                                    const win = window.open(url, '_blank');
                                                    if (win) {
                                                        win.focus();
                                                    } else {
                                                        console.log('Pop-up blocked. Please allow pop-ups for this site.');
                                                    }
                                                }}
                                            >
                                                <FiEdit size={16} className="primary" />
                                            </button>
                                        ) : (
                                            <></>
                                        )
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else {
                if (objColumn.dataField === "opportunity_status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            width={145}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "id") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            width={65}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    const renderProposalsTableView = () => {
        return (
            <DataGrid
                dataSource={proposalsData}
                columnHidingEnabled={false}
                showBorders={true}
                export={{
                    fileName: "Opportunity",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderProposalColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    return (
        <>
            <div className="tab-pane" id="policies" aria-labelledby="policies-tab" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("all");
                                                props.actionClientTabProposalsView({
                                                    type: "all",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_ids: [props.clientId],
                                                    client_data: "all"
                                                });
                                                setProposalPoolListCount(0)
                                            }}
                                            className={`btn btn-md ${proposalType === 'all' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Total {proposalType === 'all' ? (proposalPoolListCount > 0) ? `(` + proposalPoolListCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("unassigned");
                                                props.actionClientTabProposalsView({
                                                    type: "unassigned",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_ids: [props.clientId],
                                                    client_data: "all"
                                                });
                                                setProposalPoolListCount(0)
                                            }}
                                            className={`btn btn-md ${proposalType === 'unassigned' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Unassigned  {proposalType === 'unassigned' ? (proposalPoolListCount > 0) ? `(` + proposalPoolListCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("assigned");
                                                props.actionClientTabProposalsView({
                                                    type: "assigned",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_ids: [props.clientId],
                                                    client_data: "all"
                                                });
                                                setProposalPoolListCount(0)
                                            }}
                                            className={`btn btn-md ${proposalType === 'assigned' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Assigned {proposalType === 'assigned' ? (proposalPoolListCount > 0) ? `(` + proposalPoolListCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("quote_rejected");
                                                props.actionClientTabProposalsView({
                                                    type: "quote_rejected",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_ids: [props.clientId],
                                                    client_data: "all"
                                                });
                                                setProposalPoolListCount(0)
                                            }}
                                            className={`btn btn-md ${proposalType === 'quote_rejected' && proposalPoolListCount > 0 ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Quotes Rejected {proposalType === 'quote_rejected' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("proposal_success");
                                                props.actionClientTabProposalsView({
                                                    type: "proposal_success",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_ids: [props.clientId],
                                                    client_data: "all"
                                                });
                                                setProposalPoolListCount(0)
                                            }}
                                            className={`btn btn-md ${proposalType === 'proposal_success' && proposalPoolListCount > 0 ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Proposal Success {proposalType === 'proposal_success' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("proposal_lost");
                                                props.actionClientTabProposalsView({
                                                    type: "proposal_lost",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_ids: [props.clientId],
                                                    client_data: "all"
                                                });
                                                setProposalPoolListCount(0)
                                            }}
                                            className={`btn btn-md ${proposalType === 'proposal_lost' && proposalPoolListCount > 0 ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Proposal Lost {proposalType === 'proposal_lost' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}
                                        </button>
                                    </li>
                                </ul>
                                {
                                    proposalsData != null && proposalsData.length > 0 ?
                                        renderProposalsTableView() :
                                        <h6 className="text-bold-500 text-center">No Opportunity Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProposalDetails
                show={showProposalDetailsView}
                proposalDetails={proposalDetails}
                handleClose={() => {
                    setShowProposalDetailsView(false);
                }}
            />
        </>
    )

}

function mapStateToProps(state) {

    var ClientTabProposalsView = null;

    if (
        state.ClientTabProposalsViewReducer != null &&
        Object.keys(state.ClientTabProposalsViewReducer).length > 0 &&
        state.ClientTabProposalsViewReducer.constructor === Object
    ) {
        switch (state.ClientTabProposalsViewReducer.tag) {
            case ACTION_CLIENTTABPROPOSALSVIEW_SUCCESS:
                ClientTabProposalsView = Object.assign({}, state.ClientTabProposalsViewReducer.data);
                delete state.ClientTabProposalsViewReducer;
                return { ClientTabProposalsView };
            default:
        }
    }
    return { ClientTabProposalsView }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionClientTabProposalsView }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientTabProposalsView));
