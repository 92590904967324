import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_NATURE_EXPENCES_LIST } from '../../../../../../api/constants';
import { API_NATURE_EXPENCES_STATUS } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_NATURE_EXPENCES_LIST_LOADING = 'ACTION_NATURE_EXPENCES_LIST_LOADING';
export const ACTION_NATURE_EXPENCES_LIST_SUCCESS = 'ACTION_NATURE_EXPENCES_LIST_SUCCESS';

export const ACTION_NATURE_EXPENCES_STATUS_LOADING = 'ACTION_NATURE_EXPENCES_STATUS_LOADING';
export const ACTION_NATURE_EXPENCES_STATUS_SUCCESS = 'ACTION_NATURE_EXPENCES_STATUS_SUCCESS';

export function actionNatureExpencesList() {
    return (dispatch, getState) =>
        Api.get(API_NATURE_EXPENCES_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_NATURE_EXPENCES_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_NATURE_EXPENCES_LIST_LOADING, { loading: false, data }));
        });
}

export function actionNatureExpencesStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_NATURE_EXPENCES_STATUS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_NATURE_EXPENCES_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_NATURE_EXPENCES_STATUS_LOADING, { loading: false, data }));
        });
}