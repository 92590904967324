import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { API_EDIT_RISK_REQUEST, API_MASTER_CITIES, API_RISK_MANAGEMENT_REQUEST_VIEW, API_RISK_MASTER_CUSTOMER_TYPE, API_RISK_MASTER_SALES_RM } from "../../../../../../api/constants"
import { API_RISK_MANAGEMENT_CLIENT_TYPE } from "../../../../../../api/constants";
import { API_RISK_SELECT_CUSTOMER } from "../../../../../../api/constants";
import { API_RISK_MANAGEMENT_TYPE_OF_INSPECTION } from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";

export const ACTION_EDIT_NEW_REQUEST_SUCCESS = "ACTION_EDIT_NEW_REQUEST_SUCCESS";
export const ACTION_EDIT_NEW_REQUEST_LOADING = "ACTION_EDIT_NEW_REQUEST_LOADING";

export const ACTION_VIEW_NEW_REQUEST_SUCCESS = "ACTION_VIEW_NEW_REQUEST_SUCCESS";
export const ACTION_VIEW_NEW_REQUEST_LOADING = "ACTION_VIEW_NEW_REQUEST_LOADING";

export const ACTION_EDIT_CLIENT_TYPE_SUCCESS = "ACTION_EDIT_CLIENT_TYPE_SUCCESS";
export const ACTION_EDIT_CLIENT_TYPE_LOADING = "ACTION_EDIT_CLIENT_TYPE_LOADING";

export const ACTION_EDIT_CLIENT_NAME_SUCCESS = "ACTION_EDIT_CLIENT_NAME_SUCCESS";
export const ACTION_EDIT_CLIENT_NAME_LOADING = "ACTION_EDIT_CLIENT_NAME_LOADING";

export const ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS = "ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS";
export const ACTION_EDIT_TYPE_OF_INSPECTION_LOADING = "ACTION_EDIT_TYPE_OF_INSPECTION_LOADING";

export const ACTION_EDIT_MASTER_SALES_RM_LOADING = 'ACTION_EDIT_MASTER_SALES_RM_LOADING';
export const ACTION_EDIT_MASTER_SALES_RM_SUCCESS = 'ACTION_EDIT_MASTER_SALES_RM_SUCCESS';

export const ACTION_EDIT_MASTER_CITIES_LOADING = 'ACTION_EDIT_MASTER_CITIES_LOADING';
export const ACTION_EDIT_MASTER_CITIES_SUCCESS = 'ACTION_EDIT_MASTER_CITIES_SUCCESS';

export function actionEditNewRequest(params, files) {
    return (dispatch, getState) => {
        Api.multipart(API_EDIT_RISK_REQUEST, params, files).then((res) => {
            const data = res;
            PRINT('ACTION_EDIT_NEW_REQUEST', data)
            dispatch(fetchSuccess(ACTION_EDIT_NEW_REQUEST_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_NEW_REQUEST_LOADING, { loading: false, data }))
        })
    };
}

export function actionViewNewRequest(params) {
    return (dispatch, getState) => {
        Api.post(API_RISK_MANAGEMENT_REQUEST_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION VIEW', data)
            dispatch(fetchSuccess(ACTION_VIEW_NEW_REQUEST_SUCCESS, { data }))
        }).catch((reason) => {
            var data = {
                message: ""
            }
            dispatch(loading(ACTION_VIEW_NEW_REQUEST_LOADING, { loading: false, data }))
        })
    }
}

export function actionEditRiskManagementTypeOfInspection() {
    return (dispatch, getState) => {
        Api.get(API_RISK_MANAGEMENT_TYPE_OF_INSPECTION).then((res) => {
            const data = res;
            PRINT('ACTION VIEW', data)
            dispatch(fetchSuccess(ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS, { data }))
        }).catch((reason) => {
            var data = {
                message: ""
            }
            dispatch(loading(ACTION_EDIT_TYPE_OF_INSPECTION_LOADING, { loading: false, data }))
        })
    }
}

export function actionEditRiskManagementClientName(params) {
    return (dispatch, getState) => {
        Api.post(API_RISK_MASTER_CUSTOMER_TYPE, params).then((res) => {
            const data = res;
            PRINT('ACTION VIEW', data)
            dispatch(fetchSuccess(ACTION_EDIT_CLIENT_NAME_SUCCESS, { data }))
        }).catch((reason) => {
            var data = {
                message: ""
            }
            dispatch(loading(ACTION_EDIT_CLIENT_NAME_LOADING, { loading: false, data }))
        })
    }
}

export function actionEditRiskManagementClientType() {
    return (dispatch, getState) => {
        Api.get(API_RISK_MANAGEMENT_CLIENT_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION VIEW', data)
            dispatch(fetchSuccess(ACTION_EDIT_CLIENT_TYPE_SUCCESS, { data }))
        }).catch((reason) => {
            var data = {
                message: ""
            }
            dispatch(loading(ACTION_EDIT_CLIENT_TYPE_LOADING, { loading: false, data }))
        })
    }
}

export function actionSalesRM(params) {
    return (dispatch, getState) =>
        Api.get(API_RISK_MASTER_SALES_RM).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_MASTER_SALES_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_MASTER_SALES_RM_LOADING, { loading: false, data }));
        });
}

export function actionMasterCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_MASTER_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_MASTER_CITIES_LOADING, { loading: false, data }));
        });
}