import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UNAUTHORIZED } from "../../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../../utils/preferences';
import ContactAdd from "../contact_add";
import ContactEdit from "../contact_edit";
import ContactDetails from "../contact_view";
import { actionMasterGetInsurerBranchContact, ACTION_MASTER_GET_BRANCH_CONTACT_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../../utils";

const ContactList = (props) => {
    const { match } = props;
    const [showEditContactDetails, setShowEditContactDetails] = useState(false)
    const [showViewContactDetails, setShowViewContactDetails] = useState(false)
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [contactData, setContactData] = useState(null)
    const [contactList, setContactList] = useState([]);
    const [branchId, setBranchId] = useState(null);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "name", caption: "Contact Name" },
        { dataField: "mobile", caption: "Mobile Number" },
        { dataField: "email", caption: "Email ID" },
        { dataField: "designation", caption: "Designation" },
        { dataField: "status", caption: "Status" }
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        if (props?.location?.state?.detail?.id != null) {
            setBranchId(props?.location?.state?.detail?.id);
        }
        props.actionMasterGetInsurerBranchContact({ branch_id: props?.location?.state?.detail?.id });
    }
    useEffect(initialRender, []);

    const onMasterGetInsurerBranchContactUpdate = () => {
        const { MasterGetInsurerBranchContact } = props
        if (MasterGetInsurerBranchContact != null) {
            var MasterGetInsurerBranchContactResponse = Object.assign({}, MasterGetInsurerBranchContact);
            if (MasterGetInsurerBranchContactResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setContactList(MasterGetInsurerBranchContactResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetInsurerBranchContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetInsurerBranchContactUpdate, [props.MasterGetInsurerBranchContact])

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("MASTER_CONTACT_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                setShowViewContactDetails(true)
                                                setContactData(params.data)
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("MASTER_CONTACT_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                setShowEditContactDetails(true)
                                                setContactData(params.data)
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }

                    </Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-8 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">{`Contact List (${props?.location?.state?.insurer_name}, ${props?.location?.state?.detail?.short_name}, ${props?.location?.state?.detail?.city}, ${props?.location?.state?.detail?.state})`}</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-4">
                        <ul class="nav nav-pills float-right">
                            {
                                verifyPermission("MASTER_CONTACT_ADD") ?
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                setShowContactDetails(true)
                                            }}
                                        >
                                            Add Contact
                                        </button>
                                    </li>
                                    :
                                    <></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={contactList}
                                    showBorders={true}
                                    export={{
                                        fileName: "contacts",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="flex-column">
                        <div className="d-flex flex-row w-100 justify-content-start ">
                            <button
                                className="btn btn-secondary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ContactAdd
                show={showContactDetails}
                branchId={branchId}
                handleClose={(newContact) => {
                    if (newContact) {
                        props.actionMasterGetInsurerBranchContact({ branch_id: props?.location?.state?.detail?.id });
                    }
                    setShowContactDetails(false)
                }}
            />
            <ContactEdit
                show={showEditContactDetails}
                data={contactData}
                branchId={branchId}
                handleClose={(newContact) => {
                    if (newContact) {
                        props.actionMasterGetInsurerBranchContact({ branch_id: props?.location?.state?.detail?.id });
                    }
                    setShowEditContactDetails(false)
                }}
            />
            <ContactDetails
                show={showViewContactDetails}
                data={contactData}
                handleClose={(newContact) => {
                    setShowViewContactDetails(false)
                }}
            />

        </>
    );
};
const mapStateToProps = (state) => {
    var MasterGetInsurerBranchContact = null;

    if (
        state.MasterGetInsurerBranchContactReducer != null &&
        Object.keys(state.MasterGetInsurerBranchContactReducer).length > 0 &&
        state.MasterGetInsurerBranchContactReducer.constructor === Object
    ) {
        switch (state.MasterGetInsurerBranchContactReducer.tag) {
            case ACTION_MASTER_GET_BRANCH_CONTACT_SUCCESS:
                MasterGetInsurerBranchContact = Object.assign({}, state.MasterGetInsurerBranchContactReducer.data);
                delete state.MasterGetInsurerBranchContactReducer;
                return { MasterGetInsurerBranchContact };
            default:

        }
    }
    return {
        MasterGetInsurerBranchContact,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterGetInsurerBranchContact }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ContactList))