import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { FiDelete, FiEdit, FiTrash } from "react-icons/fi";
import {
  actionBirthdayWishList,
  ACTION_BIRTHDAY_WISH_LIST_SUCCESS,
  ACTION_BIRTHDAY_DELETE_SUCCESS,
} from "./action";
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { actionBirthdayWishReacordDelete} from './action';
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import moment from "moment";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
} from "../../../../../../utils/preferences";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

const BirthDayWishList = (props) => {
  const { match } = props;
  const [birthdayWishes, setBirthdayWishes] = useState([]);
  const[selectedMonth, setSelectedMonth]= useState([]);
  const {
    control,
  } = useForm();

  const initialRender = () => {
    $("#progress-loader").removeClass("hidden");

    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }

    props.actionBirthdayWishList();
  };
  useEffect(initialRender, []);

  const generateMonthOptions = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      const monthNumber = i + 1;
      const monthName = new Date(2000, i, 1).toLocaleDateString(
        "default",
        { month: "long" }
      );
      months.push({ value: monthNumber.toString(), text: monthName });
    }
    return months;
  };

  const onBirthdayWishesUpdate = () => {
    const { BirthdayWishes } = props;
    if (BirthdayWishes != null) {
      var BirthdayWishesResponse = Object.assign({}, BirthdayWishes);
      if (BirthdayWishesResponse.result === true) {
        $("#progress-loader").addClass("hidden");
        setBirthdayWishes(BirthdayWishesResponse.response);
      } else {
        $("#progress-loader").addClass("hidden");
        switch (BirthdayWishesResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  };
  useEffect(onBirthdayWishesUpdate, [props.BirthdayWishes]);

  const onBirthdayWishReacordDeleteUpdate = () => {
      const { BirthdayWishReacordDelete } = props
      var BirthdayWishReacordDeleteResponse = Object.assign({}, BirthdayWishReacordDelete);
      if (BirthdayWishReacordDeleteResponse.result === true) {
         $("#progress-loader").addClass("hidden");
         props.actionBirthdayWishList();
         toast.success('Record Deleted Successfully')
      } else {
        $("#progress-loader").addClass("hidden");
        switch (BirthdayWishReacordDeleteResponse.status){
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
  }
  useEffect(onBirthdayWishReacordDeleteUpdate, [props.BirthdayWishReacordDelete])

  const [columns, setColumns] = useState([
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "name", caption: "Name", visible: true },
    { dataField: "mobile", caption: "Number", visible: true },
    { dataField: "email", caption: "Email", visible: true },
    { dataField: "dob", caption: "Date of Birth", visible: true },
    { dataField: "sales_rm_name", caption: "Sales RM", visible: true },
    { dataField: "send_cake", caption: "Send Cake", visible: true },
    { dataField: "created_by_name", caption: "Created By", visible: true },
    { dataField: "created_at", caption: "Created On", visible: true },
  ]);

  const renderFormatedDate = (cell) => {
    return (
      <div>
        {cell?.data?.created_at != null
          ? moment(cell?.data?.created_at).format("DD/MM/YYYY")
          : ""}
      </div>
    );
  };

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="action" type="buttons">
            <Button
              render={(params) => (
                ((Number(props.auth.getUser().user_id) == Number(params?.data?.sales_rm_id)) || (Number(props.auth.getUser().user_id) == Number(params?.data?.created_by))) &&
                (<span
                  className="btn btn-icon btn-sm"
                  onClick={() => {
                    props.history.push({
                      pathname: `${match.url}/edit`,
                      state: { data: params.data },
                    });
                  }}
                >
                  <FiEdit size={16} className="primary" />
                </span>)
                )}
            />
 
            <Button
            render={(params) => (
              params.data.editable === "yes" ?
                  <span
                  className="btn btn-icon btn-sm"
                  onClick={() =>{
                      Swal.fire({
                        icon: 'warning',
                        text: 'Are you sure, you want to delete?',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                      }).then(result => {
                        if(result.isConfirmed){
                          props.actionBirthdayWishReacordDelete({
                              id: params.data.id,
                              "is_delete": 1
                          })
                        }
                      })
                  }}>
                    <FiTrash size={16} className="primary" />
                  </span>
                  :
                  <></>
            )} />
            
          </Column>
        );
      } else if (objColumn.dataField === "created_at") {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            cellRender={renderFormatedDate}
            visible={objColumn.visible}
          ></Column>
        );
      } else {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            visible={objColumn.visible}
          ></Column>
        );
      }
    });
    return arrColumns;
  };

  return (
    <>
      <div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-1">
              Birthday Wishes
            </h5>
          </div>
          <div className="content-header-right col-12 col-md-6 col-lg-6">
            <ul class="nav nav-pills float-right">
              <li class="nav-item">
                <button
                  className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                  onClick={() => {
                    props.history.push(`${match.url}/add`);
                  }}
                >
                  Add
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-5 ">
            <p className="content-header-title text-capitalize mb-1 font-small-3 text-right">
              <span className="text-primary">Note :</span> System will take 24
              hours to reflect the changes added.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                <div className="col-12 col-md-4">
              <div className="form-group">
                          <label className="label-control">
                            Month
                          </label>
                          <Controller
                            name="dob_month"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                id="dob_month"
                                name="dob_month"
                                dataSource={generateMonthOptions()}
                                allowFiltering={false}
                                placeholder="Select Month"
                                value={field.value}
                                fields={{ text: "text", value: "value" }}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    setBirthdayWishes([])
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionBirthdayWishList(event.itemData.value);
                                    field.onChange(event.itemData.value);
                                    setSelectedMonth(
                                      Number(event.itemData.value)
                                    );
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Month is required",
                            }}
                          />
                        </div>
              </div>
              <div className="col-12 col-md-4">
                  <button className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-2" onClick={()=>{
                       $("#progress-loader").removeClass("hidden");
                         props.actionBirthdayWishList()
                  }}>Reset</button>
              </div>
                </div>
             
              <div className="col-12 col-lg-12 col-md-12">
              <DataGrid
                  dataSource={birthdayWishes}
                  showBorders={true}
                  columnAutoWidth={true}
                  export={{
                    fileName: "Birthday Wishes",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                  }}
                >
                  <SearchPanel visible={true} />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  {renderColumns()}
                  <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                  />
                  <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                  />
                </DataGrid>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  var BirthdayWishes = null;
  var BirthdayWishReacordDelete = null

  if (
    state.BirthdayWishesReducer != null &&
    Object.keys(state.BirthdayWishesReducer).length > 0 &&
    state.BirthdayWishesReducer.constructor === Object
  ) {
    switch (state.BirthdayWishesReducer.tag) {
      case ACTION_BIRTHDAY_WISH_LIST_SUCCESS:
        BirthdayWishes = Object.assign({}, state.BirthdayWishesReducer.data);
        delete state.BirthdayWishesReducer;
        return { BirthdayWishes };
      case ACTION_BIRTHDAY_DELETE_SUCCESS:
          BirthdayWishReacordDelete = Object.assign({}, state.BirthdayWishesReducer.data);
          delete state.BirthdayWishesReducer;
          return { BirthdayWishReacordDelete }
      default:
    }
  }
  return {
    BirthdayWishes,
    BirthdayWishReacordDelete
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionBirthdayWishList, actionBirthdayWishReacordDelete }, dispatch);
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(BirthDayWishList)
);
