import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { ERROR, UNAUTHORIZED } from "../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import _ from "lodash";
import { verifyPermission } from "../../../utils";
import $ from "jquery";
import { actionWeeklyMeetings, ACTION_WEEKLY_MEETINGS_SUCCESS } from "./action";
import {
  actionWeeklyMeetingTrainings,
  ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS,
} from "./action";
import {
  actionTeamOnLeave,
  ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS,
} from "./action";
import moment from "moment";

const WeeklyMeetingDashbaord = (props) => {
  const [weeklyMeetings, setWeeklyMeetings] = useState([]);
  const [weeklyMeetingTrainings, setWeeklyMeetingTrainings] = useState([]);
  const [teamOnLeaveList, setTeamOnLeaveList] = useState([]);
  const [length, setLength] = useState(0);
  const [trainingLength, setTrainingLength] = useState(0);
  const [showMeeting, setShowMeeting] = useState(false);
  const [showWeeklyMeeting, setShowWeeklyMeeting] = useState(false);
  const [showTrainingMeeting, setShowTrainingMeeting] = useState(false);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const initialRender = () => {
    setShowWeeklyMeeting(true);
    props.actionWeeklyMeetings();
    props.actionWeeklyMeetingTrainings();
    props.actionTeamOnLeave();
  };
  useEffect(initialRender, []);

  const onWeeklyMeetingsUpdate = () => {
    const { WeeklyMeetings } = props;
    var WeeklyMeetingsResponse = Object.assign({}, WeeklyMeetings);
    if (WeeklyMeetingsResponse.result === true) {
      $("#weekly_meeting_loader").addClass("hidden");
      setWeeklyMeetings(WeeklyMeetingsResponse.response);
      setLength(Number(WeeklyMeetingsResponse.response.length));
    } else {
      switch (WeeklyMeetingsResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        case ERROR:
          setShowMeeting(true);
          break;
        default:
      }
    }
  };
  useEffect(onWeeklyMeetingsUpdate, [props.WeeklyMeetings]);

  const onWeeklyMeetingTrainingsUpdate = () => {
    const { WeeklyMeetingTrainings } = props;
    var WeeklyMeetingTrainingsResponse = Object.assign(
      {},
      WeeklyMeetingTrainings
    );
    if (WeeklyMeetingTrainingsResponse.result === true) {
      $("#weekly_meeting_loader").addClass("hidden");
      setWeeklyMeetingTrainings(WeeklyMeetingTrainingsResponse.response);
      setTrainingLength(Number(WeeklyMeetingTrainingsResponse.response.length));
    } else {
      switch (WeeklyMeetingTrainingsResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        case ERROR:
          setShowMeeting(true);
          break;
        default:
      }
    }
  };
  useEffect(onWeeklyMeetingTrainingsUpdate, [props.WeeklyMeetingTrainings]);

  const onTeamOnLeaveWidgetUpdate = () => {
    const { TeamOnLeaveWidget } = props;
    var TeamOnLeaveWidgetResponse = Object.assign({}, TeamOnLeaveWidget);
    if (TeamOnLeaveWidgetResponse.result === true) {
      setTeamOnLeaveList(TeamOnLeaveWidgetResponse.response);
    } else {
      switch (TeamOnLeaveWidgetResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        case ERROR:
          setShowMeeting(true);
          break;
        default:
      }
    }
  };
  useEffect(onTeamOnLeaveWidgetUpdate, [props.TeamOnLeaveWidget]);

  const renderTeamOnLeave = () => {
    return (
      <div>
        {teamOnLeaveList &&
          teamOnLeaveList.length > 0 &&
          teamOnLeaveList?.map((member, index) => (
            <span className="text-primary text-bold-700 cursor-pointer" key={member.user_id}
            onClick={() => {
                props.history.push({
                    pathname: '/profile',
                    state: {
                        user_id: member?.user_id,
                    }
                });
            }}
            >
              {member.full_name}
              {index < teamOnLeaveList?.length - 1 && ", "}
            </span>
          ))}
      </div>
    );
  };

  const renderAttenders = (item) => {
    const fullNames = item.map((obj) => obj.full_name);
    const result = fullNames.join(", ");
    return result;
  };

  const renderEventSection = () => {
    var arrEvents = [];
    if (weeklyMeetings != null) {
      if (weeklyMeetings != null) {
        weeklyMeetings.length > 0
          ? weeklyMeetings.forEach((meeting, indexMeeting) => {
              arrEvents.push(
                <div
                  className=" cursor-pointer "
                  key={indexMeeting}
                  onClick={() => {
                    switch (meeting.meeting_type) {
                      case "Internal":
                        props.history.push({
                          pathname: `/meetings/internal-meetings/view`,
                          state: { internal_meeting_id: meeting.id },
                        });
                        break;
                      case "Reinsurance":
                        props.history.push({
                          pathname: `/meetings/ri-meetings/view`,
                          state: { ri_meeting: meeting.id },
                        });
                        break;
                      case "Tpa":
                        props.history.push({
                          pathname: `/meetings/tpa-meetings/view`,
                          state: { tpa_meeting_id: meeting.id },
                        });
                        break;
                      case "Surveyor":
                        props.history.push({
                          pathname: `/meetings/serveyor-meetings/view`,
                          state: { serveyor_meeting_id: meeting.id },
                        });
                        break;
                      case "Lead":
                      case "Client":
                        props.history.push({
                          pathname: `/meetings/customer-meetings/view`,
                          state: { customer_meeting: meeting },
                        });
                        break;
                      case "Insurer":
                        props.history.push({
                          pathname: `/meetings/insurance-company-meetings/view`,
                          state: { insurer_branch_id: meeting.id },
                        });
                        break;
                      default:
                    }
                  }}
                >
                  <div className="card bg-blue bg-lighten-5  mb-0">
                    <div className="card-body  ">
                      <div className="row">
                        <div className="col-6 ">
                          <div className="d-flex flex-row justify-content-start align-items-center w-100">
                            <div className="flex-column">
                              <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                {meeting.day != null ? meeting.day : ""}
                              </h1>
                            </div>
                            <div className="flex-column">
                              <h6 className="font-medium-1 text-bold-500 my-0">
                                {meeting.month != null ? meeting.month : ""}
                              </h6>
                              <h6 className="font-medium-1 text-bold-500 my-0">
                                {meeting.year != null ? meeting.year : ""}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <p className="mb-0 font-small-3">
                            <b>From: </b>{" "}
                            {meeting.from_time != null ? meeting.from_time : ""}
                          </p>
                          <p className="font-small-3">
                            <b>To:</b>{" "}
                            {meeting.to_time != null ? meeting.to_time : ""}
                          </p>
                        </div>
                        <div className="col-12 border-top">
                          <p className="font-medium-1 text-bold-600 text-primary  mb-0 mt-half">
                            {meeting.company_name != null
                              ? meeting.company_name
                              : ""}
                            {meeting.company_name === null &&
                            meeting.meeting_type == "Insurer"
                              ? meeting.insurer_company_name
                              : ""}
                            {meeting.company_name === null &&
                            meeting.meeting_type == "Internal"
                              ? "Internal Meeting"
                              : ""}
                          </p>
                          <p className="font-small-3 mb-0">
                            <b> Meeting Type :</b>{" "}
                            {meeting.meeting_type != null
                              ? meeting.meeting_type === "Tpa"
                                ? _.upperCase(meeting.meeting_type)
                                : _.capitalize(meeting.meeting_type)
                              : ""}
                          </p>
                          <p className="font-small-3 mb-0">
                            <b> Agenda :</b>{" "}
                            {meeting.agenda != null ? meeting.agenda : ""}
                          </p>
                          <p className="font-small-3 mb-half">
                            <b> Accompanied details :</b>{" "}
                            {meeting.attenders != null
                              ? renderAttenders(meeting.attenders)
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : arrEvents.push(
              <div className="col-12 col-md-12 col-lg-12">
                <div className="d-flex justify-content-center">
                  <h6 className="text-bold-500 font-small-3 w-100">
                    No Data Found
                  </h6>
                </div>
              </div>
            );
      }
    }
    return arrEvents;
  };

  const renderWeeklyMeetingTraining = () => {
    var arrEvents = [];
    if (weeklyMeetingTrainings != null) {
      if (weeklyMeetingTrainings != null) {
        weeklyMeetingTrainings.length > 0
          ? weeklyMeetingTrainings.forEach((meeting, indexMeeting) => {
              arrEvents.push(
                <div
                  className=" cursor-pointer "
                  key={indexMeeting}
                  onClick={() => {
                    props.history.push({
                      pathname: `/meetings/training/view`,
                      state: { id: meeting.id },
                    });
                  }}
                >
                  <div className="card bg-blue bg-lighten-5  mb-0">
                    <div className="card-body  ">
                      <div className="row">
                        <div className="col-6 ">
                          <div className="d-flex flex-row justify-content-start align-items-center w-100">
                            <div className="flex-column">
                              <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                {meeting.day != null ? meeting.day : ""}
                              </h1>
                            </div>
                            <div className="flex-column">
                              <h6 className="font-medium-1 text-bold-500 my-0">
                                {meeting.month != null ? meeting.month : ""}
                              </h6>
                              <h6 className="font-medium-1 text-bold-500 my-0">
                                {meeting.year != null ? meeting.year : ""}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <p className="mb-0 font-small-3">
                            <b>From: </b>{" "}
                            {meeting.from_time != null ? meeting.from_time : ""}
                          </p>
                          <p className="font-small-3">
                            <b>To:</b>{" "}
                            {meeting.to_time != null ? meeting.to_time : ""}
                          </p>
                        </div>
                        <div className="col-12 border-top">
                          <p className="font-medium-1 text-bold-600 text-primary  mb-0 mt-half">
                            {meeting.training_name != null
                              ? _.upperCase(meeting.training_name)
                              : ""}
                          </p>
                          <p className="font-small-3 mb-0">
                            <b> Type of Training :</b>{" "}
                            {meeting.training_type != null
                              ? meeting.training_type
                              : ""}
                          </p>
                          <p className="font-small-3 mb-0">
                            <b> Training Mode :</b>{" "}
                            {meeting.training_mode != null
                              ? meeting.training_mode
                              : ""}
                          </p>
                          <p className="font-small-3 mb-0">
                            <b>End Date :</b>{" "}
                            {meeting.to_date != null
                              ? moment(meeting.to_date).format("DD-MM-YYYY")
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : arrEvents.push(
              <div className="col-12 col-md-12 col-lg-12">
                <div className="d-flex justify-content-center">
                  <h6 className="text-bold-500 font-small-3 w-100">
                    No Data Found
                  </h6>
                </div>
              </div>
            );
      }
    }
    return arrEvents;
  };

  const renderUI = () => {
    try {
      return (
        <>
          <div className="card dashboard-card-round shadow">
            <div
              className={`card-body px-1  ${
                weeklyMeetings.length > 4 && weeklyMeetingTrainings.length > 4
                  ? "pb-0"
                  : ""
              }`}
            >
              <h6 className=" text-black text-bold-600 mb-half">
                Your Weekly Activity Insight
              </h6>
              <div class="d-flex">
                <div class="mr-auto">
                  <div className="card-title">
                    <span
                      className={`btn font-small-3 font-weight-bold ${
                        showWeeklyMeeting === true ? "primary" : "light"
                      } font-small-3 font-weight-bold `}
                      onClick={() => {
                        setShowTrainingMeeting(false);
                        setShowWeeklyMeeting(true);
                      }}
                    >{`meeting ${"(" + length + ")"}`}</span>
                  </div>
                </div>
                <div class="p-auto">
                  <div className="card-title">
                    <span
                      className={`btn font-small-3 font-weight-bold ${
                        showTrainingMeeting === true ? "primary" : "light"
                      } font-small-3 font-weight-bold `}
                      onClick={() => {
                        setShowWeeklyMeeting(false);
                        setShowTrainingMeeting(true);
                      }}
                    >{`Hall of IVY ${"(" + trainingLength + ")"}`}</span>
                  </div>
                </div>
              </div>
              <div id="weekly_meeting_loader" className="text-center">
                <div className="spinner-border spinner-border-sm ml-half text-primary"></div>
              </div>
              {weeklyMeetings != null &&
              weeklyMeetings.length > 0 &&
              showWeeklyMeeting === true ? (
                <div className="card p-0 m-0">
                  <div className="">
                    <div className="weekly-task">
                      <Carousel
                        showDots={weeklyMeetings.length > 4 ? false : true}
                        responsive={responsive}
                        infinite={true}
                        ssr={true}
                        transitionDuration={350}
                        keyBoardControl={true}
                        arrows={weeklyMeetings.length > 4 ? true : false}
                        containerClass={`carousel-container mt-1${
                          weeklyMeetings.length < 4 ? "mb-2" : ""
                        } `}
                        itemClass="mb-0"
                        renderDotsOutside={true}
                      >
                        {renderEventSection()}
                      </Carousel>
                    </div>
                  </div>
                </div>
              ) : showWeeklyMeeting === true ? (
                <div
                  className={`text-center py-2 ${
                    $("#weekly_meeting_loader").addClass("hidden") &&
                    showMeeting != true
                      ? "hidden"
                      : ""
                  }`}
                >
                  <p className="h5 fw-bold">No Meeting Found</p>
                </div>
              ) : (
                <></>
              )}
              {weeklyMeetingTrainings != null &&
              weeklyMeetingTrainings.length > 0 &&
              showTrainingMeeting === true ? (
                <div className="card p-0 m-0">
                  <div className="">
                    <div className="weekly-task">
                      <Carousel
                        showDots={
                          weeklyMeetingTrainings.length > 4 ? false : true
                        }
                        responsive={responsive}
                        infinite={true}
                        ssr={true}
                        transitionDuration={350}
                        keyBoardControl={true}
                        arrows={
                          weeklyMeetingTrainings.length > 4 ? true : false
                        }
                        containerClass={`carousel-container mt-1${
                          weeklyMeetingTrainings.length < 4 ? "mb-2" : ""
                        } `}
                        itemClass="mb-0"
                        renderDotsOutside={true}
                      >
                        {renderWeeklyMeetingTraining()}
                      </Carousel>
                    </div>
                  </div>
                </div>
              ) : showTrainingMeeting === true ? (
                <div
                  className={`text-center py-2 ${
                    $("#weekly_meeting_loader").addClass("hidden") &&
                    showMeeting != true
                      ? "hidden"
                      : ""
                  }`}
                >
                  <p className="h5 fw-bold">No Details Found</p>
                </div>
              ) : (
                <></>
              )}
            </div>
            {weeklyMeetings != null &&
            weeklyMeetings.length > 0 &&
            showWeeklyMeeting === true ? (
              <div className="card-footer weekly-report-footer px-0">
                <div className="d-flex">
                  <div className="col-6 text-left">
                    <a
                      href=""
                      className={`card-link ${
                        verifyPermission("SHOW_TEAM") ? "show" : "hidden"
                      }`}
                      onClick={() => {
                        props.history.push({
                          pathname: "/holidays/calendar",
                          state: {
                            from: "dashboard",
                          },
                        });
                      }}
                    >
                      <u>Team Meeting</u>
                    </a>
                  </div>
                  <div className="col-6 text-right">
                    <a href="/meetings" class="card-link">
                      <u>All Meeting</u>
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="mb-1 border-top-primary pt-1">
                <div className="px-1">
              <h6 className="text-black text-bold-600 mb-half ">
              Team on Leave Today
              </h6>
              <div className="mt-2">
                {teamOnLeaveList && teamOnLeaveList.length > 0
                  ? renderTeamOnLeave()
                  :<span className=" d-flex justify-content-center fw-bold">None</span>} 
              </div>
              </div>
            </div>
          </div>
        </>
      );
    } catch (e) {
      return (
        <>
          <h1>Error Event</h1>
        </>
      );
    }
  };

  return <>{renderUI()}</>;
};
const mapStateToProps = (state) => {
  var WeeklyMeetings = null;
  var WeeklyMeetingTrainings = null;
  var TeamOnLeaveWidget = null;

  if (
    state.WeeklyMeetingReducer != null &&
    Object.keys(state.WeeklyMeetingReducer).length > 0 &&
    state.WeeklyMeetingReducer.constructor === Object
  ) {
    switch (state.WeeklyMeetingReducer.tag) {
      case ACTION_WEEKLY_MEETINGS_SUCCESS:
        WeeklyMeetings = Object.assign({}, state.WeeklyMeetingReducer.data);
        delete state.WeeklyMeetingReducer;
        return { WeeklyMeetings };
      case ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS:
        WeeklyMeetingTrainings = Object.assign(
          {},
          state.WeeklyMeetingReducer.data
        );
        delete state.WeeklyMeetingReducer;
        return { WeeklyMeetingTrainings };
      case ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS:
        TeamOnLeaveWidget = Object.assign({}, state.WeeklyMeetingReducer.data);
        delete state.WeeklyMeetingReducer;
        return { TeamOnLeaveWidget };
      default:
    }
  }
  return {
    WeeklyMeetings,
    WeeklyMeetingTrainings,
    TeamOnLeaveWidget,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionWeeklyMeetings,
      actionWeeklyMeetingTrainings,
      actionTeamOnLeave,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(WeeklyMeetingDashbaord)
);
