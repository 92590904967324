import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../component/form/error_label";
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';

const TeamMembers = (props) => {
    const [myTeamVertical, setMyTeamVertical] = useState(null)
    const [mySelectedTeam, setMySelectedTeam] = useState(null)
    const { handleSubmit, control, formState: { errors }, setValue } = useForm({});

    const initialRender = () => {
        $("#save-team-member").prop("disabled", false);
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            $("#team-member-form-loader").addClass("hidden");
            setMyTeamVertical(props?.myTeamVertical)
            setMySelectedTeam(props?.myTeamMembers)
            setValue('team_user_ids', props?.myTeamMembers);
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            $("#team-member-form-loader").addClass("hidden");
        }

        return () => {
            // Calls once component is un link / closed / hide
            setMySelectedTeam([])
            setMyTeamVertical([])
        }
    };
    useEffect(initialRender, [props.show]);

    // useEffect(() => {
    //     if (props.myTeamMembers != null) {
    //         setValue('team_user_ids', props.myTeamMembers);
    //     }
    // }, [props.myTeamMembers])

    const onSubmit = (data) => {
        // if (data != null) {
        //     data.team_user_ids = data.team_user_ids;
        //     $("#team-member-form-loader").removeClass("hidden");
        //     $("#save-team-member").prop("disabled", true);
        //     props.handleClose(data);
        // }

        if (mySelectedTeam != null) {
            props.handleClose(mySelectedTeam);
        }
    }
    return (

        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <label>Select Team Member</label>
                                        <div className="form-group">
                                            <Controller
                                                name="team_user_ids"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: myTeamVertical,
                                                                key: 'value'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        deferRendering={true}
                                                        displayExpr="label"
                                                        placeholder="Select team member"
                                                        valueExpr="value"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={5}
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        acceptCustomValue={false}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value != null && field.value.length > 0 ? field.value : mySelectedTeam}
                                                        onValueChanged={({ value, event }) => {
                                                            if ((value != null || value !== undefined) && value.length > 0) {
                                                                setMySelectedTeam(value)
                                                                field.onChange(value);
                                                            } else {
                                                                if (mySelectedTeam != null && mySelectedTeam.length > 0) {
                                                                    field.onChange("");
                                                                    setMySelectedTeam([])
                                                                }
                                                            }
                                                            if (event != null) {
                                                                if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                    field.onChange("");
                                                                    setMySelectedTeam([])
                                                                }
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please select team member'
                                                }}
                                            />
                                            {errors.team_user_ids && <ErrorLabel message={errors.team_user_ids?.message} />}
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-12 col-lg-12'>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                type='button'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    props.handleClose();

                                                }}
                                            > Cancel</button>
                                            <button
                                                id="save-team-member"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >Submit
                                                <span id="team-member-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TeamMembers