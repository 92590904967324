import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_SUBMISSION_DETAIL, API_IT_SUBMISSION_DOWNLOAD } from '../../../../../../api/constants';

export const ACTION_IT_SUBMISSION_DETAIL_LOADING = 'ACTION_IT_SUBMISSION_DETAIL_LOADING';
export const ACTION_IT_SUBMISSION_DETAIL_SUCCESS = 'ACTION_IT_SUBMISSION_DETAIL_SUCCESS';

export const ACTION_SUBMISSION_DOWNLOAD_LOADING = 'ACTION_SUBMISSION_DOWNLOAD_LOADING';
export const ACTION_SUBMISSION_DOWNLOAD_SUCCESS = 'ACTION_SUBMISSION_DOWNLOAD_SUCCESS';

export function actionItSubmissionDetail(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_SUBMISSION_DETAIL, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_SUBMISSION_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_SUBMISSION_DETAIL_LOADING, { loading: false, data }));
        });
}

export function actionItSubmissionDownload(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_SUBMISSION_DOWNLOAD, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_SUBMISSION_DOWNLOAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SUBMISSION_DOWNLOAD_LOADING, { loading: false, data }));
        });
}