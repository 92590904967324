import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from '../../../../../../component/form/error_label';

const SpecificPlacementView = (props) => {

    const [details, setDetails] = useState(null);

    const initialRender = () => {
        if (props?.location?.state?.data != null) {
            setDetails(JSON.stringify(props?.location?.state?.data));
        }
    }
    useEffect(initialRender, []);

    return (
        <>
            <ScreenTitle title="Specific Preference View" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row mt-2">
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurer Name</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.insurer_company != null ?
                                            props?.location?.state?.data?.insurer_company : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">State</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data.state != null ?
                                            props?.location?.state?.data?.state : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.address != null ?
                                            props?.location?.state?.data?.address : '-'
                                    }
                                </h4>
                            </div>

                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">City</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.city != null ?
                                            props?.location?.state?.data?.city : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.policy_type != null ?
                                            props?.location?.state?.data?.policy_type : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Department</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.department_name != null ?
                                            props?.location?.state?.data?.department_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Remark</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.remark != null ?
                                            props?.location?.state?.data?.remark : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Premium Range</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.premium_min != null && props?.location?.state?.data?.premium_max != null ?
                                            (props?.location?.state?.data?.premium_min) + '-' + (props?.location?.state?.data?.premium_max) : '-'
                                    }

                                </h4>
                            </div>
                            <div className="col-12 col-md-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Sum Insured</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.location?.state?.data != null && props?.location?.state?.data?.sum_insured_min != null && props?.location?.state?.data?.sum_insured_max != null ?
                                            (props?.location?.state?.data?.sum_insured_min) + '-' + (props?.location?.state?.data?.sum_insured_max) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-medium-1 text-justify border-top pt-1">Contact Details</h6>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    props?.location?.state?.data?.contacts != null && props?.location?.state?.data?.contacts.length > 0 ?
                                        <table className="table table-striped table-bordered w-100 table-responsive-lg">
                                            <thead>
                                                <tr>
                                                    <th>Contact Name</th>
                                                    <th>Contact Designation</th>
                                                    <th>Contact Phone</th>
                                                    <th>Contact Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props?.location?.state?.data?.contacts.map((contact, indexContact) => (
                                                        <tr key={contact?.id}>
                                                            <td>{contact?.name}</td>
                                                            <td>{contact?.designation}</td>
                                                            <td>{contact?.mobile}</td>
                                                            <td>{contact?.email}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <></>
                                }
                                <ErrorLabel message={props?.location?.state?.data?.contacts?.length === 0 ? 'No contacts found' : ''} />
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SpecificPlacementView