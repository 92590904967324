import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_PERMISSION_ROLES } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_PERMISSION_ROLES_LOADING = 'ACTION_PERMISSION_ROLES_LOADING';
export const ACTION_PERMISSION_ROLES_SUCCESS = 'ACTION_PERMISSION_ROLES_SUCCESS';

export function actionPermissionRoles() {
    return (dispatch, getState) =>
        Api.get(API_PERMISSION_ROLES).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PERMISSION_ROLES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PERMISSION_ROLES_LOADING, { loading: false, data }));
        });
}