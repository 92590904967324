/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import $ from "jquery";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionGetPost, ACTION_GET_POST_SUCCESS } from "./action";
import { ADMIN_USER, UNAUTHORIZED } from "../../../constants";
import { generateInitialLettersString, linkify } from "../../../utils";
import Swal from "sweetalert2";
import { FiThumbsUp } from "react-icons/fi";
import { clearData, getData, KEY_GLOBAL_SEARCH } from "../../../utils/preferences";
import HTMLReactParser from "html-react-parser";
import { RiChat1Line, RiSendPlane2Line } from "react-icons/ri";
import { FaHeart } from "react-icons/fa";
import LikeDetails from "../like";
import {actionActivityFeedLike, ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS} from './action'
import {actionActivityFeedCreateComment, ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS} from './action'
import {actionActivityFeedCommentLike, ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS} from './action'
import {actionActivityFeedCommentDelete, ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS} from './action'
import {actionActivityFeedCommentEdit, ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS} from './action'

const PostDetails = (props) => {

    const [postDetails, setPostDetails] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [showLikeDetails, setShowLikeDetails] = useState(false);
    const [likeDetails, setLikeDetails] = useState([]);
    const [actionPostCommentId, setActionPostCommentId] = useState(-1);

    const initialRender = () => {
        //props.actionGetPost({}); 
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("body").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            var global_search = getData(KEY_GLOBAL_SEARCH)
            if (global_search != null) {
                var objGlobalSearch = JSON.parse(global_search)
                var users = Object.assign([], objGlobalSearch.users)
                setAllUsers(users)
            }
        } else {
            $("body").removeClass("modal-open");
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    useEffect(() => {
        if(props.data != null) {
            setPostDetails(props.data)
        }
    }, [props.data])

    const onGetPostUpdate = () => {
        const { GetPost } = props;
        var GetPostResponse = Object.assign({}, GetPost);
        if (GetPostResponse.result === true) {
            setPostDetails(GetPostResponse.response);
        } else {
            switch (GetPostResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onGetPostUpdate, [props.GetPost]);

    const onActivityFeedLikeUpdate = () => {
        const {ActivityFeedLike} = props;

        const ActivityFeedLikeResponse = Object.assign({}, ActivityFeedLike)
        if(ActivityFeedLikeResponse.result === true) {
            setPostDetails(ActivityFeedLikeResponse.response)
        } else {
            switch(ActivityFeedLikeResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Session',
                        text: 'Your session has been expired. Please Login to continue'
                    }).then(success => {
                        clearData()
                        setTimeout(() => {
                            window.location.replace('/login')
                        }, 200)
                        props.auth.logout();
                    })
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedLikeUpdate, [props.ActivityFeedLike])
    
    const onActivityFeedCreateCommentUpdate = () => {
        const {ActivityFeedCreateComment} = props;

        const ActivityFeedCreateCommentResponse = Object.assign({}, ActivityFeedCreateComment)
        if(ActivityFeedCreateCommentResponse.result === true) {
            setPostDetails(ActivityFeedCreateCommentResponse.response)
            $(`#post-detail-${ActivityFeedCreateCommentResponse.response.id}`).val('')
        } else {
            switch(ActivityFeedCreateCommentResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Session',
                        text: 'Your session has been expired. Please Login to continue'
                    }).then(success => {
                        clearData()
                        setTimeout(() => {
                            window.location.replace('/login')
                        }, 200)
                        props.auth.logout();
                    })
                   
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCreateCommentUpdate, [props.ActivityFeedCreateComment])

    const onActivityFeedCommentLikeUpdate = () => {
        const {ActivityFeedCommentLike} = props;
        const ActivityFeedCommentLikeResponse = Object.assign({}, ActivityFeedCommentLike);
        if(ActivityFeedCommentLikeResponse.result === true) {
            setPostDetails(ActivityFeedCommentLikeResponse.response)
        } else {
            switch(ActivityFeedCommentLikeResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Session',
                        text: 'Your session has been expired. Please Login to continue'
                    }).then(success => {
                        clearData()
                        setTimeout(() => {
                            window.location.replace('/login')
                        }, 200)
                        props.auth.logout();
                    })
                   
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCommentLikeUpdate, [props.ActivityFeedCommentLike])

    const onActivityFeedCommentDeleteUpdate = () => {
        const {ActivityFeedCommentDelete} = props;
        const ActivityFeedCommentDeleteResponse = Object.assign({}, ActivityFeedCommentDelete);
        if(ActivityFeedCommentDeleteResponse.result === true) {
            setPostDetails(ActivityFeedCommentDeleteResponse.response)
        } else {
            switch(ActivityFeedCommentDeleteResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Session',
                        text: 'Your session has been expired. Please Login to continue'
                    }).then(success => {
                        clearData()
                        setTimeout(() => {
                            window.location.replace('/login')
                        }, 200)
                        props.auth.logout();
                    })
                   
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCommentDeleteUpdate, [props.ActivityFeedCommentDelete])

    const onActivityFeedCommentEditUpdate = () => {
        const {ActivityFeedCommentEdit} = props;
        const ActivityFeedCommentEditResponse = Object.assign({}, ActivityFeedCommentEdit);
        if(ActivityFeedCommentEditResponse.result === true) {
            setPostDetails(ActivityFeedCommentEditResponse.response)
            $(`#post-comment-edit-view-${actionPostCommentId}`).addClass('hidden')
            $(`#post-comment-view-${actionPostCommentId}`).removeClass('hidden')
            setActionPostCommentId(-1)
        } else {
            setActionPostCommentId(-1)
            switch(ActivityFeedCommentEditResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Session',
                        text: 'Your session has been expired. Please Login to continue'
                    }).then(success => {
                        clearData()
                        setTimeout(() => {
                            window.location.replace('/login')
                        }, 200)
                        props.auth.logout();
                    })
                   
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCommentEditUpdate, [props.ActivityFeedCommentEdit])

    const renderPostImage = (images) => { 

        // var arrImages = images.map(image => image.full_url);

        // console.log("ARR IMAGES" + arrImages);

        return ( 
            <Carousel
                showThumbs={false}
                showIndicators={false}
                infiniteLoop={true}
                autoPlay={false}
                dynamicHeight={true}
                showStatus={false}
                showArrows={true}
                useKeyboardArrows={true}
                swipeable={true}
                emulateTouch={true}
                className="bg-blue-grey bg-darken-4"
            >
                {
                    images.map((image, indexImage) => (
                        <div style={{height: '100vh', width: '100%'}} key={`image-${indexImage}`}>
                            <img 
                                src={image.full_url} alt="" 
                                style={{objectFit: 'contain'}} 
                                width="80%" 
                                height="80%" 
                            />
                        </div>
                    ))
                }
            </Carousel>
        )
    }

    const renderTags = (tags) => {
        var arrTags = []
        if(tags.length === 1) {
            arrTags.push(
                <button key={`btn-tag-${0}`} type="button" class="btn text-primary text-bold-700 p-0" onClick={() => { props.history.push({pathname: '/profile', state: {user_id: tags[0].user_id}}) }}>
                    @{tags[0].full_name}
                </button>
            )
        } else {
            tags.forEach((tag, tagIndex) => {
               arrTags.push(
                    <button key={`btn-tag-${tagIndex}`} type="button" class="btn text-primary text-bold-700 mr-half p-0" onClick={() => { props.history.push({pathname: '/profile', state: {user_id: tag.user_id}}) }}>
                        @{tag.full_name}
                    </button>
               )
            })
        }
        return arrTags
    }

    const renderPostContent = (postContent,activity_id,activity) => {
        var formatText = postContent
        // PRINT('selected Tags', selectedTagUserText)
        if(allUsers != null && allUsers.length > 0) {
            allUsers.forEach((user, indexUser) => {
                var indexOfUser = String(formatText).indexOf(`##${user.id}:${user.name}##`)
                // PRINT('index user', indexOfUser)
                if(indexOfUser > -1) {
                    formatText = formatText.replace(`##${user.id}:${user.name}##`, `<button id=${user.id} type="button" class='btn text-primary text-bold-700 p-0 btn-post-tag'>@${user.name}</button>`)
                }
            })
        }
        // if(formatText.indexOf(activity.meta_url) > -1) {
        //     formatText = formatText.replace(activity.meta_url,`<a href=${activity.meta_url} target='_blank' class='text-primary'>${activity.meta_url}</a>`)
        // }
        if(formatText.length > 300){
            // var subString1 = formatText.substring(0,300)
            var subString1 = formatText && formatText.length > 300 ? formatText.slice(0,300).split(' ').slice(0, -1).join(' ') : formatText;
            // subString1 += ' ';
            var subString2 = `<span id='post-str-${activity_id}' class="hidden">${formatText.substring(subString1.length,formatText.length)}</span>`
            var readMore = `<button id='btn-post-str-${activity_id}' class="d-inline btn btn-sm px-0 text-primary text-bold-600 btn-post-str">Read more</button>`
            var mainString = subString1.concat(readMore)
            mainString = mainString.concat(subString2)
            mainString = linkify(mainString)
            return HTMLReactParser(String(mainString).trim())
        } else {
            formatText = linkify(formatText)
        }
        return HTMLReactParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n','<br>'))
    }

    return (
    
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog-lg" role="document">
                <div className="modal-content vh-100">
                    <div className="modal-header hidden">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Posts
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                var objPost = Object.assign({}, postDetails)
                                props.handleClose(objPost);
                                $("body").removeClass("modal-open");
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll p-0" style={{ overflow: "scroll" }}>
                        <div className="row">
                            
                            <div className="d-flex flex-column justify-content-center col-12 col-md-12 col-lg-8 bg-blue-grey bg-darken-4" style={{height: '100vh'}}>
                                {
                                    postDetails != null && postDetails.images != null && postDetails.images.length > 0 ?
                                    <div className="post-images">
                                        {renderPostImage(postDetails.images)}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className="col-12 col-md-12 col-lg-4" style={{
                                height: '100vh',
                                overflow: 'hidden',
                                overflowY: 'scroll'
                            }}>
                                <button
                                    type="button"
                                    className="btn float-right position-fixed mr-1"
                                    style={{
                                        right: 0
                                    }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        var objPost = Object.assign({}, postDetails)
                                        props.handleClose(objPost);
                                    }}
                                >
                                    <span aria-hidden="true"  style={{fontSize: 24}}>&times;</span>
                                </button>
                                {
                                    postDetails != null ?
                                    <>
                                        <div className="d-flex flex-row">
                                            <div className="flex-column">
                                                {
                                                    (postDetails.profile_pic != null && postDetails.profile_pic.length > 0) ?
                                                        <img
                                                            src={postDetails.profile_pic}
                                                            className="rounded-circle img-border cursor-pointer"
                                                            alt="Card"
                                                            width={50}
                                                            height={50}
                                                            style={{}}
                                                            onClick={() => {
                                                                props.history.push({pathname: '/profile', state: {user_id: postDetails.user_id}})
                                                            }}
                                                        />
                                                        :
                                                        <>
                                                            <div
                                                                className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3 cursor-pointer"
                                                                style={{ height: 50, width: 50 }}
                                                                onClick={() => {
                                                                    props.history.push({pathname: '/profile', state: {user_id: postDetails.user_id}})
                                                                }}
                                                            >
                                                                {postDetails.initial_name}
                                                            </div>
                                                        </>
                                                }
                                                <img
                                                    src="/assets/images/avatar-s-8.png"
                                                    class="rounded-circle img-border height-50 hidden"
                                                    alt="Card"
                                                />
                                            </div>
                                            <div className="flex-column align-items-center ml-1 pt-half">
                                                <h6 className="font-medium-1 py-0 text-bold-600 text-black mt-half mb-0 cursor-pointer"
                                                    onClick={() => {
                                                        props.history.push({pathname: '/profile', state: {user_id: postDetails.user_id}})
                                                    }}
                                                >
                                                    {postDetails.full_name != null ? postDetails.full_name : '-'}
                                                </h6>
                                                <h6 className="font-small-3 py-0 my-0 text-bold-400 mt-half">{postDetails.timeSince}</h6>
                                            </div>
                                        </div>
                                        {
                                            postDetails.content != null ?
                                            <div className="px-2">
                                                <p>{postDetails.content != null ? renderPostContent(postDetails.content,postDetails.id,postDetails) : ''}</p>
                                            </div>
                                            :
                                            <></>
                                        }
                                        {
                                            postDetails.meta_url != null  ?
                                            <div className="d-flex flex-row p-half rounded bg-white border m-half">
                                                <div className="flex-column mr-half">
                                                    <img 
                                                        src={postDetails.meta_image} 
                                                        style={{objectFit: 'cover'}}
                                                        height="60"
                                                        width="100"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="flex-column bg-blue-grey bg-lighten-5 p-half">
                                                    <h5 className="text-black font-medium-2 text-bold-500">
                                                        {postDetails.meta_title}
                                                    </h5>
                                                    <p>
                                                        {postDetails.meta_description}
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        {
                                            postDetails.tags != null && postDetails.tags.length > 0 ?
                                            <div className="px-2 mb-1">
                                                - with {renderTags(postDetails.tags)}
                                            </div>
                                            :
                                            <></>
                                        }
                                        <div className="row">
                                            <div className="col-auto px-0">
                                                <button type="button" className="btn d-flex align-items-center">
                                                    <FiThumbsUp
                                                        size={24}
                                                        className={`${Number(postDetails.is_liked_post) === 0 ? 'blue-grey lighten-1' : 'text-primary'}`}
                                                        onClick={() => {
                                                            if(Number(postDetails.is_liked_post) === 0) {
                                                                // setActionPostIndex(indexActivity)
                                                                props.actionActivityFeedLike({
                                                                    post_id: postDetails.id,
                                                                    user_id: props.auth.getUser().user_id,
                                                                    like: true
                                                                })
                                                            } else {
                                                                // setActionPostIndex(indexActivity)
                                                                props.actionActivityFeedLike({
                                                                    post_id: postDetails.id,
                                                                    user_id: props.auth.getUser().user_id,
                                                                    like: false
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <span className="ml-half blue-grey lighten-1">
                                                        <h6 className="mb-0" onClick={() => {
                                                            if(postDetails.likes != null && postDetails.likes.length > 0) {
                                                                setLikeDetails(postDetails.likes);    
                                                                setShowLikeDetails(true)
                                                            }
                                                        }}> {postDetails.likes != null ? postDetails.likes.length === 1 ? `1 Like` : `${postDetails.likes.length} Likes` : 0}
                                                        </h6>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="col-auto px-0">
                                                <button type="button" className="btn d-flex align-items-center">
                                                    <RiChat1Line
                                                        size={24} 
                                                        className={`${Number(postDetails.is_own_comment) === 0 ? 'blue-grey lighten-1' : 'blue-grey lighten-1'}`} 
                                                    />
                                                    <span className="ml-half blue-grey lighten-1">
                                                        {postDetails.comments != null ? postDetails.comments.length === 1 ? `1 Comment` : `${postDetails.comments.length} Comments` : 0}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            postDetails.comments != null && postDetails.comments.length > 0 ?
                                            <div className="activity-comments mb-1" key={`comment-section`}>
                                                {/* {
                                                    postDetails.comments != null && postDetails.comments.length > 3 ?
                                                    <div className={`d-flex flex-row view-previous-comment-${postDetails.id}`}>
                                                        <div className="flex-row ">
                                                            <button type="button" className="btn px-0 text-bold-600"
                                                                onClick={() => {
                                                                    $(`.activity-comment-${postDetails.id}`).removeClass('d-none').addClass('d-flex')
                                                                    $(`.view-previous-comment-${postDetails.id}`).addClass('d-none').removeClass('d-flex')
                                                                }}
                                                            >
                                                                View Previous Comments
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                                } */}
                                                {
                                                    postDetails.comments.map((comment, indexComment) => (
                                                        // <div className={`flex-row mb-1 activity-comment-${postDetails.id} ${(indexComment > 2 && $(`.view-previous-comment-${postDetails.id}`).hasClass('d-flex') ) ? 'd-none' : 'd-flex'} ${indexComment === 0 ? 'mt-1' : ''}`} key={indexComment}>
                                                        <div className={`d-flex flex-row mb-1 activity-comment-${postDetails.id} ${indexComment === 0 ? 'mt-1' : ''}`} key={indexComment}>
                                                            <div className="flex-column">
                                                                {
                                                                    (comment.profile_pic != null && comment.profile_pic.length > 0) ?
                                                                        <img
                                                                            src={comment.profile_pic}
                                                                            className="rounded-circle img-border cursor-pointer"
                                                                            alt="Card"
                                                                            width={50}
                                                                            height={50}
                                                                            style={{ }}
                                                                            onClick={() => {
                                                                                props.history.push({pathname: '/profile', state: {user_id: comment.user_id}})
                                                                            }}
                                                                        />
                                                                        :
                                                                        <>
                                                                            <div
                                                                                className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3 cursor-pointer"
                                                                                style={{ height: 50, width: 50 }}
                                                                                onClick={() => {
                                                                                    props.history.push({pathname: '/profile', state: {user_id: comment.user_id}})
                                                                                }}
                                                                            >
                                                                                {comment.initial_name}
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-column w-100">
                                                                <div id={`post-comment-view-${comment.id}`} className="bg-blue-grey bg-lighten-5 rounded p-half ml-1">
                                                                    <h1 className="font-small-3 text-bold-600 text-black mb-half cursor-pointer"
                                                                        onClick={() => {
                                                                            props.history.push({pathname: '/profile', state: {user_id: comment.user_id}})
                                                                        }}
                                                                    >
                                                                        {comment.full_name != null ? comment.full_name : '-'}
                                                                    </h1>
                                                                    <p className="font-small-3 text-bold-400 mb-0">{comment.comment != null ? HTMLReactParser(linkify(comment.comment)) : '-'}</p>
                                                                    {
                                                                        comment.likes != null && comment.likes.length > 0 ?
                                                                        <div className="badge bg-white shadow text-black round px-half float-right mr-half cursor-pointer" style={{marginTop: -5}}
                                                                            onClick={() => {
                                                                                if(comment.likes != null && comment.likes.length > 0) {
                                                                                    setLikeDetails(comment.likes);    
                                                                                    setShowLikeDetails(true)
                                                                                }
                                                                            }}
                                                                        >
                                                                            <FaHeart color={'#ff084a'} size={12} className="mr-half"/>
                                                                            <span className="font-small-1 text-bold-600">{comment.likes.length}</span>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>
                                                                <div id={`post-comment-edit-view-${comment.id}`} className="hidden">
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <div className="flex-column w-100">
                                                                            <fieldset className="form-group mb-0 mx-auto w-100">
                                                                                <input 
                                                                                    onKeyPress={(event)=>{
                                                                                        if (event.key === 'Enter') {
                                                                                            if (event.target.value.length > 0) {
                                                                                                setActionPostCommentId(comment.id)
                                                                                                props.actionActivityFeedCommentEdit({
                                                                                                    post_id: postDetails.id,
                                                                                                    id: comment.id,
                                                                                                    comment: $(`#post-detail-comment-edit-${comment.id}`).val()
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    id={`post-detail-comment-edit-${comment.id}`} 
                                                                                    className="form-control round" 
                                                                                    placeholder="write comment here" 
                                                                                />
                                                                            </fieldset>
                                                                        </div>
                                                                        <div className="flex-column ml-auto">
                                                                            <RiSendPlane2Line
                                                                                size={24}
                                                                                className="ml-half blue-grey lighten-1"
                                                                                onClick={() => {
                                                                                    // alert($(`#post-${activity.id}`).val())
                                                                                    if($(`#post-detail-comment-edit-${comment.id}`).val() != null && $(`#post-detail-comment-edit-${comment.id}`).val().length > 0) {
                                                                                        // setActionPostIndex(indexActivity)
                                                                                        setActionPostCommentId(comment.id)
                                                                                        props.actionActivityFeedCommentEdit({
                                                                                            post_id: postDetails.id,
                                                                                            id: comment.id,
                                                                                            comment: $(`#post-detail-comment-edit-${comment.id}`).val()
                                                                                        })
                                                                                    } else {
                                                                                        Swal.fire({
                                                                                            title: 'Post Comment',
                                                                                            text: 'Please enter comment',
                                                                                            icon: 'error'
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <button type="button" className="btn text-primary"
                                                                        onClick={() => {
                                                                            $(`#post-comment-edit-view-${comment.id}`).addClass('hidden')
                                                                            $(`#post-comment-view-${comment.id}`).removeClass('hidden')
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                                <div className="d-flex flex-row ml-1 align-items-center">
                                                                    <div className="flex-column flex-column font-small-2 text-light">{comment.timeSince}</div>
                                                                    <div className="flex-column">
                                                                        <button
                                                                            type="button"
                                                                            className="btn text-bold-400 text-black"
                                                                            onClick={()=>{
                                                                                var is_like_comment = false
                                                                                if(comment.likes != null && comment.likes.length > 0) {
                                                                                    var indexLike = comment.likes.findIndex((like, index) => like.like.user_id === props.auth.getUser().user_id)
                                                                                    if(indexLike > -1) {
                                                                                        is_like_comment = true
                                                                                    }
                                                                                }
                                                                                if(!is_like_comment) {
                                                                                    props.actionActivityFeedCommentLike({
                                                                                        post_id: postDetails.id,
                                                                                        comment_id: comment.id,
                                                                                        like: true
                                                                                    })
                                                                                } else {
                                                                                    props.actionActivityFeedCommentLike({
                                                                                        post_id: postDetails.id,
                                                                                        comment_id: comment.id,
                                                                                        like: false
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            {(comment.likes != null && comment.likes.length > 0 && comment.likes.findIndex((like, index) => like.like.user_id === props.auth.getUser().user_id) > -1) ? 'Unlike' : 'Like'}
                                                                        </button>
                                                                    </div>
                                                                    {
                                                                        comment.user_id === props.auth.getUser().user_id ? 

                                                                        <div className="flex-column">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn text-bold-400 text-black pl-0"
                                                                                    onClick={() => {
                                                                                        $(`#post-comment-edit-view-${comment.id}`).removeClass('hidden')
                                                                                        $(`#post-detail-comment-edit-${comment.id}`).val(comment.comment)
                                                                                        $(`#post-comment-view-${comment.id}`).addClass('hidden')            
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                    
                                                                    }
                                                                    {
                                                                        ( comment.user_id === props.auth.getUser().user_id || props.auth.getUser().user_id === ADMIN_USER )? 
                                                                       
                                                                            <div className="flex-column">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn text-bold-400 text-black pl-0"
                                                                                    onClick={() => {
                                                                                        Swal.fire({
                                                                                            title: 'Delete Post Comment',
                                                                                            text: 'Are you sure, you want to delete comment?',
                                                                                            showCancelButton:true,
                                                                                            cancelButtonText: 'No',
                                                                                            showConfirmButton: true,
                                                                                            confirmButtonText: 'Yes',
                                                                                        }).then(result => {
                                                                                            if(result.isConfirmed) {
                                                                                                props.actionActivityFeedCommentDelete({
                                                                                                    id: comment.id,
                                                                                                    post_id: postDetails.id,
                                                                                                })
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </button>
                                                                            </div>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <></>
                                        }
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="flex-column">
                                                        {
                                                            (props.auth.getUser().profile_photo != null && props.auth.getUser().profile_photo.length > 0) ?
                                                                <img
                                                                    src={props.auth.getUser().profile_photo}
                                                                    className="rounded-circle img-border"
                                                                    alt="Card"
                                                                    width={50}
                                                                    height={50}
                                                                    style={{ border: '5px solid #FFF' }}
                                                                />
                                                                :
                                                                <>
                                                                    <div
                                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3"
                                                                        style={{ border: '5px solid #FFF', height: 50, width: 50 }}
                                                                    >
                                                                        {generateInitialLettersString(props.auth.getUser().first_name + ' ' + props.auth.getUser().last_name)}
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="flex-column w-100">
                                                        <div className="d-flex flex-row align-items-center mr-1">
                                                            <div className="flex-column w-100">
                                                                <fieldset className="form-group mb-0 mx-auto w-100">
                                                                    <input 
                                                                        id={`post-detail-${postDetails.id}`} 
                                                                        className="form-control round" 
                                                                        placeholder="write comment here" 
                                                                        maxLength={5000}
                                                                        autoComplete={false}
                                                                        onKeyPress={(event)=>{
                                                                            if (event.key === 'Enter') {
                                                                                if (event.target.value.length > 0) {
                                                                                    props.actionActivityFeedCreateComment({
                                                                                        post_id: postDetails.id,
                                                                                        comment: $(`#post-detail-${postDetails.id}`).val()
                                                                                    })
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </fieldset>
                                                            </div>
                                                            <div className="flex-column ml-auto">
                                                                <RiSendPlane2Line
                                                                    size={24}
                                                                    className="ml-half blue-grey lighten-1"
                                                                    onClick={() => {
                                                                        // alert($(`#post-${activity.id}`).val())
                                                                        if($(`#post-detail-${postDetails.id}`).val() != null && $(`#post-detail-${postDetails.id}`).val().length > 0) {
                                                                            // setActionPostIndex(indexActivity)
                                                                            props.actionActivityFeedCreateComment({
                                                                                post_id: postDetails.id,
                                                                                comment: $(`#post-detail-${postDetails.id}`).val()
                                                                            })
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Post Comment',
                                                                                text: 'Please enter comment',
                                                                                icon: 'error'
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LikeDetails
                show={showLikeDetails}
                data={likeDetails}
                handleClose={() => {
                    setLikeDetails([])
                    setShowLikeDetails(false)
                }}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    var GetPost = null;
    var ActivityFeedLike = null;
    var ActivityFeedCreateComment = null;
    var ActivityFeedCommentLike = null;
    var ActivityFeedCommentDelete = null;
    var ActivityFeedCommentEdit = null;
   
    if (
        state.GetPostReducer != null &&
        Object.keys(state.GetPostReducer).length > 0 &&
        state.GetPostReducer.constructor === Object
    ) {
        switch (state.GetPostReducer.tag) { 
            case ACTION_GET_POST_SUCCESS :
                GetPost = Object.assign({}, state.GetPostReducer.data);
                delete state.GetPostReducer;
                return { GetPost };
            case ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS :
                ActivityFeedLike = Object.assign({}, state.GetPostReducer.data);
                delete state.GetPostReducer;
                return { ActivityFeedLike };
            case ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS:
                ActivityFeedCreateComment = Object.assign({}, state.GetPostReducer.data);
                delete state.GetPostReducer;
                return { ActivityFeedCreateComment };
            case ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS:
                ActivityFeedCommentLike = Object.assign({}, state.GetPostReducer.data);
                delete state.GetPostReducer;
                return { ActivityFeedCommentLike };
            case ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS:
                ActivityFeedCommentDelete = Object.assign({}, state.GetPostReducer.data);
                delete state.GetPostReducer;
                return { ActivityFeedCommentDelete };
            case ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS:
                ActivityFeedCommentEdit = Object.assign({}, state.GetPostReducer.data);
                delete state.GetPostReducer;
                return { ActivityFeedCommentEdit };
            default:
        }
    }
    return {
        GetPost,
        ActivityFeedLike,
        ActivityFeedCreateComment,
        ActivityFeedCommentLike,
        ActivityFeedCommentDelete,
        ActivityFeedCommentEdit,
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionGetPost,
            actionActivityFeedLike,
            actionActivityFeedCreateComment,
            actionActivityFeedCommentLike,
            actionActivityFeedCommentDelete,
            actionActivityFeedCommentEdit,
        },
        dispatch
    );
  };
  
  export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PostDetails))