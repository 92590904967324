import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiPhone, FiMail, FiEdit } from 'react-icons/fi';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import dateFormat from 'dateformat';
import { UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { actionCustomerMeetingsView, ACTION_CUSTOMER_MEETING_VIEW_SUCCESS } from './action';
import { generateInitialLettersString, linkify } from '../../../../../../utils';
import HTMLParser from 'html-react-parser';
import moment from 'moment';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const CustomerMeetingView = (props) => {

    const [customerMeetingsDetails, setCustomerMeetingsDetails] = useState(null);
    const [accompaniedDetails, setAccompaniedDetails] = useState([]);
    const [policyDetails, setPolicyDetails] = useState([]);
    const [contactDetails, setContactDetails] = useState([])
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (props.location.state !== undefined || id != null) {
            props.actionCustomerMeetingsView({ id: props.location?.state?.customer_meeting.id != undefined ? props.location?.state?.customer_meeting.id : id });
        } else {
            props.history.goBack();
        }
    }
    useEffect(initialRender, []);

    const onCustomerMeetingsViewUpdate = () => {
        const { CustomerMeetingsView } = props;
        var CustomerMeetingsViewResponse = Object.assign({}, CustomerMeetingsView);
        if (CustomerMeetingsViewResponse.result === true) {
            setCustomerMeetingsDetails(CustomerMeetingsViewResponse.response);
            setAccompaniedDetails(CustomerMeetingsViewResponse.response.accompany_by);
            setPolicyDetails(CustomerMeetingsViewResponse.response.policy_details);
            setContactDetails(CustomerMeetingsViewResponse.response.meeting_contacts);
        } else {
            switch (CustomerMeetingsViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerMeetingsViewUpdate, [props.CustomerMeetingsView]);

    const renderMeetingLocation = (location) => {
        try {
            var formatText = location
            if (customerMeetingsDetails != null && customerMeetingsDetails.location != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderMeetingAgenda = (agenda) => {
        try {
            var formatText = agenda
            if (customerMeetingsDetails != null && customerMeetingsDetails?.agenda != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderMinutesOfsMeeting = (minutes_of_meeting) => {
        try {
            var formatText = minutes_of_meeting
            if (customerMeetingsDetails != null && customerMeetingsDetails?.minutes_of_meeting != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderRemark = (remark) => {
        try {
            var formatText = remark
            if (customerMeetingsDetails != null && customerMeetingsDetails.remark != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }


    const renderAccompanied = () => {
        var arrAccompanied = []
        if (accompaniedDetails != null && accompaniedDetails.length > 0) {
            accompaniedDetails.forEach((contact, indexContact) => {
                arrAccompanied.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row mb-1">
                                <div className="flex-column mr-half">
                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer"
                                     onClick={() => {
                                        props.history.push({
                                            pathname: '/profile',
                                            state: {
                                                user_id: contact?.user_id,
                                            }
                                        });
                                    }}
                                    >{contact.name}</p>
                                    {/* <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{contact.name}</p> */}
                                    <div class="font-small-3 text-bold-700">{contact.designation_name}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.mobile_no != null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.mobile_no != null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.mobile_no}</div>
                                        </div>
                                        <div className={`flex-column ${contact.email != null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.email != null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrAccompanied
    }


    const renderContactDetails = () => {
        var arrContactDetails = []
        if (contactDetails != null && contactDetails.length > 0) {
            contactDetails.forEach((contact, indexContact) => {
                arrContactDetails.push(
                    <div class="col-12 col-md-6 px-0 mb-1 border-bottom">
                        <div className="">
                            <div className="d-flex flex-row mb-1">
                                <div className="flex-column mr-half">
                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.contact_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{contact.contact_name}</p>
                                    <div class="font-small-3 text-bold-700">{contact.contact_designation}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrContactDetails
    }

    const renderPolicyDetails = () => {
        var arrPolicyDetails = []
        if (policyDetails != null && policyDetails.length > 0) {
            policyDetails.forEach((policy, indexPolicy) => {
                arrPolicyDetails.push(
                    <div class="col-12 col-md-6 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row pb-half">
                                <div className="flex-column mr-half">
                                    {
                                        policy?.insurer_id != null ?
                                            <img src={`/assets/images/insurance_companies/${policy?.insurer_id}.png`} className="rounded-circle" style={{ width: 50, height: 50, objectFit: 'cover' }} alt="" />
                                            :
                                            <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-3" style={{ width: 50, height: 50 }}>
                                                <IoShieldCheckmarkOutline size={30} />
                                            </div>
                                    }

                                </div>
                                <div className="flex-column">
                                    <p className="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{policy?.oppurtunity_type != 'Fresh' ? policy?.insurer_name : ''}<div className="badge badge-success bg-accent-1 round font-small-2 px-1 ml-half">{policy?.policy_type}</div></p>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Opportunity Type: </span>{policy?.oppurtunity_type}</div>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Department: </span>{policy?.policy}</div>
                                    <div className={`font-small-3 text-bold-700  ${policy?.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}><span className={`text-secondary text-bold-500 mr-half`}>Next Renewal on</span>{policy?.lead_expiry_date !== null && moment(policy?.lead_expiry_date).isValid() ? moment(policy?.lead_expiry_date).format('MMM, DD YYYY') : '-'}</div>
                                    <div className={`font-small-3 text-bold-700 ${policy?.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}><span className="text-secondary text-bold-500 mr-half">Intermediary Type: </span>{policy?.intermediary_type}</div>
                                    <div className={`font-small-3 text-bold-700 ${policy?.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}><span className="text-secondary text-bold-500 mr-half">Intermediary Details: </span>{policy?.intermediary_value}</div>
                                    <div className={`font-small-3 text-bold-700 ${policy?.client_remark != null && policy?.client_remark != "" ? "" : "hidden"}`}><span className="text-secondary text-bold-500 mr-half">Remark: </span>{policy?.client_remark}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrPolicyDetails
    }

    return (
        <>
        <div className='row justify-content-between '>
                <div className='col-md-6'>
                <ScreenTitle title="Customer Meeting" />
                </div>
                {
                    customerMeetingsDetails?.status?.toLowerCase() ==='open' &&
                    <div className='col-md-6 text-right'>
                    <a onClick={() => {props.history.push({ pathname: `/meetings/customer-meetings/edit`, state: { customer_meeting: customerMeetingsDetails} })}}>
                    <FiEdit size={16} className="primary" /></a>
                </div> 
                }
                
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="flex-column">
                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                            {
                                                customerMeetingsDetails != null ?
                                                    customerMeetingsDetails.day : ''
                                            }
                                        </h1>
                                    </div>
                                    <div className="flex-column">
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                customerMeetingsDetails != null ?
                                                    customerMeetingsDetails.month : ''
                                            }
                                        </h6>
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                customerMeetingsDetails != null ?
                                                    customerMeetingsDetails.year : ''
                                            }
                                        </h6>
                                    </div>
                                    {/* <div className="d-flex d-md-none d-lg-none flex-column ml-auto">
                                        <div className="btn btn-outline-success btn-sm px-1">
                                            <h6 className="font-small-3 text-bold-500 my-0">
                                                {
                                                    customerMeetingsDetails != null ?
                                                        _.upperFirst(customerMeetingsDetails.status) : ''
                                                }
                                            </h6>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-6 col-md-6 col-lg-6 text-right">
                                <div className={`btn btn-sm px-1 ${customerMeetingsDetails != null && (customerMeetingsDetails.status === 'close' || customerMeetingsDetails.status === 'cancel') ? 'btn-outline-danger' : 'btn-outline-success'}`}>
                                    <h6 className="font-small-3 text-bold-500 my-0">
                                        {
                                            customerMeetingsDetails != null ?
                                                _.upperFirst(customerMeetingsDetails.status) : ''
                                        }
                                    </h6>
                                </div>
                               
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mt-1">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            customerMeetingsDetails != null ?
                                                customerMeetingsDetails.from_time : ''
                                        }
                                    </div>
                                    <div className="flex-column px-1">to</div>
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            customerMeetingsDetails != null ?
                                                customerMeetingsDetails.to_time : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-12 col-md-4 col-lg-4 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Customer</h6>
                                <h4 className="font-small-3 text-bold-700 text-justify text-primary cursor-pointer"
                                    onClick={(() => {
                                        if (customerMeetingsDetails.meeting_type === 'Lead') {
                                            props.history.push({
                                                pathname: '/leads/view',
                                                state: {
                                                    client_id: customerMeetingsDetails?.client_id,
                                                }
                                            });
                                        } else if (customerMeetingsDetails.meeting_type === 'Client') {
                                            props.history.push({
                                                pathname: '/client/view',
                                                state: {
                                                    client_id: customerMeetingsDetails?.client_id,
                                                }
                                            });
                                        }
                                    })}
                                >
                                    {
                                        customerMeetingsDetails != null ?
                                            customerMeetingsDetails.client_name : ''
                                    }

                                </h4>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting With</h6>
                                <h4 className="font-small-3 text-bold-700 text-justify">
                                    {
                                        customerMeetingsDetails != null ?
                                            _.capitalize(customerMeetingsDetails.meeting_type) : '-'
                                    }
                                </h4>
                            </div>
                            <div className={`col-12 col-md-4 col-lg-4  mb-1 ${customerMeetingsDetails != null && customerMeetingsDetails.business_type != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Type</h6>
                                <h4 className="font-small-3 text-bold-700 text-justify">
                                    {
                                        customerMeetingsDetails != null ?
                                            customerMeetingsDetails.business_type : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Agenda</h6>
                                <h4 className="font-small-3 text-bold-700 text-justify">
                                    {
                                        customerMeetingsDetails != null ?
                                            renderMeetingAgenda(customerMeetingsDetails?.agenda) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Location</h6>
                                <h4 className="font-small-3 text-bold-700 text-justify">
                                    {
                                        renderMeetingLocation(customerMeetingsDetails?.location)
                                    }
                                </h4>
                            </div>
                            <div className={`col-12 col-md-4 col-lg-4 mb-1 ${customerMeetingsDetails != null && customerMeetingsDetails.minutes_of_meeting != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Minutes Of Meeting</h6>
                                <h4 className="font-small-3 text-bold-700 text-justify">
                                    {
                                        renderMinutesOfsMeeting(customerMeetingsDetails?.minutes_of_meeting)
                                    }
                                </h4>
                            </div>
                            <div className={`col-12   mb-1 ${customerMeetingsDetails != null && customerMeetingsDetails.remark != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Remarks</h6>
                                <h4 className="font-small-3 text-bold-700 text-justify">
                                    {
                                        renderRemark(customerMeetingsDetails?.remark)
                                    }
                                </h4>
                            </div>
                            {
                                customerMeetingsDetails !== null && customerMeetingsDetails.status === 'close' ?
                                    <div className={`col-12 col-md-12 col-lg-12 mb-1`}>
                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Converted To</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                            {
                                                customerMeetingsDetails != null && (customerMeetingsDetails.converted_to !== null) ?
                                                    _.capitalize(customerMeetingsDetails.converted_to) : 'None'
                                            }
                                        </h4>
                                    </div>
                                    :
                                    <></>
                            }
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Document</h6>
                                {
                                    (customerMeetingsDetails != null && customerMeetingsDetails.meeting_document != null) ?
                                        // <a target="_blank" href={customerMeetingsDetails.meeting_document} download="Meeting Document">Click Here</a> : <>-</>
                                        <>
                                            <a className="primary  font-small-3 text-bold-700"
                                                onClick={() => {
                                                    if (customerMeetingsDetails.meeting_document?.split('.').pop() == 'pdf' ||
                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'PDF' ||
                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'png' ||
                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'jpg' ||
                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'jpeg' ||
                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'gif'
                                                    ) {
                                                        setSelectedDocumentIndex(customerMeetingsDetails.meeting_document)
                                                        setShowDocumentDetails(true);
                                                        setExtension(customerMeetingsDetails.meeting_document?.split('.').pop())
                                                        setDocumentDetails(customerMeetingsDetails)
                                                    } else {
                                                        downloadFile(customerMeetingsDetails.meeting_document);
                                                    }
                                                }}
                                            >
                                               <u>Click Here</u> 
                                            </a>
                                            <a id="downloadLink" className="d-none"></a>
                                        </>
                                        :
                                        <>-</>
                                }
                            </div>
                        </div>
                        {
                            customerMeetingsDetails != null && customerMeetingsDetails.prev_meeting_details != null ?
                                <>
                                    <hr />
                                    <h4>Previous Meeting Details</h4>
                                    <div className="row mb-1 mt-1">
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Date</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {
                                                    customerMeetingsDetails.prev_meeting_details.start_date
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">From Time</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {
                                                    customerMeetingsDetails.prev_meeting_details.from_time
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">To Time</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {
                                                    customerMeetingsDetails.prev_meeting_details.to_time
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Agenda</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {
                                                    customerMeetingsDetails.prev_meeting_details.agenda
                                                }
                                            </h4>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }
                        <hr />
                        <h4>Contact Details</h4>
                        <div className="row mb-1 mt-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        contactDetails != null && contactDetails.length > 0 ?
                                            renderContactDetails()
                                            :
                                            <h6 className="text-bold-500 text-center">No Contact Details</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Accompanied By</h4>
                        <div className="row mb-1 mt-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        accompaniedDetails.length > 0 ?
                                            renderAccompanied()
                                            :
                                            <div className='border-bottom pb-1 col-12'>
                                                <h6 className="text-bold-500 text-center border-bottom text-primary">No Accompanied By Details</h6>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Opportunity Details</h4>
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        policyDetails.length > 0 ?
                                            renderPolicyDetails()
                                            :
                                            <div className='border-bottom pb-1 col-12'>
                                                <h6 className="text-bold-500 text-center text-primary">No Opportunity Details</h6>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Created By</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    <div class="col-12 px-0 pb-1 border-bottom">
                                        <div className="float-left">
                                            <div className="d-flex flex-row">
                                                <div className="flex-column mr-half mb-half">
                                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                                        {generateInitialLettersString(customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details != null && customerMeetingsDetails?.created_by_details?.name != null ? customerMeetingsDetails.created_by_details.name : '')}
                                                    </div>
                                                </div>
                                                <div className="flex-column">
                                                <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer"
                                                    onClick={() => {
                                                        props.history.push({
                                                            pathname: '/profile',
                                                            state: {
                                                                user_id: customerMeetingsDetails?.created_by,
                                                            }
                                                        });
                                                    }}
                                                    >{customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details != null && customerMeetingsDetails?.created_by_details?.name != null ? customerMeetingsDetails.created_by_details.name : ''}</p>
                                                    {/* <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details != null && customerMeetingsDetails?.created_by_details?.name != null ? customerMeetingsDetails.created_by_details.name : ''}</p> */}
                                                    <div class="font-small-3 text-bold-700">{customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details?.designation_name != null ? customerMeetingsDetails.created_by_details.designation_name : ''}</div>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className={`flex-column ${customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                            <FiPhone size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                            <div class="font-small-3 secondary">{customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details?.mobile_no != null ? customerMeetingsDetails.created_by_details.mobile_no : ''}</div>
                                                        </div>
                                                        <div className={`flex-column ${customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                            <FiMail size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                            <div class="d-inline font-small-3 secondary">{customerMeetingsDetails != null && customerMeetingsDetails?.created_by_details?.email != null ? customerMeetingsDetails.created_by_details.email : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var CustomerMeetingsView = null;
    if (
        state.CustomerMeetingsViewReducer != null &&
        Object.keys(state.CustomerMeetingsViewReducer).length > 0 &&
        state.CustomerMeetingsViewReducer.constructor === Object
    ) {
        switch (state.CustomerMeetingsViewReducer.tag) {
            case ACTION_CUSTOMER_MEETING_VIEW_SUCCESS:
                CustomerMeetingsView = Object.assign({}, state.CustomerMeetingsViewReducer.data);
                delete state.CustomerMeetingsViewReducer;
                return { CustomerMeetingsView };
            default:

        }
    }
    return {
        CustomerMeetingsView
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionCustomerMeetingsView }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CustomerMeetingView))