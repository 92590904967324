import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CUSTOMER_VERIFICATION_REPORT } from '../../../../../api/constants';

export const ACTION_CUSTOMER_VERIFICATION_REPORT_LOADING = 'ACTION_CUSTOMER_VERIFICATION_REPORT_LOADING';
export const ACTION_CUSTOMER_VERIFICATION_REPORT_SUCCESS = 'ACTION_CUSTOMER_VERIFICATION_REPORT_SUCCESS';

export function actionCustomerVerificationReport(params) {
    return (dispatch, getState) =>
        Api.post(API_CUSTOMER_VERIFICATION_REPORT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_CUSTOMER_VERIFICATION_REPORT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CUSTOMER_VERIFICATION_REPORT_LOADING, { loading: false, data }));
        });
}