import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_GET_DEPARTMENT, API_MASTER_GET_POLICY_TYPE, API_MASTER_GET_EMPLOYEE_LIST, API_MASTER_ADD_PLACEMENT_RIGHTS } from '../../../../../../api/constants';

export const ACTION_MASTER_GET_DEPARTMENT_LOADING = 'ACTION_MASTER_GET_DEPARTMENT_LOADING';
export const ACTION_MASTER_GET_DEPARTMENT_SUCCESS = 'ACTION_MASTER_GET_DEPARTMENT_SUCCESS';

export const ACTION_MASTER_GET_POLICY_TYPE_LOADING = 'ACTION_MASTER_GET_POLICY_TYPE_LOADING';
export const ACTION_MASTER_GET_POLICY_TYPE_SUCCESS = 'ACTION_MASTER_GET_POLICY_TYPE_SUCCESS';

export const ACTION_MASTER_GET_EMPLOYEE_LIST_LOADING = 'ACTION_MASTER_GET_EMPLOYEE_LIST_LOADING';
export const ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS = 'ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS';

export const ACTION_MASTER_ADD_PLACEMENT_RIGHTS_LOADING = 'ACTION_MASTER_ADD_PLACEMENT_RIGHTS_LOADING';
export const ACTION_MASTER_ADD_PLACEMENT_RIGHTS_SUCCESS = 'ACTION_MASTER_ADD_PLACEMENT_RIGHTS_SUCCESS';

export function actionMasterDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_DEPARTMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_GET_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionMasterPolicyType(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_GET_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionMasterEmployeeList() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_GET_EMPLOYEE_LIST).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_GET_EMPLOYEE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionMasterAddPlacementRights(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ADD_PLACEMENT_RIGHTS, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ADD_PLACEMENT_RIGHTS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ADD_PLACEMENT_RIGHTS_LOADING, { loading: false, data }));
        });
}