import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Controller, useForm } from 'react-hook-form';
import ErrorLabel from '../../../../../../component/form/error_label';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import { withAuth } from 'react-auth-guard';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionReinsuranceClientEdit, ACTION_API_REINSURANCE_EDIT_SUCCESS } from './action';
import { actionReinsuranceClientView, ACTION_API_REINSURANCE_VIEW_SUCCESS } from './action';
import { actionClientType, ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS } from "./action";
import { actionCountry, ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS } from "./action";
import { toast } from 'react-toastify';

const ClientEdit = (props) => {

    const { register, control, handleSubmit, formState: { errors }, setValue } = useForm();

    const [reinsuranceClientView, setReinsuranceClientView] = useState([]);
    const [country, setCountry] = useState(null);
    const [clientType, setClientType] = useState(null);

    const initialRender = () => {
        props.actionReinsuranceClientView({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onCountryUpdate = () => {

        const { CountryUpdate } = props;
        var CountryUpdateResponse = Object.assign({}, CountryUpdate);
        if (CountryUpdateResponse.result === true) {
            setCountry(CountryUpdateResponse.response);
        } else {
            switch (CountryUpdateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onCountryUpdate, [props.CountryUpdate]);

    const onClientTypeUpdate = () => {

        const { ClientType } = props;
        var ClientTypeUpdateResponse = Object.assign({}, ClientType);
        if (ClientTypeUpdateResponse.result === true) {
            setClientType(ClientTypeUpdateResponse.response);
        } else {
            switch (ClientTypeUpdateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    const onReinsuranceClientEditUpdate = () => {
        const { ReinsuranceClientEdit } = props
        var ReinsuranceClientEditResponse = Object.assign({}, ReinsuranceClientEdit);
        $("#edit_client").prop("disabled", false);
        if (ReinsuranceClientEditResponse.result === true) {
            $("#edit-client-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Client Updated Successfully");
            props.history.goBack();
        } else {
            $("#edit-client-form-loader").addClass("hidden");
            switch (ReinsuranceClientEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(ReinsuranceClientEditResponse.message);
                default:
            }
        }
    }
    useEffect(onReinsuranceClientEditUpdate, [props.ReinsuranceClientEdit])

    useEffect(() => {
        if (reinsuranceClientView != null) {
            props.actionCountry({});
            props.actionClientType({});
            setValue('name', reinsuranceClientView.name);
        }
    }, [reinsuranceClientView])

    useEffect(() => {
        if (reinsuranceClientView.client_type != null) {
            setValue('client_type', reinsuranceClientView.client_type);
        }

    }, [clientType])

    useEffect(() => {
        if (reinsuranceClientView.country_id != null) {
            setValue('country_id', reinsuranceClientView.country_id);
        }
    }, [country])

    const onReinsuranceClientViewUpdate = () => {
        const { ReinsuranceClientView } = props
        var ReinsuranceClientViewResponse = Object.assign({}, ReinsuranceClientView);
        if (ReinsuranceClientViewResponse.result === true) {
            setReinsuranceClientView(ReinsuranceClientViewResponse.response);
        } else {
            switch (ReinsuranceClientViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReinsuranceClientViewUpdate, [props.ReinsuranceClientView])

    const onSubmit = (data) => {
        data.id = reinsuranceClientView.id;
        data.client_of = "reinsurance";
        data.is_new = "new";
        $("#edit-client-form-loader").removeClass("hidden");
        $("#edit_client").prop("disabled", true);
        props.actionReinsuranceClientEdit(data);
    }
    return (
        <>
            <ScreenTitle title="Edit Client" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Reinsurance Client
                                        </h4>
                                    </div>


                                    <div className="col-12 col-md-6 col-lg-6" >
                                        <div className="form-group">
                                            <label className="label-control ">Client Type&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="client_type"
                                                className="form-control"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        dataSource={clientType}
                                                        fields={{ text: "title", value: "value" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Client Type"
                                                        value={field.value}
                                                        change={(event) => field.onChange(event.itemData.value)}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Client Type is required'
                                                }}
                                            />
                                            {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Client Name&nbsp;<span className="danger">*</span></label>

                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Client Name"
                                                id="name"
                                                name="name"
                                                {...register('name', { required: 'Client Name is required' })} />
                                            {errors.name && <ErrorLabel message={errors.name?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Country&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="country_id"
                                                className="form-control"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        dataSource={country}
                                                        fields={{ text: "name", value: "id" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Country"
                                                        value={field.value}
                                                        change={(event) => field.onChange(event.itemData.id)}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Country is required'
                                                }}
                                            />
                                            {errors.country_id && <ErrorLabel message={errors.country_id?.message} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none">
                                <button
                                    id="edit_client"
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        Submit
                                        <span id="edit-client-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                                <button
                                    className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.goBack();
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {

    var ReinsuranceClientEdit = null;
    var ReinsuranceClientView = null;
    var CountryUpdate = null;
    var ClientType = null;

    if (
        state.ReinsuranceClientEditReducer != null &&
        Object.keys(state.ReinsuranceClientEditReducer).length > 0 &&
        state.ReinsuranceClientEditReducer.constructor === Object
    ) {
        switch (state.ReinsuranceClientEditReducer.tag) {
            case ACTION_API_REINSURANCE_EDIT_SUCCESS:
                ReinsuranceClientEdit = Object.assign({}, state.ReinsuranceClientEditReducer.data);
                delete state.ReinsuranceClientEditReducer;
                return { ReinsuranceClientEdit };
            case ACTION_API_REINSURANCE_VIEW_SUCCESS:
                ReinsuranceClientView = Object.assign({}, state.ReinsuranceClientEditReducer.data);
                delete state.ReinsuranceClientEditReducer;
                return { ReinsuranceClientView };
            case ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS:
                CountryUpdate = Object.assign({}, state.ReinsuranceClientEditReducer.data);
                delete state.ReinsuranceClientEditReducer;
                return { CountryUpdate };
            case ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.ReinsuranceClientEditReducer.data);
                delete state.ReinsuranceClientEditReducer;
                return { ClientType };
            default:
        }
    }
    return {
        ReinsuranceClientEdit,
        ReinsuranceClientView,
        CountryUpdate,
        ClientType
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReinsuranceClientEdit, actionReinsuranceClientView, actionClientType, actionCountry }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientEdit))
