import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { PRIMARY_COLOR, UNAUTHORIZED } from "../../../constants";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionApproveMobileAmount, ACTION_APPROVE_AMOUNT_MOBILE_SUCCESS } from './action';
import Swal from "sweetalert2";
import { downloadFile } from "../../../utils";
import DocumentIframe from "../../popups/document_iframe";

const ApprovalMobileClaim = (props) => {

    const { setValue } = useForm()
    const [reimbursementApprovalDetail, setReimbursementApprovalDetail] = useState([])
    const [isCopyCheck, setIsCopyCheck] = useState(false)
    const [reimbursementTypeId, setReimbursementTypeId] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [popup, setPopup] = useState(null)

    const initialRender = () => {

        if (props.show === true) {
            setReimbursementApprovalDetail(props.data);
            setApprovedAmountCheck(props.data);
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            setValue('reimbursement_type_id', props.data != null ? props.data.reimbursement_type_id : '');
            setValue('claim_amount', props.data != null ? props.data.claim_amount : '');
            setValue('description', props.data != null ? props.data.description : '');
            setValue('approved_amount', props.data != null ? props.data.approved_amount : '');
            if (props.id != null && props.id != undefined) {
                setReimbursementTypeId(props.id);
            }
        } else {
            setIsCopyCheck(false);
            setReimbursementApprovalDetail(null)
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setValue('reimbursement_type_id', '');
            setValue('claim_amount', '');
            setValue('description', '');
            setValue('approved_amount', '');
        }
    }
    useEffect(initialRender, [props.show]);

    const renderMobileApproval = () => {
        var arrMobileApproval = []
        if (reimbursementApprovalDetail != null && reimbursementApprovalDetail.length > 0) {
            reimbursementApprovalDetail.map((row, index) => {
                return (
                    arrMobileApproval.push(
                        <>
                            <form>
                                <div className="row mt-1">
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Amount Of Bill</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{<div><span>&#8377;</span> {row.bill_amount} </div>}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Reimbursement Amount</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0"> {<div><span>&#8377;</span> {row.claim_amount} </div>}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Manager Approved Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`approved_amount${index}`}
                                                name="approved_amount"
                                                placeholder="Enter Manager Approved Amount"
                                                value={row.approved_amount != null || isCopyCheck && props?.userType === 'manager' ? String(row.approved_amount) : '--'}
                                                disabled={props?.userType === 'finance' || reimbursementApprovalDetail.status === 'approved by manager' || reimbursementApprovalDetail.status === 'approved by finance manager' ? true : false}

                                                onChange={(event) => {
                                                    if (event.target.value > row.claim_amount) {
                                                        $(`#err-approved-amount-${`approved_amount${index}`}`).removeClass('d-none')
                                                    } else {
                                                        $(`#err-approved-amount-${`approved_amount${index}`}`).addClass('d-none')
                                                    }
                                                    var tempMobileArray = Object.assign([], reimbursementApprovalDetail)
                                                    var objMobile = Object.assign({}, tempMobileArray[index])
                                                    objMobile.approved_amount = event.target.value
                                                    tempMobileArray[index] = objMobile
                                                    setReimbursementApprovalDetail(tempMobileArray);
                                                    setIsCopyCheck(false);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <label id={`err-approved-amount-${`approved_amount${index}`}`} className="font-check-label font-small-2 text-danger error-msg d-none">Enter amount is greater than Reimbursement amount</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Finance Approved Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`finance_approved_amount${index}`}
                                                name="finance_approved_amount"
                                                placeholder="Enter Finance Approved Amount"
                                                value={row.finance_approved_amount != null || isCopyCheck && props?.userType === 'finance' ? String(row.finance_approved_amount) : "--"}
                                                disabled={props?.userType === 'finance' && reimbursementApprovalDetail.status === 'approved by finance manager' || props?.userType === 'manager' ? true : false}
                                                onChange={(event) => {
                                                    if (event.target.value > row.claim_amount) {
                                                        $(`#err-finance-approved-amount-${`finance_approved_amount${index}`}`).removeClass('d-none')
                                                    } else {
                                                        $(`#err-finance-approved-amount-${`finance_approved_amount${index}`}`).addClass('d-none')
                                                    }
                                                    var tempMobileArray = Object.assign([], reimbursementApprovalDetail)
                                                    var objMobile = Object.assign({}, tempMobileArray[index])
                                                    objMobile.finance_approved_amount = event.target.value
                                                    tempMobileArray[index] = objMobile
                                                    setReimbursementApprovalDetail(tempMobileArray);
                                                    setIsCopyCheck(false);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <label id={`err-finance-approved-amount-${`finance_approved_amount${index}`}`} className="font-check-label font-small-2 text-danger error-msg d-none">Enter amount is greater than Reimbursement amount</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Description</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{row.description}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Attachment</label>
                                            <div className="form-group">
                                                {
                                                    (row != null && row.reimbursement_document != null) ?
                                                        // <a className='primary' target="_blank" rel="noreferrer" href={row.reimbursement_document}>{row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}</a> : <>-</>
                                                        <>
                                                            <a className="primary"
                                                                onClick={() => {
                                                                    if (row.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'png' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'gif'
                                                                    ) {
                                                                        setSelectedDocumentIndex(row.reimbursement_document)
                                                                        setShowDocumentDetails(true);
                                                                        setExtension(row.reimbursement_document?.split('.').pop())
                                                                        setDocumentDetails(row)
                                                                        setPopup(true)
                                                                    } else {
                                                                        downloadFile(row.reimbursement_document);
                                                                    }
                                                                }}
                                                            >
                                                                {row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}
                                                            </a>
                                                            <a id="downloadLink" className="d-none"></a>
                                                        </>
                                                        :
                                                        <>-</>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="d-flex form-section my-0 black"></h4>
                            </form>

                        </>
                    )
                )
            })
        }
        return arrMobileApproval
    }

    const setApprovedAmountCheck = (list) => {
        var arrReimbursementApprovalTypeWiseList = Object.assign([], list)
        var arrTempReimbursementApprovalTypeWiseList = [];
        arrReimbursementApprovalTypeWiseList.forEach((item, index) => {
            if (props?.userType === 'finance' && (item.finance_approved_amount === null || item.finance_approved_amount === item.claim_amount)) {
                arrTempReimbursementApprovalTypeWiseList.push(item)
            }
            else if (props?.userType === 'manager' && (item.approved_amount === null || item.approved_amount === item.claim_amount)) {

                arrTempReimbursementApprovalTypeWiseList.push(item)
            }
            if (list.length === arrTempReimbursementApprovalTypeWiseList.length) {
                setIsCopyCheck(true)
            } else {
                setIsCopyCheck(false)
            }
        })
    }

    useEffect(() => {
        var arrReimbursementApprovalDetail = Object.assign([], reimbursementApprovalDetail)
        var arrTempReimbursementApprovalDetail = [];
        arrReimbursementApprovalDetail.forEach((item, index) => {
            if (isCopyCheck && props?.userType === "manager") {
                item.approved_amount = item.claim_amount;
            } else if (isCopyCheck && props?.userType === "finance") {
                item.finance_approved_amount = item.claim_amount;
            }
            arrTempReimbursementApprovalDetail.push(item)
        })
        if (arrTempReimbursementApprovalDetail.length > 0 && arrTempReimbursementApprovalDetail != null) {
            setReimbursementApprovalDetail(arrTempReimbursementApprovalDetail)
        }
    }, [isCopyCheck])

    const onMobileClaimUpdate = () => {
        const { ApprovalMobileClaim } = props;
        var ApprovalMobileClaimResponse = Object.assign({}, ApprovalMobileClaim);
        if (ApprovalMobileClaimResponse.result === true) {
            props.handleClose(ApprovalMobileClaimResponse.response);
            $("#approval-mobile_loader").addClass("hidden");
        } else {
            $("#approval-mobile_loader").addClass("hidden");
            switch (ApprovalMobileClaimResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMobileClaimUpdate, [props.ApprovalMobileClaim]);

    return (
        <div
            className="modal fade show"
            id="approval-mobile-modal"
            role="dialog"
            tabindex="-1"
            aria-labelledby="approvalMobileModalTitle"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title">
                                {props.remTitle != null ? props.remTitle : ''} Reimbursement
                            </h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        $("#root").removeClass("modal-open");
                                        $(".modal-backdrop").remove();
                                        props.handleClose();
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                {

                                    ((props.details != null && props?.userType === 'manager' && props.details?.status === 'pending') || (props.details != null && props?.userType === 'finance' && props.details?.status === 'approved by manager')) ?
                                        <div class="form-check cust-form-check float-right mt-0 mb-1">
                                            <input
                                                name="is_on_probation"
                                                class="form-check-input"
                                                type="checkbox"
                                                id="is_on_probation"
                                                checked={isCopyCheck}
                                                onClick={(e) => {
                                                    setIsCopyCheck(!isCopyCheck)
                                                }}
                                            />
                                            <label class="form-check-label my-auto" for="is_on_probation">
                                                <span className="cust-fs-12">Copy Reimbursement Amount</span>
                                            </label>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        {props.data != null && props.data.length > 0 ? renderMobileApproval() : <></>}

                        <div className="row mt-2">
                            <div className="col-12 col-md-3 col-lg-3"></div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="label-control font-bold">Total Reimbursement Amount</label>
                                    <div className="form-group">
                                        <label className="label-control"><b>{props.amount != null ?
                                            <span><span>&#8377;</span> {Number(props.amount).toFixed(2)} </span> : "--"}</b></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="label-control">Total Manager Approved Amount</label>
                                    <div className="form-group">
                                        <label className="label-control"><b>{props.approvedAmount != null ?
                                            <span><span>&#8377;</span> {Number(props.approvedAmount).toFixed(2)} </span> : "--"}</b></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="label-control">Total Finance Approved Amount</label>
                                    <div className="form-group">
                                        <label className="label-control"><b>{props.financeApprovedAmount != null ?
                                            <span><span>&#8377;</span> {Number(props.financeApprovedAmount).toFixed(2)} </span> : "--"}</b></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="form-section my-0 black"></h4>
                        {

                            ((props.details != null && props?.userType === 'manager' && props.details?.status === 'pending') || (props.details != null && props?.userType === 'finance' && props.details?.status === 'approved by manager')) ?
                                <div className="form-actions border-0  float-left">
                                    <button
                                        id="mobile_approved"
                                        name="mobile_approved"
                                        className="btn btn-primary mr-1 mt-0 shadow float-right text-uppercase"
                                        onClick={() => {
                                            // $('#err-approved-amount').addClass('d-none')
                                            var arrInput = []
                                            reimbursementApprovalDetail.map((item) => {
                                                var approval_amount = item.approved_amount
                                                var finance_approved_amount = item.finance_approved_amount
                                                if (approval_amount !== null && approval_amount !== '' && props?.userType === "manager") {
                                                    if (approval_amount >= 0) {
                                                        arrInput.push({ id: item.details_id, approved_amount: approval_amount })
                                                    }

                                                }
                                                if (finance_approved_amount !== null && finance_approved_amount !== '' && props?.userType === "finance") {
                                                    if (finance_approved_amount >= 0) {
                                                        arrInput.push({ id: item.details_id, approved_amount: finance_approved_amount })
                                                    }
                                                }
                                            })
                                            if (reimbursementApprovalDetail.length === arrInput.length) {
                                                $("#approval-mobile_loader").removeClass("hidden");
                                                $("#approval-mobile-modal .close").click()
                                                if (props?.userType === "manager") {
                                                    props.actionApproveMobileAmount({ data: arrInput, reimbursement_id: reimbursementTypeId, approved_by: 'approval_manager' });
                                                } else {
                                                    props.actionApproveMobileAmount({ data: arrInput, reimbursement_id: reimbursementTypeId, approved_by: 'finance_manager' });
                                                }
                                            } else {
                                                Swal.fire({
                                                    title: 'Error',
                                                    icon: 'error',
                                                    html: "Please insert apporval amount in all entries or inserted approval amount should be less than or equal to Reimbursement amount",
                                                    confirmButtonColor: PRIMARY_COLOR
                                                }).then((result) => {
                                                });
                                            }
                                        }}
                                    >Submit <span id="approval-mobile_loader" className="spinner-border spinner-border-sm ml-half hidden"></span></button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary mr-1 mt-0 shadow  text-uppercase"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(event) => {
                                            props.handleClose();
                                            event.preventDefault();
                                            setIsCopyCheck(false);
                                            setReimbursementApprovalDetail(null);
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div >
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                popup={popup}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </div >
    )
}
const mapStateToProps = (state) => {
    var ApprovalMobileClaim = null;
    if (
        state.ApprovalMobileClaimReducer != null &&
        Object.keys(state.ApprovalMobileClaimReducer).length > 0 &&
        state.ApprovalMobileClaimReducer.constructor === Object
    ) {
        switch (state.ApprovalMobileClaimReducer.tag) {
            case ACTION_APPROVE_AMOUNT_MOBILE_SUCCESS:
                ApprovalMobileClaim = Object.assign({}, state.ApprovalMobileClaimReducer.data);
                delete state.ApprovalMobileClaimReducer;
                return { ApprovalMobileClaim };
            default:
        }
    }

    return {
        ApprovalMobileClaim,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionApproveMobileAmount }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ApprovalMobileClaim))
