import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UNAUTHORIZED } from "../../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../../utils/preferences';
import { actionMasterGetInsurerBranch, ACTION_MASTER_GET_INSURER_BRANCH_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../../utils";

const BranchList = (props) => {
    const { match } = props;
    const [branchList, setBranchList] = useState([]);
    const [insurerId, setInsurerId] = useState(null);
    const [insurerStatus, setInsurerStatus] = useState(null);
    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "branch_id", caption: "Branch ID" },
        { dataField: "type", caption: "Branch Type" },
        { dataField: "short_name", caption: "Branch Short Name" },
        { dataField: "city", caption: "Branch City" },
        { dataField: "address", caption: "Branch Address" },
        { dataField: "status", caption: "Status" },
        { dataField: "Contact", caption: "Contact", type: "button" }
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        if (props?.location?.state?.data?.id != null) {
            setInsurerId(props?.location?.state?.data?.id);
        }
        if (props?.location?.state?.data?.status != null) {
            setInsurerStatus(props?.location?.state?.data.status);
        }
        props.actionMasterGetInsurerBranch({ insurer_id: [props.location.state.data?.id] });
    }
    useEffect(initialRender, []);

    const onMasterGetInsurerBranchUpdate = () => {
        const { MasterGetInsurerBranch } = props
        if (MasterGetInsurerBranch != null) {
            var MasterGetInsurerBranchResponse = Object.assign({}, MasterGetInsurerBranch);
            if (MasterGetInsurerBranchResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setBranchList(MasterGetInsurerBranchResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetInsurerBranchResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetInsurerBranchUpdate, [props.MasterGetInsurerBranch])

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("MASTER_BRANCH_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { detail: params.data } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("MASTER_BRANCH_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { detail: params.data, insurer_id: insurerId, insurer_status: insurerStatus } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }

                    </Column>
                );
            } else if (objColumn.dataField === "Contact") {
                arrColumns.push(
                    <Column dataField="contact" type="buttons">
                        {
                            verifyPermission("MASTER_CONTACT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/contacts`, state: { detail: params.data, insurer_name: props.location.state.data.short_name } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            }
            else if (objColumn.dataField === "branch_id") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        width={100}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
            else if (objColumn.dataField === "short_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        width={140}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
            else if (objColumn.dataField === "address") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        width={400}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-8 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">{`Branch List (${props?.location?.state?.data?.short_name})`}</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-4">
                        <ul class="nav nav-pills float-right">
                            {
                                verifyPermission("MASTER_BRANCH_ADD") ?
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/add`, state: { insurer_id: insurerId } });
                                            }}
                                        >
                                            Add Branch
                                        </button>
                                    </li>
                                    :
                                    <></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={branchList}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Branch List",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="flex-column">
                        <div className="d-flex flex-row w-100 justify-content-start ">
                            <button
                                className="btn btn-secondary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    var MasterGetInsurerBranch = null;

    if (
        state.MasterGetInsurerBranchReducer != null &&
        Object.keys(state.MasterGetInsurerBranchReducer).length > 0 &&
        state.MasterGetInsurerBranchReducer.constructor === Object
    ) {
        switch (state.MasterGetInsurerBranchReducer.tag) {
            case ACTION_MASTER_GET_INSURER_BRANCH_SUCCESS:
                MasterGetInsurerBranch = Object.assign({}, state.MasterGetInsurerBranchReducer.data);
                delete state.MasterGetInsurerBranchReducer;
                return { MasterGetInsurerBranch };
            default:

        }
    }
    return {
        MasterGetInsurerBranch,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterGetInsurerBranch }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(BranchList))