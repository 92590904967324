import React, { useState, useEffect } from 'react';
import { withAuth } from 'react-auth-guard';
import { useForm, Controller } from "react-hook-form";
import { DateBox } from 'devextreme-react/date-box';
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { UNAUTHORIZED } from "../../../../../../../constants";
import $ from "jquery";
import ClaimRegisterLossSite from '../../../../../../../component/popups/claims/register_loss_site';
import { actionLossSiteVisit, ACTION_LOSS_SITE_VISIT_SUCCESS } from './action';
import { actionClaimDownload, ACTION_CLAIM_DOWNLOAD_SUCCESS } from './action';
import { verifyPermission } from '../../../../../../../utils';

const LossSiteVisit = (props) => {
  const {  control } = useForm();

  const [lossSiteVisitList, setLossSiteVisitList] = useState();
  const [fromDate, setFromDate] = useState(`04/01/${new Date().getFullYear()}`);
  const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
  const [showPolicyView, setShowPolicyView] = useState(false);
  const [clientPolicyDetails, setClientPolicyDetails] = useState(null);
  const [downloadFileName, setDownloadFileName] = useState("Loss Site Visit");

  const initialRender = () => {
    $("#progress-loader").removeClass("hidden");
    props.actionLossSiteVisit({
      from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
      to_date: dateFormat(toDate, 'yyyy-mm-dd')
    });
  }
  useEffect(initialRender, []);

  const [columns, setColumns] = useState([
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "pinc_claim_ref_no", caption: "PINC Claim Ref. No." },
    { dataField: "employee_name", caption: "Employee Name" },
    { dataField: "insurer_name", caption: "Name of Insurer" },
    { dataField: "name_of_insured", caption: "Name of Insured" },
    { dataField: "policy_type", caption: "Policy Type" },
    { dataField: "policy_no", caption: "Policy No." },
    { dataField: "loss_location", caption: "Loss Location" },
    { dataField: "loss_description", caption: "Loss Description" },
    { dataField: "date_of_loss_site_visit", caption: "Date of Loss Site Visit" },
    { dataField: "estimated_loss_amount", caption: "Estimated Loss Amount" },
    { dataField: "loss_site_contact_person_name", caption: "Loss Site Contact Person Name" },
    { dataField: "loss_site_contact_person_mobile", caption: "Loss Site Contact Person Mobile Number" },
    { dataField: "loss_site_contact_person_email", caption: "Loss Site Contact Person Email ID" },
    { dataField: "alternate_contact_person_name", caption: "Alternate Contact Person Name" },
    { dataField: "alternate_contact_person_mobile", caption: "Alternate Contact Person Mobile Number" },
    { dataField: "alternate_contact_person_email", caption: "Alternate Contact Person Email ID" },
    // { dataField: "concerned_person_name", caption: "Name Of Concerned Person at loss location" },
    // { dataField: "concerned_person_contact", caption: "Contact details of Concerned Person at Loss Location" },
    { dataField: "date_of_loss", caption: "Date of Loss" },
    { dataField: "survey_location", caption: "Location Where Survey is Done" },
    { dataField: "cause_of_loss", caption: "Cause of Loss" },
    { dataField: "surveyor_contact_details", caption: "Surveyor Name & No./Workshop Details" },
    { dataField: "remarks", caption: "Remarks" },
  ]);

  const onLossSiteVisitListUpdate = () => {
    const { LossSiteVisitList } = props;
    if (LossSiteVisitList != null) {
      var LossSiteVisitListResponse = Object.assign({}, LossSiteVisitList);
      if (LossSiteVisitListResponse.result === true) {
        $("#progress-loader").addClass("hidden");
        setColumns([
          { dataField: "Action", caption: "Action", type: "button" },
          { dataField: "pinc_claim_ref_no", caption: "PINC Claim Ref. No." },
          { dataField: "employee_name", caption: "Employee Name" },
          { dataField: "insurer_name", caption: "Name of Insurer" },
          { dataField: "name_of_insured", caption: "Name of Insured" },
          { dataField: "policy_type", caption: "Policy Type" },
          { dataField: "policy_no", caption: "Policy No." },
          { dataField: "loss_location", caption: "Loss Location" },
          { dataField: "loss_description", caption: "Loss Description" },
          { dataField: "date_of_loss_site_visit", caption: "Date of Loss Site Visit" },
          { dataField: "estimated_loss_amount", caption: "Estimated Loss Amount" },
          { dataField: "loss_site_contact_person_name", caption: "Loss Site Contact Person Name" },
          { dataField: "loss_site_contact_person_mobile", caption: "Loss Site Contact Person Mobile Number" },
          { dataField: "loss_site_contact_person_email", caption: "Loss Site Contact Person Email ID" },
          { dataField: "alternate_contact_person_name", caption: "Alternate Contact Person Name" },
          { dataField: "alternate_contact_person_mobile", caption: "Alternate Contact Person Mobile Number" },
          { dataField: "alternate_contact_person_email", caption: "Alternate Contact Person Email ID" },
          // { dataField: "concerned_person_name", caption: "Name of Concerned Person at Loss Location" },
          // { dataField: "concerned_person_contact", caption: "Contact details of Concerned Person at loss location" },
          { dataField: "date_of_loss", caption: "Date of Loss" },
          { dataField: "survey_location", caption: "Location Where Survey is Done" },
          { dataField: "cause_of_loss", caption: "Cause of Loss" },
          { dataField: "surveyor_contact_details", caption: "Surveyor Name & No./Workshop Details" },
          { dataField: "remarks", caption: "Remarks" },
        ]);

        setLossSiteVisitList(LossSiteVisitListResponse.response);
        setDownloadFileName("Loss Site Visit");
      } else {
        $("#progress-loader").addClass("hidden");
        switch (LossSiteVisitListResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  }
  useEffect(onLossSiteVisitListUpdate, [props.LossSiteVisitList])

  const onClaimDownloadUpdate = () => {
    const { ClaimDownload } = props;
    if (ClaimDownload != null) {
      var ClaimDownloadResponse = Object.assign({}, ClaimDownload);
      if (ClaimDownloadResponse.result === true) {
        window.open(ClaimDownloadResponse.response.url);
      }
    }
  }
  useEffect(onClaimDownloadUpdate, [props.ClaimDownload]);

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="Action" type="buttons">
            {
              verifyPermission("VIEW_LOSS_SITE_VISIT") ?
                <Button
                  render={(params) => (
                    <button
                      className="btn btn-icon btn-sm float-left"
                      onClick={() => {
                        setShowPolicyView(true);
                        setClientPolicyDetails(params.data)
                      }}
                    >
                      <FiEye size={16} className="primary" />
                    </button>
                  )}
                />
                :
                <></>
            }
          </Column>
        );
      } else {
        if (objColumn.dataField === "estimated_loss_amount") {
          arrColumns.push(
            <Column
              dataField={objColumn.dataField}
              caption={objColumn.caption}
              alignment="left"
            ></Column>
          );
        }
        else {
          arrColumns.push(
            <Column
              dataField={objColumn.dataField}
              caption={objColumn.caption}
            ></Column>
          );
        }
      }
    });
    return arrColumns;
  };

  return (
    <>

      <div className="card dashboard-card-round">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 pb-1">
              <div className="row d-flex align-items-center">
                <div className="col-12 col-md-6 col-lg-6">
                  <h4 class="text-black text-bold-600 mb-0">Loss Site Visit List</h4>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                    <div className="flex-column mr-1">
                      <Controller
                        name="from_date"
                        control={control}
                        defaultValue={fromDate}
                        render={({ field }) =>
                          <DateBox
                            defaultValue={new Date()}
                            calendarOptions={{
                              showTodayButton: true,
                            }}
                            stylingMode="underlined"
                            pickerType="calendar"
                            placeholder="From Date"
                            type="date"
                            displayFormat="dd/MM/yyyy"
                            showClearButton={false}
                            value={field.value}
                            acceptCustomValue={false}
                            onValueChange={(value) => {
                              var objFromDate = new Date(value);
                              var objToDate = new Date(toDate);

                              if (objToDate.getTime() < objFromDate.getTime()) {
                                Swal.fire({
                                  title: 'Error',
                                  text: "From date can't be greater than To date",
                                  icon: 'error'
                                }).then(success => {

                                })
                              } else {
                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                props.actionLossSiteVisit({
                                  from_date: dateFormat(value, 'yyyy-mm-dd'),
                                  to_date: dateFormat(toDate, 'yyyy-mm-dd')
                                });
                              }
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="flex-column mr-1">
                      <Controller
                        className="flex-column"
                        name="to_date"
                        control={control}
                        defaultValue={toDate}
                        render={({ field }) =>
                          <DateBox
                            defaultValue={new Date()}
                            calendarOptions={{
                              showTodayButton: true,
                            }}
                            stylingMode="underlined"
                            pickerType="calendar"
                            placeholder="To Date"
                            type="date"
                            displayFormat="dd/MM/yyyy"
                            showClearButton={false}
                            value={field.value}
                            acceptCustomValue={false}
                            onValueChange={(value) => {
                              var objFromDate = new Date(fromDate);
                              var objToDate = new Date(value);

                              if (objToDate.getTime() < objFromDate.getTime()) {
                                Swal.fire({
                                  title: 'Error',
                                  text: "To date must be greater than from date",
                                  icon: 'error'
                                }).then(success => {

                                })
                              } else {
                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                props.actionLossSiteVisit({
                                  from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
                                  to_date: dateFormat(value, 'yyyy-mm-dd')
                                });
                              }
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12">
              <div className="card">
                <div className="card-body">
                  <DataGrid
                    dataSource={lossSiteVisitList}
                    columnAutoWidth={true}
                    showBorders={true}
                    export={{
                      fileName: downloadFileName,
                      enabled: lossSiteVisitList != null && lossSiteVisitList.length > 0 ? true : false,
                      allowExportSelectedData: false,
                      excelFilterEnabled: true,
                    }}
                    onToolbarPreparing={(e) => {
                      e.toolbarOptions.items.unshift({
                        location: 'before'

                      })
                    }}
                  >
                    <SearchPanel visible={true} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    {renderColumns()}
                    <Pager
                      visible={true}
                      showPageSizeSelector={false}
                      showNavigationButtons={true}
                      allowedPageSizes={[4, 6, 10]}
                    />
                    <Paging
                      defaultPageIndex={0}
                      defaultPageSize={10}
                      enabled={true}
                    />
                  </DataGrid>
                  <button class="btn btn-outline-secondary shadow round text-uppercase mt-0" type='button'
                    onClick={() => { props.history.goBack() }}> Back
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <ClaimRegisterLossSite
        show={showPolicyView}
        clientPolicyDetails={clientPolicyDetails}
        handleClose={(register) => {
          setShowPolicyView(false);
        }}
      />
    </>
  )
}

function mapStateToProps(state) {

  var LossSiteVisitList = null;
  var ClaimDownload = null;

  if (
    state.LossSiteVisitReducer != null &&
    Object.keys(state.LossSiteVisitReducer).length > 0 &&
    state.LossSiteVisitReducer.constructor === Object
  ) {
    switch (state.LossSiteVisitReducer.tag) {

      case ACTION_LOSS_SITE_VISIT_SUCCESS:
        LossSiteVisitList = Object.assign({}, state.LossSiteVisitReducer.data);
        delete state.LossSiteVisitReducer;
        return { LossSiteVisitList };
      case ACTION_CLAIM_DOWNLOAD_SUCCESS:
        ClaimDownload = Object.assign({}, state.LossSiteVisitReducer.data);
        delete state.LossSiteVisitReducer;
        return { ClaimDownload };
      default:
    }
  }
  return {
    LossSiteVisitList,
    ClaimDownload
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ actionLossSiteVisit, actionClaimDownload }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LossSiteVisit));