import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import ClientRoute from '../../routes/app/auth/client';
import DashboardRoute from '../../routes/app/auth/dashboard';
import HomeRoute from '../../routes/app/auth/home';
import MyProfile from '../../routes/app/auth/my_profile';
import MeetingRoute from '../../routes/app/auth/meetings';
import TeamRoute from '../../routes/app/auth/teams';
import ReportRoute from '../../routes/app/auth/reports';
import LeadRoute from '../../routes/app/auth/lead';
import proposalRoute from '../../routes/app/auth/proposal';
import HRMSRoute from '../../routes/app/auth/hrms';
import ReinsuranceRoute from '../../routes/app/auth/reinsurance';
import Documents from '../../routes/app/auth/documents';
import PermissionRoute from '../../routes/app/auth/permission';
import InsurerRoute from '../../routes/app/auth/insurer';
import ApprovalsRoute from '../../routes/app/auth/approvals';
import NotificationRoute from '../../routes/app/auth/notification';
import RetentionRoute from '../../routes/app/auth/retention';
import RiskManagementRoute from '../../routes/app/auth/risk_management';
import WellnessManagementRoute from '../../routes/app/auth/wellness_management';
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED } from '../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionGetSearchList, ACTION_GET_SEARCH_LIST_SUCCESS } from "./action";
import { getData, KEY_GLOBAL_SEARCH, setData } from '../../utils/preferences';
import ClaimsRoute from '../../routes/app/auth/claims';
import TaskDetailsRoute from '../../routes/app/auth/task_details';
import PolicyRoute from '../../routes/app/auth/policy'
// import ProposalPoolList from '../../routes/app/auth/proposal_pool/list';
import ProposalPoolRoute from '../../routes/app/auth/proposal_pool';
// import { getMessaging, onMessage } from "firebase/messaging";
import HolidayRoute from '../../routes/app/auth/holiday';
import MastersRoute from '../../routes/app/auth/master';
import PlacementRoute from '../../routes/app/auth/placement';
import BirthDayWishRoute from '../../routes/app/auth/customer_services/birthday_wishes';
import TravelCalendarRoute from '../../routes/app/auth/travel_calender';

const Content = (props) => {

  const initialRender = () => {

    var global_search = getData(KEY_GLOBAL_SEARCH);

    if (global_search == null) {
      props.actionGetSearchList({});
    }

    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload);
    // });

  };
  useEffect(initialRender, [props.show]);

  const onGetSearchListUpdate = () => {
    const { GetSearchList } = props;
    var GetSearchListResponse = Object.assign({}, GetSearchList);
    if (GetSearchListResponse.result === true) {
      setData(KEY_GLOBAL_SEARCH, JSON.stringify(GetSearchListResponse.response))
    } else {
      switch (GetSearchListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  }
  useEffect(onGetSearchListUpdate, [props.GetSearchList]);

  return (
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div
        id="progress-loader"
        className="hidden"
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
        }}>
        <div
          className="blockUI blockOverlay"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            opacity: 0.6,
          }}>
        </div>
        <div
          class="spinner-border text-dark"
          role="status"
          style={{
            position: 'absolute',
            left: '50%',
            top: '3%',
            right: '10%',
            zIndex: 100
          }}
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div className="content-wrapper">
        <Switch>
          <Route path="/dashboard" component={DashboardRoute} />
          <Route path="/home" component={HomeRoute} />
          <Route path="/profile" component={MyProfile} />
          <Route path="/client" component={ClientRoute} />
          <Route path="/claims" component={ClaimsRoute} />
          <Route path="/leads" component={LeadRoute} />
          <Route path="/proposal" component={proposalRoute} />
          <Route path="/meetings" component={MeetingRoute} />
          <Route path="/teams" component={TeamRoute} />
          <Route path="/hrms" component={HRMSRoute} />
          <Route path="/approvals" component={ApprovalsRoute} />
          <Route path="/settings" component={PermissionRoute} />
          <Route path="/documents" component={Documents} />
          <Route path="/reports" component={ReportRoute} />
          <Route path="/reinsurance" component={ReinsuranceRoute} />
          <Route path="/insurer" component={InsurerRoute} />
          <Route path="/holidays" component={HolidayRoute} />
          <Route path="/notification" component={NotificationRoute} />
          <Route path="/retention" component={RetentionRoute} />
          <Route path="/tasks" component={TaskDetailsRoute} />
          <Route path="/policy" component={PolicyRoute} />
          <Route path="/proposal_pool" component={ProposalPoolRoute} />
          <Route path="/risk_management" component={RiskManagementRoute} />
          <Route path="/wellness_management" component={WellnessManagementRoute} />
          <Route path="/master" component={MastersRoute} />
          <Route path="/placement" component={PlacementRoute} />
          <Route path ='/customer-service/birthday-reminder' component={BirthDayWishRoute}/>
          <Route path ='/travel-calendar' component={TravelCalendarRoute}/>
        </Switch>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  var GetSearchList = null;

  if (
    state.SearchListGetReducer != null &&
    Object.keys(state.SearchListGetReducer).length > 0 &&
    state.SearchListGetReducer.constructor === Object
  ) {
    switch (state.SearchListGetReducer.tag) {
      case ACTION_GET_SEARCH_LIST_SUCCESS:
        GetSearchList = Object.assign({}, state.SearchListGetReducer.data);
        delete state.SearchListGetReducer;
        return { GetSearchList };
      default:
    }
  }
  return {
    GetSearchList
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionGetSearchList,
    },
    dispatch
  );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Content))