import React, { useState, useEffect } from 'react';
import { DataGrid, DateBox } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Controller, useForm } from 'react-hook-form';
import ErrorLabel from "../../../../../../component/form/error_label";
import { UNAUTHORIZED } from '../../../../../../constants';
import $ from 'jquery';
import { withAuth } from 'react-auth-guard';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import moment from 'moment';
import { actionReimbursementReportDetails, actionRembursementReport, ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS, ACTION_REIMBURSEMENT_REPORT_SUCCESS } from './action';

const ReimbursementPage = (props) => {
    const { handleSubmit, control, formState: { errors }, setValue } = useForm();

    const initialRender = () => {
        // $("#progress-loader").removeClass("hidden");
        props.actionReimbursementReportDetails();
        props.actionRembursementReport({
            employee_id: employeeId,
            month: moment(selectedMonth, 'YYYY-MM').format('M'),
            year: moment(selectedMonth, 'YYYY-MM').format('YYYY')
        })
    }
    useEffect(initialRender, []);

    const [employeeId, setEmployeeId] = useState();
    const [employeeDetails, setEmployeeDetails] = useState([])
    const [reimbursementReport, setReimbursementReport] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
    const columns = [
        { dataField: "employee_id", caption: "Employee Id", visible: true },
        { dataField: "employee_name", caption: "Employee Name", visible: true },
        { dataField: "created_at", caption: "Date Create", visible: false },
        { dataField: "created_at_formated", caption: "Date Create", visible: true },
        { dataField: "mobile_expenses", caption: "Mobile Expenses", visible: true },
        { dataField: "conveyance_expenenses", caption: "Conveyance Expenenses", visible: true },
        { dataField: "other_expenses", caption: "Other Expenses", visible: true },
        { dataField: "business_promotion", caption: "Business Promotion", visible: true },
        { dataField: "staff_welfare", caption: "Staff Welfare", visible: true },
        { dataField: "postage_expenses", caption: "Postage Expenses", visible: true },
        { dataField: "internet_expenses", caption: "Internet Expenses", visible: true },
        { dataField: "office_expenses", caption: "Office Expenses", visible: true },
        { dataField: "printing_n_stationery", caption: "Printing And Stationery", visible: true },
        { dataField: "training_n_data_processing_charges", caption: "Training And Data Processing Charges", visible: true },
        { dataField: "insurance_charges", caption: "Insurance Charges", visible: true },
        { dataField: "travelling_expenses_domestic", caption: "Travelling Expenses Domestic", visible: true },
        { dataField: "travelling_expenses_foreign", caption: "Travelling Expenses Foreign", visible: true },
        { dataField: "repairs_n_maintenance", caption: "Repairs And Maintenance", visible: true },
        { dataField: "claim_amount", caption: "Reimbursement amount", visible: true },
        { dataField: "total_amount", caption: "Total Approved Amount", visible: true },
        { dataField: "finance_approval_amount", caption: "Total Finance Approved Amount", visible: true },
        { dataField: "remark", caption: "Remark", visible: true },
        { dataField: "status", caption: "Status", visible: true },
        { dataField: "manager_action_date", caption: "Manager Action Date", visible: true },
        { dataField: "finance_manager_action_date", caption: "Finance Action Date", visible: true },
    ];

    const onReimbursementReportListUpdate = () => {
        const { ReimbursementReportList } = props;
        var ReimbursementReportListResponse = Object.assign({}, ReimbursementReportList);
        if (ReimbursementReportListResponse.result === true) {
            setEmployeeDetails(ReimbursementReportListResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            switch (ReimbursementReportListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementReportListUpdate, [props.ReimbursementReportList]);

    const onReimbursementReportUpdate = () => {
        const { ReimbursementReport } = props;
        var ReimbursementReportResponse = Object.assign({}, ReimbursementReport);
        $("#reimbursement-report").prop("disabled", false);
        if (ReimbursementReportResponse.result === true) {
            setReimbursementReport(ReimbursementReportResponse.response);
            $("#reimbursement-report-loader").addClass("hidden");
        } else {
            $("#reimbursement-report-loader").addClass("hidden");
            switch (ReimbursementReportResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementReportUpdate, [props.ReimbursementReport]);

    const renderFormatedDate = (cell) => {
        return <div>{cell?.data?.created_at_formated != null ? moment(cell?.data?.created_at_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "created_at_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderFormatedDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const onSubmit = (data) => {
        $("#reimbursement-report-loader").removeClass("hidden");
        $("#reimbursement-report").prop("disabled", true);
        setReimbursementReport([])
        props.actionRembursementReport({
            employee_id: employeeId,
            month: moment(selectedMonth, 'YYYY-MM').format('M'),
            year: moment(selectedMonth, 'YYYY-MM').format('YYYY')
        })
    }
    return (
        <>
            <section>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 pb-1">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 my-auto">
                                <h5 className="content-header-title text-capitalize mb-0">Reimbursement Report {reimbursementReport?.length === 0 ? '' : `(${reimbursementReport?.length})`}</h5>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="flex-column">
                                    <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                        <button
                                            className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <label className="label-control primary">Month</label>
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                defaultValue={moment().format('YYYY-MM')}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            maxZoomLevel: 'year',
                                                            minZoomLevel: 'century'
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="monthAndYear"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        max={moment().format('YYYY-MM')}
                                                        onValueChange={(value) => {
                                                            field.onChange(moment(value).format('YYYY-MM'));
                                                            setSelectedMonth(moment(value).format('YYYY-MM'));
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <label className="label-control primary">Employee Name</label>
                                            <Controller
                                                name="employee_id"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: employeeDetails,
                                                                key: 'user_id',
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        allowFiltering={true}
                                                        deferRendering={true}
                                                        displayExpr='full_name'
                                                        placeholder="Select Employee Name"
                                                        searchEnabled={true}
                                                        valueExpr='user_id'
                                                        searchMode="contains"
                                                        maxDisplayedTags={2}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined || value != '') {
                                                                field.onChange(value);
                                                                setEmployeeId(value);
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                            />

                                            {errors.employee_id && <ErrorLabel message={errors.employee_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-actions mt-1 border-0 float-none">
                                            <button
                                                id="reimbursement-report"
                                                type='submit'
                                                className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                            >Search
                                                <span id="reimbursement-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={reimbursementReport}
                                    showBorders={true}
                                    export={{
                                        fileName: "Reimbursement Report",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("created_at_formated", "visible", false)
                                        event.component.columnOption("created_at", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("created_at_formated", "visible", true)
                                        event.component.columnOption("created_at", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ReimbursementReportList = null;
    var ReimbursementReport = null;
    if (
        state.ReimbursementReportReducer != null &&
        Object.keys(state.ReimbursementReportReducer).length > 0 &&
        state.ReimbursementReportReducer.constructor === Object
    ) {
        switch (state.ReimbursementReportReducer.tag) {
            case ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS:
                ReimbursementReportList = Object.assign({}, state.ReimbursementReportReducer.data);
                delete state.ReimbursementReportReducer;
                return { ReimbursementReportList };
            case ACTION_REIMBURSEMENT_REPORT_SUCCESS:
                ReimbursementReport = Object.assign({}, state.ReimbursementReportReducer.data);
                delete state.ReimbursementReportReducer;
                return { ReimbursementReport };
            default:
        }
    }
    return {
        ReimbursementReportList,
        ReimbursementReport
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReimbursementReportDetails, actionRembursementReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReimbursementPage))
