import React, { useEffect, useState } from "react";
import $ from "jquery";

const TrainingEmployeeList = (props) => {
    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Training Employee Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 col-md-12 col-lg-12">
                            {
                                props?.data != null && props?.data?.length > 0 ?
                                    <table className="table table-striped table-bordered w-100 table-responsive-lg">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Name</th>
                                                <th>Branch Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                props?.data?.map((employee, indexContact) => (
                                                    <tr key={employee?.id}>
                                                        <td>{Number(indexContact + 1)}</td>
                                                        <td>{employee?.full_name}</td>
                                                        <td>{employee?.branch}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    <h5 className="text-center text-bold-400">No Employee Found</h5>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TrainingEmployeeList;
