import React, { useEffect, useState } from "react"
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { bindActionCreators } from 'redux';
import { PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import ApprovalMobileClaim from '../../../../../../component/form/approval_mobile_claim';
import ApprovalConveyanceClaim from '../../../../../../component/form/approval_conveyance_claim';
import ApprovalOtherClaim from '../../../../../../component/form/approval_other_claim';
import RejectClaims from '../../../../../../component/form/reject_claim';
import { verifyPermission } from '../../../../../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import Swal from 'sweetalert2';
import _ from "lodash";
import $ from 'jquery';
import { FiFileText } from "react-icons/fi";
import moment from "moment";
import { actionReimbursementApprovalView, ACTION_REIMBURSEMENT_APPROVAL_VIEW_SUCCESS } from './action';
import { actionReimbursementApprovReject, ACTION_REIMBURSEMENT_APPROVE_REJECT_SUCCESS } from './action';
import { withAuth } from "react-auth-guard";

const ReimbursementApprovalView = (props) => {
    const { handleSubmit } = useForm();
    const [showmobileapprovalclaim, setShowMobileApprovalClaim] = useState(false);
    const [showconveyanceapprovalclaim, setShowConveyanceApprovalClaim] = useState(false);
    const [showotherapprovalclaim, setShowOtherApprovalClaim] = useState(false);
    const [showrejectclaim, setShowRejectClaim] = useState(false);
    const [reimbursementApprovalDetail, setReimbursementApprovalDetail] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);
    const [totalApprovedAmount, setTotalApprovedAmount] = useState(null);
    const [totalFinanceApprovedAmount, setTotalFinanceApprovedAmount] = useState(null);
    const [objReimbursementItem, setObjReimbursementItem] = useState([]);
    const [reimbursementId, setReimbursementId] = useState(null);
    const [remTitle, setRemTitle] = useState(null);
    const [checkYes, setCheckYes] = useState(false);

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        if (props?.location?.state?.type === 'NEW_REIMBURSMENT') {
            props.actionReimbursementApprovalView({
                id: props.location.state.id,
            });
        } else {
            props.actionReimbursementApprovalView({
                user_id: props.location.state?.user_id,
                month: props.location.state?.month,
                year: props.location.state?.rem_year
            });
        }
    }
    useEffect(initialRender, []);


    const onReimbursementApprovalViewUpdate = () => {
        const { ReimbursementApprovalView } = props;
        var ReimbursementApprovalViewResponse = Object.assign({}, ReimbursementApprovalView);
        if (ReimbursementApprovalViewResponse.result === true) {
            setReimbursementApprovalDetail(ReimbursementApprovalViewResponse.response);
            setReimbursementId(ReimbursementApprovalViewResponse.response.id);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReimbursementApprovalViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementApprovalViewUpdate, [props.ReimbursementApprovalView]);

    const onReimbursementApproveRejectUpdate = () => {
        const { ReimbursementApproveReject } = props;
        var ReimbursementApproveRejectResponse = Object.assign({}, ReimbursementApproveReject);
        if (ReimbursementApproveRejectResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Status Change Successfully");
            if (props?.location?.state?.type === 'NEW_REIMBURSMENT') {
                props.actionReimbursementApprovalView({
                    id: props.location.state.id,
                });
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.actionReimbursementApprovalView({
                    user_id: props.location.state?.user_id,
                    month: props.location.state?.month,
                    year: props.location.state?.rem_year
                });
                props.history.goBack();
            }

        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReimbursementApproveRejectResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementApproveRejectUpdate, [props.ReimbursementApproveReject]);

    const onSubmit = (data) => {
        console.log(data)
    }

    return (
        <>
            {/* //     <ScreenTitle title="Reimbursement" /> */}
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Reimbursement
                    </h5>
                </div>
                <div className="content-header-right col-12 col-md-12 col-lg-12">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'NEW_REIMBURSMENT') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            > Back</button>
                        </li>
                    </ul>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {/* <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Employee Code</label>
                                                <div className='mt-1'>
                                                    <label className="label-control font-weight-bold mr-1">{reimbursementApprovalDetail != null ? reimbursementApprovalDetail?.employee_code : '-'}</label>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Employee Name</label>
                                                <div className='mt-1'>
                                                    <label className="label-control  font-weight-bold mr-1 text-primary cursor-pointer"
                                                    onClick={() => {
                                                        props.history.push({
                                                            pathname: '/profile',
                                                            state: {
                                                                user_id: props.location?.state?.user_id,
                                                            }
                                                        })
                                                    }}  
                                                    >{
                                                        props?.location?.state?.type === 'NEW_REIMBURSMENT' ? reimbursementApprovalDetail?.creator_name :
                                                            props.location?.state?.full_name != null ? props.location?.state?.full_name : '-'
                                                    }</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Month of Reimbursement </label>
                                                <div className='mt-1'>
                                                    <label className="label-control  font-weight-bold mr-1">{
                                                        props?.location?.state?.type === 'NEW_REIMBURSMENT' && moment(reimbursementApprovalDetail?.rem_month).isValid() && reimbursementApprovalDetail?.rem_month != null && reimbursementApprovalDetail?.rem_year != null ? moment(reimbursementApprovalDetail?.rem_month, 'M').format('MMMM') + ' ' + reimbursementApprovalDetail?.rem_year :
                                                            props.location?.state?.rem_month != null && props.location?.state?.rem_year != null ? props.location?.state?.rem_month + ' ' + props.location?.state?.rem_year : '-'
                                                    }</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Status: <b>{reimbursementApprovalDetail != null ? _.upperFirst(reimbursementApprovalDetail?.status.replace('_', ' ').replace('_', ' ').replace('_', ' ')) : '-'}</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-header">
                    <h3 className="content-header-title text-capitalize mb-2"> Reimbursement  Details</h3>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form>
                                    <div className='row'>
                                        <div class="col-12 col-md-12 col-lg-12 ">
                                            <h4 class="align-items-center form-section  black text-bold-700">
                                                Reimbursement Overview
                                            </h4>
                                            {
                                                reimbursementApprovalDetail?.all_details != null && reimbursementApprovalDetail?.all_details.length > 0 ?
                                                    reimbursementApprovalDetail?.all_details.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                    <div className='row'>
                                                                        <div className="col-12 col-md-3 col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Reimbursement for</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control text-uppercase font-weight-bold">{item.title}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            verifyPermission('EDIT_REIMBURSEMENT_APPROVAL') ?

                                                                                <div>
                                                                                    {
                                                                                        item.view_type === "MOBILE" ?
                                                                                            <div className="col-12 col-md-3 col-lg-1">
                                                                                                <div className="form-group">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn px-0 primary cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            setObjReimbursementItem(item.list);
                                                                                                            setTotalAmount(item.total_claim_amount);
                                                                                                            setTotalApprovedAmount(item.total_approved_amount);
                                                                                                            setTotalFinanceApprovedAmount(item.total_finance_approved_amount);
                                                                                                            setRemTitle(item.title);
                                                                                                            setShowMobileApprovalClaim(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiFileText size={20} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        item.view_type === "CONVENIENCE" ?
                                                                                            <div className="col-12 col-md-3 col-lg-1">
                                                                                                <div className="form-group">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn px-0 primary cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            setObjReimbursementItem(item.list);
                                                                                                            setTotalAmount(item.total_claim_amount);
                                                                                                            setTotalApprovedAmount(item.total_approved_amount);
                                                                                                            setTotalFinanceApprovedAmount(item.total_finance_approved_amount);
                                                                                                            setRemTitle(item.title);
                                                                                                            setShowConveyanceApprovalClaim(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiFileText size={20} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        item.view_type === "OTHERS" ?
                                                                                            <div className="col-12 col-md-3 col-lg-1">
                                                                                                <div className="form-group">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn px-0 primary cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            setObjReimbursementItem(item.list);
                                                                                                            setTotalAmount(item.total_claim_amount);
                                                                                                            setTotalApprovedAmount(item.total_approved_amount);
                                                                                                            setTotalFinanceApprovedAmount(item.total_finance_approved_amount);
                                                                                                            setRemTitle(item.title);
                                                                                                            setShowOtherApprovalClaim(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiFileText size={20} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Reimbursement Amount</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control"><b>{item.total_claim_amount != null ?
                                                                                        <span><span>&#8377;</span> {Number(item.total_claim_amount).toFixed(2)} </span> : "--"}</b></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Manager Approved Amount</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control"><b>{item.total_approved_amount != null ?
                                                                                        <span><span>&#8377;</span> {Number(item.total_approved_amount).toFixed(2)} </span> : "--"}</b></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Finance Approved Amount</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control"><b>{item.total_finance_approved_amount != null ?
                                                                                        <span><span>&#8377;</span> {Number(item.total_finance_approved_amount).toFixed(2)} </span> : "--"}</b></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div >
                                                                </div>
                                                                <h4 class="align-items-center form-section px-1 black"></h4>
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                <div className='row mt-1'>
                                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">Reimbursement Amount</label>
                                                            <div className="form-group">
                                                                <label className="label-control"><b>{reimbursementApprovalDetail != null && reimbursementApprovalDetail.claim_amount != null ?
                                                                    <span><span>&#8377;</span> {Number(reimbursementApprovalDetail.claim_amount).toFixed(2)} </span> : "--"}</b></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">Manager Approved Amount</label>
                                                            <div className="form-group">
                                                                <label className="label-control"><b>{reimbursementApprovalDetail != null && reimbursementApprovalDetail.approval_amount != null ?
                                                                    <span><span>&#8377;</span> {Number(reimbursementApprovalDetail.approval_amount).toFixed(2)} </span> : "--"}</b></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">Finance Approved Amount</label>
                                                            <div className="form-group">
                                                                <label className="label-control"><b>{reimbursementApprovalDetail != null && reimbursementApprovalDetail.finance_approval_amount != null ?
                                                                    <span><span>&#8377;</span> {Number(reimbursementApprovalDetail.finance_approval_amount).toFixed(2)} </span> : "--"}</b></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="align-items-center form-section px-1 black"></h4>
                                            {
                                                // reimbursementApprovalDetail != null && (reimbursementApprovalDetail?.status === 'reject_by_finance_manager' || reimbursementApprovalDetail?.status === 'approved_by_manager' || reimbursementApprovalDetail?.status === 'approved_by_finance_manager') ?
                                                //     <></>
                                                //     :
                                                reimbursementApprovalDetail != null && (reimbursementApprovalDetail?.status === 'pending') && reimbursementApprovalDetail?.all_details != null && reimbursementApprovalDetail?.all_details.length > 0 ?
                                                    <>
                                                        <div class="cust-fs-12 my-auto ml-1">
                                                            <input
                                                                id="terms-consent"
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                required
                                                                onClick={() => {
                                                                    if (checkYes === true) {
                                                                        setCheckYes(false);
                                                                    }
                                                                    else {
                                                                        setCheckYes(true);
                                                                    }
                                                                }}
                                                            />
                                                            &nbsp;
                                                            <span htmlFor='terms-consent'>I hereby confirm that the above documents and their amounts have all been verified by me.</span>
                                                        </div>
                                                        < div className="form-actions border-0 float-none">
                                                            <button
                                                                id="reimbursement_approved"
                                                                name="reimbursement_approved"
                                                                type="button"
                                                                // disabled={checkYes === false ? true : false}
                                                                className="btn btn-primary mr-1 mt-0 shadow  text-uppercase"
                                                                onClick={() => {
                                                                    const nullAmount = reimbursementApprovalDetail?.all_details.filter(obj => obj.total_approved_amount === null);
                                                                    if (checkYes === false) {
                                                                        Swal.fire({
                                                                            title: 'Error',
                                                                            icon: 'error',
                                                                            html: "Please confirm the checkbox",
                                                                            confirmButtonColor: PRIMARY_COLOR
                                                                        }).then((result) => {
                                                                        });
                                                                    } else if (nullAmount.length > 0) {
                                                                        Swal.fire({
                                                                            title: 'Error',
                                                                            icon: 'error',
                                                                            html: "Please insert apporval amount in all entries or inserted approval amount should be less than or equal to Reimbursement amount",
                                                                            confirmButtonColor: PRIMARY_COLOR
                                                                        }).then((result) => {
                                                                        });
                                                                    } else {
                                                                        Swal.fire({
                                                                            icon: 'warning',
                                                                            text: 'Are you sure, you want to approve this Reimbursement ?',
                                                                            cancelButtonText: "Cancel",
                                                                            showCancelButton: true,
                                                                            inputPlaceholder: 'Reason',
                                                                            input: 'text',
                                                                        }).then(({ isConfirmed, value }) => {
                                                                            if (isConfirmed) {
                                                                                $("#progress-loader").removeClass("hidden");
                                                                                props.actionReimbursementApprovReject({
                                                                                    "reimbursement_id": props.location.state.id,
                                                                                    "status": "approved_by_manager",
                                                                                    "remark": value.length > 0 ? value : '',
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                }
                                                            >
                                                                Approve
                                                            </button>
                                                            <button
                                                                id="reimbursement_reject"
                                                                name="reimbursement_reject"
                                                                type="button"
                                                                className=" btn btn-outline-secondary mr-1 mt-0 shadow  text-uppercase"
                                                                onClick={() => {
                                                                    // if (reimbursementApprovalDetail != null && reimbursementApprovalDetail.claim_amount != null && reimbursementApprovalDetail.claim_amount === 0) {
                                                                    Swal.fire({
                                                                        icon: 'warning',
                                                                        text: 'Are you sure, you want to reject this Reimbursement ?',
                                                                        cancelButtonText: "Cancel",
                                                                        showCancelButton: true,
                                                                        inputPlaceholder: 'Reason',
                                                                        input: 'text',
                                                                        customClass: {
                                                                            validationMessage: 'my-validation-message'
                                                                        },
                                                                        preConfirm: (value) => {
                                                                            if (!value) {
                                                                                Swal.showValidationMessage(
                                                                                    'Reason is required'
                                                                                )
                                                                            }
                                                                        }
                                                                    }).then(({ isConfirmed, value }) => {
                                                                        if (isConfirmed) {
                                                                            $("#progress-loader").removeClass("hidden");
                                                                            props.actionReimbursementApprovReject({
                                                                                "reimbursement_id": props.location.state.id,
                                                                                "status": "reject_by_manager",
                                                                                "remark": value.length > 0 ? value : '',
                                                                            })
                                                                        }
                                                                    })
                                                                    // }
                                                                }}
                                                            >
                                                                Reject
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
            <ApprovalMobileClaim
                data={objReimbursementItem}
                amount={totalAmount}
                approvedAmount={totalApprovedAmount}
                financeApprovedAmount={totalFinanceApprovedAmount}
                details={reimbursementApprovalDetail}
                userType='manager'
                show={showmobileapprovalclaim}
                id={reimbursementId}
                remTitle={remTitle}
                handleClose={(newMobileClaim) => {
                    setShowMobileApprovalClaim(false)
                    if (props?.location?.state?.type === 'NEW_REIMBURSMENT') {
                        props.actionReimbursementApprovalView({
                            id: props.location.state.id,
                        });
                    } else {
                        props.actionReimbursementApprovalView({
                            user_id: props.location.state?.user_id,
                            month: props.location.state?.month,
                            year: props.location.state?.rem_year
                        });
                    }
                }}
            />
            <ApprovalConveyanceClaim
                claimDetail={reimbursementApprovalDetail}
                data={objReimbursementItem}
                amount={totalAmount}
                approvedAmount={totalApprovedAmount}
                financeApprovedAmount={totalFinanceApprovedAmount}
                userType='manager'
                show={showconveyanceapprovalclaim}
                id={reimbursementId}
                remTitle={remTitle}
                handleClose={(newMobileClaim) => {
                    setShowConveyanceApprovalClaim(false)
                    if (props?.location?.state?.type === 'NEW_REIMBURSMENT') {
                        props.actionReimbursementApprovalView({
                            id: props.location.state.id,
                        });
                    } else {
                        props.actionReimbursementApprovalView({
                            user_id: props.location.state?.user_id,
                            month: props.location.state?.month,
                            year: props.location.state?.rem_year
                        });
                    }
                }}
            />
            <ApprovalOtherClaim
                data={objReimbursementItem}
                amount={totalAmount}
                approvedAmount={totalApprovedAmount}
                financeApprovedAmount={totalFinanceApprovedAmount}
                details={reimbursementApprovalDetail}
                userType='manager'
                show={showotherapprovalclaim}
                id={reimbursementId}
                remTitle={remTitle}
                handleClose={(newMobileClaim) => {
                    setShowOtherApprovalClaim(false)
                    if (props?.location?.state?.type === 'NEW_REIMBURSMENT') {
                        props.actionReimbursementApprovalView({
                            id: props.location.state.id,
                        });
                    } else {
                        props.actionReimbursementApprovalView({
                            user_id: props.location.state?.user_id,
                            month: props.location.state?.month,
                            year: props.location.state?.rem_year
                        });
                    }
                }}
            />
            <RejectClaims
                show={showrejectclaim}
                handleClose={(newMobileClaim) => {
                    setShowRejectClaim(false)

                }}

            />
        </>
    )
}
const mapStateToProps = (state) => {
    var ReimbursementApprovalView = null;
    var ReimbursementApproveReject = null;
    if (
        state.ViewReimbursementReducer != null &&
        Object.keys(state.ViewReimbursementReducer).length > 0 &&
        state.ViewReimbursementReducer.constructor === Object
    ) {
        switch (state.ViewReimbursementReducer.tag) {
            case ACTION_REIMBURSEMENT_APPROVAL_VIEW_SUCCESS:
                ReimbursementApprovalView = Object.assign({}, state.ViewReimbursementReducer.data);
                delete state.ViewReimbursementReducer;
                return { ReimbursementApprovalView };
            case ACTION_REIMBURSEMENT_APPROVE_REJECT_SUCCESS:
                ReimbursementApproveReject = Object.assign({}, state.ViewReimbursementReducer.data);
                delete state.ViewReimbursementReducer;
                return { ReimbursementApproveReject };
            default:
        }
    }
    return {
        ReimbursementApprovalView,
        ReimbursementApproveReject
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReimbursementApprovalView, actionReimbursementApprovReject }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReimbursementApprovalView))

