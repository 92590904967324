import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_FINANCE_LIMIT_ADD } from '../../../../../../api/constants';
import { API_MASTER_ENTITY } from '../../../../../../api/constants';
import { API_MASTER_SUB_ENTITY } from '../../../../../../api/constants';
import { API_MASTER_DESIGNATION, API_NATURE_EXPENCES_LIST } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_FINANCE_LIMIT_ADD_LOADING = 'ACTION_FINANCE_LIMIT_ADD_LOADING';
export const ACTION_FINANCE_LIMIT_ADD_SUCCESS = 'ACTION_FINANCE_LIMIT_ADD_SUCCESS';

export const ACTION_FINANCE_LIMIT_ENTITY_LOADING = 'ACTION_FINANCE_LIMIT_ENTITY_LOADING';
export const ACTION_FINANCE_LIMIT_ENTITY_SUCCESS = 'ACTION_FINANCE_LIMIT_ENTITY_SUCCESS';

export const ACTION_FINANCE_LIMIT_DEPARTMENT_LOADING = 'ACTION_FINANCE_LIMIT_DEPARTMENT_LOADING';
export const ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS = 'ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS';

export const ACTION_FINANCE_LIMIT_DESIGNATION_LOADING = 'ACTION_FINANCE_LIMIT_DESIGNATION_LOADING';
export const ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS = 'ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS';

export const ACTION_REIMBURSEMENT_TYPE_LOADING = 'ACTION_REIMBURSEMENT_TYPE_LOADING';
export const ACTION_REIMBURSEMENT_TYPE_SUCCESS = 'ACTION_REIMBURSEMENT_TYPE_SUCCESS';

export function actionFinanceLimitAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_FINANCE_LIMIT_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_ADD_LOADING, { loading: false, data }));
        });
}

export function actionFinanceMasterEntity(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_ENTITY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_ENTITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_ENTITY_LOADING, { loading: false, data }));
        });
}

export function actionFinanceMasterDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_SUB_ENTITY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionFinanceMasterDesignation(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_DESIGNATION, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_DESIGNATION_LOADING, { loading: false, data }));
        });
}

export function actionGetReimbursementType() {
    return (dispatch, getState) =>
        Api.get(API_NATURE_EXPENCES_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_TYPE_LOADING, { loading: false, data }));
        });
}


