/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiPlus, FiUser, FiPhone, FiShield, FiEdit, FiTrash, FiEye, FiTrash2 } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import Select from 'react-select';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED, characterLimit, customerMinCharacters, MEETING_FILE_SIZE, PRIMARY_COLOR, MEETING_FILE_ERROR_MESSAGE, customerMeetingCharacterLimit } from '../../../../../../constants';
import $ from "jquery";
import Swal from 'sweetalert2';
import { checkFileExtension, PRICE_FORMAT, PRINT } from '../../../../../../utils';
import ContactDetails from '../../../../../../component/form/contact_details';
import PolicyDetailModal from '../../../../../../component/form/policy_details';
import AddNewCompany from '../../../../../../component/form/new_customer';
import { getAccompainedByIds } from '../../../../../../utils';
import { actionMeetingType, ACTION_API_MEETING_TYPE_SUCCESS } from './action';
import { actionSelectCustomer, ACTION_SELECT_CUSTOMER_SUCCESS } from './action';
import { actionBusinessType, ACTION_API_BUSINESS_TYPE_SUCCESS } from './action';
import { actionCustomer, ACTION_API_CUSTOMER_SUCCESS } from './action';
import { actionPreviousMeeting, ACTION_API_PREVIOUS_MEETING_SUCCESS } from './action';
import { actionAccompainedBy, ACTION_API_ACCOMPAINED_BY_SUCCESS } from './action';
import { actionGetClientDetails, ACTION_MASTER_CLIENT_DETAILS_SUCCESS } from "./action";
import { actionCustomerMeetingContact, ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS } from "./action";
import { actionCustomerMeetingPolicy, ACTION_CUSTOMER_MEETING_POLICY_SUCCESS } from "./action";
import { actionCustomerMeetingsView, ACTION_CUSTOMER_MEETING_VIEW_SUCCESS } from './action';
import { actionCustomerMeetingsEdit, ACTION_CUSTOMER_MEETING_EDIT_SUCCESS } from './action';
import { actionMasterInsuranceCompanyName, ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS } from './action';
import { actionAddPolicy, ACTION_ADD_POLICY_SUCCESS } from './action';
import { actionEditPolicy, ACTION_EDIT_POLICY_SUCCESS } from './action';
import { actionCustomerMeetingSave, ACTION_CUSTOMER_MEETING_SAVE_SUCCESS } from './action';
import { actionCustomerMeetingAddContact, ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS } from './action';
import { actionDeleteDocument, ACTION_DOCUMENT_DELETE_SUCCESS } from './action';
import { actionPolicyDelete, ACTION_DELETE_POLICY_CUSTOMER_MEETING_SUCCESS } from './action';
// import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import ProposalDetailModal from '../../../../../../component/popups/proposal_details';
import { toast } from 'react-toastify';
import moment from 'moment';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const CustomerMeetingEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch, setValue, getValues } = useForm({
        defaultValues: {
            status: 'open'
        }
    })

    var meetingAgendaWatcher = watch('agenda', '')
    var meetingLocationWatcher = watch('location', '')
    var watchMeetInsurerCompany = watch('meet_insurer_company');
    var watchDecisionMakerStatus = watch('decision_maker_status');
    var watchMinutesOfMeeting = watch('minutes_of_meeting', '');

    const [customerMeetingsDetails, setCustomerMeetingsDetails] = useState(null);
    const [masterInsuranceCompanyName, setMasterInsuranceCompanyName] = useState([]);
    const [meetingType, setMeetingType] = useState();
    const [customer, setCustomer] = useState();
    const [businessType, setBusinessType] = useState();
    const [previousMeeting, setPreviousMeeting] = useState();
    const [accompainedBy, setAccompainedBy] = useState();
    const [convertedTo, setConvertedTo] = useState('open');
    const [addNewCompany, setAddNewCompany] = useState(false);
    const [clientDetails, setClientDetails] = useState();
    const [newCustomerId, setNewCustomerId] = useState(0)
    const [customerCompanyId, setCustomerCompanyId] = useState(0)
    const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([])
    const [customerPolicyName, setCustomerPolicyName] = useState(null)
    const [customerCompanyName, setCustomerCompanyName] = useState(null)
    const [customerPolicyId, setCustomerPolicyId] = useState(0)
    const [selectedCompanyPolicy, setSelectedCompanyPolicy] = useState([])
    const [editPolicyIndex, setEditPolicyIndex] = useState(-1)
    const [contactDetails, setContactDetails] = useState([]);
    const [policyDetails, setPolicyDetails] = useState([]);
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [showPolicyDetails, setShowPolicyDetails] = useState(false)
    const [status, setStatus] = useState('open');
    const [clientType, setClientType] = useState('lead');
    const [meetingTypeDropdown, setMeetingTypeDropdown] = useState(null);
    const [nextMeeting, setNextMeeting] = useState(false);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [showProposalDetails, setShowProposalDetails] = useState(false);
    const [meetingTypeId, setMeetingTypeId] = useState(null);
    const [convertedToCustomer, setConvertedToCustomer] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [assignRMId, setAssignRMId] = useState(null);
    const [existingDate, setExistingDate] = useState(false);

    const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

    const initialRender = () => {
        if (props.location.state != null && props.location.state !== undefined) {
            if (props?.location?.state?.meeting_type === 'Lead' || props?.location?.state?.meeting_type === 'Client') {
                props.actionCustomerMeetingsView({ id: props.location.state.customer_meeting_id });
            } else {
                props.actionCustomerMeetingsView({ id: props.location.state.customer_meeting.id });
            }
        } else {
            props.history.goBack();
        }
    }
    useEffect(initialRender, []);

    // Call All Master API Once We Received The Customer Details
    useEffect(() => {

        if (customerMeetingsDetails != null) {

            props.actionMeetingType({});
            props.actionBusinessType({});
            props.actionAccompainedBy({});
            props.actionMasterInsuranceCompanyName();

            setCustomerCompanyId(customerMeetingsDetails?.client_id)
            setCustomerPolicyId(customerMeetingsDetails?.client_id)
            setCustomerCompanyName(customerMeetingsDetails?.client_name)
            setCustomerPolicyName(customerMeetingsDetails?.client_name)
            props.actionPreviousMeeting({ client_id: customerMeetingsDetails?.client_id });
            props.actionGetClientDetails({ id: customerMeetingsDetails?.client_id })
            props.actionCustomerMeetingContact({ client_id: customerMeetingsDetails?.client_id })
            props.actionCustomerMeetingPolicy({ client_id: customerMeetingsDetails?.client_id })

            var objSelectedPolicies = Object.assign([], customerMeetingsDetails.policy_ids);
            setSelectedCompanyPolicy(objSelectedPolicies);
        }
    }, [customerMeetingsDetails]);

    useEffect(() => {
        if (customerMeetingsDetails != null) {
            props.actionSelectCustomer({ client_type: customerMeetingsDetails.meeting_type });
        }
    }, [customerMeetingsDetails])

    // Call On Page Load To Get The Meeting Types **Meeting With**
    const onMeetingTypeUpdate = () => {
        const { MeetingType } = props;
        var MeetingTypeResponse = Object.assign({}, MeetingType);
        if (MeetingTypeResponse.result === true) {
            setMeetingType(MeetingTypeResponse.response);
            if (customerMeetingsDetails != null) {
                setValue('meeting_type',
                    customerMeetingsDetails.meeting_type === 'Client' ? 'client' : 'lead'
                );

            }
        } else {
            switch (MeetingTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMeetingTypeUpdate, [props.MeetingType]);

    // Call On Page Load To Get The Meeting Types **Meeting With**
    const onSelectCustomerUpdate = () => {
        const { Customers } = props;
        var CustomersResponse = Object.assign({}, Customers);
        if (CustomersResponse.result === true) {
            setCustomer(CustomersResponse.response);
        } else {
            switch (CustomersResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onSelectCustomerUpdate, [props.Customers]);

    const onMasterInsuranceCompanyNameUpdate = () => {
        const { MasterInsuranceCompanyName } = props;
        var MasterInsuranceCompanyNameResponse = Object.assign({}, MasterInsuranceCompanyName);
        if (MasterInsuranceCompanyNameResponse.result === true) {
            setMasterInsuranceCompanyName(MasterInsuranceCompanyNameResponse.response);
        } else {
            switch (MasterInsuranceCompanyNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterInsuranceCompanyNameUpdate, [props.MasterInsuranceCompanyName]);

    // Call On Page Load To Get Customer List **Select Customer**
    const onCustomerUpdate = () => {
        const { Customer } = props;
        var CustomerResponse = Object.assign({}, Customer);
        if (CustomerResponse.result === true) {
            setCustomer(CustomerResponse.response.client_list);
            // if (customerMeetingsDetails != null) {
            //     setValue('client_id', customerMeetingsDetails.client_id);
            // }
        } else {
            switch (CustomerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerUpdate, [props.Customer]);

    // Call On Page Load To Get Business Types **Meeting Type**
    const onBusinessTypeUpdate = () => {
        const { BusinessType } = props;
        var BusinessTypeResponse = Object.assign({}, BusinessType);
        if (BusinessTypeResponse.result === true) {
            setBusinessType(BusinessTypeResponse.response);
            if (customerMeetingsDetails != null) {
                setValue('business_type', customerMeetingsDetails.business_type);
            }
        } else {
            switch (BusinessTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onBusinessTypeUpdate, [props.BusinessType]);

    useEffect(() => {
        if (customer != null && customer.length > 0) {

            if (newCustomerId != null && newCustomerId > 0) {
                setTimeout(() => {
                    setValue('customer', newCustomerId)
                })
            }
            if (customerCompanyName > 0) {
                setTimeout(() => {
                    setValue('client_id', customerCompanyName)
                }, 500);
            }
            if (customerPolicyName > 0) {
                setTimeout(() => {
                    setValue('client_id', customerPolicyName)
                }, 500);
            }
        }
    }, [customer]);

    // Call When Select Customer Value Change
    const onClientDetailUpdate = () => {
        const { ClientDetails } = props;
        var ClientDetailsResponse = Object.assign({}, ClientDetails);
        if (ClientDetailsResponse.result === true) {
            setClientDetails(ClientDetailsResponse.response);
            if (contactDetails != null && contactDetails.length === 0) {
                var contacts = ClientDetailsResponse.response.contact_details != null && ClientDetailsResponse.response.contact_details.length > 0 ? Object.assign([], ClientDetailsResponse.response.contact_details) : []
                var selectedContacts = []
                // if(contacts.length > 0) {
                //     contacts.forEach((contact, index) => {
                //         selectedContacts.push(contact.id)
                //     });
                //     setSelectedCompanyContacts(selectedContacts)
                // }
                setContactDetails(ClientDetailsResponse.response.contact_details);
            }
            if (policyDetails != null && policyDetails.length === 0) {
                var policies = ClientDetailsResponse.response.policy_details != null && ClientDetailsResponse.response.policy_details.length > 0 ? Object.assign([], ClientDetailsResponse.response.policy_details) : []

                setPolicyDetails(policies);
            }
            setAssignRMId(ClientDetailsResponse?.response?.rm_details?.user_id)
        } else {
            switch (ClientDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onClientDetailUpdate, [props.ClientDetails]);

    // Call When Select Customer Value Change To Get Previous Meetings **Previous Meeting**
    const onPreviousMeetingUpdate = () => {
        const { PreviousMeeting } = props;
        var PreviousMeetingResponse = Object.assign({}, PreviousMeeting);
        if (PreviousMeetingResponse.result === true) {
            setPreviousMeeting(PreviousMeetingResponse.response);
            if (customerMeetingsDetails != null) {
                setValue('prev_meeting', customerMeetingsDetails.prev_meeting);
            }
        } else {
            switch (PreviousMeetingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onPreviousMeetingUpdate, [props.PreviousMeeting]);

    useEffect(() => {
        if (accompainedBy != null && accompainedBy.length > 0) {
            if (customerMeetingsDetails != null && customerMeetingsDetails.accompany_by != null) {
                setValue('accompained_by', customerMeetingsDetails.accompany_by);
            }
        }
    }, [accompainedBy])

    // Call On Page Load To Get The Accompained By
    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    // Call When Select Customer Value Change and Setup the Contacts Under **Contact Details** Section
    // NEED TO CHECK
    const onCompanyContactsUpdate = () => {
        const { MeetingContact } = props
        var MeetingContactsResponse = Object.assign({}, MeetingContact)
        if (MeetingContactsResponse.result === true) {
            setContactDetails(MeetingContactsResponse.response)
            setSelectedCompanyContacts([]);
            if (customerMeetingsDetails != null) {
                setSelectedCompanyContacts(customerMeetingsDetails.contact_ids);
            }
        } else {
            switch (MeetingContactsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setContactDetails([])
                    setSelectedCompanyContacts([])
                    break;
                default:
            }
        }
    }
    useEffect(onCompanyContactsUpdate, [props.MeetingContact])

    // Call When Select Customer Value Change and Setup the Proposal Under **Proposal Details** Section
    const onCompanyPolicyUpdate = () => {
        const { MeetingPolicy } = props
        var MeetingPolicyResponse = Object.assign({}, MeetingPolicy)
        if (MeetingPolicyResponse.result === true) {
            setPolicyDetails(MeetingPolicyResponse.response);
            // if (customerMeetingsDetails != null) {
            //     setSelectedCompanyPolicy(customerMeetingsDetails.policy_ids);
            // }
        } else {
            switch (MeetingPolicyResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setPolicyDetails([])
                    // setSelectedCompanyPolicy([])
                    break;
                default:
            }
        }
    }
    useEffect(onCompanyPolicyUpdate, [props.MeetingPolicy])

    // Call When New Policy Added
    const onAddPolicyUpdate = () => {

        const { AddPolicy } = props;
        if (AddPolicy != null) {
            var AddPolicyResponse = Object.assign({}, AddPolicy);
            if (AddPolicyResponse.result === true) {
                props.actionCustomerMeetingPolicy({ client_id: customerPolicyId });
                var newPolicy = Object.assign({}, AddPolicyResponse.response);
                var arrPolicies = Object.assign([], policyDetails);
                arrPolicies.push(newPolicy);
                var arrSelectedCompanyPolicy = Object.assign([], selectedCompanyPolicy);
                arrSelectedCompanyPolicy.push(newPolicy.id);
                setPolicyDetails(arrPolicies);
                setSelectedCompanyPolicy(arrSelectedCompanyPolicy);
            } else {
                switch (AddPolicyResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAddPolicyUpdate, [props.AddPolicy]);

    const onEditPolicyUpdate = () => {

        const { EditPolicy } = props;
        if (EditPolicy != null) {
            var EditPolicyResponse = Object.assign({}, EditPolicy);
            if (EditPolicyResponse.result === true) {
                props.actionCustomerMeetingPolicy({ client_id: customerPolicyId });
                var newPolicy = Object.assign({}, EditPolicyResponse.response);
                var arrPolicies = Object.assign([], policyDetails);
                arrPolicies[editPolicyIndex] = newPolicy
                var arrSelectedCompanyPolicy = Object.assign([], selectedCompanyPolicy);
                if (!arrSelectedCompanyPolicy.includes(newPolicy.id)) {
                    arrSelectedCompanyPolicy.push(newPolicy.id);
                }
                setEditPolicyIndex(-1)
                setPolicyDetails(arrPolicies);
                setSelectedCompanyPolicy(arrSelectedCompanyPolicy);
            } else {
                switch (EditPolicyResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEditPolicyUpdate, [props.EditPolicy]);

    const onNewContactUpdate = () => {

        const { NewContact } = props;

        if (NewContact != null) {
            var NewContactResponse = Object.assign({}, NewContact);
            if (NewContactResponse.result === true) {
                var newContact = Object.assign({}, NewContactResponse.response);
                var arrContacts = Object.assign([], contactDetails);
                PRINT("CONTACT DETAILS", JSON.stringify(arrContacts))
                arrContacts.push(newContact);
                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                arrSelectedCompanyContact.push(newContact.id);
                setContactDetails(arrContacts);
                setSelectedCompanyContacts(arrSelectedCompanyContact);
            } else {
                switch (NewContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onNewContactUpdate, [props.NewContact]);

    const onPolicyDeleteUpdate = () => {
        const { PolicyDelete } = props;
        if (PolicyDelete != null) {
            var PolicyDeleteResponse = Object.assign({}, PolicyDelete);
            if (PolicyDeleteResponse.result === true) {
                toast.success(PolicyDeleteResponse.message);
                props.actionCustomerMeetingPolicy({ client_id: customerPolicyId });
            } else {
                switch (PolicyDeleteResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onPolicyDeleteUpdate, [props.PolicyDelete]);

    const onCustomerMeetingsViewUpdate = () => {
        const { CustomerMeetingsView } = props;
        var CustomerMeetingsViewResponse = Object.assign({}, CustomerMeetingsView);
        if (CustomerMeetingsViewResponse.result === true) {
            setCustomerMeetingsDetails(CustomerMeetingsViewResponse.response);
            setValue('start_date', dateFormat(new Date(CustomerMeetingsViewResponse.response.start_date_time), "yyyy-mm-dd"));
            if (moment(CustomerMeetingsViewResponse.response.start_date_time).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                setExistingDate(true);
            } else {
                setExistingDate(false);
            }
            setValue('from_time', dateFormat(CustomerMeetingsViewResponse.response.start_date_time, 'HH:MM:ss'));
            setValue('to_time', dateFormat(CustomerMeetingsViewResponse.response.end_date_time, 'HH:MM:ss'));
            setValue('agenda', CustomerMeetingsViewResponse.response.agenda);
            setValue('location', (CustomerMeetingsViewResponse.response.location === null) ? '' : CustomerMeetingsViewResponse.response.location);
            setValue('description', (CustomerMeetingsViewResponse.response.description === null) ? '' : CustomerMeetingsViewResponse.response.description);
            setValue('created_by', CustomerMeetingsViewResponse.response.created_by);
            setValue('client_id', CustomerMeetingsViewResponse?.response?.client_name);
            setClientType(CustomerMeetingsViewResponse?.response?.client_type);
            setMeetingTypeDropdown(CustomerMeetingsViewResponse?.response?.meeting_type);

        } else {
            switch (CustomerMeetingsViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerMeetingsViewUpdate, [props.CustomerMeetingsView]);

    const onCustomerMeetingSaveUpdate = () => {
        const { CustomerMeetingSave } = props;
        var CustomerMeetingSaveResponse = Object.assign({}, CustomerMeetingSave);
        if (CustomerMeetingSaveResponse.result === true) {
            // $("#progress-loader").addClass("hidden");
            toast.success("Customer Meeting Saved Successfully");
            // setData(KEY_TOAST_MESSAGE, "Customer Meeting Saved Successfully");
            props.history.goBack();
        } else {
            // $("#progress-loader").addClass("hidden");
            switch (CustomerMeetingSaveResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(CustomerMeetingSaveResponse.message);
                default:
            }
        }
    }
    useEffect(onCustomerMeetingSaveUpdate, [props.CustomerMeetingSave]);

    const onCustomerMeetingsEditUpdate = () => {
        const { CustomerMeetingsEdit } = props;
        var CustomerMeetingsEditResponse = Object.assign({}, CustomerMeetingsEdit);
        $("#edit_customer").prop("disabled", false);
        if (CustomerMeetingsEditResponse.result === true) {

            if (nextMeeting === true) {

                var strMeetingType = '';

                if (customerMeetingsDetails.meeting_type === 'client') {
                    strMeetingType = 'client';
                } else {
                    if (getValues("converted_to") === "client") {
                        strMeetingType = 'client';
                    } else {
                        strMeetingType = 'lead';
                    }
                }

                var data = {
                    meeting_type: strMeetingType,
                    business_type: customerMeetingsDetails.business_type,
                    client_id: customerMeetingsDetails.client_id,
                    prev_meeting: customerMeetingsDetails.id,
                    start_date_time: getValues('start_date_time') + ' ' + getValues('from_time'),
                    end_date_time: getValues('start_date_time') + ' ' + getValues('to_time'),
                    created_by: props.auth.getUser().id,
                }
                props.actionCustomerMeetingSave(data);

            } else {
                toast.success("Customer Meeting Saved Successfully");
                // setData(KEY_TOAST_MESSAGE, "Customer Meeting Saved Successfully");
                $("#edit_customer-loader").addClass("hidden");
                // $("#progress-loader").addClass("hidden");
                props.history.goBack();
            }
        } else {
            // $("#progress-loader").addClass("hidden");
            $("#edit_customer-loader").addClass("hidden");
            switch (CustomerMeetingsEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerMeetingsEditUpdate, [props.CustomerMeetingsEdit]);

    const onCustomerMeetingDocumentDeleteUpdate = () => {
        const { DocumentDelete } = props
        var DocumentDeleteResponse = Object.assign({}, DocumentDelete)
        if (DocumentDeleteResponse.result === true) {
            $('#view_document').addClass('hidden')
        } else {
            switch (DocumentDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerMeetingDocumentDeleteUpdate, [props.DocumentDelete])

    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (selectedCompanyContacts.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Please select at least one Contact',
                icon: 'error'
            }).then(success => {

            })
        } else {
            if (fromTimeDate >= toTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: 'From Time cannot be greater than or equal to To Time',
                    icon: 'error'
                }).then(success => {

                })
            } else {

                data.id = customerMeetingsDetails.id;
                data.client_id = customerMeetingsDetails.client_id;
                data.created_by = props.auth.getUser().user_id;
                data.start_date_time = data.start_date + ' ' + data.from_time;
                data.end_date_time = data.start_date + ' ' + data.to_time;
                data.accompained_by = JSON.stringify(getAccompainedByIds(data.accompained_by));
                data.contact_ids = JSON.stringify(selectedCompanyContacts);
                data.policies_ids = JSON.stringify(selectedCompanyPolicy);
                // data.insurer_id = watchMeetInsurerCompany !== undefined && Number(watchMeetInsurerCompany) === 1 ? data.insurer_id : null
                // data.decision_maker = watchDecisionMakerStatus !== undefined && Number(watchDecisionMakerStatus) === 1 ? data.decision_maker : null

                var files = [];
                if (uploadDocument != null) {
                    files.push({ 'name': 'upload_document', 'value': uploadDocument });
                }
                if (data.prev_meeting === null || data.prev_meeting === undefined) {
                    delete data.prev_meeting;
                }

                delete data.from_time;
                delete data.next_meeting;
                delete data.to_time;
                delete data.start_date;
                delete data.upload_document;
                delete data.decision_maker_status;

                if (data.status === 'close') {
                    data.policy_details = JSON.stringify(Object.assign([], policyDetails))
                    delete data.next_from_time
                    delete data.next_to_time
                } else {
                    // delete data.meet_insurer_company;
                    // delete data.decision_maker;
                    // delete data.is_pinc_policy;
                    delete data.converted_to;
                }
                if (data.converted_to === 'client') {
                    var client_details = {
                        "id": clientDetails.id != null ? clientDetails.id : null,
                        "address": clientDetails.address != null ? clientDetails.address : null,
                        "email": clientDetails.email != null ? clientDetails.email : null,
                        "pincode": clientDetails.pincode != null ? clientDetails.pincode : null,
                        "mobile": clientDetails.mobile != null ? clientDetails.mobile : null,
                        "pan": clientDetails.pan != null ? clientDetails.pan : null,
                        "landline": clientDetails.landline != null ? clientDetails.landline : null,
                        "website": clientDetails.website != null ? clientDetails.website : null,
                        "contact_name": clientDetails.mobile != null ? clientDetails.mobile : null,
                        "contact_designation": clientDetails.contact_designation != null ? clientDetails.contact_designation : null,
                        "contact_phone": clientDetails.contact_phone != null ? clientDetails.contact_phone : null,
                        "contact_email": clientDetails.contact_email != null ? clientDetails.contact_email : null,
                        "company_group_name": clientDetails.company_group_name != null ? clientDetails.company_group_name : null,
                        "name": clientDetails.company_name != null ? clientDetails.company_name : null,
                        "category": clientDetails.category != null ? clientDetails.category : null,
                        "state": clientDetails.state_id != null ? clientDetails.state_id : null,
                        "city_id": clientDetails.city_id != null ? clientDetails.city_id : null,
                        "industrial_segment_id": clientDetails.industrial_segment_id != null ? clientDetails.industrial_segment_id : null,
                        "form_of_organization_id": clientDetails.form_of_organization_id != null ? clientDetails.form_of_organization_id : null,
                        "multi_branch": clientDetails.multi_branch != null && clientDetails.multi_branch === 'Yes' ? 1 : 0,
                        "lead_source_id": clientDetails.lead_source_id != null ? clientDetails.lead_source_id : null,
                        "created_by": clientDetails.created_by != null ? clientDetails.created_by : null,
                        "clientOrLead": 'client',
                        "client_type": 'client',
                        "is_product_specialist_required": clientDetails.isProductSpecialist != null ? clientDetails.isProductSpecialist : null,
                        "vertical": clientDetails.vertical != null ? clientDetails.vertical : null,
                        "client_of": clientDetails.client_of != null ? clientDetails.client_of : null,
                        "contact_details": clientDetails.contact_details != null ? clientDetails.contact_details : [],
                        "policy_details": clientDetails.policy_details != null ? clientDetails.policy_details : [],
                        "primary_contact": clientDetails.primary_contact != null ? clientDetails.primary_contact : {},
                    }

                    if (
                        client_details.category != null &&
                        client_details.pincode != null &&
                        client_details.email != null &&
                        client_details.mobile != null &&
                        client_details.state != null &&
                        client_details.city_id != null &&
                        client_details.industrial_segment_id != null &&
                        client_details.form_of_organization_id != null &&
                        client_details.multi_branch != null &&
                        client_details.contact_name != null &&
                        client_details.lead_source_id != null &&
                        client_details.created_by != null
                        // client_details.is_product_specialist_required != null
                    ) {
                        data.client_details = client_details;
                        $("#edit_customer-loader").removeClass("hidden");
                        $("#edit_customer").prop("disabled", true);
                        if(data?.prev_meeting ==null ||data?.prev_meeting ==undefined){
                            delete data?.prev_meeting
                        }
                        props.actionCustomerMeetingsEdit(data, files);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: 'Converted To Customer Failed'
                        })
                    }
                } else {
                    $("#edit_customer-loader").removeClass("hidden");
                    $("#edit_customer").prop("disabled", true);
                    if(data?.prev_meeting ==null ||data?.prev_meeting ==undefined){
                        delete data?.prev_meeting
                    }
                    props.actionCustomerMeetingsEdit(data, files);
                }
            }
        }


    }

    return (
        <>
            <ScreenTitle title="Edit Customer Meeting" />
            {/* <h1>{props.location.state?.customer_meeting.created_by}</h1> */}
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Meeting Information
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting With&nbsp;<span className="danger">*</span></label>

                                                    <Controller
                                                        name="meeting_type"
                                                        className="form-control"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={meetingType}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Meeting With"
                                                                value={field.value}
                                                                enabled={false}
                                                                change={(event) => {

                                                                    field.onChange(event.itemData.value)
                                                                    if (event.itemData.value === 'lead') {
                                                                        setMeetingTypeId(event.itemData.value);
                                                                        $("#customer-field").removeClass("col-12 col-md-6 col-lg-6");
                                                                        $("#customer-field").addClass("col-12 col-md-4 col-lg-4");
                                                                        $("#customer-new-button").removeClass("hidden");
                                                                    } else {
                                                                        $("#customer-field").addClass("col-12 col-md-6 col-lg-6");
                                                                        $("#customer-field").removeClass("col-12 col-md-4 col-lg-4");
                                                                        $("#customer-new-button").addClass("hidden");
                                                                    }
                                                                }}

                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Meeting Information is required'
                                                        }}
                                                    />
                                                    {errors.meeting_type && <ErrorLabel message={errors.meeting_type?.message} />}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Select Customer&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="client_id"
                                                        name="client_id"
                                                        disabled={true}
                                                        placeholder="Select Customer"
                                                        {...register('client_id', { required: 'Customer is require' })}
                                                    />
                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* {
                                                clientType === 'client' && meetingTypeDropdown != 'lead' ? */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="business_type"
                                                        className="form-control"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={businessType}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Meeting Type"
                                                                value={field.value}
                                                                enabled={false}
                                                                change={(event) => field.onChange(event.itemData.value)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Meeting Type is required'
                                                        }}
                                                    />
                                                    {errors.business_type && <ErrorLabel message={errors.business_type?.message} />}
                                                </div>
                                            </div>
                                            {/* :
                                                    <></>
                                            } */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Previous Meeting</label>
                                                    <Controller
                                                        name="prev_meeting"
                                                        className="form-control"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                dataSource={previousMeeting}
                                                                fields={{ text: 'title', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Previous Meeting"
                                                                value={field.value}
                                                                enabled={false}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                    />
                                                    {errors.prev_meeting && <ErrorLabel message={errors.prev_meeting?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="start_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                min={moment(new Date()).subtract(60, 'days').calendar()}
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    if (moment(value).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                                                                        setExistingDate(true);
                                                                    } else {
                                                                        setExistingDate(false);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Next Meeting Start Date is required'
                                                        }}
                                                    />
                                                    {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">From Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="from_time"
                                                        name="from_time"
                                                        placeholder="--:-- --"
                                                        {...register('from_time', { required: 'From Time is required' })}
                                                    />
                                                    {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">To Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="next_to_time"
                                                        name="to_time"
                                                        placeholder="--:-- --"
                                                        {...register('to_time', { required: 'To Time is required' })}
                                                    />
                                                    {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                                </div>
                                            </div>

                                            {/* call when select customer value **Contact Details** */}
                                            {
                                                customerCompanyId > 0 ?
                                                    [<div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={() => {
                                                                    setShowContactDetails(true)
                                                                }}
                                                            >
                                                                <FiPlus
                                                                    cursor="pointer"
                                                                    size={20}
                                                                />
                                                            </button>
                                                        </h4>
                                                    </div>,
                                                    <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                        {
                                                            contactDetails != null && contactDetails.length > 0 ?
                                                                <table className="table table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>Contact Name</th>
                                                                            <th>Contact Designation</th>
                                                                            <th>Contact Phone</th>
                                                                            <th>Contact Email</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            contactDetails.map((contact, indexContact) => (
                                                                                <tr key={contact.id}>
                                                                                    <th>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            id={`${contact.id}`}
                                                                                            defaultChecked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                            checked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                            onChange={(event) => {
                                                                                                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                                                                                                if (!arrSelectedCompanyContact.includes(contact.id)) {
                                                                                                    arrSelectedCompanyContact.push(contact.id)
                                                                                                    setSelectedCompanyContacts(arrSelectedCompanyContact)
                                                                                                } else {
                                                                                                    var contactIndex = arrSelectedCompanyContact.findIndex((objContact, index) => objContact === contact.id);
                                                                                                    if (contactIndex >= 0) {
                                                                                                        arrSelectedCompanyContact.splice(contactIndex, 1);
                                                                                                        setSelectedCompanyContacts(arrSelectedCompanyContact);
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </th>
                                                                                    <td>{contact.contact_name}</td>
                                                                                    <td>{contact.contact_designation}</td>
                                                                                    <td>{contact.contact_phone}</td>
                                                                                    <td>{contact.contact_email}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <></>
                                                        }
                                                        <ErrorLabel message={customerCompanyId > 0 && contactDetails.length === 0 ? 'No contacts found' : ''} />
                                                    </div>
                                                    ]
                                                    :
                                                    <></>
                                            }

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="agenda"
                                                        name="agenda"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Meeting Agenda"
                                                        {...register('agenda', { required: 'Meeting Agenda is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingAgendaWatcher != null ? meetingAgendaWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.agenda && <ErrorLabel message={errors.agenda?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Meeting Location&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="location"
                                                        name="location"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Meeting Location"
                                                        {...register('location', { required: 'Meeting Location is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingLocationWatcher != null ? meetingLocationWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.location && <ErrorLabel message={errors.location?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Accompanied By</label>
                                                    <Controller
                                                        control={control}

                                                        name="accompained_by"
                                                        render={({ field }) => {
                                                            return (
                                                                <Select
                                                                    {...field}
                                                                    placeholder="Select"
                                                                    menuPlacement={"top"}
                                                                    isMulti={true}
                                                                    hideSelectedOptions={false}
                                                                    maxMenuHeight={200}
                                                                    name="name"
                                                                    options={accompainedBy}
                                                                    value={field.value}
                                                                    onChange={(selectedOptions) => {
                                                                        field.onChange(selectedOptions);
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Upload Document</label>
                                                        <div class="custom-file">
                                                            <input type="file"
                                                                class="custom-file-input"
                                                                id="upload_document"
                                                                name="upload_document"
                                                                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx"
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.files
                                                                    ) {

                                                                        var fileSize = event.target.files[0].size;

                                                                        var ext = (event.target.files[0].name).split('.').pop();

                                                                        if (checkFileExtension(ext, validExt)) {

                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                setUploadDocument(event.target.files[0]);
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }

                                                                        } else {

                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'You can only upload jpg, jpeg, png, pdf, doc, docx, xlsx, xls, ppt & pptx files',
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })

                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label class="custom-file-label" for="upload_document">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument.name : "Choose file"
                                                                }
                                                            </label>
                                                            <div className="mt-1">
                                                                {
                                                                    (customerMeetingsDetails != null && customerMeetingsDetails.meeting_document != null) ?
                                                                        <div id="view_document">
                                                                            {/* <a target="_blank" href={customerMeetingsDetails.meeting_document}>View Document</a> */}
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (customerMeetingsDetails.meeting_document?.split('.').pop() == 'pdf' ||
                                                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'PDF' ||
                                                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'png' ||
                                                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'jpg' ||
                                                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'jpeg' ||
                                                                                        customerMeetingsDetails.meeting_document?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(customerMeetingsDetails.meeting_document)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(customerMeetingsDetails?.meeting_document?.split('.').pop())
                                                                                        setDocumentDetails(customerMeetingsDetails)
                                                                                    } else {
                                                                                        downloadFile(customerMeetingsDetails?.meeting_document);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                            <FiTrash
                                                                                onClick={() => {
                                                                                    Swal.fire({
                                                                                        icon: 'warning',
                                                                                        text: 'Are you sure, you want to delete this document?',
                                                                                        cancelButtonText: "Cancel",
                                                                                        showCancelButton: true,
                                                                                    }).then(({ isConfirmed }) => {
                                                                                        if (isConfirmed) {
                                                                                            props.actionDeleteDocument({
                                                                                                "dms_id": customerMeetingsDetails.document_dms_id,
                                                                                                "type": "meeting",
                                                                                                "id": customerMeetingsDetails.id
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}
                                                                                size={"16"}
                                                                                cursor={"pointer"}
                                                                                className="ml-half"
                                                                            ></FiTrash>
                                                                        </div>
                                                                        : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={
                                                                    existingDate != true
                                                                        ? [
                                                                            {
                                                                                name: 'Update',
                                                                                id: 'open'
                                                                            },
                                                                            {
                                                                                name: 'Cancel',
                                                                                id: 'cancel'
                                                                            }
                                                                        ]
                                                                        : [
                                                                            {
                                                                                name: 'Update',
                                                                                id: 'open'
                                                                            },
                                                                            {
                                                                                name: 'Cancel',
                                                                                id: 'cancel'
                                                                            },
                                                                            {
                                                                                name: 'Close',
                                                                                id: 'close'
                                                                            }
                                                                        ]
                                                                }
                                                                fields={{ text: 'name', value: 'id' }}
                                                                placeholder="Select status"
                                                                value={field.value}
                                                                change={(event) => {

                                                                    if (event.itemData != null) {

                                                                        field.onChange(event.itemData.id)
                                                                        setStatus(event.itemData.id)

                                                                        if (event.itemData.id === 'cancel') {
                                                                            setNextMeeting(false)
                                                                        }

                                                                        if (event.itemData.id !== 'close') {
                                                                            setValue('minutes_of_meeting', '');
                                                                            setValue('remark', '');
                                                                            setValue('converted_to', '');
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>

                                            {/* call when status close **Status** */}
                                            {
                                                status === 'close' ?
                                                    [<div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Minutes Of Meeting&nbsp;<span className="danger">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                id="minutes_of_meeting"
                                                                name="minutes_of_meeting"
                                                                placeholder="Enter Minutes Of Meeting"
                                                                maxLength={characterLimit}
                                                                rows={5}
                                                                {...register('minutes_of_meeting', { required: 'Minutes of meeting is required', minLength: customerMinCharacters })}
                                                            />
                                                            <div className="d-flex flex-row justify-content-between w-100">
                                                                <div className="flex-column"><small className="text-muted">{`Min ${customerMeetingCharacterLimit} characters  (Minutes Visible to Customer)`}</small></div>
                                                                <div className="flex-column"><small>{watchMinutesOfMeeting != null && watchMinutesOfMeeting != undefined ? watchMinutesOfMeeting?.length : 0}/{characterLimit}</small></div>
                                                            </div>
                                                            {errors.minutes_of_meeting?.type === 'required' && <ErrorLabel message={errors.minutes_of_meeting?.message} />}
                                                            {errors.minutes_of_meeting?.type === 'minLength' && <ErrorLabel message={'Minimum Length 50 is required'} />}
                                                        </div>
                                                    </div>,
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Remarks</label>
                                                            <textarea
                                                                className="form-control"
                                                                id="remark"
                                                                name="remark"
                                                                placeholder="Enter Remarks"
                                                                rows={5}
                                                                {...register('remark')}
                                                            />
                                                            {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                        </div>
                                                    </div>,
                                                    // <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                    //     <label>Whether met with Insurance Company ?</label>
                                                    //     <div className="row mt-half mb-half">
                                                    //         <div className="radio ml-1 mr-1">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={1}
                                                    //                     name="meet_insurer_company"
                                                    //                     {...register('meet_insurer_company')}
                                                    //                 />
                                                    //                 Yes
                                                    //             </label>
                                                    //         </div>
                                                    //         <div className="radio">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={0}
                                                    //                     name="meet_insurer_company"
                                                    //                     {...register('meet_insurer_company')}
                                                    //                 />
                                                    //                 No
                                                    //             </label>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>,
                                                    // <>
                                                    //     {
                                                    //         watchMeetInsurerCompany !== undefined && Number(watchMeetInsurerCompany) === 1 ?
                                                    //             <div className="col-12 col-md-6 col-lg-6">
                                                    //                 <div className="form-group">
                                                    //                     <label className="label-control">Insurance Company</label>
                                                    //                     <Controller
                                                    //                         name="insurer_id"
                                                    //                         control={control}

                                                    //                         render={({ field }) =>
                                                    //                             <DropDownListComponent
                                                    //                                 {...field}
                                                    //                                 dataSource={masterInsuranceCompanyName}
                                                    //                                 fields={{ text: 'name', value: 'id' }}
                                                    //                                 allowFiltering={true}
                                                    //                                 placeholder="Select Insurance Company"
                                                    //                                 value={field.value}
                                                    //                                 change={(event) => {
                                                    //                                     if (event.itemData != null) {
                                                    //                                         field.onChange(event.itemData.id)
                                                    //                                     }
                                                    //                                 }}
                                                    //                             />
                                                    //                         }
                                                    //                     />
                                                    //                     {errors.insurer_id && <ErrorLabel message={errors.insurer_id?.message} />}
                                                    //                 </div>
                                                    //             </div>
                                                    //             :
                                                    //             <></>
                                                    //     }
                                                    // </>
                                                    //     ,
                                                    // <>
                                                    //     {
                                                    //         watchMeetInsurerCompany !== undefined && Number(watchMeetInsurerCompany) === 1 ?
                                                    //             <div className="col-12 col-md-6 col-lg-6">
                                                    //                 <div className="form-group">
                                                    //                     <label className="label-control ">Quote</label>
                                                    //                     <input
                                                    //                         className="form-control"
                                                    //                         id="quote"
                                                    //                         name="quote"
                                                    //                         placeholder="Enter quote"
                                                    //                         {...register('quote')}
                                                    //                     />
                                                    //                     {errors.quote && <ErrorLabel message={errors.quote?.message} />}
                                                    //                 </div>
                                                    //             </div>
                                                    //             :
                                                    //             <></>
                                                    //     }
                                                    // </>
                                                    //     ,
                                                    // <div className="col-12 col-md-6 col-lg-6">
                                                    //     <label>Whether RM has met decision maker ?</label>
                                                    //     <div className="row mt-half mb-half">
                                                    //         <div className="radio ml-1 mr-1">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={1}
                                                    //                     name="decision_maker_status"
                                                    //                     {...register('decision_maker_status')}
                                                    //                 />Yes </label>
                                                    //         </div>
                                                    //         <div className="radio">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={0}
                                                    //                     name="decision_maker_status"
                                                    //                     {...register('decision_maker_status')}
                                                    //                 />No</label>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>
                                                    //     ,
                                                    // <div className="col-12 col-md-6 col-lg-6">
                                                    //     {
                                                    //         watchDecisionMakerStatus !== undefined && Number(watchDecisionMakerStatus) === 1 ?
                                                    //             <div className="form-group">
                                                    //                 <label className="label-control">Decision Maker Name</label>
                                                    //                 <Controller
                                                    //                     name="decision_maker"
                                                    //                     control={control}

                                                    //                     render={({ field }) =>
                                                    //                         <DropDownListComponent
                                                    //                             {...field}
                                                    //                             dataSource={contactDetails}
                                                    //                             fields={{ text: 'title', value: 'id' }}
                                                    //                             allowFiltering={true}
                                                    //                             placeholder="Select Decision Maker Name"
                                                    //                             value={field.value}
                                                    //                             change={(event) => field.onChange(event.itemData.id)}
                                                    //                         />
                                                    //                     }
                                                    //                 />
                                                    //                 {errors.decision_maker_name && <ErrorLabel message={errors.decision_maker_name?.message} />}
                                                    //             </div>
                                                    //             :
                                                    //             <></>
                                                    //     }
                                                    // </div>
                                                    //     ,

                                                    <>
                                                        {
                                                            clientType === 'lead' && meetingTypeId === 'lead' ?
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label className="label-control">Converted To</label>
                                                                        <Controller
                                                                            name="converted_to"
                                                                            control={control}
                                                                            render={({ field }) =>
                                                                                <DropDownListComponent
                                                                                    {...field}
                                                                                    dataSource={[
                                                                                        {
                                                                                            name: 'None of Above',
                                                                                            id: 'None of Above'
                                                                                        },
                                                                                        {
                                                                                            name: 'Customer',
                                                                                            id: 'client'
                                                                                        },
                                                                                        {
                                                                                            name: 'Lead',
                                                                                            id: 'lead'
                                                                                        }
                                                                                    ]}
                                                                                    fields={{ text: 'name', value: 'id' }}
                                                                                    placeholder="Select Converted To"
                                                                                    value={field.value}
                                                                                    change={(event) => {
                                                                                        if (event.itemData != null) {
                                                                                            setConvertedToCustomer(true)
                                                                                            if (event.itemData.id === 'client') {
                                                                                                setAddNewCompany(true)
                                                                                                // setMeetingTypeId(event.itemData.id);
                                                                                            }
                                                                                            setConvertedTo(event.itemData.id);
                                                                                            field.onChange(event.itemData.id);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                        />
                                                                        {errors.converted_to && <ErrorLabel message={errors.converted_to?.message} />}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                                        ,
                                                    // <div className="col-12 col-md-6 col-lg-6">
                                                    //     <label>Is Policy placed with PINC ?</label>
                                                    //     <div className="row mt-half mb-half">
                                                    //         <div className="radio ml-1 mr-1">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={1}
                                                    //                     name="is_pinc_policy"
                                                    //                     {...register('is_pinc_policy')}
                                                    //                 />
                                                    //                 Yes
                                                    //             </label>
                                                    //         </div>
                                                    //         <div className="radio">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={0}
                                                    //                     name="is_pinc_policy"
                                                    //                     {...register('is_pinc_policy')}
                                                    //                 />
                                                    //                 No
                                                    //             </label>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>,
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="next_meeting"
                                                                    id="next_meeting"
                                                                    {...register('next_meeting')}
                                                                    onChange={(event) => { setNextMeeting(event.target.checked); }}
                                                                />
                                                                {errors.next_meeting && <ErrorLabel message={errors.next_meeting?.message} />}
                                                                <label class="custom-control-label" for="next_meeting">Create Next Meeting</label>
                                                            </div>
                                                        </div>
                                                    </div>]
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>

                                    {/* call when **Create Next Meeting** checkBox click */}
                                    {
                                        nextMeeting ?
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h6 className="mb-2 font-medium-1 text-bold-600">Next Meeting Details</h6>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="start_date_time"
                                                            className="form-control"
                                                            control={control}

                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    className="form-control"
                                                                    pickerType="calendar"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    min={dateFormat(new Date)}
                                                                    showClearButton={true}
                                                                    acceptCustomValue={false}
                                                                    value={field.value}
                                                                    onValueChange={(value) => {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Start Date is required'
                                                            }}
                                                        />
                                                        {errors.start_date_time && <ErrorLabel message={errors.start_date_time?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">From Time</label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_from_time"
                                                            name="next_from_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_from_time', { required: 'From Time is required' })}
                                                        />
                                                        {errors.next_from_time && <ErrorLabel message={errors.next_from_time?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">To Time</label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_to_time"
                                                            name="next_to_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_to_time', { required: 'To Time is required' })}
                                                        />
                                                        {errors.next_to_time && <ErrorLabel message={errors.next_to_time?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                    {/* call when select customer value **Proposal Details** */}
                                    {
                                        customerPolicyId > 0 ?
                                            [<div className="col-12 col-md-12 col-lg-12 px-0">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <span><FiShield size={16} />&nbsp;Opportunity Details</span>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setEditPolicyIndex(-1)
                                                            setShowPolicyDetails(true)
                                                        }}
                                                    >
                                                        <FiPlus
                                                            cursor="pointer"
                                                            size={20}
                                                        />
                                                    </button>
                                                </h4>
                                            </div>,
                                            <div className="col-12 col-md-12 col-lg-12 mb-1 px-0">
                                                {
                                                    policyDetails != null && policyDetails.length > 0 ?
                                                        <table className="table table-striped table-bordered table-responsive">
                                                            <thead>
                                                                <tr>
                                                                    <th>Action</th>
                                                                    <th>Relevant</th>
                                                                    <th>Opportunity Type</th>
                                                                    <th>Policy Type</th>
                                                                    <th>Department</th>
                                                                    <th>Intermediary Type</th>
                                                                    <th>Intermediary Details/Broker Name</th>
                                                                    <th>Insurance Company</th>
                                                                    <th>Next Renewal on</th>
                                                                    {/* <th>Brokerage Amount</th> */}
                                                                    <th>Expected Premium</th>
                                                                    <th>Probability(%)</th>
                                                                    <th>Status</th>
                                                                    {
                                                                        status === 'close' ?
                                                                            ([
                                                                                <th>New Status</th>,
                                                                                <th>Remark</th>
                                                                            ])
                                                                            :
                                                                            <></>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    policyDetails.map((policy, indexPolicy) => (
                                                                        <tr key={policy.id}>
                                                                            <td>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn btn-icon'
                                                                                    onClick={() => {
                                                                                        setEditPolicyIndex(indexPolicy)
                                                                                        setShowProposalDetails(true);
                                                                                    }}
                                                                                >
                                                                                    <FiEye size={16} className="primary" />
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-icon"
                                                                                    onClick={() => {
                                                                                        setEditPolicyIndex(indexPolicy)
                                                                                        setShowPolicyDetails(true)
                                                                                    }}
                                                                                >
                                                                                    <FiEdit size={16} className="primary" />
                                                                                </button>
                                                                                {
                                                                                    moment(policy.created_at) < moment().subtract(60, "days") ?
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-icon"
                                                                                            onClick={() => {
                                                                                                Swal.fire({
                                                                                                    icon: 'warning',
                                                                                                    text: 'Are you sure, you want to delete this policy details?',
                                                                                                    cancelButtonText: "Cancel",
                                                                                                    showCancelButton: true,
                                                                                                }).then(({ isConfirmed }) => {
                                                                                                    if (isConfirmed) {
                                                                                                        props.actionPolicyDelete({ id: policy.id });
                                                                                                    }
                                                                                                })

                                                                                            }}
                                                                                        >
                                                                                            <FiTrash2 size={16} className="primary" />
                                                                                        </button>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`${policy.id}`}
                                                                                    name=""
                                                                                    defaultChecked={selectedCompanyPolicy.includes(policy.id) ? true : false}
                                                                                    checked={selectedCompanyPolicy.includes(policy.id) ? true : false}
                                                                                    onChange={(event) => {

                                                                                        var arrSelectedCompanyPolicy = Object.assign([], selectedCompanyPolicy)
                                                                                        if (!arrSelectedCompanyPolicy.includes(policy.id)) {
                                                                                            arrSelectedCompanyPolicy.push(policy.id)
                                                                                            setSelectedCompanyPolicy(arrSelectedCompanyPolicy)
                                                                                        } else {
                                                                                            var policyIndex = arrSelectedCompanyPolicy.findIndex((objPolicy, index) => objPolicy === policy.id);
                                                                                            if (policyIndex >= 0) {
                                                                                                arrSelectedCompanyPolicy.splice(policyIndex, 1);
                                                                                                setSelectedCompanyPolicy(arrSelectedCompanyPolicy);
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>{policy.oppurtunity_type}</td>
                                                                            <td>{policy.policy_type}</td>
                                                                            <td>{policy.department_name}</td>
                                                                            <td>{policy.intermediary_type}</td>
                                                                            <td>{policy.intermediary_value}</td>
                                                                            <td>{policy.insurer_name}</td>
                                                                            <td>{(policy.lead_expiry_date != null && policy.lead_expiry_date != '0000-00-00 00:00:00') ? moment(policy.lead_expiry_date).format('MMM, DD YYYY') : ''}</td>
                                                                            {/* <td>{policy.brokerage_amnt != null ? <span>&#8377;&nbsp;{PRICE_FORMAT(policy.brokerage_amnt)}</span> : ''}</td> */}
                                                                            <td>{policy.expected_premium != null ? <span>&#8377;&nbsp;{PRICE_FORMAT(policy.expected_premium)}</span> : ''}</td>
                                                                            <td>{policy.probability}</td>
                                                                            <td>{policy.status}</td>
                                                                            {
                                                                                status === 'close' ?
                                                                                    ([
                                                                                        <td>
                                                                                            <div className="form-group" style={{ width: 200 }}>
                                                                                                <DropDownListComponent
                                                                                                    dataSource={[
                                                                                                        {
                                                                                                            name: 'Successful',
                                                                                                            id: 'Successful'
                                                                                                        },
                                                                                                        {
                                                                                                            name: 'Unsuccessful',
                                                                                                            id: 'Unsuccessful'
                                                                                                        },
                                                                                                        {
                                                                                                            name: 'Irrelevant',
                                                                                                            id: 'Irrelevant'
                                                                                                        },
                                                                                                        {
                                                                                                            name: 'Lead',
                                                                                                            id: 'Lead'
                                                                                                        },
                                                                                                        {
                                                                                                            name: 'Open',
                                                                                                            id: 'Open'
                                                                                                        },
                                                                                                        {
                                                                                                            name: 'Other',
                                                                                                            id: 'Other'
                                                                                                        },
                                                                                                    ]}
                                                                                                    fields={{ text: 'name', value: 'id' }}
                                                                                                    placeholder="Select Status"
                                                                                                    value={policy.new_status != null ? policy.new_status : ''}
                                                                                                    className="form-control"
                                                                                                    change={(event) => {
                                                                                                        if (event.itemData != null) {
                                                                                                            var arrPolicyDetails = Object.assign([], policyDetails)
                                                                                                            var objPolicy = Object.assign({}, arrPolicyDetails[indexPolicy])
                                                                                                            objPolicy.new_status = event.itemData.id
                                                                                                            arrPolicyDetails[indexPolicy] = objPolicy
                                                                                                            setPolicyDetails(arrPolicyDetails)
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </td>,
                                                                                        <td>
                                                                                            <div className="form-group" style={{ width: 200 }}>
                                                                                                <textarea
                                                                                                    className="form-control"
                                                                                                    placeholder="Remark"
                                                                                                    value={policy.remark != null ? policy.remark : ''}
                                                                                                    onChange={(event) => {
                                                                                                        var arrPolicyDetails = Object.assign([], policyDetails)
                                                                                                        var objPolicy = Object.assign({}, arrPolicyDetails[indexPolicy])
                                                                                                        objPolicy.remark = event.target.value != null ? event.target.value : ''
                                                                                                        arrPolicyDetails[indexPolicy] = objPolicy
                                                                                                        setPolicyDetails(arrPolicyDetails)
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                    ])
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <></>
                                                }
                                                <ErrorLabel message={customerPolicyId > 0 && policyDetails.length === 0 ? 'No Opportunity found' : ''} />
                                            </div>
                                            ]
                                            :
                                            <></>
                                    }
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="edit_customer"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="edit_customer-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        > Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <AddNewCompany
                    show={addNewCompany}
                    createClient={meetingTypeId != 'lead' ? true : false}
                    convertedToCustomer={convertedToCustomer}
                    clientId={customerMeetingsDetails != null ? customerMeetingsDetails.client_id : null}
                    handleClose={(newCompany) => {
                        if (newCompany != null) {
                            setNewCustomerId(newCompany.id)
                            props.actionCustomer({ vertical: "corporate" });
                        }
                        props.actionGetClientDetails({ id: clientDetails.id })
                        setAddNewCompany(false)
                    }}
                />
                <ContactDetails
                    show={showContactDetails}
                    handleClose={(newContact) => {
                        if (newContact != null) {
                            props.actionCustomerMeetingAddContact({
                                client_id: customerCompanyId,
                                client_type: "client",
                                contact_designation: newContact.contact_designation,
                                contact_email: newContact.contact_email,
                                contact_name: newContact.contact_name,
                                contact_phone: newContact.contact_phone,
                            });
                        }
                        setShowContactDetails(false)
                    }}
                />
                <PolicyDetailModal
                    show={showPolicyDetails}
                    assignRMId={assignRMId}
                    handleClose={(newPolicy) => {
                        if (newPolicy != null) {
                            if (newPolicy.hasOwnProperty('id')) {

                                props.actionEditPolicy({
                                    id: newPolicy.id,
                                    client_id: customerCompanyId,
                                    department_id: newPolicy.department_id,
                                    insurer_id: newPolicy.insurer_id,
                                    brokerage_amnt: newPolicy.brokerage_amnt,
                                    product_specialist_id: newPolicy.product_specialist_id,
                                    expected_premium: newPolicy.expected_premium,
                                    // expiring_date: newPolicy.oppurtunity_type != 'Fresh' ? newPolicy.expiring_date : '',
                                    expiring_date: newPolicy.expiring_date,
                                    probability: newPolicy.probability,
                                    policy_type_id: newPolicy.policy_type_id,
                                    client_remark: newPolicy.client_remark,
                                    is_product_specialist_required: newPolicy.is_product_specialist_required,
                                    opportunity_questions: newPolicy.opportunity_questions,
                                    oppurtunity_type: newPolicy.oppurtunity_type,
                                    intermediary_type: newPolicy.intermediary_type,
                                    intermediary_value: newPolicy.intermediary_value,
                                    posted_from: "pincnetwork"
                                })
                            } else {
                                props.actionAddPolicy({
                                    id: newPolicy.id,
                                    client_id: customerCompanyId,
                                    department_id: newPolicy.department_id,
                                    insurer_id: newPolicy.insurer_id,
                                    brokerage_amnt: newPolicy.brokerage_amnt,
                                    broker: newPolicy.broker,
                                    product_specialist_id: newPolicy.product_specialist_id,
                                    expected_premium: newPolicy.expected_premium,
                                    // expiring_date: newPolicy.oppurtunity_type != 'Fresh' ? newPolicy.expiring_date : '',
                                    expiring_date: newPolicy.expiring_date,
                                    probability: newPolicy.probability,
                                    policy_type_id: newPolicy.policy_type_id,
                                    client_remark: newPolicy.client_remark,
                                    is_product_specialist_required: newPolicy.is_product_specialist_required,
                                    opportunity_questions: newPolicy.opportunity_questions,
                                    oppurtunity_type: newPolicy.oppurtunity_type,
                                    intermediary_type: newPolicy.intermediary_type,
                                    intermediary_value: newPolicy.intermediary_value,
                                    posted_from: "pincnetwork"
                                })
                            }
                        }
                        setShowPolicyDetails(false)
                    }}
                    data={editPolicyIndex > -1 ? policyDetails[editPolicyIndex] : null}
                />
                <ProposalDetailModal
                    {...props}
                    show={showProposalDetails}
                    proposalDetails={editPolicyIndex > -1 ? policyDetails[editPolicyIndex] : null}
                    handleClose={() => {
                        setShowProposalDetails(false);
                    }}
                />
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}

const mapStateToProps = (state) => {

    var MeetingType = null;
    var MasterInsuranceCompanyName = null;
    var Customer = null;
    var Customers = null;
    var PreviousMeeting = null;
    var AccompainedBy = null;
    var ClientDetails = null;
    var MeetingContact = null;
    var MeetingPolicy = null;
    var EditPolicy = null;
    var BusinessType = null;
    var CustomerMeetingsView = null;
    var CustomerMeetingsEdit = null;
    var AddPolicy = null;
    var NewContact = null;
    var CustomerMeetingSave = null;
    var DocumentDelete = null;
    var PolicyDelete = null;

    if (
        state.EditCustomerMeetingReducer != null &&
        Object.keys(state.EditCustomerMeetingReducer).length > 0 &&
        state.EditCustomerMeetingReducer.constructor === Object
    ) {
        switch (state.EditCustomerMeetingReducer.tag) {
            case ACTION_API_MEETING_TYPE_SUCCESS:
                MeetingType = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { MeetingType };
            case ACTION_SELECT_CUSTOMER_SUCCESS:
                Customers = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { Customers };
            case ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS:
                MasterInsuranceCompanyName = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { MasterInsuranceCompanyName };
            case ACTION_API_BUSINESS_TYPE_SUCCESS:
                BusinessType = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { BusinessType };
            case ACTION_API_CUSTOMER_SUCCESS:
                Customer = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { Customer };
            case ACTION_API_PREVIOUS_MEETING_SUCCESS:
                PreviousMeeting = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { PreviousMeeting };
            case ACTION_API_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { AccompainedBy };
            case ACTION_MASTER_CLIENT_DETAILS_SUCCESS:
                ClientDetails = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { ClientDetails };
            case ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS:
                MeetingContact = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { MeetingContact };
            case ACTION_CUSTOMER_MEETING_POLICY_SUCCESS:
                MeetingPolicy = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { MeetingPolicy };
            case ACTION_EDIT_POLICY_SUCCESS:
                EditPolicy = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { EditPolicy };
            case ACTION_CUSTOMER_MEETING_VIEW_SUCCESS:
                CustomerMeetingsView = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { CustomerMeetingsView };
            case ACTION_CUSTOMER_MEETING_EDIT_SUCCESS:
                CustomerMeetingsEdit = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { CustomerMeetingsEdit };
            case ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS:
                NewContact = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { NewContact }
            case ACTION_ADD_POLICY_SUCCESS:
                AddPolicy = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { AddPolicy };
            case ACTION_CUSTOMER_MEETING_SAVE_SUCCESS:
                CustomerMeetingSave = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { CustomerMeetingSave }
            case ACTION_DOCUMENT_DELETE_SUCCESS:
                DocumentDelete = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { DocumentDelete }
            case ACTION_DELETE_POLICY_CUSTOMER_MEETING_SUCCESS:
                PolicyDelete = Object.assign({}, state.EditCustomerMeetingReducer.data);
                delete state.EditCustomerMeetingReducer;
                return { PolicyDelete }
            default:
        }
    }
    return {
        MeetingType,
        Customers,
        MasterInsuranceCompanyName,
        BusinessType,
        Customer,
        PreviousMeeting,
        AccompainedBy,
        ClientDetails,
        MeetingContact,
        MeetingPolicy,
        AddPolicy,
        EditPolicy,
        NewContact,
        CustomerMeetingsView,
        CustomerMeetingsEdit,
        CustomerMeetingSave,
        DocumentDelete,
        PolicyDelete
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionMeetingType,
        actionSelectCustomer,
        actionBusinessType,
        actionGetClientDetails,
        actionCustomer,
        actionPreviousMeeting,
        actionAccompainedBy,
        actionCustomerMeetingContact,
        actionCustomerMeetingPolicy,
        actionMasterInsuranceCompanyName,
        actionAddPolicy,
        actionEditPolicy,
        actionCustomerMeetingAddContact,
        actionCustomerMeetingsView,
        actionCustomerMeetingsEdit,
        actionCustomerMeetingSave,
        actionDeleteDocument,
        actionPolicyDelete
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CustomerMeetingEdit))