
import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

import { API_EDIT_EMPLOYMENT } from '../../../../../../api/constants';
import { API_GET_EMPLOYMENT_DETAIL } from '../../../../../../api/constants';

export const ACTION_EDIT_EMPLOYMENT_LOADING = 'ACTION_EDIT_EMPLOYMENT_LOADING';
export const ACTION_EDIT_EMPLOYMENT_SUCCESS = 'ACTION_EDIT_EMPLOYMENT_SUCCESS';

export const ACTION_GET_EMPLOYMENT_DETAIL_LOADING = 'ACTION_GET_EDUCATION_DETAIL_LOADING';
export const ACTION_GET_EMPLOYMENT_DETAIL_SUCCESS = 'ACTION_GET_EDUCATION_DETAIL_SUCCESS';

export function actionEditEmployment(params) {
    return (dispatch, getState) =>
        Api.post(API_EDIT_EMPLOYMENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_EMPLOYMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_EMPLOYMENT_LOADING, { loading: false, data }));
        });
}
export function actionGetEmploymentDetail(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_EMPLOYMENT_DETAIL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_EMPLOYMENT_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_EMPLOYMENT_DETAIL_LOADING, { loading: false, data }));
        });
}