import React from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from '../../../../../../component/form/error_label';

const Edit = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        alert(JSON.stringify(data));
    }

    return (
        <>
            <ScreenTitle title="Edit Lead Sources"/>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Lead Sources Name&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="lead_sources_name"
                                                    name="lead_sources_name"
                                                    placeholder="Enter Lead Sources Name"
                                                    {...register('lead_sources_name', { required: 'Lead Sources Name is required' })}
                                                />
                                                {errors.permission_name && <ErrorLabel message={errors.permission_name?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                            {...field}
                                                                dataSource={['Active', 'Inactive']}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.value)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Status is required'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                props.history.push('/settings/lead-sources')
                                            }}
                                        >Cancel</button>

                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Edit