import createReducer from "../../../reducers/createReducer";
import { ACTION_GET_EMPLOYEE_LIST_SUCCESS } from "./action";
import { ACTION_CREATE_POST_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const ActivityCreatePostReducer = createReducer(INITIAL_STATE, {
    [ACTION_GET_EMPLOYEE_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_EMPLOYEE_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CREATE_POST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CREATE_POST_SUCCESS,
                loading: false,
            }
        );
    },
});
export default ActivityCreatePostReducer;
