import React, { useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { withAuth } from 'react-auth-guard';
import $ from "jquery";
import { actionItSupportList, ACTION_IT_SUPPORT_SUCCESS } from './action';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UNAUTHORIZED } from '../../../../../../constants';
import _ from 'lodash';
import moment from "moment";

const ItSupportList = (props) => {

    const [itSupport, setItSupport] = useState([]);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "ticket_no", caption: "Ticket No.", visible: true },
        { dataField: "raised_date", caption: "Ticket Raise Date", visible: false },
        { dataField: "raised_date_formated", caption: "Ticket Raise Date", visible: true },
        { dataField: "name", caption: " Type of Issue", visible: true },
        { dataField: "description", caption: "Description", visible: true },
        { dataField: "priority", caption: "Priority", visible: true },
        { dataField: "action_date", caption: "Last Action Date", visible: false },
        { dataField: "action_date_formated", caption: "Last Action Date", visible: true },
        { dataField: "status_name", caption: "Current Status", visible: true },
    ]);

    const initialRender = () => {

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
        props.actionItSupportList({});
    }
    useEffect(initialRender, []);

    const onItSupportListUpdate = () => {
        const { ItSupportList } = props;
        if (ItSupportList != null) {
            var ItSupportListResponse = Object.assign({}, ItSupportList);
            if (ItSupportListResponse.result === true) {
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "ticket_no", caption: "Ticket No.", visible: true },
                    { dataField: "raised_date", caption: "Ticket Raise Date", visible: false },
                    { dataField: "raised_date_formated", caption: "Ticket Raise Date", visible: true },
                    { dataField: "name", caption: " Type of Issue", visible: true },
                    { dataField: "description", caption: "Description", visible: true },
                    { dataField: "priority", caption: "Priority", visible: true },
                    { dataField: "action_date", caption: "Last Action Date", visible: false },
                    { dataField: "action_date_formated", caption: "Last Action Date", visible: true },
                    { dataField: "status_name", caption: "Current Status", visible: true },
                ]);
                setItSupport(ItSupportListResponse.response);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ItSupportListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onItSupportListUpdate, [props.ItSupportList])

    const renderActionDate = (cell) => {
        return <div>{cell?.data?.action_date_formated != null ? moment(cell?.data?.action_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderRaisedDate = (cell) => {
        return <div>{cell?.data?.raised_date_formated != null ? moment(cell?.data?.raised_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `/hrms/it-support/view`, state: { id: params.data.id } })
                                    }}

                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField == 'priority') {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={(cell) => {
                                return _.startCase(cell.data.priority)
                            }}
                            visible={objColumn.visible}
                        ></Column>
                    )
                } else if (objColumn.dataField == 'name') {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={(cell) => {
                                return _.startCase(cell.data.name)
                            }}
                            visible={objColumn.visible}
                        ></Column>
                    )
                } else if (objColumn.dataField === "action_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderActionDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "raised_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderRaisedDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>
                    )
                }

            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">IT Support</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6 my-auto">
                        <ul class="nav nav-pills float-right">
                            <li class="nav-item">
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                    onClick={() => {
                                        props.history.push({ pathname: `/hrms/it-support/add` });
                                    }}>
                                    Raise New Ticket
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={itSupport}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "It Support Details",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}

                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("action_date_formated", "visible", false)
                                        event.component.columnOption("action_date", "visible", true)
                                        event.component.columnOption("raised_date_formated", "visible", false)
                                        event.component.columnOption("raised_date", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("action_date_formated", "visible", true)
                                        event.component.columnOption("action_date", "visible", false)
                                        event.component.columnOption("raised_date_formated", "visible", true)
                                        event.component.columnOption("raised_date", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    {/* {renderStatus()}
                                    {renderTravelType()} */}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var ItSupportList = null;
    if (
        state.ItSupportReducer != null &&
        Object.keys(state.ItSupportReducer).length > 0 &&
        state.ItSupportReducer.constructor === Object
    ) {
        switch (state.ItSupportReducer.tag) {
            case ACTION_IT_SUPPORT_SUCCESS:
                ItSupportList = Object.assign({}, state.ItSupportReducer.data);
                delete state.ItSupportReducer;
                return { ItSupportList };
            default:
        }
    }
    return {
        ItSupportList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItSupportList }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ItSupportList))