
import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_LOSS_SITE_VISIT_LIST, API_CLAIM_DOWNLOAD } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_LOSS_SITE_VISIT_LOADING = 'ACTION_LOSS_SITE_VISIT_LOADING';
export const ACTION_LOSS_SITE_VISIT_SUCCESS = 'ACTION_LOSS_SITE_VISIT_SUCCESS';

export const ACTION_CLAIM_DOWNLOAD_LOADING = 'ACTION_CLAIM_DOWNLOAD_LOADING';
export const ACTION_CLAIM_DOWNLOAD_SUCCESS = 'ACTION_CLAIM_DOWNLOAD_SUCCESS';

export function actionLossSiteVisit(params) {
    return (dispatch, getState) =>
        Api.post(API_LOSS_SITE_VISIT_LIST,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_LOSS_SITE_VISIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_LOSS_SITE_VISIT_LOADING, { loading: false, data }));
        });
}

export function actionClaimDownload(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIM_DOWNLOAD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_DOWNLOAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_DOWNLOAD_LOADING, { loading: false, data }));
        });
}