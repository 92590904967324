/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser, FiPhone, FiPlus, FiTrash } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import NewTpaAdd from '../../../../../../component/form/new_tpa';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { ERROR, UNAUTHORIZED, characterLimit, MEETING_FILE_SIZE, MEETING_FILE_ERROR_MESSAGE, PRIMARY_COLOR } from '../../../../../../constants';
import { actionAccompainedBy, ACTION_API_ACCOMPAINED_BY_SUCCESS } from './action';
import { actionEditGetTpaName, ACTION_EDIT_GET_TPA_NAME_SUCCESS } from './action';
import { actionTpaMeetingsView, ACTION_TPA_MEETING_VIEW_SUCCESS } from './action';
import { actionTPAMeetingEditContact, ACTION_TPAMEETINGEDITCONTACT_SUCCESS } from './action';
import { actionGetCompanyContacts, ACTION_TPA_GET_CONTACT_SUCCESS } from './action';
import { actionTpaMeetingsEdit, ACTION_TPA_MEETING_EDIT_SUCCESS } from './action';
import { actionDeleteDocument, ACTION_DOCUMENT_DELETE_SUCCESS } from './action';
import ContactDetails from '../../../../../../component/form/contact_details';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import { toast } from 'react-toastify';
import { checkFileExtension, getAccompainedByIds } from '../../../../../../utils';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const TPAMeetingEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues, watch } = useForm()

    var meetingAgendaWatcher = watch('agenda', '')
    var meetingLocationWatcher = watch('location', '')
    var minutesOfMeetingWatcher = watch('minutes_of_meeting', '')

    const [showContactDetails, setShowContactDetails] = useState(false)
    const [tpaCompanyId, setTpaCompanyId] = useState(0)
    const [status, setStatus] = useState('')
    const [companyContacts, setCompanyContacts] = useState([])
    const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([])
    const [showNewTpaEdit, setShowNewTpaEdit] = useState(false);
    const [tpaNames, setTpaNames] = useState([]);
    const [tpaMeetingsDetails, setTpaMeetingsDetails] = useState(null);
    const [accompainedBy, setAccompainedBy] = useState([]);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [existingDate, setExistingDate] = useState(false);

    const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

    const initialRender = () => {
        if (props.location.state !== undefined) {
            props.actionTpaMeetingsView({ id: props.location.state.tpa_meeting_id });
        } else {
            props.history.goBack();
        }
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (tpaMeetingsDetails != null) {
            props.actionEditGetTpaName({ clientOf: "tpa" });
            props.actionAccompainedBy({});
        }
    }, [tpaMeetingsDetails])

    // Call On Page Load To Get The TPA Names
    const onGetTpaNameUpdate = () => {
        const { GetTpaName } = props;
        var GetTpaNameResponse = Object.assign({}, GetTpaName);
        if (GetTpaNameResponse.result === true) {
            setTpaNames(GetTpaNameResponse.response);
        } else {
            switch (GetTpaNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetTpaNameUpdate, [props.GetTpaName]);

    useEffect(() => {
        if (tpaMeetingsDetails != null && tpaMeetingsDetails.accompany_by != null) {
            if (accompainedBy != null && accompainedBy.length > 0) {
                setValue('accompained_by', tpaMeetingsDetails.accompany_by);
            }
        }
    }, [accompainedBy])

    // Call On Page Load To Get The Accompained By
    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);

        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    // Set The Newly Added TPA In Dropdown
    useEffect(() => {
        if (tpaNames != null && tpaNames.length > 0) {
            if (tpaCompanyId > 0) {
                setTimeout(() => {
                    setValue('client_id', tpaCompanyId)
                }, 500);
            } else {
                setValue('client_id', tpaMeetingsDetails.client_id);
            }
        }
    }, [tpaNames]);

    // Call View API To Get the Details of Meeting 
    const onTpaMeetingsViewUpdate = () => {
        const { TpaMeetingsView } = props;
        var TpaMeetingsViewResponse = Object.assign({}, TpaMeetingsView);
        if (TpaMeetingsViewResponse.result === true) {

            setTpaMeetingsDetails(TpaMeetingsViewResponse.response);

            setValue('start_date', dateFormat(new Date(TpaMeetingsViewResponse.response.start_date_time), "yyyy-mm-dd"));
            if (moment(TpaMeetingsViewResponse.response.start_date_time).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                setExistingDate(true);
            } else {
                setExistingDate(false);
            }
            setValue('from_time', dateFormat(TpaMeetingsViewResponse.response.start_date_time, 'HH:MM:ss'));
            setValue('to_time', dateFormat(TpaMeetingsViewResponse.response.end_date_time, 'HH:MM:ss'));
            setValue('agenda', TpaMeetingsViewResponse.response.agenda);
            setValue('location', (TpaMeetingsViewResponse.response.location === null) ? '' : TpaMeetingsViewResponse.response.location);
            setValue('description', (TpaMeetingsViewResponse.response.description === null) ? '' : TpaMeetingsViewResponse.response.description);
            setValue('created_by', TpaMeetingsViewResponse.response.created_by);

        } else {
            switch (TpaMeetingsViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onTpaMeetingsViewUpdate, [props.TpaMeetingsView]);

    // Call When New Contact Is Added
    const onTPAMeetingEditContactUpdate = () => {

        const { TPAMeetingEditContact } = props;

        if (TPAMeetingEditContact != null) {
            var TPAMeetingEditContactResponse = Object.assign({}, TPAMeetingEditContact);
            if (TPAMeetingEditContactResponse.result === true) {
                var newContact = Object.assign({}, TPAMeetingEditContactResponse.response);
                var arrContacts = Object.assign([], companyContacts);
                arrContacts.push(newContact);
                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                arrSelectedCompanyContact.push(newContact.id);
                setCompanyContacts(arrContacts);
                setSelectedCompanyContacts(arrSelectedCompanyContact);
            } else {
                switch (TPAMeetingEditContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onTPAMeetingEditContactUpdate, [props.TPAMeetingEditContact]);

    // Call When TPA Name is Changed From Dropdown
    const onCompanyContactsUpdate = () => {
        const { MeetingContacts } = props
        var MeetingContactsResponse = Object.assign({}, MeetingContacts)
        if (MeetingContactsResponse.result === true) {
            setCompanyContacts(MeetingContactsResponse.response);
            setSelectedCompanyContacts([])
            if (tpaMeetingsDetails != null) {
                setSelectedCompanyContacts(tpaMeetingsDetails.contact_ids);
            }
        } else {
            switch (MeetingContactsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setCompanyContacts([])
                    setSelectedCompanyContacts([])
                    break;
                default:
            }
        }
    }
    useEffect(onCompanyContactsUpdate, [props.MeetingContacts]);

    const onTpaMeetingsEditUpdate = () => {
        const { TpaMeetingsEdit } = props;
        var TpaMeetingsEditResponse = Object.assign({}, TpaMeetingsEdit);
        $("#edit_tpa").prop("disabled", false);
        if (TpaMeetingsEditResponse.result === true) {
            // $("#progress-loader").addClass("hidden");
            $("#edit_tpa-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "TPA Meeting Saved Successfully");
            props.history.goBack();
        } else {
            // $("#progress-loader").addClass("hidden");
            $("#edit_tpa-loader").addClass("hidden");
            switch (TpaMeetingsEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onTpaMeetingsEditUpdate, [props.TpaMeetingsEdit]);

    const onTPADocumentDeleteUpdate = () => {
        const { DocumentDelete } = props
        var DocumentDeleteResponse = Object.assign({}, DocumentDelete)
        if (DocumentDeleteResponse.result === true) {
            $('#view_document').addClass('hidden')
        } else {
            switch (DocumentDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onTPADocumentDeleteUpdate, [props.DocumentDelete])


    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (selectedCompanyContacts.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Please select at least one Contact',
                icon: 'error'
            }).then(success => {

            })
        } else {
            if (fromTimeDate >= toTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: 'From Time cannot be greater than or equal to To Time',
                    icon: 'error'
                }).then(success => {

                })
            } else {
                data.id = tpaMeetingsDetails.id;
                data.meeting_type = 'tpa';
                data.created_by = props.auth.getUser().user_id;
                data.start_date_time = data.start_date + ' ' + data.from_time;
                data.end_date_time = data.start_date + ' ' + data.to_time;
                data.client_contact_ids = JSON.stringify(selectedCompanyContacts);
                data.accompained_by = JSON.stringify(getAccompainedByIds(data.accompained_by));

                var files = [];
                if (uploadDocument != null) {
                    files.push({ 'name': 'upload_document', 'value': uploadDocument });
                }

                if (data.status.length === 0) {
                    delete data.status;
                }

                delete data.from_time;
                delete data.to_time;
                delete data.start_date;
                delete data.upload_document;
                $("#edit_tpa-loader").removeClass("hidden");
                $("#edit_tpa").prop("disabled", true);
                // $("#progress-loader").removeClass("hidden");
                props.actionTpaMeetingsEdit(data, files);
            }
        }
    }

    return (
        <>
            <ScreenTitle title="Edit TPA Meeting" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;TPA Meeting Information
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-9 col-lg-9">
                                        <div className="form-group" >
                                            <label className="label-control">TPA Name</label>
                                            <span className="danger">*</span>
                                            <Controller
                                                name="client_id"
                                                className="form-control"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={tpaNames}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={false}
                                                        placeholder="Select TPA Name"
                                                        value={field.value}

                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                                setTpaCompanyId(event.itemData.id)
                                                                props.actionGetCompanyContacts({ client_id: event.itemData.id })
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'TPA Name is required'
                                                }}
                                            />
                                            {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3 d-flex align-items-center">
                                        <button type="button" class="btn btn-primary btn-block round shadow"
                                            onClick={() => {
                                                setShowNewTpaEdit(true);
                                            }}
                                        > Add New TPA </button>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="start_date"
                                                className="form-control"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        min={moment(new Date()).subtract(60, 'days').calendar()}
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={true}
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                            if (moment(value).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                                                                setExistingDate(true);
                                                            } else {
                                                                setExistingDate(false);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Start Date is required'
                                                }}
                                            />
                                            {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">From Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                id="from_time"
                                                name="from_time"
                                                placeholder="--:-- --"
                                                {...register('from_time', { required: 'From Time is required' })}
                                            />
                                            {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">To Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                id="to_time"
                                                name="to_time"
                                                placeholder="--:-- --"
                                                {...register('to_time', { required: 'To Time is required' })}
                                            />
                                            {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                        </div>
                                    </div>
                                    {
                                        tpaCompanyId > 0 ?
                                            [<div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setShowContactDetails(true)
                                                        }}
                                                    >
                                                        <FiPlus
                                                            cursor="pointer"
                                                            size={20}
                                                        />
                                                    </button>
                                                </h4>
                                            </div>,
                                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                {
                                                    companyContacts != null && companyContacts.length > 0 ?
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Contact Name</th>
                                                                    <th>Contact Designation</th>
                                                                    <th>Contact Phone</th>
                                                                    <th>Contact Email</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    companyContacts.map((contact, indexContact) => (
                                                                        <tr key={contact.id}>
                                                                            <th>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`${contact.id}`}
                                                                                    name=""
                                                                                    defaultChecked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                    checked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                    onChange={(event) => {
                                                                                        var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                                                                                        if (!arrSelectedCompanyContact.includes(contact.id)) {
                                                                                            arrSelectedCompanyContact.push(contact.id)
                                                                                            setSelectedCompanyContacts(arrSelectedCompanyContact)
                                                                                        } else {
                                                                                            var contactIndex = arrSelectedCompanyContact.findIndex((objContact, index) => objContact == contact.id);
                                                                                            if (contactIndex >= 0) {
                                                                                                arrSelectedCompanyContact.splice(contactIndex, 1);
                                                                                                setSelectedCompanyContacts(arrSelectedCompanyContact);
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </th>
                                                                            <td>{contact.contact_name}</td>
                                                                            <td>{contact.contact_designation}</td>
                                                                            <td>{contact.contact_phone}</td>
                                                                            <td>{contact.contact_email}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <></>
                                                }
                                                <ErrorLabel message={tpaCompanyId > 0 && companyContacts.length === 0 ? 'No contacts found' : ''} />
                                            </div>
                                            ]
                                            :
                                            <></>
                                    }
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="agenda"
                                                name="agenda"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Agenda"
                                                {...register('agenda', { required: 'Meeting Agenda is required' })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                <div className="flex-column"><small>{meetingAgendaWatcher != null ? meetingAgendaWatcher.length : 0}/{characterLimit}</small></div>
                                            </div>
                                            {errors.agenda && <ErrorLabel message={errors.agenda?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group" >
                                            <label className="label-control ">Meeting Location</label>
                                            <textarea
                                                className="form-control"
                                                id="location"
                                                name="location"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Agenda"
                                                {...register('location')}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                <div className="flex-column"><small>{meetingLocationWatcher != null ? meetingLocationWatcher.length : 0}/{characterLimit}</small></div>
                                            </div>
                                            {errors.location && <ErrorLabel message={errors.location?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label-control ">Accompanied By</label>
                                            <Controller
                                                control={control}
                                                name="accompained_by"
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            placeholder="Select"
                                                            menuPlacement={"top"}
                                                            isMulti={true}
                                                            hideSelectedOptions={false}
                                                            maxMenuHeight={200}
                                                            options={accompainedBy}
                                                            value={field.value}
                                                            onChange={(selectedOptions) => {
                                                                if (selectedOptions != null) {
                                                                    field.onChange(selectedOptions);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }}
                                            />
                                            {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Description</label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Descrption"
                                                {...register('description')}
                                            />
                                            {errors.description && <ErrorLabel message={errors.description?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <fieldset class="form-group">
                                                <label for="basicInputFile">Upload Document</label>
                                                <div class="custom-file">
                                                    <input type="file"
                                                        class="custom-file-input"
                                                        id="upload_document"
                                                        name="upload_document"
                                                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx"
                                                        onChange={(event) => {
                                                            if (
                                                                event.target.files
                                                            ) {

                                                                var fileSize = event.target.files[0].size;

                                                                var ext = (event.target.files[0].name).split('.').pop();

                                                                if (checkFileExtension(ext, validExt)) {
                                                                    if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                        setUploadDocument(event.target.files[0]);
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Error',
                                                                            icon: 'error',
                                                                            html: MEETING_FILE_ERROR_MESSAGE,
                                                                            confirmButtonColor: PRIMARY_COLOR
                                                                        }).then((result) => {

                                                                        });
                                                                    }
                                                                } else {
                                                                    Swal.fire({
                                                                        title: 'Upload Error',
                                                                        text: 'You can only upload jpg, jpeg, png, pdf, doc, docx, xlsx, xls, ppt & pptx files',
                                                                        icon: 'error'
                                                                    }).then(success => {

                                                                    })
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-file-label" for="upload_document">
                                                        {
                                                            uploadDocument != null ?
                                                                uploadDocument.name : "Choose file"
                                                        }
                                                    </label>
                                                    <div className="mt-1">
                                                        {
                                                            (tpaMeetingsDetails != null && tpaMeetingsDetails.meeting_document != null)
                                                                ?
                                                                <div id="view_document">
                                                                    {/* <a target="_blank" href={tpaMeetingsDetails.meeting_document}>View Document</a> */}
                                                                    <a className="primary" id="action_risk_management_document"
                                                                        onClick={() => {

                                                                            if (tpaMeetingsDetails.meeting_document?.split('.').pop() == 'pdf' ||
                                                                                tpaMeetingsDetails.meeting_document?.split('.').pop() == 'PDF' ||
                                                                                tpaMeetingsDetails.meeting_document?.split('.').pop() == 'png' ||
                                                                                tpaMeetingsDetails.meeting_document?.split('.').pop() == 'jpg' ||
                                                                                tpaMeetingsDetails.meeting_document?.split('.').pop() == 'jpeg' ||
                                                                                tpaMeetingsDetails.meeting_document?.split('.').pop() == 'gif'
                                                                            ) {
                                                                                setSelectedDocumentIndex(tpaMeetingsDetails.meeting_document)
                                                                                setShowDocumentDetails(true);
                                                                                setExtension(tpaMeetingsDetails.meeting_document?.split('.').pop())
                                                                                setDocumentDetails(tpaMeetingsDetails)
                                                                            } else {
                                                                                downloadFile(tpaMeetingsDetails.meeting_document);
                                                                            }
                                                                        }}
                                                                    >
                                                                        View Document
                                                                    </a>
                                                                    <a id="downloadLink" className="d-none"></a>
                                                                    <FiTrash
                                                                        onClick={() => {
                                                                            Swal.fire({
                                                                                icon: 'warning',
                                                                                text: 'Are you sure, you want to delete this document?',
                                                                                cancelButtonText: "Cancel",
                                                                                showCancelButton: true,
                                                                            }).then(({ isConfirmed }) => {
                                                                                if (isConfirmed) {
                                                                                    props.actionDeleteDocument({
                                                                                        "dms_id": tpaMeetingsDetails.document_dms_id,
                                                                                        "type": "meeting",
                                                                                        "id": tpaMeetingsDetails.id
                                                                                    })
                                                                                }
                                                                            })
                                                                        }}
                                                                        size={"16"}
                                                                        cursor={"pointer"}
                                                                        className="ml-half"
                                                                    ></FiTrash>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Status</label>
                                            <Controller
                                                name="status"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={
                                                            existingDate != true
                                                                ? [
                                                                    {
                                                                        name: 'Update',
                                                                        id: ''
                                                                    },
                                                                    {
                                                                        name: 'Cancel',
                                                                        id: 'cancel'
                                                                    }
                                                                ]
                                                                : [
                                                                    {
                                                                        name: 'Update',
                                                                        id: ''
                                                                    },
                                                                    {
                                                                        name: 'Cancel',
                                                                        id: 'cancel'
                                                                    },
                                                                    {
                                                                        name: 'Close',
                                                                        id: 'close'
                                                                    }
                                                                ]
                                                        }
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={true}
                                                        placeholder="Select status"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {

                                                                field.onChange(event.itemData.id);
                                                                setStatus(event.itemData.id);

                                                                if (event.itemData.id !== 'close') {
                                                                    setValue('minutes_of_meeting', '');
                                                                    setValue('remark', '');
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                        </div>
                                    </div>
                                    {
                                        status === 'close' ?
                                            [<div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Minutes Of Meeting&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="minutes_of_meeting"
                                                        name="minutes_of_meeting"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Minutes Of Meeting"
                                                        {...register('minutes_of_meeting', { required: 'Minutes Of Meeting is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{minutesOfMeetingWatcher != null ? minutesOfMeetingWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.minutes_of_meeting && <ErrorLabel message={errors.minutes_of_meeting?.message} />}
                                                </div>
                                            </div>,
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remarks</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        rows={5}
                                                        placeholder="Enter Remarks"
                                                        {...register('remark')}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>]
                                            :
                                            <></>
                                    }

                                </div>
                            </div>
                            <div className="form-actions border-0 float-none">
                                <button
                                    id="edit_tpa"
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        &nbsp;Submit
                                        <span id="edit_tpa-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                                <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.goBack();
                                    }}
                                > Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
                <NewTpaAdd
                    show={showNewTpaEdit}
                    handleClose={(tpa) => {
                        if (tpa != null) {
                            setTpaCompanyId(tpa.id);
                            props.actionEditGetTpaName({ clientOf: "tpa" })
                        }
                        setShowNewTpaEdit(false)
                    }}
                />
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
            <ContactDetails
                show={showContactDetails}
                handleClose={(newContact) => {
                    if (newContact != null) {
                        props.actionTPAMeetingEditContact({
                            client_id: getValues("client_id"),
                            client_type: "client",
                            contact_designation: newContact.contact_designation,
                            contact_email: newContact.contact_email,
                            contact_name: newContact.contact_name,
                            contact_phone: newContact.contact_phone,
                        });
                    }
                    setShowContactDetails(false)
                }}
            />
        </>
    )

}

const mapStateToProps = (state) => {
    var GetTpaName = null;
    var TpaMeetingsView = null;
    var AccompainedBy = null;
    var TPAMeetingEditContact = null;
    var MeetingContacts = null;
    var TpaMeetingsEdit = null;
    var DocumentDelete = null;

    if (
        state.EditTpaMeetingReducer != null &&
        Object.keys(state.EditTpaMeetingReducer).length > 0 &&
        state.EditTpaMeetingReducer.constructor === Object
    ) {
        switch (state.EditTpaMeetingReducer.tag) {
            case ACTION_API_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.EditTpaMeetingReducer.data);
                delete state.EditTpaMeetingReducer;
                return { AccompainedBy };
            case ACTION_EDIT_GET_TPA_NAME_SUCCESS:
                GetTpaName = Object.assign({}, state.EditTpaMeetingReducer.data);
                delete state.EditTpaMeetingReducer;
                return { GetTpaName };
            case ACTION_TPA_MEETING_VIEW_SUCCESS:
                TpaMeetingsView = Object.assign({}, state.EditTpaMeetingReducer.data);
                delete state.EditTpaMeetingReducer;
                return { TpaMeetingsView };
            case ACTION_TPAMEETINGEDITCONTACT_SUCCESS:
                TPAMeetingEditContact = Object.assign({}, state.EditTpaMeetingReducer.data);
                delete state.EditTpaMeetingReducer;
                return { TPAMeetingEditContact };
            case ACTION_TPA_GET_CONTACT_SUCCESS:
                MeetingContacts = Object.assign({}, state.EditTpaMeetingReducer.data);
                delete state.EditTpaMeetingReducer;
                return { MeetingContacts };
            case ACTION_TPA_MEETING_EDIT_SUCCESS:
                TpaMeetingsEdit = Object.assign({}, state.EditTpaMeetingReducer.data);
                delete state.EditTpaMeetingReducer;
                return { TpaMeetingsEdit };
            case ACTION_DOCUMENT_DELETE_SUCCESS:
                DocumentDelete = Object.assign({}, state.EditTpaMeetingReducer.data);
                delete state.EditTpaMeetingReducer;
                return { DocumentDelete };
            default:
        }
    }
    return {
        GetTpaName,
        TpaMeetingsView,
        AccompainedBy,
        TPAMeetingEditContact,
        MeetingContacts,
        TpaMeetingsEdit,
        DocumentDelete
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionAccompainedBy,
        actionEditGetTpaName,
        actionTpaMeetingsView,
        actionTPAMeetingEditContact,
        actionGetCompanyContacts,
        actionTpaMeetingsEdit,
        actionDeleteDocument
    }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TPAMeetingEdit))