import React, { useState, useEffect } from "react"
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionNatureExpencesEdit, ACTION_NATURE_EXPENCES_EDIT_SUCCESS } from './action';
import { actionNatureExpencesView, ACTION_NATURE_EXPENCES_VIEW_SUCCESS } from './action';
import { toast } from "react-toastify";
const Edit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm()
    const { match } = props;
    const [natureexpences, setNatureExpences] = useState(null);
    const onSubmit = (data) => {
    }

    const onNatureExpencesViewUpdate = () => {
        const { NatureExpencesView } = props;
        var NatureExpencesViewResponse = Object.assign({}, NatureExpencesView);
        if (NatureExpencesViewResponse.result === true) {
            setNatureExpences(NatureExpencesViewResponse.response);
            setValue('name', NatureExpencesViewResponse.response.name);
            setValue('status', NatureExpencesViewResponse.response.status);
        } else {
            switch (NatureExpencesViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onNatureExpencesViewUpdate, [props.NatureExpencesView]);

    const onNatureExpencesEditUpdate = () => {
        const { NatureExpencesEdit } = props;
        var NatureExpencesEditResponse = Object.assign({}, NatureExpencesEdit);
        if (NatureExpencesEditResponse.result === true) {
            props.history.goBack();
        } else {
            switch (NatureExpencesEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(NatureExpencesEditResponse.message);
                default:
            }
        }
    }
    useEffect(onNatureExpencesEditUpdate, [props.NatureExpencesEdit]);
    return (
        <>
            <ScreenTitle title="Edit Nature of Expenses" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"> Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter  Name"
                                                        {...register('name', { required: ' Name is required' })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        title: "Active",
                                                                        id: 'active'
                                                                    },
                                                                    {
                                                                        title: "Inactive",
                                                                        id: 'inactive'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var NatureExpencesEdit = null;
    var NatureExpencesView = null;

    if (
        state.EditNatureExpencesReducer != null &&
        Object.keys(state.EditNatureExpencesReducer).length > 0 &&
        state.EditNatureExpencesReducer.constructor === Object
    ) {
        switch (state.EditNatureExpencesReducer.tag) {
            case ACTION_NATURE_EXPENCES_EDIT_SUCCESS:
                NatureExpencesEdit = Object.assign({}, state.EditNatureExpencesReducer.data);
                delete state.EditNatureExpencesReducer
                return { NatureExpencesEdit };
            case ACTION_NATURE_EXPENCES_VIEW_SUCCESS:
                NatureExpencesView = Object.assign({}, state.EditNatureExpencesReducer.data);
                delete state.EditNatureExpencesReducer
                return { NatureExpencesView };
            default:
        }
    }

    return {
        NatureExpencesEdit,
        NatureExpencesView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionNatureExpencesEdit, actionNatureExpencesView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)

