import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_API_MEETING_TYPE_SUCCESS, ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS, ACTION_CUSTOMER_MEETING_POLICY_SUCCESS, ACTION_EDIT_POLICY_SUCCESS, ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS } from './action';
import { ACTION_API_BUSINESS_TYPE_SUCCESS } from './action';
import { ACTION_API_CUSTOMER_SUCCESS } from './action';
import { ACTION_API_PREVIOUS_MEETING_SUCCESS } from "./action";
import { ACTION_API_ACCOMPAINED_BY_SUCCESS } from "./action";
import { ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS } from "./action";
import { ACTION_ADDPOLICY_SUCCESS } from './action';
import { ACTION_CUSTOMER_MEETING_SAVE_SUCCESS, ACTION_CUSTOMER_MEETING_NEXT_SUCCESS } from './action';
import { ACTION_SELECT_CUSTOMER_SUCCESS } from './action';

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddCustomerMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_MEETING_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MEETING_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SELECT_CUSTOMER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SELECT_CUSTOMER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_BUSINESS_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_BUSINESS_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_CUSTOMER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_CUSTOMER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_PREVIOUS_MEETING_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_PREVIOUS_MEETING_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CUSTOMER_MEETING_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CUSTOMER_MEETING_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADDPOLICY_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_ADDPOLICY_SUCCESS,
            loading: false
        });
    },
    [ACTION_EDIT_POLICY_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_EDIT_POLICY_SUCCESS,
            loading: false
        });
    },
    [ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS,
            loading: false
        });
    },
    [ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS,
            loading: false
        });
    },
    [ACTION_CUSTOMER_MEETING_SAVE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_CUSTOMER_MEETING_SAVE_SUCCESS,
            loading: false
        });
    },
    [ACTION_CUSTOMER_MEETING_NEXT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_CUSTOMER_MEETING_NEXT_SUCCESS,
            loading: false
        });
    }
});
export default AddCustomerMeetingReducer;
