import createReducer from "../../../reducers/createReducer";
import { ACTION_EDIT_COMPANY_SUCCESS, ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS, ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS, ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS, ACTION_MASTER_NEW_LEAD_STATES_SUCCESS } from "./action";
import { ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS } from "./action";
import { ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS } from "./action";
import { ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS } from "./action";
import { ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS } from "./action";
import { ACTION_ADD_NEW_COMPANY_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const CustomerAddNewCustomerReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_NEW_LEAD_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_LEAD_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADD_NEW_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_NEW_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
});
export default CustomerAddNewCustomerReducer;