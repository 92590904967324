import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { TagBox } from 'devextreme-react';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import ArrayStore from 'devextreme/data/array_store';
import { ACTION_EDIT_GET_DEPARTMENT_SUCCESS, actionGetDepartment } from './action';
import { ACTION_EDIT_GET_POLICY_TYPE_SUCCESS, actionGetPolicyType } from './action';
import { ACTION_EDIT_GET_INSURER_SUCCESS, actionGetCompanyList } from './action';
import { ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS, actionGetBranchList } from "./action"
import { ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS, actionEditSpecificPreference } from './action';
import { actionMasterGetInsurerBranchContact, ACTION_SPECIFIC_CONTACT_SUCCESS } from "./action";
import { actionGetCityList, actionGetStateList, ACTION_SPECIFIC_MASTER_STATES_SUCCESS, ACTION_SPECIFIC_MASTER_CITIES_SUCCESS } from './action'
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import $ from 'jquery'
import Swal from 'sweetalert2';

const SpecificPlacementEdit = (props) => {

    const { handleSubmit, control, formState: { errors }, register, setValue } = useForm();
    const [selectedPolicyType, setSelectedPolicyType] = useState(null)
    const [selectedContactPerson, setSelectedContactPerson] = useState([])
    const [insurerList, setInsurerList] = useState([])
    const [policyList, setPolicyList] = useState([])
    const [masterState, setMasterState] = useState([]);
    const [masterCity, setMasterCity] = useState([]);
    const [masterBranch, setMasterBranch] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [selectedDepartmentValue, setSelectedDepartmentValue] = useState(null);
    const [specificPlacementDetails, setSpecificPlacementDetails] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [contacts, setContacts] = useState()
    const [contactPerson, setContactPerson] = useState([])
    const [selectedInsurerId, setSelectedInsurerId] = useState()

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGetCompanyList({ status: "ACTIVE" });
        props.actionGetDepartment({
            status: "ACTIVE",
            // "eligible_only": "yes"
        });
        if (props?.location?.state?.data != null) {
            setSpecificPlacementDetails(props?.location?.state?.data);
            const contactIdsArray = [];
            props?.location?.state?.contactData.map((contact) => {
                contactIdsArray.push(contact.contact_id);
                setContacts(contactIdsArray)
            });
        }
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (specificPlacementDetails != null) {
            setValue('insurer_id', specificPlacementDetails.insurer_id)
            setValue('branch_id', specificPlacementDetails.branch_id)
            setValue('department_id', specificPlacementDetails.department_id)
            setValue('state', specificPlacementDetails.state_id)
            setValue('city', specificPlacementDetails.city_id)
            setValue('branch_id', specificPlacementDetails.branch_id)
            setValue('policy_type_id', specificPlacementDetails.policy_type_id)
            setValue('remark', specificPlacementDetails.remark)
            setValue('premium_min', specificPlacementDetails.premium_min)
            setValue('premium_max', specificPlacementDetails.premium_max)
            setValue('sum_insured_min', specificPlacementDetails.sum_insured_min)
            setValue('sum_insured_max', specificPlacementDetails.sum_insured_max)
            setValue('contact_ids', contacts)
        }
    }, [specificPlacementDetails])

    const onBranchContactListUpdate = () => {
        const { BranchContactList } = props
        if (BranchContactList != null) {
            var BranchContactListResponse = Object.assign({}, BranchContactList);
            if (BranchContactListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setContactPerson(BranchContactListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (BranchContactListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onBranchContactListUpdate, [props.BranchContactList])

    useEffect(() => {
        if (contactPerson != null && contactPerson.length > 0) {
            if (specificPlacementDetails != null && specificPlacementDetails.contacts.length > 0) {
                setValue('contact_ids', contacts)
            }
        }
    }, [contactPerson])

    const onMasterGetInsurerListUpdate = () => {
        const { MasterGetInsurerList } = props
        if (MasterGetInsurerList != null) {
            var MasterGetInsurerListResponse = Object.assign({}, MasterGetInsurerList);
            if (MasterGetInsurerListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setInsurerList(MasterGetInsurerListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetInsurerListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetInsurerListUpdate, [props.MasterGetInsurerList])

    useEffect(() => {
        if (insurerList != null && insurerList.length > 0) {
            if (specificPlacementDetails != null && specificPlacementDetails.insurer_id != null) {
                setValue('insurer_id', specificPlacementDetails.insurer_id)
            }
        }
    }, [insurerList])

    const onMasterGetDepartmentUpdate = () => {
        const { MasterGetDepartment } = props
        if (MasterGetDepartment != null) {
            var MasterGetDepartmentResponse = Object.assign({}, MasterGetDepartment);
            if (MasterGetDepartmentResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setDepartmentList(MasterGetDepartmentResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetDepartmentUpdate, [props.MasterGetDepartment])

    useEffect(() => {
        if (selectedDepartmentValue != null) {
            props.actionGetPolicyType({ 'department_id': selectedDepartmentValue, status: "ACTIVE" });
        }
    }, [selectedDepartmentValue])

    useEffect(() => {
        if (departmentList != null && departmentList.length > 0) {
            if (specificPlacementDetails != null && specificPlacementDetails.department_id != null) {
                setValue('department_id', specificPlacementDetails.department_id)
            }
        }
    }, [departmentList])

    const onMasterGetPolicyListUpdate = () => {
        const { MasterGetPolicyList } = props
        if (MasterGetPolicyList != null) {
            var MasterGetPolicyListResponse = Object.assign({}, MasterGetPolicyList);
            if (MasterGetPolicyListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPolicyList(MasterGetPolicyListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetPolicyListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetPolicyListUpdate, [props.MasterGetPolicyList])

    useEffect(() => {
        if (policyList != null && policyList.length > 0) {
            if (specificPlacementDetails != null && specificPlacementDetails.policy_type_id != null) {
                setValue('policy_type_id', specificPlacementDetails.policy_type_id)
            }
        }
    }, [policyList])

    const onMasterStatesUpdate = () => {
        const { MasterStates } = props
        if (MasterStates != null) {
            var MasterStatesResponse = Object.assign({}, MasterStates);
            if (MasterStatesResponse.result === true) {
                setMasterState(MasterStatesResponse.response);
            } else {
                switch (MasterStatesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterState([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterStatesUpdate, [props.MasterStates])

    useEffect(() => {
        if (masterState != null && masterState.length > 0) {
            if (specificPlacementDetails != null && specificPlacementDetails.state_id != null) {
                setValue('state', specificPlacementDetails.state_id)
            }
        }
    }, [masterState])

    const onMasterCitiesUpdate = () => {
        const { MasterCities } = props
        if (MasterCities != null) {
            var MasterCitiesResponse = Object.assign({}, MasterCities);
            if (MasterCitiesResponse.result === true) {
                setMasterCity(MasterCitiesResponse.response);
            } else {
                switch (MasterCitiesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterCity([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.MasterCities])

    useEffect(() => {
        if (selectedCity != null) {
            props.actionGetBranchList({ city_id: [selectedCity], "status": "ACTIVE", })
        }
    }, [selectedCity])

    useEffect(() => {
        if (masterCity != null && masterCity.length > 0) {
            if (specificPlacementDetails != null && specificPlacementDetails.city_id != null) {
                setValue('city', specificPlacementDetails.city_id)
            }
        }
    }, [masterCity])

    const onMasterBranchesUpdate = () => {
        const { MasterBranches } = props
        if (MasterBranches != null) {
            var MasterBranchesResponse = Object.assign({}, MasterBranches);
            if (MasterBranchesResponse.result === true) {
                setMasterBranch(MasterBranchesResponse.response);
            } else {
                switch (MasterBranchesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterCity([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBranchesUpdate, [props.MasterBranches])

    useEffect(() => {
        if (masterBranch != null && masterBranch.length > 0) {
            if (specificPlacementDetails != null && specificPlacementDetails.branch_id != null) {
                setValue('branch_id', specificPlacementDetails.branch_id)
            }
        }
    }, [masterBranch])

    const onEditSpecificPreferenceUpdate = () => {
        const { EditSpecificPreference } = props
        if (EditSpecificPreference != null) {
            var EditSpecificPreferenceResponse = Object.assign({}, EditSpecificPreference);
            $("#specific_placement").prop("disabled", false);
            if (EditSpecificPreferenceResponse.result === true) {
                $("#specific_placement-form-loader").addClass("hidden");
                // $("#progress-loader").addClass("hidden");
                props.history.goBack();
            } else {
                $("#specific_placement-form-loader").addClass("hidden");
                // $("#progress-loader").addClass("hidden");
                switch (EditSpecificPreferenceResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEditSpecificPreferenceUpdate, [props.EditSpecificPreference])

    const onSubmit = (data) => {

        const premiumMinEntered = data.premium_min != null && data.premium_min !== '';
        const premiumMaxEntered = data.premium_max != null && data.premium_max !== '';
        const sumInsuredMinEntered = data.sum_insured_min != null && data.sum_insured_min !== '';
        const sumInsuredMaxEntered = data.sum_insured_max != null && data.sum_insured_max !== '';

        if (
            (premiumMinEntered || premiumMaxEntered) ||
            (sumInsuredMinEntered || sumInsuredMaxEntered)
        ) {
            if (
                (premiumMinEntered && !premiumMaxEntered) ||
                (!premiumMinEntered && premiumMaxEntered) ||
                (sumInsuredMinEntered && !sumInsuredMaxEntered) ||
                (!sumInsuredMinEntered && sumInsuredMaxEntered) ||
                (premiumMinEntered && premiumMaxEntered && Number(data.premium_min) >= Number(data.premium_max)) ||
                (sumInsuredMinEntered && sumInsuredMaxEntered && Number(data.sum_insured_min) >= Number(data.sum_insured_max))
            ) {
                Swal.fire({
                    title: 'Error',
                    text: "Min value can't be greater than or equal to max value",
                    icon: 'error'
                }).then(success => {

                });
            } else {
                $("#specific_placement-form-loader").removeClass("hidden");
                $("#specific_placement").prop("disabled", true);
                props.actionEditSpecificPreference({
                    "id": props.location.state.data?.id,
                    "premium_min": data.premium_min,
                    "premium_max": data.premium_max,
                    "sum_insured_min": data.sum_insured_min,
                    "sum_insured_max": data.sum_insured_max,
                    "insurer_id": data.insurer_id,
                    "state": data.state,
                    "city": data.city,
                    "branch_id": data.branch_id,
                    "department_id": data.department_id,
                    "policy_type_id": data.policy_type_id,
                    "contact_ids": selectedContactPerson,
                    "remark": data.remark
                });
            }
        } else {
            $("#specific_placement-form-loader").removeClass("hidden");
            $("#specific_placement").prop("disabled", true);
            props.actionEditSpecificPreference({
                "id": props.location.state.data?.id,
                "premium_min": data.premium_min,
                "premium_max": data.premium_max,
                "sum_insured_min": data.sum_insured_min,
                "sum_insured_max": data.sum_insured_max,
                "insurer_id": data.insurer_id,
                "state": data.state,
                "city": data.city,
                "branch_id": data.branch_id,
                "department_id": data.department_id,
                "policy_type_id": data.policy_type_id,
                "contact_ids": selectedContactPerson,
                "remark": data.remark
            });
        }
    }

    return (
        <>
            <ScreenTitle title="Edit Specific Preference" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">Insurance Company&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="insurer_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="insurer_id"
                                                                name="insurer_id"
                                                                dataSource={insurerList}
                                                                allowFiltering={true}
                                                                sortOrder={"Ascending"}
                                                                filterType={"Contains"}
                                                                placeholder="Select Company name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectedInsurerId(event.itemData.id)
                                                                        props.actionGetStateList({ 'insurer_id': event.itemData.id });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Company name is required'
                                                        }}
                                                    />

                                                    {errors.insurer_id && <ErrorLabel message={errors.insurer_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">State&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="state"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="state"
                                                                name="state"
                                                                dataSource={masterState}
                                                                allowFiltering={false}
                                                                placeholder="Select State"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        props.actionGetCityList({ state_id: event.itemData.id, insurer_id: selectedInsurerId })

                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'State is required'
                                                        }}
                                                    />

                                                    {errors.state && <ErrorLabel message={errors.state?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">City&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="city"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="city"
                                                                name="city"
                                                                dataSource={masterCity}
                                                                allowFiltering={false}
                                                                placeholder="Select City"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectedCity(event.itemData.id)
                                                                        props.actionGetBranchList({ city_id: [event.itemData.id], "status": "ACTIVE", insurer_id: [selectedInsurerId] })
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'City is required'
                                                        }}
                                                    />

                                                    {errors.city && <ErrorLabel message={errors.city?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="branch_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="branch_id"
                                                                name="branch_id"
                                                                dataSource={masterBranch}
                                                                allowFiltering={true}
                                                                placeholder="Select Branch"
                                                                value={field.value}
                                                                fields={{ text: 'address', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        props.actionMasterGetInsurerBranchContact({ branch_id: event.itemData.id });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch is required'
                                                        }}
                                                    />

                                                    {errors.branch_id && <ErrorLabel message={errors.branch_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Department</label>
                                                    <Controller
                                                        name="department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="department_id"
                                                                name="department_id"
                                                                dataSource={departmentList}
                                                                allowFiltering={false}
                                                                placeholder="Select Department"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectedDepartmentValue(event.itemData.id)
                                                                        setSelectedPolicyType(null);
                                                                        setValue('policy_type_id', null);
                                                                        // props.actionGetPolicyType({ 'department_id': event.itemData.id });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Policy&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="policy_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="policy_type_id"
                                                                name="policy_type_id"
                                                                dataSource={policyList}
                                                                allowFiltering={false}
                                                                placeholder="Select Policy Type"
                                                                enabled={selectedDepartmentValue != null ? true : false}
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectedPolicyType(event.itemData.id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.policy_type_id && <ErrorLabel message={errors.policy_type_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">Contact Person&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="contact_ids"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                id="contact_ids"
                                                                name="contact_ids"
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: contactPerson,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        ('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        ('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        ('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        ('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                valueExpr="id"
                                                                placeholder="Select Contact Person"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={3}
                                                                selectAllMode={true}
                                                                showSelectionControls={true}
                                                                acceptCustomValue={false}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value != null && field.value.length > 0 ? field.value : selectedContactPerson}
                                                                onValueChanged={({ value, event }) => {
                                                                    if ((value != null || value !== undefined) && value.length > 0) {
                                                                        setSelectedContactPerson(value)
                                                                        field.onChange(value);
                                                                    } else {
                                                                        if (selectedContactPerson != null && selectedContactPerson.length > 0) {
                                                                            field.onChange("");
                                                                            setSelectedContactPerson([])
                                                                        }
                                                                    }
                                                                    if (event != null) {
                                                                        if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                            field.onChange("");
                                                                            setSelectedContactPerson([])
                                                                        }
                                                                    }
                                                                }}
                                                                onSelectionChanged={(e) => {
                                                                    if (e.addedItems.length > 0) {
                                                                        e.component.field().value = "";
                                                                        e.component.close();
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Contact Person'
                                                        }}
                                                    />
                                                    {errors.contact_ids && <ErrorLabel message={errors.contact_ids?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Premium Range</label>
                                                    <div className=' d-flex'>
                                                        <div className="col-6 px-0">
                                                            <input
                                                                className="form-control"
                                                                id="premium_min"
                                                                name="premium_min"
                                                                placeholder="Enter Min Premium"
                                                                {...register('premium_min', {
                                                                    // required: 'Min Premium is required'
                                                                })}
                                                            />
                                                            {errors.premium_min && <ErrorLabel message={errors.premium_min?.message} />}
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                className="form-control"
                                                                id="premium_max"
                                                                name="premium_max"
                                                                placeholder="Enter Max Premium"
                                                                {...register('premium_max', {
                                                                    // required: 'Min Premium is required'
                                                                })}
                                                            />
                                                            {errors.premium_max && <ErrorLabel message={errors.premium_max?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control">Sum Insured Range</label>
                                                    <div className=' d-flex'>
                                                        <div className="col-6 px-0">
                                                            <input
                                                                className="form-control"
                                                                id="sum_insured_min"
                                                                name="sum_insured_min"
                                                                placeholder="Enter Min Sum Insured"
                                                                {...register('sum_insured_min', {
                                                                    // required: 'Min Premium is required'
                                                                })}
                                                            />
                                                            {errors.sum_insured_min && <ErrorLabel message={errors.sum_insured_min?.message} />}
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                className="form-control"
                                                                id="sum_insured_max"
                                                                name="sum_insured_max"
                                                                placeholder="Enter max Sum Insured"
                                                                {...register('sum_insured_max', {
                                                                    // required: 'Max Sum Insured is required'
                                                                })}
                                                            />
                                                            {errors.sum_insured_max && <ErrorLabel message={errors.sum_insured_max?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Remarks&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        placeholder="Enter Remark"
                                                        {...register('remark', {
                                                            // required: 'Remark is required'
                                                        })}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="specific_placement"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="specific_placement-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var MasterGetDepartment = null;
    var MasterGetPolicyList = null;
    var MasterGetInsurerList = null;
    var MasterStates = null;
    var MasterCities = null;
    var MasterBranches = null;
    var EditSpecificPreference = null;
    var BranchContactList = null;

    if (
        state.MasterEditSpecificPreferenceReducer != null &&
        Object.keys(state.MasterEditSpecificPreferenceReducer).length > 0 &&
        state.MasterEditSpecificPreferenceReducer.constructor === Object
    ) {
        switch (state.MasterEditSpecificPreferenceReducer.tag) {
            case ACTION_EDIT_GET_DEPARTMENT_SUCCESS:
                MasterGetDepartment = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { MasterGetDepartment };
            case ACTION_EDIT_GET_POLICY_TYPE_SUCCESS:
                MasterGetPolicyList = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { MasterGetPolicyList };
            case ACTION_EDIT_GET_INSURER_SUCCESS:
                MasterGetInsurerList = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { MasterGetInsurerList };
            case ACTION_SPECIFIC_MASTER_STATES_SUCCESS:
                MasterStates = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { MasterStates };
            case ACTION_SPECIFIC_MASTER_CITIES_SUCCESS:
                MasterCities = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { MasterCities };
            case ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS:
                MasterBranches = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { MasterBranches };
            case ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS:
                EditSpecificPreference = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { EditSpecificPreference };
            case ACTION_SPECIFIC_CONTACT_SUCCESS:
                BranchContactList = Object.assign({}, state.MasterEditSpecificPreferenceReducer.data);
                delete state.MasterEditSpecificPreferenceReducer;
                return { BranchContactList };
            default:

        }
    }
    return {
        MasterGetDepartment,
        MasterGetPolicyList,
        MasterGetInsurerList,
        MasterStates,
        MasterCities,
        MasterBranches,
        EditSpecificPreference,
        BranchContactList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGetDepartment, actionGetPolicyType, actionGetCompanyList, actionGetStateList, actionGetCityList, actionGetBranchList, actionEditSpecificPreference, actionMasterGetInsurerBranchContact }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(SpecificPlacementEdit))