import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_DECLARATION_ADD } from '../../../../../../api/constants';

export const ACTION_IT_DECLARATION_ADD_LOADING = 'ACTION_IT_DECLARATION_ADD_LOADING';
export const ACTION_IT_DECLARATION_ADD_SUCCESS = 'ACTION_IT_DECLARATION_ADD_SUCCESS';

export function actionItDeclarationAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_ADD, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_ADD_LOADING, { loading: false, data }));
        });
}