import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_GET_DEPARTMENT } from '../../../../../../api/constants';
import { API_MASTER_GET_POLICY_TYPE } from '../../../../../../api/constants';
import { API_MASTER_GET_INSURER } from '../../../../../../api/constants';
import { API_MASTER_INSURER_CITIES } from '../../../../../../api/constants';
import { API_MASTER_INSURER_STATES } from '../../../../../../api/constants';
import { API_MASTER_GET_INSURER_BRANCH } from '../../../../../../api/constants';
import { API_MASTER_GET_BRANCH_CONTACT } from '../../../../../../api/constants';
import { API_EDIT_SPECIFIC_PREFERENCE } from '../../../../../../api/constants';

export const ACTION_EDIT_GET_DEPARTMENT_LOADING = 'ACTION_EDIT_GET_DEPARTMENT_LOADING';
export const ACTION_EDIT_GET_DEPARTMENT_SUCCESS = 'ACTION_EDIT_GET_DEPARTMENT_SUCCESS';

export const ACTION_EDIT_GET_POLICY_TYPE_LOADING = 'ACTION_EDIT_GET_POLICY_TYPE_LOADING';
export const ACTION_EDIT_GET_POLICY_TYPE_SUCCESS = 'ACTION_EDIT_GET_POLICY_TYPE_SUCCESS';

export const ACTION_EDIT_GET_INSURER_LOADING = 'ACTION_EDIT_GET_INSURER_LOADING';
export const ACTION_EDIT_GET_INSURER_SUCCESS = 'ACTION_EDIT_GET_INSURER_SUCCESS';

export const ACTION_SPECIFIC_MASTER_CITIES_LOADING = "ACTION_SPECIFIC_MASTER_CITIES_LOADING";
export const ACTION_SPECIFIC_MASTER_CITIES_SUCCESS = "ACTION_SPECIFIC_MASTER_CITIES_SUCCESS";

export const ACTION_SPECIFIC_MASTER_STATES_LOADING = "ACTION_SPECIFIC_MASTER_STATES_LOADING";
export const ACTION_SPECIFIC_MASTER_STATES_SUCCESS = "ACTION_SPECIFIC_MASTER_STATES_SUCCESS";

export const ACTION_SPECIFIC_MASTER_BRANCH_LOADING = "ACTION_SPECIFIC_MASTER_BRANCH_LOADING";
export const ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS = "ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS";

export const ACTION_EDIT_SPECIFIC_PREFERENCE_LOADING = "ACTION_EDIT_SPECIFIC_PREFERENCE_LOADING";
export const ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS = "ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS";

export const ACTION_SPECIFIC_CONTACT_LOADING = 'ACTION_SPECIFIC_CONTACT_LOADING';
export const ACTION_SPECIFIC_CONTACT_SUCCESS = 'ACTION_SPECIFIC_CONTACT_SUCCESS';

export function actionMasterGetInsurerBranchContact(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_BRANCH_CONTACT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_SPECIFIC_CONTACT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SPECIFIC_CONTACT_LOADING, { loading: false, data }));
        });
}

export function actionGetDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_DEPARTMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_GET_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_GET_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionGetPolicyType(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_GET_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_GET_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionGetCompanyList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_GET_INSURER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_GET_INSURER_LOADING, { loading: false, data }));
        });
}

export function actionGetStateList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_INSURER_STATES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_SPECIFIC_MASTER_STATES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SPECIFIC_MASTER_STATES_LOADING, { loading: false, data }));
        });
}

export function actionGetCityList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_INSURER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_SPECIFIC_MASTER_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SPECIFIC_MASTER_CITIES_LOADING, { loading: false, data }));
        });
}

export function actionGetBranchList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_INSURER_BRANCH, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SPECIFIC_MASTER_BRANCH_LOADING, { loading: false, data }));
        });
}

export function actionEditSpecificPreference(params) {
    return (dispatch, getState) =>
        Api.post(API_EDIT_SPECIFIC_PREFERENCE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_SPECIFIC_PREFERENCE_LOADING, { loading: false, data }));
        });
}