import { Route, Switch } from "react-router";
import Notifications from "./list";

const NotificationRoute = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Notifications} />
    </Switch>
  );
};
export default NotificationRoute;
