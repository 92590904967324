
import React from 'react';
import { Route, Switch } from 'react-router';
import PlacementSpecificRoute from './specific_placement';
import PlacementGeneralRoute from './general_placement';
import GeneralPlacementView from './general_placement_view';
const PlacementRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/general-view`} component={GeneralPlacementView} />
                <Route path={`${match.url}/general`} component={PlacementGeneralRoute} />
                <Route path={`${match.url}/specific`} component={PlacementSpecificRoute} />
            </Switch>
        </>
    )
}

export default PlacementRoute