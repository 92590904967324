import Api from '../../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../../utils/action.util';
import { API_MYVERTICAL } from '../../../../../../../../api/constants';
import { PRINT } from '../../../../../../../../utils';

export const MYVERTICAL_LOADING = 'MYVERTICAL_LOADING';
export const ACTION_MYVERTICAL_SUCCESS = 'ACTION_MYVERTICAL_SUCCESS';

export function actionMyVertical() {
        return (dispatch, getState) => Api.get(API_MYVERTICAL).then((res) => {
            const data = res;
            PRINT('MYTEAMVERTICAL ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_MYVERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(MYVERTICAL_LOADING, { loading: false, data }));
        });
    }
