import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import ChangeStatus from '../../../../../../component/popups/approval_action';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from "jquery";
import { withAuth } from 'react-auth-guard';
import { REQUEST_ASSET, UNAUTHORIZED, APPROVED_BY_MANAGER, APPROVED_BY_HR, REVIEW_BY_SUPERIOR, REVIEW_BY_HR } from '../../../../../../constants';
import { actionAssetApprovalView, ACTION_ASSET_APPROVAL_VIEW_SUCCESS } from './action';
import { generateInitialLettersString } from '../../../../../../utils';
import RequestHistory from '../../../../../../component/widgets/request_history';

const AssetApprovalsView = (props) => {

    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [assetApprovalView, setAssetApprovalView] = useState([]);
    const [requestHistory, setRequestHistory] = useState(null);
    const [approvalBy, setApprovalBy] = useState(null);

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionAssetApprovalView({
            id: props.location.state.id,
        });
    }
    useEffect(initialRender, []);

    const onAssetApprovalViewUpdate = () => {
        const { AssetApprovalView } = props;
        var AssetApprovalViewResponse = Object.assign({}, AssetApprovalView);
        if (AssetApprovalViewResponse.result === true) {
            setAssetApprovalView(AssetApprovalViewResponse.response);

            if (AssetApprovalViewResponse.response.request_status.length > 0) {
                setRequestHistory(AssetApprovalViewResponse.response.request_status);
            }

        } else {
            switch (AssetApprovalViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAssetApprovalViewUpdate, [props.AssetApprovalView])
    return (
        <>
            <ScreenTitle title="Asset Approval Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (assetApprovalView != null && assetApprovalView.profile_photo != null) ?
                                                <img
                                                    src={assetApprovalView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(assetApprovalView.full_name))}

                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    assetApprovalView != null && assetApprovalView.full_name != null ?
                                                        assetApprovalView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success text-bold-500 round">
                                                {
                                                    assetApprovalView != null && assetApprovalView.designation_name != null ?
                                                        assetApprovalView.designation_name : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        assetApprovalView != null && assetApprovalView.status_name != null ?
                                                            assetApprovalView.status_name : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2">By <span className="text-bold-700 text-justify">
                                                {

                                                    assetApprovalView != null && assetApprovalView.status != null &&
                                                        assetApprovalView.status != 'Cancel By Employee' ? assetApprovalView?.approval_manager_name : assetApprovalView.full_name

                                                }
                                            </span> On: <span className="text-primary">
                                                    {
                                                        assetApprovalView != null && assetApprovalView.modified_at != null ?
                                                            assetApprovalView.modified_at : '-'
                                                    }
                                                </span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Full Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetApprovalView != null && assetApprovalView.full_name != null ?
                                            assetApprovalView.full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetApprovalView != null && assetApprovalView.approval_manager_name != null ?
                                            assetApprovalView.approval_manager_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Asset Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetApprovalView != null && assetApprovalView.asset_name != null ?
                                            assetApprovalView.asset_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Request Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetApprovalView != null && assetApprovalView.request_date != null ?
                                            assetApprovalView.request_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        assetApprovalView != null && assetApprovalView.status_name != null ?
                                            assetApprovalView.status_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        assetApprovalView != null && assetApprovalView.description != null ?
                                            assetApprovalView.description : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetApprovalView != null && assetApprovalView.remark != null ?
                                            assetApprovalView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }

                        <div className="form-actions border-0 pt-1 float-none">
                            {
                                assetApprovalView != null && (assetApprovalView.status_name === REVIEW_BY_SUPERIOR && assetApprovalView.is_hr === 0) ?
                                    <button
                                        id="your_action"
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            setShowChangeStatus(true);
                                            setApprovalBy(APPROVED_BY_MANAGER);
                                        }}
                                    >Your Action</button>
                                    :
                                    assetApprovalView != null && (assetApprovalView.status_name === REVIEW_BY_HR && assetApprovalView.is_hr === 1) ?
                                        <button
                                            id="your_action"
                                            className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                            onClick={() => {
                                                setShowChangeStatus(true);
                                                setApprovalBy(APPROVED_BY_HR);
                                            }}
                                        >Your Action</button>
                                        :
                                        <></>
                            }
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'ASSET_REQUEST_CREATE_AM_RM' || props?.location?.state?.type === 'ASSET_APPROVE_BY_AM_DM' || props?.location?.state?.type === 'ASSET_APROVAL_NOTIFICATION') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
                <ChangeStatus
                    approval={assetApprovalView}
                    show={showChangeStatus}
                    type={REQUEST_ASSET}
                    approvedBy={approvalBy}
                    handleClose={() => {
                        setShowChangeStatus(false);
                        setRequestHistory(null);
                        props.actionAssetApprovalView({
                            id: props.location.state.id,
                        });
                    }}
                />
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var AssetApprovalView = null;

    if (
        state.AssetApprovalViewReducer != null &&
        Object.keys(state.AssetApprovalViewReducer).length > 0 &&
        state.AssetApprovalViewReducer.constructor === Object
    ) {
        switch (state.AssetApprovalViewReducer.tag) {
            case ACTION_ASSET_APPROVAL_VIEW_SUCCESS:
                AssetApprovalView = Object.assign({}, state.AssetApprovalViewReducer.data);
                delete state.AssetApprovalViewReducer;
                return { AssetApprovalView };
            default:
        }
    }

    return {
        AssetApprovalView
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAssetApprovalView }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AssetApprovalsView));

