import Api from "../../../../../../api";
import { API_OPPORTUNITY_QUESTION_ADD_EDIT, API_OPPORTUNITY_QUESTION_LIST } from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";

export const ACTION_OPPORTUNITY_QUESTION_ADD_LIST_LOADING = 'ACTION_OPPORTUNITY_QUESTION_ADD_LIST_LOADING';
export const ACTION_OPPORTUNITY_QUESTION_ADD_LIST_SUCCESS = 'ACTION_OPPORTUNITY_QUESTION_ADD_LIST_SUCCESS';

export const ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_LOADING = 'ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_LOADING';
export const ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_SUCCESS = 'ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_SUCCESS';

export function actionOpportunityQuestionAddList() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_QUESTION_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE OPPORTUNITY QUESTION LIST', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_QUESTION_ADD_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_QUESTION_ADD_LIST_LOADING, { loading: false, data }));
        });
}
 export  function actionOpportunityQuestionAddEdit(params){
    return (dispatch, getState) =>
    Api.post(API_OPPORTUNITY_QUESTION_ADD_EDIT,params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE OPPORTUNITY QUESTION LIST', data)
        dispatch(fetchSuccess(ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_OPPORTUNITY_QUESTION_ADD_EDIT_LOADING, { loading: false, data }));
    });
 }