import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ScreenTitle from "../../../../../component/screen_title"
import $ from "jquery";
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from "lodash";
import Swal from "sweetalert2";
import { verifyPermission } from "../../../../../utils";
import { KEY_TOAST_MESSAGE, removeData, getData } from '../../../../../utils/preferences';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actionLogoutAllUser, ACTION_LOGOUT_ALL_USER_SUCCESS } from "./action";
import { actionLogoutAllUserClients, ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS } from "./action";
import { HiOutlineDeviceMobile } from "react-icons/hi"
import { FaMobileAlt } from "react-icons/fa"
import { GoDeviceDesktop } from "react-icons/go"

const LogoutAll = (props) => {

    const { handleSubmit } = useForm();

    const [loginTab, setLoginTab] = useState('Pinc Network');
    const device = [
        {
            "id": 'web',
            "title": "Web",
            "icon": <GoDeviceDesktop size={16} className="mr-1" />,
        },
        {
            "id": 'android',
            "title": "Android",
            "icon": <HiOutlineDeviceMobile size={18} className="mr-1" />,
        },
        {
            "id": 'ios',
            "title": "iOS",
            "icon": <FaMobileAlt size={16} className="mr-1" />,
        }
    ];
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectedDeviceName, setSelectedDeviceName] = useState([]);
    const [cplSelectDevices, setCplSelectedDevices] = useState([]);

    const onLogoutAllUserUpdate = () => {
        const { LogoutAllUser } = props;
        var LogoutAllUserResponse = Object.assign({}, LogoutAllUser);
        if (LogoutAllUserResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            if (getData(KEY_TOAST_MESSAGE) != null) {
                toast.success(getData(KEY_TOAST_MESSAGE));
                removeData(KEY_TOAST_MESSAGE);
            }
            toast.success(LogoutAllUserResponse.message);
            setSelectedDevices([]);
            setSelectedDeviceName([]);
            $("#select-all").prop("checked", false);
        } else {
            $("#progress-loader").addClass("hidden");
            switch (LogoutAllUserResponse.status) {
                case UNAUTHORIZED:
                    break;
                default:
            }
        }
    }
    useEffect(onLogoutAllUserUpdate, [props.LogoutAllUser]);

    const onLogoutAllUserClientsUpdate = () => {
        const { LogoutAllUserClients } = props;
        var LogoutAllUserClientsResponse = Object.assign({}, LogoutAllUserClients);
        if (LogoutAllUserClientsResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            if (getData(KEY_TOAST_MESSAGE) != null) {
                toast.success(getData(KEY_TOAST_MESSAGE));
                removeData(KEY_TOAST_MESSAGE);
            }
            toast.success(LogoutAllUserClientsResponse.message);
            setCplSelectedDevices([]);
            $("#web").prop("checked", false);
        } else {
            $("#progress-loader").addClass("hidden");
            switch (LogoutAllUserClientsResponse.status) {
                case UNAUTHORIZED:
                    break;
                default:
            }
        }
    }
    useEffect(onLogoutAllUserClientsUpdate, [props.LogoutAllUserClients]);

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        {
                            verifyPermission("PINC_NETWORK_LOGOUT") ?
                                <li class="nav-item">
                                    <button
                                        onClick={() => {
                                            setLoginTab('Pinc Network')
                                        }}
                                        className={`btn ${loginTab === 'Pinc Network' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        PINC Network
                                    </button>
                                </li>
                                :
                                <></>
                        }
                        {
                            verifyPermission("CIMS_LOGOUT") ?
                                <li class="nav-items ml-1">
                                    <button
                                        onClick={() => {
                                            setLoginTab('CIMS')
                                        }}
                                        className={`btn ${loginTab === 'CIMS' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        CIMS
                                    </button>
                                </li>
                                :
                                <></>
                        }

                    </ul>
                </div>

            </>
        )

    }

    const onSubmit = (data) => {
        if (loginTab === 'Pinc Network') {
            if (selectedDevices != null && selectedDevices?.length > 0) {
                Swal.fire({
                    title: 'Alert!',
                    icon: 'info',
                    html: `Are you sure, Do you want to Logout for all the Users from PINC Network <b>${selectedDeviceName != null && selectedDeviceName.length > 0 ? _.capitalize(selectedDeviceName) : 'Web, Android, iOS'}</b> Application?`,
                    confirmButtonColor: PRIMARY_COLOR,
                    cancelButtonText: "Cancel",
                    showCancelButton: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        props.actionLogoutAllUser({ device_type: selectedDevices })
                    }
                });
            }
            else {
                Swal.fire({
                    title: 'Alert!',
                    icon: 'warning',
                    html: 'Please select at least one option',
                    confirmButtonColor: PRIMARY_COLOR,
                });
            }
        } else {
            if (cplSelectDevices != null && cplSelectDevices?.length > 0) {
                Swal.fire({
                    title: 'Alert!',
                    icon: 'info',
                    html: `Are you sure, Do you want to Logout for all the Users from CIMS <b>Web</b> Application?`,
                    confirmButtonColor: PRIMARY_COLOR,
                    cancelButtonText: "Cancel",
                    showCancelButton: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        props.actionLogoutAllUserClients({ device_type: cplSelectDevices })
                    }
                });
            }
            else {
                Swal.fire({
                    title: 'Alert!',
                    icon: 'warning',
                    html: 'Please select at least one option',
                    confirmButtonColor: PRIMARY_COLOR,
                });
            }

        }
    }
    return (
        <>
            <ScreenTitle title="Bulk User Logout from the Application" />
            <div className="content-header row mb-1">
                {renderHeaderButtonUI()}
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        {
                                            loginTab != null && loginTab === 'Pinc Network' ?
                                                <div className="row">
                                                    {/* <div className="flex-column"> */}
                                                    {/* <div className="col-12">
                                                        <div className={`form-group`}>
                                                            <input
                                                                className="cursor-pointer"
                                                                type="checkbox"
                                                                id="select-all"
                                                                onChange={(event) => {
                                                                    if (event.target.checked) {
                                                                        var deviceIds = _.map(device, 'id');
                                                                        deviceIds.push('all');
                                                                        setSelectedDevices(deviceIds);

                                                                    } else {
                                                                        setSelectedDevices([]);
                                                                    }
                                                                }}
                                                            />
                                                            <label for="select-all" className="ml-1 mb-0 "><h4 className="mb-0 font-weight-bold">Select All</h4></label>
                                                        </div>
                                                    </div> */}
                                                    {/* </div> */}
                                                    {
                                                        device != null & device.length > 0 ?
                                                            device.map((platform, index) => (
                                                                <div className="col-md-4">
                                                                    <div className="dashboard-card-round shadow">
                                                                        <div className="card-body">
                                                                            <div className="form-group mb-0">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div>
                                                                                        <input
                                                                                            className="my-auto cursor-pointer"
                                                                                            type="checkbox"
                                                                                            value={platform.id}
                                                                                            checked={selectedDevices.includes(platform.id) ? true : false}
                                                                                            onChange={(event) => {
                                                                                                $("#select-all").prop("checked", false);
                                                                                                var arrSelectedDevices = Object.assign([], selectedDevices);
                                                                                                var arrSelectedDeviceName = Object.assign([], selectedDevices);
                                                                                                if (!arrSelectedDevices.includes(platform.id)) {
                                                                                                    arrSelectedDevices.push(platform.id)
                                                                                                    setSelectedDevices(arrSelectedDevices)

                                                                                                    arrSelectedDeviceName.push(platform.title);
                                                                                                    setSelectedDeviceName(arrSelectedDeviceName);
                                                                                                } else {
                                                                                                    var deviceIndex = arrSelectedDevices.findIndex((objDevice, index) => objDevice === platform.id);
                                                                                                    var deviceIndexName = arrSelectedDeviceName.findIndex((objDevice, index) => objDevice === platform.id);
                                                                                                    if (deviceIndex >= 0) {
                                                                                                        arrSelectedDevices.splice(deviceIndex, 1);
                                                                                                        setSelectedDevices(arrSelectedDevices);
                                                                                                    }
                                                                                                    if (deviceIndexName >= 0) {
                                                                                                        arrSelectedDeviceName.splice(deviceIndexName, 1);
                                                                                                        setSelectedDeviceName(arrSelectedDeviceName);
                                                                                                    }
                                                                                                }
                                                                                                if (arrSelectedDevices.length === device.length) {
                                                                                                    $("#select-all").prop("checked", true);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <label for="all" className="ml-1 mb-0 my-auto"><h5 className="mb-0">{platform.title}</h5></label>
                                                                                    </div>
                                                                                    <div> {platform.icon}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="dashboard-card-round shadow">
                                                            <div className="card-body">
                                                                <div>
                                                                    <div className="form-group mb-0">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div>
                                                                                <input
                                                                                    className="my-auto cursor-pointer"
                                                                                    type="checkbox"
                                                                                    id="web"
                                                                                    onChange={(event) => {
                                                                                        if (event.target.checked) {
                                                                                            var arrCplSelectedDevices = Object.assign([], cplSelectDevices);
                                                                                            arrCplSelectedDevices.push('desktop');
                                                                                            setCplSelectedDevices(arrCplSelectedDevices);
                                                                                            $("#web").prop("checked", true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label for="web" className="ml-1 mb-0 my-auto"><h5 className="mb-0">Web</h5></label>
                                                                            </div>
                                                                            <div className=""> <GoDeviceDesktop size={16} className="mr-1" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="all_logout"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="all_logout-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setSelectedDevices([]);
                                                setCplSelectedDevices([]);
                                                props.history.goBack();
                                            }}
                                        > Back</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var LogoutAllUser = null;
    var LogoutAllUserClients = null;

    if (
        state.LogoutAllUserReducer != null &&
        Object.keys(state.LogoutAllUserReducer).length > 0 &&
        state.LogoutAllUserReducer.constructor === Object
    ) {
        switch (state.LogoutAllUserReducer.tag) {
            case ACTION_LOGOUT_ALL_USER_SUCCESS:
                LogoutAllUser = Object.assign({}, state.LogoutAllUserReducer.data);
                delete state.LogoutAllUserReducer
                return { LogoutAllUser };
            case ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS:
                LogoutAllUserClients = Object.assign({}, state.LogoutAllUserReducer.data);
                delete state.LogoutAllUserReducer
                return { LogoutAllUserClients };
            default:
        }
    }

    return {
        LogoutAllUser,
        LogoutAllUserClients
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionLogoutAllUser, actionLogoutAllUserClients }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutAll)
