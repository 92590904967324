import React from 'react';
import { Route, Switch } from 'react-router';
import Dashboard from './dashboard';
import FilterPage from './filters';
import ReportDetail from './detail';
// import MeetingPage from './meeting';
import AttendancePage from './attendance/attendance';
import LeavePage from './attendance/leave';
import MeetingPage from './meeting/meeting';
import DealsClosed from './meeting/deals_closed';
import MeetingDetailPage from './meeting/meeting_detail_report';
import NoOfProposal from './meeting/no_of_proposal_details';
import OpenDeals from './meeting/open_deals';
import ReimbursementPage from './reimbursement/reimbursement';
import LeadClientMeetingDetail from './meeting/lead_client_meeting';
import LeadClientMeetingView from './meeting/lead_client_meeting/view';
import CustomerVerificationReport from './customer_verification';
import TrainingReport from './trainings/training';
import TrainingParticipationReport from './trainings/training_participation';

const ReportRoute = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route path={`${match.url}/training`} component={TrainingReport} />
            <Route path={`${match.url}/training_participation`} component={TrainingParticipationReport} />
            <Route path={`${match.url}/filters`} component={FilterPage} />
            <Route path={`${match.url}/detail`} component={ReportDetail} />
            <Route exact path={`${match.url}`} component={Dashboard} />
            <Route path={`${match.url}/meeting`} component={MeetingPage} />
            <Route path={`${match.url}/attendance`} component={AttendancePage} />
            <Route path={`${match.url}/leave`} component={LeavePage} />
            <Route path={`${match.url}/meeting`} component={MeetingPage} />
            <Route path={`${match.url}/deals_closed`} component={DealsClosed} />
            <Route path={`${match.url}/meeting_detail_report`} component={MeetingDetailPage} />
            <Route path={`${match.url}/lead_client_meeting_report/view`} component={LeadClientMeetingView} />
            <Route path={`${match.url}/lead_client_meeting_report`} component={LeadClientMeetingDetail} />
            <Route path={`${match.url}/no_of_proposal_details`} component={NoOfProposal} />
            <Route path={`${match.url}/open_deals`} component={OpenDeals} />
            <Route path={`${match.url}/reimbursement`} component={ReimbursementPage} />
            <Route path={`${match.url}/customer_verification_report`} component={CustomerVerificationReport} />
        </Switch>
    )
}
export default ReportRoute