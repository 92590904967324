import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS, ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS, ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS, ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS, ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS, ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const EditWellnessApprovalReducer = createReducer(INITIAL_STATE, {
    [ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
}
)
export default EditWellnessApprovalReducer