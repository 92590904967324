import createReducer from "../../../reducers/createReducer";
import {
  ACTION_GET_POST_SUCCESS,
  ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS,
  ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
  ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
  ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
  ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS
} from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const GetPostReducer = createReducer(INITIAL_STATE, {

    [ACTION_GET_POST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_POST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS,
                loading: false,
            }
        );
    },
});
export default GetPostReducer;
