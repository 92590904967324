import Api from "../../../../../api";
import { fetchSuccess, loading } from "../../../../../utils/action.util";
import {
  API_GET_POST_WITH_COMMENTS,
  API_POST_CREATE_COMMENT,
  API_POST_DELETE,
  API_POST_LIKE_DISLIKE,
  API_POST_COMMENT_LIKE,
  API_POST_COMMENT_DELETE,
  API_POST_COMMENT_EDIT,
  API_GET_TICKER
} from "../../../../../api/constants";
// import { PRINT } from "../../../../../utils";

export const ACTIVITYFEEDLIST_LOADING = "ACTIVITYFEEDLIST_LOADING";
export const ACTION_ACTIVITYFEEDLIST_SUCCESS =
  "ACTION_ACTIVITYFEEDLIST_SUCCESS";
export const ACTION_ACTIVITYFEEDLIKE_SUCCESS = 'ACTION_ACTIVITYFEEDLIKE_SUCCESS';
export const ACTION_ACTIVITYFEEDDELETE_SUCCESS = 'ACTION_ACTIVITYFEEDDELETE_SUCCESS';
export const ACTION_ACTIVITYFEEDCREATECOMMENT_SUCCESS = 'ACTION_ACTIVITYFEEDCREATECOMMENT_SUCCESS';
export const ACTION_ACTIVITYFEEDCOMMENTLIKE_SUCCESS = 'ACTION_ACTIVITYFEEDCOMMENTLIKE_SUCCESS';
export const ACTION_ACTIVITYFEEDCOMMENTDELETE_SUCCESS = 'ACTION_ACTIVITYFEEDCOMMENTDELETE_SUCCESS';
export const ACTION_ACTIVITYFEEDCOMMENTEDIT_SUCCESS = 'ACTION_ACTIVITYFEEDCOMMENTEDIT_SUCCESS';

export const ACTION_GET_TICKER_LOADING = "ACTION_GET_TICKER_DETAILS_LOADING";
export const ACTION_GET_TICKER_SUCCESS = "ACTION_GET_TICKER_DETAILS_SUCCESS";

export function actionActivityFeedList() {
  return (dispatch, getState) =>
    Api.post(API_GET_POST_WITH_COMMENTS, {})
      .then((res) => {
        const data = res;
        // PRINT("ACTIVITYFEEDLIST ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_ACTIVITYFEEDLIST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedLike(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_LIKE_DISLIKE, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTIVITY FEED LIKE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_ACTIVITYFEEDLIKE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedDelete(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_DELETE, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTIVITY FEED DELETE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_ACTIVITYFEEDDELETE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedCreateComment(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_CREATE_COMMENT, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTIVITY FEED CREATE COMMENT ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_ACTIVITYFEEDCREATECOMMENT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedCommentLike(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_LIKE, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTIVITY FEED COMMENT LIKE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_ACTIVITYFEEDCOMMENTLIKE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedCommentDelete(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_DELETE, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTIVITY FEED COMMENT DELETE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_ACTIVITYFEEDCOMMENTDELETE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedCommentEdit(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_EDIT, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTIVITY FEED COMMENT EDIT ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_ACTIVITYFEEDCOMMENTEDIT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionGetTicker(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_TICKER, params)
      .then((res) => {
        const data = res;
        // PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_GET_TICKER_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_GET_TICKER_LOADING, { loading: false, data }));
      });
}