import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IMPORT_MASTER_DATA } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_IMPORT_MASTER_DATA_LOADING = 'ACTION_IMPORT_MASTER_DATA_LOADING';
export const ACTION_IMPORT_MASTER_DATA_SUCCESS = 'ACTION_IMPORT_MASTER_DATA_SUCCESS';


export function actionImportMasterData(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_IMPORT_MASTER_DATA,params, files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_IMPORT_MASTER_DATA_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IMPORT_MASTER_DATA_LOADING, { loading: false, data }));
        });
}
