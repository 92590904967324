import React, { useEffect, useState } from 'react';
import { FiGlobe } from 'react-icons/fi';
import { BsBuilding } from 'react-icons/bs';
import {  HiOutlineInformationCircle } from 'react-icons/hi';
import { IoDocumentTextOutline, IoDocumentAttachOutline } from 'react-icons/io5';
import { FaRegHandshake, FaShieldAlt } from 'react-icons/fa';
import { UNAUTHORIZED } from '../../../../../constants';
import ScreenTitle from '../../../../../component/screen_title';
import _ from 'lodash';
import Swal from 'sweetalert2';
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import TabMeetings from './tabs/meetings';
import TabPolicies from './tabs/policies';
import TabClients from './tabs/clients';
import TabCompanyNews from './tabs/company_news';
import TabContactPersons from './tabs/contact_persons';
import TabInsuranceDetails from './tabs/insurance_details';
import { actionInsurerViewTabBusinessSummary, ACTION_INSURER_VIEW_TAB_BUSINESS_SUMMARY_SUCCESS } from './action';
const InsurerView = (props) => {

    const { control } = useForm({});

    const [businessSummary, setBusinessSummary] = useState(null);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [selectedTab, setSelectedTab] = useState(1);
    const [insurerId, setInsurerId] = useState( ( props.location.state != null && props.location.state.insurer_id ) != null ? props.location.state.insurer_id : null);


    const initialRender = () => {
        if (props.location.state !== undefined || props.location.state != null) {
            setFromDate('');
            setToDate('');
            setInsurerId(props.location.state.insurer_id);
            setSelectedTab(1)

            props.actionInsurerViewTabBusinessSummary({insurer_id: props.location.state.insurer_id});
          
        } else { 
            setInsurerId(0);
        }

        // $(".auto-suggestion").remove();
    }
    useEffect(initialRender, [props.location.state]);

    const onBusinessSummaryUpdate = () => {
        const { BusinessSummary } = props;
        if (BusinessSummary != null) {
            var BusinessSummaryResponse = Object.assign({}, BusinessSummary);
            if (BusinessSummaryResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setBusinessSummary(BusinessSummaryResponse.response);
                $(".insurance-tab").removeClass("active");
                $("#insurance-details-tab").addClass("active");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (BusinessSummaryResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout();
                        })
                        break;

                    default:

                }
            }
        }
    }
    useEffect(onBusinessSummaryUpdate, [props.BusinessSummary]);

    const renderTab = () => {
        switch (selectedTab) {
            case 1:
                return (
                    <TabInsuranceDetails 
                        {...props}
                        tab={selectedTab}
                        insurerId={insurerId}
                    />
                )
            case 2:
                return (
                    <TabMeetings 
                        {...props}
                        tab={selectedTab}
                        insurerId={insurerId}
                    />
                )
            case 3:
                return (
                    <TabPolicies 
                        {...props}
                        tab={selectedTab}
                        insurerId={insurerId}
                    />
                )
            case 4:
                return (
                    <TabClients 
                        {...props}
                        tab={selectedTab}
                        insurerId={insurerId}
                    />
                )
            case 5:
                return (
                    <TabContactPersons 
                        {...props}
                        tab={selectedTab}
                        insurerId={insurerId}
                    />
                )
            case 6:
                return (
                    <TabCompanyNews 
                        {...props}
                        tab={selectedTab}
                        insurerId={insurerId}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }
    useEffect(renderTab, [selectedTab,insurerId])

    return (
        <>
            <ScreenTitle title="Insurance Company" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 border-right">
                                <div className="d-flex">
                                    {/* <span className="primary cursor-pointer" onClick={() => {
                                        props.history.goBack();
                                    }}><FiArrowLeft size={16} /> Back to Insurance Company</span> */}
                                </div>
                                <div className="d-flex flex-row justify-content-center w-100 mt-2">
                                    <div className="flex-column text-center">
                                        <div className="d-flex justify-content-center mb-1">
                                            <div className="d-flex justify-content-center align-items-center rounded-circle bg-blue-grey bg-lighten-4 " style={{ height: 80, width: 80 }}>
                                                {
                                                    insurerId != null ?
                                                    <img
                                                    src={`/assets/images/insurance_companies/${insurerId}.png`}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={80}
                                                    height={80}
                                                    style={{ border: '5px solid #FFF' }}
                                                    />
                                                    :
                                                    <div className="d-flex justify-content-center align-items-center rounded-circle bg-blue-grey bg-lighten-4 " style={{ height: 80, width: 80 }}>
                                                        <IoShieldCheckmarkOutline size={80} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <h4 className="font-medium-2 text-bold-600">{
                                                businessSummary != null ?
                                                businessSummary.insurer_company : '-'
                                            }</h4>
                                        <h4 className="font-medium-1 text-bold-500 ">Insurance Company</h4>
                                    </div>
                                </div>
                                <p className="font-small-3 text-bold-600 lighten-1 mb-half">
                                    <a 
                                        className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                        target="_blank"
                                        onClick={() => { 
                                            // if( clientDetails != null && clientDetails.website != null ) { 
                                            //     if( clientDetails.website.includes("http") ) { 
                                            //         window.open(clientDetails.website, "_blank");
                                            //     } else { 
                                            //         window.open( "http://" + clientDetails.website, "_blank");
                                            //     }
                                                
                                            // }
                                        }}
                                    >
                                        <FiGlobe size={12} className="my-auto" />
                                    </a>
                                    {
                                        businessSummary != null && businessSummary.news_link != null ? 
                                        <a 
                                            href={businessSummary.news_link}
                                            target="_blank"
                                            className="text-template"
                                        >
                                            <span className="ml-half">Company News</span> 
                                        </a>
                                        :
                                        <></>
                                    }
                                </p>
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 mt-2 mt-md-0 mt-lg-0 px-0">
                                <ul className="nav nav-tabs nav-underline border-bottom" role="tablist" style={{ backgroundColor: 'transparent' }}>
                                    <li className="nav-item">
                                        <a 
                                            className="nav-link insurance-tab d-flex align-items-center justify-content-center active" 
                                            id="insurance-details-tab" 
                                            data-toggle="tab" 
                                            aria-controls="insurance-details" 
                                            role="tab" 
                                            aria-selected="true"
                                            onClick={()=>{
                                                setSelectedTab(1)
                                            }}
                                        >
                                            <HiOutlineInformationCircle size={16} />
                                            <span className="d-none d-sm-block">&nbsp;Business Summary</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a 
                                            className="nav-link insurance-tab d-flex align-items-center justify-content-center" 
                                            id="meetings-tab" 
                                            data-toggle="tab" 
                                            aria-controls="meetings" 
                                            role="tab" 
                                            aria-selected="false"
                                            onClick={()=>{
                                                setSelectedTab(2)
                                            }}
                                        >
                                            <FaRegHandshake size={16} />
                                            <span className="d-none d-sm-block">&nbsp;Meetings</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a 
                                            className="nav-link insurance-tab d-flex align-items-center justify-content-center" 
                                            id="policies-tab" 
                                            data-toggle="tab" 
                                            aria-controls="policies" 
                                            role="tab" 
                                            aria-selected="false"
                                            onClick={()=>{
                                                setSelectedTab(3)
                                            }}
                                        >
                                            <IoDocumentTextOutline size={16} />
                                            <span className="d-none d-sm-block">&nbsp;Policies</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a 
                                            className="nav-link insurance-tab d-flex align-items-center justify-content-center" 
                                            id="clients-tab" 
                                            data-toggle="tab" 
                                            aria-controls="clients" 
                                            role="tab" 
                                            aria-selected="false"
                                            onClick={()=>{
                                                setSelectedTab(4)
                                            }}
                                        >
                                            <BsBuilding size={16} />
                                            <span className="d-none d-sm-block">&nbsp;Clients</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a 
                                            className="nav-link insurance-tab d-flex align-items-center justify-content-center" 
                                            id="contact-person-tab" 
                                            data-toggle="tab" 
                                            aria-controls="contact-person" 
                                            role="tab" 
                                            aria-selected="false"
                                            onClick={()=>{
                                                setSelectedTab(5)
                                            }}
                                        >
                                            <FaShieldAlt size={16} />
                                            <span className="d-none d-sm-block">&nbsp;Contact Person</span>
                                        </a>
                                    </li>
                                    <li className="nav-item hidden">
                                        <a 
                                            className="nav-link insurance-tab d-flex align-items-center justify-content-center" 
                                            id="company-news-tab" 
                                            data-toggle="tab" 
                                            aria-controls="company-news"
                                            role="tab" 
                                            aria-selected="false"
                                            onClick={()=>{
                                                setSelectedTab(6)
                                            }}
                                        >
                                            <IoDocumentAttachOutline size={18} />
                                            <span className="d-none d-sm-block">&nbsp;Company News</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    {renderTab()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

function mapStateToProps(state) {

    var BusinessSummary = null;

    if (
        state.InsurerBusinessSummaryReducer != null &&
        Object.keys(state.InsurerBusinessSummaryReducer).length > 0 &&
        state.InsurerBusinessSummaryReducer.constructor === Object
    ) {
        switch (state.InsurerBusinessSummaryReducer.tag) {

            case ACTION_INSURER_VIEW_TAB_BUSINESS_SUMMARY_SUCCESS:
                BusinessSummary = Object.assign({}, state.InsurerBusinessSummaryReducer.data);
                delete state.InsurerBusinessSummaryReducer;
                return { BusinessSummary };
            default:
        }
    }
    return { 
        BusinessSummary
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionInsurerViewTabBusinessSummary }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InsurerView);
