import { ACTION_LOGOUT_ALL_USER_SUCCESS, ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS } from "./action";
import createReducer from "../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LogoutAllUserReducer = createReducer(INITIAL_STATE, {
    [ACTION_LOGOUT_ALL_USER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LOGOUT_ALL_USER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS,
                loading: false,
            }
        );
    },
});

export default LogoutAllUserReducer;