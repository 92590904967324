import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionAssetRequestView, ACTION_ASSET_REQUEST_VIEW_SUCCESS } from './action';
import { actionChangeAssetStatus, ACTION_CHANGE_ASSET_STATUS_SUCCESS } from './action';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { generateInitialLettersString } from '../../../../../../utils';
import { bindActionCreators } from 'redux';
import { CANCEL_BY_EMPLOYEE, REQUEST_ASSET, UNAUTHORIZED, RETURN_REQUEST } from '../../../../../../constants';
import Swal from 'sweetalert2';
import RequestHistory from '../../../../../../component/widgets/request_history';

const AssetRequestView = (props) => {
    const [assetRequestView, setAssetRequestView] = useState([]);
    const [changeAssetStatus, setChangeAssetStatus] = useState();
    const [requestHistory, setRequestHistory] = useState(null);

    const initialRender = () => {
        props.actionAssetRequestView({
            id: props.location.state.id,
        });
    }
    useEffect(initialRender, []);

    const onViewAssetRequestUpdate = () => {
        const { ViewAssetRequest } = props;
        if (ViewAssetRequest != null) {

            var ViewAssetRequestResponse = Object.assign({}, ViewAssetRequest);
            if (ViewAssetRequestResponse.result === true) {
                setAssetRequestView(ViewAssetRequestResponse.response);
                if (ViewAssetRequestResponse.response.request_status.length > 0) {
                    setRequestHistory(ViewAssetRequestResponse.response.request_status);
                }

            } else {
                switch (ViewAssetRequestResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onViewAssetRequestUpdate, [props.ViewAssetRequest])

    const onChangeAssetStatusUpdate = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        const { ChangeAssetStatus } = props;
        if (ChangeAssetStatus != null) {
            var ChangeAssetStatusResponse = Object.assign({}, ChangeAssetStatus);
            if (ChangeAssetStatusResponse.result === true) {
                setChangeAssetStatus(ChangeAssetStatusResponse.response);
                props.actionAssetRequestView({ id: props.location.state.id });
                setData(KEY_TOAST_MESSAGE, "Status Change Successfully");
            } else {
                switch (ChangeAssetStatusResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onChangeAssetStatusUpdate, [props.ChangeAssetStatus])

    return (
        <>
            <ScreenTitle title="Asset Request Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (assetRequestView != null && assetRequestView.profile_photo != null) ?
                                                <img
                                                    src={assetRequestView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(assetRequestView.full_name))}
                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    assetRequestView != null && assetRequestView.full_name != null ?
                                                        assetRequestView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success text-bold-500 round">
                                                {
                                                    assetRequestView != null && assetRequestView.designation != null ?
                                                        assetRequestView.designation : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        assetRequestView != null && assetRequestView.status != null ?
                                                            assetRequestView.status : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2">By <span className="text-bold-700 text-justify">
                                                {

                                                    assetRequestView != null && assetRequestView.status != null &&
                                                        assetRequestView.status != 'Cancel By Employee' ? assetRequestView?.approval_manager_name : assetRequestView.full_name

                                                }
                                            </span> On: <span className="text-primary">
                                                    {
                                                        assetRequestView != null && assetRequestView.modified_at != null ?
                                                            assetRequestView.modified_at : '-'
                                                    }</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Full Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetRequestView != null && assetRequestView.full_name != null ?
                                            assetRequestView.full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetRequestView != null && assetRequestView.approval_manager_name != null ?
                                            assetRequestView.approval_manager_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Asset Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetRequestView != null && assetRequestView.asset_name != null ?
                                            assetRequestView.asset_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Request Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetRequestView != null && assetRequestView.request_date != null ?
                                            assetRequestView.request_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        assetRequestView != null && assetRequestView.status != null ?
                                            assetRequestView.status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        assetRequestView != null && assetRequestView.description != null ?
                                            assetRequestView.description : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        assetRequestView != null && assetRequestView.remark != null ?
                                            assetRequestView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'ASSET_REQUEST_CREATE_AM_RM' ||
                                        props?.location?.state?.type === 'ASSET_APPROVE_BY_AM_DM' ||
                                        props?.location?.state?.type === 'ASSET_APROVAL_NOTIFICATION' ||
                                        props?.location?.state?.type === 'ASSET_REJECT_BY_AM_DM' ||
                                        props?.location?.state?.type === 'ASSET_REJECTED_BY_AM_DM') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >Back</button>
                            {
                                // The Below condition for Task Owner To Cancel The Request
                                assetRequestView != null && (assetRequestView.status === 'Reviewing By Superior') ?
                                    <button
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, Do you want to cancel your asset request?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                                inputPlaceholder: 'Remark (If Any)',
                                                input: 'text',
                                            }).then(({ isConfirmed, value }) => {
                                                if (isConfirmed) {
                                                    setRequestHistory(null);
                                                    props.actionChangeAssetStatus({
                                                        "id": props.location.state.id,
                                                        "remark": value.length > 0 ? value : '',
                                                        "status": CANCEL_BY_EMPLOYEE,
                                                        "type": REQUEST_ASSET
                                                    })
                                                }
                                            })
                                        }}
                                    >Cancel Asset Request</button>
                                    :
                                    <></>
                            }
                            {
                                // The Below condition for Task Owner To Return Asset
                                assetRequestView != null && (assetRequestView.status === 'Asset Allocated') ?
                                    <button
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, Do you want to return the asset?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                                inputPlaceholder: 'Remark (If Any)',
                                                input: 'text',
                                            }).then(({ isConfirmed, value }) => {
                                                if (isConfirmed) {
                                                    props.actionChangeAssetStatus({
                                                        "id": props.location.state.id,
                                                        "remark": value.length > 0 ? value : '',
                                                        "status": RETURN_REQUEST,
                                                        "type": REQUEST_ASSET
                                                    })
                                                }
                                            })
                                        }}
                                    >Return Asset</button>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ViewAssetRequest = null;
    var ChangeAssetStatus = null;
    if (
        state.AssetRequestViewReducer != null &&
        Object.keys(state.AssetRequestViewReducer).length > 0 &&
        state.AssetRequestViewReducer.constructor === Object
    ) {
        switch (state.AssetRequestViewReducer.tag) {
            case ACTION_ASSET_REQUEST_VIEW_SUCCESS:
                ViewAssetRequest = Object.assign({}, state.AssetRequestViewReducer.data);
                delete state.AssetRequestViewReducer;
                return { ViewAssetRequest };
            case ACTION_CHANGE_ASSET_STATUS_SUCCESS:
                ChangeAssetStatus = Object.assign({}, state.AssetRequestViewReducer.data);
                delete state.AssetRequestViewReducer;
                return { ChangeAssetStatus };
            default:
        }
    }

    return {
        ViewAssetRequest,
        ChangeAssetStatus,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAssetRequestView, actionChangeAssetStatus }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AssetRequestView));
