import { ACTION_ATTENDANCE_LEAVE_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const DashboardAttendaceLeaveReducer = createReducer(INITIAL_STATE, {
    [ACTION_ATTENDANCE_LEAVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ATTENDANCE_LEAVE_SUCCESS,
                loading: false,
            }
        );
    },
});
export default DashboardAttendaceLeaveReducer;