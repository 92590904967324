import React, { useState, useEffect } from "react";
import { withAuth } from "react-auth-guard";
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DateBox } from 'devextreme-react/date-box';
import moment from "moment";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import Swal from 'sweetalert2';
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, MEETING_TRAINING_FILE_ERROR_MESSAGE, MEETING_TRAINING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { KEY_TOAST_MESSAGE, setData, removeData, getData } from '../../../../../../utils/preferences';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { actionMasterTraining, ACTION_UPDATE_MASTER_TRAINING_SUCCESS } from "./action";
import { actionMasterBrokerEntity, ACTION_UPDATE_MASTER_BROKER_ENTITY_SUCCESS } from "./action";
import { actionMasterBrokerSubEntity, ACTION_UPDATE_MASTER_BROKER_SUB_ENTITY_SUCCESS } from "./action";
import { actionMasterBrokerDepartment, ACTION_UPDATE_MASTER_BROKER_DEPARTMENT_SUCCESS } from "./action";
import { actionMasterBrokerBranch, ACTION_UPDATE_MASTER_TRAINING_BRANCH_SUCCESS } from "./action";
import { actionBrokerTrainingDetail, ACTION_BROKER_TRAINING_DETAIL_SUCCESS } from "./action";
import { actionBrokerTrainingEdit, ACTION_BROKER_TRAINING_EDIT_SUCCESS } from "./action";
import { actionBranchCity, ACTION_EDIT_BRANCH_CITY_SUCCESS } from "./action";
import { actionEmployeeNames, ACTION_EDIT_EMPLOYEE_NAMES_SUCCESS } from "./action";
import { checkFileExtension, downloadFile } from "../../../../../../utils";
import { actionExpiredCertificateEmployee, ACTION_EDIT_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS } from "./action";
import DocumentIframe from "../../../../../../component/popups/document_iframe";

const EditTraining = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm();
    var watchTrainingDetails = watch('training_details');
    var watchSpeakerName = watch('speaker_name');
    const [typeOfTraining, setTypeOfTraining] = useState([]);
    const [masterEntity, setMasterEntity] = useState([]);
    const [masterSubEntity, setMasterSubEntity] = useState([]);
    const [masterDepartment, setMasterDepartment] = useState([]);
    const [masterBranch, setMasterBranch] = useState([]);
    const [masterBranchCity, setMasterBranchCity] = useState([]);
    const [employeeNames, setEmployeeNames] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [brokerTrainingDetail, setBrokerTrainingDetail] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [uploadCertificateCopy, setUploadCertificateCopy] = useState(null);
    const [employeeExpiredCertificate, setEmployeeExpiredCertificate] = useState([]);
    const validExt = ['jpg', 'jpeg', 'png'];

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionBrokerTrainingDetail({ id: props.location.state.id });
        props.actionMasterTraining({ "status": "ACTIVE" });
        props.actionMasterBrokerEntity();
        props.actionMasterBrokerDepartment();

    }
    useEffect(initialRender, []);

    const onBrokerTrainingDetailUpdate = () => {
        const { BrokerTrainingDetail } = props
        if (BrokerTrainingDetail != null) {
            var BrokerTrainingDetailResponse = Object.assign({}, BrokerTrainingDetail);
            if (BrokerTrainingDetailResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setBrokerTrainingDetail(BrokerTrainingDetailResponse.response);
                setValue('training_type_id', BrokerTrainingDetailResponse.response.training_type_id);
                setValue('companies', BrokerTrainingDetailResponse.response.companies);
                setValue('sub_companies', BrokerTrainingDetailResponse.response.sub_companies);
                setValue('department', BrokerTrainingDetailResponse.response.department);
                setValue('branch_city', BrokerTrainingDetailResponse.response.city_id);
                setValue('branch', BrokerTrainingDetailResponse.response.branch_id);
                setValue('emp_ids', BrokerTrainingDetailResponse.response.emp_list);
                setValue('training_mode', BrokerTrainingDetailResponse.response.training_mode);
                setValue('training_name', BrokerTrainingDetailResponse.response.training_name);
                setValue('start_date', BrokerTrainingDetailResponse.response.start_date_exact);
                setValue('end_date', BrokerTrainingDetailResponse.response.end_date_exact);
                setValue('start_time', BrokerTrainingDetailResponse.response.start_time_exact);
                setValue('end_time', BrokerTrainingDetailResponse.response.end_time_exact);
                setValue('training_details', BrokerTrainingDetailResponse.response.training_details);
                setValue('speaker_name', BrokerTrainingDetailResponse.response.speaker_name);
                setValue('status', BrokerTrainingDetailResponse.response.status);
                setToDate(moment(BrokerTrainingDetailResponse.response.end_date));
            } else {
                $("#progress-loader").addClass("hidden");
                switch (BrokerTrainingDetailResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onBrokerTrainingDetailUpdate, [props.BrokerTrainingDetail])

    useEffect(() => {
        if (typeOfTraining != null && typeOfTraining.length > 0) {
            if (brokerTrainingDetail != null && brokerTrainingDetail?.training_type_id != null) {
                setValue('training_type_id', brokerTrainingDetail?.training_type_id);
            }
        }
    }, [typeOfTraining])

    useEffect(() => {
        props.actionEmployeeNames({
            "entity_id": selectedEntity,
            "department_id": selectedDepartment,
            "branch_id": selectedBranch,
            "function_id": selectedFunction,
        });
    }, [selectedEntity, selectedDepartment, selectedBranch, selectedFunction])

    const onMasterTrainingUpdate = () => {
        const { MasterTraining } = props
        if (MasterTraining != null) {
            var MasterTrainingResponse = Object.assign({}, MasterTraining);
            if (MasterTrainingResponse.result === true) {
                setTypeOfTraining(MasterTrainingResponse.response);
            } else {
                switch (MasterTrainingResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterTrainingUpdate, [props.MasterTraining])

    const onMasterBrokerEntityUpdate = () => {
        const { MasterBrokerEntity } = props
        if (MasterBrokerEntity != null) {
            var MasterBrokerEntityResponse = Object.assign({}, MasterBrokerEntity);
            if (MasterBrokerEntityResponse.result === true) {
                setMasterEntity(MasterBrokerEntityResponse.response);
            } else {
                switch (MasterBrokerEntityResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerEntityUpdate, [props.MasterBrokerEntity])

    const onMasterBrokerSubEntityUpdate = () => {
        const { MasterBrokerSubEntity } = props
        if (MasterBrokerSubEntity != null) {
            var MasterBrokerSubEntityResponse = Object.assign({}, MasterBrokerSubEntity);
            if (MasterBrokerSubEntityResponse.result === true) {
                setMasterSubEntity(MasterBrokerSubEntityResponse.response);
            } else {
                switch (MasterBrokerSubEntityResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerSubEntityUpdate, [props.MasterBrokerSubEntity])

    const onMasterBrokerDepartmentUpdate = () => {
        const { MasterBrokerDepartment } = props
        if (MasterBrokerDepartment != null) {
            var MasterBrokerDepartmentResponse = Object.assign({}, MasterBrokerDepartment);
            if (MasterBrokerDepartmentResponse.result === true) {
                setMasterDepartment(MasterBrokerDepartmentResponse.response);
            } else {
                switch (MasterBrokerDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerDepartmentUpdate, [props.MasterBrokerDepartment])

    const onMasterBranchCityUpdate = () => {
        const { MasterBranchCity } = props
        if (MasterBranchCity != null) {
            var MasterBranchCityResponse = Object.assign({}, MasterBranchCity);
            if (MasterBranchCityResponse.result === true) {
                setMasterBranchCity(MasterBranchCityResponse.response);
                // const idSet = new Set(MasterBranchCityResponse.response.map(item => item.id));
                // setValue('branch_city', Array.from(idSet));
            } else {
                switch (MasterBranchCityResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBranchCityUpdate, [props.MasterBranchCity])

    const onMasterBrokerBranchUpdate = () => {
        const { MasterBrokerBranch } = props
        if (MasterBrokerBranch != null) {
            var MasterBrokerBranchResponse = Object.assign({}, MasterBrokerBranch);
            if (MasterBrokerBranchResponse.result === true) {
                setMasterBranch(MasterBrokerBranchResponse.response);
            } else {
                switch (MasterBrokerBranchResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBrokerBranchUpdate, [props.MasterBrokerBranch])

    const onEmployeeExpiredCertificateUpdate = () => {
        const { EmployeeExpiredCertificate } = props
        if (EmployeeExpiredCertificate != null) {
            var EmployeeExpiredCertificateResponse = Object.assign({}, EmployeeExpiredCertificate);
            if (EmployeeExpiredCertificateResponse.result === true) {
                setEmployeeExpiredCertificate(EmployeeExpiredCertificateResponse.response.data);
                if (brokerTrainingDetail?.training_type != 'BQP Training') {
                    const ExpiredCertificationId = new Set()
                    employeeNames.forEach(item => {
                        if (EmployeeExpiredCertificateResponse.response.data.includes(item.user_id)) {
                            ExpiredCertificationId.add(item.user_id);
                        }
                    });
                    setValue('emp_ids', Array.from(ExpiredCertificationId));
                }
            } else {
                switch (EmployeeExpiredCertificateResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEmployeeExpiredCertificateUpdate, [props.EmployeeExpiredCertificate])

    const onEmployeeNamesUpdate = () => {
        const { EmployeeNames } = props
        if (EmployeeNames != null) {
            var EmployeeNamesResponse = Object.assign({}, EmployeeNames);
            if (EmployeeNamesResponse.result === true) {
                setEmployeeNames(EmployeeNamesResponse.response);
            } else {
                switch (EmployeeNamesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEmployeeNamesUpdate, [props.EmployeeNames])

    const onBrokerTrainingEditUpdate = () => {
        const { BrokerTrainingEdit } = props
        if (BrokerTrainingEdit != null) {
            var BrokerTrainingEditResponse = Object.assign({}, BrokerTrainingEdit);
            $("#edit-function").prop("disabled", false);
            if (BrokerTrainingEditResponse.result === true) {
                $("#edit-function-loader").addClass("hidden");
                props.history.push({
                    pathname: '/settings/hrms-settings'
                })
                setData(KEY_TOAST_MESSAGE, BrokerTrainingEditResponse.message);
            } else {
                $("#edit-function-loader").addClass("hidden");
                switch (BrokerTrainingEditResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setData(KEY_TOAST_MESSAGE, BrokerTrainingEditResponse.message);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onBrokerTrainingEditUpdate, [props.BrokerTrainingEdit])

    const onSubmit = (data) => {
        const { start_time, end_time } = data;
        const startTimeDate = new Date(`2000-01-01T${start_time}`);
        const endTimeDate = new Date(`2000-01-01T${end_time}`);
        data.companies = JSON.stringify(data.companies);
        data.product_ids = JSON.stringify(data.product_ids);
        data.sub_companies = JSON.stringify(data.sub_companies);
        data.department = JSON.stringify(data.department);
        data.branch_city = JSON.stringify(data.branch_city);
        data.branch = JSON.stringify(data.branch);
        data.emp_ids = JSON.stringify(data.emp_ids);
        if (fromDate > toDate) {
            Swal.fire({
                title: 'Error',
                text: "Start date can't be greater than End date",
                icon: 'error',
            }).then(success => {

            });
        } else {
            if (startTimeDate >= endTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: "Start time cannot be greater than or equal to end time",
                    icon: 'error',
                }).then(success => {

                });
            } else {
                var files = [];
                if (brokerTrainingDetail?.speaker_photo != null || uploadCertificateCopy != null) {
                    files.push({ 'name': 'speaker_photo', 'value': uploadCertificateCopy });
                    $("#edit-function-loader").removeClass("hidden");
                    $("#edit-function").prop("disabled", true);
                    data.id = brokerTrainingDetail?.id;
                    props.actionBrokerTrainingEdit(data, files);
                } else {
                    $('#error-pdf-doc-not-found').removeClass('d-none')
                }
                delete data.speaker_photo;
            }
        }
    }

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <h3 className="content-header-title text-capitalize mb-0 ">Edit Training Setting</h3>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Type of Training&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="training_type_id"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="training_type_id"
                                                        name="training_type_id"
                                                        dataSource={typeOfTraining}
                                                        fields={{ text: "name", value: "id" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Type of Training"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                                if (event.itemData.name === 'BQP Training') {
                                                                    props.actionExpiredCertificateEmployee()
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Type of Training'
                                                }}
                                            />
                                            {errors.training_type_id && <ErrorLabel message={errors.training_type_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Name&nbsp;<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                id="training_name"
                                                name="training_name"
                                                placeholder="Enter Training Name"
                                                {...register('training_name', { required: 'Training Name is required' })}
                                            />
                                            {errors.training_name && <ErrorLabel message={errors.training_name?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Start date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="start_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        {...field}
                                                        id="start_date"
                                                        name="start_date"
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        displayFormat="dd/MM/yyyy"
                                                        placeholder="Start date"
                                                        showClearButton={false}
                                                        min={moment().subtract(30, 'days').toDate()}
                                                        max={moment().add(180, 'days').toDate()}
                                                        onValueChange={(value) => {
                                                            const selectedDate = moment(value);
                                                            if (toDate && selectedDate.isAfter(toDate)) {
                                                                setToDate(null);
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "Start date can't be greater than End date",
                                                                    icon: 'error',
                                                                }).then(success => { });
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setFromDate(selectedDate);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please select start date'
                                                }}
                                            />
                                            {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">End date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="end_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        {...field}
                                                        id="end_date"
                                                        name="end_date"
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        displayFormat="dd/MM/yyyy"
                                                        placeholder="End date"
                                                        showClearButton={false}
                                                        min={fromDate != null ? moment(fromDate).format("YYYY-MM-DD") : moment().toDate()}
                                                        max={fromDate != null ? moment(fromDate).add(180, 'days').format("YYYY-MM-DD") : moment().add(180, 'days').toDate()}
                                                        onValueChange={(value) => {
                                                            const selectedDate = moment(value);
                                                            if (fromDate && selectedDate.isBefore(fromDate)) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: 'End date must be greater than Start date',
                                                                    icon: 'error',
                                                                }).then(success => { });
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setToDate(selectedDate);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please select end date'
                                                }}
                                            />
                                            {errors.end_date && <ErrorLabel message={errors.end_date?.message} />}

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Timing&nbsp;<span className="danger">*</span></label>
                                            <div className="d-flex flex-row">
                                                <div className="col-6">
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="start_time"
                                                        name="start_time"
                                                        placeholder="--:-- --"
                                                        {...register('start_time', { required: 'Start time is required' })}
                                                    />
                                                    {errors.start_time && <ErrorLabel message={errors.start_time?.message} />}
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="end_time"
                                                        name="end_time"
                                                        placeholder="--:-- --"
                                                        {...register('end_time', { required: 'End time is required' })}
                                                    />
                                                    {errors.end_time && <ErrorLabel message={errors.end_time?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Entity&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="companies"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterEntity,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="companies"
                                                        name="companies"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Entity"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={4}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                props.actionMasterBrokerSubEntity({ 'company_ids': value });
                                                                setValue('sub_companies', '');
                                                                setSelectedEntity(value)
                                                                setMasterSubEntity([])
                                                                // props.actionEmployeeNames({
                                                                //     "entity_id": value,
                                                                //     "department_id": selectedDepartment,
                                                                //     "branch_id": selectedBranch,
                                                                //     "function_id": selectedFunction,
                                                                // });
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Entity'
                                                }}
                                            />
                                            {errors.companies && <ErrorLabel message={errors.companies?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Department&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="sub_companies"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterSubEntity,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="sub_companies"
                                                        name="sub_companies"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Department"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={5}
                                                        showMultiTagOnly={false}
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                // props.actionMasterBrokerBranch({ 'company_ids': value });
                                                                props.actionBranchCity({ 'company_ids': value });
                                                                setValue('branch_city', '');
                                                                setMasterBranchCity([])
                                                                setSelectedDepartment(value)
                                                                // props.actionEmployeeNames({
                                                                //     "entity_id": selectedEntity,
                                                                //     "department_id": value,
                                                                //     "branch_id": selectedBranch,
                                                                //     "function_id": selectedFunction,
                                                                // });
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Department'
                                                }}
                                            />
                                            {errors.sub_companies && <ErrorLabel message={errors.sub_companies?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Function&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="department"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterDepartment,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="department"
                                                        name="department"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Function"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={5}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                setSelectedFunction(value)
                                                                props.actionEmployeeNames({
                                                                    "entity_id": selectedEntity,
                                                                    "department_id": selectedDepartment,
                                                                    "branch_id": selectedBranch,
                                                                    "function_id": value,
                                                                });
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Function'
                                                }}
                                            />
                                            {errors.department && <ErrorLabel message={errors.department?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Mode&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="training_mode"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="training_mode"
                                                        name="training_mode"
                                                        dataSource={[
                                                            {
                                                                id: "Physical",
                                                                name: "Physical"
                                                            },
                                                            {
                                                                id: "Online",
                                                                name: "Online"
                                                            },
                                                            {
                                                                id: "Online+Offline",
                                                                name: "Online+Offline"
                                                            }
                                                        ]}
                                                        fields={{ text: "name", value: "id" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Training Mode"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Training Mode'
                                                }}
                                            />
                                            {errors.training_mode && <ErrorLabel message={errors.training_mode?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Branch City&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="branch_city"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterBranchCity,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="branch_city"
                                                        name="branch_city"
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        placeholder="Select Branch City"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        maxDisplayedTags={2}
                                                        height={"30%"}
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                props.actionMasterBrokerBranch({ 'city_ids': value });
                                                                console.log("value", value);
                                                                setValue('branch', null)
                                                                setMasterBranch([])
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Branch City'
                                                }}
                                            />
                                            {errors.branch_city && <ErrorLabel message={errors.branch_city?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Branch Address&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="branch"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: masterBranch,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="branch"
                                                        name="branch"
                                                        deferRendering={true}
                                                        displayExpr="address1"
                                                        placeholder="Select Branch Address"
                                                        valueExpr="id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        maxDisplayedTags={2}
                                                        height={"30%"}
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                console.log("value", value);
                                                                setSelectedBranch(value)
                                                                setEmployeeNames([])
                                                                setValue('emp_ids', '')
                                                                props.actionEmployeeNames({
                                                                    "entity_id": selectedEntity,
                                                                    "department_id": selectedDepartment,
                                                                    "branch_id": value,
                                                                    "function_id": selectedFunction,
                                                                });
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Branch Address'
                                                }}
                                            />
                                            {errors.branch && <ErrorLabel message={errors.branch?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Employee List</label>
                                            <Controller
                                                name="emp_ids"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: employeeNames,
                                                                key: 'user_id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        id="emp_ids"
                                                        name="emp_ids"
                                                        deferRendering={true}
                                                        displayExpr="full_name"
                                                        placeholder="Select Employees"
                                                        valueExpr="user_id"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        maxDisplayedTags={2}
                                                        height={"30%"}
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                                console.log("value", value);
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                            // rules={{
                                            //     required: 'Please Select Employees'
                                            // }}
                                            />
                                            {/* {errors.emp_ids && <ErrorLabel message={errors.emp_ids?.message} />} */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Training Details&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="training_details"
                                                name="training_details"
                                                rows={5}
                                                maxLength={200}
                                                minLength={10}
                                                placeholder="Enter Short Description"
                                                {...register('training_details', {
                                                    required: 'Short Description is required',
                                                    minLength: {
                                                        value: 10,
                                                        message: "Please enter minimum 10 Characters"
                                                    }
                                                })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                {/* <div className="flex-column"><small className="text-muted">{`Max ${200} characters`}</small></div> */}
                                                <div className="flex-column"><small>{watchTrainingDetails != null ? watchTrainingDetails.length : 0}/{200}</small></div>
                                            </div>
                                            {errors.training_details && <ErrorLabel message={errors.training_details?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Speaker Name&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="speaker_name"
                                                name="speaker_name"
                                                rows={5}
                                                maxLength={200}
                                                placeholder="Enter Speaker Name"
                                                {...register('speaker_name', {
                                                    required: 'Speaker Name is required',
                                                })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                {/* <div className="flex-column"><small className="text-muted">{`Max ${200} characters`}</small></div> */}
                                                <div className="flex-column"><small>{watchSpeakerName != null ? watchSpeakerName.length : 0}/{200}</small></div>
                                            </div>
                                            {errors.speaker_name && <ErrorLabel message={errors.speaker_name?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <fieldset class="form-group">
                                            <label for="basicInputFile">Speaker Photo&nbsp;<span className="danger">*</span></label>
                                            <div class="custom-file">
                                                <input
                                                    type="file"
                                                    class="custom-file-input"
                                                    accept=".jpg, .jpeg, .png"
                                                    id="speaker_photo"
                                                    name="speaker_photo"
                                                    onChange={(event) => {
                                                        $('#error-pdf-doc-not-found').addClass('d-none')
                                                        if (event.target.files != null) {

                                                            var fileSize = event.target.files[0]?.size;

                                                            var ext = (event.target.files[0]?.name)?.split('.').pop();

                                                            if (checkFileExtension(ext, validExt)) {

                                                                if (Math.round(fileSize / 1024) <= MEETING_TRAINING_FILE_SIZE) {
                                                                    setUploadCertificateCopy(event.target.files[0]);
                                                                } else {
                                                                    Swal.fire({
                                                                        title: 'Error',
                                                                        icon: 'error',
                                                                        html: MEETING_TRAINING_FILE_ERROR_MESSAGE,
                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                    }).then((result) => {

                                                                    });
                                                                }
                                                            } else {
                                                                Swal.fire({
                                                                    title: 'Upload Error',
                                                                    text: 'You can only upload jpg, jpeg & png files',
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            }
                                                        } else {
                                                            $('#error-pdf-doc-not-found').removeClass('d-none')
                                                        }
                                                    }}
                                                />
                                                <small className="error-msg d-none text-danger" id="error-pdf-doc-not-found">Document is required</small>
                                                <label class="custom-file-label" for="speaker_photo">
                                                    {
                                                        uploadCertificateCopy != null ?
                                                            uploadCertificateCopy.name : "Choose file"
                                                    }
                                                </label>
                                                {
                                                    brokerTrainingDetail?.speaker_photo != '' && brokerTrainingDetail?.speaker_photo != null ?
                                                        <div id="view_document mt-1" >
                                                            <a className="primary"
                                                                onClick={() => {
                                                                    if (brokerTrainingDetail?.speaker_photo?.split('.').pop() == 'pdf' ||
                                                                        brokerTrainingDetail?.speaker_photo?.split('.').pop() == 'PDF' ||
                                                                        brokerTrainingDetail?.speaker_photo?.split('.').pop() == 'png' ||
                                                                        brokerTrainingDetail?.speaker_photo?.split('.').pop() == 'jpg' ||
                                                                        brokerTrainingDetail?.speaker_photo?.split('.').pop() == 'jpeg' ||
                                                                        brokerTrainingDetail?.speaker_photo?.split('.').pop() == 'gif'
                                                                    ) {
                                                                        setSelectedDocumentIndex(brokerTrainingDetail?.speaker_photo)
                                                                        setShowDocumentDetails(true);
                                                                        setExtension(brokerTrainingDetail?.speaker_photo?.split('.').pop())
                                                                        setDocumentDetails(brokerTrainingDetail)
                                                                    } else {
                                                                        downloadFile(brokerTrainingDetail?.speaker_photo);
                                                                    }
                                                                }}
                                                            >
                                                                View Photo
                                                            </a>
                                                            <a id="downloadLink" className="d-none"></a>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="status"
                                                        name="status"
                                                        dataSource={[
                                                            {
                                                                id: "Active",
                                                                name: "Active"
                                                            },
                                                            {
                                                                id: 'Inactive',
                                                                name: 'Inactive'
                                                            },
                                                        ]}
                                                        fields={{ text: "name", value: "id" }}
                                                        allowFiltering={false}
                                                        placeholder="Select Status"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Status'
                                                }}
                                            />
                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions border-0 float-none">
                                    <button
                                        id="edit-function"
                                        className="btn btn-primary mr-1 shadow round text-uppercase"
                                        type="submit"
                                    >
                                        <div className="d-flex align-items-center">
                                            Submit
                                            <span id="edit-function-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </div>
                                    </button>
                                    <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            props.history.goBack();
                                        }}
                                    >Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterTraining = null;
    var MasterBrokerEntity = null;
    var MasterBrokerSubEntity = null;
    var MasterBrokerDepartment = null;
    var MasterBrokerBranch = null;
    var BrokerTrainingDetail = null;
    var BrokerTrainingEdit = null;
    var MasterBranchCity = null;
    var EmployeeNames = null;
    var EmployeeExpiredCertificate = null;

    if (
        state.BrokerTrainingEditReducer != null &&
        Object.keys(state.BrokerTrainingEditReducer).length > 0 &&
        state.BrokerTrainingEditReducer.constructor === Object
    ) {
        switch (state.BrokerTrainingEditReducer.tag) {
            case ACTION_UPDATE_MASTER_TRAINING_SUCCESS:
                MasterTraining = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { MasterTraining };
            case ACTION_UPDATE_MASTER_BROKER_ENTITY_SUCCESS:
                MasterBrokerEntity = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { MasterBrokerEntity };
            case ACTION_UPDATE_MASTER_BROKER_SUB_ENTITY_SUCCESS:
                MasterBrokerSubEntity = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { MasterBrokerSubEntity };
            case ACTION_UPDATE_MASTER_BROKER_DEPARTMENT_SUCCESS:
                MasterBrokerDepartment = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { MasterBrokerDepartment };
            case ACTION_UPDATE_MASTER_TRAINING_BRANCH_SUCCESS:
                MasterBrokerBranch = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { MasterBrokerBranch };
            case ACTION_BROKER_TRAINING_DETAIL_SUCCESS:
                BrokerTrainingDetail = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { BrokerTrainingDetail };
            case ACTION_BROKER_TRAINING_EDIT_SUCCESS:
                BrokerTrainingEdit = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { BrokerTrainingEdit };
            case ACTION_EDIT_BRANCH_CITY_SUCCESS:
                MasterBranchCity = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { MasterBranchCity };
            case ACTION_EDIT_EMPLOYEE_NAMES_SUCCESS:
                EmployeeNames = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { EmployeeNames };
            case ACTION_EDIT_EXPIRED_CERTIFICATE_EMPLOYEE_SUCCESS:
                EmployeeExpiredCertificate = Object.assign({}, state.BrokerTrainingEditReducer.data);
                delete state.BrokerTrainingEditReducer;
                return { EmployeeExpiredCertificate };
            default:

        }
    }
    return {
        MasterTraining,
        MasterBrokerEntity,
        MasterBrokerSubEntity,
        MasterBrokerDepartment,
        MasterBrokerBranch,
        BrokerTrainingDetail,
        BrokerTrainingEdit,
        MasterBranchCity,
        EmployeeNames,
        EmployeeExpiredCertificate
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionMasterTraining,
        actionMasterBrokerEntity,
        actionMasterBrokerSubEntity,
        actionMasterBrokerDepartment,
        actionMasterBrokerBranch,
        actionBrokerTrainingDetail,
        actionBrokerTrainingEdit,
        actionBranchCity,
        actionEmployeeNames,
        actionExpiredCertificateEmployee

    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditTraining))