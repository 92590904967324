import { ACTION_GROUP_PROFILE_DETAIL_SUCCESS } from "./action";
import createReducer from "../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const GroupProfileViewReducer = createReducer(INITIAL_STATE, {
    [ACTION_GROUP_PROFILE_DETAIL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GROUP_PROFILE_DETAIL_SUCCESS,
                loading: false,
            }
        );
    }

});
export default GroupProfileViewReducer;