import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { actionGeneralPlacementList, ACTION_GENERAL_PREFERENCE_LIST_SUCCESS, ACTION_GENERAL_PREFERENCE_DELETE_SUCCESS, actionGeneralPlacementDelete } from "./action";
import { KEY_TOAST_MESSAGE, getData } from "../../../../../../utils/preferences";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeData } from "jquery";
import { UNAUTHORIZED } from "../../../../../../constants";
import $ from 'jquery';
import { toast } from "react-toastify";
import { verifyPermission } from "../../../../../../utils";
import Swal from "sweetalert2";

const GeneralPlacementList = (props) => {
    const { match } = props;
    const [generalPlacementList, setGeneralPlacementList] = useState([]);
    const [placementsRight, setPlacementsRight] = useState(null)
    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionGeneralPlacementList();
    }
    useEffect(initialRender, []);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "insurer_company", caption: "Insurer Name" },
        { dataField: "department_name", caption: "Department" },
        { dataField: "policy_type", caption: "Policy" },
        { dataField: "rating", caption: "Preference" },

    ]);

    const onGeneralPlacementDeleteUpdate = () => {
        const { GeneralPlacementDelete } = props
        if (GeneralPlacementDelete != null) {
            var GeneralPlacementDeleteResponse = Object.assign({}, GeneralPlacementDelete);
            if (GeneralPlacementDeleteResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                props.actionGeneralPlacementList();
                toast.success(GeneralPlacementDeleteResponse.message);
            } else {
                toast.error(GeneralPlacementDeleteResponse.message);
                $("#progress-loader").addClass("hidden");
                switch (GeneralPlacementDeleteResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGeneralPlacementDeleteUpdate, [props.GeneralPlacementDelete])

    const onGeneralPlacementListUpdate = () => {
        const { GeneralPlacementList } = props
        if (GeneralPlacementList != null) {
            var GeneralPlacementListResponse = Object.assign({}, GeneralPlacementList);
            if (GeneralPlacementListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setGeneralPlacementList(GeneralPlacementListResponse.response?.data);
                setPlacementsRight(GeneralPlacementListResponse.response.authorized)
            } else {
                $("#progress-loader").addClass("hidden");
                switch (GeneralPlacementListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGeneralPlacementListUpdate, [props.GeneralPlacementList])

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        <Button
                            render={(params) => (
                                params.data.authorized === true ?
                                    <button
                                        className="btn btn-icon btn-sm"
                                        onClick={() => {
                                            props.history.push({ pathname: `${match.url}/edit`, state: { data: params.data } });
                                        }}
                                    >
                                        <FiEdit size={16} className="primary" />
                                    </button>
                                    :
                                    <></>
                            )}
                        />
                        <Button
                            render={(params) => (
                                params.data.authorized === true ?
                                    <button
                                        className="btn btn-icon btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, you want to delete this preference?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                            }).then(({ isConfirmed }) => {
                                                if (isConfirmed) {
                                                    props.actionGeneralPlacementDelete({ id: params.data.id })
                                                }
                                            })
                                        }}
                                    >
                                        <FiTrash2 size={16} className="primary" />
                                    </button>
                                    :
                                    <></>
                            )}
                        />
                    </Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Add Preference</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {placementsRight === true ?
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push(`${match.url}/add`);
                                        }}
                                    >
                                        Add General Preference
                                    </button>
                                </li>
                                :
                                <></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={generalPlacementList}
                                    showBorders={true}
                                    export={{
                                        fileName: "General Preference",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var GeneralPlacementList = null;
    var GeneralPlacementDelete = null;

    if (
        state.GeneralPreferenceListReducer != null &&
        Object.keys(state.GeneralPreferenceListReducer).length > 0 &&
        state.GeneralPreferenceListReducer.constructor === Object
    ) {
        switch (state.GeneralPreferenceListReducer.tag) {
            case ACTION_GENERAL_PREFERENCE_LIST_SUCCESS:
                GeneralPlacementList = Object.assign({}, state.GeneralPreferenceListReducer.data);
                delete state.GeneralPreferenceListReducer;
                return { GeneralPlacementList };
            case ACTION_GENERAL_PREFERENCE_DELETE_SUCCESS:
                GeneralPlacementDelete = Object.assign({}, state.GeneralPreferenceListReducer.data);
                delete state.GeneralPreferenceListReducer;
                return { GeneralPlacementDelete };
            default:

        }
    }
    return {
        GeneralPlacementList,
        GeneralPlacementDelete,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGeneralPlacementList, actionGeneralPlacementDelete }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GeneralPlacementList))


