import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_ACCOMPAINED_BY_SUCCESS, ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS, ACTION_GET_BRANCH_WISE_CONTACT_SUCCESS } from "./action";
import { ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS } from "./action";
import { ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS } from "./action";
import { ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS } from "./action";
import { ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS } from "./action";
import { ACTION_INSURANCE_MEETING_SAVE_SUCCESS } from "./action";
import { ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddInsuranceMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_STATE_BY_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CITY_BY_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_BRANCH_BY_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_INSURANCE_MEETING_SAVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INSURANCE_MEETING_SAVE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INSURANCE_MEETING_CONTACT_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INSURANCE_MEETING_ADD_CONTACT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_BRANCH_WISE_CONTACT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_BRANCH_WISE_CONTACT_SUCCESS,
                loading: false,
            }
        );
    }
});
export default AddInsuranceMeetingReducer;