import { ACTION_ASSET_REQUEST_ADD_SUCCESS, ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AssetRequestAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_ASSET_REQUEST_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ASSET_REQUEST_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
});

export default AssetRequestAddReducer;