import React, { useState, useEffect } from "react"
import { Doughnut } from 'react-chartjs-2';
import Chart from "react-apexcharts";

const Bar = (props) => {
    const [details, setDetails] = useState(null);

    const initialRender = () => {
        if (props != null) {
            setDetails(props.details)
        }
    }
    useEffect(initialRender, []);
    return (
        <div>
            {
                props.details != null ?
                    <div class="row rounded bg-accent-2">
                        <div class="col-12 col-md-6 col-lg-6">
                            <div class="card">
                                <div class="card-content">
                                    <div class="top-row statistics-card-title">
                                        <div class="statistics-chart d-flex justify-content-center align-self-center">
                                            <Chart
                                                type="donut"
                                                height={350}
                                                width={550}
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },

                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "60%",
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "10px",
                                                        fontWeight: 600,
                                                        position: "bottom",
                                                        markers: {
                                                            width: 5,
                                                            height: 5,
                                                            radius: 5,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: [
                                                        "Total Premium",
                                                        "Total Claim Amount",
                                                    ],
                                                }}
                                                series={[
                                                    props.details.total_premium,
                                                    props.details.total_claim,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                            <div class="card">
                                <div class="card-content">
                                    <div class="top-row statistics-card-title">
                                        <div class="statistics-chart d-flex justify-content-center align-self-center">
                                            <Chart
                                                type="donut"
                                                height={350}
                                                width={550}
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },

                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "60%",
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "10px",
                                                        fontWeight: 600,
                                                        position: "bottom",
                                                        markers: {
                                                            width: 5,
                                                            height: 5,
                                                            radius: 5,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: [
                                                        "Total Meeting",
                                                        "Total Policies",
                                                        "Total Clients",
                                                        "Pending Claims",
                                                        "Settled Claims",
                                                    ],
                                                }}
                                                series={[
                                                    props.details.no_of_meetings,
                                                    props.details.no_of_policies,
                                                    props.details.no_of_clients,
                                                    props.details.no_of_pending_claims,
                                                    props.details.no_of_settled_claims,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }

        </div>
    )
}

export default Bar
