import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { generateInitialLettersString } from '../../../../../../utils';
import { CANCEL_BY_EMPLOYEE, REQUEST_REGULARIZE, UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { withAuth } from 'react-auth-guard';
import { removeData, getData, setData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast, ToastContainer } from "react-toastify";
import { actionRegularizeRequestView, ACTION_REGULARIZE_REQUEST_VIEW_SUCCESS } from './action';
import { actionRegularizeRequestChangeStatus, ACTION_REGULARIZE_REQUEST_CHANGE_STATUS_SUCCESS } from './action';
import RequestHistory from '../../../../../../component/widgets/request_history';

const RegularizeRequestView = (props) => {

    const [regularizeRequestView, setRegularizeRequestView] = useState([]);
    const [changeStatus, setChangeStatus] = useState();
    const [requestHistory, setRequestHistory] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionRegularizeRequestView({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onRegularizeViewUpdate = () => {
        const { RegularizeView } = props;
        if (RegularizeView != null) {
            var RegularizeViewResponse = Object.assign({}, RegularizeView);
            if (RegularizeViewResponse.result === true) {
                setRegularizeRequestView(RegularizeViewResponse.response);
                if (RegularizeViewResponse.response.request_status.length > 0) {
                    setRequestHistory(RegularizeViewResponse.response.request_status);
                }
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (RegularizeViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onRegularizeViewUpdate, [props.RegularizeView])

    const onRegularizeRequestChangeStatusUpdate = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        const { RegularizeRequestChangeStatus } = props;
        var RegularizeRequestChangeStatusResponse = Object.assign({}, RegularizeRequestChangeStatus);
        if (RegularizeRequestChangeStatusResponse.result === true) {
            setChangeStatus(RegularizeRequestChangeStatusResponse.response);
            props.actionRegularizeRequestView({ id: props.location.state.id });
            setData(KEY_TOAST_MESSAGE, "Status Change Successfully");
        } else {
            switch (RegularizeRequestChangeStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onRegularizeRequestChangeStatusUpdate, [props.RegularizeRequestChangeStatus])

    return (
        <>
            <ScreenTitle title="Regularize Request Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (regularizeRequestView != null && regularizeRequestView.profile_photo != null) ?
                                                <img
                                                    src={regularizeRequestView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(regularizeRequestView.full_name))}

                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    regularizeRequestView != null && regularizeRequestView.full_name != null ?
                                                        regularizeRequestView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success font-small-1 text-bold-500 round">
                                                {
                                                    regularizeRequestView != null && regularizeRequestView.designation_name != null ?
                                                        regularizeRequestView.designation_name : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        regularizeRequestView != null && regularizeRequestView.status != null ?
                                                            regularizeRequestView.status : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2">By <span className="text-bold-700 text-justify">
                                                {

                                                    regularizeRequestView != null && regularizeRequestView.status != null &&
                                                        regularizeRequestView.status != 'Cancel By Employee' ? regularizeRequestView?.approval_manager_name : regularizeRequestView.full_name

                                                }
                                            </span> On: <span className="text-primary">{regularizeRequestView != null && regularizeRequestView.modified_at != null ? regularizeRequestView.modified_at : '-'}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">User Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.full_name != null ?
                                            regularizeRequestView.full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.approval_manager_name != null ?
                                            regularizeRequestView.approval_manager_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Old Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.old_status != null ?
                                            regularizeRequestView.old_status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">New Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.new_status != null ?
                                            regularizeRequestView.new_status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Reason</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.reason != null ?
                                            regularizeRequestView.reason : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Regularize Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.regularize_date != null ?
                                            regularizeRequestView.regularize_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.status != null ?
                                            regularizeRequestView.status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeRequestView != null && regularizeRequestView.remark != null ?
                                            regularizeRequestView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 pt-3 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'REGULARIZE_APPROVAL_NOTIFICATION') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >Back</button>
                            {
                                regularizeRequestView != null && (regularizeRequestView.status === 'Reviewing By Superior') ?
                                    <button
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, Do you want to cancel your regularize request?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                                inputPlaceholder: 'Remark (If Any)',
                                                input: 'text',
                                            }).then(({ isConfirmed, value }) => {
                                                if (isConfirmed) {
                                                    setRequestHistory(null);
                                                    props.actionRegularizeRequestChangeStatus({
                                                        "id": props.location.state.id,
                                                        "remark": value.length > 0 ? value : '',
                                                        "status": CANCEL_BY_EMPLOYEE,
                                                        "type": REQUEST_REGULARIZE
                                                    })
                                                }
                                            })
                                        }}
                                    >Cancel Regularize Request</button>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}
const mapStateToProps = (state) => {
    var RegularizeView = null;
    var RegularizeRequestChangeStatus = null;

    if (
        state.RegularizeRequestViewReducer != null &&
        Object.keys(state.RegularizeRequestViewReducer).length > 0 &&
        state.RegularizeRequestViewReducer.constructor === Object
    ) {
        switch (state.RegularizeRequestViewReducer.tag) {
            case ACTION_REGULARIZE_REQUEST_VIEW_SUCCESS:
                RegularizeView = Object.assign({}, state.RegularizeRequestViewReducer.data);
                delete state.RegularizeRequestViewReducer;
                return { RegularizeView };
            case ACTION_REGULARIZE_REQUEST_CHANGE_STATUS_SUCCESS:
                RegularizeRequestChangeStatus = Object.assign({}, state.RegularizeRequestViewReducer.data);
                delete state.RegularizeRequestViewReducer;
                return { RegularizeRequestChangeStatus };
            default:
        }
    }

    return {
        RegularizeView,
        RegularizeRequestChangeStatus
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRegularizeRequestView, actionRegularizeRequestChangeStatus }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RegularizeRequestView));