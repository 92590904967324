import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiEdit, FiMail, FiPhone } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionServeyorMeetingsView, ACTION_SURVEYOR_MEETING_VIEW_SUCCESS } from './action';
import _ from 'lodash';
import { generateInitialLettersString, linkify } from '../../../../../../utils';
import HTMLParser from 'html-react-parser';
import { toast } from 'react-toastify';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const ServeyorMeetingView = (props) => {

    const [serveyorMeetingsDetails, setServeyorMeetingsDetails] = useState(null);
    const [accompaniedDetails, setAccompaniedDetails] = useState([]);
    const [meetingContacts, setMeetingContacts] = useState([]);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        const urlParams = new URLSearchParams(window.location?.search);
        const id = urlParams.get('id');
        if (props.location?.state !== undefined || id != null) {
            props.actionServeyorMeetingsView({ id: props.location?.state?.serveyor_meeting_id != undefined ? props.location?.state?.serveyor_meeting_id : id });
        }
    }
    useEffect(initialRender, []);

    const onServeyorMeetingsViewUpdate = () => {
        const { ServeyorMeetingsView } = props;
        var ServeyorMeetingsViewResponse = Object.assign({}, ServeyorMeetingsView);
        if (ServeyorMeetingsViewResponse.result === true) {
            setServeyorMeetingsDetails(ServeyorMeetingsViewResponse.response);
            setAccompaniedDetails(ServeyorMeetingsViewResponse.response.accompany_by);
            setMeetingContacts(ServeyorMeetingsViewResponse.response.meeting_contacts);
        } else {
            switch (ServeyorMeetingsViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onServeyorMeetingsViewUpdate, [props.ServeyorMeetingsView]);

    const renderMeetingLocation = (location) => {
        try {
            var formatText = location
            if (serveyorMeetingsDetails != null && (serveyorMeetingsDetails.location !== "null" && serveyorMeetingsDetails.location !== null)) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderMeetingAgenda = (agenda) => {
        try {
            var formatText = agenda
            if (serveyorMeetingsDetails != null && serveyorMeetingsDetails.agenda != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderMeetingDescription = (description) => {
        try {
            var formatText = description
            if (serveyorMeetingsDetails != null && serveyorMeetingsDetails.description != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderContacts = () => {
        var arrContacts = []
        if (meetingContacts != null && meetingContacts.length > 0) {
            meetingContacts.forEach((contact, indexContact) => {
                arrContacts.push(
                    <div class="col-12 px-0 mb-1 border-bottom pb-1">
                        <div className="float-left">
                            <div className="d-flex flex-row mb-1">
                                <div className="flex-column mr-half">
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.contact_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 " 
                                >
                                           
                                            {contact.contact_name}
                                        </p>
                                    <div class="font-small-3 text-bold-700">{contact.contact_designation}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrContacts;
    }

    const renderAccompanied = () => {
        var arrAccompanied = []
        if (accompaniedDetails != null && accompaniedDetails.length > 0) {
            accompaniedDetails.forEach((contact, indexContact) => {
                arrAccompanied.push(
                    <div class="col-12 px-0 mb-1 border-bottom pb-2">
                        <div className="float-left">
                            <div className="d-flex flex-row">
                                <div className="flex-column mr-half">
                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.full_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: '/profile',
                                            state: {
                                                user_id: contact?.user_id,
                                            }
                                        });
                                    }}>
                                        {contact.full_name}
                                        </p>
                                    <div class="font-small-3 text-bold-700">{contact.designation_name}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.mobile_no != null && contact.mobile_no.length > 0 ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.mobile_no != null && contact.mobile_no.length > 0 ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.mobile_no}</div>
                                        </div>
                                        <div className={`flex-column ${contact.email != null && contact.email.length > 0 ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.email != null && contact.email.length > 0 ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrAccompanied
    }

    return (
        <>
         <div className='row justify-content-between '>
                <div className='col-md-6'>
                <ScreenTitle title="Surveyor Meeting" />
                </div>
                {serveyorMeetingsDetails?.status?.toLowerCase() ==='open' &&
                <div className='col-md-6 text-right'>
                    <a onClick={() => {props.history.push({ pathname: `/meetings/serveyor-meetings/edit`, state: { serveyor_meeting_id: serveyorMeetingsDetails?.id} })}}>
                    <FiEdit size={16} className="primary" /></a>
                </div> 
}
            </div>
            
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="flex-column">
                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                            {
                                                serveyorMeetingsDetails != null ?
                                                    serveyorMeetingsDetails.day : ''
                                            }
                                        </h1>
                                    </div>
                                    <div className="flex-column">
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                serveyorMeetingsDetails != null ?
                                                    serveyorMeetingsDetails.month : ''
                                            }
                                        </h6>
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                serveyorMeetingsDetails != null ?
                                                    serveyorMeetingsDetails.year : ''
                                            }
                                        </h6>
                                    </div>
                                    <div className="d-flex d-md-none d-lg-none flex-column ml-auto">
                                        <div className="btn btn-outline-success btn-sm px-1">
                                            <h6 className="font-small-3 text-bold-500 my-0">
                                                {
                                                    serveyorMeetingsDetails != null ?
                                                        _.upperFirst(serveyorMeetingsDetails.status) : ''
                                                }
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 d-none d-md-flex d-lg-flex justify-content-start justify-content-md-end justify-content-lg-end align-items-center">
                                <div className={`btn btn-sm px-1 ${serveyorMeetingsDetails != null && (serveyorMeetingsDetails.status === 'close' || serveyorMeetingsDetails.status === 'cancel') ? 'btn-outline-danger' : 'btn-outline-success'}`}>
                                    <h6 className="font-small-3 text-bold-500 my-0">
                                        {
                                            serveyorMeetingsDetails != null ?
                                                _.upperFirst(serveyorMeetingsDetails.status) : ''
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mt-1">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            serveyorMeetingsDetails != null ?
                                                serveyorMeetingsDetails.from_time : ''
                                        }
                                    </div>
                                    <div className="flex-column px-1">to</div>
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            serveyorMeetingsDetails != null ?
                                                serveyorMeetingsDetails.to_time : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Surveyor Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        serveyorMeetingsDetails != null ?
                                            serveyorMeetingsDetails.client_name : ''}
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Agenda</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        serveyorMeetingsDetails != null ?
                                            renderMeetingAgenda(serveyorMeetingsDetails?.agenda) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Description</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        (serveyorMeetingsDetails != null && (serveyorMeetingsDetails.description !== "null" && serveyorMeetingsDetails.description !== null)) ?
                                            renderMeetingDescription(serveyorMeetingsDetails?.description) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Location</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        (serveyorMeetingsDetails != null && (serveyorMeetingsDetails.location !== "null" && serveyorMeetingsDetails.location !== null)) ?
                                            renderMeetingLocation(serveyorMeetingsDetails?.location) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Document</h6>
                                {
                                    (serveyorMeetingsDetails != null && serveyorMeetingsDetails.meeting_document != null) ?
                                        // <a target="_blank" href={serveyorMeetingsDetails.meeting_document} download={serveyorMeetingsDetails.meeting_document}>Click Here</a> : <>-</>
                                        <>
                                            <a className="primary"
                                                onClick={() => {
                                                    if (serveyorMeetingsDetails.meeting_document?.split('.').pop() == 'pdf' ||
                                                        serveyorMeetingsDetails.meeting_document?.split('.').pop() == 'PDF' ||
                                                        serveyorMeetingsDetails.meeting_document?.split('.').pop() == 'png' ||
                                                        serveyorMeetingsDetails.meeting_document?.split('.').pop() == 'jpg' ||
                                                        serveyorMeetingsDetails.meeting_document?.split('.').pop() == 'jpeg' ||
                                                        serveyorMeetingsDetails.meeting_document?.split('.').pop() == 'gif'
                                                    ) {
                                                        setSelectedDocumentIndex(serveyorMeetingsDetails.meeting_document)
                                                        setShowDocumentDetails(true);
                                                        setExtension(serveyorMeetingsDetails.meeting_document?.split('.').pop())
                                                        setDocumentDetails(serveyorMeetingsDetails)
                                                    } else {
                                                        downloadFile(serveyorMeetingsDetails.meeting_document);
                                                    }
                                                }}
                                            >
                                                Click Here
                                            </a>
                                            <a id="downloadLink" className="d-none"></a>
                                        </>
                                        :
                                        <>-</>
                                }
                            </div>
                            <div className={`col-12 col-md-12 col-lg-12 mb-1 ${serveyorMeetingsDetails != null && serveyorMeetingsDetails.minutes_of_meeting != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Minutes Of Meeting</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        serveyorMeetingsDetails != null ?
                                            serveyorMeetingsDetails.minutes_of_meeting : '-'
                                    }
                                </h4>
                            </div>
                            <div className={`col-12 col-md-12 col-lg-12 ${serveyorMeetingsDetails != null && serveyorMeetingsDetails.remark != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Remarks</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        serveyorMeetingsDetails != null ?
                                            serveyorMeetingsDetails.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <hr />
                        <h4>Contacts</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        meetingContacts.length > 0 ?
                                            renderContacts()
                                            :
                                            <h6 className="text-bold-500 text-center">No Contact Details Available</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Accompanied By</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12 pb-1">
                                <div className="row mx-0">
                                    {
                                        accompaniedDetails.length > 0 ?
                                            renderAccompanied()
                                            :
                                            <h6 className="text-bold-500 text-center">No Details Available</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Created By</h4>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    <div class="col-12 px-0 border-bottom pb-1" >
                                        <div className="float-left">
                                            <div className="d-flex flex-row">
                                                <div className="flex-column mr-half mb-1">
                                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                                        {generateInitialLettersString(serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details != null && serveyorMeetingsDetails?.created_by_details?.name != null ? serveyorMeetingsDetails.created_by_details.name : '')}
                                                    </div>
                                                </div>
                                                <div className="flex-column">
                                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer"
                                                    onClick={() => {
                                                        props.history.push({
                                                            pathname: '/profile',
                                                            state: {
                                                                user_id: serveyorMeetingsDetails?.created_by,
                                                            }
                                                        })
                                                    }}>
                                                        {serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details != null && serveyorMeetingsDetails?.created_by_details?.name != null ? serveyorMeetingsDetails.created_by_details.name : ''}</p>
                                                    <div class="font-small-3 text-bold-500 mb-half">{serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details?.designation_name != null ? serveyorMeetingsDetails.created_by_details.designation_name : ''}</div>
                                                    <div className="d-flex flex-row align-items-center mb-1">
                                                        <div className={`flex-column ${serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                            <FiPhone size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                            <div class="font-small-3 secondary">{serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details?.mobile_no != null ? serveyorMeetingsDetails.created_by_details.mobile_no : ''}</div>
                                                        </div>
                                                        <div className={`flex-column ${serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                            <FiMail size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                            <div class="d-inline font-small-3 secondary">{serveyorMeetingsDetails != null && serveyorMeetingsDetails?.created_by_details?.email != null ? serveyorMeetingsDetails.created_by_details.email : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ServeyorMeetingsView = null;
    if (
        state.ServeyorMeetingsViewReducer != null &&
        Object.keys(state.ServeyorMeetingsViewReducer).length > 0 &&
        state.ServeyorMeetingsViewReducer.constructor === Object
    ) {
        switch (state.ServeyorMeetingsViewReducer.tag) {
            case ACTION_SURVEYOR_MEETING_VIEW_SUCCESS:
                ServeyorMeetingsView = Object.assign({}, state.ServeyorMeetingsViewReducer.data);
                delete state.ServeyorMeetingsViewReducer;
                return { ServeyorMeetingsView };
            default:

        }
    }
    return {
        ServeyorMeetingsView
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionServeyorMeetingsView }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ServeyorMeetingView))