import React, { useEffect } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiPhone, FiMail, FiEdit } from 'react-icons/fi';
import { useState } from 'react';
import { actionRiMeetingsView, ACTION_RI_MEETING_VIEW_SUCCESS } from './action';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { UNAUTHORIZED } from '../../../../../../constants';
import _ from 'lodash';
import { generateInitialLettersString, linkify } from '../../../../../../utils';
import HTMLParser from 'html-react-parser';
import { toast } from 'react-toastify';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const RiMeetingView = (props) => {

    const [riMeetingsDetails, setRiMeetingsDetails] = useState(null);
    const [accompaniedDetails, setAccompaniedDetails] = useState([]);
    const [contactDetails, setContactDetails] = useState([])
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        const urlParams = new URLSearchParams(window.location?.search);
        const id = urlParams.get('id');
        props.actionRiMeetingsView({ id: props.location?.state?.ri_meeting != undefined ? props.location?.state?.ri_meeting : id });
    }
    useEffect(initialRender, []);

    const onRiMeetingViewUpdate = () => {
        const { RiMeetingView } = props;
        var RiMeetingViewResponse = Object.assign({}, RiMeetingView);
        if (RiMeetingViewResponse.result === true) {
            setRiMeetingsDetails(RiMeetingViewResponse.response);
            setAccompaniedDetails(RiMeetingViewResponse.response.accompany_by);
            setContactDetails(RiMeetingViewResponse.response.meeting_contacts);
        } else {
            switch (RiMeetingViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onRiMeetingViewUpdate, [props.RiMeetingView]);

    const renderMeetingAgenda = (agenda) => {
        try {
            var formatText = agenda
            if (riMeetingsDetails != null && riMeetingsDetails.agenda != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderMeetingDescription = (description) => {
        try {
            var formatText = description
            if (riMeetingsDetails != null && riMeetingsDetails.description != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderAccompanied = () => {
        var arrAccompanied = []
        if (accompaniedDetails != null && accompaniedDetails.length > 0) {
            accompaniedDetails.forEach((contact, indexContact) => {
                arrAccompanied.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row">
                                <div className="flex-column mr-half">
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.full_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer" 
                                    onClick={() => {
                                        props.history.push({
                                            pathname: "/profile",
                                            state: {
                                                user_id: contact.user_id
                                            }
                                        })
                                    }}>
                                        {contact.full_name}</p>
                                    <div class="font-small-3 text-bold-700">{contact.designation_name}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="flex-column">
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className="flex-column mr-1">
                                            <div class="font-small-3 secondary">{contact.mobile_no}</div>
                                        </div>
                                        <div className="flex-column">
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className="flex-column">
                                            <div class="d-inline font-small-3 secondary">{contact.email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrAccompanied
    }

    const renderContactDetails = () => {
        var arrContactDetails = []
        if (contactDetails != null && contactDetails.length > 0) {
            contactDetails.forEach((contact, indexContact) => {
                arrContactDetails.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row mb-1">
                                <div className="flex-column mr-half">
                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.contact_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{contact.contact_name}</p>
                                    <div class="font-small-3 text-bold-700">{contact.contact_designation}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrContactDetails
    }

    return (
        <>
        <div className='row justify-content-between '>
                <div className='col-md-6'>
                <ScreenTitle title="View RI Meetings" />
                </div>
                {riMeetingsDetails?.status?.toLowerCase() ==='open' &&
                <div className='col-md-6 text-right'>
                    <a onClick={() => {props.history.push({ pathname: `/meetings/ri-meetings/edit`, state: { ri_meeting: riMeetingsDetails?.id} })}}>
                    <FiEdit size={16} className="primary" /></a>
                </div> 
}
            </div>
           
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="flex-column">
                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                            {
                                                riMeetingsDetails != null ?
                                                    riMeetingsDetails.day : ''
                                            }
                                        </h1>
                                    </div>
                                    <div className="flex-column">
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                riMeetingsDetails != null ?
                                                    riMeetingsDetails.month : ''
                                            }
                                        </h6>
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                riMeetingsDetails != null ?
                                                    riMeetingsDetails.year : ''
                                            }
                                        </h6>
                                    </div>
                                    <div className="d-flex d-md-none d-lg-none flex-column ml-auto">
                                        <div className="btn btn-outline-success btn-sm px-1">
                                            <h6 className="font-small-3 text-bold-500 my-0">
                                                {
                                                    riMeetingsDetails != null ?
                                                        _.upperFirst(riMeetingsDetails.status) : ''
                                                }
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 d-none d-md-flex d-lg-flex justify-content-start justify-content-md-end justify-content-lg-end align-items-center">
                                <div className={`btn btn-sm px-1 ${riMeetingsDetails != null && (riMeetingsDetails.status === 'close' || riMeetingsDetails.status === 'cancel') ? 'btn-outline-danger' : 'btn-outline-success'}`}>
                                    <h6 className="font-small-3 text-bold-500 my-0">
                                        {
                                            riMeetingsDetails != null ?
                                                _.upperFirst(riMeetingsDetails.status) : ''
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mt-1">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            riMeetingsDetails != null ?
                                                riMeetingsDetails.from_time : ''
                                        }
                                    </div>
                                    <div className="flex-column px-1">to</div>
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            riMeetingsDetails != null ?
                                                riMeetingsDetails.to_time : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Client Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null && riMeetingsDetails.client_type != null ?
                                            _.upperFirst(riMeetingsDetails.client_type) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Client</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null && riMeetingsDetails.client_name != null ?
                                            riMeetingsDetails.client_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Country</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null && riMeetingsDetails.country_name != null ?
                                            riMeetingsDetails.country_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Business Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null && riMeetingsDetails.business_type != null ?
                                            riMeetingsDetails.business_type : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Agenda</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null && riMeetingsDetails.agenda != null ?
                                            renderMeetingAgenda(riMeetingsDetails?.agenda) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Description</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null && riMeetingsDetails.description != null ?
                                            renderMeetingDescription(riMeetingsDetails?.description) : '-'
                                    }
                                </h4>
                            </div>

                            <div className={`col-12 col-md-12 col-lg-12 mb-1 ${riMeetingsDetails != null && riMeetingsDetails.minutes_of_meeting != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Pinc Minutes</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null ?
                                            riMeetingsDetails.minutes_of_meeting : '-'
                                    }
                                </h4>
                            </div>
                            <div className={`col-12 col-md-12 col-lg-12  mb-1 ${riMeetingsDetails != null && riMeetingsDetails.remark != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Minutes</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        riMeetingsDetails != null ?
                                            riMeetingsDetails.remark : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1 ">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Document</h6>
                                {
                                    (riMeetingsDetails != null && riMeetingsDetails.meeting_document != null) ?
                                        // <a target="_blank" href={riMeetingsDetails.meeting_document} download="Meeting Document">View Document</a> 
                                        <>
                                            <a className="primary"
                                                onClick={() => {
                                                    if (riMeetingsDetails.meeting_document?.split('.').pop() == 'pdf' ||
                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'PDF' ||
                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'png' ||
                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'jpg' ||
                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'jpeg' ||
                                                        riMeetingsDetails.meeting_document?.split('.').pop() == 'gif'
                                                    ) {
                                                        setSelectedDocumentIndex(riMeetingsDetails.meeting_document)
                                                        setShowDocumentDetails(true);
                                                        setExtension(riMeetingsDetails.meeting_document?.split('.').pop())
                                                        setDocumentDetails(riMeetingsDetails)
                                                    } else {
                                                        downloadFile(riMeetingsDetails.meeting_document);
                                                    }
                                                }}
                                            >
                                                Click Here
                                            </a>
                                            <a id="downloadLink" className="d-none"></a>
                                        </>
                                        :
                                        <>-</>
                                }
                            </div>
                        </div>
                        {
                            riMeetingsDetails != null && riMeetingsDetails.prev_meeting_details != null ?
                                <>
                                    <hr />
                                    <h4>Previous Meeting Details</h4>
                                    <div className="row mb-1 mt-1">
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Date</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {
                                                    riMeetingsDetails.prev_meeting_details.start_date
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">From Time</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {
                                                    riMeetingsDetails.prev_meeting_details.from_time
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">To Time</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {
                                                    riMeetingsDetails.prev_meeting_details.to_time
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Agenda</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {
                                                    riMeetingsDetails.prev_meeting_details.agenda
                                                }
                                            </h4>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }
                        <hr />

                        <h4>Contact Details</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        contactDetails != null && contactDetails.length > 0 ?
                                            renderContactDetails()
                                            :
                                            <h6 className="text-bold-500 text-center">No Contact Details</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Accompanied By</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        accompaniedDetails.length > 0 ?
                                            renderAccompanied()
                                            :
                                            <h6 className="text-bold-500 text-center">No Details Available</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            riMeetingsDetails != null && riMeetingsDetails?.created_by_details != null ?
                                <>
                                    <h4>Created By</h4>
                                    <div className="row mb-1">
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="row mx-0">
                                                <div class="col-12 px-0 border-bottom">
                                                    <div className="float-left">
                                                        <div className="d-flex flex-row">
                                                            <div className="flex-column mr-half mb-half">
                                                                {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                                                <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                                                    {generateInitialLettersString(riMeetingsDetails != null && riMeetingsDetails?.created_by_details != null && riMeetingsDetails?.created_by_details?.name != null ? riMeetingsDetails.created_by_details.name : '')}
                                                                </div>
                                                            </div>
                                                            <div className="flex-column">
                                                                <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer" 
                                                                onClick={() => {
                                                                    props.history.push({
                                                                            pathname: '/Profile',
                                                                           state: {
                                                                            user_id: riMeetingsDetails.created_by
                                                                           } 
                                                                    }) 
                                                                }}>
                                                                    {riMeetingsDetails != null && riMeetingsDetails?.created_by_details != null && riMeetingsDetails?.created_by_details?.name != null ? riMeetingsDetails.created_by_details.name : ''}</p>
                                                                <div class="font-small-3 text-bold-700">{riMeetingsDetails != null && riMeetingsDetails?.created_by_details?.designation_name != null ? riMeetingsDetails.created_by_details.designation_name : ''}</div>
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <div className={`flex-column ${riMeetingsDetails != null && riMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                                        <FiPhone size={12} className="mr-half primary" />
                                                                    </div>
                                                                    <div className={`flex-column mr-1 ${riMeetingsDetails != null && riMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                                        <div class="font-small-3 secondary">{riMeetingsDetails != null && riMeetingsDetails?.created_by_details?.mobile_no != null ? riMeetingsDetails.created_by_details.mobile_no : ''}</div>
                                                                    </div>
                                                                    <div className={`flex-column ${riMeetingsDetails != null && riMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                                        <FiMail size={12} className="mr-half primary" />
                                                                    </div>
                                                                    <div className={`flex-column mr-1 ${riMeetingsDetails != null && riMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                                        <div class="d-inline font-small-3 secondary">{riMeetingsDetails != null && riMeetingsDetails?.created_by_details?.email != null ? riMeetingsDetails.created_by_details.email : ''}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }

                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    var RiMeetingView = null;
    if (
        state.RiMeetingsViewReducer != null &&
        Object.keys(state.RiMeetingsViewReducer).length > 0 &&
        state.RiMeetingsViewReducer.constructor === Object
    ) {
        switch (state.RiMeetingsViewReducer.tag) {
            case ACTION_RI_MEETING_VIEW_SUCCESS:
                RiMeetingView = Object.assign({}, state.RiMeetingsViewReducer.data);
                delete state.RiMeetingsViewReducer;
                return { RiMeetingView };
            default:

        }
    }
    return {
        RiMeetingView
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRiMeetingsView }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiMeetingView))