import { Route, Switch } from "react-router";
import HomeDetails from "./detail";

const HomeRoute = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={HomeDetails} />
    </Switch>
  );
};
export default  HomeRoute;
