import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import ScreenTitle from '../../../../../../component/screen_title';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import ErrorLabel from '../../../../../../component/form/error_label';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { checkFileExtension } from '../../../../../../utils';
import Swal from 'sweetalert2';
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { toast } from 'react-toastify';
import $ from "jquery";
import _ from 'lodash';
import { FiInfo } from 'react-icons/fi';
import { UNAUTHORIZED, PRIMARY_COLOR, CLAIM_FILE_SIZE, CLAIM_FILE_ERROR_MESSAGE } from '../../../../../../constants';
import { actionClaimsDocumentList, ACTION_CLAIMS_DOCUMENT_LIST_SUCCESS } from './action';
import { actionClaimsDocumentUpload, ACTION_CLAIMS_DOCUMENT_UPLOAD_SUCCESS } from './action';
import { actionClaimsDocumentURL, ACTION_GET_CLAIMS_DOCUMENT_SUCCESS } from './action';

const ClaimDocument = (props) => {
    const { handleSubmit, control, formState: { errors } } = useForm();

    const [claimsDocumentList, setClaimsDocumentList] = useState([]);
    const [claimDetails, setClaimDetails] = useState();
    const [uploadDocument, setUploadDocument] = useState(null);
    const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx', 'zip'];

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
        props.actionClaimsDocumentList({ claim_id: props.location.state.claim_id });
    }
    useEffect(initialRender, []);

    const onClaimsDocumentListUpdate = () => {
        const { ClaimsDocumentList } = props;
        if (ClaimsDocumentList != null) {
            var ClaimsDocumentListResponse = Object.assign({}, ClaimsDocumentList);
            if (ClaimsDocumentListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setClaimsDocumentList(ClaimsDocumentListResponse.response.documents);
                setClaimDetails(ClaimsDocumentListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                setClaimsDocumentList([]);
                switch (ClaimsDocumentListResponse.status) {
                    case UNAUTHORIZED:
                        $("#progress-loader").addClass("hidden");
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClaimsDocumentListUpdate, [props.ClaimsDocumentList]);

    const onClaimsDocumentUploadUpdate = () => {
        const { ClaimsDocumentUpload } = props;
        if (ClaimsDocumentUpload != null) {
            var ClaimsDocumentUploadResponse = Object.assign({}, ClaimsDocumentUpload);
            if (ClaimsDocumentUploadResponse.result === true) {
                setData(KEY_TOAST_MESSAGE, "Document Inserted Successfully");
                props.history.goBack();
            } else {
                switch (ClaimsDocumentUploadResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClaimsDocumentUploadUpdate, [props.ClaimsDocumentUpload]);

    const onClaimsDocumentGetUpdate = () => {
        const { ClaimsDocument } = props;
        if (ClaimsDocument != null) {

            window.open(ClaimsDocument.url);

        }
    }
    useEffect(onClaimsDocumentGetUpdate, [props.ClaimsDocument]);

    const onSubmit = (data) => {
        data.claim_id = props.location.state.claim_id;

        var files = [];
        if (uploadDocument != null) {
            files.push({ 'name': 'upload_document', 'value': uploadDocument });
            $("#claims_upload_document_loader").removeClass("hidden");
            $("#claims_upload_document").prop("disabled", true);
            props.actionClaimsDocumentUpload(data, files);
        } else {
            $('#error-pdf-doc-not-found').removeClass('d-none')
        }
        delete data.upload_document;

    }

    return (
        <>
            <div>
                <ScreenTitle title="Claim Upload Document" />
                <div className="card">
                    <div className="card-body">
                        <div class="form-body">
                            <form>
                                <div className="row mb-1">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <div><FiInfo size={16} />&nbsp;Claim Details</div>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-6 col-md-6">
                                                <h4 className="text-uppercase font-small-3 mb-half">PINC Claim Ref. No.</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimDetails != null && claimDetails.pinc_claim_ref_no != null ?
                                                            claimDetails.pinc_claim_ref_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6">
                                                <h4 className="text-uppercase font-small-3 mb-half">Customer Name</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimDetails != null && claimDetails.customer_name != null ?
                                                            claimDetails.customer_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6 mt-1">
                                                <h4 className="text-uppercase font-small-3 mb-half">Policy Control No.</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimDetails != null && claimDetails.control_no != null ?
                                                            claimDetails.control_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6 mt-1">
                                                <h4 className="text-uppercase font-small-3 mb-half">Policy No.</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimDetails != null && claimDetails.policy_no != null ?
                                                            claimDetails.policy_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="form-body">
                            <form>
                                <div className="row">
                                    <div class="col-12 col-md-12 col-lg-12 mt-1">
                                        <h4 class="d-flex align-items-center form-section mb-2 black">
                                            <FiInfo size={16} />&nbsp;Document
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        {
                                            claimsDocumentList != null && claimsDocumentList.length > 0 ?
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="px-1">Document Type</th>
                                                            <th className="px-1">Document Received Date</th>
                                                            <th className="px-1">Uploaded Document</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            claimsDocumentList.map((claimDocument, indexClaim) => (
                                                                <tr>
                                                                    <td>{_.capitalize(claimDocument.claim_document_type)}</td>
                                                                    <td>{claimDocument.document_receive_date}</td>
                                                                    <td>
                                                                        <button type="button" className="btn primary" onClick={() => {
                                                                            props.actionClaimsDocumentURL({
                                                                                'actual_file_relative_url': claimDocument.actual_file_relative_url
                                                                            });
                                                                        }}>View</button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <h4>Document Not Found</h4>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="form-body">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <h4 class="d-flex align-items-center form-section mb-2 black">
                                            <FiInfo size={16} />&nbsp;Upload Document
                                        </h4>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label class="label-control">Document Type&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="document_type"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={[
                                                            {
                                                                id: 'policy_copy',
                                                                name: 'Policy Copy'
                                                            },
                                                            {
                                                                id: 'mandate_letter',
                                                                name: 'Mandate Letter'
                                                            },
                                                            {
                                                                id: 'claim_form',
                                                                name: 'Claim Form'
                                                            },
                                                            {
                                                                id: 'claim_bill',
                                                                name: 'Claim Bill'
                                                            },
                                                            {
                                                                id: 'lor',
                                                                name: 'LOR'
                                                            },
                                                            {
                                                                id: 'photographs',
                                                                name: 'Photographs'
                                                            },
                                                            {
                                                                id: 'assessment',
                                                                name: 'Assessment'
                                                            },
                                                            {
                                                                id: 'settlement_details',
                                                                name: 'Settlement Details'
                                                            },
                                                            {
                                                                id: 'others',
                                                                name: 'Others'
                                                            },
                                                        ]}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={false}
                                                        placeholder="Select Document Type"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Document Type is required'
                                                }}
                                            />
                                            {errors.document_type && <ErrorLabel message={errors.document_type?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <fieldset class="form-group">
                                                <label for="basicInputFile">Select Document&nbsp;<span className="danger">*</span></label>
                                                <div class="custom-file">
                                                    <input type="file"
                                                        class="custom-file-input"
                                                        id="upload_document"
                                                        name="upload_document"
                                                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx, .zip"
                                                        onChange={(event) => {
                                                            $('#error-pdf-doc-not-found').addClass('d-none')
                                                            if (
                                                                event.target.files
                                                            ) {

                                                                var fileSize = event.target.files[0].size;
                                                                var ext = (event.target.files[0].name).split('.').pop();

                                                                if (checkFileExtension(ext, validExt)) {
                                                                    if (Math.round(fileSize / 1024) <= CLAIM_FILE_SIZE) {
                                                                        setUploadDocument(event.target.files[0]);
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Error',
                                                                            icon: 'error',
                                                                            html: CLAIM_FILE_ERROR_MESSAGE,
                                                                            confirmButtonColor: PRIMARY_COLOR
                                                                        }).then((result) => {

                                                                        });
                                                                    }
                                                                } else {

                                                                    Swal.fire({
                                                                        title: 'Upload Error',
                                                                        text: 'You can only upload jpg, jpeg, png, pdf, doc, docx, xlsx, xls, ppt, zip & pptx files',
                                                                        icon: 'error'
                                                                    }).then(success => {

                                                                    })

                                                                }
                                                            } else {
                                                                $('#error-pdf-doc-not-found').removeClass('d-none')
                                                            }
                                                        }}
                                                    />
                                                    <small className="error-msg d-none text-danger" id="error-pdf-doc-not-found">Document is required</small>
                                                    <label class="custom-file-label" for="upload_document">
                                                        {
                                                            uploadDocument != null ?
                                                                uploadDocument.name : "Choose file"
                                                        }
                                                    </label>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label class="label-control">Document Received Date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="document_receive_date"
                                                className="form-control"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        value={field.value}
                                                        acceptCustomValue={false}
                                                        placeholder='DD/MM/YYYY'
                                                        onValueChange={(value) => {
                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Document Received Date is required'
                                                }}
                                            />
                                            {errors.document_receive_date && <ErrorLabel message={errors.document_receive_date?.message} />}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <button className="btn btn-outline-secondary ml-0 shadow round text-uppercase"
                                            type="button"
                                            onClick={() => { props.history.goBack() }}> Back
                                        </button>
                                        <button id="claims_upload_document"
                                            class="btn btn-primary ml-1 shadow round text-uppercase"
                                            type="submit">
                                            <div class="d-flex align-items-center">Upload
                                                <span
                                                    id="claims_upload_document_loader"
                                                    class="spinner-border spinner-border-sm ml-half hidden">
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    var ClaimsDocumentList = null;
    var ClaimsDocumentUpload = null;
    var ClaimsDocument = null;
    if (
        state.ClaimsDocumentReducer != null &&
        Object.keys(state.ClaimsDocumentReducer).length > 0 &&
        state.ClaimsDocumentReducer.constructor === Object
    ) {
        switch (state.ClaimsDocumentReducer.tag) {
            case ACTION_CLAIMS_DOCUMENT_LIST_SUCCESS:
                ClaimsDocumentList = Object.assign({}, state.ClaimsDocumentReducer.data);
                delete state.ClaimsDocumentReducer;
                return { ClaimsDocumentList };
            case ACTION_CLAIMS_DOCUMENT_UPLOAD_SUCCESS:
                ClaimsDocumentUpload = Object.assign({}, state.ClaimsDocumentReducer.data);
                delete state.ClaimsDocumentReducer;
                return { ClaimsDocumentUpload };
            case ACTION_GET_CLAIMS_DOCUMENT_SUCCESS:
                ClaimsDocument = Object.assign({}, state.ClaimsDocumentReducer.data);
                delete state.ClaimsDocumentReducer;
                return { ClaimsDocument };
            default:
        }
    }
    return {
        ClaimsDocumentList,
        ClaimsDocumentUpload,
        ClaimsDocument
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionClaimsDocumentList,
        actionClaimsDocumentUpload,
        actionClaimsDocumentURL
    }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClaimDocument));