import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_GROUP_POLICIES } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const GROUP_ALL_POLICIES_LOADING = 'GROUP_ALL_POLICIES_LOADING';
export const GROUP_ALL_POLICIES_SUCCESS = 'GROUP_ALL_POLICIES_SUCCESS';

export function actionGroupPolicies(params) {
    return (dispatch, getState) => Api.post(API_GROUP_POLICIES, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(GROUP_ALL_POLICIES_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(GROUP_ALL_POLICIES_LOADING, { loading: false, data }));
    });
}
