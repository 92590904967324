var localStorage = window.localStorage;

export const KEY_USER_TOKEN = "user_token";
export const KEY_FIREBASE_TOKEN = "firebase_token";
export const KEY_USER = "user";
export const KEY_PERMISSION = "key_permission";
export const KEY_GLOBAL_SEARCH = "global_search";
export const KEY_TOAST_MESSAGE = "TOAST_MESSAGE";
export const KEY_MARK_INOUT = "MARK_INOUT_MESSAGE";
export const KEY_MEETING_DETAILS = "MEETING_DETAILS";
export const KEY_NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const KEY_CLAIM_DETAILS = "CLAIM_DETAILS";
export const KEY_WORKING_DAY = "KEY_WORKING_DAY"
/**
 *
 * @param {venu section} key
 * @param {*} data
 */
export const setData = (key, data) => {
	try {
		localStorage.setItem(key, data);
	} catch (error) {
		// Error saving data
	}
};

export const getData = (key) => {
	try {
		const value = localStorage.getItem(key);
		if (value !== null) {
			return value;
		}
	} catch (error) {
		return null;
	}
	return null;
};

export const removeData = (key) => {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		// Error while removing
	}
};

export const clearData = () => {
	try {
		localStorage.clear();
	} catch (error) {
		// Error while removing
	}
};
