/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import AllMeetingsReducer from "../routes/app/auth/meetings/all_meetings/list/reducer";
import CustomerMeetingsReducer from "../routes/app/auth/meetings/customer/list/reducer";
import InsuranceCompanyMeetingsReducer from "../routes/app/auth/meetings/insurance_company/list/reducer";
import InternalMeetingsReducer from "../routes/app/auth/meetings/internal/list/reducer";
import RiMeetingsReducer from "../routes/app/auth/meetings/ri-meetings/list/reducer";
import ServeyorMeetingsReducer from "../routes/app/auth/meetings/serveyor/list/reducer";
import LeadCreateCorporateReducer from "../routes/app/auth/lead/create/components/corporate/reducer";
import RegistrationReducer from "../routes/app/auth/hrms/registration/reducer";
import EmployeeListReducer from "../routes/app/auth/hrms/employeelist/reducer";
import AdminLoginReducer from "../routes/app/public/login/reducer";
import ClientListReducer from "../routes/app/auth/client/list/reducer";
import ClientViewReducer from "../routes/app/auth/client/view/reducer";
import ForgotPasswordReducer from "../routes/app/public/forgot_password/reducer";
import ChangePasswordReducer from "../routes/app/public/change_password/reducer";
import LeadReducer from "../routes/app/auth/lead/list/reducer";
import EmployeeViewReducer from "../routes/app/auth/hrms/employee_view/reducer";
import PermissionReducer from "../routes/app/auth/permission/roles/add/reducer";
import EmployeeEditReducer from "../routes/app/auth/hrms/employee_edit/reducer";
import EmployeeChangePasswordReducer from "../routes/app/auth/hrms/employeelist/change_password/reducer";
import AddCustomerMeetingReducer from "../routes/app/auth/meetings/customer/add/reducer";
import LeadCreatePolicyDetailsReducer from "../component/form/policy_details/reducer";
import ProbabilityReducer from "../routes/app/auth/proposal/create/reducer";
import ProposalListReducer from "../routes/app/auth/proposal/view/reducer"
// import LeadEditCorporateReducer from "../component/form/policy_details/reducer";
import AddInsuranceMeetingReducer from "../routes/app/auth/meetings/insurance_company/add/reducer";
import AddInternalMeetingReducer from "../routes/app/auth/meetings/internal/add/reducer";
import AddServeyorMeetingReducer from "../routes/app/auth/meetings/serveyor/add/reducer";
import AddRiMeetingReducer from "../routes/app/auth/meetings/ri-meetings/add/reducer";
// import LeadCreatePolicyDetailsReducer from "../routes/app/auth/lead/create/components/policy_details/reducer";
import LeadEditCorporateReducer from "../routes/app/auth/lead/edit/reducer";
import CustomerAddNewCustomerReducer from "../component/form/new_customer/reducer";
import LeadViewReducer from "../routes/app/auth/lead/view/reducer";
import LeadCreateRetailReducer from "../routes/app/auth/lead/create/components/retail/reducer";
import InternalMeetingsViewReducer from "../routes/app/auth/meetings/internal/view/reducer";
import InternalMeetingsEditReducer from "../routes/app/auth/meetings/internal/edit/reducer";
import EditInsuranceMeetingReducer from "../routes/app/auth/meetings/insurance_company/edit/reducer";
import InsuranceMeetingsViewReducer from "../routes/app/auth/meetings/insurance_company/view/reducer";
import AddTpaMeetingReducer from "../routes/app/auth/meetings/tpa/add/reducer";
import TpaMeetingsReducer from "../routes/app/auth/meetings/tpa/list/reducer";
import NewServeyorReducer from "../component/form/new_serveyor/reducer";
import NewTpaReducer from "../component/form/new_tpa/reducer";
import EditServeyorMeetingReducer from "../routes/app/auth/meetings/serveyor/edit/reducer";
import EditTpaMeetingReducer from "../routes/app/auth/meetings/tpa/edit/reducer";
import ServeyorMeetingsViewReducer from "../routes/app/auth/meetings/serveyor/view/reducer";
import TpaMeetingsViewReducer from "../routes/app/auth/meetings/tpa/view/reducer";
import InsuranceCompanyAddNewBranchReducer from "../component/form/insurance_company_contact_details/reducer";
import CustomerMeetingsViewReducer from "../routes/app/auth/meetings/customer/view/reducer";
import EditCustomerMeetingReducer from "../routes/app/auth/meetings/customer/edit/reducer";
import EditPermissionRoleReducer from "../routes/app/auth/permission/roles/edit/reducer";
import ViewPermissionRoleReducer from "../routes/app/auth/permission/roles/view/reducer";
import PermissionAddReducer from "../routes/app/auth/permission/permissions/add/reducer";
import EditPermissionReducer from "../routes/app/auth/permission/permissions/edit/reducer";
import PermissionRolesReducer from "../routes/app/auth/permission/roles/list/reducer";
import ListPermissionReducer from "../routes/app/auth/permission/permissions/list/reducer";
import FeedbackAddReducer from "../component/form/feedback/reducer";
import SearchListGetReducer from "../component/content/reducer";
import PopupCustomerMeetingsViewReducer from "../component/popups/meetings/customer/reducer";
import MyTeamVerticalReducer from '../routes/app/auth/teams/component/my_team/reducer';
import MyReportingVerticalReducer from '../routes/app/auth/teams/component/my_reporting/reducer';
import MyVerticalReducer from '../routes/app/auth/teams/component/my_verticals/reducer';
import MyProfileReducer from "../routes/app/auth/my_profile/reducer";
import AddEducationReducer from "../routes/app/auth/my_profile/educations/add/reducer";
import ActivityCreatePostReducer from "../component/activity_feed/create_post/reducer";
import ClientLeadListReducer from '../routes/app/auth/my_profile/tabs/clients/reducer';
import LeadListReducer from '../../src/routes/app/auth/my_profile/tabs/leads/reducer';
import MeetingListReducer from '../../src/routes/app/auth/my_profile/tabs/meetings/reducer';
import AddEmploymentReducer from "../routes/app/auth/my_profile/employment/add/reducer";
import EditEducationReducer from "../routes/app/auth/my_profile/educations/edit/reducer";
import EditEmploymentReducer from "../routes/app/auth/my_profile/employment/edit/reducer";
import EditPersonalInformationReducer from "../routes/app/auth/my_profile/personal_information/edit/reducer";
import GetPostReducer from "../component/activity_feed/post/reducer";
import ClientTabPoliciesViewReducer from '../routes/app/auth/client/view/tabs/policies/reducer';
import ClientTapProfileDetailsReducer from '../routes/app/auth/client/view/tabs/client_profile/reducer';
import ClientTabProposalsViewReducer from '../routes/app/auth/client/view/tabs/proposals/reducer';
import ClientTabMeetingsViewReducer from '../routes/app/auth/client/view/tabs/meetings/reducer';
import ClientTabClaimsReducer from '../routes/app/auth/client/view/tabs/claims/reducer';
import ClientPolicyDetailReducer from "../component/popups/policies/reducer";
import ClientTabBusinessSummaryReducer from "../routes/app/auth/client/view/tabs/business_summary/reducer";
import TabLeadProposalsViewReducer from '../routes/app/auth/lead/view/tabs/proposals/reducer';
import TabLeadMeetingViewReducer from '../routes/app/auth/lead/view/tabs/meetings/reducer';
import InsurerTabContactPersonReducer from "../routes/app/auth/insurer/view/tabs/contact_persons/reducer";
import InsurerViewTabMeetingsReducer from "../routes/app/auth/insurer/view/tabs/meetings/reducer";
import InsurerViewTabPoliciesReducer from "../routes/app/auth/insurer/view/tabs/policies/reducer";
import InsurerTabBusinessSummaryReducer from "../routes/app/auth/insurer/view/tabs/insurance_details/reducer";
import BusinessSummaryReducer from "../routes/app/auth/my_profile/tabs/business_summary/reducer";
import DashboardReducer from "../routes/app/auth/dashboard/detail/reducer";
import AddDocumentReducer from "../routes/app/auth/documents/add/reducer";
import DocumentListReducer from "../routes/app/auth/documents/list/reducer";
import PopupInsuranceMeetingsViewReducer from "../component/popups/meetings/insurance_company/reducer";
import MarkInMarkOutReducer from "../../src/component/header/reducer";
import InsurerViewTabClientsReducer from "../routes/app/auth/insurer/view/tabs/clients/reducer";
import InsurerViewTabCompanyNewsReducer from "../routes/app/auth/insurer/view/tabs/company_news/reducer";
import InsurerBusinessSummaryReducer from "../routes/app/auth/insurer/view/reducer"
import MyprofilePostReducer from "../routes/app/auth/my_profile/tabs/post/reducer";
import DashboardSummaryReducer from "../routes/app/auth/dashboard/summary/reducer";
import PermissionContentReducer from "../routes/app/auth/permission/content/list/reducer";
import EditPermissionContentReducer from "../routes/app/auth/permission/content/edit/reducer";
import DashboardEventReducer from "../component/activity_feed/events/reducer";
import HomeReducer from "../routes/app/auth/home/detail/reducer";
import CalendarHolidayReducer from "../routes/app/auth/holiday/list/reducer";
import HolidayAddReducer from "../routes/app/auth/holiday/add/reducer";
import EditHolidayReducer from "../routes/app/auth/holiday/edit/reducer";
import ViewHolidayReducer from "../routes/app/auth/holiday/view/reducer";
import LeaveRequestListReducer from "../routes/app/auth/hrms/leaves_request/list/reducer";
import LeaveRequestAddReducer from "../routes/app/auth/hrms/leaves_request/add/reducer";
import AddRegularizeReducer from "../routes/app/auth/hrms/regularize_request/add/reducer";
import RegularizeRequestListReducer from "../routes/app/auth/hrms/regularize_request/list/reducer";
import RegularizeRequestViewReducer from "../routes/app/auth/hrms/regularize_request/view/reducer";
import RegularizeApprovalListReducer from "../routes/app/auth/approvals/regularize_approval/list/reducer";
import RegularizeApprovalViewReducer from "../routes/app/auth/approvals/regularize_approval/view/reducer";
import LeaveRequestViewReducer from "../routes/app/auth/hrms/leaves_request/view/reducer";
import LeaveApprovalViewReducer from "../routes/app/auth/approvals/leave_approvals/view/reducer";
import LeaveApprovalListReducer from "../routes/app/auth/approvals/leave_approvals/list/reducer";
import ApprovalChangeStatusReducer from "../component/popups/approval_action/reducer";
import DashboardLatestFiveClientReducer from "../component/widgets/my_clients/reducer";
import UpcomingMeetingReducer from "../component/widgets/upcoming_meeting/reducer";
import DashboardAttendaceLeaveReducer from "../component/widgets/attandance_leaves/reducer";
import DashboardPendingApprovalReducer from "../component/widgets/pending_approval/reducer";
import DashboardManagerApprovalReducer from "../component/widgets/manager_approval/reducer";
import EditTeamMasterReducer from "../routes/app/auth/permission/team_master/edit/reducer";
import TeamMasterListReducer from '../routes/app/auth/permission/team_master/list/reducer';
import AddTeamMasterReducer from "../routes/app/auth/permission/team_master/add/reducer";
import CalenderReducer from "../routes/app/auth/holiday/calendar/reducer";
import LogOutReducer from "../../src/component/header/reducer";
import UserNotificationReducer from "../routes/app/auth/notification/list/reducer";
import EditAssignRetentionReducer from "../component/popups/edit_retention/reducer";
import RetentionListReducer from "../routes/app/auth/retention/list/reducer";
import ReinsuranceClientReducer from "../routes/app/auth/reinsurance/client/list/reducer";
import ReinsuranceClientEditReducer from "../routes/app/auth/reinsurance/client/edit/reducer";
import ImportMasterDataReducer from "../routes/app/auth/reinsurance/master/import_master_data/reducer";
import ExportReinsuranceMasterReducer from "../routes/app/auth/reinsurance/master/reducer";
import ImportClientDataReducer from "../routes/app/auth/reinsurance/client/import_client_data/reducer"
import RiMeetingsViewReducer from "../routes/app/auth/meetings/ri-meetings/view/reducer";
import RiMeetingsEditReducer from "../routes/app/auth/meetings/ri-meetings/edit/reducer";
import AddRiNewClientReducer from "../component/form/reinsurance_new_customer/reducer";
import AssetRequestViewReducer from "../routes/app/auth/hrms/asset_request/view/reducer";
import AssetApprovalListReducer from "../routes/app/auth/approvals/asset_approvals/list/reducer"
import AssetRequestAddReducer from "../routes/app/auth/hrms/asset_request/add/reducer";
import AssetApprovalViewReducer from "../routes/app/auth/approvals/asset_approvals/view/reducer";
import AssetRequestListReducer from "../routes/app/auth/hrms/asset_request/list/reducer";
import TravelRequestListReducer from "../routes/app/auth/hrms/travel_request/list/reducer";
import TravelRequestAddReducer from "../routes/app/auth/hrms/travel_request/add/reducer";
import TravelRequestViewReducer from "../routes/app/auth/hrms/travel_request/view/reducer";
import TravelApprovalListReducer from "../routes/app/auth/approvals/travel_approvals/list/reducer";
import TravelApprovalViewReducer from "../routes/app/auth/approvals/travel_approvals/view/reducer";
import CreateTaskReducer from "../routes/app/auth/task_details/create_task/reducer";
import TaskListReducer from "../routes/app/auth/task_details/task/list/reducer";
import TaskPoolListReducer from "../routes/app/auth/task_details/task_pool/list/reducer";
import TaskViewReducer from "../routes/app/auth/task_details/task/view/reducer";
import TaskPoolViewReducer from "../routes/app/auth/task_details/task_pool/view/reducer";
import TripDetailsReducer from "../component/form/trip_details/reducer";
import MandateAddReducer from "../routes/app/auth/claims/register/mandate/reducer";
import TaskStatusReducer from "../component/popups/task_approval/reducer";
import DashboardTaskStatusReducer from "../component/widgets/task_status/reducer"
import ViewClaimsReducer from "../routes/app/auth/claims/view/reducer";
import ClaimsReducer from "../routes/app/auth/claims/list/reducer";
import ClaimDashboardReducer from "../routes/app/auth/claims/dashboard/reducer";
import ItSupportAddReducer from "../routes/app/auth/hrms/it_support/add/reducer"
import ItSupportViewReducer from "../routes/app/auth/hrms/it_support/view/reducer";
import ItSupportReducer from "../routes/app/auth/hrms/it_support/list/reducer";
import CronDashboardViewReducer from "../component/popups/cron_dashboard/reducer";
import CronDashboardReducer from "../routes/app/auth/permission/cron_dashboard/reducer";
import PincReducer from "../routes/app/auth/claims/register/pinc/list/reducer";
import PincAddReducer from "../routes/app/auth/claims/register/pinc/add/reducer";
import AddLossSiteVisitReducer from "../routes/app/auth/claims/register/loss_site_visit/add/reducer";
import EditClaimsReducer from "../routes/app/auth/claims/edit/reducer";
import NewPasswordReducer from "../routes/app/public/new_password/reducer";
import LossSiteVisitReducer from "../routes/app/auth/claims/register/loss_site_visit/list/reducer";
import ClaimsDocumentReducer from "../routes/app/auth/claims/document/upload/reducer";
import DownloadClaimsMISListReducer from "../routes/app/auth/claims/download/claim_MIS/list/reducer";
import AddShiftTimingReducer from "../routes/app/auth/permission/shift_timing/add/reducer";
import EditShiftTimingReducer from "../routes/app/auth/permission/shift_timing/edit/reducer"
import ListShiftTimingReducer from "../routes/app/auth/permission/shift_timing/list/reducer"
import AddFinanceLimitReducer from "../routes/app/auth/permission/set_finance_manager/add/reducer";
import ListFinanceLimitReducer from "../routes/app/auth/permission/set_finance_manager/list/reducer";
import EditFinanceLimitReducer from "../routes/app/auth/permission/set_finance_manager/edit/reducer";
import ViewFinanceLimitReducer from "../routes/app/auth/permission/set_finance_manager/view/reducer";
// reports
import attendanceReportReducer from "../routes/app/auth/reports/attendance/attendance/reducer";
import LeaveReportReducer from "../routes/app/auth/reports/attendance/leave/reducer";
import meetingReportReducer from "../routes/app/auth/reports/meeting/meeting/reducer";
import meetingDetailReportReducer from "../routes/app/auth/reports/meeting/meeting_detail_report/reducer";
import AddReimbursementReducer from "../routes/app/auth/hrms/reimbursement_request/add/reducer";
import ReimbursementListReducer from "../routes/app/auth/hrms/reimbursement_request/list/reducer";
import AddNatureExpencesReducer from "../routes/app/auth/permission/nature_of_expenses/add/reducer";
import NatureExpencesListReducer from "../routes/app/auth/permission/nature_of_expenses/list/reducer";
import EditNatureExpencesReducer from "../routes/app/auth/permission/nature_of_expenses/edit/reducer";
import AddTransportReducer from "../routes/app/auth/permission/transport/add/reducer";
import EditTransportReducer from "../routes/app/auth/permission/transport/edit/reducer"
import TransportListReducer from "../routes/app/auth/permission/transport/list/reducer";
import ReimbursementApprovalListReducer from "../routes/app/auth/approvals/reimbursement_approvals/list/reducer";
import ViewReimbursementReducer from "../routes/app/auth/approvals/reimbursement_approvals/view/reducer";
import ReimbursementPoolListReducer from "../routes/app/auth/approvals/reimbursement_pool/list/reducer";
import ReimbursementViewReducer from "../routes/app/auth/approvals/reimbursement_pool/view/reducer";
import ConveyanceClaimReducer from "../component/form/conveyance_claim/reducer";
import MobileClaimReducer from "../component/form/mobile_claim/reducer";
import OtherClaimReducer from "../component/form/other_claim/reducer"
import ApprovalOtherClaimReducer from "../component/form/approval_other_claim/reducer";
import ApprovalMobileClaimReducer from "../component/form/approval_mobile_claim/reducer"
import ApprovalConveyanceClaimReducer from "../component/form/approval_conveyance_claim/reducer";
import ReimbursementReportReducer from "../routes/app/auth/reports/reimbursement/reimbursement/reducer";
import PolicyReducer from "../routes/app/auth/policy/create/reducer"
import PolicyViewReducer from "../routes/app/auth/policy/view/reducer"
import ListAppSettingsReducer from "../routes/app/auth/permission/app_setting/list/reducer"
import AddAppSettingsReducer from "../routes/app/auth/permission/app_setting/add/reducer"
import EditAppSettingsReducer from "../routes/app/auth/permission/app_setting/edit/reducer"
import ReinsuranceListReducer from "../routes/app/auth/reinsurance/create/list/reducer";
import ReinsuranceCreateReducer from "../routes/app/auth/reinsurance/create/add/reducer";
import ReinsuranceViewReducer from "../routes/app/auth/reinsurance/create/view/reducer";
import ReinsuranceEditReducer from "../routes/app/auth/reinsurance/create/Edit/reducer";
import RMTransferReducer from "../routes/app/auth/permission/rm_transfer/add/reducer";
import EditAssignproposalReducer from "../component/popups/edit_productspecialist/reducer";
import ProposalPoolListReducer from "../routes/app/auth/proposal_pool/list/reducer";
import unattendClientLeadReportReducer from "../routes/app/auth/reports/meeting/lead_client_meeting/reducer";
import PolicyFilterReducer from "../component/popups/policy_filter/reducer";
import addRiskManagementReducer from "../routes/app/auth/risk_management/new_request/add/reducer";
import RiskApprovalListReducer from "../routes/app/auth/risk_management/request_approvals/list/reducer";
import RiskPoolListReducer from "../routes/app/auth/risk_management/risk_pool/list/reducer";
import viewRiskManagementReducer from "../routes/app/auth/risk_management/reducer"
import AddRiskListReducer from "../routes/app/auth/risk_management/new_request/list/reducer";
import EditAssignEngineerReducer from "../component/popups/edit_risk_assign_engineer/reducer";
import EditRiskManagementPoolReducer from "../routes/app/auth/risk_management/risk_pool/edit/reducer";
import EditRiskListReducer from "../routes/app/auth/risk_management/new_request/edit/reducer";
import EditRiskManagementApprovalReducer from "../routes/app/auth/risk_management/request_approvals/edit/reducer";
import RiskManagementChangeStatusReducer from "../routes/app/auth/risk_management/request_approvals/view/change_status/reducer";
import OpportunityQuestionListReducer from "../routes/app/auth/permission/opportunity_questions/list/reducer";
import OpportunityQuestionAddReducer from "../routes/app/auth/permission/opportunity_questions/add/reducer";
import ProbabilityListReducer from "../routes/app/auth/permission/probability/list/reducer";
import ProposalDetailsViewReducer from "../component/popups/proposal_details/reducer";
import OpportunityQuestionViewReducer from "../component/popups/proposal/reducer";
import ClientEditCorporateReducer from "../routes/app/auth/client/edit/reducer";
import WellnessRequestListReducer from "../routes/app/auth/wellness_management/new_request/list/reducer";
import addWellnessManagementReducer from "../routes/app/auth/wellness_management/new_request/add/reducer";
import editWellnessManagementReducer from "../routes/app/auth/wellness_management/new_request/edit/reducer";
import WellnessApprovalListReducer from "../routes/app/auth/wellness_management/request_approvals/list/reducer";
import WellnessPoolListReducer from "../routes/app/auth/wellness_management/wellness_pool/list/reducer";
import viewWellnessManagementReducer from "../routes/app/auth/wellness_management/reducer";
import EditWellnessManagementPoolReducer from "../routes/app/auth/wellness_management/wellness_pool/edit/reducer";
import EditWellnessApprovalReducer from "../routes/app/auth/wellness_management/request_approvals/edit/reducer";
import EditAssignRMReducer from "../component/popups/edit_wellness_assign_rm/reducer";
import WellnessManagementReportReducer from "../routes/app/auth/wellness_management/report/reducer";
import riskManagementReportReducer from "../routes/app/auth/risk_management/risk_report/reducer";
import LogoutAllUserReducer from "../routes/app/auth/permission/logout/reducer";
// IT declaration
import ItDeclarationAddReducer from "../routes/app/auth/hrms/declarations/add_declaration/reducer";
import ItDeclarationSubmitReducer from "../routes/app/auth/hrms/declarations/add_flexible/reducer";
import ItDeclarationListReducer from "../routes/app/auth/hrms/declarations/list/reducer";
import ItDeclarationDetailReducer from "../routes/app/auth/hrms/declarations/view/reducer";
import ItDeclarationPoolListReducer from "../routes/app/auth/hrms/declaration_pool/list/reducer";
import ItDeclarationPoolDetailReducer from "../routes/app/auth/hrms/declaration_pool/view/reducer";
import ItDeclarationPoolEditReducer from "../routes/app/auth/hrms/declaration_pool/edit/reducer";
import ItDeclarationSettingListReducer from "../routes/app/auth/permission/declaration/list/reducer";
import ItDeclarationSettingAddReducer from "../routes/app/auth/permission/declaration/add/reducer";
import ItDeclarationEditReducer from "../routes/app/auth/permission/declaration/edit/reducer";
import HRMSItDeclarationEditReducer from "../routes/app/auth/hrms/declarations/edit_declaration/reducer";
import HRMSFlexibleEditReducer from "../routes/app/auth/hrms/declarations/edit_flexible/reducer";
import WeeklyMeetingReducer from "../component/widgets/weekly_meeting_dashboard/reducer";
import EmployeeVisitingCardReducer from "../component/popups/visiting_card/reducer";
import RMTransferDashboardReducer from "../routes/app/auth/permission/rm_transfer/dashboard/reducer";
// IT submission
import HRMSItSubmissionEditReducer from "../routes/app/auth/hrms/declarations/edit_submission/reducer";
import ItSubmissionDetailReducer from "../routes/app/auth/hrms/declarations/view_submission/reducer";
import ItSubmissionPoolListReducer from "../routes/app/auth/hrms/submission_pool/list/reducer";
import ItSubmissionPoolDetailReducer from "../routes/app/auth/hrms/submission_pool/view/reducer";
import ItSubmissionPoolEditReducer from "../routes/app/auth/hrms/submission_pool/edit/reducer";
import ItSubmissionAddReducer from "../routes/app/auth/hrms/declarations/add_submission/reducer";
// group level 
import TabGroupBusinessSummaryReducer from "../routes/app/auth/client/group_level/tabs/business_summary/reducer";
import TabGroupOpportunityReducer from "../routes/app/auth/client/group_level/tabs/opportunities/reducer";
import TabGroupMeetingReducer from "../routes/app/auth/client/group_level/tabs/meetings/reducer";
import TabGroupPoliciesViewReducer from "../routes/app/auth/client/group_level/tabs/policies/reducer";
import TabGroupClaimsReducer from "../routes/app/auth/client/group_level/tabs/claims/reducer";
import TabGroupCompaniesReducer from "../routes/app/auth/client/group_level/tabs/companies/reducer";
import TabGroupActivitiesReducer from "../routes/app/auth/client/group_level/tabs/activities/reducer";
import TabGroupPincRMReducer from "../routes/app/auth/client/group_level/tabs/pinc_rm/reducer";
import GroupProfileViewReducer from "../routes/app/auth/client/group_level/reducer";
import OpportunityPoolReducer from "../component/popups/opportunity_pool/reducer";
import customerVerificationReportReducer from "../routes/app/auth/reports/customer_verification/reducer";
// broker training
import BrokerTrainingAddReducer from "../routes/app/auth/permission/hrms_setting/add_function/reducer";
import BrokerTrainingListReducer from "../routes/app/auth/permission/hrms_setting/list/reducer";
import BrokerTrainingDetailReducer from "../routes/app/auth/permission/hrms_setting/view/reducer";
import BrokerTrainingEditReducer from "../routes/app/auth/permission/hrms_setting/edit/reducer";
import TrainingParticipationReducer from "../routes/app/auth/reports/trainings/training_participation/reducer";
import TrainingReportReducer from "../routes/app/auth/reports/trainings/training/reducer";
// master details
import MasterGetDepartmentReducer from "../routes/app/auth/master/department/list/reducer";
import MasterAddDepartmentReducer from "../routes/app/auth/master/department/add/reducer";
import MasterEditDepartmentReducer from "../routes/app/auth/master/department/edit/reducer";
import MasterGetPolicyTypeReducer from "../routes/app/auth/master/policy/list/reducer";
import MasterAddPolicyTypeReducer from "../routes/app/auth/master/policy/add/reducer";
import MasterEditPolicyTypeReducer from "../routes/app/auth/master/policy/edit/reducer";
import MasterGetSpecificPreferenceReducer from "../routes/app/auth/placement/specific_placement/list/reducer";
import MasterGetInsurerReducer from "../routes/app/auth/master/insurer/list/reducer";
import MasterAddInsurerReducer from "../routes/app/auth/master/insurer/add/reducer";
import MasterEditInsurerReducer from "../routes/app/auth/master/insurer/edit/reducer";
import MasterGetInsurerBranchReducer from "../routes/app/auth/master/insurer/branch/list/reducer";
import MasterEditInsurerBranchReducer from "../routes/app/auth/master/insurer/branch/edit/reducer";
import MasterAddAdminRightReducer from "../routes/app/auth/master/admin_right/add_admin__right/reducer";
import MasterListAdminRightReducer from "../routes/app/auth/master/admin_right/list/reducer";
import MasterAddSpecificPreferenceReducer from "../routes/app/auth/placement/specific_placement/add/reducer";
import MasterEditSpecificPreferenceReducer from "../routes/app/auth/placement/specific_placement/edit/reducer";
import MasterGetPlacementRightReducer from "../routes/app/auth/master/placement/list/reducer";
import MasterAddPlacementRightReducer from "../routes/app/auth/master/placement/add_placement/reducer";
import MasterEditPlacementRightReducer from "../routes/app/auth/master/placement/edit_placement/reducer";
import MasterAddInsurerBranchReducer from "../routes/app/auth/master/insurer/branch/add/reducer";
import MasterGetGeneralPreferenceReducer from "../routes/app/auth/placement/general_placement_view/reducer";
import GeneralPreferenceListReducer from "../routes/app/auth/placement/general_placement/list/reducer";
import GeneralPreferenceAddReducer from "../routes/app/auth/placement/general_placement/add/reducer";
import GeneralPreferenceEditReducer from "../routes/app/auth/placement/general_placement/edit/reducer";
import MasterGetInsurerBranchContactReducer from "../routes/app/auth/master/insurer/contacts/list/reducer";
import MasterAddInsurerBranchContactReducer from "../routes/app/auth/master/insurer/contacts/contact_add/reducer";
import MasterEditInsurerBranchContactReducer from "../routes/app/auth/master/insurer/contacts/contact_edit/reducer";
import MasterGetPlacementSpecificRightReducer from "../routes/app/auth/master/placement_specific/list/reducer";
import MasterAddSpecificPlacementRightReducer from "../routes/app/auth/master/placement_specific/add/reducer";
import TabGroupPincContactReducer from "../routes/app/auth/client/group_level/tabs/contacts/reducer";
import MasterGetTrainingTypeReducer from "../routes/app/auth/master/type_of_training/list/reducer";
import MasterAddTrainingTypeReducer from "../routes/app/auth/master/type_of_training/add/reducer";
import MasterEditTrainingTypeReducer from "../routes/app/auth/master/type_of_training/edit/reducer";
// opportunity to rfq
import OpportunitySettingListReducer from "../routes/app/auth/proposal_pool/opportunity_settings/list/reducer";
import OpportunitySettingAddReducer from "../routes/app/auth/proposal_pool/opportunity_settings/add/reducer";
import OpportunitySettingEditReducer from "../routes/app/auth/proposal_pool/opportunity_settings/edit/reducer";
import ProposalPoolEditReducer from "../routes/app/auth/proposal_pool/edit/reducer";
import ProposalPoolViewReducer from "../routes/app/auth/proposal_pool/view/reducer";
import OpportunityPlacementReducer from "../routes/app/auth/proposal_pool/edit/placement_popup/reducer";
import ProposalPoolFilterReducer from "../routes/app/auth/proposal_pool/list/opportunity_filter/reducer";
// birthday wish 
import BirthdayWishesReducer from "../routes/app/auth/customer_services/birthday_wishes/list/reducer";
import BirthdayWishesAddReducer from "../routes/app/auth/customer_services/birthday_wishes/add/reducer";
import BirthdayWishesEditReducer from "../routes/app/auth/customer_services/birthday_wishes/edit/reducer";
import BirthdayWishLogsReducer from "../routes/app/auth/customer_services/birthday_wish_logs/list/reducer";
import CalendarTravelReducer from "../routes/app/auth/travel_calender/view/reducer";
import CalendarTravelUpdateReducer from "../routes/app/auth/travel_calender/update/reducer";
import TravelCalendarSettingListReducer from "../routes/app/auth/permission/travel_calendar/list/reducer";
import CalendarTravelSettingUpdateReducer from "../routes/app/auth/permission/travel_calendar/update/reducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    AllMeetingsReducer,
    CustomerMeetingsReducer,
    InsuranceCompanyMeetingsReducer,
    InternalMeetingsReducer,
    RiMeetingsReducer,
    ServeyorMeetingsReducer,
    LeadCreateCorporateReducer,
    RegistrationReducer,
    AdminLoginReducer,
    ClientListReducer,
    ClientViewReducer,
    ForgotPasswordReducer,
    ChangePasswordReducer,
    EmployeeListReducer,
    LeadReducer,
    EmployeeViewReducer,
    EmployeeEditReducer,
    PermissionReducer,
    EmployeeChangePasswordReducer,
    AddCustomerMeetingReducer,
    LeadCreatePolicyDetailsReducer,
    LeadEditCorporateReducer,
    AddInsuranceMeetingReducer,
    AddInternalMeetingReducer,
    AddServeyorMeetingReducer,
    AddRiMeetingReducer,
    CustomerAddNewCustomerReducer,
    LeadViewReducer,
    LeadCreateRetailReducer,
    InternalMeetingsViewReducer,
    InternalMeetingsEditReducer,
    EditInsuranceMeetingReducer,
    InsuranceMeetingsViewReducer,
    AddTpaMeetingReducer,
    TpaMeetingsReducer,
    NewServeyorReducer,
    NewTpaReducer,
    EditServeyorMeetingReducer,
    EditTpaMeetingReducer,
    ServeyorMeetingsViewReducer,
    TpaMeetingsViewReducer,
    InsuranceCompanyAddNewBranchReducer,
    CustomerMeetingsViewReducer,
    EditCustomerMeetingReducer,
    EditPermissionRoleReducer,
    ViewPermissionRoleReducer,
    PermissionAddReducer,
    EditPermissionReducer,
    PermissionRolesReducer,
    ListPermissionReducer,
    FeedbackAddReducer,
    SearchListGetReducer,
    PopupCustomerMeetingsViewReducer,
    MyTeamVerticalReducer,
    MyReportingVerticalReducer,
    MyVerticalReducer,
    MyProfileReducer,
    AddEducationReducer,
    ActivityCreatePostReducer,
    ClientLeadListReducer,
    LeadListReducer,
    MeetingListReducer,
    AddEmploymentReducer,
    EditEducationReducer,
    EditEmploymentReducer,
    EditPersonalInformationReducer,
    GetPostReducer,
    ClientTabPoliciesViewReducer,
    ClientTapProfileDetailsReducer,
    ClientTabProposalsViewReducer,
    ClientTabMeetingsViewReducer,
    ClientTabClaimsReducer,
    ClientPolicyDetailReducer,
    ClientTabBusinessSummaryReducer,
    TabLeadProposalsViewReducer,
    TabLeadMeetingViewReducer,
    InsurerTabContactPersonReducer,
    InsurerViewTabMeetingsReducer,
    InsurerViewTabPoliciesReducer,
    InsurerTabBusinessSummaryReducer,
    InsurerBusinessSummaryReducer,
    BusinessSummaryReducer,
    DashboardReducer,
    AddDocumentReducer,
    DocumentListReducer,
    PopupInsuranceMeetingsViewReducer,
    MarkInMarkOutReducer,
    InsurerViewTabClientsReducer,
    InsurerViewTabCompanyNewsReducer,
    MyprofilePostReducer,
    DashboardSummaryReducer,
    PermissionContentReducer,
    EditPermissionContentReducer,
    DashboardEventReducer,
    HomeReducer,
    CalendarHolidayReducer,
    HolidayAddReducer,
    EditHolidayReducer,
    ViewHolidayReducer,
    LeaveRequestListReducer,
    LeaveRequestAddReducer,
    AddRegularizeReducer,
    RegularizeRequestListReducer,
    RegularizeRequestViewReducer,
    RegularizeApprovalListReducer,
    RegularizeApprovalViewReducer,
    LeaveRequestViewReducer,
    LeaveApprovalViewReducer,
    LeaveApprovalListReducer,
    ApprovalChangeStatusReducer,
    DashboardLatestFiveClientReducer,
    UpcomingMeetingReducer,
    DashboardAttendaceLeaveReducer,
    DashboardPendingApprovalReducer,
    DashboardManagerApprovalReducer,
    EditTeamMasterReducer,
    TeamMasterListReducer,
    AddTeamMasterReducer,
    CalenderReducer,
    LogOutReducer,
    UserNotificationReducer,
    EditAssignRetentionReducer,
    RetentionListReducer,
    ReinsuranceClientReducer,
    ReinsuranceClientEditReducer,
    ImportMasterDataReducer,
    ExportReinsuranceMasterReducer,
    ImportClientDataReducer,
    RiMeetingsViewReducer,
    RiMeetingsEditReducer,
    AddRiNewClientReducer,
    AssetRequestViewReducer,
    AssetApprovalListReducer,
    AssetRequestAddReducer,
    AssetApprovalViewReducer,
    AssetRequestListReducer,
    TravelRequestListReducer,
    TravelRequestAddReducer,
    TravelRequestViewReducer,
    TravelApprovalListReducer,
    TravelApprovalViewReducer,
    CreateTaskReducer,
    TaskListReducer,
    TaskPoolListReducer,
    TaskViewReducer,
    TaskPoolViewReducer,
    TripDetailsReducer,
    MandateAddReducer,
    TaskStatusReducer,
    DashboardTaskStatusReducer,
    ViewClaimsReducer,
    ClaimsReducer,
    ClaimDashboardReducer,
    ItSupportAddReducer,
    ItSupportViewReducer,
    ItSupportReducer,
    CronDashboardViewReducer,
    CronDashboardReducer,
    PincReducer,
    PincAddReducer,
    AddLossSiteVisitReducer,
    EditClaimsReducer,
    NewPasswordReducer,
    LossSiteVisitReducer,
    ClaimsDocumentReducer,
    DownloadClaimsMISListReducer,
    AddShiftTimingReducer,
    EditShiftTimingReducer,
    ListShiftTimingReducer,
    // reports
    attendanceReportReducer,
    LeaveReportReducer,
    meetingReportReducer,
    meetingDetailReportReducer,
    customerVerificationReportReducer,
    AddReimbursementReducer,
    ReimbursementListReducer,
    AddNatureExpencesReducer,
    NatureExpencesListReducer,
    EditNatureExpencesReducer,
    AddTransportReducer,
    EditTransportReducer,
    TransportListReducer,
    ReimbursementApprovalListReducer,
    ViewReimbursementReducer,
    ReimbursementPoolListReducer,
    ReimbursementViewReducer,
    ConveyanceClaimReducer,
    MobileClaimReducer,
    OtherClaimReducer,
    ApprovalOtherClaimReducer,
    ApprovalMobileClaimReducer,
    ApprovalConveyanceClaimReducer,
    ReimbursementReportReducer,
    AddFinanceLimitReducer,
    ListFinanceLimitReducer,
    EditFinanceLimitReducer,
    ViewFinanceLimitReducer,
    ProbabilityReducer,
    ProposalListReducer,
    PolicyReducer,
    PolicyViewReducer,
    ListAppSettingsReducer,
    AddAppSettingsReducer,
    EditAppSettingsReducer,
    ReinsuranceListReducer,
    ReinsuranceCreateReducer,
    ReinsuranceViewReducer,
    ReinsuranceEditReducer,
    RMTransferReducer,
    EditAssignproposalReducer,
    ProposalPoolListReducer,
    unattendClientLeadReportReducer,
    PolicyFilterReducer,
    addRiskManagementReducer,
    RiskApprovalListReducer,
    RiskPoolListReducer,
    viewRiskManagementReducer,
    AddRiskListReducer,
    EditAssignEngineerReducer,
    EditRiskManagementPoolReducer,
    EditRiskListReducer,
    EditRiskManagementApprovalReducer,
    RiskManagementChangeStatusReducer,
    OpportunityQuestionListReducer,
    OpportunityQuestionAddReducer,
    ProbabilityListReducer,
    ProposalDetailsViewReducer,
    OpportunityQuestionViewReducer,
    ClientEditCorporateReducer,
    WellnessRequestListReducer,
    WellnessApprovalListReducer,
    WellnessPoolListReducer,
    addWellnessManagementReducer,
    editWellnessManagementReducer,
    viewWellnessManagementReducer,
    EditWellnessManagementPoolReducer,
    EditWellnessApprovalReducer,
    EditAssignRMReducer,
    WellnessManagementReportReducer,
    LogoutAllUserReducer,
    // IT declaration
    ItDeclarationAddReducer,
    ItDeclarationSubmitReducer,
    ItDeclarationListReducer,
    ItDeclarationDetailReducer,
    ItDeclarationPoolListReducer,
    ItDeclarationPoolDetailReducer,
    ItDeclarationPoolEditReducer,
    ItDeclarationSettingListReducer,
    ItDeclarationSettingAddReducer,
    ItDeclarationEditReducer,
    HRMSItDeclarationEditReducer,
    HRMSFlexibleEditReducer,
    EmployeeVisitingCardReducer,
    riskManagementReportReducer,
    //IT submission
    HRMSItSubmissionEditReducer,
    ItSubmissionDetailReducer,
    ItSubmissionPoolListReducer,
    ItSubmissionPoolDetailReducer,
    ItSubmissionPoolEditReducer,
    ItSubmissionAddReducer,
    // group level
    TabGroupBusinessSummaryReducer,
    TabGroupOpportunityReducer,
    TabGroupMeetingReducer,
    TabGroupPoliciesViewReducer,
    TabGroupClaimsReducer,
    TabGroupCompaniesReducer,
    TabGroupActivitiesReducer,
    TabGroupPincRMReducer,
    GroupProfileViewReducer,
    RMTransferDashboardReducer,
    WeeklyMeetingReducer,
    OpportunityPoolReducer,
    // broker training
    BrokerTrainingAddReducer,
    BrokerTrainingListReducer,
    BrokerTrainingDetailReducer,
    BrokerTrainingEditReducer,
    TrainingParticipationReducer,
    TrainingReportReducer,
    // master details
    MasterGetDepartmentReducer,
    MasterAddDepartmentReducer,
    MasterEditDepartmentReducer,
    MasterGetPolicyTypeReducer,
    MasterAddPolicyTypeReducer,
    MasterEditPolicyTypeReducer,
    MasterGetSpecificPreferenceReducer,
    MasterGetInsurerReducer,
    MasterAddInsurerReducer,
    MasterEditInsurerReducer,
    MasterGetInsurerBranchReducer,
    MasterEditInsurerBranchReducer,
    MasterAddAdminRightReducer,
    MasterListAdminRightReducer,
    MasterAddSpecificPreferenceReducer,
    MasterEditSpecificPreferenceReducer,
    MasterGetPlacementRightReducer,
    MasterAddPlacementRightReducer,
    MasterEditPlacementRightReducer,
    MasterAddInsurerBranchReducer,
    MasterGetGeneralPreferenceReducer,
    GeneralPreferenceListReducer,
    GeneralPreferenceAddReducer,
    GeneralPreferenceEditReducer,
    MasterGetInsurerBranchContactReducer,
    MasterAddInsurerBranchContactReducer,
    MasterEditInsurerBranchContactReducer,
    MasterGetPlacementSpecificRightReducer,
    MasterAddSpecificPlacementRightReducer,
    TabGroupPincContactReducer,
    MasterGetTrainingTypeReducer,
    MasterAddTrainingTypeReducer,
    MasterEditTrainingTypeReducer,
    // opportunity to rfq
    OpportunitySettingListReducer,
    OpportunitySettingAddReducer,
    OpportunitySettingEditReducer,
    ProposalPoolEditReducer,
    ProposalPoolViewReducer,
    OpportunityPlacementReducer,
    ProposalPoolFilterReducer,
    // birthdaywishes
    BirthdayWishesReducer,
    BirthdayWishesAddReducer,
    BirthdayWishesEditReducer,
    BirthdayWishLogsReducer,
    CalendarTravelReducer,
    CalendarTravelUpdateReducer,
    TravelCalendarSettingListReducer,
    CalendarTravelSettingUpdateReducer
  });

