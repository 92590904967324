import { ACTION_REGULARIZE_APPROVALS_VIEW_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const RegularizeApprovalViewReducer = createReducer(INITIAL_STATE, {
    [ACTION_REGULARIZE_APPROVALS_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REGULARIZE_APPROVALS_VIEW_SUCCESS,
                loading: false,
            }
        );
    }
});

export default RegularizeApprovalViewReducer;