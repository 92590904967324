import { ACTION_LEAVE_REQUEST_ADD_SUCCESS, ACTION_HOLIDAY_LIST_SUCCESS } from "./action";
import { ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS, ACTION_TOTAL_LEAVE_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeaveRequestAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_LEAVE_REQUEST_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LEAVE_REQUEST_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TOTAL_LEAVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TOTAL_LEAVE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_HOLIDAY_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_HOLIDAY_LIST_SUCCESS,
                loading: false,
            }
        );
    },
});

export default LeaveRequestAddReducer;