import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { PRINT } from '../../../../../../utils';
import { API_BUSINESS_SUMMARY, API_TEAM_BUSINESS_SUMMARY, API_MYTEAMVERTICAL } from '../../../../../../api/constants';

export const ACTION_BUSINESS_SUMMARY_LOADING = 'ACTION_BUSINESS_SUMMARY_LOADING';
export const ACTION_BUSINESS_SUMMARY_SUCCESS = 'ACTION_BUSINESS_SUMMARY_SUCCESS';

export const ACTION_TEAM_BUSINESS_SUMMARY_LOADING = 'ACTION_TEAM_BUSINESS_SUMMARY_LOADING';
export const ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS = 'ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS';

export const ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_LOADING = 'ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_LOADING';
export const ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS = 'ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS';

export function actionBusinessSummary(params) {
    return (dispatch, getState) =>
        Api.post(API_BUSINESS_SUMMARY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_BUSINESS_SUMMARY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BUSINESS_SUMMARY_LOADING, { loading: false, data }));
        });
}

export function actionTeamBusinessSummary(params) {
    return (dispatch, getState) =>
        Api.post(API_TEAM_BUSINESS_SUMMARY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TEAM_BUSINESS_SUMMARY_LOADING, { loading: false, data }));
        });
}

export function actionProfileBusinessSummaryTeamMember(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_LOADING, { loading: false, data }));
        });
}