import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import { useForm, Controller } from "react-hook-form";
import { DateBox } from 'devextreme-react/date-box';
import { withAuth } from 'react-auth-guard';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { actionClaimDashboard, ACTION_CLAIMS_DASHBOARD_SUCCESS } from './action';
import { actionClaimDownload, ACTION_CLAIM_DOWNLOAD_SUCCESS } from './action';
import moment from 'moment';

const ClaimsDashboard = (props) => {

    const { control } = useForm({});
    const [claimDashboard, setClaimDashboard] = useState(null);
    const [fromDate, setFromDate] = useState(moment().month() < 3
        ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("yyyy-MM-DD")
        : moment()
            .month(3)
            .startOf("month")
            .format("yyyy-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));

    const initialRender = () => {
        props.actionClaimDashboard({
            from_date: moment(fromDate).format('yyyy-MM-DD'),
            to_date: moment(toDate).format('yyyy-MM-DD')
        });
    }
    useEffect(initialRender, []);

    const onClaimsListUpdate = () => {
        const { ClaimsDashboard } = props;
        if (ClaimsDashboard != null) {
            var ClaimsDashboardResponse = Object.assign({}, ClaimsDashboard);
            if (ClaimsDashboardResponse.result === true) {
                setClaimDashboard(ClaimsDashboardResponse.response);
            }
        }
    }
    useEffect(onClaimsListUpdate, [props.ClaimsDashboard]);

    const onClaimDownloadUpdate = () => {
        const { ClaimDownload } = props;
        if (ClaimDownload != null) {
            var ClaimDownloadResponse = Object.assign({}, ClaimDownload);
            if (ClaimDownloadResponse.result === true) {
                window.open(ClaimDownloadResponse.response.url);
            }
        }
    }
    useEffect(onClaimDownloadUpdate, [props.ClaimDownload]);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 pb-1">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <h4 class="text-black text-bold-600 mb-0">Non Health PINC Claim</h4>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                <div className="flex-column mr-1">
                                    <Controller
                                        name="from_date"
                                        control={control}
                                        render={({ field }) =>
                                            <DateBox
                                                calendarOptions={{
                                                    showTodayButton: true,
                                                }}
                                                acceptCustomValue={false}
                                                stylingMode="underlined"
                                                pickerType="calendar"
                                                placeholder="From Date"
                                                type="date"
                                                displayFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                value={fromDate}
                                                onValueChange={(value) => {
                                                    var objFromDate = new Date(value);
                                                    var objToDate = new Date(toDate);

                                                    if (objToDate.getTime() < objFromDate.getTime()) {
                                                        Swal.fire({
                                                            title: 'Error',
                                                            text: "From date can't be greater than To date",
                                                            icon: 'error'
                                                        }).then(success => {

                                                        })
                                                    } else {
                                                        field.onChange(moment(value).format('yyyy-MM-DD'));
                                                        setFromDate(moment(value).format('yyyy-MM-DD'));
                                                        props.actionClaimDashboard({
                                                            from_date: moment(value).format('yyyy-MM-DD'),
                                                            to_date: moment(toDate).format('yyyy-MM-DD')
                                                        });
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                </div>
                                <div className="flex-column mr-1">
                                    <Controller
                                        className="flex-column"
                                        name="to_date"
                                        control={control}
                                        render={({ field }) =>
                                            <DateBox
                                                calendarOptions={{
                                                    showTodayButton: true,
                                                }}
                                                acceptCustomValue={false}
                                                stylingMode="underlined"
                                                pickerType="calendar"
                                                placeholder="To Date"
                                                type="date"
                                                displayFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                value={toDate}
                                                onValueChange={(value) => {
                                                    var objFromDate = new Date(fromDate);
                                                    var objToDate = new Date(value);

                                                    if (objToDate.getTime() < objFromDate.getTime()) {
                                                        Swal.fire({
                                                            title: 'Error',
                                                            text: "To date must be greater than from date",
                                                            icon: 'error'
                                                        }).then(success => {

                                                        })
                                                    } else {
                                                        field.onChange(moment(value).format('yyyy-MM-DD'));
                                                        setToDate(moment(value).format('yyyy-MM-DD'));
                                                        props.actionClaimDashboard({
                                                            from_date: moment(fromDate).format('yyyy-MM-DD'),
                                                            to_date: moment(value).format('yyyy-MM-DD')
                                                        });
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                </div>
                                <div className="flex-column">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-large shadow round"
                                        onClick={() => {
                                            props.actionClaimDownload({
                                                from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
                                                to_date: dateFormat(toDate, 'yyyy-mm-dd')
                                            });
                                        }}
                                    >
                                        Download&nbsp;Data
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div id="goal-list-scroll" class="table-responsive height-250 position-relative ps ps--active-y" style={{ minHeight: 280 }}>
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className="py-2">Type</th>
                                            <th className="py-2">Count</th>
                                            <th className="py-2">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Settled (Closed) Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.settle.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.settle.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Open / Pending Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.open.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.open.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Rejected / Repudiated Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.reject.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.reject.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Withdrawn Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.withdraw.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.withdraw.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Total Claims</b></td>
                                            <td>
                                                <b>
                                                    {
                                                        claimDashboard != null ?
                                                            claimDashboard.pinc_claim.motor.total_count : '0'
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                <b>
                                                    {
                                                        claimDashboard != null ?
                                                            claimDashboard.pinc_claim.motor.total_amount + ' Lac' : '0'
                                                    }
                                                </b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 px-0 mb-1 mb-lg-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?
                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.pinc_claim.motor.settle.count, claimDashboard.pinc_claim.motor.open.count, claimDashboard.pinc_claim.motor.reject.count, claimDashboard.pinc_claim.motor.withdraw.count]}
                                            />
                                            :
                                            <></>
                                    }

                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.total_count : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Count
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 px-0 mb-1 mb-lg-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?
                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.pinc_claim.motor.settle.amount, claimDashboard.pinc_claim.motor.open.amount, claimDashboard.pinc_claim.motor.reject.amount, claimDashboard.pinc_claim.motor.withdraw.amount]}
                                            />
                                            :
                                            <></>
                                    }
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.motor.total_amount + ' Lac' : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Amount
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Non Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div id="goal-list-scroll" class="table-responsive height-250 position-relative ps ps--active-y" style={{ minHeight: 280 }}>
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className="py-2">Type</th>
                                            <th className="py-2">Count</th>
                                            <th className="py-2">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Settled (Closed) Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.settle.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.settle.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Open / Pending Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.open.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.open.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Rejected / Repudiated Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.reject.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.reject.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Withdrawn Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.withdraw.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.withdraw.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Total Claims</b></td>
                                            <td>
                                                <b>
                                                    {
                                                        claimDashboard != null ?
                                                            claimDashboard.pinc_claim.non_motor.total_count : '0'
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                <b>
                                                    {
                                                        claimDashboard != null ?
                                                            claimDashboard.pinc_claim.non_motor.total_amount + ' Lac' : '0'
                                                    }
                                                </b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Non Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div className="row">
                                <div className="col-6 col-md-6 col-lg-6 px-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?
                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.pinc_claim.non_motor.settle.count, claimDashboard.pinc_claim.non_motor.open.count, claimDashboard.pinc_claim.non_motor.reject.count, claimDashboard.pinc_claim.non_motor.withdraw.count]}
                                            />
                                            :
                                            <></>
                                    }
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.total_count : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Count
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6 px-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?
                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.pinc_claim.non_motor.settle.amount, claimDashboard.pinc_claim.non_motor.open.amount, claimDashboard.pinc_claim.non_motor.reject.amount, claimDashboard.pinc_claim.non_motor.withdraw.amount]}
                                            />
                                            :
                                            <></>
                                    }
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.pinc_claim.non_motor.total_amount + ' Lac' : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Amount
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 pb-1">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <h4 class="text-black text-bold-600 mb-0">Non Health MANDATE Claim</h4>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div id="goal-list-scroll" class="table-responsive height-250 position-relative ps ps--active-y" style={{ minHeight: 280 }}>
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className="py-2">Type</th>
                                            <th className="py-2">Count</th>
                                            <th className="py-2">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Settled (Closed) Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.settle.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.settle.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Open / Pending Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.open.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.open.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Rejected / Repudiated Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.reject.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.reject.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Withdrawn Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.withdraw.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.withdraw.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Total Claims</b></td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.total_count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.total_amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 px-0 mb-1 mb-lg-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?
                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.mandate_claim.motor.settle.count, claimDashboard.mandate_claim.motor.open.count, claimDashboard.mandate_claim.motor.reject.count, claimDashboard.mandate_claim.motor.withdraw.count]}
                                            />
                                            :
                                            <></>
                                    }
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.total_count : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Count
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 px-0 mb-1 mb-lg-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?
                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.mandate_claim.motor.settle.amount, claimDashboard.mandate_claim.motor.open.amount, claimDashboard.mandate_claim.motor.reject.amount, claimDashboard.mandate_claim.motor.withdraw.amount]}
                                            />
                                            :
                                            <></>
                                    }
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.motor.total_amount + ' Lac' : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Amount
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Non Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div id="goal-list-scroll" class="table-responsive height-250 position-relative ps ps--active-y" style={{ minHeight: 280 }}>
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className="py-2">Type</th>
                                            <th className="py-2">Count</th>
                                            <th className="py-2">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Settled (Closed) Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.settle.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.settle.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Open / Pending Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.open.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.open.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Rejected / Repudiated Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.reject.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.reject.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Withdrawn Claims</td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.withdraw.count : '0'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.withdraw.amount + ' Lac' : '0'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Total Claims</b></td>
                                            <td>
                                                <b>
                                                    {
                                                        claimDashboard != null ?
                                                            claimDashboard.mandate_claim.non_motor.total_count : '0'
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                <b>
                                                    {
                                                        claimDashboard != null ?
                                                            claimDashboard.mandate_claim.non_motor.total_amount + ' Lac' : '0'
                                                    }
                                                </b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round height-350">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        Non Motor Claim
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 pb-1">
                            <div className="row">
                                <div className="col-6 col-md-6 col-lg-6 px-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?

                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.mandate_claim.non_motor.settle.count, claimDashboard.mandate_claim.non_motor.open.count, claimDashboard.mandate_claim.non_motor.reject.count, claimDashboard.mandate_claim.non_motor.withdraw.count]}
                                            />
                                            :
                                            <></>
                                    }
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.total_count : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Count
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6 px-0" style={{ minHeight: 240 }}>
                                    {
                                        claimDashboard != null ?
                                            <Chart
                                                type="donut"
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "65%",
                                                                labels: {
                                                                    show: true
                                                                }
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        position: "bottom",
                                                        horizontalAlign: "center",
                                                        markers: {
                                                            width: 12,
                                                            height: 12,
                                                            radius: 12,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: ["Settled Claim", "Open Claim", "Rejected Claim", "Withdrawn Claim"],
                                                }}
                                                series={[claimDashboard.mandate_claim.non_motor.settle.amount, claimDashboard.mandate_claim.non_motor.open.amount, claimDashboard.mandate_claim.non_motor.reject.amount, claimDashboard.mandate_claim.non_motor.withdraw.amount]}
                                            />
                                            :
                                            <></>
                                    }
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                        <div className="flex-column">
                                            <h5 className="font-medium-1 text-black text-bold-600 mb-0 text-center">
                                                {
                                                    claimDashboard != null ?
                                                        claimDashboard.mandate_claim.non_motor.total_amount + ' Lac' : '0'
                                                }
                                            </h5>
                                            <h5 className="font-medium-1 text-secondary text-bold-500 mb-0 text-center">
                                                Total Claim Amount
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    var ClaimsDashboard = null;
    var ClaimDownload = null;
    if (
        state.ClaimDashboardReducer != null &&
        Object.keys(state.ClaimDashboardReducer).length > 0 &&
        state.ClaimDashboardReducer.constructor === Object
    ) {
        switch (state.ClaimDashboardReducer.tag) {
            case ACTION_CLAIMS_DASHBOARD_SUCCESS:
                ClaimsDashboard = Object.assign({}, state.ClaimDashboardReducer.data);
                delete state.ClaimDashboardReducer;
                return { ClaimsDashboard };
            case ACTION_CLAIM_DOWNLOAD_SUCCESS:
                ClaimDownload = Object.assign({}, state.ClaimDashboardReducer.data);
                delete state.ClaimDashboardReducer;
                return { ClaimDownload };
            default:
        }
    }
    return {
        ClaimsDashboard,
        ClaimDownload
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionClaimDashboard, actionClaimDownload }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClaimsDashboard))