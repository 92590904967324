import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_PROPOSAL_NEW_POOL_LIST } from '../../../../../../../api/constants';
import { PRINT } from '../.././../../../../../utils';

export const CLIENTTABPROPOSALSVIEW_LOADING = 'CLIENTTABPROPOSALSVIEW_LOADING';
export const ACTION_CLIENTTABPROPOSALSVIEW_SUCCESS = 'ACTION_CLIENTTABPROPOSALSVIEW_SUCCESS';

export function actionClientTabProposalsView(params) {
    return (dispatch, getState) => Api.post(API_PROPOSAL_NEW_POOL_LIST, params).then((res) => {
        const data = res;
        PRINT('CLIENTTABPROPOSALSVIEW ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_CLIENTTABPROPOSALSVIEW_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(CLIENTTABPROPOSALSVIEW_LOADING, { loading: false, data }));
    });
}
