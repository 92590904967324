
import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REINSURANCE } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_REINSURANCE_CLIENT_LOADING = 'ACTION_REINSURANCE_CLIENT_LOADING';
export const ACTION_REINSURANCE_CLIENT_SUCCESS = 'ACTION_REINSURANCE_CLIENT_SUCCESS';

export function actionReinsuranceClient(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_CLIENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_CLIENT_LOADING, { loading: false, data }));
        });
}