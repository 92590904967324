import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
} from "devextreme-react/data-grid";
import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import ErrorLabel from "../../../../../../component/form/error_label";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import {
    actionGetTrainingType, ACTION_TRAINING_TYPE_SUCCESS,
    actionGetTrainingDate, ACTION_TRAINING_DATE_SUCCESS,
    actionGetParticipationDetails, API_PARTICIPATION_REPORT_SUCCESS
} from "./action";
import { TagBox } from 'devextreme-react/tag-box';
import moment from "moment";
import ArrayStore from 'devextreme/data/array_store';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import _ from "lodash";

const TrainingParticipationReport = (props) => {

    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {
            training_type: [],
            status: [],
            training_dates: []
        }
    });
    const [trainingTypeDetails, setTrainingTypeDetails] = useState([]);
    const [dateDetails, setDateDetails] = useState([]);
    const [participationDetails, setParticipationDetails] = useState([]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGetParticipationDetails();
        props.actionGetTrainingType({
            "status": "ACTIVE"
        });
        props.actionGetTrainingDate();
    }
    useEffect(initialRender, []);

    const columns = [
        { dataField: "training_type", caption: "Type of Training", visible: true },
        { dataField: "start_date", caption: "Start Date", visible: false },
        { dataField: "start_date_formated", caption: "Start Date", visible: true },
        { dataField: "end_date", caption: "End Date", visible: false },
        { dataField: "end_date_formated", caption: "End Date", visible: true },
        { dataField: "employee_code", caption: "Employee Code", visible: true },
        { dataField: "employee_name", caption: "Employee Name", visible: true },
        { dataField: "employee_branch_city", caption: "Employee Branch", visible: true },
        { dataField: "reporting_to", caption: "Reporting To", visible: true },
        { dataField: "employee_response", caption: "Employee Response", visible: true },
    ];


    const renderStartDate = (cell) => {
        return <div>{cell?.data?.start_date_formated != null ? moment(cell?.data?.start_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderEndDate = (cell) => {
        return <div>{cell?.data?.end_date_formated != null ? moment(cell?.data?.end_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderEmployeeNameCell = (cell) => {
        if (cell?.data?.employee_name != null) {
            return <a
            className='text-primary'
            onClick={() => {
                props.history.push({
                    pathname: '/profile',
                    state: {
                        user_id: cell?.data?.user_id,
                    }
                })
            }}
            ><u>{cell?.data?.employee_name}</u></a>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "start_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStartDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "end_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEndDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "employee_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEmployeeNameCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    const onParticipationDetailUpdate = () => {
        const { GetParticipationDetails } = props;
        var GetParticipationDetailsResponse = Object.assign({}, GetParticipationDetails);
        if (GetParticipationDetailsResponse.result === true) {
            setParticipationDetails(GetParticipationDetailsResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (GetParticipationDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setParticipationDetails([]);
                    break;
                default:
            }
        }
    }
    useEffect(onParticipationDetailUpdate, [props.GetParticipationDetails]);

    const onMasterTrainingTypeDetailsUpdate = () => {
        const { GetTrainingType } = props;
        var GetTrainingTypeResponse = Object.assign({}, GetTrainingType);
        if (GetTrainingTypeResponse.result === true) {
            setTrainingTypeDetails(GetTrainingTypeResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (GetTrainingTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMasterTrainingTypeDetailsUpdate, [props.GetTrainingType]);

    const onTrainingDateDetailsUpdate = () => {
        const { GetTrainingDate } = props;
        var GetTrainingDateResponse = Object.assign({}, GetTrainingDate);
        if (GetTrainingDateResponse.result === true) {
            setDateDetails(GetTrainingDateResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (GetTrainingDateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setDateDetails([]);
                    break;
                default:
            }
        }
    }
    useEffect(onTrainingDateDetailsUpdate, [props.GetTrainingDate]);

    const onSubmit = (data) => {
        data.training_type = data.training_type;
        data.status = data.status;
        data.training_dates = data.training_dates;
        props.actionGetParticipationDetails(data);
    }

    return (
        <section>
            <div className="row">
                <div className="col-12">
                    <div className="col-12 col-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Training Participation Report</h5>
                    </div>
                    <div className="col-12 col-6">
                        <div className="flex-column">
                            <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                <button
                                    className="btn btn-secondary btn-sm mr-1 mb-1 shadow round"
                                    onClick={() => {
                                        props.history.goBack();
                                    }}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-body">
                            <div className="row">
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control primary">Training type</label>
                                        <Controller
                                            name="training_type"
                                            control={control}
                                            render={({ field }) =>
                                                <TagBox
                                                    {...field}
                                                    dataSource={
                                                        new ArrayStore({
                                                            data: trainingTypeDetails,
                                                            key: 'id',
                                                        })
                                                    }
                                                    dropDownOptions={{
                                                        onInitialized: () => {
                                                            console.log('initial')
                                                        },
                                                        onContentReady: () => {
                                                            console.log('content ready')
                                                        },
                                                        onShowing: () => {
                                                            console.log('showing')
                                                        },
                                                        onShown: () => {
                                                            console.log('shown')
                                                        }
                                                    }}
                                                    allowFiltering={false}
                                                    deferRendering={true}
                                                    displayExpr='name'
                                                    placeholder="Select Type"
                                                    searchEnabled={true}
                                                    valueExpr='id'
                                                    searchMode="contains"
                                                    maxDisplayedTags={2}
                                                    showMultiTagOnly={false}
                                                    className="form-control"
                                                    stylingMode="underlined"
                                                    value={field.value}
                                                    onValueChanged={({ value }) => {
                                                        if (value != null || value !== undefined || value != '') {
                                                            field.onChange(value);
                                                            props.actionGetTrainingDate({ training_id: value });
                                                            // setValue('training_dates', '');
                                                            // setDateDetails([]);
                                                        }
                                                    }}
                                                    showClearButton={true}
                                                />
                                            }
                                        />

                                        {errors.training_type && <ErrorLabel message={errors.training_type?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control primary">Status</label>
                                        <Controller
                                            name="status"
                                            control={control}
                                            render={({ field }) =>
                                                <TagBox
                                                    {...field}
                                                    dataSource={
                                                        new ArrayStore({
                                                            data:
                                                                [
                                                                    {
                                                                        "id": 'Confirm',
                                                                        "name": "Confirm"
                                                                    },
                                                                    {
                                                                        "id": 'Tentative',
                                                                        "name": "Tentative"
                                                                    },
                                                                    {
                                                                        "id": 'Not Attending',
                                                                        "name": "Not Attending"
                                                                    },
                                                                    {
                                                                        "id": 'No Response',
                                                                        "name": "No Response"
                                                                    }
                                                                ],

                                                            key: 'id',


                                                        })
                                                    }
                                                    dropDownOptions={{
                                                        onInitialized: () => {
                                                            console.log('initial')
                                                        },
                                                        onContentReady: () => {
                                                            console.log('content ready')
                                                        },
                                                        onShowing: () => {
                                                            console.log('showing')
                                                        },
                                                        onShown: () => {
                                                            console.log('shown')
                                                        }
                                                    }}
                                                    allowFiltering={false}
                                                    deferRendering={true}
                                                    displayExpr='name'
                                                    placeholder="Select Status"
                                                    searchEnabled={true}
                                                    valueExpr='id'
                                                    searchMode="contains"
                                                    maxDisplayedTags={2}
                                                    showMultiTagOnly={false}
                                                    className="form-control"
                                                    stylingMode="underlined"
                                                    value={field.value}
                                                    onValueChanged={({ value }) => {
                                                        if (value != null || value !== undefined || value != '') {
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    showClearButton={true}
                                                />
                                            }
                                        />

                                        {errors.status && <ErrorLabel message={errors.status?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control primary">Training Date</label>
                                        <Controller
                                            name="training_dates"
                                            control={control}
                                            render={({ field }) =>
                                                <TagBox
                                                    {...field}
                                                    id="training_dates"
                                                    name="training_dates"
                                                    dataSource={
                                                        new ArrayStore({
                                                            data: dateDetails,
                                                            key: 'start_date',
                                                        })
                                                    }
                                                    dropDownOptions={{
                                                        onInitialized: () => {
                                                            console.log('initial')
                                                        },
                                                        onContentReady: () => {
                                                            console.log('content ready')
                                                        },
                                                        onShowing: () => {
                                                            console.log('showing')
                                                        },
                                                        onShown: () => {
                                                            console.log('shown')
                                                        }
                                                    }}
                                                    allowFiltering={false}
                                                    deferRendering={true}
                                                    displayExpr='label'
                                                    placeholder="Select Date"
                                                    searchEnabled={true}
                                                    valueExpr='start_date'
                                                    searchMode="contains"
                                                    maxDisplayedTags={2}
                                                    showMultiTagOnly={false}
                                                    className="form-control"
                                                    stylingMode="underlined"
                                                    value={field.value}
                                                    onValueChanged={({ value }) => {
                                                        if (value != null || value !== undefined || value != '') {
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    showClearButton={true}
                                                />
                                            }
                                        />

                                        {errors.training_dates && <ErrorLabel message={errors.training_dates?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-actions mt-1 border-0 float-none">
                                        <button
                                            id="attendance-report"
                                            type='submit'
                                            className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                        >Search
                                            <span id="attendance-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={participationDetails}
                                showBorders={true}
                                columnAutoWidth={true}
                                remoteOperations={true}
                                export={{
                                    fileName: "Training Participation Report",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("start_date_formated", "visible", false)
                                    event.component.columnOption("start_date", "visible", true)
                                    event.component.columnOption("end_date_formated", "visible", false)
                                    event.component.columnOption("end_date", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("start_date_formated", "visible", true)
                                    event.component.columnOption("start_date", "visible", false)
                                    event.component.columnOption("end_date_formated", "visible", true)
                                    event.component.columnOption("end_date", "visible", false)
                                    event.component.endUpdate();
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    var GetTrainingType = null;
    var GetTrainingDate = null;
    var GetParticipationDetails = null;

    if (
        state.TrainingParticipationReducer != null &&
        Object.keys(state.TrainingParticipationReducer).length > 0 &&
        state.TrainingParticipationReducer.constructor === Object
    ) {
        switch (state.TrainingParticipationReducer.tag) {
            case ACTION_TRAINING_TYPE_SUCCESS:
                GetTrainingType = Object.assign({}, state.TrainingParticipationReducer.data);
                delete state.TrainingParticipationReducer;
                return { GetTrainingType };
            case ACTION_TRAINING_DATE_SUCCESS:
                GetTrainingDate = Object.assign({}, state.TrainingParticipationReducer.data);
                delete state.TrainingParticipationReducer;
                return { GetTrainingDate };
            case API_PARTICIPATION_REPORT_SUCCESS:
                GetParticipationDetails = Object.assign({}, state.TrainingParticipationReducer.data);
                delete state.TrainingParticipationReducer;
                return { GetParticipationDetails };
            default:
        }
    }
    return {
        GetTrainingType,
        GetTrainingDate,
        GetParticipationDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGetTrainingType, actionGetTrainingDate, actionGetParticipationDetails }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TrainingParticipationReport))
