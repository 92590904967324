import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_GETCLIENTMEETINGSDETAIL } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const TABLEADMEETINGVIEW_LOADING = 'TABLEADMEETINGVIEW_LOADING';
export const ACTION_TABLEADMEETINGVIEW_SUCCESS = 'ACTION_TABLEADMEETINGVIEW_SUCCESS';

export function actionTabLeadMeetingView(params) {
        return (dispatch, getState) => Api.post(API_GETCLIENTMEETINGSDETAIL, params).then((res) => {
            const data = res;
            PRINT('TABLEADMEETINGVIEW ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_TABLEADMEETINGVIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(TABLEADMEETINGVIEW_LOADING, { loading: false, data }));
        });
    }