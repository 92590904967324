import { ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const unattendClientLeadReportReducer = createReducer(INITIAL_STATE, {
    [ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS,
                loading: false,
            }
        )
    }
}
)

export default unattendClientLeadReportReducer