import { ACTION_ADD_LOSS_SITE_VISIT_SUCCESS } from './action';
import { ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS } from './action';
import { ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS } from './action';
import { ACTION_ADD_CLAIMS_VIEW_SUCCESS } from "./action";
import createReducer from '../../../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const AddLossSiteVisitReducer = createReducer(INITIAL_STATE, {
    [ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADD_LOSS_SITE_VISIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_LOSS_SITE_VISIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADD_CLAIMS_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_CLAIMS_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
});
export default AddLossSiteVisitReducer;