import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import { RiLayoutGridFill } from 'react-icons/ri';
import { BsTable } from 'react-icons/bs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import $ from "jquery";
import _ from 'lodash';
import { DateBox } from 'devextreme-react';
import { useForm, Controller } from "react-hook-form";
import { getDateFilter, verifyPermission } from '../../../../../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiFilter } from "react-icons/fi";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import TeamMembers from '../../../../../../component/popups/team_details';
import { actionAllMeetings, ACTION_API_ALL_MEETINGS_SUCCESS } from './action';
import { actionAllTeamMeetingMember, ACTION_ALL_TEAM_VERTICAL_SUCCESS } from './action';
import moment from 'moment';

const AllMeetings = (props) => {

    const { match } = props;
    const { control } = useForm();
    const [allMeetings, setAllMeetings] = useState([]);
    const [selectedTemplate, setselectedTemplate] = useState('LIST')
    const [myMeetingCount, setMyMeetingCount] = useState(0);
    const [myTeamMeetingCount, setMyTeamMeetingCount] = useState('');
    const [type, setType] = useState('my');
    const [showRMName, setShowRMName] = useState(false);
    const [showTeamMeeting, setShowTeamMeeting] = useState(false);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [myTeamVertical, setMyTeamVertical] = useState([]);
    const [status, setStatus] = useState();
    const [userIds, setUserIds] = useState([]);
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(90, 'days').format('YYYY-MM-DD'));
    const [displaySelectedFormatBtn, setDisplaySelectedFormatBtn] = useState(null);
    let day = Object.assign({}, getDateFilter('DAY'))
    let reset = Object.assign({}, getDateFilter('RESET'))

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionAllTeamMeetingMember({ user_id: props.auth.getUser().user_id });
        props.actionAllMeetings({
            type: type,
            meeting_type: "all",
            initial: 1,
            from_date: fromDate,
            to_date: toDate,
        });
    }
    useEffect(initialRender, []);

    const onAllMeetingsUpdate = () => {
        const { AllMeetings } = props
        if (AllMeetings != null) {
            var AllMeetingsResponse = Object.assign({}, AllMeetings);
            setStatus(AllMeetingsResponse.status);
            if (AllMeetingsResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "created_by", caption: "Created By", visible: true },
                    { dataField: "meeting_type", caption: "Meeting Type", visible: true },
                    { dataField: "meeting_with_name", caption: "Company Name", visible: true },
                    { dataField: "meeting_date", caption: "Meeting Date", visible: false },
                    { dataField: "meeting_date_formated", caption: "Meeting Date", visible: true },
                    { dataField: "agenda", caption: "Agenda", visible: true },
                    { dataField: "location", caption: "Location", visible: true },
                    { dataField: "status", caption: "Status", visible: true },
                ]);

                if (type === "my") {
                    setMyMeetingCount(AllMeetingsResponse.response.length);
                    setAllMeetings(AllMeetingsResponse.response);
                    setShowRMName(false);
                } else {
                    setMyMeetingCount(0);
                    setMyTeamMeetingCount(AllMeetingsResponse.response.length);
                    setAllMeetings(AllMeetingsResponse.response);
                    setShowRMName(false);
                }

            } else {
                $("#progress-loader").addClass("hidden");
                switch (AllMeetingsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        $("#progress-loader").addClass("hidden");
                        setAllMeetings([]);
                        setMyMeetingCount(0);
                        setMyTeamMeetingCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAllMeetingsUpdate, [props.AllMeetings])

    const onAllTeamMeetingMemberUpdate = () => {
        const { AllTeamMeetingMember } = props;
        var AllTeamMeetingMemberResponse = Object.assign({}, AllTeamMeetingMember);
        if (AllTeamMeetingMemberResponse.result === true) {
            var myTeam = []
            var myTeamMember = []
            AllTeamMeetingMemberResponse.response.map((item) => {
                if (item.id != props.auth.getUser().user_id) {
                    myTeam.push(item);
                    myTeamMember.push(item.id);
                    setMyTeamVertical(myTeam);
                    setMyTeamMembers(myTeamMember);
                }
            })

        } else {
            switch (AllTeamMeetingMemberResponse.status) {
                case UNAUTHORIZED:
                    $("#progress-loader").addClass("hidden");
                    toast.success("Your Login session has expired, Please try to Login again!");
                    props.auth.logout();
                    break;
                case ERROR:
                    $("#progress-loader").addClass("hidden");
                    break;
                default:
            }
        }
    }
    useEffect(onAllTeamMeetingMemberUpdate, [props.AllTeamMeetingMember]);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "created_by", caption: "Created By", visible: true },
        { dataField: "meeting_type", caption: "Meeting Type", visible: true },
        { dataField: "meeting_with_name", caption: "Company Name", visible: true },
        { dataField: "meeting_date", caption: "Meeting Date", visible: false },
        { dataField: "meeting_date_formated", caption: "Meeting Date", visible: true },
        { dataField: "agenda", caption: "Agenda", visible: true },
        { dataField: "location", caption: "Location", visible: true },
        { dataField: "status", caption: "Status", visible: true },
    ]);

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'Open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>Open</div>;
            case 'Close':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>Close</div>;
            case 'Cancel':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>Cancel</div>;
            default:
                return <></>
        }
    }

    const renderMeetingDate = (cell) => {
        return <div>{cell?.data?.meeting_date_formated != null ? moment(cell?.data?.meeting_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderCreatedByCell = (cell) => {
        if (cell?.data?.created_by != null) {
            return <a
            className='text-primary'
            onClick={() => {
                props.history.push({
                    pathname: '/profile',
                    state: {
                        user_id: cell?.data?.created_by_id,
                    }
                })
            }}
            ><u>{cell?.data?.created_by}</u></a>
        }
    }

    const renderCompanyNameCell = (cell) => {
        if(cell?.data?.meeting_with_name != null){
            return <a
                className= {(cell?.data?.meeting_type === 'Lead' || cell?.data?.meeting_type === 'client') ? "text-primary text-decoration-underline" : "" }
                onClick={(() => {
                    if(cell?.data?.meeting_type === 'Lead'){
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                    }
                    else if (cell?.data?.meeting_type === 'Client'){
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        })
                    }
                })}
            >{cell?.data?.meeting_with_name}</a>
        }
    }
    // const renderCompanyName = (cell) => {

    //     if (cell.data.meeting_with_name != null) {
    //         return cell.data.meeting_with_name;
    //     }
    //     return cell.data.insurer_company_name;
    // }

    const renderMeetingType = (cell) => {
        return _.upperFirst(cell.data.meeting_type);
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        switch (params.data.meeting_type) {
                                            case 'Reinsurance':
                                                props.history.push({ pathname: `/meetings/ri-meetings/view`, state: { ri_meeting: params.data.id } });
                                                break;
                                            case 'Internal':
                                                props.history.push({ pathname: `/meetings/internal-meetings/view`, state: { internal_meeting_id: params.data.id } });
                                                break;
                                            case 'Tpa':
                                                props.history.push({ pathname: `/meetings/tpa-meetings/view`, state: { tpa_meeting_id: params.data.id } })
                                                break;
                                            case 'Surveyor':
                                                props.history.push({ pathname: `/meetings/serveyor-meetings/view`, state: { serveyor_meeting_id: params.data.id } })
                                                break;
                                            case 'Lead':
                                            case 'Client':
                                                props.history.push({ pathname: `/meetings/customer-meetings/view`, state: { customer_meeting: params.data } })
                                                break;
                                            case 'Insurer':
                                                props.history.push({ pathname: `/meetings/insurance-company-meetings/view`, state: { insurer_branch_id: params.data.id } })
                                                break;
                                        }
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                props.auth.userId === params.data.created_by_id ?
                                    <button
                                        className={`btn btn-icon btn-sm ${params.data != null && (params.data.status === 'Close' || params.data.status === 'Cancel') ? 'hidden' : ''}`}
                                        onClick={() => {
                                            switch (params.data.meeting_type) {
                                                case 'Reinsurance':
                                                    props.history.push({ pathname: `/meetings/ri-meetings/edit`, state: { ri_meeting: params.data.id } });
                                                    break;
                                                case 'Internal':
                                                    props.history.push({ pathname: `/meetings/internal-meetings/edit`, state: { internal_meeting_id: params.data.id } });
                                                    break;
                                                case 'Tpa':
                                                    props.history.push({ pathname: `/meetings/tpa-meetings/edit`, state: { tpa_meeting_id: params.data.id } })
                                                    break;
                                                case 'Surveyor':
                                                    props.history.push({ pathname: `/meetings/serveyor-meetings/edit`, state: { serveyor_meeting_id: params.data.id } })
                                                    break;
                                                case 'Lead':
                                                case 'Client':
                                                    props.history.push({ pathname: `/meetings/customer-meetings/edit`, state: { customer_meeting: params.data } })
                                                    break;
                                                case 'Insurer':
                                                    props.history.push({ pathname: `/meetings/insurance-company-meetings/edit`, state: { insurer_branch_id: params.data.id } })
                                                    break;
                                            }
                                        }}
                                    >
                                        <FiEdit size={16} className="primary" />
                                    </button>
                                    : <> </>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "created_by"){
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderCreatedByCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "meeting_with_name"){
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderCompanyNameCell}
                            visible={objColumn.visible}
                        >
                        </Column>
                    );
                }
                else if (objColumn.dataField === "meeting_type") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderMeetingType}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "meeting_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderMeetingDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };
    const renderListUI = () => {
        return (
            <DataGrid
                dataSource={allMeetings}
                showBorders={true}
                export={{
                    fileName: "All Meetings",
                    enabled: allMeetings != null && allMeetings.length > 0 ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption("meeting_date_formated", "visible", false)
                    event.component.columnOption("meeting_date", "visible", true)
                }}
                onExported={(event) => {
                    event.component.columnOption("meeting_date_formated", "visible", true)
                    event.component.columnOption("meeting_date", "visible", false)
                    event.component.endUpdate();
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    const renderGridUI = () => {
        var arrGridColumns = []
        if (allMeetings != null && allMeetings.length > 0) {
            allMeetings.map((objAllMeetings, index) => (
                arrGridColumns.push(
                    <div className="col-12 col-md-6 col-lg-6" key={index}>
                        <div className="card border">
                            <div className={`card-body px-0 py-1`}>
                                <div className="d-flex flex-row px-1">
                                    <div className="profile-img-container-sm bg-light rounded-circle mr-1 border">
                                        <h6 class="name-preffix-sm"> {String(objAllMeetings.created_by).substring(0, 2).toUpperCase()}</h6>
                                    </div>
                                    <div>
                                        <p className={`card-text-esc font-medium-1 mb-0 text-capitalize`}>{objAllMeetings.created_by}</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>Company Name: {objAllMeetings.meeting_with_name}</p>
                                    </div>
                                    <div className={`ml-auto font-small-2`}>
                                        <div>
                                            <span>Meeting Date:</span> {objAllMeetings.meeting_date}
                                        </div>
                                        <div>
                                            <span>Meeting Type: {_.capitalize(objAllMeetings.meeting_type)}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className={`my-half`} />
                                <div className="d-flex flex-row px-1">
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Agenda:</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>{objAllMeetings.agenda}</p>
                                    </div>
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Location:</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>{objAllMeetings.location}</p>
                                    </div>
                                    <div className={"align-self-end d-inline-flex"}>
                                        {
                                            props.auth.userId === objAllMeetings.created_by_id ?
                                                <FiEdit size={14} className={`primary mr-half ${objAllMeetings != null && (objAllMeetings.status === 'close' || objAllMeetings.status === 'cancel') ? 'hidden' : ''}`} cursor="pointer"
                                                    onClick={() => {
                                                        switch (objAllMeetings.meeting_type) {
                                                            case 'internal':
                                                                props.history.push({ pathname: `/meetings/internal-meetings/edit`, state: { internal_meeting_id: objAllMeetings.id } });
                                                                break;
                                                            case 'tpa':
                                                                props.history.push({ pathname: `/meetings/tpa-meetings/edit`, state: { tpa_meeting_id: objAllMeetings.id } })
                                                                break;
                                                            case 'surveyor':
                                                                props.history.push({ pathname: `/meetings/serveyor-meetings/edit`, state: { serveyor_meeting_id: objAllMeetings.id } })
                                                                break;
                                                            case 'lead':
                                                            case 'client':
                                                                props.history.push({ pathname: `/meetings/customer-meetings/edit`, state: { customer_meeting: objAllMeetings } })
                                                                break;
                                                            case 'insurer':
                                                                props.history.push({ pathname: `/meetings/insurance-company-meetings/edit`, state: { insurer_branch_id: objAllMeetings.id } })
                                                                break;
                                                        }
                                                    }}

                                                /> : <> </>
                                        }
                                        <FiEye size={14} className={`primary`} cursor="pointer"
                                            onClick={() => {
                                                switch (objAllMeetings.meeting_type) {
                                                    case 'internal':
                                                        props.history.push({ pathname: `/meetings/internal-meetings/view`, state: { internal_meeting_id: objAllMeetings.id } });
                                                        break;
                                                    case 'tpa':
                                                        props.history.push({ pathname: `/meetings/tpa-meetings/view`, state: { tpa_meeting_id: objAllMeetings.id } })
                                                        break;
                                                    case 'surveyor':
                                                        props.history.push({ pathname: `/meetings/serveyor-meetings/view`, state: { serveyor_meeting_id: objAllMeetings.id } })
                                                        break;
                                                    case 'lead':
                                                    case 'client':
                                                        props.history.push({ pathname: `/meetings/customer-meetings/view`, state: { customer_meeting: objAllMeetings } })
                                                        break;
                                                    case 'insurer':
                                                        props.history.push({ pathname: `/meetings/insurance-company-meetings/view`, state: { insurer_branch_id: objAllMeetings.id } })
                                                        break;
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            ))
        }
        return arrGridColumns
    }

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                id="btn-my-meeting"
                                className={`btn ${type === "my" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setDisplaySelectedFormatBtn(null);
                                    setType("my");
                                    setShowTeamMeeting(false);
                                    setShowRMName(false);
                                    setMyTeamMeetingCount(0);
                                    setFromDate(moment(new Date()).subtract(90, 'days').format('YYYY-MM-DD'))
                                    setToDate(moment(new Date()).format('YYYY-MM-DD'))
                                    props.actionAllMeetings({
                                        meeting_type: "all",
                                        type: "my",
                                        initial: 1,
                                        from_date: reset.start,
                                        to_date: reset.end,
                                    });
                                    props.actionAllTeamMeetingMember({ user_id: props.auth.getUser().user_id });
                                }}
                            >
                                My Meetings {myMeetingCount > 0 ? `(` + myMeetingCount + `)` : ``}
                            </button>
                        </li>
                        {
                            verifyPermission("SHOW_TEAM") ?
                                <li class="nav-item ml-1">
                                    <button
                                        id="btn-my-team-meeting"
                                        className={`btn ${type === "team" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            setType("team");
                                            setDisplaySelectedFormatBtn(null);
                                            setMyMeetingCount(0);
                                            setShowTeamMeeting(true);
                                            setFromDate(moment(new Date()).subtract(90, 'days').format('YYYY-MM-DD'))
                                            setToDate(moment(new Date()).format('YYYY-MM-DD'))
                                            if (showTeamMeeting === true) {
                                                setShowRMName(true);
                                            } else {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionAllMeetings({
                                                    meeting_type: "all",
                                                    type: "team",
                                                    team_user_ids: myTeamMembers,
                                                    initial: 1,
                                                    from_date: reset.start,
                                                    to_date: reset.end,
                                                });
                                            }
                                        }}
                                    >
                                        Team Meetings <span className='mr-half'>{status !== 402 ? (myTeamMeetingCount > 0 ? `(` + myTeamMeetingCount + `)` : '') : ``}</span>
                                        <FiFilter size={16} />
                                    </button>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {/* {
                            allMeetings != null && allMeetings.length > 0 ?
                                <li class="nav-item mr-1">
                                    <button className="btn btn-primary btn-md font-small-3 shadow round"
                                        onClick={(event) => {
                                            if (selectedTemplate === "LIST") {
                                                setselectedTemplate("GRID")
                                            } else {
                                                setselectedTemplate("LIST")
                                            }
                                        }}
                                    >
                                        {
                                            selectedTemplate === "LIST" ?
                                                <RiLayoutGridFill />
                                                :
                                                <BsTable />
                                        }


                                    </button>
                                </li>
                                : <></>
                        } */}
                        {verifyPermission("ALL_MEETING_MENU") ?
                            <div className="content-header-right col-12 col-md-6 col-lg-6">
                                <ul class="nav nav-pills float-left">
                                    <li class="nav-item">
                                        <div class="btn-group mr-1 mb-1">
                                            <button type="button"
                                                class="btn btn-primary btn-md px-1 font-small-3 shadow round dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="true">
                                                Create Meeting
                                            </button>
                                            <div class="dropdown-menu" x-placement="bottom-start">
                                                {
                                                    verifyPermission("CREATE_CUSTOMER_MEETING") ?
                                                        <a class="dropdown-item"
                                                            onClick={() => {
                                                                props.history.push(`${match.url}/customer-meetings/add`)
                                                            }}
                                                        >
                                                            Customer Meetings
                                                        </a>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    verifyPermission("CREATE_INSURANCE_MEETING") ?
                                                        <a class="dropdown-item"
                                                            onClick={() => {
                                                                props.history.push(`${match.url}/insurance-company-meetings/add`)
                                                            }}
                                                        >
                                                            Insurance Company Meetings
                                                        </a>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    verifyPermission("CREATE_INTERNAL_MEETING") ?
                                                        <a class="dropdown-item"
                                                            onClick={() => {
                                                                props.history.push(`${match.url}/internal-meetings/add`)
                                                            }}
                                                        >
                                                            Internal Meetings
                                                        </a>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    verifyPermission("CREATE_SURVEYOR_MEETING") ?
                                                        <a class="dropdown-item"
                                                            onClick={() => {
                                                                props.history.push(`${match.url}/serveyor-meetings/add`)
                                                            }}
                                                        >
                                                            Serveyor Meetings
                                                        </a>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    verifyPermission("CREATE_TPA_MEETING") ?
                                                        <a class="dropdown-item"
                                                            onClick={() => {
                                                                props.history.push(`${match.url}/tpa-meetings/add`)
                                                            }}
                                                        >
                                                            TPA Meetings
                                                        </a>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    verifyPermission("CREATE_RI_MEETING") ?
                                                        <a class="dropdown-item"
                                                            onClick={() => {
                                                                props.history.push(`${match.url}/ri-meetings/add`)
                                                            }}
                                                        >
                                                            RI Meetings
                                                        </a>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            : <> </>
                        }

                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="content-header row mb-1">
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className="form-group">
                                        <Controller
                                            name="from_date"
                                            control={control}
                                            render={({ field }) =>
                                                <DateBox
                                                    calendarOptions={{
                                                        showTodayButton: true,
                                                    }}
                                                    acceptCustomValue={false}
                                                    stylingMode="underlined"
                                                    pickerType="calendar"
                                                    placeholder="From Date"
                                                    type="date"
                                                    displayFormat="dd/MM/yyyy"
                                                    showClearButton={false}
                                                    value={fromDate}
                                                    onValueChange={(value) => {
                                                        var objFromDate = new Date(value);
                                                        var objToDate = new Date(toDate);

                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "From date can't be greater than To date",
                                                                icon: 'error'
                                                            }).then(success => {

                                                            })
                                                        } else {
                                                            $("#progress-loader").removeClass("hidden");
                                                            setDisplaySelectedFormatBtn(null);
                                                            field.onChange(moment(value).format('yyyy-MM-DD'));

                                                            var strFromDate = moment(value).format('yyyy-MM-DD')
                                                            setFromDate(strFromDate);

                                                            if (type === 'team') {
                                                                props.actionAllMeetings({
                                                                    meeting_type: "all",
                                                                    type: type,
                                                                    initial: 0,
                                                                    from_date: strFromDate,
                                                                    to_date: toDate,
                                                                    team_user_ids: myTeamMembers
                                                                });
                                                            } else {
                                                                props.actionAllMeetings({
                                                                    meeting_type: "all",
                                                                    type: type,
                                                                    initial: 0,
                                                                    from_date: strFromDate,
                                                                    to_date: toDate,
                                                                });
                                                            }
                                                        }
                                                    }
                                                    }
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className="form-group">
                                        <Controller
                                            name="to_date"
                                            control={control}
                                            render={({ field }) =>
                                                <DateBox
                                                    calendarOptions={{
                                                        showTodayButton: true,
                                                    }}
                                                    acceptCustomValue={false}
                                                    stylingMode="underlined"
                                                    pickerType="calendar"
                                                    placeholder="To Date"
                                                    type="date"
                                                    displayFormat="dd/MM/yyyy"
                                                    showClearButton={false}
                                                    value={toDate}
                                                    onValueChange={(value) => {
                                                        var objFromDate = new Date(fromDate);
                                                        var objToDate = new Date(value);

                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "To date must be greater than from date",
                                                                icon: 'error'
                                                            }).then(success => {

                                                            })
                                                        } else {
                                                            $("#progress-loader").removeClass("hidden");
                                                            setDisplaySelectedFormatBtn(null);
                                                            field.onChange(moment(value).format('yyyy-MM-DD'));

                                                            var strToDate = moment(value).format('yyyy-MM-DD');
                                                            setToDate(strToDate);

                                                            if (type === 'team') {
                                                                props.actionAllMeetings({
                                                                    meeting_type: "all",
                                                                    type: type,
                                                                    initial: 0,
                                                                    from_date: fromDate,
                                                                    to_date: strToDate,
                                                                    team_user_ids: myTeamMembers
                                                                });
                                                            } else {
                                                                props.actionAllMeetings({
                                                                    meeting_type: "all",
                                                                    type: type,
                                                                    initial: 0,
                                                                    from_date: fromDate,
                                                                    to_date: strToDate,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-2 my-md-auto">
                            <div className='row justify-content-end'>
                                <div className="col-12 col-md-auto">
                                    <div class="form-group ">
                                        <div class="btn-group btn-group-sm" role="group">
                                            <button type="button"
                                                className={`btn ${displaySelectedFormatBtn == 'Day' ? 'btn-primary' : 'btn-outline-primary'} border-right-0`}
                                                onClick={() => {
                                                    $("#progress-loader").removeClass("hidden");
                                                    setDisplaySelectedFormatBtn('Day');
                                                    setFromDate(day.start);
                                                    setToDate(day.end);
                                                    if (type === 'team') {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: day.start,
                                                            to_date: day.end,
                                                            team_user_ids: myTeamMembers
                                                        });
                                                    } else {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: day.start,
                                                            to_date: day.end,
                                                        });
                                                    }
                                                }}
                                            >DAY</button>
                                            <button type="button"
                                                className={`btn ${displaySelectedFormatBtn == 'Week' ? 'btn-primary' : 'btn-outline-primary'}`}
                                                onClick={() => {
                                                    $("#progress-loader").removeClass("hidden");
                                                    setDisplaySelectedFormatBtn('Week');
                                                    let week = Object.assign({}, getDateFilter('WEEK'))
                                                    setFromDate(week.start);
                                                    setToDate(week.end);
                                                    if (type === 'team') {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: week.start,
                                                            to_date: week.end,
                                                            team_user_ids: myTeamMembers
                                                        });
                                                    } else {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: week.start,
                                                            to_date: week.end,
                                                        });
                                                    }
                                                }}
                                            >WEEK</button>
                                            <button type="button"
                                                className={`btn ${displaySelectedFormatBtn == 'MONTH' ? 'btn-primary' : 'btn-outline-primary'} border-right-0`}
                                                onClick={() => {
                                                    $("#progress-loader").removeClass("hidden");
                                                    setDisplaySelectedFormatBtn('MONTH');
                                                    let month = Object.assign({}, getDateFilter('MONTH'))
                                                    setFromDate(month.start);
                                                    setToDate(month.end);
                                                    if (type === 'team') {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: month.start,
                                                            to_date: month.end,
                                                            team_user_ids: myTeamMembers
                                                        });
                                                    } else {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: month.start,
                                                            to_date: month.end
                                                        });
                                                    }

                                                }}
                                            >MONTH</button>
                                            <button type="button"
                                                className={`btn ${displaySelectedFormatBtn == 'YEAR' ? 'btn-primary' : 'btn-outline-primary'} `}
                                                onClick={() => {
                                                    $("#progress-loader").removeClass("hidden");
                                                    setDisplaySelectedFormatBtn('YEAR');
                                                    let year = Object.assign({}, getDateFilter('YEAR'))
                                                    setFromDate(year.start);
                                                    setToDate(year.end);
                                                    if (type === 'team') {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: year.start,
                                                            to_date: year.end,
                                                            team_user_ids: myTeamMembers
                                                        });
                                                    } else {
                                                        props.actionAllMeetings({
                                                            type: type,
                                                            meeting_type: "all",
                                                            initial: 0,
                                                            from_date: year.start,
                                                            to_date: year.end,
                                                        });
                                                    }

                                                }}
                                            >YEAR</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <button
                                        className="btn btn-sm btn-primary round text-uppercase"
                                        type="button"
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            let reset = Object.assign({}, getDateFilter('RESET'))
                                            setFromDate(reset.start);
                                            setToDate(reset.end);
                                            setDisplaySelectedFormatBtn(null);
                                            if (type === 'team') {
                                                props.actionAllMeetings({
                                                    type: type,
                                                    meeting_type: "all",
                                                    initial: 1,
                                                    from_date: reset.start,
                                                    to_date: reset.end,
                                                    team_user_ids: myTeamMembers
                                                });
                                            } else {
                                                props.actionAllMeetings({
                                                    type: type,
                                                    meeting_type: "all",
                                                    initial: 1,
                                                    from_date: reset.start,
                                                    to_date: reset.end,
                                                });
                                            }

                                        }}
                                    >Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            {
                                selectedTemplate === "LIST" ?
                                    renderListUI()
                                    : <></>
                            }
                        </div>
                        {
                            selectedTemplate === "GRID" ?
                                renderGridUI()
                                : <></>
                        }

                    </div>
                </div>
            </div>
            <TeamMembers
                {...props}
                show={showRMName}
                myTeamVertical={myTeamVertical}
                myTeamMembers={userIds != null && userIds.length > 0 ? userIds : myTeamMembers}
                handleClose={(teamMember) => {
                    if (teamMember != null) {
                        setUserIds(teamMember);
                        props.actionAllMeetings({
                            type: "team",
                            meeting_type: "all",
                            team_user_ids: teamMember,
                            initial: 1,
                            from_date: reset.start,
                            to_date: reset.end,
                        });
                    } else {
                        $("#progress-loader").addClass("hidden");
                    }
                    setShowRMName(false);
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var AllMeetings = null;
    var AllTeamMeetingMember = null;
    if (
        state.AllMeetingsReducer != null &&
        Object.keys(state.AllMeetingsReducer).length > 0 &&
        state.AllMeetingsReducer.constructor === Object
    ) {
        switch (state.AllMeetingsReducer.tag) {
            case ACTION_API_ALL_MEETINGS_SUCCESS:
                AllMeetings = Object.assign({}, state.AllMeetingsReducer.data);
                delete state.AllMeetingsReducer;
                return { AllMeetings };
            case ACTION_ALL_TEAM_VERTICAL_SUCCESS:
                AllTeamMeetingMember = Object.assign({}, state.AllMeetingsReducer.data);
                delete state.AllMeetingsReducer;
                return { AllTeamMeetingMember };
            default:

        }

    }
    return {
        AllMeetings,
        AllTeamMeetingMember
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAllMeetings, actionAllTeamMeetingMember }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AllMeetings))
