import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../constants';
import { actionAddFeedback, ACTION_ADD_FEEDBACK_SUCCESS } from "./action";
import Swal from "sweetalert2";

const AddFeedback = (props) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const initialRender = () => {
        if (props.show === true) {

            reset({
                'feedback': ''
            })

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            $("#feedback_link").removeClass("open")
        }
    };
    useEffect(initialRender, [props.show]);

    const onFeedbackSaveUpdate = () => {
        const { NewFeedback } = props;
        var NewFeedbackResponse = Object.assign({}, NewFeedback);

        $("#save_feedback").prop("disabled", false);

        if (NewFeedbackResponse.result === true) {
            $("#form-loader").addClass("hidden");
            Swal.fire({
                title: 'Success',
                icon: 'success',
                html: "Your Feedback Submitted Successfully",
                confirmButtonColor: PRIMARY_COLOR,
            }).then((result) => {
                props.handleClose();
            });
        } else {
            $("#form-loader").addClass("hidden");
            switch (NewFeedbackResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFeedbackSaveUpdate, [props.NewFeedback]);

    const onSubmit = (data) => {

        $("#form-loader").removeClass("hidden");
        $("#save_feedback").prop("disabled", true);
        props.actionAddFeedback(data);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Feedback
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Feedback&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="feedback"
                                                        name="feedback"
                                                        placeholder="Enter Feedback"
                                                        {...register('feedback', { required: 'Feedback is required' })}
                                                    />
                                                    {errors.feedback && <ErrorLabel message={errors.feedback?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="save_feedback"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Send
                                                <span id="form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    var NewFeedback = null;

    if (
        state.FeedbackAddReducer != null &&
        Object.keys(state.FeedbackAddReducer).length > 0 &&
        state.FeedbackAddReducer.constructor === Object
    ) {
        switch (state.FeedbackAddReducer.tag) {
            case ACTION_ADD_FEEDBACK_SUCCESS:
                NewFeedback = Object.assign({}, state.FeedbackAddReducer.data);
                delete state.FeedbackAddReducer;
                return { NewFeedback };
            default:
        }
    }
    return {
        NewFeedback

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionAddFeedback,
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AddFeedback))
