import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionItSupportView, ACTION_IT_SUPPORT_VIEW_SUCCESS } from './action';
import { actionChangeStatus, ACTION_CHANGE_STATUS_SUCCESS } from './action';
import $ from "jquery";
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { generateInitialLettersString } from '../../../../../../utils';
import { bindActionCreators } from 'redux';
import { CANCEL_BY_EMPLOYEE, REQUEST_IT } from '../../../../../../constants';
import Swal from 'sweetalert2';
import RequestHistory from '../../../../../../component/widgets/request_history';
import { UNAUTHORIZED } from '../../../../../../constants';
import ChangeStatus from '../../../../../../component/popups/approval_action';
import _ from "lodash";
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const ItSupportView = (props) => {
    const [itSupportView, setItSupportView] = useState([]);
    const [changeStatus, setChangeStatus] = useState();
    const [requestHistory, setRequestHistory] = useState(null);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionItSupportView({
            id: props.location.state.id,
        });
    }
    useEffect(initialRender, []);

    const onViewItSupportUpdate = () => {
        const { ViewItSupport } = props;
        if (ViewItSupport != null) {
            var ViewItSupportResponse = Object.assign({}, ViewItSupport);
            if (ViewItSupportResponse.result === true) {
                setItSupportView(ViewItSupportResponse.response);
                if (ViewItSupportResponse.response.request_status.length > 0) {
                    setRequestHistory(ViewItSupportResponse.response.request_status);
                }
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ViewItSupportResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onViewItSupportUpdate, [props.ViewItSupport])

    const onChangeStatusUpdate = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        const { ChangeStatus } = props;
        if (ChangeStatus != null) {
            var ChangeStatusResponse = Object.assign({}, ChangeStatus);
            if (ChangeStatusResponse.result === true) {
                setChangeStatus(ChangeStatusResponse.response);
                props.actionItSupportView({ id: props.location.state.id });
                setData(KEY_TOAST_MESSAGE, "Status Change Successfully");
            } else {
                switch (ChangeStatusResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onChangeStatusUpdate, [props.ChangeStatus])
    return (
        <>
            <ScreenTitle title=" Ticket Details" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (itSupportView != null && itSupportView.profile_photo != null) ?
                                                <img
                                                    src={itSupportView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(itSupportView.full_name))}
                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    itSupportView != null && itSupportView.full_name != null ?
                                                        itSupportView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success text-bold-500 round font-small-2">
                                                {
                                                    itSupportView != null && itSupportView.designation_name != null ?
                                                        itSupportView.designation_name : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-2 text-bold-500 my-0">
                                                    {
                                                        itSupportView != null && itSupportView.status != null ?
                                                            _.startCase(itSupportView.status) : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2 mt-half">On <span className="text-justify text-primary">
                                                {
                                                    itSupportView != null && itSupportView.raised_date != null ?
                                                        itSupportView.raised_date : '-'
                                                }</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Ticket ID</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-small-3 text-bold-700 mb-0">
                                    {
                                        itSupportView != null && itSupportView.ticket_no != null ?
                                            _.startCase(itSupportView.ticket_no) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Current Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-small-3 text-bold-700 mb-0">
                                    {
                                        itSupportView != null && itSupportView.status != null ?
                                            _.startCase(itSupportView.status) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0"> Priority</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-small-3 text-bold-700 mb-0">
                                    {
                                        itSupportView != null && itSupportView.priority != null ?
                                            _.upperFirst(itSupportView.priority) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0"> Description</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-small-3 text-bold-700 mb-0">
                                    {
                                        itSupportView != null && itSupportView.description != null ?
                                            itSupportView.description : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Ticket Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-small-3 text-bold-700 mb-0">
                                    {
                                        itSupportView != null && itSupportView.name != null ?
                                            _.startCase(itSupportView.name) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3">
                                <h4 className="text-uppercase font-small-3 mb-0">Document</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3">
                                {
                                    (itSupportView != null && itSupportView.user_request_document != null && itSupportView.user_request_document.length > 0) ?
                                        itSupportView.user_request_document.map((objSupportLink, index) => (
                                            // <>
                                            //     <div>
                                            //         <a target="_blank" href={objSupportLink.user_request_document} download="Meeting Document">Document {index + 1}</a>
                                            //     </div>
                                            // </>      
                                            <>
                                                <a className="primary"
                                                    onClick={() => {
                                                        if (objSupportLink.user_request_document?.split('.').pop() == 'pdf' ||
                                                            objSupportLink.user_request_document?.split('.').pop() == 'PDF' ||
                                                            objSupportLink.user_request_document?.split('.').pop() == 'png' ||
                                                            objSupportLink.user_request_document?.split('.').pop() == 'jpg' ||
                                                            objSupportLink.user_request_document?.split('.').pop() == 'jpeg' ||
                                                            objSupportLink.user_request_document?.split('.').pop() == 'gif'
                                                        ) {
                                                            setSelectedDocumentIndex(objSupportLink.user_request_document)
                                                            setShowDocumentDetails(true);
                                                            setExtension(objSupportLink.user_request_document?.split('.').pop())
                                                            setDocumentDetails(objSupportLink)
                                                        } else {
                                                            downloadFile(objSupportLink.user_request_document);
                                                        }
                                                    }}
                                                >
                                                    Document {index + 1}
                                                </a>
                                                <a id="downloadLink" className="d-none"></a>
                                            </>

                                        )) : <>-</>
                                }
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 pt-3 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {

                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                            {
                                // The Below condition for Task Owner To Cancel The Request
                                itSupportView != null && (itSupportView.status_name === 'Open') ?
                                    <button
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, Do you want to cancel your request?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                                inputPlaceholder: 'Remark (If Any)',
                                                input: 'text',
                                            }).then(({ isConfirmed, value }) => {
                                                if (isConfirmed) {
                                                    props.actionChangeStatus({
                                                        "id": props.location.state.id,
                                                        "remark": value.length > 0 ? value : '',
                                                        "status": CANCEL_BY_EMPLOYEE,
                                                        "type": REQUEST_IT
                                                    })
                                                }
                                            })
                                        }}
                                    >Cancel Request</button>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <ChangeStatus
                approval={itSupportView}
                show={showChangeStatus}
                type={REQUEST_IT}
                handleClose={() => {
                    setShowChangeStatus(false);
                    setRequestHistory(null);
                    props.actionItSupportView({
                        id: props.location.state.id,
                    });
                }}
            />
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />

        </>
    )
}

const mapStateToProps = (state) => {
    var ViewItSupport = null;
    var ChangeStatus = null;
    if (
        state.ItSupportViewReducer != null &&
        Object.keys(state.ItSupportViewReducer).length > 0 &&
        state.ItSupportViewReducer.constructor === Object
    ) {
        switch (state.ItSupportViewReducer.tag) {
            case ACTION_IT_SUPPORT_VIEW_SUCCESS:
                ViewItSupport = Object.assign({}, state.ItSupportViewReducer.data);
                delete state.ItSupportViewReducer;
                return { ViewItSupport };
            case ACTION_CHANGE_STATUS_SUCCESS:
                ChangeStatus = Object.assign({}, state.ItSupportViewReducer.data);
                delete state.ItSupportViewReducer;
                return { ChangeStatus };
            default:
        }
    }

    return {
        ViewItSupport,
        ChangeStatus,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItSupportView, actionChangeStatus }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ItSupportView));