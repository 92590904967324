import { ACTION_API_GROUP_LEVEL_MEETING_SUCCESS } from "./action";
import createReducer from "../../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const TabGroupMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_GROUP_LEVEL_MEETING_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_GROUP_LEVEL_MEETING_SUCCESS,
                loading: false,
            }
        );
    },
});
export default TabGroupMeetingReducer