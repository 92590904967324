import React from 'react';
import { Route, Switch } from 'react-router';
import MeetingTrainingList from './list';
import MeetingTrainingDetail from './view';

const MeetingTrainingRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/view`} component={MeetingTrainingDetail} />
                <Route exact path={`${match.url}`} component={MeetingTrainingList} />
            </Switch>
        </>
    )
}

export default MeetingTrainingRoute