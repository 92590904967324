import { ACTION_HOLIDAY_EDIT_SUCCESS } from "./action";
import { ACTION_HOLIDAY_VIEW_SUCCESS } from "./action";
import {ACTION_HOLIDAY_EDIT_BRANCH_SUCCESS} from "./action"

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditHolidayReducer = createReducer(INITIAL_STATE, {
    [ACTION_HOLIDAY_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_HOLIDAY_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_HOLIDAY_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_HOLIDAY_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_HOLIDAY_EDIT_BRANCH_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_HOLIDAY_EDIT_BRANCH_SUCCESS,
                loading: false,
            }
        );
    },
});

export default EditHolidayReducer;