import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_VIEW_CRON_DASHBOARD } from '../../../api/constants';
import { PRINT } from '../../../utils';

export const ACTION_VIEW_CRON_DASHBOARD_LOADING = 'ACTION_VIEW_CRON_DASHBOARD_LOADING';
export const ACTION_VIEW_CRON_DASHBOARD_SUCCESS = 'ACTION_VIEW_CRON_DASHBOARD_SUCCESS';

export function actionCronDashboardView(params) {
    return (dispatch, getState) =>
        Api.post(API_VIEW_CRON_DASHBOARD,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_VIEW_CRON_DASHBOARD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_VIEW_CRON_DASHBOARD_LOADING, { loading: false, data }));
        });
}