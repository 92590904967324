import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_UPCOMING_MEETING } from "../../../api/constants";
import { PRINT } from "../../../utils";

export const ACTION_UPCOMING_MEETING_LOADING = "ACTION_UPCOMING_MEETING_LOADING";
export const ACTION_UPCOMING_MEETING_SUCCESS = 'ACTION_UPCOMING_MEETING_SUCCESS';

export function actionUpcomingMeeting() {
    return (dispatch, getState) =>
      Api.get(API_UPCOMING_MEETING)
        .then((res) => {
          const data = res;
          PRINT("ACTION RESPONSE : ", data);
          dispatch(fetchSuccess(ACTION_UPCOMING_MEETING_SUCCESS, { data }));
        })
        .catch((reason) => {
          /** Handle Error */
          var data = {
            message: "",
          };
          dispatch(loading(ACTION_UPCOMING_MEETING_LOADING, { loading: false, data }));
        });
  }