/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FiMail, FiLock, FiEye, FiEyeOff } from 'react-icons/fi';
import ErrorLabel from "../../../../component/form/error_label";
import { actionAdminLogin, ACTION_ADMINLOGIN_SUCCESS } from './action';
import { PRIMARY_COLOR, ERROR, VALIDATION_ERROR } from '../../../../constants';
import { KEY_USER_TOKEN, KEY_USER, setData, KEY_PERMISSION, KEY_FIREBASE_TOKEN, KEY_NOTIFICATION_COUNT } from '../../../../utils/preferences';
import FirebaseApp from "../../auth/firebase";
// const messaging = FirebaseApp.messaging();

const Login = (props) => {

    const { match } = props;

    const { register, handleSubmit, formState: { errors } } = useForm({});

    const [firebaseToken, setFirebaseToken] = useState(null);

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const initialRender = () => {
        $("body").addClass("bg-background");
        $("body")
            .removeClass("content-left-sidebar fixed-navbar")
            .addClass("1-column")
            .css("background-image", "url('/assets/images/background.jpg')")
            .css("background-attachment", "fixed")
            .css("background-repeat", "no-repeat")
            .css("background-size", "cover")
            .css("background-position", "center")
            .css("height", "100%");


        // messaging.getToken({ vapidKey: 'BL751SlUfAzR449yKLoM7iNuH4Q7FSCwU9GBSNnUaNQizBRokuq9YBNZd5C7nz00DpyRKB4tqe3DvEDHLsfQXkc' })
        //     .then((currentToken) => {
        //         if (currentToken) {
        //             console.log('################ TOKEN ################');
        //             console.log(currentToken);
        //             setFirebaseToken(currentToken);
        //         } else {
        //             console.log('No registration token available. Request permission to generate one.');
        //             return null;
        //         }
        //     }).catch((err) => {
        //         console.log('An error occurred while retrieving token. ', err);
        //         return null;
        //     });

        return () => {
            $("body").addClass("content-left-sidebar fixed-navbar");
            $("body").css("background-image", "none")
        };

    }
    useEffect(initialRender, []);

    const handleLoginSuccess = () => {
        $("#login").prop("disabled", false);
        const { Login } = props;
        var LoginResponse = Object.assign({}, Login);
        if (LoginResponse.result === true) {
            $("#login-loader").addClass("hidden");
            var isValidPassword = 1;

            if (password.search(/[0-9]/) == -1) {
                isValidPassword = 0;
            }
            if (password.search(/[a-z]/) == -1) {
                isValidPassword = 0;
            }
            if (password.search(/[A-Z]/) == -1) {
                isValidPassword = 0;
            }
            if (password.search(/[#$^+=!*()@%&]/) == -1) {
                isValidPassword = 0;
            }

            if (0 == isValidPassword) {
                props.history.push({
                    pathname: `/new-password`, state: {
                        email: email
                    }
                })
            } else {
                $("#login-loader").addClass("hidden");
                setData(KEY_USER, JSON.stringify(LoginResponse.response.user_detail));
                setData(KEY_USER_TOKEN, JSON.stringify(LoginResponse.response.token));
                var objPermission = []
                var showTeam = "SHOW_TEAM"
                var showCalendar = "SHOW_TRAVEL_CALENDAR";
                objPermission = LoginResponse.response.permission;
                if (LoginResponse.response.has_team === true) {
                    objPermission.push(showTeam)
                }
                if (LoginResponse.response.travel_settings) {
                    objPermission.push(showCalendar)
                }
                setData(KEY_PERMISSION, JSON.stringify(objPermission));
                setData(KEY_NOTIFICATION_COUNT, JSON.stringify(LoginResponse.response.notification_count));
                // if (firebaseToken != null) {
                //     setData(KEY_FIREBASE_TOKEN, firebaseToken);
                // }
                props.auth.updateToken(LoginResponse.response.token);
                props.history.replace({ pathname: `/dashboard` })
            }

        } else {
            $("#login-loader").addClass("hidden");
            switch (LoginResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: LoginResponse.message,
                        confirmButtonColor: PRIMARY_COLOR
                    }).then((result) => {
                    });
                    break;
                case VALIDATION_ERROR:
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: LoginResponse.message.email,
                        confirmButtonColor: PRIMARY_COLOR
                    }).then((result) => {
                    });
                    break;
                default:
            }
        }
    }
    useEffect(handleLoginSuccess, [props.Login]);

    const onSubmit = (data) => {
        $("#login-loader").removeClass("hidden");
        $("#login").prop("disabled", true);
        setEmail(data.email);
        setPassword(data.password);

        // if (firebaseToken != null) {
        //     data.device_token = firebaseToken;
        // }

        props.actionAdminLogin(data);
    }

    return (
        <>
            <div className="container login-container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1 order-lg-1 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-none d-md-flex d-lg-flex flex-row mb-2">
                            <div className="flex-column">
                                <img src={'/assets/images/app-logo.jpg'} style={{ height: 30, objectFit: 'cover' }} alt="" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-2">
                            <div className="flex-column">
                                <h1 className="text-bold-500 text-black">
                                    <span className="d-block">Networking &amp; customer</span>
                                    <span className="d-block">management made easier,</span>
                                    <span className="d-block">just for you!</span>
                                </h1>
                            </div>
                        </div>
                        <div className="d-flex flex-row mt-2">
                            <div className="flex-column">
                                <p className="font-small-4 text-black lh-1">
                                    <span className="d-block text-justify">an interactive platform for PINC employees to help manage work on the go;</span>
                                    <span className="d-block text-justify">Log in meetings and leads with clients. Share news. Congratulate co-worker on</span>
                                    <span className="d-block text-justify">achievements and interact with co-workers by using the dashboard.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 order-1 order-md-2 order-lg-2 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-flex d-md-none d-lg-none flex-row mb-2 justify-content-center justify-content-lg-start justify-content-md-start">
                            <img src={'/assets/images/app-logo.jpg'} style={{ height: 30, objectFit: 'cover' }} alt="" />
                        </div>
                        <div className="card shadow rounded p-4 p-md-5 mb-0">
                            <div className="card-title text-center text-black" style={{ fontWeight: 600 }}>Login to your account</div>
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <label>Email Address<span className="text-danger">*</span></label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        {...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Please enter valid Email Id'
                                            }
                                        })}
                                    />
                                    {errors.email && <ErrorLabel message={errors.email?.message} />}
                                    <div className="form-control-position">
                                        <FiMail size={18} className="primary" />
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <label>Password<span className="text-danger">*</span></label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        {...register('password', { required: 'Password is required' })}
                                    />
                                    {errors.password && <ErrorLabel message={errors.password?.message} />}
                                    <div className="form-control-position">
                                        <FiLock size={18} className="primary" />

                                    </div>
                                    <div className="form-control-position" style={{ position: 'absolute', right: 0 }}>
                                        <FiEye size={18} className="primary hidden" id="show_password" onClick={() => {
                                            $("#password").attr("type", "password");
                                            $("#show_password").addClass("hidden");
                                            $("#hide_password").removeClass("hidden");
                                        }} />
                                        <FiEyeOff size={18} className="primary" id="hide_password" onClick={() => {
                                            $("#password").attr("type", "text");
                                            $("#hide_password").addClass("hidden");
                                            $("#show_password").removeClass("hidden");
                                        }} />
                                    </div>
                                </fieldset>
                                <div className="d-flex flex-row justify-content-end mb-2">
                                    <button
                                        type="button"
                                        className="btn pr-0 font-small-3 blue-grey"
                                        onClick={() => {
                                            props.history.push('forgot-password');
                                        }}
                                    >
                                        Forgot Password?
                                    </button>
                                </div>

                                <div className="d-flex flex-row justify-content-center">
                                    <button
                                        type="submit"
                                        id="login"
                                        className="btn btn-primary btn-block"
                                        onClick={() => {
                                            // props.auth.updateToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4MDg4MjkiLCJuYW1lIjoiVHVzaGFyIiwiaWF0IjoxNjE5MjQ0MDAwfQ.39emaBEl06OxhpA2bWISdIArcs63IBTPf57ywN5W6zs")
                                        }}
                                    >
                                        Login
                                        <span id="login-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var Login = null;

    if (
        state.AdminLoginReducer != null &&
        Object.keys(state.AdminLoginReducer).length > 0 &&
        state.AdminLoginReducer.constructor === Object
    ) {
        switch (state.AdminLoginReducer.tag) {
            case ACTION_ADMINLOGIN_SUCCESS:
                Login = Object.assign({}, state.AdminLoginReducer.data);
                delete state.AdminLoginReducer;
                return { Login };
            default:
                return {
                    Login
                }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAdminLogin }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Login));
