import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label";

const ContactDetails = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

    const initialRender = () => {

        $("#save-contact").prop("disabled", false);

        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            $("#contact-form-loader").addClass("hidden");
            reset({
                contact_name: '',
                contact_designation: '',
                contact_phone: '',
                contact_email: ''
            });

            if (props.data != null) {
                if (props.contact === true) {
                    setValue('contact_name', props.data != null && props?.data?.contact_name != null ? props.data.contact_name : '');
                    setValue('contact_designation', props.data != null && props?.data?.contact_designation != null ? props.data.contact_designation : '');
                    setValue('contact_phone', props.data != null && props?.data?.contact_phone != null ? props.data.contact_phone : '');
                    setValue('contact_email', props.data != null && props?.data?.contact_email != null ? props.data.contact_email : '');
                }
            }

            if (props.isRi === true) {
                setValue('department', props.data != null ? props.data.department : '');
                setValue('office_number', props.data != null ? props.data.office_number : '');
            }

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            $("#contact-form-loader").addClass("hidden");

            setValue('contact_name', '');
            setValue('contact_designation', '');
            setValue('contact_phone', '');
            setValue('contact_email', '');

            if (props.isRi === true) {
                setValue('department', '');
                setValue('office_number', '');
            }
        }
    };
    useEffect(initialRender, [props.show]);

    const onSubmit = (data) => {

        var newContact = {};

        if (props.data != null && props.data.hasOwnProperty('id')) {
            newContact.id = props.data.id;
        }
        newContact.contact_name = data.contact_name;
        newContact.contact_phone = data.contact_phone;
        newContact.contact_designation = data.contact_designation;
        newContact.contact_email = data.contact_email;

        if (props.isRi === true) {
            newContact.department = data.department;
            newContact.office_number = data.office_number;
        }

        $("#contact-form-loader").removeClass("hidden");
        $("#save-contact").prop("disabled", true);
        props.handleClose(newContact);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Add Contact Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Name&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_name"
                                                        name="contact_name"
                                                        placeholder="Enter Contact Name"
                                                        {...register('contact_name', {
                                                            required: 'Contact Name is required',
                                                            pattern: {
                                                                value: /^(?!^ ?[A-Za-z] ?$)([A-Za-z]+( [A-Za-z]+)*)?$/,
                                                                message: 'Please Enter Valid Name'
                                                            },
                                                        })}
                                                    />
                                                    {errors.contact_name && <ErrorLabel message={errors.contact_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Designation
                                                        &nbsp;
                                                        {
                                                            props.isRi === true ?
                                                                <>
                                                                    <span className="danger">
                                                                        *
                                                                    </span>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_designation"
                                                        name="contact_designation"
                                                        placeholder="Enter Contact Designation"
                                                        {...register('contact_designation', { required: props.isRi ? 'Contact Designation is required' : false })}
                                                    />
                                                    {errors.contact_designation && <ErrorLabel message={errors.contact_designation?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        {
                                                            props.isRi === true ?
                                                                <>
                                                                    Mobile Number &nbsp;<span className="danger">*</span>
                                                                </>
                                                                :
                                                                <>
                                                                    Contact Phone
                                                                </>
                                                        }
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_phone"
                                                        name="contact_phone"
                                                        maxLength={10}
                                                        placeholder="Enter Number"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register('contact_phone', {
                                                            required: props.isRi ? 'Mobile Number is required' : false,
                                                            pattern: {
                                                                value: /^[6789]\d{9}$/,
                                                                message: 'Please enter 10 digit valid Mobile Number'
                                                            },
                                                        })}
                                                    />
                                                    {errors.contact_phone && <ErrorLabel message={errors.contact_phone?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Email
                                                        &nbsp;
                                                        {
                                                            props.isRi === true ?
                                                                <>
                                                                    <span className="danger">
                                                                        *
                                                                    </span>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_email"
                                                        name="contact_email"
                                                        placeholder="Enter Contact Email"
                                                        {...register('contact_email', {
                                                            required: props.isRi ? 'Contact Email is required' : false,
                                                            pattern: {
                                                                value: /^(?!.*@.*pincinsure)[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/,
                                                                message: 'Please enter valid Email Id'
                                                            },
                                                        })}
                                                    />
                                                    {errors.contact_email && <ErrorLabel message={errors.contact_email?.message} />}
                                                </div>
                                            </div>
                                            {
                                                props.isRi === true ?
                                                    <>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="label-control">
                                                                    Department&nbsp;
                                                                    <span className="danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    id="department"
                                                                    name="department"
                                                                    placeholder="Enter Department"
                                                                    {...register('department', { required: props.isRi ? 'Department is required' : false })}
                                                                />
                                                                {errors.department && <ErrorLabel message={errors.department?.message} />}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="label-control">
                                                                    Phone Number
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    id="office_number"
                                                                    name="office_number"
                                                                    placeholder="Enter Phone Number"
                                                                    {...register('office_number')}
                                                                />
                                                                {errors.office_number && <ErrorLabel message={errors.office_number?.message} />}
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="save-contact"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="contact-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>

                                        <button
                                            id="cancel-contact"
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            type="button"
                                            onClick={(event) => {
                                                props.handleClose();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactDetails;
