import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_PINC_ADD } from '../../../../../../../api/constants';
import { API_POLICY_DETAILS } from '../../../../../../../api/constants';
import { API_CLAIMS_POLICY_TYPE } from '../../../../../../../api/constants';
import { API_NATURE_OF_LOSS } from '../../../../../../../api/constants';
import { API_CLAIM_TYPE } from '../../../../../../../api/constants';
import { API_CLAIM_STATUS } from '../../../../../../../api/constants';
import { API_CLAIM_HANDLER } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_PINC_ADD_LOADING = 'ACTION_PINC_ADD_LOADING';
export const ACTION_PINC_ADD_SUCCESS = 'ACTION_PINC_ADD_SUCCESS';

export const ACTION_POLICY_DETAILS_LOADING = 'ACTION_POLICY_DETAILS_LOADING';
export const ACTION_POLICY_DETAILS_SUCCESS = 'ACTION_POLICY_DETAILS_SUCCESS';

export const ACTION_CLAIMS_POLICY_TYPE_LOADING = 'ACTION_CLAIMS_POLICY_TYPE_LOADING';
export const ACTION_CLAIMS_POLICY_TYPE_SUCCESS = 'ACTION_CLAIMS_POLICY_TYPE_SUCCESS';

export const ACTION_NATURE_OF_LOSS_LOADING = 'ACTION_NATURE_OF_LOSS_LOADING';
export const ACTION_NATURE_OF_LOSS_SUCCESS = 'ACTION_NATURE_OF_LOSS_SUCCESS';

export const ACTION_CLAIM_TYPE_LOADING = 'ACTION_CLAIM_TYPE_LOADING';
export const ACTION_CLAIM_TYPE_SUCCESS = 'ACTION_CLAIM_TYPE_SUCCESS';

export const ACTION_CLAIM_STATUS_LOADING = 'ACTION_CLAIM_STATUS_LOADING';
export const ACTION_CLAIM_STATUS_SUCCESS = 'ACTION_CLAIM_STATUS_SUCCESS';

export const ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_LOADING = 'ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_LOADING';
export const ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS = 'ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS';

export function actionPincAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_PINC_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PINC_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PINC_ADD_LOADING, { loading: false, data }));
        });
}

export function actionClaimPolicyDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_POLICY_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_POLICY_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_POLICY_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionClaimPolicyType() {
    return (dispatch, getState) =>
        Api.get(API_CLAIMS_POLICY_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionNatureOfLoss() {
    return (dispatch, getState) =>
        Api.get(API_NATURE_OF_LOSS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_NATURE_OF_LOSS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_NATURE_OF_LOSS_LOADING, { loading: false, data }));
        });
}

export function actionClaimType() {
    return (dispatch, getState) =>
        Api.get(API_CLAIM_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionClaimStatus() {
    return (dispatch, getState) =>
        Api.get(API_CLAIM_STATUS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_STATUS_LOADING, { loading: false, data }));
        });
}
export function actionClaimHandler(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIM_HANDLER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_LOADING, { loading: false, data }));
        });
}