import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_CLIENTTAPPROFILEDETAILS } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const CLIENTTAPPROFILEDETAILS_LOADING = 'CLIENTTAPPROFILEDETAILS_LOADING';
export const ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS = 'ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS';

export function actionClientTapProfileDetails(params) {
        return (dispatch, getState) => Api.post(API_CLIENTTAPPROFILEDETAILS, params).then((res) => {
            const data = res;
            PRINT('CLIENTTAPPROFILEDETAILS ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(CLIENTTAPPROFILEDETAILS_LOADING, { loading: false, data }));
        });
    }
