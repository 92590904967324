import Api from "../../../../../../api";
import { API_MASTER_TRAINING, API_TRAINING_DATE, API_TRAINING_PARTICIPATION_REPORT } from '../../../../../../api/constants'
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { PRINT } from "../../../../../../utils";

export const ACTION_TRAINING_TYPE_SUCCESS = 'ACTION_TRAINING_TYPE_SUCCESS';
export const ACTION_TRAINING_TYPE_LOADING = 'ACTION_TRAINING_TYPE_LOADING';

export const ACTION_TRAINING_DATE_SUCCESS = 'ACTION_TRAINING_DATE_SUCCESS';
export const ACTION_TRAINING_DATE_LOADING = 'ACTION_TRAINING_DATE_LOADING';

export const API_PARTICIPATION_REPORT_SUCCESS = 'API_PARTICIPATION_REPORT_SUCCESS'
export const API_PARTICIPATION_REPORT_LOADING = 'API_PARTICIPATION_REPORT_LOADING'

export function actionGetTrainingType(params) {
    return (dispatch, getState) => {
        Api.post(API_MASTER_TRAINING, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_TRAINING_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAINING_TYPE_LOADING, { loading: false, data }))
        })
    };
}

export function actionGetTrainingDate(params) {
    return (dispatch, getState) => {
        Api.post(API_TRAINING_DATE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_TRAINING_DATE_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAINING_DATE_LOADING, { loading: false, data }))
        })
    };
}

export function actionGetParticipationDetails(params) {
    return (dispatch, getState) => {
        Api.post(API_TRAINING_PARTICIPATION_REPORT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(API_PARTICIPATION_REPORT_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(API_PARTICIPATION_REPORT_LOADING, { loading: false, data }))
        })
    };
}
