import { ACTION_PINC_ADD_SUCCESS } from "./action";
import { ACTION_POLICY_DETAILS_SUCCESS } from "./action";
import { ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from "./action";
import { ACTION_NATURE_OF_LOSS_SUCCESS } from "./action";
import { ACTION_CLAIM_TYPE_SUCCESS } from "./action";
import { ACTION_CLAIM_STATUS_SUCCESS } from "./action";
import { ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS } from "./action"

import createReducer from "../../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const PincAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_PINC_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PINC_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POLICY_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POLICY_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_NATURE_OF_LOSS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_NATURE_OF_LOSS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIM_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIM_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIM_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIM_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS,
                loading: false,
            }
        );
    },
});

export default PincAddReducer;