import React from 'react';
import { Route, Switch } from 'react-router';
import GeneralPlacementList from './list';
import GeneralPlacementAdd from './add';
import GeneralPlacementEdit from './edit';

const PlacementGeneralRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/edit`} component={GeneralPlacementEdit} />
                <Route path={`${match.url}/add`} component={GeneralPlacementAdd} />
                <Route path={`${match.url}`} component={GeneralPlacementList} />
            </Switch>
        </>
    )
}

export default PlacementGeneralRoute