import createReducer from "../../../../../reducers/createReducer";
import { ACTION_API_MY_LEAD_SUCCESS } from './action';
import { ACTION_API_MY_TEAM_LEAD_SUCCESS, ACTION_LEAD_TEAM_VERTICAL_SUCCESS } from './action';
import { ACTION_API_OPEN_LEAD_SUCCESS } from './action';
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeadReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_MY_LEAD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MY_LEAD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_MY_TEAM_LEAD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MY_TEAM_LEAD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_OPEN_LEAD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_OPEN_LEAD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_LEAD_TEAM_VERTICAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LEAD_TEAM_VERTICAL_SUCCESS,
                loading: false,
            }
        );
    }
});
export default LeadReducer;
