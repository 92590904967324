import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_NATURE_EXPENCES_EDIT } from '../../../../../../api/constants';
import { API_NATURE_EXPENCES_VIEW } from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';

export const ACTION_NATURE_EXPENCES_EDIT_LOADING = 'ACTION_NATURE_EXPENCES_EDIT_LOADING';
export const ACTION_NATURE_EXPENCES_EDIT_SUCCESS = 'ACTION_NATURE_EXPENCES_EDIT_SUCCESS';

export const ACTION_NATURE_EXPENCES_VIEW_LOADING = 'ACTION_NATURE_EXPENCES_VIEW_LOADING';
export const ACTION_NATURE_EXPENCES_VIEW_SUCCESS = 'ACTION_NATURE_EXPENCES_VIEW_SUCCESS';

export function actionNatureExpencesEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_NATURE_EXPENCES_EDIT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_NATURE_EXPENCES_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_NATURE_EXPENCES_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionNatureExpencesView(params) {
    return (dispatch, getState) =>
        Api.post(API_NATURE_EXPENCES_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_NATURE_EXPENCES_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_NATURE_EXPENCES_VIEW_LOADING, { loading: false, data }));
        });
}