import createReducer from "../../reducers/createReducer";
import {ACTION_LOG_OUT_SUCCESS} from './action'
import {ACTION_HEADER_USER_NOTIFICATION_SUCCESS} from './action'

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LogOutReducer = createReducer(INITIAL_STATE, {
    [ACTION_LOG_OUT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LOG_OUT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_HEADER_USER_NOTIFICATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_HEADER_USER_NOTIFICATION_SUCCESS,
                loading: false,
            }
        );
    },
});
export default LogOutReducer;