import { ACTION_LATEST_FIVE_CLIENTS_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const DashboardLatestFiveClientReducer = createReducer(INITIAL_STATE, {
    [ACTION_LATEST_FIVE_CLIENTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LATEST_FIVE_CLIENTS_SUCCESS,
                loading: false,
            }
        );
    },
});
export default DashboardLatestFiveClientReducer;