import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, getData, removeData } from "../../../../../../utils/preferences";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import dateFormat from 'dateformat';
import { actionSettingItDeclarationList, ACTION_IT_DECLARATION_SETTINGS_SUCCESS } from './action'
import { verifyPermission } from '../../../../../../utils';
import moment from 'moment';

const DeclarationSettingList = (props) => {

    const { match } = props;

    const [declarationSettingsList, setDeclarationSettingsList] = useState([]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionSettingItDeclarationList()
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
    }
    useEffect(initialRender, []);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "type", caption: "Type of Window", visible: true },
        { dataField: "fy", caption: "FY Year", visible: true },
        { dataField: "start_date", caption: "Start Date", visible: false },
        { dataField: "end_date", caption: "End Date", visible: false },
        { dataField: "start_date_formated", caption: "Start Date", visible: true },
        { dataField: "end_date_formated", caption: "End Date", visible: true },
        { dataField: "window_period", caption: "Window Period", visible: true },
        { dataField: 'updated_at', caption: "Last Modified Date", visible: true },
    ]);

    const renderStartDate = (cell) => {
        return <div>{cell?.data?.start_date_formated != null ? moment(cell?.data?.start_date_formated).format('DD-MMM-YYYY') : ''}</div>
    }

    const renderEndDate = (cell) => {
        return <div>{cell?.data?.end_date_formated != null ? moment(cell?.data?.end_date_formated).format('DD-MMM-YYYY') : ''}</div>
    }

    const renderUpdatedAtDate = (cell) => {
        return <div>{cell.data.updated_at != null && moment(cell.data.updated_at).isValid() ? moment(cell.data.updated_at).format('DD-MMM-YYYY') : ''}</div>
    }

    const renderWindow = (cell) =>
        (cell.data.window_period) != null ? <>{`${cell.data.window_period} days`}</> : <>-</>

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("IT_DECLARATION_SETTING_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id, detail: params.data } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "updated_at") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderUpdatedAtDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "window_period") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderWindow}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "start_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStartDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "end_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEndDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }


        });
        return arrColumns;
    };

    const onDeclarationSettingsListUpdate = () => {
        const { DeclarationSettingsList } = props;
        var DeclarationSettingsListResponse = Object.assign({}, DeclarationSettingsList);
        if (DeclarationSettingsListResponse.result === true) {
            setDeclarationSettingsList(DeclarationSettingsListResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            switch (DeclarationSettingsListResponse.status) {
                case ERROR:
                    $("#progress-loader").addClass("hidden");
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onDeclarationSettingsListUpdate, [props.DeclarationSettingsList]);

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <h3 className="content-header-title text-capitalize mb-0 ">Declaration</h3>
                </div>
                {
                    verifyPermission("IT_DECLARATION_SETTING_ADD") ?
                        <div className="content-header-right col-12 col-md-6 col-lg-6">
                            <ul class="nav nav-pills float-right">
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push(`${match.url}/add`);
                                        }}
                                    >
                                        Add Window
                                    </button>
                                </li>
                            </ul>
                        </div>
                        :
                        <></>
                }
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={declarationSettingsList}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "declarationSettingsList",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("start_date_formated", "visible", false)
                                    event.component.columnOption("start_date", "visible", true)
                                    event.component.columnOption("end_date_formated", "visible", false)
                                    event.component.columnOption("end_date", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("start_date_formated", "visible", true)
                                    event.component.columnOption("start_date", "visible", false)
                                    event.component.columnOption("end_date_formated", "visible", true)
                                    event.component.columnOption("end_date", "visible", false)
                                    event.component.endUpdate();
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var DeclarationSettingsList = null;

    if (
        state.ItDeclarationSettingListReducer != null &&
        Object.keys(state.ItDeclarationSettingListReducer).length > 0 &&
        state.ItDeclarationSettingListReducer.constructor === Object
    ) {
        switch (state.ItDeclarationSettingListReducer.tag) {
            case ACTION_IT_DECLARATION_SETTINGS_SUCCESS:
                DeclarationSettingsList = Object.assign({}, state.ItDeclarationSettingListReducer.data);
                delete state.ItDeclarationSettingListReducer;
                return { DeclarationSettingsList };
            default:
        }
    }

    return {
        DeclarationSettingsList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionSettingItDeclarationList }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DeclarationSettingList))
