import moment from "moment";
import ScreenTitle from '../../../../../../component/screen_title';
import { GrPlan } from "react-icons/gr"
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { FiUser } from "react-icons/fi";
import { KEY_TOAST_MESSAGE, setData } from "../../../../../../utils/preferences";
import { actionItDeclarationPoolEdit, ACTION_IT_DECLARATION_POOL_EDIT_SUCCESS } from "./action";
import { actionItDeclarationPoolDetail, ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS } from "./action";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import Tippy from '@tippyjs/react';

const DeclarationPoolEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
    });

    const [selectRequest, setSelectRequest] = useState(null);
    const [declarationPoolDetails, SetDeclarationPoolDetails] = useState()
    const tooltipSection80C = "Maximum eligible limit INR 150,000 (1.5 Lakhs).";
    const tooltipSection80CCD = "Maximum eligible limit INR 50,000 (50K).";
    const tooltipSection80D = "Eligibility INR 25000 for Family for Emp <60 Years, 50000 for Emp > 60 Years, 50000 for Dependent Parent.";
    const tooltipOthers = "INR 200,000 (2 lakhs) maximum eligibility for Interest Paid on Housing Loan & Loss from House Property.";
    const tooltipFlexible = "(Up to max of Rs 50,000 annually - TWICE in a BLOCK OF FOUR YEARS)";

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionItDeclarationPoolDetail({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onItDeclarationPoolDetailUpdate = () => {
        const { ItDeclarationPoolDetail } = props;
        var ItDeclarationPoolDetailResponse = Object.assign({}, ItDeclarationPoolDetail);
        if (ItDeclarationPoolDetailResponse.result === true) {
            SetDeclarationPoolDetails(ItDeclarationPoolDetailResponse.response);
            $("#progress-loader").addClass("hidden");
            if ((ItDeclarationPoolDetailResponse?.response?.finance_remark != null && ItDeclarationPoolDetailResponse?.response?.finance_remark != '') || ItDeclarationPoolDetailResponse?.response?.status === 'ACCEPTED') {
                setValue('status', ItDeclarationPoolDetailResponse?.response?.status)
            }
            setValue('finance_remark', ItDeclarationPoolDetailResponse?.response?.finance_remark)
        } else {
            switch (ItDeclarationPoolDetailResponse.status) {
                case ERROR:
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onItDeclarationPoolDetailUpdate, [props.ItDeclarationPoolDetail]);

    const onDeclarationPoolEditUpdate = () => {
        const { DeclarationPoolEdit } = props;
        var DeclarationPoolEditResponse = Object.assign({}, DeclarationPoolEdit);
        if (DeclarationPoolEditResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            $("#edit-declaration-pool").prop("disabled", false);
            $("#edit-declaration-pool-loader").addClass("hidden");
            props.history.goBack();
            setData(KEY_TOAST_MESSAGE, DeclarationPoolEditResponse?.message);
        } else {
            $("#progress-loader").addClass("hidden");
            $("#edit-declaration-pool").prop("disabled", false);
            $("#edit-declaration-pool-loader").addClass("hidden");
            switch (DeclarationPoolEditResponse.status) {
                case ERROR:
                    toast.error(DeclarationPoolEditResponse.message);
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onDeclarationPoolEditUpdate, [props.DeclarationPoolEdit]);

    const onSubmit = (data) => {
        $("#progress-loader").removeClass("hidden");
        $("#edit-declaration-pool").prop("disabled", true);
        $("#edit-declaration-pool-loader").removeClass("hidden");
        data.id = declarationPoolDetails?.id;
        props.actionItDeclarationPoolEdit(data)
    }

    return (
        <>
            <section>
                <ScreenTitle title="Declaration Details" />
                <div className='card'>
                    <div className='card-body'>
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Employee Details
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <label className="label-control">Employee Name</label>
                                        <input
                                            className="form-control"
                                            id="employee_name"
                                            name="employee_name"
                                            disabled={true}
                                            value={declarationPoolDetails != null ? declarationPoolDetails?.full_name : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <label className="label-control">Employee Id</label>
                                        <input
                                            className="form-control"
                                            id="employee_id"
                                            name="employee_id"
                                            disabled={true}
                                            value={declarationPoolDetails != null ? declarationPoolDetails?.pinc_employee_id : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <label className="label-control">Payroll Entity</label>
                                        <input
                                            className="form-control"
                                            id="payroll_entity"
                                            name="payroll_entity"
                                            disabled={true}
                                            value={declarationPoolDetails != null ? declarationPoolDetails?.payroll_entity : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <label className="label-control">Primary Department</label>
                                        <input
                                            className="form-control"
                                            id="primary_department"
                                            name="primary_department"
                                            disabled={true}
                                            value={declarationPoolDetails != null ? declarationPoolDetails?.primary_department : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <label className="label-control">Joining Date</label>
                                        <input
                                            className="form-control"
                                            id="joining_date"
                                            name="joining_date"
                                            disabled={true}
                                            value={declarationPoolDetails != null ? moment(declarationPoolDetails?.joining_date).format("DD-MMM-YYYY") : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <label className="label-control">Employee Mobile</label>
                                        <input
                                            className="form-control"
                                            id="employee_mobile"
                                            name="employee_mobile"
                                            disabled={true}
                                            value={declarationPoolDetails != null ? declarationPoolDetails?.mobile_no : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <label className="label-control">Reporting Manager</label>
                                        <input
                                            className="form-control"
                                            id="reporting_manager"
                                            name="reporting_manager"
                                            disabled={true}
                                            value={declarationPoolDetails != null ? declarationPoolDetails?.reporting_to_full_name : '-'}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">TAX REGIME&nbsp;<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                id="tax_regime"
                                                name="tax_regime"
                                                placeholder="Tax Regime"
                                                disabled={true}
                                                value={declarationPoolDetails != null ? declarationPoolDetails?.regime : '-'}
                                            />
                                        </div>
                                    </div>
                                    {
                                        declarationPoolDetails != null && declarationPoolDetails?.regime === "OLD" ?
                                            <>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 C&nbsp;
                                                        <Tippy content={tooltipSection80C}>
                                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                        </Tippy>
                                                    </h4>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <label className="label-control">Type of Investment</label>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Life Insurance Premium / Pension Schemes</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.life_insurance_premium_or_pension_schemes : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Public Provident Fund (PPF)</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.ppf : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">ULIP / ELSS / Tax saving Mutual Funds</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">NSC</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.nsc : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Repayment of Housing Loan (Principal Amount)</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.repayment_of_housing_loan : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Tuition Fees for Children (Max for 2 children)</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.tuition_fees_for_children : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">5 year Fixed Deposits (with specified banks)</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.five_year_fd : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Others</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.others : '-'}
                                                                    {...register('amount')}
                                                                />
                                                                {errors.amount && <ErrorLabel message={errors.amount?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Grand Total</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="grand_total"
                                                                    name="grand_total"
                                                                    type="number"
                                                                    placeholder="Enter Grand Total"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80c?.grand_total : '-'}
                                                                    {...register('grand_total')}
                                                                />
                                                                {errors.grand_total && <ErrorLabel message={errors.grand_total?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <GrPlan size={16} />&nbsp;EPF Contribution
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <label className="label-control">PF Contribution&nbsp;<span className="danger">*</span></label>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        id="pf_contribution"
                                                        name="pf_contribution"
                                                        disabled={true}
                                                        value={declarationPoolDetails != null ? declarationPoolDetails?.pf_contribution : '-'}
                                                        {...register('pf_contribution')}
                                                    />
                                                    {errors.pf_contribution && <ErrorLabel message={errors.pf_contribution?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        declarationPoolDetails != null && declarationPoolDetails?.regime === "OLD" ?
                                            <>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 CCD (1B)&nbsp;
                                                        <Tippy content={tooltipSection80CCD}>
                                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                        </Tippy>
                                                    </h4>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">National Pension Scheme&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="national_pension_scheme"
                                                                    name="national_pension_scheme"
                                                                    type="text"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80ccd?.nps : '-'}
                                                                    placeholder="Enter National Pension Scheme"
                                                                    {...register('national_pension_scheme')}
                                                                />
                                                                {errors.national_pension_scheme && <ErrorLabel message={errors.national_pension_scheme?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 D&nbsp;
                                                        <Tippy content={tooltipSection80D}>
                                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                        </Tippy>
                                                    </h4>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Family (Self, Spouse & Children)&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="family"
                                                                    name="family"
                                                                    type="text"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80d?.family_mediclaim : '-'}
                                                                    {...register('family')}
                                                                />
                                                                {errors.family && <ErrorLabel message={errors.family?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Parents&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="parents"
                                                                    name="parents"
                                                                    type="text"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80d?.parents_mediclaim : '-'}
                                                                    {...register('parents')}
                                                                />
                                                                {errors.parents && <ErrorLabel message={errors.parents?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Preventive Health Check up&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="preventive_health_check_up"
                                                                    name="preventive_health_check_up"
                                                                    type="text"
                                                                    placeholder="Enter Preventive Health Check up"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80d?.preventive_health_check_up : '-'}
                                                                    {...register('preventive_health_check_up')}
                                                                />
                                                                {errors.preventive_health_check_up && <ErrorLabel message={errors.preventive_health_check_up?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 E
                                                    </h4>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Interest Paid on Loan for Self-Education&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="interest_paid_loan_for_self_education"
                                                                    name="interest_paid_loan_for_self_education"
                                                                    type="text"
                                                                    placeholder="Enter Interest Paid on Loan for Self-Education"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_80e?.interest_paid_on_loan_for_self_education : '-'}
                                                                    {...register('interest_paid_loan_for_self_education')}
                                                                />
                                                                {errors.interest_paid_loan_for_self_education && <ErrorLabel message={errors.interest_paid_loan_for_self_education?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <GrPlan size={16} />&nbsp;Other Declarations&nbsp;
                                                        <Tippy content={tooltipOthers}>
                                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                        </Tippy>
                                                    </h4>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Interest Paid on Housing Loan&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="interest_paid_housing_loan"
                                                                    name="interest_paid_housing_loan"
                                                                    type="text"
                                                                    placeholder="Enter Interest Paid on Housing Loan"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_other?.interest_paid_on_housing_loan : '-'}
                                                                    {...register('interest_paid_housing_loan')}
                                                                />
                                                                {errors.interest_paid_housing_loan && <ErrorLabel message={errors.interest_paid_housing_loan?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Loss from House Property&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="loss_from_house_property"
                                                                    name="loss_from_house_property"
                                                                    type="text"
                                                                    placeholder="Enter Loss from House Property"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_other?.loss_from_house_property : '-'}
                                                                    {...register('loss_from_house_property')}
                                                                />
                                                                {errors.loss_from_house_property && <ErrorLabel message={errors.loss_from_house_property?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <label className="label-control">Rent Paid (HRA) Annually&nbsp;<span className="danger">*</span></label>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    id="rent_paid_annually"
                                                                    name="rent_paid_annually"
                                                                    type="text"
                                                                    placeholder="Enter Rent Paid (HRA) Annually"
                                                                    disabled={true}
                                                                    value={declarationPoolDetails != null ? declarationPoolDetails?.declaration_other?.hra : '-'}
                                                                    {...register('rent_paid_annually')}
                                                                />
                                                                {errors.rent_paid_annually && <ErrorLabel message={errors.rent_paid_annually?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <GrPlan size={16} />&nbsp;Flexible Plan Details&nbsp;
                                            <Tippy content={tooltipFlexible}>
                                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                            </Tippy>
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <label className="label-control">LTA&nbsp;<span className="danger">*</span></label>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        id="lta"
                                                        name="lta"
                                                        disabled={true}
                                                        value={declarationPoolDetails != null ? declarationPoolDetails?.lta : '-'}
                                                        {...register('lta')}
                                                    />
                                                    {errors.lta && <ErrorLabel message={errors.lta?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <label className="label-control">HDFC Meal Card&nbsp;<span className="danger">*</span></label>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        id="hdfc_food_card"
                                                        name="hdfc_food_card"
                                                        disabled={true}
                                                        value={declarationPoolDetails != null ? declarationPoolDetails?.hdfc_food_card : '-'}
                                                        {...register('hdfc_food_card')}
                                                    />
                                                    {errors.hdfc_food_card && <ErrorLabel message={errors.hdfc_food_card?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <label className="label-control">Special Allowance&nbsp;<span className="danger">*</span></label>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        id="special_allowance"
                                                        name="special_allowance"
                                                        disabled={true}
                                                        value={declarationPoolDetails != null ? declarationPoolDetails?.special_allowance : '-'}
                                                        {...register('special_allowance')}
                                                    />
                                                    {errors.special_allowance && <ErrorLabel message={errors.special_allowance?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black" />
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <label className="label-control">Remarks</label>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        maxLength={200}
                                                        disabled={true}
                                                        value={declarationPoolDetails != null ? declarationPoolDetails?.emp_remark : '-'}
                                                        placeholder="Enter Remarks"
                                                        {...register('remarks')}
                                                    />
                                                    {errors.remarks && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label className="label-control">Finance Action&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={[
                                                            {
                                                                "title": "Request Changes",
                                                                "value": "REJECTED"
                                                            },
                                                            {
                                                                "title": "Not Required",
                                                                "value": "ACCEPTED"
                                                            }
                                                        ]}
                                                        fields={{ text: 'title', value: 'value' }}
                                                        allowFiltering={false}
                                                        placeholder="Select Finance Action"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData?.value);
                                                                if (event.itemData?.value === 'ACCEPTED') {
                                                                    setValue('finance_remark', '')
                                                                }
                                                                setSelectRequest(event.itemData?.value);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Request'
                                                }}
                                            />
                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                        </div>
                                    </div>
                                    {
                                        selectRequest === 'REJECTED' ?
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        id="finance_remark"
                                                        name="finance_remark"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Finance Remark"
                                                        {...register('finance_remark', {
                                                            required: 'Please Enter Remark'
                                                        })}
                                                    />
                                                    {errors.finance_remark && <ErrorLabel message={errors.finance_remark?.message} />}
                                                </div>
                                            </div>
                                            :
                                            <></>

                                    }
                                </div>
                            </div>
                            <div className="form-actions border-0">
                                <button
                                    id="edit-declaration-pool"
                                    className="btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        Submit
                                        <span id="edit-declaration-pool-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                                <button className="btn btn-outline-secondary shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.goBack();
                                    }}
                                > Back</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var DeclarationPoolEdit = null;
    var ItDeclarationPoolDetail = null;
    if (
        state.ItDeclarationPoolEditReducer != null &&
        Object.keys(state.ItDeclarationPoolEditReducer).length > 0 &&
        state.ItDeclarationPoolEditReducer.constructor === Object
    ) {
        switch (state.ItDeclarationPoolEditReducer.tag) {
            case ACTION_IT_DECLARATION_POOL_EDIT_SUCCESS:
                DeclarationPoolEdit = Object.assign({}, state.ItDeclarationPoolEditReducer.data);
                delete state.ItDeclarationPoolEditReducer;
                return { DeclarationPoolEdit };
            case ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS:
                ItDeclarationPoolDetail = Object.assign({}, state.ItDeclarationPoolEditReducer.data);
                delete state.ItDeclarationPoolEditReducer;
                return { ItDeclarationPoolDetail };
            default:
        }
    }

    return {
        DeclarationPoolEdit,
        ItDeclarationPoolDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItDeclarationPoolEdit, actionItDeclarationPoolDetail }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DeclarationPoolEdit))

