import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm, Controller } from "react-hook-form";
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from '../../../../../../component/form/error_label';
import { actionItSupportAdd, ACTION_IT_SUPPORT_ADD_SUCCESS } from './action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { ERROR, UNAUTHORIZED, characterLimit } from '../../../../../../constants';
import _ from "lodash";

const AddLossSiteVisit = (props) => {

   const { register, handleSubmit, control, watch, formState: { errors } } = useForm();

   const [uploadDocument, setUploadDocument] = useState(null);

   const [files, setFiles] = useState([]);

   // const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

   var watchDescription = watch('description');

   const onItSupportAddUpdate = () => {
      const { ItSupportAdd } = props;
      var ItSupportAddResponse = Object.assign({}, ItSupportAdd);
      $("#it-support-request").prop("disabled", false);
      if (ItSupportAddResponse.result === true) {
         $("#it-support-request-loader").addClass("hidden");
         // $("#progress-loader").addClass("hidden");
         setData(KEY_TOAST_MESSAGE, "It Support Request Added Successfully");
         props.history.goBack();
      } else {
         $("#it-support-request-loader").addClass("hidden");
         // $("#progress-loader").addClass("hidden");
         switch (ItSupportAddResponse.status) {
            case ERROR:
               Swal.fire({
                  title: 'Upload Error',
                  text: ItSupportAddResponse.message,
                  icon: 'error'
               }).then(success => {

               })
               break;
            case UNAUTHORIZED:
               props.auth.logout();
               toast.success("Your Login session has expired, Please try to Login again!");
               break;
            default:
         }
      }
   }
   useEffect(onItSupportAddUpdate, [props.ItSupportAdd]);

   const onSubmit = (data) => {
      data.user_id = props.auth.getUser().id;
      data.type = 'it';

      var sendFiles = [];
      if (files != null && files.length > 0) {
         files.forEach((file, indexFile) => {
            sendFiles.push({ 'name': `file[${indexFile}]`, 'value': file });
         });
      }

      delete data.upload_document;
      // $("#progress-loader").removeClass("hidden");
      $("#it-support-request-loader").removeClass("hidden");
      $("#it-support-request").prop("disabled", true);
      props.actionItSupportAdd(data, sendFiles);
   };

   return (
      <>
         <div>
            <ScreenTitle title="IT Support" />
            <div class="card">
               <div class="card-body">
                  <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                     <div class="form-body">
                        <div class="row">
                           {/* <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 IT Support
                              </h4>
                           </div> */}
                           <div class="col-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                 <label class="label-control">Type of Issue&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="name"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={[
                                             {
                                                'title': 'Software',
                                                'value': 'software'
                                             },
                                             {
                                                'title': 'Hardware',
                                                'value': 'hardware'
                                             },

                                          ]}
                                          id="name"
                                          name="name"
                                          fields={{ text: 'title', value: 'value' }}
                                          allowFiltering={false}
                                          placeholder="Select Type of Issue"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.value)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Type of Issue is required'
                                    }}
                                 />
                                 {errors.name && <ErrorLabel message={errors.name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                 <label class="label-control">Priority&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="priority"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={[
                                             {
                                                'title': 'Low',
                                                'value': 'low'
                                             },
                                             {
                                                'title': 'Medium',
                                                'value': 'medium'
                                             },
                                             {
                                                'title': 'High',
                                                'value': 'high'
                                             },

                                          ]}
                                          id="priority"
                                          name="priority"
                                          fields={{ text: 'title', value: 'value' }}
                                          allowFiltering={false}
                                          placeholder="Select Priority"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.value)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Priority is required'
                                    }}
                                 />
                                 {errors.priority && <ErrorLabel message={errors.priority?.message} />}
                              </div>
                           </div>
                           <div className="col-12 col-md-6 col-lg-6 mt-1">
                              <div className="form-group">
                                 <label className="label-control">Brief Details&nbsp;<span className="danger">*</span></label>
                                 <textarea
                                    className="form-control"
                                    id="description"
                                    name="briefdescription_details"

                                    placeholder="Brief Details"
                                    {...register('description', { required: "Brief Details is Required" })}
                                 />
                                 <div className="d-flex flex-row justify-content-between w-100">
                                    <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                    <div className="flex-column"><small>{watchDescription != null ? watchDescription.length : 0}/{characterLimit}</small></div>
                                 </div>
                                 {errors.description && <ErrorLabel message={errors.description?.message} />}
                              </div>
                           </div>
                           <div className="col-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                 <fieldset class="form-group">
                                    <label for="basicInputFile">Upload Document</label>
                                    <div class="custom-file">
                                       <input
                                          type="file"
                                          class="custom-file-input"
                                          id="upload_document"
                                          name="upload_document"
                                          max={2}
                                          multiple={true}
                                          onChange={(event) => {
                                             setFiles([]);
                                             if (
                                                event.target.files &&
                                                event.target.files.length <= 2
                                             ) {

                                                if ((files.length + event.target.files.length) <= 2) {
                                                   var arrFiles = Object.assign([], files);
                                                   var fileNames = '';
                                                   for (let i = 0; i < event.target.files.length; i++) {
                                                      let file = event.target.files[i];
                                                      arrFiles.push(file);
                                                      fileNames += event.target.files[i].name + ", ";
                                                   }
                                                   setUploadDocument(_.trim(fileNames, ", "));
                                                   setFiles(arrFiles);
                                                } else {
                                                   Swal.fire({
                                                      title: 'Upload Error',
                                                      text: 'Maximum 2 files are allowed.',
                                                      icon: 'error'
                                                   }).then(success => {
                                                   });
                                                }
                                             } else {
                                                Swal.fire({
                                                   title: 'Upload Error',
                                                   text: 'Maximum 2 files are allowed.',
                                                   icon: 'error'
                                                }).then(success => {
                                                });
                                             }
                                          }}
                                       />
                                       <label class="custom-file-label" for="upload_document">
                                          {
                                             uploadDocument != null ?
                                                uploadDocument : "Choose file"
                                          }
                                       </label>
                                    </div>
                                 </fieldset>
                              </div>
                           </div>
                        </div>
                     </div>
                     <button
                        id="it-support-request"
                        className="btn btn-primary mr-1 shadow round text-uppercase"
                        type="submit"
                     >
                        <div className="d-flex align-items-center">
                           Submit
                           <span id="it-support-request-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                        </div>
                     </button>
                     <button class="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                        onClick={() => { props.history.goBack() }}> Cancel
                     </button>
                  </form>
               </div>
            </div>
         </div>
      </>
   )
}


const mapStateToProps = (state) => {
   var ItSupportAdd = null;

   if (
      state.ItSupportAddReducer != null &&
      Object.keys(state.ItSupportAddReducer).length > 0 &&
      state.ItSupportAddReducer.constructor === Object
   ) {
      switch (state.ItSupportAddReducer.tag) {
         case ACTION_IT_SUPPORT_ADD_SUCCESS:
            ItSupportAdd = Object.assign({}, state.ItSupportAddReducer.data);
            delete state.ItSupportAddReducer;
            return { ItSupportAdd };
         default:
      }
   }

   return {
      ItSupportAdd,
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators({ actionItSupportAdd }, dispatch)
}


export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AddLossSiteVisit))

