import React from 'react';
import { Route, Switch } from "react-router";
import AddFunction from "./add_function";
import HRMSBrokerTrainingList from "./list";
import ViewTrainingSetting from './view';
import EditTraining from './edit';

const HRMSSettingsRoute = (props) => {

    const { match } = props

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`} component={AddFunction} />
                <Route path={`${match.url}/view`} component={ViewTrainingSetting} />
                <Route path={`${match.url}/edit`} component={EditTraining} />
                <Route exact path={`${match.url}`} component={HRMSBrokerTrainingList} />
            </Switch>
        </>
    )
}
export default HRMSSettingsRoute