import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_TRANSPORT_LIST } from '../../../../../../api/constants';
import { API_TRANSPORT_STATUS } from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';

export const ACTION_TRANSPORT_LIST_LOADING = 'ACTION_TRANSPORT_LIST_LOADING';
export const ACTION_TRANSPORT_LIST_SUCCESS = 'ACTION_TRANSPORT_LIST_SUCCESS';

export const ACTION_TRANSPORT_STATUS_LOADING = 'ACTION_TRANSPORT_STATUS_LOADING';
export const ACTION_TRANSPORT_STATUS_SUCCESS = 'ACTION_TRANSPORT_STATUS_SUCCESS';

export function actionTransportList() {
    return (dispatch, getState) =>
        Api.get(API_TRANSPORT_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRANSPORT_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRANSPORT_LIST_LOADING, { loading: false, data }));
        });
}
export function actionTransportStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_TRANSPORT_STATUS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRANSPORT_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRANSPORT_STATUS_LOADING, { loading: false, data }));
        });
}