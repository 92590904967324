import React from 'react';
import { Route, Switch } from "react-router";
import RMTransferAdd from './add';
import RMTransferDashboard from './dashboard';

const RMTransferRoute = (props) => {
    const { match } = props

    return (
        <>
            <Switch>
                <Route path={`${match.url}/dashboard`} component={RMTransferDashboard} />
                <Route exact path={`${match.url}`} component={RMTransferAdd} />
            </Switch>
        </>
    )
}
export default RMTransferRoute