import createReducer from "../../../../../reducers/createReducer";
import { ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS, ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS, ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS } from "./action";
import { ACTION_CLIENT_DETAILS_SUCCESS } from "./action";
import { ACTION_CLIENT_EDIT_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_LOADING } from "./action";
import { ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ClientEditCorporateReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_LOADING](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_LOADING,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS,
                loading: false,
            }
        );
    }
});
export default ClientEditCorporateReducer;