import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionGroupLevelOpportunityView, ACTION_API_GROUP_LEVEL_OPPORTUNITY_SUCCESS } from './action';
import { actionGroupLevelOpportunityEdit, ACTION_GROUP_OPPORTUNITY_EDIT_SUCCESS } from './action';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { toast } from 'react-toastify';
import ProposalDetails from '../../../../../../../component/popups/proposal';
import moment from 'moment';
import { withAuth } from 'react-auth-guard';
import PolicyDetailModal from '../../../../../../../component/form/policy_details';
import { verifyPermission } from '../../../../../../../utils';

const GroupLevelProposalsView = (props) => {
    const [proposalsData, setProposalsData] = useState([]);
    const [showProposalDetails, setShowProposalDetails] = useState(false);
    const [proposalColumn, setProposalColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "id", caption: "Opportunity ID" },
        { dataField: "customer_name", caption: "Company Name" },
        { dataField: "policy_type", caption: "Policy Type" },
        { dataField: "expiring_date", caption: "Renewal Date" },
        { dataField: "ps_name", caption: "Product RM" },
        { dataField: "assigned_rm_name", caption: "Sales RM" },
        { dataField: "opportunity_status", caption: "Status" },
    ]);

    const [showProposalDetailsView, setShowProposalDetailsView] = useState(false);
    const [proposalDetails, setProposalDetails] = useState(null);
    const [proposalType, setProposalType] = useState('all');
    const [proposalPoolListCount, setProposalPoolListCount] = useState(0);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGroupLevelOpportunityView({
                client_group_id: Number(props.clientGroupId),
                user_ids: [props.auth.getUser().user_id],
                type: proposalType,
                client_data: "all"
            })
    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupOpportunityUpdate = () => {

        const { GroupOpportunity } = props;

        if (GroupOpportunity != null) {
            var GroupOpportunityResponse = Object.assign({}, GroupOpportunity);
            if (GroupOpportunityResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setProposalColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "id", caption: "Opportunity ID" },
                    { dataField: "customer_name", caption: "Company Name" },
                    { dataField: "policy_type", caption: "Policy Type" },
                    { dataField: "expiring_date", caption: "Renewal Date" },
                    { dataField: "ps_name", caption: "Product RM" },
                    { dataField: "assigned_rm_name", caption: "Sales RM" },
                    { dataField: "opportunity_status", caption: "Status" },
                ])
                setProposalsData(GroupOpportunityResponse.response);
                setProposalPoolListCount(GroupOpportunityResponse.response.length);

            } else {
                $("#progress-loader").addClass("hidden");
                setProposalsData([])
                switch (GroupOpportunityResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGroupOpportunityUpdate, [props.GroupOpportunity]);

    const onGroupOpportunityEditUpdate = () => {

        const { GroupOpportunityEdit } = props;

        if (GroupOpportunityEdit != null) {
            var GroupOpportunityEditResponse = Object.assign({}, GroupOpportunityEdit);
            if (GroupOpportunityEditResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                props.actionGroupLevelOpportunityView({
                    client_group_id: Number(props.clientGroupId),
                    user_ids: [props.auth.getUser().user_id],
                    type: 'all',
                    client_data: "all"
                })
            } else {
                $("#progress-loader").addClass("hidden");
                setProposalsData([])
                switch (GroupOpportunityEditResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGroupOpportunityEditUpdate, [props.GroupOpportunityEdit]);

    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.customer_name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                    if (cell?.data?.client_type === 'lead') {
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                    } else if (cell?.data?.client_type === 'client') {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                    }
                })}
            ><u>{cell?.data?.customer_name}</u></a>
        }
    }

    const renderProposalColumns = () => {
        var arrColumns = [];
        proposalColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1"
                                    onClick={() => {
                                        setShowProposalDetailsView(true)
                                        setProposalDetails(params.data);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                params.data.editable === 'yes' ? (
                                    <button
                                        className="btn btn-icon btn-sm"
                                        onClick={() => {

                                            localStorage.setItem('detailsData', JSON.stringify(params.data));

                                            const url = `/proposal_pool/edit?id=${params.data.id}&type=${proposalType}`;
                                            const win = window.open(url, '_blank');
                                            if (win) {
                                                win.focus();
                                            } else {
                                                console.log('Pop-up blocked. Please allow pop-ups for this site.');
                                            }
                                        }}
                                    >
                                        <FiEdit size={16} className="primary" />
                                    </button>
                                ) : (
                                    <></>
                                )
                            )}
                        />
                    </Column>

                );
            } else {
                if (objColumn.dataField === "opportunity_status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            // cellRender={renderStatusCell}
                            width={148}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "customer_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            width={140}
                            cellRender={renderCompanyNameCell}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "id") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            width={140}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "expiring_date") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            width={125}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "ps_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            width={135}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "assigned_rm_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            width={135}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    const renderProposalsTableView = () => {
        return (
            <DataGrid
                dataSource={proposalsData}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                columnHidingEnabled={true}
                export={{
                    fileName: "Opportunity",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderProposalColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 ">
                                <ul class="nav nav-pills  d-md-flex d-lg-flex mb-1 mb-md-0">
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("all");
                                                setProposalPoolListCount(0)
                                                props.actionGroupLevelOpportunityView({
                                                    client_group_id: Number(props.clientGroupId),
                                                    type: 'all',
                                                    client_data: "all",
                                                    user_ids: [props.auth.getUser().user_id],
                                                });
                                            }}
                                            className={`btn btn-md ${proposalType === 'all' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Total {proposalType === 'all' ? (proposalPoolListCount > 0) ? `(` + proposalPoolListCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("unassigned");
                                                setProposalPoolListCount(0)
                                                props.actionGroupLevelOpportunityView({
                                                    client_group_id: Number(props.clientGroupId),
                                                    type: 'unassigned',
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_data: "all"
                                                });

                                            }}
                                            className={`btn btn-md ${proposalType === 'unassigned' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Unassigned  {proposalType === 'unassigned' ? (proposalPoolListCount > 0) ? `(` + proposalPoolListCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType('assigned');
                                                setProposalPoolListCount(0)
                                                props.actionGroupLevelOpportunityView({
                                                    type: "assigned",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_group_id: Number(props.clientGroupId),
                                                    client_data: "all"
                                                });
                                            }}
                                            className={`btn btn-md ${proposalType === 'assigned' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Assigned {proposalType === 'assigned' ? (proposalPoolListCount > 0) ? `(` + proposalPoolListCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("quote_rejected");
                                                setProposalPoolListCount(0)
                                                props.actionGroupLevelOpportunityView({
                                                    type: "quote_rejected",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_group_id: Number(props.clientGroupId),
                                                    client_data: "all"
                                                });
                                            }}
                                            className={`btn btn-md ${proposalType === 'quote_rejected' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Quotes Rejected {proposalType === 'quote_rejected' ? (proposalPoolListCount > 0) ? `(` + proposalPoolListCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("proposal_success");
                                                setProposalPoolListCount(0)
                                                props.actionGroupLevelOpportunityView({
                                                    type: "proposal_success",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_group_id: Number(props.clientGroupId),
                                                    client_data: "all"
                                                });
                                            }}
                                            className={`btn btn-md ${proposalType === 'proposal_success' && proposalPoolListCount > 0 ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Proposal Success {proposalType === 'proposal_success' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setProposalType("proposal_lost");
                                                setProposalPoolListCount(0)
                                                props.actionGroupLevelOpportunityView({
                                                    type: "proposal_lost",
                                                    user_ids: [props.auth.getUser().user_id],
                                                    client_group_id: Number(props.clientGroupId),
                                                    client_data: "all"
                                                });
                                            }}
                                            className={`btn btn-md ${proposalType === 'proposal_lost' && proposalPoolListCount > 0 ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Proposal Lost {proposalType === 'proposal_lost' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}
                                        </button>
                                    </li>
                                </ul>
                                {
                                    proposalsData != null && proposalsData.length > 0 ?
                                        renderProposalsTableView() :
                                        <h6 className="text-bold-500 text-center">No Opportunity Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProposalDetails
                show={showProposalDetailsView}
                proposalDetails={proposalDetails}
                handleClose={() => {
                    setShowProposalDetailsView(false);
                }}
            />
            <PolicyDetailModal
                show={showProposalDetails}
                handleClose={(newPolicy) => {
                    if (newPolicy != null) {
                        console.log("policy", newPolicy);
                        props.actionGroupLevelOpportunityEdit({
                            id: newPolicy.id,
                            client_id: proposalDetails?.client_id,
                            department_id: newPolicy.department_id,
                            insurer_id: newPolicy.insurer_id,
                            broker: newPolicy.broker,
                            expected_premium: newPolicy.expected_premium,
                            expiring_date: newPolicy?.expiring_date,
                            probability: newPolicy.probability,
                            policy_type_id: newPolicy.policy_type_id,
                            client_remark: newPolicy.client_remark,
                            is_product_specialist_required: newPolicy.is_product_specialist_required,
                            product_specialist_id: newPolicy.product_specialist_id,
                            opportunity_questions: newPolicy.opportunity_questions,
                            oppurtunity_type: newPolicy.oppurtunity_type,
                            intermediary_type: newPolicy.intermediary_type,
                            intermediary_value: newPolicy.intermediary_value,
                            posted_from: "pincnetwork"
                        })
                    }
                    setShowProposalDetails(false)
                }}
                data={proposalDetails}
            />
        </>
    )

}

function mapStateToProps(state) {

    var GroupOpportunity = null;
    var GroupOpportunityEdit = null;

    if (
        state.TabGroupOpportunityReducer != null &&
        Object.keys(state.TabGroupOpportunityReducer).length > 0 &&
        state.TabGroupOpportunityReducer.constructor === Object
    ) {
        switch (state.TabGroupOpportunityReducer.tag) {

            case ACTION_API_GROUP_LEVEL_OPPORTUNITY_SUCCESS:
                GroupOpportunity = Object.assign({}, state.TabGroupOpportunityReducer.data);
                delete state.TabGroupOpportunityReducer;
                return { GroupOpportunity };
            case ACTION_GROUP_OPPORTUNITY_EDIT_SUCCESS:
                GroupOpportunityEdit = Object.assign({}, state.TabGroupOpportunityReducer.data);
                delete state.TabGroupOpportunityReducer;
                return { GroupOpportunityEdit };
            default:
        }
    }
    return { GroupOpportunity, GroupOpportunityEdit }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupLevelOpportunityView, actionGroupLevelOpportunityEdit }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupLevelProposalsView));

