export const HOST =
  process.env.NODE_ENV === "development"
    ? "https://api-crm.staypinc.com"
    : "https://api-crm.staypinc.com";
export const IMAGE_PATH =
  process.env.NODE_ENV === "development"
    ? "https://api-crm.staypinc.com"
    : "https://api-crm.staypinc.com";

const BASE_URL = HOST;
// const BASE_URL = 'http://192.168.141.225:8080/pinc-crm-laravel-apis/public';
// 192.168.1.17

// PREFIX
const PREFIX = "/api";

export const API_ADMINLOGIN = BASE_URL + PREFIX + "/user/login";

export const API_FORGOTPASSWORD = BASE_URL + PREFIX + "/user/password/forgot";
export const API_CHANGEPASSWORD = BASE_URL + PREFIX + "/profile/change-password";
export const API_RESET_PASSWORD = BASE_URL + PREFIX + "/user/password/reset";
export const API_VALIDATE_PASSWORD_TOKEN = BASE_URL + PREFIX + "/user/password/reset/token/verify";

export const API_ALL_MEETINGS = BASE_URL + PREFIX + "/meeting/get";
export const API_CUSTOMER_MEETINGS = BASE_URL + PREFIX + "/meeting/get";
export const API_INSURANCE_COMPANY_MEETINGS = BASE_URL + PREFIX + "/meeting/get";
export const API_INTERNAL_MEETINGS = BASE_URL + PREFIX + "/meeting/get";
export const API_RI_MEETINGS = BASE_URL + PREFIX + "/meeting/get";
export const API_SERVEYOR_MEETINGS = BASE_URL + PREFIX + "/meeting/get";
export const API_CLIENT = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_MY_LEAD = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_MY_TEAM_LEAD = BASE_URL + PREFIX + "/client/my-team-clients-leads";
export const API_OPEN_LEAD = BASE_URL + PREFIX + "/client/my-open-clients-leads";
export const API_RETENTION_CLIENTS_MEETINGS = BASE_URL + PREFIX + "/meeting/get-my-retention-clients-meetings";

export const API_MY_CLIENT = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_MY_TEAM_CLIENT = BASE_URL + PREFIX + "/client/my-team-clients-leads";

export const API_MASTER_COMPANY_GROUP = BASE_URL + PREFIX + "/masters/client-group-of-company";
export const API_MASTER_CUSTOMER_CATEGORY = BASE_URL + PREFIX + "/masters/customer-category";
export const API_MASTER_INDUSTRIAL_SEGMENT = BASE_URL + PREFIX + "/masters/industrial-segment";
export const API_MASTER_FORM_OF_ORGANIZATION = BASE_URL + PREFIX + "/masters/form-of-organization";
export const API_MASTER_LEAD_SOURCE = BASE_URL + PREFIX + "/masters/lead-source";
export const API_MASTER_ASSIGN_RM = BASE_URL + PREFIX + "/masters/assign-RM";

export const API_MASTER_ENTITY = BASE_URL + PREFIX + "/masters/entity";
export const API_MASTER_SUB_ENTITY = BASE_URL + PREFIX + "/masters/sub-entity";
export const API_MASTER_BRANCH_CITY = BASE_URL + PREFIX + "/masters/branch-city";
export const API_MASTER_DEPARTMENT = BASE_URL + PREFIX + "/masters/department";
export const API_MASTER_GRADE = BASE_URL + PREFIX + "/masters/grade";
export const API_MASTER_BRANCH_ADDRESS = BASE_URL + PREFIX + "/masters/branch-address";
export const API_MASTER_GET_REPORTING = BASE_URL + PREFIX + "/masters/reporting-persons";
export const API_MASTER_JOB_ROLE = BASE_URL + PREFIX + "/masters/job-role";
export const API_MASTER_DESIGNATION = BASE_URL + PREFIX + "/masters/grade-designation";
export const API_MASTER_TEAM_HEAD = BASE_URL + PREFIX + "/masters/team-names";
export const API_MASTER_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/approval-managers";
export const API_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/finance-users";

export const API_EMPLOYEE_ADD = BASE_URL + PREFIX + "/employee/add";
export const API_EMPLOYEE_EDIT = BASE_URL + PREFIX + "/employee/edit";
export const API_EMPLOYEE_DETAILS = BASE_URL + PREFIX + "/employee/detail";
/* NOT IN USE */
export const API_MASTER_ASSET_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/asset-approval-manager";
export const API_MASTER_FINANCE_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/finance-approval-manager";
export const API_MASTER_TRAVEL_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/travel-approval-manager";
export const API_MASTER_ATTENDANCE_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/attendance-approval-manager";
export const API_MASTER_MEETING_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/meeting-approval-manager";
export const API_MASTER_PERFORMANCE_OBJECTIVE_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/performance-objective-approval-manager";
/* EOC : NOT IN USE */

export const API_MASTER_STATES = BASE_URL + PREFIX + "/masters/states";
export const API_MASTER_CITIES = BASE_URL + PREFIX + "/masters/cities";

export const API_EMPLOYEE_LIST = BASE_URL + PREFIX + "/employee/getAllEmployee";
export const API_EMPLOYEE_VIEW = BASE_URL + PREFIX + "/employee/viewEmployee";
export const API_EMPLOYEE_STATUS = BASE_URL + PREFIX + "/employee/change-user-status";
export const API_EMPLOYEE_CHANGE_PASSWORD = BASE_URL + PREFIX + "/employee/change-password";

export const API_MEETING_TYPE = BASE_URL + PREFIX + "/masters/meeting-types";
export const API_CUSTOMER = BASE_URL + PREFIX + "/masters/all-lead-client";
export const API_SELECT_CUSTOMER = BASE_URL + PREFIX + "/masters/select-customers";
export const API_BUSINESS_TYPE = BASE_URL + PREFIX + "/masters/business-types";
export const API_PREVIOUS_MEETING = BASE_URL + PREFIX + "/masters/previous-meetings";
export const API_ACCOMPAINED_BY = BASE_URL + PREFIX + "/masters/accompanied-by";
export const API_MASTER_NEW_COMPANY_GROUP = BASE_URL + PREFIX + "/masters/client-group-of-company";
export const API_MASTER_NEW_CUSTOMER_CATEGORY = BASE_URL + PREFIX + "/masters/customer-category";
export const API_MASTER_NEW_INDUSTRIAL_SEGMENT = BASE_URL + PREFIX + "/masters/industrial-segment";
export const API_MASTER_NEW_FORM_OF_ORGANIZATION = BASE_URL + PREFIX + "/masters/form-of-organization";
export const API_MASTER_NEW_LEAD_SOURCE = BASE_URL + PREFIX + "/masters/lead-source";
export const API_MASTER_NEW_ASSIGN_RM = BASE_URL + PREFIX + "/masters/assign-RM";
export const API_MASTER_NEW_STATES = BASE_URL + PREFIX + "/masters/states";
export const API_MASTER_NEW_CITIES = BASE_URL + PREFIX + "/masters/cities";

export const API_MASTER_POLICY = BASE_URL + PREFIX + "/masters/policy/type";
export const API_MASTER_INSURANCE_COMPANY = BASE_URL + PREFIX + "/masters/insurer-companies";
export const API_MASTER_PROBABILITY = BASE_URL + PREFIX + "/masters/probabilities";
export const API_GET_DEPARTMENT = BASE_URL + PREFIX + '/masters/saiba-department';
export const API_GET_BROKER_NAME = BASE_URL + PREFIX + '/masters/broker';

export const API_EDIT_LEAD = BASE_URL + PREFIX + "/client/my-clients";
export const API_CREATE_CORPORATE_CLIENT = BASE_URL + PREFIX + "/client/create";
export const API_CORPORATE_CLIENT_DETAILS = BASE_URL + PREFIX + "/client/detail";
export const API_GETCLIENTMEETINGSDETAIL = BASE_URL + PREFIX + "/client/meetings-by-clientid";
export const API_CLIENTVIEWPROPOSALS = BASE_URL + PREFIX + "/client/leadpolicies-by-clientid";
export const API_CLIENTVIEWPOLICIES = BASE_URL + PREFIX + "/client/policies-by-clientid"

export const API_CORPORATE_CLIENT_EDIT = BASE_URL + PREFIX + "/client/edit";

export const API_INTERNAL_MEETING_ADD = BASE_URL + PREFIX + "/meeting/add/internal";
export const API_INTERNAL_MEETING_EDIT = BASE_URL + PREFIX + "/meeting/edit/internal";
export const API_INTERNAL_MEETING_VIEW = BASE_URL + PREFIX + "/meeting/view/internal";

export const API_MASTER_INSURANCE_COMPANY_NAME = BASE_URL + PREFIX + "/masters/insurer-companies";
export const API_MASTER_STATE_BY_INSURANCE_COMPANY = BASE_URL + PREFIX + "/masters/state-by-insurercompanywise";
export const API_MASTER_CITY_BY_INSURANCE_COMPANY = BASE_URL + PREFIX + "/masters/city-by-insurercompanywise";
export const API_MASTER_BRANCH_BY_INSURANCE_COMPANY = BASE_URL + PREFIX + "/masters/branch-by-insurercompanycitywise";

export const API_INSURANCE_MEETING_ADD = BASE_URL + PREFIX + "/meeting/add/insurance";
export const API_INSURANCE_MEETING_EDIT = BASE_URL + PREFIX + "/meeting/edit/insurance";
export const API_INSURANCE_MEETING_VIEW = BASE_URL + PREFIX + "/meeting/view/insurance";
export const API_TPA_MEETINGS = BASE_URL + PREFIX + "/meeting/get";
export const API_MEETING_LIST_CONTACT = BASE_URL + PREFIX + "/contact/get";
export const API_GET_SERVEYOR_NAME = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_EDIT_GET_SERVEYOR_NAME = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_EDIT_GET_TPA_NAME = BASE_URL + PREFIX + "/client/my-clients-leads";

export const API_CREATE_NEW_TPA = BASE_URL + PREFIX + "/client/create";
export const API_GET_TPA_NAME = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_TPA_MEETING_ADD = BASE_URL + PREFIX + "/meeting/add/surveyor-tpa";
export const API_TPA_MEETING_VIEW = BASE_URL + PREFIX + "/meeting/view/surveyor-tpa";
export const API_TPA_MEETING_EDIT = BASE_URL + PREFIX + "/meeting/edit/surveyor-tpa";
export const API_TPAMEETINGADDCONTACT = BASE_URL + PREFIX + "/contact/add";
export const API_TPAMEETINGEDITCONTACT = BASE_URL + PREFIX + "/contact/edit";

export const API_CREATE_NEW_SURVEYOR = BASE_URL + PREFIX + "/client/create";
export const API_SURVEYOR_MEETING_ADD = BASE_URL + PREFIX + "/meeting/add/surveyor-tpa";
export const API_SURVEYOR_MEETING_VIEW = BASE_URL + PREFIX + "/meeting/view/surveyor-tpa";
export const API_SURVEYOR_MEETING_EDIT = BASE_URL + PREFIX + "/meeting/edit/surveyor-tpa";
export const API_SERVEYORMEETINGADDCONTACT = BASE_URL + PREFIX + "/contact/add";
export const API_EDITSERVEYORMEETINGEDITCONTACT = BASE_URL + PREFIX + "/contact/edit";

export const API_MASTER_NEW_BRANCH_INSURANCE_COMPANY = BASE_URL + PREFIX + "/masters/branch-by-insurercompanywise";
export const API_CUSTOMER_MEETING_CONTACT = BASE_URL + PREFIX + "/contact/get";

export const API_CUSTOMER_MEETING_POLICY = BASE_URL + PREFIX + "/policy/get";

export const API_ADDPOLICY = BASE_URL + PREFIX + "/policy/add";
export const API_EDITPOLICY = BASE_URL + PREFIX + "/policy/edit";
export const API_DELETE_POLICY = BASE_URL + PREFIX + "/policy/delete";

export const API_ADD_NEW_COMPANY = BASE_URL + PREFIX + "/client/create";

export const API_INSURANCE_MEETING_ADD_CONTACT = BASE_URL + PREFIX + "/contact/add";

export const API_PERMISSION_ADD_CREATE = BASE_URL + PREFIX + "/permission/create";
export const API_PERMISSION_EDIT = BASE_URL + PREFIX + "/permission/edit";
export const API_PERMISSION_VIEW = BASE_URL + PREFIX + "/permission/view";
export const API_PERMISSION = BASE_URL + PREFIX + "/permission/get";

export const API_PERMISSION_ROLES_CREATE = BASE_URL + PREFIX + "/permission/create/role";
export const API_PERMISSION_ROLES_EDIT = BASE_URL + PREFIX + "/permission/edit/role";
export const API_PERMISSION_ROLES_VIEW = BASE_URL + PREFIX + "/permission/view/role";
export const API_PERMISSION_ROLES = BASE_URL + PREFIX + "/permission/get/role";
export const API_GETPERMISSION = BASE_URL + PREFIX + "/permission/get-allocated";
export const API_CREATEPERMISSIONSAGAINROLE = BASE_URL + PREFIX + "/permission/create-allocated"

export const API_MODULE_NAME = BASE_URL + PREFIX + "/masters/permission-module-names";

export const API_CUSTOMER_MEETING_ADD = BASE_URL + PREFIX + "/meeting/add/customer";
export const API_CUSTOMER_MEETING_EDIT = BASE_URL + PREFIX + "/meeting/edit/customer";
export const API_CUSTOMER_MEETING_VIEW = BASE_URL + PREFIX + "/meeting/view/customer";
export const API_POPUP_CUSTOMER_MEETING_VIEW = BASE_URL + PREFIX + "/meeting/view/customer";
export const API_CUSTOMER_MEETING_NEXT = BASE_URL + PREFIX + "/meeting/next/customer";

export const API_RI_MEETING_ADD = BASE_URL + PREFIX + "/meeting/add/reinsurance";
export const API_RI_MEETING_VIEW = BASE_URL + PREFIX + "/meeting/view/reinsurance";
export const API_RI_MEETING_EDIT = BASE_URL + PREFIX + "/meeting/edit/reinsurance";
export const API_RI_MEETING_NEXT = BASE_URL + PREFIX + "/meeting/next/customer";

export const API_GETROLESDROPDOWNLIST = BASE_URL + PREFIX + "/permission/get/role/active";

export const API_ADD_FEEDBACK = BASE_URL + PREFIX + "/masters/feedback";

export const API_TEAMVERTICAL = BASE_URL + PREFIX + "/team/myvertical";
export const API_MYTEAMVERTICAL = BASE_URL + PREFIX + "/team/myteam";
export const API_MYREPORTINGVERTICAL = BASE_URL + PREFIX + "/team/myreporting";
export const API_MYVERTICAL = BASE_URL + PREFIX + "/team/myvertical"

export const API_GET_SEARCH_LIST = BASE_URL + PREFIX + "/masters/get360list";

export const API_INSURER_VIEW = BASE_URL + PREFIX + "/insurer/view";

export const API_MY_PROFILE = BASE_URL + PREFIX + "/myprofile/get"
export const API_ADD_EDUCATION = BASE_URL + PREFIX + "/myprofile/addeducation";
export const API_EDIT_EDUCATION = BASE_URL + PREFIX + "/myprofile/editeducation";
export const API_DELETE_EMPLOYEE_EDUCATION = BASE_URL + PREFIX + "/myprofile/deleteeducation"
export const API_GET_EDUCATION_DETAIL = BASE_URL + PREFIX + "/myprofile/geteducation"
export const API_ADD_EMPLOYMENT = BASE_URL + PREFIX + "/myprofile/addemployment";
export const API_EDIT_EMPLOYMENT = BASE_URL + PREFIX + "/myprofile/editemployment";
export const API_DELETE_EMPLOYEE_EMPLOYMENT = BASE_URL + PREFIX + "/myprofile/deleteemployment";
export const API_GET_EMPLOYMENT_DETAIL = BASE_URL + PREFIX + "/myprofile/getemployment"
export const API_EDIT_PERSONAL_INFORMATION = BASE_URL + PREFIX + "/myprofile/edit";

export const API_CLIENTLEADLIST = BASE_URL + PREFIX + "/client/my-team-clients-leads";
export const API_LEADLIST = BASE_URL + PREFIX + "/client/my-team-clients-leads";
export const API_GETMEETINGLIST = BASE_URL + PREFIX + "/meeting/get";

/** POST API */
export const API_CREATE_POST = BASE_URL + PREFIX + "/post/create";
export const API_GET_POST_WITH_COMMENTS = BASE_URL + PREFIX + "/post/get";
export const API_POST_DELETE = BASE_URL + PREFIX + "/post/delete";
export const API_POST_LIKE_DISLIKE = BASE_URL + PREFIX + "/post/like";
export const API_POST_CREATE_COMMENT = BASE_URL + PREFIX + "/comment/create";
export const API_POST_COMMENT_LIKE = BASE_URL + PREFIX + "/comment/like"
export const API_POST_COMMENT_DELETE = BASE_URL + PREFIX + "/comment/delete"
export const API_POST_COMMENT_EDIT = BASE_URL + PREFIX + "/comment/edit"

export const API_CLIENTTAPPROFILEDETAILS = BASE_URL + PREFIX + "/client/detail";
export const API_CLIENTTABPROPOSALSVIEW = BASE_URL + PREFIX + "/client/leadpolicies-by-clientid";
export const API_CLIENTTABMEETINGSVIEW = BASE_URL + PREFIX + "/client/meetings-by-clientid";
export const API_MY_TEAM_MEETING = BASE_URL + PREFIX + "/meeting/get-myprofile";

/** POLICIES */

export const API_CLIENT_POLICIES = BASE_URL + PREFIX + "/client/policies-by-clientid";
export const API_POLICIES = BASE_URL + PREFIX + "/policy/list";
export const API_POLICY_DETAILS = BASE_URL + PREFIX + "/client/policy-details";
export const API_ENDORSEMENT_DETAILS = BASE_URL + PREFIX + "/client/endorsement-by-policyid";
export const API_CLAIMS_DETAILS = BASE_URL + PREFIX + "/client/claims-by-policyid";

export const API_CLIENT_CLAIMS = BASE_URL + PREFIX + "/client/claims-by-clientid";
export const API_CLIENT_BUSINESS_SUMMARY = BASE_URL + PREFIX + "/client/business-summary-by-clientid";
export const API_CLIENT_CONTACTBY_INSURERID = BASE_URL + PREFIX + "/client/contacts-by-insurerid";
export const API_TABLEADPROPOSALSVIEW = BASE_URL + PREFIX + "";
export const API_INSURER_VIEW_TAB_POLICIES = BASE_URL + PREFIX + "/client/policies-by-insurerid";

export const API_BUSINESS_SUMMARY = BASE_URL + PREFIX + "/employee/business-summary-by-userid";
export const API_TEAM_BUSINESS_SUMMARY = BASE_URL + PREFIX + "/employee/business-summary-team-by-userid";

export const API_INSURER_VIEW_TAB_MEETINGS = BASE_URL + PREFIX + "/client/meetings-by-insurerid";
export const API_INSURER_VIEW_TAB_BUSINESS_SUMMARY = BASE_URL + PREFIX + "/client/business-summary-by-insurerid";
export const API_DOCUMENT_ADD = BASE_URL + PREFIX + "/documents/add";
export const API_DOCUMENT_LIST = BASE_URL + PREFIX + "/documents/get";
export const API_DELETE_DOCUMENT = BASE_URL + PREFIX + "/masters/delete-documents";
export const API_MARK_IN = BASE_URL + PREFIX + "/myprofile/mark-in";
export const API_MARK_OUT = BASE_URL + PREFIX + "/myprofile/mark-out";
export const API_MARK_STATUS = BASE_URL + PREFIX + "/myprofile/mark/status";
export const API_MASTER_DOCUMENT_CATEGORY = BASE_URL + PREFIX + "/masters/document-category";
export const API_MASTER_DOCUMENT_TYPE = BASE_URL + PREFIX + "/masters/document-type";
export const API_MASTER_DOCUMENT_APPROVED_BY = BASE_URL + PREFIX + "/masters/document/approved-by";

export const API_INSURER_VIEW_TAB_CLIENTS = BASE_URL + PREFIX + "/client/clients-by-insurerid";
export const API_DELETE_COMPANY_DOCUMENT = BASE_URL + PREFIX + "/documents/delete";
export const API_INSURER_VIEW_TAB_COMPANYNEWS = BASE_URL + PREFIX + "/insurer/news";

export const API_DASHBOARD_GET_POST = BASE_URL + PREFIX + "/post/dashboard";
export const API_DASHBOARD_BUSINESS_SUMMARY = BASE_URL + PREFIX + "/employee/business-summary-dashboard";
export const API_LATEST_FIVE_CLIENTS = BASE_URL + PREFIX + "/client/latest-five";
export const API_GET_TICKER = BASE_URL + PREFIX + "/content-setting/get-setting";
export const API_DASHBOARD_BIRTHDAY = BASE_URL + PREFIX + "/employee/dashboard/birthday";
export const API_UPCOMING_MEETING = BASE_URL + PREFIX + "/meeting/latest-five";

export const API_PERMISSION_CONTENT = BASE_URL + PREFIX + "/content-setting/get";
export const API_PERMISSION_CONTENT_EDIT = BASE_URL + PREFIX + "/content-setting/edit";
export const API_PERMISSION_CONTENT_VIEW = BASE_URL + PREFIX + "/content-setting/view";
export const API_CONTENT_STATUS = BASE_URL + PREFIX + "/content-setting/change-status";

export const API_HOLIDAY_ADD = BASE_URL + PREFIX + "/occasion/add";
export const API_HOLIDAY_EDIT = BASE_URL + PREFIX + "/occasion/edit";
export const API_HOLIDAY_VIEW = BASE_URL + PREFIX + "/occasion/get";
export const API_HOLIDAY_LIST = BASE_URL + PREFIX + "/occasion/list";
export const API_HOLIDAY_BRANCH = BASE_URL + PREFIX + "/masters/branch-address-list";

export const API_LEAVE_REQUEST_LIST = BASE_URL + PREFIX + "/hrms/leave/get";
export const API_LEAVE_HOLIDAY = BASE_URL + PREFIX + "/hrms/leave/holiday";
export const API_LEAVE_REQUEST_ADD = BASE_URL + PREFIX + "/hrms/leave/add";
export const API_LEAVE_REQUEST_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/managername";
export const API_TOTAL_LEAVE = BASE_URL + PREFIX + "/hrms/leave/total";

export const API_GET_ATTENDANCE = BASE_URL + PREFIX + "/request/get-attendance";
export const API_REGULARIZE_REQUEST_LIST = BASE_URL + PREFIX + "/hrms/regularize/get";
export const API_REGULARIZE_REQUEST_ADD = BASE_URL + PREFIX + "/hrms/regularize/add";
export const API_REGULARIZE_REQUEST_VIEW = BASE_URL + PREFIX + "/hrms/regularize/view";

export const API_REGULARIZE_APPROVALS_LIST = BASE_URL + PREFIX + "/hrms/regularize/approval/get";
export const API_REGULARIZE_APPROVALS_VIEW = BASE_URL + PREFIX + "/hrms/regularize/approval/view";
export const API_LEAVE_REQUEST_VIEW = BASE_URL + PREFIX + "/hrms/leave/view";
export const API_LEAVE_APPROVAL_VIEW = BASE_URL + PREFIX + "/hrms/leave/approval/view";
export const API_LEAVE_APPROVAL_LIST = BASE_URL + PREFIX + "/hrms/leave/approval/get";
export const API_CHANGE_STATUS = BASE_URL + PREFIX + "/hrms/request/change/status";

export const API_ATTENDANCE_LEAVE = BASE_URL + PREFIX + "/dashboard/attendance/get";
export const API_GET_PENDING_APPROVAL = BASE_URL + PREFIX + "/dashboard/pending-approval/get";
export const API_GET_MANAGER_APPROVAL = BASE_URL + PREFIX + "/dashboard/pending-request/get";
export const API_TEAM_MASTER_EDIT = BASE_URL + PREFIX + "/team-master/edit";
export const API_TEAM_HEAD_EDIT = BASE_URL + PREFIX + "/masters/accompanied-by";
export const API_BRANCH_EDIT = BASE_URL + PREFIX + "/masters/userbranch";
export const API_GET_TEAM_MASTER = BASE_URL + PREFIX + "/team-master/get";

export const API_GET_MASTER_USERBRANCH = BASE_URL + PREFIX + "/masters/userbranch";
export const API_TEAM_MASTER_STATUS = BASE_URL + PREFIX + "/team-master/change/status";
export const API_TEAM_MASTER_ADD = BASE_URL + PREFIX + "/team-master/add";
export const API_TEAM_MASTER_LIST = BASE_URL + PREFIX + "/team-master/list";

/** Calender Events */
export const API_CALENDER_EVENT = BASE_URL + PREFIX + "/calender/events";
export const API_CALENDER_TEAM_EVENT = BASE_URL + PREFIX + "/calender/get-team-data";
export const API_LOG_OUT = BASE_URL + PREFIX + "/user/logout";
export const API_USER_NOTIFICATIONS = BASE_URL + PREFIX + "/user/notification";
export const API_HEADER_USER_NOTIFICATION = BASE_URL + PREFIX + "/user/notification/latest ";
export const API_DELETE_NOTIFICATIONS = BASE_URL + PREFIX + "/user/notification/delete";
export const API_DELETE_ALL_NOTIFICATIONS = BASE_URL + PREFIX + "/user/notification/delete";
export const API_READ_NOTIFICATIONS = BASE_URL + PREFIX + "/user/notification/read";
export const API_CALENDER_GET_EVENT = BASE_URL + PREFIX + "/calender/get-single-event-data";

/** Retention **/
export const API_MASTER_ASSIGN_RETENTION = BASE_URL + PREFIX + "/masters/assign-retention";
export const API_MASTER_EDIT_ASSIGN_RETENTION = BASE_URL + PREFIX + "/retention/assign";
export const API_RETENTION_LIST = BASE_URL + PREFIX + "/retention/list";
export const API_RETENTION_ASSIGN_LIST = BASE_URL + PREFIX + "";
export const API_REINSURANCE = BASE_URL + PREFIX + "/reinsurance/client/list";
export const API_REINSURANCE_EDIT = BASE_URL + PREFIX + "/reinsurance/client/edit";
export const API_REINSURANCE_VIEW = BASE_URL + PREFIX + "/reinsurance/client/detail";
export const API_IMPORT_CLIENT_DATA = BASE_URL + PREFIX + "/reinsurance/client/import";
export const API_IMPORT_MASTER_DATA = BASE_URL + PREFIX + "/reinsurance/report/import";
export const API_EXPORT_REINSURANCE_MASTER = BASE_URL + PREFIX + "/reinsurance/master";
export const API_MASTER_COUNTRY = BASE_URL + PREFIX + "/masters/country";
export const API_ADD_NEW_CLIENT = BASE_URL + PREFIX + "/reinsurance/client/add";
export const API_CLIENT_TYPE = BASE_URL + PREFIX + "/reinsurance/client/type";
export const API_RI_CLIENT = BASE_URL + PREFIX + "";
export const API_GET_RI_NAME = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_GET_RI_NAME_ADD = BASE_URL + PREFIX + "/client/my-clients-leads";
export const API_RI_MEETING_ADD_CONTACT = BASE_URL + PREFIX + "/contact/add";
export const API_ADD_RI_MEETING_ADD_CONTACT = BASE_URL + PREFIX + "/contact/add";
export const API_ASSET_REQUEST_VIEW = BASE_URL + PREFIX + "/hrms/asset/view";
export const API_ASSET_APPROVAL_LIST = BASE_URL + PREFIX + "/hrms/asset/approval/get";
export const API_ASSET_REQUEST_ADD = BASE_URL + PREFIX + "/hrms/asset/add";
export const API_ASSET_APPROVAL_VIEW = BASE_URL + PREFIX + "/hrms/asset/approval/view";
export const API_ASSET_REQUEST_LIST = BASE_URL + PREFIX + "/hrms/asset/get";
export const API_TRAVEL_REQUEST_LIST = BASE_URL + PREFIX + "/hrms/travel/get";
export const API_TRAVEL_REQUEST_ADD = BASE_URL + PREFIX + "/hrms/travel/add";
export const API_TRAVEL_REQUEST_VIEW = BASE_URL + PREFIX + "/hrms/travel/view";
export const API_TRAVEL_APPROVAL_LIST = BASE_URL + PREFIX + "/hrms/travel/approval/get";
export const API_TRAVEL_APPROVAL_VIEW = BASE_URL + PREFIX + "/hrms/travel/approval/view";
export const API_TRAVEL_REQUEST_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/managername";
export const API_TRAVEL_REQUEST_UPCOMING_MEETINGS = BASE_URL + PREFIX + "/masters/upcoming-meeting ";
export const API_ASSET_REQUEST_APPROVAL_MANAGER = BASE_URL + PREFIX + "/masters/managername";

/**Tasks**/
export const API_CREATE_TASK = BASE_URL + PREFIX + "/hrms/task/create";
export const API_TASK_LIST = BASE_URL + PREFIX + "/hrms/task/get";
export const API_TASK_POOL_LIST = BASE_URL + PREFIX + "/hrms/task/pull/get";
export const API_TASK_VIEW = BASE_URL + PREFIX + "/hrms/task/view";
export const API_TASK_POOL_VIEW = BASE_URL + PREFIX + "/hrms/task/view";
export const API_CREATE_TASK_ACCOMPAINED_BY = BASE_URL + PREFIX + "/masters/accompanied-by";
export const API_TASK_STATUS = BASE_URL + PREFIX + "/hrms/task/update-status";
export const API_TASK_ASSIGN_TO_ME = BASE_URL + PREFIX + "/hrms/task/assign-to-me";

/**Claims**/
export const API_CLAIMS_LIST = BASE_URL + PREFIX + "/claims/get";
export const API_CLAIMS_MIS_LIST = BASE_URL + PREFIX + "/claims/mis/get";
export const API_CLAIMS_EDIT = BASE_URL + PREFIX + "/claims/edit";
export const API_CLAIMS_DASHBOARD = BASE_URL + PREFIX + "/claims/dashboard";
export const API_LOSS_SITE_VISIT_LIST = BASE_URL + PREFIX + "/claims/loss-site/get";
export const API_CLAIMS_CUSTOMER = BASE_URL + PREFIX + "/masters/group/company";
export const API_MANDATE_ADD = BASE_URL + PREFIX + "claims/mandate/add";
export const API_PINC_ADD = BASE_URL + PREFIX + "/claims/add";
export const API_CLAIMS_VIEW = BASE_URL + PREFIX + "/claims/view";
export const API_CLAIMS_RM = BASE_URL + PREFIX + "/masters/all/rm";
export const API_CLAIMS_POLICY_TYPE = BASE_URL + PREFIX + "/masters/policy/type";
export const API_ADD_MANDATE_POLICY = BASE_URL + PREFIX + "/policy/new/mandate";
export const API_NATURE_OF_LOSS = BASE_URL + PREFIX + "/masters/nature-of-loss";
export const API_CLAIM_TYPE = BASE_URL + PREFIX + "/masters/claim/type";
export const API_CLAIM_STATUS = BASE_URL + PREFIX + "/masters/claim/status";
export const API_CLAIM_DOWNLOAD = BASE_URL + PREFIX + "/claims/download";

/* IT support */
export const API_IT_SUPPORT_LIST = BASE_URL + PREFIX + "/hrms/it/get";
export const API_DASHBOARD_TASK_STATUS = BASE_URL + PREFIX + "/dashboard/pending-task/get";
export const API_IT_SUPPORT_ADD = BASE_URL + PREFIX + "/hrms/it/add";
export const API_IT_SUPPORT_VIEW = BASE_URL + PREFIX + "/hrms/it/view";
export const API_IT_SUPPORT_APPROVAL_MANAGER = BASE_URL + PREFIX + "";
export const API_IT_CHANGE_STATUS = BASE_URL + PREFIX + "/hrms/request/change/status";

/* Cron */
export const API_VIEW_CRON_DASHBOARD = BASE_URL + PREFIX + "/cron-log/get";
export const API_CRON_DASHBOARD = BASE_URL + PREFIX + "/cron-master/get";
export const API_CRON_STATISTICS = BASE_URL + PREFIX + "/cron-log/statistics";

export const API_MASTER_INSURANCE_BRANCH = BASE_URL + PREFIX + "/masters/branch-by-insurercompanywise";

// CLAIM ADD LOSS SITE VISIT
export const API_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER = BASE_URL + PREFIX + "/masters/insurer-companies";
export const API_ADD_LOSS_SITE_VISIT_POLICY_TYPE = BASE_URL + PREFIX + "/masters/policy/type";
export const API_ADD_LOSS_SITE_VISIT = BASE_URL + PREFIX + "/claims/loss-site/add";
export const API_EDIT_CLAIMS_VIEW = BASE_URL + PREFIX + "/claims/view";
export const API_NEW_PASSWORD = BASE_URL + PREFIX + "/user/reset-password";
export const API_EVENT_TYPE = BASE_URL + PREFIX + "/masters/claim-event-type-master";
export const API_CLAIM_EDIT_EVENT_DETAILS = BASE_URL + PREFIX + "/claims/event-primary-details/add";
export const API_GET_CLAIM_EDIT_EVENT_DETAILS = BASE_URL + PREFIX + "/claims/event-primary-details/get";
export const API_CLAIM_HANDLER = BASE_URL + PREFIX + "/myprofile/get";

export const API_CLAIMS_DOCUMENT_LIST = BASE_URL + PREFIX + "/claims/document/list";
export const API_CLAIMS_DOCUMENT_UPLOAD = BASE_URL + PREFIX + "/claims/upload-document";
export const API_GET_CLAIMS_DOCUMENT_UPLOAD = BASE_URL + PREFIX + "/claims/document/get-document";
export const API_REOPEN_CLAIM = BASE_URL + PREFIX + "/claims/reopen";

export const API_SHIFT_TIMING_LIST = BASE_URL + PREFIX + "/shift-timinigs/list";
export const API_SHIFT_TIMING_ADD = BASE_URL + PREFIX + "/shift-timinigs/add";
export const API_SHIFT_TIMING_EDIT = BASE_URL + PREFIX + "/shift-timinigs/edit";
export const API_SHIFT_TIMING_VIEW = BASE_URL + PREFIX + "/shift-timinigs/get";
export const API_EMPLOYEE_SHIFT_TIMINGS = BASE_URL + PREFIX + '/shift-timinigs/employee-shift-list';

// reports
export const API_ATTENDANCE_REPORT = BASE_URL + PREFIX + '/reports/attendance';
export const API_LEAVE_REPORT = BASE_URL + PREFIX + '/reports/leave';
export const API_MASTER_EMPLOYEE_DETAILS = BASE_URL + PREFIX + '/masters/employees';

//meeting reports
export const API_MEETING_REPORT = BASE_URL + PREFIX + '/reports/meetings';
export const API_MEETING_DETAIL_REPORT = BASE_URL + PREFIX + '/reports/meeting/details';
export const API_UNATTEND_CLIENT_LEAD_REPORT = BASE_URL + PREFIX + '/reports/no-meetings-scheduled';

// CIMS reports
export const API_CUSTOMER_VERIFICATION_REPORT = BASE_URL + PREFIX + '/reports/client-verification';

//reimbursement request
export const API_REIMBURSEMENT_ADD = BASE_URL + PREFIX + '/reimbursement/add';
export const API_REIMBURSEMENT_LIST = BASE_URL + PREFIX + '/reimbursement/list';
export const API_REIMBURSEMENT_REQUEST_STATUS = BASE_URL + PREFIX + ''
export const API_REIMBURSEMENT_VIEW = BASE_URL + PREFIX + '/reimbursement/get-summary';
export const API_REIMBURSEMENT_CHANGE_STATUS = BASE_URL + PREFIX + '/reimbursement/change-status';
export const API_REIMBURSEMENT_CLAIM = BASE_URL + PREFIX + '/reimbursement-details/add';
export const API_REIMBURSEMENT_CLAIM_EDIT = BASE_URL + PREFIX + '/reimbursement-details/edit';
export const API_REIMBURSEMENT_REQUEST_DELETE = BASE_URL + PREFIX + '/reimbursement-details/delete';
export const API_APPROVE_AMOUNT = BASE_URL + PREFIX + '/reimbursement-details/approve-amount';
export const API_REIMBURSEMENT_MEETINGS = BASE_URL + PREFIX + '/reimbursement/meetings/get';
export const API_REIMBURSEMENT_GET_SUMMARY = BASE_URL + PREFIX + '/reimbursement/get-summary';
export const API_PRINT_REIMBURSEMENT_REQUEST = BASE_URL + PREFIX + '/reimbursement/get-summary-pdf';
//GETMONTH DROPDOWN
export const API_REIMBURSEMENT_GET_MONTH = BASE_URL + PREFIX + '/reimbursement/get-month-of-claim';

//Reimbursement pool
export const API_REIMBURSEMENT_POOL_LIST = BASE_URL + PREFIX + '/reimbursement/list';
export const API_REIMBURSEMENT_POOL_VIEW = BASE_URL + PREFIX + '/reimbursement/get-summary';
export const API_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT = BASE_URL + PREFIX + '/reimbursement/physical/document/get';

//Nature of Expences
export const API_NATURE_EXPENCES_ADD = BASE_URL + PREFIX + '/reimbursement-type/add';
export const API_NATURE_EXPENCES_EDIT = BASE_URL + PREFIX + '';
export const API_NATURE_EXPENCES_VIEW = BASE_URL + PREFIX + '';
export const API_NATURE_EXPENCES_LIST = BASE_URL + PREFIX + '/reimbursement-type/get';
export const API_NATURE_EXPENCES_STATUS = BASE_URL + PREFIX + '/reimbursement-type/change-status';

//Transport
export const API_TRANSPORT_ADD = BASE_URL + PREFIX + '/reimbursement-transport-type/add';
export const API_TRANSPORT_EDIT = BASE_URL + PREFIX + '';
export const API_TRANSPORT_VIEW = BASE_URL + PREFIX + '';
export const API_TRANSPORT_LIST = BASE_URL + PREFIX + '/reimbursement-transport-type/get';
export const API_TRANSPORT_STATUS = BASE_URL + PREFIX + '/reimbursement-transport-type/change-status';

//Reimbursement report
export const API_REIMBURSEMENT_REPORT = BASE_URL + PREFIX + '/reports/reimbursement';

//finance limit
export const API_FINANCE_LIMIT_ADD = BASE_URL + PREFIX + '/reimbursement-limit/add';
export const API_FINANCE_LIMIT_EDIT = BASE_URL + PREFIX + '/reimbursement-limit/edit';
export const API_FINANCE_LIMIT_LIST = BASE_URL + PREFIX + '/reimbursement-limit/get';
export const API_FINANCE_LIMIT_VIEW = BASE_URL + PREFIX + '/reimbursement-limit/view';

//proposal
export const API_PROPOSAL_LIST = BASE_URL + PREFIX + '/proposal/view-details';
export const API_PROPOSAL_DASHBOARD_VIEW = BASE_URL + PREFIX + '/proposal/dashboard';

// policy
export const API_MASTER_POLICY_TYPE = BASE_URL + PREFIX + '';
export const API_SUM_INSURED = BASE_URL + PREFIX + '/policy/sum-insured';
export const API_INSURANCE_COMPANY = BASE_URL + PREFIX + '';
export const API_POLICY_VIEW_LIST = BASE_URL + PREFIX + '/policy/view-details';
export const API_POLICY_DASHBOARD = BASE_URL + PREFIX + '/policy/dashboard';

// appsettings
export const API_APP_SETTINGS_LIST = BASE_URL + PREFIX + '/setting/get';
export const API_APP_SETTING_ADD = BASE_URL + PREFIX + '/setting/add';
export const API_APP_SETTINGS_VIEW = BASE_URL + PREFIX + '/setting/view';
export const API_APP_SETTINGS_EDIT = BASE_URL + PREFIX + '/setting/edit';
export const API_APP_SETTINGS_DELETE = BASE_URL + PREFIX + '/setting/delete';

export const API_REINSURANCE_ENQUIRY_ADD = BASE_URL + PREFIX + '/reinsurance/enquiry/add';
export const API_REINSURANCE_ENQUIRY_EDIT = BASE_URL + PREFIX + '/reinsurance/enquiry/edit';
export const API_REINSURANCE_ENQUIRY_LIST = BASE_URL + PREFIX + '/reinsurance/enquiry/list';
export const API_REINSURANCE_ENQUIRY_DETAIL = BASE_URL + PREFIX + '/reinsurance/enquiry/detail';
export const API_REINSURANCE_ENQUIRY_MASTER = BASE_URL + PREFIX + '/static-data/all';

export const API_RM_GET_LEADS = BASE_URL + PREFIX + '/rm/get-leads';
export const API_RM_GET_CLIENTS = BASE_URL + PREFIX + '/rm/get-clients';
export const API_RM_TRANSFER = BASE_URL + PREFIX + '/rm/transfer';
export const API_RM_TRANSFER_DASHBOARD = BASE_URL + PREFIX + '/rm/transfer-list';

export const API_WORKING_DAYS = BASE_URL + PREFIX + '/employee/working-days/get';
export const API_ASSIGN_EDIT_PROPOSAL = BASE_URL + PREFIX + '/policy/assign-specialist';
export const API_PROPOSALPOOL_LIST = BASE_URL + PREFIX + '/policy/proposal-list';
export const API_PROPOSAL_NEW_POOL_LIST = BASE_URL + PREFIX + '/opportunity/get-list';
export const API_ASSIGN_OPPORTUNITY = BASE_URL + PREFIX + '/policy/product-specialist';
export const API_POLICY_PREMIUM = BASE_URL + PREFIX + '/policy/gross-premium'

// risk management
export const API_RISK_MANAGEMENT_CLIENT_TYPE = BASE_URL + PREFIX + '/masters/client-types';
export const API_RISK_MANAGEMENT_TYPE_OF_INSPECTION = BASE_URL + PREFIX + '/masters/inspection-types';
export const API_WELLNESS_MANAGEMENT_WELLNESS_TYPE = BASE_URL + PREFIX + '/masters/wellness-types';
export const API_RISK_MANAGEMENT_ADD = BASE_URL + PREFIX + '/risk/request/add';
export const API_RISK_APPROVAL_LIST = BASE_URL + PREFIX + '/risk/list';
export const API_RISK_MANAGEMENT_REQUEST_ADD = BASE_URL + PREFIX + '/risk/request/add';
export const API_RISK_MANAGEMENT_REQUEST_VIEW = BASE_URL + PREFIX + '/risk/view';
export const API_ASSIGN_ENGINEER = BASE_URL + PREFIX + '/risk/assign-engineer/list';
export const API_EDIT_ASSIGN_ENGINEER = BASE_URL + PREFIX + '/risk/assign-engineer/update';
export const API_RISK_MANAGEMENT_FINANCIAL_YEAR = BASE_URL + PREFIX + '/masters/financial-year/get';
export const API_RISK_MANAGEMENT_EDIT = BASE_URL + PREFIX + '/risk/request/edit';
export const API_EDIT_RISK_REQUEST = BASE_URL + PREFIX + '/risk/request/edit';
export const API_RISK_CHANGE_STATUS = BASE_URL + PREFIX + '/risk/change/status';
export const API_RISK_SELECT_CUSTOMER = BASE_URL + PREFIX + "/masters/select-customers/type";
export const API_RISK_MANAGEMENT_REPORT = BASE_URL + PREFIX + "/reports/risk/management";
export const API_RISK_MASTER_CUSTOMER_TYPE = BASE_URL + PREFIX + "/risk/select-customers/type";
export const API_RISK_MASTER_SALES_RM = BASE_URL + PREFIX + "/masters/get-all-salesrm";

export const API_ADD_PROBABILITY = BASE_URL + PREFIX + '/policy/probability/add';
export const API_OPPORTUNITY_QUESTION_LIST = BASE_URL + PREFIX + '/policy/questions/list'
export const API_OPPORTUNITY_QUESTION_ADD_EDIT = BASE_URL + PREFIX + '/policy/questions/addEdit';
export const API_GET_OPPORTUNITY_QUESTION = BASE_URL + PREFIX + '/policy/opportunity/questions/get';

export const API_WELLNESS_REQUEST_LIST = BASE_URL + PREFIX + '/wellness/list';
export const API_WELLNESS_MANAGEMENT_REQUEST_ADD = BASE_URL + PREFIX + '/wellness/request/add';
export const API_EDIT_WELLNESS_REQUEST = BASE_URL + PREFIX + '/wellness/request/update';
export const API_WELLNESS_MANAGEMENT_REQUEST_VIEW = BASE_URL + PREFIX + '/wellness/view';
export const API_ASSIGN_RM = BASE_URL + PREFIX + '/wellness/assign/rm/list';
export const API_EDIT_ASSIGN_RM = BASE_URL + PREFIX + '/wellness/assign/rm/update';
export const API_WELLNESS_MANAGEMENT_REPORT = BASE_URL + PREFIX + '/reports/wellness';

// It declaration
export const API_IT_DECLARATION_ADD = BASE_URL + PREFIX + '/it-declaration/add';
export const API_IT_DECLARATION_SUBMIT = BASE_URL + PREFIX + '/it-declaration/submit';
export const API_IT_DECLARATION_LIST = BASE_URL + PREFIX + '/it-declaration/list';
export const API_IT_DECLARATION_DETAIL = BASE_URL + PREFIX + '/it-declaration/detail';
export const API_IT_DECLARATIONS_EDIT = BASE_URL + PREFIX + '/it-declaration/edit';
export const API_IT_DECLARATION_POOL_LIST = BASE_URL + PREFIX + '/it-declaration/admin/all-list';
export const API_IT_DECLARATION_POOL_DETAIL = BASE_URL + PREFIX + '/it-declaration/admin/detail';
export const API_IT_DECLARATION_POOL_EDIT = BASE_URL + PREFIX + '/it-declaration/admin/submit';
export const API_IT_DECLARATION_SETTINGS_LIST = BASE_URL + PREFIX + '/it-declaration/settings/list';
export const API_IT_DECLARATION_SETTINGS_ADD = BASE_URL + PREFIX + '/it-declaration/settings/add';
export const API_IT_DECLARATION_EDIT = BASE_URL + PREFIX + '/it-declaration/settings/edit';
export const API_IT_DECLARATION_FINANCIAL_YEAR = BASE_URL + PREFIX + '/it-declaration/fy';
export const API_IT_DECLARATION_SETTINGS_ACTIVE = BASE_URL + PREFIX + '/it-declaration/settings/active';
export const API_GET_BRANCH_WISE_CONTACT = BASE_URL + PREFIX + '/contact/get/branch-wise';

// IT submission api
export const API_IT_DECLARATION_LIST_DROPDOWN = BASE_URL + PREFIX + '';
export const API_IT_SUBMISSION_DETAIL = BASE_URL + PREFIX + '/it-declaration/submission/detail';
export const API_IT_SUBMISSION_EDIT = BASE_URL + PREFIX + '/it-declaration/submission/edit';
export const API_IT_SUBMISSION_DOWNLOAD = BASE_URL + PREFIX + '/it-declaration/get-submission-pdf';
export const API_IT_SUBMISSION_POOL_LIST = BASE_URL + PREFIX + '/it-declaration/admin/all-list';
export const API_IT_SUBMISSION_ADD = BASE_URL + PREFIX + '/it-declaration/submission/add';
export const API_IT_SUBMISSION_POOL_DETAIL = BASE_URL + PREFIX + '/it-declaration/submission/detail';
export const API_IT_SUBMISSION_POOL_EDIT = BASE_URL + PREFIX + '/it-declaration/submission/admin-submit';

// visiting card
export const API_EMPLOYEE_VISITING_CARD = BASE_URL + PREFIX + '/visiting-card/get';
export const API_LOGOUT_ALL_USER = BASE_URL + PREFIX + '/user/logout-all';
export const API_LOGOUT_ALL_USER_CLIENTS = BASE_URL + PREFIX + '/user/logout-all-clients';

// Group level View
export const API_GROUP_BUSINESS_SUMMARY = BASE_URL + PREFIX + '/client-group/business-summary';
export const API_GROUP_LEVEL_OPPORTUNITY = BASE_URL + PREFIX + '/client-group/lead-policies';
export const API_GROUP_LEVEL_MEETING = BASE_URL + PREFIX + '/client-group/meetings';
export const API_GROUP_POLICIES = BASE_URL + PREFIX + '/client-group/policies';
export const API_GROUP_ALL_CLAIMS = BASE_URL + PREFIX + '/client-group/claims';
export const API_GROUP_COMPANIES = BASE_URL + PREFIX + '/client-group/companies';
export const API_GROUP_ALL_ACTIVITIES = BASE_URL + PREFIX + '/client-group/activity';
export const API_GROUP_LEVEL_PINC_RM = BASE_URL + PREFIX + '/client-group/rm-worked';
export const API_GROUP_PROFILE_DETAILS = BASE_URL + PREFIX + '/client-group/profile';
export const API_WEEKLY_MEETINGS = BASE_URL + PREFIX + '/meeting/weekly-meetings';

// broker training
export const API_MASTER_TRAINING = BASE_URL + PREFIX + '/masters/training';
export const API_MASTER_TRAINING_BRANCH = BASE_URL + PREFIX + '/masters/training/branch';
export const API_BROKER_TRAINING_ADD = BASE_URL + PREFIX + '/training/add';
export const API_BROKER_TRAINING_LIST = BASE_URL + PREFIX + '/training/get';
export const API_BROKER_TRAINING_DETAIL = BASE_URL + PREFIX + '/training/detail';
export const API_BROKER_TRAINING_UPDATE = BASE_URL + PREFIX + '/training/edit';
export const API_TRAINING_EMPLOYEE_PARTICIPATE_ADD = BASE_URL + PREFIX + '/training/employee-participate/add';
export const API_MEETING_WEEKLY_TRAININGS = BASE_URL + PREFIX + '/meeting/weekly-trainings';
export const API_TRAINING_DATE = BASE_URL + PREFIX + '/masters/training/start_date';
export const API_TRAINING_PARTICIPATION_REPORT = BASE_URL + PREFIX + '/reports/training/participation';
export const API_TRAINING_REPORT = BASE_URL + PREFIX + '/reports/training';
// master details
export const API_MASTER_GET_CATEGORY = BASE_URL + PREFIX + '/pinc-master/get-category';
export const API_MASTER_GET_DEPARTMENT = BASE_URL + PREFIX + '/pinc-master/get-department';
export const API_MASTER_ADD_DEPARTMENT = BASE_URL + PREFIX + '/pinc-master/add-department';
export const API_MASTER_EDIT_DEPARTMENT = BASE_URL + PREFIX + '/pinc-master/edit-department';
export const API_MASTER_GET_POLICY_TYPE = BASE_URL + PREFIX + '/pinc-master/get-policy-type';
export const API_MASTER_ADD_POLICY_TYPE = BASE_URL + PREFIX + '/pinc-master/add-policy-type';
export const API_MASTER_EDIT_POLICY_TYPE = BASE_URL + PREFIX + '/pinc-master/edit-policy-type';
export const API_MASTER_GET_SPECIFIC_PREFERENCE = BASE_URL + PREFIX + '/pinc-master/get-specific-preference';
export const API_MASTER_GET_INSURER = BASE_URL + PREFIX + '/pinc-master/get-insurer';
export const API_MASTER_ADD_INSURER = BASE_URL + PREFIX + '/pinc-master/add-insurer';
export const API_MASTER_EDIT_INSURER = BASE_URL + PREFIX + '/pinc-master/edit-insurer';
export const API_MASTER_GET_INSURER_BRANCH = BASE_URL + PREFIX + '/pinc-master/get-insurer-branch';
export const API_MASTER_ADD_INSURER_BRANCH = BASE_URL + PREFIX + '/pinc-master/add-insurer-branch';
export const API_MASTER_EDIT_INSURER_BRANCH = BASE_URL + PREFIX + '/pinc-master/edit-insurer-branch';
export const API_MASTER_GET_EMPLOYEE = BASE_URL + PREFIX + '/pinc-master/get-employee';
export const API_MASTER_GET_ADMIN_RIGHT = BASE_URL + PREFIX + '/pinc-master/admin/rights/get';
export const API_MASTER_ADD_ADMIN_RIGHT = BASE_URL + PREFIX + '/pinc-master/admin/rights/add';
export const API_MASTER_DELETE_ADMIN_RIGHT = BASE_URL + PREFIX + '/pinc-master/admin/rights/delete';
export const API_ADD_SPECIFIC_PREFERENCE = BASE_URL + PREFIX + '/pinc-master/add-specific-preference';
export const API_EDIT_SPECIFIC_PREFERENCE = BASE_URL + PREFIX + '/pinc-master/edit-specific-preference';
export const API_MASTER_GET_PLACEMENT_RIGHTS = BASE_URL + PREFIX + '/pinc-master/get-placement-rights';
export const API_MASTER_GET_EMPLOYEE_LIST = BASE_URL + PREFIX + '/pinc-master/get-emp-list';
export const API_MASTER_ADD_PLACEMENT_RIGHTS = BASE_URL + PREFIX + '/pinc-master/add-placement-right-emp';
export const API_MASTER_EDIT_PLACEMENT_RIGHTS = BASE_URL + PREFIX + '/pinc-master/edit-placement-right-emp';
export const API_ADD_GENERAL_PREFERENCE_VIEW = BASE_URL + PREFIX + '/pinc-master/get-preference-grid';
export const API_GENERAL_PREFERENCE_LIST = BASE_URL + PREFIX + '/pinc-master/get-general-preference';
export const API_GENERAL_PREFERENCE_ADD = BASE_URL + PREFIX + '/pinc-master/add-general-preference';
export const API_GENERAL_PREFERENCE_EDIT = BASE_URL + PREFIX + '/pinc-master/edit-general-preference';
export const API_MASTER_GET_BRANCH_CONTACT = BASE_URL + PREFIX + '/pinc-master/get-branch-contacts';
export const API_MASTER_ADD_BRANCH_CONTACT = BASE_URL + PREFIX + '/pinc-master/add-branch-contact';
export const API_MASTER_EDIT_BRANCH_CONTACT = BASE_URL + PREFIX + '/pinc-master/edit-branch-contact';
export const API_MASTER_GET_SPECIFIC_PLACEMENT_RIGHTS = BASE_URL + PREFIX + '/pinc-master/get-specifc-rights';
export const API_MASTER_DELETE_SPECIFIC_PLACEMENT_RIGHTS = BASE_URL + PREFIX + '/pinc-master/delete-specifc-rights-emp';
export const API_MASTER_ADD_SPECIFIC_PLACEMENT_RIGHTS = BASE_URL + PREFIX + '/pinc-master/add-specifc-rights-emp';
export const API_MASTER_INSURER_STATES = BASE_URL + PREFIX + '/pinc-master/get-insurer-state';
export const API_MASTER_INSURER_CITIES = BASE_URL + PREFIX + '/pinc-master/get-insurer-city';
export const API_GROUP_LEVEL_CONTACT = BASE_URL + PREFIX + '/client-group/contacts';
// master Bqp
export const API_MASTER_ADD_TRAINING_TYPE = BASE_URL + PREFIX + '/masters/training/add';
export const API_MASTER_EDIT_TRAINING_TYPE = BASE_URL + PREFIX + '/masters/training/edit';
export const API_EMPLOYEE_NAMES = BASE_URL + PREFIX + '/training/get-employee-list';
export const API_TRAINING_EMPLOYEE_DETAIL = BASE_URL + PREFIX + '/training/get-employees-of-training';
export const API_EMPLOYEE_EXPIRED_CERTIFICATE = BASE_URL + PREFIX + '/training/bqp-certificate-expired-employee-list';
export const API_SPECIFIC_PREFERENCE_DELETE = BASE_URL + PREFIX + '/pinc-master/delete-specific-preference';
export const API_GENERAL_PREFERENCE_DELETE = BASE_URL + PREFIX + '/pinc-master/delete-general-preference';
// Opportunity to RFQ
export const API_OPPORTUNITY_SETTINGS_LIST = BASE_URL + PREFIX + '/opportunity/setting/get';
export const API_OPPORTUNITY_SETTINGS_ADD = BASE_URL + PREFIX + '/opportunity/setting/add';
export const API_OPPORTUNITY_SETTINGS_EDIT = BASE_URL + PREFIX + '/opportunity/setting/edit';
export const API_PROPOSALPOOL_EDIT = BASE_URL + PREFIX + '/opportunity/update-status';
export const API_PROPOSALPOOL_VIEW = BASE_URL + PREFIX + '/opportunity/get-detail';
export const API_OPPORTUNITY_STATUS = BASE_URL + PREFIX + '/static-data/opportunity-status';
export const API_OPPORTUNITY_QUOTE_REJECT_STATUS = BASE_URL + PREFIX + '/static-data/opportunity-quote-reject-status'
export const API_OPPORTUNITY_LOST_STATUS = BASE_URL + PREFIX + '/static-data/opportunity-proposal-lost-status';
export const API_OPPORTUNITY_PLACEMENTS_GET = BASE_URL + PREFIX + '/opportunity/placements/get';
export const API_INSURER_STATUS = BASE_URL + PREFIX + '/static-data/opportunity-insurer-status';
export const API_OPPORTUNITY_PLACEMENT_EDIT = BASE_URL + PREFIX + '/opportunity/placements/edit';
export const API_OPPORTUNITY_PLACEMENT_ADD = BASE_URL + PREFIX + '/opportunity/placements/add';
export const API_OPPORTUNITY_SETTINGS_DELETE = BASE_URL + PREFIX + '/opportunity/setting/delete';
export const API_INTERMEDIARY_TYPE = BASE_URL + PREFIX + '/static-data/opportunity-intermediary-type';
export const API_CLOSURE_TYPE = BASE_URL + PREFIX + '/static-data/opportunity-closure-type';
export const API_INSTALLMENT = BASE_URL + PREFIX + '/static-data/opportunity-instalments';
export const API_PLACEMENT_INSURER = BASE_URL + PREFIX + '/opportunity/get-insurers';
export const API_ASSIGN_BY_OPPORTUNITY = BASE_URL + PREFIX + '/opportunity/get-settingwise-emp-list';
export const API_DEPARTMENT_WISE_GET_INSURER = BASE_URL + PREFIX + '/opportunity/get-department-wise-insurers';
export const API_DATE_TYPE = BASE_URL + PREFIX + '/static-data/opportunity-date-type-filter';
export const API_DELETE_OPPORTUNITY_DOCUMENT = BASE_URL + PREFIX + '/opportunity/delete-document';
// birthday wishes
export const BIRTHDAY_LIST = BASE_URL + PREFIX + '/customer-service/list';
export const BIRTHDAY_ADD = BASE_URL + PREFIX + '/customer-service/add';
export const BIRTHDAY_EDIT = BASE_URL + PREFIX + '/customer-service/edit';
export const BIRTHDAY_LOGS = BASE_URL + PREFIX + '/customer-service/birthday-wish-log-list';
export const BIRTHDAY_DELETE = BASE_URL + PREFIX + '/customer-service/delete';
// leave widget 
export const TEAM_ON_LEAVE_WIDGET = BASE_URL + PREFIX + '/team/teamOnLeave';
// travel calendar
export const API_TRAVEL_LIST = BASE_URL + PREFIX + '/travel/list';
export const API_TRAVEL_DELETE = BASE_URL + PREFIX + '/travel/delete';
export const API_TRAVEL_UPDATE = BASE_URL + PREFIX + '/travel';
export const API_TRAVEL_CALENDAR_LIST = BASE_URL + PREFIX + '/travel/calendar-list';
// trvel Calendar Settings
export const API_CALENDAR_SETTING_LIST = BASE_URL + PREFIX + '/travel/setting/list';
export const API_CALENDAR_SETTING_DELETE = BASE_URL + PREFIX + '/travel/setting/delete';
export const API_TRAVEL_CALENDAR_SETTING_UPDATE = BASE_URL + PREFIX + '/travel/setting';
export const API_TRAVEL_TAB_PERMISSION = BASE_URL + PREFIX + '/travel/setting/detail';
