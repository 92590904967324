import React from "react";
// import Header from "../../../components/header";
// import SideBar from "../../../components/sidebar";
// import Content from "../../../components/content";
// import Footer from "../../../components/footer";
import { withAuth } from "react-auth-guard";
import Content from "../../../component/content";
import Footer from "../../../component/footer";
import Header from "../../../component/header";
import Sidebar from "../../../component/sidebar";

const AuthRoute = (props) => {
    const { location } = props;

    var arrPaths = ['/', '/login']

    if (arrPaths.includes(location.pathname)) {
        window.location.replace("/dashboard");
    }
    return (
        <>
            <Header {...props}/>
            <Sidebar {...props}/>
            <Content {...props}/>
            <div class="sidenav-overlay"></div>
            <div class="drag-target"></div>
            <Footer {...props}/>
        </>
    );
};
export default withAuth(AuthRoute);
