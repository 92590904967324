export const SUCCESS = 200;
export const UNAUTHORIZED = 400;
export const ERROR = 402;
export const VALIDATION_ERROR = "Validation Error";
export const VALIDATION_FAILED = "Validation failed.";

export const CURRENCY_INR = "₹";

export const PRIMARY_COLOR = '#e1056d';

export const characterLimit = 500;
export const meetingCharacterLimit = 160;
export const customerMeetingCharacterLimit = 50;
export const minCharacters = 160;
export const customerMinCharacters = 50;

export const MEETING_FILE_SIZE = 20480;
export const MEETING_FILE_ERROR_MESSAGE = "Please Select File size upto 20 MB";

export const MEETING_TRAINING_FILE_SIZE = 5120
export const MEETING_TRAINING_FILE_ERROR_MESSAGE = "Please Select File size upto 5 MB";

export const CLAIM_FILE_SIZE = 10240;
export const CLAIM_FILE_ERROR_MESSAGE = "Please Select File size upto 10 MB"

// Leave Status 

export const CANCEL_BY_EMPLOYEE = 'cancel_by_employee';
export const REVIEW_BY_SUPERIOR = 'Reviewing By Superior';
export const REVIEW_BY_HR = 'Reviewing By HR';
export const REVIEW_HR = 'review_hr';
export const REQUEST_ACCEPTED = 'request_accepted';
export const REQUEST_REJECTED_SUPERVISOR = 'rejected_supervisor';
export const REQUEST_REGULARIZE = 'regularize';
export const REQUEST_LEAVE = 'leave';
export const REQUEST_ASSET = 'asset';
export const REQUEST_IT = 'it';
export const REQUEST_TRAVEL = 'travel';
export const RETURN_REQUEST = 'return_request';
export const APPROVED_BY_MANAGER = 'manager';
export const APPROVED_BY_HR = 'hr';
export const APPROVED = 'approved';
export const ASSET = 'asset';
export const TRAVEL = 'travel';
export const IT = 'it';


export const ACTIVE = 'active';
export const INACTIVE = 'inactive';

export const ADMIN_USER = 1;

export const EVENT_TYPE = {
    "meeting": "MEETING",
    "leave": "LEAVE",
    "Holiday / Special": "HOLIDAY",
    "Training": "TRAINING"
}

export const AMOUNT_REGEX = /^[0-9]{1,9}(\.[0-9]{1,2})?$/;