import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_SHIFT_TIMING_ADD } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const EMPLOYEE_SHIFT_ADD_LOADING = 'EMPLOYEE_SHIFT_ADD_LOADING';
export const EMPLOYEE_SHIFT_ADD_SUCCESS = 'EMPLOYEE_SHIFT_ADD_SUCCESS';

export function actionShiftAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_SHIFT_TIMING_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(EMPLOYEE_SHIFT_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(EMPLOYEE_SHIFT_ADD_LOADING, { loading: false, data }));
        });
}

