import {
  ACTION_CALENDAR_TRAVEL_SUCCESS,
  ACTION_TRAVEL_DELETE_SUCCESS,
  ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS,
  ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS,
  ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS,
  ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS,
  ACTION_TRAVEL_TAB_PERMISSION_SUCCESS
} from "./action";

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
  data: [],
  loading: false,
};

const CalendarTravelReducer = createReducer(INITIAL_STATE, {
  [ACTION_CALENDAR_TRAVEL_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_CALENDAR_TRAVEL_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_TRAVEL_DELETE_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_TRAVEL_DELETE_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_TRAVEL_TAB_PERMISSION_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_TRAVEL_TAB_PERMISSION_SUCCESS,
        loading: false,
      }
    );
  },
});
export default CalendarTravelReducer;
