import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_API_ACCOMPAINED_BY_SUCCESS } from "./action";
import { ACTION_API_RI_MEETING_ADD_SUCCESS } from "./action";
import { ACTION_MASTER_COUNTRY_ADD_SUCCESS } from "./action";
import { ACTION_CLIENT_TYPE_ADD_SUCCESS, ACTION_RI_GET_CONTACTS_SUCCESS } from "./action"
import { ACTION_CLIENT_ADD_SUCCESS } from "./action"
import { ACTION_RI_PREVIOUS_MEETING_SUCCESS } from "./action"
import { ACTION_REINSURANCE_CLIENT_SUCCESS, ACTION_ADD_GET_RI_NAME_SUCCESS, ACTION_RI_MEETING_ADD_CONTACT_SUCCESS, ACTION_RI_MEETING_NEXT_SUCCESS } from "./action"

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddRiMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_RI_MEETING_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_RI_MEETING_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_COUNTRY_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_COUNTRY_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_TYPE_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_TYPE_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REINSURANCE_CLIENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REINSURANCE_CLIENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_GET_CONTACTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_GET_CONTACTS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADD_GET_RI_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_GET_RI_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_MEETING_ADD_CONTACT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_MEETING_ADD_CONTACT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_PREVIOUS_MEETING_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_PREVIOUS_MEETING_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_MEETING_NEXT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_MEETING_NEXT_SUCCESS,
                loading: false,
            }
        );
    }
});
export default AddRiMeetingReducer;    