import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_ASSET_REQUEST_ADD, API_ASSET_REQUEST_APPROVAL_MANAGER } from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';

export const ACTION_ASSET_REQUEST_ADD_LOADING = 'ACTION_ASSET_REQUEST_ADD_LOADING';
export const ACTION_ASSET_REQUEST_ADD_SUCCESS = 'ACTION_ASSET_REQUEST_ADD_SUCCESS';

export const ACTION_ASSET_REQUEST_APPROVAL_MANAGER_LOADING = 'ACTION_ASSET_REQUEST_APPROVAL_MANAGER_LOADING';
export const ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS = 'ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS';
export function actionAssetRequestAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_ASSET_REQUEST_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ASSET_REQUEST_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ASSET_REQUEST_ADD_LOADING, { loading: false, data }));
        });
}

export function actionAssetRequestApprovalManager() {
    return (dispatch, getState) =>
        Api.get(API_ASSET_REQUEST_APPROVAL_MANAGER).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ASSET_REQUEST_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}