import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED } from '../../../constants';
import { actionNewBranch, ACTION_MASTER_NEW_BRANCH_INSURANCE_COMPANY_SUCCESS } from "./action";

const InsuranceCompanyContactDetails = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {
            insurer_branch_id: ''
        }
    });

    const [newBranch, setNewBranch] = useState([]);
    const [saibaBranchId, setSaibaBranchId] = useState(null);

    const initialRender = () => {

        $("#insurer-save-contact").prop("disabled", false);

        if (props.show === true) {

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            $("#insurer-contact-form-loader").addClass("hidden");
            if (props.branchId != null) {
                setSaibaBranchId(props.branchId);
            }
            setValue('contact_name', props.data != null ? props.data.contact_name : '');
            setValue('contact_designation', props.data != null ? props.data.contact_designation : '');
            setValue('contact_phone', props.data != null ? props.data.contact_phone : '');
            setValue('contact_email', props.data != null ? props.data.contact_email : '');
            props.actionNewBranch({ insurer_company_id: props.insuranceCompanyId });
            setNewBranch([]);
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            $("#insurer-contact-form-loader").addClass("hidden");

            setValue('contact_name', '');
            setValue('contact_designation', '');
            setValue('contact_phone', '');
            setValue('contact_email', '');
        }
    };
    useEffect(initialRender, [props.show]);

    useEffect(() => {
        if (newBranch != null && newBranch.length > 0) {
            if (saibaBranchId != null) {
                setValue('saiba_branch_id', saibaBranchId)
            }
        }
    }, [newBranch])

    const onMasterNewBranchUpdate = () => {
        const { NewBranch } = props;
        var NewBranchResponse = Object.assign({}, NewBranch);
        if (NewBranchResponse.result === true) {
            setNewBranch(NewBranchResponse.response);
        } else {
            switch (NewBranchResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMasterNewBranchUpdate, [props.NewBranch]);

    const onSubmit = (data) => {

        var newContact = {};

        if (props.data != null && props.data.hasOwnProperty('id')) {
            newContact.id = props.data.id;
        }

        newContact.contact_name = data.contact_name;
        newContact.contact_phone = data.contact_phone;
        newContact.contact_designation = data.contact_designation;
        newContact.contact_email = data.contact_email;
        newContact.department = data.department;
        newContact.office_number = data.office_number;
        newContact.saiba_branch_id = saibaBranchId;

        $("#insurer-contact-form-loader").removeClass("hidden");
        $("#insurer-save-contact").prop("disabled", true);
        props.handleClose(newContact);
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Add Contact Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Person Name&nbsp;
                                                        <span className="danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_name"
                                                        name="contact_name"
                                                        placeholder="Enter Contact Name"
                                                        {...register('contact_name', {
                                                            required: 'Contact Name is required',
                                                            pattern: {
                                                                value: /^(?!^ ?[A-Za-z] ?$)([A-Za-z]+( [A-Za-z]+)*)?$/,
                                                                message: 'Please Enter Valid Name'
                                                            },
                                                        })}
                                                    />
                                                    {errors.contact_name && <ErrorLabel message={errors.contact_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Designation&nbsp;
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_designation"
                                                        name="contact_designation"
                                                        placeholder="Enter Contact Designation"
                                                        {...register('contact_designation')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Mobile
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_phone"
                                                        name="contact_phone"
                                                        maxLength={10}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="Enter Contact Phone"
                                                        {...register('contact_phone', {
                                                            pattern: {
                                                                value: /^[6789]\d{9}$/,
                                                                message: 'Please enter 10 digit valid Mobile Number'
                                                            },
                                                        })}
                                                    />
                                                    {errors.contact_phone && <ErrorLabel message={errors.contact_phone?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Contact Email
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="contact_email"
                                                        name="contact_email"
                                                        placeholder="Enter Contact Email"
                                                        {...register('contact_email', {
                                                            pattern: {
                                                                value: /^(?!.*@.*pincinsure)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                message: 'Please enter valid Email Id'
                                                            },
                                                        })}
                                                    />
                                                    {errors.contact_email && <ErrorLabel message={errors.contact_email?.message} />}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Department
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="department"
                                                        name="department"
                                                        placeholder="Enter Department"
                                                        {...register('department')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Office Number
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id=" office_number"
                                                        name="office_number"
                                                        maxLength={13}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="Enter Office Number"
                                                        {...register('office_number')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 d-none">
                                                <div className="form-group">
                                                    <label className="label-control">Branch&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="saiba_branch_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={newBranch}
                                                                fields={{ text: 'address', value: 'saiba_branch_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Branch"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.saiba_branch_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch is required'
                                                        }}
                                                    />
                                                    {errors.saiba_branch_id && <ErrorLabel message={errors.saiba_branch_id?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="insurer-save-contact"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="insurer-contact-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    var NewBranch = null;

    if (
        state.InsuranceCompanyAddNewBranchReducer != null &&
        Object.keys(state.InsuranceCompanyAddNewBranchReducer).length > 0 &&
        state.InsuranceCompanyAddNewBranchReducer.constructor === Object
    ) {
        switch (state.InsuranceCompanyAddNewBranchReducer.tag) {
            case ACTION_MASTER_NEW_BRANCH_INSURANCE_COMPANY_SUCCESS:
                NewBranch = Object.assign({}, state.InsuranceCompanyAddNewBranchReducer.data);
                delete state.InsuranceCompanyAddNewBranchReducer;
                return { NewBranch };
            default:
        }
    }
    return {
        NewBranch

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionNewBranch,
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(InsuranceCompanyContactDetails))
