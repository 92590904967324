import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TagBox } from "devextreme-react";
import ArrayStore from 'devextreme/data/array_store';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { UNAUTHORIZED, VALIDATION_ERROR } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { actionMasterDepartment, ACTION_MASTER_GET_DEPARTMENT_SUCCESS } from "./action";
import { actionMasterPolicyType, ACTION_MASTER_GET_POLICY_TYPE_SUCCESS } from "./action";
import { actionMasterEmployeeList, ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS } from "./action";
import { actionMasterEditPlacementRights, ACTION_MASTER_EDIT_PLACEMENT_RIGHTS_SUCCESS } from "./action";

const PlacementEdit = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm();
    const [department, setDepartment] = useState([]);
    const [policyType, setPolicyType] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [placementDetail, setPlacementDetail] = useState(null);
    const [mySelectedTeam, setMySelectedTeam] = useState(null)

    const initialRender = () => {

        if (props.show === true) {
            props.actionMasterDepartment({ status: "ACTIVE" });
            // props.actionMasterPolicyType({ status: "ACTIVE" });
            props.actionMasterEmployeeList();
            if (props?.data != null) {
                setPlacementDetail(props?.data);
                setValue('department_id', props?.data?.department_id);
                setValue('policy_type_id', props?.data?.policy_type_id);
                setValue('status', props?.data?.status);
                const ids = props?.data?.placement.map(item => item.user_id);
                setValue('user_ids', ids);
            }
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            $("#placement-form-loader").addClass("hidden");
            setDepartment([]);
            setPolicyType([]);
            setTeamMembers([]);
            reset();
        }
    };
    useEffect(initialRender, [props.show]);

    useEffect(() => {
        if (department != null && department.length > 0) {
            if (placementDetail != null && placementDetail?.department_id != null) {
                setValue('department_id', placementDetail?.department_id);
            }
        }
    }, [department])

    const onMasterDepartmentUpdate = () => {
        const { MasterDepartment } = props
        if (MasterDepartment != null) {
            var MasterDepartmentResponse = Object.assign({}, MasterDepartment);
            if (MasterDepartmentResponse.result === true) {
                setDepartment(MasterDepartmentResponse.response);
            } else {
                switch (MasterDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterDepartmentUpdate, [props.MasterDepartment])

    useEffect(() => {
        if (policyType != null && policyType.length > 0) {
            if (placementDetail != null && placementDetail?.policy_type_id != null) {
                setValue('policy_type_id', placementDetail?.policy_type_id);
            }
        }
    }, [policyType])

    const onMasterPolicyTypeUpdate = () => {
        const { MasterPolicyType } = props
        if (MasterPolicyType != null) {
            var MasterPolicyTypeResponse = Object.assign({}, MasterPolicyType);
            if (MasterPolicyTypeResponse.result === true) {
                setPolicyType(MasterPolicyTypeResponse.response);
            } else {
                switch (MasterPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterPolicyTypeUpdate, [props.MasterPolicyType])

    useEffect(() => {
        if (teamMembers != null && teamMembers.length > 0) {
            if (placementDetail != null && placementDetail?.placement != null) {
                const ids = placementDetail?.placement.map(item => item.user_id);
                setValue('user_ids', ids);
            }
        }
    }, [teamMembers])

    const onMasterEmployeeListUpdate = () => {
        const { MasterEmployeeList } = props
        if (MasterEmployeeList != null) {
            var MasterEmployeeListResponse = Object.assign({}, MasterEmployeeList);
            if (MasterEmployeeListResponse.result === true) {
                setTeamMembers(MasterEmployeeListResponse.response);
            } else {
                switch (MasterEmployeeListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterEmployeeListUpdate, [props.MasterEmployeeList])

    const onMasterEditPlacementRightsUpdate = () => {
        const { MasterEditPlacementRights } = props
        if (MasterEditPlacementRights != null) {
            var MasterEditPlacementRightsResponse = Object.assign({}, MasterEditPlacementRights);
            $("#edit_general_placement").prop("disabled", false);
            if (MasterEditPlacementRightsResponse.result === true) {
                $("#general_placement_edit-form-loader").addClass("hidden");
                $("#edit_general_placement").prop("disabled", false);
                toast.success(MasterEditPlacementRightsResponse.message);
                props.handleClose(true);
            } else {
                $("#general_placement_edit-form-loader").addClass("hidden");
                $("#edit_general_placement").prop("disabled", false);
                switch (MasterEditPlacementRightsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case VALIDATION_ERROR:
                        toast.error(MasterEditPlacementRightsResponse.message);
                        props.handleClose(true);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterEditPlacementRightsUpdate, [props.MasterEditPlacementRights])

    const onSubmit = (data) => {
        if (mySelectedTeam != null) {
            $("#general_placement_edit-form-loader").removeClass("hidden");
            $("#edit_general_placement").prop("disabled", true);
            data.id = placementDetail?.id;
            data.user_ids = mySelectedTeam
            props.actionMasterEditPlacementRights(data);
        }
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Edit General Placements
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Department&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="department_id"
                                                                name="department_id"
                                                                dataSource={department}
                                                                allowFiltering={true}
                                                                placeholder="Select Department Type"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setValue('policy_type_id', null);
                                                                        props.actionMasterPolicyType({ 'department_id': event.itemData.id, status: "ACTIVE" });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Department Type'
                                                        }}
                                                    />
                                                    {errors.department_id && <ErrorLabel message={errors.department_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Policy&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="policy_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="policy_type_id"
                                                                name="policy_type_id"
                                                                dataSource={policyType}
                                                                allowFiltering={true}
                                                                placeholder="Select Type of Policy"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Type of Policy'
                                                        }}
                                                    />
                                                    {errors.policy_type_id && <ErrorLabel message={errors.policy_type_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="label-control">Select Team Member&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="user_ids"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                id="user_ids"
                                                                name="user_ids"
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: teamMembers,
                                                                        key: 'user_id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        ('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        ('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        ('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        ('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="full_name"
                                                                valueExpr="user_id"
                                                                placeholder="Select Team Member"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={5}
                                                                selectAllMode={true}
                                                                showSelectionControls={true}
                                                                acceptCustomValue={false}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value != null && field.value.length > 0 ? field.value : mySelectedTeam}
                                                                onValueChanged={({ value, event }) => {
                                                                    if ((value != null || value !== undefined) && value.length > 0) {
                                                                        setMySelectedTeam(value)
                                                                        field.onChange(value);
                                                                    } else {
                                                                        if (mySelectedTeam != null && mySelectedTeam.length > 0) {
                                                                            field.onChange("");
                                                                            setMySelectedTeam([])
                                                                        }
                                                                    }
                                                                    if (event != null) {
                                                                        if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                            field.onChange("");
                                                                            setMySelectedTeam([])
                                                                        }
                                                                    }
                                                                }}
                                                                onSelectionChanged={(e) => {
                                                                    if (e.addedItems.length > 0) {
                                                                        e.component.field().value = "";
                                                                        e.component.close();
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Team Member'
                                                        }}
                                                    />
                                                    {errors.user_ids && <ErrorLabel message={errors.user_ids?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        id: "ACTIVE",
                                                                        name: "ACTIVE"
                                                                    },
                                                                    {
                                                                        id: "INACTIVE",
                                                                        name: "INACTIVE"
                                                                    }
                                                                ]}
                                                                allowFiltering={true}
                                                                placeholder="Select status"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="edit_general_placement"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="general_placement_edit-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>

                                        <button
                                            id="cancel-placement"
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            type="button"
                                            onClick={(event) => {
                                                props.handleClose();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    var MasterDepartment = null;
    var MasterPolicyType = null;
    var MasterEmployeeList = null;
    var MasterEditPlacementRights = null;

    if (
        state.MasterEditPlacementRightReducer != null &&
        Object.keys(state.MasterEditPlacementRightReducer).length > 0 &&
        state.MasterEditPlacementRightReducer.constructor === Object
    ) {
        switch (state.MasterEditPlacementRightReducer.tag) {
            case ACTION_MASTER_GET_DEPARTMENT_SUCCESS:
                MasterDepartment = Object.assign({}, state.MasterEditPlacementRightReducer.data);
                delete state.MasterEditPlacementRightReducer;
                return { MasterDepartment };
            case ACTION_MASTER_GET_POLICY_TYPE_SUCCESS:
                MasterPolicyType = Object.assign({}, state.MasterEditPlacementRightReducer.data);
                delete state.MasterEditPlacementRightReducer;
                return { MasterPolicyType };
            case ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS:
                MasterEmployeeList = Object.assign({}, state.MasterEditPlacementRightReducer.data);
                delete state.MasterEditPlacementRightReducer;
                return { MasterEmployeeList };
            case ACTION_MASTER_EDIT_PLACEMENT_RIGHTS_SUCCESS:
                MasterEditPlacementRights = Object.assign({}, state.MasterEditPlacementRightReducer.data);
                delete state.MasterEditPlacementRightReducer;
                return { MasterEditPlacementRights };
            default:

        }
    }
    return {
        MasterDepartment,
        MasterPolicyType,
        MasterEmployeeList,
        MasterEditPlacementRights
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterDepartment, actionMasterPolicyType, actionMasterEmployeeList, actionMasterEditPlacementRights }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PlacementEdit))