import React, { useEffect, useState } from "react"
import ErrorLabel from "../../../component/form/error_label";
import { checkFileExtension, downloadFile } from '../../../utils';
import { DateBox } from 'devextreme-react/date-box';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from '../../../constants';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { actionConveyanceClaim, actionConveyanceClaimEdit, ACTION_CONVEYANCE_CLAIM_SUCCESS, ACTION_REIMBURSEMENT_CLAIM_EDIT_SUCCESS } from './action';
import { actionReimbursementMeetings, ACTION_REIMBURSEMENT_MEETINGS_SUCCESS } from './action';
import { actionReimbursementTransportType, ACTION_REIMBURSEMENT_TRANSPORT_TYPE_SUCCESS } from './action';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import DocumentIframe from "../../popups/document_iframe";

const ConveyanceClaim = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm({
        defaultValues: {
            'mode_id': ''
        }
    })
    const [objTransportItem, setObjTransportItem] = useState(null);
    const [isKm, setIsKm] = useState('N')
    const [kmRate, setKmRate] = useState(0);
    const validExt = ['jpg', 'jpeg', 'png', 'docx', 'doc', 'pdf', 'PDF', 'xlsx', 'csv', 'zip'];
    const [uploadDocument, setUploadDocument] = useState([]);
    const [remItemId, setRemItemId] = useState(null);
    const watchKm = watch("km", null);
    const [meetingIds, setMeetingIds] = useState(null);
    const [remMonth, setRemMonth] = useState(null);
    const [remYear, setRemYear] = useState(null);
    const [maxMonth, setMaxMonth] = useState(null);
    const [remMinAmount, setRemMinAmount] = useState(null);
    const [reimbursementMeetings, setReimbursementMeetings] = useState(null);
    const [reimbursementTransportType, setReimbursementTransportType] = useState([]);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [popup, setPopup] = useState(null)
    const [purpose, setPurpose] = useState(null)
    var watchBillDate = watch('bill_date');

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            props.actionReimbursementTransportType();

            setRemItemId(props.data != null ? props.data.details_id : '');
            if (props.month != null && props.year != null) {
                setRemMonth(Number(props.month) === 1 ? 12 : Number(props.month) - 1);
                setRemYear(Number(props.month) === 1 ? Number(props.year) - 1 : Number(props.year));
                setMaxMonth(Number(props.month) < 10 ? '0' + props.month : props.month);
            }

            if (props.claimDetail != null && props.claimDetail.reimbursement_min_amount != null) {
                setRemMinAmount(props.claimDetail.reimbursement_min_amount);
            }

            if (props?.conveyanceData === true) {
                setValue('bill_date', props.data != null ? props.data?.bill_date : '')
                setValue('km', props.data != null ? props.data.km : '');
                setValue('claim_amount', props.data != null ? Number(props.data.claim_amount).toFixed(2) : '');
                setValue('from_location', props.data != null ? props.data.from_location : '');
                setValue('to_location', props.data != null ? props.data.to_location : '');
                setValue('purpose', props.data != null ? props.data.purpose : '');
            } else {
                setReimbursementMeetings([])
            }
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setUploadDocument([]);
            setValue('bill_date', '');
            setValue('mode_id', null);
            setIsKm('N')
            setValue('km', '');
            setValue('claim_amount', '');
            setValue('from_location', '');
            setValue('to_location', '');
            setValue('meeting_id', '');
            setValue('purpose', '');
            setValue('mode_id', '');
            reset()
        }
    }
    useEffect(initialRender, [props.show, props?.dataa])

    useEffect(() => {
        if (watchKm != null && Number(kmRate) != 0 && Number(watchKm) != 0) {
            setValue('claim_amount', String(Number(Number(watchKm) * Number(kmRate)).toFixed(2)))
        }
    }, [watchKm, kmRate])

    useEffect(() => {
        if (purpose != null) {
            setValue('purpose', purpose)
        }
    }, [purpose])

    useEffect(() => {
        if (watchBillDate != null && moment(watchBillDate).isValid()) {
            props.actionReimbursementMeetings({ bill_date: moment(watchBillDate).format('YYYY-MM-DD') });
        }
    }, [watchBillDate])

    const onConveyanceClaimUpdate = () => {
        const { conveyanceClaim } = props;
        var ReimbursementConveyanceClaimResponse = Object.assign({}, conveyanceClaim);
        $("#conveyance_request").prop("disabled", false);
        if (ReimbursementConveyanceClaimResponse.result === true) {
            $("#add_conveyance_loader").addClass("hidden");
            props.handleClose(ReimbursementConveyanceClaimResponse.response);
            setMeetingIds(null);
            setMeetingIds('');
        } else {
            $("#add_conveyance_loader").addClass("hidden");
            switch (ReimbursementConveyanceClaimResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onConveyanceClaimUpdate, [props.conveyanceClaim]);

    useEffect(() => {
        if (reimbursementMeetings != null && reimbursementMeetings.length > 0) {
            if (props.data != null && props.data.meeting_id != null && remItemId && props?.conveyanceData === true) {
                setValue('meeting_id', props.data.meeting_id);
            } else {
                setValue('meeting_id', '');
            }
        }
    }, [reimbursementMeetings])

    const onReimbursementMeetingsUpdate = () => {
        const { ReimbursementMeetings } = props;
        var ReimbursementMeetingsResponse = Object.assign({}, ReimbursementMeetings);
        if (ReimbursementMeetingsResponse.result === true) {
            setReimbursementMeetings(ReimbursementMeetingsResponse.response);
        } else {
            switch (ReimbursementMeetingsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementMeetingsUpdate, [props.ReimbursementMeetings]);

    const onConveyanceClaiEditUpdate = () => {
        const { conveyanceClaimEdit } = props;
        var conveyanceClaimEditResponse = Object.assign({}, conveyanceClaimEdit);
        $("#conveyance_request").prop("disabled", false);
        if (conveyanceClaimEditResponse.result === true) {
            $("#add_conveyance_loader").addClass("hidden");
            props.handleClose(conveyanceClaimEditResponse.response);
        } else {
            $("#add_conveyance_loader").addClass("hidden");
            switch (conveyanceClaimEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onConveyanceClaiEditUpdate, [props.conveyanceClaimEdit]);

    useEffect(() => {
        if (reimbursementTransportType != null && reimbursementTransportType.length > 0) {
            if (props.data != null && props.data.mode_id != null && remItemId && props?.conveyanceData === true) {
                setValue('mode_id', props.data.mode_id);
            } else {
                setValue('mode_id', '');
            }
        }
    }, [reimbursementTransportType])

    const reimbursmentClaimLimit = () => {
        if (props.reimbursementTypeDetails != null) {
            let totalClaimAmount = props.reimbursementTypeDetails.total_claim_amount != null ? props.reimbursementTypeDetails.total_claim_amount : 0
            if (remItemId > 0 && props?.conveyanceData === true) {
                totalClaimAmount = totalClaimAmount - Number(props.data.claim_amount)
            }
            var remTypeLimit = props.reimbursementTypeDetails.type_limit - totalClaimAmount
            return remTypeLimit
        }

        return 0
    }
    const onReimbursementTransportTypeUpdate = () => {
        const { ReimbursementTransportType } = props;
        var ReimbursementTransportTypeResponse = Object.assign({}, ReimbursementTransportType);
        if (ReimbursementTransportTypeResponse.result === true) {
            setReimbursementTransportType(ReimbursementTransportTypeResponse.response);
        } else {
            switch (ReimbursementTransportTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementTransportTypeUpdate, [props.ReimbursementTransportType]);

    const onSubmit = (data) => {
        if (objTransportItem != null && objTransportItem === 'Y' && uploadDocument.length === 0 && props.conveyanceData === false) {
            $("#error-document").removeClass("d-none");
            return
        } else {
            $("#error-document").addClass("d-none");
        }
        if (remItemId > 0 && props?.conveyanceData === true) {
            $("#conveyance_request").prop("disabled", true);
            $("#add_conveyance_loader").removeClass("hidden");
            data.id = remItemId;
            data.reimbursement_id = props.reimbursementId;
            data.reimbursement_type_id = props.reimbursementTypeId;
            data.bill_date = data.bill_date;
            data.mode_id = data.mode_id;
            data.km = data.km;
            data.claim_amount = String(data.claim_amount);
            data.from_location = data.from_location;
            data.to_location = data.to_location;
            data.meeting_id = data.meeting_id;
            data.purpose = data.purpose;
            var files = [];
            if (uploadDocument != null) {
                files.push({ 'name': 'upload_document', 'value': uploadDocument });
            }
            delete data.upload_document;
            props.actionConveyanceClaimEdit(data, files)

        } else {
            data.reimbursement_id = props.reimbursementId;
            data.reimbursement_type_id = props.reimbursementTypeId;
            data.bill_date = data.bill_date;
            data.mode_id = data.mode_id;
            data.km = data.km;
            data.claim_amount = data.claim_amount;
            data.from_location = data.from_location;
            data.to_location = data.to_location;
            data.meeting_id = data.meeting_id;
            data.purpose = data.purpose;
            var files = [];
            if (uploadDocument != null) {
                files.push({ 'name': 'upload_document', 'value': uploadDocument });
                $("#conveyance_request").prop("disabled", true);
                $("#add_conveyance_loader").removeClass("hidden");
                props.actionConveyanceClaim(data, files);
            } else {
                $("#error-document").removeClass("d-none");
            }
            delete data.upload_document;
        }

    }
    return (

        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog  modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                {remItemId && props?.conveyanceData === true ? 'Edit' : 'Add New'} {props?.remTitle != null ? props?.remTitle : ''} Reimbursement
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                                $("#warning-claim").addClass('d-none')
                                $("#warning_edit_conveyance").addClass('d-none')
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <h4 class="d-flex form-section my-0 black">
                    </h4>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>
                                                        Date&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <Controller
                                                        name={'bill_date'}
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                id="bill_date"
                                                                name="bill_date"
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                value={field.value}
                                                                min={moment(`${remYear}-${remMonth}-01`).format('YYYY-MM-DD')}
                                                                max={Number(remMonth) === (moment().month()) ? moment().format('YYYY-MM-DD') :
                                                                    Number(remMonth) === 12 ? moment(`${remYear + 1}-01-01`).endOf('month').format('YYYY-MM-DD') : moment(`${remYear}-${maxMonth}-01`).endOf('month').format('YYYY-MM-DD')}
                                                                acceptCustomValue={false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                    setPurpose('')
                                                                    setValue('purpose', '');
                                                                    setMeetingIds('');
                                                                    setMeetingIds(null);
                                                                    setValue('meeting_id', null);
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Date'
                                                        }}
                                                    />
                                                    {errors.bill_date && <ErrorLabel message={errors.bill_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Mode&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <Controller
                                                        name="mode_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="mode_id"
                                                                name="mode_id"
                                                                dataSource={reimbursementTransportType}
                                                                fields={{ text: 'title', value: 'id' }}
                                                                allowFiltering={true}
                                                                filterType={"Contains"}
                                                                placeholder="Select Mode"
                                                                stylingMode="outlined"
                                                                value={field.value}
                                                                className="form-control"
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id);
                                                                        setObjTransportItem(event.itemData.is_document);
                                                                        setIsKm(event.itemData.is_km);
                                                                        setKmRate(event.itemData.km_rate);
                                                                    }
                                                                }}

                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Mode is required'
                                                        }}
                                                    />
                                                    {errors.mode_id && <ErrorLabel message={errors.mode_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        From&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="from_location"
                                                        name="from_location"
                                                        type="text"
                                                        placeholder="Enter From Location"
                                                        {...register('from_location', { required: 'From Location is required' })}
                                                    />
                                                    {errors.from_location && <ErrorLabel message={errors.from_location?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        To&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="to_location"
                                                        name="to_location"
                                                        type="text"
                                                        placeholder="Enter To Location"
                                                        {...register('to_location', { required: 'To Location is required' })}
                                                    />
                                                    {errors.to_location && <ErrorLabel message={errors.to_location?.message} />}
                                                </div>
                                            </div>
                                            {isKm === 'Y' ?
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">
                                                            Kilometer&nbsp;<span className="danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            id="km"
                                                            name="km"
                                                            type="text"
                                                            placeholder="Enter Kilometers"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            {...register('km', { required: 'Kilometer is required' })}
                                                        />
                                                        {errors.km && <ErrorLabel message={errors.km?.message} />}
                                                    </div>
                                                </div>
                                                :
                                                <></>}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Amount&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="claim_amount"
                                                        name="claim_amount"
                                                        type="text"
                                                        readOnly={isKm === 'Y' ? true : false}
                                                        placeholder="Enter Amount"
                                                        onKeyPress={(event) => {
                                                            if (!/^\d{0,2}(\.\d{0,1}){0,1}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register('claim_amount',
                                                            {
                                                                required: isKm !== 'Y' ? 'Amount is required' : '',
                                                                validate: (value) => {
                                                                    if (Number(value) < remMinAmount) {
                                                                        return `Amount should be greater than or equal to ${remMinAmount}`
                                                                    }
                                                                    let claimLimit = reimbursmentClaimLimit()
                                                                    if (Number(value) > claimLimit) {
                                                                        return `Only ${claimLimit} claim amount is pending`
                                                                    }
                                                                }
                                                            })}

                                                    />
                                                    {errors.claim_amount && <ErrorLabel message={errors.claim_amount?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Meeting ID&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <Controller
                                                        name="meeting_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={reimbursementMeetings}
                                                                id="meeting_id"
                                                                name="meeting_id"
                                                                fields={{ text: 'title', value: 'id' }}
                                                                allowFiltering={true}
                                                                filterType={"Contains"}
                                                                placeholder="Select Meeting ID"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData?.id)
                                                                        setMeetingIds(event.itemData?.id)
                                                                        if (event.itemData.id === props.data?.meeting_id && props?.conveyanceData === true && props?.data != null) {
                                                                            setPurpose(props?.data?.purpose)
                                                                        } else {
                                                                            setPurpose(event.itemData?.meeting_type)
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Meeting ID is required'
                                                        }}
                                                    />
                                                    {errors.meeting_id && <ErrorLabel message={errors.meeting_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Purpose&nbsp;<span className={`danger ${meetingIds === null ? 'show' : 'hidden'}`}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="purpose"
                                                        name="purpose"
                                                        type="text"
                                                        placeholder="Enter Purpose  for this claim"
                                                        {...register('purpose', { required: meetingIds === null ? 'Purpose is required' : '' })}
                                                    />
                                                    {errors.purpose && <ErrorLabel message={errors.purpose?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Attachment&nbsp; <span class={`text-danger ${objTransportItem != null && objTransportItem === 'Y' && uploadDocument.length === 0 && props.conveyanceData === false ? "show" : 'hidden'}`}>*</span></label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="upload_document"
                                                            accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .PDF, .xlsx, .csv, .zip"
                                                            name="upload_document"
                                                            onChange={(event) => {
                                                                $('#error-document').addClass('d-none')
                                                                if (event.target.files != null) {

                                                                    var fileSize = event.target.files[0]?.size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setUploadDocument(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg, png, docx, doc, pdf, PDF, xlsx, csv, zip files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                } else {
                                                                    $('#error-document').removeClass('d-none')
                                                                }
                                                            }}
                                                        />
                                                        <small className="error-msg d-none text-danger" id="error-document">Document is required</small>
                                                        <label class="custom-file-label" for="upload_document">
                                                            {
                                                                uploadDocument != null ?
                                                                    uploadDocument.name : "Choose file"
                                                            }
                                                        </label>
                                                        {
                                                            props.data != null && props.data?.reimbursement_document != null && props?.conveyanceData === true ?
                                                                <div id="view_document mt-1" >
                                                                    {/* <a className='primary' target="_blank" rel="noreferrer" href={props.data?.reimbursement_document}>View Document</a> */}
                                                                    <a className="primary"
                                                                        onClick={() => {
                                                                            if (props.data?.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'png' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'gif'
                                                                            ) {
                                                                                props.handleClose();
                                                                                setSelectedDocumentIndex(props.data?.reimbursement_document)
                                                                                setShowDocumentDetails(true);
                                                                                setExtension(props.data?.reimbursement_document?.split('.').pop())
                                                                                setDocumentDetails(props.data)
                                                                                setPopup(true)
                                                                            } else {
                                                                                downloadFile(props.data?.reimbursement_document);
                                                                            }
                                                                        }}
                                                                    >
                                                                        View Document
                                                                    </a>
                                                                    <a id="downloadLink" className="d-none"></a>
                                                                </div>
                                                                : <></>
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="conveyance_request"
                                            className="btn btn-primary btn-block mr-1 shadow text-center text-uppercase"
                                            type="submit"
                                        >
                                            {remItemId && props?.conveyanceData === true ? 'Update' : 'Add'}
                                            <span id="add_conveyance_loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                popup={popup}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    var conveyanceClaim = null;
    var conveyanceClaimEdit = null;
    var ReimbursementMeetings = null;
    var ReimbursementTransportType = null;

    if (
        state.ConveyanceClaimReducer != null &&
        Object.keys(state.ConveyanceClaimReducer).length > 0 &&
        state.ConveyanceClaimReducer.constructor === Object
    ) {
        switch (state.ConveyanceClaimReducer.tag) {
            case ACTION_CONVEYANCE_CLAIM_SUCCESS:
                conveyanceClaim = Object.assign({}, state.ConveyanceClaimReducer.data);
                delete state.ConveyanceClaimReducer;
                return { conveyanceClaim };
            case ACTION_REIMBURSEMENT_CLAIM_EDIT_SUCCESS:
                conveyanceClaimEdit = Object.assign({}, state.ConveyanceClaimReducer.data);
                delete state.ConveyanceClaimReducer;
                return { conveyanceClaimEdit };
            case ACTION_REIMBURSEMENT_MEETINGS_SUCCESS:
                ReimbursementMeetings = Object.assign({}, state.ConveyanceClaimReducer.data);
                delete state.ConveyanceClaimReducer;
                return { ReimbursementMeetings };
            case ACTION_REIMBURSEMENT_TRANSPORT_TYPE_SUCCESS:
                ReimbursementTransportType = Object.assign({}, state.ConveyanceClaimReducer.data);
                delete state.ConveyanceClaimReducer;
                return { ReimbursementTransportType };
            default:
        }
    }

    return {
        conveyanceClaim,
        conveyanceClaimEdit,
        ReimbursementMeetings,
        ReimbursementTransportType
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionConveyanceClaim, actionConveyanceClaimEdit, actionReimbursementMeetings, actionReimbursementTransportType }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ConveyanceClaim))
