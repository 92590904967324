import React, { useEffect, useState } from "react";
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED } from '../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { actionUpcomingMeeting, ACTION_UPCOMING_MEETING_SUCCESS } from './action';

const UpcomingMeetings = (props) => {

    const [upcomingMeeting, setUpcomingMeeting] = useState();

    const initialRender = () => {
        props.actionUpcomingMeeting({});
    }
    useEffect(initialRender, []);

    const onUpcomingMeetingUpdate = () => {
        const { UpcomingMeeting } = props;
        var UpcomingMeetingResponse = Object.assign({}, UpcomingMeeting);
        if (UpcomingMeetingResponse.result === true) {
            setUpcomingMeeting(UpcomingMeetingResponse.response)
        } else {
            switch (UpcomingMeetingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onUpcomingMeetingUpdate, [props.UpcomingMeeting]);

    const renderUI = () => {
        try {
            return (
                <>
                    <h4 className="text-black text-bold-600 mb-half">Upcoming Meetings</h4>
                    <div className="card dashboard-card-round mb-0 ">
                        <div className="card-body pt-1 px-1 pb-0">
                            <div className="row">
                                {
                                    upcomingMeeting != null && upcomingMeeting.length > 0 ?
                                        upcomingMeeting.map((meeting, indexMeeting) => (
                                            <div
                                                className="col-12 col-md-12 col-lg-12 cursor-pointer"
                                                onClick={() => {
                                                    switch (meeting.meeting_type) {
                                                        case 'internal':
                                                            props.history.push({ pathname: `/meetings/internal-meetings/view`, state: { internal_meeting_id: meeting.id } });
                                                            break;
                                                        case 'reinsurance':
                                                            props.history.push({ pathname: `/meetings/ri-meetings/view`, state: { ri_meeting: meeting.id } });
                                                            break;
                                                        case 'tpa':
                                                            props.history.push({ pathname: `/meetings/tpa-meetings/view`, state: { tpa_meeting_id: meeting.id } })
                                                            break;
                                                        case 'surveyor':
                                                            props.history.push({ pathname: `/meetings/serveyor-meetings/view`, state: { serveyor_meeting_id: meeting.id } })
                                                            break;
                                                        case 'lead':
                                                        case 'client':
                                                            props.history.push({ pathname: `/meetings/customer-meetings/view`, state: { customer_meeting: meeting } })
                                                            break;
                                                        case 'insurer':
                                                            props.history.push({ pathname: `/meetings/insurance-company-meetings/view`, state: { insurer_branch_id: meeting.id } })
                                                            break;
                                                        default:
                                                    }
                                                }}
                                            >
                                                <div className="card bg-blue bg-lighten-5 rounded mb-1">
                                                    <div className="card-body pt-half pb-1">
                                                        <div className="row">
                                                            <div className="col-2 col-md-2 col-lg-3">
                                                                <div className="d-flex flex-row justify-content-center align-items-center w-100">
                                                                    <div className="flex-column">
                                                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                                                            {
                                                                                meeting.day != null ?
                                                                                    meeting.day : ''
                                                                            }
                                                                        </h1>
                                                                    </div>
                                                                    <div className="flex-column">
                                                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                                                            {
                                                                                meeting.month != null ?
                                                                                    meeting.month : ''
                                                                            }
                                                                        </h6>
                                                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                                                            {
                                                                                meeting.year != null ?
                                                                                    meeting.year : ''
                                                                            }
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column mt-half">
                                                                    <div className="btn btn-success btn-sm px-2 mx-auto">
                                                                        <h6 className="font-small-3 text-bold-500 my-0">
                                                                            {
                                                                                meeting.status != null ?
                                                                                    meeting.status : ''
                                                                            }
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-10 col-md-8 col-lg-9">
                                                                <p className="font-small-3 text-bold-600 text-black mt-half mb-half">
                                                                    {
                                                                        meeting.company_name != null ?
                                                                            meeting.company_name
                                                                            : ""
                                                                    }
                                                                    {

                                                                        meeting.company_name === null && meeting.meeting_type == 'insurer' ?
                                                                            meeting.insurer_company_name
                                                                            :
                                                                            ""
                                                                    }
                                                                    {

                                                                        meeting.company_name === null && meeting.meeting_type == 'internal' ?
                                                                            "Internal Meeting"
                                                                            :
                                                                            ""
                                                                    }
                                                                </p>
                                                                <p className="font-small-3 card-text-esc mb-half">
                                                                    {
                                                                        meeting.agenda != null ?
                                                                            meeting.agenda : ''
                                                                    }
                                                                </p>
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <div className="flex-column rounded text-bold-600 pl-0">
                                                                        {
                                                                            meeting.from_time != null ?
                                                                                meeting.from_time : ''
                                                                        }
                                                                    </div>
                                                                    <div className="flex-column px-1">to</div>
                                                                    <div className="flex-column rounded text-bold-600 pl-0">
                                                                        {
                                                                            meeting.to_time != null ?
                                                                                meeting.to_time : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="col-12 col-md-12 col-lg-12 my-1">
                                            <h6 className="text-bold-500 text-center">No Meetings Found</h6>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )
        } catch (e) {
            return (
                <>
                    <h1>Error Upcoming Meeting</h1>
                </>
            )
        }
    }

    return (
        renderUI()
    );
}
const mapStateToProps = (state) => {
    var UpcomingMeeting = null;

    if (
        state.UpcomingMeetingReducer != null &&
        Object.keys(state.UpcomingMeetingReducer).length > 0 &&
        state.UpcomingMeetingReducer.constructor === Object
    ) {
        switch (state.UpcomingMeetingReducer.tag) {
            case ACTION_UPCOMING_MEETING_SUCCESS:
                UpcomingMeeting = Object.assign({}, state.UpcomingMeetingReducer.data);
                delete state.UpcomingMeetingReducer;
                return { UpcomingMeeting };
            default:

        }
    }
    return {
        UpcomingMeeting,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionUpcomingMeeting,
        },
        dispatch
    );
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(UpcomingMeetings))
