import { API_ACCOMPAINED_BY, API_GET_MASTER_USERBRANCH, API_TEAM_MASTER_ADD } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_TEAM_HEAD_LOADING = 'ACTION_TEAM_HEAD_LOADING';
export const ACTION_TEAM_HEAD_SUCCESS = 'ACTION_TEAM_HEAD_SUCCESS';

export const ACTION_GET_MASTER_USERBRANCH_LOADING = 'ACTION_GET_MASTER_USERBRANCH_LOADING';
export const ACTION_GET_MASTER_USERBRANCH_SUCCESS = 'ACTION_GET_MASTER_USERBRANCH_SUCCESS';

export const ACTION_TEAM_MASTER_ADD_LOADING = 'ACTION_TEAM_MASTER_ADD_LOADING';
export const ACTION_TEAM_MASTER_ADD_SUCCESS = 'ACTION_TEAM_MASTER_ADD_SUCCESS';

export function actionTeamHead() {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TEAM_HEAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TEAM_HEAD_LOADING, { loading: false, data }));
        });
}

export function actionUserBranchName(params) {
    return (dispatch, getState) =>
        Api.get(API_GET_MASTER_USERBRANCH).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_MASTER_USERBRANCH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_MASTER_USERBRANCH_LOADING, { loading: false, data }));
        });
}

export function actionTeamMasterAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_TEAM_MASTER_ADD,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TEAM_MASTER_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TEAM_MASTER_ADD_LOADING, { loading: false, data }));
        });
}