import { API_EMPLOYEE_LIST, API_CREATE_POST } from '../../../api/constants';
import Api from '../../../api';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_GET_EMPLOYEE_LIST_LOADING = 'ACTION_GET_EMPLOYEE_LIST_LOADING';
export const ACTION_GET_EMPLOYEE_LIST_SUCCESS = 'ACTION_GET_EMPLOYEE_LIST_SUCCESS';

export const ACTION_CREATE_POST_LOADING = 'ACTION_CREATE_POST_LOADING';
export const ACTION_CREATE_POST_SUCCESS = 'ACTION_CREATE_POST_SUCCESS';

export function actionGetEmployeeList() {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_EMPLOYEE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_EMPLOYEE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionCreatePost(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_CREATE_POST, params, files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CREATE_POST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CREATE_POST_LOADING, { loading: false, data }));
        });
}