import React, { useEffect } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import $ from "jquery";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";

const ParticipationTraining = (props) => {
    const { handleSubmit, control, formState: { errors }, setValue } = useForm();

    const initialRender = () => {
        if (props.show === true) {
            if (props?.statusName != null) {
                setValue('status', props?.statusName);
            }
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onSubmit = (data) => {
        data.status = data.status;
        props.handleClose(data);
    }

    return (
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Confirm Participation
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose({
                                    updated: false
                                });
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Participation Status&nbsp;<span className="danger">
                                                        *
                                                    </span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        id: "Confirm",
                                                                        name: "Confirm"
                                                                    },
                                                                    {
                                                                        id: "Tentative",
                                                                        name: "Tentative"
                                                                    },
                                                                    {
                                                                        id: "Not Attending",
                                                                        name: "Not Attending"
                                                                    },
                                                                ]}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Participation Status"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Participation Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose({
                                                    updated: false
                                                });
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ParticipationTraining