import { ACTION_GROUP_COMPANIES_SUCCESS } from "./action";
import createReducer from "../../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};


const TabGroupCompaniesReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_GROUP_COMPANIES_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_GROUP_COMPANIES_SUCCESS,
                loading: false
            });
        }
    }
);
export default TabGroupCompaniesReducer;