import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { UNAUTHORIZED } from '../../../../../../constants';
import { ACTION_OPPORTUNITY_QUESTION_LIST_SUCCESS, actionOpportunityQuestionList } from './action';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import moment from "moment";

const OpportunityQuestionsList = (props) => {
    const { match } = props;
    const initialRender = () => {
        props.actionOpportunityQuestionList({});
    }
    useEffect(initialRender, []);
    const [questionList, setQuestionList] = useState([])

    const [columns, setColumns] = useState([
        { dataField: "id", caption: "Id", visible: true },
        { dataField: "question", caption: "Title", visible: true },
        { dataField: "yes_value", caption: "Yes Probability", visible: true },
        { dataField: "no_value", caption: "No Probability", visible: true },
        { dataField: "created_date", caption: "Created date", visible: false },
        { dataField: "updated_date", caption: "Updated date", visible: false },
        { dataField: "created_date_formated", caption: "Created date", visible: true },
        { dataField: "updated_date_formated", caption: "Updated date", visible: true },
    ]);

    const renderUpdatedDate = (cell) => {
        return <div>{cell?.data?.updated_date_formated != null ? moment(cell?.data?.updated_date_formated).format('DD/MM/YYYY') : ''}</div>
    }
    const renderCreatedDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "id") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                        alignment="left"
                        width={100}
                    ></Column>

                );
            } else {
                if (objColumn.dataField === "question") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                            width={300}
                        ></Column>
                    )
                } else if (objColumn.dataField === "created_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderCreatedDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "updated_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderUpdatedDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>

                    )
                }
            }
        });
        return arrColumns;
    };

    const onOpportunityQuestionUpdate = () => {
        const { OpportunityQuestionList } = props
        var OpportunityQuestionListResponse = Object.assign({}, OpportunityQuestionList);
        if (OpportunityQuestionListResponse.result === true) {
            setQuestionList(OpportunityQuestionListResponse.response);
        } else {
            switch (OpportunityQuestionListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onOpportunityQuestionUpdate, [props.OpportunityQuestionList])

    return (

        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Opportunity Question</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push(`${match.url}/add`);
                                }}
                            >
                                Opportunity Question
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={questionList}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "Opportunity Question",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("created_date_formated", "visible", false)
                                    event.component.columnOption("created_date", "visible", true)
                                    event.component.columnOption("updated_date_formated", "visible", false)
                                    event.component.columnOption("updated_date", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("created_date_formated", "visible", true)
                                    event.component.columnOption("created_date", "visible", false)
                                    event.component.columnOption("updated_date_formated", "visible", false)
                                    event.component.columnOption("updated_date", "visible", true)
                                    event.component.endUpdate();
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var OpportunityQuestionList = null;
    if (
        state.OpportunityQuestionListReducer != null &&
        Object.keys(state.OpportunityQuestionListReducer).length > 0 &&
        state.OpportunityQuestionListReducer.constructor === Object
    ) {
        switch (state.OpportunityQuestionListReducer.tag) {
            case ACTION_OPPORTUNITY_QUESTION_LIST_SUCCESS:
                OpportunityQuestionList = Object.assign({}, state.OpportunityQuestionListReducer.data);
                delete state.OpportunityQuestionListReducer;
                return { OpportunityQuestionList };
            default:

        }
    }
    return {
        OpportunityQuestionList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionOpportunityQuestionList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(OpportunityQuestionsList))
