import Api from "../../../api";
import { API_ASSIGN_OPPORTUNITY } from '../../../api/constants';
import { API_ASSIGN_EDIT_PROPOSAL } from '../../../api/constants';
import { API_GET_SEARCH_LIST } from '../../../api/constants';
import { PRINT } from "../../../utils";
import { fetchSuccess, loading } from "../../../utils/action.util";

export const ACTION_MASTER_ASSIGN_PROPOSAL_LOADING = 'ACTION_MASTER_ASSIGN_PROPOSAL_LOADING';
export const ACTION_MASTER_ASSIGN_PROPOSAL_SUCCESS = 'ACTION_MASTER_ASSIGN_PROPOSAL_SUCCESS';

export const ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_LOADING = 'ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_LOADING';
export const ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_SUCCESS = 'ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_SUCCESS';

export const ACTION_GET_ASSIGNED_SEARCH_LIST_LOADING = 'ACTION_GET_ASSIGNED_SEARCH_LIST_LOADING';
export const ACTION_GET_ASSIGNED_SEARCH_LIST_SUCCESS = 'ACTION_GET_ASSIGNED_SEARCH_LIST_SUCCESS';

export function actionAssignProposalPool(params) {
    return (dispatch, getState) =>
        Api.post(API_ASSIGN_OPPORTUNITY,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_PROPOSAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_PROPOSAL_LOADING, { loading: false, data }));
        });
}
export function actionEditAssignProposalPool(params) {
    return (dispatch, getState) =>
        Api.post(API_ASSIGN_EDIT_PROPOSAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_LOADING, { loading: false, data }));
        });
}
export function actionGetAssignedSearchList() {
    return (dispatch, getState) =>
        Api.get(API_GET_SEARCH_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_ASSIGNED_SEARCH_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_ASSIGNED_SEARCH_LIST_LOADING, { loading: false, data }));
        });
}