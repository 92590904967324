import React, { useState } from 'react';
import { FiEye, FiUser } from 'react-icons/fi';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { Controller, useForm } from 'react-hook-form';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../../component/form/error_label";
import { connect } from 'react-redux';
import { RiLayoutGridFill } from 'react-icons/ri';
import { DateBox } from 'devextreme-react';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';

const DealsClosed = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const columns = [
        { dataField: "name", caption: "Name" },
        { dataField: "team", caption: "Team" },
        { dataField: "branch", caption: "Branch" },
        { dataField: "new_customer", caption: "New Customer" },
        { dataField: "existing_customer", caption: "Existing Customer" },
        { dataField: "internal_meeting", caption: "Internal Meeting" },
        { dataField: "accompanied_by", caption: "Accompanied By" },
        { dataField: "surveyor_meeting", caption: "Surveyor Meeting" },
        { dataField: "tpa_meeting", caption: "TPA Meeting" },
        { dataField: "total", caption: "Total" },
    ];
    const rows = [
        {
            name: "Preeti Singh",
            team: "Preeti Singh",
            branch: "Bangalore",
            new_customer: 0,
            existing_customer: 2,
            internal_meeting: 1,
            accompanied_by: 11,
            surveyor_meeting: 1,
            tpa_meeting: 0,
            total: 15
        },
        {
            name: "Ankita Kaur",
            team: "Preeti Singh",
            branch: "Bangalore",
            new_customer: 0,
            existing_customer: 0,
            internal_meeting: 1,
            accompanied_by: 11,
            surveyor_meeting: 1,
            tpa_meeting: 0,
            total: 13
        },
        {
            name: "Atul Garg",
            team: "Preeti Singh",
            branch: "Bangalore",
            new_customer: 0,
            existing_customer: 0,
            internal_meeting: 1,
            accompanied_by: 11,
            surveyor_meeting: 1,
            tpa_meeting: 0,
            total: 13
        },
    ];
    const [employeeDetails, setEmployeeDetails] = useState([
        {
            id: 1,
            title: "Neha"
        },
        {
            id: 2,
            title: "Pranali"
        }
    ]);
    const [fromDate, setFromDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [employeeId, setEmployeeId] = useState(null);
    const onSubmit = (data) => {
    }
    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Deals Closed</h5>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="flex-column">
                            <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                <button
                                    className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                    onClick={() => {
                                        props.history.goBack();
                                    }}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                       
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">From Date</label>
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                defaultValue={fromDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = new Date(value);
                                                            var objToDate = new Date(toDate);

                                                            if (objToDate.getTime() < objFromDate.getTime()) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "From date can't be greater than To date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">To Date</label>
                                            <Controller
                                                className="flex-column"
                                                name="to_date"
                                                control={control}
                                                defaultValue={toDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = new Date(fromDate);
                                                            var objToDate = new Date(value);

                                                            if (objToDate.getTime() < objFromDate.getTime()) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "To date must be greater than from date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Employee</label>
                                             <Controller
                                                name="employee_id"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: employeeDetails,
                                                                key: 'user_id',
                                                                
                                                                
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        allowFiltering={false}
                                                        deferRendering={true}
                                                        displayExpr='full_name'
                                                        placeholder="Select Employee Name"
                                                        searchEnabled={true}
                                                        valueExpr= 'user_id' 
                                                        searchMode="contains"
                                                        maxDisplayedTags={2}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                field.onChange(value);
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Employee Name'
                                                }}
                                            /> 
                                            
                                            {errors.employee_id && <ErrorLabel message={errors.employee_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="form-actions mt-1 border-0 float-none">
                                        <button className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                            onClick={(data) => {

                                            }}
                                        >Search</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={rows}
                                    showBorders={true}
                                    export={{
                                        fileName: "Deals Closed",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DealsClosed