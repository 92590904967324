import { ACTION_EDIT_CLIENT_NAME_SUCCESS, ACTION_EDIT_CLIENT_TYPE_SUCCESS, ACTION_EDIT_MASTER_CITIES_SUCCESS, ACTION_EDIT_MASTER_SALES_RM_SUCCESS, ACTION_EDIT_NEW_REQUEST_SUCCESS, ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS, ACTION_VIEW_NEW_REQUEST_SUCCESS } from './action'
import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditRiskListReducer = createReducer(INITIAL_STATE, {
    [ACTION_EDIT_NEW_REQUEST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_NEW_REQUEST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_VIEW_NEW_REQUEST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_VIEW_NEW_REQUEST_SUCCESS,
                loading: false
            })
    },
    [ACTION_EDIT_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_CLIENT_TYPE_SUCCESS,
                loading: false
            })
    },
    [ACTION_EDIT_CLIENT_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_CLIENT_NAME_SUCCESS,
                loading: false
            })
    },
    [ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS,
                loading: false
            })
    },
    [ACTION_EDIT_MASTER_SALES_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_MASTER_SALES_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_MASTER_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_MASTER_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
})
export default EditRiskListReducer
