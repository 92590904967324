import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { FiUser, FiTrash } from 'react-icons/fi';
import ErrorLabel from "../../../../../../component/form/error_label";
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import { actionEditPersonalInformation, ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS } from './action';
import { actionDeleteDocument, ACTION_DOCUMENT_DELETE_SUCCESS } from './action';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkFileExtension, downloadFile } from '../../../../../../utils';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import DocumentIframe from '../../../../../../component/popups/document_iframe';

const PersonalInformationEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm({});

    const [marriedStatus, setMarriedStatus] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [popup, setPopup] = useState(null)

    const validExt = ['jpg', 'jpeg', 'png'];

    const onEditPersonalInformationUpdate = () => {
        const { EditPersonalInformation } = props;
        var EditPersonalInformationResponse = Object.assign({}, EditPersonalInformation);
        $("#submit").prop("disabled", false);
        if (EditPersonalInformationResponse.result === true) {
            $("#personal-form-loader").addClass("hidden");
            setProfilePhoto(null);
            props.handleClose();
        } else {
            $("#personal-form-loader").addClass("hidden");
            switch (EditPersonalInformationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(EditPersonalInformationResponse.message);
                default:
            }
        }
    }
    useEffect(onEditPersonalInformationUpdate, [props.EditPersonalInformation]);

    const initialRender = () => {

        if (props.show === true) {

            setValue('father_name', (props.data.father_name != null ? props.data.father_name : ''));
            setValue('blood_group', (props.data.blood_group != null ? props.data.blood_group : ''));
            // setValue('married_status',( props.data.married_status !== null ? props.data.married_status : '' ));
            setValue('birthday_place', (props.data.birthday_place != null ? props.data.birthday_place : ''));
            setValue('description', (props.data.description != null ? props.data.description : ''));
            setValue('personal_email', (props.data.personal_email != null ? props.data.personal_email : ''));
            setValue('alternate_email', (props.data.alternate_email != null ? props.data.alternate_email : ''));
            setValue('contact', (props.data.contact != null ? props.data.contact : ''));
            setValue('communication_address', (props.data.communication_address != null ? props.data.communication_address : ''));
            setValue('emergency_contact_person', (props.data.emergency_contact_person != null ? props.data.emergency_contact_person : ''));
            setValue('permanent_address', (props.data.permanent_address != null ? props.data.permanent_address : ''));
            setValue('emergency_number', (props.data.emergency_number != null ? props.data.emergency_number : ''));
            setValue('facebook', (props.data.facebook != null ? props.data.facebook : ''));
            setValue('linkedin', (props.data.linkedin != null ? props.data.linkedin : ''));
            setValue('twitter', (props.data.twitter != null ? props.data.twitter : ''));
            setValue('gender', (props.data.gender != null ? props.data.gender : ''));
            setValue('number_of_child', (props.data.number_of_child != null ? props.data.number_of_child : ''));
            setValue('spouse_name', (props.data.spouse_name != null ? props.data.spouse_name : ''));

            if (props.data.married_status != null) {
                setValue('married_status', (props.data.married_status != null ? props.data.married_status : ''));
                setMarriedStatus(props.data.married_status);
            }

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onMyProfileDocumentDeleteUpdate = () => {
        const { DocumentDelete } = props
        var DocumentDeleteResponse = Object.assign({}, DocumentDelete)
        if (DocumentDeleteResponse.result === true) {
            $('#view_document').addClass('hidden')
        } else {
            switch (DocumentDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMyProfileDocumentDeleteUpdate, [props.DocumentDelete])

    const onSubmit = (data) => {

        if (data.blood_group.length === 0) {
            delete data.blood_group;
        }

        data.user_id = props.auth.getUser().user_id;

        var files = [];
        if (profilePhoto != null) {
            files.push({ 'name': 'profile_photo', 'value': profilePhoto });
        }

        $("#personal-form-loader").removeClass("hidden");
        $("#submit").prop("disabled", true);
        props.actionEditPersonalInformation(data, files);
    }

    return (
        <>
            <div
                className="modal fade show"
                // className="modal fade bd-example-modal-lg"
                id="default"
                role="dialog"
                aria-labelledby="personal-information-edit"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="personal-information-edit">
                                    Edit Personal Information
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <div><FiUser size={16} />&nbsp;Personal Information</div> </h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Father Name</label>
                                                        <input
                                                            className="form-control"
                                                            id="father_name"
                                                            name="father_name"
                                                            placeholder="Enter Father Name "
                                                            {...register('father_name', {
                                                                // required: 'Father Name is required' 
                                                            })}
                                                        />
                                                        {errors.father_name && <ErrorLabel message={errors.father_name?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Birthday Place</label>
                                                        <input
                                                            className="form-control"
                                                            id="birthday_place"
                                                            name="birthday_place"
                                                            placeholder="Enter Birthday Place"
                                                            {...register('birthday_place', {
                                                                // required: 'Birthday Place is required' 
                                                            })}
                                                        />
                                                        {errors.birthday_place && <ErrorLabel message={errors.birthday_place?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control"> Gender</label>

                                                        <Controller
                                                            name="gender"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={[
                                                                        {
                                                                            value: 'male',
                                                                            text: 'Male'
                                                                        },
                                                                        {
                                                                            value: 'female',
                                                                            text: 'Female'
                                                                        }
                                                                    ]}
                                                                    enabled={false}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Gender"
                                                                    fields={{ text: 'text', value: 'value' }}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                // required: 'Please Select Gender'
                                                            }}
                                                        />
                                                        {errors.gender && <ErrorLabel message={errors.gender?.message} />}

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Blood Group</label>
                                                        <Controller
                                                            name="blood_group"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={[
                                                                        {
                                                                            value: 'A+',
                                                                            text: 'A+'
                                                                        },
                                                                        {
                                                                            value: 'A-',
                                                                            text: 'A-'
                                                                        },
                                                                        {
                                                                            value: 'B+',
                                                                            text: 'B+'
                                                                        },
                                                                        {
                                                                            value: 'B-',
                                                                            text: 'B-'
                                                                        },
                                                                        {
                                                                            value: 'O+',
                                                                            text: 'O+'
                                                                        },
                                                                        {
                                                                            value: 'O-',
                                                                            text: 'O-'
                                                                        },
                                                                        {
                                                                            value: 'AB+',
                                                                            text: 'AB+'
                                                                        },
                                                                        {
                                                                            value: 'AB-',
                                                                            text: 'AB-'
                                                                        }
                                                                    ]}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Blood Group"
                                                                    value={field.value}
                                                                    fields={{ text: 'text', value: 'value' }}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                // required: 'Please Select Blood Group'
                                                            }}
                                                        />

                                                        {errors.blood_group && <ErrorLabel message={errors.blood_group?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Married Status</label>

                                                        <Controller
                                                            name="married_status"
                                                            className="form-control"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={[
                                                                        {
                                                                            value: 'not-married',
                                                                            text: 'Single'
                                                                        },
                                                                        {
                                                                            value: 'married',
                                                                            text: 'Married'
                                                                        }
                                                                    ]}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Married Status"
                                                                    value={field.value}
                                                                    fields={{ text: 'text', value: 'value' }}
                                                                    change={(event) => {

                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.value)
                                                                            setMarriedStatus(event.itemData.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                // required: 'Married Status is required'
                                                            }}
                                                        />
                                                        {errors.married_status && <ErrorLabel message={errors.married_status?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Description</label>
                                                        <input
                                                            className="form-control"
                                                            id="description"
                                                            name="description"
                                                            placeholder="Enter Description"
                                                            {...register('description', {
                                                                // required: 'Description is required' 
                                                            })}
                                                        />
                                                        {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                    </div>
                                                </div>

                                                <div className={`col-12 col-md-6 col-lg-6 ${marriedStatus === "married" ? '' : 'hidden'}`}>
                                                    <div className="form-group">
                                                        <label className="label-control">Spouse Name</label>
                                                        <input
                                                            className="form-control"
                                                            id="spouse_name"
                                                            name="spouse_name"
                                                            placeholder="Enter Spouse Name"
                                                            {...register('spouse_name')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={`col-12 col-md-6 col-lg-6 ${marriedStatus === "married" ? '' : 'hidden'}`}>
                                                    <div className="form-group">
                                                        <label className="label-control">Number of Children</label>
                                                        <input
                                                            className="form-control"
                                                            id="number_of_child"
                                                            name="number_of_child"
                                                            placeholder="Enter Number of Children"
                                                            {...register('number_of_child')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <div><FiUser size={16} />&nbsp;Contact Information</div> </h4>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Email</label>
                                                        <input
                                                            className="form-control"
                                                            id="personal_email"
                                                            name="personal_email"
                                                            placeholder="Enter Your Email"
                                                            {...register('personal_email', {
                                                                // required: 'Email is required' 
                                                            })}
                                                        />
                                                        {errors.email && <ErrorLabel message={errors.email?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Alternate Email</label>
                                                        <input
                                                            className="form-control"
                                                            id="alternate_email"
                                                            name="alternate_email"
                                                            placeholder="Enter Alternate Email"
                                                            {...register('alternate_email', {
                                                                // required: 'Alternate Email is required' 
                                                            })}
                                                        />
                                                        {errors.alternate_email && <ErrorLabel message={errors.alternate_email?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Phone Number</label>
                                                        <input
                                                            className="form-control"
                                                            id="contact"
                                                            name="contact"
                                                            placeholder="Enter Your Phone Number"
                                                            {...register('contact', {
                                                                // required: 'Phone Number is required' 
                                                            })}
                                                        />
                                                        {errors.contact && <ErrorLabel message={errors.contact?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <div><FiUser size={16} />&nbsp;Address Information</div> </h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Communication Address</label>
                                                        <input
                                                            className="form-control"
                                                            id="communication_address"
                                                            name="communication_address"
                                                            placeholder="Enter Communication Address"
                                                            {...register('communication_address', {
                                                                // required: 'Communication Address is required' 
                                                            })}
                                                        />
                                                        {errors.communication_address && <ErrorLabel message={errors.communication_address?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Permanent Address</label>
                                                        <input
                                                            className="form-control"
                                                            id="permanent_address"
                                                            name="permanent_address"
                                                            placeholder="Enter Permanent Address"
                                                            {...register('permanent_address', {
                                                                // required: 'Permanent Address is required' 
                                                            })}
                                                        />
                                                        {errors.permanent_address && <ErrorLabel message={errors.permanent_address?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <div><FiUser size={16} />&nbsp;Emergency Details</div> </h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Emergency Name</label>
                                                        <input
                                                            className="form-control"
                                                            id="emergency_contact_person"
                                                            name="emergency_contact_person"
                                                            placeholder="Enter Emergency Name"
                                                            {...register('emergency_contact_person', {
                                                                // required: 'Emergency Name is required' 
                                                            })}
                                                        />
                                                        {errors.emergency_contact_person && <ErrorLabel message={errors.emergency_contact_person?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Emergency Mobile</label>
                                                        <input
                                                            className="form-control"
                                                            id="emergency_number"
                                                            name="emergency_number"
                                                            placeholder="Enter Emergency Mobile"
                                                            {...register('emergency_number', {
                                                                // required: 'Emergency Mobile is required' 
                                                            })}
                                                        />
                                                        {errors.emergency_number && <ErrorLabel message={errors.emergency_number?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <div><FiUser size={16} />&nbsp;Social Network</div> </h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Facebook</label>
                                                        <input
                                                            className="form-control"
                                                            id="facebook"
                                                            name="facebook"
                                                            placeholder="Facebook Account"
                                                            {...register('facebook', {
                                                                // required: 'Facebook is required' 
                                                                validate: (value) => {
                                                                    if (value.length > 0 && value.search(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/) == -1) {
                                                                        return "Please enter Valid Url"
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                        {errors.facebook && <ErrorLabel message={errors.facebook?.message} />}
                                                    </div>

                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Linkedin</label>
                                                        <input
                                                            className="form-control"
                                                            id="linkedin"
                                                            name="linkedin"
                                                            placeholder="Linkedin Account"
                                                            {...register('linkedin', {
                                                                // required: 'Linkedin is required' 
                                                                validate: (value) => {
                                                                    if (value.length > 0 && value.search(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/) == -1) {
                                                                        return "Please enter Valid Url"
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                        {errors.linkedin && <ErrorLabel message={errors.linkedin?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Twitter</label>
                                                        <input
                                                            className="form-control"
                                                            id="twitter"
                                                            name="twitter"
                                                            placeholder="Twitter Account"
                                                            {...register('twitter', {
                                                                // required: 'Twitter is required' 
                                                                validate: (value) => {
                                                                    if (value.length > 0 && value.search(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/) == -1) {
                                                                        return "Please enter Valid Url"
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                        {errors.twitter && <ErrorLabel message={errors.twitter?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <div><FiUser size={16} />&nbsp;Profile Photo</div> </h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Profile Photo</label>
                                                        <div class="custom-file">
                                                            <input
                                                                type="file"
                                                                class="custom-file-input"
                                                                accept=".jpg, .jpeg, .png"
                                                                id="profile_photo"
                                                                name="profile_photo"
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.files
                                                                    ) {

                                                                        var fileSize = event.target.files[0]?.size;

                                                                        var ext = (event.target.files[0]?.name).split('.').pop();

                                                                        if (ext != null && checkFileExtension(ext.toLowerCase(), validExt)) {

                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                $("#progress-loader").addClass("hidden");
                                                                                setProfilePhoto(event.target.files[0]);
                                                                            } else {
                                                                                $("#progress-loader").addClass("hidden");
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'You can only upload jpg, jpeg & png files',
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label class="custom-file-label" for="visiting_card_front">
                                                                {
                                                                    profilePhoto != null ?
                                                                        profilePhoto.name : "Choose file"
                                                                }
                                                            </label>
                                                            <div className="mt-1">
                                                                {
                                                                    (props.data != null && props.data.profile_picture != null) ?
                                                                        <div id="view_document">
                                                                            {/* <a target="_blank" href={props.data.profile_picture}>View Photo</a> */}
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (props.data.profile_picture?.split('.').pop() == 'pdf' ||
                                                                                        props.data.profile_picture?.split('.').pop() == 'PDF' ||
                                                                                        props.data.profile_picture?.split('.').pop() == 'png' ||
                                                                                        props.data.profile_picture?.split('.').pop() == 'jpg' ||
                                                                                        props.data.profile_picture?.split('.').pop() == 'jpeg' ||
                                                                                        props.data.profile_picture?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(props.data.profile_picture)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(props.data.profile_picture?.split('.').pop())
                                                                                        setDocumentDetails(props.data)
                                                                                        setPopup(true)
                                                                                    } else {
                                                                                        downloadFile(props.data.profile_picture);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View Photo
                                                                            </a>
                                                                            <FiTrash
                                                                                onClick={() => {
                                                                                    Swal.fire({
                                                                                        icon: 'warning',
                                                                                        text: 'Are you sure, you want to delete this document?',
                                                                                        cancelButtonText: "Cancel",
                                                                                        showCancelButton: true,
                                                                                    }).then(({ isConfirmed }) => {
                                                                                        if (isConfirmed) {
                                                                                            props.actionDeleteDocument({
                                                                                                "dms_id": props.data.profile_pic_dms_id,
                                                                                                "type": "profile_photo",
                                                                                                "id": props.data.user_id
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}
                                                                                size={"16"}
                                                                                cursor={"pointer"}
                                                                                className="ml-half"
                                                                            ></FiTrash>
                                                                        </div>
                                                                        : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                id="submit"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    Submit
                                                    <span id="personal-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    props.handleClose();
                                                    event.preventDefault();
                                                }}
                                            > Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                popup={popup}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    var EditPersonalInformation = null;
    var DocumentDelete = null;

    if (
        state.EditPersonalInformationReducer != null &&
        Object.keys(state.EditPersonalInformationReducer).length > 0 &&
        state.EditPersonalInformationReducer.constructor === Object
    ) {
        switch (state.EditPersonalInformationReducer.tag) {
            case ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS:
                EditPersonalInformation = Object.assign({}, state.EditPersonalInformationReducer.data);
                delete state.EditPersonalInformationReducer;
                return { EditPersonalInformation };
            case ACTION_DOCUMENT_DELETE_SUCCESS:
                DocumentDelete = Object.assign({}, state.EditPersonalInformationReducer.data);
                delete state.EditPersonalInformationReducer;
                return { DocumentDelete }
            default:
        }
    }

    return {
        EditPersonalInformation,
        DocumentDelete
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionEditPersonalInformation, actionDeleteDocument }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PersonalInformationEdit))
