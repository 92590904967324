import createReducer from "../../../../../reducers/createReducer";
import { ACTION_PROPOSALPOOL_LIST_SUCCESS, ACTION_LIST_TEAM_VERTICAL_SUCCESS, ACTION_ADD_POLICY_SUCCESS, ACTION_DATE_TYPE_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ProposalPoolListReducer = createReducer(INITIAL_STATE, {
    [ACTION_PROPOSALPOOL_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROPOSALPOOL_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_LIST_TEAM_VERTICAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LIST_TEAM_VERTICAL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADD_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DATE_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DATE_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
}
)
export default ProposalPoolListReducer