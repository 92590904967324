import React, { useState, useEffect } from 'react';
import { FiEye } from 'react-icons/fi';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
} from "devextreme-react/data-grid";
import CustomStore from 'devextreme/data/custom_store';
import { withAuth } from 'react-auth-guard';
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RiAddBoxLine } from 'react-icons/ri';
import ClientPolicyDetails from '../../../../../../../component/popups/policies';
import { actionPinc, ACTION_PINC_SUCCESS } from './action';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../../utils/preferences';
import _ from "lodash";
/** API Calling */
import Api from '../../../../../../../api';
import { API_POLICIES } from '../../../../../../../api/constants';
import { isNotEmpty } from '../../../../../../../utils';
import { verifyPermission } from '../../../../../../../utils';

const PincPolicyList = (props) => {

    const [showPolicyView, setShowPolicyView] = useState(false);
    const [clientPolicyDetails, setClientPolicyDetails] = useState(null);

    const [type, setType] = useState('PINC');
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    const policyColumn = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "is_non_mandate_policy", caption: "Non Mandate/Mandate" },
        { dataField: "customer_name", caption: "Customer Name" },
        { dataField: "control_no", caption: "Control No." },
        { dataField: "policy_no", caption: "Policy No." },
        { dataField: "policy_type", caption: "Policy Type" },
        { dataField: "sum_insured", caption: "Sum Insured" },
        { dataField: "brokerage_amount", caption: "Brokerage Amount" },
        { dataField: "policy_expiry_date", caption: "Expiry Date" },
        { dataField: "insurer_name", caption: "Insurer Name" },
    ];

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
    }
    useEffect(initialRender, []);

    const store = new CustomStore({
        key: 'policy_no',
        load(loadOptions) {
            // var searchObj = [];
            let params = '?';
            [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }

                // if( i == 'filter' && isNotEmpty(loadOptions[i]) ) { 
                //     if( loadOptions[i].length > 1 ) { 
                //         for( var intOption = 0; intOption < loadOptions[i].length; intOption++ ) { 
                //             if(loadOptions[i][intOption] instanceof Array) { 
                //                 searchObj[loadOptions[i][intOption][0]] = loadOptions[i][intOption][2];
                //             }
                //         }
                //     }
                // }

            });
            params = params.slice(0, -1);

            var request = {};

            if (type === 'PINC') {
                request = {
                    is_non_mandate_policy: 1,
                    policy_type: 'policy',
                    params: params
                };
            } else if (type === 'Mandate') {
                request = {
                    is_non_mandate_policy: 0,
                    policy_type: 'policy',
                    params: params
                };
            } else {
                request = {
                    policy_type: 'policy',
                    params: params
                };
            }

            if (search.length > 0) {
                request.search = search;
            }

            return Api.post(API_POLICIES + params, request).then((res) => {
                $("#progress-loader").addClass("hidden");
                setTotalCount(res.response.totalCount);

                return res.response;
            }).catch((reason) => {
                $("#progress-loader").addClass("hidden");
            });
        },
    });

    const renderExpiryDate = (cell) => {
        return <div>{dateFormat(cell.data.policy_expiry_date, 'dd/mm/yyyy')}</div>;
    }

    const renderIsNonMandatePolicy = (cell) => {
        return <div>{cell.data.is_non_mandate_policy === 1 ? 'Non Mandate' : 'Mandate'}</div>;
    }

    const renderCustomerName = (cell) => {
        return <div>{_.truncate(cell.data.customer_name)}</div>
    }

    const renderSumInsured = (cell) => {
        return <div>{Number(cell.data.sum_insured).toFixed(2)}</div>
    }

    const renderPoliciesColumns = () => {
        var arrColumns = [];
        policyColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("VIEW_CLAIM_PINC") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm px-1 float-left"
                                            onClick={() => {
                                                setShowPolicyView(true);
                                                setClientPolicyDetails(params.data)
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("CLAIM_REGISTER_PINC") ?
                                <Button
                                    render={(params) => (
                                        <>
                                            {
                                                type === 'Mandate' ? 
                                                    <button
                                                    className="btn btn-icon btn-sm float-left"
                                                    title="Register Mandate Claim"
                                                    onClick={() => {
                                                        props.history.push({ 
                                                            pathname: `/claims/register/pinc`, 
                                                            state: { 
                                                                control_no: params.data.control_no, 
                                                                policy_id: params.data.id,
                                                                type: type
                                                            }})
                                                    }}
                                                >
                                                    <RiAddBoxLine size={18} className="primary" />
                                                </button>
                                                :
                                                <button
                                                    className="btn btn-icon btn-sm float-left"
                                                    title="Register PINC Claim"
                                                    onClick={() => {
                                                        props.history.push({ 
                                                            pathname: `/claims/register/pinc`, 
                                                            state: { 
                                                                control_no: params.data.control_no, 
                                                                policy_id: params.data.id,
                                                                type: type
                                                            }})
                                                    }}
                                                >
                                                    <RiAddBoxLine size={18} className="primary" />
                                                </button>

                                            }
                                            
                                        </>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            }
            else if (objColumn.dataField === "policy_expiry_date") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderExpiryDate}
                    ></Column>
                );
            } else if (objColumn.dataField === "is_non_mandate_policy") {
                if (type === 'All') {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderIsNonMandatePolicy}
                        ></Column>
                    );
                }
            } else if (objColumn.dataField === "customer_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCustomerName}
                    ></Column>
                );
            } else if( objColumn.dataField === 'control_no' ) { 
                arrColumns.push(
                    <Column
                        alignment={'left'}
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }else if (objColumn.dataField === "sum_insured") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSumInsured}
                    ></Column>
                );
            } 
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };
    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                id="btn-pinc-policies"
                                className={`btn btn-md ${type === 'PINC' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round`}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType('PINC');
                                    setTotalCount(0)
                                }}
                            >
                                PINC Policies {type === 'PINC' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                id="btn-mandate-policies"
                                className={`btn btn-md ${type === 'Mandate' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType('Mandate');
                                    setTotalCount(0);
                                }}
                            >
                                Mandate Policies {type === 'Mandate' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                id="btn-all-policies"
                                className={`btn btn-md ${type === 'All' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType('All');
                                    setTotalCount(0);
                                }}
                            >
                                All Policies {type === 'All' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }
    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            <li class="nav-item ml-6">
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                    onClick={() => {
                                        props.history.push(`/claims/register/mandate`);
                                    }}
                                >
                                    Add Mandate Policy
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <fieldset>
                                    <input
                                        type="text"
                                        className="float-right form-control form-control-sm col-5 col-lg-5 col-md-5"
                                        placeholder="Search by Customer Name, Control No., Policy No."
                                        onChange={(event) => {
                                            if (event.target.value.length > 3) {
                                                setSearch(event.target.value)
                                            }
                                            if (event.target.value.length === 0) {
                                                setSearch('')
                                            }
                                        }} />
                                </fieldset>
                            </div>
                            <div className="card-body">
                                <DataGrid
                                    dataSource={store}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    // export={{
                                    //     fileName: "Policy Details",
                                    //     enabled: true,
                                    //     allowExportSelectedData: true,
                                    //     excelFilterEnabled: true,
                                    // }}
                                    remoteOperations={true}
                                >
                                    {/* <SearchPanel visible={true} /> */}
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={false} />
                                    {renderPoliciesColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                                <button class="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={() => { props.history.goBack() }}> Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ClientPolicyDetails
                tab={1}
                claim={1}
                show={showPolicyView}
                clientPolicyDetails={clientPolicyDetails}
                handleClose={(register) => {
                    setShowPolicyView(false);
                    if (register != null) {
                        props.history.push({
                            pathname: `/claims/register/pinc`,
                            state: { control_no: clientPolicyDetails.control_no, policy_id: clientPolicyDetails.id }
                        })
                    }
                }}
            />
        </>
    )
}
function mapStateToProps(state) {

    var ClaimsPinc = null;

    if (
        state.PincReducer != null &&
        Object.keys(state.PincReducer).length > 0 &&
        state.PincReducer.constructor === Object
    ) {
        switch (state.PincReducer.tag) {

            case ACTION_PINC_SUCCESS:
                ClaimsPinc = Object.assign({}, state.PincReducer.data);
                delete state.PincReducer;
                return { ClaimsPinc };
            default:
        }
    }
    return {
        ClaimsPinc
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionPinc }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PincPolicyList));