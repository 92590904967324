import React, { useEffect, useState } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { actionFinanceLimitAdd, ACTION_FINANCE_LIMIT_ADD_SUCCESS } from './action'
import { actionFinanceMasterEntity, ACTION_FINANCE_LIMIT_ENTITY_SUCCESS } from './action';
import { actionFinanceMasterDepartment, ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS } from './action';
import { actionFinanceMasterDesignation, ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS } from './action';
import { actionGetReimbursementType, ACTION_REIMBURSEMENT_TYPE_SUCCESS } from './action';

const Add = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [masterEntity, setMasterEntity] = useState([]);
    const [masterDepartment, setMasterDepartment] = useState([]);
    const [masterDesignation, setMasterDesignation] = useState([]);
    const [reimbursementType, setReimbursementType] = useState([]);

    const initialRender = () => {
        props.actionFinanceMasterEntity();
        props.actionFinanceMasterDesignation();
        props.actionGetReimbursementType();
    }
    useEffect(initialRender, []);

    const onFinanceLimitAddUpdate = () => {
        const { FinanceLimitAdd } = props;
        var FinanceLimitAddResponse = Object.assign({}, FinanceLimitAdd);
        if (FinanceLimitAddResponse.result === true) {
            props.history.goBack();
        } else {
            switch (FinanceLimitAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        text: FinanceLimitAddResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break
                default:
            }
        }
    }
    useEffect(onFinanceLimitAddUpdate, [props.FinanceLimitAdd]);

    const onFinanceMasterEntityUpdate = () => {
        const { FinanceEntity } = props;
        var FinanceEntityResponse = Object.assign({}, FinanceEntity);
        if (FinanceEntityResponse.result === true) {
            setMasterEntity(FinanceEntityResponse.response);
        } else {
            switch (FinanceEntityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceMasterEntityUpdate, [props.FinanceEntity]);

    const onFinanceMasterDepartmentUpdate = () => {
        const { FinanceDepartment } = props;
        var FinanceDepartmentResponse = Object.assign({}, FinanceDepartment);
        if (FinanceDepartmentResponse.result === true) {
            setMasterDepartment(FinanceDepartmentResponse.response);

        } else {
            switch (FinanceDepartmentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceMasterDepartmentUpdate, [props.FinanceDepartment]);

    const onFinanceMasterDesignationUpdate = () => {
        const { FinanceDesignation } = props;
        var FinanceDesignationResponse = Object.assign({}, FinanceDesignation);
        if (FinanceDesignationResponse.result === true) {
            setMasterDesignation(FinanceDesignationResponse.response);
        } else {
            switch (FinanceDesignationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceMasterDesignationUpdate, [props.FinanceDesignation]);

    const onReimbursementTypeUpdate = () => {
        const { ReimbursementType } = props;
        var ReimbursementTypeResponse = Object.assign({}, ReimbursementType);
        if (ReimbursementTypeResponse.result === true) {
            setReimbursementType(ReimbursementTypeResponse.response);
        } else {
            switch (ReimbursementTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementTypeUpdate, [props.ReimbursementType]);

    const onSubmit = (data) => {
        data.rem_type = reimbursementType.map((objRem, index) => ({ type_id: objRem.id, limit: objRem.value }));
        props.actionFinanceLimitAdd(data);
    }

    return (
        <>
            <ScreenTitle title="Add Reimbursement Limit" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="entity_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterEntity}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Entity"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        var arrEntityIds = [];
                                                                        arrEntityIds.push(event.itemData.id);
                                                                        props.actionFinanceMasterDepartment({ 'company_ids': arrEntityIds });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Entity'
                                                        }}
                                                    />
                                                    {errors.entity_id && <ErrorLabel message={errors.entity_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Department&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="department_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterDepartment}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Department"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        var arrDepartmentIds = [];
                                                                        arrDepartmentIds.push(event.itemData.id);

                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Department'
                                                        }}
                                                    />
                                                    {errors.department_id && <ErrorLabel message={errors.department_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Designation&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="designation_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterDesignation}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Designation"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        var arrDesignationIds = [];
                                                                        arrDesignationIds.push(event.itemData.id);


                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Designation'
                                                        }}
                                                    />
                                                    {errors.designation_id && <ErrorLabel message={errors.designation_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        title: "Active",
                                                                        id: 'A'
                                                                    },
                                                                    {
                                                                        title: "Inactive",
                                                                        id: 'I'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Reimbursement Type</th>
                                                            <th>Limit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            reimbursementType.map((item, index) => (
                                                                <tr>
                                                                    <td>{item.title}</td>
                                                                    <td>
                                                                        <fieldset className="form-group">
                                                                            <Controller
                                                                                id={"limit"}
                                                                                name={`rem_type[${index}].limit`}
                                                                                control={control}
                                                                                render={({ field }) =>
                                                                                    <input
                                                                                        {...field}
                                                                                        id="limit"
                                                                                        className="form-control"
                                                                                        placeholder="Enter Limit"
                                                                                        value={field.value}
                                                                                        onChange={(event) => {
                                                                                            if (event.target.value != null) {
                                                                                                field.onChange(event.target.value)
                                                                                                var arrRemType = Object.assign([], reimbursementType);
                                                                                                var objRemType = Object.assign({}, arrRemType[index]);
                                                                                                objRemType.value = event.target.value
                                                                                                arrRemType[index] = objRemType
                                                                                                setReimbursementType(arrRemType);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                rules={{
                                                                                    required: 'Please Enter Limit'
                                                                                }}
                                                                            />
                                                                            {errors.limit && <ErrorLabel message={errors.limit?.message} />}
                                                                        </fieldset>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="add_shift"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add_shift-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
const mapStateToProps = (state) => {
    var FinanceLimitAdd = null;
    var FinanceEntity = null;
    var FinanceDepartment = null;
    var FinanceDesignation = null;
    var ReimbursementType = null;

    if (
        state.AddFinanceLimitReducer != null &&
        Object.keys(state.AddFinanceLimitReducer).length > 0 &&
        state.AddFinanceLimitReducer.constructor === Object
    ) {
        switch (state.AddFinanceLimitReducer.tag) {
            case ACTION_FINANCE_LIMIT_ADD_SUCCESS:
                FinanceLimitAdd = Object.assign({}, state.AddFinanceLimitReducer.data);
                delete state.AddFinanceLimitReducer
                return { FinanceLimitAdd };
            case ACTION_FINANCE_LIMIT_ENTITY_SUCCESS:
                FinanceEntity = Object.assign({}, state.AddFinanceLimitReducer.data);
                delete state.AddFinanceLimitReducer
                return { FinanceEntity };
            case ACTION_FINANCE_LIMIT_DEPARTMENT_SUCCESS:
                FinanceDepartment = Object.assign({}, state.AddFinanceLimitReducer.data);
                delete state.AddFinanceLimitReducer
                return { FinanceDepartment };
            case ACTION_FINANCE_LIMIT_DESIGNATION_SUCCESS:
                FinanceDesignation = Object.assign({}, state.AddFinanceLimitReducer.data);
                delete state.AddFinanceLimitReducer
                return { FinanceDesignation };
            case ACTION_REIMBURSEMENT_TYPE_SUCCESS:
                ReimbursementType = Object.assign({}, state.AddFinanceLimitReducer.data);
                delete state.AddFinanceLimitReducer
                return { ReimbursementType };
            default:
        }
    }

    return {
        FinanceLimitAdd,
        FinanceEntity,
        FinanceDepartment,
        FinanceDesignation,
        ReimbursementType
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionFinanceLimitAdd, actionFinanceMasterEntity, actionFinanceMasterDepartment, actionFinanceMasterDesignation, actionGetReimbursementType }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Add)
