import React, { useEffect } from "react";
import $ from "jquery";

const ContactDetails = (props) => {
    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            $("#contact-form-loader").addClass("hidden");
        }
    };
    useEffect(initialRender, [props.show]);

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Contact Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Contact Name</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        props?.data?.name != null ?
                                            props?.data?.name : "-"
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Mobile Number</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        props?.data?.mobile != null ?
                                            props?.data?.mobile : "-"
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Contact Email</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.data?.email != null ?
                                            props?.data?.email : "-"
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Contact Designation</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.data?.designation != null ?
                                            props?.data?.designation : "-"
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Status</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        props?.data?.status != null ?
                                            props?.data?.status : "-"
                                    }
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContactDetails;