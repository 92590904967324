import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { setData, KEY_TOAST_MESSAGE } from '../../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import { actionRiskChangeStatus, ACTION_RISK_CHANGE_STATUS_SUCCESS } from './action';

const ChangeStatus = (props) => {
    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
    const [statusValue, setStatusValue] = useState(null);
    const taskStatus = [
        {
            "text": "Approve",
            "value": "approve"
        },
        {
            "text": "Reject",
            "value": "reject"
        }
    ]

    const initialRender = () => {
        if (props.show === true) {
            reset({ remark: '' });
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };

    useEffect(initialRender, [props.show]);

    const onRiskChangeStatusUpdate = () => {
        const { RiskChangeStatus } = props;
        var RiskChangeStatusResponse = Object.assign({}, RiskChangeStatus);
        $("#change_status").prop("disabled", false);
        if (RiskChangeStatusResponse.result === true) {
            setData(KEY_TOAST_MESSAGE, RiskChangeStatusResponse.message);
            props.handleClose(true);
            $("#change_status_loader").addClass("hidden");
        } else {
            $("#change_status_loader").addClass("hidden");
            switch (RiskChangeStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                default:
            }
        }
    }
    useEffect(onRiskChangeStatusUpdate, [props.RiskChangeStatus]);

    const onSubmit = (data) => {
        $("#change_status_loader").removeClass("hidden");
        $("#change_status").prop("disabled", true);
        data.id = props.details.id;
        data.status = data.status;
        props.actionRiskChangeStatus(data);
    }

    return (

        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="education-add"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Task Status
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Action&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={taskStatus}
                                                                allowFiltering={false}
                                                                placeholder="Select Action"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value);
                                                                        setStatusValue(event.itemData.value);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Action is required'
                                                        }}
                                                    />

                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Remark&nbsp;<span className={`danger ${statusValue != 'reject' ? 'hidden' : ''}`}>*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        rows={5}
                                                        maxLength={200}
                                                        placeholder="Add Remark"
                                                        {...register('remark',
                                                            { required: statusValue === 'reject' ? 'Remark is required' : false }
                                                        )}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                reset();
                                                props.handleClose({
                                                    updated: false
                                                });
                                            }}
                                        > Cancel</button>

                                        <button
                                            id="change_status"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                &nbsp;Submit
                                                <span id="change_status_loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = (state) => {
    var RiskChangeStatus = null;
    if (
        state.RiskManagementChangeStatusReducer != null &&
        Object.keys(state.RiskManagementChangeStatusReducer).length > 0 &&
        state.RiskManagementChangeStatusReducer.constructor === Object
    ) {
        switch (state.RiskManagementChangeStatusReducer.tag) {
            case ACTION_RISK_CHANGE_STATUS_SUCCESS:
                RiskChangeStatus = Object.assign({}, state.RiskManagementChangeStatusReducer.data);
                delete state.RiskManagementChangeStatusReducer;
                return { RiskChangeStatus };
            default:
        }
    }
    return {
        RiskChangeStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionRiskChangeStatus
    }, dispatch)
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ChangeStatus)) 
