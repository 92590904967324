
import React, { useEffect, useState } from 'react';
import moment from "moment";
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser } from 'react-icons/fi';
import { GrNotes } from "react-icons/gr";
import { GrPlan } from "react-icons/gr";
import { GoPin } from "react-icons/go"
import $ from 'jquery';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import Tippy from '@tippyjs/react';
import { actionItSubmissionPoolDetail, ACTION_IT_SUBMISSION_POOL_DETAIL_SUCCESS } from './action';

const SubmissionPoolView = (props) => {

    const [submissionPoolDetails, setSubmissionPoolDetails] = useState();
    const tooltipSection80C = "Maximum eligible limit INR 150,000 (1.5 Lakhs).";
    const tooltipSection80CCD = "Maximum eligible limit INR 50,000 (50K).";
    const tooltipSection80D = 'Eligibility INR 25000 for Family for Emp <60 Years, 50000 for Emp > 60 Years, 50000 for Dependent Parent.';
    const tooltipOthers = "INR 200,000 (2 lakhs) maximum eligibility for Interest Paid on Housing Loan & Loss from House Property.";
    const tooltipFlexible = "(Up to max of Rs 50,000 annually - TWICE in a BLOCK OF FOUR YEARS)";
    const [pendingLifeInsurancePremium, setPendingLifeInsurancePremium] = useState(0)
    const [pendingPPF, setPendingPPF] = useState(0)
    const [pendingMutualFunds, setPendingMutualFunds] = useState(0)
    const [pendingNSC, setPendingNSC] = useState(0)
    const [pendingTuitionFees, setPendingTuitionFees] = useState(0)
    const [pendingRepaymentofHousingLoan, setPendingRepaymentofHousingLoan] = useState(0)
    const [pendingLTA, setPendingLTA] = useState(0)
    const [pendingFiveYearFD, setPendingFiveYearFD] = useState(0)
    const [pendingOthers, setPendingOthers] = useState(0)
    const [pendingNPS, setPendingNPS] = useState(0)
    const [pendingFamilyMediclaim, setPendingFamilyMediclaim] = useState(0)
    const [pendingParentsMediclaim, setPendingParentsMediclaim] = useState(0)
    const [pendingPreventiveHealth, setPendingPreventiveHealth] = useState(0)
    const [pendingGrandTotal, setPendingGrandTotal] = useState(0)
    const [pendingSelfEducationLoan, setPendingSelfEducationLoan] = useState(0)
    const [pendingHousingLoan, setPendingHousingLoan] = useState(0)
    const [pendingHousePropertyLoss, setPendingHousePropertyLoss] = useState(0)
    const [pendingHRA, setPendingHRA] = useState(0)
    const [detailsData, setDetailsData] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        setDetailsData(JSON.parse(localStorage.getItem('detailsData')))
        // props.actionItSubmissionPoolDetail({ window_id: props.location.state.window_id, user_id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (detailsData != null) {
            props.actionItSubmissionPoolDetail({
                "window_id": detailsData.window_id,
                user_id: detailsData.user_id,
            });
        }
    }, [detailsData])

    const onItSubmissionPoolDetailUpdate = () => {
        const { ItSubmissionPoolDetail } = props;
        var ItSubmissionPoolDetailResponse = Object.assign({}, ItSubmissionPoolDetail);
        if (ItSubmissionPoolDetailResponse.result === true) {
            setSubmissionPoolDetails(ItSubmissionPoolDetailResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ItSubmissionPoolDetailResponse.status) {
                case ERROR:
                    toast.error(ItSubmissionPoolDetailResponse.message);
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onItSubmissionPoolDetailUpdate, [props.ItSubmissionPoolDetail]);

    useEffect(() => {
        if (submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes != null && submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document!= null ) {
            const PendingLifeInsurancePremium = Number(submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes) - Number(submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document)
            setPendingLifeInsurancePremium(PendingLifeInsurancePremium)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.ppf != null && submissionPoolDetails?.submissions?.submission_80c?.ppf_document!= null ) {
            const PendingPPF = Number(submissionPoolDetails?.submissions?.submission_80c?.ppf) - Number(submissionPoolDetails?.submissions?.submission_80c?.ppf_document)
            setPendingPPF(PendingPPF)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null && submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document!= null ) {
            const PendingMutualFunds = Number(submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds) - Number(submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document)
            setPendingMutualFunds(PendingMutualFunds)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.nsc != null && submissionPoolDetails?.submissions?.submission_80c?.nsc_document!= null ) {
            const PendingNSC = Number(submissionPoolDetails?.submissions?.submission_80c?.nsc) - Number(submissionPoolDetails?.submissions?.submission_80c?.nsc_document)
            setPendingNSC(PendingNSC)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children != null && submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_document!= null ) {
            const PendingTuitionFees = Number(submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children) - Number(submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_document)
            setPendingTuitionFees(PendingTuitionFees)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan != null && submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document!= null ) {
            const PendingRepaymentofHousingLoan = Number(submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan) - Number(submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document)
            setPendingRepaymentofHousingLoan(PendingRepaymentofHousingLoan)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.five_year_fd != null && submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_document!= null ) {
            const PendingFiveYearFD = Number(submissionPoolDetails?.submissions?.submission_80c?.five_year_fd) - Number(submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_document)
            setPendingFiveYearFD(PendingFiveYearFD)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.others != null && submissionPoolDetails?.submissions?.submission_80c?.others_document!= null ) {
            const PendingOthers = Number(submissionPoolDetails?.submissions?.submission_80c?.others) - Number(submissionPoolDetails?.submissions?.submission_80c?.others_document)
            setPendingOthers(PendingOthers)
        }

        if (submissionPoolDetails?.submissions?.submission_80ccd?.nps != null && submissionPoolDetails?.submissions?.submission_80ccd?.nps_document!= null ) {
            const PendingNPS = Number(submissionPoolDetails?.submissions?.submission_80ccd?.nps) - Number(submissionPoolDetails?.submissions?.submission_80ccd?.nps_document)
            setPendingNPS(PendingNPS)
        }

        if (submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim != null && submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_document!= null ) {
            const PendingFamilyMediclaim = Number(submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim) - Number(submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_document)
            setPendingFamilyMediclaim(PendingFamilyMediclaim)
        }

        if (submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim != null && submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_document!= null ) {
            const PendingParentsMediclaim = Number(submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim) - Number(submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_document)
            setPendingParentsMediclaim(PendingParentsMediclaim)
        }

        if (submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up != null && submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_document!= null ) {
            const PendingPreventiveHealth = Number(submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up) - Number(submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_document)
            setPendingPreventiveHealth(PendingPreventiveHealth)
        }

        if (submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education != null && submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document!= null ) {
            const PendingSelfEducationLoan = Number(submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education) - Number(submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document)
            setPendingSelfEducationLoan(PendingSelfEducationLoan)
        }

        if (submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan != null && submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document!= null ) {
            const PendingHousingLoan = Number(submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan) - Number(submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document)
            setPendingHousingLoan(PendingHousingLoan)
        }

        if (submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property != null && submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_document!= null ) {
            const PendingHousePropertyLoss = Number(submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property) - Number(submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_document)
            setPendingHousePropertyLoss(PendingHousePropertyLoss)
        }

        if (submissionPoolDetails?.submissions?.submission_other?.hra != null && submissionPoolDetails?.submissions?.submission_other?.hra_document!= null ) {
            const PendingHRA = Number(submissionPoolDetails?.submissions?.submission_other?.hra) - Number(submissionPoolDetails?.submissions?.submission_other?.hra_document)
            setPendingHRA(PendingHRA)
        }

        if (submissionPoolDetails?.submissions?.lta != null && submissionPoolDetails?.submissions?.lta_document!= null ) {
            const pendingLTA = Number(submissionPoolDetails?.submissions?.lta) - Number(submissionPoolDetails?.submissions?.lta_document)
            setPendingLTA(pendingLTA)
        }

        if (submissionPoolDetails?.submissions?.submission_80c?.grand_total != null && submissionPoolDetails?.submissions?.submission_80c?.grand_total_document!= null ) {
            const PendingGrandTotal = Number(submissionPoolDetails?.submissions?.submission_80c?.grand_total) - Number(submissionPoolDetails?.submissions?.submission_80c?.grand_total_document)
            setPendingGrandTotal(PendingGrandTotal)
        }
    }, [submissionPoolDetails]);

    return (
        <>
            <section>
                <ScreenTitle title="Submission Details" />
                <div className='card'>
                    <div className='card-body'>
                        <form>
                            <div className="form-body">
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Employee Details
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Name</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.full_name != null ?
                                                    submissionPoolDetails?.profile?.full_name : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Id</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.pinc_employee_id != null ?
                                                    submissionPoolDetails?.profile?.pinc_employee_id : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Payroll Entity</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.payroll_entity != null ?
                                                    submissionPoolDetails?.profile?.payroll_entity : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Primary Department</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.primary_department != null ?
                                                    submissionPoolDetails?.profile?.primary_department : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Joining Date</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.joining_date != null ?
                                                    moment(submissionPoolDetails?.profile?.joining_date).format("DD-MMM-YYYY") : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Mobile</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.mobile_no != null ?
                                                    submissionPoolDetails?.profile?.mobile_no : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Reporting Manager</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.reporting_to_full_name != null ?
                                                    submissionPoolDetails?.profile?.reporting_to_full_name : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Branch</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.profile?.city != null ?
                                                    submissionPoolDetails?.profile?.city : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Submission Date</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.submit_on != null ?
                                                    submissionPoolDetails?.submissions?.submit_on : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Finance Status</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.status != null ?
                                                    submissionPoolDetails?.submissions?.status : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Tax Regime</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.regime != null ?
                                                    submissionPoolDetails?.submissions?.regime : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
                {
                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.regime === "OLD" ?
                        <>
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 C&nbsp;
                                        <Tippy content={tooltipSection80C}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table table-responsive mb-0">
                                            <thead>
                                                <tr >
                                                    <th className='border-0 border-light'>Type of Investment</th>
                                                    <th className='border-0 border-light'>Amount</th>
                                                    <th className='border-0 border-light'>Final Declaration</th>
                                                    <th className='border-0 border-light'>Document Submitted</th>
                                                    <th className='border-0 border-light'>Payment Pending</th>
                                                    <th className='border-0 border-light'>Approved Amount</th>
                                                    <th className='border-0 border-light'>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <th className='border-bottom-0 border-light'>Life Insurance Premium / Pension Schemes</th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.life_insurance_premium_or_pension_schemes != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.life_insurance_premium_or_pension_schemes : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingLifeInsurancePremium
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <th className='border-bottom-0 border-light'>Public Provident Fund (PPF) </th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.ppf != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.ppf : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ppf != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ppf : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ppf_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ppf_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingPPF
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ppf_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ppf_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ppf_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ppf_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <th className='border-bottom-0 border-light text-wrap'>ULIP / ELSS / Tax saving Mutual Funds</th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingMutualFunds
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light text-wrap'>NSC </th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.nsc != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.nsc : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.nsc != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.nsc : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.nsc_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.nsc_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingNSC
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.nsc_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.nsc_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.nsc_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.nsc_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Repayment of Housing Loan (Principal Amount) </th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.repayment_of_housing_loan != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.repayment_of_housing_loan : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingRepaymentofHousingLoan
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Tuition Fees for Children (Max for 2 children)</th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.tuition_fees_for_children != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.tuition_fees_for_children : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingTuitionFees
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>5 yr Fixed Deposits (with specified banks)</th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.five_year_fd != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.five_year_fd : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.five_year_fd != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.five_year_fd : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingFiveYearFD != null ? pendingFiveYearFD : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.five_year_fd_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Others</th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.others != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.others : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.others != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.others : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.others_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.others_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingOthers != null ? pendingOthers : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.others_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.others_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.others_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.others_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Grand Total</th>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80c != null && submissionPoolDetails?.declarations?.declaration_80c?.grand_total != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80c?.grand_total : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.grand_total != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.grand_total : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.grand_total_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.grand_total_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            pendingGrandTotal != null ?
                                                                pendingGrandTotal : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.grand_total_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80c?.grand_total_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        { }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-12 mt-1">
                                            <label className="label-control  font-weight-bold">Finance Remark</label>
                                            <span className="col-12 ml-3">
                                                {
                                                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80c != null && submissionPoolDetails?.submissions?.submission_80c?.finance_remark != null ?
                                                        submissionPoolDetails?.submissions?.submission_80c?.finance_remark : '-'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </>
                        :
                        <></>
                }
                <div className='row'>
                    {
                        submissionPoolDetails != null && submissionPoolDetails?.submissions?.regime === "OLD" ?
                            <div className="col-12">
                                <div>
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 CCD (1B)&nbsp;
                                        <Tippy content={tooltipSection80CCD}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table table-responsive mb-0">
                                            <thead>
                                                <tr >
                                                    <th className='border-0 border-light'>National Pension Scheme</th>
                                                    <th className='border-0 border-light'>Amount</th>
                                                    <th className='border-0 border-light'>Final Declaration</th>
                                                    <th className='border-0 border-light'>Document Submitted</th>

                                                    <th className='border-0 border-light'>Payment Pending</th>
                                                    <th className='border-0 border-light'>Approved Amount</th>
                                                    <th className='border-0 border-light'>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th className=' border-bottom-0 border-light'>
                                                        Amount
                                                    </th>
                                                    <th className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80ccd != null && submissionPoolDetails?.declarations?.declaration_80ccd?.nps != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80ccd?.nps : '-'
                                                        }
                                                    </th>
                                                    <th className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80ccd != null && submissionPoolDetails?.submissions?.submission_80ccd?.nps != null ?
                                                                submissionPoolDetails?.submissions?.submission_80ccd?.nps : '-'
                                                        }
                                                    </th>
                                                    <th className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80ccd != null && submissionPoolDetails?.submissions?.submission_80ccd?.nps_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80ccd?.nps_document : '-'
                                                        }
                                                    </th>
                                                    <th className=' border-bottom-0 border-light'>
                                                        {
                                                            pendingNPS != null ? pendingNPS : '-'
                                                        }
                                                    </th>
                                                    <th className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80ccd != null && submissionPoolDetails?.submissions?.submission_80ccd?.nps_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80ccd?.nps_approved : '-'
                                                        }
                                                    </th>
                                                    <th className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80ccd != null && submissionPoolDetails?.submissions?.submission_80ccd?.nps_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80ccd?.nps_status : '-'
                                                        }
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-12 mt-1">
                                            <label className="label-control font-weight-bold">Finance Remark</label>
                                            <span className="col-12 ml-3">
                                                {
                                                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80ccd != null && submissionPoolDetails?.submissions?.submission_80ccd?.finance_remark != null ?
                                                        submissionPoolDetails?.submissions?.submission_80ccd?.finance_remark : '-'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
                {
                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.regime === "OLD" ?
                        <>
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 D&nbsp;
                                        <Tippy content={tooltipSection80D}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table table-responsive mb-0">
                                            <thead>
                                                <tr >
                                                    <th className='border-0 border-light'>Type of Investment</th>
                                                    <th className='border-0 border-light'>Amount</th>
                                                    <th className='border-0 border-light'>Final Declaration</th>
                                                    <th className='border-0 border-light'>Document Submitted</th>

                                                    <th className='border-0 border-light'>Payment Pending</th>
                                                    <th className='border-0 border-light'>Approved Amount</th>
                                                    <th className='border-0 border-light'>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <th className='border-0 border-light'>Family (Self, Spouse & Children)</th>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80d != null && submissionPoolDetails?.declarations?.declaration_80d?.family_mediclaim != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80d?.family_mediclaim : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            pendingFamilyMediclaim != null ? pendingFamilyMediclaim : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Parents</th>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80d != null && submissionPoolDetails?.declarations?.declaration_80d?.parents_mediclaim != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80d?.parents_mediclaim : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            pendingParentsMediclaim != null ? pendingParentsMediclaim : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim_status : '-'
                                                        }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Preventive Health Check up</th>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80d != null && submissionPoolDetails?.declarations?.declaration_80d?.preventive_health_check_up != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80d?.preventive_health_check_up : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            pendingPreventiveHealth != null ? pendingPreventiveHealth : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-12 mt-1">
                                            <label className="label-control  font-weight-bold">Finance Remark</label>
                                            <span className="col-12 ml-3">
                                                {
                                                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80d != null && submissionPoolDetails?.submissions?.submission_80d?.finance_remark != null ?
                                                        submissionPoolDetails?.submissions?.submission_80d?.finance_remark : '-'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </>
                        :
                        <></>
                }
                {
                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.regime === "OLD" ?
                        <>
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 E
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table table-responsive mb-0">
                                            <thead>
                                                <tr >
                                                    <th className='border-0 border-light'>Interest Paid on Loan for Self-Education </th>
                                                    <th className='border-0 border-light'>Amount</th>
                                                    <th className='border-0 border-light'>Final Declaration</th>
                                                    <th className='border-0 border-light'>Document Submitted</th>

                                                    <th className='border-0 border-light'>Payment Pending</th>
                                                    <th className='border-0 border-light'>Approved Amount</th>
                                                    <th className='border-0 border-light'>Status</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>
                                                        Amount
                                                    </th>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_80e != null && submissionPoolDetails?.declarations?.declaration_80e?.interest_paid_on_loan_for_self_education != null ?
                                                                submissionPoolDetails?.declarations?.declaration_80e?.interest_paid_on_loan_for_self_education : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80e != null && submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education != null ?
                                                                submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80e != null && submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            pendingSelfEducationLoan != null ? pendingSelfEducationLoan : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80e != null && submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80e != null && submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-12 mt-1">
                                            <label className="label-control  font-weight-bold">Finance Remark</label>
                                            <span className="col-12 ml-3">
                                                {
                                                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_80e != null && submissionPoolDetails?.submissions?.submission_80e?.finance_remark != null ?
                                                        submissionPoolDetails?.submissions?.submission_80e?.finance_remark : '-'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Other Declarations&nbsp;
                                        <Tippy content={tooltipOthers}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table  table-responsive mb-0">
                                            <thead>
                                                <tr >
                                                    <th className='border-0 border-light'>Type of Investment </th>
                                                    <th className='border-0 border-light'>Amount</th>
                                                    <th className='border-0 border-light'>Final Declaration</th>
                                                    <th className='border-0 border-light'>Document Submitted</th>

                                                    <th className='border-0 border-light'>Payment Pending</th>
                                                    <th className='border-0 border-light'>Approved Amount</th>
                                                    <th className='border-0 border-light'>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th className='border-0 border-light'>Interest Paid On Housing Loan</th>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_other != null && submissionPoolDetails?.declarations?.declaration_other?.interest_paid_on_housing_loan != null ?
                                                                submissionPoolDetails?.declarations?.declaration_other?.interest_paid_on_housing_loan : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            pendingHousingLoan != null ? pendingHousingLoan : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Loss from House Property </th>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_other != null && submissionPoolDetails?.declarations?.declaration_other?.loss_from_house_property != null ?
                                                                submissionPoolDetails?.declarations?.declaration_other?.loss_from_house_property : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            pendingHousePropertyLoss != null ?
                                                                pendingHousePropertyLoss : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='border-bottom-0 border-light'>Rent Paid (HRA) Annually</th>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.declarations?.declaration_other != null && submissionPoolDetails?.declarations?.declaration_other?.hra != null ?
                                                                submissionPoolDetails?.declarations?.declaration_other?.hra : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.hra != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.hra : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.hra_document != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.hra_document : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            pendingHRA != null ?
                                                                pendingHRA : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.hra_approved != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.hra_approved : '-'
                                                        }
                                                    </td>
                                                    <td className='border-bottom-0 border-light'>
                                                        {
                                                            submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.hra_status != null ?
                                                                submissionPoolDetails?.submissions?.submission_other?.hra_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-12 mt-1">
                                            <label className="label-control  font-weight-bold">Finance Remark</label>
                                            <span className="col-12 ml-3">
                                                {
                                                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.submission_other != null && submissionPoolDetails?.submissions?.submission_other?.finance_remark != null ?
                                                        submissionPoolDetails?.submissions?.submission_other?.finance_remark : '-'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </>
                        :
                        <></>
                }
                <div >
                    <div className="col-12 col-md-12 col-lg-12">
                        <h4 className="d-flex align-items-center form-section mb-2 black">
                            <GrPlan size={16} />&nbsp;Flexible Plan Details&nbsp;
                            <Tippy content={tooltipFlexible}>
                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                            </Tippy>
                        </h4>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <table class="table table-responsive mb-0">
                                <thead>
                                    <tr >
                                        <th className='border-0 border-light'>Type of Investment </th>
                                        <th className='border-0 border-light'>Amount</th>
                                        <th className='border-0 border-light'>Final Declaration</th>
                                        <th className='border-0 border-light'>Document Submitted</th>

                                        <th className='border-0 border-light'>Payment Pending</th>
                                        <th className='border-0 border-light'>Approved Amount</th>
                                        <th className='border-0 border-light'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='border-bottom-0 border-light'>LTA</th>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.declarations?.lta != null ?
                                                    submissionPoolDetails?.declarations?.lta : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.lta != null ?
                                                    submissionPoolDetails?.submissions?.lta : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.lta_document != null ?
                                                    submissionPoolDetails?.submissions?.lta_document : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                pendingLTA != null ? pendingLTA : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.lta_approved != null ?
                                                    submissionPoolDetails?.submissions?.lta_approved : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.lta_status != null ?
                                                    submissionPoolDetails?.submissions?.lta_status : '-'
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="col-12 mt-1">
                                <label className="label-control  font-weight-bold">Finance Remark</label>
                                <span className="col-12 ml-3">
                                    {
                                        submissionPoolDetails != null && submissionPoolDetails?.submissions != null && submissionPoolDetails?.submissions?.finance_flexible_remark != null ?
                                            submissionPoolDetails?.submissions?.finance_flexible_remark : '-'
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div >
                <div >
                    <div className="col-12 col-md-12 col-lg-12">
                        <h4 className="d-flex align-items-center form-section mb-2 black">
                            <GrPlan size={16} />&nbsp;Remarks
                        </h4>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <table class="table mb-0">
                                <tbody>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-0 border-light text-wrap'>Employee Remark</th>
                                        <td scope="col" className='col-md-6 border-0 border-light'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.emp_remark != null && submissionPoolDetails?.submissions?.emp_remark != '' ?
                                                    submissionPoolDetails?.submissions?.emp_remark : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Finance Remark</th>
                                        <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                            {
                                                submissionPoolDetails != null && submissionPoolDetails?.submissions?.finance_remark != null && submissionPoolDetails?.submissions?.finance_remark != '' ?
                                                    submissionPoolDetails?.submissions?.finance_remark : '-'
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <button className="btn btn-outline-secondary shadow round text-uppercase mb-3"
                    onClick={(event) => {
                        event.preventDefault();
                        window.close();
                    }}
                > Back</button>
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var ItSubmissionPoolDetail = null;

    if (
        state.ItSubmissionPoolDetailReducer != null &&
        Object.keys(state.ItSubmissionPoolDetailReducer).length > 0 &&
        state.ItSubmissionPoolDetailReducer.constructor === Object
    ) {
        switch (state.ItSubmissionPoolDetailReducer.tag) {
            case ACTION_IT_SUBMISSION_POOL_DETAIL_SUCCESS:
                ItSubmissionPoolDetail = Object.assign({}, state.ItSubmissionPoolDetailReducer.data);
                delete state.ItSubmissionPoolDetailReducer;
                return { ItSubmissionPoolDetail };
            default:
        }
    }
    return {
        ItSubmissionPoolDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItSubmissionPoolDetail }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(SubmissionPoolView))

