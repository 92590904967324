import Api from '../../api';
import { PRINT } from '../../utils';
import { fetchSuccess, loading } from '../../utils/action.util';
import { API_LOG_OUT } from '../../api/constants';
import  { API_HEADER_USER_NOTIFICATION } from '../../api/constants';

export const ACTION_LOG_OUT_LOADING = 'ACTION_LOG_OUT_LOADING';
export const ACTION_LOG_OUT_SUCCESS = 'ACTION_LOG_OUT_SUCCESS';

export const ACTION_HEADER_USER_NOTIFICATION_LOADING = 'ACTION_HEADER_USER_NOTIFICATION_LOADING';
export const ACTION_HEADER_USER_NOTIFICATION_SUCCESS = 'ACTION_HEADER_USER_NOTIFICATION_SUCCESS';

export function actionLogOut() {
    return (dispatch, getState) =>
        Api.get(API_LOG_OUT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_LOG_OUT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_LOG_OUT_LOADING, { loading: false, data }));
        });
}
export function actionHeaderUserNotification() {
    return (dispatch, getState) =>
        Api.get(API_HEADER_USER_NOTIFICATION).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_HEADER_USER_NOTIFICATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_HEADER_USER_NOTIFICATION_LOADING, { loading: false, data }));
        });
}
