import createReducer from "../../../../../reducers/createReducer";

import { ACTION_MASTER_CLIENT_DETAILS_SUCCESS } from "./action";


const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ClientViewReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_CLIENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CLIENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    }

});
export default ClientViewReducer;