import { ACTION_TASK_POOL_LIST_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const TaskPoolListReducer = createReducer(INITIAL_STATE, {
    [ACTION_TASK_POOL_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TASK_POOL_LIST_SUCCESS,
                loading: false,
            }
        );
    },
});

export default TaskPoolListReducer;