import createReducer from "../../../reducers/createReducer";
import { 
    ACTION_POLICY_SUCCESS, 
    ACTION_ENDORSEMENTS_SUCCESS, 
    ACTION_CLAIMS_SUCCESS 
} from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ClientPolicyDetailReducer = createReducer(INITIAL_STATE, {
    [ACTION_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ENDORSEMENTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ENDORSEMENTS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_SUCCESS,
                loading: false,
            }
        );
    },    
});
export default ClientPolicyDetailReducer;