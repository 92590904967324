import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionRollView, ACTION_ROLL_VIEW_SUCCESS } from './action';
import { actionGetPermission, ACTION_GETPERMISSION_SUCCESS } from './action';
import { actionCreatePermissionsAgainRole, ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS } from './action';

const View = (props) => {

    const [rolesPermission, setRolesPermission] = useState([])


    const initialRender = () => {
        props.actionGetPermission({ role_id: props.location.state.roles.id })
        //props.actionRollView({ id: props.location.state.roles.id });
    }
    useEffect(initialRender, []);

    const onRollViewUpdate = () => {
        const { RollView } = props;
        var RollViewResponse = Object.assign({}, RollView);
        if (RollViewResponse.result === true) {
        } else {
            switch (RollViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onRollViewUpdate, [props.RollView]);

    const ongetPermissionUpdate = () => {

        const { getPermission } = props;

        if (getPermission != null) {
            var getPermissionResponse = Object.assign({}, getPermission);
            if (getPermissionResponse.result === true) {
                setRolesPermission(getPermissionResponse.response)
            } else {
                switch (getPermissionResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }

    useEffect(ongetPermissionUpdate, [props.getPermission]);

    const onCreatePermissionsAgainRoleUpdate = () => {
        const { CreatePermissionsAgainRole } = props;
        if (CreatePermissionsAgainRole != null) {
            var CreatePermissionsAgainRoleResponse = Object.assign({}, CreatePermissionsAgainRole);
            if (CreatePermissionsAgainRoleResponse.result === true) {
                props.history.goBack();
            } else {
                switch (CreatePermissionsAgainRoleResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }

    useEffect(onCreatePermissionsAgainRoleUpdate, [props.CreatePermissionsAgainRole]);

    const onSubmit = (event) => {
        event.preventDefault()
        props.actionCreatePermissionsAgainRole({
            role_id: props.location.state.roles.id,
            updated_permission: rolesPermission
        })
    }
    const renderPermissionOptionUI = (indexRolePermission, title) => {
        var arrRolesItems = []
        var arrRolePermission = Object.assign([], rolesPermission)
        var objRolePermission = arrRolePermission[indexRolePermission]
        var arrRolesPermission = Object.assign([], objRolePermission.permissions)
        arrRolesPermission.forEach((permissionItem, indexPermission) => {
            arrRolesItems.push(
                <div className="col-12 col-md-4 col-lg-4"
                    key={indexPermission}
                >
                    <div className="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id={`permissionitem-${title}-${indexPermission}`} name={`permissionitem-${title}-${indexPermission}`}
                                defaultChecked={
                                    Number(permissionItem.value) === 1
                                        ? true
                                        : false
                                }
                                checked={
                                    Number(permissionItem.value) === 1
                                        ? true
                                        : false
                                }
                                onChange={(event) => {
                                    var arrRolesPermissions = Object.assign([], rolesPermission)
                                    var objRolePermission = Object.assign({}, arrRolesPermissions[indexRolePermission])
                                    var arrRolesPermission = Object.assign([], objRolePermission.permissions)
                                    var permissionItem = Object.assign({}, arrRolesPermission[indexPermission])
                                    if (permissionItem.value === 0) {
                                        permissionItem.value = 1
                                    } else {
                                        permissionItem.value = 0
                                    }

                                    arrRolesPermission[indexPermission] = permissionItem
                                    objRolePermission.permissions = arrRolesPermission
                                    arrRolesPermissions[indexRolePermission] = objRolePermission
                                    setRolesPermission(arrRolesPermissions)
                                }}

                            />

                            <label class="custom-control-label" for={`permissionitem-${title}-${indexPermission}`}>{permissionItem.title}</label>
                        </div>
                    </div>
                </div>
            )
        })

        return arrRolesItems
    }


    const renderPermissionUI = () => {
        var arrPermission = []

        if (rolesPermission != null && rolesPermission.length > 0) {
            rolesPermission.forEach((rolePermission, indexRolePermission) => {
                arrPermission.push(
                    <>
                        <div className="col-12 col-md-12 col-lg-12" key={indexRolePermission}>
                            <h4 className="d-flex align-items-center form-section mb-2 black"
                                id={`permission-${indexRolePermission}`}
                                name={`permission-${indexRolePermission}`}
                            >
                                {rolePermission.title}
                            </h4>
                        </div>

                        {renderPermissionOptionUI(indexRolePermission, rolePermission.title)}
                    </>
                )
            })

        }
        return arrPermission
    }

    return (
        <>
            <ScreenTitle title="Roles" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form onSubmit={e => { e.preventDefault() }}>
                                    {renderPermissionUI()}
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                        {rolesPermission != null && rolesPermission.length > 0 ? <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                            onClick={(event) => {
                                                onSubmit(event)
                                            }}
                                        >Submit</button> : ''}

                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var RollView = null;
    var getPermission = null;
    var CreatePermissionsAgainRole = null;
    if (
        state.ViewPermissionRoleReducer != null &&
        Object.keys(state.ViewPermissionRoleReducer).length > 0 &&
        state.ViewPermissionRoleReducer.constructor === Object
    ) {
        switch (state.ViewPermissionRoleReducer.tag) {
            case ACTION_ROLL_VIEW_SUCCESS:
                RollView = Object.assign({}, state.ViewPermissionRoleReducer.data);
                delete state.ViewPermissionRoleReducer
                return { RollView };
            case ACTION_GETPERMISSION_SUCCESS:
                getPermission = Object.assign({}, state.ViewPermissionRoleReducer.data);
                delete state.ViewPermissionRoleReducer;
                return { getPermission };
            case ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS:
                CreatePermissionsAgainRole = Object.assign({}, state.ViewPermissionRoleReducer.data);
                delete state.ViewPermissionRoleReducer;
                return { CreatePermissionsAgainRole };
            default:
        }
    }

    return {
        RollView,
        getPermission,
        CreatePermissionsAgainRole
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRollView, actionGetPermission, actionCreatePermissionsAgainRole }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(View)