import React, { useEffect, useState } from "react";
import $ from "jquery";
import { UNAUTHORIZED } from '../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCronDashboardView, ACTION_VIEW_CRON_DASHBOARD_SUCCESS } from './action';

const CronDashboardView = (props) => {
    const [cronDashboardView, setCronDashboardView] = useState([]);

    const initialRender = () => {
        if (props.show === true) {
            setCronDashboardView([]);
            props.actionCronDashboardView({
                id: props.cronId,
            });

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }

    };
    useEffect(initialRender, [props.show]);

    const onCronDashboardViewUpdate = () => {
        const { CronDashboardView } = props;
        var CronDashboardResponse = Object.assign({}, CronDashboardView);
        if (CronDashboardResponse.result === true) {
            setCronDashboardView(CronDashboardResponse.response);
        } else {
            switch (CronDashboardResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCronDashboardViewUpdate, [props.CronDashboardView])

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="flex-column">
                                <h5 className="font-medium-1 text-black text-bold-600 mt-0">
                                    {props.name}
                                </h5>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div class="col-12">
                            <div className="card-body card-dashboard p-0 pb-1">
                                <div id="goal-list-scroll" class="table-responsive position-relative ps ps--active-y" style={{ minHeight: 280 }}>

                                    {
                                        cronDashboardView != null && cronDashboardView.length > 0 ?
                                            <table className="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="py-2">Date</th>
                                                        <th className="py-2">Status</th>
                                                        <th className="py-2">Schedule Time</th>
                                                        <th className="py-2">Run Time</th>
                                                        <th className="py-2">Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        cronDashboardView.map((cron, indexCron) => (
                                                            <tr>
                                                                <td>{cron.running_datetime}</td>
                                                                <td>{cron.status}</td>
                                                                <td>{cron.schedule_time}</td>
                                                                <td>{cron.running_time}</td>
                                                                <td>{cron.remark}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            :
                                            <div className="col-12 col-md-12 col-lg-12 my-1">
                                                <h6 className="text-bold-500 text-center">No Logs Found</h6>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    var CronDashboardView = null;

    if (
        state.CronDashboardViewReducer != null &&
        Object.keys(state.CronDashboardViewReducer).length > 0 &&
        state.CronDashboardViewReducer.constructor === Object
    ) {
        switch (state.CronDashboardViewReducer.tag) {
            case ACTION_VIEW_CRON_DASHBOARD_SUCCESS:
                CronDashboardView = Object.assign({}, state.CronDashboardViewReducer.data);
                delete state.CronDashboardViewReducer;
                return { CronDashboardView };
            default:
        }
    }

    return {
        CronDashboardView
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionCronDashboardView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CronDashboardView)
