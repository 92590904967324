import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { verifyPermission } from '../../../../../../utils';
import { actionPermissionContent, ACTION_PERMISSION_CONTENT_SUCCESS } from './action';
import { actionContentStatus, ACTION_CONTENT_STATUS_SUCCESS } from './action';
import Swal from 'sweetalert2';
import $ from "jquery";

const Content = (props) => {

    const { match } = props;

    const [permissionContent, setPermissionContent] = useState({});

    const initialRender = () => {
        props.actionPermissionContent({});
    }
    useEffect(initialRender, []);

    const onPermissionContentUpdate = () => {
        const { PermissionContent } = props
        var PermissionContentResponse = Object.assign({}, PermissionContent);
        if (PermissionContentResponse.result === true) {
            setColumns([
                { dataField: "Action", caption: "Action", type: "button" },
                { dataField: "screen_name", caption: "Screen Name" },
                { dataField: "section_name", caption: "Section Name" },
                { dataField: "display_content_title", caption: "Content Title" },
                { dataField: "display_content_description", caption: "Content Description" },
                { dataField: "status", caption: "Status" },
            ]);

            setPermissionContent(PermissionContentResponse.response);

        } else {
            switch (PermissionContentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onPermissionContentUpdate, [props.PermissionContent])

    const onContentStatusUpdate = () => {
        const { ContentStatus } = props;
        var ContentStatusResponse = Object.assign({}, ContentStatus);
        if (ContentStatusResponse.result === true) {
            props.actionPermissionContent();
            $("#progress-loader").addClass("hidden");
        } else {
            switch (ContentStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onContentStatusUpdate, [props.ContentStatus])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "screen_name", caption: "Screen Name" },
        { dataField: "section_name", caption: "Section Name" },
        { dataField: "display_content_title", caption: "Content Title" },
        { dataField: "display_content_description", caption: "Content Description" },
        { dataField: "status", caption: "Status" },
    ]);

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 1:
                return <div className={"badge badge-success round px-1 cursor-pointer"} onClick={() => {
                    Swal.fire({
                        title: 'Inactivate Content',
                        text: 'Are you sure, you want to inactivate this Content ?',
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: PRIMARY_COLOR,
                    }).then(success => {
                        if (success.isConfirmed) {
                            $("#progress-loader").removeClass("hidden");
                            props.actionContentStatus({
                                id: cell.data.id,
                                status: 0
                            })
                        }
                    });
                }}>Active</div>;
            case 0:
                return <div className={"badge badge-danger round px-1 cursor-pointer"} onClick={() => {
                    Swal.fire({
                        title: 'Activate Content',
                        text: 'Are you sure, you want to activate this Content ?',
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: PRIMARY_COLOR,
                    }).then(success => {
                        if (success.isConfirmed) {
                            props.actionContentStatus({
                                id: cell.data.id,
                                status: 1
                            })
                        }
                    });
                }}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("EDIT_CONTENT")?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit` , state: { content_id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }

            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Content</h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={permissionContent}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "content",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var PermissionContent = null;
    var ContentStatus = null;

    if (
        state.PermissionContentReducer != null &&
        Object.keys(state.PermissionContentReducer).length > 0 &&
        state.PermissionContentReducer.constructor === Object
    ) {
        switch (state.PermissionContentReducer.tag) {
            case ACTION_PERMISSION_CONTENT_SUCCESS:
                PermissionContent = Object.assign({}, state.PermissionContentReducer.data);
                delete state.PermissionContentReducer;
                return { PermissionContent };
            case ACTION_CONTENT_STATUS_SUCCESS:
                ContentStatus = Object.assign({}, state.PermissionContentReducer.data);
                delete state.PermissionContentReducer;
                return { ContentStatus };    
            default:

        }
    }
    return {
        PermissionContent,
        ContentStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionPermissionContent,actionContentStatus }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Content))