import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionAttendanceLeave, ACTION_ATTENDANCE_LEAVE_SUCCESS } from './action';
import { UNAUTHORIZED } from "../../../constants";
import moment from "moment";

const AttendanceLeave = (props) => {
    const [attendanceLeave, setAttendanceLeave] = useState([]);
    const initialRender = () => {
        props.actionAttendanceLeave({});
    }
    useEffect(initialRender, []);
    const onAttendanceLeaveUpdate = () => {
        const { AttendanceLeave } = props;
        const AttendanceLeaveResponse = Object.assign({}, AttendanceLeave)
        if (AttendanceLeaveResponse.result === true) {
            setAttendanceLeave(AttendanceLeaveResponse.response)
        } else {
            switch (AttendanceLeaveResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onAttendanceLeaveUpdate, [props.AttendanceLeave]);

    const returnYearText = () => {
        var lastYear = moment().subtract(1, 'year').format('yyyy')
        var currentYear = moment().year()
        if (moment().month() >= 3) {
            lastYear = moment().year()
            currentYear = moment().add(1, 'year').format('yyyy')
        }
        try {
            return (
                <p className="font-small-2">
                    Your leave statistics for the period from{" "}
                    <strong>April {lastYear} to March {currentYear}</strong>
                </p>
            )
        } catch (e) {
            return (<p className="font-small-2">No Data Found</p>)
        }

    }

    const renderUI = () => {
        try {
            return (
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round dashboard-other-summary shadow">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-small-4 text-black text-bold-500 mb-0">
                                        Attendance and Leave
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-1 py-1">
                            {returnYearText()}
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column">
                                    <h5 className="font-small-3 text-black mb-0">
                                        Annual Leave Kitty
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            attendanceLeave != null && attendanceLeave?.leave_kitty != null && attendanceLeave?.leave_kitty >= 0 ?
                                                attendanceLeave?.leave_kitty : 0
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column">
                                    <h5 className="font-small-3 text-black mb-0 cursor-pointer"
                                      onClick={() => {
                                        props.history.push({
                                            pathname: '/hrms/request',
                                        });
                                    }}
                                    >
                                        Leave Balance
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className={`badge round px-1 ${attendanceLeave != null && Number(attendanceLeave.leave_balance) >= 0 ? 'badge-success' : 'badge-danger'}`}>
                                        {attendanceLeave != null ? attendanceLeave.leave_balance : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column">
                                    <h5 className="font-small-3 text-black mb-0 cursor-pointer"
                                     onClick={() => {
                                        props.history.push({ pathname: '/holidays' });
                                    }}>
                                        Holiday Calendar {new Date().getFullYear()}
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            attendanceLeave != null ?
                                                attendanceLeave.upcoming_holiday : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1 hidden">
                                <div className="flex-column">
                                    <h5 className="font-small-3 text-black mb-0">
                                        Total Absence Days
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            attendanceLeave != null ?
                                                attendanceLeave.absent_days : ''
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
        } catch (e) {
            return (
                <>
                    <h1>Error Attendance Leave</h1>
                </>
            )
        }
    }
    return (
        renderUI()
    );
}
const mapStateToProps = (state) => {

    var AttendanceLeave = null;
    if (
        state.DashboardAttendaceLeaveReducer != null &&
        Object.keys(state.DashboardAttendaceLeaveReducer).length > 0 &&
        state.DashboardAttendaceLeaveReducer.constructor === Object
    ) {
        switch (state.DashboardAttendaceLeaveReducer.tag) {
            case ACTION_ATTENDANCE_LEAVE_SUCCESS:
                AttendanceLeave = Object.assign({}, state.DashboardAttendaceLeaveReducer.data);
                delete state.DashboardAttendaceLeaveReducer;
                return { AttendanceLeave };
            default:
        }
    }
    return {
        AttendanceLeave,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionAttendanceLeave,
        },
        dispatch
    );
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AttendanceLeave))
