import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiPhone, FiMail, FiEdit } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionInternalMeetingsView, ACTION_INTERNAL_MEETING_VIEW_SUCCESS } from './action';
import _ from 'lodash';
import { generateInitialLettersString, linkify } from '../../../../../../utils';
import HTMLParser from 'html-react-parser';
import { toast } from 'react-toastify';

const InternalMeetingView = (props) => {

    const [internalMeetingsDetails, setInternalMeetingsDetails] = useState(null);
    const [accompaniedDetails, setAccompaniedDetails] = useState([]);

    const initialRender = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        props.actionInternalMeetingsView({ id: props.location?.state?.internal_meeting_id != undefined ? props.location?.state?.internal_meeting_id : id });
    }
    useEffect(initialRender, []);

    const onInternalMeetingsViewUpdate = () => {
        const { InternalMeetingsView } = props;
        var InternalMeetingsViewResponse = Object.assign({}, InternalMeetingsView);
        if (InternalMeetingsViewResponse.result === true) {
            setInternalMeetingsDetails(InternalMeetingsViewResponse.response);
            setAccompaniedDetails(InternalMeetingsViewResponse.response.accompany_by);
        } else {
            switch (InternalMeetingsViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onInternalMeetingsViewUpdate, [props.InternalMeetingsView]);

    const renderMeetingLocation = (location) => {
        try {
            var formatText = location
            if (internalMeetingsDetails != null && internalMeetingsDetails.location != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderMeetingAgenda = (agenda) => {
        try {
            var formatText = agenda
            if (internalMeetingsDetails != null && internalMeetingsDetails.agenda != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderMeetingDescription = (description) => {
        try {
            var formatText = description
            if (internalMeetingsDetails != null && internalMeetingsDetails.description != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    const renderAccompanied = () => {
        var arrAccompanied = []
        if (accompaniedDetails != null && accompaniedDetails.length > 0) {
            accompaniedDetails.forEach((contact, indexContact) => {
                arrAccompanied.push(
                    <div class="col-12 px-0 mb-1 border-bottom pb-2">
                        <div className="float-left">
                            <div className="d-flex flex-row">
                                <div className="flex-column mr-half">
                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.full_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: '/profile',
                                            state: {
                                                user_id: contact?.user_id,
                                            }
                                        });
                                    }}>
                                        {contact.full_name}
                                        </p>
                                    <div class="font-small-3 text-bold-700">{contact.designation_name}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.mobile_no != null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.mobile_no != null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.mobile_no}</div>
                                        </div>
                                        <div className={`flex-column ${contact.email != null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.email != null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrAccompanied
    }

    return (
        <>
        <div className='row justify-content-between '>
                <div className='col-md-6'>
                <ScreenTitle title="Internal Meeting" />
                </div>
                {
                    internalMeetingsDetails?.status?.toLowerCase() ==='open' &&
                <div className='col-md-6 text-right'>
                    <a onClick={() => {props.history.push({ pathname: `/meetings/internal-meetings/edit`, state: { internal_meeting_id: internalMeetingsDetails?.id} })}}>
                    <FiEdit size={16} className="primary" /></a>
                </div> 
}
            </div>
           
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="flex-column">
                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                            {
                                                internalMeetingsDetails != null ?
                                                    internalMeetingsDetails.day : ''
                                            }
                                        </h1>
                                    </div>
                                    <div className="flex-column">
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                internalMeetingsDetails != null ?
                                                    internalMeetingsDetails.month : ''
                                            }
                                        </h6>
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                internalMeetingsDetails != null ?
                                                    internalMeetingsDetails.year : ''
                                            }
                                        </h6>
                                    </div>
                                    <div className="d-flex d-md-none d-lg-none flex-column ml-auto">
                                        <div className="btn btn-outline-success btn-sm px-1">
                                            <h6 className="font-small-3 text-bold-500 my-0">
                                                {
                                                    internalMeetingsDetails != null ?
                                                        _.upperFirst(internalMeetingsDetails.status) : ''
                                                }
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 d-none d-md-flex d-lg-flex justify-content-start justify-content-md-end justify-content-lg-end align-items-center">
                                <div className={`btn btn-sm px-1 ${internalMeetingsDetails != null && (internalMeetingsDetails.status === 'close' || internalMeetingsDetails.status === 'cancel') ? 'btn-outline-danger' : 'btn-outline-success'}`}>
                                    <h6 className="font-small-3 text-bold-500 my-0">
                                        {
                                            internalMeetingsDetails != null ?
                                                _.upperFirst(internalMeetingsDetails.status) : ''
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mt-1">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            internalMeetingsDetails != null ?
                                                internalMeetingsDetails.from_time : ''
                                        }
                                    </div>
                                    <div className="flex-column px-1">to</div>
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            internalMeetingsDetails != null ?
                                                internalMeetingsDetails.to_time : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Agenda</h6>
                                <h4 className="font-small-2 text-bold-600 text-justify">
                                    {
                                        internalMeetingsDetails != null ?
                                            renderMeetingAgenda(internalMeetingsDetails?.agenda) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Minutes of Meetings</h6>
                                <h4 className="font-small-2 text-bold-600 text-justify">
                                    {
                                        internalMeetingsDetails != null ?
                                            renderMeetingDescription(internalMeetingsDetails?.description) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Location</h6>
                                <h4 className="font-small-2 text-bold-600 text-justify">
                                    {
                                        internalMeetingsDetails != null && internalMeetingsDetails?.location != null ?
                                            renderMeetingLocation(internalMeetingsDetails?.location) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <hr />
                        <h4>Accompanied By</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        accompaniedDetails.length > 0 ?
                                            renderAccompanied()
                                            :
                                            <h6 className="text-bold-500 text-center">No Details Available</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Created By</h4>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    <div class="col-12 px-0 border-bottom pb-2">
                                        <div className="float-left">
                                            <div className="d-flex flex-row">
                                                <div className="flex-column mr-half mb-half">
                                                    {/* <FaUserCircle size={50} className="blue-grey" /> */}
                                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                                        {generateInitialLettersString(internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details != null && internalMeetingsDetails?.created_by_details?.name != null ? internalMeetingsDetails.created_by_details.name : '')}
                                                    </div>
                                                </div>
                                                <div className="flex-column">
                                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1 cursor-pointer"
                                                    onClick={()=> {
                                                        props.history.push({
                                                                pathname: "/profile",
                                                                state: {
                                                                    user_id: internalMeetingsDetails.created_by,
                                                                }
                                                        }); 
                                                    }} >
                                                        {internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details != null && internalMeetingsDetails?.created_by_details?.name != null ? internalMeetingsDetails.created_by_details.name : ''}</p>
                                                    <div class="font-small-3 text-bold-700">{internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details?.designation_name != null ? internalMeetingsDetails.created_by_details.designation_name : ''}</div>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className={`flex-column ${internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                            <FiPhone size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details?.mobile_no != null ? '' : 'hidden'}`}>
                                                            <div class="font-small-3 secondary">{internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details?.mobile_no != null ? internalMeetingsDetails.created_by_details.mobile_no : ''}</div>
                                                        </div>
                                                        <div className={`flex-column ${internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                            <FiMail size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details?.email != null ? '' : 'hidden'}`}>
                                                            <div class="d-inline font-small-3 secondary">{internalMeetingsDetails != null && internalMeetingsDetails?.created_by_details?.email != null ? internalMeetingsDetails.created_by_details.email : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var InternalMeetingsView = null;
    if (
        state.InternalMeetingsViewReducer != null &&
        Object.keys(state.InternalMeetingsViewReducer).length > 0 &&
        state.InternalMeetingsViewReducer.constructor === Object
    ) {
        switch (state.InternalMeetingsViewReducer.tag) {
            case ACTION_INTERNAL_MEETING_VIEW_SUCCESS:
                InternalMeetingsView = Object.assign({}, state.InternalMeetingsViewReducer.data);
                delete state.InternalMeetingsViewReducer;
                return { InternalMeetingsView };
            default:

        }
    }
    return {
        InternalMeetingsView
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionInternalMeetingsView }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(InternalMeetingView))