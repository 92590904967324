import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiTrash } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import { ERROR, PRIMARY_COLOR } from "../../../../../../constants";
import AddAdminRights from "../add_admin__right";
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { actionMasterGetAdminRight, ACTION_MASTER_GET_ADMIN_RIGHT_SUCCESS } from "./action";
import { actionMasterAddAdminRight, ACTION_MASTER_ADD_ADMIN_RIGHT_SUCCESS } from "./action";
import { actionMasterDeleteAdminRight, ACTION_MASTER_DELETE_ADMIN_RIGHT_SUCCESS } from "./action";
import moment from "moment";
import { verifyPermission } from "../../../../../../utils";

const AdminRightList = (props) => {
    const [showAdminRightsDetails, setShowAdminRightsDetails] = useState(false)
    const [policyList, setPolicyList] = useState([]);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "admin_user", caption: "Employee Name" },
        { dataField: "creator_name", caption: "Added By" },
        { dataField: "created_at_date", caption: "Date" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionMasterGetAdminRight();
    }
    useEffect(initialRender, []);

    const onMasterGetAdminRightUpdate = () => {
        const { MasterGetAdminRight } = props
        if (MasterGetAdminRight != null) {
            var MasterGetAdminRightResponse = Object.assign({}, MasterGetAdminRight);
            if (MasterGetAdminRightResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPolicyList(MasterGetAdminRightResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetAdminRightResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setPolicyList([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetAdminRightUpdate, [props.MasterGetAdminRight])

    const onMasterAddAdminRightUpdate = () => {
        const { MasterAddAdminRight } = props
        if (MasterAddAdminRight != null) {
            var MasterAddAdminRightResponse = Object.assign({}, MasterAddAdminRight);
            if (MasterAddAdminRightResponse.result === true) {
                toast.success(MasterAddAdminRightResponse.message);
                props.actionMasterGetAdminRight();
            } else {
                switch (MasterAddAdminRightResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        toast.error(MasterAddAdminRightResponse.message);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterAddAdminRightUpdate, [props.MasterAddAdminRight])

    const onMasterDeleteAdminRightUpdate = () => {
        const { MasterDeleteAdminRight } = props
        if (MasterDeleteAdminRight != null) {
            var MasterDeleteAdminRightResponse = Object.assign({}, MasterDeleteAdminRight);
            if (MasterDeleteAdminRightResponse.result === true) {
                toast.success(MasterDeleteAdminRightResponse.message);
                props.actionMasterGetAdminRight();
            } else {
                switch (MasterDeleteAdminRightResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        toast.error(MasterDeleteAdminRightResponse.message);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterDeleteAdminRightUpdate, [props.MasterDeleteAdminRight])

    const renderDate = (cell) =>
        (cell.data.created_at_date != null && cell.data.created_at_date != "null") ? <>{moment(cell.data.created_at_date).format("DD/MM/YYYY")}</> : <></>

        const renderEmployeeNameCell = (cell) => {
            if (cell?.data?.admin_user != null) {
                return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/profile',
                        state: {
                            user_id: cell?.data?.user_id,
                        }
                    })
                }}
                ><u>{cell?.data?.admin_user}</u></a>
            }
        }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("ADMIN_RIGHTS_DELETE") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: `Delete`,
                                                    text: `Are you sure, you want to Delete ${params.data.admin_user} ?`,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Cancel',
                                                    confirmButtonText: 'Ok',
                                                    confirmButtonColor: PRIMARY_COLOR,
                                                }).then(success => {
                                                    if (success.isConfirmed) {
                                                        props.actionMasterDeleteAdminRight({ id: params.data.id });
                                                    } else {
                                                        console.log('not deleted');
                                                    }
                                                });
                                            }}
                                        >
                                            <FiTrash size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "created_at_date") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderDate}
                    ></Column>
                );
            } else if (objColumn.dataField === "admin_user") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEmployeeNameCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Placement Admin Rights</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {
                                verifyPermission("ADMIN_RIGHTS_ADD") ?
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                setShowAdminRightsDetails(true)
                                            }}
                                        >
                                            Add
                                        </button>
                                    </li>
                                    :
                                    <></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={policyList}
                                    showBorders={true}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddAdminRights
                show={showAdminRightsDetails}
                handleClose={(newEmployee) => {
                    if (newEmployee != null && newEmployee.length > 0) {
                        props.actionMasterAddAdminRight({ employee_ids: newEmployee });
                    }
                    setShowAdminRightsDetails(false)
                }}
            />

        </>
    );
};
const mapStateToProps = (state) => {
    var MasterGetAdminRight = null;
    var MasterAddAdminRight = null;
    var MasterDeleteAdminRight = null;

    if (
        state.MasterListAdminRightReducer != null &&
        Object.keys(state.MasterListAdminRightReducer).length > 0 &&
        state.MasterListAdminRightReducer.constructor === Object
    ) {
        switch (state.MasterListAdminRightReducer.tag) {
            case ACTION_MASTER_GET_ADMIN_RIGHT_SUCCESS:
                MasterGetAdminRight = Object.assign({}, state.MasterListAdminRightReducer.data);
                delete state.MasterListAdminRightReducer;
                return { MasterGetAdminRight };
            case ACTION_MASTER_ADD_ADMIN_RIGHT_SUCCESS:
                MasterAddAdminRight = Object.assign({}, state.MasterListAdminRightReducer.data);
                delete state.MasterListAdminRightReducer;
                return { MasterAddAdminRight };
            case ACTION_MASTER_DELETE_ADMIN_RIGHT_SUCCESS:
                MasterDeleteAdminRight = Object.assign({}, state.MasterListAdminRightReducer.data);
                delete state.MasterListAdminRightReducer;
                return { MasterDeleteAdminRight };
            default:

        }
    }
    return {
        MasterGetAdminRight,
        MasterAddAdminRight,
        MasterDeleteAdminRight
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterGetAdminRight, actionMasterAddAdminRight, actionMasterDeleteAdminRight }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AdminRightList))