import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScreenTitle from "../../../../../component/screen_title";
import { FiUser, FiTrash } from 'react-icons/fi';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import { withAuth } from 'react-auth-guard';
import { actionProposalPolicyType, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from './action';
import { actionMasterEntity, ACTION_API_MASTER_ENTITY_SUCCESS } from './action';
import { actionEmployeeDetails, ACTION_EMPLOYEE_DETAIL_SUCCESS } from './action';
import { actionEmployeeEdit, ACTION_EMPLOYEE_EDIT_SUCCESS } from './action';
import { actionMasterSubEntity, ACTION_API_MASTER_SUB_ENTITY_SUCCESS } from './action';
import { actionMasterBranchCity, ACTION_API_MASTER_BRANCH_CITY_SUCCESS } from './action';
import { actionMasterDepartment, ACTION_API_MASTER_DEPARTMENT_SUCCESS } from './action';
import { actionMasterGrade, ACTION_API_MASTER_GRADE_SUCCESS } from './action';
import { actionMasterBranchAddress, ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS } from './action';
import { actionMasterGetReporting, ACTION_API_MASTER_GET_REPORTING_SUCCESS } from './action';
import { actionMasterDesignation, ACTION_API_MASTER_DESIGNATION_SUCCESS } from './action';
import { actionMasterJobRole, ACTION_API_MASTER_JOB_ROLE_SUCCESS } from './action';
import { actionMasterTeamHead, ACTION_API_MASTER_TEAM_HEAD_SUCCESS } from './action';
import { actionMasterApprovalManager, ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS } from './action';
import { actionGetRolesDropdownList, ACTION_GETROLESDROPDOWNLIST_SUCCESS } from './action';
import { actionDeleteDocument, ACTION_DOCUMENT_DELETE_SUCCESS } from './action';
import { actionGetEmployeeShifts, EMPLOYEE_SHIFT_TIMINGS_SUCCESS } from './action';
import { actionGetReimbursementFinanceApprovalManager, ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS } from './action';
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../constants';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { checkFileExtension, verifyPermission } from '../../../../../utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import DocumentIframe from '../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../utils';

const EmployeeEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, watch, setValue, getValues } = useForm({
        defaultValues: {
            // first_name: 'Tushar',
            // last_name: 'Mahajan',
            // pinc_employee_id: 'ING4545',
            // email: 'tushar@capternal.com',
            // mobile_no: '7447866276',
            // vertical: 'Test',
            // segment: 'Test',
            // region: 'Test',
            // product: 'Test'
        }
    });

    const [masterEntity, setMasterEntity] = useState([]);
    const [masterPayrollEntity, setMasterPayrollEntity] = useState({});
    const [masterPrimaryEntity, setMasterPrimaryEntity] = useState([]);
    const [masterSubEntity, setMasterSubEntity] = useState([]);
    const [selectedMasterSubEntity, setSelectedMasterSubEntity] = useState([]);
    const [masterPrimarySubEntity, setMasterPrimarySubEntity] = useState([]);
    const [masterBranchCity, setMasterBranchCity] = useState([]);
    const [masterDepartment, setMasterDepartment] = useState([]);
    const [masterGrade, setMasterGrade] = useState({})
    const [masterBranchAddress, setMasterBranchAddress] = useState([]);
    const [masterGetReporting, setMasterGetReporting] = useState({});
    const [masterDesignation, setMasterDesignation] = useState({});
    const [masterJobRole, setMasterJobRole] = useState({});
    const [masterTeamHead, setMasterTeamHead] = useState([]);
    const [masterApprovalManager, setMasterApprovalManager] = useState([]);
    const [masterAssetApprovalManager, setMasterAssetApprovalManager] = useState([]);
    const [masterFinanceApprovalManager, setMasterFinanceApprovalManager] = useState([]);
    const [masterTravelApprovalManager, setMasterTravelApprovalManager] = useState([]);
    const [masterAttendanceApprovalManager, setMasterAttendanceApprovalManager] = useState([]);
    const [masterMeetingApprovalManager, setMasterMeetingApprovalManager] = useState([]);
    const [masterPerformanceObjectiveApprovalManager, setMasterPerformanceObjectiveApprovalManager] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [permissionRoles, setPermissionRoles] = useState([]);
    const [employeeShifts, setEmployeeShifts] = useState([]);
    const [masterreimburstmentapprovalmanager, setMasterReimburstmentApprovalmanager] = useState([]);
    const [masterfinancereimburstmentapprovalmanager, setMasterfinanceReimburstmentApprovalManager] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [uploadNocDocument, setUploadNocDocument] = useState(null);
    var watchTaskOperation = watch('task_operation', true);

    const [visitingCardFront, setVisitingCardFront] = useState(null);
    const [visitingCardBack, setVisitingCardBack] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [clientVisibleImage, setClientVisibleImage] = useState(null);
    const [sameAsApprovalManager, setSameAsApprovalManager] = useState(false);
    const [deleteDocumentType, setDeleteDocumentType] = useState(null);
    const showWorkingDays = 5;
    const [isProduct, setIsProduct] = useState(false);
    const [proposalPolicy, setProposalPolicy] = useState(null);
    const [bqpCertified, setBQPCertified] = useState(null);
    const [brokeringEntity, setBrokeringEntity] = useState(null);
    const validExt = ['jpg', 'jpeg', 'png'];
    const [uploadCertificateCopy, setUploadCertificateCopy] = useState(null);
    const [nocType, setNocType] = useState(null);
    const [bqpStatus, setBqpStatus] = useState(null);

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }

        props.actionEmployeeDetails({
            id: props.location.state.pinc_employee_id,
        });
    }
    useEffect(initialRender, [props.show]);

    useEffect(() => {

        if (employeeDetails != null) {
            props.actionProposalPolicyType();
            props.actionMasterEntity();
            props.actionMasterTeamHead();
            props.actionGetRolesDropdownList();
            props.actionMasterDepartment();
            props.actionMasterGrade();
            props.actionMasterDesignation();
            props.actionGetEmployeeShifts();
            props.actionGetReimbursementFinanceApprovalManager();

            props.actionMasterGetReporting({
                'companies_ids': employeeDetails.sub_companies,
                'department_ids': employeeDetails.department
            })

        }
    }, [employeeDetails])

    const onEmployeeDetails = () => {
        const { EmployeeDetails } = props;
        var EmployeeDetailsResponse = Object.assign({}, EmployeeDetails);

        if (EmployeeDetailsResponse.result === true) {
            setFunctions(EmployeeDetailsResponse?.response?.department);
            setBQPCertified(EmployeeDetailsResponse?.response?.certificate?.bqp_certified);
            setBrokeringEntity(EmployeeDetailsResponse?.response?.certificate?.brokering_entity);
            setNocType(EmployeeDetailsResponse?.response?.certificate?.noc_type);
            setBqpStatus(EmployeeDetailsResponse?.response?.certificate?.bqp_status);
            setValue('bqp_status', EmployeeDetailsResponse?.response?.certificate?.bqp_status);
            setValue('bqp_certified', EmployeeDetailsResponse?.response?.certificate?.bqp_certified);
            setValue('certificate_number', EmployeeDetailsResponse?.response?.certificate?.certificate_number != 'undefined' ? EmployeeDetailsResponse?.response?.certificate?.certificate_number : "");
            setValue('start_date', EmployeeDetailsResponse?.response?.certificate?.start_date != "0000-00-00 00:00:00" ? EmployeeDetailsResponse?.response?.certificate?.start_date : '');
            setValue('end_date', EmployeeDetailsResponse?.response?.certificate?.end_date != "0000-00-00 00:00:00" ? EmployeeDetailsResponse?.response?.certificate?.end_date : '');
            setValue('brokering_entity', EmployeeDetailsResponse?.response?.certificate?.brokering_entity);
            setValue('noc_type', EmployeeDetailsResponse?.response?.certificate?.noc_type);
            setValue('id', EmployeeDetailsResponse.response.user_id != null ? EmployeeDetailsResponse.response.user_id : '');
            setValue('first_name', (EmployeeDetailsResponse.response.first_name != null ? EmployeeDetailsResponse.response.first_name : ''));
            setValue('last_name', EmployeeDetailsResponse.response.last_name != null ? EmployeeDetailsResponse.response.last_name : '');
            setValue('pinc_employee_id', EmployeeDetailsResponse.response.pinc_employee_id != null ? EmployeeDetailsResponse.response.pinc_employee_id : '');
            setValue('saiba_rm_id', (EmployeeDetailsResponse.response.saiba_rm_id != null ? EmployeeDetailsResponse.response.saiba_rm_id : ''));
            setValue('email', EmployeeDetailsResponse.response.email != null ? EmployeeDetailsResponse.response.email : '');
            setValue('mobile_no', EmployeeDetailsResponse.response.mobile_no != null ? EmployeeDetailsResponse.response.mobile_no : '');
            setValue('gender', EmployeeDetailsResponse.response.gender);
            setValue('date_of_birth', EmployeeDetailsResponse.response.date_of_birth);
            setValue('joining_date', EmployeeDetailsResponse.response.joining_date);
            setValue('companies', EmployeeDetailsResponse.response.companies);
            setValue('sub_companies', EmployeeDetailsResponse.response.sub_companies);
            setValue('reimursment_primary_entity_id', EmployeeDetailsResponse.response.primary_entity);
            setValue('reimursment_primary_department_id', EmployeeDetailsResponse.response.primary_department);
            setValue('vertical', EmployeeDetailsResponse.response.vertical != null ? EmployeeDetailsResponse.response.vertical : '');
            setValue('segment', EmployeeDetailsResponse.response.segment != null ? EmployeeDetailsResponse.response.segment : '');
            setValue('region', EmployeeDetailsResponse.response.region != null ? EmployeeDetailsResponse.response.region : '');
            setValue('product_ids', EmployeeDetailsResponse.response.product_specialist);
            setValue('product_name', EmployeeDetailsResponse.response.product_name != null ? EmployeeDetailsResponse.response.product_name : '');
            setValue('request_view', EmployeeDetailsResponse.response.request_view);
            setValue('request_approval', EmployeeDetailsResponse.response.request_approval);
            setValue('task_operation', EmployeeDetailsResponse.response.task_operation);
            setValue('grade_id', EmployeeDetailsResponse.response.grade_id);
            setValue('job_role', EmployeeDetailsResponse.response.job_role);
            setValue('total_leaves', EmployeeDetailsResponse.response?.total_leaves)
            setValue('leave_kitty', EmployeeDetailsResponse.response?.leave_kitty)
            setValue('working_days', EmployeeDetailsResponse.response?.working_days)
            setValue('is_on_probation', EmployeeDetailsResponse.response.is_on_probation);
            setValue('auto_attendance', EmployeeDetailsResponse.response.auto_attendance);
            setValue('is_product_specialist', EmployeeDetailsResponse.response.is_product_specialist === 1 ? true : false);
            setIsProduct(EmployeeDetailsResponse.response.is_product_specialist === 1 ? true : false);
            setEmployeeDetails(EmployeeDetailsResponse.response);
        } else {
            switch (EmployeeDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(EmployeeDetailsResponse.message);
                default:
            }
        }
    }
    useEffect(onEmployeeDetails, [props.EmployeeDetails]);

    useEffect(() => {
        if (proposalPolicy != null && proposalPolicy.length > 0) {
            if (employeeDetails != null) {
                setValue('product_ids', employeeDetails.product_specialist);
            }
        }
    }, [proposalPolicy])

    const onProposalPolicyTypeUpdate = () => {
        const { ProposalPolicyType } = props;
        if (ProposalPolicyType != null) {
            var ProposalPolicyTypeResponse = Object.assign({}, ProposalPolicyType);
            $("#proposal-reset").prop("disabled", false);
            if (ProposalPolicyTypeResponse.result === true) {
                $("#proposal-reset-loader").addClass("hidden");
                setProposalPolicy(ProposalPolicyTypeResponse.response);
                // props.actionProbability();
            } else {
                $("#proposal-reset-loader").addClass("hidden");
                switch (ProposalPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(ProposalPolicyTypeResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onProposalPolicyTypeUpdate, [props.ProposalPolicyType]);

    useEffect(() => {
        if (masterPayrollEntity != null && masterPayrollEntity.length > 0) {
            if (employeeDetails != null) {
                setValue('payroll_id', employeeDetails.payroll_id);
            }
        }
    }, [masterPayrollEntity])

    useEffect(() => {
        if (masterPrimaryEntity != null && masterPrimaryEntity.length > 0) {
            if (employeeDetails != null) {
                setValue('reimursment_primary_entity_id', employeeDetails.primary_entity);
            }
        }
    }, [masterPrimaryEntity])

    const onMasterEntityUpdate = () => {
        const { Entity } = props;
        var EntityResponse = Object.assign({}, Entity);
        if (EntityResponse.result === true) {
            setMasterEntity(EntityResponse.response);
            setMasterPayrollEntity(EntityResponse.response);
            setMasterPrimaryEntity(EntityResponse.response);
        } else {
            switch (EntityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(EntityResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterEntityUpdate, [props.Entity]);

    useEffect(() => {
        if (masterPrimarySubEntity != null && masterPrimarySubEntity.length > 0) {
            if (employeeDetails != null) {
                setValue('reimursment_primary_department_id', employeeDetails.primary_department);
            }
        }
    }, [masterPrimarySubEntity])

    const onMasterSubEntityUpdate = () => {
        const { SubEntity } = props;
        var SubEntityResponse = Object.assign({}, SubEntity);
        if (SubEntityResponse.result === true) {
            setMasterSubEntity(SubEntityResponse.response);
            setMasterPrimarySubEntity(SubEntityResponse.response);
        } else {
            switch (SubEntityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(SubEntityResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterSubEntityUpdate, [props.SubEntity]);

    useEffect(() => {
        if (masterBranchCity != null && masterBranchCity.length > 0) {
            setValue('city', Number(employeeDetails?.city));
        }
    }, [masterBranchCity])

    const onMasterBranchCityUpdate = () => {
        const { BranchCity } = props;
        var BranchCityResponse = Object.assign({}, BranchCity);
        if (BranchCityResponse.result === true) {
            setMasterBranchCity(BranchCityResponse.response);
        } else {
            switch (BranchCityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(BranchCityResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterBranchCityUpdate, [props.BranchCity]);

    useEffect(() => {
        if (masterBranchAddress != null && masterBranchAddress.length > 0) {
            if (employeeDetails != null) {
                setValue('branch', Number(employeeDetails.branch_address));
            }
        }
    }, [masterBranchAddress])

    const onMasterBranchAddressUpdate = () => {
        const { BranchAddress } = props;
        var BranchAddressResponse = Object.assign({}, BranchAddress);
        if (BranchAddressResponse.result === true) {
            setMasterBranchAddress(BranchAddressResponse.response);
        } else {
            switch (BranchAddressResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(BranchAddressResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterBranchAddressUpdate, [props.BranchAddress]);

    useEffect(() => {
        if (masterDepartment != null && masterDepartment.length > 0) {
            if (employeeDetails != null) {
                setValue('department', employeeDetails.department);
            }
        }
    }, [masterDepartment])

    const onMasterDepartmentUpdate = () => {
        const { Department } = props;
        var DepartmentResponse = Object.assign({}, Department);
        if (DepartmentResponse.result === true) {
            setMasterDepartment(DepartmentResponse.response);
        } else {
            switch (DepartmentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(DepartmentResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterDepartmentUpdate, [props.Department]);

    useEffect(() => {
        if (masterGrade != null && masterGrade.length > 0) {
            if (employeeDetails != null) {
                setValue('grade_id', employeeDetails.grade_id);
            }
        }
    }, [masterGrade])

    const onMasterGradeUpdate = () => {
        const { Grade } = props;
        var GradeResponse = Object.assign({}, Grade);
        if (GradeResponse.result === true) {
            setMasterGrade(GradeResponse.response);
        } else {
            switch (GradeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(GradeResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterGradeUpdate, [props.Grade]);

    useEffect(() => {
        if (masterGetReporting != null && masterGetReporting.length > 0) {
            if (employeeDetails != null) {
                setValue('reporting_to_id', employeeDetails.reporting_to_id);
            }
        }
    }, [masterGetReporting])

    const onMasterGetReportingUpdate = () => {
        const { GetReporting } = props;
        var GetReportingResponse = Object.assign({}, GetReporting);
        if (GetReportingResponse.result === true) {
            setMasterGetReporting(GetReportingResponse.response);
        } else {
            switch (GetReportingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                // toast.error(GetReportingResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterGetReportingUpdate, [props.GetReporting]);

    useEffect(() => {
        if (masterDesignation != null && masterDesignation.length > 0) {
            if (employeeDetails != null) {
                setValue('designation_id', employeeDetails.designation_id);
            }
        }
    }, [masterDesignation])

    const onMasterDesignationUpdate = () => {
        const { Designation } = props;
        var DesignationResponse = Object.assign({}, Designation);
        if (DesignationResponse.result === true) {
            setMasterDesignation(DesignationResponse.response);
        } else {
            switch (DesignationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(DesignationResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterDesignationUpdate, [props.Designation]);

    const onMasterJobRoleUpdate = () => {
        const { JobRole } = props;
        var JobRoleResponse = Object.assign({}, JobRole);
        if (JobRoleResponse.result === true) {
            setMasterJobRole(JobRoleResponse.response);
        } else {
            switch (JobRoleResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(JobRoleResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterJobRoleUpdate, [props.JobRole]);

    useEffect(() => {
        if (masterTeamHead != null && masterTeamHead.length > 0) {
            if (employeeDetails != null) {
                setValue('team_master_id', Number(employeeDetails.team_master_id));
            }
        }
    }, [masterTeamHead])

    const onMasterTeamHeadUpdate = () => {
        const { TeamHead } = props;
        var TeamHeadResponse = Object.assign({}, TeamHead);
        if (TeamHeadResponse.result === true) {
            setMasterTeamHead(TeamHeadResponse.response);
        } else {
            switch (TeamHeadResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(TeamHeadResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterTeamHeadUpdate, [props.TeamHead]);

    const onMasterApprovalManagerUpdate = () => {
        const { ApprovalManager } = props;
        var ApprovalManagerResponse = Object.assign({}, ApprovalManager);
        if (ApprovalManagerResponse != null) {
            if (ApprovalManagerResponse.result === true) {
                setMasterApprovalManager(ApprovalManagerResponse.response);
                setMasterAssetApprovalManager(ApprovalManagerResponse.response);
                setMasterFinanceApprovalManager(ApprovalManagerResponse.response);
                setMasterTravelApprovalManager(ApprovalManagerResponse.response);
                setMasterAttendanceApprovalManager(ApprovalManagerResponse.response);
                setMasterMeetingApprovalManager(ApprovalManagerResponse.response);
                setMasterPerformanceObjectiveApprovalManager(ApprovalManagerResponse.response);
                setMasterReimburstmentApprovalmanager(ApprovalManagerResponse.response);
                setValue('approval_manager_id', Number(employeeDetails.approval_manager_id));
                setValue('asset_manager_id', Number(employeeDetails.asset_manager_id));
                setValue('finance_manager_id', Number(employeeDetails.finance_manager_id));
                setValue('travel_manager_id', Number(employeeDetails.travel_manager_id));
                setValue('attendance_manager_id', Number(employeeDetails.attendance_manager_id));
                setValue('meeting_manager_id', Number(employeeDetails.meeting_manager_id));
                setValue('performance_manager_id', Number(employeeDetails.performance_manager_id));
                setValue('reimburstment_approval_manager_id', Number(employeeDetails.reimburstment_approval_manager_id));

                var manager_ids = [employeeDetails.approval_manager_id, employeeDetails.asset_manager_id, employeeDetails.finance_manager_id, employeeDetails.travel_manager_id, employeeDetails.attendance_manager_id, employeeDetails.meeting_manager_id, employeeDetails.performance_manager_id, employeeDetails.reimburstment_approval_manager_id]
                if (manager_ids.every(manager_id => manager_id === manager_ids[0])) {
                    setSameAsApprovalManager(true);
                    $("#same_as_approval_manager").removeAttr("disabled");
                    $("#same_as_approval_manager").attr('checked', true);
                }

            } else {
                switch (ApprovalManagerResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(ApprovalManagerResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onMasterApprovalManagerUpdate, [props.ApprovalManager]);

    const onReimbursementFinanceApprovalManagerUpdate = () => {
        const { ReimbursementFinanceApprovalManager } = props;
        if (ReimbursementFinanceApprovalManager != null) {
            var ReimbursementFinanceApprovalManagerResponse = Object.assign({}, ReimbursementFinanceApprovalManager);
            if (ReimbursementFinanceApprovalManagerResponse.result === true) {
                setMasterfinanceReimburstmentApprovalManager(ReimbursementFinanceApprovalManagerResponse.response);
                setValue('finance_reimburstment_approval_manager_id', Number(employeeDetails.finance_reimburstment_approval_manager_id));
            } else {
                switch (ReimbursementFinanceApprovalManagerResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(ReimbursementFinanceApprovalManagerResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onReimbursementFinanceApprovalManagerUpdate, [props.ReimbursementFinanceApprovalManager]);


    const onEmployeeEditUpdate = () => {
        const { Employee } = props;
        var EmployeeResponse = Object.assign({}, Employee);
        $("#edit_employee").prop("disabled", false);
        if (EmployeeResponse.result === true) {
            $("#edit_employee-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Employee Updated Successfully");
            props.history.goBack();
        }
        else {
            // $("#progress-loader").addClass("hidden");
            $("#edit_employee-loader").addClass("hidden");
            switch (EmployeeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(EmployeeResponse.message);
                default:
            }
        }
    }
    useEffect(onEmployeeEditUpdate, [props.Employee]);

    useEffect(() => {
        if (permissionRoles != null && permissionRoles.length > 0) {
            if (employeeDetails != null) {
                setValue('permission_role_id', employeeDetails.permission_role_id);
            }
        }
    }, [permissionRoles])

    const ongetRolesDropdownListUpdate = () => {

        const { PermissionRole } = props;

        if (PermissionRole != null) {
            var PermissionRoleResponse = Object.assign({}, PermissionRole);
            if (PermissionRoleResponse.result === true) {
                setPermissionRoles(PermissionRoleResponse.response);
            } else {
                switch (PermissionRoleResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(PermissionRoleResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(ongetRolesDropdownListUpdate, [props.PermissionRole]);

    useEffect(() => {
        if (employeeShifts != null && employeeShifts.length > 0) {
            if (employeeDetails != null) {
                setValue('shift_id', employeeDetails.shift_id);
            }
        }
    }, [employeeShifts])

    const ongetEmployeeShiftUpdate = () => {

        const { EmployeeShift } = props;

        if (EmployeeShift != null) {
            var EmployeeShiftResponse = Object.assign({}, EmployeeShift);
            if (EmployeeShiftResponse.result === true) {
                setEmployeeShifts(EmployeeShiftResponse.response);
            } else {
                switch (EmployeeShiftResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(EmployeeShiftResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(ongetEmployeeShiftUpdate, [props.EmployeeShift]);

    const onEmployeeDocumentDeleteUpdate = () => {
        const { DocumentDelete } = props
        var DocumentDeleteResponse = Object.assign({}, DocumentDelete)
        if (DocumentDeleteResponse.result === true) {
            $('#' + deleteDocumentType).addClass('hidden');
            setDeleteDocumentType(null);
        } else {
            switch (DocumentDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(DocumentDeleteResponse.message);
                default:
            }
        }
    }
    useEffect(onEmployeeDocumentDeleteUpdate, [props.DocumentDelete])

    const onSubmit = (data) => {

        var files = [];

        if (visitingCardFront != null) {
            files.push({ 'name': 'visiting_card_front', 'value': visitingCardFront });
        }
        if (visitingCardBack != null) {
            files.push({ 'name': 'visiting_card_back', 'value': visitingCardBack });
        }
        if (profilePhoto != null) {
            files.push({ 'name': 'profile_photo', 'value': profilePhoto });
        }
        if (clientVisibleImage != null) {
            files.push({ 'name': 'client_visible_image', 'value': clientVisibleImage });
        }

        data.request_view = data.request_view === true ? 1 : 0;
        data.request_approval = data.request_approval === true ? 1 : 0;
        data.task_operation = data.task_operation === true ? 1 : 0;
        data.is_on_probation = data.is_on_probation === true ? 1 : 0;
        data.is_product_specialist = isProduct === true ? 1 : 0;
        data.auto_attendance = data.auto_attendance === true ? 1 : 0;
        data.total_leaves = Number(data.total_leaves)
        data.companies = JSON.stringify(data.companies);
        data.product_ids = JSON.stringify(data.product_ids);
        data.sub_companies = JSON.stringify(data.sub_companies);
        data.department = JSON.stringify(data.department);
        data.reimursment_primary_entity_id = JSON.stringify(data.reimursment_primary_entity_id);
        data.reimursment_primary_department_id = JSON.stringify(data.reimursment_primary_department_id);
        data.bqp_status = data.bqp_status != null ? data.bqp_status : employeeDetails?.certificate?.bqp_status;
        /**
         * API : /api/employee/edit
         * METHOD : POST 
         * Description : Update Employee Record 
         * INPUT : Employee Object With id 
         */
        // $("#progress-loader").removeClass("hidden");


        if ((data.bqp_status === 'exam' || bqpStatus === 'exam') && employeeDetails?.certificate != null) {
            data.bqp_certified = data.bqp_certified != null ? data.bqp_certified : employeeDetails?.certificate?.bqp_certified;
            if (data.bqp_certified === 'Valid' || data.bqp_certified === 'Expired') {
                data.certificate_number = data.certificate_number != null ? data.certificate_number : (employeeDetails?.certificate?.certificate_number != 'undefined' ? employeeDetails?.certificate?.certificate_number : "");
                data.start_date = data.start_date != null ? data.start_date : employeeDetails?.certificate?.start_date;
                data.end_date = data.end_date != null ? data.end_date : employeeDetails?.certificate?.end_date;
                data.brokering_entity = data.brokering_entity != null ? data.brokering_entity : employeeDetails?.certificate?.brokering_entity;
                data.noc_type = data.noc_type != null ? data.noc_type : employeeDetails?.certificate?.noc_type;
            } else {
                data.certificate_number = '';
                data.start_date = '';
                data.end_date = '';
                data.brokering_entity = '';
                data.noc_type = '';
            }
            // if ((data.bqp_certified === 'Valid' || data.bqp_certified === 'Expired') && employeeDetails?.certificate?.employee_bqp_document != null && nocType === 'NOC Received') {
            //     files.push({ 'name': 'bqp_certificate', 'value': uploadCertificateCopy });
            //     $("#edit_employee-loader").removeClass("hidden");
            //     $("#edit_employee").prop("disabled", true);
            //     console.log("data2", data);
            //     props.actionEmployeeEdit(data, files);
            // } else if ((data.bqp_certified === 'Not Available' || data.bqp_certified === 'Not Applicable') && employeeDetails?.certificate?.employee_bqp_document != null) {
            //     $("#edit_employee-loader").removeClass("hidden");
            //     $("#edit_employee").prop("disabled", true);
            //     console.log("data2", data);
            //     props.actionEmployeeEdit(data);
            // }
            // else {
            //     if (uploadCertificateCopy != null) {
            //         files.push({ 'name': 'bqp_certificate', 'value': uploadCertificateCopy });
            //         $("#edit_employee-loader").removeClass("hidden");
            //         $("#edit_employee").prop("disabled", true);
            //         console.log("data3", data);
            //         props.actionEmployeeEdit(data, files);
            //     }
            //     else {
            //         if (employeeDetails?.certificate?.employee_bqp_document != null) {
            //             files.push({ 'name': 'bqp_certificate', 'value': uploadCertificateCopy });
            //             $("#edit_employee-loader").removeClass("hidden");
            //             $("#edit_employee").prop("disabled", true);
            //             console.log("data4", data);
            //             props.actionEmployeeEdit(data, files);
            //         } else {
            //             $('#error-edit-bqp-certificate-not-found').removeClass('d-none')
            //         }

            //     }

            // }
            if ((data.bqp_certified === 'Valid' || data.bqp_certified === 'Expired') && (nocType === 'NOC Received' || employeeDetails?.certificate?.noc_type === 'NOC Received')) {
                // alert('1')
                if ((employeeDetails?.certificate?.employee_bqp_document != null || uploadCertificateCopy != null) && (employeeDetails?.certificate?.employee_noc_document != null || uploadNocDocument != null)) {
                    files.push({ 'name': 'bqp_certificate', 'value': uploadCertificateCopy });
                    files.push({ 'name': 'noc_document', 'value': uploadNocDocument });
                    $("#edit_employee-loader").removeClass("hidden");
                    $("#edit_employee").prop("disabled", true);
                    console.log("data2", data);
                    props.actionEmployeeEdit(data, files);
                } else if ((employeeDetails?.certificate?.employee_bqp_document === null && uploadCertificateCopy === null) || (employeeDetails?.certificate?.employee_bqp_document === undefined && uploadCertificateCopy === undefined)) {
                    $('#error-edit-bqp-certificate-not-found').removeClass('d-none')
                } else if ((employeeDetails?.certificate?.employee_noc_document === null && uploadNocDocument === null) || (employeeDetails?.certificate?.employee_noc_document === undefined || uploadNocDocument === undefined)) {
                    $('#error-noc-document-not-found').removeClass('d-none')
                }
            } else if (data.bqp_certified === 'Valid' || data.bqp_certified === 'Expired') {
                // alert('2')
                if (employeeDetails?.certificate?.employee_bqp_document != null || uploadCertificateCopy != null) {
                    files.push({ 'name': 'bqp_certificate', 'value': uploadCertificateCopy });
                    $("#edit_employee-loader").removeClass("hidden");
                    $("#edit_employee").prop("disabled", true);
                    console.log("data2", data);
                    props.actionEmployeeEdit(data, files);
                } else {
                    $('#error-edit-bqp-certificate-not-found').removeClass('d-none')
                }
            } else if (data.bqp_certified === 'Not Available' || data.bqp_certified === 'Not Applicable') {
                $("#edit_employee-loader").removeClass("hidden");
                $("#edit_employee").prop("disabled", true);
                props.actionEmployeeEdit(data, files);
            }
        } else {
            $("#edit_employee-loader").removeClass("hidden");
            $("#edit_employee").prop("disabled", true);
            props.actionEmployeeEdit(data, files);
        }
        delete data.bqp_certificate;
        delete data.noc_document;
    }

    return (
        <>
            <ScreenTitle title="Employee Edit" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">First Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="first_name"
                                                        name="first_name"
                                                        placeholder="Employee First Name"
                                                        {...register('first_name', { required: 'First Name is required' })}
                                                    />
                                                    {errors.first_name && <ErrorLabel message={errors.first_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Last Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="last_name"
                                                        name="last_name"
                                                        placeholder="Employee Last Name"
                                                        {...register('last_name', { required: 'Last Name is required' })}
                                                    />
                                                    {errors.last_name && <ErrorLabel message={errors.last_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Pinc Employee Id&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="pinc_employee_id"
                                                        name="pinc_employee_id"
                                                        placeholder="Employee Id"
                                                        {...register('pinc_employee_id', { required: 'Employee Id is required' })}
                                                    />
                                                    {errors.pinc_employee_id && <ErrorLabel message={errors.pinc_employee_id?.message} />}
                                                </div>
                                                <div class="form-check cust-form-check mt-0 mb-1">
                                                    <input
                                                        name="is_on_probation"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="is_on_probation"
                                                        {...register("is_on_probation")}
                                                    />
                                                    <label class="form-check-label my-auto" for="is_on_probation">
                                                        <span className="cust-fs-12">Probation Applicable</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Saiba Id</label>
                                                    <input
                                                        className="form-control"
                                                        id="saiba_rm_id"
                                                        name="saiba_rm_id"
                                                        placeholder="Enter Saiba Id"
                                                        {...register('saiba_rm_id')}
                                                    />
                                                </div>
                                                <div class="form-check cust-form-check mt-0 mb-1">
                                                    <input
                                                        name="auto_attendance"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="auto_attendance"
                                                        {...register("auto_attendance")}
                                                    />
                                                    <label class="form-check-label my-auto" for="auto_attendance">
                                                        <span className="cust-fs-12">Auto Attendance</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Email Id&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Employee Email Id"
                                                        {...register('email', {
                                                            required: 'Email Id is required',
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                message: 'Please enter valid Email Id'
                                                            },
                                                        })}
                                                    />
                                                    {errors.email && <ErrorLabel message={errors.email?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Employee Mobile Number&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="mobile_no"
                                                        name="mobile_no"
                                                        maxLength={10}
                                                        placeholder="Employee Mobile Number"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();

                                                            }
                                                        }}
                                                        {...register('mobile_no', {
                                                            required: 'Employee Mobile Number is required',
                                                            pattern: {
                                                                value: /^[6789]\d{9}$/,
                                                                message: 'Please enter 10 digit valid Mobile Number'
                                                            }
                                                        })}
                                                    />
                                                    {errors.mobile_no && <ErrorLabel message={errors.mobile_no?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Gender&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="gender"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        title: "Male",
                                                                        id: 'male'
                                                                    },
                                                                    {
                                                                        title: "Female",
                                                                        id: 'female'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Gender"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Gender'
                                                        }}
                                                    />
                                                    {errors.gender && <ErrorLabel message={errors.gender?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date Of Birth&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="date_of_birth"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                {...field}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                max={new Date()}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                displayFormat="dd/MM/yyyy"
                                                                placeholder="Employee Date Of Birth"
                                                                showClearButton={true}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Employee Date Of Birth is required'
                                                        }}
                                                    />
                                                    {errors.date_of_birth && <ErrorLabel message={errors.date_of_birth?.message} />}

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Joining&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="joining_date"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                {...field}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                value={field.value}
                                                                acceptCustomValue={false}
                                                                displayFormat="dd/MM/yyyy"
                                                                placeholder="Employee Date Of Joining"
                                                                showClearButton={true}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'))
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Employee Joining Date is required'
                                                        }}
                                                    />
                                                    {errors.joining_date && <ErrorLabel message={errors.joining_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="companies"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: masterEntity,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                placeholder="Select Entity"
                                                                valueExpr="id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={4}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {

                                                                        field.onChange(value);
                                                                        setValue('reimursment_primary_entity_id', '');
                                                                        // Call Sub entity API
                                                                        props.actionMasterSubEntity({ 'company_ids': value });

                                                                        setMasterSubEntity([]);
                                                                        setMasterBranchCity({});
                                                                        setMasterBranchAddress({});
                                                                        setValue('sub_companies', []);
                                                                        setValue('city', '');
                                                                        setValue('branch', '');
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select entity'
                                                        }}
                                                    />
                                                    {errors.companies && <ErrorLabel message={errors.companies?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Department&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="sub_companies"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: masterSubEntity,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                placeholder="Select Department"
                                                                valueExpr="id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={2}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value)
                                                                        setValue('reimursment_primary_department_id', null);
                                                                        setValue('reimursment_primary_entity_id', null);
                                                                        setSelectedMasterSubEntity(value)
                                                                        // Call Branch City API
                                                                        props.actionMasterBranchCity({ 'company_ids': value });
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Department'
                                                        }}
                                                    />
                                                    {errors.sub_companies && <ErrorLabel message={errors.sub_companies?.message} />}


                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reimbursement Primary Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reimursment_primary_entity_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterPrimaryEntity}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Reimbursement Primary Entity"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reimbursement Primary Entity'
                                                        }}
                                                    />
                                                    {errors.reimursment_primary_entity_id && <ErrorLabel message={errors.reimursment_primary_entity_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reimbursement Primary Department&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reimursment_primary_department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterPrimarySubEntity}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Reimbursement Primary Department"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reimbursement Primary Department'
                                                        }}
                                                    />
                                                    {errors.reimursment_primary_department_id && <ErrorLabel message={errors.reimursment_primary_department_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Branch City&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="city"

                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterBranchCity}
                                                                allowFiltering={false}
                                                                fields={{ text: "name", value: "id" }}
                                                                placeholder="Select Branch City"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)

                                                                        // Call Branch Address API
                                                                        props.actionMasterBranchAddress({ 'city_ids': event.itemData.id });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Branch City'
                                                        }}
                                                    />
                                                    {errors.city && <ErrorLabel message={errors.city?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Branch Address&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="branch"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterBranchAddress}
                                                                allowFiltering={false}
                                                                fields={{ text: "address1", value: "id" }}
                                                                placeholder="Select Branch Address"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Branch Address'
                                                        }}
                                                    />
                                                    {errors.branch && <ErrorLabel message={errors.branch?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Payroll Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="payroll_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterPayrollEntity}
                                                                allowFiltering={false}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                placeholder="Select Payroll Entity"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Payroll Entity'
                                                        }}
                                                    />
                                                    {errors.payroll_id && <ErrorLabel message={errors.payroll_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Function&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="department"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: masterDepartment,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                valueExpr="id"
                                                                placeholder="Select Function"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={4}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value);
                                                                        setFunctions(value)
                                                                        setMasterGetReporting([]);

                                                                        props.actionMasterGetReporting({
                                                                            'companies_ids': selectedMasterSubEntity,
                                                                            'department_ids': value
                                                                        })

                                                                        props.actionMasterApprovalManager({
                                                                            'department_ids': value
                                                                        })

                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Function'
                                                        }}
                                                    />
                                                    {errors.department && <ErrorLabel message={errors.department?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reporting To&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reporting_to_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterGetReporting}
                                                                allowFiltering={true}
                                                                fields={{ text: 'name', value: 'user_id' }}
                                                                placeholder="Select Reporting To"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id);
                                                                        $("#same_as_approval_manager").attr('disabled', true);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reporting To'
                                                        }}
                                                    />
                                                    {errors.reporting_to_id && <ErrorLabel message={errors.reporting_to_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Grade&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="grade_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterGrade}
                                                                allowFiltering={false}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                placeholder="Select Grade"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id);
                                                                        var arrGradeIds = [];
                                                                        arrGradeIds.push(event.itemData.id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Grade'
                                                        }}
                                                    />
                                                    {errors.grade_id && <ErrorLabel message={errors.grade_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Designation&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="designation_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterDesignation}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Designation"
                                                                value={field.value}
                                                                sortOrder={"Ascending"}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)

                                                                        var arrDesignationIds = [];

                                                                        arrDesignationIds.push(event.itemData.id);

                                                                        props.actionMasterJobRole({
                                                                            'designation_ids': arrDesignationIds
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Designation'
                                                        }}
                                                    />
                                                    {errors.designation_id && <ErrorLabel message={errors.designation_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Facing Designation&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="job_role"
                                                        name="job_role"
                                                        placeholder="Client Facing Designation"
                                                        {...register('job_role', { required: 'Client Facing Designation is required' })}
                                                    />
                                                    {errors.job_role && <ErrorLabel message={errors.job_role?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Permission Role&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="permission_role_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={permissionRoles}
                                                                allowFiltering={false}
                                                                fields={{ text: 'title', value: 'id' }}
                                                                placeholder="Select Permission Role"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Permission Role'
                                                        }}
                                                    />
                                                    {errors.permission_role_id && <ErrorLabel message={errors.permission_role_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Total Leave Balance&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="total_leaves"
                                                        name="total_leaves"
                                                        placeholder="Employee Total leave balance"
                                                        maxLength={6}
                                                        {...register('total_leaves',
                                                            {
                                                                required: 'Total leaves is required',
                                                            }
                                                        )}
                                                    />
                                                    {errors.total_leaves && <ErrorLabel message={errors.total_leaves?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Leave Kitty</label>
                                                    <input
                                                        className="form-control"
                                                        id="leave_kitty"
                                                        name="leave_kitty"
                                                        placeholder="Leave kitty"
                                                        maxLength={6}
                                                        {...register('leave_kitty')}
                                                    />
                                                    {errors.leave_kitty && <ErrorLabel message={errors.leave_kitty?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Employee Shift &nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="shift_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={employeeShifts}
                                                                fields={{ text: "shift_name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Employee Shift"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Employee Shift'
                                                        }}
                                                    />
                                                    {errors.shift_id && <ErrorLabel message={errors.shift_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Working Days&nbsp;</label>
                                                    <Controller
                                                        name="working_days"
                                                        control={control}
                                                        defaultValue={showWorkingDays}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="working_days"
                                                                name="working_days"
                                                                dataSource={[
                                                                    {
                                                                        id: 5,
                                                                        name: '5 days'
                                                                    },
                                                                    {
                                                                        id: 6,
                                                                        name: '6 days'
                                                                    }
                                                                ]}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Working Days"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {
                                                functions?.length > 0 && (functions.includes(9) || functions.includes(7) || functions.includes(8) || functions.includes(13) || functions.includes(16)) ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">BQP Status&nbsp;<span className="danger">*</span></label>
                                                            <Controller
                                                                name="bqp_status"
                                                                control={control}
                                                                defaultValue={showWorkingDays}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="bqp_status"
                                                                        name="bqp_status"
                                                                        dataSource={[
                                                                            {
                                                                                id: 'training',
                                                                                name: 'Training Completed'
                                                                            },
                                                                            {
                                                                                id: 'exam',
                                                                                name: 'Exam Completed'
                                                                            }
                                                                        ]}
                                                                        fields={{ text: "name", value: "id" }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select BQP Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.id)
                                                                                setBqpStatus(event.itemData.id)
                                                                                setValue('bqp_certified', null)
                                                                                setValue('bqp_certificate', null)
                                                                                setValue('noc_document', null)
                                                                                setValue('certificate_number', '')
                                                                                setValue('end_date', '')
                                                                                setValue('start_date', '')
                                                                                setUploadCertificateCopy(null)
                                                                                setValue('brokering_entity', null)
                                                                                setValue('noc_type', null)
                                                                                setUploadNocDocument(null)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select BQP Status'
                                                                }}
                                                            />
                                                            {errors.bqp_status && <ErrorLabel message={errors.bqp_status?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                functions?.length > 0 && (functions.includes(9) || functions.includes(7) || functions.includes(8) || functions.includes(13) || functions.includes(16)) && bqpStatus != null && bqpStatus === 'exam' ?
                                                    <>
                                                        <div className="col-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label className="label-control">BQP Certified&nbsp;<span className="danger">*</span></label>
                                                                <Controller
                                                                    name="bqp_certified"
                                                                    control={control}
                                                                    render={({ field }) =>
                                                                        <DropDownListComponent
                                                                            {...field}
                                                                            id="bqp_certified"
                                                                            name="bqp_certified"
                                                                            dataSource={[
                                                                                {
                                                                                    value: 'Valid',
                                                                                    name: 'Valid'
                                                                                },
                                                                                {
                                                                                    value: 'Expired',
                                                                                    name: 'Expired'
                                                                                },
                                                                                {
                                                                                    value: 'Not Available',
                                                                                    name: 'Not Available'
                                                                                },
                                                                                {
                                                                                    value: 'Not Applicable',
                                                                                    name: 'Not Applicable'
                                                                                }
                                                                            ]}
                                                                            fields={{ text: "name", value: "value" }}
                                                                            allowFiltering={false}
                                                                            placeholder="Select BQP certified"
                                                                            value={field.value}
                                                                            enabled={verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                            change={(event) => {
                                                                                if (event.itemData != null) {
                                                                                    field.onChange(event.itemData.value)
                                                                                    setBQPCertified(event.itemData.value)
                                                                                    setValue('bqp_certificate', null)
                                                                                    setValue('noc_document', null)
                                                                                    setValue('certificate_number', '')
                                                                                    setValue('end_date', '')
                                                                                    setValue('start_date', '')
                                                                                    setUploadCertificateCopy(null)
                                                                                    setValue('brokering_entity', null)
                                                                                    setValue('noc_type', null)
                                                                                    setUploadNocDocument(null)
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                    rules={{
                                                                        required: "Please Select BQP Certified"
                                                                    }}
                                                                />
                                                                {errors.bqp_certified && <ErrorLabel message={errors.bqp_certified?.message} />}
                                                            </div>
                                                        </div>
                                                        {bqpCertified != null && bqpCertified === 'Valid' || bqpCertified === 'Expired'
                                                            ?
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="label-control">Certificate Number&nbsp;<span className="danger">*</span></label>
                                                                    <input
                                                                        className="form-control"
                                                                        id="certificate_number"
                                                                        name="certificate_number"
                                                                        placeholder="Certificate Number"
                                                                        disabled={!verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                        {...register('certificate_number', { required: "Please Enter Certificate Number" })}
                                                                    />
                                                                    {errors.certificate_number && <ErrorLabel message={errors.certificate_number?.message} />}
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            bqpCertified != null && bqpCertified === 'Expired' ?
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label className="label-control">Expiry Date&nbsp;<span className="danger">*</span></label>
                                                                        <Controller
                                                                            name="end_date"
                                                                            control={control}
                                                                            render={({ field }) =>
                                                                                <DateBox
                                                                                    calendarOptions={{
                                                                                        showTodayButton: true,
                                                                                    }}
                                                                                    {...field}
                                                                                    id="end_date"
                                                                                    name="end_date"
                                                                                    stylingMode="underlined"
                                                                                    className="form-control"
                                                                                    pickerType="calendar"
                                                                                    type="date"
                                                                                    max={new Date()}
                                                                                    acceptCustomValue={false}
                                                                                    value={field.value}
                                                                                    displayFormat="dd/MM/yyyy"
                                                                                    placeholder="Expiry Date"
                                                                                    showClearButton={true}
                                                                                    disabled={!verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                                    onValueChange={(value) => {
                                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                    }}
                                                                                />
                                                                            }
                                                                            rules={{
                                                                                required: 'Expiry Date is Required'
                                                                            }}
                                                                        />
                                                                        {errors.end_date && <ErrorLabel message={errors.end_date?.message} />}

                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {bqpCertified != null && bqpCertified === 'Valid' ?
                                                            <>
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                                        <Controller
                                                                            name="start_date"
                                                                            control={control}
                                                                            render={({ field }) =>
                                                                                <DateBox
                                                                                    calendarOptions={{
                                                                                        showTodayButton: true,
                                                                                    }}
                                                                                    {...field}
                                                                                    id="start_date"
                                                                                    name="start_date"
                                                                                    stylingMode="underlined"
                                                                                    className="form-control"
                                                                                    pickerType="calendar"
                                                                                    type="date"
                                                                                    min={moment(new Date()).subtract(3, 'year').format('YYYY-MM-DD')}
                                                                                    max={moment(new Date())}
                                                                                    acceptCustomValue={false}
                                                                                    value={field.value}
                                                                                    displayFormat="dd/MM/yyyy"
                                                                                    placeholder="Start Date"
                                                                                    showClearButton={true}
                                                                                    disabled={!verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                                    onValueChange={(value) => {
                                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                    }}
                                                                                />
                                                                            }
                                                                            rules={{
                                                                                required: 'Start Date is Required'
                                                                            }}
                                                                        />
                                                                        {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}

                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label className="label-control">End date&nbsp;<span className="danger">*</span></label>
                                                                        <Controller
                                                                            name="end_date"
                                                                            control={control}
                                                                            render={({ field }) =>
                                                                                <DateBox
                                                                                    calendarOptions={{
                                                                                        showTodayButton: true,
                                                                                    }}
                                                                                    {...field}
                                                                                    id="end_date"
                                                                                    name="end_date"
                                                                                    stylingMode="underlined"
                                                                                    className="form-control"
                                                                                    pickerType="calendar"
                                                                                    type="date"
                                                                                    min={moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}
                                                                                    max={moment(new Date()).add(3, 'year').format('YYYY-MM-DD')}
                                                                                    acceptCustomValue={false}
                                                                                    value={field.value}
                                                                                    displayFormat="dd/MM/yyyy"
                                                                                    placeholder="End Date"
                                                                                    disabled={!verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                                    showClearButton={true}
                                                                                    onValueChange={(value) => {
                                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                                    }}
                                                                                />
                                                                            }
                                                                            rules={{
                                                                                required: 'End Date is Required'
                                                                            }}
                                                                        />
                                                                        {errors.end_date && <ErrorLabel message={errors.end_date?.message} />}

                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                        }

                                                        {bqpCertified != null && bqpCertified === 'Valid' || bqpCertified === 'Expired'
                                                            ?
                                                            <>
                                                                {/* {
                                                                    nocType != null && nocType === 'NOC Received' ? */}

                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <fieldset class="form-group">
                                                                        <label for="basicInputFile">Upload Certificate Copy&nbsp;<span className="danger">*</span></label>
                                                                        <div class="custom-file">
                                                                            <input
                                                                                type="file"
                                                                                class="custom-file-input"
                                                                                accept=".jpg, .jpeg, .png"
                                                                                id="bqp_certificate"
                                                                                name="bqp_certificate"
                                                                                disabled={!verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                                onChange={(event) => {
                                                                                    $('#error-edit-bqp-certificate-not-found').addClass('d-none')
                                                                                    if (event.target.files != null) {

                                                                                        var fileSize = event.target.files[0]?.size;

                                                                                        var ext = (event.target.files[0]?.name).split('.').pop();

                                                                                        if (checkFileExtension(ext, validExt)) {

                                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                                setUploadCertificateCopy(event.target.files[0]);
                                                                                            } else {
                                                                                                Swal.fire({
                                                                                                    title: 'Error',
                                                                                                    icon: 'error',
                                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                                }).then((result) => {

                                                                                                });
                                                                                            }
                                                                                        } else {
                                                                                            Swal.fire({
                                                                                                title: 'Upload Error',
                                                                                                text: 'You can only upload jpg, jpeg & png files',
                                                                                                icon: 'error'
                                                                                            }).then(success => {

                                                                                            })
                                                                                        }
                                                                                    } else {
                                                                                        $('#error-edit-bqp-certificate-not-found').removeClass('d-none')
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <small className="error-msg d-none text-danger" id="error-edit-bqp-certificate-not-found">Document is required</small>
                                                                            <label class="custom-file-label" for="bqp_certificate">
                                                                                {
                                                                                    uploadCertificateCopy != null ?
                                                                                        uploadCertificateCopy.name : "Choose file"
                                                                                }
                                                                            </label>
                                                                            {
                                                                                employeeDetails?.certificate != null && employeeDetails?.certificate?.employee_bqp_document != null ?
                                                                                    <div id="view_document mt-1" >
                                                                                        {/* <a className='primary' target="_blank" rel="noreferrer" href={employeeDetails?.certificate?.employee_bqp_document}>View Document</a> */}
                                                                                        <a className="primary"
                                                                                            onClick={() => {
                                                                                                if (employeeDetails?.certificate?.employee_bqp_document?.split('.').pop() == 'pdf' ||
                                                                                                    employeeDetails?.certificate?.employee_bqp_document?.split('.').pop() == 'PDF' ||
                                                                                                    employeeDetails?.certificate?.employee_bqp_document?.split('.').pop() == 'png' ||
                                                                                                    employeeDetails?.certificate?.employee_bqp_document?.split('.').pop() == 'jpg' ||
                                                                                                    employeeDetails?.certificate?.employee_bqp_document?.split('.').pop() == 'jpeg' ||
                                                                                                    employeeDetails?.certificate?.employee_bqp_document?.split('.').pop() == 'gif'
                                                                                                ) {
                                                                                                    setSelectedDocumentIndex(employeeDetails?.certificate?.employee_bqp_document)
                                                                                                    setShowDocumentDetails(true);
                                                                                                    setExtension(employeeDetails?.certificate?.employee_bqp_document?.split('.').pop())
                                                                                                    setDocumentDetails(employeeDetails)
                                                                                                } else {
                                                                                                    downloadFile(employeeDetails?.certificate?.employee_bqp_document);
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            View Document
                                                                                        </a>
                                                                                        <a id="downloadLink" className="d-none"></a>
                                                                                    </div>
                                                                                    :
                                                                                    <></>
                                                                            }

                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                                {/* :
                                                                        <></>
                                                                } */}
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label className="label-control">Brokering Entity&nbsp;<span className="danger">*</span></label>
                                                                        <Controller
                                                                            name="brokering_entity"
                                                                            control={control}
                                                                            render={({ field }) =>
                                                                                <DropDownListComponent
                                                                                    {...field}
                                                                                    id="brokering_entity"
                                                                                    name="brokering_entity"
                                                                                    dataSource={[
                                                                                        {
                                                                                            value: 'Previous Organization',
                                                                                            name: 'Previous Organization'
                                                                                        },
                                                                                        {
                                                                                            value: 'PINC',
                                                                                            name: 'PINC '
                                                                                        },
                                                                                    ]}
                                                                                    fields={{ text: "name", value: "value" }}
                                                                                    allowFiltering={false}
                                                                                    placeholder="Select Brokering Entity"
                                                                                    value={field.value}
                                                                                    enabled={verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                                    change={(event) => {
                                                                                        if (event.itemData != null) {
                                                                                            field.onChange(event.itemData.value)
                                                                                            setBrokeringEntity(event.itemData.value)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                            rules={{
                                                                                required: "Please Select Brokering Entity"
                                                                            }}
                                                                        />
                                                                        {errors.brokering_entity && <ErrorLabel message={errors.brokering_entity?.message} />}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    brokeringEntity != null && brokeringEntity === 'Previous Organization' ?
                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                            <div className="form-group">
                                                                                <label className="label-control">NOC&nbsp;<span className="danger">*</span></label>
                                                                                <Controller
                                                                                    name="noc_type"
                                                                                    control={control}
                                                                                    render={({ field }) =>
                                                                                        <DropDownListComponent
                                                                                            {...field}
                                                                                            id="noc_type"
                                                                                            name="noc_type"
                                                                                            dataSource={[
                                                                                                {
                                                                                                    value: 'NOC Received',
                                                                                                    name: 'NOC Received'
                                                                                                },
                                                                                                {
                                                                                                    value: 'NOC Pending',
                                                                                                    name: 'NOC Pending'
                                                                                                },
                                                                                            ]}
                                                                                            fields={{ text: "name", value: "value" }}
                                                                                            allowFiltering={false}
                                                                                            placeholder="Select NOC"
                                                                                            value={field.value}
                                                                                            enabled={verifyPermission("BROKER_TRAINING_EDIT_HR_FIELDS") ? true : false}
                                                                                            change={(event) => {
                                                                                                if (event.itemData != null) {
                                                                                                    field.onChange(event.itemData.value)
                                                                                                    setNocType(event.itemData.value)
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    rules={{
                                                                                        required: "Please Select NOC"
                                                                                    }}
                                                                                />
                                                                                {errors.noc_type && <ErrorLabel message={errors.noc_type?.message} />}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    nocType != null && nocType === 'NOC Received' ?
                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                            <fieldset class="form-group">
                                                                                <label for="basicInputFile">NOC Document&nbsp;<span className="danger">*</span></label>
                                                                                <div class="custom-file">
                                                                                    <input
                                                                                        type="file"
                                                                                        class="custom-file-input"
                                                                                        accept=".jpg, .jpeg, .png"
                                                                                        id="noc_document"
                                                                                        name="noc_document"
                                                                                        onChange={(event) => {
                                                                                            $('#error-noc-document-not-found').addClass('d-none')
                                                                                            if (event.target.files != null) {

                                                                                                var fileSize = event.target.files[0].size;

                                                                                                var ext = (event.target.files[0].name).split('.').pop();

                                                                                                if (checkFileExtension(ext, validExt)) {

                                                                                                    if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                                        setUploadNocDocument(event.target.files[0]);
                                                                                                    } else {
                                                                                                        Swal.fire({
                                                                                                            title: 'Error',
                                                                                                            icon: 'error',
                                                                                                            html: MEETING_FILE_ERROR_MESSAGE,
                                                                                                            confirmButtonColor: PRIMARY_COLOR
                                                                                                        }).then((result) => {

                                                                                                        });
                                                                                                    }
                                                                                                } else {
                                                                                                    Swal.fire({
                                                                                                        title: 'Upload Error',
                                                                                                        text: 'You can only upload jpg, jpeg & png files',
                                                                                                        icon: 'error'
                                                                                                    }).then(success => {

                                                                                                    })
                                                                                                }
                                                                                            } else {
                                                                                                $('#error-noc-document-not-found').removeClass('d-none')
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <small className="error-msg d-none text-danger" id="error-noc-document-not-found">Document is required</small>
                                                                                    <label class="custom-file-label" for="noc_document">
                                                                                        {
                                                                                            uploadNocDocument != null ?
                                                                                                uploadNocDocument.name : "Choose file"
                                                                                        }
                                                                                    </label>
                                                                                    {
                                                                                        employeeDetails?.certificate != null && employeeDetails?.certificate?.employee_noc_document != null ?
                                                                                            <div id="view_document mt-1" >
                                                                                                {/* <a className='primary' target="_blank" rel="noreferrer" href={employeeDetails?.certificate?.employee_bqp_document}>View Document</a> */}
                                                                                                <a className="primary"
                                                                                                    onClick={() => {
                                                                                                        if (employeeDetails?.certificate?.employee_noc_document?.split('.').pop() == 'pdf' ||
                                                                                                            employeeDetails?.certificate?.employee_noc_document?.split('.').pop() == 'PDF' ||
                                                                                                            employeeDetails?.certificate?.employee_noc_document?.split('.').pop() == 'png' ||
                                                                                                            employeeDetails?.certificate?.employee_noc_document?.split('.').pop() == 'jpg' ||
                                                                                                            employeeDetails?.certificate?.employee_noc_document?.split('.').pop() == 'jpeg' ||
                                                                                                            employeeDetails?.certificate?.employee_noc_document?.split('.').pop() == 'gif'
                                                                                                        ) {
                                                                                                            setSelectedDocumentIndex(employeeDetails?.certificate?.employee_noc_document)
                                                                                                            setShowDocumentDetails(true);
                                                                                                            setExtension(employeeDetails?.certificate?.employee_noc_document?.split('.').pop())
                                                                                                            setDocumentDetails(employeeDetails)
                                                                                                        } else {
                                                                                                            downloadFile(employeeDetails?.certificate?.employee_noc_document);
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    View Document
                                                                                                </a>
                                                                                                <a id="downloadLink" className="d-none"></a>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Team
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Team Name</label>
                                                    <Controller
                                                        name="team_master_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterTeamHead}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Team Name"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.team_master_id && <ErrorLabel message={errors.team_master_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Approval Manager
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="approval_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        $("#same_as_approval_manager").removeAttr('disabled');
                                                                        field.onChange(event.itemData.user_id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Approval Manager'
                                                        }}
                                                    />
                                                    {errors.approval_manager_id && <ErrorLabel message={errors.approval_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="same_as_approval_manager"
                                                            id="same_as_approval_manager"
                                                            defaultChecked={sameAsApprovalManager}
                                                            checked={sameAsApprovalManager}
                                                            onChange={(event) => {
                                                                setSameAsApprovalManager(event.target.checked);
                                                                setValue('asset_manager_id', getValues('approval_manager_id'));
                                                                setValue('finance_manager_id', getValues('approval_manager_id'));
                                                                setValue('travel_manager_id', getValues('approval_manager_id'));
                                                                setValue('attendance_manager_id', getValues('approval_manager_id'));
                                                                setValue('meeting_manager_id', getValues('approval_manager_id'));
                                                                setValue('performance_manager_id', getValues('approval_manager_id'));
                                                                setValue('reimburstment_approval_manager_id', getValues('approval_manager_id'));
                                                            }}
                                                        />

                                                        <label class="custom-control-label" for="same_as_approval_manager">Same As Approval Manager</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Other Approval Manager
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Asset Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="asset_manager_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterAssetApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Asset Approval Manager"
                                                                value={field.value}
                                                                enabled={!sameAsApprovalManager}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Asset Approval Manager'
                                                        }}
                                                    />
                                                    {errors.asset_manager_id && <ErrorLabel message={errors.asset_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Finance Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="finance_manager_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterFinanceApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Finance Approval Manager"
                                                                value={field.value}
                                                                enabled={!sameAsApprovalManager}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Finance Approval Manager'
                                                        }}
                                                    />
                                                    {errors.finance_manager_id && <ErrorLabel message={errors.finance_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Travel Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="travel_manager_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterTravelApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Travel Approval Manager"
                                                                value={field.value}
                                                                enabled={!sameAsApprovalManager}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Travel Approval Manager'
                                                        }}
                                                    />
                                                    {errors.travel_manager_id && <ErrorLabel message={errors.travel_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Attendance Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="attendance_manager_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterAttendanceApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Attendance Approval Manager"
                                                                value={field.value}
                                                                enabled={!sameAsApprovalManager}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Attendance Approval Manager'
                                                        }}
                                                    />
                                                    {errors.attendance_manager_id && <ErrorLabel message={errors.attendance_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Meeting Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="meeting_manager_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterMeetingApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Meeting Approval Manager"
                                                                value={field.value}
                                                                enabled={!sameAsApprovalManager}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Meeting Approval Manager'
                                                        }}
                                                    />
                                                    {errors.meeting_manager_id && <ErrorLabel message={errors.meeting_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Performance Objective Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="performance_manager_id"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterPerformanceObjectiveApprovalManager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Performance Objective Approval Manager"
                                                                value={field.value}
                                                                enabled={!sameAsApprovalManager}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Performance Objective Approval Manager'
                                                        }}
                                                    />
                                                    {errors.performance_manager_id && <ErrorLabel message={errors.performance_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reimbursement Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="reimburstment_approval_manager_id"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterreimburstmentapprovalmanager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                enabled={!sameAsApprovalManager}
                                                                placeholder="Select Reimbursement Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Reimbursement Approval Manager'
                                                        }}
                                                    />
                                                    {errors.reimburstment_approval_manager_id && <ErrorLabel message={errors.reimburstment_approval_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Finance Reimbursement Approval Manager&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="finance_reimburstment_approval_manager_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={masterfinancereimburstmentapprovalmanager}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                enabled={!sameAsApprovalManager}
                                                                placeholder="Select Finance Reimbursement Approval Manager"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Finance Reimbursement Approval Manager'
                                                        }}
                                                    />
                                                    {errors.finance_reimburstment_approval_manager_id && <ErrorLabel message={errors.finance_reimburstment_approval_manager_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Other Information
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Vertical</label>
                                                    <input
                                                        className="form-control"
                                                        id="vertical"
                                                        name="vertical"
                                                        placeholder="Employee Vertical"
                                                        {...register('vertical')}
                                                    />
                                                    {errors.vertical && <ErrorLabel message={errors.vertical?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Segment</label>
                                                    <input
                                                        className="form-control"
                                                        id="segment"
                                                        name="segment"
                                                        placeholder="Employee Segment"
                                                        {...register('segment')}
                                                    />
                                                    {errors.segment && <ErrorLabel message={errors.segment?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Region</label>
                                                    <input
                                                        className="form-control"
                                                        id="region"
                                                        name="region"
                                                        placeholder="Employee Region"
                                                        {...register('region')}
                                                    />
                                                    {errors.region && <ErrorLabel message={errors.region?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Product Specialist
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Product&nbsp;</label>
                                                    <Controller
                                                        name="product_ids"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                id="product_ids"
                                                                name="product_ids"
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: proposalPolicy,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        ('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        ('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        ('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        ('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                valueExpr="id"
                                                                placeholder="Select Product"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                maxDisplayedTags={2}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value}
                                                                onValueChanged={({ value }) => {
                                                                    if (value != null || value !== undefined) {
                                                                        field.onChange(value);
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: isProduct === true ? 'Please Select Product' : false
                                                        }}
                                                    />
                                                    {errors.product_ids && <ErrorLabel message={errors.product_ids?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div class="form-check cust-form-check mt-0 mb-1 ml-1">
                                                    <input
                                                        name="is_product_specialist"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="is_product_specialist"
                                                        onClick={(event) => {
                                                            setIsProduct(event.target.checked);
                                                        }}
                                                        {...register("is_product_specialist")}
                                                    />
                                                    <label class="form-check-label my-auto" for="is_product_specialist">
                                                        <span className="cust-fs-12">User is product Specialist?</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Assign Rights For
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="request_view"
                                                            id="request_view"
                                                            {...register('request_view')}
                                                        />
                                                        {errors.request_view && <ErrorLabel message={errors.request_view?.message} />}
                                                        <label class="custom-control-label" for="request_view">Request View</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="request_approval"
                                                            id="request_approval"
                                                            {...register('request_approval')}
                                                        />
                                                        {errors.request_approval && <ErrorLabel message={errors.request_approval?.message} />}
                                                        <label class="custom-control-label" for="request_approval">Request Approval</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="task_operation"
                                                            id="task_operation"
                                                            {...register('task_operation')}
                                                        />
                                                        {errors.task_operation && <ErrorLabel message={errors.task_operation?.message} />}
                                                        <label class="custom-control-label" for="task_operation">Task Operation</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Upload Visiting Card
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Visiting Card Front</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="visiting_card_front"
                                                            name="visiting_card_front"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0]?.size;

                                                                    var ext = (event.target.files[0]?.name).split('.').pop();

                                                                    if (ext != null && checkFileExtension(ext.toLowerCase(), validExt)) {
                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setVisitingCardFront(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {

                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })

                                                                    }
                                                                }
                                                            }}
                                                        />

                                                        <label class="custom-file-label" for="visiting_card_front">
                                                            {
                                                                visitingCardFront != null ?
                                                                    visitingCardFront.name : "Choose file"
                                                            }
                                                        </label>
                                                        <div className="mt-1">
                                                            {
                                                                (employeeDetails != null && employeeDetails.visiting_card_front != null) ?
                                                                    <div id="action_visiting_card_front">
                                                                        {/* <a target="_blank" href={employeeDetails.visiting_card_front}> Click Here to View</a> */}
                                                                        <>
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (employeeDetails.visiting_card_front?.split('.').pop() == 'pdf' ||
                                                                                        employeeDetails.visiting_card_front?.split('.').pop() == 'PDF' ||
                                                                                        employeeDetails.visiting_card_front?.split('.').pop() == 'png' ||
                                                                                        employeeDetails.visiting_card_front?.split('.').pop() == 'jpg' ||
                                                                                        employeeDetails.visiting_card_front?.split('.').pop() == 'jpeg' ||
                                                                                        employeeDetails.visiting_card_front?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(employeeDetails.visiting_card_front)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(employeeDetails.visiting_card_front?.split('.').pop())
                                                                                        setDocumentDetails(employeeDetails)
                                                                                    } else {
                                                                                        downloadFile(employeeDetails.visiting_card_front);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Click Here to View
                                                                            </a>
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </>
                                                                        <FiTrash
                                                                            onClick={() => {
                                                                                Swal.fire({
                                                                                    icon: 'warning',
                                                                                    text: 'Are you sure, you want to delete this document?',
                                                                                    cancelButtonText: "Cancel",
                                                                                    showCancelButton: true,
                                                                                }).then(({ isConfirmed }) => {
                                                                                    if (isConfirmed) {
                                                                                        setDeleteDocumentType("action_visiting_card_front");
                                                                                        props.actionDeleteDocument({
                                                                                            "dms_id": employeeDetails.visiting_card_dms_id_front,
                                                                                            "type": "visiting_card_front",
                                                                                            "id": employeeDetails.user_id
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }}
                                                                            size={"16"}
                                                                            cursor={"pointer"}
                                                                            className="ml-half"
                                                                        ></FiTrash>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Visiting Card Back</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="visiting_card_back"
                                                            name="visiting_card_back"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0]?.size;

                                                                    var ext = (event.target.files[0]?.name).split('.').pop();

                                                                    if (ext != null && checkFileExtension(ext.toLowerCase(), validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setVisitingCardBack(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {

                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })

                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="visiting_card_back">
                                                            {
                                                                visitingCardBack != null ?
                                                                    visitingCardBack.name : "Choose file"
                                                            }
                                                        </label>
                                                        <div className="mt-1">
                                                            {
                                                                (employeeDetails != null && employeeDetails.visiting_card_back != null) ?
                                                                    <div id="action_visiting_card_back">
                                                                        <>
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (employeeDetails.visiting_card_back?.split('.').pop() == 'pdf' ||
                                                                                        employeeDetails.visiting_card_back?.split('.').pop() == 'PDF' ||
                                                                                        employeeDetails.visiting_card_back?.split('.').pop() == 'png' ||
                                                                                        employeeDetails.visiting_card_back?.split('.').pop() == 'jpg' ||
                                                                                        employeeDetails.visiting_card_back?.split('.').pop() == 'jpeg' ||
                                                                                        employeeDetails.visiting_card_back?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(employeeDetails.visiting_card_back)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(employeeDetails.visiting_card_back?.split('.').pop())
                                                                                        setDocumentDetails(employeeDetails)
                                                                                    } else {
                                                                                        downloadFile(employeeDetails.visiting_card_back);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Click Here to View
                                                                            </a>
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </>
                                                                        {/* <a target="_blank" href={employeeDetails.visiting_card_back}>
                                                                            Click Here to View
                                                                            </a> */}
                                                                        <FiTrash
                                                                            onClick={() => {
                                                                                Swal.fire({
                                                                                    icon: 'warning',
                                                                                    text: 'Are you sure, you want to delete this document?',
                                                                                    cancelButtonText: "Cancel",
                                                                                    showCancelButton: true,
                                                                                }).then(({ isConfirmed }) => {
                                                                                    if (isConfirmed) {
                                                                                        setDeleteDocumentType("action_visiting_card_back");
                                                                                        props.actionDeleteDocument({
                                                                                            "dms_id": employeeDetails.visiting_card_dms_id_back,
                                                                                            "type": "visiting_card_back",
                                                                                            "id": employeeDetails.user_id
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }}
                                                                            size={"16"}
                                                                            cursor={"pointer"}
                                                                            className="ml-half"
                                                                        ></FiTrash>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Profile Photo
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Profile Photo</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="profile_photo"
                                                            name="profile_photo"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0]?.size;

                                                                    var ext = (event.target.files[0].name)?.split('.').pop();

                                                                    if (ext != null && checkFileExtension(ext.toLowerCase(), validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setProfilePhoto(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="profile_photo">
                                                            {
                                                                profilePhoto != null ?
                                                                    profilePhoto.name : "Choose file"
                                                            }
                                                        </label>
                                                        <div className="mt-1">
                                                            {
                                                                (employeeDetails != null && employeeDetails.profile_photo != null) ?
                                                                    <div id="action_profile_photo">
                                                                        {/* <a target="_blank" href={employeeDetails.profile_photo}>Click Here to View</a> */}
                                                                        <>
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (employeeDetails.profile_photo?.split('.').pop() == 'pdf' ||
                                                                                        employeeDetails.profile_photo?.split('.').pop() == 'PDF' ||
                                                                                        employeeDetails.profile_photo?.split('.').pop() == 'png' ||
                                                                                        employeeDetails.profile_photo?.split('.').pop() == 'jpg' ||
                                                                                        employeeDetails.profile_photo?.split('.').pop() == 'jpeg' ||
                                                                                        employeeDetails.profile_photo?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(employeeDetails.profile_photo)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(employeeDetails.profile_photo?.split('.').pop())
                                                                                        setDocumentDetails(employeeDetails)
                                                                                    } else {
                                                                                        downloadFile(employeeDetails.profile_photo);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Click Here to View
                                                                            </a>
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </>
                                                                        <FiTrash
                                                                            onClick={() => {
                                                                                Swal.fire({
                                                                                    icon: 'warning',
                                                                                    text: 'Are you sure, you want to delete this document?',
                                                                                    cancelButtonText: "Cancel",
                                                                                    showCancelButton: true,
                                                                                }).then(({ isConfirmed }) => {
                                                                                    if (isConfirmed) {
                                                                                        setDeleteDocumentType("action_profile_photo");
                                                                                        props.actionDeleteDocument({
                                                                                            "dms_id": employeeDetails.profile_pic_dms_id,
                                                                                            "type": "profile_photo",
                                                                                            "id": employeeDetails.user_id
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }}
                                                                            size={"16"}
                                                                            cursor={"pointer"}
                                                                            className="ml-half"
                                                                        ></FiTrash>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Client Visible Image</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="client_visible_image"
                                                            name="client_visible_image"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0]?.size;

                                                                    var ext = (event.target.files[0]?.name).split('.').pop();

                                                                    if (ext != null && checkFileExtension(ext.toLowerCase(), validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setClientVisibleImage(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg & png files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="client_visible_image">
                                                            {
                                                                clientVisibleImage != null ?
                                                                    clientVisibleImage.name : "Choose file"
                                                            }
                                                        </label>
                                                        <div className="mt-1">
                                                            {
                                                                (employeeDetails != null && employeeDetails.client_visible_image != null) ?
                                                                    <div id="action_client_visible_image">
                                                                        {/* <a target="_blank" href={employeeDetails.client_visible_image}>Click Here to View</a> */}
                                                                        <>
                                                                            <a className="primary"
                                                                                onClick={() => {
                                                                                    if (employeeDetails.client_visible_image?.split('.').pop() == 'pdf' ||
                                                                                        employeeDetails.client_visible_image?.split('.').pop() == 'PDF' ||
                                                                                        employeeDetails.client_visible_image?.split('.').pop() == 'png' ||
                                                                                        employeeDetails.client_visible_image?.split('.').pop() == 'jpg' ||
                                                                                        employeeDetails.client_visible_image?.split('.').pop() == 'jpeg' ||
                                                                                        employeeDetails.client_visible_image?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(employeeDetails.client_visible_image)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(employeeDetails.client_visible_image?.split('.').pop())
                                                                                        setDocumentDetails(employeeDetails)
                                                                                    } else {
                                                                                        downloadFile(employeeDetails.client_visible_image);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Click Here to View
                                                                            </a>
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </>
                                                                        <FiTrash
                                                                            onClick={() => {
                                                                                Swal.fire({
                                                                                    icon: 'warning',
                                                                                    text: 'Are you sure, you want to delete this document?',
                                                                                    cancelButtonText: "Cancel",
                                                                                    showCancelButton: true,
                                                                                }).then(({ isConfirmed }) => {
                                                                                    if (isConfirmed) {
                                                                                        setDeleteDocumentType("action_client_visible_image");
                                                                                        props.actionDeleteDocument({
                                                                                            "dms_id": employeeDetails.client_visible_dms_id,
                                                                                            "type": "client_visible",
                                                                                            "id": employeeDetails.user_id
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }}
                                                                            size={"16"}
                                                                            cursor={"pointer"}
                                                                            className="ml-half"
                                                                        ></FiTrash>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_employee"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="edit_employee-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/hrms');
                                            }}
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    var ProposalPolicyType = null;
    var Entity = null;
    var SubEntity = null;
    var BranchCity = null;
    var Department = null;
    var Grade = null;
    var BranchAddress = null;
    var GetReporting = null;
    var JobRole = null;
    var Designation = null;
    var TeamHead = null;
    var ApprovalManager = null;
    var AssetApprovalManager = null;
    var FinanceApprovalManager = null;
    var TravelApprovalManager = null;
    var AttendanceApprovalManager = null;
    var MeetingApprovalManager = null;
    var PerformanceObjectiveApprovalManager = null;
    var Employee = null;
    var EmployeeDetails = null;
    var PermissionRole = null;
    var DocumentDelete = null;
    var EmployeeShift = null;
    var ReimbursementFinanceApprovalManager = null;

    if (
        state.EmployeeEditReducer != null &&
        Object.keys(state.EmployeeEditReducer).length > 0 &&
        state.EmployeeEditReducer.constructor === Object
    ) {
        switch (state.EmployeeEditReducer.tag) {
            case ACTION_CLAIMS_POLICY_TYPE_SUCCESS:
                ProposalPolicyType = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { ProposalPolicyType };
            case ACTION_API_MASTER_ENTITY_SUCCESS:
                Entity = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { Entity };
            case ACTION_API_MASTER_SUB_ENTITY_SUCCESS:
                SubEntity = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { SubEntity };
            case ACTION_API_MASTER_BRANCH_CITY_SUCCESS:
                BranchCity = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { BranchCity };
            case ACTION_API_MASTER_DEPARTMENT_SUCCESS:
                Department = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { Department };
            case ACTION_API_MASTER_GRADE_SUCCESS:
                Grade = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { Grade };
            case ACTION_API_MASTER_BRANCH_ADDRESS_SUCCESS:
                BranchAddress = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { BranchAddress };
            case ACTION_API_MASTER_GET_REPORTING_SUCCESS:
                GetReporting = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { GetReporting };
            case ACTION_API_MASTER_DESIGNATION_SUCCESS:
                Designation = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { Designation };
            case ACTION_API_MASTER_JOB_ROLE_SUCCESS:
                JobRole = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { JobRole };
            case ACTION_API_MASTER_TEAM_HEAD_SUCCESS:
                TeamHead = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { TeamHead };
            case ACTION_API_MASTER_APPROVAL_MANAGER_SUCCESS:
                ApprovalManager = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { ApprovalManager };
            case ACTION_EMPLOYEE_EDIT_SUCCESS:
                Employee = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { Employee };
            case ACTION_EMPLOYEE_DETAIL_SUCCESS:
                EmployeeDetails = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { EmployeeDetails };
            case ACTION_GETROLESDROPDOWNLIST_SUCCESS:
                PermissionRole = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { PermissionRole };
            case ACTION_DOCUMENT_DELETE_SUCCESS:
                DocumentDelete = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { DocumentDelete };
            case EMPLOYEE_SHIFT_TIMINGS_SUCCESS:
                EmployeeShift = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { EmployeeShift };
            case ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS:
                ReimbursementFinanceApprovalManager = Object.assign({}, state.EmployeeEditReducer.data);
                delete state.EmployeeEditReducer;
                return { ReimbursementFinanceApprovalManager };
            default:
        }
    }

    return {
        ProposalPolicyType,
        Entity,
        SubEntity,
        BranchCity,
        Department,
        Grade,
        BranchAddress,
        GetReporting,
        Designation,
        JobRole,
        TeamHead,
        ApprovalManager,
        AssetApprovalManager,
        FinanceApprovalManager,
        TravelApprovalManager,
        AttendanceApprovalManager,
        MeetingApprovalManager,
        PerformanceObjectiveApprovalManager,
        Employee,
        EmployeeDetails,
        PermissionRole,
        DocumentDelete,
        EmployeeShift,
        ReimbursementFinanceApprovalManager
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionProposalPolicyType,
        actionEmployeeEdit,
        actionMasterEntity,
        actionMasterSubEntity,
        actionMasterBranchCity,
        actionMasterBranchAddress,
        actionMasterDepartment,
        actionMasterGetReporting,
        actionMasterGrade,
        actionMasterDesignation,
        actionMasterJobRole,
        actionMasterTeamHead,
        actionMasterApprovalManager,
        actionEmployeeDetails,
        actionGetRolesDropdownList,
        actionDeleteDocument,
        actionGetEmployeeShifts,
        actionGetReimbursementFinanceApprovalManager
    }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit));