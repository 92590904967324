import React, { useEffect } from 'react';
import { withAuth } from "react-auth-guard";
import { getData, KEY_USER_TOKEN } from '../../../../utils/preferences';

const NotFound = (props) => {

    const initialRender = () => { 
        if( getData(KEY_USER_TOKEN) != null ) { 
            props.auth.updateToken(getData(KEY_USER_TOKEN))         
        }
    }
    useEffect(initialRender,[]);

    return (
        <>
            <h1>Redirecting...</h1>
        </>
    )
}

export default withAuth(NotFound);
