import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_API_ACCOMPAINED_BY_SUCCESS } from "./action";
import { ACTION_TPA_MEETING_SAVE_SUCCESS } from "./action";
import { ACTION_GET_TPA_NAME_SUCCESS } from "./action";
import { ACTION_TPAMEETINGADDCONTACT_SUCCESS } from './action';
import { ACTION_TPA_GET_CONTACT_SUCCESS } from './action';

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddTpaMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_TPA_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_TPA_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TPA_MEETING_SAVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TPA_MEETING_SAVE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TPAMEETINGADDCONTACT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_TPAMEETINGADDCONTACT_SUCCESS,
            loading: false
        });
    },
    [ACTION_TPA_GET_CONTACT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_TPA_GET_CONTACT_SUCCESS,
            loading: false
        });
    }

});
export default AddTpaMeetingReducer;    