import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ArrayStore from 'devextreme/data/array_store';
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionOpportunitySettingEdit, ACTION_OPPORTUNITY_SETTINGS_EDIT_SUCCESS } from "./action";
import { actionLocationList, ACTION_EDIT_LOCATION_LIST_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../utils";
import { Controller, useForm } from "react-hook-form";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TagBox } from "devextreme-react";
import ErrorLabel from "../../../../../../component/form/error_label";
import Swal from "sweetalert2";

const ProposalSettingsEdit = (props) => {
    const { match } = props;
    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm()
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [isAll, setIsAll] = useState(0);
    const [isAssigned, setIsAssigned] = useState(0);
    const [isUnAssigned, setIsUnassigned] = useState(0);
    const [getLocationsList, GetLocationsList] = useState([]);

    const initialRender = () => {
        // $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionLocationList();
        setValue('department', props.location.state.detail?.department_name);
        setValue('emp_ids', props.location.state.detail?.full_name);
        setValue('branch', props.location.state.detail?.branch_city);
        const ids = props.location.state.detail?.cities.map(item => item.id);
        setValue('locations', ids);
        props.location.state.detail?.access_all == 1 ? setIsAll(1) : setIsAll(0)
        props.location.state.detail?.access_assigned == 1 ? setIsAssigned(1) : setIsAssigned(0)
        props.location.state.detail?.access_unassigned == 1 ? setIsUnassigned(1) : setIsUnassigned(0)


    }
    useEffect(initialRender, []);

    const onGetLocationListUpdate = () => {
        const { GetLocationList } = props;
        var GetLocationListResponse = Object.assign({}, GetLocationList);
        if (GetLocationListResponse.result === true) {
            GetLocationsList(GetLocationListResponse.response)
        } else {
            switch (GetLocationListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(GetLocationListResponse.message);
                default:
            }
        }
    }
    useEffect(onGetLocationListUpdate, [props.GetLocationList]);

    const onOpportunitySettingEditUpdate = () => {
        const { OpportunitySettingEdit } = props;
        var OpportunitySettingEditResponse = Object.assign({}, OpportunitySettingEdit);
        $("#edit_opportunity").prop("disabled", false);
        if (OpportunitySettingEditResponse.result === true) {
            $("#opportunity_edit-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Opportunity Settings Updated Successfully");
            props.history.goBack();
        } else {
            $("#opportunity_edit-form-loader").addClass("hidden");
            switch (OpportunitySettingEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(OpportunitySettingEditResponse.message);
                default:
            }
        }
    }
    useEffect(onOpportunitySettingEditUpdate, [props.OpportunitySettingEdit]);

    const onSubmit = (data) => {
        data.id = props.location.state.detail.id
        data.user_ids = data.emp_ids;
        data.access_all = isAll;
        data.access_assigned = isAssigned;
        data.access_unassigned = isUnAssigned;
        data.city = selectedLocations;

        if (isAll != 1 && isAssigned != 1 && isUnAssigned != 1) {
            Swal.fire({
                icon: 'error',
                text: 'Please Assign Right',
            }).then(({ isConfirmed }) => {

            })
        } else {
            $("#opportunity_edit-form-loader").removeClass("hidden");
            $("#edit_opportunity").prop("disabled", true);
            props.actionOpportunitySettingEdit(data)
        }
    }

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Opportunity Settings Edit</h5>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    {/* <div className="form-body"> */}
                                    <div className="row">
                                        {/* <div className="col-12 col-md-4 col-lg-4"> */}
                                        {/* <div className="form-group"> */}
                                        {/* <div className='col-12 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label className="label-control">
                                                    Department
                                                </label>
                                                <Controller
                                                    name="department"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            // dataSource={insuranceCompanyGroup}
                                                            id="department"
                                                            name="department"
                                                            fields={{ text: 'name', value: 'id' }}
                                                            allowFiltering={true}
                                                            filterType={"Contains"}
                                                            placeholder="Select Department"
                                                            value={field.value}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    // setInsurerCompany(event.itemData.name)
                                                                    field.onChange(event.itemData.id)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                                {errors.department && <ErrorLabel message={errors.department?.message} />}
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Department&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    disabled={true}
                                                    placeholder="Enter Department"
                                                    {...register('department')}
                                                />
                                                {/* {errors.department?.type === 'required' && <ErrorLabel message={errors.department?.message} />} */}
                                            </div>
                                        </div>
                                        {/* <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Employee List&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="emp_ids"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    // data: employeeNames,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            id="emp_ids"
                                                            name="emp_ids"
                                                            deferRendering={true}
                                                            displayExpr="full_name"
                                                            valueExpr="user_id"
                                                            placeholder="Select Employees"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            maxDisplayedTags={2}
                                                            height={"30%"}
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedEmployee}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedEmployee(value);
                                                                    field.onChange(value);
                                                                } else {
                                                                    if (selectedEmployee != null && selectedEmployee.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedEmployee([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedEmployee([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Employee Names'
                                                    }}
                                                />
                                                {errors.emp_ids && <ErrorLabel message={errors.emp_ids?.message} />}
                                            </div>
                                        </div> */}

                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Employee&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="emp_ids"
                                                    name="emp_ids"
                                                    disabled={true}
                                                    placeholder="Enter Employee"
                                                    {...register('emp_ids')}
                                                />
                                                {/* {errors.emp_ids?.type === 'required' && <ErrorLabel message={errors.emp_ids?.message} />} */}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Employee Branch&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="branch"
                                                    name="branch"
                                                    disabled={true}
                                                    placeholder="Enter Branch"
                                                    {...register('branch', { required: 'Branch is required' })}
                                                />
                                                {errors.branch?.type === 'required' && <ErrorLabel message={errors.branch?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mb-0 mr-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="access_all"
                                                        id="access_all"
                                                        defaultChecked={isAll === 1 ? true : false}
                                                        checked={isAll === 1 ? true : false}
                                                        {...register('access_all')}
                                                        onChange={(event) => {
                                                            if (event.target.checked === true) {
                                                                setIsAll(1)
                                                                setIsAssigned(1)
                                                                setIsUnassigned(1)
                                                            } else {
                                                                setIsAll(0)
                                                                setIsAssigned(0)
                                                                setIsUnassigned(0)
                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-control-label" for="access_all">All</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mb-0 mr-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="access_assigned"
                                                        id="access_assigned"
                                                        disabled={isAll === Number(1) ? true : false}
                                                        defaultChecked={isAssigned === 1 ? true : false}
                                                        checked={isAssigned === 1 ? true : false}
                                                        {...register('access_assigned')}
                                                        onChange={(event) => {
                                                            if (event.target.checked === true) {
                                                                setIsAssigned(1)
                                                            } else {
                                                                setIsAssigned(0)
                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-control-label" for="access_assigned">Assigned</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mb-0 mr-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="access_unassigned"
                                                        id="access_unassigned"
                                                        disabled={isAll === Number(1) ? true : false}
                                                        defaultChecked={isUnAssigned === 1 ? true : false}
                                                        checked={isUnAssigned === 1 ? true : false}
                                                        {...register('access_unassigned')}
                                                        onChange={(event) => {
                                                            if (event.target.checked === true) {
                                                                setIsUnassigned(1)
                                                                setIsAssigned(1)
                                                            } else {
                                                                setIsUnassigned(0)
                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-control-label" for="access_unassigned">Un-Assigned</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mt-1">
                                                <label className="label-control">Locations&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="locations"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: getLocationsList,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            id="locations"
                                                            name="locations"
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select Locations"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            maxDisplayedTags={2}
                                                            height={"30%"}
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedLocations}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedLocations(value);
                                                                    field.onChange(value);
                                                                } else {
                                                                    if (selectedLocations != null && selectedLocations.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedLocations([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedLocations([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Locations'
                                                    }}
                                                />
                                                {errors.locations && <ErrorLabel message={errors.locations?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_opportunity"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="opportunity_edit-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    var OpportunitySettingEdit = null;
    var GetLocationList = null;

    if (
        state.OpportunitySettingEditReducer != null &&
        Object.keys(state.OpportunitySettingEditReducer).length > 0 &&
        state.OpportunitySettingEditReducer.constructor === Object
    ) {
        switch (state.OpportunitySettingEditReducer.tag) {
            case ACTION_OPPORTUNITY_SETTINGS_EDIT_SUCCESS:
                OpportunitySettingEdit = Object.assign({}, state.OpportunitySettingEditReducer.data);
                delete state.OpportunitySettingEditReducer;
                return { OpportunitySettingEdit };
            case ACTION_EDIT_LOCATION_LIST_SUCCESS:
                GetLocationList = Object.assign({}, state.OpportunitySettingEditReducer.data);
                delete state.OpportunitySettingEditReducer;
                return { GetLocationList };
            default:
        }
    }
    return {
        OpportunitySettingEdit,
        GetLocationList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionOpportunitySettingEdit,
        actionLocationList
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProposalSettingsEdit))