import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_TRANSPORT_ADD } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const TRANSPORT_ADD_LOADING = 'TRANSPORT_ADD_LOADING';
export const TRANSPORT_ADD_SUCCESS = 'TRANSPORT_ADD_SUCCESS';

export function actionTransportAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_TRANSPORT_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(TRANSPORT_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(TRANSPORT_ADD_LOADING, { loading: false, data }));
        });
}
