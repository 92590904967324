import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_GET_DEPARTMENT, API_MASTER_GET_CATEGORY, API_MASTER_ADD_POLICY_TYPE } from '../../../../../../api/constants';

export const ACTION_MASTER_DEPARTMENT_LOADING = 'ACTION_MASTER_DEPARTMENT_LOADING';
export const ACTION_MASTER_DEPARTMENT_SUCCESS = 'ACTION_MASTER_DEPARTMENT_SUCCESS';

export const ACTION_MASTER_CATEGORY_LOADING = 'ACTION_MASTER_CATEGORY_LOADING';
export const ACTION_MASTER_CATEGORY_SUCCESS = 'ACTION_MASTER_CATEGORY_SUCCESS';

export const ACTION_MASTER_ADD_POLICY_TYPE_LOADING = 'ACTION_MASTER_ADD_POLICY_TYPE_LOADING';
export const ACTION_MASTER_ADD_POLICY_TYPE_SUCCESS = 'ACTION_MASTER_ADD_POLICY_TYPE_SUCCESS';

export function actionMasterGetDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_DEPARTMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionMasterGetCategory() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_GET_CATEGORY).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_CATEGORY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CATEGORY_LOADING, { loading: false, data }));
        });
}

export function actionAddMasterPolicyType(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ADD_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ADD_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ADD_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}