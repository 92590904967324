
import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_CLAIMS_MIS_LIST } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_DOWNLOAD_CLAIMS_MIS_LOADING = 'ACTION_DOWNLOAD_CLAIMS_MIS_LOADING';
export const ACTION_DOWNLOAD_CLAIMS_MIS_SUCCESS = 'ACTION_DOWNLOAD_CLAIMS_MIS_SUCCESS';


export function actionDownloadClaimsMISList(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_MIS_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOWNLOAD_CLAIMS_MIS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOWNLOAD_CLAIMS_MIS_LOADING, { loading: false, data }));
        });
}