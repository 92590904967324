import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiPhone, FiPlus, FiUser } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import NewServeyorAdd from '../../../../../../component/form/new_serveyor';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { bindActionCreators } from 'redux';
import { UNAUTHORIZED, characterLimit, MEETING_FILE_ERROR_MESSAGE, PRIMARY_COLOR, MEETING_FILE_SIZE, ERROR } from '../../../../../../constants';
import ContactDetails from '../../../../../../component/form/contact_details';
import Select from 'react-select';
import Swal from 'sweetalert2';
import $ from "jquery";
import { checkFileExtension, getAccompainedByIds } from '../../../../../../utils';
import { actionAccompainedBy, ACTION_ACCOMPAINED_BY_SUCCESS } from './action';
import { actionGetServeyorName, ACTION_ADD_GET_SERVEYOR_NAME_SUCCESS } from './action';
import { actionServeyorMeetingSave, ACTION_SURVEYOR_MEETING_SAVE_SUCCESS } from './action';
import { actionServeyorMeetingAddContact, ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS } from './action';
import { actionServeyorGetContacts, ACTION_SERVEYOR_GET_CONTACTS_SUCCESS } from './action';

import moment from 'moment';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { toast } from 'react-toastify';

const ServeyorMeetingAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors }, getValues, setValue, watch } = useForm()

    var meetingAgendaWatcher = watch('agenda', '')
    var meetingLocationWatcher = watch('location', '')
    var minutesOfMeetingWatcher = watch('minutes_of_meeting', '')

    const [showNewServeyorAdd, setShowNewServeyorAdd,] = useState(false);
    const [accompainedBy, setAccompainedBy] = useState([]);
    const [serveyorNames, setServeyorNames] = useState([]);
    const [companyContacts, setCompanyContacts] = useState([])
    const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([])
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [companyId, setCompanyId] = useState(0)
    const [clientContactIds, setClientContactIds] = useState(0)
    const [uploadDocument, setUploadDocument] = useState(null);
    const [status, setStatus] = useState('close');
    const [existingDate, setExistingDate] = useState(false);

    const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

    const initialRender = () => {
        props.actionAccompainedBy({});
        props.actionGetServeyorName({ clientOf: "surveyor" })
    }
    useEffect(initialRender, []);

    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    const onServeyorNameUpdate = () => {
        const { ServeyorName } = props;
        var ServeyorNameResponse = Object.assign({}, ServeyorName);
        if (ServeyorNameResponse.result === true) {
            setServeyorNames(ServeyorNameResponse.response);
        } else {
            switch (ServeyorNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onServeyorNameUpdate, [props.ServeyorName]);

    useEffect(() => {
        if (serveyorNames != null && serveyorNames.length > 0) {
            if (companyId > 0) {
                setValue('client_id', companyId)
            }
        }
    }, [serveyorNames])

    const onServeyorMeetingSaveUpdate = () => {
        const { ServeyorMeetingSave } = props;
        var ServeyorMeetingResponse = Object.assign({}, ServeyorMeetingSave);
        $("#add_surveyor").prop("disabled", false);
        if (ServeyorMeetingResponse.result === true) {
            // $("#progress-loader").addClass("hidden");
            $("#add_surveyor-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Surveyor Meeting Saved Successfully");
            props.history.goBack();
        } else {
            // $("#progress-loader").addClass("hidden");
            $("#add_surveyor-loader").addClass("hidden");
            switch (ServeyorMeetingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(ServeyorMeetingResponse.message);
                default:
            }
        }
    }
    useEffect(onServeyorMeetingSaveUpdate, [props.ServeyorMeetingSave]);

    const onServeyorMeetingAddContactUpdate = () => {

        const { ServeyorMeetingAddContact } = props;

        if (ServeyorMeetingAddContact != null) {
            var ServeyorMeetingAddContactResponse = Object.assign({}, ServeyorMeetingAddContact);
            if (ServeyorMeetingAddContactResponse.result === true) {
                var newContact = Object.assign({}, ServeyorMeetingAddContactResponse.response)
                var arrContacts = Object.assign([], companyContacts)
                arrContacts.push(newContact)
                var arrSelectedCompanyContacts = Object.assign([], selectedCompanyContacts)
                arrSelectedCompanyContacts.push(newContact.id)
                setCompanyContacts(arrContacts)
                setSelectedCompanyContacts(arrSelectedCompanyContacts)
            } else {
                switch (ServeyorMeetingAddContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        toast.error(ServeyorMeetingAddContactResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onServeyorMeetingAddContactUpdate, [props.ServeyorMeetingAddContact]);

    const onServeyorGetContactUpdate = () => {
        const { ServeyorContacts } = props
        var ServeyorContactsResponse = Object.assign({}, ServeyorContacts)
        if (ServeyorContactsResponse.result === true) {
            setCompanyContacts(ServeyorContactsResponse.response)
        } else {
            switch (ServeyorContactsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onServeyorGetContactUpdate, [props.ServeyorContacts])

    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (selectedCompanyContacts.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Please select at least one Contact',
                icon: 'error'
            }).then(success => {

            })
        } else {
            if (fromTimeDate >= toTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: 'From Time cannot be greater than or equal to To Time',
                    icon: 'error'
                }).then(success => {

                })
            } else {
                data.created_by = props.auth.getUser().user_id;
                data.meeting_type = 'surveyor';
                data.start_date_time = data.start_date + ' ' + data.from_time;
                data.end_date_time = data.start_date + ' ' + data.to_time;
                data.client_contact_ids = JSON.stringify(selectedCompanyContacts);
                data.accompained_by = JSON.stringify(getAccompainedByIds(data.accompained_by));
                if (existingDate === true) {
                    data.status = status
                }

                var files = [];
                if (uploadDocument != null) {
                    files.push({ 'name': 'upload_document', 'value': uploadDocument });
                }

                delete data.from_time;
                delete data.to_time;
                delete data.start_date;
                delete data.upload_document;
                $("#add_surveyor-loader").removeClass("hidden");
                $("#add_surveyor").prop("disabled", true);

                // $("#progress-loader").removeClass("hidden");
                props.actionServeyorMeetingSave(data, files);
            }
        }
    }
    return (
        <>
            <ScreenTitle title="Add Surveyor Meeting" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Surveyor Meeting Information
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-9 col-lg-9">
                                        <div className="form-group" >
                                            <label className="label-control  "> Surveyor Name</label>
                                            <span className="danger">*</span>
                                            <Controller
                                                name="client_id"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={serveyorNames}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={false}
                                                        placeholder="Select Surveyor Name"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.id)
                                                                props.actionServeyorGetContacts({ client_id: event.itemData.id })
                                                                setCompanyId(event.itemData.id)
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Surveyor Name is required'
                                                }}
                                            />
                                            {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label></label>
                                            <button
                                                type="button"
                                                className="btn btn-block btn-primary shadow round text-uppercase font-small-2"
                                                onClick={(event) => {
                                                    setShowNewServeyorAdd(true);
                                                }}
                                            > Add New Surveyor</button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="start_date"
                                                className="form-control"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        stylingMode="underlined"
                                                        className="form-control"
                                                        pickerType="calendar"
                                                        type="date"
                                                        min={moment(new Date()).subtract(60, 'days').calendar()}
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={true}
                                                        acceptCustomValue={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                            if (moment(value).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                                                                setExistingDate(true);
                                                            } else {
                                                                setExistingDate(false);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Start Date is required'
                                                }}
                                            />
                                            {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">From Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                defaultValue={moment().format('HH:mm')}
                                                id="from_time"
                                                name="from_time"
                                                placeholder="--:-- --"
                                                {...register('from_time', { required: 'From Time is required' })}
                                            />
                                            {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">To Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                defaultValue={moment().add(1, 'hours').format('HH:mm')}
                                                id="to_time"
                                                name="to_time"
                                                placeholder="--:-- --"
                                                {...register('to_time', { required: 'To Time is required' })}
                                            />
                                            {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                        </div>
                                    </div>


                                    {
                                        companyId > 0 ?
                                            [<div className="col-12 col-md-12 col-lg-12" key="contact_details">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setShowContactDetails(true)
                                                        }}
                                                    >
                                                        <FiPlus
                                                            cursor="pointer"
                                                            size={20}
                                                        />
                                                    </button>
                                                </h4>
                                            </div>,
                                            <div className="col-12 col-md-12 col-lg-12 mb-1" key="contact_details_list">
                                                {
                                                    companyContacts != null && companyContacts.length > 0 ?
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Contact Name</th>
                                                                    <th>Contact Designation</th>
                                                                    <th>Contact Phone</th>
                                                                    <th>Contact Email</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    companyContacts.map((contact, indexContact) => (
                                                                        <tr key={contact.id}>
                                                                            <th>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`${contact.id}`}
                                                                                    name=""
                                                                                    defaultChecked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                    checked={selectedCompanyContacts.includes(contact.id) ? true : false}
                                                                                    onChange={(event) => {
                                                                                        var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                                                                                        if (!arrSelectedCompanyContact.includes(contact.id)) {
                                                                                            arrSelectedCompanyContact.push(contact.id)
                                                                                            setSelectedCompanyContacts(arrSelectedCompanyContact)
                                                                                        } else {
                                                                                            var contactIndex = arrSelectedCompanyContact.findIndex((objContact, index) => objContact == contact.id);
                                                                                            if (contactIndex >= 0) {
                                                                                                arrSelectedCompanyContact.splice(contactIndex, 1);
                                                                                                setSelectedCompanyContacts(arrSelectedCompanyContact);
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </th>
                                                                            <td>{contact.contact_name}</td>
                                                                            <td>{contact.contact_designation}</td>
                                                                            <td>{contact.contact_phone}</td>
                                                                            <td>{contact.contact_email}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <></>
                                                }
                                                <ErrorLabel message={companyId > 0 && companyContacts.length === 0 ? 'No contacts found' : ''} />
                                            </div>
                                            ]
                                            :
                                            <></>
                                    }


                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                id="agenda"
                                                name="agenda"
                                                placeholder="Enter Meeting Agenda"
                                                rows={5}
                                                maxLength={characterLimit}
                                                {...register('agenda', { required: 'Meeting Agenda is required' })}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                <div className="flex-column"><small>{meetingAgendaWatcher != null ? meetingAgendaWatcher.length : 0}/{characterLimit}</small></div>
                                            </div>
                                            {errors.agenda && <ErrorLabel message={errors.agenda?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group" >
                                            <label className="label-control ">Meeting Location</label>
                                            <textarea
                                                className="form-control"
                                                id="location"
                                                name="location"
                                                placeholder="Enter Meeting Location"
                                                rows={5}
                                                maxLength={characterLimit}
                                                {...register('location')}
                                            />
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                <div className="flex-column"><small>{meetingLocationWatcher != null ? meetingLocationWatcher.length : 0}/{characterLimit}</small></div>
                                            </div>
                                            {errors.location && <ErrorLabel message={errors.location?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label-control">Accompanied By</label>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="accompained_by"
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            placeholder="Select"
                                                            menuPlacement={"top"}
                                                            isMulti={true}
                                                            hideSelectedOptions={false}
                                                            maxMenuHeight={200}
                                                            name="name"
                                                            options={accompainedBy}
                                                            value={field.value}
                                                            onChange={(selectedOptions) => {
                                                                field.onChange(selectedOptions);
                                                            }}
                                                        />
                                                    )
                                                }}
                                            />
                                            {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label-control ">Meeting Description</label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                rows={5}
                                                maxLength={characterLimit}
                                                placeholder="Enter Meeting Description"
                                                {...register('description')}
                                            />
                                            {errors.description && <ErrorLabel message={errors.description?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <fieldset class="form-group">
                                                <label for="basicInputFile">Upload Document</label>
                                                <div class="custom-file">
                                                    <input type="file"
                                                        class="custom-file-input"
                                                        id="upload_document"
                                                        name="upload_document"
                                                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx"
                                                        onChange={(event) => {
                                                            if (
                                                                event.target.files
                                                            ) {

                                                                var fileSize = event.target.files[0].size;

                                                                var ext = (event.target.files[0].name).split('.').pop();

                                                                if (checkFileExtension(ext, validExt)) {
                                                                    if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                        setUploadDocument(event.target.files[0]);
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Error',
                                                                            icon: 'error',
                                                                            html: MEETING_FILE_ERROR_MESSAGE,
                                                                            confirmButtonColor: PRIMARY_COLOR
                                                                        }).then((result) => {

                                                                        });
                                                                    }
                                                                } else {
                                                                    Swal.fire({
                                                                        title: 'Upload Error',
                                                                        text: 'You can only upload jpg, jpeg, png, pdf, doc, docx, xlsx, xls, ppt & pptx files',
                                                                        icon: 'error'
                                                                    }).then(success => {

                                                                    })
                                                                }

                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-file-label" for="upload_document">
                                                        {
                                                            uploadDocument != null ?
                                                                uploadDocument.name : "Choose file"
                                                        }
                                                    </label>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    {
                                        existingDate === true ?
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={
                                                                    [

                                                                        {
                                                                            name: 'Close',
                                                                            id: 'close'
                                                                        }
                                                                    ]
                                                                }
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select status"
                                                                value={status}
                                                                enabled={false}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {

                                                                        field.onChange(event.itemData.id);
                                                                        setStatus(event.itemData.id);

                                                                        if (event.itemData.id !== 'close') {
                                                                            setValue('minutes_of_meeting', '');
                                                                            setValue('remark', '');
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                    {
                                        existingDate === true && status === 'close' ?
                                            [<div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Minutes Of Meeting&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="minutes_of_meeting"
                                                        name="minutes_of_meeting"
                                                        placeholder="Enter Minutes Of Meeting"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        {...register('minutes_of_meeting', { required: 'Minutes of meeting is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{minutesOfMeetingWatcher != null ? minutesOfMeetingWatcher.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.minutes_of_meeting && <ErrorLabel message={errors.minutes_of_meeting?.message} />}
                                                </div>
                                            </div>,
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control ">Remarks</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        placeholder="Enter Remarks"
                                                        rows={5}
                                                        {...register('remark')}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>]
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none">
                                <button
                                    id="add_surveyor"
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        &nbsp;Submit
                                        <span id="add_surveyor-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div> </button>
                                <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.goBack();
                                    }}
                                > Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
                <NewServeyorAdd
                    show={showNewServeyorAdd}
                    handleClose={(serveyor) => {
                        if (serveyor != null) {
                            setCompanyId(serveyor.id)
                            props.actionGetServeyorName({ clientOf: "surveyor" })
                        }
                        setShowNewServeyorAdd(false)
                    }}
                />
            </section>
            <ContactDetails
                show={showContactDetails}
                handleClose={(newContact) => {
                    if (newContact != null) {
                        setClientContactIds(newContact.id)
                        props.actionServeyorMeetingAddContact({
                            client_id: getValues("client_id"),
                            client_type: "client",
                            contact_designation: newContact.contact_designation,
                            contact_email: newContact.contact_email,
                            contact_name: newContact.contact_name,
                            contact_phone: newContact.contact_phone,
                        })
                    }
                    setShowContactDetails(false)
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {

    var AccompainedBy = null;
    var ServeyorName = null;
    var ServeyorMeetingSave = null;
    var ServeyorMeetingAddContact = null;
    var ServeyorContacts = null;

    if (
        state.AddServeyorMeetingReducer != null &&
        Object.keys(state.AddServeyorMeetingReducer).length > 0 &&
        state.AddServeyorMeetingReducer.constructor === Object
    ) {
        switch (state.AddServeyorMeetingReducer.tag) {
            case ACTION_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.AddServeyorMeetingReducer.data);
                delete state.AddServeyorMeetingReducer;
                return { AccompainedBy };
            case ACTION_ADD_GET_SERVEYOR_NAME_SUCCESS:
                ServeyorName = Object.assign({}, state.AddServeyorMeetingReducer.data);
                delete state.AddServeyorMeetingReducer;
                return { ServeyorName };
            case ACTION_SURVEYOR_MEETING_SAVE_SUCCESS:
                ServeyorMeetingSave = Object.assign({}, state.AddServeyorMeetingReducer.data);
                delete state.AddServeyorMeetingReducer;
                return { ServeyorMeetingSave };
            case ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS:
                ServeyorMeetingAddContact = Object.assign({}, state.AddServeyorMeetingReducer.data);
                delete state.AddServeyorMeetingReducer;
                return { ServeyorMeetingAddContact };
            case ACTION_SERVEYOR_GET_CONTACTS_SUCCESS:
                ServeyorContacts = Object.assign({}, state.AddServeyorMeetingReducer.data);
                delete state.AddServeyorMeetingReducer;
                return { ServeyorContacts };

            default:
        }
    }
    return {
        AccompainedBy,
        ServeyorName,
        ServeyorMeetingSave,
        ServeyorMeetingAddContact,
        ServeyorContacts,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAccompainedBy, actionGetServeyorName, actionServeyorMeetingSave, actionServeyorMeetingAddContact, actionServeyorGetContacts }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ServeyorMeetingAdd))