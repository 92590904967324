import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FaCircle, FaRegHandshake, FaShieldAlt } from 'react-icons/fa';
import { FiArrowLeft, FiEdit, FiGlobe, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import { BsBuilding } from 'react-icons/bs';
import { BiUserVoice } from 'react-icons/bi';
import { MdLocalActivity } from 'react-icons/md'
import { HiOutlineInformationCircle, HiOutlineUserGroup } from 'react-icons/hi';
import { GoLocation } from 'react-icons/go';
import { IoDocumentTextOutline, IoDocumentAttachOutline } from 'react-icons/io5';
import { actionGetGroupProfileDetails, ACTION_GROUP_PROFILE_DETAIL_SUCCESS } from "./action";
import $ from "jquery";
import { UNAUTHORIZED } from '../../../../../constants';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import GroupBusinessSummary from './tabs/business_summary';
import GroupLevelProposalsView from './tabs/opportunities';
import GroupMeetingsView from './tabs/meetings';
import GroupPolicyView from './tabs/policies';
import GroupClaims from './tabs/claims';
import GroupLevelCompaniesView from './tabs/companies';
import GroupActivitiesView from './tabs/activities';
import GroupPincRMView from './tabs/pinc_rm';
import GroupContacts from './tabs/contacts'
import { BiPhoneCall } from "react-icons/bi";
import { verifyPermission } from '../../../../../utils';

const GroupLevelClientView = (props) => {

    const [groupProfileDetails, setGroupProfileDetails] = useState();
    const [selectedTab, setSelectedTab] = useState(0);

    const initialRender = () => {
        if (props.location.state !== undefined || props.location.state != null) {
            $("#progress-loader").removeClass("hidden");
            props.actionGetGroupProfileDetails({ client_group_id: props.location.state.client_group_id });
        } else {
            props.history.goBack();
        }
    }
    useEffect(initialRender, [props.location.state]);

    useEffect(() => {
        if (props?.history?.location?.state?.client_detail) {
            $("#progress-loader").removeClass("hidden");
            props.actionGetGroupProfileDetails({ client_group_id: props?.history?.location?.state?.client_group_id });
            setSelectedTab(props?.location?.state?.tab)
        }

    }, [props?.history?.location?.state])

    const onGroupProfileDetailsUpdate = () => {
        const { GroupProfileDetails } = props;
        if (GroupProfileDetails != null) {
            var GroupProfileDetailsResponse = Object.assign({}, GroupProfileDetails);
            if (GroupProfileDetailsResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setGroupProfileDetails(GroupProfileDetailsResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (GroupProfileDetailsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGroupProfileDetailsUpdate, [props.GroupProfileDetails]);

    const renderTab = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <GroupBusinessSummary
                        {...props}
                        clientGroupId={props?.location?.state?.client_group_id}
                        tab={selectedTab}
                    />

                )
            case 2:
                return (
                    <GroupLevelProposalsView
                        {...props}
                        clientGroupId={props?.location?.state?.client_group_id}
                        tab={selectedTab}
                    />
                )
            case 3:
                return (
                    <GroupMeetingsView
                        {...props}
                        tab={selectedTab}
                        clientGroupId={props?.location?.state?.client_group_id}
                    />
                )
            case 4:
                return (
                    <GroupPolicyView
                        {...props}
                        tab={selectedTab}
                        clientGroupId={props?.location?.state?.client_group_id}
                    />
                )
            case 5:
                return (
                    <GroupClaims
                        {...props}
                        tab={selectedTab}
                        clientGroupId={props?.location?.state?.client_group_id}
                    />
                )
            case 6:
                return (
                    <GroupLevelCompaniesView
                        {...props}
                        tab={selectedTab}
                        clientGroupId={props?.location?.state?.client_group_id}
                    />
                )
            case 7:
                return (
                    <GroupActivitiesView
                        {...props}
                        tab={selectedTab}
                        clientGroupId={props?.location?.state?.client_group_id}
                    />
                )
            case 8:
                return (
                    <GroupPincRMView
                        {...props}
                        tab={selectedTab}
                        clientGroupId={props?.location?.state?.client_group_id}
                    />
                )
            case 9:
                return (
                    <GroupContacts
                        {...props}
                        tab={selectedTab}
                        clientGroupId={props?.location?.state?.client_group_id}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }
    useEffect(renderTab, [selectedTab])

    const renderTabTitle = () => {
        return (
            <>
                <ul className="nav nav-tabs nav-underline border-bottom" role="tablist" style={{ backgroundColor: 'transparent' }}>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center ${selectedTab === 0 ? 'active' : ''}`}
                            onClick={(event) => {
                                setSelectedTab(0)
                            }}
                        >
                            <HiOutlineInformationCircle size={16} />
                            <span className="d-none d-sm-block">&nbsp;Business Summary</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center ${selectedTab === 2 ? 'active' : ''}`}
                            onClick={(event) => {
                                setSelectedTab(2)
                            }}
                        >
                            <IoDocumentTextOutline size={16} />
                            <span className="d-none d-sm-block">&nbsp;Opportunities</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center  ${selectedTab === 3 ? 'active' : ''}`}
                            onClick={(event) => {
                                setSelectedTab(3)
                            }}
                        >
                            <FaRegHandshake size={16} />
                            <span className="d-none d-sm-block">&nbsp;Meetings</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center  ${selectedTab === 4 ? 'active' : ''}`}

                            onClick={(event) => {
                                setSelectedTab(4)
                            }}
                        >
                            <FaShieldAlt size={16} />
                            <span className="d-none d-sm-block">&nbsp;Policies</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center ${selectedTab === 5 ? 'active' : ''}`}

                            onClick={(event) => {
                                setSelectedTab(5)
                            }}
                        >
                            <IoDocumentAttachOutline size={18} />
                            <span className="d-none d-sm-block">&nbsp;Claims</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center ${selectedTab === 6 ? 'active' : ''}`}
                            onClick={(event) => {
                                setSelectedTab(6)
                            }}
                        >
                            <BsBuilding size={18} />
                            <span className="d-none d-sm-block">&nbsp;Companies</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center ${selectedTab === 7 ? 'active' : ''}`}
                            onClick={(event) => {
                                setSelectedTab(7)
                            }}
                        >

                            <MdLocalActivity size={18} />
                            <span className="d-none d-sm-block">&nbsp;Activities</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center ${selectedTab === 8 ? 'active' : ''}`}
                            onClick={(event) => {
                                setSelectedTab(8)
                            }}
                        >
                            <HiOutlineUserGroup size={16} />
                            <span className="d-none d-sm-block">&nbsp;PINC RM</span>
                        </a>
                    </li>
                    <li className={`nav-item`}>
                        <a className={`nav-link client-tab d-flex align-items-center justify-content-center ${selectedTab === 9 ? 'active' : ''}`}
                            onClick={(event) => {
                                setSelectedTab(9)
                            }}
                        >
                            <BiPhoneCall size={16} />
                            <span className="d-none d-sm-block">&nbsp;Customer Contacts</span>
                        </a>
                    </li>
                </ul>
            </>
        )
    }

    const renderGroupProfileDetails = () => {
        return (
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className="col-12 col-md-12 col-lg-4">
                            <div className="d-flex ">
                                <div className='col-4 col-md-2'>
                                    <div className="group-level-main-div d-flex justify-content-center align-items-center rounded-circle bg-blue-grey bg-lighten-4 my-auto">
                                        <BsBuilding className="blue-grey bg-lighten-2 _icon" />
                                    </div>
                                </div>
                                <div className='col-8 col-md-10 my-auto '>
                                    <h4 className="group-level-company-name text-bold-500 ml-1">{(groupProfileDetails != null && groupProfileDetails.group_name != null) ? groupProfileDetails.group_name : '-'}</h4>
                                    <div className='d-flex'>
                                        <div className={`justify-content-center mt-half mx-1 ${(groupProfileDetails != null && groupProfileDetails.category != null) ? '' : 'hidden'}`}>
                                            <button className="btn btn-sm btn-success font-small-2 text-bold-600 round no-hover" >{(groupProfileDetails != null && groupProfileDetails.category != null) ? _.capitalize(groupProfileDetails.category) : '-'}</button>
                                        </div>
                                        <div className={`justify-content-center mt-half ml-1 ${groupProfileDetails != null && groupProfileDetails?.client_type != 'Lead' ? '' : 'hidden'}`}>
                                            {groupProfileDetails != null && groupProfileDetails?.is_active === 'yes' ? <button className={`btn btn-sm btn-success font-small-6 text-bold-300 round no-hover`}>Active</button> : <button className={`btn btn-sm btn-danger font-small-6 text-bold-300 round no-hover`}>Inactive</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4  mt-3 mt-md-3 mt-lg-0'>
                            <div className="d-flex align-items-center ">
                                <div className="">
                                    <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                                        <a
                                            className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                            href={`${groupProfileDetails != null && groupProfileDetails.contact_phone != null ? `tel:` + groupProfileDetails.contact_phone : '#'}`}
                                        >
                                            <FiPhone size={12} className="my-auto" />
                                        </a>
                                    </p>
                                </div>
                                <div className="ml-half">
                                    <a
                                        className="text-template"
                                        href={`${groupProfileDetails != null && groupProfileDetails.contact_phone != null ? `tel:` + groupProfileDetails.contact_phone : '#'}`}
                                    >
                                        <p className="font-small-3 text-bold-600 mb-half">{(groupProfileDetails != null && groupProfileDetails.contact_phone != null) ? groupProfileDetails.contact_phone : '-'}</p>
                                    </a>

                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-half">
                                <div>
                                    <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                                        <a
                                            className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                            href={`${groupProfileDetails != null && groupProfileDetails.contact_email != null ? `mailto:` + groupProfileDetails.contact_email : '#'}`}
                                        >
                                            <FiMail size={12} className="my-auto" />
                                        </a>
                                    </p>
                                </div>
                                <div className="ml-half">
                                    <a
                                        className="text-template"
                                        href={`${groupProfileDetails != null && groupProfileDetails.contact_email != null ? `mailto:` + groupProfileDetails.contact_email : '#'}`}
                                    >
                                        <p className="font-small-3 text-bold-600 mb-half">{(groupProfileDetails != null && groupProfileDetails.contact_email != null) ? groupProfileDetails.contact_email : '-'}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4  mt-0 mt-md-3 mt-lg-0'>
                            <div className="d-flex align-items-center">
                                <div>
                                    <a
                                        className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${groupProfileDetails != null && groupProfileDetails.address != null ? `https://maps.google.com/maps?q=` + groupProfileDetails.address : '#'}`}
                                    >
                                        <GoLocation size={12} className="my-auto" />
                                    </a>
                                </div>
                                <div className="ml-half">
                                    <a
                                        className="text-template"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${groupProfileDetails != null && groupProfileDetails.address != null ? `https://maps.google.com/maps?q=` + groupProfileDetails.address : '#'}`}
                                    >
                                        <p className="font-small-3 text-bold-600 mb-half">{(groupProfileDetails != null && groupProfileDetails.address != null) ? groupProfileDetails.address : '-'}</p>
                                    </a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-half">
                                <div>
                                    <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                                        <a
                                            className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() => {
                                                if (groupProfileDetails != null && groupProfileDetails.website != null) {
                                                    if (groupProfileDetails.website.includes("http")) {
                                                        window.open(groupProfileDetails.website, "_blank");
                                                    } else {
                                                        window.open("http://" + groupProfileDetails.website, "_blank");
                                                    }

                                                }
                                            }}
                                        >
                                            <FiGlobe size={12} className="my-auto" />
                                        </a>
                                    </p>
                                </div>
                                <div className="ml-half">
                                    <a
                                        className="text-template"
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            if (groupProfileDetails != null && groupProfileDetails.website != null) {
                                                if (groupProfileDetails.website.includes("http")) {
                                                    window.open(groupProfileDetails.website, "_blank");
                                                } else {
                                                    window.open("http://" + groupProfileDetails.website, "_blank");
                                                }

                                            }
                                        }}
                                    >
                                        <p className="font-small-3 text-bold-600 mb-half">{(groupProfileDetails != null && groupProfileDetails.website != null) ? groupProfileDetails.website : '-'}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <section>
                <div className="card">
                    <div className="card-body pb-0">
                        <div className="row">
                            <div className="col-12 mt-2 mt-md-0 mt-lg-0 px-0 ">
                                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                    <span
                                        className="primary cursor-pointer"
                                        onClick={() => {
                                            if (props?.history?.location?.state?.type === 'Group') {
                                                props.history.push({
                                                    pathname: '/dashboard'
                                                })
                                            } else {
                                                props.history.goBack();
                                            }
                                        }}>
                                        <FiArrowLeft size={16} /> Back
                                    </span>
                                    {/* {
                                        verifyPermission("UPDATE_CLIENT") ?
                                            <FiEdit
                                                size={16}
                                                className="ml-2"
                                                cursor="pointer"
                                                onClick={() => {
                                                   
                                                }}
                                            />
                                            :
                                            <></>
                                    } */}
                                </div>
                                {renderGroupProfileDetails()}
                                <div className='mt-2 border-top'>

                                    {renderTabTitle()}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className=''>

                        <div className="tab-content">
                            {renderTab()}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var GroupProfileDetails = null;


    if (
        state.GroupProfileViewReducer != null &&
        Object.keys(state.GroupProfileViewReducer).length > 0 &&
        state.GroupProfileViewReducer.constructor === Object
    ) {
        switch (state.GroupProfileViewReducer.tag) {
            case ACTION_GROUP_PROFILE_DETAIL_SUCCESS:
                GroupProfileDetails = Object.assign({}, state.GroupProfileViewReducer.data);
                delete state.GroupProfileViewReducer;
                return { GroupProfileDetails };
            default:

        }
    }
    return {
        GroupProfileDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionGetGroupProfileDetails
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupLevelClientView))