import { ACTION_MOBILE_CLAIM_SUCCESS,ACTION_MOBILE_CLAIM_EDIT_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const MobileClaimReducer = createReducer(INITIAL_STATE, {
    [ACTION_MOBILE_CLAIM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MOBILE_CLAIM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MOBILE_CLAIM_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MOBILE_CLAIM_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
});

export default MobileClaimReducer;