import { ACTION_MASTER_BRANCH_STATES_SUCCESS, ACTION_MASTER_BRANCH_CITIES_SUCCESS, ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS } from "./action";

import createReducer from "../../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const MasterAddInsurerBranchReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_BRANCH_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_BRANCH_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_BRANCH_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_BRANCH_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS,
                loading: false,
            }
        );
    }
});
export default MasterAddInsurerBranchReducer;