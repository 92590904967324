import { ACTION_SETTINGS_EDIT_SUCCESS, ACTION_SETTINGS_VIEW_SUCCESS } from "./action";


import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditAppSettingsReducer = createReducer(INITIAL_STATE, {
    [ACTION_SETTINGS_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SETTINGS_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SETTINGS_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SETTINGS_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
})
export default EditAppSettingsReducer