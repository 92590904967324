import Api from "../../../api";
import { API_GET_OPPORTUNITY_QUESTION } from "../../../api/constants";
import { fetchSuccess, loading } from "../../../utils/action.util";

export const ACTION_VIEW_OPPORTUNITY_QUESTION_LOADING = 'ACTION_VIEW_OPPORTUNITY_QUESTION_LOADING';
export const ACTION_VIEW_OPPORTUNITY_QUESTION_SUCCESS = 'ACTION_VIEW_OPPORTUNITY_QUESTION_SUCCESS';

export function actionViewOpportunityQuestion(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_OPPORTUNITY_QUESTION,params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_VIEW_OPPORTUNITY_QUESTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_VIEW_OPPORTUNITY_QUESTION_LOADING, { loading: false, data }));
        });
}