
import React from 'react';
import { Route, Switch } from 'react-router';
import Add from './add';
import Edit from './edit';
import Transport from './list';

const TransportRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}/add`}  component={Add}/>
                <Route exact path={`${match.url}/edit`}  component={Edit}/>
                <Route exact path={`${match.url}`}  component={Transport}/>
            </Switch>
        </>
    )
}

export default TransportRoute