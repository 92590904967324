import createReducer from "../../../reducers/createReducer";
import { ACTION_MASTER_ASSIGN_PROPOSAL_SUCCESS, ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_SUCCESS, ACTION_GET_ASSIGNED_SEARCH_LIST_SUCCESS } from "./action";
const INITIAL_STATE = {
    data: [],
    loading: false,
};
const EditAssignproposalReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_ASSIGN_PROPOSAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_PROPOSAL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_ASSIGN_PROPOSAL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_ASSIGNED_SEARCH_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_ASSIGNED_SEARCH_LIST_SUCCESS,
                loading: false,
            }
        );
    }
}
);
export default EditAssignproposalReducer;