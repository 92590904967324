import React, { useEffect, useState } from "react";
import { UNAUTHORIZED } from "../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { actionGetPendingApproval, ACTION_GET_PENDING_APPROVAL_SUCCESS } from './action';

const PendingApproval = (props) => {
    const [getPendingApproval, setGetPendingApproval] = useState([]);

    const initialRender = () => {
        props.actionGetPendingApproval({});
    }
    useEffect(initialRender, []);

    const onGetPendingApprovalUpdate = () => {
        const { GetPendingApproval } = props;
        const GetPendingApprovalResponse = Object.assign({}, GetPendingApproval)
        if (GetPendingApprovalResponse.result === true) {
            setGetPendingApproval(GetPendingApprovalResponse.response)
        } else {
            switch (GetPendingApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onGetPendingApprovalUpdate, [props.GetPendingApproval]);

    const renderUI = () => {
        try {
            return (
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round dashboard-other-summary shadow">
                        <div className="card-header border-bottom-primary px-1 py-half">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-small-4 text-black text-bold-500 mb-0">
                                        Pending Requests
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <button type="button" className="btn font-small-3">&nbsp;</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-1 py-1">
                            <p className="font-small-2">
                                 Your raised requests, which are pending desired approvals.                             
                            </p>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: '/hrms/request',
                                            state: {status: ['Pending']}
                                        });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Leave
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getPendingApproval != null ?
                                                getPendingApproval.leaves : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/hrms/regularize', state: {status: ['Pending']} });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Regularise
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getPendingApproval != null ?
                                                getPendingApproval.regularizes : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/hrms/asset-request' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Assets
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getPendingApproval != null ?
                                                getPendingApproval.assets : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/hrms/travel-request' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Travel
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getPendingApproval != null ?
                                                getPendingApproval.travel : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/hrms/reimbursement_request' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Reimbursement
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getPendingApproval != null ?
                                                getPendingApproval.reimbursement : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } catch (e) {
            return (
                <>
                    <h1>Error Pending Approval</h1>
                </>
            )
        }
    }

    return (
        renderUI()
    );
}
const mapStateToProps = (state) => {

    var GetPendingApproval = null;
    if (
        state.DashboardPendingApprovalReducer != null &&
        Object.keys(state.DashboardPendingApprovalReducer).length > 0 &&
        state.DashboardPendingApprovalReducer.constructor === Object
    ) {
        switch (state.DashboardPendingApprovalReducer.tag) {
            case ACTION_GET_PENDING_APPROVAL_SUCCESS:
                GetPendingApproval = Object.assign({}, state.DashboardPendingApprovalReducer.data);
                delete state.DashboardPendingApprovalReducer;
                return { GetPendingApproval };
            default:
        }
    }
    return {
        GetPendingApproval,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionGetPendingApproval,
        },
        dispatch
    );
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PendingApproval))