import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { PRINT } from '../../../../../../utils';
import { API_MY_CLIENT } from '../../../../../../api/constants';
import { API_MY_TEAM_CLIENT, API_MYTEAMVERTICAL } from '../../../../../../api/constants';

export const ACTION_MY_CLIENT_LOADING = 'ACTION_MY_CLIENT_LOADING';
export const ACTION_MY_CLIENT_SUCCESS = 'ACTION_MY_CLIENT_SUCCESS';

export const ACTION_MY_TEAM_CLIENT_LOADING = 'ACTION_MY_TEAM_CLIENT_LOADING';
export const ACTION_MY_TEAM_CLIENT_SUCCESS = 'ACTION_MY_TEAM_CLIENT_SUCCESS';

export const ACTION_PROFILE_CLIENT_TEAM_VERTICAL_LOADING = 'ACTION_PROFILE_CLIENT_TEAM_VERTICAL_LOADING';
export const ACTION_PROFILE_CLIENT_TEAM_VERTICAL_SUCCESS = 'ACTION_PROFILE_CLIENT_TEAM_VERTICAL_SUCCESS';

export function actionMyClient(params) {
    return (dispatch, getState) =>
        Api.post(API_MY_CLIENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MY_CLIENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MY_CLIENT_LOADING, { loading: false, data }));
        });
}

export function actionMyTeamClient(params) {
    return (dispatch, getState) =>
        Api.post(API_MY_TEAM_CLIENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MY_TEAM_CLIENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MY_TEAM_CLIENT_LOADING, { loading: false, data }));
        });
}

export function actionProfileClientTeamMember(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROFILE_CLIENT_TEAM_VERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROFILE_CLIENT_TEAM_VERTICAL_LOADING, { loading: false, data }));
        });
}