import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";

import { FiEye } from 'react-icons/fi';

import $ from "jquery";
import { UNAUTHORIZED } from "../../../../../../../constants";
import { actionInsurerViewTabMeetings, ACTION_INSURER_VIEW_TAB_MEETINGS_SUCCESS } from "./action";
import InsuranceCompanyMeetingView from '../../../../../../../component/popups/meetings/insurance_company';
import Swal from "sweetalert2";

const TabMeetings = (props) => { 

    const [meetings, setMeetings] = useState();
    const [showInsuranceMeetingView, setShowInsuranceMeetingView] = useState(false);
    const [insuranceMeetingId, setInsuranceMeetingId] = useState(null);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "created_by_name", caption: "Created By" },
        { dataField: "insurer_company_name", caption: "Company Name" },
        { dataField: "meeting_date", caption: "Meeting Date" },
        { dataField: "agenda", caption: "Agenda" },
        { dataField: "location", caption: "Location" },
        { dataField: "status", caption: "Status" },
    ]);

    const initialRender = () => { 
            $("#progress-loader").removeClass("hidden");
            props.actionInsurerViewTabMeetings({
                insurer_id: props.insurerId
            })
    }
    useEffect(initialRender,[props.tab]);
    

    const onInsurerViewTabMeetings = () => {
        const { TabMeetings } = props;
        if( TabMeetings != null ) {

            var TabMeetingsResponse = Object.assign({}, TabMeetings);

            if (TabMeetingsResponse.result === true) {

                $("#progress-loader").addClass("hidden");

                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "created_by_name", caption: "Created By" },
                    { dataField: "insurer_company_name", caption: "Company Name" },
                    { dataField: "meeting_date", caption: "Meeting Date" },
                    { dataField: "agenda", caption: "Agenda" },
                    { dataField: "business_type", caption: "Objecive" },
                    { dataField: "status", caption: "Status" },
                ]);

                setMeetings(TabMeetingsResponse.response);

            } else {

                $("#progress-loader").addClass("hidden");

                switch (TabMeetingsResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout();
                        })
                        break;

                    default:
                }
            }
        }
    }
    useEffect(onInsurerViewTabMeetings, [props.TabMeetings]);

    const renderStatusCell = (cell) => {
        console.log(cell);
        switch (cell.data.status) {
            case 'open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>Open</div>;
            case 'close':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>Close</div>;
            case 'cancel':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>Cancel</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {

        var arrColumns = [];

        columns.forEach((objColumn, indexColumn) => {

            if (objColumn.dataField === "Action") {

                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm float-left"
                                    onClick={() => {
                                        setShowInsuranceMeetingView(true)
                                        setInsuranceMeetingId(params.data.id);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    const renderListUI = () => {
        return (
            <DataGrid
                dataSource={meetings}
                showBorders={true}
                export={{
                    fileName: "companies",
                    enabled: meetings != null && meetings.length > 0 ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: 'before'

                    })
                }}
            >
                <SearchPanel 
                    visible={true}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    return (
        <>
            <div className="tab-pane active" id="meetings" aria-labelledby="meetings-tab" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    meetings != null && meetings.length > 0 ?
                                    renderListUI()
                                    : 
                                    <h6 className="text-bold-500 text-center">No Meetings Found</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InsuranceCompanyMeetingView
                show={showInsuranceMeetingView}
                insuranceMeetingId={insuranceMeetingId}
                handleClose={() => {
                    setShowInsuranceMeetingView(false);
                }}
            />
        </>
        
    )
}

const mapStateToProps = (state) => {

    var TabMeetings = null;
    
    if (
        state.InsurerViewTabMeetingsReducer != null &&
        Object.keys(state.InsurerViewTabMeetingsReducer).length > 0 &&
        state.InsurerViewTabMeetingsReducer.constructor === Object
    ) {
        switch (state.InsurerViewTabMeetingsReducer.tag) {
            case ACTION_INSURER_VIEW_TAB_MEETINGS_SUCCESS:
                TabMeetings = Object.assign({}, state.InsurerViewTabMeetingsReducer.data);
                delete state.InsurerViewTabMeetingsReducer;
                return { TabMeetings };
            default:
        }
    }
    return {
        TabMeetings
       
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({actionInsurerViewTabMeetings}, dispatch);
}
export default (connect(mapStateToProps, mapDispatchToProps)(TabMeetings));