import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { actionCalendarHoliday, ACTION_CALENDAR_HOLIDAY_SUCCESS } from './action';
import { UNAUTHORIZED } from "../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import moment from "moment";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import { verifyPermission } from '../../../../../utils';

const HolidayList = (props) => {
    const { match } = props;
    const [calendarHoliday, setCalendarHoliday] = useState([]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionCalendarHoliday();
    }
    useEffect(initialRender, []);

    const onCalendarHolidayUpdate = () => {
        const { CalendarHoliday } = props
        if (CalendarHoliday != null) {
            var CalendarHolidayResponse = Object.assign({}, CalendarHoliday);
            if (CalendarHolidayResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setCalendarHoliday(CalendarHolidayResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (CalendarHolidayResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onCalendarHolidayUpdate, [props.CalendarHoliday])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "title", caption: "Title", visible: true },
        { dataField: "date", caption: "Date", visible: false },
        { dataField: "date_formated", caption: "Date", visible: true },
        { dataField: "type", caption: "Type", visible: true },
        { dataField: "short_description", caption: "Description", visible: true },
        { dataField: "branch", caption: "Branch", visible: true },
        { dataField: "pre_render", caption: "Pre render", visible: true },

    ]);
    const renderDescription = (cell) =>
        (cell.data.description != null && cell.data.description != "null") ? <>{cell.data.description}</> : <></>

    const renderFormatedDate = (cell) => {
        return <div>{cell?.data?.date_formated != null ? moment(cell?.data?.date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {/* {
                            verifyPermission("VIEW_HOLIDAY") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        } */}
                        {
                            verifyPermission("EDIT_HOLIDAY") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }

                    </Column>
                );
            } else if (objColumn.dataField === "description") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderDescription}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderFormatedDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Holidays</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {
                                verifyPermission("CREATE_HOLIDAY") ?
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                props.history.push(`${match.url}/add`);
                                            }}
                                        >
                                            Add Holiday
                                        </button>
                                    </li>
                                    :
                                    <></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={calendarHoliday}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Holidays",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("date_formated", "visible", false)
                                        event.component.columnOption("date", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("date_formated", "visible", true)
                                        event.component.columnOption("date", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var CalendarHoliday = null;

    if (
        state.CalendarHolidayReducer != null &&
        Object.keys(state.CalendarHolidayReducer).length > 0 &&
        state.CalendarHolidayReducer.constructor === Object
    ) {
        switch (state.CalendarHolidayReducer.tag) {
            case ACTION_CALENDAR_HOLIDAY_SUCCESS:
                CalendarHoliday = Object.assign({}, state.CalendarHolidayReducer.data);
                delete state.CalendarHolidayReducer;
                return { CalendarHoliday };
            default:

        }
    }
    return {
        CalendarHoliday,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionCalendarHoliday }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(HolidayList))

