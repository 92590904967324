import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { actionEditEducation, ACTION_EDIT_EDUCATION_SUCCESS } from './action';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionGetEducationDetail, ACTION_GET_EDUCATION_DETAIL_SUCCESS } from './action';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
const EducationEdit = (props) => {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const initialRender = () => {
        if (props.show === true) {
            props.actionGetEducationDetail({ id: props.educationId })
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onEditEducationUpdate = () => {
        const { EditEducation } = props;
        var EditEducationResponse = Object.assign({}, EditEducation);
        $("#edit_education").prop("disabled", false);
        if (EditEducationResponse.result === true) {
            props.handleClose(EditEducationResponse.response);
            $("#edit_education-loader").addClass("hidden");
        } else {
            $("#edit_education-loader").addClass("hidden");
            switch (EditEducationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(EditEducationResponse.message);
                default:
            }
        }
    }
    useEffect(onEditEducationUpdate, [props.EditEducation]);

    const onGetEducationDetailUpdate = () => {
        const { GetEducationDetail } = props;
        var GetEducationDetailResponse = Object.assign({}, GetEducationDetail);
        if (GetEducationDetailResponse.result === true) {
            setValue('year_of_passing', GetEducationDetailResponse.response.year_of_passing);
            setValue('education', GetEducationDetailResponse.response.education);
            setValue('institute_name', GetEducationDetailResponse.response.institute_name);
            setValue('id', GetEducationDetailResponse.response.id);
            setValue('user_id', GetEducationDetailResponse.response.user_id);
        } else {
            switch (GetEducationDetailResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetEducationDetailUpdate, [props.GetEducationDetail])

    const onSubmit = (data) => {
        $("#edit_education-loader").removeClass("hidden");
        $("#edit_education").prop("disabled", true);
        props.actionEditEducation(data);
    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="education-edit"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="education-edit">
                                    Edit Education Details
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Education&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="education"
                                                            name="education"
                                                            placeholder="Enter Education"
                                                            {...register('education', { required: 'Education is required' })}
                                                        />
                                                        {errors.education && <ErrorLabel message={errors.education?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Year of Passing&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="year_of_passing"
                                                            name="year_of_passing"
                                                            placeholder="Enter Year of Passing"
                                                            {...register('year_of_passing', { required: 'Year of Passing is required' })}
                                                        />
                                                        {errors.year_of_passing && <ErrorLabel message={errors.year_of_passing?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Institute Name&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="institute_name"
                                                            name="institute_name"
                                                            placeholder="Enter Institute Name"
                                                            {...register('institute_name', { required: 'Institute Name is required' })}
                                                        />
                                                        {errors.institute_name && <ErrorLabel message={errors.institute_name?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                id="edit_education"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Submit
                                                    <span id="edit_education-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>

                                            <button
                                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    props.handleClose();
                                                    event.preventDefault();
                                                }}
                                            > Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    var EditEducation = null;
    var GetEducationDetail = null;

    if (
        state.EditEducationReducer != null &&
        Object.keys(state.EditEducationReducer).length > 0 &&
        state.EditEducationReducer.constructor === Object
    ) {
        switch (state.EditEducationReducer.tag) {
            case ACTION_EDIT_EDUCATION_SUCCESS:
                EditEducation = Object.assign({}, state.EditEducationReducer.data);
                delete state.EditEducationReducer;
                return { EditEducation };
            case ACTION_GET_EDUCATION_DETAIL_SUCCESS:
                GetEducationDetail = Object.assign({}, state.EditEducationReducer.data);
                delete state.EditEducationReducer;
                return { GetEducationDetail };
            default:
        }
    }

    return {
        EditEducation,
        GetEducationDetail,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionEditEducation, actionGetEducationDetail }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EducationEdit))