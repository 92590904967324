import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { FiCheck, FiClock } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { BsClockHistory } from 'react-icons/bs';
import { FiEye } from 'react-icons/fi';
import CronDashboardView from '../../../../../component/popups/cron_dashboard';
import { actionCronDashboard, ACTION_CRON_DASHBOARD_SUCCESS } from './action';
import { actionCronStatistics, ACTION_CRON_STATISTICS_SUCCESS } from './action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UNAUTHORIZED } from '../../../../../constants';
import { verifyPermission } from '../../../../../utils';

const CronDashboard = (props) => {

    const [showCronDashboardView, setShowCronDashboardView] = useState(false);
    const [cronDashboard, setCronDashboard] = useState([]);
    const [cronStatistics, setCronStatistics] = useState();
    const [cronId, setCronId] = useState(0)
    const [cronName, setCronName] = useState(null)
    const { control } = useForm({});

    const initialRender = () => {
        props.actionCronDashboard({});
        props.actionCronStatistics({});
    }
    useEffect(initialRender, []);
    const onCronDashboardUpdate = () => {
        const { CronDashboard } = props;
        var CronDashboardResponse = Object.assign({}, CronDashboard);
        if (CronDashboardResponse.result === true) {
            setCronDashboard(CronDashboardResponse.response);
        } else {
            switch (CronDashboardResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCronDashboardUpdate, [props.CronDashboard])

    const onCronStatisticsUpdate = () => {
        const { CronStatistics } = props;
        var CronStatisticsResponse = Object.assign({}, CronStatistics);
        if (CronStatisticsResponse.result === true) {
            setCronStatistics(CronStatisticsResponse.response);
        } else {
            switch (CronStatisticsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCronStatisticsUpdate, [props.CronStatistics])

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Cron Dashboard</h5>
                </div>
            </div>
            <div className="row">
                <div class="col-12 px-half">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12">
                                    <div class="d-flex align-items-start mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                        <span class="primary d-flex align-items-center justify-content-center">
                                            <FiClock size={32} className="blue-grey" />
                                        </span>
                                        <div class="stats-amount mr-3 ml-1">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    cronStatistics != null && cronStatistics.total_count != null ?
                                                        cronStatistics.total_count : 0
                                                }
                                            </h3>
                                            <p class="sub-heading">Total Cron</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12">
                                    <div class="d-flex align-items-start mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                        <span class="primary d-flex align-items-center justify-content-center">
                                            <FiCheck size={32} className="success" />
                                        </span>
                                        <div class="stats-amount mr-3 ml-1">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    cronStatistics != null && cronStatistics.success_count != null ?
                                                        cronStatistics.success_count : 0
                                                }
                                            </h3>
                                            <p class="sub-heading">Today's Success Cron</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12">
                                    <div class="d-flex align-items-start border-right-blue-grey border-right-lighten-5">
                                        <span class="primary d-flex align-items-center justify-content-center ">
                                            <IoMdClose size={32} className="danger" />
                                        </span>
                                        <div class="stats-amount mr-3 ml-1">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    cronStatistics != null && cronStatistics.failed_count != null ?
                                                        cronStatistics.failed_count : 0
                                                }
                                            </h3>
                                            <p class="sub-heading">Today's Faliure Cron</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-sm-6 col-12">
                                    <div class="d-flex align-items-start">
                                        <span class="primary d-flex align-items-center justify-content-center">
                                            <BsClockHistory size={32} className="warning" />
                                        </span>
                                        <div class="stats-amount mr-3 ml-1">
                                            <h3 class="heading-text text-bold-600">
                                                {
                                                    cronStatistics != null && cronStatistics.pending_count != null ?
                                                        cronStatistics.pending_count : 0
                                                }
                                            </h3>
                                            <p class="sub-heading">Today's Pending Cron</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 px-half">
                    <div className="card">
                        <div className="card-header border-bottom-primary px-1 py-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-medium-1 text-black text-bold-600 mb-0">
                                        CRON Statistics
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body card-dashboard p-0 pb-1">
                            <div id="goal-list-scroll" class="table-responsive position-relative ps ps--active-y" style={{ minHeight: 280 }}>
                                {
                                    cronDashboard != null && cronDashboard.length > 0 ?
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="py-2">Name</th>
                                                    <th className="py-2">Schedule Time</th>
                                                    <th className="py-2">Type</th>
                                                    <th className="py-2">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cronDashboard.map((cron, indexCron) => (
                                                        <tr key={cron.id}>
                                                            <td>{cron.name}</td>
                                                            <td>{cron.schedule_time}</td>
                                                            <td>{cron.cron_type}</td>
                                                            <td>
                                                                {
                                                                    verifyPermission("VIEW_CRON_DASHBOARD") ?
                                                                        <button
                                                                            className="btn btn-icon btn-sm"
                                                                            onClick={() => {
                                                                                setCronName(cron.name)
                                                                                setCronId(cron.id)
                                                                                setShowCronDashboardView(true)
                                                                            }}
                                                                        >
                                                                            <FiEye size={16} className="primary" />
                                                                        </button>
                                                                        :
                                                                        <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CronDashboardView
                show={showCronDashboardView}
                cronId={cronId}
                name={cronName}
                handleClose={() => {
                    setShowCronDashboardView(false)
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var CronDashboard = null;
    var CronStatistics = null;

    if (
        state.CronDashboardReducer != null &&
        Object.keys(state.CronDashboardReducer).length > 0 &&
        state.CronDashboardReducer.constructor === Object
    ) {
        switch (state.CronDashboardReducer.tag) {
            case ACTION_CRON_DASHBOARD_SUCCESS:
                CronDashboard = Object.assign({}, state.CronDashboardReducer.data);
                delete state.CronDashboardReducer;
                return { CronDashboard };
            case ACTION_CRON_STATISTICS_SUCCESS:
                CronStatistics = Object.assign({}, state.CronDashboardReducer.data);
                delete state.CronDashboardReducer;
                return { CronStatistics };
            default:
        }
    }

    return {
        CronDashboard,
        CronStatistics,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionCronStatistics, actionCronDashboard }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CronDashboard)

