import React, { useEffect, useState } from "react";
import { generateInitialLettersString } from "../../../utils";
import { FiEye } from "react-icons/fi";
import { UNAUTHORIZED } from "../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { actionLatestFiveClients, ACTION_LATEST_FIVE_CLIENTS_SUCCESS } from './action';

const DashboardMyClient = (props) => {
    const [latestFiveClients, setLatestFiveClients] = useState([]);
    const initialRender = () => {
        props.actionLatestFiveClients({});
    }
    useEffect(initialRender, []);

    const onLatestFiveClientsUpdate = () => {
        const { LatestFiveClients } = props;
        const LatestFiveClientsResponse = Object.assign({}, LatestFiveClients)
        if (LatestFiveClientsResponse.result === true) {
            setLatestFiveClients(LatestFiveClientsResponse.response)
        } else {
            switch (LatestFiveClientsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onLatestFiveClientsUpdate, [props.LatestFiveClients]);

    const renderUI = () => {
        try {
            return (
                <>
                    {
                        latestFiveClients != null && latestFiveClients.length > 0 ?
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card dashboard-card-round dashboard-other-summary shadow">
                                    <div className="card-header border-bottom-primary px-1 py-half">
                                        <div className="d-flex flex-row justify-content-between align-items-center">
                                            <div className="flex-column">
                                                <h5 className="font-small-4 text-black text-bold-500 mb-0">
                                                    Recent Clients
                                                </h5>
                                            </div>
                                            <div className="flex-column">
                                                <button type="button" className="btn font-small-3"
                                                    onClick={() => {
                                                        props.history.push('/client')
                                                    }}>
                                                    View All
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body px-1 py-1">
                                        <p className="font-small-2">Your recent Clients</p>
                                        {
                                            latestFiveClients != null && latestFiveClients.length > 0 ?
                                                latestFiveClients.map((objClient, index) => (
                                                    <>
                                                        <div
                                                            className="col-12 mb-1 cursor-pointer px-0" key={index}
                                                            onClick={() => {
                                                                props.history.push(
                                                                    {
                                                                        pathname: '/client/view',
                                                                        state: {
                                                                            client_id: objClient.id
                                                                        }
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <div className="d-flex flex-row align-items-center mb-1">
                                                                <div className="flex-column">
                                                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-small-1" style={{ height: 25, width: 25 }}>
                                                                        {generateInitialLettersString(objClient.name)}
                                                                    </div>
                                                                </div>
                                                                <div className="flex-column ml-half">
                                                                    <h5 className="font-small-3 text-black mb-0 text-bold-600">
                                                                        {objClient.name}
                                                                    </h5>
                                                                </div>
                                                                <div className="flex-column ml-auto">
                                                                    <FiEye size="14"></FiEye>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                </>
            )
        } catch (e) {
            return (
                <>
                    <h1>Error Clients</h1>
                </>
            )
        }
    }

    return (
        renderUI()
    );
};
const mapStateToProps = (state) => {

    var LatestFiveClients = null;
    if (
        state.DashboardLatestFiveClientReducer != null &&
        Object.keys(state.DashboardLatestFiveClientReducer).length > 0 &&
        state.DashboardLatestFiveClientReducer.constructor === Object
    ) {
        switch (state.DashboardLatestFiveClientReducer.tag) {
            case ACTION_LATEST_FIVE_CLIENTS_SUCCESS:
                LatestFiveClients = Object.assign({}, state.DashboardLatestFiveClientReducer.data);
                delete state.DashboardLatestFiveClientReducer;
                return { LatestFiveClients };
            default:
        }
    }
    return {
        LatestFiveClients,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionLatestFiveClients,
        },
        dispatch
    );
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DashboardMyClient))
