import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { PRINT } from '../../../../../../../utils';
import { API_ADD_LOSS_SITE_VISIT } from '../../../../../../../api/constants';
import { API_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER } from '../../../../../../../api/constants';
import { API_ADD_LOSS_SITE_VISIT_POLICY_TYPE } from '../../../../../../../api/constants';
import { API_CLAIMS_VIEW } from '../../../../../../../api/constants';


export const ACTION_ADD_LOSS_SITE_VISIT_LOADING = 'ACTION_ADD_LOSS_SITE_VISIT_LOADING';
export const ACTION_ADD_LOSS_SITE_VISIT_SUCCESS = 'ACTION_ADD_LOSS_SITE_VISIT_SUCCESS';

export const ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_LOADING = 'ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_LOADING';
export const ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS = 'ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS';

export const ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_LOADING = 'ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_LOADING';
export const ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS = 'ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS';

export const ACTION_ADD_CLAIMS_VIEW_LOADING = 'ACTION_ADD_CLAIMS_VIEW_LOADING';
export const ACTION_ADD_CLAIMS_VIEW_SUCCESS = 'ACTION_ADD_CLAIMS_VIEW_SUCCESS';

export function actionLossSiteVisit(params) {
	return (dispatch, getState) =>
		Api.post(API_ADD_LOSS_SITE_VISIT, params).then((res) => {
			const data = res;
			PRINT('ACTION RESPONSE', data)
			dispatch(fetchSuccess(ACTION_ADD_LOSS_SITE_VISIT_SUCCESS, { data }));
		}).catch((reason) => {
			var data = {
				message: ''
			}
			dispatch(loading(ACTION_ADD_LOSS_SITE_VISIT_LOADING, { loading: false, data }));

		});
}
export function actionLossSiteVisitNameOfInsurer() {
	return (dispatch, getState) =>
		Api.get(API_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER).then((res) => {
			const data = res;
			PRINT('ACTION RESPONSE', data)
			dispatch(fetchSuccess(ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS, { data }));
		}).catch((reason) => {
			var data = {
				message: ''
			}
			dispatch(loading(ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_LOADING, { loading: false, data }));

		});
}
export function actionLossSiteVisitPolicyType() {
	return (dispatch, getState) =>
		Api.get(API_ADD_LOSS_SITE_VISIT_POLICY_TYPE).then((res) => {
			const data = res;
			PRINT('ACTION RESPONSE', data)
			dispatch(fetchSuccess(ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS, { data }));
		}).catch((reason) => {
			/** Handle Error */
			var data = {
				message: ''
			}
			dispatch(loading(ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_LOADING, { loading: false, data }));
		});
}
export function actionAddClaimsView(params) {
	return (dispatch, getState) =>
		Api.post(API_CLAIMS_VIEW, params).then((res) => {
			const data = res;
			PRINT('ACTION RESPONSE', data)
			dispatch(fetchSuccess(ACTION_ADD_CLAIMS_VIEW_SUCCESS, { data }));
		}).catch((reason) => {
			/** Handle Error */
			var data = {
				message: ''
			}
			dispatch(loading(ACTION_ADD_CLAIMS_VIEW_LOADING, { loading: false, data }));
		});
}