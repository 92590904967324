
import React from "react"
import WellnessManagementList from "./new_request/list";
import { Route, Switch } from "react-router"
import WellnessManagementApprovalsList from "./request_approvals/list";
import WellnessManagementAdd from "./new_request/add";
import WellnessManagementEdit from "./new_request/edit";
import WellnessManagementPoolList from "./wellness_pool/list";
import WellnessManagementView from "./new_request/view";
import WellnessPoolView from "./wellness_pool/view";
import WellnessPoolEdit from "./wellness_pool/edit";
import WellnessApprovalEdit from "./request_approvals/edit";
import WellnessApprovalView from "./request_approvals/view";
import WellnessManagementReport from "./report";

const WellnessManagementRoute = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route path={`${match.url}/new_request/add`} component={WellnessManagementAdd} />
            <Route path={`${match.url}/new_request/edit`} component={WellnessManagementEdit} />
            <Route path={`${match.url}/new_request/view`} component={WellnessManagementView} />
            <Route path={`${match.url}/new_request`} component={WellnessManagementList} />
            <Route path={`${match.url}/wellness_pool/edit`} component={WellnessPoolEdit} />
            <Route path={`${match.url}/wellness_pool/view`} component={WellnessPoolView} />
            <Route path={`${match.url}/wellness_pool`} component={WellnessManagementPoolList} />          
            <Route path={`${match.url}/request_approvals/edit`} component={WellnessApprovalEdit} />
            <Route path={`${match.url}/request_approvals/view`} component={WellnessApprovalView} />
            <Route path={`${match.url}/request_approvals`} component={WellnessManagementApprovalsList} />
            <Route path={`${match.url}/report`} component={WellnessManagementReport} />
        </Switch>
    )
}
export default WellnessManagementRoute