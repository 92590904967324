import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionMasterCategory, ACTION_MASTER_GET_CATEGORY_SUCCESS } from './action';
import { actionMasterAddDepartment, ACTION_MASTER_ADD_DEPARTMENT_SUCCESS } from './action';

const DepartmentAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            status: 'ACTIVE'
        }
    });

    const [masterCategory, setMasterCategory] = useState([]);

    const initialRender = () => {
        // props.actionMasterCategory();
    }
    useEffect(initialRender, []);

    const onMasterCategoryUpdate = () => {
        const { MasterCategory } = props
        if (MasterCategory != null) {
            var MasterCategoryResponse = Object.assign({}, MasterCategory);
            if (MasterCategoryResponse.result === true) {
                setMasterCategory(MasterCategoryResponse.response);
            } else {
                switch (MasterCategoryResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterCategoryUpdate, [props.MasterCategory])

    const onMasterAddDepartmentUpdate = () => {
        const { MasterAddDepartment } = props;
        var MasterAddDepartmentResponse = Object.assign({}, MasterAddDepartment);
        $("#save_department").prop("disabled", false);
        if (MasterAddDepartmentResponse.result === true) {
            $("#department-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Department Saved Successfully");
            props.history.goBack();
        } else {
            $("#department-form-loader").addClass("hidden");
            switch (MasterAddDepartmentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(MasterAddDepartmentResponse.message);
                default:
            }
        }
    }
    useEffect(onMasterAddDepartmentUpdate, [props.MasterAddDepartment]);

    const onSubmit = (data) => {
        $("#department-form-loader").removeClass("hidden");
        $("#save_department").prop("disabled", true);
        props.actionMasterAddDepartment(data);
    }

    return (
        <>
            <ScreenTitle title="Add Department" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Department Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Department Name"
                                                        {...register('name', { required: 'Department Name is required' })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Category&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="category"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="category"
                                                                name="category"
                                                                dataSource={[
                                                                    {
                                                                        value: 'GENERAL INSURANCE',
                                                                        title: 'GENERAL INSURANCE'

                                                                    },
                                                                    {
                                                                        value: 'LIFE INSURANCE',
                                                                        title: 'LIFE INSURANCE'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Category"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Category is required'
                                                        }}
                                                    />

                                                    {errors.category && <ErrorLabel message={errors.category?.message} />}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        value: 'ACTIVE',
                                                                        text: 'ACTIVE'

                                                                    },
                                                                    {
                                                                        value: 'INACTIVE',
                                                                        text: 'INACTIVE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Status is required'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_department"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="department-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterCategory = null;
    var MasterAddDepartment = null;

    if (
        state.MasterAddDepartmentReducer != null &&
        Object.keys(state.MasterAddDepartmentReducer).length > 0 &&
        state.MasterAddDepartmentReducer.constructor === Object
    ) {
        switch (state.MasterAddDepartmentReducer.tag) {
            case ACTION_MASTER_GET_CATEGORY_SUCCESS:
                MasterCategory = Object.assign({}, state.MasterAddDepartmentReducer.data);
                delete state.MasterAddDepartmentReducer
                return { MasterCategory };
            case ACTION_MASTER_ADD_DEPARTMENT_SUCCESS:
                MasterAddDepartment = Object.assign({}, state.MasterAddDepartmentReducer.data);
                delete state.MasterAddDepartmentReducer
                return { MasterAddDepartment };
            default:
        }
    }

    return {
        MasterCategory,
        MasterAddDepartment
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterCategory, actionMasterAddDepartment }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DepartmentAdd)) 