import {
  API_GET_POST_WITH_COMMENTS,
  API_POST_COMMENT_DELETE,
  API_POST_COMMENT_EDIT,
  API_POST_COMMENT_LIKE,
  API_POST_CREATE_COMMENT,
  API_POST_LIKE_DISLIKE,
} from "../../../api/constants";
import Api from "../../../api";
import { PRINT } from "../../../utils";
import { fetchSuccess, loading } from "../../../utils/action.util";

export const ACTION_POST_LOADING = "ACTION_GET_POST_LOADING";
export const ACTION_GET_POST_SUCCESS = "ACTION_GET_POST_SUCCESS";
export const ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS =
  "ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS";
export const ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS = 'ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS';
export const ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS = 'ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS';
export const ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS = 'ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS';
export const ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS = 'ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS';

export function actionGetPost(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_POST_WITH_COMMENTS, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE", data);
        dispatch(fetchSuccess(ACTION_GET_POST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_POST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedLike(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_LIKE_DISLIKE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED LIKE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_POST_ACTIVITYFEEDLIKE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_POST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedCreateComment(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_CREATE_COMMENT, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED CREATE COMMENT ACTION RESPONSE : ", data);
        dispatch(
          fetchSuccess(ACTION_POST_ACTIVITYFEEDCREATECOMMENT_SUCCESS, { data })
        );
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_POST_LOADING, { loading: false, data }));
      });
}

export function actionActivityFeedCommentLike(params) {
    return (dispatch, getState) =>
      Api.post(API_POST_COMMENT_LIKE, params)
        .then((res) => {
          const data = res;
          PRINT("ACTIVITY FEED COMMENT LIKE ACTION RESPONSE : ", data);
          dispatch(fetchSuccess(ACTION_POST_ACTIVITYFEEDCOMMENTLIKE_SUCCESS, { data }));
        })
        .catch((reason) => {
          /** Handle Error */
          var data = {
            message: "",
          };
          dispatch(loading(ACTION_POST_LOADING, { loading: false, data }));
        });
  }
  
  export function actionActivityFeedCommentDelete(params) {
    return (dispatch, getState) =>
      Api.post(API_POST_COMMENT_DELETE, params)
        .then((res) => {
          const data = res;
          PRINT("ACTIVITY FEED COMMENT DELETE ACTION RESPONSE : ", data);
          dispatch(fetchSuccess(ACTION_POST_ACTIVITYFEEDCOMMENTDELETE_SUCCESS, { data }));
        })
        .catch((reason) => {
          /** Handle Error */
          var data = {
            message: "",
          };
          dispatch(loading(ACTION_POST_LOADING, { loading: false, data }));
        });
  }
  
  export function actionActivityFeedCommentEdit(params) {
    return (dispatch, getState) =>
      Api.post(API_POST_COMMENT_EDIT, params)
        .then((res) => {
          const data = res;
          PRINT("ACTIVITY FEED COMMENT EDIT ACTION RESPONSE : ", data);
          dispatch(fetchSuccess(ACTION_POST_ACTIVITYFEEDCOMMENTEDIT_SUCCESS, { data }));
        })
        .catch((reason) => {
          /** Handle Error */
          var data = {
            message: "",
          };
          dispatch(loading(ACTION_POST_LOADING, { loading: false, data }));
        });
  }