import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { FiInfo, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionClientTapProfileDetails, ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS } from './action';
import { generateInitialLettersString } from '../../../../../../../utils';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';

const ClientTapProfileDetails = (props) => {

    const [clientDetails, setClientDetails] = useState(null)
    const [contacts, setContacts] = useState([]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionClientTapProfileDetails({
            id: props.clientId
        })
    }
    useEffect(initialRender, [props.clientId]);


    const onClientTapProfileDetailsUpdate = () => {

        const { ClientTapProfileDetails } = props;

        if (ClientTapProfileDetails != null) {
            var ClientTapProfileDetailsResponse = Object.assign({}, ClientTapProfileDetails);
            if (ClientTapProfileDetailsResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setClientDetails(ClientTapProfileDetailsResponse.response);
                setContacts(ClientTapProfileDetailsResponse.response.contact_details);
            } else {
                switch (ClientTapProfileDetailsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:

                }
            }
        }
    }


    useEffect(onClientTapProfileDetailsUpdate, [props.ClientTapProfileDetails]);


    const renderContacts = () => {
        var arrContacts = []
        if (contacts != null && contacts.length > 0) {
            contacts.forEach((contact, indexContact) => {
                arrContacts.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row mb-half">
                                <div className="flex-column">
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.contact_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{contact.contact_name}</p>
                                    <div class="font-small-3 text-bold-700">{contact.contact_designation}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone !== null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone !== null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email !== null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column ${contact.contact_email !== null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrContacts
    }

    return (
        <>
            <div className="tab-pane" id="contacts" aria-labelledby="contacts-tab" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off">
                            <div className="form-body">
                                <div className="row mb-1">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <div><FiInfo size={16} />&nbsp;Basic Information</div>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">State</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.state != null) ? clientDetails.state : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">City</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.city_name != null) ? clientDetails.city_name : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Pincode</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.pincode != null) ? clientDetails.pincode : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Email</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0 card-text-esc" title={(clientDetails != null && clientDetails.email != null) ? clientDetails.email : ''}>{(clientDetails != null && clientDetails.email != null) ? clientDetails.email : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Mobile</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.mobile != null) ? clientDetails.mobile : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Landline No</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.landline != null) ? clientDetails.landline : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Pan No</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.pan != null) ? clientDetails.pan : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Industrial Segment</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.industrial_segment != null) ? clientDetails.industrial_segment : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Website</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0 card-text-esc" title={(clientDetails != null && clientDetails.website != null) ? clientDetails.website : ''}>{(clientDetails != null && clientDetails.website != null) ? clientDetails.website : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Form Of Organization</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.form_organization != null) ? clientDetails.form_organization : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Multi Branch</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.multi_branch != null) ? clientDetails.multi_branch : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Lead Source</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.lead_source != null) ? clientDetails.lead_source : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                        <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Client Converted On</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.client_converted_on != null) ? clientDetails.client_converted_on : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-2">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <div><FiUser size={16} />&nbsp;Primary Contact</div>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Conatct Name</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.primary_contact != null) ? clientDetails.primary_contact.contact_name : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Contact Designation</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.primary_contact != null) ? clientDetails.primary_contact.contact_designation : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Conatct Phone</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0">{(clientDetails != null && clientDetails.primary_contact != null) ? clientDetails.primary_contact.contact_phone : '-'}</h4>
                                            </div>
                                            <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                                <h4 className="text-uppercase font-small-3 mb-0">Conatct Email ID</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                                <h4 className="font-medium-1 text-bold-700 mb-0 card-text-esc" title={(clientDetails != null && clientDetails.primary_contact != null) ? clientDetails.primary_contact.contact_email : ''}>{(clientDetails != null && clientDetails.primary_contact != null) ? clientDetails.primary_contact.contact_email : '-'}</h4>
                                            </div>
                                        </div>
                                        <div className="row pt-2">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <div><FiPhone size={16} />&nbsp;Contact Details</div>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            {
                                                contacts.length > 0 ?
                                                    renderContacts()
                                                    :
                                                    <h6 className="text-bold-500 text-center">No Contact Details</h6>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}


function mapStateToProps(state) {

    var ClientTapProfileDetails = null;

    if (
        state.ClientTapProfileDetailsReducer != null &&
        Object.keys(state.ClientTapProfileDetailsReducer).length > 0 &&
        state.ClientTapProfileDetailsReducer.constructor === Object
    ) {
        switch (state.ClientTapProfileDetailsReducer.tag) {

            case ACTION_CLIENTTAPPROFILEDETAILS_SUCCESS:
                ClientTapProfileDetails = Object.assign({}, state.ClientTapProfileDetailsReducer.data);
                delete state.ClientTapProfileDetailsReducer;
                return { ClientTapProfileDetails };
            default:
                return { ClientTapProfileDetails }
        }
    }
    return { ClientTapProfileDetails }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionClientTapProfileDetails }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientTapProfileDetails));
