import { Route, Switch } from "react-router";
import DashboardSummary from "./summary";
import DashboardDetail from "./detail";

const DashboardRoute = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/activity-feeds`} component={DashboardDetail} />
      <Route exact path={`${match.url}`} component={DashboardSummary} />
    </Switch>
  );
};
export default  DashboardRoute;
