import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import moment from "moment";
import { FiUser } from "react-icons/fi";
import Swal from "sweetalert2";
import { AMOUNT_REGEX, ERROR, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { actionItSubmissionAdd, ACTION_IT_SUBMISSION_ADD_SUCCESS } from './action';
import { actionItSubmissionDownload, ACTION_SUBMISSION_DOWNLOAD_SUCCESS } from './action';
import { actionItSubmissionDetail, ACTION_SUBMISSION_DETAIL_SUCCESS } from './action';
import { CalculateFinancialYear } from "../../../../../../utils";

const AddSubmission = (props) => {
    const { register, handleSubmit, control, formState: { errors }, reset, watch, setValue } = useForm({
        defaultValues: {
            nps: 0,
            family_mediclaim: 0,
            parents_mediclaim: 0,
            preventive_health_check_up: 0,
            interest_paid_on_loan_for_self_education: 0,
            interest_paid_on_housing_loan: 0,
            loss_from_house_property: 0,
            hra: 0
        }
    });

    const [selectTaxRegime, setSelectTaxRegime] = useState(null);
    const tooltipSection80C = "Maximum eligible limit INR 150,000 (1.5 Lakhs).";
    const tooltipSection80CCD = "Maximum eligible limit INR 50,000 (50K)";
    const tooltipSection80D = "Maximum Eligibility INR 25000 for Family for Emp <60 Years INR 50000 for Emp > 60 Years INR 50000 for Dependent Parent INR 5000 for Preventive Health Check Up (Combine Maximum Limit 75000 / 100000)";
    const tooltipOthers = "INR 200,000 (2 lakhs) maximum eligibility for Interest Paid on Housing Loan & Loss from House Property.";
    const [regime, setRegime] = useState([
        {
            "title": "OLD",
            "value": "OLD"
        },
        {
            "title": "NEW",
            "value": "NEW"
        }
    ]);
    const [pfContribution, setPfContribution] = useState([
        {
            "title": "0",
            "value": "0"
        },
        {
            "title": "1800",
            "value": "1800"
        },
        // {
        //     "title": "6% of Basic",
        //     "value": "6% of Basic"
        // },
        {
            "title": "12% of Basic",
            "value": "12% of Basic"
        }
    ]);

    const [submissionDetail, setSubmissionDetail] = useState(null);
    const [printSubmissionDetail, setPrintSubmissionDetail] = useState(null)
    const watchDocumentLifeInsurancePremium = watch('submissions_document_life_insurance_premium_or_pension_schemes')
    const watchDocumentPPF = watch('submissions_document_ppf')
    const watchDocumentUlipOrElssOrTaxSavingMutualFunds = watch('submissions_document_ulip_or_elss_or_tax_saving_mutual_funds')
    const watchDocumentNSC = watch('submissions_document_nsc')
    const watchDocumentRepaymentOfHousingLoan = watch('submissions_document_repayment_of_housing_loan')
    const watchDocumenttUitionFeesForChildren = watch('submissions_document_tuition_fees_for_children')
    const watchDocumentFiveYearFd = watch('submissions_document_five_year_fd')
    const watchDocumentOthers = watch('submissions_document_others')
    const watchDocumentNPS = watch('submissions_document_nps')
    const watchDocumentFamilyMediclaim = watch('submissions_document_family_mediclaim')
    const watchDocumentParentMediclaim = watch('submissions_document_parents_mediclaim')
    const watchDocumentHealthCheckUp = watch('submissions_document_preventive_health_check_up')

    const watchDocumentSelfEducation = watch('submissions_document_interest_paid_on_loan_for_self_education')
    const watchDocumentHousingLoan = watch('submissions_document_interest_paid_on_housing_loan')
    const watchDocumentHouseProperty = watch('submissions_document_loss_from_house_property')
    const watchDocumentHRA = watch('submissions_document_hra')
    const watchDocumentLTA = watch('submissions_document_lta')

    const watchSubmissionGrandTotal = watch('submissions_grand_total')
    const watchSubmissionDocumentTotal = watch('submissions_document_grand_total')

    // final watch 
    const watchSubmissionLifeInsurancePremium = watch('submissions_life_insurance_premium_or_pension_schemes')
    const watchSubmissionPPF = watch('submissions_ppf')
    const watchSubmissionUlipOrElssOrTaxSavingMutualFunds = watch('submissions_ulip_or_elss_or_tax_saving_mutual_funds')
    const watchSubmissionNSC = watch('submissions_nsc')
    const watchSubmissionRepaymentOfHousingLoan = watch('submissions_repayment_of_housing_loan')
    const watchSubmissiontUitionFeesForChildren = watch('submissions_tuition_fees_for_children')
    const watchSubmissionFiveYearFd = watch('submissions_five_year_fd')
    const watchSubmissionOthers = watch('submissions_others')
    const watchSubmissionNPS = watch('submissions_nps')
    const watchSubmissionFamilyMediclaim = watch('submissions_family_mediclaim')
    const watchSubmissionParentMediclaim = watch('submissions_parents_mediclaim')
    const watchSubmissionHealthCheckUp = watch('submissions_preventive_health_check_up')

    const watchSubmissionSelfEducation = watch('submissions_interest_paid_on_loan_for_self_education')
    const watchSubmissionHousingLoan = watch('submissions_interest_paid_on_housing_loan')
    const watchSubmissionHouseProperty = watch('submissions_loss_from_house_property')
    const watchSubmissionHRA = watch('submissions_hra')
    const watchSubmissionLTA = watch('submissions_lta')

    const initialRender = () => {
        reset({
            regime: null,
            pf_contribution: '',
            life_insurance_premium_or_pension_schemes: '',
            ppf: '',
            ulip_or_elss_or_tax_saving_mutual_funds: '',
            nsc: '',
            repayment_of_housing_loan: '',
            tuition_fees_for_children: '',
            five_year_fd: '',
            others: '',
            grand_total: '',
            nps: '',
            family_mediclaim: '',
            parents_mediclaim: '',
            preventive_health_check_up: '',
            interest_paid_on_housing_loan: '',
            interest_paid_on_loan_for_self_education: '',
            loss_from_house_property: '',
            hra: '',
            emp_remark: ''
        })
        if (props.show === true) {
            props.actionItSubmissionDetail({ window_id: props.windowId, user_id: props.auth.getUser().user_id });
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    function base64toPDF(base64, fileName) {
        const binaryString = atob(base64);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }

    const calculateGrandTotal = (watchValues, setValueKey) => {
        var grandTotal = 0;
        var grandTotal = Object.values(watchValues).reduce((total, value) => {
            if (value != null) {
                total += Number(value);
            }
            return total;
        }, 0);
        setValue(setValueKey, grandTotal.toLocaleString('fullwide', { useGrouping: false }));
    };

    useEffect(() => {
        calculateGrandTotal(
            {
                watchSubmissionLifeInsurancePremium,
                watchSubmissionPPF,
                watchSubmissionUlipOrElssOrTaxSavingMutualFunds,
                watchSubmissionNSC,
                watchSubmissionRepaymentOfHousingLoan,
                watchSubmissiontUitionFeesForChildren,
                watchSubmissionFiveYearFd,
                watchSubmissionOthers,
            },
            'submissions_grand_total'
        );
    }, [
        watchSubmissionLifeInsurancePremium,
        watchSubmissionPPF,
        watchSubmissionUlipOrElssOrTaxSavingMutualFunds,
        watchSubmissionNSC,
        watchSubmissionRepaymentOfHousingLoan,
        watchSubmissiontUitionFeesForChildren,
        watchSubmissionFiveYearFd,
        watchSubmissionOthers,
    ]);

    useEffect(() => {
        calculateGrandTotal(
            {
                watchDocumentLifeInsurancePremium,
                watchDocumentPPF,
                watchDocumentUlipOrElssOrTaxSavingMutualFunds,
                watchDocumentNSC,
                watchDocumentRepaymentOfHousingLoan,
                watchDocumenttUitionFeesForChildren,
                watchDocumentFiveYearFd,
                watchDocumentOthers,
            },
            'submissions_document_grand_total'
        );
    }, [
        watchDocumentLifeInsurancePremium,
        watchDocumentPPF,
        watchDocumentUlipOrElssOrTaxSavingMutualFunds,
        watchDocumentNSC,
        watchDocumentRepaymentOfHousingLoan,
        watchDocumenttUitionFeesForChildren,
        watchDocumentFiveYearFd,
        watchDocumentOthers,
    ]);

    useEffect(() => {

        let PendingLifeInsurancePremium, PendingPPF, PendingMutualFunds, PendingNSC, PendingHousingRepayment, PendingChildrenFees, PendingFiveYearFd, PendingOthers, PendingFamilyMediclaim, PendingParents, PendingNPS, PendingHealthCheck, PendingSelfEducation, PendingHousingLoan, PendingHouseProperty, PendingHRA, PendingLTA;

        if (watchSubmissionLifeInsurancePremium != null && watchDocumentLifeInsurancePremium != null) {
            PendingLifeInsurancePremium = Number(watchSubmissionLifeInsurancePremium) - Number(watchDocumentLifeInsurancePremium)
            setValue('submissions_pending_life_insurance_premium_or_pension_schemes', PendingLifeInsurancePremium);
        }

        if (watchSubmissionPPF != null && watchDocumentPPF != null) {
            PendingPPF = Number(watchSubmissionPPF) - Number(watchDocumentPPF)
            setValue('submissions_pending_ppf', PendingPPF);
        }

        if (watchSubmissionUlipOrElssOrTaxSavingMutualFunds != null && watchDocumentUlipOrElssOrTaxSavingMutualFunds != null) {
            PendingMutualFunds = Number(watchSubmissionUlipOrElssOrTaxSavingMutualFunds) - Number(watchDocumentUlipOrElssOrTaxSavingMutualFunds)
            setValue('submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds', PendingMutualFunds);
        }

        if (watchSubmissionNSC != null && watchDocumentNSC != null) {
            PendingNSC = Number(watchSubmissionNSC) - Number(watchDocumentNSC)
            setValue('submissions_pending_nsc', PendingNSC);
        }

        if (watchSubmissionRepaymentOfHousingLoan != null && watchDocumentRepaymentOfHousingLoan != null) {
            PendingHousingRepayment = Number(watchSubmissionRepaymentOfHousingLoan) - Number(watchDocumentRepaymentOfHousingLoan)
            setValue('submissions_pending_repayment_of_housing_loan', PendingHousingRepayment);
        }

        if (watchSubmissiontUitionFeesForChildren != null && watchDocumenttUitionFeesForChildren != null) {
            PendingChildrenFees = Number(watchSubmissiontUitionFeesForChildren) - Number(watchDocumenttUitionFeesForChildren)
            setValue('submissions_pending_tuition_fees_for_children', PendingChildrenFees);
        }

        if (watchSubmissionFiveYearFd != null && watchDocumentFiveYearFd != null) {
            PendingFiveYearFd = Number(watchSubmissionFiveYearFd) - Number(watchDocumentFiveYearFd)
            setValue('submissions_pending_five_year_fd', PendingFiveYearFd);
        }

        if (watchSubmissionOthers != null && watchDocumentOthers != null) {
            PendingOthers = Number(watchSubmissionOthers) - Number(watchDocumentOthers)
            setValue('submissions_pending_others', PendingOthers);
        }

        if (watchSubmissionNPS != null && watchDocumentNPS != null) {
            PendingNPS = Number(watchSubmissionNPS) - Number(watchDocumentNPS)
            setValue('submissions_pending_nps', PendingNPS);
        }

        if (watchSubmissionFamilyMediclaim != null && watchDocumentFamilyMediclaim != null) {
            PendingFamilyMediclaim = Number(watchSubmissionFamilyMediclaim) - Number(watchDocumentFamilyMediclaim)
            setValue('submissions_pending_family_mediclaim', PendingFamilyMediclaim);
        }

        if (watchSubmissionParentMediclaim != null && watchDocumentParentMediclaim != null) {
            PendingParents = Number(watchSubmissionParentMediclaim) - Number(watchDocumentParentMediclaim)
            setValue('submissions_pending_parents_mediclaim', PendingParents);
        }

        if (watchSubmissionHealthCheckUp != null && watchDocumentHealthCheckUp != null) {
            PendingHealthCheck = Number(watchSubmissionHealthCheckUp) - Number(watchDocumentHealthCheckUp)
            setValue('submissions_pending_preventive_health_check_up', PendingHealthCheck);
        }

        if (watchSubmissionSelfEducation != null && watchDocumentSelfEducation != null) {
            PendingSelfEducation = Number(watchSubmissionSelfEducation) - Number(watchDocumentSelfEducation)
            setValue('submissions_pending_interest_paid_on_loan_for_self_education', PendingSelfEducation);
        }

        if (watchSubmissionHousingLoan != null && watchDocumentHousingLoan != null) {
            PendingHousingLoan = Number(watchSubmissionHousingLoan) - Number(watchDocumentHousingLoan)
            setValue('submissions_pending_interest_paid_on_housing_loan', PendingHousingLoan);
        }
        if (watchSubmissionHouseProperty != null && watchDocumentHouseProperty != null) {
            PendingHouseProperty = Number(watchSubmissionHouseProperty) - Number(watchDocumentHouseProperty)
            setValue('submissions_pending_loss_from_house_property', PendingHouseProperty);
        }
        if (watchSubmissionHRA != null && watchDocumentHRA != null) {
            PendingHRA = Number(watchSubmissionHRA) - Number(watchDocumentHRA)
            setValue('submissions_pending_hra', PendingHRA);
        }
        if (watchSubmissionLTA != null && watchDocumentLTA != null) {
            PendingLTA = Number(watchSubmissionLTA) - Number(watchDocumentLTA)
            setValue('submissions_pending_lta', PendingLTA);
        }

        if (watchSubmissionGrandTotal != null && watchSubmissionDocumentTotal != null) {
            const PendingGrandTotal = Number(watchSubmissionGrandTotal) - Number(watchSubmissionDocumentTotal)
            setValue('submissions_pending_grand_total', PendingGrandTotal);
        }

        const pendingValues = [
            PendingLifeInsurancePremium,
            PendingPPF,
            PendingMutualFunds,
            PendingNSC,
            PendingHousingRepayment,
            PendingChildrenFees,
            PendingFiveYearFd,
            PendingOthers,
            PendingFamilyMediclaim,
            PendingParents,
            PendingHealthCheck,
            PendingSelfEducation,
            PendingHousingLoan,
            PendingHouseProperty,
            PendingHRA,
            PendingLTA,
            PendingNPS
        ];

        const hasNegativePendingValue = pendingValues.some((value) => value < 0);

        if (hasNegativePendingValue) {
            Swal.fire({
                icon: 'warning',
                text: 'Document amount cannot be greater than “Final Declaration Amount”',
                confirmButtonText: "okay",
                confirmButtonColor: "text-primary",
            }).then(({ isConfirmed }) => {
            })
            $("#add-submission").prop("disabled", true);
        } else {
            $("#add-submission").prop("disabled", false);
        }

    }, [watchSubmissionLifeInsurancePremium, watchDocumentLifeInsurancePremium, watchSubmissionPPF,
        watchDocumentPPF, watchDocumentUlipOrElssOrTaxSavingMutualFunds, watchSubmissionUlipOrElssOrTaxSavingMutualFunds,
        watchSubmissionNSC, watchDocumentNSC, watchDocumentRepaymentOfHousingLoan, watchSubmissionRepaymentOfHousingLoan,
        watchDocumenttUitionFeesForChildren, watchSubmissiontUitionFeesForChildren, watchSubmissionFiveYearFd,
        watchDocumentFiveYearFd, watchDocumentNPS, watchSubmissionNPS, watchDocumentOthers, watchSubmissionOthers,
        watchSubmissionFamilyMediclaim, watchDocumentFamilyMediclaim, watchSubmissionParentMediclaim, watchDocumentParentMediclaim,
        watchSubmissionHealthCheckUp, watchDocumentHealthCheckUp, watchSubmissionSelfEducation, watchDocumentSelfEducation,
        watchSubmissionHousingLoan, watchDocumentHousingLoan, watchSubmissionHouseProperty, watchDocumentHouseProperty,
        watchSubmissionHRA, watchDocumentHRA, watchSubmissionLTA, watchDocumentLTA, watchSubmissionGrandTotal, watchSubmissionDocumentTotal
    ]);

    const onItSubmissionDetailUpdate = () => {
        const { ItSubmissionDetail } = props;
        if (ItSubmissionDetail != null) {
            var ItSubmissionDetailResponse = Object.assign({}, ItSubmissionDetail);
            if (ItSubmissionDetailResponse?.result === true) {
                setSubmissionDetail(ItSubmissionDetailResponse?.response);
                setValue("regime", ItSubmissionDetailResponse?.response?.declarations?.regime);
                setValue('actual_life_insurance_premium_or_pension_schemes', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.life_insurance_premium_or_pension_schemes);
                setValue('actual_ppf', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.ppf);
                setValue('actual_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds);
                setValue('actual_nsc', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.nsc);
                setValue('actual_repayment_of_housing_loan', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.repayment_of_housing_loan);
                setValue('actual_tuition_fees_for_children', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.tuition_fees_for_children);
                setValue('actual_five_year_fd', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.five_year_fd);
                setValue('actual_others', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.others);
                setValue('actual_grand_total', ItSubmissionDetailResponse?.response?.declarations?.declaration_80c?.grand_total);
                setValue('actual_nps', ItSubmissionDetailResponse?.response?.declarations?.declaration_80ccd?.nps);
                setValue('actual_family_mediclaim', ItSubmissionDetailResponse?.response?.declarations?.declaration_80d?.family_mediclaim);
                setValue('actual_parents_mediclaim', ItSubmissionDetailResponse?.response?.declarations?.declaration_80d?.parents_mediclaim);
                setValue('actual_preventive_health_check_up', ItSubmissionDetailResponse?.response?.declarations?.declaration_80d?.preventive_health_check_up);
                setValue('actual_interest_paid_on_loan_for_self_education', ItSubmissionDetailResponse?.response?.declarations?.declaration_80e?.interest_paid_on_loan_for_self_education);
                setValue('actual_interest_paid_on_housing_loan', ItSubmissionDetailResponse?.response?.declarations?.declaration_other?.interest_paid_on_housing_loan);
                setValue('actual_loss_from_house_property', ItSubmissionDetailResponse?.response?.declarations?.declaration_other?.loss_from_house_property);
                setValue('actual_hra', ItSubmissionDetailResponse?.response?.declarations?.declaration_other?.hra);
                setValue('actual_emp_remark', ItSubmissionDetailResponse?.response?.declarations?.emp_remark);
                setValue("actual_pf_contribution", ItSubmissionDetailResponse?.response?.declarations?.pf_contribution);
                setValue("actual_lta", ItSubmissionDetailResponse?.response?.declarations?.lta);
                setValue("actual_special_allowance", ItSubmissionDetailResponse?.response?.declarations?.special_allowance);
                setValue('emp_remark', ItSubmissionDetailResponse?.response?.submissions?.emp_remark);
                // submission details 
                // setValue('submissions_life_insurance_premium_or_pension_schemes', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes);
                // setValue('submissions_document_life_insurance_premium_or_pension_schemes', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document);
                setValue('submissions_status_life_insurance_premium_or_pension', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status);
                setValue('submissions_approved_life_insurance_premium_or_pension_schemes', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_approved);

                // setValue('submissions_ppf', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ppf);
                // setValue('submissions_document_ppf', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ppf_document);
                setValue('submissions_status_ppf', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ppf_status);
                setValue('submissions_approved_ppf', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ppf_approved);

                // setValue('submissions_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds);
                // setValue('submissions_document_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document);
                setValue('submissions_status_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status);
                setValue('submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_approved);

                // setValue('submissions_nsc', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.nsc);
                // setValue('submissions_document_nsc', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.nsc_document);
                setValue('submissions_status_nsc', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.nsc_status);
                setValue('submissions_approved_nsc', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.nsc_approved);

                // setValue('submissions_repayment_of_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan);
                // setValue('submissions_document_repayment_of_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_document);
                setValue('submissions_status_repayment_of_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_status);
                setValue('submissions_approved_repayment_of_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_approved);

                // setValue('submissions_tuition_fees_for_children', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan);
                // setValue('submissions_document_tuition_fees_for_children', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_document);
                setValue('submissions_status_tuition_fees_for_children', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_status);
                setValue('submissions_approved_tuition_fees_for_children', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_approved);

                // setValue('submissions_five_year_fd', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children);
                // setValue('submissions_document_five_year_fd', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_document);
                setValue('submissions_status_five_year_fd', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_status);
                setValue('submissions_approval_five_year_fd', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_approved);

                setValue('submissions_approved_others', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.others_approved);
                // setValue('submissions_others', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.others);
                // setValue('submissions_document_others', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.others_document);
                setValue('submissions_status_others', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.others_status);

                setValue('submissions_grand_total', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.grand_total);
                // setValue('submissions_document_grand_total', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.grand_total_document);
                // setValue('submissions_pending_grand_total', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.grand_total);
                setValue('submissions_approved_grand_total', ItSubmissionDetailResponse?.response?.submissions?.submission_80c?.grand_total_approved);

                // setValue('submissions_nps', ItSubmissionDetailResponse?.response?.submissions?.submission_80ccd?.nps);
                setValue('submissions_status_nps', ItSubmissionDetailResponse?.response?.submissions?.submission_80ccd?.nps_status);
                // setValue('submissions_document_nps', ItSubmissionDetailResponse?.response?.submissions?.submission_80ccd?.nps_document);
                setValue('submissions_approved_nps', ItSubmissionDetailResponse?.response?.submissions?.submission_80ccd?.nps_approved);

                // setValue('submissions_family_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim);
                // setValue('submissions_document_family_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_document);
                setValue('submissions_status_family_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_status);
                setValue('submissions_approved_family_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_approved);

                // setValue('submissions_parents_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim);
                // setValue('submissions_document_parents_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_document);
                setValue('submissions_status_parents_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_status);
                setValue('submissions_approved_parents_mediclaim', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_approved);

                // setValue('submissions_preventive_health_check_up', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up);
                // setValue('submissions_document_preventive_health_check_up', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_document);
                setValue('submissions_status_preventive_health_check_up', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_status);
                setValue('submissions_approved_preventive_health_check_up', ItSubmissionDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_approved);

                // setValue('submissions_interest_paid_on_loan_for_self_education', ItSubmissionDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education);
                // setValue('submissions_document_interest_paid_on_loan_for_self_education', ItSubmissionDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document);
                setValue('submissions_status_interest_paid_on_loan_for_self_education', ItSubmissionDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status);
                setValue('submissions_approved_interest_paid_on_loan_for_self_education', ItSubmissionDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_approved);

                // setValue('submissions_interest_paid_on_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan);
                // setValue('submissions_document_interest_paid_on_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_document);
                setValue('submissions_status_interest_paid_on_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_status);
                setValue('submissions_approved_interest_paid_on_housing_loan', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_approved);

                // setValue('submissions_loss_from_house_property', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property);
                // setValue('submissions_document_loss_from_house_property', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_document);
                setValue('submissions_status_loss_from_house_property', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_status);
                setValue('submissions_approved_loss_from_house_property', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_approved);

                // setValue('submissions_hra', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.hra);
                // setValue('submissions_document_hra', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.hra_document);
                setValue('submissions_status_hra', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.hra_status);
                setValue('submissions_approved_hra', ItSubmissionDetailResponse?.response?.submissions?.submission_other?.hra_approved);

                // setValue("submissions_lta", ItSubmissionDetailResponse?.response?.submissions?.lta);
                // setValue("submissions_document_lta", ItSubmissionDetailResponse?.response?.submissions?.lta_document);
                setValue("submissions_status_lta", ItSubmissionDetailResponse?.response?.submissions?.lta_status);
                setValue("submissions_approved_lta", ItSubmissionDetailResponse?.response?.submissions?.lta_approved);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ItSubmissionDetailResponse?.status) {
                    case ERROR:
                        setSubmissionDetail(null);
                        break;
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onItSubmissionDetailUpdate, [props.ItSubmissionDetail]);

    const onItSubmissionDownloadUpdate = () => {
        const { ItSubmissionDownload } = props;
        var ItSubmissionDownloadResponse = Object.assign({}, ItSubmissionDownload);
        if (ItSubmissionDownloadResponse.result === true) {
            if(submissionDetail?.profile?.full_name){
            base64toPDF(`${ItSubmissionDownloadResponse?.response?.submission}`, submissionDetail?.profile?.full_name + ' Submission Detail.pdf')
            }
        } else {
            switch (ItSubmissionDownloadResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(ItSubmissionDownloadResponse.message);
                default:
            }
        }
    }
    useEffect(onItSubmissionDownloadUpdate, [props.ItSubmissionDownload]);

    const onItSubmissionAddUpdate = () => {
        const { ItSubmissionAdd } = props;
        var ItSubmissionAddResponse = Object.assign({}, ItSubmissionAdd);
        $("#add-submission").prop("disabled", false);
        if (ItSubmissionAddResponse.result === true) {
            $("#add-submission-loader").addClass("hidden");
            props.handleClose({ 'from': 'submission' });
            Swal.fire({
                icon: 'message',
                text: 'Please download copy of your form',
                cancelButtonText: "Cancel",
                showCancelButton: true,
                confirmButtonText: "Download",
                confirmButtonColor: "primary",
            }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                    // $("#progress-loader").removeClass("hidden");
                    props.actionItSubmissionDownload({ window_id: props.windowId, user_id: props.auth.getUser().user_id })
                }
            })
        } else {
            $("#add-submission-loader").addClass("hidden");
            switch (ItSubmissionAddResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        text: ItSubmissionAddResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    setRegime([]);
                    setPfContribution([]);
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onItSubmissionAddUpdate, [props.ItSubmissionAdd]);

    const onSubmit = (data) => {
        $("#add-submission-loader").removeClass("hidden");
        $("#add-submission").prop("disabled", true);
        if (data?.regime === 'OLD') {
            data = {
                window_id: props.windowId != null ? props.windowId : '',
                regime: submissionDetail?.declarations?.regime != null ? submissionDetail?.declarations?.regime : data?.regime,
                emp_remark: data?.emp_remark,
                pf_contribution: data?.pf_contribution,
                lta: data?.submissions_lta,
                lta_document: data?.submissions_document_lta,
                submission_80c: {
                    life_insurance_premium_or_pension_schemes: data?.submissions_life_insurance_premium_or_pension_schemes,
                    life_insurance_premium_or_pension_schemes_document: data?.submissions_document_life_insurance_premium_or_pension_schemes,

                    ppf: data?.submissions_ppf,
                    ppf_document: data?.submissions_document_ppf,

                    ulip_or_elss_or_tax_saving_mutual_funds: data?.submissions_ulip_or_elss_or_tax_saving_mutual_funds,
                    ulip_or_elss_or_tax_saving_mutual_funds_document: data?.submissions_document_ulip_or_elss_or_tax_saving_mutual_funds,

                    nsc: data?.submissions_nsc,
                    nsc_document: data?.submissions_document_nsc,

                    repayment_of_housing_loan: data?.submissions_repayment_of_housing_loan,
                    repayment_of_housing_loan_document: data?.submissions_document_repayment_of_housing_loan,

                    tuition_fees_for_children: data?.submissions_tuition_fees_for_children,
                    tuition_fees_for_children_document: data?.submissions_document_tuition_fees_for_children,

                    five_year_fd: data?.submissions_five_year_fd,
                    five_year_fd_document: data?.submissions_document_five_year_fd,

                    others: data?.submissions_others,
                    others_document: data?.submissions_document_others,

                    grand_total: data?.submissions_grand_total,
                    grand_total_document: data?.submissions_document_grand_total
                },
                submission_80ccd: {
                    nps: data?.submissions_nps,
                    nps_document: data?.submissions_document_nps,
                },
                submission_80d: {
                    family_mediclaim: data?.submissions_family_mediclaim,
                    family_mediclaim_document: data?.submissions_document_family_mediclaim,
                    parents_mediclaim: data?.submissions_parents_mediclaim,
                    parents_mediclaim_document: data?.submissions_document_parents_mediclaim,
                    preventive_health_check_up: data?.submissions_preventive_health_check_up,
                    preventive_health_check_up_document: data?.submissions_document_preventive_health_check_up,
                },
                submission_80e: {
                    interest_paid_on_loan_for_self_education: data?.submissions_interest_paid_on_loan_for_self_education,
                    interest_paid_on_loan_for_self_education_document: data?.submissions_document_interest_paid_on_loan_for_self_education,
                },
                submission_other: {
                    interest_paid_on_housing_loan: data?.submissions_interest_paid_on_housing_loan,
                    loss_from_house_property: data?.submissions_loss_from_house_property,
                    hra: data?.submissions_hra,
                    interest_paid_on_housing_loan_document: data?.submissions_document_interest_paid_on_housing_loan,
                    loss_from_house_property_document: data?.submissions_document_loss_from_house_property,
                    hra_document: data?.submissions_document_hra,
                },
            }
        } else {
            data = {
                window_id: props.windowId != null ? props.windowId : '',
                regime: submissionDetail?.declarations?.regime != null ? submissionDetail?.declarations?.regime : data?.regime,
                emp_remark: data?.emp_remark,
                pf_contribution: data?.pf_contribution,
                lta: data?.submissions_lta,
                lta_document: data?.submissions_document_lta,
            }
        }
        props.actionItSubmissionAdd(data);
    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="add-declaration"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog cust-modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="add-declaration">
                                    {`Income Tax Declaration ${CalculateFinancialYear()}`}
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    reset();
                                    setSelectTaxRegime(null);
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control">TAX REGIME&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="regime"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={regime}
                                                            fields={{ text: 'title', value: 'value' }}
                                                            allowFiltering={false}
                                                            placeholder="Select Tax Regime"
                                                            enabled={submissionDetail?.declarations?.regime != null ? false : true}
                                                            value={field.value?.title}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData?.value);
                                                                    setSelectTaxRegime(event.itemData?.value);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    rules={{
                                                        required: submissionDetail?.declarations?.regime != null ? '' : 'Please Select Tax Regime'
                                                    }}
                                                />
                                                {errors.regime && <ErrorLabel message={errors.regime?.message} />}
                                            </div>
                                        </div>
                                        {
                                            selectTaxRegime != null && (selectTaxRegime === "OLD") ?
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <FiUser size={16} />&nbsp;Details of Investment Under Section 80 C&nbsp;
                                                        <Tippy content={tooltipSection80C}>
                                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                        </Tippy>
                                                    </h4>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            selectTaxRegime != null && selectTaxRegime === "OLD" ?
                                                <>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Type of Investment</label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Actual Declaration</label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Final Declaration</label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Doc Submitted</label>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <label className="label-control">Pending Amt.</label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Approved Amt</label>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <label className="label-control">Status</label>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Life Insurance Premium / Pension Schemes&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control bg-white border-0"
                                                                        id="actual_life_insurance_premium_or_pension_schemes"
                                                                        name="actual_life_insurance_premium_or_pension_schemes"
                                                                        placeholder="-"
                                                                        disabled={true}
                                                                        {...register('actual_life_insurance_premium_or_pension_schemes', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border rounded pl-1"
                                                                        id="submissions_life_insurance_premium_or_pension_schemes"
                                                                        name="submissions_life_insurance_premium_or_pension_schemes"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_life_insurance_premium_or_pension_schemes', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_life_insurance_premium_or_pension_schemes && <ErrorLabel message={errors.submissions_life_insurance_premium_or_pension_schemes?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control border rounded pl-1"
                                                                        id="submissions_document_life_insurance_premium_or_pension_schemes"
                                                                        name="submissions_document_life_insurance_premium_or_pension_schemes"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_life_insurance_premium_or_pension_schemes', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_life_insurance_premium_or_pension_schemes && <ErrorLabel message={errors.submissions_document_life_insurance_premium_or_pension_schemes?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control px-0 form-control border-0 bg-white"
                                                                        id="submissions_pending_life_insurance_premium_or_pension_schemes"
                                                                        disabled={true}
                                                                        name="submissions_pending_life_insurance_premium_or_pension_schemes"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_life_insurance_premium_or_pension_schemes', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_life_insurance_premium_or_pension_schemes"
                                                                        name="submissions_approved_life_insurance_premium_or_pension_schemes"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_life_insurance_premium_or_pension_schemes', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_life_insurance_premium_or_pension"
                                                                        disabled={true}
                                                                        name="submissions_status_life_insurance_premium_or_pension"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_life_insurance_premium_or_pension', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Public Provident Fund (PPF)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_ppf"
                                                                        name="actual_ppf"
                                                                        disabled={true}
                                                                        placeholder="-"
                                                                        {...register('actual_ppf', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_ppf"
                                                                        name="submissions_ppf"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_ppf', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_ppf && <ErrorLabel message={errors.submissions_ppf?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_ppf"
                                                                        name="submissions_document_ppf"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_ppf', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_ppf && <ErrorLabel message={errors.submissions_document_ppf?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_ppf"
                                                                        disabled={true}
                                                                        name="submissions_pending_ppf"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_ppf', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_ppf"
                                                                        name="submissions_approved_ppf"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_ppf', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_ppf"
                                                                        disabled={true}
                                                                        name="submissions_status_ppf"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_ppf', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">ULIP / ELSS / Tax saving Mutual Funds&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border border-0 bg-white"
                                                                        id="actual_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        name="actual_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        disabled={true}
                                                                        placeholder="-"
                                                                        {...register('actual_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        name="submissions_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_ulip_or_elss_or_tax_saving_mutual_funds && <ErrorLabel message={errors.submissions_ulip_or_elss_or_tax_saving_mutual_funds?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        name="submissions_document_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_ulip_or_elss_or_tax_saving_mutual_funds && <ErrorLabel message={errors.submissions_document_ulip_or_elss_or_tax_saving_mutual_funds?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        disabled={true}
                                                                        name="submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border border-0 bg-white"
                                                                        id="submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        name="submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border border-0 bg-white"
                                                                        id="submissions_status_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        disabled={true}
                                                                        name="submissions_status_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">NSC&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_nsc"
                                                                        name="actual_nsc"
                                                                        disabled={true}
                                                                        placeholder="-"
                                                                        {...register('actual_nsc', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_nsc"
                                                                        name="submissions_nsc"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_nsc', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_nsc && <ErrorLabel message={errors.submissions_nsc?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_nsc"
                                                                        name="submissions_document_nsc"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_nsc', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_nsc && <ErrorLabel message={errors.submissions_document_nsc?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_nsc"
                                                                        disabled={true}
                                                                        name="submissions_pending_nsc"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_nsc', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_nsc"
                                                                        name="submissions_approved_nsc"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_nsc', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_nsc"
                                                                        disabled={true}
                                                                        name="submissions_status_nsc"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_nsc', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Repayment of Housing Loan (Principal Amount)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_repayment_of_housing_loan"
                                                                        name="actual_repayment_of_housing_loan"
                                                                        disabled={true}
                                                                        placeholder="-"
                                                                        {...register('actual_repayment_of_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_repayment_of_housing_loan"
                                                                        name="submissions_repayment_of_housing_loan"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_repayment_of_housing_loan', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_repayment_of_housing_loan && <ErrorLabel message={errors.submissions_repayment_of_housing_loan?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_repayment_of_housing_loan"
                                                                        name="submissions_document_repayment_of_housing_loan"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_repayment_of_housing_loan', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_repayment_of_housing_loan && <ErrorLabel message={errors.submissions_document_repayment_of_housing_loan?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_repayment_of_housing_loan"
                                                                        disabled={true}
                                                                        name="submissions_pending_repayment_of_housing_loan"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_repayment_of_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_repayment_of_housing_loan"
                                                                        name="submissions_approved_repayment_of_housing_loan"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_repayment_of_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_repayment_of_housing_loan"
                                                                        disabled={true}
                                                                        name="submissions_status_repayment_of_housing_loan"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_repayment_of_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Tuition Fees for Children (Max for 2 children)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_tuition_fees_for_children"
                                                                        name="actual_tuition_fees_for_children"
                                                                        disabled={true}
                                                                        placeholder="-"
                                                                        {...register('actual_tuition_fees_for_children', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_tuition_fees_for_children"
                                                                        name="submissions_tuition_fees_for_children"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_tuition_fees_for_children', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_tuition_fees_for_children && <ErrorLabel message={errors.submissions_tuition_fees_for_children?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_tuition_fees_for_children"
                                                                        name="submissions_document_tuition_fees_for_children"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_tuition_fees_for_children', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_tuition_fees_for_children && <ErrorLabel message={errors.submissions_document_tuition_fees_for_children?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_tuition_fees_for_children"
                                                                        disabled={true}
                                                                        name="submissions_pending_tuition_fees_for_children"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_tuition_fees_for_children', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_tuition_fees_for_children"
                                                                        name="submissions_approved_tuition_fees_for_children"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_tuition_fees_for_children', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_tuition_fees_for_children"
                                                                        disabled={true}
                                                                        name="submissions_status_tuition_fees_for_children"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_tuition_fees_for_children', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">5 year Fixed Deposits (with specified banks)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_five_year_fd"
                                                                        name="actual_five_year_fd"
                                                                        disabled={true}
                                                                        placeholder="-"
                                                                        {...register('actual_five_year_fd', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_five_year_fd"
                                                                        name="submissions_five_year_fd"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_five_year_fd', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_five_year_fd && <ErrorLabel message={errors.submissions_five_year_fd?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_five_year_fd"
                                                                        name="submissions_document_five_year_fd"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_five_year_fd', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_five_year_fd && <ErrorLabel message={errors.submissions_document_five_year_fd?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_five_year_fd"
                                                                        disabled={true}
                                                                        name="submissions_pending_five_year_fd"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_five_year_fd', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approval_five_year_fd"
                                                                        name="submissions_approval_five_year_fd"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approval_five_year_fd', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_five_year_fd"
                                                                        disabled={true}
                                                                        name="submissions_status_five_year_fd"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_five_year_fd', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Others&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_others"
                                                                        name="actual_others"
                                                                        disabled={true}
                                                                        placeholder="-"
                                                                        {...register('actual_others', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_others"
                                                                        name="submissions_others"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_others', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_others && <ErrorLabel message={errors.submissions_others?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_others"
                                                                        name="submissions_document_others"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_others', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_others && <ErrorLabel message={errors.submissions_document_others?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_others"
                                                                        disabled={true}
                                                                        name="submissions_pending_others"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_others', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_others"
                                                                        name="submissions_approved_others"
                                                                        disabled={true}
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_others', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_others"
                                                                        disabled={true}
                                                                        name="submissions_status_others"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_others', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Grand Total</label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_grand_total"
                                                                        name="actual_grand_total"
                                                                        disabled={true}
                                                                        type="number"
                                                                        placeholder="-"
                                                                        readOnly={true}
                                                                        {...register('actual_grand_total', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_grand_total"
                                                                        name="submissions_grand_total"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        readOnly={true}
                                                                        {...register('submissions_grand_total', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_grand_total"
                                                                        name="submissions_document_grand_total"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        readOnly={true}
                                                                        {...register('submissions_document_grand_total', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_grand_total"
                                                                        disabled={true}
                                                                        name="submissions_pending_grand_total"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        readOnly={true}
                                                                        {...register('submissions_pending_grand_total', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_grand_total"
                                                                        name="submissions_approved_grand_total"
                                                                        disabled={true}
                                                                        type="number"
                                                                        placeholder="0"
                                                                        readOnly={true}
                                                                        {...register('submissions_approved_grand_total', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            selectTaxRegime != null && (selectTaxRegime === "OLD" || selectTaxRegime === "NEW") ?
                                                <>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;EPF Contribution
                                                        </h4>
                                                    </div>
                                                    {
                                                        selectTaxRegime != null && selectTaxRegime === "NEW" ?
                                                            <>
                                                                <div className="col-12 col-md-2 col-lg-2">
                                                                    <label className="label-control">Type of Investment</label>
                                                                </div>
                                                                <div className="col-12 col-md-2 col-lg-2">
                                                                    <label className="label-control">Actual Declaration</label>
                                                                </div>
                                                                <div className="col-12 col-md-2 col-lg-2">
                                                                    <label className="label-control">Final Declaration</label>
                                                                </div>
                                                                <div className="col-12 col-md-2 col-lg-2">
                                                                    <label className="label-control">Doc Submitted</label>
                                                                </div>
                                                                <div className="col-12 col-md-1 col-lg-1">
                                                                    <label className="label-control">Pending Amt.</label>
                                                                </div>
                                                                <div className="col-12 col-md-2 col-lg-2">
                                                                    <label className="label-control">Approved Amt</label>
                                                                </div>
                                                                <div className="col-12 col-md-1 col-lg-1">
                                                                    <label className="label-control">Status</label>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">PF Contribution&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <Controller
                                                                        name="actual_pf_contribution"
                                                                        disabled={true}
                                                                        control={control}
                                                                        render={({ field }) =>
                                                                            <DropDownListComponent
                                                                                {...field}
                                                                                dataSource={pfContribution}
                                                                                fields={{ text: 'title', value: 'value' }}
                                                                                allowFiltering={false}
                                                                                placeholder="Select PF Contribution"
                                                                                enabled={false}
                                                                                value={field.value?.title}
                                                                                change={(event) => {
                                                                                    if (event.itemData != null) {
                                                                                        field.onChange(event.itemData?.value);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            selectTaxRegime != null && selectTaxRegime === "OLD" ?
                                                <>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Details of Investment Under Section 80 CCD (1B)&nbsp;
                                                            <Tippy content={tooltipSection80CCD}>
                                                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                            </Tippy>
                                                        </h4>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">National Pension Scheme&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border border-0 bg-white"
                                                                        id="actual_nps"
                                                                        name="actual_nps"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_nps', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_nps"
                                                                        name="submissions_nps"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_nps', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_nps && <ErrorLabel message={errors.submissions_nps?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_nps"
                                                                        name="submissions_document_nps"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_nps', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_nps && <ErrorLabel message={errors.submissions_document_nps?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_nps"
                                                                        disabled={true}
                                                                        name="submissions_pending_nps"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_nps', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_nps"
                                                                        name="submissions_approved_nps"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_nps', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border border-0 bg-white"
                                                                        id="submissions_status_nps"
                                                                        disabled={true}
                                                                        name="submissions_status_nps"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_nps', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Details of Investment Under Section 80 D&nbsp;
                                                            <Tippy content={tooltipSection80D}>
                                                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                            </Tippy>
                                                        </h4>

                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Family (Self, Spouse & Children)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_family_mediclaim"
                                                                        name="actual_family_mediclaim"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_family_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_family_mediclaim"
                                                                        name="submissions_family_mediclaim"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_family_mediclaim', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_family_mediclaim && <ErrorLabel message={errors.submissions_family_mediclaim?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_family_mediclaim"
                                                                        name="submissions_document_family_mediclaim"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_family_mediclaim', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_family_mediclaim && <ErrorLabel message={errors.submissions_document_family_mediclaim?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_family_mediclaim"
                                                                        disabled={true}
                                                                        name="submissions_pending_family_mediclaim"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_family_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_family_mediclaim"
                                                                        name="submissions_approved_family_mediclaim"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_family_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_family_mediclaim"
                                                                        disabled={true}
                                                                        name="submissions_status_family_mediclaim"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_family_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Parents&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_parents_mediclaim"
                                                                        name="actual_parents_mediclaim"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_parents_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_parents_mediclaim"
                                                                        name="submissions_parents_mediclaim"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_parents_mediclaim', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_parents_mediclaim && <ErrorLabel message={errors.submissions_parents_mediclaim?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_parents_mediclaim"
                                                                        name="submissions_document_parents_mediclaim"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_parents_mediclaim', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_parents_mediclaim && <ErrorLabel message={errors.submissions_document_parents_mediclaim?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_parents_mediclaim"
                                                                        disabled={true}
                                                                        name="submissions_pending_parents_mediclaim"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_parents_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_parents_mediclaim"
                                                                        name="submissions_approved_parents_mediclaim"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_parents_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_parents_mediclaim"
                                                                        disabled={true}
                                                                        name="submissions_status_parents_mediclaim"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_parents_mediclaim', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Preventive Health Check up&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_preventive_health_check_up"
                                                                        name="actual_preventive_health_check_up"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_preventive_health_check_up', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_preventive_health_check_up"
                                                                        name="submissions_preventive_health_check_up"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_preventive_health_check_up', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_preventive_health_check_up && <ErrorLabel message={errors.submissions_preventive_health_check_up?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_preventive_health_check_up"
                                                                        name="submissions_document_preventive_health_check_up"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_preventive_health_check_up', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_preventive_health_check_up && <ErrorLabel message={errors.submissions_document_preventive_health_check_up?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_preventive_health_check_up"
                                                                        disabled={true}
                                                                        name="submissions_pending_preventive_health_check_up"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_preventive_health_check_up', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_preventive_health_check_up"
                                                                        name="submissions_approved_preventive_health_check_up"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_preventive_health_check_up', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_preventive_health_check_up"
                                                                        disabled={true}
                                                                        name="submissions_status_preventive_health_check_up"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_preventive_health_check_up', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Details of Investment Under Section 80 E
                                                        </h4>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Interest Paid on Loan for Self-Education&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_interest_paid_on_loan_for_self_education"
                                                                        name="actual_interest_paid_on_loan_for_self_education"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_interest_paid_on_loan_for_self_education', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_interest_paid_on_loan_for_self_education"
                                                                        name="submissions_interest_paid_on_loan_for_self_education"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_interest_paid_on_loan_for_self_education', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_interest_paid_on_loan_for_self_education && <ErrorLabel message={errors.submissions_interest_paid_on_loan_for_self_education?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_interest_paid_on_loan_for_self_education"
                                                                        name="submissions_document_interest_paid_on_loan_for_self_education"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_interest_paid_on_loan_for_self_education', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_interest_paid_on_loan_for_self_education && <ErrorLabel message={errors.submissions_document_interest_paid_on_loan_for_self_education?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_interest_paid_on_loan_for_self_education"
                                                                        disabled={true}
                                                                        name="submissions_pending_interest_paid_on_loan_for_self_education"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_interest_paid_on_loan_for_self_education', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_interest_paid_on_loan_for_self_education"
                                                                        name="submissions_approved_interest_paid_on_loan_for_self_education"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_interest_paid_on_loan_for_self_education', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_interest_paid_on_loan_for_self_education"
                                                                        disabled={true}
                                                                        name="submissions_status_interest_paid_on_loan_for_self_education"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_interest_paid_on_loan_for_self_education', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Other Declarations&nbsp;
                                                            <Tippy content={tooltipOthers}>
                                                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                            </Tippy>
                                                        </h4>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Interest Paid on Housing Loan&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_interest_paid_on_housing_loan"
                                                                        name="actual_interest_paid_on_housing_loan"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_interest_paid_on_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_interest_paid_on_housing_loan"
                                                                        name="submissions_interest_paid_on_housing_loan"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_interest_paid_on_housing_loan', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_interest_paid_on_housing_loan && <ErrorLabel message={errors.submissions_interest_paid_on_housing_loan?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_interest_paid_on_housing_loan"
                                                                        name="submissions_document_interest_paid_on_housing_loan"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_interest_paid_on_housing_loan', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_interest_paid_on_housing_loan && <ErrorLabel message={errors.submissions_document_interest_paid_on_housing_loan?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_interest_paid_on_housing_loan"
                                                                        disabled={true}
                                                                        name="submissions_pending_interest_paid_on_housing_loan"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_interest_paid_on_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_interest_paid_on_housing_loan"
                                                                        name="submissions_approved_interest_paid_on_housing_loan"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_interest_paid_on_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_interest_paid_on_housing_loan"
                                                                        disabled={true}
                                                                        name="submissions_status_interest_paid_on_housing_loan"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_interest_paid_on_housing_loan', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Loss from House Property&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_loss_from_house_property"
                                                                        name="actual_loss_from_house_property"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_loss_from_house_property', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_loss_from_house_property"
                                                                        name="submissions_loss_from_house_property"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_loss_from_house_property', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_loss_from_house_property && <ErrorLabel message={errors.submissions_loss_from_house_property?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_loss_from_house_property"
                                                                        name="submissions_document_loss_from_house_property"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_loss_from_house_property', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_loss_from_house_property && <ErrorLabel message={errors.submissions_document_loss_from_house_property?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_loss_from_house_property"
                                                                        disabled={true}
                                                                        name="submissions_pending_loss_from_house_property"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_loss_from_house_property', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_loss_from_house_property"
                                                                        name="submissions_approved_loss_from_house_property"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_loss_from_house_property', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_loss_from_house_property"
                                                                        disabled={true}
                                                                        name="submissions_status_loss_from_house_property"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_loss_from_house_property', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <label className="label-control">Rent Paid (HRA) Annually&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="actual_hra"
                                                                        name="actual_hra"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('actual_hra', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_hra"
                                                                        name="submissions_hra"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_hra', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_hra && <ErrorLabel message={errors.submissions_hra?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border bg-white pl-1"
                                                                        id="submissions_document_hra"
                                                                        name="submissions_document_hra"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('submissions_document_hra', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.submissions_document_hra && <ErrorLabel message={errors.submissions_document_hra?.message} />}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_pending_hra"
                                                                        disabled={true}
                                                                        name="submissions_pending_hra"
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_pending_hra', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_approved_hra"
                                                                        name="submissions_approved_hra"
                                                                        disabled={true}
                                                                        type="text"
                                                                        placeholder="0"
                                                                        {...register('submissions_approved_hra', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1 col-lg-1">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control border-0 bg-white"
                                                                        id="submissions_status_hra"
                                                                        disabled={true}
                                                                        name="submissions_status_hra"
                                                                        type="text"
                                                                        placeholder="-"
                                                                        {...register('submissions_status_hra', {
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                <FiUser size={16} />&nbsp;Flexible Plan Details&nbsp;
                                                <Tippy content={tooltipOthers}>
                                                    <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                </Tippy>
                                            </h4>

                                            <div className="row mt-1">

                                                <div className="col-12 col-md-2 col-lg-2">
                                                    <label className="label-control">LTA&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-2">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control border-0 bg-white"
                                                            id="actual_lta"
                                                            name="actual_lta"
                                                            disabled={true}
                                                            type="text"
                                                            placeholder="-"
                                                            {...register('actual_lta', {
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-2">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control border bg-white pl-1"
                                                            id="submissions_lta"
                                                            name="submissions_lta"
                                                            type="text"
                                                            placeholder="Enter 0 if Nothing to Declare"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            {...register('submissions_lta', {
                                                                required: 'Please Enter LTA'
                                                            })}
                                                        />
                                                        {errors.submissions_lta && <ErrorLabel message={errors.submissions_lta?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-2">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control border bg-white pl-1"
                                                            id="submissions_document_lta"
                                                            name="submissions_document_lta"
                                                            type="text"
                                                            placeholder="Enter 0 if Nothing to Declare"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            {...register('submissions_document_lta', {
                                                                required: 'Please Enter LTA'
                                                            })}
                                                        />
                                                        {errors.submissions_document_lta && <ErrorLabel message={errors.submissions_document_lta?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-1 col-lg-1">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control border-0 bg-white"
                                                            id="submissions_pending_lta"
                                                            disabled={true}
                                                            name="submissions_pending_lta"
                                                            type="text"
                                                            placeholder="0"
                                                            {...register('submissions_pending_lta', {
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-2">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control border-0 bg-white"
                                                            id="submissions_approved_lta"
                                                            name="submissions_approved_lta"
                                                            placeholder="0"
                                                            disabled={true}
                                                            type="text"
                                                            {...register('submissions_approved_lta', {
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-1 col-lg-1">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control border-0 bg-white"
                                                            id="submissions_status_lta"
                                                            placeholder="-"
                                                            disabled={true}
                                                            name="submissions_status_lta"
                                                            type="text"
                                                            {...register('submissions_status_lta', {
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black" />
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-3 col-lg-3">
                                                            <label className="label-control">Remarks</label>
                                                        </div>
                                                        <div className="col-12 col-md-3 col-lg-3">
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control "
                                                                    id="emp_remark"
                                                                    name="emp_remark"
                                                                    type="text"
                                                                    maxLength={200}
                                                                    placeholder="Enter Remarks"
                                                                    {...register('emp_remark', {
                                                                        maxLength: {
                                                                            value: 200,
                                                                            message: "Please enter upto 200 characters"
                                                                        }
                                                                    })}
                                                                />
                                                                {errors.emp_remark && <ErrorLabel message={errors.emp_remark?.message} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        name="terms_submission_add"
                                                        id="terms_submission_add"
                                                        {...register('terms_submission_add', { required: "Please acknowledge the terms" })}
                                                    />
                                                    <label className="custom-control-label text-bold-600" htmlFor="terms_submission_add">
                                                        Items with “Pending Payment” document proof to be submitted at the earliest. In absence of the same, respective TAX adjustment will be auto deducted from March Salary.
                                                    </label>
                                                    {errors.terms_submission_add && (
                                                        <div className="text-danger">
                                                            {errors.terms_submission_add?.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions border-0">
                                    <button
                                        id="add-submission"
                                        className="btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0"
                                        type="submit"
                                    >
                                        <div className="d-flex align-items-center">
                                            Submit
                                            <span id="add-submission-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </div>
                                    </button>
                                    <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                        onClick={(event) => {
                                            reset();
                                            props.handleClose();
                                            event.preventDefault();
                                        }}
                                    > Cancel</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )

}

const mapStateToProps = (state) => {
    var ItSubmissionDetail = null;
    var ItSubmissionAdd = null;
    var ItSubmissionDownload = null;

    if (
        state.ItSubmissionAddReducer != null &&
        Object.keys(state.ItSubmissionAddReducer).length > 0 &&
        state.ItSubmissionAddReducer.constructor === Object
    ) {
        switch (state.ItSubmissionAddReducer.tag) {
            case ACTION_IT_SUBMISSION_ADD_SUCCESS:
                ItSubmissionAdd = Object.assign({}, state.ItSubmissionAddReducer.data);
                delete state.ItSubmissionAddReducer;
                return { ItSubmissionAdd };
            case ACTION_SUBMISSION_DOWNLOAD_SUCCESS:
                ItSubmissionDownload = Object.assign({}, state.ItSubmissionAddReducer.data);
                delete state.ItSubmissionAddReducer;
                return { ItSubmissionDownload };
            case ACTION_SUBMISSION_DETAIL_SUCCESS:
                ItSubmissionDetail = Object.assign({}, state.ItSubmissionAddReducer.data);
                delete state.ItSubmissionAddReducer;
                return { ItSubmissionDetail };
            default:
        }
    }

    return {
        ItSubmissionAdd,
        ItSubmissionDetail,
        ItSubmissionDownload
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItSubmissionAdd, actionItSubmissionDownload, actionItSubmissionDetail }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AddSubmission))