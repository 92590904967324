import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_TRAINING_TYPE_SUCCESS, ACTION_TRAINING_DATE_SUCCESS, API_PARTICIPATION_REPORT_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const TrainingParticipationReducer = createReducer(INITIAL_STATE, {
    [ACTION_TRAINING_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TRAINING_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TRAINING_DATE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TRAINING_DATE_SUCCESS,
                loading: false,
            }
        );
    },
    [API_PARTICIPATION_REPORT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: API_PARTICIPATION_REPORT_SUCCESS,
                loading: false,
            }
        );
    }
}
)
export default TrainingParticipationReducer