import React, { useState, useEffect } from "react";
import ScreenTitle from "../../../../../../component/screen_title";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { ACTION_RISK_MANAGEMENT_VIEW_SUCCESS, actionViewRiskManagmentRequest } from "../../action";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from '../../../../../../constants';
import _ from 'lodash';
import moment from "moment";
import RequestHistory from '../../../../../../component/widgets/request_history';
import ChangeStatus from "./change_status";
import DocumentIframe from "../../../../../../component/popups/document_iframe";
import { downloadFile } from "../../../../../../utils";

const RiskManagementApprovalView = (props) => {
    const [riskManagementApprovalDetail, setRiskManagementDetail] = useState(null);
    const [requestHistory, setRequestHistory] = useState(null);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        props.actionViewRiskManagmentRequest({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onRequestViewUpdate = () => {
        const { RequestView } = props;
        var RequestViewResponse = Object.assign({}, RequestView);
        if (RequestViewResponse.result === true) {
            setRiskManagementDetail(RequestViewResponse.response);
            if (RequestViewResponse.response.request_risk_status.length > 0) {
                setRequestHistory(RequestViewResponse.response.request_risk_status);
            }
        } else {
            switch (RequestViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestViewUpdate, [props.RequestView]);

    return (
        <>
            <ScreenTitle title="View Risk Approval Details" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Client Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.client_type != null ?
                                            _.capitalize(riskManagementApprovalDetail?.client_type) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Client Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.client_name != null ?
                                            riskManagementApprovalDetail?.client_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Location Address</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.address != null ?
                                            riskManagementApprovalDetail?.address : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Occupancy</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.occupancy != null ?
                                            riskManagementApprovalDetail?.occupancy : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Sum Insured</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.sum_insured != null ?
                                            riskManagementApprovalDetail?.sum_insured : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Type of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.inspection_type != null ?
                                            riskManagementApprovalDetail?.inspection_type : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            riskManagementApprovalDetail != null && riskManagementApprovalDetail.inspection_type != null && riskManagementApprovalDetail.inspection_type === "Others" ?
                                <div className="row pt-2">
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                                    </div>
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="font-medium-1 text-bold-700 mb-0">
                                            {
                                                riskManagementApprovalDetail != null && riskManagementApprovalDetail.description != null ?
                                                    riskManagementApprovalDetail.description : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">RM Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail.rm_first_name != null ?
                                            `${riskManagementApprovalDetail.rm_first_name} ${riskManagementApprovalDetail.rm_last_name || ""}` : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">RM Location</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail.rm_city_name != null ?
                                            riskManagementApprovalDetail.rm_city_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Start Date of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.inspection_date != null ?
                                            moment(riskManagementApprovalDetail?.inspection_date).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">End Date of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail.inspection_end_date != null ?
                                            moment(riskManagementApprovalDetail.inspection_end_date).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.remarks != null ?
                                            riskManagementApprovalDetail?.remarks : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3">
                                <h4 className="text-uppercase font-small-3 mb-0">Document</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3">
                                {
                                    (riskManagementApprovalDetail != null && riskManagementApprovalDetail.risk_management_document != null) ?
                                        // <div>
                                        //     <a target="_blank" href={riskManagementApprovalDetail.risk_management_document} download="Meeting Document">View Document</a>
                                        // </div>
                                        <>
                                            <a className="primary"
                                                onClick={() => {
                                                    if (riskManagementApprovalDetail.risk_management_document?.split('.').pop() == 'pdf' ||
                                                        riskManagementApprovalDetail.risk_management_document?.split('.').pop() == 'PDF' ||
                                                        riskManagementApprovalDetail.risk_management_document?.split('.').pop() == 'png' ||
                                                        riskManagementApprovalDetail.risk_management_document?.split('.').pop() == 'jpg' ||
                                                        riskManagementApprovalDetail.risk_management_document?.split('.').pop() == 'jpeg' ||
                                                        riskManagementApprovalDetail.risk_management_document?.split('.').pop() == 'gif'
                                                    ) {
                                                        setSelectedDocumentIndex(riskManagementApprovalDetail.risk_management_document)
                                                        setShowDocumentDetails(true);
                                                        setExtension(riskManagementApprovalDetail.risk_management_document?.split('.').pop())
                                                        setDocumentDetails(riskManagementApprovalDetail)
                                                    } else {
                                                        downloadFile(riskManagementApprovalDetail.risk_management_document);
                                                    }
                                                }}
                                            >
                                                View Document
                                            </a>
                                            <a id="downloadLink" className="d-none"></a>
                                        </>
                                        :
                                        <h4 className="font-medium-1 text-bold-700 mb-0">-</h4>
                                }
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Created Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.created_at != null ?
                                            moment(riskManagementApprovalDetail?.created_at).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approved By</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.approved_by != null ?
                                            riskManagementApprovalDetail?.approved_by : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Assign Engineer</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.assign_engineer != null ?
                                            riskManagementApprovalDetail?.assign_engineer : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Actual Date of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.actual_date_inspection != null ?
                                            moment(riskManagementApprovalDetail?.actual_date_inspection).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Financial Year</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskManagementApprovalDetail != null && riskManagementApprovalDetail?.financial_year != null ?
                                            riskManagementApprovalDetail?.financial_year : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 float-none">
                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1" onClick={(event) => {
                                event.preventDefault();
                                props.history.goBack();

                            }}
                            >Back</button>
                            {/* <button
                                id="your_action"
                                className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                onClick={() => {
                                    setShowChangeStatus(true);
                                }}
                            >Your Action</button> */}
                        </div>
                    </div>
                </div>
                <ChangeStatus
                    details={riskManagementApprovalDetail}
                    show={showChangeStatus}
                    handleClose={(updated) => {
                        setShowChangeStatus(false);
                        if (updated === true) {
                            props.history.goBack();
                            setRequestHistory(null);
                            props.actionViewRiskManagmentRequest({ id: props.location.state.id });
                        }

                    }}
                />
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var RequestView = null;

    if (
        state.viewRiskManagementReducer != null &&
        Object.keys(state.viewRiskManagementReducer).length > 0 &&
        state.viewRiskManagementReducer.constructor === Object
    ) {
        switch (state.viewRiskManagementReducer.tag) {

            case ACTION_RISK_MANAGEMENT_VIEW_SUCCESS:
                RequestView = Object.assign({}, state.viewRiskManagementReducer.data);
                delete state.viewRiskManagementReducer;
                return { RequestView };
            default:
        }
    }
    return {
        RequestView,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewRiskManagmentRequest
    }, dispatch)
};


export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementApprovalView))