
import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REINSURANCE_EDIT, API_REINSURANCE_VIEW, API_MASTER_COUNTRY, API_CLIENT_TYPE } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_API_REINSURANCE_EDIT_LOADING = 'ACTION_API_REINSURANCE_EDIT_LOADING';
export const ACTION_API_REINSURANCE_EDIT_SUCCESS = 'ACTION_API_REINSURANCE_EDIT_SUCCESS';

export const ACTION_API_REINSURANCE_VIEW_LOADING = 'ACTION_API_REINSURANCE_VIEW_LOADING';
export const ACTION_API_REINSURANCE_VIEW_SUCCESS = 'ACTION_API_REINSURANCE_VIEW_SUCCESS';

export const ACTION_REINSURANCE_MASTER_COUNTRY_LOADING = 'ACTION_REINSURANCE_MASTER_COUNTRY_LOADING';
export const ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS = 'ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS';

export const ACTION_REINSURANCE_CLIENT_TYPE_LOADING = 'ACTION_REINSURANCE_CLIENT_TYPE_LOADING';
export const ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS = 'ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS';

export function actionReinsuranceClientEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE_EDIT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_REINSURANCE_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_REINSURANCE_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionReinsuranceClientView(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_REINSURANCE_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_REINSURANCE_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionCountry() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_COUNTRY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_MASTER_COUNTRY_LOADING, { loading: false, data }));
        });
}

export function actionClientType() {
    return (dispatch, getState) =>
        Api.get(API_CLIENT_TYPE,).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}