import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../../component/screen_title';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from '../../../../../../../component/form/error_label';
import { ERROR, UNAUTHORIZED } from "../../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../../utils/preferences';
import { FiPlus, FiPhone, FiEdit } from 'react-icons/fi';
import ContactAdd from '../../contacts/contact_add';
import { actionMasterBranchStates, ACTION_MASTER_BRANCH_STATES_SUCCESS } from "./action";
import { actionMasterBranchCities, ACTION_MASTER_BRANCH_CITIES_SUCCESS } from "./action";
import { actionMasterAddInsurerBranch, ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS } from "./action";
import Swal from 'sweetalert2';

const BranchMasterAdd = (props) => {

    const { handleSubmit, control, formState: { errors }, register, setValue } = useForm({
        defaultValues: {
            status: "ACTIVE"
        }
    });
    const [masterState, setMasterState] = useState([]);
    const [masterCity, setMasterCity] = useState([]);
    const [insurerDetails, setInsurerDetails] = useState(null);
    const [contactDetails, setContactDetails] = useState([]);
    const [showContactDetails, setShowContactDetails] = useState(false);

    const initialRender = () => {
        if (props?.location?.state?.detail != null) {
            setInsurerDetails(props?.location?.state?.detail);
        }
        props.actionMasterBranchStates();
    }
    useEffect(initialRender, []);

    const onMasterBranchStatesUpdate = () => {
        const { MasterBranchStates } = props
        if (MasterBranchStates != null) {
            var MasterBranchStatesResponse = Object.assign({}, MasterBranchStates);
            if (MasterBranchStatesResponse.result === true) {
                setMasterState(MasterBranchStatesResponse.response);
            } else {
                switch (MasterBranchStatesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterState([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBranchStatesUpdate, [props.MasterBranchStates])

    const onMasterBranchCitiesUpdate = () => {
        const { MasterBranchCities } = props
        if (MasterBranchCities != null) {
            var MasterBranchCitiesResponse = Object.assign({}, MasterBranchCities);
            if (MasterBranchCitiesResponse.result === true) {
                setMasterCity(MasterBranchCitiesResponse.response);
            } else {
                switch (MasterBranchCitiesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterCity([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBranchCitiesUpdate, [props.MasterBranchCities])

    const onMasterAddInsurerBranchUpdate = () => {
        const { MasterAddInsurerBranch } = props
        if (MasterAddInsurerBranch != null) {
            var MasterAddInsurerBranchResponse = Object.assign({}, MasterAddInsurerBranch);
            $("#save_insurer_branch").prop("disabled", false);
            if (MasterAddInsurerBranchResponse.result === true) {
                $("#insurer-branch-form-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Insurer Branch Created Successfully");
                props.history.goBack();
            } else {
                $("#insurer-branch-form-loader").addClass("hidden");
                switch (MasterAddInsurerBranchResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterAddInsurerBranchUpdate, [props.MasterAddInsurerBranch])

    const onSubmit = (data) => {

        if (contactDetails.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Please Add Contact',
                icon: 'error'
            }).then(success => {

            })
        } else {
            data.insurer_id = props?.location?.state?.insurer_id;
            data.contacts = contactDetails;
            $("#insurer-branch-form-loader").removeClass("hidden");
            $("#save_insurer_branch").prop("disabled", true);
            props.actionMasterAddInsurerBranch(data);
        }
    }

    return (
        <>
            <ScreenTitle title="Add Branch" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="type"
                                                                name="type"
                                                                dataSource={[
                                                                    {
                                                                        value: 'Head Office',
                                                                        title: 'Head Office'
                                                                    },
                                                                    {
                                                                        value: 'Branch Office',
                                                                        title: 'Branch Office'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Branch Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch Type is required'
                                                        }}
                                                    />

                                                    {errors.type && <ErrorLabel message={errors.type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Branch Code</label>
                                                    <input
                                                        className="form-control"
                                                        id="branch_code"
                                                        name="branch_code"
                                                        maxLength={300}
                                                        placeholder="Branch Code"
                                                        {...register('branch_code')}
                                                    />
                                                    {errors.branch_code && <ErrorLabel message={errors.branch_code?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Branch Short Name</label>
                                                    <input
                                                        className="form-control"
                                                        id="short_name"
                                                        name="short_name"
                                                        maxLength={300}
                                                        placeholder="Branch Short Name"
                                                        {...register('short_name')}
                                                    />
                                                    {errors.short_name && <ErrorLabel message={errors.short_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch State&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="state_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="state_id"
                                                                name="state_id"
                                                                dataSource={masterState}
                                                                allowFiltering={true}
                                                                placeholder="Select Branch State"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        props.actionMasterBranchCities({ state_ids: [event.itemData.id] })
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch State is required'
                                                        }}
                                                    />

                                                    {errors.state_id && <ErrorLabel message={errors.state_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch City&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="city_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="city_id"
                                                                name="city_id"
                                                                dataSource={masterCity}
                                                                allowFiltering={true}
                                                                placeholder="Select Branch City"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch City is required'
                                                        }}
                                                    />

                                                    {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Branch Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        maxLength={300}
                                                        placeholder="Branch Address"
                                                        {...register('address', {
                                                            required: "Please Enter Branch Address"
                                                        })}
                                                    />
                                                    {errors.address && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        value: 'ACTIVE',
                                                                        text: 'ACTIVE'

                                                                    },
                                                                    {
                                                                        value: 'INACTIVE',
                                                                        text: 'INACTIVE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setShowContactDetails(true)
                                                        }}
                                                    >
                                                        {
                                                            contactDetails != null && contactDetails.length > 0 ?
                                                                < FiEdit
                                                                    cursor="pointer"
                                                                    size={20}
                                                                />
                                                                :
                                                                < FiPlus
                                                                    cursor="pointer"
                                                                    size={20}
                                                                />
                                                        }
                                                    </button>
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                {
                                                    contactDetails != null && contactDetails.length > 0 ?
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Contact Name</th>
                                                                    <th>Mobile Number</th>
                                                                    <th>Contact Email</th>
                                                                    <th>Contact Designation</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    contactDetails.map((contact, indexContact) => (
                                                                        <tr key={contact.id}>
                                                                            <td>{contact.name}</td>
                                                                            <td>{contact.mobile}</td>
                                                                            <td>{contact.email}</td>
                                                                            <td>{contact.designation}</td>
                                                                            <td>{contact.status}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <></>
                                                }
                                                <ErrorLabel message={contactDetails.length === 0 ? 'No contacts found' : ''} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_insurer_branch"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            branch_type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="insurer-branch-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactAdd
                show={showContactDetails}
                data={Object.assign({}, contactDetails[0])}
                handleClose={(newContact) => {
                    if (newContact != null) {
                        var contacts = [];
                        var newContactDetails = {};
                        newContactDetails.name = newContact.name;
                        newContactDetails.mobile = newContact.mobile;
                        newContactDetails.designation = newContact.designation;
                        newContactDetails.email = newContact.email;
                        newContactDetails.status = newContact.status;
                        contacts.push(newContactDetails);
                        setContactDetails(contacts);
                    }
                    setShowContactDetails(false)
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterBranchStates = null;
    var MasterBranchCities = null;
    var MasterAddInsurerBranch = null;

    if (
        state.MasterAddInsurerBranchReducer != null &&
        Object.keys(state.MasterAddInsurerBranchReducer).length > 0 &&
        state.MasterAddInsurerBranchReducer.constructor === Object
    ) {
        switch (state.MasterAddInsurerBranchReducer.tag) {
            case ACTION_MASTER_BRANCH_STATES_SUCCESS:
                MasterBranchStates = Object.assign({}, state.MasterAddInsurerBranchReducer.data);
                delete state.MasterAddInsurerBranchReducer;
                return { MasterBranchStates };
            case ACTION_MASTER_BRANCH_CITIES_SUCCESS:
                MasterBranchCities = Object.assign({}, state.MasterAddInsurerBranchReducer.data);
                delete state.MasterAddInsurerBranchReducer;
                return { MasterBranchCities };
            case ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS:
                MasterAddInsurerBranch = Object.assign({}, state.MasterAddInsurerBranchReducer.data);
                delete state.MasterAddInsurerBranchReducer;
                return { MasterAddInsurerBranch };
            default:

        }
    }
    return {
        MasterBranchStates,
        MasterBranchCities,
        MasterAddInsurerBranch
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterBranchStates, actionMasterBranchCities, actionMasterAddInsurerBranch }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(BranchMasterAdd))