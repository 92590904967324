import { API_WELLNESS_REQUEST_LIST } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { PRINT } from '../../../../../../utils';

export const ACTION_ADD_WELLNESS_LIST_LOADING = 'ACTION_ADD_WELLNESS_LIST_LOADING';
export const ACTION_ADD_WELLNESS_LIST_SUCCESS = 'ACTION_ADD_WELLNESS_LIST_SUCCESS';

export function actionAddWellnessList(params) {
    return (dispatch, getState) =>
        Api.post(API_WELLNESS_REQUEST_LIST, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_ADD_WELLNESS_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ADD_WELLNESS_LIST_LOADING, { loading: false, data }));
        });
}