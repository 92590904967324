import React from 'react';
import { Route, Switch } from "react-router";
import AppSettingList from './list';
import AppSettingAdd from './add';
import AppSettingEdit from './edit';

const AppSettingsRoute = (props) => {
    const { match } = props

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`} component={AppSettingAdd} />
                <Route path={`${match.url}/edit`} component={AppSettingEdit} />
                <Route exact path={`${match.url}`} component={AppSettingList} />
            </Switch>
        </>
    )
}
export default AppSettingsRoute