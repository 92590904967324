import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { convertAmount } from '../../../../../../../utils';

const PolicyDetails = (props) => {
    const initialRender = () => {

        if (props.show === true) {
            console.log("SERIES+" + JSON.stringify(props.PolicyDetails));
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    return (

        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Policy Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.policy_type != null ? props.policyListSelectedData.policy_type : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Reporting To</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.policy_rm_reporting_person_name != null ? props.policyListSelectedData.policy_rm_reporting_person_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">RM Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.rm_name != null ? props.policyListSelectedData.rm_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Customer Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.customer_name != null ? props.policyListSelectedData.customer_name.toUpperCase() : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Customer Group Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.company_group_name != null ? props.policyListSelectedData.company_group_name.toUpperCase() : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurance Company</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.insurance_company != null ? props.policyListSelectedData.insurance_company.toUpperCase() : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Retention RM</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.retention_name ? props.policyListSelectedData.retention_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Expiry Date</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData.policy_expiry_date != null ? props.policyListSelectedData.policy_expiry_date : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Sum Insured</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData?.sum_insured != null ?
                                        <span>&#8377;&nbsp;{convertAmount(props.policyListSelectedData?.sum_insured)}</span> : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Gross Premium</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData?.gross_premium != null ?
                                        <span>&#8377;&nbsp; {convertAmount(props.policyListSelectedData?.gross_premium)} </span> : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Brokerage Amount</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData?.brokerage_amount != null ?
                                        <span>&#8377;&nbsp; {convertAmount(props.policyListSelectedData?.brokerage_amount)}</span> : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Net premium</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {props.policyListSelectedData != null && props.policyListSelectedData?.net_premium != null ?
                                        <span>&#8377;&nbsp; {convertAmount(props.policyListSelectedData?.net_premium)}</span> : '-'}
                                </h4>
                            </div>
                        </div>
                        {
                            props?.policyListSelectedData != null && props?.policyListSelectedData?.endorsement_count != null && Number(props?.policyListSelectedData?.endorsement_count) > 0 ?
                                <div className="border-top px-0">
                                    <h4 className='mb-1 mt-1'>Endorsement Details</h4>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Endorsement Brokerage</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {props.policyListSelectedData != null && props.policyListSelectedData.endorsement_brokerage_amount != null ?
                                                    <span>&#8377;&nbsp; {convertAmount(props.policyListSelectedData.endorsement_brokerage_amount)}</span> : '-'}
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Endorsement Net Premium</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {props.policyListSelectedData != null && props.policyListSelectedData.endorsement_net_premium != null ?
                                                    <span>&#8377;&nbsp; {convertAmount(props.policyListSelectedData.endorsement_net_premium)}</span> : '-'}
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Endorsement Gross Premium</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {props.policyListSelectedData != null && props.policyListSelectedData.endorsement_gross_premium != null ?
                                                    <span>&#8377;&nbsp; {convertAmount(props.policyListSelectedData.endorsement_gross_premium)}</span> : '-'}
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Endorsement Count</h6>
                                            <h4 className="font-medium-1 text-bold-700 text-justify">
                                                {props.policyListSelectedData != null && props.policyListSelectedData.endorsement_count != null ? props.policyListSelectedData.endorsement_count : '-'}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.handleClose();
                                }}
                            >Close</button>
                        </div>
                    </div >
                </div>
            </div>
        </div>

    );
};

export default PolicyDetails