import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { UNAUTHORIZED } from "../../../../../../../constants";
import { actionInsurerViewTabPolicies, ACTION_INSURER_VIEW_TAB_POLICIES_SUCCESS } from "./action";
import Swal from "sweetalert2";
import ClientPolicyDetails from '../../../../../../../component/popups/policies';

const TabPolicies = (props) => { 

    const [type, setType] = useState('Active');
    const [totalCount, setTotalCount] = useState(0);
    const [showPolicyView, setShowPolicyView] = useState(false);
    const [policiesData, setPoliciesData] = useState([]);
    const [policyDetails, setPolicyDetails] = useState();

    const [policyColumn, setPolicyColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "policy_no", caption: "Policy No" },
        { dataField: "policy_type", caption: "Policy Type" },
        { dataField: "sum_insured", caption: "Sum Insured" },
        { dataField: "brokerage_amount", caption: "Brokerage Amount" },
        { dataField: "policy_expiry_date", caption: "Expiry Date" },
    ]);
    
    const initialRender = () => { 
        $("#progress-loader").removeClass("hidden");
        props.actionInsurerViewTabPolicies({
            insurer_id: props.insurerId,
            type: 'active'
        })
    }
    useEffect(initialRender,[props.tab]);

    const renderSumInsured = (cell) =>
    {
        return <div>{Number(cell.data.sum_insured).toFixed(2)}</div>;
    }

    const onInsurerViewTabPolicies = () => {

        const { TabPolicies } = props;

        if (TabPolicies != null) {
            var TabPoliciesResponse = Object.assign({}, TabPolicies);
            if (TabPoliciesResponse.result === true) {

                $("#progress-loader").addClass("hidden");
                setPolicyColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "policy_no", caption: "Policy No" },
                    { dataField: "policy_type", caption: "Policy Type" },
                    { dataField: "sum_insured", caption: "Sum Insured" },
                    { dataField: "brokerage_amount", caption: "Brokerage Amount" },
                    { dataField: "policy_expiry_date", caption: "Expiry Date" },
                ])
                setPoliciesData(TabPoliciesResponse.response);
                setTotalCount(TabPoliciesResponse.response.length);
            } else {
                $("#progress-loader").addClass("hidden");
                setPoliciesData([])
                switch (TabPoliciesResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout();
                        })
                        break;

                    default:

                }
            }
        }
    }
    useEffect(onInsurerViewTabPolicies, [props.TabPolicies]);
    
    const renderPoliciesColumns = () => {
        var arrColumns = [];
        policyColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        setShowPolicyView(true);
                                        setPolicyDetails(params.data);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else if (objColumn.dataField === "sum_insured") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSumInsured}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderPoliciesTableView = () => {
        return (
            <DataGrid
                dataSource={policiesData}
                columnHidingEnabled={false}
                showBorders={true}
                export={{
                    fileName: "policies",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderPoliciesColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    return (
        <>
        <div className="tab-pane active" id="policies" aria-labelledby="policies-tab" role="tabpanel">
            <div className="card">
                <div className="card-body">
                    <div className="row my-1">
                    <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {

                                                $("#progress-loader").removeClass("hidden");
                                                setType('Active');
                                                setTotalCount(0)
                                                props.actionInsurerViewTabPolicies({
                                                    insurer_id: props.insurerId,
                                                    type: 'active'
                                                });

                                            }}
                                            className={`btn btn-md ${ type === 'Active' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round`}>
                                            Active  { type === 'Active' ? ( totalCount > 0 ) ?  `(` + totalCount + `)` : '(0)' :``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setType('Expired');
                                                setTotalCount(0)
                                                props.actionInsurerViewTabPolicies({
                                                    insurer_id: props.insurerId,
                                                    type: 'expired'
                                                });
                                            }}
                                            className={`btn btn-md ${ type === 'Expired' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Expired { type === 'Expired' ? ( totalCount > 0 ) ?  `(` + totalCount + `)` : '(0)' :``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionInsurerViewTabPolicies({
                                                    insurer_id: props.insurerId,
                                                    type: 'all'
                                                });
                                                setType('All');
                                                setTotalCount(0)
                                            }}
                                            className={`btn btn-md ${ type === 'All' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            All { type === 'All' ? ( totalCount > 0 ) ?  `(` + totalCount + `)` : '(0)' :``}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    {
                                    
                        policiesData != null && policiesData.length > 0 ?
                        renderPoliciesTableView() :
                            <h6 className="text-bold-500 text-center">No Policy Details</h6>
                    }
                </div>
            </div>
        </div>
        <ClientPolicyDetails
            show={showPolicyView}
            clientPolicyDetails={policyDetails}
            handleClose={() => {
                setShowPolicyView(false);
            }}
            tab={1}
        />
    </>
    )
}
const mapStateToProps = (state) => {

    var TabPolicies = null;
    
    if (
        state.InsurerViewTabPoliciesReducer != null &&
        Object.keys(state.InsurerViewTabPoliciesReducer).length > 0 &&
        state.InsurerViewTabPoliciesReducer.constructor === Object
    ) {
        switch (state.InsurerViewTabPoliciesReducer.tag) {
            case ACTION_INSURER_VIEW_TAB_POLICIES_SUCCESS:
                TabPolicies = Object.assign({}, state.InsurerViewTabPoliciesReducer.data);
                delete state.InsurerViewTabPoliciesReducer;
                return { TabPolicies };
            default:
        }
    }
    return {
        TabPolicies,
       
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({actionInsurerViewTabPolicies}, dispatch);
}
export default (connect(mapStateToProps, mapDispatchToProps)(TabPolicies));
