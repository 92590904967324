import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_PERMISSION_CONTENT_EDIT } from '../../../../../../api/constants';
import { API_PERMISSION_CONTENT_VIEW } from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';

export const ACTION_CONTENT_EDIT_LOADING = 'ACTION_CONTENT_EDIT_LOADING';
export const ACTION_CONTENT_EDIT_SUCCESS = 'ACTION_CONTENT_EDIT_SUCCESS';

export const ACTION_CONTENT_VIEW_LOADING = 'ACTION_CONTENT_VIEW_LOADING';
export const ACTION_CONTENT_VIEW_SUCCESS = 'ACTION_CONTENT_VIEW_SUCCESS';

export function actionContentEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_PERMISSION_CONTENT_EDIT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CONTENT_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CONTENT_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionContentView(params) {
    return (dispatch, getState) =>
        Api.post(API_PERMISSION_CONTENT_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CONTENT_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CONTENT_VIEW_LOADING, { loading: false, data }));
        });
}