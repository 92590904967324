import { API_MASTER_COUNTRY } from '../../../api/constants';
import { API_ADD_NEW_CLIENT } from '../../../api/constants';
import { API_CLIENT_TYPE } from '../../../api/constants';
import Api from '../../../api';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_MASTER_COUNTRY_LOADING = 'ACTION_MASTER_COUNTRY_LOADING';
export const ACTION_MASTER_COUNTRY_SUCCESS = 'ACTION_MASTER_COUNTRY_SUCCESS';

export const ACTION_ADD_NEW_CLIENT_LOADING = 'ACTION_ADD_NEW_CLIENT_LOADING';
export const ACTION_ADD_NEW_CLIENT_SUCCESS = 'ACTION_ADD_NEW_CLIENT_SUCCESS';

export const ACTION_CLIENT_TYPE_LOADING = 'ACTION_CLIENT_TYPE_LOADING';
export const ACTION_CLIENT_TYPE_SUCCESS = 'ACTION_CLIENT_TYPE_SUCCESS';

export function actionCountry() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_COUNTRY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_COUNTRY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_COUNTRY_LOADING, { loading: false, data }));
        });
}
export function actionAddNewClient(params) {
    return (dispatch, getState) =>
        Api.post(API_ADD_NEW_CLIENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ADD_NEW_CLIENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ADD_NEW_CLIENT_LOADING, { loading: false, data }));
        });
}
export function actionClientType() {
    return (dispatch, getState) =>
        Api.get(API_CLIENT_TYPE,).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

