import React from 'react';
import { Route, Switch } from 'react-router';
import AllMeetings from './list';

const AllMeetingRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}`}  component={AllMeetings}/>
            </Switch>
        </>
    )
}

export default AllMeetingRoute