import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { useForm } from "react-hook-form";
import { FiTrash2 } from "react-icons/fi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import { UNAUTHORIZED } from "../../../../../constants";
import { timeSince } from "../../../../../utils";
import { actionUserNotification, ACTION_USER_NOTIFICATIONS_SUCCESS } from "./action"
import { actionDeleteNotification, ACTION_DELETE_NOTIFICATIONS_SUCCESS } from "./action"
import { actionDeleteAllNotification, ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS } from "./action";
import { actionReadNotification, ACTION_READ_NOTIFICATIONS_SUCCESS } from "./action";
import { toast } from 'react-toastify';
import { setData, KEY_NOTIFICATION_COUNT, getData, KEY_TOAST_MESSAGE, removeData } from '../../../../../utils/preferences';
import _ from "lodash";
import $ from "jquery";

const Notifications = (props) => {

    const { register } = useForm({});

    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        $("#loading-details").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionUserNotification({});
        props.actionReadNotification();
    }
    useEffect(initialRender, []);

    const onUserNotificationReadUpdate = () => {

        const { ReadNotification } = props;

        var ReadNotificationResponse = Object.assign({}, ReadNotification);

        if (ReadNotificationResponse.result === true) {
            setData(KEY_NOTIFICATION_COUNT, 0);
            $("#notification-count").html("0");
            $("#loading-details").addClass("hidden");
        } else {
            $("#loading-details").addClass("hidden");
            switch (ReadNotificationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onUserNotificationReadUpdate, [props.ReadNotification]);

    const onUserNotificationUpdate = () => {
        const { UserNotification } = props;
        var UserNotificationResponse = Object.assign({}, UserNotification);
        if (UserNotificationResponse.result === true) {
            setNotifications(UserNotificationResponse.response.data);
            setFilteredNotifications(UserNotificationResponse.response.data);
            setNotificationCount(UserNotificationResponse.response.count);
            $("#progress-loader").addClass("hidden");
            $("#loading-details").addClass("hidden");

        } else {
            $("#progress-loader").addClass("hidden");
            $("#loading-details").addClass("hidden");
            switch (UserNotificationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onUserNotificationUpdate, [props.UserNotification]);

    const onDeleteNotificationUpdate = () => {
        const { DeleteNotification } = props;
        var DeleteNotificationResponse = Object.assign({}, DeleteNotification);
        if (DeleteNotificationResponse.result === true) {
            toast.success(DeleteNotificationResponse.message);
            props.actionUserNotification({});
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (DeleteNotificationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onDeleteNotificationUpdate, [props.DeleteNotification]);

    const onDeleteAllNotificationUpdate = () => {
        const { DeleteAllNotification } = props;
        var DeleteAllNotificationResponse = Object.assign({}, DeleteAllNotification);
        if (DeleteAllNotificationResponse.result === true) {
            toast.success(DeleteAllNotificationResponse.message);
            props.actionUserNotification({});
            setFilteredNotifications([]);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (DeleteAllNotificationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onDeleteAllNotificationUpdate, [props.DeleteAllNotification]);
    useEffect(() => {
        if (searchText != null && searchText.length > 3) {
            const filteredNotification = notifications.filter(notification => String(notification.notification).toLowerCase().includes(searchText.toLowerCase()) || String(notification.title).toLowerCase().includes(searchText.toLowerCase()));
            setFilteredNotifications(filteredNotification);
        } else {
            setFilteredNotifications(notifications);
        }
    }, [searchText])

    return (
        <>
            <div className="card notification-list shadow">
                <div className="card-header pb-0">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="flex-column">
                            <h4 className="text-black text-bold-600 mb-0">Notifications ({notificationCount != null ? notificationCount : 0}) </h4>
                        </div>
                        {
                            filteredNotifications != null && filteredNotifications.length > 0 ?
                                <div className="flex-column font-medium-1 text-black mb-1">
                                    <button id="btn-edit" type="button" className={`btn btn-primary btn-md px-1 font-small-3 shadow round ${isEdit === false && filteredNotifications.length > 0 ? '' : 'hidden'}`}
                                        onClick={() => {
                                            setIsEdit(true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button id="btn-delete" type="button" className={`btn btn-primary btn-md px-1 font-small-3 shadow round ml-1 ${isEdit === false ? 'hidden' : ''}`}
                                        onClick={() => {
                                            if (selectedNotifications.length > 0) {
                                                Swal.fire({
                                                    icon: 'warning',
                                                    text: 'Are you sure, you want to delete all notifications?',
                                                    cancelButtonText: "Cancel",
                                                    showCancelButton: true,
                                                }).then(({ isConfirmed }) => {
                                                    if (isConfirmed) {
                                                        if (selectedNotifications != null && selectedNotifications.length > 0) {
                                                            props.actionDeleteAllNotification({ id: selectedNotifications })
                                                        }
                                                    }
                                                })
                                            } else {
                                                Swal.fire({
                                                    icon: 'warning',
                                                    text: 'Please select at least one Notification.',
                                                    cancelButtonText: "Cancel",
                                                    showCancelButton: true,
                                                }).then(({ isConfirmed }) => {
                                                })
                                            }

                                        }}
                                    >
                                        <FiTrash2 size={16} />
                                    </button>
                                    <button id="btn-delete" type="button" className={`btn btn-primary btn-md px-1 font-small-3 shadow round ml-1 ${isEdit === false ? 'hidden' : ''}`}
                                        onClick={() => {
                                            setIsEdit(false);
                                            setSelectedNotifications([]);
                                            $("#select-all").prop("checked", false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                        <div className="flex-column">
                            <fieldset className="form-group mb-0 position-relative has-icon-right">
                                <input
                                    type="text"
                                    className="form-control border-0 round bg-blue-grey bg-lighten-5 blue-grey darken-4 font-medium-1 search-width"
                                    placeholder="Search Here..."
                                    onChange={(event) => {
                                        setSearchText(event.target.value);
                                    }}
                                />

                            </fieldset>
                        </div>
                    </div>
                    {
                        filteredNotifications != null && filteredNotifications.length > 0 ?
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <div className={`form-group mb-0 ml-1 ${isEdit === false ? 'hidden' : ''}`}>
                                        <input
                                            type="checkbox"
                                            id="select-all"
                                            className="mr-1"
                                            onChange={(event) => {

                                                if (event.target.checked) {
                                                    var notificationIds = _.map(notifications, 'id');
                                                    setSelectedNotifications(notificationIds);
                                                } else {
                                                    setSelectedNotifications([]);
                                                }
                                            }}
                                        />
                                        <label for="select-all" className="mb-0">Select All</label>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className="text-center align-items-center mt-2">
                                <div id="loading-details" className="spinner-border text-light hidden" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <h4 class="my-5">No Notification Found</h4>
                            </div>
                    }
                </div>
                <div class="card-body pt-0">
                    <div class="list-group mt-1">
                        {
                            filteredNotifications != null && filteredNotifications.length > 0 ?
                                filteredNotifications.map((notification, index) => (
                                    <a
                                        className={`list-group-item list-group-item-action flex-column align-items-start ${index == 0 ? 'border-top' : ''}`}
                                    >
                                        <div className="notification-title">
                                            <div class="d-flex flex-row w-100 align-items-center">
                                                <div className={`flex-column mr-1  ${isEdit === false ? 'hidden' : ''}`}>
                                                    <input
                                                        type="checkbox"
                                                        value={notification.id}
                                                        checked={selectedNotifications.includes(notification.id) ? true : false}
                                                        onChange={(event) => {
                                                            $("#select-all").prop("checked", false);
                                                            var arrSelectedNotifications = Object.assign([], selectedNotifications);
                                                            if (!arrSelectedNotifications.includes(notification.id)) {
                                                                arrSelectedNotifications.push(notification.id)
                                                                setSelectedNotifications(arrSelectedNotifications)
                                                            } else {
                                                                var notificationIndex = arrSelectedNotifications.findIndex((objNotification, index) => objNotification === notification.id);
                                                                if (notificationIndex >= 0) {
                                                                    arrSelectedNotifications.splice(notificationIndex, 1);
                                                                    setSelectedNotifications(arrSelectedNotifications);
                                                                }
                                                            }
                                                            if (arrSelectedNotifications.length === notifications.length) {
                                                                $("#select-all").prop("checked", true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="flex-column"
                                                    onClick={() => {
                                                        switch (notification.type) {
                                                            case 'INSURANCE_MEETING_SELF_NOTIFY':
                                                            case 'INSURANCE_MEETING_ATTENDEE_NOTIFY':
                                                            case 'MEETING_CLOSE_INSURER':
                                                                props.history.replace({ pathname: notification.link, state: { insurer_branch_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'INSURANCE_MEETING_CLOSE_ATTENDEE_NOTIFY':
                                                                props.history.replace({ pathname: notification.link, state: { insurer_branch_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'MEETING_CREATE_WITH_OTHER_RM_COMPANY':
                                                            case 'RM_MEETING_CREATE_SELF_NOTIFY':
                                                            case 'RM_MEETING_CREATE_ATTENDEE_NOTIFY':
                                                            case 'MEETING_CLOSE_CLIENT':
                                                            case 'MEETING_CLOSE_LEAD':
                                                            case 'LEADMEETING_CREATE_WITH_OTHER_RM_COMPANY':
                                                                props.history.replace({ pathname: notification.link, state: { customer_meeting: { id: notification.record_id } } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'CUSTOMER_MEETING_CLOSE_ATTENDEE_NOTIFY':
                                                                props.history.replace({ pathname: notification.link, state: { customer_meeting: { id: notification.record_id } } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'INTERNAL_MEETING_SELF_NOTIFY':
                                                            case 'INTERNAL_MEETING_ATTENDEE_NOTIFY':
                                                                props.history.replace({ pathname: notification.link, state: { internal_meeting_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'TPA_MEETING_SELF_NOTIFY':
                                                            case 'TPA_MEETING_ATTENDEE_NOTIFY':
                                                            case 'MEETING_CLOSE_TPA':
                                                                props.history.replace({ pathname: notification.link, state: { tpa_meeting_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'TPA_MEETING_CLOSE_ATTENDEE_NOTIFY':
                                                                props.history.replace({ pathname: notification.link, state: { tpa_meeting_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'SURVEYOR_MEETING_SELF_NOTIFY':
                                                            case 'SURVEYOR_MEETING_ATTENDEE_NOTIFY':
                                                            case 'MEETING_CLOSE_SURVEYOR':
                                                                props.history.replace({ pathname: notification.link, state: { serveyor_meeting_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'SURVEYOR_MEETING_CLOSE_ATTENDEE_NOTIFY':
                                                                props.history.replace({ pathname: notification.link, state: { serveyor_meeting_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RI_MEETING_ATTENDEE_NOTIFY':
                                                            case 'RI_MEETING_EDIT_ATTENDEE_NOTIFY':
                                                            case 'RI_MEETING_CLOSE_ATTENDEE_NOTIFY':
                                                            case 'MEETING_CLOSE_REINSURANCE':
                                                                props.history.replace({ pathname: notification.link, state: { ri_meeting: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'INTERNAL_MEETING_CLOSE_ATTENDEE_NOTIFY':
                                                                props.history.replace({ pathname: notification.link, state: { internal_meeting_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'COMMENTED_ON_POST':
                                                                props.history.replace({ pathname: notification.link, state: { post_id: notification.id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'LIKED_ON_POST':
                                                                props.history.replace({ pathname: notification.link, state: { post_id: notification.id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'REGULARIZE_REQUEST_TO_AM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REGULARIZE_APPROVAL_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'LEAVE_REQUEST_CREATE_AM_RM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'LEAVE_APPROVAL_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'LEAVE_REVIEW_SUPERVISOR':
                                                                props.history.replace({ pathname: notification.link, state: { type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REGULARIZE_REVIEW_SUPERVISOR':
                                                                props.history.replace({ pathname: notification.link });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'ASSIGNED_RETENTION_CLIENT':
                                                                props.history.replace({ pathname: notification.link, state: { client_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'CUSTOMER_MEETING_RETENTION_NOTIFY':
                                                                props.history.replace({ pathname: notification.link, state: { customer_meeting: { id: notification.record_id } } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'ASSET_REQUEST_CREATE_AM_RM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'ASSET_REJECT_BY_AM_DM':
                                                            case 'ASSET_REJECTED_BY_AM_DM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'ASSET_APPROVE_BY_AM_DM':
                                                            case 'ASSET_APROVAL_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'TRAVEL_REQUEST_CREATE_AM_RM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'TRAVEL_APPROVE_RM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'TASK_POOL_TRAVEL_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'TASK_TO_CANCELLED':
                                                            case 'TASK_TO_COMPLETED':
                                                            case 'TASK_TO_REOPEN':
                                                            case 'TASK_IN_PROGRESS':
                                                            case 'TASK_TO_REVIEW':
                                                            case 'TASK_CREATE':
                                                            case 'SELF_TASK_CREATE':
                                                            case 'TASK_POOL_ASSET_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'TASK_REMINDER':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification?.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'NEW_REIMBURSMENT':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REIMBURSMENT_REQUEST_REMINDER_FOR_MANAGER':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'REIMBURSMENT_REQUEST_REMINDER_FOR_FINANCE':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'UNASSIGNED_PROPOSAL':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification?.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'ADD_RISK_REQUEST':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RISK_APPROVE_AM_TO_DM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RISK_POOL_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, from: 'notification' } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RISK_ASSIGNEE_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RISK_CREATOR_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RISK_REJECT_AM_TO_DM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RISK_REQUEST_BY_RISK_USER':
                                                                props.history.replace({ pathname: notification.link, state: { from: 'notification', id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'RISK_REQUEST_ASSIGN_BY_RISK_USER':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification?.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'ADD_WELLNESS_REQUEST':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'WELLNESS_APPROVE_AM_TO_DM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'WELLNESS_POOL_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, from: 'notification' } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'WELLNESS_REJECT_AM_TO_DM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'WELLNESS_ASSIGNEE_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'WELLNESS_CREATOR_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'WELLNESS_REQUEST_BY_WELLNESS_USER':
                                                                props.history.replace({ pathname: notification.link, state: { from: 'notification' } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'WELLNESS_REQUEST_ASSIGN_BY_WELLNESS_USER':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'WELLNESS_POOL_NOTIFICATION':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, from: 'notification' } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'NEW_EMP_IN_TEAM':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'INVESTMENT_DECLARATION_STARTED':
                                                            case 'REMINDER_ITD':
                                                            case 'FINAL_REMINDER_ITD':
                                                                props.history.replace({ pathname: notification.link });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'COMMENT_FROM_FINANCE_ON_ITD':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'CUSTOMER_MAPPED_WITH_OTHER_RM':
                                                            case 'CUSTOMER_MAPPED_WITH_OTHER_RM_TO_MANAGER':
                                                                props.history.replace({ pathname: notification.link, state: { client_id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'PROPOSAL_ASSIGNED':
                                                            case 'UNATTENDED_CLIENTS':
                                                            case 'UNATTENDED_LEADS':
                                                            case 'TODAYS_MEETING_REMINDER':
                                                            case 'SET_MEETING_REMINDER':
                                                            case 'LATE_MARK':
                                                            case 'LATE_MARK_LEAVE_DEDUCT':
                                                            case 'NOT_MARKED_ATTENDANCE':
                                                            case 'NOTIFY_USERS_FOR_MARK':
                                                            case 'NOTIFY_USERS_FOR_MARKED_ABSENT':
                                                                props.history.replace({ pathname: notification.link });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;

                                                            case 'LEAD_MAPPED_WITH_OTHER_RM':
                                                                props.history.replace({ pathname: notification.link, state: { client_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'NEW_DOCUMENT_UPLOADED':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id }});
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'CLIENT_REASSIGNED':
                                                                props.history.replace({ pathname: notification.link, state: { client_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'LEAD_REASSIGNED':
                                                                props.history.replace({ pathname: notification.link, state: { client_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'TAGGED_POST':
                                                                props.history.replace({ pathname: notification.link, state: { event: 'activity-feed', activity_id: notification.record_id } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'POLICY_EXPIRY_TO_RM':
                                                                props.history.replace({ pathname: notification.link });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'NEW_TRAINING_INCLUDE_BQP_FOR_EMPLOYEE_CERTIFICATE_EXPIRY_60_DAYS':
                                                            case 'NEW_TRAINING_EXCLUDE_BQP':
                                                                props.history.replace({ pathname: notification.link, state: { id: notification.record_id, type: notification.type } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'TRAINING_REMINDER_BEFORE_5_DAYS':
                                                            case 'TRAINING_REMINDER_BEFORE_30_MINS':
                                                            case 'TRAINING_REMINDER_BEFORE_1_DAYS':
                                                                props.history.replace({ pathname: notification.link });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                            case 'INVESTMENT_SUBMISSION_STARTED':
                                                            case 'FINAL_REMINDER_ITS':
                                                            case 'REMINDER_ITS':
                                                                props.history.replace({ pathname: notification.link, state: { from: 'Notification', tab: 'Submission' } });
                                                                $(".app-content").removeClass("show-overlay");
                                                                break;
                                                                case  'MEETING_CLOSE_REPORTING_MANAGER_CLIENT':
                                                                    case  'MEETING_CLOSE_REPORTING_MANAGER_LEAD':
                                                                        props.history.replace({ pathname: notification.link, state: { customer_meeting: { id: notification.record_id } } });
                                                                        $(".app-content").removeClass("show-overlay");
                                                                        break;
                                                                    case 'MEETING_CLOSE_REPORTING_MANAGER_SURVEYOR':
                                                                        props.history.replace({ pathname: notification.link, state: { serveyor_meeting_id: notification.record_id } });
                                                                        $(".app-content").removeClass("show-overlay");
                                                                        break;
                                                                    case 'MEETING_CLOSE_REPORTING_MANAGER_TPA':
                                                                        props.history.replace({ pathname: notification.link, state: { tpa_meeting_id: notification.record_id } });
                                                                        $(".app-content").removeClass("show-overlay");
                                                                        break;
                                                                    case 'MEETING_CLOSE_REPORTING_MANAGER_REINSURANCE':
                                                                        props.history.replace({ pathname: notification.link, state: { ri_meeting: notification.record_id } });
                                                                        $(".app-content").removeClass("show-overlay");
                                                                        break;
                                                                    case 'MEETING_CLOSE_REPORTING_MANAGER_INSURER':
                                                                        props.history.replace({ pathname: notification.link, state: { insurer_branch_id: notification.record_id } });
                                                                    $(".app-content").removeClass("show-overlay");
                                                                    break;
                                                            default:
                                                        }
                                                    }}
                                                >
                                                    <h5 className="font-medium-2 text-bold-700" style={{ color: notification.notification_style.color_code }}>
                                                        {notification.title}
                                                    </h5>
                                                    <p className="my-0 font-medium-1 text-black"> {notification.notification}</p>
                                                    <div class="mt-1 text-bold-700 blue bg-darken-2">{timeSince(notification.created_at)}</div>
                                                </div>
                                                <div className="flex-column ml-auto">
                                                    <small className={`text-bold-700 text-primary ${isEdit === false ? '' : 'hidden'}`}  >
                                                        <FiTrash2 size={16}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                Swal.fire({
                                                                    icon: 'warning',
                                                                    text: 'Are you sure, you want to delete this notification?',
                                                                    cancelButtonText: "Cancel",
                                                                    showCancelButton: true,
                                                                }).then(({ isConfirmed }) => {
                                                                    if (isConfirmed) {
                                                                        props.actionDeleteNotification({
                                                                            id: notification.id
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))
                                :
                                <></>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}
const mapStateToProps = (state) => {
    var UserNotification = null;
    var DeleteNotification = null;
    var DeleteAllNotification = null;
    var ReadNotification = null;
    if (
        state.UserNotificationReducer != null &&
        Object.keys(state.UserNotificationReducer).length > 0 &&
        state.UserNotificationReducer.constructor === Object
    ) {
        switch (state.UserNotificationReducer.tag) {
            case ACTION_USER_NOTIFICATIONS_SUCCESS:
                UserNotification = Object.assign({}, state.UserNotificationReducer.data);
                delete state.UserNotificationReducer;
                return { UserNotification };
            case ACTION_DELETE_NOTIFICATIONS_SUCCESS:
                DeleteNotification = Object.assign({}, state.UserNotificationReducer.data);
                delete state.UserNotificationReducer;
                return { DeleteNotification };
            case ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS:
                DeleteAllNotification = Object.assign({}, state.UserNotificationReducer.data);
                delete state.UserNotificationReducer;
                return { DeleteAllNotification };
            case ACTION_READ_NOTIFICATIONS_SUCCESS:
                ReadNotification = Object.assign({}, state.UserNotificationReducer.data);
                delete state.UserNotificationReducer;
                return { ReadNotification };
            default:

        }
    }
    return {
        UserNotification,
        DeleteNotification,
        DeleteAllNotification,
        ReadNotification
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionUserNotification,
        actionDeleteNotification,
        actionDeleteAllNotification,
        actionReadNotification
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Notifications))
