import { API_CORPORATE_CLIENT_DETAILS, API_CORPORATE_CLIENT_EDIT, API_MASTER_NEW_COMPANY_GROUP } from '../../../api/constants';
import { API_MASTER_NEW_CUSTOMER_CATEGORY } from '../../../api/constants';
import { API_MASTER_NEW_INDUSTRIAL_SEGMENT } from '../../../api/constants';
import { API_MASTER_NEW_FORM_OF_ORGANIZATION } from '../../../api/constants';
import { API_MASTER_NEW_LEAD_SOURCE } from '../../../api/constants';
import { API_MASTER_NEW_ASSIGN_RM } from '../../../api/constants';
import { API_MASTER_NEW_STATES } from '../../../api/constants';
import { API_MASTER_NEW_CITIES } from '../../../api/constants';
import { API_ADD_NEW_COMPANY } from '../../../api/constants';
import Api from '../../../api';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_MASTER_NEW_COMPANY_GROUP_LOADING = 'ACTION_MASTER_NEW_COMPANY_GROUP_LOADING';
export const ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS = 'ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS';

export const ACTION_MASTER_NEW_CUSTOMER_CATEGORY_LOADING = 'ACTION_MASTER_NEW_CUSTOMER_CATEGORY_LOADING';
export const ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS = 'ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS';

export const ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_LOADING = 'ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_LOADING';
export const ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS = 'ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS';

export const ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_LOADING = 'ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_LOADING';
export const ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS = 'ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS';

export const ACTION_MASTER_NEW_LEAD_SOURCE_LOADING = 'ACTION_MASTER_NEW_LEAD_SOURCE_LOADING';
export const ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS = 'ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS';

export const ACTION_MASTER_NEW_ASSIGN_RM_LOADING = 'ACTION_MASTER_NEW_ASSIGN_RM_LOADING';
export const ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS';

export const ACTION_MASTER_NEW_LEAD_STATES_LOADING = 'ACTION_MASTER_NEW_LEAD_STATES_LOADING';
export const ACTION_MASTER_NEW_LEAD_STATES_SUCCESS = 'ACTION_MASTER_NEW_LEAD_STATES_SUCCESS';

export const ACTION_MASTER_NEW_LEAD_CITIES_LOADING = 'ACTION_MASTER_NEW_LEAD_CITIES_LOADING';
export const ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS = 'ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS';

export const ACTION_ADD_NEW_COMPANY_LOADING = 'ACTION_ADD_NEW_COMPANY_LOADING';
export const ACTION_ADD_NEW_COMPANY_SUCCESS = 'ACTION_ADD_NEW_COMPANY_SUCCESS';

export const ACTION_GET_CORPORATE_CLIENT_DETAILS_LOADING = 'ACTION_GET_CORPORATE_CLIENT_DETAILS_LOADING';
export const ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS = 'ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS';

export const ACTION_EDIT_COMPANY_LOADING = 'ACTION_EDIT_COMPANY_LOADING';
export const ACTION_EDIT_COMPANY_SUCCESS = 'ACTION_EDIT_COMPANY_SUCCESS';

export function actionNewCompanyGroup() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_NEW_COMPANY_GROUP).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_COMPANY_GROUP_LOADING, { loading: false, data }));
        });
}

export function actionNewCustomerCategory() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_NEW_CUSTOMER_CATEGORY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_CUSTOMER_CATEGORY_LOADING, { loading: false, data }));
        });
}

export function actionNewIndustrialSegment() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_NEW_INDUSTRIAL_SEGMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_LOADING, { loading: false, data }));
        });
}

export function actionNewFormOfOrganization() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_NEW_FORM_OF_ORGANIZATION).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_LOADING, { loading: false, data }));
        });
}

export function actionNewLeadSource() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_NEW_LEAD_SOURCE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_LEAD_SOURCE_LOADING, { loading: false, data }));
        });
}

export function actionNewAssignRM(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_NEW_ASSIGN_RM, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}

export function actionNewStates() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_NEW_STATES).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_LEAD_STATES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_LEAD_STATES_LOADING, { loading: false, data }));
        });
}

export function actionNewCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_NEW_CITIES, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_NEW_LEAD_CITIES_LOADING, { loading: false, data }));
        });
}

export function actionAddNewCompany(params) {
    return (dispatch, getState) =>
        Api.post(API_ADD_NEW_COMPANY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ADD_NEW_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ADD_NEW_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionGetClientDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CORPORATE_CLIENT_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_CORPORATE_CLIENT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionEditCompany(params) {
    return (dispatch, getState) =>
        Api.post(API_CORPORATE_CLIENT_EDIT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_COMPANY_LOADING, { loading: false, data }));
        });
}