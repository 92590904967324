import React, { useEffect, useState } from "react";
import { withAuth } from 'react-auth-guard';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import dateFormat from 'dateformat';
import { actionOpportunityPlacementEdit, ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS } from "./action";
import { actionOpportunityPlacementAdd, ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS } from "./action";
import { actionInsurerStatus, ACTION_INSURER_STATUS_SUCCESS } from "./action";
import { actionPlacementGetInsurer, ACTION_PLACEMENT_GET_INSURER_SUCCESS } from "./action";
import { actionPlacementGetInsurerBranch, ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS } from "./action";
import { actionPlacementGetInsurerState, ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS } from "./action";
import { actionPlacementGetInsurerCity, ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS } from "./action";
import { random } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import ArrayStore from 'devextreme/data/array_store';
import { KEY_TOAST_MESSAGE, setData } from "../../../../../../utils/preferences";
import { TagBox } from "devextreme-react";
import Swal from "sweetalert2";

const AddOpportunityPlacement = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, reset, getValues } = useForm({
        defaultValues: {
            remark: '',
            // quote_amount: '',
            opportunityQuestion: [],
            product_specialist_id: 0
        }
    });
    const {
        fields: opportunityQuestionFields
    } = useFieldArray({ control, name: "opportunityQuestion" })

    const [policyGroup, setPolicyGroup] = useState([]);
    const [visibleButton, setVisibleButton] = useState(false)
    const [selectedInsurer, setSelectedInsurer] = useState([])
    const [proposalItemId, setProposalItemId] = useState(null);
    const [insuranceCompanyGroup, setInsuranceCompanyGroup] = useState([]);
    const [getAssignProductRM, setGetAssignProductRM] = useState([]);
    const [getAssignRM, setGetAssignRM] = useState(0);
    const [probabilityGroup, setProbabilityGroup] = useState([]);
    const [insurerCompany, setInsurerCompany] = useState('');
    const [policy, setPolicy] = useState('');
    const [department, setDepartment] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const [requestDate, setRequestDate] = useState(null);
    const [receiveDate, setReceiveDate] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(null)
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [selectedCompanyValue, setSelectedCompanyValue] = useState(null);
    const [placementGetInsurerBranch, setPlacementGetInsurerBranch] = useState([]);
    const [masterState, setMasterState] = useState([]);
    const [masterCity, setMasterCity] = useState([]);
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [placementGetInsurer, SetPlacementGetInsurer] = useState([]);
    const [insurerStatus, SetInsurerStatus] = useState([]);

    const initialRender = () => {
        if (props.show === true) {
            if (props.data != null) {
                setValue('company_name', props.data?.insurer_id);
                setSelectedCompanyValue(props.data?.insurer_id)
                props.actionPlacementGetInsurerState({ 'insurer_id': [props.data?.insurer_id] })
                props.actionPlacementGetInsurerCity({ 'state_id': props.data?.state_id, 'insurer_id': [props.data?.insurer_id] })
                props.actionPlacementGetInsurerBranch({ 'insurer_id': [props.data?.insurer_id], 'city_id': [props.data?.city_id], 'status': 'ACTIVE', policy_id: props?.opportunityId, placement_id: props.data?.id })
                setValue('branch_id', props.data?.insurer_branch_id);
                setSelectedBranch(props.data?.insurer_branch_id)
                setValue('state_id', props.data?.state_id)
                setValue('city_id', props.data?.city_id)
                setValue('status', props.data?.status);
                setCurrentStatus(props.data?.status)
                setValue('quote_amount', props.data?.quote_amount != null ? props.data?.quote_amount : 0);
                setRequestDate(moment(props.data?.exact_quote_request_date).format('yyyy-MM-DD'))
                setReceiveDate(moment(props.data?.quote_received_date).format('yyyy-MM-DD'))
                setValue('quote_request_date', props.data?.exact_quote_request_date != null && props.data?.exact_quote_request_date != '0000-00-00 00:00:00' ? props.data?.exact_quote_request_date : null);
                setValue('quote_received_date', props.data?.exact_quote_received_date != null && props.data?.exact_quote_received_date != '0000-00-00 00:00:00' ? props.data?.exact_quote_received_date : null);
                setValue('remark', props.data?.remark);
                setProposalItemId(props.data != null ? props.data.id : '')
            }
            setVisibleButton(true)
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            props.actionInsurerStatus()
            props.actionPlacementGetInsurer({ status: 'ACTIVE', department_id: props?.departmentId })
        } else {
            setSelectedInsurer([])
            reset()
            setProposalItemId(null)
            $("#policy-details-form-loader").addClass("hidden");
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onInsurerStatusUpdate = () => {
        const { InsurerStatus } = props;
        var InsurerStatusResponse = Object.assign({}, InsurerStatus);
        if (InsurerStatusResponse.result === true) {
            SetInsurerStatus(InsurerStatusResponse.response.opportunity_insurer_status)
        } else {
            switch (InsurerStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(InsurerStatusResponse.message);
                default:
            }
        }
    }
    useEffect(onInsurerStatusUpdate, [props.InsurerStatus]);

    useEffect(() => {
        if (insurerStatus != null && insurerStatus.length > 0) {
            if (props.data != null && props.data?.status != null) {
                setValue('status', props.data?.status)
            }
        }
    }, [insurerStatus])

    const onPlacementGetInsurerUpdate = () => {
        const { PlacementGetInsurer } = props;
        var PlacementGetInsurerResponse = Object.assign({}, PlacementGetInsurer);
        if (PlacementGetInsurerResponse.result === true) {
            SetPlacementGetInsurer(PlacementGetInsurerResponse.response)
        } else {
            switch (PlacementGetInsurerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(PlacementGetInsurerResponse.message);
                default:
            }
        }
    }
    useEffect(onPlacementGetInsurerUpdate, [props.PlacementGetInsurer]);

    useEffect(() => {
        if (placementGetInsurer != null) {
            if (props.data != null && props.data?.insurer_id != null) {
                setValue('company_name', props.data?.insurer_id)
                setVisibleButton(true)
            }
        }
    }, [placementGetInsurer])

    const onMasterStatesUpdate = () => {
        const { MasterStates } = props
        if (MasterStates != null) {
            var MasterStatesResponse = Object.assign({}, MasterStates);
            if (MasterStatesResponse.result === true) {
                setMasterState(MasterStatesResponse.response);
            } else {
                switch (MasterStatesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterState([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterStatesUpdate, [props.MasterStates])

    useEffect(() => {
        if (masterState != null && masterState.length > 0) {
            if (props.data != null && props.data?.state_id != null) {
                if (props.data?.insurer_id === selectedCompanyValue) {
                    setValue('state_id', props.data?.state_id)
                }
            }
        }
    }, [masterState])

    const onMasterCitiesUpdate = () => {
        const { MasterCities } = props
        if (MasterCities != null) {
            var MasterCitiesResponse = Object.assign({}, MasterCities);
            if (MasterCitiesResponse.result === true) {
                setMasterCity(MasterCitiesResponse.response);
            } else {
                switch (MasterCitiesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterCity([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.MasterCities])

    useEffect(() => {
        if (masterCity != null && masterCity.length > 0) {
            if (props.data != null && props.data?.city_id != null) {
                setValue('city_id', props.data?.city_id)
            }
        }
    }, [masterCity])

    const onPlacementGetInsurerBranchUpdate = () => {
        const { PlacementGetInsurerBranch } = props;
        var PlacementGetInsurerBranchResponse = Object.assign({}, PlacementGetInsurerBranch);
        if (PlacementGetInsurerBranchResponse.result === true) {
            setPlacementGetInsurerBranch(PlacementGetInsurerBranchResponse.response)
        } else {
            switch (PlacementGetInsurerBranchResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(PlacementGetInsurerBranchResponse.message);
                default:
            }
        }
    }
    useEffect(onPlacementGetInsurerBranchUpdate, [props.PlacementGetInsurerBranch]);

    useEffect(() => {
        if (placementGetInsurerBranch != null && placementGetInsurerBranch.length > 0) {
            if (props.data != null && props.data?.insurer_branch_id != null) {
                setValue('branch_id', props.data?.insurer_branch_id)
            }
        }
    }, [placementGetInsurerBranch])

    const onOpportunityPlacementEditUpdate = () => {
        const { OpportunityPlacementEdit } = props;
        var OpportunityPlacementEditResponse = Object.assign({}, OpportunityPlacementEdit);
        $("#save-placement-details").prop("disabled", false);
        if (OpportunityPlacementEditResponse.result === true) {
            $("#placement-add-form-loader").addClass("hidden");
            props.handleClose();
            setProposalItemId(null)
            toast.success("Opportunity Placement Updated Successfully");
        } else {
            $("#placement-add-form-loader").addClass("hidden");
            switch (OpportunityPlacementEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(OpportunityPlacementEditResponse.message);
                default:
            }
        }
    }
    useEffect(onOpportunityPlacementEditUpdate, [props.OpportunityPlacementEdit]);

    const onOpportunityPlacementAddUpdate = () => {
        const { OpportunityPlacementAdd } = props;
        var OpportunityPlacementAddResponse = Object.assign({}, OpportunityPlacementAdd);
        $("#save-placement-details").prop("disabled", false);
        if (OpportunityPlacementAddResponse.result === true) {
            $("#placement-add-form-loader").addClass("hidden");
            props.handleClose();
            // setProposalItemId(null)
            toast.success("Opportunity Placement Added Successfully");
            // props.history.goBack();
        } else {
            $("#placement-add-form-loader").addClass("hidden");
            switch (OpportunityPlacementAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(OpportunityPlacementAddResponse.message);
                default:
            }
        }
    }
    useEffect(onOpportunityPlacementAddUpdate, [props.OpportunityPlacementAdd]);

    const onSubmit = (data) => {
        if (props?.data?.id) {
            var editPlacement = [];
            editPlacement.id = selectedCompanyValue;
            editPlacement.insurer_branch_id = selectedBranch;
            editPlacement.quote_received_date = data.quote_received_date;
            editPlacement.quote_request_date = data.quote_request_date;
            editPlacement.status = currentStatus
            $("#save-placement-details").prop("disabled", true);
            $("#placement-add-form-loader").removeClass("hidden");
            props.actionOpportunityPlacementEdit({
                "id": props.data.id,
                "insurer_id": data.company_name,
                "policy_id": props?.detailsDataId,
                "insurer_branch_id": selectedBranch,
                "status": currentStatus,
                "quote_amount": data.quote_amount,
                "quote_request_date": data.quote_request_date,
                "quote_received_date": data.quote_received_date,
                "remark": data.remark,
            })
        } else {
            $("#save-placement-details").prop("disabled", true);
            $("#placement-add-form-loader").removeClass("hidden");
            props.actionOpportunityPlacementAdd({ 'policy_id': props?.detailsDataId, "insurer_ids": selectedInsurer, quote_request_date: moment(new Date()).format('YYYY-MM-DD') })
        }
    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                {proposalItemId ? 'Edit' : 'Add '} Placement Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                                // setProposalItemId(null)
                                setProposalItemId(null)
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ "overflow": "visible" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            {proposalItemId ?
                                                <>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Insurance Company</label>
                                                            <Controller
                                                                name="company_name"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="company_name"
                                                                        name="company_name"
                                                                        dataSource={placementGetInsurer}
                                                                        allowFiltering={true}
                                                                        filterType={"Contains"}
                                                                        placeholder="Select Company name"
                                                                        value={field.value}
                                                                        fields={{ text: 'name', value: 'id' }}
                                                                        change={(event) => {
                                                                            setSelectedState(null)
                                                                            setValue('state_id', null)
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.id)
                                                                                setSelectedCompanyValue(event.itemData.id)
                                                                                props.actionPlacementGetInsurerState({ 'insurer_id': [event.itemData.id] })
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">State</label>
                                                            <Controller
                                                                name="state_id"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="state_id"
                                                                        name="state_id"
                                                                        dataSource={masterState}
                                                                        allowFiltering={true}
                                                                        placeholder="Select State"
                                                                        value={field.value}
                                                                        fields={{ text: 'name', value: 'id' }}
                                                                        change={(event) => {
                                                                            setSelectedCity(null)
                                                                            setValue('city_id', null)
                                                                            if (event.itemData != null) {
                                                                                // setMasterCity([])
                                                                                // setPlacementGetInsurerBranch([])
                                                                                field.onChange(event.itemData.id)
                                                                                setSelectedState(event.itemData.id)
                                                                                props.actionPlacementGetInsurerCity({ state_id: event.itemData.id, insurer_id: [selectedCompanyValue] })
                                                                                // props.actionPlacementGetInsurerCity({ 'insurer_id': [selectedCompanyValue], 'state_id': event.itemData.id })
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                            {errors.state_id && <ErrorLabel message={errors.state_id?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">City</label>
                                                            <Controller
                                                                name="city_id"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="city_id"
                                                                        name="city_id"
                                                                        dataSource={masterCity}
                                                                        allowFiltering={true}
                                                                        placeholder="Select City"
                                                                        value={field.value}
                                                                        fields={{ text: 'name', value: 'id' }}
                                                                        change={(event) => {
                                                                            setSelectedBranch(null)
                                                                            setValue('branch_id', null)
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.id)
                                                                                setSelectedBranch(null)
                                                                                setSelectedCity(event.itemData.id)
                                                                                setValue('branch_id', '')
                                                                                props.actionPlacementGetInsurerBranch({ city_id: [event.itemData.id], "status": "ACTIVE", insurer_id: [selectedCompanyValue], policy_id: props?.opportunityId, placement_id: props?.data?.id })
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                            {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="form-group">
                                                            <label className="label-control">Insurer Branch</label>
                                                            <Controller
                                                                name="branch_id"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="branch_id"
                                                                        name="branch_id"
                                                                        dataSource={placementGetInsurerBranch}
                                                                        allowFiltering={true}
                                                                        placeholder="Select Branch"
                                                                        value={field.value}
                                                                        fields={{ text: 'address', value: 'id' }}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.id)
                                                                                setSelectedBranch(event.itemData.id)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                            {errors.branch_id && <ErrorLabel message={errors.branch_id?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">Current Status&nbsp;<span className="danger">*</span></label>
                                                            <Controller
                                                                name="status"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="status"
                                                                        name="status"
                                                                        placeholder="Select Current Status"
                                                                        dataSource={insurerStatus}
                                                                        allowFiltering={false}
                                                                        value={field.value}
                                                                        fields={{ text: 'name', value: 'name' }}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.name)
                                                                                setCurrentStatus(event.itemData.name)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            // rules={{
                                                            //     required: 'Please Select Current Status'
                                                            // }}
                                                            />
                                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">
                                                                Quote Amt (Net)&nbsp;<span className={`danger ${currentStatus === 'Quote Pending' || currentStatus === 'Blocked' || currentStatus === 'Declined' ? 'hidden' : ''}`} >*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="quote_amount"
                                                                name="quote_amount"
                                                                placeholder="Enter Quote Amt (Net)"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                onPaste={(event) => {
                                                                    const pastedText = event.clipboardData.getData('text/plain');
                                                                    const textWithoutCommas = pastedText.replace(/,/g, '');
                                                                    if (!/^\d+$/.test(textWithoutCommas)) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        document.execCommand('insertText', false, textWithoutCommas);
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('quote_amount', {
                                                                    required: currentStatus === 'Quote Pending' || currentStatus === 'Blocked' || currentStatus === 'Declined' ? false : 'Please Enter Quote Amount'
                                                                })}
                                                            />
                                                            {errors.quote_amount && <ErrorLabel message={errors.quote_amount?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">Quote Request Date</label>
                                                            <Controller
                                                                name="quote_request_date"
                                                                className="form-control"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DateBox
                                                                        defaultValue={new Date()}
                                                                        calendarOptions={{
                                                                            showTodayButton: true,
                                                                        }}
                                                                        stylingMode="underlined"
                                                                        className="form-control"
                                                                        pickerType="calendar"
                                                                        min={moment(new Date()).subtract(180, 'days').calendar()}
                                                                        max={moment(new Date())}
                                                                        type="date"
                                                                        displayFormat="dd/MM/yyyy"
                                                                        acceptCustomValue={false}
                                                                        value={field.value}
                                                                        onValueChange={(value) => {
                                                                            const selectedDate = moment(value);
                                                                            if (receiveDate && selectedDate.isAfter(receiveDate)) {
                                                                                setRequestDate(null);
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    text: "Quote Request date can't be greater than Quote Received date",
                                                                                    icon: 'error',
                                                                                }).then(success => { });
                                                                            } else {
                                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                                setRequestDate(selectedDate);
                                                                            }
                                                                        }}
                                                                        {...register('quote_request_date', {
                                                                            // required: 'Please Enter Quote Request Date'
                                                                        })}
                                                                    />
                                                                }
                                                            />
                                                            {errors.quote_request_date && <ErrorLabel message={errors.quote_request_date?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">
                                                                Quote Receive Date&nbsp;<span className={`danger ${currentStatus === 'Quote Pending' || currentStatus === 'Blocked' || currentStatus === 'Declined' || currentStatus === 'Withdrawn' || currentStatus === 'Others' ? 'hidden' : ''}`} >*</span>
                                                            </label>
                                                            <Controller
                                                                name="quote_received_date"
                                                                className="form-control"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DateBox
                                                                        defaultValue={new Date()}
                                                                        calendarOptions={{
                                                                            showTodayButton: true,
                                                                        }}
                                                                        stylingMode="underlined"
                                                                        className="form-control"
                                                                        pickerType="calendar"
                                                                        min={moment(new Date()).subtract(180, 'days').calendar()}
                                                                        max={moment(new Date())}
                                                                        type="date"
                                                                        displayFormat="dd/MM/yyyy"
                                                                        // showClearButton={true}
                                                                        acceptCustomValue={false}
                                                                        value={field.value}
                                                                        onValueChange={(value) => {
                                                                            const selectedDate = moment(value);
                                                                            if (requestDate && selectedDate.isBefore(requestDate)) {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    text: "Quote Received date must be greater than Quote requested date",
                                                                                    icon: 'error',
                                                                                }).then(success => { });
                                                                            } else {
                                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                                setReceiveDate(selectedDate);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                {...register('quote_received_date', {
                                                                    required: currentStatus === 'Quote Pending' || currentStatus === 'Blocked' || currentStatus === 'Declined' || currentStatus === 'Withdrawn' || currentStatus === 'Others' ? false : 'Please Enter Quote Received Date'
                                                                })}
                                                            />
                                                            {errors.quote_received_date && <ErrorLabel message={errors.quote_received_date?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12">
                                                        <div className="form-group">
                                                            <label className="label-control">
                                                                Remarks
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                id="remark"
                                                                name="remark"
                                                                placeholder="Enter Remark"
                                                                maxLength={150}
                                                                {...register('remark')}
                                                            />
                                                            <div className='font-small-1 text-black mt-half'>
                                                                Max 150 Character
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Insurer Name&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="company_name"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <TagBox
                                                                    {...field}
                                                                    dataSource={
                                                                        new ArrayStore({
                                                                            data: placementGetInsurer,
                                                                            key: 'insurer_id'
                                                                        })
                                                                    }
                                                                    dropDownOptions={{
                                                                        onInitialized: () => {
                                                                            console.log('initial')
                                                                        },
                                                                        onContentReady: () => {
                                                                            console.log('content ready')
                                                                        },
                                                                        onShowing: () => {
                                                                            console.log('showing')
                                                                        },
                                                                        onShown: () => {
                                                                            console.log('shown')
                                                                        }
                                                                    }}
                                                                    deferRendering={true}
                                                                    displayExpr="name"
                                                                    placeholder="Select Insurer"
                                                                    valueExpr="id"
                                                                    searchEnabled={true}
                                                                    searchMode="contains"
                                                                    acceptCustomValue={false}
                                                                    maxDisplayedTags={4}
                                                                    showSelectionControls={true}
                                                                    showMultiTagOnly={false}
                                                                    className="form-control"
                                                                    stylingMode="underlined"
                                                                    value={field.value != null && field.value.length > 0 ? field.value : selectedInsurer}
                                                                    onValueChanged={({ value, event }) => {
                                                                        if ((value != null || value !== undefined) && value.length > 0) {
                                                                            setSelectedInsurer(value)
                                                                            field.onChange(value);
                                                                            // props.actionPlacementGetInsurerBranch({ 'insurer_id': selectedInsurer })
                                                                        } else {
                                                                            if (selectedInsurer != null && selectedInsurer.length > 0) {
                                                                                field.onChange('');
                                                                                setSelectedInsurer([])
                                                                            }
                                                                        }
                                                                        if (event != null) {
                                                                            if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                                field.onChange('');
                                                                                setSelectedInsurer([])
                                                                            }
                                                                        }
                                                                    }}
                                                                    showClearButton={true}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Please Select Insurer'
                                                            }}
                                                        />
                                                        {errors.company_name && <ErrorLabel message={errors.company_name?.message} />}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        visibleButton === true ?
                                            <div className="form-actions border-0 mt-0 pt-0 float-none">
                                                <button
                                                    id="save-placement-details"
                                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                                    type="submit"
                                                >
                                                    <div className="d-flex align-items-center">
                                                        Submit
                                                        <span id="placement-add-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                                <button
                                                    className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        props.handleClose();
                                                        setProposalItemId(null)
                                                    }}
                                                >Cancel</button>
                                            </div>
                                            :
                                            <></>
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};
const mapStateToProps = (state) => {
    var InsurerStatus = null;
    var OpportunityPlacementAdd = null;
    var OpportunityPlacementEdit = null;
    var PlacementGetInsurer = null;
    var PlacementGetInsurerBranch = null;
    var MasterStates = null;
    var MasterCities = null;

    if (
        state.OpportunityPlacementReducer != null &&
        Object.keys(state.OpportunityPlacementReducer).length > 0 &&
        state.OpportunityPlacementReducer.constructor === Object
    ) {
        switch (state.OpportunityPlacementReducer.tag) {
            case ACTION_INSURER_STATUS_SUCCESS:
                InsurerStatus = Object.assign({}, state.OpportunityPlacementReducer.data);
                delete state.OpportunityPlacementReducer;
                return { InsurerStatus };
            case ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS:
                OpportunityPlacementAdd = Object.assign({}, state.OpportunityPlacementReducer.data);
                delete state.OpportunityPlacementReducer;
                return { OpportunityPlacementAdd };
            case ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS:
                OpportunityPlacementEdit = Object.assign({}, state.OpportunityPlacementReducer.data);
                delete state.OpportunityPlacementReducer;
                return { OpportunityPlacementEdit };
            case ACTION_PLACEMENT_GET_INSURER_SUCCESS:
                PlacementGetInsurer = Object.assign({}, state.OpportunityPlacementReducer.data);
                delete state.OpportunityPlacementReducer;
                return { PlacementGetInsurer };
            case ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS:
                PlacementGetInsurerBranch = Object.assign({}, state.OpportunityPlacementReducer.data);
                delete state.OpportunityPlacementReducer;
                return { PlacementGetInsurerBranch };
            case ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS:
                MasterStates = Object.assign({}, state.OpportunityPlacementReducer.data);
                delete state.OpportunityPlacementReducer;
                return { MasterStates };
            case ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS:
                MasterCities = Object.assign({}, state.OpportunityPlacementReducer.data);
                delete state.OpportunityPlacementReducer;
                return { MasterCities };
            default:
        }
    }
    return {
        InsurerStatus,
        OpportunityPlacementAdd,
        OpportunityPlacementEdit,
        PlacementGetInsurer,
        PlacementGetInsurerBranch,
        MasterStates,
        MasterCities
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionInsurerStatus,
            actionOpportunityPlacementEdit,
            actionOpportunityPlacementAdd,
            actionPlacementGetInsurer,
            actionPlacementGetInsurerBranch,
            actionPlacementGetInsurerState,
            actionPlacementGetInsurerCity
        },
        dispatch
    );
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AddOpportunityPlacement))
