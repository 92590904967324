import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionGroupLevelRM, ACTION_GROUP_LEVEL_PINC_RM_SUCCESS } from './action';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';
import { verifyPermission } from '../../../../../../../utils';

const GroupPincRMView = (props) => {
    const [pincRmData, setPincRmData] = useState([]);


    const [pincRMColumn, setPincRMColumn] = useState([
        { dataField: "rm_name", caption: "RM Name" },
        { dataField: "category", caption: "RM Type" },
        { dataField: "city", caption: "RM branch" },
        { dataField: "rm_reporting_to_name", caption: "RM reporting Manager" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGroupLevelRM({
            client_group_id: props.clientGroupId,
        })
    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupPincRMUpdate = () => {

        const { GroupPincRM } = props;

        if (GroupPincRM != null) {
            var GroupPincRMResponse = Object.assign({}, GroupPincRM);
            if (GroupPincRMResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPincRMColumn([
                    { dataField: "rm_name", caption: "RM Name" },
                    { dataField: "category", caption: "RM Type" },
                    { dataField: "city", caption: "RM branch" },
                    { dataField: "rm_reporting_to_name", caption: "RM reporting Manager" },
                ])
                setPincRmData(GroupPincRMResponse.response);

            } else {
                setPincRmData([])
                $("#progress-loader").addClass("hidden");
                switch (GroupPincRMResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }

            }
        }
    }

    useEffect(onGroupPincRMUpdate, [props.GroupPincRM]);

    const renderRMCell = (cell) => {
        if (cell?.data?.rm_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/profile',
                        state: {
                            user_id: cell?.data?.user_id,
                        }
                    });
                }}
            ><u>{cell?.data?.rm_name}</u></a>
        }
    }

    const renderPincRMColumn = () => {
        var arrColumns = [];
        pincRMColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "rm_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRMCell}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };
    const renderPincRMTableView = () => {
        return (
            <DataGrid
                dataSource={pincRmData}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                columnHidingEnabled={true}
                export={{
                    fileName: "PINC RM",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderPincRMColumn()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />

            </DataGrid>
        )
    }
    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    pincRmData != null && pincRmData.length > 0 ?
                                        renderPincRMTableView() :
                                        <h6 className="text-bold-500 text-center">No Pinc RM  Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}
function mapStateToProps(state) {

    var GroupPincRM = null;

    if (
        state.TabGroupPincRMReducer != null &&
        Object.keys(state.TabGroupPincRMReducer).length > 0 &&
        state.TabGroupPincRMReducer.constructor === Object
    ) {
        switch (state.TabGroupPincRMReducer.tag) {

            case ACTION_GROUP_LEVEL_PINC_RM_SUCCESS:
                GroupPincRM = Object.assign({}, state.TabGroupPincRMReducer.data);
                delete state.TabGroupPincRMReducer;
                return { GroupPincRM };
            default:
                return { GroupPincRM }
        }
    }
    return { GroupPincRM }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupLevelRM }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupPincRMView));