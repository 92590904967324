import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_LEAVE_REQUEST_ADD, API_LEAVE_HOLIDAY } from '../../../../../../api/constants';
import { API_LEAVE_REQUEST_APPROVAL_MANAGER, API_TOTAL_LEAVE } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_LEAVE_REQUEST_ADD_LOADING = 'ACTION_LEAVE_REQUEST_ADD_LOADING';
export const ACTION_LEAVE_REQUEST_ADD_SUCCESS = 'ACTION_LEAVE_REQUEST_ADD_SUCCESS';

export const ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_LOADING = 'ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_LOADING';
export const ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS = 'ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS';

export const ACTION_TOTAL_LEAVE_LOADING = 'ACTION_TOTAL_LEAVE_LOADING';
export const ACTION_TOTAL_LEAVE_SUCCESS = 'ACTION_TOTAL_LEAVE_SUCCESS';

export const ACTION_HOLIDAY_LIST_LOADING = "ACTION_HOLIDAY_LIST_LOADING";
export const ACTION_HOLIDAY_LIST_SUCCESS = "ACTION_HOLIDAY_LIST_SUCCESS";

export function actionLeaveRequestAdd(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_LEAVE_REQUEST_ADD, params, files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_LEAVE_REQUEST_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_LEAVE_REQUEST_ADD_LOADING, { loading: false, data }));
        });
}

export function actionLeaveRequestApprovalManager() {
    return (dispatch, getState) =>
        Api.get(API_LEAVE_REQUEST_APPROVAL_MANAGER).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_LEAVE_REQUEST_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}

export function actionTotalLeave() {
    return (dispatch, getState) =>
        Api.get(API_TOTAL_LEAVE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TOTAL_LEAVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TOTAL_LEAVE_LOADING, { loading: false, data }));
        });
}

export function actionHolidayList() {
    return (dispatch, getState) =>
        Api.get(API_LEAVE_HOLIDAY)
            .then((res) => {
                const data = res;
                PRINT("ACTION RESPONSE : ", data);
                dispatch(fetchSuccess(ACTION_HOLIDAY_LIST_SUCCESS, { data }));
            })
            .catch((reason) => {
                /** Handle Error */
                var data = {
                    message: "",
                };
                dispatch(loading(ACTION_HOLIDAY_LIST_LOADING, { loading: false, data }));
            });
}