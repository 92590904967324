import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MYREPORTINGVERTICAL } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const MYREPORTINGVERTICAL_LOADING = 'MYREPORTINGVERTICAL_LOADING';
export const ACTION_MYREPORTINGVERTICAL_SUCCESS = 'ACTION_MYREPORTINGVERTICAL_SUCCESS';

export function actionMyReportingVertical(params) {
        return (dispatch, getState) => Api.post(API_MYREPORTINGVERTICAL,params).then((res) => {
            const data = res;
            PRINT('ACTION_MYREPORTINGVERTICAL_SUCCESS ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_MYREPORTINGVERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(MYREPORTINGVERTICAL_LOADING, { loading: false, data }));
        });
    }
