import React, { useEffect, useState } from "react";
import _ from "lodash";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../../../../constants";
import Screen_title from "../../../../../../component/screen_title";
import {
  actionReinsuranceView,
  ACTION_REINSURANCE_VIEW_SUCCESS,
} from "./action";
import { FiUser } from "react-icons/fi";
import moment from "moment";

const ReinsuranceView = (props) => {
  const [reinsurance, setReinsurance] = useState();

  const initialRender = () => {
    props.actionReinsuranceView({ id: props.location.state.id });
  };
  useEffect(initialRender, []);

  const onReinsuranceViewsUpdate = () => {
    const { ReinsuranceViews } = props;
    var ReinsuranceViewsResponse = Object.assign({}, ReinsuranceViews);
    if (ReinsuranceViewsResponse.result === true) {
      setReinsurance(ReinsuranceViewsResponse.response);
    } else {
      switch (ReinsuranceViewsResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onReinsuranceViewsUpdate, [props.ReinsuranceViews]);

  return (
    <>
      <Screen_title title="Reinsurance Entry View" />
      <section>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-bold text-uppercase blue-grey darken-1">
                  PINC Reference No
                </h6>

                {reinsurance != null && reinsurance.pinc_ref_no != null
                  ? reinsurance.pinc_ref_no
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Operation Person
                </h6>
                {reinsurance != null && reinsurance.full_name != null
                  ? reinsurance.full_name
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Created On
                </h6>
                {reinsurance != null && reinsurance.created_at != null
                  ? moment(
                      reinsurance.created_at,
                      "DD-MM-YYYY HH:mm:ss"
                    ).format("DD/MM/YYYY")
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Branch
                </h6>
                {reinsurance != null && reinsurance.branch != null
                  ? reinsurance.branch
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Entry processed In
                </h6>

                {reinsurance != null && reinsurance.type != null
                  ? reinsurance.type
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Business Type
                </h6>
                {reinsurance != null && reinsurance.business_type != null
                  ? reinsurance.business_type
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Cedant
                </h6>

                {reinsurance != null && reinsurance.cedant != null
                  ? reinsurance.cedant
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Insured / Treaty Name
                </h6>
                {reinsurance != null && reinsurance.insured_treaty_name != null
                  ? reinsurance.insured_treaty_name
                  : "-"}
              </div>
              {reinsurance?.type === "Endorsement" ? (
                <div className="col-12 col-md-6 col-lg-6 mt-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Type of Endorsement
                  </h6>

                  {reinsurance != null && reinsurance?.endorsement_type != null
                    ? reinsurance?.endorsement_type
                    : "-"}
                </div>
              ) : (
                <></>
              )}
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Endorsement Control Number
                </h6>

                {reinsurance != null && reinsurance.endorsement_no != null
                  ? reinsurance.endorsement_no
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Cover BIND
                </h6>

                {reinsurance != null && reinsurance.cover_bind != null
                  ? reinsurance.cover_bind
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Deal Documents Saved
                </h6>
                {reinsurance != null && reinsurance.document_saved != null
                  ? reinsurance.document_saved
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Approval Status
                </h6>
                {reinsurance != null && reinsurance.approved != null
                  ? reinsurance.approved
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Signed Documents Received
                </h6>
                {reinsurance != null &&
                reinsurance.signed_document_received != null
                  ? reinsurance.signed_document_received
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  RI Team Remark
                </h6>
                {reinsurance != null && reinsurance.ri_remark != null
                  ? reinsurance.ri_remark
                  : "-"}
              </div>
              <div className="col-12 col-md-12 col-lg-12 mt-1">
                <h4 className="d-flex align-items-center form-section mb-2 black">
                  <FiUser size={16} />
                  &nbsp;For Finance
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  SARB Enquiry code
                </h6>
                {reinsurance != null && reinsurance.sarb_enquiry_code != null
                  ? reinsurance.sarb_enquiry_code
                  : "-"}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Control Number
                </h6>
                {reinsurance != null && reinsurance.control_no != null
                  ? reinsurance.control_no
                  : "-"}
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Finance Team Remark
                </h6>
                {reinsurance != null && reinsurance.finance_remark != null
                  ? reinsurance.finance_remark
                  : "-"}
              </div>
            </div>
            <div className="form-actions border-0 mt-0 pt-0 float-none">
              <button
                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                onClick={(event) => {
                  event.preventDefault();
                  props.history.goBack();
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  var ReinsuranceViews = null;

  if (
    state.ReinsuranceViewReducer != null &&
    Object.keys(state.ReinsuranceViewReducer).length > 0 &&
    state.ReinsuranceViewReducer.constructor === Object
  ) {
    switch (state.ReinsuranceViewReducer.tag) {
      case ACTION_REINSURANCE_VIEW_SUCCESS:
        ReinsuranceViews = Object.assign({}, state.ReinsuranceViewReducer.data);
        delete state.ReinsuranceViewReducer;
        return { ReinsuranceViews };
      default:
    }
  }

  return {
    ReinsuranceViews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionReinsuranceView }, dispatch);
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ReinsuranceView)
);
