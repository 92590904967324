import React, { useState, useEffect } from 'react';
import { FiEye } from 'react-icons/fi';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
} from "devextreme-react/data-grid";
import { Controller, useForm } from 'react-hook-form';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { DateBox } from 'devextreme-react';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import _, { set } from 'lodash';
import { actionMeetingDetailReport, ACTION_MEETING_DETAIL_REPORT_SUCCESS } from './action';
import moment from 'moment';
import { getData, KEY_MEETING_DETAILS, setData, removeData } from '../../../../../../utils/preferences';

const MeetingDetailPage = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [fromDate, setFromDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [employeeReport, setEmployeeReport] = useState(null);
    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "branch", caption: "Branch Name", visible: true },
        { dataField: "meeting_date", caption: "Meeting date", visible: false },
        { dataField: "meeting_date_formated", caption: "Meeting date", visible: true },
        { dataField: "start_time", caption: "Start time", visible: true },
        { dataField: "end_time", caption: "End time", visible: true },
        { dataField: "full_name", caption: "Full name", visible: true },
        { dataField: "accompanied_by", caption: "Accompanied by", visible: true },
        { dataField: "meeting_type", caption: "Meeting type", visible: true },
        { dataField: "company_name", caption: "Company name", visible: true },
        { dataField: "agenda", caption: "Agenda", visible: true },
        { dataField: "location", caption: "Location", visible: true },
        { dataField: "status", caption: "Status", visible: true },
    ]);

    const initialRender = () => {
        var meetingDetail = getData(KEY_MEETING_DETAILS)
        if (meetingDetail != null) {
            console.log("meetingDetail", meetingDetail);
            var input = JSON.parse(meetingDetail)
            props.actionMeetingDetailReport({
                from_date: dateFormat(input.from_date, 'yyyy-mm-dd'),
                to_date: dateFormat(input.to_date, 'yyyy-mm-dd'),
            });
            setValue('from_date', dateFormat(input.from_date, 'yyyy-mm-dd'));
            setValue('to_date', dateFormat(input.to_date, 'yyyy-mm-dd'));
        } else {
            props.actionMeetingDetailReport({
                from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
                to_date: dateFormat(toDate, 'yyyy-mm-dd'),
            });
        }
    }
    useEffect(initialRender, []);

    const onMeetingDetailReportUpdate = () => {
        const { MeetingDetailReport } = props;
        var MeetingDetailReportResponse = Object.assign({}, MeetingDetailReport);
        $("#meeting-detail-report").prop("disabled", false);
        if (MeetingDetailReportResponse.result === true) {
            setEmployeeReport(MeetingDetailReportResponse.response);
            $("#meeting-detail-report-loader").addClass("hidden");
            $("#progress-loader").addClass("hidden");
        } else {
            $("#meeting-detail-report-loader").addClass("hidden");
            switch (MeetingDetailReportResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMeetingDetailReportUpdate, [props.MeetingDetailReport]);

    const renderStatusCell = (cell) => {
        console.log(cell);
        switch (cell.data.status) {
            case 'Open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>Open</div>;
            case 'Close':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>Close</div>;
            case 'Cancel':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>Cancel</div>;
            default:
                return <></>
        }
    }

    const renderMeetingType = (cell) => {
        return _.upperFirst(cell.data.meeting_type);
    }

    const renderAccompaniedBy = (cell) => {
        return <div>{_.truncate(cell.data.accompanied_by)}</div>
    }

    const renderCompanyName = (cell) => {
        return <div>{_.truncate(cell.data.company_name)}</div>
    }

    const renderAgenda = (cell) => {
        return <div>{_.truncate(cell.data.agenda)}</div>
    }

    const renderLocation = (cell) => {
        return <div>{_.truncate(cell.data.location)}</div>
    }

    const renderMeetingDate = (cell) => {
        return <div>{cell?.data?.meeting_date_formated != null ? moment(cell?.data?.meeting_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    // view all customer meeting permission verify
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        switch (params.data.meeting_type) {
                                            case 'Internal':
                                                props.history.push({ pathname: `/meetings/internal-meetings/view`, state: { internal_meeting_id: params.data.id } });
                                                break;
                                            case 'Tpa':
                                                props.history.push({ pathname: `/meetings/tpa-meetings/view`, state: { tpa_meeting_id: params.data.id } })
                                                break;
                                            case 'Surveyor':
                                                props.history.push({ pathname: `/meetings/serveyor-meetings/view`, state: { serveyor_meeting_id: params.data.id } })
                                                break;
                                            case 'Lead':
                                            case 'Client':
                                                props.history.push({ pathname: `/meetings/customer-meetings/view`, state: { customer_meeting: params.data } })
                                                break;
                                            case 'Insurer':
                                                props.history.push({ pathname: `/meetings/insurance-company-meetings/view`, state: { insurer_branch_id: params.data.id } })
                                                break;
                                            case 'Reinsurance':
                                                props.history.push({ pathname: `/meetings/ri-meetings/view`, state: { ri_meeting: params.data.id } })
                                                break;
                                        }
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />

                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "meeting_type") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderMeetingType}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "accompanied_by") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderAccompaniedBy}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "company_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderCompanyName}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "agenda") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderAgenda}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "location") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderLocation}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "meeting_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderMeetingDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    const onSubmit = (data) => {
    }
    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Meeting Details</h5>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="flex-column">
                            <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                <button
                                    className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                    onClick={() => {
                                        removeData(KEY_MEETING_DETAILS);
                                        props.history.goBack();

                                    }}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">From Date</label>
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                defaultValue={fromDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(value).format('YYYY-MM-DD');
                                                            var objToDate = moment(toDate).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "From date can't be greater than To date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">To Date</label>
                                            <Controller
                                                className="flex-column"
                                                name="to_date"
                                                control={control}
                                                defaultValue={toDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(fromDate).format('YYYY-MM-DD');
                                                            var objToDate = moment(value).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "To date must be greater than from date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="form-actions mt-1 border-0 float-none">
                                        <button
                                            id="meeting-detail-report"
                                            className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                            onClick={(data) => {
                                                $("#meeting-detail-report-loader").removeClass("hidden");
                                                $("#meeting-detail-report").prop("disabled", true);
                                                setData(KEY_MEETING_DETAILS, JSON.stringify({
                                                    from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
                                                    to_date: dateFormat(toDate, 'yyyy-mm-dd'),
                                                }))
                                                props.actionMeetingDetailReport({
                                                    from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
                                                    to_date: dateFormat(toDate, 'yyyy-mm-dd'),
                                                });
                                            }}
                                        >Search
                                            <span id="meeting-detail-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={employeeReport}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    remoteOperations={true}
                                    export={{
                                        fileName: "Meeting Detail Report",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("meeting_date_formated", "visible", false)
                                        event.component.columnOption("meeting_date", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("meeting_date_formated", "visible", true)
                                        event.component.columnOption("meeting_date", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    {renderColumns()}
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var MeetingDetailReport = null;
    if (
        state.meetingDetailReportReducer != null &&
        Object.keys(state.meetingDetailReportReducer).length > 0 &&
        state.meetingDetailReportReducer.constructor === Object
    ) {
        switch (state.meetingDetailReportReducer.tag) {
            case ACTION_MEETING_DETAIL_REPORT_SUCCESS:
                MeetingDetailReport = Object.assign({}, state.meetingDetailReportReducer.data);
                delete state.meetingDetailReportReducer;
                return { MeetingDetailReport };
            default:
        }
    }

    return {
        MeetingDetailReport,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMeetingDetailReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MeetingDetailPage))

