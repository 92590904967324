import moment from "moment";
import ScreenTitle from '../../../../../../component/screen_title';
import { GrPlan } from "react-icons/gr"
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { FiUser } from "react-icons/fi";
import { KEY_TOAST_MESSAGE, setData } from "../../../../../../utils/preferences";
import { actionItSubmissionPoolEdit, ACTION_IT_SUBMISSION_POOL_EDIT_SUCCESS } from "./action";
import { actionItSubmissionPoolDetail, ACTION_IT_SUBMISSION_POOL_DETAIL_SUCCESS } from "./action";
import { ERROR, UNAUTHORIZED, AMOUNT_REGEX } from "../../../../../../constants";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import Tippy from '@tippyjs/react';
import Swal from "sweetalert2";

const SubmissionPoolEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    });

    const [pfContribution, setPfContribution] = useState([
        {
            "title": "0",
            "value": "0"
        },
        {
            "title": "1800",
            "value": "1800"
        },
        // {
        //     "title": "6% of Basic",
        //     "value": "6% of Basic"
        // },
        {
            "title": "12% of Basic",
            "value": "12% of Basic"
        }
    ]);

    const [selectRequest, setSelectRequest] = useState(null);
    const [submissionPoolDetails, SetSubmissionPoolDetails] = useState()
    const tooltipSection80C = "Maximum eligible limit INR 150,000 (1.5 Lakhs).";
    const tooltipSection80CCD = "Maximum eligible limit INR 50,000 (50K).";
    const tooltipSection80D = "Eligibility INR 25000 for Family for Emp <60 Years, 50000 for Emp > 60 Years, 50000 for Dependent Parent.";
    const tooltipOthers = "INR 200,000 (2 lakhs) maximum eligibility for Interest Paid on Housing Loan & Loss from House Property.";
    const tooltipFlexible = "(Up to max of Rs 50,000 annually - TWICE in a BLOCK OF FOUR YEARS)";
    const [detailsData, setDetailsData] = useState(null);
    const [grandTotal, setGrandTotal] = useState(0);
    const [statusInsurance, setStatusInsurance] = useState(null);
    const [statusPPF, setStatusPPF] = useState(null);
    const [statusMutualFunds, setStatusMutualFunds] = useState(null);
    const [statusNSC, setStatusNSC] = useState(null);
    const [statusRepaymentOfHousingLoan, setStatusRepaymentOfHousingLoan] = useState(null);
    const [statusTuitionFees, setStatusTuitionFees] = useState(null);
    const [statusFiveYearFD, setStatusFiveYearFD] = useState(null);
    const [statusOthers, setStatusOthers] = useState(null);
    const [statusNPS, setStatusNPS] = useState(null);
    const [statusFamilyMediclaim, setStatusFamilyMediclaim] = useState(null);
    const [statusParentMediclaim, setStatusParentMediclaim] = useState(null);
    const [statusPreventiveHealthCheckup, setStatusPreventiveHealthCheckup] = useState(null);
    const [statusSelfEducation, setStatusSelfEducation] = useState(null);
    const [statusHousingLoan, setStatusHousingLoan] = useState(null);
    const [statusHouseProperty, setStatusHouseProperty] = useState(null);
    const [statusHRA, setStatusHRA] = useState(null);
    const [statusLTA, setStatusLTA] = useState(null);
    const watchDocumentLifeInsurancePremium = watch('submissions_document_life_insurance_premium_or_pension_schemes')
    const watchDocumentPPF = watch('submissions_document_ppf')
    const watchDocumentUlipOrElssOrTaxSavingMutualFunds = watch('submissions_document_ulip_or_elss_or_tax_saving_mutual_funds')
    const watchDocumentNSC = watch('submissions_document_nsc')
    const watchDocumentRepaymentOfHousingLoan = watch('submissions_document_repayment_of_housing_loan')
    const watchDocumenttUitionFeesForChildren = watch('submissions_document_tuition_fees_for_children')
    const watchDocumentFiveYearFd = watch('submissions_document_five_year_fd')
    const watchDocumentOthers = watch('submissions_document_others')
    const watchDocumentNPS = watch('submissions_document_nps')
    const watchDocumentFamilyMediclaim = watch('submissions_document_family_mediclaim')
    const watchDocumentParentMediclaim = watch('submissions_document_parents_mediclaim')
    const watchDocumentHealthCheckUp = watch('submissions_document_preventive_health_check_up')
    const watchDocumentSelfEducation = watch('submissions_document_interest_paid_on_loan_for_self_education')
    const watchDocumentHousingLoan = watch('submissions_document_interest_paid_on_housing_loan')
    const watchDocumentHouseProperty = watch('submissions_document_loss_from_house_property')
    const watchDocumentHRA = watch('submissions_document_hra')
    const watchDocumentLTA = watch('submissions_document_lta')
    const watchSubmissionGrandTotal = watch('submissions_grand_total')
    const watchSubmissionDocumentTotal = watch('submissions_document_grand_total')

    // final watch 
    const watchSubmissionLifeInsurancePremium = watch('submissions_life_insurance_premium_or_pension_schemes')
    const watchSubmissionPPF = watch('submissions_ppf')
    const watchSubmissionUlipOrElssOrTaxSavingMutualFunds = watch('submissions_ulip_or_elss_or_tax_saving_mutual_funds')
    const watchSubmissionNSC = watch('submissions_nsc')
    const watchSubmissionRepaymentOfHousingLoan = watch('submissions_repayment_of_housing_loan')
    const watchSubmissiontUitionFeesForChildren = watch('submissions_tuition_fees_for_children')
    const watchSubmissionFiveYearFd = watch('submissions_five_year_fd')
    const watchSubmissionOthers = watch('submissions_others')
    const watchSubmissionNPS = watch('submissions_nps')
    const watchSubmissionFamilyMediclaim = watch('submissions_family_mediclaim')
    const watchSubmissionParentMediclaim = watch('submissions_parents_mediclaim')
    const watchSubmissionHealthCheckUp = watch('submissions_preventive_health_check_up')
    const watchSubmissionSelfEducation = watch('submissions_interest_paid_on_loan_for_self_education')
    const watchSubmissionHousingLoan = watch('submissions_interest_paid_on_housing_loan')
    const watchSubmissionHouseProperty = watch('submissions_loss_from_house_property')
    const watchSubmissionHRA = watch('submissions_hra')
    const watchSubmissionLTA = watch('submissions_lta')

    // approved
    const watchLifeInsurancePremium = watch('submissions_approved_life_insurance_premium_or_pension_schemes')
    const watchPPF = watch('submissions_approved_ppf')
    const watchUlipOrElssOrTaxSavingMutualFunds = watch('submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds')
    const watchNSC = watch('submissions_approved_nsc')
    const watchRepaymentOfHousingLoan = watch('submissions_approved_repayment_of_housing_loan')
    const watchtUitionFeesForChildren = watch('submissions_approved_tuition_fees_for_children')
    const watchFiveYearFd = watch('submissions_approved_five_year_fd')
    const watchOthers = watch('submissions_approved_others')
    const watchNPS = watch('submissions_approved_nps')
    const watchFamilyMediclaim = watch('submissions_approved_family_mediclaim')
    const watchParentMedicalim = watch('submissions_approved_parents_mediclaim')
    const watchPreventiveHealth = watch('submissions_approved_preventive_health_check_up')
    const watchInterestPaid = watch('submissions_approved_interest_paid_on_loan_for_self_education')
    const watchtHousingLoan = watch('submissions_approved_interest_paid_on_housing_loan')
    const watchHouseProperty = watch('submissions_approved_loss_from_house_property')
    const watchHRA = watch('submissions_approved_hra')
    const watchLTA = watch('submissions_approved_lta')

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        setDetailsData(JSON.parse(localStorage.getItem('detailsData')))
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (detailsData != null) {
            props.actionItSubmissionPoolDetail({
                "window_id": detailsData.window_id,
                user_id: detailsData?.user_id,
            });
        }
    }, [detailsData])

    const onItSubmissionPoolDetailUpdate = () => {
        const { ItSubmissionPoolDetail } = props;
        var ItSubmissionPoolDetailResponse = Object.assign({}, ItSubmissionPoolDetail);
        if (ItSubmissionPoolDetailResponse.result === true) {
            SetSubmissionPoolDetails(ItSubmissionPoolDetailResponse.response);
            setValue("regime", ItSubmissionPoolDetailResponse?.response?.declarations?.regime);
            setValue('actual_life_insurance_premium_or_pension_schemes', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.life_insurance_premium_or_pension_schemes != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.life_insurance_premium_or_pension_schemes : '-');
            setValue('actual_ppf', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.ppf != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.ppf : '-');
            setValue('actual_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds : '-');
            setValue('actual_nsc', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.nsc != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.nsc : '-');
            setValue('actual_repayment_of_housing_loan', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.repayment_of_housing_loan != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.repayment_of_housing_loan : '-');
            setValue('actual_tuition_fees_for_children', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.tuition_fees_for_children != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.tuition_fees_for_children : '-');
            setValue('actual_five_year_fd', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.five_year_fd != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.five_year_fd : '-');
            setValue('actual_others', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.others != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.others : '-');
            setValue('actual_grand_total', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.grand_total != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80c?.grand_total : '-');
            setValue('actual_nps', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80ccd?.nps != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80ccd?.nps : '-');
            setValue('actual_family_mediclaim', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80d?.family_mediclaim != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80d?.family_mediclaim : '-');
            setValue('actual_parents_mediclaim', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80d?.parents_mediclaim != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80d?.parents_mediclaim : '-');
            setValue('actual_preventive_health_check_up', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80d?.preventive_health_check_up != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80d?.preventive_health_check_up : '-');
            setValue('actual_interest_paid_on_loan_for_self_education', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80e?.interest_paid_on_loan_for_self_education != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_80e?.interest_paid_on_loan_for_self_education : '-');
            setValue('actual_interest_paid_on_housing_loan', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_other?.interest_paid_on_housing_loan != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_other?.interest_paid_on_housing_loan : '-');
            setValue('actual_loss_from_house_property', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_other?.loss_from_house_property != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_other?.loss_from_house_property : '-');
            setValue('actual_hra', ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_other?.hra != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.declaration_other?.hra : '-');
            setValue("actual_pf_contribution", ItSubmissionPoolDetailResponse?.response?.declarations?.pf_contribution != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.pf_contribution : '-');
            setValue("actual_lta", ItSubmissionPoolDetailResponse?.response?.declarations?.lta != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.lta : '-');
            setValue("actual_special_allowance", ItSubmissionPoolDetailResponse?.response?.declarations?.special_allowance != null ? ItSubmissionPoolDetailResponse?.response?.declarations?.special_allowance : '-');

            // submission details 
            setValue('section_80c_finance_remark', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.finance_remark != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.finance_remark : '');
            setValue('section_80ccd_finance_remark', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.finance_remark != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.finance_remark : '');
            setValue('section_80d_finance_remark', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.finance_remark != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.finance_remark : '');
            setValue('section_80e_finance_remark', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.finance_remark != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.finance_remark : '');
            setValue('section_other_finance_remark', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.finance_remark != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.finance_remark : '');
            setValue('section_flexible_finance_remark', ItSubmissionPoolDetailResponse?.response?.submissions?.finance_flexible_remark != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.finance_flexible_remark : '')

            setValue('submissions_life_insurance_premium_or_pension_schemes', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes);
            setValue('submissions_document_life_insurance_premium_or_pension_schemes', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document);
            setValue('submissions_status_life_insurance_premium_or_pension', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status : '');
            setValue('submissions_approved_life_insurance_premium_or_pension_schemes', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_approved : '');

            setValue('submissions_ppf', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ppf);
            setValue('submissions_document_ppf', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ppf_document);
            setValue('submissions_status_ppf', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ppf_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ppf_status : '');
            setValue('submissions_approved_ppf', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ppf_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ppf_approved : '');

            setValue('submissions_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds);
            setValue('submissions_document_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document);
            setValue('submissions_status_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status : '');
            setValue('submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_approved : '');

            setValue('submissions_nsc', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.nsc);
            setValue('submissions_document_nsc', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.nsc_document);
            setValue('submissions_status_nsc', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.nsc_status != "Pending" ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.nsc_status : '');
            setValue('submissions_approved_nsc', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.nsc_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.nsc_approved : '');

            setValue('submissions_repayment_of_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan);
            setValue('submissions_document_repayment_of_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_document);
            setValue('submissions_status_repayment_of_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_status : '');
            setValue('submissions_approved_repayment_of_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_approved : '');

            setValue('submissions_tuition_fees_for_children', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children);
            setValue('submissions_document_tuition_fees_for_children', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_document);
            setValue('submissions_status_tuition_fees_for_children', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_status : '');
            setValue('submissions_approved_tuition_fees_for_children', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_approved : '');

            setValue('submissions_five_year_fd', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.five_year_fd);
            setValue('submissions_document_five_year_fd', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_document);
            setValue('submissions_status_five_year_fd', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_status : '');
            setValue('submissions_approved_five_year_fd', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_approved : '');

            setValue('submissions_approved_others', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.others_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.others_approved : '');
            setValue('submissions_others', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.others);
            setValue('submissions_document_others', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.others_document);
            setValue('submissions_status_others', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.others_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.others_status : '');

            setValue('submissions_grand_total', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.grand_total);
            setValue('submissions_document_grand_total', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.grand_total_document);
            setValue('submissions_approved_grand_total', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.grand_total_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.grand_total_approved : '');

            setValue('submissions_nps', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.nps);
            setValue('submissions_status_nps', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.nps_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.nps_status : '');
            setValue('submissions_document_nps', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.nps_document);
            setValue('submissions_approved_nps', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.nps_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.nps_approved : '');

            setValue('submissions_family_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim);
            setValue('submissions_document_family_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_document);
            setValue('submissions_status_family_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_status : '');
            setValue('submissions_approved_family_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_approved : '');

            setValue('submissions_parents_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim);
            setValue('submissions_document_parents_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_document);
            setValue('submissions_status_parents_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_status : '');
            setValue('submissions_approved_parents_mediclaim', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_approved : '');

            setValue('submissions_preventive_health_check_up', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up);
            setValue('submissions_document_preventive_health_check_up', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_document);
            setValue('submissions_status_preventive_health_check_up', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_status : '');
            setValue('submissions_approved_preventive_health_check_up', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_approved : '');

            setValue('submissions_interest_paid_on_loan_for_self_education', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education);
            setValue('submissions_document_interest_paid_on_loan_for_self_education', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document);
            setValue('submissions_status_interest_paid_on_loan_for_self_education', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status : '');
            setValue('submissions_approved_interest_paid_on_loan_for_self_education', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_approved : '');

            setValue('submissions_interest_paid_on_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan);
            setValue('submissions_document_interest_paid_on_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_document);
            setValue('submissions_status_interest_paid_on_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_status != "Pending" ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_status : '');
            setValue('submissions_approved_interest_paid_on_housing_loan', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_approved : '');

            setValue('submissions_loss_from_house_property', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property);
            setValue('submissions_document_loss_from_house_property', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_document);
            setValue('submissions_status_loss_from_house_property', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_status : '');
            setValue('submissions_approved_loss_from_house_property', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_approved : '');

            setValue('submissions_hra', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.hra);
            setValue('submissions_document_hra', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.hra_document);
            setValue('submissions_status_hra', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.hra_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.hra_status : '');
            setValue('submissions_approved_hra', ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.hra_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.hra_approved : '');

            setValue("submissions_lta", ItSubmissionPoolDetailResponse?.response?.submissions?.lta);
            setValue("submissions_document_lta", ItSubmissionPoolDetailResponse?.response?.submissions?.lta_document);
            setValue("submissions_status_lta", ItSubmissionPoolDetailResponse?.response?.submissions?.lta_status != 'Pending' ? ItSubmissionPoolDetailResponse?.response?.submissions?.lta_status : '');
            setValue("submissions_approved_lta", ItSubmissionPoolDetailResponse?.response?.submissions?.lta_approved != null ? ItSubmissionPoolDetailResponse?.response?.submissions?.lta_approved : '');

            if (ItSubmissionPoolDetailResponse?.response?.submissions?.lta != null && ItSubmissionPoolDetailResponse?.response?.submissions?.lta_document != null) {
                const PendingLTA = Number(ItSubmissionPoolDetailResponse?.response?.submissions?.lta) - Number(ItSubmissionPoolDetailResponse?.response?.submissions?.lta_document)
                setValue('submissions_pending_lta', PendingLTA);
            }

            setValue('remarks', ItSubmissionPoolDetailResponse?.response?.submissions?.emp_remark);

            setStatusInsurance(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status)
            setStatusPPF(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ppf_status)
            setStatusMutualFunds(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status)
            setStatusNSC(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.nsc_status)
            setStatusRepaymentOfHousingLoan(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.repayment_of_housing_loan_status)
            setStatusTuitionFees(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.tuition_fees_for_children_status)
            setStatusFiveYearFD(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.five_year_fd_status)
            setStatusOthers(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80c?.others_status)
            setStatusNPS(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80ccd?.nps_status)
            setStatusFamilyMediclaim(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.family_mediclaim_status)
            setStatusParentMediclaim(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.parents_mediclaim_status)
            setStatusPreventiveHealthCheckup(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80d?.preventive_health_check_up_status)
            setStatusSelfEducation(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status)
            setStatusHousingLoan(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.interest_paid_on_housing_loan_status)
            setStatusHouseProperty(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.loss_from_house_property_status)
            setStatusLTA(ItSubmissionPoolDetailResponse?.response?.submissions?.lta_status)
            setStatusHRA(ItSubmissionPoolDetailResponse?.response?.submissions?.submission_other?.hra_status)

            $("#progress-loader").addClass("hidden");
            if ((ItSubmissionPoolDetailResponse?.response?.finance_remark != null && ItSubmissionPoolDetailResponse?.response?.finance_remark != '') || ItSubmissionPoolDetailResponse?.response?.status === 'ACCEPTED') {
                setValue('status', ItSubmissionPoolDetailResponse?.response?.status)
            }
            setValue('finance_remark', ItSubmissionPoolDetailResponse?.response?.submissions?.finance_remark)
        } else {
            switch (ItSubmissionPoolDetailResponse.status) {
                case ERROR:
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onItSubmissionPoolDetailUpdate, [props.ItSubmissionPoolDetail]);

    useEffect(() => {
        if (
            statusInsurance != null && statusInsurance === 'Rejected' ||
            statusPPF != null && statusPPF === 'Rejected' ||
            statusMutualFunds != null && statusMutualFunds === 'Rejected' ||
            statusNSC != null && statusNSC === 'Rejected' ||
            statusRepaymentOfHousingLoan != null && statusRepaymentOfHousingLoan === 'Rejected' ||
            statusTuitionFees != null && statusTuitionFees === 'Rejected' ||
            statusFiveYearFD != null && statusFiveYearFD === 'Rejected' ||
            statusOthers != null && statusOthers === 'Rejected' ||
            statusNPS != null && statusNPS === 'Rejected' ||
            statusFamilyMediclaim != null && statusFamilyMediclaim === 'Rejected' ||
            statusParentMediclaim != null && statusParentMediclaim === 'Rejected' ||
            statusPreventiveHealthCheckup != null && statusPreventiveHealthCheckup === 'Rejected' ||
            statusSelfEducation != null && statusSelfEducation === 'Rejected' ||
            statusHousingLoan != null && statusHousingLoan === 'Rejected' ||
            statusHouseProperty != null && statusHouseProperty === 'Rejected' ||
            statusHRA != null && statusHRA === 'Rejected' ||
            statusLTA != null && statusLTA === 'Rejected'
        ) {
            setValue('status', 'REJECTED');
            setSelectRequest('REJECTED')

        } else {
            setValue('status', 'ACCEPTED');
            setSelectRequest('ACCEPTED')
        }
    }, [statusInsurance, statusPPF, statusMutualFunds, statusNSC,
        statusRepaymentOfHousingLoan, statusTuitionFees, statusFiveYearFD,
        statusOthers, statusOthers, statusNPS, statusFamilyMediclaim,
        statusPreventiveHealthCheckup, statusSelfEducation, statusHousingLoan,
        statusHouseProperty, statusHRA, statusParentMediclaim, statusLTA
    ])

    useEffect(() => {
        if (watchSubmissionLifeInsurancePremium != null && watchDocumentLifeInsurancePremium != null) {
            const PendingLifeInsurancePremium = Number(watchSubmissionLifeInsurancePremium) - Number(watchDocumentLifeInsurancePremium)
            setValue('submissions_pending_life_insurance_premium_or_pension_schemes', PendingLifeInsurancePremium);
        }

        if (watchSubmissionPPF != null && watchDocumentPPF != null) {
            const PendingPPF = Number(watchSubmissionPPF) - Number(watchDocumentPPF)
            setValue('submissions_pending_ppf', PendingPPF);
        }

        if (watchSubmissionUlipOrElssOrTaxSavingMutualFunds != null && watchDocumentUlipOrElssOrTaxSavingMutualFunds != null) {
            const PendingMutualFunds = Number(watchSubmissionUlipOrElssOrTaxSavingMutualFunds) - Number(watchDocumentUlipOrElssOrTaxSavingMutualFunds)
            setValue('submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds', PendingMutualFunds);
        }

        if (watchSubmissionNSC != null && watchDocumentNSC != null) {
            const PendingNSC = Number(watchSubmissionNSC) - Number(watchDocumentNSC)
            setValue('submissions_pending_nsc', PendingNSC);
        }

        if (watchSubmissionRepaymentOfHousingLoan != null && watchDocumentRepaymentOfHousingLoan != null) {
            const PendingHousingRepayment = Number(watchSubmissionRepaymentOfHousingLoan) - Number(watchDocumentRepaymentOfHousingLoan)
            setValue('submissions_pending_repayment_of_housing_loan', PendingHousingRepayment);
        }

        if (watchSubmissiontUitionFeesForChildren != null && watchDocumenttUitionFeesForChildren != null) {
            const PendingChildrenFees = Number(watchSubmissiontUitionFeesForChildren) - Number(watchDocumenttUitionFeesForChildren)
            setValue('submissions_pending_tuition_fees_for_children', PendingChildrenFees);
        }

        if (watchSubmissionFiveYearFd != null && watchDocumentFiveYearFd != null) {
            const PendingFiveYearFd = Number(watchSubmissionFiveYearFd) - Number(watchDocumentFiveYearFd)
            setValue('submissions_pending_five_year_fd', PendingFiveYearFd);
        }

        if (watchSubmissionOthers != null && watchDocumentOthers != null) {
            const PendingOthers = Number(watchSubmissionOthers) - Number(watchDocumentOthers)
            setValue('submissions_pending_others', PendingOthers);
        }

        if (watchSubmissionNPS != null && watchDocumentNPS != null) {
            const PendingNPS = Number(watchSubmissionNPS) - Number(watchDocumentNPS)
            setValue('submissions_pending_nps', PendingNPS);
        }

        if (watchSubmissionFamilyMediclaim != null && watchDocumentFamilyMediclaim != null) {
            const PendingFamilyMediclaim = Number(watchSubmissionFamilyMediclaim) - Number(watchDocumentFamilyMediclaim)
            setValue('submissions_pending_family_mediclaim', PendingFamilyMediclaim);
        }

        if (watchSubmissionParentMediclaim != null && watchDocumentParentMediclaim != null) {
            const PendingParents = Number(watchSubmissionParentMediclaim) - Number(watchDocumentParentMediclaim)
            setValue('submissions_pending_parents_mediclaim', PendingParents);
        }

        if (watchSubmissionHealthCheckUp != null && watchDocumentHealthCheckUp != null) {
            const PendingHealthCheck = Number(watchSubmissionHealthCheckUp) - Number(watchDocumentHealthCheckUp)
            setValue('submissions_pending_preventive_health_check_up', PendingHealthCheck);
        }

        if (watchSubmissionSelfEducation != null && watchDocumentSelfEducation != null) {
            const PendingSelfEducation = Number(watchSubmissionSelfEducation) - Number(watchDocumentSelfEducation)
            setValue('submissions_pending_interest_paid_on_loan_for_self_education', PendingSelfEducation);
        }

        if (watchSubmissionHousingLoan != null && watchDocumentHousingLoan != null) {
            const PendingHousingLoan = Number(watchSubmissionHousingLoan) - Number(watchDocumentHousingLoan)
            setValue('submissions_pending_interest_paid_on_housing_loan', PendingHousingLoan);
        }
        if (watchSubmissionHouseProperty != null && watchDocumentHouseProperty != null) {
            const PendingHouseProperty = Number(watchSubmissionHouseProperty) - Number(watchDocumentHouseProperty)
            setValue('submissions_pending_loss_from_house_property', PendingHouseProperty);
        }
        if (watchSubmissionHRA != null && watchDocumentHRA != null) {
            const PendingHRA = Number(watchSubmissionHRA) - Number(watchDocumentHRA)
            setValue('submissions_pending_hra', PendingHRA);
        }

        if (watchSubmissionGrandTotal != null && watchSubmissionDocumentTotal != null) {
            const PendingGrandTotal = Number(watchSubmissionGrandTotal) - Number(watchSubmissionDocumentTotal)
            setValue('submissions_pending_grand_total', PendingGrandTotal);
        }

        if (watchSubmissionLTA != null && watchDocumentLTA != null) {
            const PendingGrandTotal = Number(watchSubmissionLTA) - Number(watchDocumentLTA)
            setValue('submissions_pending_lta', PendingGrandTotal);
        }

    }, [watchSubmissionLifeInsurancePremium, watchDocumentLifeInsurancePremium, watchSubmissionPPF,
        watchDocumentPPF, watchDocumentUlipOrElssOrTaxSavingMutualFunds, watchSubmissionUlipOrElssOrTaxSavingMutualFunds,
        watchSubmissionNSC, watchDocumentNSC, watchDocumentRepaymentOfHousingLoan, watchSubmissionRepaymentOfHousingLoan,
        watchDocumenttUitionFeesForChildren, watchSubmissiontUitionFeesForChildren, watchSubmissionFiveYearFd,
        watchDocumentFiveYearFd, watchDocumentNPS, watchSubmissionNPS, watchDocumentOthers, watchSubmissionOthers,
        watchSubmissionFamilyMediclaim, watchDocumentFamilyMediclaim, watchSubmissionParentMediclaim, watchDocumentParentMediclaim,
        watchSubmissionHealthCheckUp, watchDocumentHealthCheckUp, watchSubmissionSelfEducation, watchDocumentSelfEducation,
        watchSubmissionHousingLoan, watchDocumentHousingLoan, watchSubmissionHouseProperty, watchDocumentHouseProperty,
        watchSubmissionHRA, watchDocumentHRA, watchSubmissionLTA, watchDocumentLTA, watchSubmissionGrandTotal, watchSubmissionDocumentTotal
    ]);

    useEffect(() => {
        var GrandTotal = 0;
        if (watchLifeInsurancePremium != null) {
            GrandTotal += Number(watchLifeInsurancePremium)
        }
        if (watchPPF != null) {
            GrandTotal += Number(watchPPF)
        }
        if (watchUlipOrElssOrTaxSavingMutualFunds != null) {
            GrandTotal += Number(watchUlipOrElssOrTaxSavingMutualFunds)
        }
        if (watchNSC != null) {
            GrandTotal += Number(watchNSC)
        }
        if (watchRepaymentOfHousingLoan != null) {
            GrandTotal += Number(watchRepaymentOfHousingLoan)
        }
        if (watchtUitionFeesForChildren != null) {
            GrandTotal += Number(watchtUitionFeesForChildren)
        }
        if (watchFiveYearFd != null) {
            GrandTotal += Number(watchFiveYearFd)
        }
        if (watchOthers != null) {
            GrandTotal += Number(watchOthers)
        }
        setValue('submissions_approved_grand_total', GrandTotal.toLocaleString('fullwide', { useGrouping: false }))
        setGrandTotal(GrandTotal)

    }, [watchLifeInsurancePremium, watchPPF, watchUlipOrElssOrTaxSavingMutualFunds, watchNSC, watchRepaymentOfHousingLoan, watchtUitionFeesForChildren, watchFiveYearFd, watchOthers])

    useEffect(() => {

        let ApprovedPendingLifeInsurancePremium, ApprovedPendingPPF, ApprovedPendingMutualFunds, ApprovedPendingNSC, ApprovedPendingHousingRepayment, ApprovedPendingChildrenFees, ApprovedPendingFiveYearFd, ApprovedPendingOthers, ApprovedPendingFamilyMediclaim, ApprovedPendingParents, ApprovedPendingNPS, ApprovedPendingHealthCheck, ApprovedPendingSelfEducation, ApprovedPendingHousingLoan, ApprovedPendingHouseProperty, ApprovedPendingHRA, ApprovedPendingLTA;

        if (watchLifeInsurancePremium != null) {
            ApprovedPendingLifeInsurancePremium = Number(submissionPoolDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes) - Number(watchLifeInsurancePremium)
        }

        if (watchPPF != null) {
            ApprovedPendingPPF = Number(submissionPoolDetails?.submissions?.submission_80c?.ppf) - Number(watchPPF)
        }

        if (watchUlipOrElssOrTaxSavingMutualFunds != null) {
            ApprovedPendingMutualFunds = Number(submissionPoolDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds) - Number(watchUlipOrElssOrTaxSavingMutualFunds)
        }

        if (watchNSC != null) {
            ApprovedPendingNSC = Number(submissionPoolDetails?.submissions?.submission_80c?.nsc) - Number(watchNSC)
        }

        if (watchRepaymentOfHousingLoan != null) {
            ApprovedPendingHousingRepayment = Number(submissionPoolDetails?.submissions?.submission_80c?.repayment_of_housing_loan) - Number(watchRepaymentOfHousingLoan)
        }

        if (watchtUitionFeesForChildren != null) {
            ApprovedPendingChildrenFees = Number(submissionPoolDetails?.submissions?.submission_80c?.tuition_fees_for_children) - Number(watchtUitionFeesForChildren)
        }

        if (watchFiveYearFd != null) {
            ApprovedPendingFiveYearFd = Number(submissionPoolDetails?.submissions?.submission_80c?.five_year_fd) - Number(watchFiveYearFd)
        }

        if (watchOthers != null) {
            ApprovedPendingOthers = Number(submissionPoolDetails?.submissions?.submission_80c?.others) - Number(watchOthers)
        }
        // 
        if (watchNPS != null) {
            ApprovedPendingNPS = Number(submissionPoolDetails?.submissions?.submission_80ccd?.nps) - Number(watchNPS)
        }

        if (watchFamilyMediclaim != null) {
            ApprovedPendingFamilyMediclaim = Number(submissionPoolDetails?.submissions?.submission_80d?.family_mediclaim) - Number(watchFamilyMediclaim)
        }

        if (watchParentMedicalim != null) {
            ApprovedPendingParents = Number(submissionPoolDetails?.submissions?.submission_80d?.parents_mediclaim) - Number(watchParentMedicalim)
        }

        if (watchPreventiveHealth != null) {
            ApprovedPendingHealthCheck = Number(submissionPoolDetails?.submissions?.submission_80d?.preventive_health_check_up) - Number(watchPreventiveHealth)
        }

        if (watchInterestPaid != null) {
            ApprovedPendingSelfEducation = Number(submissionPoolDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education) - Number(watchInterestPaid)
        }

        if (watchtHousingLoan != null) {
            ApprovedPendingHousingLoan = Number(submissionPoolDetails?.submissions?.submission_other?.interest_paid_on_housing_loan) - Number(watchtHousingLoan)
        }
        if (watchHouseProperty != null) {
            ApprovedPendingHouseProperty = Number(submissionPoolDetails?.submissions?.submission_other?.loss_from_house_property) - Number(watchHouseProperty)
        }
        if (watchHRA != null) {
            ApprovedPendingHRA = Number(submissionPoolDetails?.submissions?.submission_other?.hra) - Number(watchHRA)
        }
        if (watchLTA != null) {
            ApprovedPendingLTA = Number(submissionPoolDetails?.submissions?.lta) - Number(watchLTA)
        }

        const pendingValues = [
            ApprovedPendingLifeInsurancePremium,
            ApprovedPendingPPF,
            ApprovedPendingMutualFunds,
            ApprovedPendingNSC,
            ApprovedPendingHousingRepayment,
            ApprovedPendingChildrenFees,
            ApprovedPendingFiveYearFd,
            ApprovedPendingOthers,
            ApprovedPendingFamilyMediclaim,
            ApprovedPendingParents,
            ApprovedPendingHealthCheck,
            ApprovedPendingSelfEducation,
            ApprovedPendingHousingLoan,
            ApprovedPendingHouseProperty,
            ApprovedPendingHRA,
            ApprovedPendingLTA
        ];

        const hasNegativePendingValue = pendingValues.some((value) => value < 0);

        if (hasNegativePendingValue) {
            Swal.fire({
                icon: 'warning',
                text: 'Approve Amount cannot be greater than “Final Declaration Amount”.',
                confirmButtonText: "okay",
                confirmButtonColor: "text-primary",
            }).then(({ isConfirmed }) => {
            })
            $("#edit-submission-pool").prop("disabled", true);
        } else {
            $("#edit-submission-pool").prop("disabled", false);
        }


    }, [watchLifeInsurancePremium, watchPPF, watchUlipOrElssOrTaxSavingMutualFunds, watchNSC, watchRepaymentOfHousingLoan, watchtUitionFeesForChildren, watchLTA, submissionPoolDetails,
        watchFiveYearFd, watchOthers, watchNPS, watchFamilyMediclaim, watchParentMedicalim, watchPreventiveHealth, watchInterestPaid, watchtHousingLoan, watchHouseProperty, watchHRA
    ])

    const onSubmissionPoolEditUpdate = () => {
        const { SubmissionPoolEdit } = props;
        var SubmissionPoolEditResponse = Object.assign({}, SubmissionPoolEdit);
        if (SubmissionPoolEditResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            $("#edit-declaration-pool").prop("disabled", false);
            $("#edit-declaration-pool-loader").addClass("hidden");
            window.close()
            setData(KEY_TOAST_MESSAGE, SubmissionPoolEditResponse?.message);
        } else {
            $("#progress-loader").addClass("hidden");
            $("#edit-declaration-pool").prop("disabled", false);
            $("#edit-declaration-pool-loader").addClass("hidden");
            switch (SubmissionPoolEditResponse.status) {
                case ERROR:
                    toast.error(SubmissionPoolEditResponse.message);
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onSubmissionPoolEditUpdate, [props.SubmissionPoolEdit]);

    const onSubmit = (data) => {
        $("#progress-loader").removeClass("hidden");
        $("#edit-declaration-pool").prop("disabled", true);
        $("#edit-declaration-pool-loader").removeClass("hidden");

        if (submissionPoolDetails?.submissions?.regime === 'OLD') {
            data = {
                window_id: detailsData.window_id,
                regime: submissionPoolDetails?.submissions?.regime,
                user_id: detailsData?.user_id,
                finance_remark: data?.finance_remark,
                status: selectRequest,
                lta_status: data?.submissions_status_lta,
                lta_approved: data?.submissions_approved_lta,
                pf_contribution: submissionPoolDetails?.declarations?.pf_contribution,
                finance_flexible_remark: data.section_flexible_finance_remark,
                submission_80c: {
                    life_insurance_premium_or_pension_schemes_approved: data?.submissions_approved_life_insurance_premium_or_pension_schemes,
                    life_insurance_premium_or_pension_schemes_status: data?.submissions_status_life_insurance_premium_or_pension,

                    ppf_approved: data?.submissions_approved_ppf,
                    ppf_status: data?.submissions_status_ppf,

                    ulip_or_elss_or_tax_saving_mutual_funds_approved: data?.submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds,
                    ulip_or_elss_or_tax_saving_mutual_funds_status: data?.submissions_status_ulip_or_elss_or_tax_saving_mutual_funds,

                    nsc_approved: data?.submissions_approved_nsc,
                    nsc_status: data?.submissions_status_nsc,

                    repayment_of_housing_loan_approved: data?.submissions_approved_repayment_of_housing_loan,
                    repayment_of_housing_loan_status: data?.submissions_status_repayment_of_housing_loan,

                    tuition_fees_for_children_approved: data?.submissions_approved_tuition_fees_for_children,
                    tuition_fees_for_children_status: data?.submissions_status_tuition_fees_for_children,

                    five_year_fd_approved: data?.submissions_approved_five_year_fd,
                    five_year_fd_status: data?.submissions_status_five_year_fd,

                    others_approved: data?.submissions_approved_others,
                    others_status: data?.submissions_status_others,

                    grand_total_approved: grandTotal,
                    finance_remark: data?.section_80c_finance_remark

                },
                submission_80ccd: {
                    nps_approved: data?.submissions_approved_nps,
                    nps_status: data?.submissions_status_nps,
                    finance_remark: data?.section_80ccd_finance_remark,
                },
                submission_80d: {
                    family_mediclaim_approved: data?.submissions_approved_family_mediclaim,
                    family_mediclaim_status: data?.submissions_status_family_mediclaim,
                    parents_mediclaim_approved: data?.submissions_approved_parents_mediclaim,
                    parents_mediclaim_status: data?.submissions_status_parents_mediclaim,
                    preventive_health_check_up_approved: data?.submissions_approved_preventive_health_check_up,
                    preventive_health_check_up_status: data?.submissions_status_preventive_health_check_up,
                    finance_remark: data?.section_80d_finance_remark,
                },
                submission_80e: {
                    interest_paid_on_loan_for_self_education_approved: data?.submissions_approved_interest_paid_on_loan_for_self_education,
                    interest_paid_on_loan_for_self_education_status: data?.submissions_status_interest_paid_on_loan_for_self_education,
                    finance_remark: data?.section_80e_finance_remark,
                },
                submission_other: {
                    interest_paid_on_housing_loan_approved: data?.submissions_approved_interest_paid_on_housing_loan,
                    loss_from_house_property_approved: data?.submissions_approved_loss_from_house_property,
                    hra_approved: data?.submissions_approved_hra,
                    interest_paid_on_housing_loan_status: data?.submissions_status_interest_paid_on_housing_loan,
                    loss_from_house_property_status: data?.submissions_status_loss_from_house_property,
                    hra_status: data?.submissions_status_hra,
                    finance_remark: data?.section_other_finance_remark,
                },
            }
        } else {
            data = {
                window_id: detailsData.window_id,
                regime: submissionPoolDetails?.submissions?.regime,
                user_id: detailsData?.user_id,
                finance_remark: data?.finance_remark,
                pf_contribution: submissionPoolDetails?.declarations?.pf_contribution,
                status: selectRequest,
                lta_status: data?.submissions_status_lta,
                lta_approved: data?.submissions_approved_lta,
                finance_flexible_remark: data.section_flexible_finance_remark,
            }
        }
        props.actionItSubmissionPoolEdit(data)
    }

    return (
        <>
            <section>
                <ScreenTitle title="Submission Details" />
                <div className='card'>
                    <div className='card-body'>
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Employee Details
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-4 mb-1'>
                                        <label className="label-control">Employee Name</label>
                                        <input
                                            className="form-control"
                                            id="employee_name"
                                            name="employee_name"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? submissionPoolDetails?.profile?.full_name : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-4 mb-1'>
                                        <label className="label-control">Employee Id</label>
                                        <input
                                            className="form-control"
                                            id="employee_id"
                                            name="employee_id"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? submissionPoolDetails?.profile?.pinc_employee_id : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-4 mb-1'>
                                        <label className="label-control">Payroll Entity</label>
                                        <input
                                            className="form-control"
                                            id="payroll_entity"
                                            name="payroll_entity"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? submissionPoolDetails?.profile?.payroll_entity : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-4 mb-1'>
                                        <label className="label-control">Primary Department</label>
                                        <input
                                            className="form-control"
                                            id="primary_department"
                                            name="primary_department"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? submissionPoolDetails?.profile?.primary_department : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-4 mb-1'>
                                        <label className="label-control">Joining Date</label>
                                        <input
                                            className="form-control"
                                            id="joining_date"
                                            name="joining_date"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? moment(submissionPoolDetails?.profile?.joining_date).format("DD-MMM-YYYY") : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-4 mb-1'>
                                        <label className="label-control">Employee Mobile</label>
                                        <input
                                            className="form-control"
                                            id="employee_mobile"
                                            name="employee_mobile"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? submissionPoolDetails?.profile?.mobile_no : '-'}
                                        />
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-4 mb-1'>
                                        <label className="label-control">Reporting Manager</label>
                                        <input
                                            className="form-control"
                                            id="reporting_manager"
                                            name="reporting_manager"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? submissionPoolDetails?.profile?.reporting_to_full_name : '-'}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-1">
                                        <label className="label-control">Employee Branch&nbsp;<span className="danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="tax_regime"
                                            name="tax_regime"
                                            placeholder="Tax Regime"
                                            disabled={true}
                                            value={submissionPoolDetails?.profile != null ? submissionPoolDetails?.profile?.city : '-'}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-1">
                                        <label className="label-control">Submission Date&nbsp;<span className="danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="tax_regime"
                                            name="tax_regime"
                                            placeholder="Tax Regime"
                                            disabled={true}
                                            value={submissionPoolDetails?.submissions != null ? submissionPoolDetails?.submissions?.submit_on : '-'}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-1">
                                        <label className="label-control">Finance Status&nbsp;<span className="danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="tax_regime"
                                            name="tax_regime"
                                            placeholder="Tax Regime"
                                            disabled={true}
                                            value={submissionPoolDetails?.submissions != null ? submissionPoolDetails?.submissions?.status : '-'}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-1">
                                        <label className="label-control">TAX REGIME&nbsp;<span className="danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="tax_regime"
                                            name="tax_regime"
                                            placeholder="Tax Regime"
                                            disabled={true}
                                            value={submissionPoolDetails?.submissions != null ? submissionPoolDetails?.submissions?.regime : '-'}
                                        />
                                    </div>

                                </div>
                                {
                                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.regime === "OLD" ?
                                        <div>
                                            <div>
                                                <h4 className="d-flex align-items-center form-section mb-2 black bg-light-primary">
                                                    <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 C&nbsp;
                                                    <Tippy content={tooltipSection80C}>
                                                        <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                    </Tippy>
                                                </h4>
                                            </div>

                                            <div className="lic">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Life Insurance Premium / Pension Schemes&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    {/* <div className="col-12 col-md-2 col-lg-2">
                                                            <label className="label-control">Type of Investment</label>
                                                        </div> */}
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_life_insurance_premium_or_pension_schemes"
                                                                name="actual_life_insurance_premium_or_pension_schemes"
                                                                disabled={true}
                                                                {...register('actual_life_insurance_premium_or_pension_schemes', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_life_insurance_premium_or_pension_schemes"
                                                                name="submissions_life_insurance_premium_or_pension_schemes"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_life_insurance_premium_or_pension_schemes', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_life_insurance_premium_or_pension_schemes"
                                                                name="submissions_document_life_insurance_premium_or_pension_schemes"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_life_insurance_premium_or_pension_schemes', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_life_insurance_premium_or_pension_schemes"
                                                                disabled={true}
                                                                name="submissions_pending_life_insurance_premium_or_pension_schemes"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_life_insurance_premium_or_pension_schemes', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control border rounded pl-1"
                                                                id="submissions_approved_life_insurance_premium_or_pension_schemes"
                                                                name="submissions_approved_life_insurance_premium_or_pension_schemes"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                {...register('submissions_approved_life_insurance_premium_or_pension_schemes', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_life_insurance_premium_or_pension_schemes && <ErrorLabel message={errors.submissions_approved_life_insurance_premium_or_pension_schemes?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_life_insurance_premium_or_pension"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusInsurance(event.itemData?.value)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_life_insurance_premium_or_pension && <ErrorLabel message={errors.submissions_status_life_insurance_premium_or_pension?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ppf">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Public Provident Fund (PPF)&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    {/* <div className="col-12 col-md-2 col-lg-2">
                                                            <label className="label-control">Type of Investment</label>
                                                        </div> */}
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Public Provident Fund (PPF)&nbsp;<span className="danger">*</span></label>
                                                    </div> */}
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_ppf"
                                                                name="actual_ppf"
                                                                disabled={true}
                                                                {...register('actual_ppf', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_ppf"
                                                                name="submissions_ppf"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_ppf', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_ppf"
                                                                name="submissions_document_ppf"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_ppf', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_ppf"
                                                                disabled={true}
                                                                name="submissions_pending_ppf"
                                                                placeholder="Enter 0 if Nothing to Declare text-bold-600"
                                                                {...register('submissions_pending_ppf', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_ppf"
                                                                name="submissions_approved_ppf"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                {...register('submissions_approved_ppf', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_ppf && <ErrorLabel message={errors.submissions_approved_ppf?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_ppf"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusPPF(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_ppf && <ErrorLabel message={errors.submissions_status_ppf?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mutual_fund">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">ULIP / ELSS / Tax saving Mutual Funds&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row  mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                name="actual_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                disabled={true}
                                                                {...register('actual_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                name="submissions_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                name="submissions_document_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                disabled={true}
                                                                name="submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                name="submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                {...register('submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds && <ErrorLabel message={errors.submissions_approved_ulip_or_elss_or_tax_saving_mutual_funds?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_ulip_or_elss_or_tax_saving_mutual_funds"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusMutualFunds(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_ulip_or_elss_or_tax_saving_mutual_funds && <ErrorLabel message={errors.submissions_status_ulip_or_elss_or_tax_saving_mutual_funds?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nsc">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">NSC&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_nsc"
                                                                name="actual_nsc"
                                                                disabled={true}
                                                                {...register('actual_nsc', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_nsc"
                                                                name="submissions_nsc"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_nsc', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_nsc"
                                                                name="submissions_document_nsc"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_nsc', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_nsc"
                                                                disabled={true}
                                                                name="submissions_pending_nsc"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_nsc', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_nsc"
                                                                name="submissions_approved_nsc"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                {...register('submissions_approved_nsc', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_nsc && <ErrorLabel message={errors.submissions_approved_nsc?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_nsc"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusNSC(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_nsc && <ErrorLabel message={errors.submissions_status_nsc?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="housing_loan">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Repayment of Housing Loan (Principal Amount)&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row  mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_repayment_of_housing_loan"
                                                                name="actual_repayment_of_housing_loan"
                                                                disabled={true}
                                                                {...register('actual_repayment_of_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_repayment_of_housing_loan"
                                                                name="submissions_repayment_of_housing_loan"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_repayment_of_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_repayment_of_housing_loan"
                                                                name="submissions_document_repayment_of_housing_loan"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_repayment_of_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_repayment_of_housing_loan"
                                                                disabled={true}
                                                                name="submissions_pending_repayment_of_housing_loan"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_repayment_of_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_repayment_of_housing_loan"
                                                                name="submissions_approved_repayment_of_housing_loan"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_repayment_of_housing_loan', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_repayment_of_housing_loan && <ErrorLabel message={errors.submissions_approved_repayment_of_housing_loan?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_repayment_of_housing_loan"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusRepaymentOfHousingLoan(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_repayment_of_housing_loan && <ErrorLabel message={errors.submissions_status_repayment_of_housing_loan?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tuition_fees">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Tuition Fees for Children (Max for 2 children)&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_tuition_fees_for_children"
                                                                name="actual_tuition_fees_for_children"
                                                                disabled={true}
                                                                {...register('actual_tuition_fees_for_children', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_tuition_fees_for_children"
                                                                name="submissions_tuition_fees_for_children"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_tuition_fees_for_children', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_tuition_fees_for_children"
                                                                name="submissions_document_tuition_fees_for_children"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_tuition_fees_for_children', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_tuition_fees_for_children"
                                                                disabled={true}
                                                                name="submissions_pending_tuition_fees_for_children"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_tuition_fees_for_children', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_tuition_fees_for_children"
                                                                name="submissions_approved_tuition_fees_for_children"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                {...register('submissions_approved_tuition_fees_for_children', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_tuition_fees_for_children && <ErrorLabel message={errors.submissions_approved_tuition_fees_for_children?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_tuition_fees_for_children"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusTuitionFees(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_tuition_fees_for_children && <ErrorLabel message={errors.submissions_status_tuition_fees_for_children?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="five_year_fd">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">5 year Fixed Deposits (with specified banks)&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row  mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_five_year_fd"
                                                                name="actual_five_year_fd"
                                                                disabled={true}
                                                                {...register('actual_five_year_fd', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_five_year_fd"
                                                                name="submissions_five_year_fd"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_five_year_fd', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_five_year_fd"
                                                                name="submissions_document_five_year_fd"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_five_year_fd', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_five_year_fd"
                                                                disabled={true}
                                                                name="submissions_pending_five_year_fd"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_five_year_fd', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_five_year_fd"
                                                                name="submissions_approved_five_year_fd"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                {...register('submissions_approved_five_year_fd', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_five_year_fd && <ErrorLabel message={errors.submissions_approved_five_year_fd?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_five_year_fd"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusFiveYearFD(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_five_year_fd && <ErrorLabel message={errors.submissions_status_five_year_fd?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="other">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Others&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row  mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="actual_others"
                                                                name="actual_others"
                                                                disabled={true}
                                                                {...register('actual_others', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_others"
                                                                name="submissions_others"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_others', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_others"
                                                                name="submissions_document_others"
                                                                disabled={true}
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_others', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_others"
                                                                disabled={true}
                                                                name="submissions_pending_others"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_others', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_others"
                                                                name="submissions_approved_others"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/^[0-9.]*$/.test(event.key)) {
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                {...register('submissions_approved_others', {
                                                                    required: 'Please Enter 0 if Nothing to Declare',
                                                                    pattern: {
                                                                        value: AMOUNT_REGEX,
                                                                        message: 'Numeric Field (up to 2 decimals allowed)',
                                                                    }
                                                                })}
                                                            />
                                                            {errors.submissions_approved_others && <ErrorLabel message={errors.submissions_approved_others?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_others"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusOthers(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_others && <ErrorLabel message={errors.submissions_status_others?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="total">
                                                <div className=" border-bottom ">
                                                    <label className="label-control text-bold-600 text-primary mb-0">Grand Total</label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_grand_total"
                                                                name="actual_grand_total"
                                                                disabled={true}
                                                                type="number"
                                                                placeholder="-"
                                                                readOnly={true}
                                                                {...register('actual_grand_total', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_grand_total"
                                                                name="submissions_grand_total"
                                                                disabled={true}
                                                                type="number"
                                                                placeholder="Enter Grand Total"
                                                                readOnly={true}
                                                                {...register('submissions_grand_total', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_grand_total"
                                                                name="submissions_document_grand_total"
                                                                disabled={true}
                                                                type="number"
                                                                placeholder="Enter Grand Total"
                                                                readOnly={true}
                                                                {...register('submissions_document_grand_total', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_pending_grand_total"
                                                                disabled={true}
                                                                name="submissions_pending_grand_total"
                                                                type="number"
                                                                placeholder="Enter Grand Total"
                                                                readOnly={true}
                                                                {...register('submissions_pending_grand_total', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_grand_total"
                                                                name="submissions_approved_grand_total"
                                                                disabled={true}
                                                                type="number"
                                                                placeholder="Enter Grand Total"
                                                                readOnly={true}
                                                                {...register('submissions_approved_grand_total', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section remark">
                                                <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control bg-white border rounded pl-1"
                                                        id="section_80c_finance_remark"
                                                        name="section_80c_finance_remark"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Finance Remark"
                                                        {...register('section_80c_finance_remark', {
                                                            required: 'Please Enter Remark'
                                                        })}
                                                    />
                                                    {errors.section_80c_finance_remark && <ErrorLabel message={errors.section_80c_finance_remark?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                <div className="epf">
                                    <h4 className="d-flex align-items-center form-section mb-2 black bg-light-primary">
                                        <GrPlan size={16} />&nbsp;EPF Contribution
                                    </h4>
                                    <div className="">
                                        <label className="label-control text-bold-600 mb-0">PF Contribution&nbsp;<span className="danger">*</span></label>
                                    </div>
                                    <div className="row mt-half">
                                        <div className="col-12 col-md-2 col-lg-2">
                                            <label className="label-control">Actual Declaration</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2 ">
                                            <div className="form-group">
                                                <input
                                                    className="form-control bg-white border-0 "
                                                    id="actual_pf_contribution"
                                                    name="actual_pf_contribution"
                                                    disabled={true}
                                                    type="text"
                                                    {...register('actual_pf_contribution', {
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    submissionPoolDetails != null && submissionPoolDetails?.submissions?.regime === "OLD" ?
                                        <>
                                            <h4 className="d-flex align-items-center form-section mb-2 black bg-light-primary">
                                                <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 CCD (1B)&nbsp;
                                                <Tippy content={tooltipSection80CCD}>
                                                    <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                </Tippy>
                                            </h4>
                                            <div className="nps">
                                                <div className="py-1">
                                                    <label className="label-control text-bold-600 mb-0">National Pension Scheme&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_nps"
                                                                name="actual_nps"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_nps', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_nps"
                                                                name="submissions_nps"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_nps', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_nps"
                                                                name="submissions_document_nps"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_nps', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_nps"
                                                                disabled={true}
                                                                name="submissions_pending_nps"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_nps', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_nps"
                                                                name="submissions_approved_nps"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_nps', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_nps && <ErrorLabel message={errors.submissions_approved_nps?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_nps"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusNPS(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_nps && <ErrorLabel message={errors.submissions_status_nps?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section remark">
                                                <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control bg-white border rounded pl-1 "
                                                        id="section_80ccd_finance_remark"
                                                        name="section_80ccd_finance_remark"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Finance Remark"
                                                        {...register('section_80ccd_finance_remark', {
                                                            required: 'Please Enter Remark'
                                                        })}
                                                    />
                                                    {errors.section_80ccd_finance_remark && <ErrorLabel message={errors.section_80ccd_finance_remark?.message} />}
                                                </div>
                                            </div>
                                            <h4 className="d-flex align-items-center form-section mb-2 black bg-light-primary">
                                                <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 D&nbsp;
                                                <Tippy content={tooltipSection80D}>
                                                    <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                </Tippy>
                                            </h4>

                                            <div className="family">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Family (Self, Spouse & Children)&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_family_mediclaim"
                                                                name="actual_family_mediclaim"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_family_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_family_mediclaim"
                                                                name="submissions_family_mediclaim"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_family_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_family_mediclaim"
                                                                name="submissions_document_family_mediclaim"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_family_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_family_mediclaim"
                                                                disabled={true}
                                                                name="submissions_pending_family_mediclaim"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_family_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded text-bold-0 pl-1"
                                                                id="submissions_approved_family_mediclaim"
                                                                name="submissions_approved_family_mediclaim"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_family_mediclaim', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_family_mediclaim && <ErrorLabel message={errors.submissions_approved_family_mediclaim?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_family_mediclaim"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusFamilyMediclaim(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_family_mediclaim && <ErrorLabel message={errors.submissions_status_family_mediclaim?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="parent">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Parents&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_parents_mediclaim"
                                                                name="actual_parents_mediclaim"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_parents_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_parents_mediclaim"
                                                                name="submissions_parents_mediclaim"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_parents_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_parents_mediclaim"
                                                                name="submissions_document_parents_mediclaim"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_parents_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_parents_mediclaim"
                                                                disabled={true}
                                                                name="submissions_pending_parents_mediclaim"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_parents_mediclaim', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_parents_mediclaim"
                                                                name="submissions_approved_parents_mediclaim"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_parents_mediclaim', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_parents_mediclaim && <ErrorLabel message={errors.submissions_approved_parents_mediclaim?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_parents_mediclaim"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusParentMediclaim(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_parents_mediclaim && <ErrorLabel message={errors.submissions_status_parents_mediclaim?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Preventive Health Check up&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_preventive_health_check_up"
                                                                name="actual_preventive_health_check_up"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_preventive_health_check_up', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_preventive_health_check_up"
                                                                name="submissions_preventive_health_check_up"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_preventive_health_check_up', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_preventive_health_check_up"
                                                                name="submissions_document_preventive_health_check_up"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_preventive_health_check_up', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_preventive_health_check_up"
                                                                disabled={true}
                                                                name="submissions_pending_preventive_health_check_up"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_preventive_health_check_up', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_preventive_health_check_up"
                                                                name="submissions_approved_preventive_health_check_up"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_preventive_health_check_up', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_preventive_health_check_up && <ErrorLabel message={errors.submissions_approved_preventive_health_check_up?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_preventive_health_check_up"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusPreventiveHealthCheckup(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_preventive_health_check_up && <ErrorLabel message={errors.submissions_status_preventive_health_check_up?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section remark">
                                                <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control bg-white border rounded pl-1"
                                                        id="section_80d_finance_remark"
                                                        name="section_80d_finance_remark"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Finance Remark"
                                                        {...register('section_80d_finance_remark', {
                                                            required: 'Please Enter Remark'
                                                        })}
                                                    />
                                                    {errors.section_80d_finance_remark && <ErrorLabel message={errors.section_80d_finance_remark?.message} />}
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            {/* <div className="col-12 col-md-12 col-lg-12"> */}
                                            <h4 className="d-flex align-items-center form-section mb-2 black bg-light-primary ">
                                                <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 E
                                            </h4>
                                            <div className="self_education">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Interest Paid on Loan for Self-Education&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_interest_paid_on_loan_for_self_education"
                                                                name="actual_interest_paid_on_loan_for_self_education"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_interest_paid_on_loan_for_self_education', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_interest_paid_on_loan_for_self_education"
                                                                name="submissions_interest_paid_on_loan_for_self_education"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_interest_paid_on_loan_for_self_education', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_interest_paid_on_loan_for_self_education"
                                                                name="submissions_document_interest_paid_on_loan_for_self_education"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_interest_paid_on_loan_for_self_education', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_interest_paid_on_loan_for_self_education"
                                                                disabled={true}
                                                                name="submissions_pending_interest_paid_on_loan_for_self_education"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_interest_paid_on_loan_for_self_education', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_interest_paid_on_loan_for_self_education"
                                                                name="submissions_approved_interest_paid_on_loan_for_self_education"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_interest_paid_on_loan_for_self_education', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_interest_paid_on_loan_for_self_education && <ErrorLabel message={errors.submissions_approved_interest_paid_on_loan_for_self_education?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_interest_paid_on_loan_for_self_education"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusSelfEducation(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_interest_paid_on_loan_for_self_education && <ErrorLabel message={errors.submissions_status_interest_paid_on_loan_for_self_education?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="section remark">
                                                    <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                                    <div className="form-group">
                                                        <textarea
                                                            className="form-control bg-white border rounded pl-1"
                                                            id="section_80e_finance_remark"
                                                            name="section_80e_finance_remark"
                                                            type="text"
                                                            maxLength={200}
                                                            placeholder="Enter Finance Remark"
                                                            {...register('section_80e_finance_remark', {
                                                                required: 'Please Enter Remark'
                                                            })}
                                                        />
                                                        {errors.section_80e_finance_remark && <ErrorLabel message={errors.section_80e_finance_remark?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="d-flex align-items-center form-section mb-2 black bg-light-primary">
                                                <GrPlan size={16} />&nbsp;Other Declarations&nbsp;
                                                <Tippy content={tooltipOthers}>
                                                    <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                </Tippy>
                                            </h4>
                                            <div className="housing_loan">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Interest Paid on Housing Loan&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_interest_paid_on_housing_loan"
                                                                name="actual_interest_paid_on_housing_loan"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_interest_paid_on_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_interest_paid_on_housing_loan"
                                                                name="submissions_interest_paid_on_housing_loan"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_interest_paid_on_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_interest_paid_on_housing_loan"
                                                                name="submissions_document_interest_paid_on_housing_loan"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_interest_paid_on_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_interest_paid_on_housing_loan"
                                                                disabled={true}
                                                                name="submissions_pending_interest_paid_on_housing_loan"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_interest_paid_on_housing_loan', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_interest_paid_on_housing_loan"
                                                                name="submissions_approved_interest_paid_on_housing_loan"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_interest_paid_on_housing_loan', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_interest_paid_on_housing_loan && <ErrorLabel message={errors.submissions_approved_interest_paid_on_housing_loan?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_interest_paid_on_housing_loan"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusHousingLoan(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_interest_paid_on_housing_loan && <ErrorLabel message={errors.submissions_status_interest_paid_on_housing_loan?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="housing_property">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Loss from House Property&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_loss_from_house_property"
                                                                name="actual_loss_from_house_property"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_loss_from_house_property', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_loss_from_house_property"
                                                                name="submissions_loss_from_house_property"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_loss_from_house_property', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_loss_from_house_property"
                                                                name="submissions_document_loss_from_house_property"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_loss_from_house_property', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_loss_from_house_property"
                                                                disabled={true}
                                                                name="submissions_pending_loss_from_house_property"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_loss_from_house_property', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group ">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_loss_from_house_property"
                                                                name="submissions_approved_loss_from_house_property"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_loss_from_house_property', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_loss_from_house_property && <ErrorLabel message={errors.submissions_approved_loss_from_house_property?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_loss_from_house_property"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },

                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusHouseProperty(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_loss_from_house_property && <ErrorLabel message={errors.submissions_status_loss_from_house_property?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hra">
                                                <div className="">
                                                    <label className="label-control text-bold-600 mb-0">Rent Paid (HRA) Annually&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="row mt-half">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Actual Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Final Declaration</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Document Submitted</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Pending Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Approved Amount</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <label className="label-control">Status</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="actual_hra"
                                                                name="actual_hra"
                                                                disabled={true}
                                                                type="text"
                                                                {...register('actual_hra', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_hra"
                                                                name="submissions_hra"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_hra', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0"
                                                                id="submissions_document_hra"
                                                                name="submissions_document_hra"
                                                                disabled={true}
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_document_hra', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border-0 text-bold-600"
                                                                id="submissions_pending_hra"
                                                                disabled={true}
                                                                name="submissions_pending_hra"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                {...register('submissions_pending_hra', {
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control bg-white border rounded pl-1"
                                                                id="submissions_approved_hra"
                                                                name="submissions_approved_hra"
                                                                type="text"
                                                                placeholder="Enter 0 if Nothing to Declare"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('submissions_approved_hra', {
                                                                    required: 'Please Enter 0 if Nothing to Declare'
                                                                })}
                                                            />
                                                            {errors.submissions_approved_hra && <ErrorLabel message={errors.submissions_approved_hra?.message} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <Controller
                                                                name="submissions_status_hra"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={[
                                                                            {
                                                                                "title": "Approved",
                                                                                "value": "Approved"
                                                                            },
                                                                            {
                                                                                "title": "Partially Approved",
                                                                                "value": "Partially Approved"
                                                                            },
                                                                            {
                                                                                "title": "Rejected",
                                                                                "value": "Rejected"
                                                                            },
                                                                        ]}
                                                                        fields={{ text: 'title', value: 'value' }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData?.value);
                                                                                setStatusHRA(event.itemData?.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Status'
                                                                }}
                                                            />
                                                            {errors.submissions_status_hra && <ErrorLabel message={errors.submissions_status_hra?.message} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section remark">
                                                <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control bg-white border rounded pl-1"
                                                        id="section_other_finance_remark"
                                                        name="section_other_finance_remark"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Finance Remark"
                                                        {...register('section_other_finance_remark', {
                                                            required: 'Please Enter Remark'
                                                        })}
                                                    />
                                                    {errors.section_other_finance_remark && <ErrorLabel message={errors.section_other_finance_remark?.message} />}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                <h4 className="d-flex align-items-center form-section mb-2 black bg-light-primary">
                                    <GrPlan size={16} />&nbsp;Flexible Plan Details&nbsp;
                                    <Tippy content={tooltipFlexible}>
                                        <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                    </Tippy>
                                </h4>
                                <div className="">
                                    <label className="label-control text-bold-600 mb-0">LTA&nbsp;<span className="danger">*</span></label>
                                </div>
                                <div className="row mt-half">

                                    <div className="col-12 col-md-2 col-lg-2">
                                        <label className="label-control">Actual Declaration</label>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <label className="label-control">Final Declaration</label>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <label className="label-control">Document Submitted</label>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <label className="label-control">Pending Amount</label>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <label className="label-control">Approved Amount</label>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <label className="label-control">Status</label>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <input
                                                className="form-control bg-white border-0"
                                                id="actual_lta"
                                                name="actual_lta"
                                                disabled={true}
                                                type="text"
                                                placeholder="Enter LTA"
                                                {...register('actual_lta', {
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <input
                                                className="form-control bg-white border-0"
                                                id="submissions_lta"
                                                name="submissions_lta"
                                                disabled={true}
                                                type="text"
                                                placeholder="0"
                                                {...register('submissions_lta', {
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <input
                                                className="form-control bg-white border-0"
                                                id="submissions_document_lta"
                                                name="submissions_document_lta"
                                                disabled={true}
                                                type="text"
                                                placeholder="0"
                                                {...register('submissions_document_lta', {
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <input
                                                className="form-control bg-white border-0"
                                                id="submissions_pending_lta"
                                                disabled={true}
                                                name="submissions_pending_lta"
                                                type="text"
                                                placeholder="0"
                                                {...register('submissions_pending_lta', {
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <input
                                                className="form-control bg-white border rounded pl-1"
                                                id="submissions_approved_lta"
                                                name="submissions_approved_lta"
                                                type="text"
                                                placeholder="Enter LTA"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register('submissions_approved_lta', {
                                                    required: 'Please Enter LTA'
                                                })}
                                            />
                                            {errors.submissions_approved_lta && <ErrorLabel message={errors.submissions_approved_lta?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <Controller
                                                name="submissions_status_lta"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={[
                                                            {
                                                                "title": "Approved",
                                                                "value": "Approved"
                                                            },
                                                            {
                                                                "title": "Partially Approved",
                                                                "value": "Partially Approved"
                                                            },
                                                            {
                                                                "title": "Rejected",
                                                                "value": "Rejected"
                                                            },
                                                        ]}
                                                        fields={{ text: 'title', value: 'value' }}
                                                        allowFiltering={false}
                                                        placeholder="Select Status"
                                                        value={field.value}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData?.value);
                                                                setStatusLTA(event.itemData?.value);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Select Status'
                                                }}
                                            />
                                            {errors.submissions_status_lta && <ErrorLabel message={errors.submissions_status_lta?.message} />}
                                        </div>
                                    </div>

                                </div>
                                <div className="section remark">
                                    <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                    <div className="form-group">
                                        <textarea
                                            className="form-control bg-white border rounded pl-1"
                                            id="section_flexible_finance_remark"
                                            name="section_flexible_finance_remark"
                                            type="text"
                                            maxLength={200}
                                            placeholder="Enter Finance Remark"
                                            {...register('section_flexible_finance_remark', {
                                                required: 'Please Enter Remark'
                                            })}
                                        />
                                        {errors.section_flexible_finance_remark && <ErrorLabel message={errors.section_flexible_finance_remark?.message} />}
                                    </div>
                                </div>
                                {/* </div> */}
                                <div className="">
                                    <h4 className="d-flex align-items-center form-section mb-2 black" />
                                    <div className="row mt-1">

                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Remarks</label>
                                                <input
                                                    className="form-control"
                                                    id="remarks"
                                                    name="remarks"
                                                    type="text"
                                                    maxLength={200}
                                                    disabled={true}
                                                    value={submissionPoolDetails != null ? submissionPoolDetails?.submissions?.emp_remark : '-'}
                                                    placeholder="Enter Remarks"
                                                    {...register('remarks')}
                                                />
                                                {errors.remarks && <ErrorLabel message={errors.remarks?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Finance Action&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={[
                                                                {
                                                                    "title": "Request Changes",
                                                                    "value": "REJECTED"
                                                                },
                                                                {
                                                                    "title": "Not Required",
                                                                    "value": "ACCEPTED"
                                                                }
                                                            ]}
                                                            fields={{ text: 'title', value: 'value' }}
                                                            allowFiltering={false}
                                                            enabled={false}
                                                            placeholder="Select Finance Action"
                                                            value={field.value}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData?.value);
                                                                    if (event.itemData?.value === 'ACCEPTED') {
                                                                    }
                                                                    setSelectRequest(event.itemData?.value);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <label className="label-control">Finance Remark&nbsp;<span className="danger">*</span></label>
                                    <div className="form-group">
                                        <textarea
                                            className="form-control bg-white border rounded pl-1"
                                            id="finance_remark"
                                            name="finance_remark"
                                            type="text"
                                            maxLength={200}
                                            placeholder="Enter Finance Remark"
                                            {...register('finance_remark', {
                                                required: 'Please Enter Remark'
                                            })}
                                        />
                                        {errors.finance_remark && <ErrorLabel message={errors.finance_remark?.message} />}
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions border-0">
                                <button
                                    id="edit-submission-pool"
                                    className="btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        Submit
                                        <span id="edit-submission-pool-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                                <button className="btn btn-outline-secondary shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        window.close()
                                    }}
                                > Back</button>
                            </div>
                        </form>
                    </div>

                </div >
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var SubmissionPoolEdit = null;
    var ItSubmissionPoolDetail = null;
    if (
        state.ItSubmissionPoolEditReducer != null &&
        Object.keys(state.ItSubmissionPoolEditReducer).length > 0 &&
        state.ItSubmissionPoolEditReducer.constructor === Object
    ) {
        switch (state.ItSubmissionPoolEditReducer.tag) {
            case ACTION_IT_SUBMISSION_POOL_EDIT_SUCCESS:
                SubmissionPoolEdit = Object.assign({}, state.ItSubmissionPoolEditReducer.data);
                delete state.ItSubmissionPoolEditReducer;
                return { SubmissionPoolEdit };
            case ACTION_IT_SUBMISSION_POOL_DETAIL_SUCCESS:
                ItSubmissionPoolDetail = Object.assign({}, state.ItSubmissionPoolEditReducer.data);
                delete state.ItSubmissionPoolEditReducer;
                return { ItSubmissionPoolDetail };
            default:
        }
    }

    return {
        SubmissionPoolEdit,
        ItSubmissionPoolDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItSubmissionPoolEdit, actionItSubmissionPoolDetail }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(SubmissionPoolEdit))

