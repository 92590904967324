import { ACTION_RI_MEETING_EDIT_SUCCESS, ACTION_REINSURANCE_CLIENT_SUCCESS, ACTION_RI_GET_CONTACTS_SUCCESS, ACTION_ADD_GET_RI_NAME_SUCCESS, ACTION_RI_MEETING_ADD_CONTACT_SUCCESS, ACTION_RI_MEETING_VIEW_SUCCESS } from "./action";
import { ACTION_MASTER_COUNTRY_SUCCESS, ACTION_RI_ACCOMPAINED_BY_SUCCESS, ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS } from "./action";
import {ACTION_CLIENT_TYPE_SUCCESS, ACTION_DOCUMENT_DELETE_SUCCESS} from "./action";
import {ACTION_CLIENT_SUCCESS} from "./action";
import {ACTION_RI_MEETING_SAVE_SUCCESS} from "./action";
import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const RiMeetingsEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_RI_MEETING_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_MEETING_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_COUNTRY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_COUNTRY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REINSURANCE_CLIENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REINSURANCE_CLIENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_GET_CONTACTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_GET_CONTACTS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ADD_GET_RI_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_GET_RI_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_MEETING_ADD_CONTACT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_MEETING_ADD_CONTACT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_MEETING_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_MEETING_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DOCUMENT_DELETE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DOCUMENT_DELETE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_MEETING_SAVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_MEETING_SAVE_SUCCESS,
                loading: false,
            }
        );
    },
});
export default RiMeetingsEditReducer;