
import Api from '../../../../../../api';
import { fetchSuccess, loading } from './../../../../../../utils/action.util';
import { BIRTHDAY_DELETE, BIRTHDAY_LIST } from '../../../../../../api/constants'
import { PRINT } from './../../../../../../utils/index';

export const ACTION_BIRTHDAY_WISH_LIST_LOADING = 'ACTION_BIRTHDAY_WISH_LIST_LOADING';
export const ACTION_BIRTHDAY_WISH_LIST_SUCCESS = 'ACTION_BIRTHDAY_WISH_LIST_SUCCESS';

export const ACTION_BIRTHDAY_DELETE_SUCCESS = 'ACTION_BIRTHDAY_DELETE_SUCCESS';
export const ACTION_BIRTHDAY_DELETE_LOADING = 'ACTION_BIRTHDAY_DELETE_LOADING';

export function actionBirthdayWishList(params) {
    return (dispatch, getState) =>
        {
          var paramsFinal = params ? '?dob_month='+params:''
            Api.get(BIRTHDAY_LIST+paramsFinal).then((res) => {
                const data = res;
                PRINT('ACTION RESPONSE', data)
                dispatch(fetchSuccess(ACTION_BIRTHDAY_WISH_LIST_SUCCESS, { data }));
            }).catch((reason) => {
                /** Handle Error */
                var data = {
                    message: ''
                }
                dispatch(loading(ACTION_BIRTHDAY_WISH_LIST_LOADING, { loading: false, data }));
            });
        }
        
}

export function actionBirthdayWishReacordDelete(params) {
    return (dispatch) => 
        Api.post(BIRTHDAY_DELETE, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_BIRTHDAY_DELETE_SUCCESS, { data }));
        }).catch((reson) => {
            // Handle Error
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BIRTHDAY_DELETE_LOADING, { loading: false, data}));
        });
}