import Api from "../../../../../api";
import { fetchSuccess, loading } from "../../../../../utils/action.util";
import {
  API_GET_POST_WITH_COMMENTS,
  API_POST_CREATE_COMMENT,
  API_POST_DELETE,
  API_POST_LIKE_DISLIKE,
  API_POST_COMMENT_LIKE,
  API_POST_COMMENT_DELETE,
  API_POST_COMMENT_EDIT,
  API_GET_TICKER
} from "../../../../../api/constants";
import { PRINT } from "../../../../../utils";

export const ACTION_HOME_ACTIVITYFEEDLIST_LOADING = "ACTION_HOME_ACTIVITYFEEDLIST_LOADING";
export const ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS ="ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS";
export const ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS = 'ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS';
export const ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS = 'ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS';
export const ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS = 'ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS';
export const ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS = 'ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS';
export const ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS = 'ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS';
export const ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS = 'ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS';

export const ACTION_HOME_GET_TICKER_LOADING = "ACTION_HOME_GET_TICKER_DETAILS_LOADING";
export const ACTION_HOME_GET_TICKER_SUCCESS = "ACTION_HOME_GET_TICKER_DETAILS_SUCCESS";

export function actionHomeActivityFeedList() {
  return (dispatch, getState) =>
    Api.post(API_GET_POST_WITH_COMMENTS, {})
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionHomeActivityFeedLike(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_LIKE_DISLIKE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionHomeActivityFeedDelete(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_DELETE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionHomeActivityFeedCreateComment(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_CREATE_COMMENT, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionHomeActivityFeedCommentLike(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_LIKE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionHomeActivityFeedCommentDelete(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_DELETE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionHomeActivityFeedCommentEdit(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_EDIT, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionHomeGetTicker(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_TICKER, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_HOME_GET_TICKER_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_HOME_GET_TICKER_LOADING, { loading: false, data }));
      });
}