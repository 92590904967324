import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CRON_STATISTICS } from "../../../../../api/constants";
import { API_CRON_DASHBOARD } from "../../../../../api/constants";
import { PRINT } from '../../../../../utils';

export const ACTION_CRON_DASHBOARD_LOADING = 'ACTION_CRON_DASHBOARD_LOADING';
export const ACTION_CRON_DASHBOARD_SUCCESS = 'ACTION_CRON_DASHBOARD_SUCCESS';

export const ACTION_CRON_STATISTICS_LOADING = 'ACTION_CRON_STATISTICS_LOADING';
export const ACTION_CRON_STATISTICS_SUCCESS = 'ACTION_CRON_STATISTICS_SUCCESS';

export function actionCronDashboard() {
    return (dispatch, getState) =>
        Api.get(API_CRON_DASHBOARD).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CRON_DASHBOARD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CRON_DASHBOARD_LOADING, { loading: false, data }));
        });
}
export function actionCronStatistics() {
    return (dispatch, getState) =>
        Api.get(API_CRON_STATISTICS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CRON_STATISTICS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CRON_STATISTICS_LOADING, { loading: false, data }));
        });
}