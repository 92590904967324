import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REINSURANCE_ENQUIRY_DETAIL, API_REINSURANCE_ENQUIRY_MASTER, API_REINSURANCE_ENQUIRY_EDIT } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_REINSURANCE_ENQUIRY_DETAIL_LOADING = 'ACTION_REINSURANCE_ENQUIRY_DETAIL_LOADING';
export const ACTION_REINSURANCE_ENQUIRY_DETAIL_SUCCESS = 'ACTION_REINSURANCE_ENQUIRY_DETAIL_SUCCESS';

export const ACTION_REINSURANCE_ENQUIRY_UPDATE_LOADING = 'ACTION_REINSURANCE_ENQUIRY_UPDATE_LOADING';
export const ACTION_REINSURANCE_ENQUIRY_UPDATE_SUCCESS = 'ACTION_REINSURANCE_ENQUIRY_UPDATE_SUCCESS';

export const ACTION_REINSURANCE_ENQUIRY_MASTER_LOADING = 'ACTION_REINSURANCE_ENQUIRY_MASTER_LOADING';
export const ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS = 'ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS';

export function actionReinsuranceEnquiryDetail(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE_ENQUIRY_DETAIL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_ENQUIRY_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            console.log("ERROR", reason);
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_ENQUIRY_DETAIL_LOADING, { loading: false, data }));
        });
}

export function actionReinsuranceEnquiryMaster() {
    return (dispatch, getState) =>
        Api.get(API_REINSURANCE_ENQUIRY_MASTER).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_ENQUIRY_MASTER_LOADING, { loading: false, data }));
        });
}

export function actionReinsuranceEnquiryUpdate(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE_ENQUIRY_EDIT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_ENQUIRY_UPDATE_SUCCESS, { data }));
        }).catch((reason) => {
            console.log("ERROR", reason);
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_ENQUIRY_UPDATE_LOADING, { loading: false, data }));
        });
}