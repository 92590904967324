import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { actionTravelCalendarSettingList, ACTION_CALENDAR_SETTING_LIST_SUCCESS } from "./action";
import { actionTravelCalendarSettingDelete, ACTION_CALENDAR_SETTING_DELETE_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../utils";
import Swal from "sweetalert2";

const TravelCalendarSettingList = (props) => {
    const { match } = props;
    const [travelCalendarSettingList, setTravelCalendarSettingList] = useState([]);
    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "user_details.full_name", caption: "Employee Name" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionTravelCalendarSettingList();
    }
    useEffect(initialRender, []);

    const onTravelCalendarSettingListUpdate = () => {
        const { TravelCalendarSettingList } = props
        if (TravelCalendarSettingList != null) {
            var TravelCalendarSettingListResponse = Object.assign({}, TravelCalendarSettingList);
            if (TravelCalendarSettingListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setTravelCalendarSettingList(TravelCalendarSettingListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (TravelCalendarSettingListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onTravelCalendarSettingListUpdate, [props.TravelCalendarSettingList])

    const onTravelCalendarSettingDeleteUpdate = () => {
        const { TravelCalendarSettingDelete } = props
        if (TravelCalendarSettingDelete != null) {
            var TravelCalendarSettingDeleteResponse = Object.assign({}, TravelCalendarSettingDelete);
            if (TravelCalendarSettingDeleteResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                toast.success(TravelCalendarSettingDeleteResponse.message);
                props.actionTravelCalendarSettingList();
            } else {
                $("#progress-loader").addClass("hidden");
                switch (TravelCalendarSettingDeleteResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onTravelCalendarSettingDeleteUpdate, [props.TravelCalendarSettingDelete])

    // const renderEmployeeNameCell = (cell) => {
    //     if (cell?.data?.full_name != null) {
    //         return <a
    //         className='text-primary'
    //         onClick={() => {
    //             props.history.push({
    //                 pathname: '/profile',
    //                 state: {
    //                     user_id: cell?.data?.user_id,
    //                 }
    //             })
    //         }}
    //         ><u>{cell?.data?.full_name}</u></a>
    //     }
    // }
    
    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
          if (objColumn.dataField === "Action") {
            arrColumns.push(
              <Column dataField="action" type="buttons">
                {/* {verifyPermission("EDIT_TRAVEL") ? ( */}
                <Button
                  render={(params) => (
                    <button
                      className="btn btn-icon btn-sm"
                      onClick={() => {
                        props.history.push({
                          pathname: `${match.url}/update`,
                          state: { data: params.data },
                        });
                      }}
                    >
                      <FiEdit size={16} className="primary" />
                    </button>
                  )}
                />
                {/* ) : (
                  <></>
                )} */}
                <Button
                  render={(params) => (
                    <button
                      className="btn btn-icon btn-sm"
                      onClick={() => {
                        Swal.fire({
                          icon: "warning",
                          text: "Are you sure, you want to delete?",
                          cancelButtonText: "Cancel",
                          showCancelButton: true,
                        }).then(({ isConfirmed }) => {
                          if (isConfirmed) {
                            $("#progress-loader").removeClass("hidden");
                            props.actionTravelCalendarSettingDelete({ id: params?.data?.id });
                          }
                        });
                      }}
                    >
                      <FiTrash2 size={16} className="primary" />
                    </button>
                  )}
                />
              </Column>
            );
          } else {
            arrColumns.push(
              <Column
                dataField={objColumn.dataField}
                caption={objColumn.caption}
              ></Column>
            );
          }
        });
        return arrColumns;
      };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Travel Calendar Settings</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {/* {
                                verifyPermission("MASTER_DEPARTMENT_ADD") ? */}
                            <li class="nav-item">
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                    onClick={() => {
                                        props.history.push(`${match.url}/update`);
                                    }}
                                >
                                    Add Travel Setting
                                </button>
                            </li>
                            {/* :
                                    <></>
                            } */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                        <div className="card-body">
                      <DataGrid
                        dataSource={travelCalendarSettingList}
                        showBorders={true}
                        columnAutoWidth={true}
                        export={{
                          fileName: "Travel List Setting",
                          enabled: true,
                          allowExportSelectedData: false,
                          excelFilterEnabled: true,
                        }}
                      >
                        <SearchPanel visible={true} />
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        {renderColumns()}
                        <Pager
                          visible={true}
                          showPageSizeSelector={false}
                          showNavigationButtons={true}
                          allowedPageSizes={[4, 6, 10]}
                        />
                        <Paging
                          defaultPageIndex={0}
                          defaultPageSize={10}
                          enabled={true}
                        />
                      </DataGrid>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    var TravelCalendarSettingList = null;
    var TravelCalendarSettingDelete = null;
    if (
        state.TravelCalendarSettingListReducer != null &&
        Object.keys(state.TravelCalendarSettingListReducer).length > 0 &&
        state.TravelCalendarSettingListReducer.constructor === Object
    ) {
        switch (state.TravelCalendarSettingListReducer.tag) {
            case ACTION_CALENDAR_SETTING_LIST_SUCCESS:
                TravelCalendarSettingList = Object.assign({}, state.TravelCalendarSettingListReducer.data);
                delete state.TravelCalendarSettingListReducer;
                return { TravelCalendarSettingList };
            case ACTION_CALENDAR_SETTING_DELETE_SUCCESS:
                TravelCalendarSettingDelete = Object.assign({}, state.TravelCalendarSettingListReducer.data);
                delete state.TravelCalendarSettingListReducer;
                return { TravelCalendarSettingDelete };
            default:

        }
    }
    return {
        TravelCalendarSettingList,
        TravelCalendarSettingDelete
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTravelCalendarSettingList, actionTravelCalendarSettingDelete}, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TravelCalendarSettingList))