import React from 'react';
import { Route, Switch } from 'react-router';
import PolicyMasterList from './list';
import PolicyMasterAdd from './add';
import PolicyMasterEdit from './edit';
import PolicyMasterView from './view';

const PolicyMasterRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/view`} component={PolicyMasterView} />
                <Route path={`${match.url}/edit`} component={PolicyMasterEdit} />
                <Route path={`${match.url}/add`} component={PolicyMasterAdd} />
                <Route path={`${match.url}`} component={PolicyMasterList} />
            </Switch>
        </>
    )
}

export default PolicyMasterRoute