import React, { useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { verifyPermission } from '../../../../../../utils';
const BranchAddress = (props) => {

    const [branchAddress, setBranchAddress] = useState([
        {

            city: 'Mumbai',
            address: '1219, 12th Floor, Maker Chamber V, Nariman Point, Mumbai 400021 Tel: 022-66186400 / 022-6618663',
            status: 'A'
        },
        {

            city: 'Chennai',
            address: 'No: 17 B/4 (Old No: 9B/4), Fourth Floor , Adwave Towers',
            status: 'A'
        },
        {
            city: 'Bangalore ',
            address: ' No.105 - 105A, Prestige Terminus - 1, 1st Floor, Old Airport Exit Road, Bangalore - 560017 Tel: +91-80-2522 4422 | Fax: +91-80-2522 5540',
            status: 'A'
        },
        {
            city: 'Delhi',
            address: '812, 8th Floor, Kailash Building, 26, Kasturba Gandhi Marg, Connaught Place, New Delhi - 110 001.',
            status: 'A'
        },
        {
            city: 'Coimbatore',
            address: '122 west Ramalingam Road, R.S Puram,Coimbatore-641 002',
            status: 'A'
        },
        {
            city: 'Pune',
            address: 'Global Trade Centre, 1/1 Rambaug Colony, LBS Marg, Navi Peth, Pune 411030',
            status: 'A'
        }

    ]);
    const columns = [
        // { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "city", caption: "City" },
        { dataField: "address", caption: "Address" },
        { dataField: "status", caption: "Status" },
    ];
    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'A':
                return <div className={"badge badge-success round px-1"}>Active</div>;
            case 'I':
                return <div className={"badge badge-danger round px-1"}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push('/settings/branch-addresses/edit')
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }

            }
        });
        return arrColumns;
    };
    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Branch Address</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {
                            verifyPermission("CREATE_BRANCH_ADDRESSES") ?
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push('/settings/branch-addresses/add');
                                        }}
                                    >
                                        Create Branch
                                    </button>
                                </li>
                                : <> </>

                        }

                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={branchAddress}
                                columnHidingEnabled={false}
                                showBorders={true}
                                columnAutoWidth={true}
                                export={{
                                    fileName: "roles",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',

                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BranchAddress