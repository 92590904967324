import { ACTION_CLAIMS_DASHBOARD_SUCCESS, ACTION_CLAIM_DOWNLOAD_SUCCESS } from "./action";

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ClaimDashboardReducer = createReducer(INITIAL_STATE, {
    [ACTION_CLAIMS_DASHBOARD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_DASHBOARD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIM_DOWNLOAD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIM_DOWNLOAD_SUCCESS,
                loading: false,
            }
        );
    }
});

export default ClaimDashboardReducer;