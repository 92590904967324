import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import Root from './routes';
import FirebaseApp from "./routes/app/auth/firebase";
import { ToastContainer } from 'react-toastify';
// const messaging = FirebaseApp.messaging();


const App = (props) => {
  const { store, history } = props;

//   messaging.onMessage((payload) => {
//     console.log(payload)
//     const notificationTitle = payload.data.title;
//     const notificationOptions = {
//         body: payload.data.body,
//         // icon: '/firebase-logo.png'
//     };

//     // toast.info(payload.data.body);

//     return new Notification(notificationTitle, notificationOptions).addEventListener('click', event => {
//         console.log(event)
//     })

// });

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
          <Route path="/" component={Root} />
          <ToastContainer autoClose={3000} />
      </ConnectedRouter>
    </Provider>
  )
}

export default App