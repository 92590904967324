import { ACTION_CLAIMS_VIEW_SUCCESS, ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS, ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS, ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS } from "./action";
import createReducer from "../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ViewClaimsReducer = createReducer(INITIAL_STATE, {
    [ACTION_CLAIMS_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS,
                loading: false,
            }
        );
    },
});

export default ViewClaimsReducer;
