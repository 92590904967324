import React, { useEffect, useState } from 'react';
import { FiPhone, FiMail } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../constants';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import $ from "jquery";
import { actionPopupInsuranceMeetingsView, ACTION_POPUP_INSURANCE_MEETING_VIEW_SUCCESS } from './action';
import { generateInitialLettersString } from '../../../../utils';

const InsuranceCompanyMeetingView = (props) => {

    const [insuranceMeetingsDetails, setInsuranceMeetingsDetails] = useState(null);
    const [accompaniedDetails, setAccompaniedDetails] = useState([]);
    const [meetingContacts, setMeetingContacts] = useState([]);

    const initialRender = () => {

        if (props.show === true) {
            // console.log(JSON.stringify(props.insuranceMeetingId));
            props.actionPopupInsuranceMeetingsView({ id: props.insuranceMeetingId });
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onInsuranceMeetingsViewUpdate = () => {
        const { InsuranceMeetingsView } = props;
        var InsuranceMeetingsViewResponse = Object.assign({}, InsuranceMeetingsView);
        if (InsuranceMeetingsViewResponse.result === true) {
            setInsuranceMeetingsDetails(InsuranceMeetingsViewResponse.response);
            setAccompaniedDetails(InsuranceMeetingsViewResponse.response.accompany_by);
            setMeetingContacts(InsuranceMeetingsViewResponse.response.meeting_contacts);
        } else {
            switch (InsuranceMeetingsViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onInsuranceMeetingsViewUpdate, [props.InsuranceMeetingsView]);

    const renderAccompanied = () => {
        var arrAccompanied = []
        if (accompaniedDetails != null && accompaniedDetails.length > 0) {
            accompaniedDetails.forEach((contact, indexContact) => {
                arrAccompanied.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row">
                                <div className="flex-column mr-half">
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{contact.name}</p>
                                    <div class="font-small-3 text-bold-700">{contact.designation_name}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.mobile_no != null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.mobile_no != null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.mobile_no}</div>
                                        </div>
                                        <div className={`flex-column ${contact.email != null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.email != null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrAccompanied
    }

    const renderContacts = () => {
        var arrContacts = []
        if (meetingContacts != null && meetingContacts.length > 0) {
            meetingContacts.forEach((contact, indexContact) => {
                arrContacts.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row mb-1">
                                <div className="flex-column mr-half">
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.contact_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{contact.contact_name}</p>
                                    <div class="font-small-3 text-bold-700">{contact.contact_designation}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone != null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_email != null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrContacts;
    }

    return (
        
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Insurance Meeting
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>  
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                    <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="flex-column">
                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                            {
                                                insuranceMeetingsDetails != null ?
                                                    insuranceMeetingsDetails.day : ''
                                            }
                                        </h1>
                                    </div>
                                    <div className="flex-column">
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                insuranceMeetingsDetails != null ?
                                                    insuranceMeetingsDetails.month : ''
                                            }
                                        </h6>
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                insuranceMeetingsDetails != null ?
                                                    insuranceMeetingsDetails.year : ''
                                            }
                                        </h6>
                                    </div>
                                    <div className="d-flex d-md-none d-lg-none flex-column ml-auto">
                                        <div className="btn btn-outline-success btn-sm px-1">
                                            <h6 className="font-small-3 text-bold-500 my-0">
                                                {
                                                    insuranceMeetingsDetails != null ?
                                                        _.upperFirst(insuranceMeetingsDetails.status) : ''
                                                }
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 d-none d-md-flex d-lg-flex justify-content-start justify-content-md-end justify-content-lg-end align-items-center">
                                <div className={`btn btn-sm px-1 ${insuranceMeetingsDetails != null && (insuranceMeetingsDetails.status === 'close' || insuranceMeetingsDetails.status === 'cancel') ? 'btn-outline-danger' : 'btn-outline-success'}`}>
                                    <h6 className="font-small-3 text-bold-500 my-0">
                                        {
                                            insuranceMeetingsDetails != null ?
                                                _.upperFirst(insuranceMeetingsDetails.status) : ''
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mt-1">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            insuranceMeetingsDetails != null ?
                                                insuranceMeetingsDetails.from_time : ''
                                        }
                                    </div>
                                    <div className="flex-column px-1">to</div>
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            insuranceMeetingsDetails != null ?
                                                insuranceMeetingsDetails.to_time : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Company Name</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.company_name : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Organization Objective</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.business_type : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">State</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.state_name : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">City</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.city_name : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.branch_name : ''
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Agenda</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.agenda : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Meeting Description</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.description : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className={`col-12 col-md-12 col-lg-12 mb-1 ${insuranceMeetingsDetails != null && insuranceMeetingsDetails.minutes_of_meeting != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Minutes Of Meeting</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.minutes_of_meeting : '-'
                                    }
                                </h4>
                            </div>
                            <div className={`col-12 col-md-12 col-lg-12 ${insuranceMeetingsDetails != null && insuranceMeetingsDetails.remark != null ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Remarks</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insuranceMeetingsDetails != null ?
                                            insuranceMeetingsDetails.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <hr />
                        <h4>Contacts</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        meetingContacts.length > 0 ?
                                        renderContacts()
                                        :
                                        <h6 className="text-bold-500 text-center">No Contact Details Available</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Accompanied By</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    {
                                        accompaniedDetails.length > 0 ?
                                        renderAccompanied()
                                        :
                                        <h6 className="text-bold-500 text-center">No Details Available</h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <h4>Created By</h4>
                        <div className="row mb-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row mx-0">
                                    <div class="col-12 px-0 mb-1 border-bottom">
                                        <div className="float-left">
                                            <div className="d-flex flex-row">
                                                <div className="flex-column mr-half mb-half">
                                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                                        {generateInitialLettersString(insuranceMeetingsDetails != null ? insuranceMeetingsDetails.created_by_details.name : '')}
                                                    </div>
                                                </div>
                                                <div className="flex-column">
                                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{insuranceMeetingsDetails != null ? insuranceMeetingsDetails.created_by_details.name : ''}</p>
                                                    <div class="font-small-3 text-bold-700">{insuranceMeetingsDetails != null ? insuranceMeetingsDetails.created_by_details.designation_name : ''}</div>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className={`flex-column ${insuranceMeetingsDetails != null && insuranceMeetingsDetails.created_by_details.mobile_no != null ? '' : 'hidden'}`}>
                                                            <FiPhone size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${insuranceMeetingsDetails != null && insuranceMeetingsDetails.created_by_details.mobile_no != null ? '' : 'hidden'}`}>
                                                            <div class="font-small-3 secondary">{insuranceMeetingsDetails != null && insuranceMeetingsDetails.created_by_details.mobile_no}</div>
                                                        </div>
                                                        <div className={`flex-column ${insuranceMeetingsDetails != null && insuranceMeetingsDetails.created_by_details.email != null ? '' : 'hidden'}`}>
                                                            <FiMail size={12} className="mr-half primary" />
                                                        </div>
                                                        <div className={`flex-column mr-1 ${insuranceMeetingsDetails != null && insuranceMeetingsDetails.created_by_details.email != null ? '' : 'hidden'}`}>
                                                            <div class="d-inline font-small-3 secondary">{insuranceMeetingsDetails != null && insuranceMeetingsDetails.created_by_details.email}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.handleClose();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    var InsuranceMeetingsView = null;
    if (
        state.PopupInsuranceMeetingsViewReducer != null &&
        Object.keys(state.PopupInsuranceMeetingsViewReducer).length > 0 &&
        state.PopupInsuranceMeetingsViewReducer.constructor === Object
    ) {
        switch (state.PopupInsuranceMeetingsViewReducer.tag) {
            case ACTION_POPUP_INSURANCE_MEETING_VIEW_SUCCESS:
                InsuranceMeetingsView = Object.assign({}, state.PopupInsuranceMeetingsViewReducer.data);
                delete state.PopupInsuranceMeetingsViewReducer;
                return { InsuranceMeetingsView };
            default:

        }
    }
    return {
        InsuranceMeetingsView
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionPopupInsuranceMeetingsView }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(InsuranceCompanyMeetingView))