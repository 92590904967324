import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_EDIT_PERSONAL_INFORMATION } from '../../../../../../api/constants';
import { API_DELETE_DOCUMENT } from '../../../../../../api/constants';

export const ACTION_EDIT_PERSONAL_INFORMATION_LOADING = 'ACTION_EDIT_PERSONAL_INFORMATION_LOADING';
export const ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS = 'ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS';

export const ACTION_DOCUMENT_DELETE_LOADING = 'ACTION_DOCUMENT_DELETE_LOADING';
export const ACTION_DOCUMENT_DELETE_SUCCESS = 'ACTION_DOCUMENT_DELETE_SUCCESS';

export function actionEditPersonalInformation(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_EDIT_PERSONAL_INFORMATION, params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_PERSONAL_INFORMATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_PERSONAL_INFORMATION_LOADING, { loading: false, data }));
        });
}

export function actionDeleteDocument(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_DOCUMENT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOCUMENT_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_DELETE_LOADING, { loading: false, data }));
        });
}