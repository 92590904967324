import Api from "../../../../../../api";
import { API_ASSIGN_RM, API_EDIT_WELLNESS_REQUEST, API_RISK_MANAGEMENT_CLIENT_TYPE, API_RISK_SELECT_CUSTOMER, API_WELLNESS_MANAGEMENT_REQUEST_VIEW, API_WELLNESS_MANAGEMENT_WELLNESS_TYPE } from "../../../../../../api/constants";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";


export const ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS = "ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS";
export const ACTION_VIEW_WELLNESS_APPROVAL_LOADING = "ACTION_VIEW_WELLNESS_APPROVAL_LOADING";

export const ACTION_WELLNEESS_APPROVAL_EDIT_LOADING = 'ACTION_WELLNEESS_APPROVAL_EDIT_LOADING';
export const ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS = 'ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS';

export const ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS = "ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS";
export const ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_LOADING = "ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_LOADING";

export const ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS = "ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS";
export const ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_LOADING = "ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_LOADING";

export const ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS = "ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS";
export const ACTION_EDIT_WELLNESS_APPROVAL_TYPE_LOADING = "ACTION_EDIT_WELLNESS_APPROVAL_TYPE_LOADING";

export const ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS = "ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS";
export const ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_LOADING = "ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_LOADING";

export function actionViewWellnessApproval(params) {
    return (dispatch, getState) => {
        Api.post(API_WELLNESS_MANAGEMENT_REQUEST_VIEW, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS, { data }))
        }).catch((reason) => {
            var data = {
                message: ""
            }
            dispatch(loading(ACTION_VIEW_WELLNESS_APPROVAL_LOADING, { loading: false, data }))
        })
    }
}
export function actionEditWellnessApproval(params, files) {
    return (dispatch, getState) => {
        Api.multipart(API_EDIT_WELLNESS_REQUEST, params, files).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNEESS_APPROVAL_EDIT_LOADING, { loading: false, data }))
        })
    };
}
export function actionEditWellnessApprovalClientType() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_CLIENT_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionEditWellnessApprovalClientName(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_SELECT_CUSTOMER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_LOADING, { loading: false, data }));
        });
}
export function actionWellnessManagementWellnessType() {
    return (dispatch, getState) =>
        Api.get(API_WELLNESS_MANAGEMENT_WELLNESS_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_WELLNESS_APPROVAL_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionWellnessManagementAssignRm() {
    return (dispatch, getState) =>
        Api.get(API_ASSIGN_RM).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}