import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import { actionMyTeamVertical, ACTION_MYTEAMVERTICAL_SUCCESS } from './action';
import TreeView from 'devextreme-react/tree-view';

const MyTeam = (props) => {

    const [currentItem, setCurrentItem] = useState([]);
    const [myTeam, setMyTeam] = useState([]);
    const [value, setValue] = ('');
    const [message, setMessage] = useState("")

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionMyTeamVertical({ user_id: props.userId })
    }
    useEffect(initialRender, [props.userId]);


    const onMyTeamVerticalUpdate = () => {
        const { MyTeamVertical } = props;
        if (MyTeamVertical != null) {
            var MyTeamVerticalResponse = Object.assign({}, MyTeamVertical);
            if (MyTeamVerticalResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                if (MyTeamVerticalResponse.response.length > 0) {
                    setMyTeam(MyTeamVerticalResponse.response)
                    setCurrentItem(MyTeamVerticalResponse.response)
                } else {
                    setMessage("No Team Found");
                }

            } else {
                $("#progress-loader").addClass("hidden");
                setMyTeam([])
                setCurrentItem([])
                setMessage("No Team Found");

                switch (MyTeamVerticalResponse.status) {

                }
            }
        }
    }


    useEffect(onMyTeamVerticalUpdate, [props.MyTeamVertical]);

    const selectItem = (event) => {
        if (event.itemData != null) {
            props.history.replace({ pathname: `/profile`, state: { user_id: event.itemData.id } });
        }
        var currentItem = Object.assign({}, event.itemData)
        setCurrentItem(currentItem)
    }

    return (
        <>
            {
                myTeam != null && myTeam.length > 0 ?
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 mx-0 px-0">
                            <div className="m-2">
                                <TreeView
                                    items={myTeam}
                                    dataStructure="plain"
                                    displayExpr="name"
                                    parentIdExpr="reporting_to_id"
                                    keyExpr="id"
                                    onItemClick={selectItem}
                                    searchMode={value}
                                    searchEnabled={true}
                                />

                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <h6 className="text-bold-500 text-center">{message}</h6>
                    </>
            }

        </>
    )
}

function mapStateToProps(state) {

    var MyTeamVertical = null;

    if (
        state.MyTeamVerticalReducer != null &&
        Object.keys(state.MyTeamVerticalReducer).length > 0 &&
        state.MyTeamVerticalReducer.constructor === Object
    ) {
        switch (state.MyTeamVerticalReducer.tag) {

            case ACTION_MYTEAMVERTICAL_SUCCESS:
                MyTeamVertical = Object.assign({}, state.MyTeamVerticalReducer.data);
                delete state.MyTeamVerticalReducer;
                return { MyTeamVertical };
            default:
                return { MyTeamVertical }
        }
    }
    return { MyTeamVertical }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionMyTeamVertical }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam);
