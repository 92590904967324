import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CLAIMS_EDIT } from '../../../../../api/constants';
import { API_POLICY_DETAILS } from '../../../../../api/constants';
import { API_CLAIMS_POLICY_TYPE } from '../../../../../api/constants';
import { API_NATURE_OF_LOSS } from '../../../../../api/constants';
import { API_CLAIM_TYPE } from '../../../../../api/constants';
import { API_CLAIM_STATUS } from '../../../../../api/constants';
import { API_EDIT_CLAIMS_VIEW } from '../../../../../api/constants';
import { API_EVENT_TYPE } from '../../../../../api/constants';
import { API_CLAIM_EDIT_EVENT_DETAILS } from '../../../../../api/constants';
import { API_GET_CLAIM_EDIT_EVENT_DETAILS } from '../../../../../api/constants';
import { API_CLAIM_HANDLER } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_CLAIMS_EDIT_LOADING = 'ACTION_CLAIMS_EDIT_LOADING';
export const ACTION_CLAIMS_EDIT_SUCCESS = 'ACTION_CLAIMS_EDIT_SUCCESS';

export const ACTION_CLAIM_POLICY_DETAILS_LOADING = 'ACTION_CLAIM_POLICY_DETAILS_LOADING';
export const ACTION_CLAIM_POLICY_DETAILS_SUCCESS = 'ACTION_CLAIM_POLICY_DETAILS_SUCCESS';

export const ACTION_EDIT_CLAIMS_POLICY_TYPE_LOADING = 'ACTION_EDIT_CLAIMS_POLICY_TYPE_LOADING';
export const ACTION_EDIT_CLAIMS_POLICY_TYPE_SUCCESS = 'ACTION_EDIT_CLAIMS_POLICY_TYPE_SUCCESS';

export const ACTION_EDIT_NATURE_OF_LOSS_LOADING = 'ACTION_EDIT_NATURE_OF_LOSS_LOADING';
export const ACTION_EDIT_NATURE_OF_LOSS_SUCCESS = 'ACTION_EDIT_NATURE_OF_LOSS_SUCCESS';

export const ACTION_EDIT_CLAIM_TYPE_LOADING = 'ACTION_EDIT_CLAIM_TYPE_LOADING';
export const ACTION_EDIT_CLAIM_TYPE_SUCCESS = 'ACTION_EDIT_CLAIM_TYPE_SUCCESS';

export const ACTION_EDIT_CLAIM_STATUS_LOADING = 'ACTION_EDIT_CLAIM_STATUS_LOADING';
export const ACTION_EDIT_CLAIM_STATUS_SUCCESS = 'ACTION_EDIT_CLAIM_STATUS_SUCCESS';

export const ACTION_EDIT_CLAIMS_VIEW_LOADING = 'ACTION_EDIT_CLAIMS_VIEW_LOADING';
export const ACTION_EDIT_CLAIMS_VIEW_SUCCESS = 'ACTION_EDIT_CLAIMS_VIEW_SUCCESS';

export const ACTION_EDIT_CLAIM_EVENT_TYPE_LOADING = 'ACTION_EDIT_CLAIM_EVENT_TYPE_LOADING';
export const ACTION_EDIT_CLAIM_EVENT_TYPE_SUCCESS = 'ACTION_EDIT_CLAIM_EVENT_TYPE_SUCCESS';

export const ACTION_CLAIM_EDIT_EVENT_DETAILS_LOADING = 'ACTION_CLAIM_EDIT_EVENT_DETAILS_LOADING';
export const ACTION_CLAIM_EDIT_EVENT_DETAILS_SUCCESS = 'ACTION_CLAIM_EDIT_EVENT_DETAILS_SUCCESS';

export const ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_LOADING = 'ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_LOADING';
export const ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_SUCCESS = 'ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_SUCCESS';

export const ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_LOADING = 'ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_LOADING';
export const ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_SUCCESS = 'ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_SUCCESS';

export function actionClaimsEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_EDIT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_EDIT_LOADING, { loading: false, data }));
        });
}
export function actionEditClaimPolicyDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_POLICY_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_POLICY_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_POLICY_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionClaimPolicyType() {
    return (dispatch, getState) =>
        Api.get(API_CLAIMS_POLICY_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_CLAIMS_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_CLAIMS_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionNatureOfLoss() {
    return (dispatch, getState) =>
        Api.get(API_NATURE_OF_LOSS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_NATURE_OF_LOSS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_NATURE_OF_LOSS_LOADING, { loading: false, data }));
        });
}

export function actionClaimType() {
    return (dispatch, getState) =>
        Api.get(API_CLAIM_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_CLAIM_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_CLAIM_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionClaimStatus() {
    return (dispatch, getState) =>
        Api.get(API_CLAIM_STATUS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_CLAIM_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_CLAIM_STATUS_LOADING, { loading: false, data }));
        });
}
export function actionEditClaimsView(params) {
    return (dispatch, getState) =>
        Api.post(API_EDIT_CLAIMS_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_CLAIMS_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_CLAIMS_VIEW_LOADING, { loading: false, data }));
        });
}
export function actionEditClaimsEventType() {
    return (dispatch, getState) =>
        Api.get(API_EVENT_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_CLAIM_EVENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_CLAIM_EVENT_TYPE_LOADING, { loading: false, data }));
        });
}
export function actionEditClaimsEventDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIM_EDIT_EVENT_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_EDIT_EVENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_EDIT_EVENT_DETAILS_LOADING, { loading: false, data }));
        });
}
export function actionGetEditClaimsEventDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_CLAIM_EDIT_EVENT_DETAILS, params).then((res) => {            
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionClaimHandler(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIM_HANDLER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_LOADING, { loading: false, data }));
        });
}
