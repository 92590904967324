import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionClientClaims, CLIENT_ALL_CLAIMS_SUCCESS } from './action';
import ClientPolicyDetails from '../../../../../../../component/popups/policies';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';

const ClientTabClaims = (props) => {

    const [showClaimView, setShowClaimView] = useState(false);
    const [claimData, setClaimData] = useState([]);
    const [clientPolicyDetails, setClientPolicyDetails] = useState(null);

    const [type, setType] = useState('settled');
    const [totalCount, setTotalCount] = useState(0);

    const [claimColumn, setClaimColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "policy_no", caption: "Policy No" },
        { dataField: "policy", caption: "Policy" },
        { dataField: "claim_no", caption: "Claim No" },
        { dataField: "status", caption: "Claim Status" },
        { dataField: "date_of_loss", caption: "Date Of Loss" },
        { dataField: "amount", caption: "Claim Amount" },
    ]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        props.actionClientClaims({
            client_id: props.clientId,
            type: 'settled'
        })

    }
    useEffect(initialRender, [props.clientId]);

    useEffect(() => {
        setTotalCount(claimData.length);
    }, [claimData])

    const onClaimsUpdate = () => {

        const { Claims } = props;
        // alert(JSON.stringify(Claims))
        if (Claims != null) {
            var ClaimsResponse = Object.assign({}, Claims);
            if (ClaimsResponse.result === true) {

                $("#progress-loader").addClass("hidden");

                setClaimColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "policy_no", caption: "Policy No" },
                    { dataField: "policy", caption: "Policy" },
                    { dataField: "claim_no", caption: "Claim No" },
                    { dataField: "status", caption: "Claim Status" },
                    { dataField: "date_of_loss", caption: "Date Of Loss" },
                    { dataField: "amount", caption: "Claim Amount" },
                ])

                // alert(JSON.stringify(ClaimsResponse.response))

                setClaimData(ClaimsResponse.response);
                setTotalCount(ClaimsResponse.response.length);

            } else {
                $("#progress-loader").addClass("hidden");
                setClaimData([])
                switch (ClaimsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClaimsUpdate, [props.Claims]);

    const renderClaimColumns = () => {
        var arrColumns = [];
        claimColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        setShowClaimView(true);
                                        setClientPolicyDetails(params.data)
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderClaimsTableView = () => {
        return (
            <DataGrid
                dataSource={claimData}
                columnHidingEnabled={false}
                showBorders={true}
                export={{
                    fileName: "claims",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderClaimColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    return (
        <>
            <div className="tab-pane" id="claims" aria-labelledby="claims-tab" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                setClaimData([])
                                                $("#progress-loader").removeClass("hidden");
                                                setType('settled');
                                                setTotalCount(0)
                                                props.actionClientClaims({
                                                    client_id: props.clientId,
                                                    type: 'settled'
                                                });

                                            }}
                                            className={`btn btn-md ${type === 'settled' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round`}>
                                            Settled  {type === 'settled' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                setClaimData([])
                                                $("#progress-loader").removeClass("hidden");
                                                setType('pending');
                                                setTotalCount(0)
                                                props.actionClientClaims({
                                                    client_id: props.clientId,
                                                    type: 'pending'
                                                });
                                            }}
                                            className={`btn btn-md ${type === 'pending' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Pending {type === 'pending' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                setClaimData([])
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionClientClaims({
                                                    client_id: props.clientId
                                                });
                                                setTotalCount(0)
                                                setType('all');
                                            }}
                                            className={`btn btn-md ${type === 'all' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            All {type === 'all' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                </ul>
                                {
                                    claimData != null && claimData.length > 0 ?
                                        renderClaimsTableView() :
                                        <h6 className="text-bold-500 text-center">No Claim Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClientPolicyDetails
                show={showClaimView}
                clientPolicyDetails={clientPolicyDetails}
                handleClose={() => {
                    setShowClaimView(false);
                }}
                tab={3}
            />
        </>
    )

}

function mapStateToProps(state) {

    var Claims = null;

    if (
        state.ClientTabClaimsReducer != null &&
        Object.keys(state.ClientTabClaimsReducer).length > 0 &&
        state.ClientTabClaimsReducer.constructor === Object
    ) {
        switch (state.ClientTabClaimsReducer.tag) {

            case CLIENT_ALL_CLAIMS_SUCCESS:
                Claims = Object.assign({}, state.ClientTabClaimsReducer.data);
                delete state.ClientTabClaimsReducer;
                return { Claims };
            default:
                return {
                    Claims
                }
        }
    }
    return {
        Claims
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionClientClaims }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientTabClaims));