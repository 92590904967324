import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MEETING_REPORT } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';
export const ACTION_MEETING_REPORT_LOADING = 'ACTION_MEETING_REPORT_LOADING';
export const ACTION_MEETING_REPORT_SUCCESS = 'ACTION_MEETING_REPORT_SUCCESS';


export function actionMeetingReport(params) {
    return (dispatch, getState) =>
        Api.post(API_MEETING_REPORT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MEETING_REPORT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MEETING_REPORT_LOADING, { loading: false, data }));
        });
}
