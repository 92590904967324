import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { AiOutlineDownload } from 'react-icons/ai';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { FiTrash } from 'react-icons/fi';
import { checkFileExtension } from '../../../../../../utils';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import { actionImportMasterData, ACTION_IMPORT_MASTER_DATA_SUCCESS } from './action';

const ImportReinsuranceMaster = (props) => {

    const { match } = props

    const [importMasterData, setImportMasterData] = useState();
    const [importFile, setImportFile] = useState(null);
    const validExt = ['xlsx', 'csv'];

    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const onImportMasterDataUpdate = () => {
        const { ImportMasterData } = props;
        var ImportMasterDataResponse = Object.assign({}, ImportMasterData);
        $("#save_master").prop("disabled", false);
        if (ImportMasterDataResponse.result === true) {
            // setImportMasterData(ImportMasterDataResponse.response);
            setImportFile(null);
            $("#master-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, " Master Data Imported Successfully");
            props.history.goBack();
        } else {
            switch (ImportMasterDataResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onImportMasterDataUpdate, [props.ImportMasterData]);

    const onSubmit = (data) => {
        var files = [];
        if (importFile != null) {
            files.push({ 'name': 'file', 'value': importFile });
            $("#master-form-loader").removeClass("hidden");
            $("#save_master").prop("disabled", true);
            props.actionImportMasterData(data, files);
        } else {
            Swal.fire({
                title: 'Upload Error',
                text: 'Please select the File',
                icon: 'error'
            }).then(success => {

            })
        }

    }

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <h3 className="content-header-title  text-capitalize mb-0">Import Reinsurance Master</h3>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <fieldset class="form-group">
                                            <label for="basicInputFile">Import File</label>
                                            <div class="custom-file">
                                                <input
                                                    type="file"
                                                    class="custom-file-input"
                                                    accept=".xlsx, .csv"
                                                    id="file"
                                                    name="file"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.files
                                                        ) {

                                                            var fileSize = event.target.files[0].size;

                                                            var ext = (event.target.files[0].name).split('.').pop();

                                                            if (checkFileExtension(ext, validExt)) {

                                                                if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                    $("#progress-loader").addClass("hidden");
                                                                    setImportFile(event.target.files[0]);
                                                                } else {
                                                                    $("#progress-loader").addClass("hidden");
                                                                    Swal.fire({
                                                                        title: 'Error',
                                                                        icon: 'error',
                                                                        html: MEETING_FILE_ERROR_MESSAGE,
                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                    }).then((result) => {

                                                                    });
                                                                }
                                                            } else {
                                                                Swal.fire({
                                                                    title: 'Upload Error',
                                                                    text: 'You can only upload xlsx files',
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            }
                                                        }
                                                    }}
                                                />
                                                <label class="custom-file-label" for="file">
                                                    {
                                                        importFile != null ?
                                                            importFile.name : "Choose file"
                                                    }
                                                </label>
                                                <div className="mt-1">
                                                    {
                                                        (props.data != null && props.data.import_file != null) ?
                                                            <div id="view_document">
                                                                <a target="_blank" href={props.data.import_file}>View Import File</a>
                                                                <FiTrash
                                                                    onClick={() => {
                                                                        Swal.fire({
                                                                            icon: 'warning',
                                                                            text: 'Are you sure, you want to delete this document?',
                                                                            cancelButtonText: "Cancel",
                                                                            showCancelButton: true,
                                                                        }).then(({ isConfirmed }) => {
                                                                            if (isConfirmed) {

                                                                            }
                                                                        })
                                                                    }}
                                                                    size={"16"}
                                                                    cursor={"pointer"}
                                                                    className="ml-half"
                                                                ></FiTrash>
                                                            </div>
                                                            : <></>
                                                    }
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none ">
                                <button
                                    id="save_master"
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                >
                                    <div className="d-flex align-items-center">
                                        Submit
                                        <span id="master-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                                <button
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="button"
                                    onClick={() => {
                                        window.open("/assets/files/ri-db.csv", "_blank");
                                    }}
                                >
                                    <div className="d-flex align-items-center">
                                        <AiOutlineDownload size={20} />
                                        &nbsp;Download File Format
                                    </div>
                                </button>
                                <button
                                    className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.goBack();
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {

    var ImportMasterData = null;

    if (
        state.ImportMasterDataReducer != null &&
        Object.keys(state.ImportMasterDataReducer).length > 0 &&
        state.ImportMasterDataReducer.constructor === Object
    ) {
        switch (state.ImportMasterDataReducer.tag) {
            case ACTION_IMPORT_MASTER_DATA_SUCCESS:
                ImportMasterData = Object.assign({}, state.ImportMasterDataReducer.data);
                delete state.ImportMasterDataReducer;
                return { ImportMasterData };

            default:
        }
    }
    return {
        ImportMasterData,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionImportMasterData }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ImportReinsuranceMaster))