import createReducer from "../../../reducers/createReducer";
import { ACTION_MASTER_CITIES_SUCCESS, ACTION_MASTER_STATES_SUCCESS,ACTION_CREATE_NEW_SURVEYOR_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const NewServeyorReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CREATE_NEW_SURVEYOR_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CREATE_NEW_SURVEYOR_SUCCESS,
                loading: false,
            }
        );
    },
});
export default NewServeyorReducer;