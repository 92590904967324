import Api from '../../../api';
import { API_MASTER_ASSIGN_RETENTION, API_MASTER_EDIT_ASSIGN_RETENTION } from '../../../api/constants';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_MASTER_EDIT_ASSIGN_RETENTION_LOADING = 'ACTION_MASTER_EDIT_ASSIGN_RETENTION_LOADING';
export const ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS = 'ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS';

export const ACTION_MASTER_ASSIGN_RETENTION_LOADING = 'ACTION_MASTER_ASSIGN_RETENTION_LOADING';
export const ACTION_MASTER_ASSIGN_RETENTION_SUCCESS = 'ACTION_MASTER_ASSIGN_RETENTION_SUCCESS';

export function actionEditAssignRetention() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_ASSIGN_RETENTION,).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_ASSIGN_RETENTION_LOADING, { loading: false, data }));
        });
}

export function actionAssignRetention(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_EDIT_ASSIGN_RETENTION,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RETENTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RETENTION_LOADING, { loading: false, data }));
        });
}