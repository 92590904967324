import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_DOCUMENT_LIST } from '../../../../../api/constants';
import { API_DELETE_COMPANY_DOCUMENT } from '../../../../../api/constants';

export const ACTION_DOCUMENT_LIST_LOADING = 'ACTION_DOCUMENT_LIST_LOADING';
export const ACTION_DOCUMENT_LIST_SUCCESS = 'ACTION_DOCUMENT_LIST_SUCCESS';

export const ACTION_DELETE_DOCUMENT_LOADING = 'ACTION_DELETE_DOCUMENT_LOADING';
export const ACTION_DELETE_DOCUMENT_SUCCESS = 'ACTION_DELETE_DOCUMENT_SUCCESS';

export function actionDocumentList() {
    return (dispatch, getState) =>
        Api.get(API_DOCUMENT_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOCUMENT_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_LIST_LOADING, { loading: false, data }));
        });
}
export function actionDeleteDocument(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_COMPANY_DOCUMENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DELETE_DOCUMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DELETE_DOCUMENT_LOADING, { loading: false, data }));
        });
}