import React, { useEffect, useState } from "react";
import ScreenTitle from "../../../../../../component/screen_title";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { withAuth } from "react-auth-guard";
import {
  actionReinsuranceEnquiryDetail,
  ACTION_REINSURANCE_ENQUIRY_DETAIL_SUCCESS,
} from "./action";
import {
  actionReinsuranceEnquiryMaster,
  ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS,
} from "./action";
import {
  actionReinsuranceEnquiryUpdate,
  ACTION_REINSURANCE_ENQUIRY_UPDATE_SUCCESS,
} from "./action";
import {
  KEY_TOAST_MESSAGE,
  setData,
} from "../../../../../../utils/preferences";
import { FiUser } from "react-icons/fi";
import { verifyPermission } from "../../../../../../utils";
import moment from "moment";

const ReinsuranceEdit = (props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [reinsuranceDetail, setReinsuranceDetail] = useState([]);
  const [branch, setBranch] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [entryType, setEntryType] = useState([]);
  const [endorsementType, setEndorsementType] = useState([]);
  const [selectedEntryType, setSelectedEntryType] = useState(null);

  const initialRender = () => {
    props.actionReinsuranceEnquiryMaster();
    props.actionReinsuranceEnquiryDetail({ id: props.location.state.id });
  };
  useEffect(initialRender, []);

  const onReinsuranceEnquiryMasterUpdate = () => {
    const { ReinsuranceEnquiryMaster } = props;
    if (ReinsuranceEnquiryMaster != null) {
      var ReinsuranceEnquiryMasterResponse = Object.assign(
        {},
        ReinsuranceEnquiryMaster
      );
      if (ReinsuranceEnquiryMasterResponse.result === true) {
        setBranch(ReinsuranceEnquiryMasterResponse.response.enquiry_branch);
        setBusinessType(
          ReinsuranceEnquiryMasterResponse.response.enquiry_business_type
        );
        setEntryType(ReinsuranceEnquiryMasterResponse.response.entry_type);
        setEndorsementType(
          ReinsuranceEnquiryMasterResponse.response.endorsement_type
        );
      } else {
        switch (ReinsuranceEnquiryMasterResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  };
  useEffect(onReinsuranceEnquiryMasterUpdate, [props.ReinsuranceEnquiryMaster]);

  useEffect(() => {
    if (branch != null && branch.length > 0) {
      if (reinsuranceDetail != null && reinsuranceDetail.branch != null) {
        setValue("branch", reinsuranceDetail.branch);
      }
    }
  }, [branch, reinsuranceDetail]);

  useEffect(() => {
    if (businessType != null && businessType.length > 0) {
      if (
        reinsuranceDetail != null &&
        reinsuranceDetail.business_type != null
      ) {
        setValue("business_type", reinsuranceDetail.business_type);
      }
    }
  }, [businessType, reinsuranceDetail]);

  useEffect(() => {
    if (entryType != null && entryType.length > 0) {
      if (reinsuranceDetail != null && reinsuranceDetail.type != null) {
        setValue("type", reinsuranceDetail.type);
      }
    }
  }, [entryType, reinsuranceDetail]);

  useEffect(() => {
    if (endorsementType != null && endorsementType.length > 0) {
      if (
        reinsuranceDetail != null &&
        reinsuranceDetail.endorsement_type != null
      ) {
        setValue("endorsement_type", reinsuranceDetail.endorsement_type);
      }
    }
  }, [endorsementType, reinsuranceDetail]);

  const onReinsuranceEnquiryDetailUpdate = () => {
    const { ReinsuranceEnquiryDetail } = props;
    if (ReinsuranceEnquiryDetail != null) {
      var ReinsuranceEnquiryDetailResponse = Object.assign(
        {},
        ReinsuranceEnquiryDetail
      );
      if (ReinsuranceEnquiryDetailResponse.result === true) {
        setReinsuranceDetail(ReinsuranceEnquiryDetailResponse.response);
        setValue(
          "pinc_ref_no",
          ReinsuranceEnquiryDetailResponse.response.pinc_ref_no
        );
        setValue(
          "user_id",
          ReinsuranceEnquiryDetailResponse.response.full_name
        );
        setValue("cedant", ReinsuranceEnquiryDetailResponse.response.cedant);
        setValue(
          "cover_bind",
          ReinsuranceEnquiryDetailResponse.response.cover_bind
        );
        setValue(
          "document_saved",
          ReinsuranceEnquiryDetailResponse.response.document_saved
        );
        setValue(
          "approved",
          ReinsuranceEnquiryDetailResponse.response.approved
        );
        setValue(
          "signed_document_received",
          ReinsuranceEnquiryDetailResponse.response.signed_document_received
        );
        setValue(
          "ri_remark",
          ReinsuranceEnquiryDetailResponse.response.ri_remark
        );
        setValue(
          "sarb_enquiry_code",
          ReinsuranceEnquiryDetailResponse.response.sarb_enquiry_code
        );
        setValue(
          "control_no",
          ReinsuranceEnquiryDetailResponse.response.control_no
        );
        setValue(
          "finance_remark",
          ReinsuranceEnquiryDetailResponse.response.finance_remark
        );
        setValue(
          "insured_treaty_name",
          ReinsuranceEnquiryDetailResponse.response.insured_treaty_name
        );
        setValue(
          "endorsement_no",
          ReinsuranceEnquiryDetailResponse.response.endorsement_no
        );
        setValue(
          "created_at",
          moment(
            ReinsuranceEnquiryDetailResponse.response.created_at,
            "DD-MM-YYYY HH:mm:ss"
          ).format("DD/MM/YYYY")
        );
      } else {
        switch (ReinsuranceEnquiryDetailResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  };
  useEffect(onReinsuranceEnquiryDetailUpdate, [props.ReinsuranceEnquiryDetail]);

  const onReinsuranceEnquiryUpdate = () => {
    const { ReinsuranceEnquiryUpdate } = props;
    if (ReinsuranceEnquiryUpdate != null) {
      var ReinsuranceEnquiryUpdateResponse = Object.assign(
        {},
        ReinsuranceEnquiryUpdate
      );
      $("#reinsurance-update").prop("disabled", false);
      if (ReinsuranceEnquiryUpdateResponse.result === true) {
        $("#reinsurance-update-loader").addClass("hidden");
        setData(KEY_TOAST_MESSAGE, ReinsuranceEnquiryUpdateResponse.message);
        props.history.goBack();
      } else {
        $("#reinsurance-update-loader").addClass("hidden");
        switch (ReinsuranceEnquiryUpdateResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  };
  useEffect(onReinsuranceEnquiryUpdate, [props.ReinsuranceEnquiryUpdate]);

  const onSubmit = (data) => {
    $("#reinsurance-update-loader").removeClass("hidden");
    $("#reinsurance-update").prop("disabled", true);
    data.id = reinsuranceDetail.id;
    if (!verifyPermission("REINSURANCE_ENQUIRY_EDIT_RI_FIELDS")) {
      data.cedant = reinsuranceDetail.cedant;
    } else {
      data.cedant = data.cedant;
    }
    props.actionReinsuranceEnquiryUpdate(data);
  };

  return (
    <>
      <ScreenTitle title="Edit Enquiry" />
      <section>
        <div className="card">
          <div className="card-body">
            <form
              noValidate
              autoComplete="Off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-body">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        PINC Reference No.&nbsp;
                        <span className="danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Operation Person"
                        id="pinc_ref_no"
                        name="pinc_ref_no"
                        disabled={true}
                        {...register("pinc_ref_no")}
                      />
                      {errors.pinc_ref_no && (
                        <ErrorLabel message={errors.pinc_ref_no?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Operation Person&nbsp;<span className="danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Operation Person"
                        id="user_id"
                        name="user_id"
                        disabled={true}
                        {...register("user_id")}
                      />
                      {errors.user_id && (
                        <ErrorLabel message={errors.user_id?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Created On&nbsp;<span className="danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Operation Person"
                        id="created_at"
                        name="created_at"
                        disabled={true}
                        {...register("created_at")}
                      />
                      {errors.created_at && (
                        <ErrorLabel message={errors.created_at?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Branch&nbsp;<span className="danger">*</span>
                      </label>
                      <Controller
                        name="branch"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="branch"
                            name="branch"
                            dataSource={branch}
                            fields={{ text: "name", value: "name" }}
                            allowFiltering={true}
                            enabled={verifyPermission(
                              "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                            )}
                            placeholder="Select Branch"
                            value={field.value}
                            change={(event) => {
                              field.onChange(event.itemData.name);
                            }}
                          />
                        )}
                        rules={{
                          required: "Branch is required",
                        }}
                      />
                      {errors.branch && (
                        <ErrorLabel message={errors.branch?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Entry Processed In&nbsp;
                        <span className="danger">*</span>
                      </label>
                      <Controller
                        name="type"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="type"
                            name="type"
                            dataSource={entryType}
                            fields={{ text: "name", value: "name" }}
                            allowFiltering={true}
                            enabled={verifyPermission(
                              "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                            )}
                            placeholder="Select Entry Processed In"
                            value={field.value}
                            change={(event) => {
                                if(event.itemData?.name != reinsuranceDetail?.type){
                                    setValue("endorsement_type", '');
                                }
                              setSelectedEntryType(event.itemData.name);
                              field.onChange(event.itemData.name);
                            }}
                          />
                        )}
                        rules={{
                          required: "Entry Processed In is required",
                        }}
                      />
                      {errors.type && (
                        <ErrorLabel message={errors.type?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Business Type&nbsp;<span className="danger">*</span>
                      </label>
                      <Controller
                        name="business_type"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="business_type"
                            name="business_type"
                            dataSource={businessType}
                            fields={{ text: "name", value: "name" }}
                            allowFiltering={false}
                            enabled={verifyPermission(
                              "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                            )}
                            placeholder="Select Business Type"
                            value={field.value}
                            change={(event) => {
                              field.onChange(event.itemData.name);
                            }}
                          />
                        )}
                        rules={{
                          required: "Business Type is required",
                        }}
                      />
                      {errors.business_type && (
                        <ErrorLabel message={errors.business_type?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Cedant/Broker&nbsp;<span className="danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Cedant/Broker"
                        id="cedant"
                        name="cedant"
                        disabled={
                          !verifyPermission(
                            "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                          )
                        }
                        {...register("cedant", {
                          required: "Cedant/Broker is required",
                        })}
                      />
                      {errors.cedant && (
                        <ErrorLabel message={errors.cedant?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Insured / Treaty Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Insured / Treaty Name"
                        id="insured_treaty_name"
                        name="insured_treaty_name"
                        disabled={
                          !verifyPermission(
                            "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                          )
                        }
                        {...register("insured_treaty_name")}
                      />
                      {errors.insured_treaty_name && (
                        <ErrorLabel
                          message={errors.insured_treaty_name?.message}
                        />
                      )}
                    </div>
                  </div>
                  {selectedEntryType === "Endorsement" ? (
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control ">
                          Type of Endorsement
                        </label>
                        <Controller
                          name="endorsement_type"
                          className="form-control"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              id="endorsement_type"
                              name="endorsement_type"
                              dataSource={endorsementType}
                              fields={{ text: "name", value: "name" }}
                              allowFiltering={false}
                              placeholder="Select Type of Endorsement"
                              value={field.value}
                              enabled={verifyPermission(
                                "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                              )}
                              change={(event) =>
                                field.onChange(event.itemData.name)
                              }
                            />
                          )}
                        />
                        {errors.endorsement_type && (
                          <ErrorLabel
                            message={errors.endorsement_type?.message}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Endorsement Control Number
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Endorsement Control Number"
                        id="endorsement_no"
                        name="endorsement_no"
                        disabled={
                          !verifyPermission(
                            "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                          )
                        }
                        {...register("endorsement_no")}
                      />
                      {errors.endorsement_no && (
                        <ErrorLabel message={errors.endorsement_no?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">Cover BIND</label>
                      <Controller
                        name="cover_bind"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="cover_bind"
                            name="cover_bind"
                            dataSource={[
                              {
                                id: "InProgress",
                                name: "In Progress",
                              },
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            enabled={verifyPermission(
                              "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                            )}
                            placeholder="Select Cover BIND"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.cover_bind && (
                        <ErrorLabel message={errors.cover_bind?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Deal Documents Saved
                      </label>
                      <Controller
                        name="document_saved"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="document_saved"
                            name="document_saved"
                            dataSource={[
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            enabled={verifyPermission(
                              "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                            )}
                            placeholder="Select Deal Documents Saved"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.document_saved && (
                        <ErrorLabel message={errors.document_saved?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">Approval Status</label>
                      <Controller
                        name="approved"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="approved"
                            name="approved"
                            dataSource={[
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            enabled={verifyPermission(
                              "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                            )}
                            placeholder="Select Approval Status"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.approved && (
                        <ErrorLabel message={errors.approved?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Signed Documents Received
                      </label>
                      <Controller
                        name="signed_document_received"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="signed_document_received"
                            name="signed_document_received"
                            dataSource={[
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            enabled={verifyPermission(
                              "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                            )}
                            placeholder="Select Signed Documents Received"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.signed_document_received && (
                        <ErrorLabel
                          message={errors.signed_document_received?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">RI Team Remark</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter RI Team Remark"
                        id="ri_remark"
                        name="ri_remark"
                        disabled={
                          !verifyPermission(
                            "REINSURANCE_ENQUIRY_EDIT_RI_FIELDS"
                          )
                        }
                        maxLength={250}
                        {...register("ri_remark")}
                      />
                      {errors.ri_remark && (
                        <ErrorLabel message={errors.ri_remark?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12">
                    <h4 className="d-flex align-items-center form-section mb-2 black">
                      <FiUser size={16} />
                      &nbsp;For Finance
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">SARB Enquiry Code</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter SARB Enquiry code"
                        id="sarb_enquiry_code"
                        disabled={
                          !verifyPermission(
                            "REINSURANCE_ENQUIRY_EDIT_FINANCE_FIELDS"
                          )
                        }
                        name="sarb_enquiry_code"
                        {...register("sarb_enquiry_code")}
                      />
                      {errors.sarb_enquiry_code && (
                        <ErrorLabel
                          message={errors.sarb_enquiry_code?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">Control No.</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Control No."
                        id="control_no"
                        name="control_no"
                        disabled={
                          !verifyPermission(
                            "REINSURANCE_ENQUIRY_EDIT_FINANCE_FIELDS"
                          )
                        }
                        {...register("control_no")}
                      />
                      {errors.control_no && (
                        <ErrorLabel message={errors.control_no?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">Finance Remark</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Finance Remark"
                        id="finance_remark"
                        name="finance_remark"
                        disabled={
                          !verifyPermission(
                            "REINSURANCE_ENQUIRY_EDIT_FINANCE_FIELDS"
                          )
                        }
                        maxLength={250}
                        {...register("finance_remark")}
                      />
                      {errors.finance_remark && (
                        <ErrorLabel message={errors.finance_remark?.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-actions border-0 float-none">
                <button
                  id="reinsurance-update"
                  className="btn btn-primary mr-1 shadow round text-uppercase"
                  type="submit"
                >
                  <div className="d-flex align-items-center">
                    Submit
                    <span
                      id="reinsurance-update-loader"
                      className="spinner-border spinner-border-sm ml-half hidden"
                    ></span>
                  </div>
                </button>
                <button
                  className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.goBack();
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  var ReinsuranceEnquiryDetail = null;
  var ReinsuranceEnquiryUpdate = null;
  var ReinsuranceEnquiryMaster = null;

  if (
    state.ReinsuranceEditReducer != null &&
    Object.keys(state.ReinsuranceEditReducer).length > 0 &&
    state.ReinsuranceEditReducer.constructor === Object
  ) {
    switch (state.ReinsuranceEditReducer.tag) {
      case ACTION_REINSURANCE_ENQUIRY_DETAIL_SUCCESS:
        ReinsuranceEnquiryDetail = Object.assign(
          {},
          state.ReinsuranceEditReducer.data
        );
        delete state.ReinsuranceEditReducer;
        return { ReinsuranceEnquiryDetail };
      case ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS:
        ReinsuranceEnquiryMaster = Object.assign(
          {},
          state.ReinsuranceEditReducer.data
        );
        delete state.ReinsuranceEditReducer;
        return { ReinsuranceEnquiryMaster };
      case ACTION_REINSURANCE_ENQUIRY_UPDATE_SUCCESS:
        ReinsuranceEnquiryUpdate = Object.assign(
          {},
          state.ReinsuranceEditReducer.data
        );
        delete state.ReinsuranceEditReducer;
        return { ReinsuranceEnquiryUpdate };
      default:
    }
  }
  return {
    ReinsuranceEnquiryDetail,
    ReinsuranceEnquiryUpdate,
    ReinsuranceEnquiryMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionReinsuranceEnquiryDetail,
      actionReinsuranceEnquiryMaster,
      actionReinsuranceEnquiryUpdate,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ReinsuranceEdit)
);
