import { API_ACCOMPAINED_BY, API_MEETING_LIST_CONTACT, API_SERVEYORMEETINGADDCONTACT } from '../../../../../../api/constants';
import { API_EDIT_GET_SERVEYOR_NAME } from '../../../../../../api/constants';
import { API_SURVEYOR_MEETING_VIEW } from '../../../../../../api/constants';
import { API_SURVEYOR_MEETING_EDIT } from '../../../../../../api/constants';
import { API_DELETE_DOCUMENT } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_API_ACCOMPAINED_BY_LOADING = 'ACTION_API_ACCOMPAINED_BY_LOADING';
export const ACTION_API_ACCOMPAINED_BY_SUCCESS = 'ACTION_API_ACCOMPAINED_BY_SUCCESS';

export const ACTION_EDIT_GET_SERVEYOR_NAME_LOADING = 'ACTION_EDIT_GET_SERVEYOR_NAME_LOADING';
export const ACTION_EDIT_GET_SERVEYOR_NAME_SUCCESS = 'ACTION_EDIT_GET_SERVEYOR_NAME_SUCCESS';

export const ACTION_SURVEYOR_MEETING_VIEW_LOADING = 'ACTION_SURVEYOR_MEETING_VIEW_LOADING';
export const ACTION_SURVEYOR_MEETING_VIEW_SUCCESS = 'ACTION_SURVEYOR_MEETING_VIEW_SUCCESS';

export const EDITSERVEYORMEETINGEDITCONTACT_LOADING = 'EDITSERVEYORMEETINGEDITCONTACT_LOADING';
export const ACTION_EDITSERVEYORMEETINGEDITCONTACT_SUCCESS = 'ACTION_EDITSERVEYORMEETINGEDITCONTACT_SUCCESS';

export const ACTION_SERVEYOR_GET_CONTACTS_LOADING = 'ACTION_SERVEYOR_GET_CONTACTS_LOADING';
export const ACTION_SERVEYOR_GET_CONTACTS_SUCCESS = 'ACTION_SERVEYOR_GET_CONTACTS_SUCCESS';

export const ACTION_SURVEYOR_MEETING_EDIT_LOADING = 'ACTION_SURVEYOR_MEETING_EDIT_LOADING';
export const ACTION_SURVEYOR_MEETING_EDIT_SUCCESS = 'ACTION_SURVEYOR_MEETING_EDIT_SUCCESS';

export const ACTION_DELETE_DOCUMENT_LOADING = 'ACTION_DELETE_DOCUMENT_LOADING';
export const ACTION_DELETE_DOCUMENT_SUCCESS = 'ACTION_DELETE_DOCUMENT_SUCCESS';

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionEditGetServeyorName(params) {
    return (dispatch, getState) =>
        Api.post(API_EDIT_GET_SERVEYOR_NAME, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_GET_SERVEYOR_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_GET_SERVEYOR_NAME_LOADING, { loading: false, data }));
        });
}

export function actionServeyorMeetingsView(params) {
    return (dispatch, getState) =>
        Api.post(API_SURVEYOR_MEETING_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_SURVEYOR_MEETING_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SURVEYOR_MEETING_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionServeyorMeetingsEdit(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_SURVEYOR_MEETING_EDIT, params, files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_SURVEYOR_MEETING_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SURVEYOR_MEETING_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionEditServeyorMeetingEditContact(params) {
    return (dispatch, getState) => Api.post(API_SERVEYORMEETINGADDCONTACT, params).then((res) => {
        const data = res;
        PRINT('EDITSERVEYORMEETINGEDITCONTACT ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_EDITSERVEYORMEETINGEDITCONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(EDITSERVEYORMEETINGEDITCONTACT_LOADING, { loading: false, data }));
    });
}

export function actionServeyorGetContacts(params) {
    return (dispatch, getState) => Api.post(API_MEETING_LIST_CONTACT, params).then((res) => {
        const data = res;
        PRINT('ACTION_SERVEYOR_GET_CONTACTS_SUCCESS RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_SERVEYOR_GET_CONTACTS_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_SERVEYOR_GET_CONTACTS_LOADING, { loading: false, data }));
    });
}
export function actionDeleteDocument(params) {
    return (dispatch, getState) => Api.post(API_DELETE_DOCUMENT,params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_DELETE_DOCUMENT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_DELETE_DOCUMENT_LOADING, { loading: false, data }));
    });
}