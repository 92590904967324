import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS, ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const OpportunitySettingListReducer = createReducer(INITIAL_STATE, {
    [ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS,
                loading: false,
            }
        );
    },
}
)
export default OpportunitySettingListReducer