import { API_PROPOSAL_NEW_POOL_LIST, API_MYTEAMVERTICAL, API_ADDPOLICY, API_DATE_TYPE } from '../../../../../api/constants';
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { PRINT } from '../../../../../utils';

export const ACTION_PROPOSALPOOL_LIST_LOADING = 'ACTION_PROPOSALPOOL_LIST_LOADING';
export const ACTION_PROPOSALPOOL_LIST_SUCCESS = 'ACTION_PROPOSALPOOL_LIST_SUCCESS';

export const ACTION_LIST_TEAM_VERTICAL_LOADING = 'ACTION_LIST_TEAM_VERTICAL_LOADING';
export const ACTION_LIST_TEAM_VERTICAL_SUCCESS = 'ACTION_LIST_TEAM_VERTICAL_SUCCESS';

export const ACTION_ADD_POLICY_LOADING = 'ACTION_ADD_POLICY_LOADING';
export const ACTION_ADD_POLICY_SUCCESS = 'ACTION_ADD_POLICY_SUCCESS';

export const ACTION_DATE_TYPE_LOADING = 'ACTION_DATE_TYPE_LOADING';
export const ACTION_DATE_TYPE_SUCCESS = 'ACTION_DATE_TYPE_SUCCESS';

export function actionProposalPoolList(params) {
    return (dispatch, getState) =>
        Api.post(API_PROPOSAL_NEW_POOL_LIST, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROPOSALPOOL_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROPOSALPOOL_LIST_LOADING, { loading: false, data }));
        });
}

export function actionTeamList(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_LIST_TEAM_VERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_LIST_TEAM_VERTICAL_LOADING, { loading: false, data }));
        });
}

export function actionAddPolicy(params) {
    return (dispatch, getState) => Api.post(API_ADDPOLICY, params).then((res) => {
        const data = res;
        // PRINT('ADDPOLICY ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_ADD_POLICY_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_ADD_POLICY_LOADING, { loading: false, data }));
    });
}

export function actionDateType() {
    return (dispatch, getState) => Api.get(API_DATE_TYPE).then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_DATE_TYPE_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_DATE_TYPE_LOADING, { loading: false, data }));
    });
}