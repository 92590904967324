import React, { useEffect, useState } from 'react';
import { HiOutlineAcademicCap, HiOutlineInformationCircle, HiOutlineKey, HiOutlineOfficeBuilding } from 'react-icons/hi';
import EducationAdd from './educations/add';
import EmploymentAdd from './employment/add';
import EmploymentEdit from './employment/edit';
import EducationEdit from './educations/edit/index';
import PersonalInformationEdit from './personal_information/edit'
import { FiEdit, FiGlobe, FiHome, FiPhone, FiPhoneCall, FiPlus, FiTrash2, FiUser } from 'react-icons/fi';
import VisitingCard from '../../../../component/popups/visiting_card';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../component/form/error_label";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import { actionMyProfile, ACTION_MY_PROFILE_SUCCESS } from './action';
import { actionDeleteEmployeeEducation, ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS } from './action';
import { actionDeleteEmployeeEmployment, ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS } from './action';
import { actionChangePassword, ACTION_CHANGE_PASSWORD_SUCCESS } from './action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TabPost from './tabs/post';
import TabClients from './tabs/clients';
import TabLeads from './tabs/leads';
import TabTeam from './tabs/team';
import TabMeetings from './tabs/meetings';
import TabBusinessSummary from './tabs/business_summary';
import { withAuth } from 'react-auth-guard';
import _ from 'lodash';
import { getData, KEY_USER } from '../../../../utils/preferences';
import $ from "jquery";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { IoMdRibbon } from 'react-icons/io';
import { getHttpUrl, verifyPermission, checkFileExtension } from '../../../../utils';
import { PRIMARY_COLOR, UNAUTHORIZED, ERROR, } from '../../../../constants';
import { AiOutlineIdcard } from 'react-icons/ai';
import moment from 'moment';
import { downloadFile } from '../../../../utils';
import DocumentIframe from '../../../../component/popups/document_iframe';

const MyProfile = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm({
        defaultValues: {
        }
    });
    // For Popups
    const [showEducationAdd, setShowEducationAdd] = useState(false);
    const [showEducationEdit, setShowEducationEdit] = useState(false);
    const [showEmploymentAdd, setShowEmploymentAdd] = useState(false);
    const [showEmploymentEdit, setShowEmploymentEdit] = useState(false);
    const [showPersonalInformationEdit, setShowPersonalInformationEdit] = useState(false);
    const [showVisitingCard, setShowVisitingCard] = useState(false);

    // For Edit Selected Index
    const [selectedEducationIndex, setSelectedEducationIndex] = useState(null);
    const [selectedEmploymentIndex, setSelectedEmploymentIndex] = useState(null);

    // For Edit Selected Id 
    const [selectedEducationId, setSelectedEducationId] = useState(null);
    const [selectedEmploymentId, setSelectedEmploymentId] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [educationDetails, setEducationDetails] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [myProfileInfo, setMyProfileInfo] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [isTeamMember, setIsTeamMember] = useState(false);
    const [changePassword, setChangePassword] = useState();
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    // certification
    const [certificationDetails, setCertificationDetails] = useState(null);

    useEffect(() => {

        setSelectedTab(0);

        var userDetail = getData(KEY_USER);

        if ((props.location.state !== undefined || props.location.state != null) && (props.location.state.hasOwnProperty('user_id') || props.location.state.hasOwnProperty('id'))) {
            $("#progress-loader").removeClass("hidden");
            if (props.location.state.user_id != null) {
                props.actionMyProfile({ user_id: props.location.state.user_id });
            } else {
                props.actionMyProfile({ user_id: props.location.state.id });
            }


            if (userDetail != null) {

                var userObj = JSON.parse(userDetail);
                if (userObj.id === props.location.state.user_id) {
                    setIsEditable(true);
                } else {
                    setIsEditable(false);
                }
            }

        } else {

            if (userDetail != null) {
                var userObj = JSON.parse(userDetail);
                $("#progress-loader").removeClass("hidden");
                props.actionMyProfile({ user_id: userObj.user_id });
            }

            setIsEditable(true)
        }
    }, [props.location.state])

    useEffect(() => {
        if (props?.history?.location?.state?.client_detail) {
            $("#progress-loader").removeClass("hidden");
            props.actionMyProfile({ user_id: props?.history?.location?.state?.user_id });
            setSelectedTab(props?.location?.state?.tab)
        }

    }, [props?.history?.location?.state])

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        // $(".auto-suggestion").remove();
    }
    useEffect(initialRender, []);

    const onMyProfileInfoUpdate = () => {
        const { MyProfileInfo } = props;
        var MyProfileInfoResponse = Object.assign({}, MyProfileInfo);
        if (MyProfileInfoResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setMyProfileInfo(MyProfileInfoResponse.response);
            setSelectedUserId(MyProfileInfoResponse.response.user_id);
            setEducationDetails(MyProfileInfoResponse.response.education);
            setEmployeeDetails(MyProfileInfoResponse.response.employment);
            setIsTeamMember(MyProfileInfoResponse.response.is_team_member);
            setCertificationDetails(MyProfileInfoResponse.response.certificate)

            if (MyProfileInfoResponse.response.is_team_member == false) {
                setSelectedTab(6)
            }

        } else {
            $("#progress-loader").addClass("hidden");
            switch (MyProfileInfoResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMyProfileInfoUpdate, [props.MyProfileInfo])

    const onChangePasswordUpdate = () => {
        const { ChangePassword } = props;
        var ChangePasswordResponse = Object.assign({}, ChangePassword);
        $("#change_password").prop("disabled", false);
        if (ChangePasswordResponse.result === true) {
            setChangePassword(ChangePasswordResponse.response);
            setValue('old_password', '');
            setValue('new_password', '');
            setValue('confirm_password', '');
            Swal.fire({
                title: 'Success',
                icon: 'success',
                text: 'Your Password Update Successfully',
                confirmButtonColor: PRIMARY_COLOR
            }).then((result) => {
            });
            $("#change_password-loader").addClass("hidden");
        } else {
            $("#change_password-loader").addClass("hidden");
            switch (ChangePasswordResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: ChangePasswordResponse.message,
                        confirmButtonColor: PRIMARY_COLOR
                    }).then((result) => {
                    });
                default:
            }
        }
    }
    useEffect(onChangePasswordUpdate, [props.ChangePassword])

    const onDeleteEmployeeEducationUpdate = () => {
        const { DeleteEmployeeEducation } = props;
        var DeleteEmployeeEducationResponse = Object.assign({}, DeleteEmployeeEducation);
        if (DeleteEmployeeEducationResponse.result === true) {

            var arrEducationDetails = Object.assign([], educationDetails);
            arrEducationDetails.splice(selectedEducationIndex, 1);
            setEducationDetails(arrEducationDetails);
            setSelectedEducationIndex(null);

        } else {
            switch (DeleteEmployeeEducationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onDeleteEmployeeEducationUpdate, [props.DeleteEmployeeEducation])

    const onDeleteEmployeeEmploymentUpdate = () => {
        const { DeleteEmployeeEmployment } = props;
        var DeleteEmployeeEmploymentResponse = Object.assign({}, DeleteEmployeeEmployment);
        if (DeleteEmployeeEmploymentResponse.result === true) {

            var arrEmployeeDetails = Object.assign([], employeeDetails);
            arrEmployeeDetails.splice(selectedEmploymentIndex, 1);
            setEmployeeDetails(arrEmployeeDetails);
            setSelectedEmploymentIndex(null);

        } else {
            switch (DeleteEmployeeEmploymentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onDeleteEmployeeEmploymentUpdate, [props.DeleteEmployeeEmployment])




    const onSubmit = (data) => {
        $("#change_password-loader").removeClass("hidden");
        $("#change_password").prop("disabled", true);
        props.actionChangePassword(data);
    }


    const renderEducationDetails = () => {
        var arrEducationDetails = []
        if (educationDetails != null && educationDetails.length > 0) {
            educationDetails.forEach((education, indexEducation) => {
                arrEducationDetails.push(
                    <div className="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row pb-1">
                                <div className="flex-column">
                                    <p className="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">
                                        {education.education}
                                    </p>
                                    <div className="font-small-3 text-bold-700">
                                        {education.year_of_passing}
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${education.institute_name.length === 0 ? 'hidden' : ''}`}>
                                            {education.institute_name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="float-right">
                            {
                                isEditable ?
                                    <button type="button" className="btn pt-0"
                                        onClick={() => {
                                            setSelectedEducationId(education.id);
                                            setShowEducationEdit(true)
                                            setSelectedEducationIndex(indexEducation);
                                        }}
                                    >
                                        <FiEdit size={16} />
                                    </button>
                                    :
                                    <></>
                            }
                            {
                                isEditable ?
                                    <button type="button" className="btn pt-0"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, you want to delete this education?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                            }).then(({ isConfirmed }) => {
                                                if (isConfirmed) {
                                                    setSelectedEducationIndex(indexEducation);
                                                    props.actionDeleteEmployeeEducation({ id: education.id })
                                                }
                                            })
                                        }}
                                    >
                                        <FiTrash2 size={16} />
                                    </button>
                                    :
                                    <></>
                            }

                        </div>
                        <div className="divider"></div>
                    </div>
                )
            })
        }
        return arrEducationDetails
    }


    const renderEmployeeDetails = () => {
        var arrEmployeeDetails = []
        if (employeeDetails != null && employeeDetails.length > 0) {
            employeeDetails.forEach((employee, indexEmployee) => {
                arrEmployeeDetails.push(
                    <div className="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row pb-1">
                                <div className="flex-column">
                                    <p className="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">
                                        {employee.name_of_organization}
                                    </p>
                                    <div className="font-small-3 text-bold-700">
                                        {employee.designation}
                                    </div>
                                    <div className="font-small-3">
                                        Duration: {employee.duration}
                                    </div>
                                    <div className="font-small-3">
                                        {employee.description}
                                    </div>
                                    <div className="font-small-3">
                                        {employee.Designation}
                                    </div>

                                </div >
                            </div>
                        </div>
                        <div className="d-md-flex d-lg-flex float-right">
                            {
                                isEditable ?
                                    <button type="button"
                                        className="btn pt-0 "
                                        onClick={() => {
                                            setSelectedEmploymentId(employee.id);
                                            setShowEmploymentEdit(true)
                                            setSelectedEmploymentIndex(indexEmployee)
                                        }}
                                    >
                                        <FiEdit size={16} />
                                    </button>
                                    :
                                    <></>
                            }
                            {
                                isEditable ?
                                    <button type="button" className="btn pt-0"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, you want to delete this policy details?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                            }).then(({ isConfirmed }) => {
                                                if (isConfirmed) {
                                                    setSelectedEmploymentIndex(indexEmployee)
                                                    props.actionDeleteEmployeeEmployment({ id: employee.id })

                                                }
                                            })
                                        }}
                                    >
                                        <FiTrash2 size={16} />
                                    </button>
                                    :
                                    <></>
                            }
                        </div>
                        <div className="divider"></div>
                    </div>
                )
            })
        }
        return arrEmployeeDetails
    }

    const renderTab = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <TabBusinessSummary
                        {...props}
                        userId={selectedUserId}
                        isTeamMember={isTeamMember}
                    />
                )
            case 2:
                return (
                    <TabPost
                        {...props}
                        userId={selectedUserId}
                        isTeamMember={isTeamMember}
                    />
                )
            case 3:
                return (
                    <TabMeetings
                        {...props}
                        userId={selectedUserId}
                        tab={selectedTab}
                    />
                )
            case 4:
                return (
                    <TabClients
                        {...props}
                        userId={selectedUserId}
                        tab={selectedTab}
                    />
                )
            case 5:
                return (
                    <TabLeads
                        {...props}
                        userId={selectedUserId}
                    />
                )
            case 6:
                return (
                    <TabTeam
                        {...props}
                        userId={selectedUserId}
                        isTeamMember={isTeamMember}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }
    useEffect(renderTab, [selectedTab])

    return (
        <>
            <div id="user-profile">
                <div className="row">
                    <div className="col-12">
                        <div className="card profile-with-cover">
                            <div className="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div className="media profil-cover-details w-100 border-bottom pb-2">
                                <div className="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (myProfileInfo != null && myProfileInfo.profile_picture_thumb != null) ?
                                                <img
                                                    src={myProfileInfo.profile_picture_thumb}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {myProfileInfo.initial_name}
                                                    </div>
                                                </>
                                        }

                                    </a>
                                </div>
                                <div className="media-body pt-1 px-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className="card-title mb-0">{myProfileInfo?.full_name}
                                                <span class="badge badge-pill font-small-1 badge-success mr-half ml-half text-bold-500">{myProfileInfo?.designation}</span>
                                                <span class="badge badge-pill font-small-1 badge-danger  text-bold-500">{myProfileInfo?.payroll_entity}</span>
                                            </h3>
                                            <h4 className="mb-0 font-small-3 mt-half"><span className="text-bold-600">Reporting To:</span > 
                                            <span className='text-primary cursor-pointer'
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/profile',
                                                    state: {
                                                        user_id: myProfileInfo?.reporting_to_id,
                                                    }
                                                })}}
                                            > {myProfileInfo?.reporting_name != null ? myProfileInfo?.reporting_name : ''}</span></h4>
                                            <h4 className="mb-0 font-small-3 mt-half"><span className="text-bold-600">Email Id:</span> {myProfileInfo?.email != null ? myProfileInfo?.email : ''}</h4>
                                        </div>
                                        <div className="col-md-4">
                                            <h4 className="mb-0 font-small-3 mt-2"><span className="text-bold-600">Mobile No:</span> {myProfileInfo?.mobile_no != null ? myProfileInfo?.mobile_no : ''}</h4>
                                            <h4 className="mb-0 font-small-3 mt-half"><span className="text-bold-600">Branch:</span> {myProfileInfo?.branch_city != null ? myProfileInfo?.branch_city : ''}</h4>
                                        </div>
                                        {
                                            myProfileInfo != null && myProfileInfo?.payroll_entity != null && myProfileInfo?.payroll_entity === 'PINC Insurance' ?
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn pt-0 float-right"
                                                        onClick={() => {
                                                            setShowVisitingCard(true)
                                                        }}
                                                    >
                                                        <AiOutlineIdcard size={25} />
                                                    </button>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <nav className="navbar navbar-light navbar-profile align-self-end">
                                <button className="navbar-toggler d-sm-none" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation"></button>
                                <nav className="navbar navbar-expand-lg">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav mr-auto">
                                            <li className="nav-item">
                                                <span
                                                    className={`cursor-pointer nav-link ${selectedTab === 0 ? 'primary' : ''} ${isTeamMember == false ? 'hidden' : ''}`}
                                                    onClick={() => {
                                                        setSelectedTab(0)
                                                    }}
                                                >
                                                    Business Summary
                                                </span>
                                            </li>
                                            <li className="nav-item">
                                                <span
                                                    className={`cursor-pointer nav-link ${selectedTab === 3 ? 'primary' : ''} ${isTeamMember == false ? 'hidden' : ''}`}
                                                    onClick={() => {
                                                        setSelectedTab(3)
                                                    }}>
                                                    Meeting
                                                </span>
                                            </li>
                                            {
                                                verifyPermission("VIEW_ALL_CLIENTS") ?
                                                    <>
                                                        <li className="nav-item">
                                                            <span
                                                                className={`cursor-pointer nav-link ${selectedTab === 4 ? 'primary' : ''} ${isTeamMember == false ? 'hidden' : ''}`}
                                                                onClick={() => {
                                                                    setSelectedTab(4)
                                                                }}
                                                            >
                                                                Clients
                                                            </span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span
                                                                className={`cursor-pointer nav-link ${selectedTab === 5 ? 'primary' : ''} ${isTeamMember == false ? 'hidden' : ''}`}
                                                                onClick={() => {
                                                                    setSelectedTab(5)
                                                                }}
                                                            >
                                                                Leads
                                                            </span>
                                                        </li>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            <li className="nav-item">
                                                <span
                                                    className={`cursor-pointer nav-link ${selectedTab === 6 ? 'primary' : ''}`
                                                        // ${isTeamMember == false ? 'hidden' : ''}`
                                                    }
                                                    onClick={() => {
                                                        setSelectedTab(6)
                                                    }}
                                                >
                                                    Team
                                                </span>
                                            </li>
                                            <li className="nav-item">
                                                <span
                                                    className={`cursor-pointer nav-link ${selectedTab === 1 ? 'primary' : ''}`
                                                        //  ${isTeamMember == false ? 'hidden' : ''}`
                                                    }

                                                    onClick={() => {
                                                        setSelectedTab(1)
                                                    }}
                                                >
                                                    My Profile
                                                </span>
                                            </li>
                                            <li className="nav-item">
                                                <span
                                                    className={`cursor-pointer nav-link ${selectedTab === 2 ? 'primary' : ''}`
                                                        //  ${isTeamMember == false ? 'hidden' : ''}`
                                                    }
                                                    onClick={() => {
                                                        setSelectedTab(2)
                                                    }}
                                                >
                                                    Post
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${selectedTab == 1 ? '' : 'hidden'}`}>
                <div className="mb-1 rounded">
                    <div className="card-content">
                        <div className="card-body px-0 py-0">
                            <ul className="nav nav-tabs nav-underline border-bottom" role="tablist" style={{ backgroundColor: 'transparent' }}>
                                <li className="nav-item">
                                    <a className="nav-link d-flex align-items-center justify-content-center active" id="personal-information-tab" data-toggle="tab" href="#personal-information" aria-controls="personal-information" role="tab" aria-selected="true">
                                        <HiOutlineInformationCircle size={16} />
                                        <span className="d-none d-sm-block">&nbsp;Personal Information</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link d-flex align-items-center justify-content-center" id="education-tab" data-toggle="tab" href="#education" aria-controls="education" role="tab" aria-selected="false">
                                        <HiOutlineAcademicCap size={16} />
                                        <span className="d-none d-sm-block">&nbsp;Education</span>
                                    </a>
                                </li>
                                {
                                    certificationDetails != null && ['Valid', 'Expired', 'Not Available'].includes(certificationDetails?.bqp_certified) && myProfileInfo?.is_team_member === true ?
                                        <li className="nav-item">
                                            <a className="nav-link d-flex align-items-center justify-content-center" id="certification-tab" data-toggle="tab" href="#certification" aria-controls="certification" role="tab" aria-selected="false">
                                                <IoMdRibbon size={16} />
                                                <span className="d-none d-sm-block">&nbsp;Certification</span>
                                            </a>
                                        </li>
                                        :
                                        <></>
                                }
                                <li className="nav-item">
                                    <a className="nav-link d-flex align-items-center justify-content-center" id="employment-tab" data-toggle="tab" href="#employment" aria-controls="employment" role="tab" aria-selected="false">
                                        <HiOutlineOfficeBuilding size={16} />
                                        <span className="d-none d-sm-block">&nbsp;Employment</span>
                                    </a>
                                </li>
                                {
                                    isEditable ?
                                        <li className="nav-item">
                                            <a className="nav-link d-flex align-items-center justify-content-center" id="change-password-tab" data-toggle="tab" href="#change-password" aria-controls="change-password" role="tab" aria-selected="false">
                                                <HiOutlineKey size={16} />
                                                <span className="d-none d-sm-block">&nbsp;Change Password</span>
                                            </a>
                                        </li>
                                        :
                                        <></>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <section className="users-edit">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="personal-information" aria-labelledby="personal-information-tab" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body px-0 py-0">
                                                <div className="form-body">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                                <div><FiUser size={16} />&nbsp;Personal Information</div>
                                                                {
                                                                    isEditable ?
                                                                        <button
                                                                            type="button"
                                                                            className="btn"
                                                                            onClick={() => {
                                                                                setShowPersonalInformationEdit(true);
                                                                            }}
                                                                        >
                                                                            <FiEdit size={16} />
                                                                        </button>
                                                                        :
                                                                        <></>
                                                                }

                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Employee ID</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo?.pinc_employee_id}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Father Name</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.father_name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Spouse Name</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.spouse_name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Birth Date</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.birthday}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Birth Place</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.birthday_place}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Blood Group</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.blood_group}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Gender</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{_.capitalize(myProfileInfo.gender)}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Status</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{_.capitalize(myProfileInfo.married_status)}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">No. of children</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.number_of_child}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.description}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                <FiPhone size={16} />&nbsp;Contact Information
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Email</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.personal_email}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Alternate Email</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.alternate_email}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Phone Number</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.contact}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                <FiHome size={16} />&nbsp;Address Information
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Communication Address</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.communication_address}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Permanent Address</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.permanent_address}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                <FiPhoneCall size={16} />&nbsp;Emergency Details
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Emergency Name</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.emergency_contact_person}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0">Emergency Mobile</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{myProfileInfo.emergency_number}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                <FiGlobe size={16} />&nbsp;Social Network
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className={`row`}>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0"><FaFacebookSquare size={20} color="#4267B2" /> Facebook</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className={`font-medium-1 text-bold-700 mb-0  ${myProfileInfo != null && myProfileInfo.facebook != null ? '' : 'hidden'}`}>
                                                                <a className="text-primary" onClick={() => {
                                                                    window.open(getHttpUrl(myProfileInfo.facebook), "_blank");
                                                                }}> Click Here </a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className={`row pt-2`}>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0"><FaLinkedin size={20} color="#0077b5" /> LinkedIn </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className={`font-medium-1 text-bold-700 mb-0 ${myProfileInfo != null && myProfileInfo.linkedin != null ? '' : 'hidden'}`}>
                                                                <a className="text-primary" onClick={() => {
                                                                    window.open(getHttpUrl(myProfileInfo.linkedin), "_blank");
                                                                }}> Click Here </a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className={`row pt-2`}>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className="text-uppercase font-small-3 mb-0"><FaTwitter size={20} color="#1DA1F2" /> Twitter</h4>
                                                        </div>
                                                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                                            <h4 className={`font-medium-1 text-bold-700 mb-0  ${myProfileInfo != null && myProfileInfo.twitter != null ? '' : 'hidden'}`}>
                                                                <a className="text-primary" onClick={() => {
                                                                    window.open(getHttpUrl(myProfileInfo.twitter), "_blank");
                                                                }}> Click Here </a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="education" aria-labelledby="education-tab" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body px-0 py-0">
                                                <form noValidate autoComplete="Off">
                                                    <div className="form-body">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                                    <div><HiOutlineAcademicCap size={20} />&nbsp;Education</div>
                                                                    {
                                                                        isEditable ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn"
                                                                                onClick={() => {
                                                                                    // setShowEducationDetails(true)
                                                                                    setShowEducationAdd(true);
                                                                                }}
                                                                            >
                                                                                <FiPlus size={20}
                                                                                    cursor="pointer"
                                                                                />
                                                                            </button>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </h4>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <div className="row mx-0">
                                                                    {
                                                                        educationDetails.length > 0 ?
                                                                            renderEducationDetails()
                                                                            :
                                                                            <h6 className="text-bold-500 text-center">No Education Details</h6>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        certificationDetails != null && certificationDetails?.bqp_certified != null && certificationDetails?.bqp_certified != 'Not Applicable' ?
                                            <div className="tab-pane" id="certification" aria-labelledby="certification-tab" role="tabpanel">
                                                <div className="card">
                                                    <div className="card-body  px-0 py-0">
                                                        <div className="row pt-1 mb-1">
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                    <IoMdRibbon size={16} />&nbsp;Certification
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                <h6 className="font-small-3 text-uppercase blue-grey darken-1">BQP Certified</h6>
                                                            </div>
                                                            <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                    certificationDetails != null && certificationDetails?.bqp_certified ?
                                                                        certificationDetails?.bqp_certified : "-"
                                                                }</h4>
                                                            </div>
                                                        </div>
                                                        {
                                                            certificationDetails != null && certificationDetails?.bqp_certified === 'Valid' || certificationDetails?.bqp_certified === 'Expired' ?
                                                                <div className={`row pt-2 ${props?.location?.state?.user_id === props?.location?.state?.id ? '' : 'hidden'}`}>
                                                                    <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Certificate Number </h6>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                            certificationDetails != null && certificationDetails?.certificate_number ?
                                                                                certificationDetails?.certificate_number : "-"
                                                                        }</h4>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            certificationDetails != null && certificationDetails?.bqp_certified === 'Expired' ?
                                                                <div className="row pt-2">
                                                                    <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Expiry Date</h6>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                        <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                            certificationDetails != null && certificationDetails?.end_date ?
                                                                                moment(certificationDetails?.end_date).format("DD/MM/YYYY") : "-"
                                                                        }</h4>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            certificationDetails != null && certificationDetails?.bqp_certified === 'Valid' ?
                                                                <>
                                                                    <div className="row pt-2">
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Start Date</h6>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                                certificationDetails != null && certificationDetails?.start_date ?
                                                                                    moment(certificationDetails?.start_date).format("DD/MM/YYYY") : "-"
                                                                            }</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row pt-2">
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">End Date</h6>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                                certificationDetails != null && certificationDetails?.end_date ?
                                                                                    moment(certificationDetails?.end_date).format("DD/MM/YYYY") : "-"
                                                                            }</h4>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            certificationDetails != null && certificationDetails?.bqp_certified === 'Valid' || certificationDetails?.bqp_certified === 'Expired' ?
                                                                <>
                                                                    <div className={`row pt-2 ${props?.location?.state?.user_id === props?.location?.state?.id ? '' : 'hidden'}`}>
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Certificate Copy</h6>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                                (certificationDetails != null && certificationDetails?.employee_bqp_document != null) ?
                                                                                    <div id="view_document">
                                                                                        {/* <a target="_blank" href={certificationDetails?.employee_bqp_document}>View Document</a> */}
                                                                                        <a className="primary"
                                                                                            onClick={() => {
                                                                                                if (certificationDetails?.employee_bqp_document?.split('.').pop() == 'pdf' ||
                                                                                                    certificationDetails?.employee_bqp_document?.split('.').pop() == 'PDF' ||
                                                                                                    certificationDetails?.employee_bqp_document?.split('.').pop() == 'png' ||
                                                                                                    certificationDetails?.employee_bqp_document?.split('.').pop() == 'jpg' ||
                                                                                                    certificationDetails?.employee_bqp_document?.split('.').pop() == 'jpeg' ||
                                                                                                    certificationDetails?.employee_bqp_document?.split('.').pop() == 'gif'
                                                                                                ) {
                                                                                                    setSelectedDocumentIndex(certificationDetails?.employee_bqp_document)
                                                                                                    setShowDocumentDetails(true);
                                                                                                    setExtension(certificationDetails?.employee_bqp_document?.split('.').pop())
                                                                                                    setDocumentDetails(certificationDetails)
                                                                                                } else {
                                                                                                    downloadFile(certificationDetails?.employee_bqp_document);
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            View Document
                                                                                        </a>
                                                                                        <a id="downloadLink" className="d-none"></a>
                                                                                    </div>
                                                                                    : <h4 className="font-medium-1 text-bold-700 mb-0">-</h4>
                                                                            }</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`row pt-2 ${props?.location?.state?.user_id === props?.location?.state?.id ? '' : 'hidden'}`}>
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Brokering Entity</h6>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                            <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                                certificationDetails != null && certificationDetails?.brokering_entity ?
                                                                                    certificationDetails?.brokering_entity : "-"
                                                                            }</h4>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                        {certificationDetails != null && certificationDetails?.brokering_entity === 'Previous Organization' ?
                                                            <div className={`row pt-2 ${props?.location?.state?.user_id === props?.location?.state?.id ? '' : 'hidden'}`}>
                                                                <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">NOC</h6>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-md-6 align-items-center">
                                                                    <h4 className="font-medium-1 text-bold-700 mb-0">{
                                                                        certificationDetails != null && certificationDetails?.noc_type ?
                                                                            certificationDetails?.noc_type : "-"
                                                                    }</h4>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                    <div className="tab-pane" id="employment" aria-labelledby="employment-tab" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body px-0 py-0">
                                                <form noValidate autoComplete="Off">
                                                    <div className="form-body">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                                    <div><HiOutlineAcademicCap size={20} />&nbsp;Employment</div>
                                                                    {
                                                                        isEditable ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn"
                                                                                onClick={() => {
                                                                                    setShowEmploymentAdd(true);
                                                                                }}
                                                                            >
                                                                                <FiPlus size={20} />
                                                                            </button>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </h4>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <div className="row mx-0">
                                                                    {
                                                                        employeeDetails.length > 0 ?
                                                                            renderEmployeeDetails()
                                                                            :
                                                                            <h6 className="text-bold-500 text-center">No Employee Details</h6>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="change-password" aria-labelledby="change-password-tab" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body px-0 py-0">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="form-body">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                                            <HiOutlineKey size={16} />&nbsp;Change Password
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div className="form-group">
                                                                            <label className="label-control">Current Password&nbsp;<span className="danger">*</span></label>
                                                                            <input
                                                                                className="form-control"
                                                                                id="old_password"
                                                                                name="old_password"
                                                                                placeholder="Enter Current Password"
                                                                                {...register('old_password', {
                                                                                    required: 'Current Password is required',
                                                                                    minLength: {
                                                                                        value: 8,
                                                                                        message: 'Please enter minimum 8 character'
                                                                                    }
                                                                                })}
                                                                            />
                                                                            {errors.old_password && <ErrorLabel message={errors.old_password?.message} />}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div className="form-group">
                                                                            <label className="label-control">Your New Password&nbsp;<span className="danger">*</span></label>
                                                                            <input
                                                                                className="form-control"
                                                                                id="new_password"
                                                                                name="new_password"
                                                                                placeholder="Enter Your New Password"
                                                                                {...register('new_password', {
                                                                                    required: 'New Password is required',
                                                                                    minLength: {
                                                                                        value: 8,
                                                                                        message: 'Please enter minimum 8 character'
                                                                                    },
                                                                                    validate: (value) => {
                                                                                        if (value.search(/[0-9]/) == -1) {
                                                                                            return "Atlease 1 numeric value is required"
                                                                                        }
                                                                                        if (value.search(/[a-z]/) == -1) {
                                                                                            return "Atlease 1 small letter is required"
                                                                                        }
                                                                                        if (value.search(/[A-Z]/) == -1) {
                                                                                            return "Atlease 1 Capital letter is required"
                                                                                        }
                                                                                        if (value.search(/[#$^+=!*()@%&]/) == -1) {
                                                                                            return "Atlease 1 Special Symbol is required"
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            {errors.new_password && <ErrorLabel message={errors.new_password?.message} />}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div className="form-group">
                                                                            <label className="label-control">Confirm New Password&nbsp;<span className="danger">*</span></label>
                                                                            <input
                                                                                className="form-control"
                                                                                id="confirm_password"
                                                                                name="confirm_password"
                                                                                placeholder="Enter Confirm New Password"
                                                                                {...register('confirm_password', {
                                                                                    required: 'Confirm Password is required',
                                                                                    minLength: {
                                                                                        value: 8,
                                                                                        message: 'Please enter minimum 8 character'
                                                                                    },
                                                                                    validate: (value) => {
                                                                                        if (value.search(/[0-9]/) == -1) {
                                                                                            return "Atlease 1 numeric value is required"
                                                                                        }
                                                                                        if (value.search(/[a-z]/) == -1) {
                                                                                            return "Atlease 1 small letter is required"
                                                                                        }
                                                                                        if (value.search(/[A-Z]/) == -1) {
                                                                                            return "Atlease 1 Capital letter is required"
                                                                                        }
                                                                                        if (value.search(/[#$^+=!*()@%&]/) == -1) {
                                                                                            return "Atlease 1 Special Symbol is required"
                                                                                        }
                                                                                    },
                                                                                    validate: (value) => {
                                                                                        return getValues("new_password") === value || "New Password & Confirm Password do not match"
                                                                                    }
                                                                                })}
                                                                            />
                                                                            {errors.confirm_password && <ErrorLabel message={errors.confirm_password?.message} />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-actions border-0 mt-0 pt-0 float-none">
                                                                <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                                    onClick={(event) => {
                                                                        props.handleClose();
                                                                        event.preventDefault();
                                                                    }}
                                                                >Cancel</button>

                                                                <button
                                                                    id="change_password"
                                                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                                                    type="submit"
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        Submit
                                                                        <span id="change_password-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                                    </div> </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                    <EducationAdd
                        show={showEducationAdd}
                        handleClose={(newEducation) => {
                            if (newEducation != null) {
                                var arrnewEducation = Object.assign([], educationDetails)
                                arrnewEducation.push(newEducation)
                                setEducationDetails(arrnewEducation)
                            }
                            setShowEducationAdd(false)
                        }}
                    />

                    {/* <EducationEdit
                        show={showEducationEdit}
                        handleClose={() => {
                            setShowEducationEdit(false)
                        }}
                        educationId={selectedEducationId}
                    /> */}
                    <EducationEdit
                        show={showEducationEdit}
                        handleClose={(newEducation) => {
                            if (newEducation != null) {
                                var arrnewEducation = Object.assign([], educationDetails)
                                if (selectedEducationIndex != null) {
                                    arrnewEducation[selectedEducationIndex] = newEducation;
                                    setSelectedEducationIndex(null);
                                } else {
                                    arrnewEducation.push(newEducation)
                                }
                                setEducationDetails(arrnewEducation)
                            }
                            setShowEducationEdit(false)
                        }}
                        educationId={selectedEducationId}
                    />


                    <EmploymentAdd
                        show={showEmploymentAdd}
                        handleClose={(newEmployment) => {
                            if (newEmployment != null) {
                                var arrnewEmployment = Object.assign([], employeeDetails)
                                arrnewEmployment.push(newEmployment)
                                setEmployeeDetails(arrnewEmployment)
                            }
                            setShowEmploymentAdd(false)
                        }}
                    />
                    <EmploymentEdit
                        show={showEmploymentEdit}
                        handleClose={(newEmployment) => {
                            if (newEmployment != null) {
                                var arrnewEmployment = Object.assign([], employeeDetails)
                                if (selectedEmploymentIndex != null) {
                                    arrnewEmployment[selectedEmploymentIndex] = newEmployment;
                                    setSelectedEmploymentIndex(null);
                                } else {
                                    arrnewEmployment.push(newEmployment)
                                }
                                setEmployeeDetails(arrnewEmployment)
                            }
                            setShowEmploymentEdit(false)
                        }}
                        employmentId={selectedEmploymentId}
                    />

                    <PersonalInformationEdit
                        show={showPersonalInformationEdit}
                        handleClose={(response) => {

                            setShowPersonalInformationEdit(false)
                            $("#progress-loader").removeClass("hidden");
                            props.actionMyProfile({ user_id: props.auth.getUser().user_id });
                            // if( response.reload ) { 
                            //     window.location.reload();
                            // }
                        }}
                        data={myProfileInfo}
                    />

                </section >
            </div >
            {/* <div className={`${selectedTab == 1 ? '' : 'hidden'}`}>
                <TabPost 
                    userId={selectedUserId}
                    tab={selectedTab}
                />
            </div>
            <div className={`${selectedTab == 2 ? '' : 'hidden'}`}>
                <TabMeetings 
                    userId={selectedUserId}
                    tab={selectedTab}
                />
            </div>
            <div className={`${selectedTab == 3 ? '' : 'hidden'}`}>
                <TabClients 
                    userId={selectedUserId}
                    tab={selectedTab}
                />
            </div>
            <div className={`${selectedTab == 4 ? '' : 'hidden'}`}>
                <TabLeads 
                    userId={selectedUserId}
                />
            </div>
            <div className={`${selectedTab == 5 ? '' : 'hidden'}`}>
                <TabTeam 
                    userId={selectedUserId}
                />
            </div> */}
            {
                renderTab()
            }
            <VisitingCard
                show={showVisitingCard}
                data={myProfileInfo}
                handleClose={() => {
                    setShowVisitingCard(false)
                }}
            />
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}


const mapStateToProps = (state) => {
    var MyProfileInfo = null;
    var DeleteEmployeeEducation = null;
    var DeleteEmployeeEmployment = null;
    var ChangePassword = null;


    if (
        state.MyProfileReducer != null &&
        Object.keys(state.MyProfileReducer).length > 0 &&
        state.MyProfileReducer.constructor === Object
    ) {
        switch (state.MyProfileReducer.tag) {
            case ACTION_MY_PROFILE_SUCCESS:
                MyProfileInfo = Object.assign({}, state.MyProfileReducer.data);
                delete state.MyProfileReducer;
                return { MyProfileInfo };
            case ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS:
                DeleteEmployeeEducation = Object.assign({}, state.MyProfileReducer.data);
                delete state.MyProfileReducer;
                return { DeleteEmployeeEducation };
            case ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS:
                DeleteEmployeeEmployment = Object.assign({}, state.MyProfileReducer.data);
                delete state.MyProfileReducer;
                return { DeleteEmployeeEmployment };
            case ACTION_CHANGE_PASSWORD_SUCCESS:
                ChangePassword = Object.assign({}, state.MyProfileReducer.data);
                delete state.MyProfileReducer;
                return { ChangePassword };
            default:

        }
    }

    return {
        MyProfileInfo,
        DeleteEmployeeEducation,
        DeleteEmployeeEmployment,
        ChangePassword
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMyProfile, actionDeleteEmployeeEducation, actionDeleteEmployeeEmployment, actionChangePassword }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MyProfile))
