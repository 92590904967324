import React from 'react';
import { Route, Switch } from 'react-router';
import ServeyorMeetingAdd from './add';
import ServeyorMeetingEdit from './edit';
import ServeyorMeetings from './list';
import ServeyorMeetingView from './view';

const ServeyorMeetingRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={ServeyorMeetingAdd}/>
                <Route path={`${match.url}/edit`}  component={ServeyorMeetingEdit}/>
                <Route path={`${match.url}/view`}  component={ServeyorMeetingView}/>
                <Route exact path={`${match.url}`}  component={ServeyorMeetings}/>
            </Switch>
        </>
    )
}

export default ServeyorMeetingRoute