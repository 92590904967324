import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_PROPOSAL_NEW_POOL_LIST, API_EDITPOLICY } from '../../../../../../../api/constants';
import { PRINT } from '../.././../../../../../utils';

export const ACTION_API_GROUP_LEVEL_OPPORTUNITY_LOADING = 'ACTION_API_GROUP_LEVEL_OPPORTUNITY_LOADING';
export const ACTION_API_GROUP_LEVEL_OPPORTUNITY_SUCCESS = 'ACTION_API_GROUP_LEVEL_OPPORTUNITY_SUCCESS';

export const ACTION_GROUP_OPPORTUNITY_EDIT_LOADING = 'ACTION_GROUP_OPPORTUNITY_EDIT_LOADING';
export const ACTION_GROUP_OPPORTUNITY_EDIT_SUCCESS = 'ACTION_GROUP_OPPORTUNITY_EDIT_SUCCESS';

export function actionGroupLevelOpportunityView(params) {
    return (dispatch, getState) => Api.post(API_PROPOSAL_NEW_POOL_LIST, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_API_GROUP_LEVEL_OPPORTUNITY_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_API_GROUP_LEVEL_OPPORTUNITY_LOADING, { loading: false, data }));
    });
}

export function actionGroupLevelOpportunityEdit(params) {
    return (dispatch, getState) => Api.post(API_EDITPOLICY, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_GROUP_OPPORTUNITY_EDIT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_GROUP_OPPORTUNITY_EDIT_LOADING, { loading: false, data }));
    });
}
