import React, { useState,useEffect } from "react";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiUserPlus } from 'react-icons/fi';
import { verifyPermission } from '../../../../../../utils';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import $ from 'jquery';
import { PRIMARY_COLOR, UNAUTHORIZED, ACTIVE } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { actionNatureExpencesList, ACTION_NATURE_EXPENCES_LIST_SUCCESS } from './action';
import{actionNatureExpencesStatus,ACTION_NATURE_EXPENCES_STATUS_SUCCESS} from './action'
const NatureOfExpenses = (props) => {
    const { match } = props;

    const[NatureExpencesList,setNatureExpencesList]=useState([]);
    const columns = [
        // { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "title", caption: " Name" },
        { dataField: "status", caption: "Status" },

    ];
    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        // $("#progress-loader").removeClass("hidden");
        props.actionNatureExpencesList({});
    }
    useEffect(initialRender, []);
   
    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'A':
                return <div className={"badge badge-success round px-1"} 
                // onClick={() => {
                //     Swal.fire({
                //         title: `Inactivate Team`,
                //         text: `Are you sure, you want to inactivate ?`,
                //         showCancelButton: true,
                //         cancelButtonText: 'Cancel',
                //         confirmButtonText: 'Ok',
                //         confirmButtonColor: PRIMARY_COLOR,
                //     }).then(success => {
                //         if (success.isConfirmed) {
                //             $("#progress-loader").removeClass("hidden");
                //             props.actionNatureExpencesStatus({
                //                 id: cell.data.id,
                //                 status: 'I'
                //             })
                //         }
                //     });
                // }}
                >
                    Active</div>;
            case 'I':
                return <div className={"badge badge-danger round px-1 "} 
                // cursor-pointer
                // onClick={() => {
                //     Swal.fire({
                //         title: `Activate Team`,
                //         text: `Are you sure, you want to activate ?`,
                //         showCancelButton: true,
                //         cancelButtonText: 'Cancel',
                //         confirmButtonText: 'Ok',
                //         confirmButtonColor: PRIMARY_COLOR,
                //     }).then(success => {
                //         if (success.isConfirmed) {
                //             props.actionNatureExpencesStatus({
                //                 id: cell.data.id,
                //                 status: 'A'
                //             })
                //         }
                //     });
                // }}
                >Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("UPDATE_ROLES") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { roles: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }
                    </Column>
                );
            }
            else {
                if (objColumn.dataField === "natureexpenses_id") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            alignment="left"
                            width={200}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}

                        ></Column>

                    )
                }

            }


        });

        return arrColumns;
    };
    const onNatureExpencesListUpdate = () => {
        const { NatureExpencesList } = props
        var NatureExpencesListResponse = Object.assign({}, NatureExpencesList);
        if (NatureExpencesListResponse.result === true) {
            setNatureExpencesList(NatureExpencesListResponse.response);
        } else {
            switch (NatureExpencesListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onNatureExpencesListUpdate, [props.NatureExpencesList])
    
    const onNatureExpencesStatusUpdate = () => {
        const { NatureExpencesStatus } = props;
        var NatureExpencesStatusResponse = Object.assign({}, NatureExpencesStatus);
        if (NatureExpencesStatusResponse.result === true) {
            props.actionNatureExpencesList();
            $("#progress-loader").addClass("hidden");
        } else {
            switch (NatureExpencesStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onNatureExpencesStatusUpdate, [props.NatureExpencesStatus])

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 ">
                    <h5 className="content-header-title text-capitalize mb-0 ">Reimbursement Type</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            {/* <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push('/settings/nature_of_expenses/add');
                                }}
                            >
                                Create Nature Of Expenses
                            </button> */}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={NatureExpencesList}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "Reimbursement type",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var NatureExpencesList = null;
    var NatureExpencesStatus=null;
    if (
        state.NatureExpencesListReducer != null &&
        Object.keys(state.NatureExpencesListReducer).length > 0 &&
        state.NatureExpencesListReducer.constructor === Object
    ) {
        switch (state.NatureExpencesListReducer.tag) {
            case ACTION_NATURE_EXPENCES_LIST_SUCCESS:
                NatureExpencesList = Object.assign({}, state.NatureExpencesListReducer.data);
                delete state.NatureExpencesListReducer;
                return { NatureExpencesList };
                case ACTION_NATURE_EXPENCES_STATUS_SUCCESS:
                    NatureExpencesStatus = Object.assign({}, state.NatureExpencesListReducer.data);
                delete state.NatureExpencesListReducer;
                return { NatureExpencesStatus };
            default:

        }
    }
    return {
        NatureExpencesList,
        NatureExpencesStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionNatureExpencesList,actionNatureExpencesStatus }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(NatureOfExpenses))
