
import Api from '../../../../../../api';
import { fetchSuccess, loading } from './../../../../../../utils/action.util';
import { BIRTHDAY_ADD } from '../../../../../../api/constants'
import { PRINT } from './../../../../../../utils/index';
import { API_MASTER_ASSIGN_RM,  } from '../../../../../../api/constants';

export const ACTION_BIRTHDAY_WISH_ADD_LOADING = 'ACTION_BIRTHDAY_WISH_ADD_LOADING';
export const ACTION_BIRTHDAY_WISH_ADD_SUCCESS = 'ACTION_BIRTHDAY_WISH_ADD_SUCCESS';

export const ACTION_MASTER_SALES_RM_LOADING = 'ACTION_MASTER_SALES_RM_LOADING';
export const ACTION_MASTER_SALES_RM_SUCCESS = 'ACTION_MASTER_SALES_RM_SUCCESS';

export function actionBirthdayWishAdd(params) {
    return (dispatch, getState) =>
        Api.post(BIRTHDAY_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_BIRTHDAY_WISH_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BIRTHDAY_WISH_ADD_LOADING, { loading: false, data }));
        });
}

export function actionSalesRM(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ASSIGN_RM, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_SALES_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_SALES_RM_LOADING, { loading: false, data }));
        });
}