import createReducer from "../../../../../reducers/createReducer";
import { ACTION_MASTER_POLICY_TYPE_SUCCESS, ACTION_POLICY_VIEW_LIST_SUCCESS, ACTION_MASTER_ASSIGN_RM_SUCCESS, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const PolicyViewReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POLICY_VIEW_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POLICY_VIEW_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
   
})
export default PolicyViewReducer