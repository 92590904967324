import React, { useEffect } from "react";
import $ from "jquery";
import { useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { actionAddEmployment, ACTION_ADD_EMPLOYMENT_SUCCESS } from "./action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { withAuth } from "react-auth-guard";
import { toast } from "react-toastify";
const EmploymentAdd = (props) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onAddEmploymentUpdate = () => {
        const { AddEmployment } = props;
        var AddEmploymentResponse = Object.assign({}, AddEmployment);
        $("#add_employment").prop("disabled", false);
        if (AddEmploymentResponse.result === true) {
            props.handleClose(AddEmploymentResponse.response);
            $("#add_employment-loader").addClass("hidden");
        } else {
            $("#add_employment-loader").addClass("hidden");
            switch (AddEmploymentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(AddEmploymentResponse.message);
                default:
            }
        }
    }
    useEffect(onAddEmploymentUpdate, [props.AddEmployment])

    const initialRender = () => {
        if (props.show === true) {

            reset({
                name_of_organization: '',
                duration: '',
                description: '',
                designation: ''
            })

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onSubmit = (data) => {
        $("#add_employment-loader").removeClass("hidden");
        $("#add_employment").prop("disabled", true);
        data.user_id = props.auth.getUser().user_id
        props.actionAddEmployment(data);
    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="employment-add"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="employment-add">
                                    Add Employment Details
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">

                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Name of organization&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="name_of_organization"
                                                            name="name_of_organization"
                                                            placeholder="Enter Name of organization"
                                                            {...register('name_of_organization', { required: 'Name of organization is required' })}
                                                        />
                                                        {errors.name_of_organization && <ErrorLabel message={errors.name_of_organization?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Duration&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="duration"
                                                            name="duration"
                                                            placeholder="Enter Duration"
                                                            {...register('duration', { required: 'Duration is required' })}
                                                        />
                                                        {errors.duration && <ErrorLabel message={errors.duration?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="description"
                                                            name="description"
                                                            placeholder="Enter Description"
                                                            {...register('description', { required: 'Description is required' })}
                                                        />
                                                        {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Designation&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="designation"
                                                            name="designation"
                                                            placeholder="Enter Designation"
                                                            {...register('designation', { required: 'Designation is required' })}
                                                        />
                                                        {errors.designation && <ErrorLabel message={errors.designation?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                id="add_employment"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Submit
                                                    <span id="add_employment-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    props.handleClose();
                                                    event.preventDefault();
                                                }}
                                            > Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var AddEmployment = null;

    if (
        state.AddEmploymentReducer != null &&
        Object.keys(state.AddEmploymentReducer).length > 0 &&
        state.AddEmploymentReducer.constructor === Object
    ) {
        switch (state.AddEmploymentReducer.tag) {
            case ACTION_ADD_EMPLOYMENT_SUCCESS:
                AddEmployment = Object.assign({}, state.AddEmploymentReducer.data);
                delete state.AddEmploymentReducer;
                return { AddEmployment };
            default:
        }
    }

    return {
        AddEmployment
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAddEmployment }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EmploymentAdd))
