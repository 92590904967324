import React from 'react';
import {Route,Switch} from "react-router";
import BranchAddress from './list';
import Add from './add';
import Edit from './edit';

const BranchAddressesRoute = (props) =>{
    const {match} = props

    return (
        <>
        <Switch>
            <Route path={`${match.url}/add`}  component={Add}/>
            <Route path={`${match.url}/edit`}  component={Edit}/>
            <Route exact path={`${match.url}`}  component={BranchAddress}/>
        </Switch>
        </>
    )
}
export default BranchAddressesRoute