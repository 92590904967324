import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { useForm, Controller } from "react-hook-form";
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import ScreenTitle from '../../../../../../../component/screen_title';
import ErrorLabel from '../../../../../../../component/form/error_label';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { actionClaimPolicyDetails, ACTION_POLICY_DETAILS_SUCCESS } from './action';
import { actionClaimPolicyType, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from './action';
import { actionNatureOfLoss, ACTION_NATURE_OF_LOSS_SUCCESS } from './action';
import { actionClaimType, ACTION_CLAIM_TYPE_SUCCESS } from './action';
import { actionClaimStatus, ACTION_CLAIM_STATUS_SUCCESS } from './action';
import { actionPincAdd, ACTION_PINC_ADD_SUCCESS } from './action';
import { actionClaimHandler, ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS } from './action'
import moment from 'moment';
import Swal from 'sweetalert2';

const Pinc = (props) => {

   const [fromDate, setFromDate] = useState(dateFormat(moment().add(1, 'days'), "yyyy-mm-dd"));
   const { register, handleSubmit, control, formState: { errors } } = useForm({});
   const [lossDate, setLossDate] = useState(dateFormat("mm/dd/yyyy"));
   const [insuredDate, setInsuredDate] = useState(dateFormat("mm/dd/yyyy"));
   const [insurerDate, setInsurerDate] = useState(dateFormat("mm/dd/yyyy"));
   const maxDateValue = new Date()
   const minFollowUpdate = (fromDate)
   const [claimPolicyDetails, setClaimPolicyDetails] = useState([]);
   const [claimPolicyType, setClaimPolicyType] = useState([]);
   const [natureOfLoss, setNatureOfLoss] = useState([]);
   const [claimType, setClaimType] = useState([]);
   const [claimStatus, setClaimStatus] = useState([]);
   const [selectedPolicyType, setSelectedPolicyType] = useState(null);
   const [userProfile, setUserProfile] = useState([]);

   const initialRender = () => {
      $("#progress-loader").removeClass("hidden");
      props.actionClaimPolicyDetails({ control_no: props.location.state.control_no });
      props.actionClaimPolicyType();
      props.actionNatureOfLoss();
      props.actionClaimType();
      props.actionClaimStatus();
      props.actionClaimHandler({ user_id: props.auth.getUser().user_id })
   }
   useEffect(initialRender, []);


   const onClaimPolicyDetailsUpdate = () => {
      const { ClaimPolicyDetails } = props;
      if (ClaimPolicyDetails != null) {
         var ClaimPolicyDetailsResponse = Object.assign({}, ClaimPolicyDetails);
         if (ClaimPolicyDetailsResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setClaimPolicyDetails(ClaimPolicyDetailsResponse.response);
            if (ClaimPolicyDetailsResponse.response.department) {
               setSelectedPolicyType(ClaimPolicyDetailsResponse.response.department)
            }
         } else {
            $("#progress-loader").addClass("hidden");
            switch (ClaimPolicyDetailsResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onClaimPolicyDetailsUpdate, [props.ClaimPolicyDetails]);

   const onClaimPolicyTypeUpdate = () => {
      const { ClaimPolicyType } = props;
      if (ClaimPolicyType != null) {
         var ClaimPolicyTypeResponse = Object.assign({}, ClaimPolicyType);
         if (ClaimPolicyTypeResponse.result === true) {
            setClaimPolicyType(ClaimPolicyTypeResponse.response);
         } else {
            switch (ClaimPolicyTypeResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onClaimPolicyTypeUpdate, [props.ClaimPolicyType]);

   // useEffect(() => {
   //    if (claimPolicyDetails != null && claimPolicyDetails.policy_type != null) {
   //       if (claimPolicyType != null && claimPolicyType.length > 0) {
   //          setValue('policy_type', claimPolicyDetails.policy_type);
   //       }
   //    }
   // }, [claimPolicyType])

   const onNatureOfLossUpdate = () => {
      const { NatureOfLoss } = props;
      if (NatureOfLoss != null) {
         var NatureOfLossResponse = Object.assign({}, NatureOfLoss);
         if (NatureOfLossResponse.result === true) {
            setNatureOfLoss(NatureOfLossResponse.response);
         } else {
            switch (NatureOfLossResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onNatureOfLossUpdate, [props.NatureOfLoss]);

   const onClaimTypeUpdate = () => {
      const { ClaimType } = props;
      if (ClaimType != null) {
         var ClaimTypeResponse = Object.assign({}, ClaimType);
         if (ClaimTypeResponse.result === true) {
            setClaimType(ClaimTypeResponse.response);
         } else {
            switch (ClaimTypeResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onClaimTypeUpdate, [props.ClaimType]);

   const onClaimStatusUpdate = () => {
      const { ClaimStatus } = props;
      if (ClaimStatus != null) {
         var ClaimStatusResponse = Object.assign({}, ClaimStatus);
         if (ClaimStatusResponse.result === true) {
            setClaimStatus(ClaimStatusResponse.response);
         } else {
            switch (ClaimStatusResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onClaimStatusUpdate, [props.ClaimStatus]);

   const onPincAddSave = (params) => {
      const { PincAdd } = props;
      if (PincAdd != null) {
         var PincAddResponse = Object.assign({}, PincAdd);
         $("#add_detail").prop("disabled", false);
         if (PincAddResponse.result === true) {
            $("#add_detail_loader").addClass("hidden");
            Swal.fire({
               title: 'Success',
               html: 'Claim has been registered successfully.<br> PINC Claim Ref. No. - ' + (PincAddResponse.response.pinc_claim_ref_no),
               icon: 'success',
            }).then((result) => {
               props.history.push({ pathname: `/claims/edit`, state: { control_no: PincAddResponse.response.control_no, claim_id: PincAddResponse.response.id } })
            });
         } else {
            switch (PincAddResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onPincAddSave, [props.PincAdd]);

   const onClaimRegisterPincAddProfileUpdate = () => {
      const { ClaimRegisterPincAddProfile } = props;
      var ClaimRegisterPincAddProfileResponse = Object.assign({}, ClaimRegisterPincAddProfile);
      if (ClaimRegisterPincAddProfileResponse.result === true) {
         setUserProfile(ClaimRegisterPincAddProfileResponse.response);
      } else {
         switch (ClaimRegisterPincAddProfileResponse.status) {
            case UNAUTHORIZED:
               props.auth.logout();
               break;
            default:
         }
      }
   }
   useEffect(onClaimRegisterPincAddProfileUpdate, [props.ClaimRegisterPincAddProfile])
   const onSubmit = (data) => {

      if (selectedPolicyType === 'MARINE') {
         delete data.motor;
      }
      if (selectedPolicyType === 'MOTOR') {
         delete data.marine;
      }
      // alert(JSON.stringify(data));

      data.policy_id = claimPolicyDetails.id;
      data.policy_type = claimPolicyDetails.policy_type;
      $("#add_detail_loader").removeClass("hidden");
      $("#add_detail").prop("disabled", true);
      props.actionPincAdd(data);

   }

   return (
      <>
         <div>
            <div className="row col-12 pr-0">
               <div className="col-12 col-md-10 col-lg-10">
                  <ScreenTitle title={props.location.state.type === 'Mandate' ? 'Register Mandate Claim' : 'Register PINC Claim'} />
               </div>
               <div className="col-12 col-md-2 col-lg-2 mb-1 mr-0 pr-0" >
                  <button class="btn btn-outline-secondary shadow round text-uppercase mx-0 ml-3 float-right"
                     onClick={() => { props.history.goBack() }}> BACK
                  </button>
               </div>
            </div>
            <div className="card">
               <div className="card-body">
                  <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                     <div class="form-body">
                        <div class="row">
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Policy Details
                              </h4>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurance Company</label>
                                 <input
                                    name="insurer_name"
                                    id="insurer_name"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.insurer_name != null ? claimPolicyDetails.insurer_name : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Branch</label>
                                 <input
                                    name="insurer_branch"
                                    id="insurer_branch"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.insurer_branch != null ? claimPolicyDetails.insurer_branch : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Customer Name</label>
                                 <input
                                    name="customer_name"
                                    id="customer_name"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.customer_name != null ? claimPolicyDetails.customer_name : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Customer Group</label>
                                 <input
                                    name="customer_group"
                                    id="customer_group"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.customer_group != null ? claimPolicyDetails.customer_group : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Type</label>
                                 <input
                                    name="policy_type"
                                    id="policy_type"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_type != null ? claimPolicyDetails.policy_type : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Number</label>
                                 <input
                                    name="policy_no"
                                    id="policy_no"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_no != null ? claimPolicyDetails.policy_no : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Start Date</label>
                                 <input
                                    name="policy_start_date"
                                    id="policy_start_date"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_start_date != null ? dateFormat(claimPolicyDetails.policy_start_date, 'dd/mm/yyyy') : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy End Date</label>
                                 <input
                                    name="policy_expiry_date"
                                    id="policy_expiry_date"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_expiry_date != null ? dateFormat(claimPolicyDetails.policy_expiry_date, 'dd/mm/yyyy') : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Sum Insured</label>
                                 <input
                                    name="sum_insured"
                                    id="sum_insured"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.sum_insured != null ? claimPolicyDetails.sum_insured : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Gross Premium</label>
                                 <input
                                    name="gross_premium"
                                    id="gross_premium"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.gross_premium != null ? claimPolicyDetails.gross_premium : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">RM Name</label>
                                 <input
                                    name="rm_name"
                                    id="rm_name"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.rm_name != null ? claimPolicyDetails.rm_name : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Control Number</label>
                                 <input
                                    name="control_no"
                                    id="control_no"
                                    className="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.control_no != null ? claimPolicyDetails.control_no : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Claim Intimation Form
                              </h4>
                           </div>
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Pinc Claim ID&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="pinc_claim_id"
                                    id="pinc_claim_id"
                                    className="form-control"
                                    type="text"
                                    {...register('pinc_claim_id', { required: 'Pinc Claim ID is required' })}
                                 />
                                 {errors.pinc_claim_id && <ErrorLabel message={errors.pinc_claim_id?.message} />}
                              </div>
                           </div> */}
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Inc/Loss Date&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="date_of_loss"
                                    className="form-control"
                                    control={control}
                                    // defaultValue={fromDate}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          max={maxDateValue}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             var objLossDate = new Date(value);
                                             var objToDate = new Date();

                                             if (objToDate.getTime() < objLossDate.getTime()) {
                                                Swal.fire({
                                                   title: 'Error',
                                                   text: "Inc/Loss Date can't be greater than Insured Intimation Date",
                                                   icon: 'error'
                                                }).then(success => {
                                                })
                                             } else {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setLossDate(dateFormat(value, 'yyyy-mm-dd'));
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Inc/Loss Date is required'
                                    }}
                                 />
                                 {errors.date_of_loss && <ErrorLabel message={errors.date_of_loss?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss/Intimation Amount&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_amount"
                                    id="loss_amount"
                                    className="form-control"
                                    type="number"
                                    placeholder="Enter Loss/Intimation Amount"
                                    {...register('loss_amount', { required: 'Loss/Intimation Amount is required' })}
                                 />
                                 {errors.loss_amount && <ErrorLabel message={errors.loss_amount?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Excess Amount</label>
                                 <input
                                    name="excess_amount"
                                    id="excess_amount"
                                    className="form-control"
                                    type="number"
                                    placeholder="Enter Excess Amount"
                                    {...register('excess_amount')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Inci/Loss Details&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_details"
                                    id="loss_details"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Inci/Loss Details"
                                    {...register('loss_details', { required: 'Loss Details is required' })}
                                 />
                                 {errors.loss_details && <ErrorLabel message={errors.loss_details?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Intimation Date&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="insured_intimation_date"
                                    className="form-control"
                                    control={control}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          placeholder='DD/MM/YYYY'
                                          max={maxDateValue}
                                          acceptCustomValue={false}
                                          onValueChange={(value) => {
                                             var objLossDate = new Date(lossDate);
                                             var objInsuredDate = new Date(value);

                                             if (objInsuredDate.getTime() < objLossDate.getTime()) {
                                                Swal.fire({
                                                   title: 'Error',
                                                   text: "Insured Intimation Date must be greater than Loss Date",
                                                   icon: 'error'
                                                }).then(success => {
                                                })
                                             } else {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setInsuredDate(dateFormat(value, 'yyyy-mm-dd'));
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Insured Intimation Date is required'
                                    }}
                                 />
                                 {errors.insured_intimation_date && <ErrorLabel message={errors.insured_intimation_date?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Name&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insured_name"
                                    id="insured_name"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Insured Name"
                                    {...register('insured_name', { required: 'Insured Name is required' })}
                                 />
                                 {errors.insured_name && <ErrorLabel message={errors.insured_name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Contact Details</label>
                                 <input
                                    name="contact_details"
                                    id="contact_details"
                                    className="form-control"
                                    placeholder="Enter Insured Contact Details"
                                    type="text"
                                    {...register('contact_details')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Email</label>
                                 <input
                                    name="insured_email"
                                    id="insured_email"
                                    className="form-control"
                                    placeholder="Enter Insured Email"
                                    {...register('insured_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    },
                                    )}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Intimation Mode</label>
                                 <input
                                    name="insured_intimation_mode"
                                    id="insured_intimation_mode"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Insured Intimation Mode"
                                    {...register('insured_intimation_mode')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Claim Ref No.</label>
                                 <input
                                    name="insured_claim_ref_no"
                                    id="insured_claim_ref_no"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Insured Claim Ref No."
                                    {...register('insured_claim_ref_no')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Nature of Loss&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="nature_of_loss"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={natureOfLoss}
                                          fields={{ text: 'name', value: 'name' }}
                                          allowFiltering={false}
                                          placeholder="Select Nature of Loss"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Nature of Loss is required'
                                    }}
                                 />
                                 {errors.nature_of_loss && <ErrorLabel message={errors.nature_of_loss?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Location</label>
                                 <input
                                    name="loss_location"
                                    id="loss_location"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Loss Location"
                                    {...register('loss_location')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss State</label>
                                 <input
                                    name="loss_state"
                                    id="loss_state"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Loss State"
                                    {...register('loss_state')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Type&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="claim_type"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimType}
                                          fields={{ text: 'name', value: 'name' }}
                                          allowFiltering={false}
                                          placeholder="Select Claim Type"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Claim type is required'
                                    }}
                                 />
                                 {errors.claim_type && <ErrorLabel message={errors.claim_type?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Asset/Machine Details</label>
                                 <input
                                    name="asset_detail"
                                    id="asset_detail"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Asset/Machine Details"
                                    {...register('asset_detail')}
                                 />
                              </div>
                           </div>
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Type</label>
                                 <Controller
                                    name="policy_type"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimPolicyType}
                                          fields={{ text: 'name', value: 'name' }}
                                          placeholder="Select Policy Type"
                                          allowFiltering={true}
                                          value={field.value}
                                          change={(event) => {

                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                                setSelectedPolicyType(event.itemData.department);
                                             }
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div> */}
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">SI Currency</label>
                                 <input
                                    name="si_currency"
                                    id="si_currency"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter SI Currency"
                                    {...register('si_currency')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Intimation Date</label>
                                 <Controller
                                    name="insurer_intimation_date"
                                    className="form-control"
                                    control={control}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          max={maxDateValue}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             var objLossDate = new Date(lossDate);
                                             var objInsurerDate = new Date(value);

                                             if (objInsurerDate.getTime() < objLossDate.getTime()) {
                                                Swal.fire({
                                                   title: 'Error',
                                                   text: "Insurer Intimation Date must be greater than Loss Date",
                                                   icon: 'error'
                                                }).then(success => {

                                                })
                                             } else {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setInsurerDate(dateFormat(value, 'yyyy-mm-dd'));
                                             }
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Intimation Mode</label>
                                 <input
                                    name="insurer_intimation_mode"
                                    id="insurer_intimation_mode"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Insurer Intimation Mode"
                                    {...register('insurer_intimation_mode')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Claim no.</label>
                                 <input
                                    name="insurer_claim_no"
                                    id="insurer_claim_no"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Insurer Claim no."
                                    {...register('insurer_claim_no')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Contact Name&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insurer_contact_name"
                                    id="insurer_contact_name"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Insurer Contact Name"
                                    {...register('insurer_contact_name', { required: 'Insurer Contact Name is required' })}
                                 />
                                 {errors.insurer_contact_name && <ErrorLabel message={errors.insurer_contact_name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Contact Number&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insurer_contact"
                                    id="insurer_contact"
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Enter Insurer Contact Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                       }
                                    }}
                                    {...register('insurer_contact', {
                                       required: 'Insurer Contact Number is required',
                                       pattern: {
                                          value: /^[6789]\d{9}$/,
                                          message: 'Please enter 10 digit valid Mobile Number'
                                       }
                                    })}
                                 />
                                 {errors.insurer_contact && <ErrorLabel message={errors.insurer_contact?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Contact Email</label>
                                 <input
                                    name="insurer_contact_email"
                                    id="insurer_contact_email"
                                    className="form-control"
                                    placeholder="Enter Insurer Contact Email"
                                    {...register('insurer_contact_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Second Contact Name</label>
                                 <input
                                    name="insurer_second_contact_name"
                                    id="insurer_second_contact_name"
                                    className="form-control"
                                    placeholder="Enter Insurer Second Contact Name"
                                    type="text"
                                    {...register('insurer_second_contact_name')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Second Contact Number</label>
                                 <input
                                    name="insurer_second_contact"
                                    id="insurer_second_contact"
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Enter Insurer Second Contact Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                       }
                                    }}
                                    {...register('insurer_second_contact', {
                                       pattern: {
                                          value: /^[6789]\d{9}$/,
                                          message: 'Please enter 10 digit valid Mobile Number'
                                       },
                                    }
                                    )}
                                 />
                                 {errors.insurer_second_contact && <ErrorLabel message={errors.insurer_second_contact?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Second Contact Email</label>
                                 <input
                                    name="insurer_second_contact_email"
                                    id="insurer_second_contact_email"
                                    className="form-control"
                                    placeholder="Enter Insurer Second Contact Email"
                                    {...register('insurer_second_contact_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })}
                                 />
                              </div>
                           </div>
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Site Contact Person Name&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_site_contact_person_name"
                                    id="loss_site_contact_person_name"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Loss Site Contact Person Name"
                                    {...register('loss_site_contact_person_name', { required: 'Loss Site Contact Person Name is required' })}
                                 />
                                 {errors.loss_site_contact_person_name && <ErrorLabel message={errors.loss_site_contact_person_name?.message} />}
                              </div>
                           </div> */}
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Site Contact Person Mobile Number&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_site_contact_person_mobile"
                                    id="loss_site_contact_person_mobile"
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Enter Loss Site Contact Person Mobile Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();

                                       }
                                    }}
                                    {...register('loss_site_contact_person_mobile',
                                       {
                                          required: 'Loss Site Contact Person Mobile Number is required',
                                          pattern: {
                                             value: /^[6789]\d{9}$/,
                                             message: 'Please enter 10 digit valid Mobile Number'
                                          }
                                       })}
                                 />
                                 {errors.loss_site_contact_person_mobile && <ErrorLabel message={errors.loss_site_contact_person_mobile?.message} />}
                              </div>
                           </div> */}
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Site Contact Person Email ID&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_site_contact_person_email"
                                    id="loss_site_contact_person_email"
                                    className="form-control"
                                    placeholder="Enter Loss Site Contact Person Email ID"
                                    {...register('loss_site_contact_person_email', {
                                       required: 'Loss Site Contact Person Email ID is required',
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })}
                                 />
                                 {errors.loss_site_contact_person_email && <ErrorLabel message={errors.loss_site_contact_person_email?.message} />}
                              </div>
                           </div> */}
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Alternate Contact Person Name</label>
                                 <input
                                    name="alternate_contact_person_name"
                                    id="alternate_contact_person_name"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Alternate Contact Person Name"
                                    {...register('alternate_contact_person_name')}
                                 />
                              </div>
                           </div> */}
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Alternate Contact Person Mobile Number</label>
                                 <input
                                    name="alternate_contact_person_mobile"
                                    id="alternate_contact_person_mobile"
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Enter Alternate Contact Person Mobile Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                       }
                                    }}
                                    {...register('alternate_contact_person_mobile', {
                                       pattern: {
                                          value: /^[6789]\d{9}$/,
                                          message: 'Please enter 10 digit valid Mobile Number'
                                       }
                                    }
                                    )}
                                 />
                              </div>
                           </div> */}
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Alternate Contact Person Email ID</label>
                                 <input
                                    name="alternate_contact_person_email"
                                    id="alternate_contact_person_email"
                                    className="form-control"
                                    placeholder="Enter Alternate Contact Person Email ID"
                                    {...register('alternate_contact_person_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })
                                    }
                                 />
                              </div>
                           </div> */}
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Status&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="claim_status"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimStatus}
                                          fields={{ text: 'name', value: 'name' }}
                                          allowFiltering={false}
                                          placeholder="Select Claim Status"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Claim Status is required'
                                    }}
                                 />
                                 {errors.claim_status && <ErrorLabel message={errors.claim_status?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Follow up Date</label>
                                 <Controller
                                    name="follow_up_date"
                                    className="form-control"
                                    control={control}
                                    defaultValue={fromDate}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          min={minFollowUpdate}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             field.onChange(value);

                                             var strFromDate = dateFormat(value, "yyyy-mm-dd");
                                             setFromDate(strFromDate);
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Handler Name</label>
                                 <input
                                    name="claim_handler_name"
                                    id="claim_handler_name"
                                    className="form-control pl-1"
                                    type="text"
                                    value={userProfile != null ? userProfile.full_name : ''}
                                    readonly="readonly"
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Handler Email</label>
                                 <input
                                    name="claim_handler_email"
                                    id="claim_handler_email"
                                    className="form-control pl-1"
                                    value={userProfile != null ? userProfile.email : ''}
                                    readonly="readonly"
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                 <label class="label-control">Claim Handler Branch</label>
                                 <textarea
                                    name="branch"
                                    id="branch"
                                    className="form-control no-scroll pb-2 pl-1"
                                    value={userProfile != null ? userProfile.branch : ''}
                                    readonly="readonly"
                                 />
                              </div>
                           </div>

                           <div class="col-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                 <label class="label-control">Claim Remarks</label>
                                 <textarea
                                    name="claim_remark"
                                    id="claim_remark"
                                    className="form-control no-scroll pb-2"
                                    type="text"
                                    placeholder="Enter Claim Remarks"
                                    {...register('claim_remark', { maxLength: 500 })}
                                 />
                              </div>
                           </div>
                           {
                              selectedPolicyType === 'MOTOR' ?
                                 <>
                                    <div class="col-12 col-md-12 col-lg-12">
                                       <h4 class="d-flex align-items-center form-section mb-2 black">
                                          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Product Details
                                       </h4>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Vehicle No./TC no.&nbsp;<span className="danger">*</span></label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Vehicle No./TC no."
                                             {...register('motor.vehicle_no', { required: 'Vehicle No./TC No. is required' })}
                                          />
                                          {errors.motor?.vehicle_no && <ErrorLabel message={errors.motor.vehicle_no?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Engine Number</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Engine Number"
                                             {...register('motor.engine_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Chassis Number</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Chassis Number"
                                             {...register('motor.chassis_number')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transporter Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transporter Name"
                                             {...register('motor.transporter_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Vehicle Make&nbsp;<span className="danger">*</span></label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Vehicle Make"
                                             {...register('motor.vehicle_make', { required: 'Vehicle Make is required' })}
                                          />
                                          {errors.motor?.vehicle_make && <ErrorLabel message={errors.motor.vehicle_make?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Vehicle Model&nbsp;<span className="danger">*</span></label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Vehicle Model"
                                             {...register('motor.vehicle_model', { required: 'Vehicle Model is required' })}
                                          />
                                          {errors.motor?.vehicle_model && <ErrorLabel message={errors.motor.vehicle_model?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Add on</label>
                                          <Controller
                                             name="motor.add_on"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'With Zero Dep Cover',
                                                         'value': 'With Zero Dep Cover'
                                                      },
                                                      {
                                                         'title': 'Without Zero Dep Cover',
                                                         'value': 'Without Zero Dep Cover'
                                                      }
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Add on"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              selectedPolicyType === 'MARINE' ?
                                 <>
                                    <div class="col-12 col-md-12 col-lg-12">
                                       <h4 class="d-flex align-items-center form-section mb-2 black">
                                          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Product Details
                                       </h4>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">MType</label>
                                          <Controller
                                             name="marine.mtype"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'FGP',
                                                         'value': 'fgp'
                                                      },
                                                      {
                                                         'title': 'RM',
                                                         'value': 'rm'
                                                      },
                                                      {
                                                         'title': 'CAPITAL GOODS',
                                                         'value': 'capital_goods'
                                                      },
                                                      {
                                                         'title': 'CONTAINERS',
                                                         'value': 'containers'
                                                      },
                                                      {
                                                         'title': 'CONSUMABLE',
                                                         'value': 'consumable'
                                                      },
                                                      {
                                                         'title': 'PACKING MATERIALS',
                                                         'value': 'packing_materials'
                                                      }
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select MType"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Invoice Number</label>
                                          <input
                                             className="form-control"
                                             type="number"
                                             placeholder="Enter Invoice Number"
                                             {...register('marine.invoice_number')}
                                          />
                                          {errors.invoice_number && <ErrorLabel message={errors.invoice_number?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Invoice Date</label>
                                          <Controller
                                             name="marine.invoice_date"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DateBox
                                                   defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Type</label>
                                          <Controller
                                             name="marine.transit_type"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'LR',
                                                         'value': 'lr'
                                                      },
                                                      {
                                                         'title': 'GR',
                                                         'value': 'gr'
                                                      },
                                                      {
                                                         'title': 'AWB',
                                                         'value': 'awb'
                                                      },
                                                      {
                                                         'title': 'BL',
                                                         'value': 'bl'
                                                      },
                                                      {
                                                         'title': 'RR',
                                                         'value': 'rr'
                                                      },
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Transit Type"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Bill No</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transit Bill No"
                                             {...register('marine.transit_bill_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Date</label>
                                          <Controller
                                             name="marine.transit_date"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DateBox
                                                   defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit From</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transit From"
                                             {...register('marine.transit_from')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit To</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transit To"
                                             {...register('marine.transit_to')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignee Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignee Name"
                                             {...register('marine.consignee_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignee Address</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignee Address"
                                             {...register('marine.consignee_address')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transporter</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transporter"
                                             {...register('marine.transporter')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignor Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignor Name"
                                             {...register('marine.consignor_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignor Unit</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignor Unit"
                                             {...register('marine.consignor_unit')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Qty Loss</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Qty Loss"
                                             {...register('marine.qty_loss')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Survery Required</label>
                                          <Controller
                                             name="marine.survery_required"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'No',
                                                         'value': 'No'
                                                      },
                                                      {
                                                         'title': 'Yes',
                                                         'value': 'yes'
                                                      }
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Survery Required"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Dealer Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Dealer Name"
                                             {...register('marine.dealer_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Dealer Email</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Dealer Email"
                                             {...register('marine.dealer_email')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Bank Details Of Dealer's</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Bank Details Of Dealer's"
                                             {...register('marine.bank_details_of_dealer')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                        </div>
                     </div>
                     <div className="row">
                        <div class="col-12 col-md-4 col-lg-4">
                           <div class="form-group">
                              <button
                                 type="button"
                                 class="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                 onClick={() => { props.history.goBack() }}> BACK
                              </button>
                              <button id="add_detail" class="btn btn-primary mr-1 shadow round text-uppercase" type="submit">
                                 <div class="d-flex align-items-center">SUBMIT<span id="add_detail_loader" class="spinner-border spinner-border-sm ml-half hidden"></span>
                                 </div>
                              </button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>
   )
}


function mapStateToProps(state) {

   var ClaimPolicyDetails = null;
   var ClaimPolicyType = null;
   var ClaimStatus = null;
   var ClaimType = null;
   var NatureOfLoss = null;
   var PincAdd = null;
   var ClaimRegisterPincAddProfile = null;
   if (
      state.PincAddReducer != null &&
      Object.keys(state.PincAddReducer).length > 0 &&
      state.PincAddReducer.constructor === Object
   ) {
      switch (state.PincAddReducer.tag) {
         case ACTION_POLICY_DETAILS_SUCCESS:
            ClaimPolicyDetails = Object.assign({}, state.PincAddReducer.data);
            delete state.PincAddReducer;
            return { ClaimPolicyDetails };
         case ACTION_CLAIMS_POLICY_TYPE_SUCCESS:
            ClaimPolicyType = Object.assign({}, state.PincAddReducer.data);
            delete state.PincAddReducer;
            return { ClaimPolicyType };
         case ACTION_NATURE_OF_LOSS_SUCCESS:
            NatureOfLoss = Object.assign({}, state.PincAddReducer.data);
            delete state.PincAddReducer;
            return { NatureOfLoss };
         case ACTION_CLAIM_TYPE_SUCCESS:
            ClaimType = Object.assign({}, state.PincAddReducer.data);
            delete state.PincAddReducer;
            return { ClaimType };
         case ACTION_CLAIM_STATUS_SUCCESS:
            ClaimStatus = Object.assign({}, state.PincAddReducer.data);
            delete state.PincAddReducer;
            return { ClaimStatus };
         case ACTION_PINC_ADD_SUCCESS:
            PincAdd = Object.assign({}, state.PincAddReducer.data);
            delete state.PincAddReducer;
            return { PincAdd };
         case ACTION_CLAIM_REGISTER_PINC_ADD_PROFILE_SUCCESS:
            ClaimRegisterPincAddProfile = Object.assign({}, state.PincAddReducer.data);
            delete state.PincAddReducer;
            return { ClaimRegisterPincAddProfile };

         default:
      }
   }
   return {
      ClaimPolicyDetails,
      ClaimPolicyType,
      NatureOfLoss,
      ClaimType,
      ClaimStatus,
      PincAdd,
      ClaimRegisterPincAddProfile,
   }
}

function mapDispatchToProps(dispatch) {
   return bindActionCreators({
      actionClaimPolicyDetails,
      actionClaimPolicyType,
      actionNatureOfLoss,
      actionClaimType,
      actionClaimStatus,
      actionPincAdd,
      actionClaimHandler,
   }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Pinc));