import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REIMBURSEMENT_VIEW } from '../../../../../../api/constants';
import { API_REIMBURSEMENT_CHANGE_STATUS, API_NATURE_EXPENCES_LIST } from '../../../../../../api/constants';
import { API_REIMBURSEMENT_REQUEST_DELETE } from '../../../../../../api/constants';
import { API_PRINT_REIMBURSEMENT_REQUEST } from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';

export const ACTION_REIMBURSEMENT_VIEW_LOADING = 'ACTION_REIMBURSEMENT_VIEW_LOADING';
export const ACTION_REIMBURSEMENT_VIEW_SUCCESS = 'ACTION_REIMBURSEMENT_VIEW_SUCCESS';

export const ACTION_REIMBURSEMENT_CHANGE_STATUS_LOADING = 'ACTION_REIMBURSEMENT_CHANGE_STATUS_LOADING';
export const ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS = 'ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS';

export const ACTION_REIMBURSEMENT_DELETE_LOADING = 'ACTION_REIMBURSEMENT_DELETE_LOADING';
export const ACTION_REIMBURSEMENT_DELETE_SUCCESS = 'ACTION_REIMBURSEMENT_DELETE_SUCCESS';

export const ACTION_GET_REIMBURSEMENT_TYPE_LOADING = 'ACTION_GET_REIMBURSEMENT_TYPE_LOADING';
export const ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS = 'ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS';

export const ACTION_PRINT_REIMBURSEMENT_REQUEST_LOADING = 'ACTION_PRINT_REIMBURSEMENT_REQUEST_LOADING';
export const ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS = 'ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS';

export function actionReimbursementView(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE ADD DATA : ', data);
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionReimbursementChangeStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_CHANGE_STATUS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE ADD DATA : ', data);
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_CHANGE_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_CHANGE_STATUS_LOADING, { loading: false, data }));
        });
}

export function actionReimbursementDelete(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_REQUEST_DELETE, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE ADD DATA : ', data);
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_DELETE_LOADING, { loading: false, data }));
        });
}

export function actionGetReimbursementType() {
    return (dispatch, getState) =>
        Api.get(API_NATURE_EXPENCES_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE ADD DATA : ', data);
            dispatch(fetchSuccess(ACTION_GET_REIMBURSEMENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_REIMBURSEMENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionPrintReimbursementRequest(params) {
    return (dispatch, getState) =>
        Api.post(API_PRINT_REIMBURSEMENT_REQUEST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE ADD DATA : ', data);
            dispatch(fetchSuccess(ACTION_PRINT_REIMBURSEMENT_REQUEST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PRINT_REIMBURSEMENT_REQUEST_LOADING, { loading: false, data }));
        });
}