import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { FiUser } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from '../../../../../../../component/form/error_label';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { actionCustomerCategory, ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { actionLeadSource, ACTION_MASTER_LEAD_SOURCE_SUCCESS } from "./action";
import { actionStates, ACTION_MASTER_STATES_SUCCESS } from "./action";
import { actionCities, ACTION_MASTER_CITIES_SUCCESS } from "./action";

const RetailForm = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
        }
    });
    const [clientRequiredFields, setClientRequiredFields] = useState(false);
    const [customerCategory, setCustomerCategory] = useState([])
    const [leadSource, setLeadSource] = useState({})
    const [masterStates, setMasterStates] = useState([])
    const [masterCities, setMasterCities] = useState([])

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
        props.actionCustomerCategory();
        props.actionLeadSource();
        props.actionStates();
    }
    useEffect(initialRender, [props.show]);

    const onMasterCustomerCategoryUpdate = () => {
        const { CustomerCategory } = props;
        var CustomerCategoryResponse = Object.assign({}, CustomerCategory);
        if (CustomerCategoryResponse.result === true) {
            setCustomerCategory(CustomerCategoryResponse.response);
        } else {
            switch (CustomerCategoryResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCustomerCategoryUpdate, [props.CustomerCategory]);

    const onMasterStatesUpdate = () => {
        const { States } = props;
        var StatesResponse = Object.assign({}, States);
        if (StatesResponse.result === true) {
            setMasterStates(StatesResponse.response);
        } else {
            switch (StatesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterStatesUpdate, [props.States]);

    const onMasterCitiesUpdate = () => {
        const { Cities } = props;
        var CitiesResponse = Object.assign({}, Cities);
        if (CitiesResponse.result === true) {
            setMasterCities(CitiesResponse.response);
        } else {
            switch (CitiesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.Cities]);

    const onMasterLeadSourceUpdate = () => {
        const { LeadSource } = props;
        var LeadSourceResponse = Object.assign({}, LeadSource);
        if (LeadSourceResponse.result === true) {
            setLeadSource(LeadSourceResponse.response);
        } else {
            switch (LeadSourceResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterLeadSourceUpdate, [props.LeadSource]);

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));

    }
    return (
        <>
            <section>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label className="label-control">Mobile Number&nbsp;<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                id="mobile_number"
                                                name="mobile_number"
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-md btn-block btn-primary mr-1 shadow round text-uppercase font-small-2 px-1">Edit</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Lead Information
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <fieldset className="form-group">
                                            <label className="label-control">Company Name&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="name"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <input
                                                        {...field}
                                                        className="form-control"
                                                        placeholder="Enter Company Name"
                                                        value={field.value}
                                                        onChange={(event) => field.onChange(event.target.value)}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Please Enter Company Name'
                                                }}
                                            />
                                            {errors.name && <ErrorLabel message={errors.name?.message} />}
                                        </fieldset>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Customer Category&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                            <Controller
                                                name="category"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={customerCategory}
                                                        fields={{ text: 'title', value: 'value' }}
                                                        allowFiltering={true}
                                                        placeholder="Select Customer Category"
                                                        value={field.value}
                                                        change={(event) => field.onChange(event.itemData.value)}
                                                    />
                                                }
                                                rules={{
                                                    required: clientRequiredFields ? 'Please Select Customer Category' : false
                                                }}
                                            />
                                            {errors.category && <ErrorLabel message={errors.category?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label-control">Address</label>
                                            <input
                                                className="form-control"
                                                id="address"
                                                name="address"
                                                placeholder="Enter Address"
                                                {...register('address', { required: 'Address is required' })}
                                            />
                                            {errors.address && <ErrorLabel message={errors.address?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Email ID</label>
                                            <input
                                                className="form-control"
                                                id="emailid"
                                                name="emailid"
                                                placeholder="Enter Email ID"
                                                {...register('emailid', {
                                                    required: 'Email Id is required',
                                                    pattern: {
                                                        value: /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/i,
                                                        message: 'Please enter valid Email Id'
                                                    },
                                                })}
                                            />
                                            {errors.emailid && <ErrorLabel message={errors.emailid?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">State</label>
                                            <Controller
                                                name="state"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={masterStates}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={true}
                                                        placeholder="Select State"
                                                        value={field.value}
                                                        change={(event) => {
                                                            field.onChange(event.itemData.id)
                                                            props.actionCities({ state_ids: [event.itemData.id] })
                                                        }}
                                                    />
                                                }
                                            />
                                            {errors.state && <ErrorLabel message={errors.state?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">City&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                            <Controller
                                                name="city_id"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={masterCities}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={true}
                                                        placeholder="Select City"
                                                        value={field.value}
                                                        change={(event) => field.onChange(event.itemData.id)}
                                                    />
                                                }
                                                rules={{
                                                    required: clientRequiredFields ? 'Please Select City' : false
                                                }}
                                            />
                                            {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Pincode&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                            <input
                                                className="form-control"
                                                id="pincode"
                                                name="pincode"
                                                placeholder="Enter Pincode"
                                                {...register('pincode', {
                                                    pattern: {
                                                        value: /^\d{6}$/,
                                                        message: 'Please enter 6 digit valid PIN Code'
                                                    },
                                                    required: clientRequiredFields ? 'Please Enter Pincode' : false
                                                })}
                                            />
                                            {errors.pincode && <ErrorLabel message={errors.pincode?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Pan No</label>
                                            <input
                                                className="form-control"
                                                id="panno"
                                                name="panno"
                                                placeholder="Enter Pan No"
                                                {...register('panno')}
                                            />
                                            {errors.panno && <ErrorLabel message={errors.panno?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Landline</label>
                                            <input
                                                className="form-control"
                                                id="landline"
                                                name="landline"
                                                placeholder="Enter Landline"
                                                {...register('landline')}
                                            />
                                            {errors.landline && <ErrorLabel message={errors.landline?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Website</label>
                                            <input
                                                className="form-control"
                                                id="website"
                                                name="website"
                                                placeholder="Enter Website"
                                                {...register('website', {
                                                    pattern: {
                                                        value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                                                        message: 'Please Enter valid Website'
                                                    },
                                                })}
                                            />
                                            {errors.website && <ErrorLabel message={errors.website?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Lead Source&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                            <Controller
                                                name="lead_source_id"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={leadSource}
                                                        fields={{ text: 'name', value: 'id' }}
                                                        allowFiltering={true}
                                                        placeholder="Select Lead Source"
                                                        value={field.value}
                                                        change={(event) => field.onChange(event.itemData.id)}
                                                    />
                                                }
                                            />
                                            {errors.lead_source_id && <ErrorLabel message={errors.lead_source_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Primary Contact
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Contact Name&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                            <input
                                                className="form-control"
                                                id="contact_name"
                                                name="contact_name"
                                                placeholder="Enter Contact Name"
                                                {...register(
                                                    'contact_name',
                                                    {
                                                        required: clientRequiredFields ? 'Please Enter Contact Name' : false
                                                    }
                                                )}
                                            />
                                            {errors.contact_name && <ErrorLabel message={errors.contact_name?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Contact Designation&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                            <input
                                                className="form-control"
                                                id="contact_designation"
                                                name="contact_designation"
                                                placeholder="Enter Contact Designation"
                                                {...register(
                                                    'contact_designation',
                                                    {
                                                        required: clientRequiredFields ? 'Please Enter Contact Designation' : false
                                                    }
                                                )}
                                            />
                                            {errors.contact_designation && <ErrorLabel message={errors.contact_designation?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Contact Phone</label>
                                            <input
                                                className="form-control"
                                                id="contact_phone"
                                                name="contact_phone"
                                                placeholder="Enter Contact Phone"
                                                {...register('contact_phone')}
                                            />
                                            {errors.contact_phone && <ErrorLabel message={errors.contact_phone?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Contact Email</label>
                                            <input
                                                className="form-control"
                                                id="contact_email"
                                                name="contact_email"
                                                placeholder="Enter Contact Email"
                                                {...register('contact_email', {
                                                    pattern: {
                                                        value: /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/i,
                                                        message: 'Please enter valid Email Id'
                                                    },
                                                }
                                                )}
                                            />
                                            {errors.contact_email && <ErrorLabel message={errors.contact_email?.message} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none">
                                <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={() => {
                                        props.history.push('/leads');
                                    }}
                                > Cancel</button>

                                <button className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setClientRequiredFields(true);
                                    }}
                                >
                                    &nbsp;Convert to Retail Customer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var CustomerCategory = null;
    var LeadSource = null;
    var States = null;
    var Cities = null;

    if (
        state.LeadCreateRetailReducer != null &&
        Object.keys(state.LeadCreateRetailReducer).length > 0 &&
        state.LeadCreateRetailReducer.constructor === Object
    ) {
        switch (state.LeadCreateRetailReducer.tag) {
            case ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS:
                CustomerCategory = Object.assign({}, state.LeadCreateRetailReducer.data);
                delete state.LeadCreateRetailReducer;
                return { CustomerCategory };
            case ACTION_MASTER_LEAD_SOURCE_SUCCESS:
                LeadSource = Object.assign({}, state.LeadCreateRetailReducer.data);
                delete state.LeadCreateRetailReducer;
                return { LeadSource };
            case ACTION_MASTER_STATES_SUCCESS:
                States = Object.assign({}, state.LeadCreateRetailReducer.data);
                delete state.LeadCreateRetailReducer;
                return { States };
            case ACTION_MASTER_CITIES_SUCCESS:
                Cities = Object.assign({}, state.LeadCreateRetailReducer.data);
                delete state.LeadCreateRetailReducer;
                return { Cities };
            default:
        }
    }
    return {
        CustomerCategory,
        LeadSource,
        States,
        Cities,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionCustomerCategory,
            actionLeadSource,
            actionStates,
            actionCities,
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RetailForm))