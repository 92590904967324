import { GROUP_ALL_CLAIMS_SUCCESS } from "./action";
import createReducer from '../../../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const TabGroupClaimsReducer = createReducer(
    INITIAL_STATE,
    {
        [GROUP_ALL_CLAIMS_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: GROUP_ALL_CLAIMS_SUCCESS,
                loading: false
            });
        }
    }
);
export default TabGroupClaimsReducer;