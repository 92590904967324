import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_ACCOMPAINED_BY_SUCCESS } from "./action";
import { ACTION_ADD_GET_SERVEYOR_NAME_SUCCESS } from "./action";
import { ACTION_SURVEYOR_MEETING_SAVE_SUCCESS } from "./action";
import {ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS} from './action';
import {ACTION_SERVEYOR_GET_CONTACTS_SUCCESS} from './action';

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddServeyorMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },

    [ACTION_ADD_GET_SERVEYOR_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_GET_SERVEYOR_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SURVEYOR_MEETING_SAVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SURVEYOR_MEETING_SAVE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS,
            loading: false
        });
    },
    [ACTION_SERVEYOR_GET_CONTACTS_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_SERVEYOR_GET_CONTACTS_SUCCESS,
            loading: false
        });
    }
});
export default AddServeyorMeetingReducer;