import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_TEAM_MASTER_EDIT } from '../../../../../../api/constants';
import { API_TEAM_HEAD_EDIT } from '../../../../../../api/constants';
import { API_BRANCH_EDIT } from '../../../../../../api/constants';
import { API_GET_TEAM_MASTER } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';
export const ACTION_TEAM_MASTER_EDIT_LOADING = 'ACTION_TEAM_MASTER_EDIT_LOADING';
export const ACTION_TEAM_MASTER_EDIT_SUCCESS = 'ACTION_TEAM_MASTER_EDIT_SUCCESS';

export const ACTION_TEAM_HEAD_EDIT_LOADING = 'ACTION_TEAM_HEAD_EDIT_LOADING';
export const ACTION_TEAM_HEAD_EDIT_SUCCESS = 'ACTION_TEAM_HEAD_EDIT_SUCCESS';

export const ACTION_BRANCH_EDIT_LOADING = 'ACTION_BRANCH_EDIT_LOADING';
export const ACTION_BRANCH_EDIT_SUCCESS = 'ACTION_BRANCH_EDIT_SUCCESS';

export const ACTION_GET_TEAM_MASTER_LOADING = 'ACTION_GET_TEAM_MASTER_LOADING';
export const ACTION_GET_TEAM_MASTER_SUCCESS = 'ACTION_GET_TEAM_MASTER_SUCCESS';

export function actionTeamMasterEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_TEAM_MASTER_EDIT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TEAM_MASTER_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TEAM_MASTER_EDIT_LOADING, { loading: false, data }));
        });
}
export function actionTeamHeadEdit() {
    return (dispatch, getState) =>
        Api.get(API_TEAM_HEAD_EDIT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TEAM_HEAD_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TEAM_HEAD_EDIT_LOADING, { loading: false, data }));
        });
}
export function actionBranchEdit() {
    return (dispatch, getState) =>
        Api.get(API_BRANCH_EDIT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_BRANCH_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BRANCH_EDIT_LOADING, { loading: false, data }));
        });
}
export function actionGetTeamMaster(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_TEAM_MASTER,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_TEAM_MASTER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_TEAM_MASTER_LOADING, { loading: false, data }));
        });
}