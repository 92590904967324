import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import Swal from 'sweetalert2';
import $ from 'jquery'
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionProbabilityList, ACTION_PROBABILITY_SUCCESS } from './action';
import { actionProbabilityAdd, ACTION_PROBABILITY_ADD_SUCCESS } from './action';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { FiEdit } from 'react-icons/fi';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';

const ProbabilityList = (props) => {
    const { match } = props;

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionProbabilityList();
    }
    useEffect(initialRender, []);

    const [probabilityList, setProbabilityList] = useState([]);

    const [columns, setColumns] = useState([
        { dataField: "title", caption: "Title" },
        { dataField: "value", caption: "Value" }
    ]);

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/edit`, state: { roles: params.data.id } })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        alignment='left'
                    ></Column>

                )
            }
        });

        return arrColumns;
    };

    const onProbabilityListUpdate = () => {
        const { probability } = props
        var probabilityResponse = Object.assign({}, probability);
        if (probabilityResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setProbabilityList(probabilityResponse.response);
        } else {
            switch (probabilityResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onProbabilityListUpdate, [props.probability])

    const onProbabilityAddUpdate = () => {
        const { probabilityAdd } = props
        var probabilityAddResponse = Object.assign({}, probabilityAdd);
        if (probabilityAddResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Probability Saved Successfully");
            props.actionProbabilityList();
        } else {
            switch (probabilityAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:

                    break;
                default:
            }
        }
    }
    useEffect(onProbabilityAddUpdate, [props.probabilityAdd])

    return (

        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Probability</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    Swal.fire({
                                        title: 'Enter Probability Value:',
                                        html:
                                            '<label for="swal-input1">Title</label>' +
                                            '<input id="swal-input1" class="swal2-input" placeholder="Enter Title">' +
                                            '<label for="swal-input2">Value</label>' +
                                            '<input id="swal-input2" class="swal2-input" input="number" placeholder="Enter Value">',
                                        showCancelButton: true,
                                        confirmButtonText: 'Submit',
                                        cancelButtonText: 'Cancel'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            const title = document.getElementById('swal-input1').value;
                                            const value = document.getElementById('swal-input2').value;
                                            let objTotalValue = probabilityList.find((objValue) => Number(objValue.value) === Number(value))
                                            if (objTotalValue === undefined && value <= 100) {
                                                props.actionProbabilityAdd({
                                                    title: title,
                                                    value: value
                                                })

                                            } else if (value > 100) {
                                                Swal.fire(`probability value should not be greater than 100`)
                                            }
                                            else {
                                                Swal.fire(`${value} Value is already available`)
                                            }
                                        }
                                    })
                                }}
                            >
                                Add Probability
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={probabilityList}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "Probability",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var probability = null;
    var probabilityAdd = null;

    if (
        state.ProbabilityListReducer != null &&
        Object.keys(state.ProbabilityListReducer).length > 0 &&
        state.ProbabilityListReducer.constructor === Object
    ) {
        switch (state.ProbabilityListReducer.tag) {
            case ACTION_PROBABILITY_SUCCESS:
                probability = Object.assign({}, state.ProbabilityListReducer.data);
                delete state.ProbabilityListReducer;
                return { probability };
            case ACTION_PROBABILITY_ADD_SUCCESS:
                probabilityAdd = Object.assign({}, state.ProbabilityListReducer.data);
                delete state.ProbabilityListReducer;
                return { probabilityAdd };
            default:

        }
    }
    return {
        probability,
        probabilityAdd
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionProbabilityList, actionProbabilityAdd }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProbabilityList))