import { ACTION_GET_POST_SUCCESS, ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS, ACTION_GET_TICKER_SUCCESS } from "./action";
import { ACTION_MARK_IN_SUCCESS } from './action'
import { ACTION_MARK_OUT_SUCCESS } from './action'
import { ACTION_MARK_STATUS_SUCCESS } from './action'
import { ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS } from './action'
import { ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS } from './action';

import createReducer from "../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const DashboardSummaryReducer = createReducer(INITIAL_STATE, {
    [ACTION_GET_POST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_POST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_TICKER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_TICKER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MARK_IN_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MARK_IN_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MARK_OUT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MARK_OUT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MARK_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MARK_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS,
                loading: false,
            }
        );
    }
});
export default DashboardSummaryReducer;
