import React, { useEffect } from "react";
import $ from "jquery";

const LikeDetails = (props) => {

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const renderUI = () => {
        try {
            return (
                <div
                    className="modal fade show"
                    id="default"
                    role="dialog"
                    aria-labelledby="myModalLabel1"
                    aria-hidden="true"
                    style={{ display: `${props.show === true ? "block" : "none"}` }}
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="media-middle mt-1">
                                    <h4 className="modal-title" id="myModalLabel1">
                                        Likes
                                    </h4>
                                </div>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        props.handleClose();
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                        {
                                            props.data != null && props.data.length > 0 ?
                                                props.data.map((like, indexLike) => (
                                                    <div className="d-flex flex-row" key={indexLike}>
                                                        <div className="flex-column">
                                                            {
                                                                (like.profile_pic != null && like.profile_pic.length > 0) ?
                                                                    <img
                                                                        src={like.profile_pic}
                                                                        className="rounded-circle img-border"
                                                                        alt="Card"
                                                                        width={50}
                                                                        height={50}
                                                                        style={{ border: '5px solid #FFF' }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        <div
                                                                            className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3"
                                                                            style={{ border: '5px solid #FFF', height: 50, width: 50 }}
                                                                        >
                                                                            {like.initial_name}
                                                                        </div>

                                                                    </>
                                                            }
                                                        </div>
                                                        <div className="align-items-center ml-1 pt-half">
                                                            <h6 className="font-medium-1 py-0 text-bold-600 mt-half mb-0">{like.full_name != null ? like.full_name : '-'}</h6>
                                                            <h6 className="font-small-3 py-0 my-0 text-bold-500">{like.designation_name != null ? like.designation_name : '-'}</h6>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } catch (e) {
            return (
                <>
                    <h1>Error Activity Like</h1>
                </>
            )
        }
    }
    return (
        renderUI()
    );
};

export default LikeDetails;