import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_STATES, API_MASTER_CITIES, API_MASTER_ADD_INSURER } from '../../../../../../api/constants';

export const ACTION_MASTER_STATES_LOADING = 'ACTION_MASTER_STATES_LOADING';
export const ACTION_MASTER_STATES_SUCCESS = 'ACTION_MASTER_STATES_SUCCESS';

export const ACTION_MASTER_CITIES_LOADING = 'ACTION_MASTER_CITIES_LOADING';
export const ACTION_MASTER_CITIES_SUCCESS = 'ACTION_MASTER_CITIES_SUCCESS';

export const ACTION_MASTER_ADD_INSURER_LOADING = 'ACTION_MASTER_ADD_INSURER_LOADING';
export const ACTION_MASTER_ADD_INSURER_SUCCESS = 'ACTION_MASTER_ADD_INSURER_SUCCESS';

export function actionMasterStates() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_STATES).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_STATES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_STATES_LOADING, { loading: false, data }));
        });
}

export function actionMasterCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CITIES_LOADING, { loading: false, data }));
        });
}

export function actionMasterAddInsurer(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ADD_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ADD_INSURER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ADD_INSURER_LOADING, { loading: false, data }));
        });
}