import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_PROBABILITY_SUCCESS, ACTION_PROBABILITY_ADD_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ProbabilityListReducer = createReducer(INITIAL_STATE, {
    [ACTION_PROBABILITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROBABILITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PROBABILITY_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROBABILITY_ADD_SUCCESS,
                loading: false,
            }
        );
    }
});
export default ProbabilityListReducer;    