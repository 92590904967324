
import createReducer from "../../../reducers/createReducer";
import { ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS, ACTION_MASTER_POLICY_TYPE_SUCCESS, ACTION_PREMIUM_SUCCESS, ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW } from "./action";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const PolicyFilterReducer = createReducer(INITIAL_STATE, {
    [ACTION_PREMIUM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PREMIUM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW,
                loading: false,
            }
        );
    },
}
)
export default PolicyFilterReducer