import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_PROPOSAL_NEW_POOL_LIST } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const TABLEADPROPOSALSVIEW_LOADING = 'TABLEADPROPOSALSVIEW_LOADING';
export const ACTION_TABLEADPROPOSALSVIEW_SUCCESS = 'ACTION_TABLEADPROPOSALSVIEW_SUCCESS';

export function actionTabLeadProposalsView(params) {
    return (dispatch, getState) => Api.post(API_PROPOSAL_NEW_POOL_LIST, params).then((res) => {
        const data = res;
        PRINT('TABLEADPROPOSALSVIEW ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_TABLEADPROPOSALSVIEW_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(TABLEADPROPOSALSVIEW_LOADING, { loading: false, data }));
    });
}
