import React, { useEffect, useState } from "react";
import { UNAUTHORIZED } from "../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { actionGetManagerApproval, ACTION_GET_MANAGER_APPROVAL_SUCCESS } from './action';

const ManagerApprovals = (props) => {

    const [getManagerApproval, setGetManagerApproval] = useState([]);

    const initialRender = () => {
        props.actionGetManagerApproval({});
    }
    useEffect(initialRender, []);

    const onGetManagerApprovalUpdate = () => {
        const { GetManagerApproval } = props;
        const GetManagerApprovalResponse = Object.assign({}, GetManagerApproval)
        if (GetManagerApprovalResponse.result === true) {
            setGetManagerApproval(GetManagerApprovalResponse.response)
        } else {
            switch (GetManagerApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onGetManagerApprovalUpdate, [props.GetManagerApproval]);

    const renderUI = () => {
        try {
            return (
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="card dashboard-card-round dashboard-other-summary shadow">
                        <div className="card-header border-bottom-primary px-1 py-half">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-column">
                                    <h5 className="font-small-4 text-black text-bold-500 mb-0">
                                        Pending Approvals
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <button type="button" className="btn font-small-3">&nbsp;</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-1 py-1">
                            <p className="font-small-2">
                                Request pending action at your end.
                            </p>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/approvals/leave-approvals' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Leave
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getManagerApproval != null ?
                                                getManagerApproval.leaves : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/approvals/regularize-approvals' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Regularise
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getManagerApproval != null ?
                                                getManagerApproval.regularizes : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/approvals/asset-approvals' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Assets
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getManagerApproval != null ?
                                                getManagerApproval.assets : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/approvals/travel-approvals' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Travel
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getManagerApproval != null ?
                                                getManagerApproval.travel : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                <div className="flex-column cursor-pointer"
                                    onClick={() => {
                                        props.history.push({ pathname: '/approvals/reimbursement_approvals' });
                                    }}
                                >
                                    <h5 className="font-small-3 text-black mb-0">
                                        Reimbursement
                                    </h5>
                                </div>
                                <div className="flex-column">
                                    <div className="badge badge-success round px-1">
                                        {
                                            getManagerApproval != null ?
                                                getManagerApproval.reimbursement : '0'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } catch (e) {
            return (
                <>
                    <h1>Error Pending Requests</h1>
                </>
            )
        }
    }

    return (
        renderUI()
    );
}
const mapStateToProps = (state) => {

    var GetManagerApproval = null;
    if (
        state.DashboardManagerApprovalReducer != null &&
        Object.keys(state.DashboardManagerApprovalReducer).length > 0 &&
        state.DashboardManagerApprovalReducer.constructor === Object
    ) {
        switch (state.DashboardManagerApprovalReducer.tag) {
            case ACTION_GET_MANAGER_APPROVAL_SUCCESS:
                GetManagerApproval = Object.assign({}, state.DashboardManagerApprovalReducer.data);
                delete state.DashboardManagerApprovalReducer;
                return { GetManagerApproval };
            default:
        }
    }
    return {
        GetManagerApproval,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionGetManagerApproval,
        },
        dispatch
    );
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ManagerApprovals))






