import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_CLIENT_CONTACTBY_INSURERID } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_CLIENT_CONTACTBY_INSURERID_LOADING = 'ACTION_CLIENT_CONTACTBY_INSURERID_LOADING';
export const ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS = 'ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS';

export function actionClientContactByInsurerId(params) {
    return (dispatch, getState) => 
    Api.post(API_CLIENT_CONTACTBY_INSURERID, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_CLIENT_CONTACTBY_INSURERID_LOADING, { loading: false, data }));
    });
}
