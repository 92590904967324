import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useForm, Controller } from "react-hook-form";
import { FiUser } from "react-icons/fi";
import ErrorLabel from "../error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { UNAUTHORIZED } from '../../../constants';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionStates, ACTION_TPA_MASTER_STATES_SUCCESS } from "./action";
import { actionCities, ACTION_TPA_MASTER_CITIES_SUCCESS } from "./action";
import { actionCreateNewTpa, ACTION_CREATE_NEW_TPA_SUCCESS } from "./action";

const NewTpaAdd = (props) => {
    const { register, handleSubmit, control, formState: { errors }, getValues } = useForm({
        defaultValues: {
            name: "",
            address: "",
            pincode: "",
            email: "",
            mobile: "",
            landline: "",
            contact_name: "",
            contact_designation: "",
            contact_phone: "",
            contact_email: "",
        }
    });
    const [masterStates, setMasterStates] = useState([])
    const [masterCities, setMasterCities] = useState([])

    const initialRender = () => {
        if (props.show === true) {
            props.actionStates();
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            setMasterStates([]);
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onMasterStatesUpdate = () => {
        const { States } = props;
        var StatesResponse = Object.assign({}, States);
        if (StatesResponse.result === true) {
            setMasterStates(StatesResponse.response);
        } else {
            switch (StatesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMasterStatesUpdate, [props.States]);

    const onMasterCitiesUpdate = () => {
        const { Cities } = props;
        var CitiesResponse = Object.assign({}, Cities);
        if (CitiesResponse.result === true) {
            setMasterCities(CitiesResponse.response);
        } else {
            switch (CitiesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.Cities]);

    const onCreateNewTpaUpdate = () => {
        const { CreateNewTpa } = props;
        var CreateNewTpaResponse = Object.assign({}, CreateNewTpa);
        $("#save-new-tpa").prop("disabled", false);
        if (CreateNewTpaResponse.result === true) {
            $("#new-tpa-form-loader").addClass("hidden");
            props.handleClose(CreateNewTpaResponse.response);
        } else {
            $("#new-tpa-form-loader").addClass("hidden");
            switch (CreateNewTpaResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCreateNewTpaUpdate, [props.CreateNewTpa]);

    const onSubmit = (data) => {
        data.client_of = "tpa";
        data.created_by = props.auth.getUser().user_id;

        data.primary_contact = {
            contact_name: getValues('contact_name'),
            contact_designation: getValues('contact_designation'),
            contact_phone: getValues('contact_phone'),
            contact_email: getValues('contact_email'),
        };
        $("#new-tpa-form-loader").removeClass("hidden");
        $("#save-new-tpa").prop("disabled", true);
        props.actionCreateNewTpa(data);
        console.log(JSON.stringify(data));
    }
    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="new-serveyor-add"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="new-serveyor-add">
                                    Add New TPA
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control ">Name&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Enter Name "
                                                            {...register('name', { required: 'Name is required' })}
                                                        />
                                                        {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control ">Address&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="address"
                                                            name="address"
                                                            placeholder="Enter Address "
                                                            {...register('address', { required: 'Address is required' })}
                                                        />
                                                        {errors.address && <ErrorLabel message={errors.address?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">State&nbsp;<span className={`danger`}>*</span></label>
                                                        <Controller
                                                            name="state"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={masterStates}
                                                                    fields={{ text: 'name', value: 'id' }}
                                                                    allowFiltering={true}
                                                                    placeholder="Select State"
                                                                    value={field.value}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.id)
                                                                            props.actionCities({ state_ids: [event.itemData.id] })
                                                                        }

                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Please Select State'
                                                            }}
                                                        />
                                                        {errors.state && <ErrorLabel message={errors.state?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">City&nbsp;<span className={`danger`}>*</span></label>
                                                        <Controller
                                                            name="city_id"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={masterCities}
                                                                    fields={{ text: 'name', value: 'id' }}
                                                                    allowFiltering={true}
                                                                    placeholder="Select City"
                                                                    value={field.value}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.id)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Please Select City'
                                                            }}
                                                        />
                                                        {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control ">Pincode&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="pincode"
                                                            name="pincode"
                                                            maxLength={6}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Enter Pincode "
                                                            {...register('pincode', { required: 'Pincode is required' })}
                                                        />
                                                        {errors.pincode && <ErrorLabel message={errors.pincode?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control ">Email&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Enter Email Id "
                                                            {...register('email', {
                                                                required: 'Email is required',
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                    message: 'Please enter valid Email Id'
                                                                }
                                                            })}
                                                        />
                                                        {errors.email && <ErrorLabel message={errors.email?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control ">Mobile Number&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="mobile"
                                                            name="mobile"
                                                            maxLength={10}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Enter Mobile Number "
                                                            {...register('mobile', { required: 'Mobile Number is required' })}
                                                        />
                                                        {errors.mobile && <ErrorLabel message={errors.mobile?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control ">Landline&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="landline"
                                                            name="landline"
                                                            maxLength={13}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Enter Landline Number"
                                                            {...register('landline', { required: 'Landline is required' })}
                                                        />
                                                        {errors.landline && <ErrorLabel message={errors.landline?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <FiUser size={16} />&nbsp;Primary Contact
                                                    </h4>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Contact Name&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="contact_name"
                                                            name="contact_name"
                                                            placeholder="Enter Contact Name"
                                                            {...register('contact_name', { required: 'Contact Name is required' })}
                                                        />
                                                        {errors.contact_name && <ErrorLabel message={errors.contact_name?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Contact Designation&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="contact_designation"
                                                            name="contact_designation"
                                                            placeholder="Enter Contact Designation"
                                                            {...register('contact_designation', { required: 'Contact Designation is required' })}
                                                        />
                                                        {errors.contact_designation && <ErrorLabel message={errors.contact_designation?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Contact Phone&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="contact_phone"
                                                            name="contact_phone"
                                                            maxLength={10}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Enter Contact Phone"
                                                            {...register('contact_phone', { required: 'Contact Phone is required' })}
                                                        />
                                                        {errors.contact_phone && <ErrorLabel message={errors.contact_phone?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Contact Email&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="contact_email"
                                                            name="contact_email"
                                                            placeholder="Enter Contact Email"
                                                            {...register('contact_email', {
                                                                required: 'Contact Email is required',
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                    message: 'Please enter valid Email Id'
                                                                }
                                                            })}
                                                        />
                                                        {errors.contact_email && <ErrorLabel message={errors.contact_email?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 float-none ">
                                            <button
                                                id="save-new-tpa"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    Submit
                                                    <span id="new-tpa-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    props.handleClose();
                                                    event.preventDefault();
                                                }}
                                            > Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapStateToProps = (state) => {
    var States = null;
    var Cities = null;
    var CreateNewTpa = null;

    if (
        state.NewTpaReducer != null &&
        Object.keys(state.NewTpaReducer).length > 0 &&
        state.NewTpaReducer.constructor === Object
    ) {
        switch (state.NewTpaReducer.tag) {
            case ACTION_TPA_MASTER_STATES_SUCCESS:
                States = Object.assign({}, state.NewTpaReducer.data);
                delete state.NewTpaReducer;
                return { States };
            case ACTION_TPA_MASTER_CITIES_SUCCESS:
                Cities = Object.assign({}, state.NewTpaReducer.data);
                delete state.NewTpaReducer;
                return { Cities };
            case ACTION_CREATE_NEW_TPA_SUCCESS:
                CreateNewTpa = Object.assign({}, state.NewTpaReducer.data);
                delete state.NewTpaReducer;
                return { CreateNewTpa };
            default:
        }
    }
    return {
        States,
        Cities,
        CreateNewTpa
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionStates,
            actionCities,
            actionCreateNewTpa
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(NewTpaAdd))