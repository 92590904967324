import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import moment from "moment";
import { FiUser } from "react-icons/fi";
import Swal from "sweetalert2";
import { AMOUNT_REGEX, ERROR, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { CalculateFinancialYear } from "../../../../../../utils";
import { actionItDeclarationDetail, ACTION_EDIT_IT_DECLARATION_DETAIL_SUCCESS } from './action';
import { actionItDeclarationsEdit, ACTION_EDIT_IT_DECLARATION_EDIT_SUCCESS } from './action';

const EditDeclaration = (props) => {
    const { register, handleSubmit, control, formState: { errors }, reset, watch, setValue } = useForm();

    const [selectTaxRegime, setSelectTaxRegime] = useState(null);
    const [selectDeclarationOne, setSelectDeclarationOne] = useState(true);
    const [selectDeclarationTwo, setSelectDeclarationTwo] = useState(true);
    const [selectDeclarationThree, setSelectDeclarationThree] = useState(true);
    const tooltipSection80C = "Maximum eligible limit INR 150,000 (1.5 Lakhs).";
    const tooltipSection80CCD = "Maximum eligible limit INR 50,000 (50K)";
    const tooltipSection80D = "Maximum Eligibility INR 25000 for Family for Emp <60 Years INR 50000 for Emp > 60 Years INR 50000 for Dependent Parent INR 5000 for Preventive Health Check Up (Combine Maximum Limit 75000 / 100000)";
    const tooltipOthers = "INR 200,000 (2 lakhs) maximum eligibility for Interest Paid on Housing Loan & Loss from House Property.";
    const [regime, setRegime] = useState([
        {
            "title": "OLD",
            "value": "OLD"
        },
        {
            "title": "NEW",
            "value": "NEW"
        }
    ]);
    const [pfContribution, setPfContribution] = useState([
        {
            "title": "0",
            "value": "0"
        },
        {
            "title": "1800",
            "value": "1800"
        },
        // {
        //     "title": "6% of Basic",
        //     "value": "6% of Basic"
        // },
        {
            "title": "12% of Basic",
            "value": "12% of Basic"
        }
    ]);
    const [declarationDetails, setDeclarationDetails] = useState(null);
    const watchLifeInsurancePremium = watch('life_insurance_premium_or_pension_schemes')
    const watchPPF = watch('ppf')
    const watchUlipOrElssOrTaxSavingMutualFunds = watch('ulip_or_elss_or_tax_saving_mutual_funds')
    const watchNSC = watch('nsc')
    const watchRepaymentOfHousingLoan = watch('repayment_of_housing_loan')
    const watchtUitionFeesForChildren = watch('tuition_fees_for_children')
    const watchFiveYearFd = watch('five_year_fd')
    const watchOthers = watch('others')

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
        // $("#progress-loader").removeClass("hidden");
        if(props.ItDeclarationId!=null){
            props.actionItDeclarationDetail({ id: props.ItDeclarationId });
        }
    }
    useEffect(initialRender, [props.show]);

    useEffect(() => {
        /**
         * set grand total value as addition of life insurance premium , ppf, Ulip or elss or tax saving mutual funds, nsc, repayment of housing loan, tuition fees for children, five year fd, others
         */
        var GrandTotal = 0;
        if (watchLifeInsurancePremium != null) {
            GrandTotal += Number(watchLifeInsurancePremium)
        }
        if (watchPPF != null) {
            GrandTotal += Number(watchPPF)
        }
        if (watchUlipOrElssOrTaxSavingMutualFunds != null) {
            GrandTotal += Number(watchUlipOrElssOrTaxSavingMutualFunds)
        }
        if (watchNSC != null) {
            GrandTotal += Number(watchNSC)
        }
        if (watchRepaymentOfHousingLoan != null) {
            GrandTotal += Number(watchRepaymentOfHousingLoan)
        }
        if (watchtUitionFeesForChildren != null) {
            GrandTotal += Number(watchtUitionFeesForChildren)
        }
        if (watchFiveYearFd != null) {
            GrandTotal += Number(watchFiveYearFd)
        }
        if (watchOthers != null) {
            GrandTotal += Number(watchOthers)
        }
        setValue('grand_total', GrandTotal.toLocaleString('fullwide', { useGrouping: false }))

    }, [watchLifeInsurancePremium, watchPPF, watchUlipOrElssOrTaxSavingMutualFunds, watchNSC, watchRepaymentOfHousingLoan, watchtUitionFeesForChildren, watchFiveYearFd, watchOthers])

    const onItDeclarationDetailUpdate = () => {
        const { ItDeclarationDetail } = props;
        if (ItDeclarationDetail != null) {
            var ItDeclarationDetailResponse = Object.assign({}, ItDeclarationDetail);
            if (ItDeclarationDetailResponse?.result === true) {
                setDeclarationDetails(ItDeclarationDetailResponse?.response);
                setValue("regime", ItDeclarationDetailResponse?.response?.regime);
                setValue('life_insurance_premium_or_pension_schemes', ItDeclarationDetailResponse?.response?.declaration_80c?.life_insurance_premium_or_pension_schemes);
                setValue('ppf', ItDeclarationDetailResponse?.response?.declaration_80c?.ppf);
                setValue('ulip_or_elss_or_tax_saving_mutual_funds', ItDeclarationDetailResponse?.response?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds);
                setValue('nsc', ItDeclarationDetailResponse?.response?.declaration_80c?.nsc);
                setValue('repayment_of_housing_loan', ItDeclarationDetailResponse?.response?.declaration_80c?.repayment_of_housing_loan);
                setValue('tuition_fees_for_children', ItDeclarationDetailResponse?.response?.declaration_80c?.tuition_fees_for_children);
                setValue('five_year_fd', ItDeclarationDetailResponse?.response?.declaration_80c?.five_year_fd);
                setValue('others', ItDeclarationDetailResponse?.response?.declaration_80c?.others);
                setValue('grand_total', ItDeclarationDetailResponse?.response?.declaration_80c?.grand_total);
                setValue('nps', ItDeclarationDetailResponse?.response?.declaration_80ccd?.nps);
                setValue('family_mediclaim', ItDeclarationDetailResponse?.response?.declaration_80d?.family_mediclaim);
                setValue('parents_mediclaim', ItDeclarationDetailResponse?.response?.declaration_80d?.parents_mediclaim);
                setValue('preventive_health_check_up', ItDeclarationDetailResponse?.response?.declaration_80d?.preventive_health_check_up);
                setValue('interest_paid_on_loan_for_self_education', ItDeclarationDetailResponse?.response?.declaration_80e?.interest_paid_on_loan_for_self_education);
                setValue('interest_paid_on_housing_loan', ItDeclarationDetailResponse?.response?.declaration_other?.interest_paid_on_housing_loan);
                setValue('loss_from_house_property', ItDeclarationDetailResponse?.response?.declaration_other?.loss_from_house_property);
                setValue('hra', ItDeclarationDetailResponse?.response?.declaration_other?.hra);
                setValue('emp_remark', ItDeclarationDetailResponse?.response?.emp_remark);
                setValue("pf_contribution", ItDeclarationDetailResponse?.response?.pf_contribution);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ItDeclarationDetailResponse?.status) {
                    case ERROR:
                        setDeclarationDetails(null);
                        break;
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onItDeclarationDetailUpdate, [props.ItDeclarationDetail]);

    const onItDeclarationEditUpdate = () => {
        const { ItDeclarationEdit } = props;
        var ItDeclarationEditResponse = Object.assign({}, ItDeclarationEdit);
        if (ItDeclarationEditResponse.result === true) {
            $("#edit-declaration-form-loader").addClass("hidden");
            $("#edit-declaration-form").prop("disabled", false);
            props.handleClose({ 'from': 'declaration' });
        }
        else {
            $("#edit-declaration-form-loader").addClass("hidden");
            $("#edit-declaration-form").prop("disabled", false);
            switch (ItDeclarationEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(ItDeclarationEditResponse.message);
                default:
            }
        }
    }
    useEffect(onItDeclarationEditUpdate, [props.ItDeclarationEdit]);

    const onSubmit = (data) => {
        /**
             * API : 
             * METHOD : POST
             * Description : Edit IT Declaration
             * INPUT : IT Declaration Object
             */
        if (selectDeclarationOne === true && selectDeclarationTwo === true && selectDeclarationThree === true) {
            $("#edit-declaration-form-loader").removeClass("hidden");
            $("#edit-declaration-form").prop("disabled", true);
            if (data?.regime === 'OLD') {
                data = {
                    window_id: props.windowId != null ? props.windowId : '',
                    regime: data?.regime,
                    emp_remark: data?.emp_remark,
                    pf_contribution: data?.pf_contribution,
                    declaration_80c: {
                        life_insurance_premium_or_pension_schemes: data?.life_insurance_premium_or_pension_schemes,
                        ppf: data?.ppf,
                        ulip_or_elss_or_tax_saving_mutual_funds: data?.ulip_or_elss_or_tax_saving_mutual_funds,
                        nsc: data?.nsc,
                        repayment_of_housing_loan: data?.repayment_of_housing_loan,
                        tuition_fees_for_children: data?.tuition_fees_for_children,
                        five_year_fd: data?.five_year_fd,
                        others: data?.others,
                        grand_total: data?.grand_total
                    },
                    declaration_80ccd: {
                        nps: data?.nps,
                    },
                    declaration_80d: {
                        family_mediclaim: data?.family_mediclaim,
                        parents_mediclaim: data?.parents_mediclaim,
                        preventive_health_check_up: data?.preventive_health_check_up,
                    },
                    declaration_80e: {
                        interest_paid_on_loan_for_self_education: data?.interest_paid_on_loan_for_self_education,
                    },
                    declaration_other: {
                        interest_paid_on_housing_loan: data?.interest_paid_on_housing_loan,
                        loss_from_house_property: data?.loss_from_house_property,
                        hra: data?.hra,
                    },
                }
            } else {
                data = {
                    window_id: props.windowId != null ? props.windowId : '',
                    regime: data?.regime,
                    emp_remark: data?.emp_remark,
                    pf_contribution: data?.pf_contribution
                }
            }
            props.actionItDeclarationsEdit(data);
        } else {
            Swal.fire({
                title: 'Missing declarations',
                icon: 'error',
                text: "IT declarations cannot be processed ahead without the acceptance of all declarations",
                confirmButtonColor: PRIMARY_COLOR
            }).then((result) => {

            });
        }

    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="edit-declaration"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="edit-declaration">
                                    {`Income Tax Declaration ${CalculateFinancialYear()}`}
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    reset();
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">TAX REGIME&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="regime"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={regime}
                                                            fields={{ text: 'title', value: 'value' }}
                                                            allowFiltering={false}
                                                            placeholder="Select Tax Regime"
                                                            value={field.value?.title}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData?.value);
                                                                    setSelectTaxRegime(event.itemData?.value);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Tax Regime'
                                                    }}
                                                />
                                                {errors.regime && <ErrorLabel message={errors.regime?.message} />}
                                            </div>
                                        </div>
                                        {
                                            selectTaxRegime != null && (selectTaxRegime === "OLD") ?
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                                        <FiUser size={16} />&nbsp;Details of Investment Under Section 80 C&nbsp;
                                                        <Tippy content={tooltipSection80C}>
                                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                        </Tippy>
                                                    </h4>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            selectTaxRegime != null && selectTaxRegime === "OLD" ?
                                                <>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <label className="label-control">Type of Investment</label>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Life Insurance Premium / Pension Schemes&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="life_insurance_premium_or_pension_schemes"
                                                                        name="life_insurance_premium_or_pension_schemes"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('life_insurance_premium_or_pension_schemes', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.life_insurance_premium_or_pension_schemes && <ErrorLabel message={errors.life_insurance_premium_or_pension_schemes?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Public Provident Fund (PPF)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="ppf"
                                                                        name="ppf"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('ppf', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.ppf && <ErrorLabel message={errors.ppf?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">ULIP / ELSS / Tax saving Mutual Funds&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        name="ulip_or_elss_or_tax_saving_mutual_funds"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('ulip_or_elss_or_tax_saving_mutual_funds', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.ulip_or_elss_or_tax_saving_mutual_funds && <ErrorLabel message={errors.ulip_or_elss_or_tax_saving_mutual_funds?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">NSC&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="nsc"
                                                                        name="nsc"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('nsc', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.nsc && <ErrorLabel message={errors.nsc?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Repayment of Housing Loan (Principal Amount)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="repayment_of_housing_loan"
                                                                        name="repayment_of_housing_loan"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('repayment_of_housing_loan', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.repayment_of_housing_loan && <ErrorLabel message={errors.repayment_of_housing_loan?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Tuition Fees for Children (Max for 2 children)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="tuition_fees_for_children"
                                                                        name="tuition_fees_for_children"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('tuition_fees_for_children', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.tuition_fees_for_children && <ErrorLabel message={errors.tuition_fees_for_children?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">5 year Fixed Deposits (with specified banks)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="five_year_fd"
                                                                        name="five_year_fd"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('five_year_fd', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.five_year_fd && <ErrorLabel message={errors.five_year_fd?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Others&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="others"
                                                                        name="others"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[0-9.]*$/.test(event.key)) {
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                        {...register('others', {
                                                                            required: 'Please Enter 0 if Nothing to Declare',
                                                                            pattern: {
                                                                                value: AMOUNT_REGEX,
                                                                                message: 'Numeric Field (up to 2 decimals allowed)',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.others && <ErrorLabel message={errors.others?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Grand Total</label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="grand_total"
                                                                        name="grand_total"
                                                                        type="number"
                                                                        placeholder="Enter Grand Total"
                                                                        maxLength={100}
                                                                        readOnly={true}
                                                                        {...register('grand_total', {
                                                                            maxLength: {
                                                                                value: 100,
                                                                                message: "Maximum limit 100"
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.grand_total && <ErrorLabel message={errors.grand_total?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            selectTaxRegime != null && (selectTaxRegime === "OLD" || selectTaxRegime === "NEW") ?
                                                <>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;EPF Contribution
                                                        </h4>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">PF Contribution&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <Controller
                                                                        name="pf_contribution"
                                                                        control={control}
                                                                        render={({ field }) =>
                                                                            <DropDownListComponent
                                                                                {...field}
                                                                                dataSource={pfContribution}
                                                                                fields={{ text: 'title', value: 'value' }}
                                                                                allowFiltering={false}
                                                                                placeholder="Select PF Contribution"
                                                                                value={field.value?.title}
                                                                                change={(event) => {
                                                                                    if (event.itemData != null) {
                                                                                        field.onChange(event.itemData?.value);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        rules={{
                                                                            required: 'Please Select PF Contribution'
                                                                        }}
                                                                    />
                                                                    {errors.pf_contribution && <ErrorLabel message={errors.pf_contribution?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            selectTaxRegime != null && selectTaxRegime === "OLD" ?
                                                <>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Details of Investment Under Section 80 CCD (1B)&nbsp;
                                                            <Tippy content={tooltipSection80CCD}>
                                                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                            </Tippy>
                                                        </h4>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">National Pension Scheme&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="nps"
                                                                        name="nps"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('nps', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.nps && <ErrorLabel message={errors.nps?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Details of Investment Under Section 80 D&nbsp;
                                                            <Tippy content={tooltipSection80D}>
                                                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                            </Tippy>
                                                        </h4>

                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Family (Self, Spouse & Children)&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="family_mediclaim"
                                                                        name="family_mediclaim"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('family_mediclaim', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.family_mediclaim && <ErrorLabel message={errors.family_mediclaim?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Parents&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="parents_mediclaim"
                                                                        name="parents_mediclaim"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('parents_mediclaim', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.parents_mediclaim && <ErrorLabel message={errors.parents_mediclaim?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Preventive Health Check up&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="preventive_health_check_up"
                                                                        name="preventive_health_check_up"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('preventive_health_check_up', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.preventive_health_check_up && <ErrorLabel message={errors.preventive_health_check_up?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Details of Investment Under Section 80 E
                                                        </h4>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Interest Paid on Loan for Self-Education&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="interest_paid_on_loan_for_self_education"
                                                                        name="interest_paid_on_loan_for_self_education"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('interest_paid_on_loan_for_self_education', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.interest_paid_on_loan_for_self_education && <ErrorLabel message={errors.interest_paid_on_loan_for_self_education?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                                            <FiUser size={16} />&nbsp;Other Declarations&nbsp;
                                                            <Tippy content={tooltipOthers}>
                                                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                            </Tippy>
                                                        </h4>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Interest Paid on Housing Loan&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="interest_paid_on_housing_loan"
                                                                        name="interest_paid_on_housing_loan"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('interest_paid_on_housing_loan', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.interest_paid_on_housing_loan && <ErrorLabel message={errors.interest_paid_on_housing_loan?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Loss from House Property&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="loss_from_house_property"
                                                                        name="loss_from_house_property"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('loss_from_house_property', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.loss_from_house_property && <ErrorLabel message={errors.loss_from_house_property?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Rent Paid (HRA) Annually&nbsp;<span className="danger">*</span></label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="hra"
                                                                        name="hra"
                                                                        type="text"
                                                                        placeholder="Enter 0 if Nothing to Declare"
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        {...register('hra', {
                                                                            required: 'Please Enter 0 if Nothing to Declare'
                                                                        })}
                                                                    />
                                                                    {errors.hra && <ErrorLabel message={errors.hra?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            selectTaxRegime != null && (selectTaxRegime === "OLD" || selectTaxRegime === "NEW") ?
                                                <>

                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center form-section mb-2 black" />
                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <label className="label-control">Remarks</label>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control"
                                                                        id="emp_remark"
                                                                        name="emp_remark"
                                                                        type="text"
                                                                        maxLength={200}
                                                                        placeholder="Enter Remarks"
                                                                        {...register('emp_remark', {
                                                                            maxLength: {
                                                                                value: 200,
                                                                                message: "Please enter upto 200 characters"
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.emp_remark && <ErrorLabel message={errors.emp_remark?.message} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="edit_declaration_one"
                                                        id="edit_declaration_one"
                                                        defaultChecked={selectDeclarationOne === true ? true : false}
                                                        onClick={(event) => {
                                                            if (event.target.checked === true) {
                                                                setSelectDeclarationOne(true);
                                                            } else {
                                                                setSelectDeclarationOne(false);
                                                            }
                                                        }}
                                                    />
                                                    {errors.edit_declaration_one && <ErrorLabel message={errors.edit_declaration_one?.message} />}
                                                    <label class="custom-control-label" for="edit_declaration_one">{`I hereby declare the above investments during FY ${CalculateFinancialYear()}. Please consider the shared details while calculation of Income Tax for FY ${CalculateFinancialYear()}.`}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="edit_declaration_two"
                                                        id="edit_declaration_two"
                                                        defaultChecked={selectDeclarationTwo === true ? true : false}
                                                        onClick={(event) => {
                                                            if (event.target.checked === true) {
                                                                setSelectDeclarationTwo(true);
                                                            } else {
                                                                setSelectDeclarationTwo(false);
                                                            }
                                                        }}
                                                    />
                                                    {errors.edit_declaration_two && <ErrorLabel message={errors.edit_declaration_two?.message} />}
                                                    <label class="custom-control-label" for="edit_declaration_two">I also accept that once declared, the regime cannot be changed during the FY, irrespective of any changes in salary.</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="edit_declaration_three"
                                                        id="edit_declaration_three"
                                                        defaultChecked={selectDeclarationThree === true ? true : false}
                                                        onClick={(event) => {
                                                            if (event.target.checked === true) {
                                                                setSelectDeclarationThree(true);
                                                            } else {
                                                                setSelectDeclarationThree(false);
                                                            }
                                                        }}
                                                    />
                                                    {errors.edit_declaration_three && <ErrorLabel message={errors.edit_declaration_three?.message} />}
                                                    <label class="custom-control-label" for="edit_declaration_three">At end of the year respective supporting against each investment declaration will be required. In absence of the same, correct TAX deduction for the entire year shall be applied in the March Salary.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions border-0">
                                    <button
                                        id="edit-declaration-form"
                                        className="btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0"
                                        type="submit"
                                    >
                                        <div className="d-flex align-items-center">
                                            Submit
                                            <span id="edit-declaration-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </div>
                                    </button>
                                    <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                        onClick={(event) => {
                                            reset();
                                            props.handleClose();
                                            event.preventDefault();
                                        }}
                                    > Cancel</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
const mapStateToProps = (state) => {
    var ItDeclarationDetail = null;
    var ItDeclarationEdit = null;
    if (
        state.HRMSItDeclarationEditReducer != null &&
        Object.keys(state.HRMSItDeclarationEditReducer).length > 0 &&
        state.HRMSItDeclarationEditReducer.constructor === Object
    ) {
        switch (state.HRMSItDeclarationEditReducer.tag) {
            case ACTION_EDIT_IT_DECLARATION_DETAIL_SUCCESS:
                ItDeclarationDetail = Object.assign({}, state.HRMSItDeclarationEditReducer.data);
                delete state.HRMSItDeclarationEditReducer;
                return { ItDeclarationDetail };
            case ACTION_EDIT_IT_DECLARATION_EDIT_SUCCESS:
                ItDeclarationEdit = Object.assign({}, state.HRMSItDeclarationEditReducer.data);
                delete state.HRMSItDeclarationEditReducer;
                return { ItDeclarationEdit };
            default:
        }
    }

    return {
        ItDeclarationDetail,
        ItDeclarationEdit
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItDeclarationDetail, actionItDeclarationsEdit }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditDeclaration))