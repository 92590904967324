import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import $ from "jquery";
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionTeamHead, ACTION_TEAM_HEAD_SUCCESS } from './action';
import { actionUserBranchName, ACTION_GET_MASTER_USERBRANCH_SUCCESS } from './action';
import { actionTeamMasterAdd, ACTION_TEAM_MASTER_ADD_SUCCESS } from './action';
import { toast } from 'react-toastify';

const TeamMasterAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [teamHead, setTeamHead] = useState();
    const [userBranchName, setUserBranchName] = useState();

    const initialRender = () => {
        props.actionTeamHead({});
        props.actionUserBranchName({});
    }
    useEffect(initialRender, [props.show]);

    const onTeamHeadUpdate = () => {
        const { TeamHead } = props;
        var TeamHeadResponse = Object.assign({}, TeamHead);
        if (TeamHeadResponse.result === true) {
            setTeamHead(TeamHeadResponse.response);
        } else {
            switch (TeamHeadResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onTeamHeadUpdate, [props.TeamHead]);

    const onUserBranchNameUpdate = () => {
        const { UserBranchName } = props;
        var UserBranchNameResponse = Object.assign({}, UserBranchName);
        if (UserBranchNameResponse.result === true) {
            setUserBranchName(UserBranchNameResponse.response);
        } else {
            switch (UserBranchNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onUserBranchNameUpdate, [props.UserBranchName]);

    const onAddTeamMasterSave = () => {
        const { AddTeamMaster } = props;
        var AddTeamMasterResponse = Object.assign({}, AddTeamMaster);
        $("#add-team-master").prop("disabled", false);
        if (AddTeamMasterResponse.result === true) {
            $("#add-team-master-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Team Master Saved Successfully");
            props.history.goBack();
        } else {
            $("#add-team-master-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (AddTeamMasterResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(AddTeamMasterResponse.message);
                default:
            }
        }
    }
    useEffect(onAddTeamMasterSave, [props.AddTeamMaster]);

    const onSubmit = (data) => {
        // data.user_id = props.auth.getUser().user_id;
        $("#add-team-master-loader").removeClass("hidden");
        $("#add-team-master").prop("disabled", true);
        // $("#progress-loader").removeClass("hidden");
        props.actionTeamMasterAdd(data);
    }
    return (
        <>
            <ScreenTitle title="Add Team" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Team Name&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter Team Name"
                                                    {...register('name', { required: 'Team Name is required' })}
                                                />
                                                {errors.name && <ErrorLabel message={errors.name?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Team Head</label>
                                                <Controller
                                                    name="user_id"
                                                    className="form-control"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={teamHead}
                                                            allowFiltering={false}
                                                            placeholder="Select Team Head"
                                                            value={field.value}
                                                            fields={{ text: 'full_name', value: 'user_id' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.value)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Team Head is required'
                                                    }}
                                                />
                                                {errors.user_id && <ErrorLabel message={errors.user_id?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Branch&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="user_branch_name"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={userBranchName}
                                                            fields={{ id: 'id', value: 'name' }}
                                                            value={field.value}
                                                            placeholder="Select Branch Name"
                                                            change={(event) => {
                                                                if (event.itemData.name != null) {
                                                                    field.onChange(event.itemData.name)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Branch Name'
                                                    }}
                                                />
                                                {errors.user_branch_name && <ErrorLabel message={errors.user_branch_name?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>

                                        <button
                                            id="add-team-master"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add-team-master-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var TeamHead = null;
    var UserBranchName = null;
    var AddTeamMaster = null;

    if (
        state.AddTeamMasterReducer != null &&
        Object.keys(state.AddTeamMasterReducer).length > 0 &&
        state.AddTeamMasterReducer.constructor === Object
    ) {
        switch (state.AddTeamMasterReducer.tag) {
            case ACTION_TEAM_HEAD_SUCCESS:
                TeamHead = Object.assign({}, state.AddTeamMasterReducer.data);
                delete state.AddTeamMasterReducer;
                return { TeamHead };
            case ACTION_GET_MASTER_USERBRANCH_SUCCESS:
                UserBranchName = Object.assign({}, state.AddTeamMasterReducer.data);
                delete state.AddTeamMasterReducer;
                return { UserBranchName };
            case ACTION_TEAM_MASTER_ADD_SUCCESS:
                AddTeamMaster = Object.assign({}, state.AddTeamMasterReducer.data);
                delete state.AddTeamMasterReducer;
                return { AddTeamMaster };
            default:
        }
    }
    return {
        TeamHead,
        UserBranchName,
        AddTeamMaster
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionTeamHead,
        actionUserBranchName,
        actionTeamMasterAdd
    }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TeamMasterAdd))