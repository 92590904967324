import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../../component/screen_title';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from '../../../../../../../component/form/error_label';
import { actionMasterBranchStates, ACTION_MASTER_EDIT_BRANCH_STATES_SUCCESS } from "./action";
import { actionMasterBranchCities, ACTION_MASTER_EDIT_BRANCH_CITIES_SUCCESS } from "./action";
import { actionMasterEditInsurerBranch, ACTION_MASTER_EDIT_INSURER_BRANCH_SUCCESS } from "./action";
import { ERROR, UNAUTHORIZED } from "../../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../../utils/preferences';

const BranchMasterEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [branchDetails, setBranchDetails] = useState(null);
    const [masterState, setMasterState] = useState([]);
    const [masterCity, setMasterCity] = useState([]);

    const initialRender = () => {
        if (props?.location?.state?.detail != null) {
            setBranchDetails(props?.location?.state?.detail);
        }
        props.actionMasterBranchStates();
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (branchDetails != null) {
            setValue('type', branchDetails?.type)
            setValue('branch_code', branchDetails?.branch_code)
            setValue('short_name', branchDetails?.short_name)
            setValue('state_id', branchDetails?.state_id)
            setValue('city_id', branchDetails?.city_id)
            setValue('address', branchDetails?.address)
            setValue('status', branchDetails.status)
        }
    }, [branchDetails])

    useEffect(() => {
        if (masterState != null && masterState.length > 0) {
            if (branchDetails != null && branchDetails?.state_id != null) {
                setValue('state_id', branchDetails.state_id)
            }
        }
    }, [masterState])

    const onMasterBranchStatesUpdate = () => {
        const { MasterBranchStates } = props
        if (MasterBranchStates != null) {
            var MasterBranchStatesResponse = Object.assign({}, MasterBranchStates);
            if (MasterBranchStatesResponse.result === true) {
                setMasterState(MasterBranchStatesResponse.response);
            } else {
                switch (MasterBranchStatesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterState([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBranchStatesUpdate, [props.MasterBranchStates])

    useEffect(() => {
        if (masterCity != null && masterCity.length > 0) {
            if (branchDetails != null && branchDetails?.city_id != null) {
                setValue('city_id', branchDetails?.city_id)
            }
        }
    }, [masterCity])

    const onMasterBranchCitiesUpdate = () => {
        const { MasterBranchCities } = props
        if (MasterBranchCities != null) {
            var MasterBranchCitiesResponse = Object.assign({}, MasterBranchCities);
            if (MasterBranchCitiesResponse.result === true) {
                setMasterCity(MasterBranchCitiesResponse.response);
            } else {
                switch (MasterBranchCitiesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterCity([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterBranchCitiesUpdate, [props.MasterBranchCities])

    const onMasterEditInsurerBranchUpdate = () => {
        const { MasterEditInsurerBranch } = props
        if (MasterEditInsurerBranch != null) {
            var MasterEditInsurerBranchResponse = Object.assign({}, MasterEditInsurerBranch);
            $("#edit_insurer_branch").prop("disabled", false);
            if (MasterEditInsurerBranchResponse.result === true) {
                $("#insurer-branch-form-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Insurer Branch Updated Successfully");
                props.history.goBack();
            } else {
                $("#insurer-branch-form-loader").addClass("hidden");
                switch (MasterEditInsurerBranchResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterEditInsurerBranchUpdate, [props.MasterEditInsurerBranch])

    const onSubmit = (data) => {
        $("#edit_insurer_branch").prop("disabled", true);
        $("#insurer-branch-form-loader").removeClass("hidden");
        data.id = branchDetails?.id;
        data.insurer_id = props?.location?.state?.insurer_id;
        // data.status = props?.location?.state?.insurer_status;
        data.status = data.status
        props.actionMasterEditInsurerBranch(data);
    }

    return (
        <>
            <ScreenTitle title="Edit Branch" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="type"
                                                                name="type"
                                                                dataSource={[
                                                                    {
                                                                        value: 'Head Office',
                                                                        title: 'Head Office'
                                                                    },
                                                                    {
                                                                        value: 'Branch Office',
                                                                        title: 'Branch Office'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Branch Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch Type is required'
                                                        }}
                                                    />

                                                    {errors.type && <ErrorLabel message={errors.type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Branch Code</label>
                                                    <input
                                                        className="form-control"
                                                        id="branch_code"
                                                        name="branch_code"
                                                        placeholder="Branch Code"
                                                        {...register('branch_code')}
                                                    />
                                                    {errors.branch_code && <ErrorLabel message={errors.branch_code?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Branch Short Name</label>
                                                    <input
                                                        className="form-control"
                                                        id="short_name"
                                                        name="short_name"
                                                        placeholder="Branch Short Name"
                                                        {...register('short_name')}
                                                    />
                                                    {errors.short_name && <ErrorLabel message={errors.short_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch State&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="state_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="state_id"
                                                                name="state_id"
                                                                dataSource={masterState}
                                                                allowFiltering={true}
                                                                placeholder="Select Branch State"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        props.actionMasterBranchCities({ state_ids: [event.itemData.id] })
                                                                        setMasterCity([]);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch State is required'
                                                        }}
                                                    />

                                                    {errors.state_id && <ErrorLabel message={errors.state_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch City&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="city_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="city_id"
                                                                name="city_id"
                                                                dataSource={masterCity}
                                                                allowFiltering={true}
                                                                placeholder="Select Branch City"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Branch City is required'
                                                        }}
                                                    />

                                                    {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control ">Branch Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        maxLength={300}
                                                        placeholder="Branch Address"
                                                        {...register('address', {
                                                            required: "Please Enter Branch Address"
                                                        })}
                                                    />
                                                    {errors.address && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        value: 'ACTIVE',
                                                                        text: 'ACTIVE'

                                                                    },
                                                                    {
                                                                        value: 'INACTIVE',
                                                                        text: 'INACTIVE'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_insurer_branch"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            branch_type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="insurer-branch-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterBranchStates = null;
    var MasterBranchCities = null;
    var MasterEditInsurerBranch = null;

    if (
        state.MasterEditInsurerBranchReducer != null &&
        Object.keys(state.MasterEditInsurerBranchReducer).length > 0 &&
        state.MasterEditInsurerBranchReducer.constructor === Object
    ) {
        switch (state.MasterEditInsurerBranchReducer.tag) {
            case ACTION_MASTER_EDIT_BRANCH_STATES_SUCCESS:
                MasterBranchStates = Object.assign({}, state.MasterEditInsurerBranchReducer.data);
                delete state.MasterEditInsurerBranchReducer;
                return { MasterBranchStates };
            case ACTION_MASTER_EDIT_BRANCH_CITIES_SUCCESS:
                MasterBranchCities = Object.assign({}, state.MasterEditInsurerBranchReducer.data);
                delete state.MasterEditInsurerBranchReducer;
                return { MasterBranchCities };
            case ACTION_MASTER_EDIT_INSURER_BRANCH_SUCCESS:
                MasterEditInsurerBranch = Object.assign({}, state.MasterEditInsurerBranchReducer.data);
                delete state.MasterEditInsurerBranchReducer;
                return { MasterEditInsurerBranch };
            default:

        }
    }
    return {
        MasterBranchStates,
        MasterBranchCities,
        MasterEditInsurerBranch
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterBranchStates, actionMasterBranchCities, actionMasterEditInsurerBranch }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(BranchMasterEdit))