import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_GENERAL_PREFERENCE_ADD } from '../../../../../../api/constants';
import { API_MASTER_GET_DEPARTMENT } from '../../../../../../api/constants';
import { API_MASTER_GET_POLICY_TYPE } from '../../../../../../api/constants';
import { API_MASTER_GET_INSURER } from '../../../../../../api/constants';

export const ACTION_GENERAL_PREFERENCE_ADD_LOADING = 'ACTION_GENERAL_PREFERENCE_ADD_LOADING';
export const ACTION_GENERAL_PREFERENCE_ADD_SUCCESS = 'ACTION_GENERAL_PREFERENCE_ADD_SUCCESS';

export const ACTION_GET_DEPARTMENT_LOADING = 'ACTION_GET_DEPARTMENT_LOADING';
export const ACTION_GET_DEPARTMENT_SUCCESS = 'ACTION_GET_DEPARTMENT_SUCCESS';

export const ACTION_GET_POLICY_TYPE_LOADING = 'ACTION_GET_POLICY_TYPE_LOADING';
export const ACTION_GET_POLICY_TYPE_SUCCESS = 'ACTION_GET_POLICY_TYPE_SUCCESS';

export const ACTION_GET_INSURER_LOADING = 'ACTION_GET_INSURER_LOADING';
export const ACTION_GET_INSURER_SUCCESS = 'ACTION_GET_INSURER_SUCCESS';

export function actionGeneralPlacementAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_GENERAL_PREFERENCE_ADD, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GENERAL_PREFERENCE_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GENERAL_PREFERENCE_ADD_LOADING, { loading: false, data }));
        });
}
export function actionGetDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_DEPARTMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionGetPolicyType(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionGetCompanyList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_INSURER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_INSURER_LOADING, { loading: false, data }));
        });
}