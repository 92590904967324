import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_BROKER_TRAINING_LIST, API_TRAINING_EMPLOYEE_PARTICIPATE_ADD } from '../../../../../../api/constants';

export const ACTION_BROKER_TRAINING_LIST_LOADING = 'ACTION_BROKER_TRAINING_LIST_LOADING';
export const ACTION_BROKER_TRAINING_LIST_SUCCESS = 'ACTION_BROKER_TRAINING_LIST_SUCCESS';

export const ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_LOADING = 'ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_LOADING';
export const ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_SUCCESS = 'ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_SUCCESS';

export function actionBrokerTrainingList(params) {
    return (dispatch, getState) =>
        Api.post(API_BROKER_TRAINING_LIST, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_BROKER_TRAINING_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BROKER_TRAINING_LIST_LOADING, { loading: false, data }));
        });
}

export function actionTrainingEmployeeParticipateAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAINING_EMPLOYEE_PARTICIPATE_ADD, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAINING_EMPLOYEE_PARTICIPATE_ADD_LOADING, { loading: false, data }));
        });
}