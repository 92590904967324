import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import CustomerMeetingsView from '../../../../../../../component/popups/meetings/customer';
import { FiEye, FiEdit } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionGroupLevelMeetingView, ACTION_API_GROUP_LEVEL_MEETING_SUCCESS } from './action';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';
import { verifyPermission } from '../../../../../../../utils';

const GroupMeetingsView = (props) => {
    const [clientMeetingData, setClientMeetingData] = useState([]);
    const [showCustomerMeetingView, setShowCustomerMeetingView] = useState(false);
    const [customerMeetingId, setCustomerMeetingId] = useState(null);


    const [meetingColumn, setMeetingColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "company_name", caption: "Company" },
        { dataField: "created_by_name", caption: "Created By" },
        { dataField: "meeting_date", caption: "Meeting Date" },
        { dataField: "business_type", caption: "Meeting Type" },
        { dataField: "status", caption: "Status" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGroupLevelMeetingView({
            client_group_id: props.clientGroupId,
        })
    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupMeetingViewUpdate = () => {

        const { GroupMeetingView } = props;

        if (GroupMeetingView != null) {
            var GroupMeetingViewResponse = Object.assign({}, GroupMeetingView);
            if (GroupMeetingViewResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setMeetingColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "company_name", caption: "Company" },
                    { dataField: "created_by_name", caption: "Created By" },
                    { dataField: "meeting_date", caption: "Meeting Date" },
                    { dataField: "business_type", caption: "Meeting Type" },
                    { dataField: "status", caption: "Status" },
                ])
                setClientMeetingData(GroupMeetingViewResponse.response);
            } else {
                setClientMeetingData([])
                $("#progress-loader").addClass("hidden");
                switch (GroupMeetingViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }

            }
        }
    }


    useEffect(onGroupMeetingViewUpdate, [props.GroupMeetingView]);

    const renderStatusCell = (cell) => {
        console.log(cell);
        switch (cell.data.status) {
            case 'Open':
                return <div className={"badge badge-success round px-1"}>Open</div>;
            case 'Close':
                return <div className={"badge badge-danger round px-1"}>Close</div>;
            case 'Cancel':
                return <div className={"badge badge-warning round px-1"}>Cancel</div>;
            default:
                return <></>
        }
    }
    const renderMeetingColumn = () => {
        var arrColumns = [];
        meetingColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        setShowCustomerMeetingView(true)
                                        setCustomerMeetingId(params.data.meeting_id);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                props.auth.userId === params.data.created_by ?
                                    <button
                                        className={`btn btn-icon btn-sm ${params.data != null && (params.data.status === 'Close' || params.data.status === 'Cancel') ? 'hidden' : ''}`}
                                        onClick={() => {

                                            console.log("1", props.auth.userId);
                                            console.log("2", params.data.created_by);
                                            switch (params.data.meeting_type) {
                                                case 'Reinsurance':
                                                    props.history.push({ pathname: `/meetings/ri-meetings/edit`, state: { ri_meeting: params.data.meeting_id } });
                                                    break;
                                                case 'Internal':
                                                    props.history.push({ pathname: `/meetings/internal-meetings/edit`, state: { internal_meeting_id: params.data.meeting_id } });
                                                    break;
                                                case 'Tpa':
                                                    props.history.push({ pathname: `/meetings/tpa-meetings/edit`, state: { tpa_meeting_id: params.data.meeting_id } })
                                                    break;
                                                case 'Surveyor':
                                                    props.history.push({ pathname: `/meetings/serveyor-meetings/edit`, state: { serveyor_meeting_id: params.data.meeting_id } })
                                                    break;
                                                case 'Lead':
                                                case 'Client':
                                                    props.history.push({ pathname: `/meetings/customer-meetings/edit`, state: { customer_meeting_id: params.data.meeting_id, meeting_type: params.data.meeting_type } })
                                                    break;
                                                case 'Insurer':
                                                    props.history.push({ pathname: `/meetings/insurance-company-meetings/edit`, state: { insurer_branch_id: params.data.meeting_id } })
                                                    break;
                                            }
                                        }}
                                    >
                                        <FiEdit size={16} className="primary" />
                                    </button>
                                    : <> </>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };
    const renderMeetingTableView = () => {
        return (
            <DataGrid
                dataSource={clientMeetingData}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                columnHidingEnabled={true}
                export={{
                    fileName: "meetings",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderMeetingColumn()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />

            </DataGrid>
        )
    }
    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    clientMeetingData != null && clientMeetingData.length > 0 ?
                                        renderMeetingTableView() :
                                        <h6 className="text-bold-500 text-center">No Meetings Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomerMeetingsView
                show={showCustomerMeetingView}
                customerMeetingId={customerMeetingId}
                handleClose={() => {
                    setShowCustomerMeetingView(false);
                }}
            />
        </>
    )


}
function mapStateToProps(state) {

    var GroupMeetingView = null;

    if (
        state.TabGroupMeetingReducer != null &&
        Object.keys(state.TabGroupMeetingReducer).length > 0 &&
        state.TabGroupMeetingReducer.constructor === Object
    ) {
        switch (state.TabGroupMeetingReducer.tag) {

            case ACTION_API_GROUP_LEVEL_MEETING_SUCCESS:
                GroupMeetingView = Object.assign({}, state.TabGroupMeetingReducer.data);
                delete state.TabGroupMeetingReducer;
                return { GroupMeetingView };
            default:
                return { GroupMeetingView }
        }
    }
    return { GroupMeetingView }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupLevelMeetingView }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupMeetingsView));
