import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyBKdYISwVl5ZFrMCGI0-SH2u6w5hjXXst8",
    authDomain: "pinc-crm-dev.firebaseapp.com",
    projectId: "pinc-crm-dev",
    storageBucket: "pinc-crm-dev.appspot.com",
    messagingSenderId: "612830629693",
    appId: "1:612830629693:web:ea7c7e416e18b6dca77f54"
};

var FirebaseApp = firebase.initializeApp(config);
export default FirebaseApp