
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_HOLIDAY_LIST } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_CALENDAR_HOLIDAY_LOADING = 'ACTION_CALENDAR_HOLIDAY_LOADING';
export const ACTION_CALENDAR_HOLIDAY_SUCCESS = 'ACTION_CALENDAR_HOLIDAY_SUCCESS';


export function actionCalendarHoliday() {
    return (dispatch, getState) =>
        Api.get(API_HOLIDAY_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CALENDAR_HOLIDAY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CALENDAR_HOLIDAY_LOADING, { loading: false, data }));
        });
}