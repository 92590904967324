import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_EDIT_GET_SERVEYOR_NAME_SUCCESS } from "./action";
import { ACTION_SURVEYOR_MEETING_VIEW_SUCCESS } from "./action";
import { ACTION_API_ACCOMPAINED_BY_SUCCESS,ACTION_EDITSERVEYORMEETINGEDITCONTACT_SUCCESS} from "./action";
import {ACTION_SERVEYOR_GET_CONTACTS_SUCCESS} from './action';
import { ACTION_SURVEYOR_MEETING_EDIT_SUCCESS } from "./action";
import { ACTION_DELETE_DOCUMENT_SUCCESS } from "./action";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditServeyorMeetingReducer = createReducer(INITIAL_STATE, {

    [ACTION_API_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },

    [ACTION_EDIT_GET_SERVEYOR_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_GET_SERVEYOR_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SURVEYOR_MEETING_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SURVEYOR_MEETING_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDITSERVEYORMEETINGEDITCONTACT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_EDITSERVEYORMEETINGEDITCONTACT_SUCCESS,
            loading: false
        });
    },
    [ACTION_SERVEYOR_GET_CONTACTS_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_SERVEYOR_GET_CONTACTS_SUCCESS,
            loading: false
        });
    },
    [ACTION_SURVEYOR_MEETING_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SURVEYOR_MEETING_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DELETE_DOCUMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DELETE_DOCUMENT_SUCCESS,
                loading: false,
            }
        );
    },
});
export default EditServeyorMeetingReducer;