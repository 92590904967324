import React from 'react';
import ScreenTitle from '../../../../../component/screen_title';
import { verifyPermission } from '../../../../../utils';

const Dashboard = (props) => {
    const { match } = props;
    return (
        <>
            {
                verifyPermission('ATTENDANCE_REPORT') || verifyPermission('LEAVE_REPORT') ?
                    <ScreenTitle title="Attendance Reports" /> : <></>
            }
            <div className="row">
                {
                    verifyPermission('ATTENDANCE_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body"
                                    onClick={() => {
                                        props.history.push(`${match.url}/attendance`);
                                    }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/attendance_report.png" alt="Attendance Report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Attendance Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
                {
                    verifyPermission('LEAVE_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body"
                                    onClick={() => {
                                        props.history.push(`${match.url}/leave`);
                                    }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/leave_report.png" alt="Leave Report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Leave Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            {
                verifyPermission('MEETING_REPORT') || verifyPermission('MEETING_DETAILS') ?
                    <ScreenTitle title="Meeting Reports" /> : <></>
            }

            <div className="row">
                {
                    verifyPermission('MEETING_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body"
                                    onClick={() => {
                                        props.history.push(`${match.url}/meeting`);
                                    }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/meeting_report.png" alt="Meeting Report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Meeting Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
                {
                    verifyPermission('MEETING_DETAILS') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body"
                                    onClick={() => {
                                        props.history.push(`${match.url}/meeting_detail_report`);
                                    }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/meeting_details.png" alt="Meeting Details" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Meeting Details</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
                {
                    verifyPermission('UNATTENDED_CLIENT_LEAD_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body"
                                    onClick={() => {
                                        props.history.push(`${match.url}/lead_client_meeting_report`);
                                    }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/meeting.png" alt="Unattended Client/ Lead report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Unattended Client/ Lead Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            {
                verifyPermission('REIMBURSEMENT_REPORT') ?
                    <ScreenTitle title="Reimbursement Reports" />
                    :
                    <></>
            }
            <div className="row">
                {
                    verifyPermission('REIMBURSEMENT_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body" onClick={() => {
                                    props.history.push(`${match.url}/reimbursement`);
                                }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/reimbursement.png" alt="Reimbursement Report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Reimbursement Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            {
                verifyPermission('CIMS_REPORT') ?
                    <ScreenTitle title="CIMS Reports" />
                    :
                    <></>
            }
            <div className="row">
                {
                    verifyPermission('CIMS_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body" onClick={() => {
                                    props.history.push(`${match.url}/customer_verification_report`);
                                }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/access_report.png" alt="Customer Verification Report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Customer Verification Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            {/* BQP */}
            {
                verifyPermission('TRAINING_REPORT') || verifyPermission('TRAINING_PARTICIPATION_REPORT') ?
                    <ScreenTitle title="Broker Training Reports" />
                    : <></>
            }
            <div className="row">
                {
                    verifyPermission('TRAINING_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body"
                                    onClick={() => {
                                        props.history.push(`${match.url}/training`);
                                    }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/attendance_report.png" alt="Training Report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Training Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
                {
                    verifyPermission('TRAINING_PARTICIPATION_REPORT') ?
                        <div className="col-12 col-lg-3 col-md-3">
                            <div className="card cursor-pointer rounded shadow zoom">
                                <div className="card-body"
                                    onClick={() => {
                                        props.history.push(`${match.url}/training_participation`);
                                    }}>
                                    <div className="d-flex">
                                        <div className="">
                                            <img src="./assets/images/reports/leave_report.png" alt="Training participation Report" />
                                        </div>
                                        <span className="ml-1 my-auto font-small-3 text-bold-600">Training participation Report</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
        </>
    )
}

export default Dashboard