import React from 'react';
import { Route, Switch } from 'react-router';
import RiskManagementList from './new_request/list';
import RiskManagementAdd from './new_request/add';
import RiskManagementEdit from './new_request/edit';
import RiskManagementView from './new_request/view';
import RiskManagementApprovalList from './request_approvals/list';
import RiskManagementApprovolEdit from './request_approvals/edit';
import RiskManagementApprovalView from './request_approvals/view';
import RiskPool from './risk_pool/list';
import RiskPoolEdit from './risk_pool/edit';
import RiskPoolView from './risk_pool/view';
import RiskManagementReports from './risk_report';

const RiskManagementRoute = (props) => {
    const { match } = props

    return (
        <Switch>
            <Route path={`${match.url}/new_request/add`} component={RiskManagementAdd} />
            <Route path={`${match.url}/new_request/edit`} component={RiskManagementEdit} />
            <Route path={`${match.url}/new_request/view`} component={RiskManagementView} />
            <Route path={`${match.url}/new_request`} component={RiskManagementList} />
            <Route path={`${match.url}/request_approvals/edit`} component={RiskManagementApprovolEdit} />
            <Route path={`${match.url}/request_approvals/view`} component={RiskManagementApprovalView} />
            <Route path={`${match.url}/request_approvals`} component={RiskManagementApprovalList} />
            <Route path={`${match.url}/risk_pool/edit`} component={RiskPoolEdit} />
            <Route path={`${match.url}/risk_pool/view`} component={RiskPoolView} />
            <Route path={`${match.url}/risk_pool`} component={RiskPool} />
            <Route path={`${match.url}/report`} component={RiskManagementReports} />
        </Switch>
    )
}

export default RiskManagementRoute;