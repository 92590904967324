import { API_MASTER_ASSIGN_RM, API_RM_GET_LEADS, API_RM_GET_CLIENTS, API_RM_TRANSFER } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_MASTER_ASSIGN_RM_LOADING = 'ACTION_MASTER_ASSIGN_RM_LOADING';
export const ACTION_MASTER_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_ASSIGN_RM_SUCCESS';

export const ACTION_RM_GET_LEADS_LOADING = 'ACTION_RM_GET_LEADS_LOADING';
export const ACTION_RM_GET_LEADS_SUCCESS = 'ACTION_RM_GET_LEADS_SUCCESS';

export const ACTION_RM_GET_CLIENTS_LOADING = 'ACTION_RM_GET_CLIENTS_LOADING';
export const ACTION_RM_GET_CLIENTS_SUCCESS = 'ACTION_RM_GET_CLIENTS_SUCCESS';

export const ACTION_RM_TRANSFER_LOADING = 'ACTION_RM_TRANSFER_LOADING';
export const ACTION_RM_TRANSFER_SUCCESS = 'ACTION_RM_TRANSFER_SUCCESS';

export function actionGetRMDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ASSIGN_RM, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}

export function actionGetRMLeads(params) {
    return (dispatch, getState) =>
        Api.post(API_RM_GET_LEADS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RM_GET_LEADS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RM_GET_LEADS_LOADING, { loading: false, data }));
        });
}

export function actionGetRMClients(params) {
    return (dispatch, getState) =>
        Api.post(API_RM_GET_CLIENTS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RM_GET_CLIENTS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RM_GET_CLIENTS_LOADING, { loading: false, data }));
        });
}

export function actionRmTransfer(params) {
    return (dispatch, getState) =>
        Api.post(API_RM_TRANSFER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RM_TRANSFER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RM_TRANSFER_LOADING, { loading: false, data }));
        });
}