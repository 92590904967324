import { ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS } from './action';
import createReducer from '../../../../../../../reducers/createReducer';
    
const INITIAL_STATE = {
    data: [],
    loading: false
};
    
const InsurerTabContactPersonReducer = createReducer(INITIAL_STATE,{
    [ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS,
            loading: false
        });
    },    
});
export default InsurerTabContactPersonReducer;
    