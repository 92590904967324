import createReducer from "../../../reducers/createReducer";
import { ACTION_ADD_FEEDBACK_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const FeedbackAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_ADD_FEEDBACK_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_FEEDBACK_SUCCESS,
                loading: false,
            }
        );
    },
});
export default FeedbackAddReducer;
