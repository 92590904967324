import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { TagBox } from 'devextreme-react';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import ArrayStore from 'devextreme/data/array_store';
import { actionGeneralPlacementAdd, ACTION_GENERAL_PREFERENCE_ADD_SUCCESS } from './action';
import { ACTION_GET_DEPARTMENT_SUCCESS, actionGetDepartment } from "./action";
import { ACTION_GET_POLICY_TYPE_SUCCESS, actionGetPolicyType } from "./action";
import { ACTION_GET_INSURER_SUCCESS, actionGetCompanyList } from "./action";
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import $ from 'jquery';
import { toast } from "react-toastify";

const GeneralPlacementAdd = (props) => {

    const { handleSubmit, control, formState: { errors }, register, setValue } = useForm();
    const [selectedDepartmentValue, setSelectedDepartmentValue] = useState(null)
    const [selectedPolicyType, setSelectedPolicyType] = useState([])
    const [selectedContactPerson, setSelectedContactPerson] = useState(null)
    const [insurerList, setInsurerList] = useState([])
    const [policyList, setPolicyList] = useState([])
    const [departmentList, setDepartmentList] = useState([]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGetDepartment({ status: "ACTIVE", "eligible_only": "yes" });
        props.actionGetCompanyList({ status: "ACTIVE" })
    }
    useEffect(initialRender, []);

    const [contactPerson, setContactPerson] = useState([
        {
            id: '1',
            name: 'Abbasali Dhoondia'
        },
        {
            id: '2',
            name: 'Ajay Gupta'
        }
    ])
    const onAddGeneralPreferenceUpdate = () => {
        const { AddGeneralPreference } = props
        if (AddGeneralPreference != null) {
            var AddGeneralPreferenceResponse = Object.assign({}, AddGeneralPreference);
            $("#save_general_placement").prop("disabled", false);
            if (AddGeneralPreferenceResponse.result === true) {
                $("#general_placement-form-loader").addClass("hidden");
                $("#progress-loader").addClass("hidden");
                props.history.goBack();
            } else {
                $("#general_placement-form-loader").addClass("hidden");
                toast.error(AddGeneralPreferenceResponse.message);
                $("#progress-loader").addClass("hidden");
                switch (AddGeneralPreferenceResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        toast.error(AddGeneralPreferenceResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onAddGeneralPreferenceUpdate, [props.AddGeneralPreference])

    const onMasterGetDepartmentUpdate = () => {
        const { MasterGetDepartment } = props
        if (MasterGetDepartment != null) {
            var MasterGetDepartmentResponse = Object.assign({}, MasterGetDepartment);
            if (MasterGetDepartmentResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setDepartmentList(MasterGetDepartmentResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetDepartmentUpdate, [props.MasterGetDepartment])

    const onMasterGetPolicyListUpdate = () => {
        const { MasterGetPolicyList } = props
        if (MasterGetPolicyList != null) {
            var MasterGetPolicyListResponse = Object.assign({}, MasterGetPolicyList);
            if (MasterGetPolicyListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPolicyList(MasterGetPolicyListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetPolicyListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetPolicyListUpdate, [props.MasterGetPolicyList])

    const onMasterGetInsurerListUpdate = () => {
        const { MasterGetInsurerList } = props
        if (MasterGetInsurerList != null) {
            var MasterGetInsurerListResponse = Object.assign({}, MasterGetInsurerList);
            if (MasterGetInsurerListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setInsurerList(MasterGetInsurerListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetInsurerListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetInsurerListUpdate, [props.MasterGetInsurerList])

    const onSubmit = (data) => {
        $("#general_placement-form-loader").removeClass("hidden");
        $("#save_general_placement").prop("disabled", true);
        props.actionGeneralPlacementAdd({
            'insurer_id': data.insurer_id,
            'policy_type_id': selectedPolicyType,
            'department_id': data.department_id,
            'rating': data.rating
        })
    }

    return (
        <>
            <ScreenTitle title="Add General Preference" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Insurance Company&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="insurer_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="insurer_id"
                                                                name="insurer_id"
                                                                dataSource={insurerList}
                                                                allowFiltering={true}
                                                                sortOrder={"Ascending"}
                                                                filterType={"Contains"}
                                                                placeholder="Select Company name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Company name is required'
                                                        }}
                                                    />

                                                    {errors.insurer_id && <ErrorLabel message={errors.insurer_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Department</label>
                                                    <Controller
                                                        name="department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="department_id"
                                                                name="department_id"
                                                                dataSource={departmentList}
                                                                allowFiltering={false}
                                                                placeholder="Select Department"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectedDepartmentValue(event.itemData.id)
                                                                        setSelectedPolicyType([]);
                                                                        setValue('policy_type_id', null);
                                                                        props.actionGetPolicyType({ 'department_id': event.itemData.id, status: "ACTIVE", "eligible_only": "yes" });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group cust-tagbox">
                                                    <label className="label-control">Type of Policy&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="policy_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                id="policy_type_id"
                                                                name="policy_type_id"
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: policyList,
                                                                        key: 'id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        ('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        ('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        ('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        ('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="name"
                                                                valueExpr="id"
                                                                placeholder="Select Policy Type"
                                                                searchEnabled={true}
                                                                filterType={"Contains"}
                                                                maxDisplayedTags={2}
                                                                selectAllMode={true}
                                                                showSelectionControls={true}
                                                                acceptCustomValue={false}
                                                                showMultiTagOnly={false}
                                                                disabled={selectedDepartmentValue != null ? false : true}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value != null && field.value.length > 0 ? field.value : selectedPolicyType}
                                                                onValueChanged={({ value, event }) => {
                                                                    if ((value != null || value !== undefined) && value.length > 0) {
                                                                        setSelectedPolicyType(value);
                                                                        field.onChange(value);
                                                                    } else {
                                                                        if (selectedPolicyType != null && selectedPolicyType.length > 0) {
                                                                            field.onChange("");
                                                                            setSelectedPolicyType([])
                                                                        }
                                                                    }
                                                                    if (event != null) {
                                                                        if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                            field.onChange("");
                                                                            setSelectedPolicyType([])
                                                                        }
                                                                    }
                                                                }}
                                                                onSelectionChanged={(e) => {
                                                                    if (e.addedItems.length > 0) {
                                                                        e.component.field().value = "";
                                                                        e.component.close();
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Policy Type'
                                                        }}
                                                    />
                                                    {errors.policy_type_id && <ErrorLabel message={errors.policy_type_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Preference Rating&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="rating"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="rating"
                                                                name="rating"
                                                                dataSource={[
                                                                    {
                                                                        value: 1,
                                                                        text: '1'

                                                                    },
                                                                    {
                                                                        value: 2,
                                                                        text: '2'
                                                                    },
                                                                    {
                                                                        value: 3,
                                                                        text: '3'

                                                                    },
                                                                    {
                                                                        value: 4,
                                                                        text: '4'
                                                                    },
                                                                    {
                                                                        value: 5,
                                                                        text: '5'

                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Preference"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Preference Rating'
                                                        }}
                                                    />
                                                    {errors.rating && <ErrorLabel message={errors.rating?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_general_placement"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="general_placement-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var AddGeneralPreference = null;
    var MasterGetDepartment = null;
    var MasterGetPolicyList = null;
    var MasterGetInsurerList = null;
    if (
        state.GeneralPreferenceAddReducer != null &&
        Object.keys(state.GeneralPreferenceAddReducer).length > 0 &&
        state.GeneralPreferenceAddReducer.constructor === Object
    ) {
        switch (state.GeneralPreferenceAddReducer.tag) {
            case ACTION_GENERAL_PREFERENCE_ADD_SUCCESS:
                AddGeneralPreference = Object.assign({}, state.GeneralPreferenceAddReducer.data);
                delete state.GeneralPreferenceAddReducer;
                return { AddGeneralPreference };
            case ACTION_GET_DEPARTMENT_SUCCESS:
                MasterGetDepartment = Object.assign({}, state.GeneralPreferenceAddReducer.data);
                delete state.GeneralPreferenceAddReducer;
                return { MasterGetDepartment };
            case ACTION_GET_POLICY_TYPE_SUCCESS:
                MasterGetPolicyList = Object.assign({}, state.GeneralPreferenceAddReducer.data);
                delete state.GeneralPreferenceAddReducer;
                return { MasterGetPolicyList };
            case ACTION_GET_INSURER_SUCCESS:
                MasterGetInsurerList = Object.assign({}, state.GeneralPreferenceAddReducer.data);
                delete state.GeneralPreferenceAddReducer;
                return { MasterGetInsurerList };
            default:

        }
    }
    return {
        AddGeneralPreference,
        MasterGetDepartment,
        MasterGetPolicyList,
        MasterGetInsurerList,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGeneralPlacementAdd, actionGetDepartment, actionGetPolicyType, actionGetCompanyList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GeneralPlacementAdd))
