import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import Calendar from 'devextreme-react/calendar';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { devXDisableWeekend } from '../../../../../../utils';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import dateFormat from 'dateformat';
import { actionGetAttendance, ACTION_GET_ATTENDANCE_SUCCESS } from './action';
import { actionRegularizeRequestApprovalManager, ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS } from './action';
import { actionRegularizeRequestAdd, ACTION_REGULARIZE_REQUEST_ADD_SUCCESS } from './action';
import Swal from 'sweetalert2';

const RegularizeAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [minFromDate, setMinFromDate] = useState(null);
    const [maxFromDate, setmaxFromDate] = useState(null);
    const [minToDate, setMinToDate] = useState(null);
    const [maxToDate, setmaxToDate] = useState(null);
    const [getAttendance, setGetAttendance] = useState([]);
    const [toDate, setToDate] = useState();
    const [oldStatus, setOldStatus] = useState('');
    const [regularizeRequestApprovalManager, setRegularizeRequestApprovalManager] = useState();
    const [reason, setReason] = useState();
    const [weekenddays, setWeekendDays] = useState(null);
    const [newStatusData, setNewStatusData] = useState([
        {
            value: 'standard_leave',
            text: 'Standard Leave'
        },
        {
            value: 'maternity_leave',
            text: 'Maternity Leave'
        },
        {
            value: 'paternity_leave',
            text: 'Paternity Leave'
        },
        {
            value: 'absent',
            text: 'Absent/Other'
        },
        {
            value: 'present',
            text: 'Present'
        },
    ]);

    const [newStatusFinalData, setStatusFinalData] = useState();
    const [selectStatus, setSelectStatus] = useState(null);
    const [holidayDetails, setHolidayDetails] = useState([]);

    const initialRender = () => {
        setStatusFinalData(newStatusData);

        props.actionGetAttendance({});
        props.actionRegularizeRequestApprovalManager({});
        var date = new Date();
        setMinFromDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
        setmaxFromDate(new Date(date.getFullYear(), date.getMonth(), 0));

        setMinToDate(new Date(date.getFullYear(), date.getMonth(), 1));
        setmaxToDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    useEffect(initialRender, [])

    useEffect(() => {
        if (getAttendance != null && getAttendance.length > 0) {
            getAttendance.forEach((attendance, index) => {
                if (attendance.status === 'NM' || attendance.status === 'AB') {

                    var arrDate = attendance.date.split("-");
                    var date = new Date();

                    if (Number(arrDate[1]) === date.getMonth()) {
                        $(".previous-month").find("#" + attendance.date).css("color", "#3030FF");
                        $(".previous-month").find("#" + attendance.date).parent().css("background-color", "#FF8080");

                    } else {
                        $(".current-month").find("#" + attendance.date).css("color", "#3030FF");
                        $(".current-month").find("#" + attendance.date).parent().css("background-color", "#FF8080");
                    }
                }
                $(".previous-month").find("#" + attendance.date).parent().addClass(attendance.date);
                $(".current-month").find("#" + attendance.date).parent().addClass(attendance.date);


                $("body").on("click", "." + attendance.date, function () {
                    $("#selected_date").val(dateFormat(attendance.date, "dd/mm/yyyy"));
                    setOldStatus('');

                    if (attendance.status === 'M' || attendance.status === 'AMO') {
                        setStatusFinalData([
                            {
                                value: 'standard_leave',
                                text: 'Standard Leave'
                            },
                            {
                                value: 'maternity_leave',
                                text: 'Maternity Leave'
                            },
                            {
                                value: 'paternity_leave',
                                text: 'Paternity Leave'
                            },
                            {
                                value: 'absent',
                                text: 'Absent/Other'
                            }
                        ]);
                    }

                    if (attendance.status === 'NM') {

                        setStatusFinalData([
                            {
                                value: 'standard_leave',
                                text: 'Standard Leave'

                            },
                            {
                                value: 'maternity_leave',
                                text: 'Maternity Leave'
                            },
                            {
                                value: 'paternity_leave',
                                text: 'Paternity Leave'
                            },
                            {
                                value: 'present',
                                text: 'Present'
                            },
                        ]);

                        setOldStatus("Absent");
                    } else {
                        setOldStatus(attendance.status_name);
                    }
                });

                console.log($(".dx-calendar-cell").attr("data-value"));
            })
        }

        $("body").on("click", ".dx-calendar-cell", function () {
            if (!holidayDetail) {
                $("#selected_date").val(dateFormat($(this).attr("data-value"), "dd/mm/yyyy"));
            }
        });

    }, [getAttendance])

    const onGetAttendanceUpdate = () => {
        const { GetAttendance } = props;
        var GetAttendanceResponse = Object.assign({}, GetAttendance);
        if (GetAttendanceResponse.result === true) {
            setWeekendDays(GetAttendanceResponse.response.working_days)
            global.working_days = GetAttendanceResponse.response.working_days
            setGetAttendance(GetAttendanceResponse.response.attendance);
            setHolidayDetails(GetAttendanceResponse.response.holiday);
        } else {
            switch (GetAttendanceResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetAttendanceUpdate, [props.GetAttendance]);

    const onRegularizeRequestApprovalManagerUpdate = () => {
        const { RegularizeRequestApprovalManager } = props;
        var RegularizeRequestApprovalManagerResponse = Object.assign({}, RegularizeRequestApprovalManager);
        if (RegularizeRequestApprovalManagerResponse.result === true) {
            setRegularizeRequestApprovalManager(RegularizeRequestApprovalManagerResponse.response.managers);
        } else {
            switch (RegularizeRequestApprovalManagerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onRegularizeRequestApprovalManagerUpdate, [props.RegularizeRequestApprovalManager]);

    const onRegularizeRequestSave = () => {
        const { RegularizeRequestAdd } = props;
        var RegularizeRequestAddResponse = Object.assign({}, RegularizeRequestAdd);
        $("#save-regularize-request").prop("disabled", false);
        if (RegularizeRequestAddResponse.result === true) {
            // $("#progress-loader").addClass("hidden");
            $("#regularize-request-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Regularize Request Added Successfully");
            props.history.goBack();
        } else {
            // $("#progress-loader").addClass("hidden");
            $("#regularize-request-loader").addClass("hidden");
            switch (RegularizeRequestAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    Swal.fire({
                        title: 'Regularize request error',
                        text: RegularizeRequestAddResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break;
                default:
            }
        }
    }
    useEffect(onRegularizeRequestSave, [props.RegularizeRequestAdd]);

    const onSubmit = (data) => {
        data.user_id = props.auth.getUser().user_id;
        data.to_date = toDate;
        data.approval_manager_id = regularizeRequestApprovalManager.attendance_manager_id;
        data.type = 'regularize';
        data.old_status = _.lowerCase(oldStatus);
        // $("#progress-loader").removeClass("hidden");
        $("#regularize-request-loader").removeClass("hidden");
        $("#save-regularize-request").prop("disabled", true);

        props.actionRegularizeRequestAdd(data);

    }

    const isWeekend = (d) => {
        const day = d.getDay();
        return day === 0;

    }

    // const devXDisableWeekend = (args) => {
    //     const dayOfWeek = args.date.getDay();
    //     const isWeekend = (dayOfWeek === 0 || (dayOfWeek === 6 && weekenddays === 5));
    //     return isWeekend;

    // }

    const holiday = (cell) => {
        holidayDetails.map((item) => {
            if (dateFormat(cell.date, "yyyy-mm-dd") === item.date) {
                return 0;
            }
        })
    }

    const holidayDetail = (cell) => {
        if (isWeekend(cell.date)) {
            return true
        } else if (holiday(cell.date)) {
            return true
        } else {
            return false
        }
    }

    const renderCalenderCell = (cell) => {
        let cssClass = '';

        if (cell.view === 'month') {
            if (!cell.date) {
                cssClass = 'week-number';
            } else {
                if (isWeekend(cell.date)) { cssClass = 'weekend'; }

                $.each(holidayDetails, (_, item) => {
                    if (dateFormat(cell.date, "yyyy-mm-dd") === item.date) {
                        cssClass = 'dx-calendar-cell-empty';
                        return false;
                    }
                    return true;
                });
            }
        }

        return <span id={dateFormat(cell.date, "yyyy-mm-dd")} className={cssClass}>{cell.text}</span>;
    }

    const removeSelectedValue = () => {
        $(".dx-calendar-cell").each(function (index) {
            if ($(this).hasClass("dx-calendar-selected-date")) {
                $(this).removeClass("dx-calendar-selected-date")
            }
        });
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Raise Regularize Request</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="d-flex justify-content-center previous-month">
                                                    <Controller
                                                        name="from_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <Calendar
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                id="from_date"
                                                                name="from_date"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                disabledDates={devXDisableWeekend}
                                                                cellRender={renderCalenderCell}
                                                                min={minFromDate}
                                                                max={maxFromDate}
                                                                onValueChange={(value) => {
                                                                    removeSelectedValue()
                                                                    setOldStatus('')
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                    $("#error-selected-date").addClass('d-none')
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="d-flex justify-content-center current-month">
                                                    <Controller
                                                        name="to_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <Calendar
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                id="to_date"
                                                                name="to_date"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                disabledDates={devXDisableWeekend}
                                                                cellRender={renderCalenderCell}
                                                                min={minToDate}
                                                                max={maxToDate}
                                                                onValueChange={(value) => {
                                                                    removeSelectedValue()
                                                                    setOldStatus('')
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                    $("#error-selected-date").addClass('d-none')
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Regularize Request Manager</label>
                                                    <input
                                                        className="form-control"
                                                        id="regularize_request_manager"
                                                        name="regularize_request_manager"
                                                        placeholder="Enter Regularize Request Manager"
                                                        disabled={true}
                                                        value={(regularizeRequestApprovalManager != null && regularizeRequestApprovalManager.attendance_manager_name != null) ? regularizeRequestApprovalManager.attendance_manager_name : ''}
                                                        {...register('regularize_request_manager')}
                                                    />
                                                    {errors.regularize_request_manager?.type === 'required' && <ErrorLabel message={errors.regularize_request_manager?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Selected Date<span class="text-danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="selected_date"
                                                        name="selected_date"
                                                        placeholder=""
                                                        disabled={true}
                                                        {...register('selected_date',
                                                            {
                                                                validate: (value) => {
                                                                    return String(value).trim().length === 0 && toDate === '' || toDate === 'null' || toDate === null || toDate === undefined ? $("#error-selected-date").removeClass('d-none') : true
                                                                }
                                                            }
                                                        )}
                                                    />
                                                    <small className="d-none error-msg text-danger" id="error-selected-date">Please Select Date</small>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Old Status</label>
                                                    <input
                                                        className="form-control"
                                                        value={oldStatus}
                                                        id="old_status"
                                                        name="old_status"
                                                        placeholder="Enter Old Status"
                                                        disabled={true}
                                                        {...register('old_status')}
                                                    />
                                                    {errors.old_status?.type === 'required' && <ErrorLabel message={errors.old_status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">New Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="regularize_to"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="regularize_to"
                                                                name="regularize_to"
                                                                dataSource={newStatusFinalData}
                                                                allowFiltering={false}
                                                                placeholder="Select New Status"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value);
                                                                        setSelectStatus(event.itemData.value);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'New Status is required'
                                                        }}
                                                    />

                                                    {errors.regularize_to && <ErrorLabel message={errors.regularize_to?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectStatus != null && selectStatus === 'present' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Reason&nbsp;<span className="danger">*</span></label>
                                                            <Controller
                                                                name="reason"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="reason"
                                                                        name="reason"
                                                                        dataSource={[
                                                                            {
                                                                                title: "Outdoor Duty",
                                                                                id: "Outdoor Duty",
                                                                            },
                                                                            {
                                                                                title: "Work from Home",
                                                                                id: "Work from Home",
                                                                            },
                                                                            {
                                                                                title: "Forgot to Mark Attendance",
                                                                                id: "Forgot to Mark Attendance",
                                                                            },
                                                                            {
                                                                                title: "Others",
                                                                                id: "Others",
                                                                            },
                                                                        ]}
                                                                        fields={{ text: "title", value: "id" }}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Reason"
                                                                        value={field.value}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.id)
                                                                                setReason(event.itemData.id)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                rules={{
                                                                    required: 'Please Select Reason'
                                                                }}
                                                            />
                                                            {errors.reason && <ErrorLabel message={errors.reason?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            <div className='col-12 col-md-6 col-lg-6'>
                                                <div className="form-group">
                                                </div>
                                            </div>
                                            <div className={`col-12 col-md-6 col-lg-6 ${reason === "Others" ? '' : 'hidden'}`}>
                                                <div className="form-group">
                                                    <label className="label-control">Remark&nbsp;
                                                        <span className={`text-danger ${reason === "Others" ? '' : 'hide'}`}>*</span>
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        rows={3}
                                                        placeholder="Add Remark"
                                                        {...register('remark', {
                                                            validate: (value) => {
                                                                return String(value).trim().length === 0 && (reason === 'Others') ? 'Remark is required' : true
                                                            }
                                                        }
                                                        )}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save-regularize-request"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="regularize-request-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var GetAttendance = null;
    var RegularizeRequestApprovalManager = null;
    var RegularizeRequestAdd = null;

    if (
        state.AddRegularizeReducer != null &&
        Object.keys(state.AddRegularizeReducer).length > 0 &&
        state.AddRegularizeReducer.constructor === Object
    ) {
        switch (state.AddRegularizeReducer.tag) {
            case ACTION_GET_ATTENDANCE_SUCCESS:
                GetAttendance = Object.assign({}, state.AddRegularizeReducer.data);
                delete state.AddRegularizeReducer;
                return { GetAttendance };
            case ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS:
                RegularizeRequestApprovalManager = Object.assign({}, state.AddRegularizeReducer.data);
                delete state.AddRegularizeReducer;
                return { RegularizeRequestApprovalManager };
            case ACTION_REGULARIZE_REQUEST_ADD_SUCCESS:
                RegularizeRequestAdd = Object.assign({}, state.AddRegularizeReducer.data);
                delete state.AddRegularizeReducer;
                return { RegularizeRequestAdd };
            default:
        }
    }

    return {
        GetAttendance,
        RegularizeRequestApprovalManager,
        RegularizeRequestAdd
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGetAttendance, actionRegularizeRequestApprovalManager, actionRegularizeRequestAdd }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RegularizeAdd))