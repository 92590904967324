import { ACTION_IT_DECLARATION_LIST_SUCCESS, ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ItDeclarationListReducer = createReducer(INITIAL_STATE, {
    [ACTION_IT_DECLARATION_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_IT_DECLARATION_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS,
                loading: false,
            }
        );
    }
});

export default ItDeclarationListReducer;