import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
  setData,
} from "../../../../../../utils/preferences";
import { actionTravelCalendarSettingUpdate,   ACTION_TRAVEL_CALENDAR_SETTING_UPDATE_SUCCESS } from "./action";
import { actionGetTravelCalendarEmpList, ACTION_GET_TRAVEL_CALENDAR_EMP_LIST_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../utils";
import { Controller, useForm } from "react-hook-form";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TagBox } from "devextreme-react";
import ErrorLabel from "../../../../../../component/form/error_label";
import Swal from "sweetalert2";

const TravelCalendarSettingUpdate = (props) => {
  const { match } = props;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [rICalendar, setRiCalendar] = useState(0);
  const [directCalendar, setDirectCalendar] = useState(0);
  const [listView, setListView] = useState(0);
  const [getEmployeeList, SetGetEmployeeList] = useState([]);

  const initialRender = () => {
    $("#progress-loader").removeClass("hidden");
      props.actionGetTravelCalendarEmpList()
      if (getData(KEY_TOAST_MESSAGE) != null) {
          toast.success(getData(KEY_TOAST_MESSAGE));
          removeData(KEY_TOAST_MESSAGE);
      }
      props?.location?.state?.data?.ri_calendar == 1 ? setRiCalendar(1) : setRiCalendar(0)
      props?.location?.state?.data?.direct_calendar == 1 ? setDirectCalendar(1) : setDirectCalendar(0)
      props?.location?.state?.data?.list_view == 1 ? setListView(1) : setListView(0)
  }
  useEffect(initialRender, []);

  const onGetEmployeeListUpdate = () => {
    const { GetEmployeeList } = props;
    var GetEmployeeListResponse = Object.assign({}, GetEmployeeList);
    if (GetEmployeeListResponse.result === true) {
      $("#progress-loader").addClass("hidden");
        SetGetEmployeeList(GetEmployeeListResponse.response.users);
        setValue("user_id", props?.location?.state?.data?.user_id);
    } else {
      $("#progress-loader").addClass("hidden");
      switch (GetEmployeeListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onGetEmployeeListUpdate, [props.GetEmployeeList]);

  const onTravelCalendarSettingUpdateSuccess = () => {
    const { TravelCalendarSettingUpdate } = props;
    const TravelCalendarSettingUpdateResponse = Object.assign({}, TravelCalendarSettingUpdate);
    if (TravelCalendarSettingUpdateResponse?.result == true) {
      $("#progress-loader").addClass("hidden");
      $("#travel_calendar_setting_update-loader").addClass("hidden");
      $("#travel_calendar_setting_update").prop("disabled", false);
      toast.success(TravelCalendarSettingUpdateResponse?.message);
         props.history.goBack();
    } else {
      $("#progress-loader").addClass("hidden");
      $("#travel_calendar_setting_update-loader").addClass("hidden");
      $("#travel_calendar_setting_update").prop("disabled", false);
      switch (TravelCalendarSettingUpdateResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelCalendarSettingUpdateSuccess, [props.TravelCalendarSettingUpdate]);

  const onSubmit = (data) => {
    if(rICalendar||directCalendar||listView){
    data.ri_calendar= rICalendar
    data.direct_calendar=directCalendar
    data.list_view=listView
    if(props?.location?.state?.data ){
      $("#travel_calendar_setting_update-loader").removeClass("hidden");
      $("#travel_calendar_setting_update").prop("disabled", true);
      $("#progress-loader").removeClass("hidden");
        data.id = props?.location?.state?.data?.id
        props.actionTravelCalendarSettingUpdate({ dynamic_url: "edit", ...data });
    }else{
      $("#travel_calendar_setting_update-loader").removeClass("hidden");
      $("#travel_calendar_setting_update").prop("disabled", true);
      $("#progress-loader").removeClass("hidden");
      props.actionTravelCalendarSettingUpdate({ dynamic_url: "add", ...data });
    }
  }else{
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Add at least one permission',
      confirmButtonText: 'Okay',
    });
  }
  };

  return (
    <>
      <div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-0">
              Travel Settings {props?.location?.state?.data ? "Edit" : "Add"}
            </h5>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <form
                  noValidate
                  autoComplete="Off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="label-control">Employee List</label>
                        <Controller
                          name="user_id"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                            {...field}
                            id="user_id"
                            name="user_id"
                            dataSource={getEmployeeList}
                            allowFiltering={false}
                            placeholder="Select Employees"
                            value={field.value}
                            fields={{ text: "name", value: "id" }}
                            change={(event) => {
                              if (event.itemData != null) {
                                field.onChange(event.itemData.id);
                              }
                            }}
                          />
                          )}
                          rules={{
                            required: "Please select Employee",
                          }}
                        />
                        {errors.user_id && (
                          <ErrorLabel message={errors.user_id?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="col-12 col-md-4">
                        <div className="form-group mr-1">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="ri_calendar"
                              id="ri_calendar"
                              defaultChecked={rICalendar === 1 ? true : false}
                              checked={rICalendar === 1 ? true : false}
                              {...register("ri_calendar")}
                              onChange={(event) => {
                                if (event.target.checked === true) {
                                    setRiCalendar(1);
                                }else{
                                    setRiCalendar(0);
                                }
                              }}
                            />
                            <label
                              class="custom-control-label"
                              for="ri_calendar"
                            >
                              RI Calendar
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group mr-1">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="direct_calendar"
                              id="direct_calendar"
                              defaultChecked={directCalendar === 1 ? true : false}
                              checked={directCalendar === 1 ? true : false}
                              {...register("direct_calendar")}
                              onChange={(event) => {
                                if (event.target.checked === true) {
                                  setDirectCalendar(1);
                                }else{
                                    setDirectCalendar(0);
                                }
                              }}
                            />
                            <label
                              class="custom-control-label"
                              for="direct_calendar"
                            >
                              Direct Calendar
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group mr-1">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="list_view"
                              id="list_view"
                              defaultChecked={listView === 1 ? true : false}
                              checked={listView === 1 ? true : false}
                              {...register("list_view")}
                              onChange={(event) => {
                                if (event.target.checked === true) {
                                  setListView(1);
                                }else{
                                    setListView(0);
                                }
                              }}
                            />
                            <label
                              class="custom-control-label"
                              for="list_view"
                            >
                              List View
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-actions border-0 float-none ">
                    <button
                      id="travel_calendar_setting_update"
                      className="btn btn-primary mr-1 shadow round text-uppercase"
                      type="submit"
                    >
                      <div className="d-flex align-items-center">
                        Submit
                        <span
                          id="travel_calendar_setting_update-loader"
                          className="spinner-border spinner-border-sm ml-half hidden"
                        ></span>
                      </div>
                    </button>
                    <button
                      className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                      onClick={(event) => {
                        event.preventDefault();
                        props.history.goBack();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  var TravelCalendarSettingUpdate = null;
  var GetEmployeeList = null;
  if (
      state.CalendarTravelSettingUpdateReducer != null &&
      Object.keys(state.CalendarTravelSettingUpdateReducer).length > 0 &&
      state.CalendarTravelSettingUpdateReducer.constructor === Object
  ) {
      switch (state.CalendarTravelSettingUpdateReducer.tag) {
          case ACTION_TRAVEL_CALENDAR_SETTING_UPDATE_SUCCESS:
              TravelCalendarSettingUpdate = Object.assign({}, state.CalendarTravelSettingUpdateReducer.data);
              delete state.CalendarTravelSettingUpdateReducer;
              return { TravelCalendarSettingUpdate };
          case ACTION_GET_TRAVEL_CALENDAR_EMP_LIST_SUCCESS:
              GetEmployeeList = Object.assign({}, state.CalendarTravelSettingUpdateReducer.data);
              delete state.CalendarTravelSettingUpdateReducer;
              return { GetEmployeeList };
          default:
      }
  }
  return {
      TravelCalendarSettingUpdate,
      GetEmployeeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      actionTravelCalendarSettingUpdate,
      actionGetTravelCalendarEmpList,
  }, dispatch)
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(TravelCalendarSettingUpdate)
);
