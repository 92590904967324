import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_RM_TRANSFER_DASHBOARD } from '../../../../../../api/constants';

export const ACTION_RM_TRANSFER_DASHBOARD_LOADING = 'ACTION_RM_TRANSFER_DASHBOARD_LOADING';
export const ACTION_RM_TRANSFER_DASHBOARD_SUCCESS = 'ACTION_RM_TRANSFER_DASHBOARD_SUCCESS';

export function actionRmTransferDashboard() {
    return (dispatch, getState) =>
        Api.get(API_RM_TRANSFER_DASHBOARD).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RM_TRANSFER_DASHBOARD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RM_TRANSFER_DASHBOARD_LOADING, { loading: false, data }));
        });
}