import Api from "../../../../../../../api"
import { fetchSuccess, loading } from "../../../../../../../utils/action.util";
import { API_RISK_CHANGE_STATUS } from "../../../../../../../api/constants";

export const ACTION_RISK_CHANGE_STATUS_LOADING = 'ACTION_RISK_CHANGE_STATUS_LOADING';
export const ACTION_RISK_CHANGE_STATUS_SUCCESS = 'ACTION_RISK_CHANGE_STATUS_SUCCESS';

export function actionRiskChangeStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_CHANGE_STATUS, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_CHANGE_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_CHANGE_STATUS_LOADING, { loading: false, data }));
        });
}