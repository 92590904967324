import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionGroupLevelContact, ACTION_GROUP_LEVEL_CONTACT_SUCCESS } from './action';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';
import { verifyPermission } from '../../../../../../../utils';

const GroupContacts = (props) => {
    const [groupContactData, setGroupContactData] = useState([]);


    const [pincContactsColumn, setPincContactsColumn] = useState([
        { dataField: "contact_name", caption: "Contact Name" },
        { dataField: "contact_designation", caption: "Contact Designation" },
        { dataField: "contact_phone", caption: "Contact Mobile " },
        { dataField: "contact_email", caption: "Contact Email" },
        { dataField: "contact_type", caption: "Contact Type" },
        { dataField: "company_name", caption: "Company Name" },
        { dataField: "updated_at", caption: "Last Modified Date" },
        { dataField: "updated_by", caption: "Last Modified By" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGroupLevelContact({
            client_group_id: props.clientGroupId,
        })
    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupPincContactsUpdate = () => {

        const { GroupPincContacts } = props;

        if (GroupPincContacts != null) {
            var GroupPincContactsResponse = Object.assign({}, GroupPincContacts);
            if (GroupPincContactsResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPincContactsColumn([
                    { dataField: "contact_name", caption: "Contact Name" },
                    { dataField: "contact_designation", caption: "Contact Designation" },
                    { dataField: "contact_phone", caption: "Contact Mobile " },
                    { dataField: "contact_email", caption: "Contact Email" },
                    { dataField: "contact_type", caption: "Contact Type" },
                    { dataField: "company_name", caption: "Company Name" },
                    { dataField: "updated_at", caption: "Last Modified Date" },
                    { dataField: "updated_by", caption: "Last Modified By" },
                ])
                setGroupContactData(GroupPincContactsResponse.response);

            } else {
                setGroupContactData([])
                $("#progress-loader").addClass("hidden");
                switch (GroupPincContactsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }

            }
        }
    }

    useEffect(onGroupPincContactsUpdate, [props.GroupPincContacts]);

    const renderPincContactColumn = () => {
        var arrColumns = [];
        pincContactsColumn.forEach((objColumn, indexColumn) => {
            arrColumns.push(
                <Column
                    dataField={objColumn.dataField}
                    caption={objColumn.caption}
                ></Column>
            );
        });
        return arrColumns;
    };
    const renderPincContactsTableView = () => {
        return (
            <DataGrid
                dataSource={groupContactData}
                showBorders={true}
                allowColumnResizing={true}
                // columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                // columnHidingEnabled={true}
                export={{
                    fileName: "Group Contact",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderPincContactColumn()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />

            </DataGrid>
        )
    }
    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    groupContactData != null && groupContactData.length > 0 ?
                                        renderPincContactsTableView() :
                                        <h6 className="text-bold-500 text-center">No Contact Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}
function mapStateToProps(state) {

    var GroupPincContacts = null;

    if (
        state.TabGroupPincContactReducer != null &&
        Object.keys(state.TabGroupPincContactReducer).length > 0 &&
        state.TabGroupPincContactReducer.constructor === Object
    ) {
        switch (state.TabGroupPincContactReducer.tag) {

            case ACTION_GROUP_LEVEL_CONTACT_SUCCESS:
                GroupPincContacts = Object.assign({}, state.TabGroupPincContactReducer.data);
                delete state.TabGroupPincContactReducer;
                return { GroupPincContacts };
            default:
                return { GroupPincContacts }
        }
    }
    return { GroupPincContacts }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupLevelContact }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupContacts));