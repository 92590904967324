import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { useForm, Controller } from "react-hook-form";
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import ScreenTitle from '../../../../../component/screen_title';
import ErrorLabel from '../../../../../component/form/error_label';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import Swal from 'sweetalert2';
import moment from "moment";
import { FiEdit, FiEye } from 'react-icons/fi';
import DataGrid, {
   Button,
   Column,
   FilterRow,
   HeaderFilter,
   Pager,
   Paging,
   SearchPanel,
} from "devextreme-react/data-grid";
import { HiOutlineDocumentAdd, HiOutlineDocumentSearch } from 'react-icons/hi';
import {  ERROR, UNAUTHORIZED } from '../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { actionEditClaimPolicyDetails, ACTION_CLAIM_POLICY_DETAILS_SUCCESS } from './action';
import { actionClaimPolicyType, ACTION_EDIT_CLAIMS_POLICY_TYPE_SUCCESS } from './action';
import { actionNatureOfLoss, ACTION_EDIT_NATURE_OF_LOSS_SUCCESS } from './action';
import { actionClaimType, ACTION_EDIT_CLAIM_TYPE_SUCCESS } from './action';
import { actionClaimStatus, ACTION_EDIT_CLAIM_STATUS_SUCCESS } from './action';
import { actionEditClaimsView, ACTION_EDIT_CLAIMS_VIEW_SUCCESS } from './action';
import { actionClaimsEdit, ACTION_CLAIMS_EDIT_SUCCESS } from './action';
import { actionEditClaimsEventType, ACTION_EDIT_CLAIM_EVENT_TYPE_SUCCESS } from './action';
import { actionEditClaimsEventDetails, ACTION_CLAIM_EDIT_EVENT_DETAILS_SUCCESS } from './action';
import { actionGetEditClaimsEventDetails, ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_SUCCESS } from './action';
import { actionClaimHandler, ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_SUCCESS } from './action';
import { toast } from 'react-toastify';

const EditClaim = (props) => {

   const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
   const {
      register: registerEvent,
      handleSubmit: handleSubmitEvent,
      control: controlEvent,
      formState: {
         errors: errorsEvent
      },
      setValue: setValueEvent,
      watch: watchEvent
   } = useForm();

   const watchEventClaimSettledGrossLossAmount = watchEvent("claim_settled.claim_settled_gross_loss_amount");
   const watchEventClaimSettledDepreciationAmount = watchEvent("claim_settled.claim_settled_depreciation_amount");
   const watchEventClaimSettledSalvageAmount = watchEvent("claim_settled.claim_settled_salvage_amount");

   const watchEventClaimSettledLossNetOfSalvageAmount = watchEvent("claim_settled.claim_settled_loss_net_of_salvage_amount");

   const watchEventClaimSettledUnderInsurance = watchEvent("claim_settled.claim_settled_under_insurance")
   const watchEventClaimSettledPolicyExcess = watchEvent("claim_settled.claim_settled_policy_excess")

   const watchEventClaimSettledRIP = watchEvent("claim_settled.claim_settled_rip_amount");

   const watchEventClaimSettledNetAssessedLossAmount = watchEvent("claim_settled.claim_settled_net_assessed_loss_amount");


   const watchEventClaimSettledNonStandardDeductionAmount = watchEvent("claim_settled.claim_settled_non_standard_deduction_amount")

   const watchEventClaimSettledAdjustedAmount = watchEvent("claim_settled.claim_settled_adjusted_amount");

   useEffect(() => {

      console.log("watchEventClaimSettledGrossLossAmount : ", watchEventClaimSettledGrossLossAmount)
      console.log("watchEventClaimSettledDepreciationAmount : ", watchEventClaimSettledDepreciationAmount)
      console.log("watchEventClaimSettledSalvageAmount : ", watchEventClaimSettledSalvageAmount)
      console.log("watchEventClaimSettledLossNetOfSalvageAmount : ", watchEventClaimSettledLossNetOfSalvageAmount)
      console.log("watchEventClaimSettledUnderInsurance : ", watchEventClaimSettledUnderInsurance)
      console.log("watchEventClaimSettledPolicyExcess : ", watchEventClaimSettledPolicyExcess)
      console.log("watchEventClaimSettledRIP : ", watchEventClaimSettledRIP)
      console.log("watchEventClaimSettledNetAssessedLossAmount : ", watchEventClaimSettledNetAssessedLossAmount)
      console.log("watchEventClaimSettledNonStandardDeductionAmount : ", watchEventClaimSettledNonStandardDeductionAmount)
      console.log("watchEventClaimSettledAdjustedAmount : ", watchEventClaimSettledAdjustedAmount)


      if (
         Number(watchEventClaimSettledGrossLossAmount) > 0 &&
         Number(watchEventClaimSettledDepreciationAmount) > 0 &&
         Number(watchEventClaimSettledSalvageAmount) > 0
      ) {

         setValueEvent('claim_settled.claim_settled_loss_net_of_salvage_amount',
            Number(
               Number(watchEventClaimSettledGrossLossAmount) -
               Number(watchEventClaimSettledDepreciationAmount) -
               Number(watchEventClaimSettledSalvageAmount)
            ).toFixed(2)
         );
      }

      if (
         Number(watchEventClaimSettledUnderInsurance) > 0 &&
         Number(watchEventClaimSettledPolicyExcess) > 0 &&
         Number(watchEventClaimSettledLossNetOfSalvageAmount) > 0
      ) {
         setValueEvent('claim_settled.claim_settled_net_assessed_loss_amount',
            Number(
               Number(watchEventClaimSettledLossNetOfSalvageAmount) -
               Number(watchEventClaimSettledUnderInsurance) -
               Number(watchEventClaimSettledPolicyExcess)
            ).toFixed(2)
         );
      }

      if (
         Number(watchEventClaimSettledRIP) > 0 &&
         Number(watchEventClaimSettledNetAssessedLossAmount) > 0
      ) {
         setValueEvent('claim_settled.claim_settled_adjusted_amount',
            Number(
               Number(watchEventClaimSettledNetAssessedLossAmount) -
               Number(watchEventClaimSettledRIP)
            ).toFixed(2)
         );
      }

      if (
         Number(watchEventClaimSettledNonStandardDeductionAmount) > 0 &&
         Number(watchEventClaimSettledAdjustedAmount) > 0
      ) {
         setValueEvent('claim_settled.claim_settled_balance_settled_amount',
            Number(
               Number(watchEventClaimSettledAdjustedAmount) -
               Number(watchEventClaimSettledNonStandardDeductionAmount)
            ).toFixed(2)
         );
      }

   }, [
      watchEventClaimSettledGrossLossAmount,
      watchEventClaimSettledDepreciationAmount,
      watchEventClaimSettledSalvageAmount,
      watchEventClaimSettledLossNetOfSalvageAmount,
      watchEventClaimSettledNetAssessedLossAmount,
      watchEventClaimSettledUnderInsurance,
      watchEventClaimSettledPolicyExcess,
      watchEventClaimSettledRIP,
      watchEventClaimSettledNonStandardDeductionAmount,
      watchEventClaimSettledAdjustedAmount
   ]);

   const { match } = props;
   const fromDate = dateFormat(moment().add(1, 'days'), "yyyy-mm-dd");
   const [claimPolicyDetails, setClaimPolicyDetails] = useState(null);
   const [claimPolicyType, setClaimPolicyType] = useState([]);
   const [natureOfLoss, setNatureOfLoss] = useState([]);
   const [claimType, setClaimType] = useState([]);
   const [claimStatus, setClaimStatus] = useState([]);
   const [selectedPolicyType, setSelectedPolicyType] = useState(null);
   const [claimView, setClaimView] = useState(null);
   const [eventType, setEventType] = useState(null);
   const [claimEventType, setClaimEventType] = useState(null);
   const [getEditClaimEventDetails, setGetEditClaimEventDetails] = useState([]);
   const [userProfile, setUserProfile] = useState([]);
   const minclaimEditFollowupdate = claimView && claimView.follow_up_date != null ? (claimView.follow_up_date) : (fromDate)
   const minFollowUpdate = (fromDate)
   const maxDateValue = new Date()
   const [lossDate, setLossDate] = useState(dateFormat("mm/dd/yyyy"));
   const [insuredDate, setInsuredDate] = useState(dateFormat("mm/dd/yyyy"));
   const [insurerDate, setInsurerDate] = useState(dateFormat("mm/dd/yyyy"));
   const [emailSendTo, setEmailSendTo] = useState([]);
   const initialRender = () => {

      $("#progress-loader").removeClass("hidden");

      props.actionEditClaimPolicyDetails({ control_no: props.location.state.control_no });

      props.actionEditClaimsView({ id: props.location.state.claim_id });
      props.actionEditClaimsEventType()
      props.actionClaimHandler({ user_id: props.auth.getUser().user_id });
   }
   useEffect(initialRender, []);

   const onGetEditClaimEventDetailsUpdate = () => {
      const { GetEditClaimEventDetails } = props;
      if (GetEditClaimEventDetails != null) {
         var GetEditClaimEventDetailsResponse = Object.assign({}, GetEditClaimEventDetails);
         if (GetEditClaimEventDetailsResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setColumns([
               { dataField: "event_date", caption: "Event Date" },
               { dataField: "event_type", caption: "Event" },
               { dataField: "claim_status", caption: "Claim Status" },
               { dataField: "event_details", caption: "Event Details" },
               { dataField: "surveyor_firm", caption: "Surv.Firm" },
               { dataField: "surveyor_name", caption: "Surv.Name" },
               { dataField: "surveyor_contact", caption: "Surv.Contact" },
               { dataField: "amount", caption: "Amount" },
               { dataField: "invoice_type", caption: "Invoice Type" },
               { dataField: "doc_requested", caption: "Doc Requested" },
               { dataField: "followup_date", caption: "Followup Type" },
               { dataField: "entry_date", caption: "Entry Date" },
               { dataField: "pending_date", caption: "Pending Date" },
               { dataField: "claim_remark", caption: "Claims Remark" },
            ]);

            setGetEditClaimEventDetails(GetEditClaimEventDetailsResponse.response);

         } else {
            $("#progress-loader").addClass("hidden");
            switch (GetEditClaimEventDetailsResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onGetEditClaimEventDetailsUpdate, [props.GetEditClaimEventDetails])

   const [columns, setColumns] = useState([
      { dataField: "event_date", caption: "Event Date" },
      { dataField: "event_type", caption: "Event" },
      { dataField: "claim_status", caption: "Claim Status" },
      { dataField: "event_details", caption: "Event Details" },
      { dataField: "surveyor_firm", caption: "Surv.Firm" },
      { dataField: "surveyor_name", caption: "Surv.Name" },
      { dataField: "surveyor_contact", caption: "Surv.Contact" },
      { dataField: "amount", caption: "Amount" },
      { dataField: "invoice_type", caption: "Invoice Type" },
      { dataField: "doc_requested", caption: "Doc Requested" },
      { dataField: "followup_date", caption: "Followup Type" },
      { dataField: "entry_date", caption: "Entry Date" },
      { dataField: "pending_date", caption: "Pending Date" },
      { dataField: "claim_remark", caption: "Claims Remark" },

   ]);

   const onClaimPolicyDetailsUpdate = () => {
      const { EditClaimPolicyDetails } = props;
      if (EditClaimPolicyDetails != null) {
         var ClaimPolicyDetailsResponse = Object.assign({}, EditClaimPolicyDetails);
         if (ClaimPolicyDetailsResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setClaimPolicyDetails(ClaimPolicyDetailsResponse.response);
            if (ClaimPolicyDetailsResponse.response.department) {
               setSelectedPolicyType(ClaimPolicyDetailsResponse.response.department)
            }
         } else {
            $("#progress-loader").addClass("hidden");
            switch (ClaimPolicyDetailsResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onClaimPolicyDetailsUpdate, [props.EditClaimPolicyDetails]);

   const onClaimHandlerUpdate = () => {
      const { ClaimHandler } = props;
      var ClaimHandlerResponse = Object.assign({}, ClaimHandler);
      if (ClaimHandlerResponse.result === true) {
         setUserProfile(ClaimHandlerResponse.response);
      } else {
         switch (ClaimHandlerResponse.status) {
            case UNAUTHORIZED:
               props.auth.logout();
               break;
            default:
         }
      }
   }
   useEffect(onClaimHandlerUpdate, [props.ClaimHandler])

   useEffect(() => {
      if (claimPolicyDetails != null) {
         props.actionClaimPolicyType();
         props.actionNatureOfLoss();
         props.actionClaimType();
         props.actionClaimStatus();
      }
   }, [claimPolicyDetails])

   const onClaimPolicyTypeUpdate = () => {
      const { EditClaimPolicyType } = props;
      if (EditClaimPolicyType != null) {
         var ClaimPolicyTypeResponse = Object.assign({}, EditClaimPolicyType);
         if (ClaimPolicyTypeResponse.result === true) {
            setClaimPolicyType(ClaimPolicyTypeResponse.response);
         } else {
            switch (ClaimPolicyTypeResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onClaimPolicyTypeUpdate, [props.EditClaimPolicyType]);

   useEffect(() => {
      if (claimView != null && claimView.policy_type != null) {
         if (claimPolicyType != null && claimPolicyType.length > 0) {
            setValue('policy_type', claimView.policy_type);
         }
      }
   }, [claimPolicyType])

   const onNatureOfLossUpdate = () => {
      const { EditClaimNatureOfLoss } = props;
      if (EditClaimNatureOfLoss != null) {
         var NatureOfLossResponse = Object.assign({}, EditClaimNatureOfLoss);
         if (NatureOfLossResponse.result === true) {
            setNatureOfLoss(NatureOfLossResponse.response);
         } else {
            switch (NatureOfLossResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onNatureOfLossUpdate, [props.EditClaimNatureOfLoss]);

   useEffect(() => {
      if (claimView != null && claimView.nature_of_claim != null) {
         if (natureOfLoss != null && natureOfLoss.length > 0) {
            setValue('nature_of_loss', claimView.nature_of_claim);
         }
      }
   }, [natureOfLoss])

   const onClaimTypeUpdate = () => {
      const { EditClaimType } = props;
      if (EditClaimType != null) {
         var ClaimTypeResponse = Object.assign({}, EditClaimType);
         if (ClaimTypeResponse.result === true) {
            setClaimType(ClaimTypeResponse.response);
         } else {
            switch (ClaimTypeResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               break;
               default:
            }
         }
      }
   }
   useEffect(onClaimTypeUpdate, [props.EditClaimType]);

   useEffect(() => {
      if (claimView != null && claimView.claim_type != null) {
         if (claimType != null && claimType.length > 0) {
            setValue('claim_type', claimView.claim_type);
         }
      }
   }, [claimType])

   const onClaimStatusUpdate = () => {
      const { EditClaimStatus } = props;
      if (EditClaimStatus != null) {
         var ClaimStatusResponse = Object.assign({}, EditClaimStatus);
         if (ClaimStatusResponse.result === true) {
            setClaimStatus(ClaimStatusResponse.response);
         } else {
            switch (ClaimStatusResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               default:
            }
         }
      }
   }
   useEffect(onClaimStatusUpdate, [props.EditClaimStatus]);

   useEffect(() => {
      if (claimView != null && claimView.status != null) {
         if (claimStatus != null && claimStatus.length > 0) {
            setValue('claim_status', claimView.status);
         }
      }
   }, [claimStatus])

   const onClaimViewUpdate = () => {
      const { ClaimView } = props;
      if (ClaimView != null) {
         var ClaimViewResponse = Object.assign({}, ClaimView);
         if (ClaimViewResponse.result === true) {
            setClaimView(ClaimViewResponse.response);
         } else {
            switch (ClaimViewResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onClaimViewUpdate, [props.ClaimView]);

   useEffect(() => {

      if (claimView != null) {

         if (claimView.date_of_loss != null) {

            let l_date_of_loss = moment(claimView.date_of_loss.trim(), 'DD/MM/YYYY');

            // alert(moment(claimView.date_of_loss.trim(), 'DD/MM/YYYY').format('DD-MM-YYYY'))

            if (l_date_of_loss.isValid()) {
               setValue('date_of_loss', l_date_of_loss.toDate());
            }
         }

         setValue('loss_details', claimView.loss_details);
         setValue('pinc_claim_id', claimView.pinc_claim_id);
         setValue('loss_amount', claimView.loss_amount);
         setValue('excess_amount', claimView.excess_amount);
         setValue('loss_details', claimView.loss_details);
         setValue('intimate_date', claimView.intimate_date);

         if (claimView.insured_intimation_date != null) {

            // alert(claimView.insured_intimation_date.trim());

            let l_insured_intimation_date = moment(claimView.insured_intimation_date.trim(), 'YYYY-MM-DD');

            if (l_insured_intimation_date.isValid()) {
               setValue('insured_intimation_date', moment(claimView.insured_intimation_date.trim(), 'YYYY-MM-DD').format('YYYY-MM-DD'));
            }

         }
         setValue('insured_name', claimView.insured_name);
         setValue('contact_details', claimView.contact_details);
         setValue('insured_email', claimView.insured_email);
         setValue('insurer_contact_email', claimView.insurer_contact_email)
         setValue('insured_intimation_mode', claimView.insured_intimation_mode);
         setValue('insured_claim_ref_no', claimView.insured_claim_ref_no);
         setValue('loss_location', claimView.loss_location);
         setValue('loss_state', claimView.loss_state);
         setValue('asset_detail', claimView.asset_detail);
         setValue('si_currency', claimView.si_currency);
         if (claimView.insurer_intimation_date != null) {

            let l_insurer_intimation_date = moment(claimView.insurer_intimation_date.trim(), 'YYYY-MM-DD');

            if (l_insurer_intimation_date.isValid()) {
               setValue('insurer_intimation_date', moment(claimView.insurer_intimation_date.trim(), 'YYYY-MM-DD').format('YYYY-MM-DD'));
            }

         }
         setValue('insurer_intimation_mode', claimView.insurer_intimation_mode);
         setValue('insurer_claim_no', claimView.insurer_claim_no);
         setValue('insurer_contact_name', claimView.insurer_contact_name);
         setValue('insurer_contact', claimView.insurer_contact);
         setValue('insurer_second_contact_name', claimView.insurer_second_contact_name);
         setValue('insurer_second_contact', claimView.insurer_second_contact);
         setValue('insurer_second_contact_email', claimView.insurer_second_contact_email);
         setValue('loss_site_contact_person_name', claimView.loss_site_contact_person_name);
         setValue('loss_site_contact_person_mobile', claimView.loss_site_contact_person_mobile);
         setValue('loss_site_contact_person_email', claimView.loss_site_contact_person_email);
         setValue('alternate_contact_person_name', claimView.alternate_contact_person_name);
         setValue('alternate_contact_person_mobile', claimView.alternate_contact_person_mobile);
         setValue('alternate_contact_person_email', claimView.alternate_contact_person_email);
         setValue('follow_up_date', claimView.follow_up_date);
         setValue('claim_remark', claimView.claim_remark);

         props.actionGetEditClaimsEventDetails({
            pinc_claim_no: claimView.pinc_claim_no
         })
      }
   }, [claimView]);

   useEffect(() => {
      if (selectedPolicyType != null) {
         if (selectedPolicyType === 'MOTOR') {
            var motor = JSON.parse(claimView?.product_details)

            setValue('motor.vehicle_no', motor != null && motor.vehicle_no != null ? motor.vehicle_no : '');
            setValue('motor.engine_no', motor != null && motor.engine_no != null ? motor.engine_no : '');
            setValue('motor.chassis_number', motor != null && motor.chassis_number != null ? motor.chassis_number : '');
            setValue('motor.transporter_name', motor != null && motor.transporter_name != null ? motor.transporter_name : '');
            setValue('motor.vehicle_make', motor != null && motor.vehicle_make != null ? motor.vehicle_make : '');
            setValue('motor.vehicle_model', motor != null && motor.vehicle_model != null ? motor.vehicle_model : '');
            setValue('motor.add_on', motor != null && motor.add_on != null ? motor.add_on : '');
         }
         if (selectedPolicyType === 'MARINE') {
            var marine = JSON.parse(claimView.product_details)
            setValue('marine.mtype', marine != null && marine.mtype != null ? marine.mtype : '');
            setValue('marine.invoice_number', marine != null && marine.invoice_number != null ? marine.invoice_number : '');
            setValue('marine.transit_bill_no', marine != null && marine.transit_bill_no != null ? marine.transit_bill_no : '');
            setValue('marine.invoice_date', marine != null && marine.invoice_date != null ? marine.invoice_date : '');
            setValue('marine.transit_type', marine != null && marine.transit_type != null ? marine.transit_type : '');
            setValue('marine.transit_date', marine != null && marine.transit_date != null ? marine.transit_date : '');
            setValue('marine.transit_from', marine != null && marine.transit_from != null ? marine.transit_from : '');
            setValue('marine.transit_to', marine != null && marine.transit_to != null ? marine.transit_to : '');
            setValue('marine.consignee_name', marine != null && marine.consignee_name != null ? marine.consignee_name : '');
            setValue('marine.consignee_address', marine != null && marine.consignee_address != null ? marine.consignee_address : '');
            setValue('marine.transporter', marine != null && marine.transporter != null ? marine.transporter : '');
            setValue('marine.consignor_name', marine != null && marine.consignor_name != null ? marine.consignor_name : '');
            setValue('marine.consignor_unit', marine != null && marine.consignor_unit != null ? marine.consignor_unit : '');
            setValue('marine.qty_loss', marine != null && marine.qty_loss != null ? marine.qty_loss : '');
            setValue('marine.dealer_name', marine != null && marine.dealer_name != null ? marine.dealer_name : '');
            setValue('marine.dealer_email', marine != null && marine.dealer_email != null ? marine.dealer_email : '');
            setValue('marine.bank_details_of_dealer', marine != null && marine.bank_details_of_dealer != null ? marine.bank_details_of_dealer : '');
            setValue('marine.survery_required', marine != null && marine.survery_required != null ? marine.survery_required : '');
         }
      }
   }, [selectedPolicyType])

   const onClaimsEditUpdate = () => {
      const { ClaimsEdit } = props;
      if (ClaimsEdit != null) {
         var ClaimsEditResponse = Object.assign({}, ClaimsEdit);
         $("#edit_detail").prop("disabled", false);
         if (ClaimsEditResponse.result === true) {
            $("#edit_detail_loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Claim Updated Successfully");
            props.history.push({ pathname: '/claims/list' });
         } else {
            $("#edit_detail_loader").addClass("hidden");
            switch (ClaimsEditResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
                  case ERROR:
                     toast.error(ClaimsEditResponse.message);
               default:
            }
         }
      }
   }
   useEffect(onClaimsEditUpdate, [props.ClaimsEdit]);

   const onEditClaimEventTypeUpdate = () => {
      const { EditClaimEventType } = props;
      if (EditClaimEventType != null) {
         var EditClaimEventTypeResponse = Object.assign({}, EditClaimEventType);
         if (EditClaimEventTypeResponse.result === true) {
            setClaimEventType(EditClaimEventTypeResponse.response);
         } else {
            switch (EditClaimEventTypeResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               default:
            }
         }
      }
   }
   useEffect(onEditClaimEventTypeUpdate, [props.EditClaimEventType]);

   const onEditClaimEventDetailsUpdate = () => {
      const { EditClaimEventDetails } = props;
      if (EditClaimEventDetails != null) {
         var EditClaimEventDetailsResponse = Object.assign({}, EditClaimEventDetails);
         if (EditClaimEventDetailsResponse.result === true) {
            setData(KEY_TOAST_MESSAGE, "Claim Event Added Successfully");
            props.history.push({ pathname: '/claims/list' });
         } else {
            switch (EditClaimEventDetailsResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
               default:
            }
         }
      }
   }
   useEffect(onEditClaimEventDetailsUpdate, [props.EditClaimEventDetails]);


   const onSubmit = (data) => {
      data.policy_id = claimPolicyDetails.id;
      data.policy_type = claimPolicyDetails.policy_type;
      data.id = props.location.state.claim_id;
      $("#edit_detail_loader").removeClass("hidden");
      $("#edit_detail").prop("disabled", true);
      props.actionClaimsEdit(data);
   }

   const onSubmitEvent = (data) => {
      data.pinc_claim_no = claimView.pinc_claim_no;
      data.claim_id = props.location.state.claim_id;
      $("#event_edit_detail_loader").removeClass("hidden");
      $("#event_edit_detail").prop("disabled", true);
      props.actionEditClaimsEventDetails(data)
   }

   const renderColumns = () => {
      var arrColumns = [];
      columns.forEach((objColumn, indexColumn) => {
         if (objColumn.dataField === "Action") {
            arrColumns.push(
               <Column dataField="Action" type="buttons">
                  <Button
                     render={(params) => (
                        <button
                           className="btn btn-icon btn-sm"
                           onClick={() => {
                              props.history.push({ pathname: `${match.url}/view` })
                           }}
                        >
                           <FiEye size={16} className="primary" />
                        </button>
                     )}
                  />
                  <Button
                     render={(params) => (
                        <button
                           className="btn btn-icon btn-sm"
                           onClick={() => {
                              props.history.push({ pathname: `${match.url}/view` })
                           }}
                        >
                           <FiEdit size={16} className="primary" />
                        </button>
                     )}
                  />
                  <Button
                     render={(params) => (
                        <button
                           className="btn btn-icon btn-sm"
                           onClick={() => {

                           }}
                        >
                           <HiOutlineDocumentSearch size={18} className="primary" />
                        </button>
                     )}
                  />
                  <Button
                     render={(params) => (
                        <button
                           className="btn btn-icon btn-sm"
                           onClick={() => {

                           }}
                        >
                           <HiOutlineDocumentAdd size={18} className="primary" />
                        </button>
                     )}
                  />
               </Column>
            );
         } else {
            arrColumns.push(
               <Column
                  dataField={objColumn.dataField}
                  caption={objColumn.caption}
               ></Column>
            )
         }
      });
      return arrColumns;
   };

   return (
      <>
         <div>
            <ScreenTitle title="edit Claim" />
            <div className="card">
               <div className="card-body">

                  <div class="form-body">
                     <form>
                        <div class="row">
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Policy Details
                              </h4>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurance Company</label>
                                 <input
                                    name="insurer_name"
                                    id="insurer_name"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.insurer_name != null ? claimPolicyDetails.insurer_name : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Branch</label>
                                 <input
                                    name="insurer_branch"
                                    id="insurer_branch"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.insurer_branch != null ? claimPolicyDetails.insurer_branch : '-'}

                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Customer Name</label>
                                 <input
                                    name="customer_name"
                                    id="customer_name"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.customer_name != null ? claimPolicyDetails.customer_name : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Customer Group</label>
                                 <input
                                    name="customer_group"
                                    id="customer_group"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.customer_group != null ? claimPolicyDetails.customer_group : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Type</label>
                                 <input
                                    name="policy_type"
                                    id="policy_type"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_type != null ? claimPolicyDetails.policy_type : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Number</label>
                                 <input
                                    name="policy_no"
                                    id="policy_no"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_no != null ? claimPolicyDetails.policy_no : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Start Date</label>
                                 <input
                                    name="policy_start_date"
                                    id="policy_start_date"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_start_date != null ? claimPolicyDetails.policy_start_date : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy End Date</label>
                                 <input
                                    name="policy_expiry_date"
                                    id="policy_expiry_date"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.policy_expiry_date != null ? claimPolicyDetails.policy_expiry_date : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Sum Insured</label>
                                 <input
                                    name="sum_insured"
                                    id="sum_insured"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.sum_insured != null ? claimPolicyDetails.sum_insured : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Gross Premium</label>
                                 <input
                                    name="gross_premium"
                                    id="gross_premium"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.gross_premium != null ? claimPolicyDetails.insurer_branch : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">RM Name</label>
                                 <input
                                    name="rm_name"
                                    id="rm_name"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.rm_name != null ? claimPolicyDetails.rm_name : '-'}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Control Number</label>
                                 <input
                                    name="control_no"
                                    id="control_no"
                                    class="form-control pl-1"
                                    type="text"
                                    readonly="readonly"
                                    value={claimPolicyDetails != null && claimPolicyDetails.control_no != null ? claimPolicyDetails.control_no : '-'}
                                 />
                              </div>
                           </div>

                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Pinc Claim ID&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="pinc_claim_id"
                                    id="pinc_claim_id"
                                    class="form-control"
                                    type="text"
                                    {...register('pinc_claim_id', { required: 'Pinc Claim ID is required' })}
                                 />
                                 {errors.pinc_claim_id && <ErrorLabel message={errors.pinc_claim_id?.message} />}
                              </div>
                           </div> */}

                           {/** Start Form Here */}
                        </div>
                     </form>
                     <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                        <div class="row">
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Edit Claim
                              </h4>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Inc/Loss Date&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="date_of_loss"
                                    id="date_of_loss"
                                    className="form-control"
                                    control={control}
                                    render={({ field }) =>
                                       <DateBox
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          max={maxDateValue}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             var objLossDate = new Date(value);
                                             var objToDate = new Date();

                                             if (objToDate.getTime() < objLossDate.getTime()) {
                                                Swal.fire({
                                                   title: 'Error',
                                                   text: "Inc/Loss Date can't be greater than Insured Intimation Date",
                                                   icon: 'error'
                                                }).then(success => {
                                                })
                                             } else {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setLossDate(dateFormat(value, 'yyyy-mm-dd'));
                                             }
                                          }}
                                       />
                                    }
                                 />
                                 {errors.date_of_loss && <ErrorLabel message={errors.date_of_loss?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss/Intimation Amount&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_amount"
                                    id="loss_amount"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Loss/Intimation Amount"
                                    {...register('loss_amount', { required: 'Loss/Intimation Amount is required' })}
                                 />
                                 {errors.loss_amount && <ErrorLabel message={errors.loss_amount?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Excess Amount</label>
                                 <input
                                    name="excess_amount"
                                    id="excess_amount"
                                    class="form-control"
                                    type="number"
                                    placeholder="Enter Excess Amount"
                                    {...register('excess_amount')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Inci/Loss Details&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_details"
                                    id="loss_details"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Inci/Loss Details"
                                    {...register('loss_details', { required: 'Inci/Loss Details is required' })}
                                 />
                                 {errors.loss_details && <ErrorLabel message={errors.loss_details?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Intimation Date&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="insured_intimation_date"
                                    className="form-control"
                                    control={control}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          max={maxDateValue}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             var objLossDate = new Date(lossDate);
                                             var objInsuredDate = new Date(value);

                                             if (objInsuredDate.getTime() < objLossDate.getTime()) {
                                                Swal.fire({
                                                   title: 'Error',
                                                   text: "Insured Intimation Date must be greater than Loss Date",
                                                   icon: 'error'
                                                }).then(success => {
                                                })
                                             } else {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setInsuredDate(dateFormat(value, 'yyyy-mm-dd'));
                                             }
                                          }}
                                       />
                                    }
                                 />
                                 {errors.insured_intimation_date && <ErrorLabel message={errors.insured_intimation_date?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Name&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insured_name"
                                    id="insured_name"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insured Name"
                                    {...register('insured_name', { required: 'Insured Name is required' })}
                                 />
                                 {errors.insured_name && <ErrorLabel message={errors.insured_name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Contact Details</label>
                                 <input
                                    name="contact_details"
                                    id="contact_details"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insured Contact Details"
                                    {...register('contact_details')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Email</label>
                                 <input
                                    name="insured_email"
                                    id="insured_email"
                                    class="form-control"
                                    placeholder="Enter Insured Email"
                                    {...register('insured_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Intimation Mode</label>
                                 <input
                                    name="insured_intimation_mode"
                                    id="insured_intimation_mode"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insured Intimation Mode"
                                    {...register('insured_intimation_mode')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insured Claim Ref No.</label>
                                 <input
                                    name="insured_claim_ref_no"
                                    id="insured_claim_ref_no"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insured Claim Ref No."
                                    {...register('insured_claim_ref_no')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Nature of Loss&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="nature_of_loss"
                                    className="form-control pl-1"
                                    control={control}
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={natureOfLoss}
                                          fields={{ text: 'name', value: 'name' }}
                                          placeholder="Select Nature of Loss"
                                          value={field.value}
                                          allowFiltering={true}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Nature of Loss is required'
                                    }}
                                 />
                                 {errors.nature_of_loss && <ErrorLabel message={errors.nature_of_loss?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Location</label>
                                 <input
                                    name="loss_location"
                                    id="loss_location"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Loss Location"
                                    {...register('loss_location')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss State</label>
                                 <input
                                    name="loss_state"
                                    id="loss_state"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Loss State"
                                    {...register('loss_state')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Type&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="claim_type"
                                    className="form-control pl-1"
                                    control={control}
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimType}
                                          fields={{ text: 'name', value: 'name' }}
                                          allowFiltering={false}
                                          placeholder="Select Claim Type"
                                          value={field.value}
                                          // allowFiltering={true}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Claim type is required'
                                    }}
                                 />
                                 {errors.claim_type && <ErrorLabel message={errors.claim_type?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Asset/Machine Details</label>
                                 <input
                                    name="asset_detail"
                                    id="asset_detail"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Asset/Machine Details"
                                    {...register('asset_detail')}
                                 />
                              </div>
                           </div>
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Type</label>
                                 <Controller
                                    name="policy_type"
                                    id="policy_type"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimPolicyType}
                                          fields={{ text: 'name', value: 'name' }}
                                          placeholder="Select Policy Type"
                                          value={field.value}
                                          allowFiltering={true}
                                          change={(event) => {

                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                                setSelectedPolicyType(event.itemData.department);
                                             }
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div> */}
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">SI Currency</label>
                                 <input
                                    name="si_currency"
                                    id="si_currency"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter SI Currency"
                                    {...register('si_currency')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Intimation Date</label>
                                 <Controller
                                    name="insurer_intimation_date"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          placeholder='DD/MM/YYYY'
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          max={maxDateValue}
                                          acceptCustomValue={false}
                                          onValueChange={(value) => {
                                             var objLossDate = new Date(lossDate);
                                             var objInsurerDate = new Date(value);

                                             if (objInsurerDate.getTime() < objLossDate.getTime()) {
                                                Swal.fire({
                                                   title: 'Error',
                                                   text: "Insurer Intimation Date must be greater than Loss Date",
                                                   icon: 'error'
                                                }).then(success => {

                                                })
                                             } else {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setInsurerDate(dateFormat(value, 'yyyy-mm-dd'));
                                             }
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Intimation Mode</label>
                                 <input
                                    name="insurer_intimation_mode"
                                    id="insurer_intimation_mode"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insurer Intimation Mode"
                                    {...register('insurer_intimation_mode')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Claim no.</label>
                                 <input
                                    name="insurer_claim_no"
                                    id="insurer_claim_no"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insurer Claim no."
                                    {...register('insurer_claim_no')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Contact Name&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insurer_contact_name"
                                    id="insurer_contact_name"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insurer Contact Name"
                                    {...register('insurer_contact_name', { required: 'Insurer Contact Name is required' })}
                                 />
                                 {errors.insurer_contact_name && <ErrorLabel message={errors.insurer_contact_name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Contact Number&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insurer_contact"
                                    id="insurer_contact"
                                    class="form-control"
                                    maxLength={10}
                                    placeholder="Enter Insurer Contact Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();

                                       }
                                    }}
                                    {...register('insurer_contact', {
                                       required: 'Insurer Contact Number is required',
                                       pattern: {
                                          value: /^[6789]\d{9}$/,
                                          message: 'Please enter 10 digit valid Mobile Number'
                                       }
                                    })}
                                 />
                                 {errors.insurer_contact && <ErrorLabel message={errors.insurer_contact?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Contact Email</label>
                                 <input
                                    name="insurer_contact_email"
                                    id="insurer_contact_email"
                                    className="form-control"
                                    placeholder="Enter Insurer Contact Email"
                                    {...register('insurer_contact_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Second Contact Name</label>
                                 <input
                                    name="insurer_second_contact_name"
                                    id="insurer_second_contact_name"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Insurer Second Contact Name"
                                    {...register('insurer_second_contact_name')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Second Contact Number</label>
                                 <input
                                    name="insurer_second_contact"
                                    id="insurer_second_contact"
                                    class="form-control"
                                    maxLength={10}
                                    placeholder="Enter Insurer Second Contact Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();

                                       }
                                    }}
                                    {...register('insurer_second_contact',
                                       {
                                          required: 'Insurer Second Contact Number is required',
                                          pattern: {
                                             value: /^[6789]\d{9}$/,
                                             message: 'Please enter 10 digit valid Mobile Number'
                                          }
                                       })}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurer Second Contact Email</label>
                                 <input
                                    name="insurer_second_contact_email"
                                    id="insurer_second_contact_email"
                                    className="form-control"
                                    placeholder="Enter Insurer Second Contact Email"
                                    {...register('insurer_second_contact_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Status</label>
                                 <input
                                    name="claim_status"
                                    id="claim_status"
                                    className="form-control pl-1"
                                    type="text"
                                    value={claimView != null ? claimView.status : ''}
                                    readonly="readonly"
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Follow up Date</label>
                                 <Controller
                                    name="follow_up_date"
                                    className="form-control"
                                    control={control}
                                    defaultValue={fromDate}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          min={minclaimEditFollowupdate}
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Handler Name</label>
                                 <input
                                    name="claim_handler_name"
                                    id="claim_handler_name"
                                    className="form-control pl-1"
                                    type="text"
                                    value={claimView != null ? claimView.claim_handler_name : ''}
                                    readonly="readonly"
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Handler Email</label>
                                 <input
                                    name="claim_handler_email"
                                    id="claim_handler_email"
                                    className="form-control pl-1"
                                    value={claimView != null ? claimView.claim_handler_email : ''}
                                    readonly="readonly"
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                 <label class="label-control">Claim Handler Branch</label>
                                 <textarea
                                    name="branch"
                                    id="branch"
                                    className="form-control no-scroll pl-1 pb-2"
                                    value={claimView != null ? claimView.claim_handler_branch : ''}
                                    readonly="readonly"
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Remarks</label>
                                 <textarea
                                    name="claim_remark"
                                    id="claim_remark"
                                    className="form-control no-scroll pl-1 pb-2"
                                    type="text"
                                    placeholder="Enter Claim Remarks"
                                    {...register('claim_remark', { maxLength: 500 })}
                                 />
                              </div>
                           </div>

                           {
                              selectedPolicyType === 'MOTOR' ?
                                 <>
                                    <div class="col-12 col-md-12 col-lg-12">
                                       <h4 class="d-flex align-items-center form-section mb-2 black">
                                          Product Details
                                       </h4>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Vehicle No./TC no.&nbsp;<span className="danger">*</span></label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Vehicle No./TC no."
                                             {...register('motor.vehicle_no', { required: 'Vehicle No./TC No. is required' })}
                                          />
                                          {errors.motor?.vehicle_no && <ErrorLabel message={errors.motor.vehicle_no?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Engine Number</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Engine Number"
                                             {...register('motor.engine_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Chassis Number</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Chassis Number"
                                             {...register('motor.chassis_number')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transporter Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transporter Name"
                                             {...register('motor.transporter_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Vehicle Make&nbsp;<span className="danger">*</span></label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Vehicle Make"
                                             {...register('motor.vehicle_make', { required: 'Vehicle Make is required' })}
                                          />
                                          {errors.motor?.vehicle_make && <ErrorLabel message={errors.motor.vehicle_make?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Vehicle Model</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Vehicle Model"
                                             {...register('motor.vehicle_model', { required: 'Vehicle Model is required' })}
                                          />
                                          {errors.motor?.vehicle_model && <ErrorLabel message={errors.motor.vehicle_model?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Add on</label>
                                          <Controller
                                             name="motor.add_on"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'With Zero Dep Cover',
                                                         'value': 'With Zero Dep Cover'
                                                      },
                                                      {
                                                         'title': 'Without Zero Dep Cover',
                                                         'value': 'Without Zero Dep Cover'
                                                      }
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Add on"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              selectedPolicyType === 'MARINE' ?
                                 <>
                                    <div class="col-12 col-md-12 col-lg-12">
                                       <h4 class="d-flex align-items-center form-section mb-2 black">
                                          Product Details
                                       </h4>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">MType</label>
                                          <Controller
                                             name="marine.mtype"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'FGP',
                                                         'value': 'fgp'
                                                      },
                                                      {
                                                         'title': 'RM',
                                                         'value': 'rm'
                                                      },
                                                      {
                                                         'title': 'CAPITAL GOODS',
                                                         'value': 'capital_goods'
                                                      },
                                                      {
                                                         'title': 'CONTAINERS',
                                                         'value': 'containers'
                                                      },
                                                      {
                                                         'title': 'CONSUMABLE',
                                                         'value': 'consumable'
                                                      },
                                                      {
                                                         'title': 'PACKING MATERIALS',
                                                         'value': 'packing_materials'
                                                      }
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select MType"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Invoice Number</label>
                                          <input
                                             className="form-control"
                                             type="number"
                                             placeholder="Enter Invoice Number"
                                             {...register('marine.invoice_number')}
                                          />
                                          {errors.invoice_number && <ErrorLabel message={errors.invoice_number?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Invoice Date</label>
                                          <Controller
                                             name="marine.invoice_date"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DateBox
                                                   defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   acceptCustomValue={false}
                                                   value={field.value}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Type</label>
                                          <Controller
                                             name="marine.transit_type"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'LR',
                                                         'value': 'lr'
                                                      },
                                                      {
                                                         'title': 'GR',
                                                         'value': 'gr'
                                                      },
                                                      {
                                                         'title': 'AWB',
                                                         'value': 'awb'
                                                      },
                                                      {
                                                         'title': 'BL',
                                                         'value': 'bl'
                                                      },
                                                      {
                                                         'title': 'RR',
                                                         'value': 'rr'
                                                      },
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Transit Type"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Bill No</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transit Bill No"
                                             {...register('marine.transit_bill_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Date</label>
                                          <Controller
                                             name="marine.transit_date"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DateBox
                                                   defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit From</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transit From"
                                             {...register('marine.transit_from')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit To</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transit To"
                                             {...register('marine.transit_to')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignee Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignee Name"
                                             {...register('marine.consignee_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignee Address</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignee Address"
                                             {...register('marine.consignee_address')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transporter</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Transporter"
                                             {...register('marine.transporter')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignor Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignor Name"
                                             {...register('marine.consignor_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Consignor Unit</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Consignor Unit"
                                             {...register('marine.consignor_unit')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Qty Loss</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Qty Loss"
                                             {...register('marine.qty_loss')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Survery Required</label>
                                          <Controller
                                             name="marine.survery_required"
                                             className="form-control"
                                             control={control}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'survery',
                                                         'value': 'survery'
                                                      },
                                                      {
                                                         'title': 'survery',
                                                         'value': 'survery'
                                                      }
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Survery Required"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Dealer Name</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Dealer Name"
                                             {...register('marine.dealer_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Dealer Email</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Dealer Email"
                                             {...register('marine.dealer_email')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Bank Details Of Dealer's</label>
                                          <input
                                             className="form-control"
                                             type="text"
                                             placeholder="Enter Bank Details Of Dealer's"
                                             {...register('marine.bank_details_of_dealer')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           <div class="col-12 col-md-12 col-lg-12">
                              <div class="form-group">
                                 <button id="edit_detail" class="btn btn-primary mr-1 shadow round text-uppercase" type="submit">
                                    <div class="d-flex align-items-center">SUBMIT<span id="edit_detail_loader" class="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </form>
                     <form>
                        <div class="col-12 col-md-12 col-lg-12 px-0">
                           <h4 class="d-flex align-items-center form-section mb-2 black">
                              Claim Event History
                           </h4>
                           <DataGrid
                              dataSource={getEditClaimEventDetails}
                              showBorders={true}
                              columnAutoWidth={true}
                              export={{
                                 fileName: "Claim Details",
                                 enabled: true,
                                 allowExportSelectedData: true,
                                 excelFilterEnabled: true,
                              }}
                           >
                              <SearchPanel visible={true} />
                              <FilterRow visible={true} />
                              <HeaderFilter visible={true} />
                              {renderColumns()}
                              {/* {renderStatus()}
                                {renderTravelType()} */}
                              <Pager
                                 visible={true}
                                 showPageSizeSelector={false}
                                 showNavigationButtons={true}
                                 allowedPageSizes={[4, 6, 10]}
                              />
                              <Paging
                                 defaultPageIndex={0}
                                 defaultPageSize={10}
                                 enabled={true}
                              />
                           </DataGrid>
                        </div>
                     </form>
                     <form noValidate autoComplete="Off" onSubmit={handleSubmitEvent(onSubmitEvent)}>
                        <div class="row">
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 Event Details
                              </h4>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Event Type&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="claim_event_type_master_id"
                                    className="form-control"
                                    control={controlEvent}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimEventType}
                                          fields={{ text: 'name', value: 'id' }}
                                          allowFiltering={false}
                                          placeholder="Select Event Type"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.id);
                                                setEventType(event.itemData.event_type_key);
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Event Type is required'
                                    }}
                                 />
                                 {errorsEvent.claim_event_type_master_id && <ErrorLabel message={errorsEvent.claim_event_type_master_id?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Dated&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="event_date"
                                    className="form-control"
                                    control={controlEvent}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}
                                       />
                                    }
                                 />
                                 {errorsEvent.event_date && <ErrorLabel message={errorsEvent.event_date?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Event Details</label>
                                 <input
                                    name="event_details"
                                    id="event_details"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Event Details"
                                    {...registerEvent('event_details')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Status&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="claim_status"
                                    className="form-control"
                                    control={controlEvent}
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimStatus}
                                          fields={{ text: 'name', value: 'name' }}
                                          allowFiltering={false}
                                          placeholder="Select Claim Status"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Claim Status is required'
                                    }}
                                 />
                                 {errorsEvent.claim_status && <ErrorLabel message={errorsEvent.claim_status?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Remarks</label>
                                 <input
                                    name="claim_remark"
                                    id="claim_remark"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Claim Remarks"
                                    {...registerEvent('claim_remark')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Followup Date&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="followup_date"
                                    className="form-control"
                                    control={controlEvent}
                                    defaultValue={fromDate}
                                    {...registerEvent('followup_date', { required: 'Followup Date is required' })}
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          min={minFollowUpdate}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}
                                       />
                                    }
                                 />
                                 {errorsEvent.followup_date && <ErrorLabel message={errorsEvent.followup_date?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Pending With</label>
                                 <Controller
                                    name="pending_with"
                                    className="form-control"
                                    control={controlEvent}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={[
                                             {
                                                'title': 'Insurer',
                                                'value': 'Insurer'
                                             },
                                             {
                                                'title': 'Insured',
                                                'value': 'Insured'
                                             },
                                             {
                                                'title': 'Surveyor',
                                                'value': 'Surveyor'
                                             },
                                          ]}
                                          fields={{ text: 'title', value: 'value' }}
                                          allowFiltering={false}
                                          placeholder="Select Pending With"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.value)
                                             }
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Email Send to</label>
                                 <Controller
                                    name="email_send_to"
                                    className="form-control"
                                    control={controlEvent}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={[
                                             {
                                                'title': 'Insurer',
                                                'value': 'insurer'
                                             },
                                             {
                                                'title': 'Insured',
                                                'value': 'insured'
                                             },
                                             {
                                                'title': 'Claim Handler',
                                                'value': 'claim_handler'
                                             },
                                             {
                                                'title': 'Other',
                                                'value': 'other'
                                             },
                                          ]}
                                          fields={{ text: 'title', value: 'value' }}
                                          allowFiltering={false}
                                          placeholder="Select Email Send to"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.value)
                                                setEmailSendTo(event.itemData.value)
                                             }
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           {
                              emailSendTo === "other" ?
                                 <div class="col-12 col-md-4 col-lg-4">
                                    <div class="form-group">
                                       <label class="label-control">Email Address</label>
                                       <input
                                          name="claim_event_email"
                                          id="claim_remark"
                                          class="form-control"
                                          type="text"
                                          placeholder="Enter Email Address"
                                          {...registerEvent('claim_event_email')}
                                       />
                                    </div>
                                 </div>
                                 :
                                 <></>
                           }
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Date of LOR</label>
                                 <Controller
                                    name="date_of_lor"
                                    className="form-control"
                                    control={controlEvent}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Claim Bill Amount</label>
                                 <input
                                    name="claim_bill_amount"
                                    id="claim_bill_amount"
                                    class="form-control"
                                    type="number"
                                    placeholder="Enter Claim Bill Amount"
                                    {...registerEvent('claim_bill_amount')}
                                 />
                              </div>
                           </div>
                           {
                              eventType != null && eventType === 'CLAIM_SETTLED' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Settled Date</label>
                                          <Controller
                                             name="claim_settled.claim_settled_settled_date"
                                             className="form-control"
                                             control={controlEvent}
                                             render={({ field }) =>
                                                <DateBox
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}

                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Gross Loss (Amt)&nbsp;<span className="danger">*</span></label>
                                          <input
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Gross Loss (Amt)"
                                             {...registerEvent('claim_settled.claim_settled_gross_loss_amount', { required: 'Gross Loss (Amt) is required' })}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Depreciation</label>
                                          <input
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Depreciation"
                                             {...registerEvent('claim_settled.claim_settled_depreciation_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Salvage</label>
                                          <input
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Salvage"
                                             {...registerEvent('claim_settled.claim_settled_salvage_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Loss Net Of Salvage</label>
                                          <input
                                             className="form-control pl-1"
                                             type="number"
                                             placeholder="Enter Loss Net Of Salvage"
                                             readonly="readonly"
                                             {...registerEvent('claim_settled.claim_settled_loss_net_of_salvage_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Under Insurance</label>
                                          <input
                                             name="under_insurance"
                                             id="under_insurance"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Under Insurance"
                                             {...registerEvent('claim_settled.claim_settled_under_insurance')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Policy Excess</label>
                                          <input
                                             name="policy_excess"
                                             id="policy_excess"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Policy Excess"
                                             {...registerEvent('claim_settled.claim_settled_policy_excess')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Net Assessed Loss</label>
                                          <input
                                             name="net_assessed_loss"
                                             id="net_assessed_loss"
                                             class="form-control pl-1"
                                             type="number"
                                             readonly="readonly"
                                             placeholder="Enter Net Assessed Loss"
                                             {...registerEvent('claim_settled.claim_settled_net_assessed_loss_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">RIP</label>
                                          <input
                                             name="rip"
                                             id="rip"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter RIP"
                                             {...registerEvent('claim_settled.claim_settled_rip_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">On A/c Payment</label>
                                          <input
                                             name="on_ac_payment"
                                             id="on_ac_payment"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter On A/c Payment"
                                             {...registerEvent('claim_settled.claim_settled_on_account_payment_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Adjusted Amt.</label>
                                          <input
                                             name="adjusted_amt"
                                             id="adjusted_amt"
                                             class="form-control pl-1"
                                             type="number"
                                             placeholder="Enter Adjusted Amt."
                                             readonly="readonly"
                                             {...registerEvent('claim_settled.claim_settled_adjusted_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Non Std. Deduction</label>
                                          <input
                                             name="non_std_deduction"
                                             id="non_std_deduction"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Non Std. Deduction"
                                             {...registerEvent('claim_settled.claim_settled_non_standard_deduction_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Bal. Settled Amt</label>
                                          <input
                                             name="bal_settled_amt"
                                             id="bal_settled_amt"
                                             class="form-control pl-1"
                                             type="number"
                                             readonly="readonly"
                                             placeholder="Enter Bal. Settled Amt"
                                             {...registerEvent('claim_settled.claim_settled_balance_settled_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Cheque/UTR Amount</label>
                                          <input
                                             name="cheque_utr_amount"
                                             id="cheque_utr_amount"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Cheque/UTR Amount"
                                             {...registerEvent('claim_settled.claim_settled_cheque_utr_amount')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Cheque UTR No</label>
                                          <input
                                             name="cheque_utr_no"
                                             id="cheque_utr_no"
                                             class="form-control"
                                             placeholder="Enter Cheque UTR No"
                                             {...registerEvent('claim_settled.claim_settled_cheque_utr_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Cheque UTR Date</label>
                                          <Controller
                                             name="claim_settled.claim_settled_cheque_utr_date"
                                             className="form-control"
                                             control={controlEvent}
                                             render={({ field }) =>
                                                <DateBox
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Policy Excess Description</label>
                                          <input
                                             name="policy_excess_description"
                                             id="policy_excess_description"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Policy Excess Description"
                                             {...registerEvent('claim_settled.claim_settled_policy_excess_description')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {

                              eventType != null && eventType === 'CO_SURVEYOR_APPOINTMENT' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Survey Firm&nbsp;<span className="danger">*</span></label>
                                          <input
                                             name="co_surveyor_appointment.survey_firm"
                                             className="form-control"
                                             id="co_surveyor_appointment.survey_firm"
                                             type="text"
                                             placeholder="Enter Survey Firm"
                                             {...registerEvent('co_surveyor_appointment.survey_firm')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Surveyor Name&nbsp;<span className="danger">*</span></label>
                                          <input
                                             name="co_surveyor_appointment.surveyor_name"
                                             className="form-control"
                                             id="co_surveyor_appointment.surveyor_name"
                                             type="text"
                                             placeholder="Enter Surveyor Name"
                                             {...registerEvent('co_surveyor_appointment.surveyor_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Surveyor Contact No.&nbsp;<span className="danger">*</span></label>
                                          <input
                                             name="surveyor_contact_no"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Surveyor Contact No."
                                             {...registerEvent('co_surveyor_appointment.surveyor_contact_no', { required: 'Surveyor Contact No. is required' })}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Surveyor License No</label>
                                          <input
                                             name="surveyor_license_no"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Surveyor License No"
                                             {...registerEvent('co_surveyor_appointment.co_surveyor_appointment_surveyor_license_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Surveyor Ref No.</label>
                                          <input
                                             name="surveyor_ref_no"
                                             id="surveyor_ref_no"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Surveyor Ref No."
                                             {...registerEvent('co_surveyor_appointment.surveyor_reference_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Insurer Claim No</label>
                                          <input
                                             name="insurer_claim_no"
                                             id="insurer_claim_no"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Insurer Claim No"
                                             {...registerEvent('co_surveyor_appointment.insurer_claim_no')}
                                          />
                                       </div>
                                    </div>
                                    {/* <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Insurer Claim No</label>
                                          <input
                                             name="insurer_claim_no"
                                             id="insurer_claim_no"
                                             class="form-control"
                                             type="number"
                                             {...registerEvent('co_surveyor_appointment.insurer_claim_no')}
                                          />
                                       </div>
                                    </div> */}
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'DOCUMENT_AWAITED' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Email Template</label>
                                          <Controller
                                             name="document_awaited.document_awaited_email_template"
                                             className="form-control"
                                             control={controlEvent}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'Reminder 1',
                                                         'value': 'reminder_1'
                                                      },
                                                      {
                                                         'title': 'Reminder 2',
                                                         'value': 'reminder_2'
                                                      },
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Email Template"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'INVOICENO_ENTRY' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">MType</label>
                                          <Controller
                                             name="invoiceno_entry.invoice_no_entry_mtype"
                                             className="form-control"
                                             control={controlEvent}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'FGP',
                                                         'value': 'fgp'
                                                      },
                                                      {
                                                         'title': 'RM',
                                                         'value': 'rm'
                                                      },
                                                      {
                                                         'title': 'CAPITAL GOODS',
                                                         'value': 'capital_goods'
                                                      },
                                                      {
                                                         'title': 'CONTAINERS',
                                                         'value': 'containers'
                                                      },
                                                      {
                                                         'title': 'CONSUMABLE',
                                                         'value': 'consumable'
                                                      },
                                                      {
                                                         'title': 'PACKING MATERIALS',
                                                         'value': 'packing_materials'
                                                      }
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select MType"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Invoice No.</label>
                                          <input
                                             name="invoice_no"
                                             id="invoice_no"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Invoice No."
                                             {...registerEvent('invoiceno_entry.invoice_no_entry_invoice_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Invoice Date</label>
                                          <Controller
                                             name="invoiceno_entry.invoice_no_entry_invoice_date"
                                             id="invoice_date"
                                             className="form-control"
                                             control={control}
                                             render={({ field }) =>
                                                <DateBox
                                                   // defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}

                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Type</label>
                                          <Controller
                                             name="invoiceno_entry.invoice_no_entry_transit_type"
                                             className="form-control"
                                             control={controlEvent}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DropDownListComponent
                                                   {...field}
                                                   dataSource={[
                                                      {
                                                         'title': 'LR',
                                                         'value': 'LR'
                                                      },
                                                      {
                                                         'title': 'GR',
                                                         'value': 'GR'
                                                      },
                                                      {
                                                         'title': 'AWB',
                                                         'value': 'AWB'
                                                      },
                                                      {
                                                         'title': 'BL',
                                                         'value': 'BL'
                                                      },
                                                      {
                                                         'title': 'RR',
                                                         'value': 'RR'
                                                      },
                                                   ]}
                                                   fields={{ text: 'title', value: 'value' }}
                                                   allowFiltering={false}
                                                   placeholder="Select Transit Type"
                                                   value={field.value}
                                                   change={(event) => {
                                                      if (event.itemData != null) {
                                                         field.onChange(event.itemData.value)
                                                      }
                                                   }}
                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Type No</label>
                                          <input
                                             name="transit_type_no"
                                             id="transit_type_no"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Transit Type No."
                                             {...registerEvent('invoiceno_entry.invoice_no_entry_transit_type_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit Date</label>
                                          <Controller
                                             name="invoiceno_entry.invoice_no_entry_transit_date"
                                             id="transit_date"
                                             className="form-control"
                                             control={control}
                                             render={({ field }) =>
                                                <DateBox
                                                   // defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}

                                                />
                                             }
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit From</label>
                                          <input
                                             name="transit_from"
                                             id="transit_from"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Transit From"
                                             {...registerEvent('invoiceno_entry.invoice_no_entry_transit_from')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Transit To</label>
                                          <input
                                             name="transit_to"
                                             id="transit_to"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Transit To"
                                             {...registerEvent('invoiceno_entry.invoice_no_entry_transit_to')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'CLAIM_FILING' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Claim Filing Date&nbsp;<span className="danger">*</span></label>
                                          <Controller
                                             name="claim_filing.claim_filing_date"
                                             className="form-control"
                                             control={controlEvent}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DateBox
                                                   defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}
                                                />
                                             }
                                          />
                                          {errorsEvent.claim_filing_date && <ErrorLabel message={errorsEvent.claim_filing_date?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Claim Filing Amount</label>
                                          <input
                                             name="claim_filing_amount"
                                             id="claim_filing_amount"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Claim Filing Amount"
                                             {...registerEvent('claim_filing.claim_filing_amount')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'SURVEY_DONE' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Date of Survey</label>
                                          <Controller
                                             name="survey_done.survey_done_date_of_survey"
                                             className="form-control"
                                             control={controlEvent}
                                             defaultValue=""
                                             render={({ field }) =>
                                                <DateBox
                                                   defaultValue={new Date()}
                                                   calendarOptions={{
                                                      showTodayButton: true,
                                                   }}
                                                   stylingMode="underlined"
                                                   className="form-control"
                                                   pickerType="calendar"
                                                   type="date"
                                                   displayFormat="dd/MM/yyyy"
                                                   value={field.value}
                                                   acceptCustomValue={false}
                                                   placeholder='DD/MM/YYYY'
                                                   onValueChange={(value) => {
                                                      field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                   }}
                                                />
                                             }
                                          />
                                          {errorsEvent.date_of_survey && <ErrorLabel message={errorsEvent.date_of_survey?.message} />}
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Survey Location</label>
                                          <input
                                             name="survey_done.survey_done_survey_location"
                                             id="survey_location"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Survey Location"
                                             {...registerEvent('survey_location')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">SURVEYOR REMARK / LIABILITY</label>
                                          <input
                                             name="survey_done.survey_done_surveyor_remark_lability"
                                             id="surveyor_remark_liability"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Surveyor Remark/Liability"
                                             {...registerEvent('surveyor_remark_liability')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'SURVEYOR_APPOINTMENT' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Survey Firm&nbsp;<span className="danger">*</span></label>
                                          <input
                                             name="surveyor_appointment.survey_firm"
                                             className="form-control"
                                             id="surveyor_appointment.survey_firm"
                                             type="text"
                                             placeholder="Enter Survey Firm"
                                             {...registerEvent('surveyor_appointment.survey_firm')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Survey Name&nbsp;<span className="danger">*</span></label>
                                          <input
                                             name="surveyor_appointment.surveyor_name"
                                             className="form-control"
                                             id="surveyor_appointment.surveyor_name"
                                             type="text"
                                             placeholder="Enter Survey Name"
                                             {...registerEvent('surveyor_appointment.surveyor_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Surveyor Contact No.&nbsp;<span className="danger">*</span></label>
                                          <input
                                             name="surveyor_contact_no"
                                             id="surveyor_contact_no"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Surveyor Contact No."
                                             {...registerEvent('surveyor_appointment.surveyor_contact_no', { required: 'Surveyor Contact No. is required' })}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Surveyor Email ID&nbsp;<span className="danger">*</span></label>
                                          <input
                                             name="surveyor_email_id"
                                             id="surveyor_email_id"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Surveyor Email ID"
                                             {...registerEvent('surveyor_appointment.surveyor_appointment_surveyor_email_id', { required: 'Surveyor Email ID is required' })}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Surveyor Ref No.</label>
                                          <input
                                             name="surveyor_ref_no"
                                             id="surveyor_ref_no"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Surveyor Ref No."
                                             {...registerEvent('surveyor_appointment.surveyor_reference_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Insurer Claim No</label>
                                          <input
                                             name="insurer_claim_no"
                                             id="insurer_claim_no"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Insurer Claim No"
                                             {...registerEvent('surveyor_appointment.insurer_claim_no')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'ON_ACCOUNT_PART_PAYMENT_RELEASED' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">On A/C Payment</label>
                                          <input
                                             name="on_ac_payment"
                                             id="on_ac_payment"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter On A/C Payment"
                                             {...registerEvent('on_account_part_payment_released.on_account_payment_amount')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'SHARING_OF_ASSESSMENT_WITH_INSURED' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Amount</label>
                                          <input
                                             name="amount"
                                             id="amount"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Amount"
                                             {...registerEvent('sharing_of_assessment_with_insured.sharing_of_assessment_with_insured_amount')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'CLAIM_INTIMATION_TO_INSURANCE_CO' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Insurer Claim No</label>
                                          <input
                                             name="insurer_claim_no"
                                             id="insurer_claim_no"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Insurer Claim No"
                                             {...registerEvent('claim_intimation_to_insurance_co.insurer_claim_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Intimation Amount</label>
                                          <input
                                             name="intimation_amount"
                                             id="intimation_amount"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Intimation Amount"
                                             {...registerEvent('claim_intimation_to_insurance_co.claim_intimation_to_insurance_company_intimation_amount')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              eventType != null && eventType === 'WORKSHOP_DETAILS' ?
                                 <>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Workshop Name</label>
                                          <input
                                             name="workshop_name"
                                             id="workshop_name"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Workshop Name"
                                             {...registerEvent('workshop_details.workshop_name')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Workshop Contact No</label>
                                          <input
                                             name="workshop_contact_no"
                                             id="workshop_contact_no"
                                             class="form-control"
                                             type="number"
                                             placeholder="Enter Workshop Contact No"
                                             {...registerEvent('workshop_details.workshop_contact_no')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Workshop Contact Person</label>
                                          <input
                                             name="workshop_contact_person"
                                             id="workshop_contact_person"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Workshop Contact Person"
                                             {...registerEvent('workshop_details.workshop_contact_person')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Workshop Email ID</label>
                                          <input
                                             name="workshop_email_id"
                                             id="workshop_email_id"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Workshop Email ID"
                                             {...registerEvent('workshop_details.workshop_email')}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4">
                                       <div class="form-group">
                                          <label class="label-control">Workshop Location</label>
                                          <input
                                             name="workshop_location"
                                             id="workshop_location"
                                             class="form-control"
                                             type="text"
                                             placeholder="Enter Workshop Location"
                                             {...registerEvent('workshop_details.workshop_location')}
                                          />
                                       </div>
                                    </div>
                                 </>
                                 :
                                 <></>
                           }
                        </div>
                        <button
                           className="btn btn-outline-secondary shadow round text-uppercase"
                           onClick={(event) => {
                              event.preventDefault();
                              props.history.push({ pathname: '/claims/list' });
                           }}
                        >Back</button>
                        <button id="event_edit_detail" class="btn btn-primary ml-1 shadow round text-uppercase" type="submit">
                           <div class="d-flex align-items-center">SUBMIT<span id="event_edit_detail_loader" class="spinner-border spinner-border-sm ml-half hidden"></span>
                           </div>
                        </button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}


function mapStateToProps(state) {

   var EditClaimPolicyDetails = null;
   var EditClaimPolicyType = null;
   var EditClaimStatus = null;
   var EditClaimType = null;
   var EditClaimNatureOfLoss = null;
   var ClaimView = null;
   var ClaimsEdit = null;
   var EditClaimEventType = null;
   var EditClaimEventDetails = null;
   var GetEditClaimEventDetails = null;
   var ClaimHandler = null;

   if (
      state.EditClaimsReducer != null &&
      Object.keys(state.EditClaimsReducer).length > 0 &&
      state.EditClaimsReducer.constructor === Object
   ) {
      switch (state.EditClaimsReducer.tag) {
         case ACTION_CLAIM_POLICY_DETAILS_SUCCESS:
            EditClaimPolicyDetails = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer;
            return { EditClaimPolicyDetails };
         case ACTION_EDIT_CLAIMS_POLICY_TYPE_SUCCESS:
            EditClaimPolicyType = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer;
            return { EditClaimPolicyType };
         case ACTION_EDIT_NATURE_OF_LOSS_SUCCESS:
            EditClaimNatureOfLoss = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer;
            return { EditClaimNatureOfLoss };
         case ACTION_EDIT_CLAIM_TYPE_SUCCESS:
            EditClaimType = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer;
            return { EditClaimType };
         case ACTION_EDIT_CLAIM_STATUS_SUCCESS:
            EditClaimStatus = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer;
            return { EditClaimStatus };
         case ACTION_EDIT_CLAIMS_VIEW_SUCCESS:
            ClaimView = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer
            return { ClaimView };
         case ACTION_CLAIMS_EDIT_SUCCESS:
            ClaimsEdit = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer
            return { ClaimsEdit };
         case ACTION_EDIT_CLAIM_EVENT_TYPE_SUCCESS:
            EditClaimEventType = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer
            return { EditClaimEventType };
         case ACTION_CLAIM_EDIT_EVENT_DETAILS_SUCCESS:
            EditClaimEventDetails = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer
            return { EditClaimEventDetails };
         case ACTION_GET_CLAIM_EDIT_EVENT_DETAILS_SUCCESS:
            GetEditClaimEventDetails = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer
            return { GetEditClaimEventDetails };
         case ACTION_CLAIM_REGISTER_PINC_EDIT_PROFILE_SUCCESS:
            ClaimHandler = Object.assign({}, state.EditClaimsReducer.data);
            delete state.EditClaimsReducer
            return { ClaimHandler };
         default:
      }
   }
   return {
      EditClaimPolicyDetails,
      EditClaimPolicyType,
      EditClaimNatureOfLoss,
      EditClaimType,
      EditClaimStatus,
      ClaimView,
      ClaimsEdit,
      EditClaimEventType,
      EditClaimEventDetails,
      GetEditClaimEventDetails,
      ClaimHandler
   }
}

function mapDispatchToProps(dispatch) {
   return bindActionCreators({
      actionEditClaimPolicyDetails,
      actionClaimPolicyType,
      actionNatureOfLoss,
      actionClaimType,
      actionClaimStatus,
      actionEditClaimsView,
      actionClaimsEdit,
      actionEditClaimsEventType,
      actionEditClaimsEventDetails,
      actionGetEditClaimsEventDetails,
      actionClaimHandler
   }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditClaim));