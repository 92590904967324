import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_MASTER_GET_BRANCH_CONTACT } from '../../../../../../../api/constants';

export const ACTION_MASTER_GET_BRANCH_CONTACT_LOADING = 'ACTION_MASTER_GET_BRANCH_CONTACT_LOADING';
export const ACTION_MASTER_GET_BRANCH_CONTACT_SUCCESS = 'ACTION_MASTER_GET_BRANCH_CONTACT_SUCCESS';

export function actionMasterGetInsurerBranchContact(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_BRANCH_CONTACT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_BRANCH_CONTACT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_GET_BRANCH_CONTACT_LOADING, { loading: false, data }));
        });
}