import React from 'react';
import { Route, Switch } from 'react-router';
import InsurerView from './view';
const InsurerRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/view`}  component={InsurerView}/>
            </Switch>
        </>
    )
}

export default InsurerRoute