import React, { useEffect, useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from "devextreme-react";
import dateFormat from "dateformat";
import { CLAIM_FILE_ERROR_MESSAGE, CLAIM_FILE_SIZE, ERROR, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from 'jquery';
import { actionWellnessManagementClientType, ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_SUCCESS } from "./action";
import { actionWellnessManagementWellnessType, ACTION_WELLNESS_MANAGEMENT_WELLNESS_TYPE_SUCCESS } from "./action";
import { actionWellnessManagementClientName, ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_SUCCESS } from "./action";
import { actionWellnessManagementAssignRm, ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS } from "./action";
import { actionWellnessManagementCustomerType, ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_SUCCESS } from "./action";
import { actionWellnessManagementRequestAdd, ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_SUCCESS } from "./action";
import { KEY_TOAST_MESSAGE, setData, KEY_USER, getData } from "../../../../../../utils/preferences";
import Swal from "sweetalert2";
import { checkFileExtension, downloadFile } from "../../../../../../utils";
import _ from 'lodash';
import { toast } from "react-toastify";

const WellnessManagementAdd = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {
            assign_rm: 0,
        }
    });
    const [clientType, setClientType] = useState([]);
    const [wellnessType, setWellnessType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [assignRm, setAssignRm] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectType, setSelectType] = useState(null)
    const [showExecutedBy, setShowExecutedBy] = useState(null);
    const [selectedExecutedBy, setSelectedExecutedBy] = useState("");
    const [uploadDocument, setUploadDocument] = useState(null);
    const [wellnessStatus, setWellnessStatus] = useState("close");
    const [files, setFiles] = useState([]);

    const validExt = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'txt', 'jpg', 'jpeg', 'png'];

    const initialRender = () => {
        var userDetail = getData(KEY_USER);
        if (userDetail != null) {
            var userObj = JSON.parse(userDetail)
            setDepartments(userObj.departments)
        }
        props.actionWellnessManagementAssignRm();
        props.actionWellnessManagementClientType();
        props.actionWellnessManagementWellnessType();
    }
    useEffect(initialRender, []);

    const onClientTypeUpdate = () => {
        const { ClientType } = props;
        var ClientTypeResponse = Object.assign({}, ClientType);
        if (ClientTypeResponse.result === true) {
            setClientType(ClientTypeResponse.response);
        } else {
            switch (ClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientType([]);
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    const onWellnessTypeUpdate = () => {
        const { WellnessType } = props;
        var WellnessTypeResponse = Object.assign({}, WellnessType);
        if (WellnessTypeResponse.result === true) {
            setWellnessType(WellnessTypeResponse.response);
        } else {
            switch (WellnessTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setWellnessType([]);
                    break;
                default:
            }
        }
    }
    useEffect(onWellnessTypeUpdate, [props.WellnessType]);

    const onClientNameUpdate = () => {
        const { ClientName } = props;
        var ClientNameResponse = Object.assign({}, ClientName);
        if (ClientNameResponse.result === true) {
            setClientName(ClientNameResponse.response);
        } else {
            switch (ClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientName([]);
                    break;
                default:
            }
        }
    }
    useEffect(onClientNameUpdate, [props.ClientName]);

    const onCustomerTypeUpdate = () => {
        const { CustomerType } = props;
        var CustomerTypeResponse = Object.assign({}, CustomerType);
        if (CustomerTypeResponse.result === true) {
            setClientName(CustomerTypeResponse.response);
        } else {
            switch (CustomerTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientName([]);
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerTypeUpdate, [props.CustomerType]);

    const onAssignRmUpdate = () => {
        const { AssignRm } = props;
        var AssignRmResponse = Object.assign({}, AssignRm);
        if (AssignRmResponse.result === true) {
            var arrAssignRM = Object.assign([], AssignRmResponse.response);
            var user = {
                user_id: 0,
                full_name: "None"
            };
            arrAssignRM.unshift(user);
            setAssignRm(arrAssignRM);
        } else {
            switch (AssignRmResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setAssignRm([]);
                    break;
                default:
            }
        }
    }
    useEffect(onAssignRmUpdate, [props.AssignRm]);

    const onRequestAddUpdate = () => {
        const { RequestAdd } = props;
        var RequestAddResponse = Object.assign({}, RequestAdd);
        $("#save_wellness_management").prop("disabled", false);
        if (RequestAddResponse.result === true) {
            $("#wellness-management-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Wellness Request added successfully");
            props.history.goBack();
        } else {
            $("#wellness-management-form-loader").addClass("hidden");
            switch (RequestAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestAddUpdate, [props.RequestAdd]);

    const onSubmit = (data) => {
        if(files != null && files.length >= 5){
            toast.error("Maximum 5 documents can be uploaded");
            return;
        }
        data.rm_status = data.rm_status != null ? data.rm_status : null;
        var sendFiles = [];
        if (files != null && files.length > 0) {
            $('#error-doc-not-found').addClass('d-none')
            files.forEach((file, indexFile) => {
                sendFiles.push({ 'name': `file[${indexFile}]`, 'value': file });
            });
        } else {
            if (data.rm_status === 'close') {
                $('#error-doc-not-found').removeClass('d-none')
                $("#wellness-pool-edit-loader").addClass("hidden");
                $("#edit_wellness_pool").prop("disabled", false);
                return
            }
        }
        delete data.file;
        if (data.rm_status === null || data.rm_status === "undefined" || data.rm_status === undefined) {
            delete data.rm_status;
        }
        if (data.actual_activity_date === null || data.actual_activity_date === "undefined" || data.actual_activity_date === undefined) {
            delete data.actual_activity_date;
        }
        $("#wellness-management-form-loader").removeClass("hidden");
        $("#save_wellness_management").prop("disabled", true);
        if (data.assign_rm == null || data.assign_rm == 0) { delete data.assign_rm; }
        props.actionWellnessManagementRequestAdd(data, sendFiles);
    }

    return (
        <>
            <ScreenTitle title="Add Wellness Request" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        if (departments != null && departments.length > 0 && departments.includes('Wellness')) {
                                                                            props.actionWellnessManagementCustomerType({ client_type: event?.itemData?.value });
                                                                        } else {
                                                                            props.actionWellnessManagementClientName({ client_type: event?.itemData?.value });
                                                                        }

                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Type is required'
                                                        }}
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={true}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                sortOrder="Ascending"
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Name is required'
                                                        }}
                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Activity</label>
                                                    <Controller
                                                        name="activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="activity_date"
                                                                name="activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    const check = new Date(value) < new Date().setHours(0, 0, 0, 0);
                                                                    if (!check) {
                                                                        setSelectedExecutedBy(null);
                                                                        setValue("wellness_executed_by", null);
                                                                        setValue("assign_rm", 0);
                                                                    }
                                                                    setShowExecutedBy(check);
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.activity_date && <ErrorLabel message={errors.activity_date?.message} />}
                                                </div>
                                            </div>
                                            {showExecutedBy && <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Wellness Executed By&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_executed_by"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_executed_by"
                                                                name="wellness_executed_by"
                                                                dataSource={["Internal Team", "Direct Vendor / TPA"]}
                                                                allowFiltering={false}
                                                                placeholder="Select Wellness Executed By"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        if(event.itemData.value === "Direct Vendor / TPA") {
                                                                            setValue("rm_status", "close");
                                                                            setValue("assign_rm", null);
                                                                        } else {
                                                                            setValue("rm_status", null);
                                                                        }
                                                                        setSelectedExecutedBy(event.itemData.value)
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Wellness Executed By is required'
                                                        }}
                                                    />
                                                    {errors.wellness_executed_by && <ErrorLabel message={errors.wellness_executed_by?.message} />}
                                                </div>
                                            </div>
                                            }
                                            {(departments != null && departments.length > 0 && departments.includes('Wellness')) || (showExecutedBy && selectedExecutedBy === "Internal Team") ?
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Assign Engineer</label>
                                                        <Controller
                                                            name="assign_rm"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    id="assign_rm"
                                                                    name="assign_rm"
                                                                    dataSource={assignRm}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Assign"
                                                                    value={field.value}
                                                                    fields={{ text: 'full_name', value: 'user_id' }}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.user_id)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        {errors.assign_rm && <ErrorLabel message={errors.assign_rm?.message} />}
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_type_id"
                                                                name="wellness_type_id"
                                                                dataSource={wellnessType}
                                                                fields={{ text: "name", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectType(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type is required'
                                                        }}
                                                    />
                                                    {errors.wellness_type_id && <ErrorLabel message={errors.wellness_type_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectType === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="wellness_type_detail"
                                                                name="wellness_type_detail"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                {...register('wellness_type_detail', {
                                                                    required: selectType === "Others" ? "Description is required" : false
                                                                })}
                                                            />
                                                            {errors.wellness_type_detail?.type && <ErrorLabel message={errors.wellness_type_detail?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            {showExecutedBy && selectedExecutedBy === "Direct Vendor / TPA" ?
                                                <>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">RM Status</label>
                                                    <Controller
                                                        name="rm_status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="rm_status"
                                                                name="rm_status"
                                                                dataSource={[
                                                                    {
                                                                        text: 'Update',
                                                                        value: 'update'
                                                                    },
                                                                    {
                                                                        text: 'Cancel',
                                                                        value: 'cancel'
                                                                    },
                                                                    {
                                                                        text: 'Close',
                                                                        value: 'close'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value != null ? field.value : 'close'}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value);
                                                                        setWellnessStatus(event.itemData.value);
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.rm_status && <ErrorLabel message={errors.rm_status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Document&nbsp;<span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                        <div class="custom-file">
                                                            <input
                                                                type="file"
                                                                class="custom-file-input"
                                                                id="file"
                                                                name="file"
                                                                max={5}
                                                                accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.txt,.jpg,.jpeg,.png"
                                                                multiple={true}
                                                                onChange={(event) => {
                                                                    setFiles([]);
                                                                    $('#error-doc-not-found').addClass('d-none')
                                                                    if (event.target.files != null) {
                                                                        if (event.target.files && event.target.files.length <= 5) {
                                                                            var ext = (event.target.files[0]?.name)?.split('.')?.pop();
                                                                            var fileSize = event.target?.files[0]?.size;
                                                                            if (Math.round(fileSize / 1024) >= CLAIM_FILE_SIZE) {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: CLAIM_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }
                                                                            if (checkFileExtension(ext, validExt)) {
                                                                                var arrFiles = Object.assign([], files);
                                                                                var fileNames = '';
                                                                                if ((files.length + event.target.files.length) <= 5) {
                                                                                    for (let i = 0; i < event.target.files.length; i++) {
                                                                                        let file = event.target.files[i];
                                                                                        arrFiles.push(file);
                                                                                        fileNames += event.target.files[i].name + ", ";
                                                                                    }
                                                                                    setUploadDocument(_.trim(fileNames, ", "));
                                                                                    setFiles(arrFiles);
                                                                                }
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Upload Error',
                                                                                    text: 'You can only upload Word, TXT, Excel, PDF, JPEG, JPG, PNG & ZIP files',
                                                                                    icon: 'error'
                                                                                }).then(success => {

                                                                                })
                                                                            }
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'Maximum 5 files are allowed.',
                                                                                icon: 'error'
                                                                            }).then(success => {
                                                                            });
                                                                        }
                                                                    } else {
                                                                        $('#error-doc-not-found').removeClass('d-none')
                                                                    }

                                                                }}
                                                            />
                                                            <small className="error-msg d-none text-danger" id="error-doc-not-found">Document is required</small>
                                                            <label class="custom-file-label" for="file">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument : "Choose file"
                                                                }
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Actual Date of Activity&nbsp;<span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="actual_activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="actual_activity_date"
                                                                name="actual_activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: wellnessStatus === 'close' ? 'Please Select Actual Date of Activity' : false
                                                        }}
                                                    />
                                                    {errors.actual_activity_date && <ErrorLabel message={errors.actual_activity_date?.message} />}
                                                </div>
                                            </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remarks</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks "
                                                        name="remarks"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Remarks"
                                                        {...register('remarks', {
                                                            maxLength:
                                                            {
                                                                value: 200,
                                                                message: "Please enter 200 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.remarks?.type === 'required' && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_wellness_management"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="wellness-management-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ClientType = null;
    var WellnessType = null;
    var ClientName = null;
    var AssignRm = null;
    var CustomerType = null;
    var RequestAdd = null;

    if (
        state.addWellnessManagementReducer != null &&
        Object.keys(state.addWellnessManagementReducer).length > 0 &&
        state.addWellnessManagementReducer.constructor === Object
    ) {
        switch (state.addWellnessManagementReducer.tag) {
            case ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { ClientType };
            case ACTION_WELLNESS_MANAGEMENT_WELLNESS_TYPE_SUCCESS:
                WellnessType = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { WellnessType };
            case ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_SUCCESS:
                ClientName = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { ClientName };
            case ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS:
                AssignRm = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { AssignRm };
            case ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_SUCCESS:
                CustomerType = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { CustomerType };
            case ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_SUCCESS:
                RequestAdd = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { RequestAdd };
            default:
        }
    }
    return {
        ClientType,
        ClientName,
        AssignRm,
        CustomerType,
        RequestAdd,
        WellnessType,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionWellnessManagementClientType,
        actionWellnessManagementClientName,
        actionWellnessManagementAssignRm,
        actionWellnessManagementCustomerType,
        actionWellnessManagementRequestAdd,
        actionWellnessManagementWellnessType,
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessManagementAdd))
