import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import { FaRegHandshake } from 'react-icons/fa';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { FiShield } from 'react-icons/fi';
import Bar from '../../chart_details';
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../../constants';
import Swal from 'sweetalert2';
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionInsurerViewTabBusinessSummary, ACTION_INSURER_VIEW_TAB_BUSINESS_SUMMARY_SUCCESS } from './action';

const TabInsuranceDetails = (props) => {

    const { control } = useForm({});
    const [businessSummary, setBusinessSummary] = useState(null);

    const [fromDate, setFromDate] = useState(`04/01/${new Date().getFullYear()}`);
    const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));

    const initialRender = () => {
        props.actionInsurerViewTabBusinessSummary({ insurer_id: props.insurerId });
    }
    useEffect(initialRender, [props.insurerId]);

    const onBusinessSummaryUpdate = () => {
        const { BusinessSummary } = props;
        if (BusinessSummary != null) {
            var BusinessSummaryResponse = Object.assign({}, BusinessSummary);
            if (BusinessSummaryResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setBusinessSummary(BusinessSummaryResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (BusinessSummaryResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout();
                        })
                        break;

                    default:

                }
            }
        }
    }
    useEffect(onBusinessSummaryUpdate, [props.BusinessSummary]);

    return (
        <div className="tab-pane active" id="insurance-details" aria-labelledby="insurance-details-tab" role="tabpanel">
            <div className="card mb-0">
                <div className="card-body">
                    <div class="row mt-1 ml-1">
                        <div className="col-12 col-md-5 col-lg-5">
                            <div className="form-group">
                                <Controller
                                    name="from_date"
                                    control={control}
                                    defaultValue={fromDate}
                                    render={({ field }) =>
                                        <DateBox
                                            defaultValue={new Date()}
                                            calendarOptions={{
                                                showTodayButton: true,
                                            }}
                                            stylingMode="underlined"
                                            pickerType="calendar"
                                            placeholder="From Date"
                                            type="date"
                                            displayFormat="dd/MM/yyyy"
                                            showClearButton={false}
                                            acceptCustomValue={false}
                                            value={field.value}
                                            onValueChange={(value) => {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5">
                            <div className="form-group">
                                <Controller
                                    name="to_date"
                                    control={control}
                                    defaultValue={toDate}
                                    render={({ field }) =>
                                        <DateBox
                                            defaultValue={new Date()}
                                            calendarOptions={{
                                                showTodayButton: true,
                                            }}
                                            stylingMode="underlined"
                                            pickerType="calendar"
                                            placeholder="To Date"
                                            type="date"
                                            displayFormat="dd/MM/yyyy"
                                            showClearButton={false}
                                            acceptCustomValue={false}
                                            value={field.value}
                                            onValueChange={(value) => {
                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-2 col-lg-2">
                            <button
                                className="btn btn-primary py-half mr-1 shadow round text-uppercase"
                                type="submit"
                                onClick={() => {
                                    if (fromDate.length > 0 && toDate.length > 0) {
                                        props.actionInsurerViewTabBusinessSummary({
                                            insurer_id: props.insurerId,
                                            from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
                                            to_date: dateFormat(toDate, 'yyyy-mm-dd')
                                        });
                                    } else {
                                        Swal.fire({
                                            title: 'Error',
                                            icon: 'error',
                                            html: 'Please Select From Date & To Date',
                                            confirmButtonColor: PRIMARY_COLOR
                                        }).then((result) => {

                                        });
                                    }
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mt-1 mx-half">
                        <div class="col-lg-6 col-xl-6 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    Number(businessSummary.premium_details.current).toFixed(2) : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Premium Current Year {
                                            businessSummary != null && businessSummary.premium_details.current_unit != null ?
                                                '(In ' + businessSummary.premium_details.current_unit + ')' : ''
                                        }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    Number(businessSummary.premium_details.previous).toFixed(2) : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Premium Previous Year {
                                            businessSummary != null && businessSummary.premium_details.previous_unit != null ?
                                                '(In ' + businessSummary.premium_details.previous_unit + ')' : ''
                                        }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mt-1 mx-half">
                        <div class="col-lg-6 col-xl-6 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.total_premium : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Total Premium {
                                            businessSummary != null && businessSummary.total_premium_unit != null ?
                                                '(In ' + businessSummary.total_premium_unit + ')' : ''
                                        }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.total_claim : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Total Claim Amount {
                                            businessSummary != null && businessSummary.total_claim_unit != null ?
                                                '(In ' + businessSummary.total_claim_unit + ')' : ''
                                        }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mt-2 mx-half">
                        <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                <div className="flex-column">
                                    <span class="primary d-flex align-items-center justify-content-center p-1">
                                        <FaRegHandshake size={28} />
                                    </span>
                                </div>
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.no_of_meetings : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Total Meeting</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                <div className="flex-column">
                                    <span class="primary d-flex align-items-center justify-content-center p-1">
                                        <FiShield size={28} />
                                    </span>
                                </div>
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.no_of_policies : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Total Policies</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                <div className="flex-column">
                                    <span class="primary d-flex align-items-center justify-content-center p-1">
                                        <IoDocumentAttachOutline size={28} />
                                    </span>
                                </div>
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.no_of_clients : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Total Clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mt-2 mx-half">
                        {/* <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                <div className="flex-column">
                                    <span class="primary d-flex align-items-center justify-content-center p-1">
                                        <HiOutlineCurrencyRupee size={28} />
                                    </span>
                                </div>
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.no_of_intimated_claims : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Intimated Claims</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                <div className="flex-column">
                                    <span class="primary d-flex align-items-center justify-content-center p-1">
                                        <HiOutlineCurrencyRupee size={28} />
                                    </span>
                                </div>
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.no_of_pending_claims : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Pending Claims</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                            <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                <div className="flex-column">
                                    <span class="primary d-flex align-items-center justify-content-center p-1">
                                        <HiOutlineCurrencyRupee size={28} />
                                    </span>
                                </div>
                                <div className="flex-column ml-1">
                                    <div class="stats-amount">
                                        <h3 class="heading-text text-bold-600">
                                            {
                                                businessSummary != null ?
                                                    businessSummary.no_of_settled_claims : 0
                                            }
                                        </h3>
                                        <p class="sub-heading mb-0 font-small-3">Settled Claims</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="card mt-0 mx-half">
                <div className="card-body py-0">
                    <div class="row bg-primary-shadow rounded bg-accent-2">
                        <div class="col-12 region-stats-chart">
                            <div class="card statistic-card">
                                <div class="card-content">
                                    <Bar details={businessSummary} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {

    var BusinessSummary = null;

    if (
        state.InsurerTabBusinessSummaryReducer != null &&
        Object.keys(state.InsurerTabBusinessSummaryReducer).length > 0 &&
        state.InsurerTabBusinessSummaryReducer.constructor === Object
    ) {
        switch (state.InsurerTabBusinessSummaryReducer.tag) {
            case ACTION_INSURER_VIEW_TAB_BUSINESS_SUMMARY_SUCCESS:
                BusinessSummary = Object.assign({}, state.InsurerTabBusinessSummaryReducer.data);
                delete state.InsurerTabBusinessSummaryReducer;
                return { BusinessSummary };
            default:
        }
    }
    return {
        BusinessSummary
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionInsurerViewTabBusinessSummary }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TabInsuranceDetails);