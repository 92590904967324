import React from 'react';
import { Route, Switch } from 'react-router';
import Add from './add';
import Edit from './edit';
import Roles from './list';
import View from './view';

const RolesRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}/add`}  component={Add}/>
                <Route exact path={`${match.url}/edit`}  component={Edit}/>
                <Route exact path={`${match.url}/view`}  component={View}/>
                <Route exact path={`${match.url}`}  component={Roles}/>
            </Switch>
        </>
    )
}

export default RolesRoute