import { TRANSPORT_ADD_SUCCESS } from './action';

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddTransportReducer = createReducer(INITIAL_STATE, {

    [TRANSPORT_ADD_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: TRANSPORT_ADD_SUCCESS,
            loading: false
        });
    }
});

export default AddTransportReducer;