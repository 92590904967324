import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionMyReportingVertical, ACTION_MYREPORTINGVERTICAL_SUCCESS } from './action';
import TreeView from 'devextreme-react/tree-view';

const MyReporting = (props) => {

    const [currentItem, setCurrentItem] = useState([]);
    const [myReporting, setMyReporting] = useState([])
    const [value, setValue] = ('');
    const [message, setMessage] = useState("")

    const initialRender = () => {
        props.actionMyReportingVertical({ user_id: props.userId })
    }
    useEffect(initialRender, [props.userId]);

    const onMyTeamVerticalUpdate = () => {
        const { MyReporting } = props;
        if (MyReporting != null) {
            var MyReportingResponse = Object.assign({}, MyReporting);
            if (MyReportingResponse.result === true) {
                if( MyReportingResponse.response.length > 0  ) { 
                    setMyReporting(MyReportingResponse.response)
                    setCurrentItem(MyReportingResponse.response)
                } else { 
                    setMessage("No Reporting Found");
                }
            } else {

                setMyReporting([])
                setCurrentItem([])

                setMessage("No Reporting Found");

                switch (MyReportingResponse.status) {

                }
            }
        }
    }


    useEffect(onMyTeamVerticalUpdate, [props.MyReporting]);

    const selectItem = (event) => {
        if( event.itemData != null ) { 
            props.history.replace({ pathname: `/profile`, state: { user_id: event.itemData.id } });
        }
        var currentItem = Object.assign({}, event.itemData)
        setCurrentItem(currentItem)
    }

    return (
        <>
            {
                myReporting != null && myReporting.length > 0 ?
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 mx-0 px-0">
                        <div className="m-2">
                            <TreeView
                                items={myReporting}
                                dataStructure="plain"
                                displayExpr="name"
                                parentIdExpr="reporting_to_id"
                                keyExpr="id"
                                onItemClick={selectItem}
                                searchMode={value}
                                searchEnabled={true}
                            />

                        </div>
                    </div>
                </div>
                : 
                <>
                    <h6 className="text-bold-500 text-center">{message}</h6>
                </>
            }
        </>


    )
}

function mapStateToProps(state) {

    var MyReporting = null;

    if (
        state.MyReportingVerticalReducer != null &&
        Object.keys(state.MyReportingVerticalReducer).length > 0 &&
        state.MyReportingVerticalReducer.constructor === Object
    ) {
        switch (state.MyReportingVerticalReducer.tag) {

            case ACTION_MYREPORTINGVERTICAL_SUCCESS:
                MyReporting = Object.assign({}, state.MyReportingVerticalReducer.data);
                delete state.MyReportingVerticalReducer;
                return { MyReporting };
            default:
                return { MyReporting }
        }
    }
    return { MyReporting }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionMyReportingVertical }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyReporting);
