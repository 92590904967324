import React, { useEffect, useState } from "react";
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED } from '../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionDashboardBirthday, ACTION_DASHBOARD_BIRTHDAY_SUCCESS } from "./action";
import { generateInitialLettersString } from '../../../utils';
import Carousel from "react-multi-carousel";
import dateFormat from "dateformat";
import $ from "jquery";
import "react-multi-carousel/lib/styles.css";

const Events = (props) => {

  const [selectedEvent, setSelectedEvent] = useState(0);
  const [events, setEvents] = useState({
    work_anniversary: [],
    birthday: [],
    best_performer: []
  });
  const [length, setLength] = useState(0);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const initialRender = () => {
    props.actionDashboardBirthday({});
  };
  useEffect(initialRender, []);

  const onDashboardBirthdayUpdate = () => {
    const { DashboardBirthday } = props;
    var DashboardBirthdayResponse = Object.assign({}, DashboardBirthday);
    if (DashboardBirthdayResponse.result === true) {
      setEvents(DashboardBirthdayResponse.response)
      setLength(Number(DashboardBirthdayResponse.response.work_anniversary.length))
    } else {
      switch (DashboardBirthdayResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  }
  useEffect(onDashboardBirthdayUpdate, [props.DashboardBirthday]);

  const renderWorkAnniversaryCards = () => {
    return events.work_anniversary.map((objEvent) => (
      <div
        className="card bg-blue-grey bg-lighten-5 mb-half" key={objEvent.id}
        style={{ borderRadius: 16 }}
      >

        <div className="card-body py-1 px-1">
          <div className="d-flex flex-row">
            <div className="flex-column cursor-pointer"
              onClick={() => {
                props.history.push({
                  pathname: '/profile',
                  state: {
                    user_id: objEvent.user_id
                  }
                })
              }}
            >
              {
                objEvent.profile_photo != null ?
                  <img
                    src={objEvent.profile_photo}
                    className="rounded-circle img-border"
                    alt="employee"
                    width={50}
                    height={50}
                    style={{ border: "5px solid #FFF" }}
                  />
                  :
                  <div
                    className="bg-blue-grey bg-lighten-4 text-bold-700 bg-circle d-flex align-items-center justify-content-center"
                  >
                    {generateInitialLettersString(objEvent.full_name)}
                  </div>
              }
            </div>
            <div className="flex-column ml-1">
              <h2
                className="font-small-3 text-bold-600 card-text-esc cursor-pointer py-0 my-0"
                title={objEvent.full_name}
                onClick={() => {
                  props.history.push({
                    pathname: '/profile',
                    state: {
                      user_id: objEvent.user_id
                    }
                  })
                }}
              >
                {objEvent.full_name}
              </h2>
              <h4 className="font-small-3 card-text-esc py-0 my-0" title={objEvent.designation}>{objEvent.designation}</h4>

              <div className="d-flex flex-row  justify-content-end mt-1">
                <div className="flex-column my-auto">
                  <h4 className="font-small-3 text-bold-600 card-text-esc">{objEvent.joining}</h4>
                </div>
                <div className="flex-column my-auto">
                  <button
                    onClick={() => {

                      if ($(".work-anniversary-post-" + objEvent.id).hasClass("card")) {
                        $('html, body').animate({
                          scrollTop: $(".work-anniversary-post-" + objEvent.id).offset().top - 70
                        }, 700);
                      } else {
                        props.history.push({
                          pathname: '/dashboard/activity-feeds',
                          state: {
                            event: 'work-anniversary',
                            user_id: objEvent.user_id
                          }
                        })
                      }

                    }}
                    className={`btn btn-sm  round ml-4 py-half px-1 font-small-1 ${dateFormat(new Date(), "dd/mmm") === objEvent.joining ? 'btn-primary' : 'btn-disabled bg-blue-grey bg-lighten-3'} `}
                  >
                    Wish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderBirthdayCards = () => {
    return events.birthday.map((objEvent) => (
      <div key={objEvent.id}>
        <div
          className="card bg-blue-grey bg-lighten-5 mb-half"
          style={{ borderRadius: 16 }}
        >
          <div className="card-body py-1 px-1">
            <div className="d-flex flex-row">
              <div
                className="flex-column cursor-pointer"
                onClick={() => {
                  props.history.push({
                    pathname: '/profile',
                    state: {
                      user_id: objEvent.user_id
                    }
                  })
                }}
              >
                {
                  objEvent.profile_photo != null ?
                    <img
                      src={objEvent.profile_photo}
                      className="rounded-circle img-border"
                      alt="employee"
                      width={50}
                      height={50}
                      style={{ border: "5px solid #FFF" }}
                    />
                    :
                    <div
                      className="bg-blue-grey bg-lighten-4 text-bold-700 bg-circle d-flex align-items-center justify-content-center"
                    >
                      {generateInitialLettersString(objEvent.full_name)}
                    </div>
                }
              </div>
              <div className="flex-column ml-1">
                <h2
                  className="font-small-3 text-bold-600 card-text-esc cursor-pointer py-0 my-0"
                  title={objEvent.full_name}
                  onClick={() => {
                    props.history.push({
                      pathname: '/profile',
                      state: {
                        user_id: objEvent.user_id
                      }
                    })
                  }}
                >
                  {objEvent.full_name}
                </h2>
                <h4
                  className="font-small-3 card-text-esc py-0 my-0"
                  title={objEvent.designation}
                >{objEvent.designation}</h4>
                <div className="d-flex flex-row  justify-content-end mt-1">
                  <div className="flex-column my-auto">
                    <h4 className="font-small-3 text-bold-600 card-text-esc">{objEvent.birthday}</h4>
                  </div>
                  <div className="flex-column my-auto">
                    <button
                      onClick={() => {

                        if ($(".birthday-post-" + objEvent.id).hasClass("card")) {
                          $('html, body').animate({
                            scrollTop: $(".birthday-post-" + objEvent.id).offset().top - 70
                          }, 700);
                        } else {
                          props.history.push({
                            pathname: '/dashboard/activity-feeds',
                            state: {
                              event: 'birthday',
                              user_id: objEvent.user_id
                            }
                          })
                        }

                      }}
                      className={`btn btn-sm  round ml-4 py-half px-1 font-small-1 ${dateFormat(new Date(), "dd/mmm") === objEvent.birthday ? 'btn-primary' : 'btn-disabled bg-blue-grey bg-lighten-3'} `}
                    >
                      Wish
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderUI = () => {
    try {
      return (<>
        <div className="dashboard-summary-bottom card dashboard-card-round">
          <div className="card-body p-1">
            {
              events?.birthday?.length > 0 ?
                <div>
                  <div
                    className={`border-bottom text-primary`}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      setSelectedEvent(1);
                      setLength(Number(events?.birthday?.length))
                    }}
                  >
                    Birthday Wishes
                  </div>
                  <Carousel
                    showDots={length > 1 ? true : false}
                    responsive={responsive}
                    swipeable={true}
                    keyBoardControl={true}
                    containerClass="carousel-container mt-1"
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                    itemClass="carousel-item-padding-40-px"
                  // renderDotsOutside={true}
                  >{renderBirthdayCards()}
                  </Carousel>
                </div>
                :
                <></>
            }
            {
              events?.work_anniversary?.length > 0 ?
                <div>
                  <div
                    className={`border-bottom text-primary`}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      setSelectedEvent(0);
                      setLength(Number(events?.work_anniversary?.length))
                    }}
                  >
                    Work Anniversary
                  </div>
                  <Carousel
                    showDots={length > 1 ? true : false}
                    responsive={responsive}
                    swipeable={true}
                    keyBoardControl={true}
                    rewind={true}
                    containerClass="carousel-container mt-1"
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                    itemClass="carousel-item-padding-40-px"
                  // renderDotsOutside={true}
                  >{renderWorkAnniversaryCards()}</Carousel>
                </div>
                :
                <></>
            }

          </div>
        </div>
      </>)
    } catch (e) {
      return (
        <>
          <h1>Error Event</h1>
        </>
      )
    }

  }

  return (
    <>
      {
        events != null && (events?.birthday?.length > 0 || events?.work_anniversary?.length > 0) ? renderUI() : <></>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  var DashboardBirthday = null;

  if (
    state.DashboardEventReducer != null &&
    Object.keys(state.DashboardEventReducer).length > 0 &&
    state.DashboardEventReducer.constructor === Object
  ) {
    switch (state.DashboardEventReducer.tag) {
      case ACTION_DASHBOARD_BIRTHDAY_SUCCESS:
        DashboardBirthday = Object.assign({}, state.DashboardEventReducer.data);
        delete state.DashboardEventReducer;
        return { DashboardBirthday };
      default:
    }
  }
  return {
    DashboardBirthday

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionDashboardBirthday,
    },
    dispatch
  );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Events))
