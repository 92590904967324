import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_GROUP_LEVEL_MEETING } from '../../../../../../../api/constants';
import { PRINT } from '../.././../../../../../utils';

export const ACTION_API_GROUP_LEVEL_MEETING_LOADING = 'ACTION_API_GROUP_LEVEL_MEETING_LOADING';
export const ACTION_API_GROUP_LEVEL_MEETING_SUCCESS = 'ACTION_API_GROUP_LEVEL_MEETING_SUCCESS';

export function actionGroupLevelMeetingView(params) {
    return (dispatch, getState) => Api.post(API_GROUP_LEVEL_MEETING, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_API_GROUP_LEVEL_MEETING_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_API_GROUP_LEVEL_MEETING_LOADING, { loading: false, data }));
    });
}
