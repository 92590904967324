import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REINSURANCE_ENQUIRY_DETAIL } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_REINSURANCE_VIEW_LOADING = 'ACTION_REINSURANCE_VIEW_LOADING';
export const ACTION_REINSURANCE_VIEW_SUCCESS = 'ACTION_REINSURANCE_VIEW_SUCCESS';

export function actionReinsuranceView(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE_ENQUIRY_DETAIL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            console.log("ERROR", reason);
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_VIEW_LOADING, { loading: false, data }));
        });
}