import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_RI_MEETING_EDIT, API_REINSURANCE, API_MEETING_LIST_CONTACT, API_GET_RI_NAME, API_RI_MEETING_ADD_CONTACT, API_RI_MEETING_VIEW } from '../../../../../../api/constants';
import { API_MASTER_COUNTRY, API_ACCOMPAINED_BY, API_PREVIOUS_MEETING, API_DELETE_DOCUMENT } from '../../../../../../api/constants';
import { API_CLIENT_TYPE } from '../../../../../../api/constants';
import { API_RI_CLIENT } from '../../../../../../api/constants';
import { API_RI_MEETING_NEXT } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_RI_MEETING_EDIT_LOADING = 'ACTION_RI_MEETING_EDIT_LOADING';
export const ACTION_RI_MEETING_EDIT_SUCCESS = 'ACTION_RI_MEETING_EDIT_SUCCESS';

export const ACTION_RI_MEETING_VIEW_LOADING = 'ACTION_RI_MEETING_VIEW_LOADING';
export const ACTION_RI_MEETING_VIEW_SUCCESS = 'ACTION_RI_MEETING_VIEW_SUCCESS';

export const ACTION_MASTER_COUNTRY_LOADING = 'ACTION_MASTER_COUNTRY_LOADING';
export const ACTION_MASTER_COUNTRY_SUCCESS = 'ACTION_MASTER_COUNTRY_SUCCESS';

export const ACTION_CLIENT_TYPE_LOADING = 'ACTION_CLIENT_TYPE_LOADING';
export const ACTION_CLIENT_TYPE_SUCCESS = 'ACTION_CLIENT_TYPE_SUCCESS';

export const ACTION_RI_ACCOMPAINED_BY_LOADING = 'ACTION_RI_ACCOMPAINED_BY_LOADING';
export const ACTION_RI_ACCOMPAINED_BY_SUCCESS = 'ACTION_RI_ACCOMPAINED_BY_SUCCESS';

export const ACTION_CLIENT_LOADING = 'ACTION_CLIENT_LOADING';
export const ACTION_CLIENT_SUCCESS = 'ACTION_CLIENT_SUCCESS';

export const ACTION_REINSURANCE_CLIENT_LOADING = 'ACTION_REINSURANCE_CLIENT_LOADING';
export const ACTION_REINSURANCE_CLIENT_SUCCESS = 'ACTION_REINSURANCE_CLIENT_SUCCESS';

export const ACTION_RI_GET_CONTACTS_LOADING = 'ACTION_RI_GET_CONTACTS_LOADING';
export const ACTION_RI_GET_CONTACTS_SUCCESS = 'ACTION_RI_GET_CONTACTS_SUCCESS';

export const ACTION_ADD_GET_RI_NAME_LOADING = 'ACTION_ADD_GET_RI_NAME_LOADING';
export const ACTION_ADD_GET_RI_NAME_SUCCESS = 'ACTION_ADD_GET_RI_NAME_SUCCESS';

export const ACTION_RI_PREVIOUS_MEETING_EDIT_LOADING = 'ACTION_RI_PREVIOUS_MEETING_EDIT_LOADING';
export const ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS = 'ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS';

export const ACTION_RI_MEETING_ADD_CONTACT_LOADING = 'ACTION_RI_MEETING_ADD_CONTACT_LOADING';
export const ACTION_RI_MEETING_ADD_CONTACT_SUCCESS = 'ACTION_RI_MEETING_ADD_CONTACT_SUCCESS';

export const ACTION_DOCUMENT_DELETE_LOADING = 'ACTION_DOCUMENT_DELETE_LOADING';
export const ACTION_DOCUMENT_DELETE_SUCCESS = 'ACTION_DOCUMENT_DELETE_SUCCESS';

export const ACTION_RI_MEETING_SAVE_LOADING = 'ACTION_RI_MEETING_SAVE_LOADING';
export const ACTION_RI_MEETING_SAVE_SUCCESS = 'ACTION_RI_MEETING_SAVE_SUCCESS';

export function actionRiMeetingsEdit(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_RI_MEETING_EDIT,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RI_MEETING_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_MEETING_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RI_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionCountry() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_COUNTRY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_COUNTRY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_COUNTRY_LOADING, { loading: false, data }));
        });
}
export function actionClientType() {
    return (dispatch, getState) =>
        Api.get(API_CLIENT_TYPE,).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}
export function actionClient() {
    return (dispatch, getState) =>
        Api.get(API_RI_CLIENT,).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLIENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLIENT_LOADING, { loading: false, data }));
        });
}

export function actionReinsuranceClient(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_CLIENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_CLIENT_LOADING, { loading: false, data }));
        });
}

export function actionRIGetContacts(params) {
    return (dispatch, getState) => 
    Api.post(API_MEETING_LIST_CONTACT, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_RI_GET_CONTACTS_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_RI_GET_CONTACTS_LOADING, { loading: false, data }));
    });
}

export function actionGetRiName(params) {
    return (dispatch, getState) => 
    Api.post(API_GET_RI_NAME, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_ADD_GET_RI_NAME_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_ADD_GET_RI_NAME_LOADING, { loading: false, data }));
    });
}

export function actionRIMeetingAddContact(params) {
    return (dispatch, getState) => 
    Api.post(API_RI_MEETING_ADD_CONTACT, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_RI_MEETING_ADD_CONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_RI_MEETING_ADD_CONTACT_LOADING, { loading: false, data }));
    });
}

export function actionRiMeetingsView(params) {
    return (dispatch, getState) =>
        Api.post(API_RI_MEETING_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RI_MEETING_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_MEETING_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionPreviousMeeting(params) {
    return (dispatch, getState) => 
    Api.post(API_PREVIOUS_MEETING, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_RI_PREVIOUS_MEETING_EDIT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_RI_PREVIOUS_MEETING_EDIT_LOADING, { loading: false, data }));
    });
}

export function actionDeleteDocument(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_DOCUMENT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOCUMENT_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_DELETE_LOADING, { loading: false, data }));
        });
}

export function actionRIMeetingSave(params) {
    return (dispatch, getState) =>
        Api.post(API_RI_MEETING_NEXT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RI_MEETING_SAVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RI_MEETING_SAVE_LOADING, { loading: false, data }));
        });
}