import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import Swal from "sweetalert2";
import dateFormat from 'dateformat';
import { DateBox } from "devextreme-react";
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from "devextreme/data/array_store";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { UNAUTHORIZED } from "../../../constants";
import { actionPolicyType, ACTION_MASTER_POLICY_TYPE_SUCCESS, actionInsuredCompany, ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS, actionSumInsuredPolicy, ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW, actionPremiumPolicy, ACTION_PREMIUM_SUCCESS } from "./action";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import moment from "moment";

const PolicyFilter = (props) => {
    const { register, handleSubmit, formState: { errors }, control, setValue, reset } = useForm();
    // const [fromDate, setFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    // const [toDate, setToDate] = useState(moment(new Date()).add(4, 'months').format('YYYY-MM-DD'));
    const [policyTypeDropdown, setPolicyTypeDropdown] = useState(null)
    const [insuredCompany, setInsuredCompany] = useState(null)
    const [objSumInsured, setObjSumInsured] = useState([]);
    const [insuranceCompanyValue, setInsuranceCompanyValue] = useState([])
    const [min, setMin] = useState(null)
    const [max, setMax] = useState(null)
    const [minNetPremium, setMinNetPremium] = useState(null)
    const [maxNetPremium, setMaxNetPremium] = useState(null)
    const [minGrossPremium, setMinGrossPremium] = useState(null)
    const [maxGrossPremium, setMaxGrossPremium] = useState(null)
    const [policyTypeValue, setPolicyTypeValue] = useState([])
    const [objPremium, setObjPremium] = useState([])
    const [netPremiumValue, setNetPremiumValue] = useState(null)
    const [netPremium, setNetPremium] = useState([])
    const [premium, setPremium] = useState([])
    const [premiumDropdown, setPremiumDropdown] = useState(false)
    const [sumInsuredDropdown, setSumInsuredDropdown] = useState(false)
    const [sumInsured, setSumInsured] = useState([])

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            props.actionPolicyType();
            props.actionInsuredCompany();
            props.actionPremiumPolicy({
                product: props.department
            })
            props.actionSumInsuredPolicy({
                product: props.department
            });
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onPolicyTypeDropdownUpdate = () => {
        const { PolicyTypeDropdown } = props;
        $("#policy-reset-popup").prop("disabled", false);
        var PolicyTypeDropdownResponse = Object.assign({}, PolicyTypeDropdown);
        if (PolicyTypeDropdownResponse.result === true) {
            $("#policy-reset-popup-loader").addClass("hidden");
            setPolicyTypeDropdown(PolicyTypeDropdownResponse.response);
        } else {
            $("#policy-reset-popup-loader").addClass("hidden");
            switch (PolicyTypeDropdownResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onPolicyTypeDropdownUpdate, [props.PolicyTypeDropdown]);

    const onInsuredCompanyUpdate = () => {
        const { InsuredCompany } = props;
        $("#policy-reset-popup").prop("disabled", false);
        var InsuredCompanyResponse = Object.assign({}, InsuredCompany);
        if (InsuredCompanyResponse.result === true) {
            $("#policy-reset-popup-loader").addClass("hidden");
            setInsuredCompany(InsuredCompanyResponse.response);
        } else {
            $("#policy-reset-popup-loader").addClass("hidden");
            switch (InsuredCompanyResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onInsuredCompanyUpdate, [props.InsuredCompany]);

    const onSumInsuredPolicyUpdate = () => {
        const { SumInsuredPolicy } = props;
        $("#policy-reset-popup").prop("disabled", false);
        var SumInsuredPolicyResponse = Object.assign({}, SumInsuredPolicy);
        if (SumInsuredPolicyResponse.result === true) {
            $("#policy-reset-popup-loader").addClass("hidden");
            setObjSumInsured(SumInsuredPolicyResponse.response)
        } else {
            $("#policy-reset-popup-loader").addClass("hidden");
            switch (SumInsuredPolicyResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onSumInsuredPolicyUpdate, [props.SumInsuredPolicy]);

    // useEffect(() => {
    //     if (objSumInsured != null && objSumInsured.length > 0 && sumInsuredDropdown === false) {
    //         if (props.location.state?.suminsured?.id != null) {
    //             setValue('sum_insured', props.location.state?.suminsured?.id);
    //         }
    //     }
    // }, [objSumInsured, sumInsuredDropdown])


    useEffect(() => {
        if (props != null) {
            setValue('insurance_company', props.location.state?.insuranceCompanyValue);
            setValue('policy_type', props.location.state?.policyValue);
            setValue('sum_insured', props.location.state?.suminsured?.id);
            setValue('gross_premium', props.location.state?.premium?.id);
        }
    }, [])

    const onPremiumPolicyUpdate = () => {
        const { PremiumPolicy } = props;
        if (PremiumPolicy != null) {
            var PremiumPolicyResponse = Object.assign({}, PremiumPolicy);
            if (PremiumPolicyResponse.result === true) {
                setObjPremium(PremiumPolicyResponse.response)
            } else {
                switch (PremiumPolicyResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onPremiumPolicyUpdate, [props.PremiumPolicy]);

    // useEffect(() => {
    //     if (objPremium != null && objPremium.length > 0 && premiumDropdown === false) {
    //         if (props.location.state?.premium?.id != null) {
    //             setValue('gross_premium', props.location.state?.premium?.id);
    //         }
    //     }
    // }, [objPremium, premiumDropdown])

    const onSubmit = () => {


    }

    return (
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Policy Filter
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                                reset();
                                setValue('sum_insured', null);
                                setValue('gross_premium', null);
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div >
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-body">
                                    {/* <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12 pb-1">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="flex-column mr-1">
                                                        <label className="label-control secondary">From Date</label>
                                                        <Controller
                                                            name="from_date"
                                                            control={control}
                                                            defaultValue={fromDate}
                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    pickerType="calendar"
                                                                    placeholder="From Date"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    showClearButton={false}
                                                                    value={field.value}
                                                                    acceptCustomValue={false}
                                                                    onValueChange={(value) => {
                                                                        var objFromDate = new Date(value);
                                                                        var objToDate = new Date(toDate);

                                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "From date can't be greater than To date",
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        } else {
                                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                            setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="flex-column mr-1">
                                                        <label className="label-control secondary">To Date</label>
                                                        <Controller
                                                            className="flex-column"
                                                            name="to_date"
                                                            control={control}
                                                            defaultValue={toDate}
                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    pickerType="calendar"
                                                                    placeholder="To Date"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    showClearButton={false}
                                                                    value={field.value}
                                                                    acceptCustomValue={false}
                                                                    onValueChange={(value) => {
                                                                        var objFromDate = new Date(fromDate);
                                                                        var objToDate = new Date(value);

                                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "To date must be greater than from date",
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        } else {
                                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                            setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6 pt-1">
                                            <div className="form-group cust-tagbox">
                                                <label className="label-control">Type of Policy&nbsp;</label>
                                                <Controller
                                                    name="policy_type"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={new ArrayStore({
                                                                data: policyTypeDropdown,
                                                                key: 'id'
                                                            })}
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="name"
                                                            placeholder="Select Type of Policy"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setPolicyTypeValue(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 pt-1">
                                            <div className="form-group">
                                                <label className="label-control">Insurance Company&nbsp;</label>
                                                <Controller
                                                    name="insurance_company"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={new ArrayStore({
                                                                data: insuredCompany,
                                                                key: 'id'
                                                            })}
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="name"
                                                            placeholder="Select Insurance Company"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                setInsuranceCompanyValue(value);
                                                                field.onChange(value);
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 pt-1">
                                            <div className="form-group">
                                                <label className="label-control">Sum Insured&nbsp;</label>
                                                <Controller
                                                    name="sum_insured"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={objSumInsured}
                                                            fields={{ text: 'title', value: 'id' }}
                                                            allowFiltering={true}
                                                            placeholder="Select Sum Insured"
                                                            value={field.value}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    setMin(event.itemData.min)
                                                                    setMax(event.itemData.max)
                                                                    setSumInsured(event.itemData)
                                                                    field.onChange(event.itemData.id)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 pt-1">
                                            <div className="form-group">
                                                <label className="label-control">Gross Premium&nbsp;</label>
                                                <Controller
                                                    name="gross_premium"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={objPremium}
                                                            fields={{ text: 'title', value: 'id' }}
                                                            allowFiltering={true}
                                                            placeholder="Select Gross Premium"
                                                            value={field.value}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    setMinGrossPremium(event.itemData.min)
                                                                    setMaxGrossPremium(event.itemData.max)
                                                                    setPremium(event.itemData)
                                                                    field.onChange(event.itemData.id)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ml-half">
                                        <div className="col-4 col-md-2 col-lg-2 col-xl-2 mr-3">
                                            <div className="form-actions  border-0 float-none">
                                                <button
                                                    type='submit'
                                                    className="btn btn-primary mr-8  btn-md w-60 shadow round"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        $("#progress-loader").removeClass("hidden");
                                                        if (props.type === 'create') {
                                                            props.actionDashboardPolicy({
                                                                "insurance_company": insuranceCompanyValue,
                                                                "policy_type": policyTypeValue,
                                                                "min_sum_insured": min,
                                                                "max_sum_insured": max,
                                                                "min_gross_premium": minGrossPremium,
                                                                "max_gross_premium": maxGrossPremium,
                                                                "department_id": props?.department,
                                                                "rm_id": props?.rm_id
                                                                // "from_date": fromDate,
                                                                // "to_date": toDate
                                                            })
                                                        } else if (props.type === 'view') {
                                                            props.actionPolicyList({
                                                                "insurance_company": insuranceCompanyValue,
                                                                "policy_type": policyTypeValue,
                                                                "min_sum_insured": min,
                                                                "max_sum_insured": max,
                                                                "min_gross_premium": minGrossPremium,
                                                                "max_gross_premium": maxGrossPremium,
                                                                "department_id": props?.department,
                                                                "rm_id": props?.rm_id,
                                                                "days_count": props?.days_count,
                                                                "title": props.title
                                                                // "from_date": fromDate,
                                                                // "to_date": toDate
                                                            });
                                                        }

                                                        var Filter = {};

                                                        Filter.policy_type = policyTypeValue;
                                                        Filter.insurance_company = insuranceCompanyValue;
                                                        Filter.min_sum_insured = min;
                                                        Filter.max_sum_insured = max;
                                                        Filter.min_gross_premium = minGrossPremium;
                                                        Filter.max_gross_premium = maxGrossPremium;
                                                        Filter.sumInsured = sumInsured;
                                                        Filter.premium = premium;
                                                        props.handleClose(Filter);
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        Apply
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                            <div className="form-actions border-0 float-none">
                                                <button
                                                    id="policy-reset-popup"
                                                    type='button'
                                                    className="btn btn-primary  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        $("#policy-reset-popup-loader").removeClass("hidden");
                                                        $("#policy-reset-popup").prop("disabled", true);
                                                        setSumInsuredDropdown(true)
                                                        setPremiumDropdown(true)
                                                        setInsuredCompany([]);
                                                        setPolicyTypeDropdown([]);
                                                        setPolicyTypeValue(null);
                                                        setInsuranceCompanyValue(null);
                                                        setObjSumInsured([]);
                                                        setPremium([])
                                                        setObjPremium([])
                                                        setValue('policy_type', null);
                                                        setValue('insurance_company', null);
                                                        setValue('sum_insured', null);
                                                        setValue('gross_premium', null);
                                                        setMin(null);
                                                        setMax(null);
                                                        setMinNetPremium(null);
                                                        setMaxNetPremium(null);
                                                        setMinGrossPremium(null);
                                                        setMaxGrossPremium(null);
                                                        props.actionPolicyType();
                                                        props.actionInsuredCompany();
                                                        props.actionSumInsuredPolicy({ product: props.department });
                                                        props.actionPremiumPolicy({ product: props.department })
                                                    }
                                                    }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Reset
                                                        <span id="policy-reset-popup-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )

}
const mapStateToProps = (state) => {
    var PolicyTypeDropdown = null;
    var InsuredCompany = null;
    var SumInsuredPolicy = null;
    var PremiumPolicy = null;

    if (
        state.PolicyFilterReducer != null &&
        Object.keys(state.PolicyFilterReducer).length > 0 &&
        state.PolicyFilterReducer.constructor === Object
    ) {
        switch (state.PolicyFilterReducer.tag) {
            case ACTION_PREMIUM_SUCCESS:
                PremiumPolicy = Object.assign({}, state.PolicyFilterReducer.data);
                delete state.PolicyFilterReducer;
                return { PremiumPolicy };
            case ACTION_MASTER_POLICY_TYPE_SUCCESS:
                PolicyTypeDropdown = Object.assign({}, state.PolicyFilterReducer.data);
                delete state.PolicyFilterReducer;
                return { PolicyTypeDropdown };
            case ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS:
                InsuredCompany = Object.assign({}, state.PolicyFilterReducer.data);
                delete state.PolicyFilterReducer;
                return { InsuredCompany };
            case ACTION_SUM_INSURED_POLICY_SUCCESS_VIEW:
                SumInsuredPolicy = Object.assign({}, state.PolicyFilterReducer.data);
                delete state.PolicyFilterReducer;
                return { SumInsuredPolicy };
            default:
        }
    }
    return {
        PolicyTypeDropdown,
        InsuredCompany,
        SumInsuredPolicy,
        PremiumPolicy,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionPolicyType,
            actionInsuredCompany,
            actionSumInsuredPolicy,
            actionPremiumPolicy,
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PolicyFilter))
