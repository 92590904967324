import { ACTION_LEAVE_REQUEST_LIST_SUCCESS, ACTION_WORKING_DAYS_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeaveRequestListReducer = createReducer(INITIAL_STATE, {
    [ACTION_LEAVE_REQUEST_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_LEAVE_REQUEST_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_WORKING_DAYS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_WORKING_DAYS_SUCCESS,
                loading: false,
            }
        );
    },
});

export default LeaveRequestListReducer;