
import {
    CLIENT_ALL_CLAIMS_SUCCESS,
} from './action';
    import createReducer from '../../../../../../../reducers/createReducer';
    
const INITIAL_STATE = {
        data: [],
        loading: false
    };
    
const ClientTabClaimsReducer = createReducer(
    INITIAL_STATE,
    {
        [CLIENT_ALL_CLAIMS_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: CLIENT_ALL_CLAIMS_SUCCESS,
                loading: false
            });
        }
    }
);
export default ClientTabClaimsReducer;
    