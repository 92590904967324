import React, { useEffect } from "react";
import $ from "jquery";
import { useForm } from "react-hook-form";
import { actionEditEmployment, ACTION_EDIT_EMPLOYMENT_SUCCESS } from './action';
import { actionGetEmploymentDetail, ACTION_GET_EMPLOYMENT_DETAIL_SUCCESS } from './action';
import { withAuth } from "react-auth-guard";
import ErrorLabel from "../../../../../../component/form/error_label";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
const EmploymentEdit = (props) => {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const initialRender = () => {
        if (props.show === true) {
            props.actionGetEmploymentDetail({ id: props.employmentId })
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onEditEmploymentUpdate = () => {
        const { EditEmployment } = props;
        var EditEmploymentResponse = Object.assign({}, EditEmployment);
        $("#edit_employment").prop("disabled", false);
        if (EditEmploymentResponse.result === true) {
            props.handleClose(
                EditEmploymentResponse.response
            );
            $("#edit_employment-loader").addClass("hidden");
        } else {
            $("#edit_employment-loader").addClass("hidden");
            switch (EditEmploymentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(EditEmploymentResponse.message);
                default:
            }
        }
    }
    useEffect(onEditEmploymentUpdate, [props.EditEmployment]);

    const onGetEmploymentDetailUpdate = () => {
        const { GetEmploymentDetail } = props;
        var GetEmploymentDetailResponse = Object.assign({}, GetEmploymentDetail);
        if (GetEmploymentDetailResponse.result === true) {
            setValue('name_of_organization', GetEmploymentDetailResponse.response.name_of_organization);
            setValue('duration', GetEmploymentDetailResponse.response.duration);
            setValue('description', GetEmploymentDetailResponse.response.description);
            setValue('designation', GetEmploymentDetailResponse.response.designation);
            setValue('id', GetEmploymentDetailResponse.response.id);
            setValue('user_id', GetEmploymentDetailResponse.response.user_id);
        } else {
            switch (GetEmploymentDetailResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetEmploymentDetailUpdate, [props.GetEmploymentDetail])

    const onSubmit = (data) => {
        $("#edit_employment-loader").removeClass("hidden");
        $("#edit_employment").prop("disabled", true);
        props.actionEditEmployment(data);
    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="employment-edit"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="employment-edit">
                                    Edit Employment Details
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">

                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Name of organization&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="name_of_organization"
                                                            name="name_of_organization"
                                                            placeholder="Enter Name of organization"
                                                            {...register('name_of_organization', { required: 'Name of organization is required' })}
                                                        />
                                                        {errors.name_of_organization && <ErrorLabel message={errors.name_of_organization?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Duration&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="duration"
                                                            name="duration"
                                                            placeholder="Enter Duration"
                                                            {...register('duration', { required: 'Duration is required' })}
                                                        />
                                                        {errors.duration && <ErrorLabel message={errors.duration?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="description"
                                                            name="description"
                                                            placeholder="Enter Description"
                                                            {...register('description', { required: 'Description is required' })}
                                                        />
                                                        {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Designation&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="designation"
                                                            name="designation"
                                                            placeholder="Enter Designation"
                                                            {...register('designation', { required: 'Designation is required' })}
                                                        />
                                                        {errors.designation && <ErrorLabel message={errors.designation?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                id="edit_employment"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Submit
                                                    <span id="edit_employment-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>

                                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    props.handleClose();
                                                    event.preventDefault();
                                                }}
                                            > Cancel</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var EditEmployment = null;
    var GetEmploymentDetail = null;
    if (
        state.EditEmploymentReducer != null &&
        Object.keys(state.EditEmploymentReducer).length > 0 &&
        state.EditEmploymentReducer.constructor === Object
    ) {
        switch (state.EditEmploymentReducer.tag) {
            case ACTION_EDIT_EMPLOYMENT_SUCCESS:
                EditEmployment = Object.assign({}, state.EditEmploymentReducer.data);
                delete state.EditEmploymentReducer;
                return { EditEmployment };
            case ACTION_GET_EMPLOYMENT_DETAIL_SUCCESS:
                GetEmploymentDetail = Object.assign({}, state.EditEmploymentReducer.data);
                delete state.EditEmploymentReducer;
                return { GetEmploymentDetail };
            default:
        }
    }

    return {
        EditEmployment,
        GetEmploymentDetail
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionEditEmployment, actionGetEmploymentDetail }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EmploymentEdit))
