import { ACTION_API_RI_MEETINGS_SUCCESS, ACTION_RI_TEAM_VERTICAL_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const RiMeetingsReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_RI_MEETINGS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_RI_MEETINGS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RI_TEAM_VERTICAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RI_TEAM_VERTICAL_SUCCESS,
                loading: false,
            }
        );
    },
});
export default RiMeetingsReducer;