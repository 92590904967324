import React from "react";

const Footer = (props) => {
    return (
        <footer class="footer footer-static footer-light navbar-border" style={{zIndex: 3}}>
            <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
                <span class="float-md-left d-block d-md-inline-block">
                    Copyright &copy; {new Date().getFullYear()}&nbsp;
                    <a
                        class="text-bold-800 grey darken-2"
                        href="https://pincinsurance.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        PINC
                    </a>
                </span>
            </p>
        </footer>
    );
};

export default Footer;
