import React from 'react';
import { Route, Switch } from 'react-router';
import ClaimsDashboard from './dashboard';
import RegisterPinc from './register/pinc/add';
import RegisterMandate from './register/mandate';
import RegisterLossSiteVisit from './register/loss_site_visit';
import RegisterLossSiteVisitAdd from './register/loss_site_visit/add';
import PincPolicyList from './register/pinc/list';
import Claims from './list';
import ViewClaim from './view';
import EditClaim from './edit';
import ClaimDocument from './document/upload';
// import ClaimsDownloadList from './download/claim_MIS/list';
import ClaimsDownloadReport from './download/claim_MIS/list';
const ClaimsRoute = (props) => {
    const {match} = props
    return (
        <Switch>
            <Route path={`${match.url}/list`}  component={Claims}/>
            <Route path={`${match.url}/view`}  component={ViewClaim}/>
            <Route path={`${match.url}/edit`}  component={EditClaim}/>
            <Route path={`${match.url}/pinc/policy/list`}  component={PincPolicyList}/>
            <Route path={`${match.url}/register/pinc`}  component={RegisterPinc}/>
            <Route path={`${match.url}/register/mandate`}  component={RegisterMandate}/>
            <Route path={`${match.url}/register/loss-site-visit`}  component={RegisterLossSiteVisit}/>
            <Route path={`${match.url}/register/loss-site-visit/add`}  component={RegisterLossSiteVisitAdd}/>
            <Route path={`${match.url}/document/upload`}  component={ClaimDocument}/>
            {/* <Route path={`${match.url}/download/report`}  component={ClaimsDownloadList}/> */}
            <Route path={`${match.url}/download/report`}  component={ClaimsDownloadReport}/>
            <Route exact path={`${match.url}`} component={ClaimsDashboard}/>
        </Switch>
    )
}

export default ClaimsRoute