import React from 'react';
import { Route, Switch } from 'react-router';
import TeamMaster from './list';
import TeamMasterAdd from './add';
import TeamMasterEdit from './edit';
const TeamMasterRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/edit`} component={TeamMasterEdit} />
                <Route exact path={`${match.url}/add`} component={TeamMasterAdd} />
                <Route exact path={`${match.url}`} component={TeamMaster} />
            </Switch>
        </>
    )
}

export default TeamMasterRoute