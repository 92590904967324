import React, { useEffect, useState } from "react";
import ScreenTitle from "../../../../../../component/screen_title";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import {
  ERROR,
  PRIMARY_COLOR,
  UNAUTHORIZED,
} from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { withAuth } from "react-auth-guard";
import {
  actionReinsuranceCreate,
  ACTION_REINSURANCE_CREATE_SUCCESS,
} from "./action";
import {
  actionReinsuranceEnquiryMaster,
  ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS,
} from "./action";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ReinsuranceCreate = (props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [branch, setBranch] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [entryType, setEntryType] = useState([]);
  const [endorsementType, setEndorsementType] = useState([]);
  const [selectedEntryType, setSelectedEntryType] = useState(null);

  const initialRender = () => {
    props.actionReinsuranceEnquiryMaster();
  };
  useEffect(initialRender, []);

  const onReinsuranceEnquiryMasterUpdate = () => {
    const { ReinsuranceEnquiryMaster } = props;
    if (ReinsuranceEnquiryMaster != null) {
      var ReinsuranceEnquiryMasterResponse = Object.assign(
        {},
        ReinsuranceEnquiryMaster
      );
      if (ReinsuranceEnquiryMasterResponse.result === true) {
        setBranch(ReinsuranceEnquiryMasterResponse.response.enquiry_branch);
        setBusinessType(
          ReinsuranceEnquiryMasterResponse.response.enquiry_business_type
        );
        setEntryType(ReinsuranceEnquiryMasterResponse.response.entry_type);
        setEndorsementType(
          ReinsuranceEnquiryMasterResponse.response.endorsement_type
        );
      } else {
        switch (ReinsuranceEnquiryMasterResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  };
  useEffect(onReinsuranceEnquiryMasterUpdate, [props.ReinsuranceEnquiryMaster]);

  const onReinsuranceCreatesUpdate = () => {
    const { ReinsuranceCreates } = props;
    var ReinsuranceCreatesResponse = Object.assign({}, ReinsuranceCreates);
    $("#reinsurance-create").prop("disabled", false);
    if (ReinsuranceCreatesResponse.result === true) {
      $("#reinsurance-create-loader").addClass("hidden");
      Swal.fire({
        title: `Info`,
        text: `Reinsurance Enquiry No ${ReinsuranceCreatesResponse.response.pinc_ref_no} add Successfully`,
        showCancelButton: false,
        cancelButtonText: "Cancel",
        confirmButtonText: "Ok",
        confirmButtonColor: PRIMARY_COLOR,
      }).then((success) => {});
      props.history.goBack();
    } else {
      $("#reinsurance-create-loader").addClass("hidden");
      switch (ReinsuranceCreatesResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ReinsuranceCreatesResponse.message);
        default:
      }
    }
  };
  useEffect(onReinsuranceCreatesUpdate, [props.ReinsuranceCreates]);

  const onSubmit = (data) => {
    $("#reinsurance-create-loader").removeClass("hidden");
    $("#reinsurance-create").prop("disabled", true);
    props.actionReinsuranceCreate(data);
  };

  return (
    <>
      <ScreenTitle title="Add Enquiry" />
      <section>
        <div className="card">
          <div className="card-body">
            <form
              noValidate
              autoComplete="Off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-body">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Operation Person&nbsp;<span className="danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Operation Person"
                        id="operation_person"
                        name="operation_person"
                        disabled={true}
                        value={
                          props.auth.getUser().first_name +
                          " " +
                          props.auth.getUser().last_name
                        }
                        {...register("operation_person")}
                      />
                      {errors.operation_person && (
                        <ErrorLabel
                          message={errors.operation_person?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Branch&nbsp;<span className="danger">*</span>
                      </label>
                      <Controller
                        name="branch"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="branch"
                            name="branch"
                            dataSource={branch}
                            fields={{ text: "name", value: "name" }}
                            allowFiltering={true}
                            placeholder="Select Branch"
                            value={field.value}
                            change={(event) => {
                              field.onChange(event.itemData.name);
                            }}
                          />
                        )}
                        rules={{
                          required: "Branch is required",
                        }}
                      />
                      {errors.branch && (
                        <ErrorLabel message={errors.branch?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Entry Processed In&nbsp;
                        <span className="danger">*</span>
                      </label>
                      <Controller
                        name="type"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="type"
                            name="type"
                            dataSource={entryType}
                            fields={{ text: "name", value: "name" }}
                            allowFiltering={false}
                            placeholder="Select Entry Processed In"
                            value={field.value}
                            change={(event) => {
                                if(event.itemData?.name != 'Endorsement'){
                                    setValue("endorsement_type", '');
                                }
                              setSelectedEntryType(event.itemData.name);
                              field.onChange(event.itemData.name);
                            }}
                          />
                        )}
                        rules={{
                          required: "Entry Processed In is required",
                        }}
                      />
                      {errors.type && (
                        <ErrorLabel message={errors.type?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Business Type&nbsp;<span className="danger">*</span>
                      </label>
                      <Controller
                        name="business_type"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="business_type"
                            name="business_type"
                            dataSource={businessType}
                            fields={{ text: "name", value: "name" }}
                            allowFiltering={false}
                            placeholder="Select Business Type"
                            value={field.value}
                            change={(event) => {
                              field.onChange(event.itemData.name);
                            }}
                          />
                        )}
                        rules={{
                          required: "Business Type is required",
                        }}
                      />
                      {errors.business_type && (
                        <ErrorLabel message={errors.business_type?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Cedant/Broker&nbsp;<span className="danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Cedant/Broker"
                        id="cedant"
                        name="cedant"
                        {...register("cedant", {
                          required: "Cedant/Broker is required",
                        })}
                      />
                      {errors.cedant && (
                        <ErrorLabel message={errors.cedant?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Insured / Treaty Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Insured / Treaty Name"
                        id="insured_treaty_name"
                        name="insured_treaty_name"
                        {...register("insured_treaty_name")}
                      />
                      {errors.insured_treaty_name && (
                        <ErrorLabel
                          message={errors.insured_treaty_name?.message}
                        />
                      )}
                    </div>
                  </div>
                  {selectedEntryType === "Endorsement" ? (
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control ">
                          Type of Endorsement
                        </label>
                        <Controller
                          name="endorsement_type"
                          className="form-control"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              id="endorsement_type"
                              name="endorsement_type"
                              dataSource={endorsementType}
                              fields={{ text: "name", value: "name" }}
                              allowFiltering={false}
                              placeholder="Select Type of Endorsement"
                              value={field.value}
                              change={(event) =>
                                field.onChange(event.itemData.name)
                              }
                            />
                          )}
                        />
                        {errors.endorsement_type && (
                          <ErrorLabel
                            message={errors.endorsement_type?.message}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">
                        Endorsement Control Number
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Endorsement Control Number"
                        id="endorsement_no"
                        name="endorsement_no"
                        {...register("endorsement_no")}
                      />
                      {errors.endorsement_no && (
                        <ErrorLabel message={errors.endorsement_no?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">Cover BIND</label>
                      <Controller
                        name="cover_bind"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="cover_bind"
                            name="cover_bind"
                            dataSource={[
                              {
                                id: "InProgress",
                                name: "In Progress",
                              },
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            placeholder="Select Cover BIND"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.cover_bind && (
                        <ErrorLabel message={errors.cover_bind?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Deal Documents Saved
                      </label>
                      <Controller
                        name="document_saved"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="document_saved"
                            name="document_saved"
                            dataSource={[
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            placeholder="Select Deal Documents Saved"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.document_saved && (
                        <ErrorLabel message={errors.document_saved?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">Approval Status</label>
                      <Controller
                        name="approved"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="approved"
                            name="approved"
                            dataSource={[
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            placeholder="Select Approval Status"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.approved && (
                        <ErrorLabel message={errors.approved?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control ">
                        Signed Documents Received
                      </label>
                      <Controller
                        name="signed_document_received"
                        className="form-control"
                        control={control}
                        render={({ field }) => (
                          <DropDownListComponent
                            id="signed_document_received"
                            name="signed_document_received"
                            dataSource={[
                              {
                                id: "Yes",
                                name: "Yes",
                              },
                              {
                                id: "No",
                                name: "No",
                              },
                            ]}
                            fields={{ text: "name", value: "id" }}
                            allowFiltering={false}
                            placeholder="Select Signed Documents Received"
                            value={field.value}
                            change={(event) =>
                              field.onChange(event.itemData.id)
                            }
                          />
                        )}
                      />
                      {errors.signed_documents_received && (
                        <ErrorLabel
                          message={errors.signed_documents_received?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label className="label-control">RI Team Remark</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter RI Team Remark"
                        id="ri_remark"
                        name="ri_remark"
                        maxLength={250}
                        {...register("ri_remark")}
                      />
                      {errors.ri_remark && (
                        <ErrorLabel message={errors.ri_remark?.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-actions border-0 float-none">
                <button
                  id="reinsurance-create"
                  className="btn btn-primary mr-1 shadow round text-uppercase"
                  type="submit"
                >
                  <div className="d-flex align-items-center">
                    Submit
                    <span
                      id="reinsurance-create-loader"
                      className="spinner-border spinner-border-sm ml-half hidden"
                    ></span>
                  </div>
                </button>
                <button
                  className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.goBack();
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  var ReinsuranceCreates = null;
  var ReinsuranceEnquiryMaster = null;

  if (
    state.ReinsuranceCreateReducer != null &&
    Object.keys(state.ReinsuranceCreateReducer).length > 0 &&
    state.ReinsuranceCreateReducer.constructor === Object
  ) {
    switch (state.ReinsuranceCreateReducer.tag) {
      case ACTION_REINSURANCE_CREATE_SUCCESS:
        ReinsuranceCreates = Object.assign(
          {},
          state.ReinsuranceCreateReducer.data
        );
        delete state.ReinsuranceCreateReducer;
        return { ReinsuranceCreates };
      case ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS:
        ReinsuranceEnquiryMaster = Object.assign(
          {},
          state.ReinsuranceCreateReducer.data
        );
        delete state.ReinsuranceCreateReducer;
        return { ReinsuranceEnquiryMaster };
      default:
    }
  }
  return {
    ReinsuranceCreates,
    ReinsuranceEnquiryMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { actionReinsuranceCreate, actionReinsuranceEnquiryMaster },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ReinsuranceCreate)
);
