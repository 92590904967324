import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_EDIT_WELLNESS_CLIENT_NAME_SUCCESS, ACTION_EDIT_WELLNESS_CLIENT_TYPE_SUCCESS, ACTION_EDIT_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS, ACTION_EDIT_WELLNESS_REQUEST_SUCCESS, ACTION_EDIT_WELLNESS_TYPE_SUCCESS, ACTION_VIEW_WELLNESS_REQUEST_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const editWellnessManagementReducer = createReducer(INITIAL_STATE, {
    [ACTION_VIEW_WELLNESS_REQUEST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_VIEW_WELLNESS_REQUEST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_REQUEST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_REQUEST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_CLIENT_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_CLIENT_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
}
)
export default editWellnessManagementReducer