import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import ChangeStatus from '../../../../../../component/popups/approval_action';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { REQUEST_LEAVE, REVIEW_BY_HR, REVIEW_BY_SUPERIOR, UNAUTHORIZED } from '../../../../../../constants';
import { actionLeaveApprovalView, ACTION_LEAVE_APPROVAL_VIEW_SUCCESS } from './action';
import { generateInitialLettersString } from '../../../../../../utils';
import RequestHistory from '../../../../../../component/widgets/request_history';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const LeaveApprovalsView = (props) => {
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [leaveApprovalView, setLeaveApprovalView] = useState([]);
    const [requestHistory, setRequestHistory] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [detailsData, setDetailsData] = useState(null);

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        setDetailsData(JSON.parse(localStorage.getItem('detailsLeaveData')))
    }

    useEffect(() => {
        if (detailsData != null||  props?.location?.state?.id) {
            props.actionLeaveApprovalView({
                id: detailsData?.id|| props?.location?.state?.id
            });
        }
    }, [detailsData, props?.location?.state?.id])


    useEffect(initialRender, []);
    const onLeaveApprovalViewUpdate = () => {
        const { LeaveApprovalView } = props;
        var LeaveApprovalViewResponse = Object.assign({}, LeaveApprovalView);
        if (LeaveApprovalViewResponse.result === true) {
            setLeaveApprovalView(LeaveApprovalViewResponse.response);

            if (LeaveApprovalViewResponse.response.request_status.length > 0) {
                setRequestHistory(LeaveApprovalViewResponse.response.request_status);
            }

        } else {
            switch (LeaveApprovalViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onLeaveApprovalViewUpdate, [props.LeaveApprovalView])
    return (
        <>
            <ScreenTitle title="Leave Request Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (leaveApprovalView != null && leaveApprovalView.profile_photo != null) ?
                                                <img
                                                    src={leaveApprovalView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(leaveApprovalView.full_name))}

                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    leaveApprovalView != null && leaveApprovalView.full_name != null ?
                                                        leaveApprovalView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success text-bold-500 round">
                                                {
                                                    leaveApprovalView != null && leaveApprovalView.designation_name != null ?
                                                        leaveApprovalView.designation_name : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        leaveApprovalView != null && leaveApprovalView.status != null ?
                                                            leaveApprovalView.status : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2">By <span className="text-bold-700 text-justify">
                                                {
                                                    leaveApprovalView != null && leaveApprovalView?.status != null &&
                                                        leaveApprovalView.status != 'Cancel By Employee' ? leaveApprovalView?.approval_manager_name : leaveApprovalView?.full_name
                                                }
                                            </span> On: <span className="text-primary">
                                                    {
                                                        leaveApprovalView != null && leaveApprovalView.modified_at != null ?
                                                            leaveApprovalView.modified_at : '-'
                                                    }
                                                </span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Full Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.full_name != null ?
                                            leaveApprovalView.full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.approval_manager_name != null ?
                                            leaveApprovalView.approval_manager_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Leave Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.leave_type_name != null ?
                                            leaveApprovalView.leave_type_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Reason</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.reason != null ?
                                            leaveApprovalView.reason : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">From Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.from_date != null ?
                                            leaveApprovalView.from_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">To Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.to_date != null ?
                                            leaveApprovalView.to_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.status != null ?
                                            leaveApprovalView.status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.remark != null ?
                                            leaveApprovalView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Upload Document</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveApprovalView != null && leaveApprovalView.medical_document != null ?
                                            // <a target="_blank" rel="noreferrer" href={leaveApprovalView.medical_document} download="Leave Document">View Document</a> : <>-</>
                                            <>
                                                <a className="primary"
                                                    onClick={() => {
                                                        if (leaveApprovalView.medical_document?.split('.').pop() == 'pdf' ||
                                                            leaveApprovalView.medical_document?.split('.').pop() == 'PDF' ||
                                                            leaveApprovalView.medical_document?.split('.').pop() == 'png' ||
                                                            leaveApprovalView.medical_document?.split('.').pop() == 'jpg' ||
                                                            leaveApprovalView.medical_document?.split('.').pop() == 'jpeg' ||
                                                            leaveApprovalView.medical_document?.split('.').pop() == 'gif'
                                                        ) {
                                                            setSelectedDocumentIndex(leaveApprovalView.medical_document)
                                                            setShowDocumentDetails(true);
                                                            setExtension(leaveApprovalView.medical_document?.split('.').pop())
                                                            setDocumentDetails(leaveApprovalView)
                                                        } else {
                                                            downloadFile(leaveApprovalView.medical_document);
                                                        }
                                                    }}
                                                >
                                                    View Document
                                                </a>
                                                <a id="downloadLink" className="d-none"></a>
                                            </>
                                            :
                                            <>-</>
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }

                        <div className="form-actions border-0 pt-1 float-none">
                            {
                                leaveApprovalView != null && (leaveApprovalView.status === REVIEW_BY_HR && leaveApprovalView.is_hr === 1) ?
                                    <button
                                        id="your_action"
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            setShowChangeStatus(true);
                                        }}
                                    >Your Action</button>
                                    :
                                    <></>
                            }
                            {
                                leaveApprovalView != null && (leaveApprovalView.status === REVIEW_BY_SUPERIOR) ?
                                    <button
                                        id="your_action"
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            setShowChangeStatus(true);
                                        }}
                                    >Your Action</button>
                                    :
                                    <></>
                            }
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'LEAVE_REQUEST_CREATE_AM_RM' || props?.location?.state?.type === 'LEAVE_REVIEW_SUPERVISOR') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        window.close()
                                    }
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
                <ChangeStatus
                    approval={leaveApprovalView}
                    show={showChangeStatus}
                    type={REQUEST_LEAVE}
                    handleClose={() => {
                        setShowChangeStatus(false);
                        setRequestHistory(null);
                        props.actionLeaveApprovalView({
                            id: detailsData?.id,
                        });
                    }}
                />
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var LeaveApprovalView = null;

    if (
        state.LeaveApprovalViewReducer != null &&
        Object.keys(state.LeaveApprovalViewReducer).length > 0 &&
        state.LeaveApprovalViewReducer.constructor === Object
    ) {
        switch (state.LeaveApprovalViewReducer.tag) {
            case ACTION_LEAVE_APPROVAL_VIEW_SUCCESS:
                LeaveApprovalView = Object.assign({}, state.LeaveApprovalViewReducer.data);
                delete state.LeaveApprovalViewReducer;
                return { LeaveApprovalView };
            default:
        }
    }

    return {
        LeaveApprovalView
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionLeaveApprovalView }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LeaveApprovalsView));

