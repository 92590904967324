import React from 'react';
import { Route, Switch } from 'react-router';
import TPAMeetingAdd from './add';
import TPAMeetingEdit from './edit';
import TPAMeetingList from './list';
import TPAMeetingView from './view';

const TPAMeetingRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={TPAMeetingAdd}/>
                <Route path={`${match.url}/edit`}  component={TPAMeetingEdit}/>
                <Route path={`${match.url}/view`}  component={TPAMeetingView}/>
                <Route exact path={`${match.url}`}  component={TPAMeetingList}/>
            </Switch>
        </>
    )
}

export default TPAMeetingRoute