import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from "react-icons/fi";
import {
  actionBirthdayWishLogs,
  ACTION_BIRTHDAY_WISH_LOGS_SUCCESS,
} from "./action";
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import moment from "moment";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
} from "../../../../../../utils/preferences";

const BirthDayWishLogs = (props) => {
  const { match } = props;
  const [birthdayWishLogs, setBirthdayWishLogs] = useState([]);

  const initialRender = () => {
    $("#progress-loader").removeClass("hidden");

    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }

    props.actionBirthdayWishLogs();
  };
  useEffect(initialRender, []);

  const onBirthdayWishLogsUpdate = () => {
    const { BirthdayWishLogs } = props;
    if (BirthdayWishLogs != null) {
      var BirthdayWishLogsResponse = Object.assign({}, BirthdayWishLogs);
      if (BirthdayWishLogsResponse.result === true) {
        $("#progress-loader").addClass("hidden");
        setBirthdayWishLogs(BirthdayWishLogsResponse.response);
      } else {
        $("#progress-loader").addClass("hidden");
        switch (BirthdayWishLogsResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  };
  useEffect(onBirthdayWishLogsUpdate, [props.BirthdayWishLogs]);

  const [columns, setColumns] = useState([
    { dataField: "name", caption: "Name", visible: true },
    { dataField: "mobile", caption: "Number", visible: true },
    { dataField: "email", caption: "Email", visible: true },
    { dataField: "type", caption: "Type", visible: true },
    { dataField: "formated_created_at", caption: "Sent On", visible: true },
  ]);

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            visible={objColumn.visible}
          ></Column>
        );
    });
    return arrColumns;
  };

  return (
    <>
      <div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-1">
              Birthday Wish Logs
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <DataGrid
                  dataSource={birthdayWishLogs}
                  showBorders={true}
                  columnAutoWidth={true}
                  export={{
                    fileName: "Birthday Wish Logs",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                  }}
                >
                  <SearchPanel visible={true} />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  {renderColumns()}
                  <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                  />
                  <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  var BirthdayWishLogs = null;

  if (
    state.BirthdayWishLogsReducer != null &&
    Object.keys(state.BirthdayWishLogsReducer).length > 0 &&
    state.BirthdayWishLogsReducer.constructor === Object
  ) {
    switch (state.BirthdayWishLogsReducer.tag) {
      case ACTION_BIRTHDAY_WISH_LOGS_SUCCESS:
        BirthdayWishLogs = Object.assign({}, state.BirthdayWishLogsReducer.data);
        delete state.BirthdayWishLogsReducer;
        return { BirthdayWishLogs };
      default:
    }
  }
  return {
    BirthdayWishLogs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionBirthdayWishLogs }, dispatch);
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(BirthDayWishLogs)
);
