import React, { useEffect, useState } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { actionFinanceLimitEdit, ACTION_FINANCE_LIMIT_EDIT_SUCCESS } from './action';
import { actionMasterEntityEdit, ACTION_FINANCE_LIMIT_ENTITY_EDIT_SUCCESS } from './action';
import { actionFinanceMasterDepartmentEdit, ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_SUCCESS } from './action';
import { actionFinanceMasterDesignationEdit, ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_SUCCESS } from './action';
import { actionFinanceLimitView, ACTION_FINANCE_LIMIT_VIEW_SUCCESS } from './action';
import { toast } from "react-toastify";

const Edit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, setError } = useForm();
    const [masterEntity, setMasterEntity] = useState([]);
    const [masterDepartment, setMasterDepartment] = useState([]);
    const [masterDesignation, setMasterDesignation] = useState([]);
    const [financeManeger, setFinanceManager] = useState(null);
    const [reimbursementType, setReimbursementType] = useState([]);

    const initialRender = () => {
        props.actionFinanceLimitView({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (financeManeger != null) {
            props.actionMasterEntityEdit();
            props.actionFinanceMasterDesignationEdit();
        }
    }, [financeManeger])

    const onFinanceMasterEntityEditUpdate = () => {
        const { FinanceEntityEdit } = props;
        var FinanceEntityEditResponse = Object.assign({}, FinanceEntityEdit);
        if (FinanceEntityEditResponse.result === true) {
            setMasterEntity(FinanceEntityEditResponse.response);
        } else {
            switch (FinanceEntityEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceMasterEntityEditUpdate, [props.FinanceEntityEdit]);

    const onFinanceMasterDepartmentEditUpdate = () => {
        const { FinanceDepartmentEdit } = props;
        var FinanceDepartmentEditResponse = Object.assign({}, FinanceDepartmentEdit);
        if (FinanceDepartmentEditResponse.result === true) {
            setMasterDepartment(FinanceDepartmentEditResponse.response);
        } else {
            switch (FinanceDepartmentEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceMasterDepartmentEditUpdate, [props.FinanceDepartmentEdit]);

    const onFinanceMasterDesignationEditUpdate = () => {
        const { FinanceDesignationEdit } = props;
        var FinanceDesignationEditResponse = Object.assign({}, FinanceDesignationEdit);
        if (FinanceDesignationEditResponse.result === true) {
            setMasterDesignation(FinanceDesignationEditResponse.response);
        } else {
            switch (FinanceDesignationEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceMasterDesignationEditUpdate, [props.FinanceDesignationEdit]);

    const onFinanceLimitViewUpdate = () => {
        const { FinanceLimitView } = props;
        var FinanceLimitViewResponse = Object.assign({}, FinanceLimitView);
        if (FinanceLimitViewResponse.result === true) {
            setFinanceManager(FinanceLimitViewResponse.response);
            setReimbursementType(FinanceLimitViewResponse.response.reimbursement_types);
            setValue('entity_id', FinanceLimitViewResponse.response.entity_id);
            setValue('department_id', FinanceLimitViewResponse.response.department_id);
            setValue('designation_id', FinanceLimitViewResponse.response.designation_id);
            setValue('status', FinanceLimitViewResponse.response.status);

        } else {
            switch (FinanceLimitViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceLimitViewUpdate, [props.FinanceLimitView]);

    useEffect(() => {
        if (masterEntity != null && masterEntity.length > 0) {
            if (financeManeger != null) {
                setValue('entity_id', financeManeger.entity_id);
            }
        }
    }, [masterEntity])

    useEffect(() => {
        if (masterDepartment != null && masterDepartment.length > 0) {
            if (financeManeger != null) {
                setValue('department_id', financeManeger.department_id);
            }
        }
    }, [masterDepartment])

    useEffect(() => {
        if (masterDesignation != null && masterDesignation.length > 0) {
            if (financeManeger != null) {
                setValue('designation_id', financeManeger.designation_id);
            }
        }
    }, [masterDesignation])

    const onFinanceLimitEditUpdate = () => {
        const { FinanceLimitEdit } = props;
        var FinanceLimitEditResponse = Object.assign({}, FinanceLimitEdit);
        if (FinanceLimitEditResponse.result === true) {
            props.history.goBack();
        } else {
            switch (FinanceLimitEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(FinanceLimitEditResponse.message);
                default:
            }
        }
    }
    useEffect(onFinanceLimitEditUpdate, [props.FinanceLimitEdit]);

    const onSubmit = (data) => {
        data.id = financeManeger.id;
        data.rem_type = reimbursementType.map((objRem, index) => ({ type_id: objRem.type_id, limit: objRem.value != null ? objRem.value : objRem.limit }));
        props.actionFinanceLimitEdit(data);
    }

    return (
        <>
            <ScreenTitle title=" Edit Reimbursement Limit" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Entity&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="entity_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="entity_id"
                                                                name="entity_id"
                                                                dataSource={masterEntity}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Entity"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)

                                                                        var arrEntityIds = [];

                                                                        arrEntityIds.push(event.itemData.id);
                                                                        props.actionFinanceMasterDepartmentEdit({ 'company_ids': arrEntityIds });

                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Entity'
                                                        }}
                                                    />
                                                    {errors.entity_id && <ErrorLabel message={errors.entity_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Department&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="department_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="department_id"
                                                                name="department_id"
                                                                dataSource={masterDepartment}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Department"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)

                                                                        var arrDepartmentIds = [];

                                                                        arrDepartmentIds.push(event.itemData.id);

                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Department'
                                                        }}
                                                    />
                                                    {errors.department_id && <ErrorLabel message={errors.department_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Designation&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="designation_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="designation_id"
                                                                name="designation_id"
                                                                dataSource={masterDesignation}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Designation"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)

                                                                        var arrDesignationIds = [];

                                                                        arrDesignationIds.push(event.itemData.id);


                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Designation'
                                                        }}
                                                    />
                                                    {errors.designation_id && <ErrorLabel message={errors.designation_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        title: "Active",
                                                                        id: 'A'
                                                                    },
                                                                    {
                                                                        title: "Inactive",
                                                                        id: 'I'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Reimbursement Type</th>
                                                            <th>Limit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            reimbursementType.map((item, index) => (
                                                                <tr>
                                                                    <td>{item.title}</td>
                                                                    <td>
                                                                        <fieldset className="form-group">
                                                                            <Controller
                                                                                name={`rem_type[${index}].limit`}
                                                                                control={control}
                                                                                render={({ field }) =>
                                                                                    <input
                                                                                        {...field}
                                                                                        id="rem_limit"
                                                                                        className="form-control"
                                                                                        placeholder="Enter Limit"
                                                                                        value={field.value != null ? field.value : item.limit}
                                                                                        onChange={(event) => {
                                                                                            if (event.target.value != null) {
                                                                                                field.onChange(event.target.value)
                                                                                                var arrRemType = Object.assign([], reimbursementType);
                                                                                                var objRemType = Object.assign({}, arrRemType[index]);
                                                                                                objRemType.value = event.target.value
                                                                                                arrRemType[index] = objRemType
                                                                                                setReimbursementType(arrRemType);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                rules={
                                                                                    require = "Please enter the limit"
                                                                                }
                                                                            />
                                                                            {errors.rem_limit && <ErrorLabel message={errors.rem_limit?.message} />}
                                                                        </fieldset>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="add_shift"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add_shift-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
const mapStateToProps = (state) => {
    var FinanceLimitEdit = null;
    var FinanceEntityEdit = null;
    var FinanceDepartmentEdit = null;
    var FinanceDesignationEdit = null;
    var FinanceLimitView = null;

    if (
        state.EditFinanceLimitReducer != null &&
        Object.keys(state.EditFinanceLimitReducer).length > 0 &&
        state.EditFinanceLimitReducer.constructor === Object
    ) {
        switch (state.EditFinanceLimitReducer.tag) {
            case ACTION_FINANCE_LIMIT_EDIT_SUCCESS:
                FinanceLimitEdit = Object.assign({}, state.EditFinanceLimitReducer.data);
                delete state.EditFinanceLimitReducer
                return { FinanceLimitEdit };
            case ACTION_FINANCE_LIMIT_ENTITY_EDIT_SUCCESS:
                FinanceEntityEdit = Object.assign({}, state.EditFinanceLimitReducer.data);
                delete state.EditFinanceLimitReducer
                return { FinanceEntityEdit };
            case ACTION_FINANCE_LIMIT_DEPARTMENT_EDIT_SUCCESS:
                FinanceDepartmentEdit = Object.assign({}, state.EditFinanceLimitReducer.data);
                delete state.EditFinanceLimitReducer
                return { FinanceDepartmentEdit };
            case ACTION_FINANCE_LIMIT_DESIGNATION_EDIT_SUCCESS:
                FinanceDesignationEdit = Object.assign({}, state.EditFinanceLimitReducer.data);
                delete state.EditFinanceLimitReducer
                return { FinanceDesignationEdit };
            case ACTION_FINANCE_LIMIT_VIEW_SUCCESS:
                FinanceLimitView = Object.assign({}, state.EditFinanceLimitReducer.data);
                delete state.EditFinanceLimitReducer
                return { FinanceLimitView };
            default:
        }
    }

    return {
        FinanceLimitEdit,
        FinanceEntityEdit,
        FinanceDepartmentEdit,
        FinanceDesignationEdit,
        FinanceLimitView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionFinanceLimitEdit, actionMasterEntityEdit, actionFinanceMasterDepartmentEdit, actionFinanceMasterDesignationEdit, actionFinanceLimitView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
