import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DateBox } from "devextreme-react/date-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Template } from "devextreme-react";
import { FiEye } from "react-icons/fi";
import { connect } from "react-redux";
import { TagBox } from "devextreme-react/tag-box";
import ArrayStore from "devextreme/data/array_store";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../../../../constants";
import {
  actionLeaveRequestList,
  actionWorkingdays,
  ACTION_LEAVE_REQUEST_LIST_SUCCESS,
  ACTION_WORKING_DAYS_SUCCESS,
} from "./action";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
} from "../../../../../../utils/preferences";
import { toast } from "react-toastify";
import { getDateFilter, getLeaveStatus } from "../../../../../../utils";
import { getLeaveType } from "../../../../../../utils";
import moment from "moment";
import { withAuth } from "react-auth-guard";
import dateFormat from "dateformat";
import FilterComponent from "../../../../../../component/hrms_smart_filters";

const LeaveList = (props) => {
  const { match } = props;

  const { control, setValue } = useForm({});

  const [leaveRequest, setLeaveRequest] = useState([]);
  const [policyTypeDropdown, setPolicyTypeDropdown] = useState([
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Approved",
      value: "approved",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Cancelled",
      value: "canceled",
    },
  ]);
  const [policyTypeValue, setPolicyTypeValue] = useState(["pending"]);
  let current_fy = Object.assign({}, getDateFilter("CURRENT_FY"));
  let previous_fy = Object.assign({}, getDateFilter("PREVIOUS_FY"));
  const [selectedOption, setSelectedOption] = useState(null);
 
  const handleFilterChange = (option) => {
    setSelectedOption(option);
    switch (option) {
      case "current_fy":
        setFromDate(current_fy.start);
        setToDate(current_fy.end);
        props.actionLeaveRequestList({
          from_date: current_fy.start,
          to_date: current_fy.end,
          status: policyTypeValue,
        });
        break;
      case "previous_fy":
        setFromDate(previous_fy.start);
        setToDate(previous_fy.end);
        props.actionLeaveRequestList({
          from_date: previous_fy.start,
          to_date: previous_fy.end,
          status: policyTypeValue,
        });
        break;
      case "all":
        setFromDate();
        setToDate();
        props.actionLeaveRequestList({
          status: policyTypeValue,
        });
        break;
      case "reset":
        $("#progress-loader").removeClass("hidden");
        setSelectedOption(null);
        setPolicyTypeValue(["pending"]);
        setFromDate(
          dateFormat(
            moment().subtract(1, "year").date(new Date().getDate()),
            "yyyy-mm-dd"
          )
        );
        setToDate(
          dateFormat(
            moment().add(1, "year").date(new Date().getDate()),
            "yyyy-mm-dd"
          )
        );
        setValue("status", ["pending"]);
        props.actionLeaveRequestList({
          from_date: fromDate,
          to_date: toDate,
          status: ["pending"],
        });
        break;
      default:
    }
  };
  const [fromDate, setFromDate] = useState(
    dateFormat(
      moment().subtract(1, "year").date(new Date().getDate()),
      "yyyy-mm-dd"
    )
  );
  const [toDate, setToDate] = useState(
    dateFormat(moment().add(1, "year").date(new Date().getDate()), "yyyy-mm-dd")
  );
  const [workingDay, setWorkingDay] = useState(5);

  const columns = [
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "request_date", caption: "From Date", visible: false },
    { dataField: "request_date_formated", caption: "From Date", visible: true },
    { dataField: "to_date", caption: "To Date", visible: false },
    { dataField: "to_date_formated", caption: "To Date", visible: true },
    { dataField: "total_leave_count", caption: "No. of Days", visible: true },
    { dataField: "leave_type", caption: "Leave Type", visible: true },
    { dataField: "reason", caption: "Reason", visible: true },
    { dataField: "status", caption: "Request Status", visible: true },
    { dataField: "remark", caption: "Remark", visible: true },
  ];

  const initialRender = () => {
    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }
    $("#progress-loader").removeClass("hidden");
    props.actionLeaveRequestList({
      from_date: fromDate,
      to_date: toDate,
      status: policyTypeValue,
    });
    props.actionWorkingdays();
  };
  useEffect(initialRender, []);

  const onLeaveRequestListUpdate = () => {
    const { LeaveRequestList } = props;
    if (LeaveRequestList != null) {
      setLeaveRequest([]);
      var LeaveRequestListResponse = Object.assign({}, LeaveRequestList);
      if (LeaveRequestListResponse.result === true) {
        setLeaveRequest(LeaveRequestListResponse.response);
        $("#progress-loader").addClass("hidden");
      } else {
        setLeaveRequest([]);
        $("#progress-loader").addClass("hidden");
        switch (LeaveRequestListResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            toast.success(
              "Your Login session has expired, Please try to Login again!"
            );
            break;
          default:
        }
      }
    }
  };
  useEffect(onLeaveRequestListUpdate, [props.LeaveRequestList]);

  useEffect(() => {
    $("#progress-loader").removeClass("hidden");
    props.actionLeaveRequestList({
      from_date: fromDate,
      to_date: toDate,
      status: policyTypeValue,
    });
  }, [policyTypeValue]);

  const renderStartDate = (cell) => {
    return (
      <div>
        {cell?.data?.request_date_formated != null
          ? moment(cell?.data?.request_date_formated).format("DD/MM/YYYY")
          : ""}
      </div>
    );
  };

  const renderEndDate = (cell) => {
    return (
      <div>
        {cell?.data?.to_date_formated != null
          ? moment(cell?.data?.to_date_formated).format("DD/MM/YYYY")
          : ""}
      </div>
    );
  };

  const renderStatus = (cell) => {
    return <div>{getLeaveStatus(cell.data.status)}</div>;
  };

  const renderLeaveType = (cell) => {
    return <div>{getLeaveType(cell.data.leave_type)}</div>;
  };

  const onWorkingDaysUpdate = () => {
    const { WorkingDays } = props;
    var WorkingDaysResponse = Object.assign({}, WorkingDays);
    if (WorkingDaysResponse.result === true) {
      setWorkingDay(WorkingDaysResponse.response.working_days);
    } else {
      switch (WorkingDaysResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onWorkingDaysUpdate, [props.WorkingDays]);

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="Action" type="buttons">
            <Button
              render={(params) => (
                <button
                  className="btn btn-icon btn-sm"
                  onClick={() => {
                    props.history.push({
                      pathname: `${match.url}/view`,
                      state: { id: params.data.id },
                    });
                  }}
                >
                  <FiEye size={16} className="primary" />
                </button>
              )}
            />
          </Column>
        );
      } else if (objColumn.dataField === "request_date_formated") {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            cellRender={renderStartDate}
            visible={objColumn.visible}
          ></Column>
        );
      } else if (objColumn.dataField === "to_date_formated") {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            cellRender={renderEndDate}
            visible={objColumn.visible}
          ></Column>
        );
      } else {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            visible={objColumn.visible}
          ></Column>
        );
      }
    });
    return arrColumns;
  };

  return (
    <div>
      <div className="content-header row mb-1">
        <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
          <h5 className="content-header-title text-capitalize mb-0">
            Leave Requests{" "}
            {leaveRequest.length === 0 ? "" : `(${leaveRequest.length})`}
          </h5>
        </div>
        <div className="content-header-right col-12 col-md-6 col-lg-6">
          <ul class="nav nav-pills float-right">
            <li class="nav-item">
              <button
                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                onClick={() => {
                  props.history.push(`${match.url}/add`, {
                    working_days: workingDay,
                  });
                }}
              >
                Raise New Leave Request
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-5">
                  <ul class="nav nav-pills d-md-flex d-lg-flex">
                    <li class="nav-item">
                      <Controller
                        name="from_date"
                        control={control}
                        defaultValue={fromDate}
                        render={({ field }) => (
                          <DateBox
                            calendarOptions={{
                              showTodayButton: true,
                            }}
                            stylingMode="underlined"
                            pickerType="calendar"
                            placeholder="From Date"
                            type="date"
                            displayFormat="dd/MM/yyyy"
                            showClearButton={false}
                            acceptCustomValue={false}
                            value={fromDate}
                            onValueChange={(value) => {
                              var strFromDate = dateFormat(value, "yyyy-mm-dd");
                              setFromDate(strFromDate);
                              field.onChange(value);
                              props.actionLeaveRequestList({
                                from_date: strFromDate,
                                to_date: toDate,
                                status: policyTypeValue,
                              });
                            }}
                          />
                        )}
                      />
                    </li>
                    <li class="nav-item ml-1  float-right">
                      <Controller
                        name="to_date"
                        control={control}
                        defaultValue={toDate}
                        render={({ field }) => (
                          <DateBox
                            calendarOptions={{
                              showTodayButton: true,
                            }}
                            stylingMode="underlined"
                            pickerType="calendar"
                            placeholder="To Date"
                            type="date"
                            displayFormat="dd/MM/yyyy"
                            showClearButton={false}
                            value={toDate}
                            acceptCustomValue={false}
                            onValueChange={(value) => {
                              field.onChange(value);

                              var strToDate = dateFormat(value, "yyyy-mm-dd");
                              setToDate(strToDate);

                              props.actionLeaveRequestList({
                                from_date: fromDate,
                                to_date: strToDate,
                                status: policyTypeValue,
                              });
                            }}
                          />
                        )}
                      />
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group cust-tagbox">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <TagBox
                          {...field}
                          dataSource={
                            new ArrayStore({
                              data: policyTypeDropdown,
                              key: "id",
                            })
                          }
                          dropDownOptions={{
                            onInitialized: () => {
                              console.log("initial");
                            },
                            onContentReady: () => {
                              console.log("content ready");
                            },
                            onShowing: () => {
                              console.log("showing");
                            },
                            onShown: () => {
                              console.log("shown");
                            },
                          }}
                          deferRendering={true}
                          displayExpr="name"
                          valueExpr="value"
                          name="status"
                          id="status"
                          placeholder="Select Status"
                          searchEnabled={true}
                          searchMode="contains"
                          maxDisplayedTags={2}
                          showMultiTagOnly={false}
                          stylingMode="underlined"
                          value={field.value ? field.value : policyTypeValue}
                          onValueChanged={({ value }) => {
                            if (value != null || value !== undefined) {
                              setPolicyTypeValue(value);
                              field.onChange(value);
                            }
                          }}
                          showClearButton={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 text-right">
                  <FilterComponent
                    selectedOption={selectedOption}
                    onFilterChange={handleFilterChange}
                  />
                </div>
              </div>
              <div>
                <DataGrid
                  dataSource={leaveRequest}
                  showBorders={true}
                  export={{
                    fileName: "Leave Requests",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                  }}
                  onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption(
                      "request_date_formated",
                      "visible",
                      false
                    );
                    event.component.columnOption(
                      "request_date",
                      "visible",
                      true
                    );
                    event.component.columnOption(
                      "to_date_formated",
                      "visible",
                      false
                    );
                    event.component.columnOption("to_date", "visible", true);
                  }}
                  onExported={(event) => {
                    event.component.columnOption(
                      "to_date_formated",
                      "visible",
                      true
                    );
                    event.component.columnOption("to_date", "visible", false);
                    event.component.columnOption(
                      "request_date_formated",
                      "visible",
                      true
                    );
                    event.component.columnOption(
                      "request_date",
                      "visible",
                      false
                    );
                    event.component.endUpdate();
                  }}
                  onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                      location: "before",
                      template: "totalGroupCount",
                    });
                  }}
                >
                  <SearchPanel visible={true} />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  {renderColumns()}
                  <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                  />
                  <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                  />
                  <Template
                    name="totalGroupCount"
                    render={() => {
                      return <></>;
                    }}
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  var LeaveRequestList = null;
  var WorkingDays = null;

  if (
    state.LeaveRequestListReducer != null &&
    Object.keys(state.LeaveRequestListReducer).length > 0 &&
    state.LeaveRequestListReducer.constructor === Object
  ) {
    switch (state.LeaveRequestListReducer.tag) {
      case ACTION_LEAVE_REQUEST_LIST_SUCCESS:
        LeaveRequestList = Object.assign(
          {},
          state.LeaveRequestListReducer.data
        );
        delete state.LeaveRequestListReducer;
        return { LeaveRequestList };
      case ACTION_WORKING_DAYS_SUCCESS:
        WorkingDays = Object.assign({}, state.LeaveRequestListReducer.data);
        delete state.LeaveRequestListReducer;
        return { WorkingDays };
      default:
    }
  }

  return {
    LeaveRequestList,
    WorkingDays,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { actionLeaveRequestList, actionWorkingdays },
    dispatch
  );
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(LeaveList)
);
