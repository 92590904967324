import { ACTION_MASTER_GET_DEPARTMENT_SUCCESS, ACTION_MASTER_GET_POLICY_TYPE_SUCCESS, ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS, ACTION_MASTER_EDIT_PLACEMENT_RIGHTS_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const MasterEditPlacementRightReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_GET_DEPARTMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_GET_DEPARTMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_GET_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_GET_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_GET_EMPLOYEE_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_PLACEMENT_RIGHTS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_PLACEMENT_RIGHTS_SUCCESS,
                loading: false,
            }
        );
    },
});
export default MasterEditPlacementRightReducer;