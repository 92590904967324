import { API_MASTER_COMPANY_GROUP } from '../../../../../api/constants';
import { API_MASTER_CUSTOMER_CATEGORY } from '../../../../../api/constants';
import { API_MASTER_INDUSTRIAL_SEGMENT } from '../../../../../api/constants';
import { API_MASTER_FORM_OF_ORGANIZATION } from '../../../../../api/constants';
import { API_MASTER_LEAD_SOURCE } from '../../../../../api/constants';
import { API_MASTER_ASSIGN_RM } from '../../../../../api/constants';
import { API_MASTER_STATES } from '../../../../../api/constants';
import { API_MASTER_CITIES } from '../../../../../api/constants';
import { API_CORPORATE_CLIENT_DETAILS } from '../../../../../api/constants';
import { API_CORPORATE_CLIENT_EDIT } from '../../../../../api/constants';
import { API_MASTER_ASSIGN_RETENTION } from '../../../../../api/constants';

import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';

export const ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_LOADING = 'ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_LOADING = 'ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_LOADING = 'ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_LOADING = 'ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_LOADING = 'ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_LOADING = 'ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_LOADING = 'ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_STATES_LOADING = 'ACTION_MASTER_EDIT_CLIENT_STATES_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS';

export const ACTION_MASTER_EDIT_CLIENT_CITIES_LOADING = 'ACTION_MASTER_EDIT_CLIENT_CITIES_LOADING';
export const ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS = 'ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS';

export const ACTION_CLIENT_DETAILS_LOADING = 'ACTION_CLIENT_DETAILS_LOADING';
export const ACTION_CLIENT_DETAILS_SUCCESS = 'ACTION_CLIENT_DETAILS_SUCCESS';

export const ACTION_CLIENT_EDIT_LOADING = 'ACTION_CLIENT_EDIT_LOADING';
export const ACTION_CLIENT_EDIT_SUCCESS = 'ACTION_CLIENT_EDIT_SUCCESS';

export function actionEditCompanyGroup() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_COMPANY_GROUP).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_LOADING, { loading: false, data }));
        });
}

export function actionEditCustomerCategory() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_CUSTOMER_CATEGORY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_LOADING, { loading: false, data }));
        });
}

export function actionEditIndustrialSegment() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_INDUSTRIAL_SEGMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_LOADING, { loading: false, data }));
        });
}

export function actionEditFormOfOrganization() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_FORM_OF_ORGANIZATION).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_LOADING, { loading: false, data }));
        });
}

export function actionEditLeadSource() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_LEAD_SOURCE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_LOADING, { loading: false, data }));
        });
}

export function actionEditAssignRM(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ASSIGN_RM, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}

export function actionEditStates() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_STATES).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_STATES_LOADING, { loading: false, data }));
        });
}

export function actionEditCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_CITIES_LOADING, { loading: false, data }));
        });
}

export function actionGetClientDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CORPORATE_CLIENT_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLIENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLIENT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionGetClientEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_CORPORATE_CLIENT_EDIT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLIENT_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLIENT_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionAssignRetentionEdit() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_ASSIGN_RETENTION).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_LOADING, { loading: false, data }));
        });
}