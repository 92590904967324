import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionGroupActivities, GROUP_ALL_ACTIVITIES_SUCCESS } from './action';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';
import { verifyPermission } from '../../../../../../../utils';

const GroupActivitiesView = (props) => {
    const [activitiesData, setActivities] = useState([]);

    const [activityColumn, setActivityColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "name", caption: "Company Name" },
        { dataField: "category", caption: "Category (Risk / Wellness)" },
        { dataField: "activity_date", caption: "Date of Activity" },
        { dataField: "engineer_name", caption: "Assigned Engineer" },
        { dataField: "status", caption: "Status" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGroupActivities({
            client_group_id: props.clientGroupId,
        })
    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupActivitiesUpdate = () => {

        const { GroupActivities } = props;

        if (GroupActivities != null) {
            var GroupActivitiesResponse = Object.assign({}, GroupActivities);
            if (GroupActivitiesResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setActivityColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "name", caption: "Company Name" },
                    { dataField: "category", caption: "Category (Risk / Wellness)" },
                    { dataField: "type", caption: "Type" },
                    { dataField: "activity_date", caption: "Date of Activity" },
                    { dataField: "engineer_name", caption: "Assigned Engineer" },
                    { dataField: "status", caption: "Status" },
                ])
                setActivities(GroupActivitiesResponse.response);

            } else {
                setActivities([])
                $("#progress-loader").addClass("hidden");
                switch (GroupActivitiesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }

            }
        }
    }


    useEffect(onGroupActivitiesUpdate, [props.GroupActivities]);

    const renderStatusCell = (cell) => {
        console.log(cell);
        switch (cell.data.status) {
            case 'approve':
                return <div className={"badge badge-success round px-1"}>Approve</div>;
            case 'reject':
                return <div className={"badge badge-danger round px-1"}>Reject</div>;
            case 'pending':
                return <div className={"badge badge-warning round px-1"}>Pending</div>;
            case 'cancel':
                return <div className={"badge badge-secondary round px-1"}>Cancel</div>;
            default:
                return <></>
        }
    }
    const renderActivityColumn = () => {
        var arrColumns = [];
        activityColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1"
                                    onClick={() => {
                                        if (params?.data?.category === 'Risk') {
                                            props.history.push({
                                                pathname: "/risk_management/risk_pool/view",
                                                state: {
                                                    id: params?.data?.id,
                                                    request_type: params?.data?.engineer_name != null && params?.data?.engineer_name != '' ? 'Assigned' : 'Unassigned',
                                                    from: 'client-group',
                                                    selectedTab: props?.tab,
                                                    status_name: params?.data?.status
                                                }
                                            })
                                        } else if (params?.data?.category === 'Wellness') {
                                            props.history.push({
                                                pathname: "/wellness_management/wellness_pool/view",
                                                state: {
                                                    id: params?.data?.id,
                                                    request_type: params?.data?.engineer_name != null && params?.data?.engineer_name != '' ? 'Assigned' : 'Unassigned',
                                                    from: 'client-group',
                                                    selectedTab: props?.tab,
                                                    status_name: params?.data?.status
                                                }
                                            })
                                        }
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };
    const renderActivityTableView = () => {
        return (
            <DataGrid
                dataSource={activitiesData}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                columnHidingEnabled={true}
                export={{
                    fileName: "Activities",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderActivityColumn()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />

            </DataGrid>
        )
    }
    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    activitiesData != null && activitiesData.length > 0 ?
                                        renderActivityTableView() :
                                        <h6 className="text-bold-500 text-center">No Activity Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}
function mapStateToProps(state) {

    var GroupActivities = null;

    if (
        state.TabGroupActivitiesReducer != null &&
        Object.keys(state.TabGroupActivitiesReducer).length > 0 &&
        state.TabGroupActivitiesReducer.constructor === Object
    ) {
        switch (state.TabGroupActivitiesReducer.tag) {

            case GROUP_ALL_ACTIVITIES_SUCCESS:
                GroupActivities = Object.assign({}, state.TabGroupActivitiesReducer.data);
                delete state.TabGroupActivitiesReducer;
                return { GroupActivities };
            default:
                return { GroupActivities }
        }
    }
    return { GroupActivities }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupActivities }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupActivitiesView));
