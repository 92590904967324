import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_WEEKLY_MEETINGS, API_MEETING_WEEKLY_TRAININGS, TEAM_ON_LEAVE_WIDGET } from "../../../api/constants";

export const ACTION_WEEKLY_MEETINGS_LOADING = "ACTION_WEEKLY_MEETINGS_LOADING";
export const ACTION_WEEKLY_MEETINGS_SUCCESS = 'ACTION_WEEKLY_MEETINGS_SUCCESS';

export const ACTION_MEETING_WEEKLY_TRAININGS_LOADING = "ACTION_MEETING_WEEKLY_TRAININGS_LOADING";
export const ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS = 'ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS';

export const ACTION_TEAM_ON_LEAVE_WIDGET_LOADING = "ACTION_TEAM_ON_LEAVE_WIDGET_LOADING";
export const ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS = 'ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS';

export function actionWeeklyMeetings() {
    return (dispatch, getState) =>
        Api.get(API_WEEKLY_MEETINGS)
            .then((res) => {
                const data = res;
                dispatch(fetchSuccess(ACTION_WEEKLY_MEETINGS_SUCCESS, { data }));
            })
            .catch((reason) => {
                /** Handle Error */
                var data = {
                    message: "",
                };
                dispatch(loading(ACTION_WEEKLY_MEETINGS_LOADING, { loading: false, data }));
            });
}

export function actionWeeklyMeetingTrainings() {
    return (dispatch, getState) =>
        Api.get(API_MEETING_WEEKLY_TRAININGS)
            .then((res) => {
                const data = res;
                dispatch(fetchSuccess(ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS, { data }));
            })
            .catch((reason) => {
                /** Handle Error */
                var data = {
                    message: "",
                };
                dispatch(loading(ACTION_MEETING_WEEKLY_TRAININGS_LOADING, { loading: false, data }));
            });
}

export function actionTeamOnLeave() {
    return (dispatch, getState) =>
        Api.get(TEAM_ON_LEAVE_WIDGET)
            .then((res) => {
                const data = res;
                dispatch(fetchSuccess(ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS, { data }));
            })
            .catch((reason) => {
                /** Handle Error */
                var data = {
                    message: "",
                };
                dispatch(loading(ACTION_TEAM_ON_LEAVE_WIDGET_LOADING, { loading: false, data }));
            });
}
