/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { Controller, useForm } from "react-hook-form";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import $ from "jquery";
import Swal from "sweetalert2";
import { FiX } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import parse from 'html-react-parser';
import { ERROR, UNAUTHORIZED } from '../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorLabel from "../../../component/form/error_label";
import { actionGetEmployeeList, ACTION_GET_EMPLOYEE_LIST_SUCCESS } from "./action";
import { actionCreatePost, ACTION_CREATE_POST_SUCCESS } from "./action";
import { getData, KEY_GLOBAL_SEARCH } from "../../../utils/preferences";
import { generateInitialLettersString } from "../../../utils";

const Item = ({ entity: { key, text, photo } }) => <div className="d-flex flex-row align-items-center">
    <div className="flex-column">
        <div
            className="bg-blue-grey bg-lighten-4 text-bold-400 bg-circle d-flex align-items-center justify-content-center font-medium-1 mr-1"
            style={{ border: '5px solid #FFF', height: 50, width: 50 }}
        >
            {generateInitialLettersString(String(text).replace('@', ''))}
        </div>
    </div>
    <div className="flex-column font-medium-1 text-bold-400 text-black">{`${String(text).replace('@', '')}`}</div>
</div>;
const Loading = ({ data }) => <div>Loading</div>;

const CreatePost = (props) => {

    const { handleSubmit, control, formState: { errors }, reset } = useForm();

    // Tag Users
    const [showTagUser, setShowTagUser] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedTagUsers, setSelectedTagUsers] = useState([]);
    const [tagFilteredUsers, setTagFilteredUsers] = useState([]);

    // @ Tag User in Post Text
    const [tagUsers, setTagUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedTagUserText, setSelectedTagUserText] = useState([]);

    // Post Files
    const [files, setFiles] = useState([]);

    const initialRender = () => {
        if (props.show === true) {

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

            var global_search = getData(KEY_GLOBAL_SEARCH)
            if (global_search != null) {
                var objGlobalSearch = JSON.parse(global_search)
                var users = Object.assign([], objGlobalSearch.users)
                var arrUsers = []
                users.forEach((user, indexUser) => {
                    arrUsers.push({
                        key: user.id,
                        text: '@' + user.name,
                        photo: user.photo,
                        caretPosition: 'next'
                    })
                })
                setAllUsers(arrUsers)
            }

            // setValue('profile_image', props.data != null ? props.data.profile_image : '');
            // setValue('name', props.data != null ? props.data.name : '');
            setFiles([]);
            setTagUsers([]);
            props.actionGetEmployeeList({});

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setAllUsers([])

            // setValue('profile_image', '');
            // setValue('name', '');
        }

        return () => {
            reset({
                content: ''
            })
            setSelectedTagUserText([])
            setFiles([]);
            setTagUsers([]);
            setSelectedTagUsers([]);
            setTagFilteredUsers([]);
        }
    };
    useEffect(initialRender, [props.show]);

    const onGetEmployeeListUpdate = () => {
        const { GetEmployeeList } = props;
        var GetEmployeeListResponse = Object.assign({}, GetEmployeeList);
        if (GetEmployeeListResponse.result === true) {
            setTagUsers(GetEmployeeListResponse.response);
            setTagFilteredUsers(GetEmployeeListResponse.response);
        } else {
            switch (GetEmployeeListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onGetEmployeeListUpdate, [props.GetEmployeeList]);

    const onCreatePostUpdate = () => {
        const { CreatePost } = props;
        var CreatePostResponse = Object.assign({}, CreatePost);
        $("#save-post").prop("disabled", false);
        if (CreatePostResponse.result === true) {
            $("#post-form-loader").addClass("hidden");
            props.handleClose(CreatePostResponse.response);
        } else {
            $("#post-form-loader").addClass("hidden");
            switch (CreatePostResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    Swal.fire({
                        icon: 'error',
                        text: CreatePostResponse.message
                    })
                    break;
                default:
            }
        }
    }
    useEffect(onCreatePostUpdate, [props.CreatePost]);

    useEffect(() => {
        if (searchText.length > 0) {
            var filteredUsers = tagUsers.filter(user => String(user.full_name).toLowerCase().includes(searchText.toLowerCase()));
            setTagFilteredUsers(filteredUsers);
        } else {
            setTagFilteredUsers(tagUsers)
        }
    }, [searchText])

    const renderSelectedText = () => {
        try {
            var strReturn = '- with ';
            if (selectedTagUsers.length > 0) {
                strReturn += '<span class="text-primary cursor-pointer">' + selectedTagUsers[0].full_name + '</span>';
            }
            if (selectedTagUsers.length >= 2) {
                strReturn += ' and <span class="text-primary cursor-pointer">' + (selectedTagUsers.length - 1) + ' others</span>';
            }
            return strReturn;
        } catch (e) {
            return (
                <>
                    <h1>Error post tag</h1>
                </>
            )
        }
    }

    const formatTagUserText = (postText) => {
        try {
            var formatText = postText
            if (selectedTagUserText != null && selectedTagUserText.length > 0) {
                selectedTagUserText.forEach((user, indexUser) => {
                    var indexOfUser = String(formatText).indexOf(user.text)
                    if (indexOfUser > -1) {
                        formatText = formatText.replace(user.text, `##${user.key}:${user.text.replace('@', '')}##`)
                    }
                })
            }
        } catch (e) {
            console.log("Format post Text")
            console.log(e)
        }
        try {
            return (String(formatText).trim())
        } catch (e) {
            return (<>{formatText}</>)
        }
    }

    const onSubmit = (data) => {
        data.content = formatTagUserText(data.content)

        var sendFiles = [];
        if (files != null && files.length > 0) {
            files.forEach((file, indexFile) => {
                sendFiles.push({ 'name': `file[${indexFile}]`, 'value': file });
            });
        }

        // delete data.upload_files;
        var tags = [];
        if (selectedTagUsers != null && selectedTagUsers.length > 0) {
            for (var tagsindex = 0; tagsindex < selectedTagUsers.length; tagsindex++) {
                tags.push(selectedTagUsers[tagsindex].user_id);

            }
        }
        if (tags != null && tags.length > 0) {
            data.tags = JSON.stringify(tags);
        }

        $("#post-form-loader").removeClass("hidden");
        $("#save-post").prop("disabled", true);
        props.actionCreatePost(data, sendFiles);
    }

    const renderUI = () =>{
        try {
            return(
                <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="myModalLabel1"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="myModalLabel1">
                                    Create Post
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                    setSearchText('');
                                    setSelectedTagUsers([]);
                                    setShowTagUser(false);
                                    setFiles([]);
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                            <div className="row">
                                <div id="tagPeople" className={`col-12 col-md-12 col-lg-12 col-xl-12 ${showTagUser ? '' : 'hidden'} `}>
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 no-scroll" style={{ height: 70, overflowY: 'scroll' }}>
                                            {
                                                selectedTagUsers.map((objTagUser, index) => {
                                                    return (
                                                        <div className="d-inline-block justify-content-center align-items-center badge round pt-half pb-half px-1 bg-pink bg-lighten-4 text-black mb-1 mr-1" key={index}>
                                                            {objTagUser.full_name}
                                                            <AiOutlineCloseCircle
                                                                size={16}
                                                                className="ml-half"
                                                                onClick={() => {
                                                                    var arrSelectedTagUsers = Object.assign([], selectedTagUsers);
                                                                    var indexTagUser = arrSelectedTagUsers.findIndex((tagUsers, indexUser) => tagUsers.user_id === objTagUser.user_id);
                                                                    arrSelectedTagUsers.splice(indexTagUser, 1);
                                                                    setSelectedTagUsers(arrSelectedTagUsers);
                                                                }}
                                                            ></AiOutlineCloseCircle>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <hr className="mb-half" />
                                    <div className="row">
                                        <div className="col-10 col-md-10 col-lg-10 col-xl-10 pr-0">
                                            <fieldset className="form-group mb-0 position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 round bg-blue-grey bg-lighten-5 blue-grey darken-4 font-medium-1 w-100"
                                                    placeholder="Search Here..."
                                                    value={searchText}
                                                    onChange={(event) => {
                                                        setSearchText(event.target.value)
                                                    }}
                                                />
                                                <div className={`form-control-position ${searchText.length > 0 ? '' : 'hidden'}`}>
                                                    <FiX size={14} className="mr-1 mb-half" onClick={() => {
                                                        setSearchText('');
                                                    }} />
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-2 col-xl-2 d-flex px-0">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-block text-primary text-bold-700 text-left font-medium-1"
                                                onClick={() => {
                                                    setShowTagUser(false);
                                                }}
                                            >
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                    <hr className="mt-half" />
    
                                    <div className={`search-input`}>
                                        <ul className={`search-list no-scroll`} style={{ maxHeight: 250, overflowY: 'scroll', paddingLeft: '0px' }}>
                                            {
                                                tagFilteredUsers != null && tagFilteredUsers.length > 0 ?
                                                    tagFilteredUsers.map((user, indexUser) => (
                                                        <li
                                                            className="py-half auto-suggestion d-flex align-items-center justify-content-between cursor-pointer border-bottom"
                                                            key={indexUser}
                                                            onClick={() => {
                                                                var arrSelectedTagUsers = Object.assign([], selectedTagUsers);
                                                                var indexTagUser = arrSelectedTagUsers.findIndex((tagUsers, indexUser) => tagUsers.user_id === user.user_id);
                                                                if (indexTagUser >= 0) {
                                                                    arrSelectedTagUsers.splice(indexTagUser, 1);
                                                                } else {
                                                                    arrSelectedTagUsers.push(user);
                                                                }
                                                                setSelectedTagUsers(arrSelectedTagUsers);
                                                            }}
                                                        >
                                                            <a className="d-flex align-items-center justify-content-between w-100">
                                                                <div className="d-flex flex-row align-items-center w-100">
                                                                    <div className="d-flex flex-column w-100">
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <div className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center">
                                                                                {String(user.full_name).substring(0, 2).toUpperCase()}
                                                                            </div>
                                                                            <div className="align-items-center ml-1">
                                                                                <h6 className="font-medium-1 py-0 text-bold-500 mt-half">{user.full_name}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        selectedTagUsers.findIndex((objTagUser, index) => objTagUser.user_id === user.user_id) >= 0 ?
                                                                            <div className="d-flex flex-column float-right">
    
                                                                                <AiOutlineCloseCircle
                                                                                    size={16}
                                                                                    className="ml-half text-primary mr-1"
                                                                                    onClick={() => {
                                                                                        var arrSelectedTagUsers = Object.assign([], selectedTagUsers);
                                                                                        var indexTagUser = arrSelectedTagUsers.findIndex((tagUsers, indexUser) => tagUsers.user_id === user.user_id);
                                                                                        arrSelectedTagUsers.splice(indexTagUser, 1);
                                                                                        setSelectedTagUsers(arrSelectedTagUsers);
                                                                                    }}
                                                                                ></AiOutlineCloseCircle>
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                    }
    
                                                                </div>
                                                            </a>
                                                        </li>
                                                    ))
                                                    :
                                                    <></>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div id="createPost" className={`col-12 col-md-12 col-lg-12 col-xl-12 ${showTagUser ? 'hidden' : ''} `}>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="flex-column">
                                            {
                                                (props.auth.getUser().profile_photo != null && props.auth.getUser().profile_photo.length > 0) ?
                                                    <img
                                                        src={props.auth.getUser().profile_photo}
                                                        className="rounded-circle img-border"
                                                        alt="Card"
                                                        width={50}
                                                        height={50}
                                                        style={{ border: '5px solid #FFF' }}
                                                    />
                                                    :
                                                    <>
                                                        <div
                                                            className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3"
                                                            style={{ border: '5px solid #FFF', height: 50, width: 50 }}
                                                        >
                                                            {generateInitialLettersString(props.auth.getUser().first_name + ' ' + props.auth.getUser().last_name)}
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <div className="flex-column align-items-center ml-1">
                                            <h6 className="font-medium-1 py-0 text-bold-600 mb-0">{`${props.auth.getUser().first_name} ${props.auth.getUser().last_name}`}</h6>
                                        </div>
                                    </div>
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <Controller
                                            name="content"
                                            className="form-control"
                                            control={control}
                                            render={({ field }) =>
                                                <ReactTextareaAutocomplete
                                                    {...field}
                                                    className="form-control"
                                                    loadingComponent={Loading}
                                                    maxLength={5000}
                                                    style={{
                                                        fontSize: "18px",
                                                        lineHeight: "20px",
                                                        padding: 5
                                                    }}
                                                    rows={5}
                                                    containerStyle={{
                                                        marginTop: 20,
                                                        margin: "20px auto"
                                                    }}
                                                    listStyle={{
                                                        position: 'absolute',
                                                        right: 10,
                                                        left: 10,
                                                        overflow: 'scroll',
                                                        height: 200
                                                    }}
                                                    onItemSelected={(item) => {
                                                        var arrSelectedTagUserText = Object.assign([], selectedTagUserText)
                                                        arrSelectedTagUserText.push(item.item)
                                                        setSelectedTagUserText(arrSelectedTagUserText)
                                                    }}
                                                    onChange={(event) => {
                                                        $(".rta").css("position", "relative").css("z-index", "10000");
                                                        field.onChange(event)
                                                    }}
                                                    value={field.value}
                                                    minChar={0}
                                                    listClassName="list-group user-tag-list"
                                                    itemClassName="list-group-item list-group-item-action py-half"
                                                    placeholder={`What's on your mind, ${props.auth.getUser().first_name}?`}
                                                    trigger={{
                                                        "@": {
                                                            dataProvider: token => {
                                                                return allUsers.filter((user) => user.text.toLowerCase().includes(token.toLocaleLowerCase()));
                                                            },
                                                            component: Item,
                                                            output: (item, trigger) => item
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: 'Post Content is required'
                                            }}
                                        />
                                        {errors.content && <ErrorLabel message={errors.content?.message} />}
                                        <fieldset className="form-group mt-1">
                                            {/* <textarea
                                                id="content"
                                                name="content"
                                                className="form-control border-0 hidden" 
                                                placeholder="Write comment here"
                                                rows={5}
                                                {...register('content')}
                                            />
                                            {errors.content && <ErrorLabel message={errors.content?.message} />} */}
                                            {
                                                selectedTagUsers.length > 0 ?
                                                    <>
                                                        <span onClick={() => {
                                                            setShowTagUser(true)
                                                        }}>
                                                            {parse(renderSelectedText())}
                                                        </span>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </fieldset>
                                        <input
                                            hidden
                                            type="file"
                                            id="upload_files"
                                            name="upload_files"
                                            multiple={true}
                                            accept=".png, .jpg, .jpeg"
                                            max={10}
                                            onChange={(event) => {
                                                if (
                                                    event.target.files &&
                                                    event.target.files.length <= 10
                                                ) {
                                                    if ((files.length + event.target.files.length) <= 10) {
                                                        var arrFiles = Object.assign([], files);
                                                        for (let i = 0; i < event.target.files.length; i++) {
                                                            let file = event.target.files[i];
                                                            arrFiles.push(file);
                                                        }
                                                        setFiles(arrFiles);
                                                    } else {
                                                        Swal.fire({
                                                            title: 'Upload Error',
                                                            text: 'Maximum 10 files are allowed.',
                                                            icon: 'error'
                                                        }).then(success => {
                                                        });
                                                    }
                                                } else {
                                                    Swal.fire({
                                                        title: 'Upload Error',
                                                        text: 'Maximum 10 files are allowed.',
                                                        icon: 'error'
                                                    }).then(success => {
                                                    });
                                                }
                                            }}
                                        // {...register('upload_files')}
                                        />
                                        {errors.upload_files && <ErrorLabel message={errors.upload_files?.message} />}
                                        <div className="row mb-half" id="displayFiles">
                                            {
                                                files != null && files.length > 0 ?
                                                    files.map((image, indexImage) => {
                                                        var anyWindow = window.URL || window.webkitURL;
                                                        var objectUrl = anyWindow.createObjectURL(image);
    
                                                        return (
                                                            <div key={indexImage} className="col-auto pr-0 mb-half">
                                                                <AiOutlineCloseCircle
                                                                    size={16}
                                                                    style={{ position: 'absolute', right: 0 }}
                                                                    onClick={() => {
                                                                        var arrFiles = Object.assign([], files);
                                                                        arrFiles.splice(indexImage, 1);
                                                                        setFiles(arrFiles);
                                                                    }}
                                                                ></AiOutlineCloseCircle>
                                                                <img className="pl-half p-half " height="80" width="80" style={{ objectFit: 'cover' }} src={objectUrl} alt="" />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-block text-left"
                                            onClick={() => {
                                                $("#upload_files").click();
                                            }}
                                        >
                                            <img src="/assets/images/new-gallary.png" className="mr-half" alt="" /> Upload Photo
                                        </button>
                                        <hr className="mb-half mt-half" />
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-block text-left mb-2"
                                            onClick={() => {
                                                setShowTagUser(true);
                                            }}
                                        >
                                            <img src="/assets/images/tag_user.png" className="mr-half" alt="" /> Tag People
                                        </button>
                                        <button
                                            id="save-post"
                                            type="submit"
                                            className="btn btn-primary btn-lg btn-block mb-0"
                                        >
                                            <div className="d-flex flex-row justify-content-center align-items-center">
                                                <div className="flex-column">Post</div>
                                                <div className="flex-column">
                                                    <span id="post-form-loader" className="spinner-border spinner-border-sm ml-half p-half hidden" />
                                                </div>
                                            </div>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        } catch (e) {
            return (
                <>
                    <h1>Error Create Post</h1>
                </>
            )
        }
    }

    return (
        renderUI()
    );
};

const mapStateToProps = (state) => {
    var GetEmployeeList = null;
    var CreatePost = null;

    if (
        state.ActivityCreatePostReducer != null &&
        Object.keys(state.ActivityCreatePostReducer).length > 0 &&
        state.ActivityCreatePostReducer.constructor === Object
    ) {
        switch (state.ActivityCreatePostReducer.tag) {
            case ACTION_GET_EMPLOYEE_LIST_SUCCESS:
                GetEmployeeList = Object.assign({}, state.ActivityCreatePostReducer.data);
                delete state.ActivityCreatePostReducer;
                return { GetEmployeeList };
            case ACTION_CREATE_POST_SUCCESS:
                CreatePost = Object.assign({}, state.ActivityCreatePostReducer.data);
                delete state.ActivityCreatePostReducer;
                return { CreatePost };
            default:
        }
    }
    return {
        GetEmployeeList,
        CreatePost

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionGetEmployeeList,
            actionCreatePost
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CreatePost))