import React, { useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { withAuth } from 'react-auth-guard';
import $ from "jquery";
import { actionClaimsList, ACTION_CLAIMS_LIST_SUCCESS } from './action';
import { removeData, getData, setData, KEY_TOAST_MESSAGE, KEY_CLAIM_DETAILS } from '../../../../../utils/preferences';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { FiEdit } from 'react-icons/fi';
import { RiAddBoxLine } from 'react-icons/ri';
import { VscIssueReopened } from 'react-icons/vsc';
import { AiOutlineUpload } from 'react-icons/ai';
import { UNAUTHORIZED } from '../../../../../constants';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import _ from "lodash";
import { verifyPermission } from '../../../../../utils';
import Swal from 'sweetalert2';
import { actionReopenClaim, ACTION_REOPEN_CLAIM_SUCCESS } from './action';

const ClaimsList = (props) => {

    const [pincPoliciesCount, setPincPoliciesCount] = useState(0);
    const [mandatePoliciesCount, setMandatePoliciesCount] = useState(0);
    const [allPoliciesCount, setallPoliciesCount] = useState(0);
    const [type, setType] = useState('non-mandate');
    const [claimsList, setClaimsList] = useState([]);
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(0);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "type", caption: "Type" },
        { dataField: "customer_name", caption: " Customer Name" },
        { dataField: "status", caption: "Status" },
        { dataField: "control_no", caption: "Control No." },
        { dataField: "policy_no", caption: " Policy No." },
        { dataField: "department", caption: "Product" },
        { dataField: "business_book_date", caption: "Claim Creation Date" },
        { dataField: "csc_name", caption: " Claim Handler" },
        { dataField: "amount", caption: " Claim Amount" },
        { dataField: "claim_updated_at", caption: "Last Update Date" },
        { dataField: "follow_up_date", caption: "Followup Date" },
        { dataField: "pinc_claim_ref_no", caption: "PINC Claim Ref. No." },
        { dataField: "claim_type", caption: "Claim Type" },
        { dataField: "policy_start_date", caption: "Policy Start Date" },
        { dataField: "policy_expiry_date", caption: "Policy End Date" },
        { dataField: "intimate_date", caption: "Date Of Intimation" },
        { dataField: "date_of_loss", caption: "Date Of Loss" },
        { dataField: "settle_amount", caption: "Settled Amount" },
        { dataField: "sum_insured", caption: "Sum Insured" },
        { dataField: "gross_premium", caption: "Policy Premium" },
        { dataField: "rm_name", caption: "RM Name" },
        { dataField: "insurer_name", caption: "Insurer Name" },
        { dataField: "claim_remark", caption: "Claim Remarks" },
    ]);

    const initialRender = () => {
        var claimDetail = getData(KEY_CLAIM_DETAILS)
        if (claimDetail != null) {
            var input = JSON.parse(claimDetail)
            setType(input.type);
            props.actionClaimsList({
                type: input.type,
                page_size: pageSize,
                offset: pageSize * currentPage
            });

        } else {
            props.actionClaimsList({
                type: type,
                page_size: pageSize,
                offset: pageSize * currentPage
            });
        }
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
    }
    useEffect(initialRender, []);

    // useEffect(() => {
    //     // removeData(KEY_CLAIM_DETAILS);
    //     props.actionClaimsList({
    //         type: type,
    //         page_size: pageSize,
    //         offset: pageSize * currentPage
    //     });
    // }, [currentPage]);

    const onClaimsListUpdate = () => {
        const { ClaimsList } = props;
        if (ClaimsList != null) {
            var ClaimsListResponse = Object.assign({}, ClaimsList);
            if (ClaimsListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "pinc_claim_ref_no", caption: "PINC Claim Ref. No." },
                    { dataField: "customer_name", caption: " Customer Name" },
                    { dataField: "control_no", caption: "Policy Control No." },
                    { dataField: "policy_no", caption: " Policy No." },
                    { dataField: "department", caption: "Product" },
                    { dataField: "business_book_date", caption: "Claim Creation Date" },
                    { dataField: "csc_name", caption: " Claim Handler" },
                    { dataField: "amount", caption: " Claim Amount" },
                    { dataField: "claim_updated_at", caption: "Last Update Date" },
                    { dataField: "follow_up_date", caption: "Followup Date" },
                    { dataField: "status", caption: "Status" },
                    { dataField: "claim_type", caption: "Claim Type" },
                    { dataField: "policy_start_date", caption: "Policy Start Date" },
                    { dataField: "policy_expiry_date", caption: "Policy End Date" },
                    { dataField: "intimate_date", caption: "Date Of Intimation" },
                    { dataField: "date_of_loss", caption: "Date Of Loss" },
                    { dataField: "settle_amount", caption: "Settled Amount" },
                    { dataField: "sum_insured", caption: "Sum Insured" },
                    { dataField: "gross_premium", caption: "Policy Premium" },
                    { dataField: "rm_name", caption: "RM Name" },
                    { dataField: "insurer_name", caption: "Insurer Name" },
                    { dataField: "claim_remark", caption: "Claim Remarks" },
                ]);

                setClaimsList(ClaimsListResponse.response);
                setPincPoliciesCount(0);
                setMandatePoliciesCount(0);
                setallPoliciesCount(0);

                switch (type) {
                    case 'non-mandate':
                        setPincPoliciesCount(ClaimsListResponse.response.length);
                        break;
                    case 'mandate':
                        setMandatePoliciesCount(ClaimsListResponse.response.length);
                        break;
                    case 'all':
                        setallPoliciesCount(ClaimsListResponse.response.length);
                        setColumns([
                            { dataField: "Action", caption: "Action", type: "button" },
                            { dataField: "type", caption: "Non Mandate/Mandate" },
                            { dataField: "pinc_claim_ref_no", caption: "PINC Claim Ref. No." },
                            { dataField: "customer_name", caption: " Customer Name" },
                            { dataField: "control_no", caption: "Policy Control No." },
                            { dataField: "policy_no", caption: " Policy No." },
                            { dataField: "department", caption: "Product" },
                            { dataField: "business_book_date", caption: "Claim Creation Date" },
                            { dataField: "csc_name", caption: " Claim Handler" },
                            { dataField: "amount", caption: " Claim Amount" },
                            { dataField: "claim_updated_at", caption: "Last Update Date" },
                            { dataField: "follow_up_date", caption: "Followup Date" },
                            { dataField: "status", caption: "Status" },
                            { dataField: "claim_type", caption: "Claim Type" },
                            { dataField: "policy_start_date", caption: "Policy Start Date" },
                            { dataField: "policy_expiry_date", caption: "Policy End Date" },
                            { dataField: "intimate_date", caption: "Date Of Intimation" },
                            { dataField: "date_of_loss", caption: "Date Of Loss" },
                            { dataField: "settle_amount", caption: "Settled Amount" },
                            { dataField: "sum_insured", caption: "Sum Insured" },
                            { dataField: "gross_premium", caption: "Policy Premium" },
                            { dataField: "rm_name", caption: "RM Name" },
                            { dataField: "insurer_name", caption: "Insurer Name" },
                            { dataField: "claim_remark", caption: "Claim Remarks" },
                        ]);
                        break;
                        default:
                }

            } else {
                $("#progress-loader").addClass("hidden");
                setClaimsList([]);
                switch (ClaimsListResponse.status) {
                    case UNAUTHORIZED:
                        $("#progress-loader").addClass("hidden");
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClaimsListUpdate, [props.ClaimsList]);

    const onReopenClaimUpdate = () => {
        const { ReopenClaim } = props;
        if (ReopenClaim != null) {
            var ReopenClaimResponse = Object.assign({}, ReopenClaim);
            if (ReopenClaimResponse.result === true) {
                props.actionClaimsList({
                    type: type,
                    page_size: pageSize,
                    offset: pageSize * currentPage
                });
            } else {
                switch (ReopenClaimResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onReopenClaimUpdate, [props.ReopenClaim]);

    const renderClaimDate = (cell) =>
        (cell.data.claim_updated_at) != null ? <>{dateFormat(cell.data.claim_updated_at, 'dd/mm/yyyy')}</> : <>-</>

    const renderType = (cell) => {
        return <div>{_.capitalize(_.replace(cell.data.type, "-", " "))}</div>
    }

    const renderIntimateDate = (cell) =>
        (cell.data.intimate_date) != null ? <>{cell.data.intimate_date} </> : <>-</>

    const renderFollowUpDate = (cell) =>
        (cell.data.follow_up_date) != null ? <>{cell.data.follow_up_date} </> : <>-</>


    const renderCustomerName = (cell) => {
        return <div>{_.truncate(cell.data.customer_name)}</div>
    }

    const renderSumInsured = (cell) => {
        return <div>{Number(cell.data.sum_insured).toFixed(2)}</div>
    }

    const renderPolicyPremium = (cell) => {
        return <div>{Number(cell.data.gross_premium).toFixed(2)}</div>
    }


    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons" width={150} >
                        {
                            verifyPermission("VIEW_CLAIM") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            title="View Claim"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: `/claims/view`,
                                                    state: {
                                                        id: params.data.id, claim_id: params.data.id, pinc_claim_no: params.data.pinc_claim_no
                                                    }
                                                })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("EDIT_CLAIM") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            title="Edit Claim"
                                            className={`btn btn-icon btn-sm  float-left ${(params.data.status === 'Approved' || params.data.status === 'Pending At Insured' || params.data.status === 'Pending At Insurer' || params.data.status === 'Pending' || params.data.status === 'Part Settled' || params.data.status === 'Reopen') ? '' : 'hidden'} `}
                                            onClick={() => {
                                                props.history.push({ pathname: `/claims/edit`, state: { control_no: params.data.control_no, claim_id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("REOPEN_CLAIM") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            title="Re-Open Claim"
                                            className={`btn btn-icon btn-sm  float-left ${(params.data.status === 'closed' || params.data.status === 'Closed' || params.data.status === 'Settled' || params.data.status === 'Rejected' || params.data.status === 'Repudiated' || params.data.status === 'Repuidated' || params.data.status === 'Withdraw' || params.data.status === 'withdrawn') ? '' : 'hidden'} `}
                                            onClick={() => {
                                                Swal.fire({
                                                    icon: 'warning',
                                                    text: 'Are you sure, you want to Reopen this claim?',
                                                    cancelButtonText: "Cancel",
                                                    showCancelButton: true,
                                                }).then(({ isConfirmed }) => {
                                                    if (isConfirmed) {
                                                        props.actionReopenClaim({
                                                            "claim_id": params.data.id
                                                        })
                                                    }
                                                })
                                            }}
                                        >
                                            <VscIssueReopened size={18} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("LOSS_SITE_VISIT_ADD") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            title="Loss Site Visit"
                                            className={`btn btn-icon btn-sm  float-left ${(params.data.status == 'closed' || params.data.status == 'Closed' || params.data.status == 'Settled' || params.data.status == 'Rejected' || params.data.status == 'Repudiated' || params.data.status == 'Repuidated') ? 'hidden' : ''} `}
                                            onClick={() => {
                                                props.history.push({ pathname: `/claims/register/loss-site-visit/add`, state: { pinc_claim_ref_no: params.data.pinc_claim_ref_no, claim_id: params.data.id } });
                                            }}
                                        >
                                            <RiAddBoxLine size={18} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("CLAIMS_DOCUMENT_UPLOAD") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            title="Document Upload"
                                            className={`btn btn-icon btn-sm  float-left ${(params.data.status == 'closed' || params.data.status == 'Closed' || params.data.status == 'Settled' || params.data.status == 'Rejected' || params.data.status == 'Repudiated' || params.data.status == 'Repuidated') ? 'hidden' : ''} `}
                                            onClick={() => {
                                                props.history.push({ pathname: `/claims/document/upload`, state: { pinc_claim_ref_no: params.data.pinc_claim_ref_no, claim_id: params.data.id } });
                                            }}
                                        >
                                            <AiOutlineUpload size={18} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            }
            else if (objColumn.dataField === "claim_updated_at") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderClaimDate}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "intimate_date") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderIntimateDate}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "follow_up_date") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderFollowUpDate}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "type") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderType}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "customer_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCustomerName}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "sum_insured") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSumInsured}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "gross_premium") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderPolicyPremium}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };
    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li className={`nav-item`}>
                            <button
                                id="btn-pinc-policies"
                                className={`btn ${pincPoliciesCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    setType('non-mandate');
                                    setData(KEY_CLAIM_DETAILS, JSON.stringify({ type: 'non-mandate' }))
                                    props.actionClaimsList({
                                        type: 'non-mandate'
                                    });
                                }}
                            >
                                PINC Claims {pincPoliciesCount > 0 ? `(` + pincPoliciesCount + `)` : ``}
                            </button>
                        </li>
                        <li className={`nav-item ml-1`}>
                            <button
                                id="btn-mandate-policies"
                                className={`btn ${mandatePoliciesCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType('mandate');
                                    setData(KEY_CLAIM_DETAILS, JSON.stringify({ type: 'mandate' }))
                                    props.actionClaimsList({
                                        type: 'mandate'
                                    });
                                }}
                            >
                                Mandate Claims {mandatePoliciesCount > 0 ? `(` + mandatePoliciesCount + `)` : ``}
                            </button>
                        </li>
                        <li className={`nav-item ml-1`}>
                            <button
                                id="btn-all-policies"
                                className={`btn ${allPoliciesCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType('all');
                                    setData(KEY_CLAIM_DETAILS, JSON.stringify({ type: 'all' }))
                                    props.actionClaimsList({
                                        type: 'all'
                                    });
                                }}
                            >
                                All Claims {allPoliciesCount > 0 ? `(` + allPoliciesCount + `)` : ``}
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            <li class="nav-item ml-6">
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                    onClick={() => {
                                        props.history.push(`/claims/pinc/policy/list`);
                                    }}
                                >
                                    Register Claim
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={claimsList}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    remoteOperations={true}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                        onPageIndexChange={(value) => {
                                            setCurrentPage(value + 1);
                                        }}
                                    />
                                </DataGrid>
                                <button class="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                    onClick={() => {
                                        removeData(KEY_CLAIM_DETAILS);
                                        props.history.goBack();
                                    }}> Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    var ClaimsList = null;
    var ReopenClaim = null;
    if (
        state.ClaimsReducer != null &&
        Object.keys(state.ClaimsReducer).length > 0 &&
        state.ClaimsReducer.constructor === Object
    ) {
        switch (state.ClaimsReducer.tag) {
            case ACTION_CLAIMS_LIST_SUCCESS:
                ClaimsList = Object.assign({}, state.ClaimsReducer.data);
                delete state.ClaimsReducer;
                return { ClaimsList };
            case ACTION_REOPEN_CLAIM_SUCCESS:
                ReopenClaim = Object.assign({}, state.ClaimsReducer.data);
                delete state.ClaimsReducer;
                return { ReopenClaim };
            default:
        }
    }
    return {
        ClaimsList,
        ReopenClaim,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionClaimsList, actionReopenClaim }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClaimsList))