/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { FiBell, FiPower, FiUser, FiX } from "react-icons/fi";
import {
  clearData,
  getData,
  KEY_GLOBAL_SEARCH,
  KEY_MARK_INOUT,
  setData,
  KEY_NOTIFICATION_COUNT,
} from "../../utils/preferences";
import { withAuth } from "react-auth-guard";
import { actionLogOut, ACTION_LOG_OUT_SUCCESS } from "./action";
import { Link } from "react-router-dom";
import $ from "jquery";
import { generateInitialLettersString, PRINT, timeSince } from "../../utils";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { UNAUTHORIZED } from "../../constants";
import {
  actionHeaderUserNotification,
  ACTION_HEADER_USER_NOTIFICATION_SUCCESS,
} from "./action";

const Header = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [markInOut, setMarkInOut] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const initialRender = () => {
    // props.actionHeaderUserNotification({})
    var notificationCount = getData(KEY_NOTIFICATION_COUNT);
    if (notificationCount != null) {
      setNotificationCount(notificationCount);
    }
    var markInOut = getData(KEY_MARK_INOUT);
    if (markInOut != null) {
      if (markInOut === "Y") {
        setMarkInOut(true);
      } else {
        setMarkInOut(false);
      }
    }
  };
  useEffect(initialRender, [props]);

  useEffect(() => {
    if (searchText.length > 2) {
      var global_search = getData(KEY_GLOBAL_SEARCH);
      if (global_search != null) {
        var objGlobalSearch = JSON.parse(global_search);
        var users = Object.assign([], objGlobalSearch.users);
        var insurer_companies = Object.assign(
          [],
          objGlobalSearch.insurer_companies
        );
        var clients = Object.assign([], objGlobalSearch.clients);
        var company_group = Object.assign([], objGlobalSearch.company_group);
        const filteredUsers = users.filter((user) =>
          String(user.name).toLowerCase().includes(searchText.toLowerCase())
        );
        const filteredInsurerCompanies = insurer_companies.filter(
          (insurer_company) =>
            String(insurer_company.name)
              .toLowerCase()
              .includes(searchText.toLowerCase())
        );
        const filteredClients = clients.filter((client) =>
          String(client.name).toLowerCase().includes(searchText.toLowerCase())
        );
        const filteredCompanyGroup = company_group.filter((group) =>
          String(group.name).toLowerCase().includes(searchText.toLowerCase())
        );
        var result = filteredUsers
          .concat(filteredInsurerCompanies)
          .concat(filteredClients)
          .concat(filteredCompanyGroup);
        setTimeout(() => {
          setSearchResult(result);
        }, 500);
      }
    }
  }, [searchText]);

  $(document).on("click", ".app-content", function () {
    setSearchText("");
  });

  const onLogOutUpdate = () => {
    const { LogOut } = props;
    var LogOutResponse = Object.assign({}, LogOut);
    if (LogOutResponse.result === true) {
      clearData();
      props.auth.updateToken(null);
      props.auth.logout();
    } else {
      switch (LogOutResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onLogOutUpdate, [props.LogOut]);

  const onNotificationsUpdate = () => {
    const { Notifications } = props;
    var NotificationsResponse = Object.assign({}, Notifications);
    if (NotificationsResponse.result === true) {
      setNotifications(NotificationsResponse.response.data);
      setData(
        KEY_NOTIFICATION_COUNT,
        JSON.stringify(NotificationsResponse.response.count)
      );
      setNotificationCount(NotificationsResponse.response.count);
    } else {
      switch (NotificationsResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onNotificationsUpdate, [props.Notifications]);

  const getUserTypeColor = (user_type) => {
    switch (user_type) {
      case "Client":
        return "badge-info";
      case "Insurance Company":
        return "badge-danger";
      default:
        return "badge-success";
    }
  };

  const renderProfilePhoto = (user) => {
    switch (user.type) {
      case "Insurar Company":
        return (
          <div className="flex-column">
            <img
              src={`/assets/images/insurance_companies/${user.id}.png`}
              className="rounded-circle img-border"
              alt="Card"
              width={50}
              height={50}
              style={{ border: "5px solid #FFF" }}
            />
          </div>
        );
      case "Employee":
        return (
          <div className="flex-column w-20">
            {user.photo != null ? (
              <img
                src={user.photo}
                className="rounded-circle img-border"
                alt="Card"
                width={50}
                height={50}
                style={{ border: "5px solid #FFF" }}
              />
            ) : (
              <>
                <div
                  className="bg-blue-grey bg-lighten-4 text-bold-700 bg-circle d-flex align-items-center justify-content-center"
                  style={{ border: "5px solid #FFF", height: 50, width: 50 }}
                >
                  {generateInitialLettersString(user.name)}
                </div>
              </>
            )}
          </div>
        );
      default:
        return (
          <div className="flex-column w-20">
            <div
              className="bg-blue-grey bg-lighten-4 text-bold-700 bg-circle d-flex align-items-center justify-content-center"
              style={{ border: "5px solid #FFF", height: 50, width: 50 }}
            >
              {generateInitialLettersString(String(user.name))}
            </div>
          </div>
        );
    }
  };

  return (
    <nav className="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-light navbar-border">
      <div className="navbar-wrapper">
        <div className="navbar-header">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item mobile-menu d-md-none mr-auto">
              <a
                className="nav-link nav-menu-main menu-toggle hidden-xs"
                href="#"
              >
                <AiOutlineMenu className="font-large-1" />
              </a>
            </li>
            <li className="nav-item">
              <Link className="navbar-brand" to={"/dashboard"}>
                <img
                  className="brand-logo"
                  style={{ height: 30, width: 80 }}
                  alt="logo"
                  src="/assets/images/app-logo.jpg"
                />
              </Link>
            </li>
            <li className="nav-item d-md-none">
              <a
                className="nav-link open-navbar-container"
                data-toggle="collapse"
                data-target="#navbar-mobile"
              >
                <FaEllipsisV size={16} />
              </a>
            </li>
          </ul>
        </div>
        <div className="navbar-container content">
          <div className="collapse navbar-collapse" id="navbar-mobile">
            <ul className="nav navbar-nav mr-auto float-left">
              <li>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <fieldset className="form-group mb-0 position-relative has-icon-right">
                      <input
                        type="text"
                        className="form-control border-0 round bg-blue-grey bg-lighten-5 blue-grey darken-4 font-medium-1 search-width"
                        placeholder="Search Here..."
                        value={searchText}
                        onChange={(event) => {
                          setSearchText(event.target.value);
                          if (event.target.value.length > 2) {
                            $(".app-content").addClass("show-overlay");
                          } else {
                            setSearchResult([]);
                            $(".app-content").removeClass("show-overlay");
                          }
                        }}
                      />
                      <div
                        className={`form-control-position float-right ${
                          searchText.length > 0 ? "" : "hidden"
                        }`}
                      >
                        <FiX
                          size={14}
                          className="mb-half"
                          onClick={() => {
                            if (searchText.length > 0) {
                              setSearchText("");
                              setSearchResult([]);
                              $(".app-content").removeClass("show-overlay");
                            }
                          }}
                        />
                      </div>
                    </fieldset>

                    {/* <div className={`bg-blue-grey bg-lighten-5 blue-grey darken-4 font-small-2 mt-half mb-half px-1 pt-half pb-half rounded search-suggestion-width ${searchText.length > 2 ? '' : 'hidden'}`}>
                                            SUGGESTION : {searchResult.length}
                                        </div> */}
                  </div>
                  <div
                    className={`search-input ${
                      searchText.length > 0 ? "open" : ""
                    }`}
                  >
                    <ul
                      className={`search-list ${
                        searchResult != null &&
                        searchResult.length > 0 &&
                        searchText.length > 2
                          ? "show"
                          : ""
                      }`}
                      style={{
                        marginTop: 70,
                        maxHeight: 400,
                        overflowY: "scroll",
                        width: 450,
                      }}
                    >
                      <li
                        className={`auto-suggestion d-flex align-items-center justify-content-between cursor-pointer border-bottom ${
                          searchResult != null && searchResult.length > 0
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <a className="d-flex align-items-center justify-content-left w-100">
                          SUGGESTION :{" "}
                          <b className="ml-half">{searchResult.length}</b>
                        </a>
                      </li>
                      {searchResult != null && searchResult.length > 0 ? (
                        searchResult.map((user, indexUser) => (
                          <li
                            className="auto-suggestion d-flex align-items-center justify-content-between cursor-pointer border-bottom"
                            onClick={(event) => {
                              PRINT("user", user);
                              switch (user.type) {
                                case "Employee":
                                  setSearchText("");
                                  props.history.replace({
                                    pathname: `/profile`,
                                    state: { user_id: user.id },
                                  });
                                  setSearchResult([]);
                                  $(".app-content").removeClass("show-overlay");
                                  break;
                                case "Client":
                                  setSearchText("");
                                  props.history.replace({
                                    pathname: `/client/view`,
                                    state: { client_id: user.id },
                                  });
                                  setSearchResult([]);
                                  $(".app-content").removeClass("show-overlay");
                                  break;
                                case "Group":
                                  setSearchText("");
                                  props.history.replace({
                                    pathname: `/client/group-level-view`,
                                    state: {
                                      client_group_id: user.id,
                                      type: user.type,
                                    },
                                  });
                                  $(".app-content").removeClass("show-overlay");
                                  break;
                                case "Insurar Company":
                                  setSearchText("");
                                  props.history.replace({
                                    pathname: "/insurer/view",
                                    state: { insurer_id: user.id },
                                  });
                                  setSearchResult([]);
                                  $(".app-content").removeClass("show-overlay");
                                  break;
                                default:
                              }
                            }}
                          >
                            <a className="">
                              <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                {renderProfilePhoto(user)}

                                <div className="flex-column ">
                                  <div className="align-items-center ml-1">
                                    <h6 className="font-medium-1 py-0 text-bold-600 mt-half mb-0">
                                      {user.name}
                                      <div
                                        className={`round mt-half px-1 font-small-2 ml-half badge ${getUserTypeColor(
                                          user.type
                                        )}`}
                                      >
                                        {user.type === "Insurar Company"
                                          ? "Insurance Company"
                                          : user.type}
                                      </div>
                                    </h6>
                                    <h6 className="font-small-1 py-0 my-0 text-bold-500 text-light">
                                      {user.description}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <ul
              className="nav navbar-nav float-right"
              style={{ alignItems: "center" }}
            >
              <li className="dropdown dropdown-notification nav-item">
                <a
                  className="nav-link nav-link-label"
                  href="#"
                  data-toggle="dropdown"
                  onClick={(event) => {
                    props.actionHeaderUserNotification({});
                  }}
                >
                  <FiBell size={16} />
                  <span
                    className="badge badge-pill badge-danger badge-up"
                    id="notification-count"
                  >
                    {notificationCount >= 100 ? "99+" : notificationCount}
                  </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                  <li className="dropdown-menu-header">
                    <h6 className="dropdown-header m-0">
                      <span className="grey darken-2">Notifications</span>
                    </h6>
                  </li>

                  <li className="scrollable-container media-list">
                    {notifications != null && notifications.length > 0 ? (
                      notifications.map((notification, notificationIndex) => (
                        <a
                          onClick={() => {
                            switch (notification.type) {
                              case "INSURANCE_MEETING_SELF_NOTIFY":
                              case "INSURANCE_MEETING_ATTENDEE_NOTIFY":
                              case "MEETING_CLOSE_INSURER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    insurer_branch_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "INSURANCE_MEETING_CLOSE_ATTENDEE_NOTIFY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    insurer_branch_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "MEETING_CREATE_WITH_OTHER_RM_COMPANY":
                              case "RM_MEETING_CREATE_SELF_NOTIFY":
                              case "RM_MEETING_CREATE_ATTENDEE_NOTIFY":
                              case "MEETING_CLOSE_CLIENT":
                              case "MEETING_CLOSE_LEAD":
                              case "LEADMEETING_CREATE_WITH_OTHER_RM_COMPANY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    customer_meeting: {
                                      id: notification.record_id,
                                    },
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "CUSTOMER_MEETING_CLOSE_ATTENDEE_NOTIFY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    customer_meeting: {
                                      id: notification.record_id,
                                    },
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "INTERNAL_MEETING_SELF_NOTIFY":
                              case "INTERNAL_MEETING_ATTENDEE_NOTIFY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    internal_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "TPA_MEETING_SELF_NOTIFY":
                              case "TPA_MEETING_ATTENDEE_NOTIFY":
                              case "MEETING_CLOSE_TPA":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    tpa_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "TPA_MEETING_CLOSE_ATTENDEE_NOTIFY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    tpa_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "SURVEYOR_MEETING_SELF_NOTIFY":
                              case "SURVEYOR_MEETING_ATTENDEE_NOTIFY":
                              case "MEETING_CLOSE_SURVEYOR":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    serveyor_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "SURVEYOR_MEETING_CLOSE_ATTENDEE_NOTIFY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    serveyor_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RI_MEETING_ATTENDEE_NOTIFY":
                              case "RI_MEETING_EDIT_ATTENDEE_NOTIFY":
                              case "RI_MEETING_CLOSE_ATTENDEE_NOTIFY":
                              case "MEETING_CLOSE_REINSURANCE":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { ri_meeting: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "INTERNAL_MEETING_CLOSE_ATTENDEE_NOTIFY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    internal_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "COMMENTED_ON_POST":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { post_id: notification.id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "LIKED_ON_POST":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { post_id: notification.id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "REGULARIZE_REQUEST_TO_AM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REGULARIZE_APPROVAL_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "LEAVE_REQUEST_CREATE_AM_RM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "LEAVE_APPROVAL_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "LEAVE_REVIEW_SUPERVISOR":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { type: notification?.type },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REGULARIZE_REVIEW_SUPERVISOR":
                                props.history.replace({
                                  pathname: notification.link,
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "ASSIGNED_RETENTION_CLIENT":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { client_id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "CUSTOMER_MEETING_RETENTION_NOTIFY":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    customer_meeting: {
                                      id: notification.record_id,
                                    },
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "ASSET_REQUEST_CREATE_AM_RM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "ASSET_REJECT_BY_AM_DM":
                              case "ASSET_REJECTED_BY_AM_DM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "ASSET_APPROVE_BY_AM_DM":
                              case "ASSET_APROVAL_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "TRAVEL_REQUEST_CREATE_AM_RM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "TRAVEL_APPROVE_RM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "TASK_POOL_TRAVEL_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "TASK_TO_CANCELLED":
                              case "TASK_TO_COMPLETED":
                              case "TASK_TO_REOPEN":
                              case "TASK_IN_PROGRESS":
                              case "TASK_TO_REVIEW":
                              case "TASK_CREATE":
                              case "SELF_TASK_CREATE":
                              case "TASK_POOL_ASSET_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "TASK_REMINDER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { id: notification?.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "NEW_REIMBURSMENT":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REIMBURSMENT_REQUEST_REJECTED_BY_MANAGER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REIMBURSMENT_REQUEST_APPROVED_BY_FINANCE":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REIMBURSMENT_REQUEST_REJECTED_BY_FINANCE":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REIMBURSMENT_REQUEST_REMINDER_FOR_MANAGER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "REIMBURSMENT_REQUEST_REMINDER_FOR_FINANCE":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "UNASSIGNED_PROPOSAL":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification?.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "ADD_RISK_REQUEST":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RISK_APPROVE_AM_TO_DM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RISK_POOL_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    from: "notification",
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RISK_ASSIGNEE_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RISK_CREATOR_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RISK_REJECT_AM_TO_DM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RISK_REQUEST_BY_RISK_USER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    from: "notification",
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "RISK_REQUEST_ASSIGN_BY_RISK_USER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification?.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "ADD_WELLNESS_REQUEST":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "WELLNESS_APPROVE_AM_TO_DM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "WELLNESS_POOL_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    from: "notification",
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "WELLNESS_REJECT_AM_TO_DM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "WELLNESS_ASSIGNEE_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "WELLNESS_CREATOR_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "WELLNESS_REQUEST_BY_WELLNESS_USER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { from: "notification" },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "WELLNESS_REQUEST_ASSIGN_BY_WELLNESS_USER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "WELLNESS_POOL_NOTIFICATION":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    from: "notification",
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "NEW_EMP_IN_TEAM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "INVESTMENT_DECLARATION_STARTED":
                              case "REMINDER_ITD":
                              case "FINAL_REMINDER_ITD":
                                props.history.replace({
                                  pathname: notification.link,
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "COMMENT_FROM_FINANCE_ON_ITD":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "CUSTOMER_MAPPED_WITH_OTHER_RM":
                              case "CUSTOMER_MAPPED_WITH_OTHER_RM_TO_MANAGER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    client_id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "PROPOSAL_ASSIGNED":
                              case "UNATTENDED_CLIENTS":
                              case "UNATTENDED_LEADS":
                              case "TODAYS_MEETING_REMINDER":
                              case "SET_MEETING_REMINDER":
                              case "LATE_MARK":
                              case "LATE_MARK_LEAVE_DEDUCT":
                              case "NOT_MARKED_ATTENDANCE":
                              case "NOTIFY_USERS_FOR_MARK":
                              case "NOTIFY_USERS_FOR_MARKED_ABSENT":
                                props.history.replace({
                                  pathname: notification.link,
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;

                              case "LEAD_MAPPED_WITH_OTHER_RM":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { client_id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "CLIENT_REASSIGNED":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { client_id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "LEAD_REASSIGNED":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { client_id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "NEW_DOCUMENT_UPLOADED":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { id: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "TAGGED_POST":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    event: "activity-feed",
                                    activity_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "POLICY_EXPIRY_TO_RM":
                                props.history.replace({
                                  pathname: notification.link,
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "NEW_TRAINING_INCLUDE_BQP_FOR_EMPLOYEE_CERTIFICATE_EXPIRY_60_DAYS":
                              case "NEW_TRAINING_EXCLUDE_BQP":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    id: notification.record_id,
                                    type: notification.type,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "TRAINING_REMINDER_BEFORE_5_DAYS":
                              case "TRAINING_REMINDER_BEFORE_30_MINS":
                              case "TRAINING_REMINDER_BEFORE_1_DAYS":
                                props.history.replace({
                                  pathname: notification.link,
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "INVESTMENT_SUBMISSION_STARTED":
                              case "FINAL_REMINDER_ITS":
                              case "REMINDER_ITS":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    from: "Notification",
                                    tab: "Submission",
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "MEETING_CLOSE_REPORTING_MANAGER_CLIENT":
                              case "MEETING_CLOSE_REPORTING_MANAGER_LEAD":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    customer_meeting: {
                                      id: notification.record_id,
                                    },
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "MEETING_CLOSE_REPORTING_MANAGER_SURVEYOR":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    serveyor_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "MEETING_CLOSE_REPORTING_MANAGER_TPA":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    tpa_meeting_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "MEETING_CLOSE_REPORTING_MANAGER_REINSURANCE":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: { ri_meeting: notification.record_id },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              case "MEETING_CLOSE_REPORTING_MANAGER_INSURER":
                                props.history.replace({
                                  pathname: notification.link,
                                  state: {
                                    insurer_branch_id: notification.record_id,
                                  },
                                });
                                $(".app-content").removeClass("show-overlay");
                                break;
                              default:
                            }
                          }}
                        >
                          <div className="media">
                            <div className="media-body">
                              <h6
                                className="text-bold-500 font-small-3"
                                style={{
                                  color:
                                    notification.notification_style.color_code,
                                }}
                              >
                                {notification.title}
                              </h6>
                              <p className="font-small-3 text-secondary lh-half">
                                {notification.notification}
                              </p>
                              <small>
                                <time
                                  className="text-bold-500 blue bg-darken-2"
                                  // dateTime="2015-06-11T18:29:20+08:00"
                                >
                                  {timeSince(notification.created_date)}
                                </time>
                              </small>
                            </div>
                          </div>
                        </a>
                      ))
                    ) : (
                      <></>
                    )}
                  </li>
                  <li className="dropdown-menu-footer">
                    <a
                      className="dropdown-item text-primary text-center text-bold-700"
                      onClick={() => {
                        props.history.push({
                          pathname: "/notification",
                        });
                      }}
                    >
                      View All
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item ml-half">
                                <div
                                    id="mark-in-out"
                                    className={`badge d-flex flex-row align-items-center justify-content-center rounded ${markInOut != null ? markInOut ? 'bg-success bg-darken-2' : 'bg-red bg-darken-2' : 'bg-blue-grey bg-darken-2'} my-half cursor-pointer`}
                                    onClick={(event) => {
                                        if (markInOut === null) {
                                            props.actionMarkIn({ user_time: "02/02/2021" });
                                            $("mark-in-out").removeClass("bg-blue-grey bg-lighten-2").addClass("bg-success bg-accent-3")
                                            setMarkInOut(true)
                                        } else if (markInOut) {
                                            props.actionMarkOut({ user_id: 60 });
                                            $("mark-in-out").removeClass("bg-success bg-accent-3 bg-red bg-lighten-2").addClass("bg-success bg-accent-3")
                                            setMarkInOut(false)
                                        } else {
                                            props.actionMarkIn({ user_time: "02/02/2021" });
                                            $("mark-in-out").removeClass("bg-success bg-accent-3").addClass("bg-blue-grey bg-lighten-2")
                                            setMarkInOut(true)
                                        }
                                    }}
                                >
                                    <span id="mark-in-out-text" className="text-white text-bold-700">{markInOut != null && markInOut ? 'Mark In' : 'Mark Out'}</span>
                                </div>
                            </li> */}
              <li className="dropdown dropdown-user nav-item">
                <a
                  className="dropdown-toggle nav-link dropdown-user-link"
                  href="#"
                  data-toggle="dropdown"
                >
                  {/* Profile Photo if required */}
                  <span className="user-name">
                    {props.auth?.getUser()?.first_name +
                      " " +
                      props.auth?.getUser()?.last_name}
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link className="dropdown-item" to={"/profile"}>
                    <FiUser className="mr-1" size={16} />
                    My Profile
                  </Link>
                  <a
                    className="dropdown-item"
                    onClick={(event) => {
                      event.preventDefault();
                      props.actionLogOut({});
                    }}
                  >
                    <FiPower className="mr-1" size={16} />
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  var LogOut = null;
  var Notifications = null;
  if (
    state.LogOutReducer != null &&
    Object.keys(state.LogOutReducer).length > 0 &&
    state.LogOutReducer.constructor === Object
  ) {
    switch (state.LogOutReducer.tag) {
      case ACTION_LOG_OUT_SUCCESS:
        LogOut = Object.assign({}, state.LogOutReducer.data);
        delete state.LogOutReducer;
        return { LogOut };
      case ACTION_HEADER_USER_NOTIFICATION_SUCCESS:
        Notifications = Object.assign({}, state.LogOutReducer.data);
        delete state.LogOutReducer;
        return { Notifications };
      default:
    }
  }
  return {
    LogOut,
    Notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { actionLogOut, actionHeaderUserNotification },
    dispatch
  );
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Header));

// export default withAuth();
