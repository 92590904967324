import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    LoadPanel,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../component/form/error_label";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED } from '../../../../../constants';
import { actionExportReinsuranceMaster, ACTION_EXPORT_REINSURANCE_MASTER_SUCCESS } from './action';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import { toast } from 'react-toastify';

const MasterExportImport = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const { match } = props

    const [exportReinsuranceMaster, setExportReinsuranceMaster] = useState([]);
    const [exportType, setExportType] = useState(null);

    const [columns, setColumns] = useState([
        { dataField: "id", caption: "ID" },
        { dataField: "name", caption: "Name" },
    ]);


    const initialRender = () => {

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

    }
    useEffect(initialRender, []);

    const onExportReinsuranceMasterUpdate = () => {

        const { ExportReinsuranceMaster } = props;
        if (ExportReinsuranceMaster != null) {
            var ExportReinsuranceMasterResponse = Object.assign({}, ExportReinsuranceMaster);            
            if (ExportReinsuranceMasterResponse.result === true) {                
                switch (exportType) {

                    case 'Branch':
                        setColumns([
                            { dataField: "id", caption: "ID" },
                            { dataField: "name", caption: "Name" },
                        ]);
                        break;

                    case 'Country':
                        setColumns([
                            { dataField: "id", caption: "ID" },
                            { dataField: "country", caption: "Country" },
                            { dataField: "region", caption: "Region" },
                        ]);
                        break;

                    case 'Risk':
                        setColumns([
                            { dataField: "dept_id", caption: "ID" },
                            { dataField: "risk", caption: "Risk" },
                            { dataField: "dept", caption: "Department" },
                        ]);
                        break;

                    case 'Database':
                        setColumns([
                            { dataField: "business_month", caption: "Business Month" },
                            { dataField: "old_control_no", caption: "Old Control No" },
                            { dataField: "control_no", caption: "Control No" },
                            { dataField: "type", caption: "Type" },
                            { dataField: "insured_name", caption: "Insured Name" },
                            { dataField: "inception_date", caption: "Inception Date" },
                        ]);
                        break;
                }
                setExportReinsuranceMaster(ExportReinsuranceMasterResponse.response);

            } else {
                setExportReinsuranceMaster([])
                switch (ExportReinsuranceMasterResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onExportReinsuranceMasterUpdate, [props.ExportReinsuranceMaster]);

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const onSubmit = (data) => {
        props.actionExportReinsuranceMaster({ type: data.type });
    }

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <h3 className="content-header-title  text-capitalize mb-0">Export Reinsurance Master</h3>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push(`/reinsurance/import`);
                                }}
                            >
                                Import Report Data
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="label-control">Export Type&nbsp;<span className="danger">*</span></label>
                                            <Controller
                                                name="type"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        dataSource={[
                                                            {
                                                                value: 'Branch',
                                                                text: 'Branch'

                                                            },
                                                            {
                                                                value: 'Country',
                                                                text: 'Region/Country'
                                                            },
                                                            {
                                                                value: 'Risk',
                                                                text: 'Department/Risk'
                                                            },
                                                            {
                                                                value: 'Database',
                                                                text: 'Report Database'
                                                            },
                                                        ]}
                                                        allowFiltering={false}
                                                        placeholder="Select Export Type"
                                                        value={field.value}
                                                        fields={{ text: 'text', value: 'value' }}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                setExportReinsuranceMaster([])
                                                                field.onChange(event.itemData.value)
                                                                setExportType(event.itemData.value);
                                                            }
                                                        }}
                                                    />
                                                }
                                                rules={{
                                                    required: 'Export Type is required'
                                                }}
                                            />

                                            {errors.type && <ErrorLabel message={errors.type?.message} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none mt-0 pt-0">                                
                                <button
                                    id="save_master"
                                    className="btn btn-primary mr-1 shadow round text-uppercase"
                                    type="submit"
                                >

                                    <div className="d-flex align-items-center">
                                        Submit
                                        <span id="master-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                    </div>
                                </button>
                            </div>
                            {
                                exportReinsuranceMaster.length > 0 ?
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-md-12">
                                                    <DataGrid
                                                        dataSource={exportReinsuranceMaster}
                                                        showBorders={true}
                                                        columnAutoWidth={true}
                                                        export={{
                                                            fileName: "Reinsurance Master Data",
                                                            enabled: true,
                                                            allowExportSelectedData: false,
                                                            excelFilterEnabled: true,
                                                        }}
                                                    >
                                                        <LoadPanel enabled={true} />
                                                        <SearchPanel visible={true} />
                                                        <FilterRow visible={true} />
                                                        <HeaderFilter visible={true} />
                                                        {renderColumns()}
                                                        <Pager
                                                            visible={true}
                                                            showPageSizeSelector={false}
                                                            showNavigationButtons={true}
                                                            allowedPageSizes={[4, 6, 10]}
                                                        />
                                                        <Paging
                                                            defaultPageIndex={0}
                                                            defaultPageSize={10}
                                                            enabled={true}
                                                        />
                                                    </DataGrid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                        </form>
                    </div>

                </div>
            </section>            
        </>
    )
}

const mapStateToProps = (state) => {

    var ExportReinsuranceMaster = null;

    if (
        state.ExportReinsuranceMasterReducer != null &&
        Object.keys(state.ExportReinsuranceMasterReducer).length > 0 &&
        state.ExportReinsuranceMasterReducer.constructor === Object
    ) {
        switch (state.ExportReinsuranceMasterReducer.tag) {
            case ACTION_EXPORT_REINSURANCE_MASTER_SUCCESS:                
                ExportReinsuranceMaster = Object.assign({}, state.ExportReinsuranceMasterReducer.data);
                delete state.ExportReinsuranceMasterReducer;
                return { ExportReinsuranceMaster };
            default:
        }
    }
    return {
        ExportReinsuranceMaster,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionExportReinsuranceMaster }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MasterExportImport))