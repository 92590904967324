import React from 'react';
import { Route, Switch } from 'react-router';
import DepartmentList from './list';
import DepartmentAdd from './add';
import DepartmentEdit from './edit';
import DepartmentView from './view';

const DepartmentMasterRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/view`} component={DepartmentView} />
                <Route path={`${match.url}/edit`} component={DepartmentEdit} />
                <Route path={`${match.url}/add`} component={DepartmentAdd} />
                <Route path={`${match.url}`} component={DepartmentList} />
            </Switch>
        </>
    )
}

export default DepartmentMasterRoute