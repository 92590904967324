import { ConnectedRouter } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import { Route } from 'react-router-dom';
import ProviderAuth from 'react-auth-guard';
import PublicRoute from './public';
import AuthRoute from './auth';
import { clearData, getData, KEY_USER } from '../../utils/preferences';

const authGetters = {
  getUser: () => {
    var objUser = null
    var user = getData(KEY_USER)
    if(user != null) {
      objUser = JSON.parse(user)
    }
    return objUser
  },
}

const fetchUser = ({ token }) => new Promise((resolve, reject) => {
  const { sub } = jwtDecode(token)
  if (sub > 0 ) {
    return resolve()
  }
  return reject()
})

const Loading = ({ isLoading, children }) => (isLoading
  ? <h1>Loading</h1>
  : children
)


const AppRoute = (props) => {
  const { history, match } = props;

  return (
      <ProviderAuth
        fetchUser={fetchUser}
        getters={authGetters}
        onLogout={async () => {
          await clearData();
          window.location.replace('/login')
        }}
        onLoginFail={() => {
          //login fail
        }}
        onLogin={({ userId }) => {
          // login success
        }}
      >
          {
            ({ authenticating, authenticated }) => (
              <Loading isLoading={(authenticating)}>
                {
                  authenticated
                    ?
                      <ConnectedRouter history={history}>
                        <Route path={`${match.url}`} component={AuthRoute} {...props} />
                      </ConnectedRouter>
                    :
                      <ConnectedRouter history={history}>
                        <Route path={`${match.url}`} component={PublicRoute} {...props} />
                      </ConnectedRouter>
                }
              </Loading>
            )
          }
      </ProviderAuth>
  )
}

export default AppRoute