import createReducer from "../../../reducers/createReducer";
import { ACTION_TPA_MASTER_CITIES_SUCCESS, ACTION_TPA_MASTER_STATES_SUCCESS, ACTION_CREATE_NEW_TPA_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const NewTpaReducer = createReducer(INITIAL_STATE, {
    [ACTION_TPA_MASTER_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TPA_MASTER_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TPA_MASTER_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TPA_MASTER_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CREATE_NEW_TPA_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CREATE_NEW_TPA_SUCCESS,
                loading: false,
            }
        );
    },    
});
export default NewTpaReducer;