import React, { useState, useEffect } from "react";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { verifyPermission } from '../../../../../../utils';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import $ from 'jquery';
import { UNAUTHORIZED  } from '../../../../../../constants';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { actionTransportList, ACTION_TRANSPORT_LIST_SUCCESS } from './action';
import { actionTransportStatus, ACTION_TRANSPORT_STATUS_SUCCESS } from './action';
import _ from "lodash";

const Transport = (props) => {
    const { match } = props;
    const [TransportDetail, setTransportDetail] = useState([]);
    const columns = [
        // { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "title", caption: " Transport Name" },
        { dataField: "is_km", caption: "Kilometer Applicable" },
        { dataField: "km_rate", caption: " Kilometer Rate " },
        { dataField: "is_document", caption: "Document Applicable" },
        { dataField: "status", caption: "Status" },

    ];
    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionTransportList({});
    }
    useEffect(initialRender, []);

    const renderKilometerRate = (cell) => {
        if (cell.data.is_km === 'Y') {
            return <div>{cell.data.km_rate}</div>;
        } else {
            return <div>-</div>;
        }
    }

    const renderTitle = (cell) => {
        return _.replace(cell.data.title, new RegExp("_"), " ");
    }

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'A':
                return <div className={"badge badge-success round px-1 "}
                // cursor-pointer
                // onClick={() => {
                //     Swal.fire({
                //         title: `Inactivate Team`,
                //         text: `Are you sure, you want to inactivate ?`,
                //         showCancelButton: true,
                //         cancelButtonText: 'Cancel',
                //         confirmButtonText: 'Ok',
                //         confirmButtonColor: PRIMARY_COLOR,
                //     }).then(success => {
                //         if (success.isConfirmed) {
                //             // $("#progress-loader").removeClass("hidden");
                //             props.actionTransportStatus({
                //                 id: cell.data.id,
                //                 status: 'I'
                //             })
                //         }
                //     });
                // }}
                >Active</div>;
            case 'I':
                return <div className={"badge badge-danger round px-1"}
                // onClick={() => {
                //     Swal.fire({
                //         title: `Activate Team`,
                //         text: `Are you sure, you want to activate ?`,
                //         showCancelButton: true,
                //         cancelButtonText: 'Cancel',
                //         confirmButtonText: 'Ok',
                //         confirmButtonColor: PRIMARY_COLOR,
                //     }).then(success => {
                //         if (success.isConfirmed) {
                //             props.actionTransportStatus({
                //                 id: cell.data.id,
                //                 status:'A'
                //             })
                //         }
                //     });
                // }}
                >Inactive</div>;
            default:
                return <></>
        }
    }
    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("UPDATE_ROLES") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { roles: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }
                    </Column>
                );
            }
            else {
                if (objColumn.dataField === "transport_id") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            alignment="left"
                            width={200}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "km_rate") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderKilometerRate}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "title") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderTitle}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}

                        ></Column>

                    )
                }

            }


        });

        return arrColumns;
    };
    const onTransportListUpdate = () => {
        const { TransportDetail } = props
        var TransportListResponse = Object.assign({}, TransportDetail);
        if (TransportListResponse.result === true) {
            setTransportDetail(TransportListResponse.response);
        } else {
            switch (TransportListResponse.status) {
                case UNAUTHORIZED:

                    break;
                default:
            }
        }
    }
    useEffect(onTransportListUpdate, [props.TransportDetail])

    const onTransportStatusUpdate = () => {
        const { TransportStatus } = props;
        var TransportStatusResponse = Object.assign({}, TransportStatus);
        if (TransportStatusResponse.result === true) {
            props.actionTransportList();
            $("#progress-loader").addClass("hidden");
        } else {
            switch (TransportStatusResponse.status) {
                case UNAUTHORIZED:
                    break;
                default:
            }
        }
    }
    useEffect(onTransportStatusUpdate, [props.TransportStatus])

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Transport</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push('/settings/transport/add');
                                }}
                            >
                                Create Transport
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={TransportDetail}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "Nature of Expenses",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var TransportDetail = null;
    var TransportStatus = null;
    if (
        state.TransportListReducer != null &&
        Object.keys(state.TransportListReducer).length > 0 &&
        state.TransportListReducer.constructor === Object
    ) {
        switch (state.TransportListReducer.tag) {
            case ACTION_TRANSPORT_LIST_SUCCESS:
                TransportDetail = Object.assign({}, state.TransportListReducer.data);
                delete state.TransportListReducer;
                return { TransportDetail };
            case ACTION_TRANSPORT_STATUS_SUCCESS:
                TransportStatus = Object.assign({}, state.TransportListReducer.data);
                delete state.TransportListReducer;
                return { TransportStatus };
            default:

        }
    }
    return {
        TransportDetail,
        TransportStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTransportList, actionTransportStatus }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Transport))
