import React from 'react';
import { Route, Switch } from 'react-router';
import ProposalSettingsEdit from './edit';
import ProposalSettingsList from './list';
import ProposalSettingsAdd from './add';

const ProposalSettingRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`} component={ProposalSettingsAdd} />
                <Route path={`${match.url}/edit`} component={ProposalSettingsEdit} />
                <Route path={`${match.url}`} component={ProposalSettingsList} />
            </Switch>
        </>
    )
}

export default ProposalSettingRoute