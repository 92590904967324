import { ACTION_CLAIMS_LIST_SUCCESS } from "./action";
import {ACTION_REOPEN_CLAIM_SUCCESS} from "./action";
import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ClaimsReducer = createReducer(INITIAL_STATE, {
    [ACTION_CLAIMS_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REOPEN_CLAIM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REOPEN_CLAIM_SUCCESS,
                loading: false,
            }
        );
    },
});
export default ClaimsReducer;