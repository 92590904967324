import { ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS } from "./action";
import createReducer from "../../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const TabGroupBusinessSummaryReducer = createReducer(INITIAL_STATE, {
    [ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS,
                loading: false,
            }
        );
    },
});
export default TabGroupBusinessSummaryReducer