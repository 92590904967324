import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_EMPLOYEE_DETAILS } from '../../../../../../api/constants';
import {API_REIMBURSEMENT_REPORT} from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_REIMBURSEMENT_REPORT_DETAILS_LOADING = 'ACTION_REIMBURSEMENT_REPORT_DETAILS_LOADING';
export const ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS = 'ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS';

export const ACTION_REIMBURSEMENT_REPORT_LOADING = 'ACTION_REIMBURSEMENT_REPORT_LOADING';
export const ACTION_REIMBURSEMENT_REPORT_SUCCESS = 'ACTION_REIMBURSEMENT_REPORT_SUCCESS';

export function actionReimbursementReportDetails() {
    return (dispatch) =>
        Api.get(API_MASTER_EMPLOYEE_DETAILS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE data', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_REPORT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionRembursementReport(params) {
    return (dispatch) =>
        Api.post(API_REIMBURSEMENT_REPORT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_REPORT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_REPORT_LOADING, { loading: false, data }));
        });
}