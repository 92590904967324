import { ACTION_HOLIDAY_ADD_SUCCESS } from "./action";
import {ACTION_HOLIDAY_BRANCH_SUCCESS} from "./action"

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const HolidayAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_HOLIDAY_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_HOLIDAY_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_HOLIDAY_BRANCH_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_HOLIDAY_BRANCH_SUCCESS,
                loading: false,
            }
        );
    },
});

export default HolidayAddReducer;    