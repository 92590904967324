import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from '../../../../../../component/form/error_label';
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { actionGetRMDetails, ACTION_MASTER_ASSIGN_RM_SUCCESS } from './action';
import { actionGetRMLeads, ACTION_RM_GET_LEADS_SUCCESS } from './action';
import { actionGetRMClients, ACTION_RM_GET_CLIENTS_SUCCESS } from './action';
import { actionRmTransfer, ACTION_RM_TRANSFER_SUCCESS } from './action';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from 'react-toastify';

const RMTransferAdd = (props) => {
    const { handleSubmit, control, formState: { errors }, setValue } = useForm();

    const [assignRM, setAssignRM] = useState([]);
    const [assignRMAll, setAssignRMAll] = useState([]);
    const [leadDetails, setLeadDetails] = useState([]);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [meetingDetails, setMeetingDetails] = useState([]);
    const [selectLead, setSelectLead] = useState(false);
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [selectMeeting, setSelectMeeting] = useState(false);
    const [selectedLeadDetails, setSelectedLeadDetails] = useState([]);
    const [selectedClientDetails, setSelectedClientDetails] = useState([]);
    const [selectMeetingDetails, setSelectMeetingDetails] = useState([]);
    const [fromRMName, setFromRMName] = useState(null);
    const [toRMName, setToRMName] = useState(null);

    const initialRender = () => {
        props.actionGetRMDetails({ user_id: props.auth.getUser().id, type: "all" });
    }
    useEffect(initialRender, []);

    const onGetRMDetailsUpdate = () => {
        const { GetRMDetails } = props;
        var GetRMDetailsResponse = Object.assign({}, GetRMDetails);
        if (GetRMDetailsResponse.result === true) {
            setAssignRMAll(GetRMDetailsResponse.response);
            const activeUsers = GetRMDetailsResponse.response.filter(user => user.status === "active");
            const arrAssignRM = Object.assign([], activeUsers);

            const user = {
                user_id: 0,
                status: "active",
                full_name: "Open"
            };

            arrAssignRM.unshift(user);

            setAssignRM(arrAssignRM);
        } else {
            switch (GetRMDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setAssignRMAll([]);
                    setAssignRM([]);
                    break;
                default:
            }
        }
    }
    useEffect(onGetRMDetailsUpdate, [props.GetRMDetails]);

    const onGetRMLeadsUpdate = () => {
        const { GetRMLeads } = props;
        var GetRMLeadsResponse = Object.assign({}, GetRMLeads);
        if (GetRMLeadsResponse.result === true) {
            setLeadDetails(GetRMLeadsResponse.response)
        } else {
            switch (GetRMLeadsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setLeadDetails([]);
                    break;
                default:
            }
        }
    }
    useEffect(onGetRMLeadsUpdate, [props.GetRMLeads]);

    const onGetRMClientsUpdate = () => {
        const { GetRMClients } = props;
        var GetRMClientsResponse = Object.assign({}, GetRMClients);
        if (GetRMClientsResponse.result === true) {
            setCustomerDetails(GetRMClientsResponse.response);
        } else {
            switch (GetRMClientsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setCustomerDetails([]);
                    break;
                default:
            }
        }
    }
    useEffect(onGetRMClientsUpdate, [props.GetRMClients]);

    const onRmTransferUpdate = () => {
        const { RmTransfer } = props;
        var RmTransferResponse = Object.assign({}, RmTransfer);
        $("#rm-transfer").prop("disabled", false);
        if (RmTransferResponse.result === true) {
            $("#rm-transfer-loader").addClass("hidden");
            if (getData(KEY_TOAST_MESSAGE) != null) {
                toast.success(getData(KEY_TOAST_MESSAGE));
                removeData(KEY_TOAST_MESSAGE);
            }
            toast.success(RmTransferResponse.message);
            renderResetValue();
        } else {
            $("#rm-transfer-loader").addClass("hidden");
            switch (RmTransferResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    break;
                default:
            }
        }
    }
    useEffect(onRmTransferUpdate, [props.RmTransfer]);

    const renderResetValue = () => {
        setAssignRM([]);
        setSelectCustomer(false);
        setSelectLead(false);
        setSelectMeeting(false);
        setLeadDetails([]);
        setCustomerDetails([]);
        setMeetingDetails([]);
        setFromRMName(null);
        setToRMName(null);
        setSelectedLeadDetails([]);
        setSelectedClientDetails([]);
        setSelectMeetingDetails([]);
        setValue('from_rm_id', null);
        setValue('to_rm_id', null);
        props.actionGetRMDetails({ user_id: props.auth.getUser().id });
    }

    const onSubmit = (data) => {
        data.lead_ids = selectedLeadDetails
        data.client_ids = selectedClientDetails
        if (data != null) {
            Swal.fire({
                title: 'Info',
                icon: 'info',
                html: "Are you sure to transfer RM",
                showCancelButton: true,
                cancelButtonText: 'No',
                showConfirmButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: PRIMARY_COLOR,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.actionRmTransfer(data);
                    $("#rm-transfer-loader").removeClass("hidden");
                    $("#rm-transfer").prop("disabled", true);
                } else {
                    renderResetValue();
                }
            });
        }

    }

    return (
        <>
            <ScreenTitle title="RM Transfer" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">From&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="from_rm_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="from_rm_id"
                                                                name="from_rm_id"
                                                                dataSource={assignRMAll}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                sortOrder={'Ascending'}
                                                                placeholder="Select RM name"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id);
                                                                        props.actionGetRMLeads({ rm_id: event.itemData.user_id });
                                                                        props.actionGetRMClients({ rm_id: event.itemData.user_id });
                                                                        setFromRMName(event.itemData.user_id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'From RM name is required'
                                                        }}
                                                    />
                                                    {errors.from_rm_id && <ErrorLabel message={errors.from_rm_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">To&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="to_rm_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="to_rm_id"
                                                                name="to_rm_id"
                                                                dataSource={assignRM}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                sortOrder={'Ascending'}
                                                                placeholder="Select RM name"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id);
                                                                        setToRMName(event.itemData.user_id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'To RM name is required'
                                                        }}
                                                    />
                                                    {errors.to_rm_id && <ErrorLabel message={errors.to_rm_id?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            fromRMName != null && toRMName != null ?
                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="leads"
                                                                    id="leads"
                                                                    onClick={(event) => {
                                                                        if (event.target.checked === true) {
                                                                            setSelectLead(true);
                                                                        } else {
                                                                            setSelectLead(false);
                                                                        }
                                                                    }}
                                                                />
                                                                {errors.leads && <ErrorLabel message={errors.leads?.message} />}
                                                                <label class="custom-control-label" for="leads">Lead</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-12 col-md-4 col-lg-4 ${toRMName != 0 ? '' : 'hidden'}`}>
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="customers"
                                                                    id="customers"
                                                                    onClick={(event) => {
                                                                        if (event.target.checked === true) {
                                                                            setSelectCustomer(true);
                                                                        } else {
                                                                            setSelectCustomer(false);
                                                                        }
                                                                    }}
                                                                />
                                                                {errors.customers && <ErrorLabel message={errors.customers?.message} />}
                                                                <label class="custom-control-label" for="customers">Customer</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className={`col-12 col-md-4 col-lg-4 ${toRMName != 0 ? '' : 'hidden'}`}>
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="meeting"
                                                                    id="meeting"
                                                                    onClick={(event) => {
                                                                        if (event.target.checked === true) {
                                                                            setSelectMeeting(true);
                                                                        } else {
                                                                            setSelectMeeting(false);
                                                                        }
                                                                    }}
                                                                />
                                                                {errors.meeting && <ErrorLabel message={errors.meeting?.message} />}
                                                                <label class="custom-control-label" for="meeting">Meeting</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            selectLead === true ?
                                                <div className="row">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        Lead
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="lead_ids"
                                                                    id="lead_ids"
                                                                    onClick={(event) => {
                                                                        var arrLeads = Object.assign([], leadDetails)
                                                                        var updatedLeads = []
                                                                        var selectedLeads = []
                                                                        arrLeads.forEach((item, index) => {
                                                                            item.selected = event.target.checked
                                                                            updatedLeads.push(item)
                                                                        })
                                                                        setLeadDetails(updatedLeads)
                                                                        arrLeads.forEach((item, index) => {
                                                                            if (event.target.checked) {
                                                                                selectedLeads.push(item.id)
                                                                            }
                                                                        })
                                                                        setSelectedLeadDetails(selectedLeads)
                                                                    }}
                                                                />
                                                                {errors.lead_ids && <ErrorLabel message={errors.lead_ids?.message} />}
                                                                <label class="custom-control-label" for="lead_ids">Select All</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Lead Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    leadDetails.map((lead, indexLead) => (
                                                                        <tr>
                                                                            <th width="10%">
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`${lead.id}`}
                                                                                    name={`${lead.id}`}
                                                                                    defaultChecked={lead.selected ? true : false}
                                                                                    checked={lead.selected ? true : false}
                                                                                    onClick={(event) => {
                                                                                        var arrLeads = Object.assign([], leadDetails)
                                                                                        var ObjLead = Object.assign({}, arrLeads[indexLead])
                                                                                        ObjLead.selected = event.target.checked ? true : false
                                                                                        if (ObjLead.selected === false) {
                                                                                            $('#lead_ids').prop("checked", false);
                                                                                        }
                                                                                        arrLeads[indexLead] = ObjLead
                                                                                        setLeadDetails(arrLeads)
                                                                                        if (event.target.checked) {
                                                                                            selectedLeadDetails.push(lead.id)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </th>
                                                                            <td>{lead.name}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            selectCustomer === true ?
                                                <div className={`row ${toRMName != 0 ? '' : 'hidden'}`}>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        Customer
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="client_ids"
                                                                    id="client_ids"
                                                                    onClick={(event) => {
                                                                        var arrCustomers = Object.assign([], customerDetails)
                                                                        var updatedCustomers = []
                                                                        var selectedCustomers = []
                                                                        arrCustomers.forEach((item, index) => {
                                                                            item.selected = event.target.checked
                                                                            updatedCustomers.push(item)
                                                                        })
                                                                        setCustomerDetails(updatedCustomers)
                                                                        arrCustomers.forEach((item, index) => {
                                                                            if (event.target.checked) {
                                                                                selectedCustomers.push(item.id)
                                                                            }
                                                                        })
                                                                        setSelectedClientDetails(selectedCustomers)
                                                                    }}
                                                                />

                                                                <label class="custom-control-label" for="client_ids">Select All</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Customer Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    customerDetails.map((customer, indexCustomer) => (
                                                                        <tr>
                                                                            <th width="10%">
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`${customer.id}`}
                                                                                    name={`${customer.id}`}
                                                                                    defaultChecked={customer.selected ? true : false}
                                                                                    checked={customer.selected ? true : false}
                                                                                    onClick={(event) => {
                                                                                        var arrCustomers = Object.assign([], customerDetails)
                                                                                        var ObjCustomer = Object.assign({}, arrCustomers[indexCustomer])
                                                                                        ObjCustomer.selected = event.target.checked ? true : false
                                                                                        if (ObjCustomer.selected === false) {
                                                                                            $('#client_ids').prop("checked", false);
                                                                                        }
                                                                                        arrCustomers[indexCustomer] = ObjCustomer
                                                                                        setCustomerDetails(arrCustomers)
                                                                                        if (event.target.checked) {
                                                                                            selectedClientDetails.push(customer.id)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </th>
                                                                            <td>{customer.name}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            selectMeeting === true ?
                                                <div className={`row ${toRMName != 0 ? '' : 'hidden'}`}>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        Meeting
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="meeting_ids"
                                                                    id="meeting_ids"
                                                                    onClick={(event) => {
                                                                        var arrMeetings = Object.assign([], meetingDetails)
                                                                        var updatedMeetings = []
                                                                        var selectedMeetings = []
                                                                        arrMeetings.forEach((item, index) => {
                                                                            item.selected = event.target.checked
                                                                            updatedMeetings.push(item)
                                                                        })
                                                                        setMeetingDetails(updatedMeetings)
                                                                        arrMeetings.forEach((item, index) => {
                                                                            if (event.target.checked) {
                                                                                selectedMeetings.push(item.id)
                                                                            }
                                                                        })
                                                                        setSelectMeetingDetails(selectedMeetings)
                                                                    }}
                                                                />

                                                                <label class="custom-control-label" for="meeting_ids">Select All</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Meeting Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    meetingDetails.map((meeting, indexMeeting) => (
                                                                        <tr>
                                                                            <th width="10%">
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`${meeting.id}`}
                                                                                    name={`${meeting.id}`}
                                                                                    defaultChecked={meeting.selected ? true : false}
                                                                                    checked={meeting.selected ? true : false}
                                                                                    onClick={(event) => {
                                                                                        var arrMeetings = Object.assign([], meetingDetails)
                                                                                        var ObjMeeting = Object.assign({}, arrMeetings[indexMeeting])
                                                                                        ObjMeeting.selected = event.target.checked ? true : false
                                                                                        if (ObjMeeting.selected === false) {
                                                                                            $('#meeting_ids').prop("checked", false);
                                                                                        }
                                                                                        arrMeetings[indexMeeting] = ObjMeeting
                                                                                        setMeetingDetails(arrMeetings)
                                                                                        if (event.target.checked) {
                                                                                            selectMeetingDetails.push(meeting.id)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </th>
                                                                            <td>{meeting.name}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="rm-transfer"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="rm-transfer-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            type="button"
                                            onClick={(event) => {
                                                renderResetValue();
                                                event.preventDefault();
                                                props.actionGetRMDetails({ user_id: props.auth.getUser().id });
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var GetRMDetails = null;
    var GetRMLeads = null;
    var GetRMClients = null;
    var RmTransfer = null;

    if (
        state.RMTransferReducer != null &&
        Object.keys(state.RMTransferReducer).length > 0 &&
        state.RMTransferReducer.constructor === Object
    ) {
        switch (state.RMTransferReducer.tag) {
            case ACTION_MASTER_ASSIGN_RM_SUCCESS:
                GetRMDetails = Object.assign({}, state.RMTransferReducer.data);
                delete state.RMTransferReducer;
                return { GetRMDetails };
            case ACTION_RM_GET_LEADS_SUCCESS:
                GetRMLeads = Object.assign({}, state.RMTransferReducer.data);
                delete state.RMTransferReducer;
                return { GetRMLeads };
            case ACTION_RM_GET_CLIENTS_SUCCESS:
                GetRMClients = Object.assign({}, state.RMTransferReducer.data);
                delete state.RMTransferReducer;
                return { GetRMClients };
            case ACTION_RM_TRANSFER_SUCCESS:
                RmTransfer = Object.assign({}, state.RMTransferReducer.data);
                delete state.RMTransferReducer;
                return { RmTransfer };
            default:
        }
    }

    return {
        GetRMDetails,
        GetRMLeads,
        GetRMClients,
        RmTransfer
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGetRMDetails, actionGetRMLeads, actionGetRMClients, actionRmTransfer }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RMTransferAdd))