import createReducer from "../../../../../../../reducers/createReducer";
import { ACTION_RISK_CHANGE_STATUS_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const RiskManagementChangeStatusReducer = createReducer(INITIAL_STATE, {
    [ACTION_RISK_CHANGE_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_CHANGE_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
}
);
export default RiskManagementChangeStatusReducer;