import { ACTION_LEAVE_MASTER_EMPLOYEE_DETAILS_SUCCESS } from './action';
import { ACTION_LEAVE_REPORT_SUCCESS } from './action';

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeaveReportReducer = createReducer(INITIAL_STATE, {

    [ACTION_LEAVE_MASTER_EMPLOYEE_DETAILS_SUCCESS](state, action) {
        
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_LEAVE_MASTER_EMPLOYEE_DETAILS_SUCCESS,
            loading: false
        });
    },

    [ACTION_LEAVE_REPORT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_LEAVE_REPORT_SUCCESS,
            loading: false
        });
    }
});

export default LeaveReportReducer;
