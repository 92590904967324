import createReducer from "../../../../reducers/createReducer";

import {ACTION_MY_PROFILE_SUCCESS} from './action'
import { ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS } from "./action";
import { ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS } from "./action";
import { ACTION_CHANGE_PASSWORD_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const MyProfileReducer = createReducer(INITIAL_STATE, {
    [ACTION_MY_PROFILE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MY_PROFILE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DELETE_EMPLOYEE_EDUCATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CHANGE_PASSWORD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CHANGE_PASSWORD_SUCCESS,
                loading: false,
            }
        );
    },
});
export default MyProfileReducer;