import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import { actionBrokerTrainingList, ACTION_BROKER_TRAINING_LIST_SUCCESS } from './action';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import moment from "moment";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { verifyPermission } from "../../../../../../utils";

const HRMSBrokerTrainingList = (props) => {
    const { match } = props;
    const [brokerTrainingDetail, setBrokerTrainingDetail] = useState([]);
    const [type, setType] = useState('ongoing');
    const [upcomingCount, setUpcomingCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [ongoingCount, setOngoingCount] = useState(0);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionBrokerTrainingList({ type: type });
    }
    useEffect(initialRender, []);

    const onBrokerTrainingListUpdate = () => {
        const { BrokerTrainingList } = props
        if (BrokerTrainingList != null) {
            var BrokerTrainingListResponse = Object.assign({}, BrokerTrainingList);
            if (BrokerTrainingListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                if (type === "upcoming") {
                    setUpcomingCount(BrokerTrainingListResponse.response.length);
                    setBrokerTrainingDetail(BrokerTrainingListResponse.response);
                    setCompletedCount(0);
                    setOngoingCount(0);
                } else if (type === "completed") {
                    setCompletedCount(BrokerTrainingListResponse.response.length);
                    setBrokerTrainingDetail(BrokerTrainingListResponse.response);
                    setUpcomingCount(0);
                    setOngoingCount(0);
                } else {
                    setOngoingCount(BrokerTrainingListResponse.response.length);
                    setBrokerTrainingDetail(BrokerTrainingListResponse.response);
                    setUpcomingCount(0);
                    setCompletedCount(0);
                }
            } else {
                $("#progress-loader").addClass("hidden");
                switch (BrokerTrainingListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setBrokerTrainingDetail([]);
                        setUpcomingCount(0);
                        setCompletedCount(0);
                        setOngoingCount(0);
                        $("#progress-loader").addClass("hidden");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onBrokerTrainingListUpdate, [props.BrokerTrainingList])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "training_type", caption: "Training Type", visible: true },
        { dataField: "training_name", caption: "Training Name", visible: true },
        { dataField: "start_date", caption: "Start Date", visible: false },
        { dataField: "start_date_formated", caption: "Start Date", visible: true },
        { dataField: "end_date", caption: "End Date", visible: false },
        { dataField: "end_date_formated", caption: "End Date", visible: true },
        { dataField: "training_mode", caption: "Training Mode", visible: true },
        { dataField: "created_by_name", caption: "Created By", visible: true },
        { dataField: "created_on", caption: "Created On", visible: true },
    ]);

    const renderStartDate = (cell) => {
        return <div>{cell?.data?.start_date_formated != null ? moment(cell?.data?.start_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderEndDate = (cell) => {
        return <div>{cell?.data?.end_date_formated != null ? moment(cell?.data?.end_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission('HRMS_SETTING_TRAINING_VIEW') ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission('HRMS_SETTING_TRAINING_EDIT') ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${type === "completed" ? 'hidden' : ''}`}
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "start_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStartDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "end_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEndDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                id="btn-my-meeting"
                                className={`btn ${type === "ongoing" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType("ongoing");
                                    props.actionBrokerTrainingList({ type: 'ongoing' });
                                }}
                            >
                                Ongoing {ongoingCount > 0 ? `(` + ongoingCount + `)` : ``}
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                id="btn-my-meeting"
                                className={`btn ${type === "upcoming" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType("upcoming");
                                    props.actionBrokerTrainingList({ type: 'upcoming' });
                                }}
                            >
                                Upcoming {upcomingCount > 0 ? `(` + upcomingCount + `)` : ``}
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                id="btn-my-team-meeting"
                                className={`btn ${type === "completed" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType("completed");
                                    props.actionBrokerTrainingList({ type: 'completed' });
                                }}
                            >
                                Completed <span className='mr-half'>{completedCount > 0 ? `(` + completedCount + `)` : ''}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Training Setting</h5>
                    </div>
                    {
                        verifyPermission('HRMS_SETTING_TRAINING_ADD') ?
                            <div className="content-header-right col-12 col-md-6 col-lg-6">
                                <ul class="nav nav-pills float-right">
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                props.history.push(`${match.url}/add`);
                                            }}
                                        >
                                            Add
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            :
                            <></>
                    }
                </div>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={brokerTrainingDetail}
                                    showBorders={true}
                                    export={{
                                        fileName: "Training settings",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("start_date_formated", "visible", false)
                                        event.component.columnOption("start_date", "visible", true)
                                        event.component.columnOption("end_date_formated", "visible", false)
                                        event.component.columnOption("end_date", "visible", true)

                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("start_date_formated", "visible", true)
                                        event.component.columnOption("start_date", "visible", false)
                                        event.component.columnOption("end_date_formated", "visible", true)
                                        event.component.columnOption("end_date", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var BrokerTrainingList = null;

    if (
        state.BrokerTrainingListReducer != null &&
        Object.keys(state.BrokerTrainingListReducer).length > 0 &&
        state.BrokerTrainingListReducer.constructor === Object
    ) {
        switch (state.BrokerTrainingListReducer.tag) {
            case ACTION_BROKER_TRAINING_LIST_SUCCESS:
                BrokerTrainingList = Object.assign({}, state.BrokerTrainingListReducer.data);
                delete state.BrokerTrainingListReducer;
                return { BrokerTrainingList };
            default:

        }
    }
    return {
        BrokerTrainingList,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionBrokerTrainingList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(HRMSBrokerTrainingList))