import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { actionFinanceLimitList, ACTION_FINANCE_LIMIT_LIST_SUCCESS } from './action';

const FinanceManager = (props) => {
    const { match } = props;
    const [FinanceManager, setFinanceManager] = useState([]);
    const initialRender = () => {
        props.actionFinanceLimitList();
    }
    useEffect(initialRender, []);

    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "entity_name", caption: "Entity", visible: true },
        { dataField: "deparment_name", caption: "Department", visible: true },
        { dataField: "designation_name", caption: "Designation", visible: true },
        { dataField: "mobile_expenses", caption: "Mobile Limit", visible: true },
        { dataField: "conveyance_expenenses", caption: "Conveyance Limit", visible: true },
        { dataField: "business_promotion", caption: "Business Promotion Limit", visible: false },
        { dataField: "staff_welfare", caption: "Staff Welfare Limit", visible: false },
        { dataField: "postage_expenses", caption: "Postage Expenses Limit", visible: false },
        { dataField: "internet_expenses", caption: "Internet Expenses Limit", visible: false },
        { dataField: "office_expenses", caption: "Office Expenses Limit", visible: false },
        { dataField: "printing_n_stationery", caption: "Printing And Stationery Limit", visible: false },
        { dataField: "training_n_data_processing_charges", caption: "Training And Data Processing Charges Limit", visible: false },
        { dataField: "insurance_charges", caption: "Insurance Charges Limit", visible: false },
        { dataField: "travelling_expenses_domestic", caption: "Travelling Expenses Domestic Limit", visible: false },
        { dataField: "travelling_expenses_foreign", caption: "Travelling Expenses Foreign Limit", visible: false },
        { dataField: "repairs_n_maintenance", caption: "Repairs And maintenance Limit", visible: false },
        { dataField: "other_expenses", caption: "Other Limit", visible: true },
        { dataField: "status", caption: "Status", visible: true },
    ];

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'A':
                return <div className={"badge badge-success round px-1"}>Active</div>;
            case 'I':
                return <div className={"badge badge-danger round px-1"}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: `${match.url}/edit`,
                                            state: {
                                                id: params.data.id,

                                            }
                                        })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: `${match.url}/view`,
                                            state: {
                                                id: params.data.id,

                                            }
                                        })
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        >
                        </Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    const onFinanceLimitListUpdate = () => {
        const { FinanceLimitList } = props
        var FinanceLimitListResponse = Object.assign({}, FinanceLimitList);
        if (FinanceLimitListResponse.result === true) {
            setFinanceManager(FinanceLimitListResponse.response)
        } else {
            switch (FinanceLimitListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceLimitListUpdate, [props.FinanceLimitList])

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Reimbursement Limit List{FinanceManager?.length === 0 ? '' : `(${FinanceManager?.length})`}</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push('/settings/reimbursement-limit/add');
                                }}
                            >
                                Create Reimbursement Limit
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={FinanceManager}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "Reimbursment Limit",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("business_promotion", "visible", true)
                                    event.component.columnOption("staff_welfare", "visible", true)
                                    event.component.columnOption("postage_expenses", "visible", true)
                                    event.component.columnOption("internet_expenses", "visible", true)
                                    event.component.columnOption("office_expenses", "visible", true)
                                    event.component.columnOption("printing_n_stationery", "visible", true)
                                    event.component.columnOption("training_n_data_processing_charges", "visible", true)
                                    event.component.columnOption("insurance_charges", "visible", true)
                                    event.component.columnOption("travelling_expenses_domestic", "visible", true)
                                    event.component.columnOption("travelling_expenses_foreign", "visible", true)
                                    event.component.columnOption("repairs_n_maintenance", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("business_promotion", "visible", false)
                                    event.component.columnOption("staff_welfare", "visible", false)
                                    event.component.columnOption("postage_expenses", "visible", false)
                                    event.component.columnOption("internet_expenses", "visible", false)
                                    event.component.columnOption("office_expenses", "visible", false)
                                    event.component.columnOption("printing_n_stationery", "visible", false)
                                    event.component.columnOption("training_n_data_processing_charges", "visible", false)
                                    event.component.columnOption("insurance_charges", "visible", false)
                                    event.component.columnOption("travelling_expenses_domestic", "visible", false)
                                    event.component.columnOption("travelling_expenses_foreign", "visible", false)
                                    event.component.columnOption("repairs_n_maintenance", "visible", false)
                                    event.component.endUpdate();
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var FinanceLimitList = null;
    if (
        state.ListFinanceLimitReducer != null &&
        Object.keys(state.ListFinanceLimitReducer).length > 0 &&
        state.ListFinanceLimitReducer.constructor === Object
    ) {
        switch (state.ListFinanceLimitReducer.tag) {
            case ACTION_FINANCE_LIMIT_LIST_SUCCESS:
                FinanceLimitList = Object.assign({}, state.ListFinanceLimitReducer.data);
                delete state.ListFinanceLimitReducer;
                return { FinanceLimitList };
            default:

        }
    }
    return {
        FinanceLimitList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionFinanceLimitList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(FinanceManager))
