import { EMPLOYEE_SHIFT_ADD_SUCCESS } from './action';

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddShiftTimingReducer = createReducer(INITIAL_STATE, {

    [EMPLOYEE_SHIFT_ADD_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: EMPLOYEE_SHIFT_ADD_SUCCESS,
            loading: false
        });
    }
});

export default AddShiftTimingReducer;
