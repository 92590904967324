import React from 'react';
import { Route, Switch } from 'react-router';
import DocumentList from './list';
import UploadDocument from './add';


const Documents = (props) => {
    const {match} = props
    return (
        <Switch>
            <Route path={`${match.url}/create`}  component={UploadDocument}/>
            <Route exact path={`${match.url}`} component={DocumentList}/>
        </Switch>
    )
}

export default Documents