import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { actionOpportunitySettingList, ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS } from "./action";
import { actionOpportunitySettingDelete, ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../utils";
import Swal from "sweetalert2";

const ProposalSettingsList = (props) => {
    const { match } = props;
    const [opportunitySetting, setOpportunitySettingList] = useState([]);
    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "department_name", caption: "Department Name" },
        { dataField: "full_name", caption: "Employee Name" },
        { dataField: "branch_city", caption: "Employee Branch" },
        { dataField: "city_concat", caption: "Location" },
        { dataField: "access_all", caption: "ALL" },
        { dataField: "access_assigned", caption: "Assigned" },
        { dataField: "access_unassigned", caption: "Unassigned" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionOpportunitySettingList();
    }
    useEffect(initialRender, []);

    const onOpportunitySettingListUpdate = () => {
        const { OpportunitySettingList } = props
        if (OpportunitySettingList != null) {
            var OpportunitySettingListResponse = Object.assign({}, OpportunitySettingList);
            if (OpportunitySettingListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setOpportunitySettingList(OpportunitySettingListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (OpportunitySettingListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onOpportunitySettingListUpdate, [props.OpportunitySettingList])

    const onOpportunitySettingDeleteUpdate = () => {
        const { OpportunitySettingDelete } = props
        if (OpportunitySettingDelete != null) {
            var OpportunitySettingDeleteResponse = Object.assign({}, OpportunitySettingDelete);
            if (OpportunitySettingDeleteResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                toast.success(OpportunitySettingDeleteResponse.message);
                props.actionOpportunitySettingList();
            } else {
                $("#progress-loader").addClass("hidden");
                switch (OpportunitySettingDeleteResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onOpportunitySettingDeleteUpdate, [props.OpportunitySettingDelete])

    const renderAllCell = (cell) => {
        return <div>{cell.data.access_all === 1 ? 'Yes' : 'No'}</div>
    }

    const renderAssignedCell = (cell) => {
        return <div>{cell.data.access_assigned === 1 ? 'Yes' : 'No'}</div>
    }

    const renderUnassignedCell = (cell) => {
        return <div>{cell.data.access_unassigned === 1 ? 'Yes' : 'No'}</div>
    }

    const renderEmployeeNameCell = (cell) => {
        if (cell?.data?.full_name != null) {
            return <a
            className='text-primary'
            onClick={() => {
                props.history.push({
                    pathname: '/profile',
                    state: {
                        user_id: cell?.data?.user_id,
                    }
                })
            }}
            ><u>{cell?.data?.full_name}</u></a>
        }
    }
    
    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {/* {
                            verifyPermission("MASTER_DEPARTMENT_EDIT") ? */}
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/edit`, state: { detail: params.data } })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                        {/* :
                                <></>
                        } */}
                        {/* {
                            verifyPermission("MASTER_DEPARTMENT_EDIT") ? */}
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        Swal.fire({
                                            icon: 'warning',
                                            text: 'Are you sure, you want to delete this setting ?',
                                            cancelButtonText: "Cancel",
                                            showCancelButton: true,
                                        }).then(({ isConfirmed }) => {
                                            if (isConfirmed) {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionOpportunitySettingDelete({ id: params.data.id })
                                            }
                                        })
                                    }}
                                >
                                    <FiTrash size={16} className="primary" />
                                </button>
                            )}
                        />
                        {/* :
                                <></>
                        } */}
                    </Column>
                );
            }
            else if (objColumn.dataField === "access_all") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderAllCell}
                    ></Column>
                );
            }else if (objColumn.dataField === "full_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEmployeeNameCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            }else if (objColumn.dataField === "access_assigned") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderAssignedCell}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "access_unassigned") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderUnassignedCell}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Opportunity Settings</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {/* {
                                verifyPermission("MASTER_DEPARTMENT_ADD") ? */}
                            <li class="nav-item">
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                    onClick={() => {
                                        props.history.push(`${match.url}/add`);
                                    }}
                                >
                                    Add Opportunity Setting
                                </button>
                            </li>
                            {/* :
                                    <></>
                            } */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={opportunitySetting}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Opportunity Settings",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    var OpportunitySettingList = null;
    var OpportunitySettingDelete = null;
    if (
        state.OpportunitySettingListReducer != null &&
        Object.keys(state.OpportunitySettingListReducer).length > 0 &&
        state.OpportunitySettingListReducer.constructor === Object
    ) {
        switch (state.OpportunitySettingListReducer.tag) {
            case ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS:
                OpportunitySettingList = Object.assign({}, state.OpportunitySettingListReducer.data);
                delete state.OpportunitySettingListReducer;
                return { OpportunitySettingList };
            case ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS:
                OpportunitySettingDelete = Object.assign({}, state.OpportunitySettingListReducer.data);
                delete state.OpportunitySettingListReducer;
                return { OpportunitySettingDelete };
            default:

        }
    }
    return {
        OpportunitySettingList,
        OpportunitySettingDelete
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionOpportunitySettingList, actionOpportunitySettingDelete }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProposalSettingsList))