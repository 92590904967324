/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FiLock } from 'react-icons/fi';
import ErrorLabel from "../../../../component/form/error_label";
import { actionChangePassword,ACTION_RESET_PASSWORD_SUCCESS } from "./action";
import { actionValidateToken,ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS } from "./action";
import { UNAUTHORIZED, PRIMARY_COLOR, ERROR } from '../../../../constants';

const ChangePassword = (props) => {

    const { match } = props;

    const { register, handleSubmit, formState: { errors }, getValues } = useForm({});

    const [token, setToken] = useState(null);
    const [email, setEmail] = useState(null)
    
    const initialRender = () => {

        if( match.params != null ) { 
            setToken(match.params.token);
            setEmail(match.params.email);

            props.actionValidateToken({
                email: match.params.email,
                token: match.params.token
            })
        }
        
    }
    useEffect(initialRender, [props.match]);

    const onUpdateValidateToken = () => { 
        const { ValidateToken } = props;
        var ValidateTokenResponse = Object.assign({}, ValidateToken);
        if (ValidateTokenResponse.result === true) {

        } else {
            switch (ValidateTokenResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: ValidateTokenResponse.message,
                        confirmButtonColor: PRIMARY_COLOR
                    }).then((result) => {
                        props.history.replace("/login");
                    });
                    break;
                default:
            }
        }
    }
    useEffect(onUpdateValidateToken,[props.ValidateToken]);

    const handleChangePasswordSuccess = () => { 
        const { ChangePassword } = props;
        var ChangePasswordResponse = Object.assign({}, ChangePassword);
        if (ChangePasswordResponse.result === true) {

            Swal.fire({
                title: 'Success',
                icon: 'success',
                text: ChangePasswordResponse.message,
                confirmButtonColor: PRIMARY_COLOR
            }).then((result) => {
                window.location.replace("/login");
            });

        } else {
            switch (ChangePasswordResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(handleChangePasswordSuccess,[props.ChangePassword]);

    const onSubmit = (data) => {

        if( token != null ) { 
            data.token = token;
        }

        if( token != null ) { 
            data.email = email;
        }

        // PRINT("PASSWORD DATA", data);

        props.actionChangePassword(data);
    }

    return (
        <>
            <div className="container login-container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1 order-lg-1 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-none d-md-flex d-lg-flex flex-row mb-2">
                            <div className="flex-column">
                                <img src={'/assets/images/app-logo.jpg'} style={{height: 30, objectFit: 'cover'}} alt="" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-2">
                            <div className="flex-column">
                                <h1 className="text-bold-500 text-black">
                                    <span className="d-block">Networking &amp; customer</span>
                                    <span className="d-block">management made easier,</span>
                                    <span className="d-block">just for you!</span>
                                </h1>
                            </div>
                        </div>
                        <div className="d-flex flex-row mt-2">
                            <div className="flex-column">
                                <p className="font-small-4 text-black lh-1">
                                    <span className="d-block text-justify">an interactive platform for PINC employees to help manage work on the go;</span>
                                    <span className="d-block text-justify">Log in meetings and leads with clients. Share news. Congratulate co-worker on</span>
                                    <span className="d-block text-justify">achievements and interact with co-workers by using the dashboard.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 order-1 order-md-2 order-lg-2 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-flex d-md-none d-lg-none flex-row mb-2 justify-content-center justify-content-lg-start justify-content-md-start">
                                <img src={'/assets/images/app-logo.jpg'} style={{height: 30, objectFit: 'cover'}} alt="" />
                        </div>
                        <div className="card shadow rounded p-4 p-md-5 mb-0">
                            <div className="card-title text-center text-black" style={{fontWeight: 600}}>Change Password</div>
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <label>Password<span className="text-danger">*</span></label>
                                    <input 
                                        type="password" 
                                        id="password" 
                                        name="password"
                                        className="form-control" 
                                        {...register('password', { 
                                            required: 'Password is required',
                                            minLength: { 
                                                value: 8,
                                                message: 'Please enter minimum 8 character'
                                            },
                                            validate: (value) => { 
                                                if( value.search(/[0-9]/) == -1 ) { 
                                                    return "Atlease 1 numeric value is required"
                                                }
                                                if( value.search(/[a-z]/) == -1 ) { 
                                                    return "Atlease 1 small letter is required"
                                                }
                                                if( value.search(/[A-Z]/) == -1 ) { 
                                                    return "Atlease 1 Capital letter is required"
                                                }
                                                if( value.search(/[#$^+=!*()@%&]/) == -1 ) { 
                                                    return "Atlease 1 Special Symbol is required"
                                                }
                                            }
                                        })}
                                    />
                                    {errors.password && <ErrorLabel message={errors.password?.message} />}
                                    <div className="form-control-position">
                                        <FiLock size={24} className="primary"/>
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <label>Confirm Password<span className="text-danger">*</span></label>
                                    <input 
                                        type="password" 
                                        id="password_confirmation" 
                                        name="password_confirmation"
                                        className="form-control" 
                                        {...register('password_confirmation', { 
                                            required: 'Confirm Password is required',
                                            minLength: { 
                                                value: 8,
                                                message: 'Please enter minimum 8 character'
                                            },
                                            validate: (value) => { 
                                                if( value.search(/[0-9]/) == -1 ) { 
                                                    return "Atlease 1 numeric value is required"
                                                }
                                                if( value.search(/[a-z]/) == -1 ) { 
                                                    return "Atlease 1 small letter is required"
                                                }
                                                if( value.search(/[A-Z]/) == -1 ) { 
                                                    return "Atlease 1 Capital letter is required"
                                                }
                                                if( value.search(/[#$^+=!*()@%&]/) == -1 ) { 
                                                    return "Atlease 1 Special Symbol is required"
                                                }
                                            },
                                            validate: (value) => { 
                                                return getValues("password") === value || "Password do not match"
                                            }
                                        })}
                                    />
                                    {errors.password_confirmation && <ErrorLabel message={errors.password_confirmation?.message} />}
                                    <div className="form-control-position">
                                        <FiLock size={24} className="primary"/>
                                    </div>
                                </fieldset>
                                <div className="d-flex flex-row justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var ChangePassword = null;
    var ValidateToken = null;

    if (
        state.ChangePasswordReducer != null &&
        Object.keys(state.ChangePasswordReducer).length > 0 &&
        state.ChangePasswordReducer.constructor === Object
    ) {
        switch (state.ChangePasswordReducer.tag) {
            case ACTION_RESET_PASSWORD_SUCCESS:
                ChangePassword = Object.assign({}, state.ChangePasswordReducer.data);
                delete state.ChangePasswordReducer;
                return { ChangePassword };
            case ACTION_VALIDATE_PASSWORD_TOKEN_SUCCESS:
                ValidateToken = Object.assign({}, state.ChangePasswordReducer.data);
                delete state.ChangePasswordReducer;
                return { ValidateToken };
            default:
        }
    }
    return {
        ChangePassword
    }

}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionChangePassword, actionValidateToken }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
