import { API_MASTER_PROBABILITY, API_ADD_PROBABILITY } from '../../../../../../api/constants';
import Api from '../../../../../../api';
// import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';


export const ACTION_PROBABILITY_LOADING = 'ACTION_PROBABILITY_LOADING';
export const ACTION_PROBABILITY_SUCCESS = 'ACTION_PROBABILITY_SUCCESS';
export const ACTION_PROBABILITY_ADD_SUCCESS = 'ACTION_PROBABILITY_ADD_SUCCESS';

export function actionProbabilityList(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_PROBABILITY).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROBABILITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROBABILITY_LOADING, { loading: false, data }));
        });
}

export function actionProbabilityAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_ADD_PROBABILITY, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROBABILITY_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROBABILITY_LOADING, { loading: false, data }));
        });
}