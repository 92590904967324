import createReducer from "../../../../../reducers/createReducer";
import { ACTION_PROPOSALPOOL_VIEW_SUCCESS, ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ProposalPoolViewReducer = createReducer(INITIAL_STATE, {
    [ACTION_PROPOSALPOOL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROPOSALPOOL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS,
                loading: false,
            }
        );
    }
}
)
export default ProposalPoolViewReducer