import Api from "../../../../../../api";
import { API_OPPORTUNITY_QUESTION_LIST } from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";

export const ACTION_OPPORTUNITY_QUESTION_LIST_LOADING = 'ACTION_OPPORTUNITY_QUESTION_LIST_LOADING';
export const ACTION_OPPORTUNITY_QUESTION_LIST_SUCCESS = 'ACTION_OPPORTUNITY_QUESTION_LIST_SUCCESS';

export function actionOpportunityQuestionList() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_QUESTION_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE OPPORTUNITY QUESTION LIST', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_QUESTION_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_QUESTION_LIST_LOADING, { loading: false, data }));
        });
}