import { API_OPPORTUNITY_SETTINGS_ADD, API_MASTER_GET_DEPARTMENT, API_GET_SEARCH_LIST, API_MASTER_BRANCH_CITY } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_OPPORTUNITY_SETTINGS_ADD_LOADING = 'ACTION_OPPORTUNITY_SETTINGS_ADD_LOADING';
export const ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS = 'ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS';

export const ACTION_GET_DEPARTMENT_LOADING = 'ACTION_GET_DEPARTMENT_LOADING';
export const ACTION_GET_DEPARTMENT_SUCCESS = 'ACTION_GET_DEPARTMENT_SUCCESS';

export const ACTION_GET_EMPLOYEE_LIST_LOADING = 'ACTION_GET_EMPLOYEE_LIST_LOADING';
export const ACTION_GET_EMPLOYEE_LIST_SUCCESS = 'ACTION_GET_EMPLOYEE_LIST_SUCCESS';

export const ACTION_GET_LOCATION_LIST_LOADING = 'ACTION_GET_LOCATION_LIST_LOADING';
export const ACTION_GET_LOCATION_LIST_SUCCESS = 'ACTION_GET_LOCATION_LIST_SUCCESS';

export function actionOpportunitySettingAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_OPPORTUNITY_SETTINGS_ADD, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_SETTINGS_ADD_LOADING, { loading: false, data }));
        });
}

export function actionGetDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_DEPARTMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeList() {
    return (dispatch, getState) =>
        Api.get(API_GET_SEARCH_LIST).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_EMPLOYEE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_EMPLOYEE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionLocationList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_BRANCH_CITY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_LOCATION_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_LOCATION_LIST_LOADING, { loading: false, data }));
        });
}