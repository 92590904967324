
import React from 'react';
import { Route, Switch } from 'react-router';
import PolicyDashboard from './create';
import PolicyView from './view';

const PolicyRoute = (props) => {
    const { match } = props
    return (
        <Switch>

            <Route path={`${match.url}/view`} component={PolicyView} />
            <Route exact path={`${match.url}`} component={PolicyDashboard} />
        </Switch>
    )
}

export default PolicyRoute