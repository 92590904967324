import { ACTION_RISK_APPROVAL_LIST_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";


const INITIAL_STATE = {
    data: [],
    loading: false,
};

const RiskApprovalListReducer = createReducer(INITIAL_STATE, {
    [ACTION_RISK_APPROVAL_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_APPROVAL_LIST_SUCCESS,
                loading: false,
            }
        );
    },
})
export default RiskApprovalListReducer