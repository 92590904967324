import React from 'react';
import { Route, Switch } from 'react-router';
import LossSiteVisitAdd from './add';
import LossSiteVisit from './list';


const LoseSiteVisitRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={LossSiteVisitAdd}/>
                <Route exact path={`${match.url}`}  component={LossSiteVisit}/>
            </Switch>
        </>
    )
}

export default LoseSiteVisitRoute