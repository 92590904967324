import { API_OPPORTUNITY_SETTINGS_LIST, API_OPPORTUNITY_SETTINGS_DELETE } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_OPPORTUNITY_SETTINGS_LIST_LOADING = 'ACTION_OPPORTUNITY_SETTINGS_LIST_LOADING';
export const ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS = 'ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS';

export const ACTION_OPPORTUNITY_SETTINGS_DELETE_LOADING = 'ACTION_OPPORTUNITY_SETTINGS_DELETE_LOADING';
export const ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS = 'ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS';


export function actionOpportunitySettingList(params) {
    return (dispatch, getState) =>
        Api.post(API_OPPORTUNITY_SETTINGS_LIST, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_SETTINGS_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_SETTINGS_LIST_LOADING, { loading: false, data }));
        });
}

export function actionOpportunitySettingDelete(params) {
    return (dispatch, getState) =>
        Api.post(API_OPPORTUNITY_SETTINGS_DELETE, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_SETTINGS_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_SETTINGS_DELETE_LOADING, { loading: false, data }));
        });
}