import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UNAUTHORIZED } from '../../../../../constants';
import dateFormat from "dateformat";
import { actionHolidayView, ACTION_HOLIDAY_VIEW_SUCCESS } from './action';
import { downloadFile } from '../../../../../utils';
import DocumentIframe from '../../../../../component/popups/document_iframe';

const HolidayView = (props) => {

    const [holidayDetails, setHolidayDetails] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        props.actionHolidayView({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onHolidayViewUpdate = () => {
        const { HolidayView } = props;
        var HolidayViewResponse = Object.assign({}, HolidayView);
        if (HolidayViewResponse.result === true) {
            setHolidayDetails(HolidayViewResponse.response);
        } else {
            switch (HolidayViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onHolidayViewUpdate, [props.HolidayView]);

    return (
        <>
            <h3 className="text-dark text-bold-700">{`Holiday Details Of Happy ${holidayDetails != null ? holidayDetails.title : ''}`}</h3>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row mt-2">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Title</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        holidayDetails != null ?
                                            holidayDetails.title : ''
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Date</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        holidayDetails != null ?
                                            dateFormat(holidayDetails.date, 'dd/mm/yyyy') : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        holidayDetails != null ?
                                            _.capitalize(holidayDetails.type) : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Pre Render</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        holidayDetails != null ?
                                            holidayDetails.pre_render : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Short Description</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        holidayDetails != null ?
                                            holidayDetails.short_description : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12  mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Description</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        holidayDetails != null ?
                                            holidayDetails.description : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Image</h6>
                                {
                                    (holidayDetails != null && holidayDetails.post_image != null) ?
                                        // <a target="_blank" href={holidayDetails.post_image}>View Image</a> : <>-</>
                                        <>
                                            <a className="primary"
                                                onClick={() => {
                                                    if (holidayDetails.post_image?.split('.').pop() == 'pdf' ||
                                                        holidayDetails.post_image?.split('.').pop() == 'PDF' ||
                                                        holidayDetails.post_image?.split('.').pop() == 'png' ||
                                                        holidayDetails.post_image?.split('.').pop() == 'jpg' ||
                                                        holidayDetails.post_image?.split('.').pop() == 'jpeg' ||
                                                        holidayDetails.post_image?.split('.').pop() == 'gif'
                                                    ) {
                                                        setSelectedDocumentIndex(holidayDetails.post_image)
                                                        setShowDocumentDetails(true);
                                                        setExtension(holidayDetails.post_image?.split('.').pop())
                                                        setDocumentDetails(holidayDetails)
                                                    } else {
                                                        downloadFile(holidayDetails.post_image);
                                                    }
                                                }}
                                            >
                                                View Image
                                            </a>
                                            <a id="downloadLink" className="d-none"></a>
                                        </>
                                        :
                                        <>-</>
                                }
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var HolidayView = null;

    if (
        state.ViewHolidayReducer != null &&
        Object.keys(state.ViewHolidayReducer).length > 0 &&
        state.ViewHolidayReducer.constructor === Object
    ) {
        switch (state.ViewHolidayReducer.tag) {
            case ACTION_HOLIDAY_VIEW_SUCCESS:
                HolidayView = Object.assign({}, state.ViewHolidayReducer.data);
                delete state.ViewHolidayReducer
                return { HolidayView };
            default:
        }
    }

    return {
        HolidayView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionHolidayView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayView)