import React, { useState, useEffect } from "react";
import ScreenTitle from "../../../../../../component/screen_title";
import { ACTION_RISK_MANAGEMENT_VIEW_SUCCESS, actionViewRiskManagmentRequest } from "../../action";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import _ from 'lodash';
import moment from "moment";
import RequestHistory from '../../../../../../component/widgets/request_history';
import DocumentIframe from "../../../../../../component/popups/document_iframe";
import { downloadFile } from "../../../../../../utils";

const RiskPoolView = (props) => {
    const [riskPool, setRiskPool] = useState(null);
    const [requestHistory, setRequestHistory] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        props.actionViewRiskManagmentRequest({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onRequestViewUpdate = () => {
        const { RequestView } = props;
        var RequestViewResponse = Object.assign({}, RequestView);
        if (RequestViewResponse.result === true) {
            setRiskPool(RequestViewResponse.response);
            if (RequestViewResponse.response.request_risk_status.length > 0) {
                setRequestHistory(RequestViewResponse.response.request_risk_status);
            }
        } else {
            switch (RequestViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestViewUpdate, [props.RequestView]);

    return (
        <>
            <ScreenTitle title={`View ${props.location.state.request_type} Risk Request`} />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Client Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.client_type != null ?
                                            _.capitalize(riskPool?.client_type) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Client Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.client_name ?
                                            riskPool?.client_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Location Address</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.address != null ?
                                            riskPool?.address : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Occupancy</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.occupancy != null ?
                                            riskPool?.occupancy : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Sum Insured</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.sum_insured != null ?
                                            riskPool?.sum_insured : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Type of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.inspection_type != null ?
                                            riskPool?.inspection_type : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            riskPool != null && riskPool.inspection_type != null && riskPool.inspection_type === "Others" ?
                                <div className="row pt-2">
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                                    </div>
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="font-medium-1 text-bold-700 mb-0">
                                            {
                                                riskPool != null && riskPool.description != null ?
                                                    riskPool.description : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">RM Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.rm_first_name != null && riskPool?.rm_first_name != 'null' ?
                                            `${riskPool?.rm_first_name} ${riskPool?.rm_last_name || ""}` : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">RM Location</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.rm_city_name != null && riskPool?.rm_city_name != 'null' ?
                                            riskPool?.rm_city_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Start Date of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.inspection_date != null ?
                                            moment(riskPool?.inspection_date).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">End Date of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.inspection_end_date != null ?
                                            moment(riskPool?.inspection_end_date).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.remarks != null && riskPool?.remarks != 'null' ?
                                            riskPool?.remarks : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3">
                                <h4 className="text-uppercase font-small-3 mb-0">Document</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3">
                                {
                                    (riskPool != null && riskPool.risk_management_document != null) ?
                                        // <div>
                                        //     <a target="_blank" href={riskPool.risk_management_document} download="Meeting Document">View Document</a>
                                        // </div>

                                        <>
                                            <a className="primary"
                                                onClick={() => {

                                                    if (riskPool?.risk_management_document?.split('.').pop() == 'pdf' ||
                                                        riskPool?.risk_management_document?.split('.').pop() == 'PDF' ||
                                                        riskPool?.risk_management_document?.split('.').pop() == 'png' ||
                                                        riskPool?.risk_management_document?.split('.').pop() == 'jpg' ||
                                                        riskPool?.risk_management_document?.split('.').pop() == 'jpeg' ||
                                                        riskPool?.risk_management_document?.split('.').pop() == 'gif'
                                                    ) {
                                                        setSelectedDocumentIndex(riskPool?.risk_management_document)
                                                        setShowDocumentDetails(true);
                                                        setExtension(riskPool?.risk_management_document?.split('.').pop())
                                                        setDocumentDetails(riskPool)
                                                    } else {
                                                        downloadFile(riskPool?.risk_management_document);
                                                    }
                                                }}
                                            >
                                                View Document
                                            </a>
                                            <a id="downloadLink" className="d-none"></a>
                                        </>
                                        :
                                        <h4 className="font-medium-1 text-bold-700 mb-0">-</h4>
                                }
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Created Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.created_at != null ?
                                            moment(riskPool?.created_at).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            props?.location?.state?.status_name != 'pending' ?
                                <div className="row pt-2">
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="text-uppercase font-small-3 mb-0">Approved By</h4>
                                    </div>
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="font-medium-1 text-bold-700 mb-0">
                                            {
                                                riskPool != null && riskPool?.approved_by != null ?
                                                    riskPool?.approved_by : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                        {
                            props?.location?.state?.request_type != 'Unassigned' ?
                                <div className="row pt-2">
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="text-uppercase font-small-3 mb-0">Assign Engineer</h4>
                                    </div>
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="font-medium-1 text-bold-700 mb-0">
                                            {
                                                riskPool != null && riskPool?.assign_engineer != null ?
                                                    riskPool?.assign_engineer : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Actual Date of Inspection</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.actual_date_inspection != null ?
                                            moment(riskPool?.actual_date_inspection).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Financial Year</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        riskPool != null && riskPool?.financial_year != null && riskPool?.financial_year != 'null' ?
                                            riskPool?.financial_year : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 float-none">
                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1" onClick={(event) => {
                                event.preventDefault();
                                if (props?.location?.state?.from === 'client-group') {
                                    props.history.push({
                                        pathname: "/client/group-level-view",
                                        state: {
                                            client_group_id: riskPool?.client_group_id,
                                            tab: props?.location?.state?.selectedTab,
                                            client_detail: "risk"
                                        }
                                    })
                                } else {
                                    props.history.goBack();
                                }
                            }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )

}

const mapStateToProps = (state) => {
    var RequestView = null;

    if (
        state.viewRiskManagementReducer != null &&
        Object.keys(state.viewRiskManagementReducer).length > 0 &&
        state.viewRiskManagementReducer.constructor === Object
    ) {
        switch (state.viewRiskManagementReducer.tag) {

            case ACTION_RISK_MANAGEMENT_VIEW_SUCCESS:
                RequestView = Object.assign({}, state.viewRiskManagementReducer.data);
                delete state.viewRiskManagementReducer;
                return { RequestView };
            default:
        }
    }
    return {
        RequestView,

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewRiskManagmentRequest
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskPoolView))