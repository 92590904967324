import React, { useEffect, useState } from 'react';
import $ from "jquery";

const DocumentIframe = (props) => {
    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else if (props?.popup != true) {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    return (
        <>
            <div
                className="modal fade show "
                id="default"
                role="dialog"
                aria-labelledby="document-iframe"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title">
                                    {props?.detailsOfDocument?.title != null ? props?.detailsOfDocument?.title : ''}
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {
                            props != null ?
                                props?.extension == 'pdf' || props?.extension == 'PDF' ?
                                    <div className="modal-body no-scroll" style={{ overflow: "hidden" }}>
                                        <div style={{ maxHeight: '1000px', height: '500px' }}>
                                            <iframe seamless="seamless" src={props?.documentDetails} style={{ height: '100%', width: '100%', overflow: 'hidden' }}></iframe>
                                        </div>
                                    </div>
                                    :
                                    <div div className="modal-body">
                                        <div className='text-center'>
                                            <a href={props?.documentDetails} target="_blank">
                                                <img src={props?.documentDetails} className='img-fluid' />
                                            </a>
                                        </div>
                                    </div>
                                :
                                <div className="text-center align-items-center mt-2">
                                    <div id="loading-details" className="spinner-border text-light" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                        }
                    </div>
                </div>
            </div >
        </>
    );
};


export default DocumentIframe

