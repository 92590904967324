import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiUserPlus } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { actionPermissionRoles, ACTION_PERMISSION_ROLES_SUCCESS } from './action';
import { verifyPermission } from '../../../../../../utils';

const Roles = (props) => {

    const { match } = props;

    const [permissionRoles, setPermissionRoles] = useState({});

    const initialRender = () => {
        props.actionPermissionRoles({});
    }
    useEffect(initialRender, []);

    const onPermissionRolesUpdate = () => {
        const { PermissionRoles } = props
        var PermissionRolesResponse = Object.assign({}, PermissionRoles);
        if (PermissionRolesResponse.result === true) {
            setColumns([
                { dataField: "Action", caption: "Action", type: "button" },
                { dataField: "title", caption: "Role Name" },
                { dataField: "status", caption: "Status" },
            ]);

            setPermissionRoles(PermissionRolesResponse.response);

        } else {
            switch (PermissionRolesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onPermissionRolesUpdate, [props.PermissionRoles])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "title", caption: "Role Name" },
        { dataField: "status", caption: "Status" },
    ]);

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'active':
                return <div className={"badge badge-success round px-1"}>Active</div>;
            case 'inactive':
                return <div className={"badge badge-danger round px-1"}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("ASSIGN_PERMISSION") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm px-1"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { roles: params.data } })
                                            }}
                                        >
                                            <FiUserPlus size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }
                        {
                            verifyPermission("UPDATE_ROLES") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { role_id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>

                        }

                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }

            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Roles</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {
                            verifyPermission("CREATE_ROLES") ?
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push('/settings/roles/add');
                                        }}
                                    >
                                        Create Role
                                    </button>
                                </li>
                                : <> </>

                        }

                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={permissionRoles}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "roles",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var PermissionRoles = null;
    if (
        state.PermissionRolesReducer != null &&
        Object.keys(state.PermissionRolesReducer).length > 0 &&
        state.PermissionRolesReducer.constructor === Object
    ) {
        switch (state.PermissionRolesReducer.tag) {
            case ACTION_PERMISSION_ROLES_SUCCESS:
                PermissionRoles = Object.assign({}, state.PermissionRolesReducer.data);
                delete state.PermissionRolesReducer;
                return { PermissionRoles };
            default:

        }
    }
    return {
        PermissionRoles
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionPermissionRoles }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Roles))