import React from 'react';
import { Route, Switch } from 'react-router';
import ProposalDashboard from './create';
import ProposalDashboardView from './view';

const proposalRoute = (props) => {
    const {match} = props
    return (
        <Switch>
    
            <Route path={`${match.url}/view`}  component={ProposalDashboardView}/>
            <Route exact path={`${match.url}`} component={ProposalDashboard}/>
        </Switch>
    )
}

export default proposalRoute