import React from 'react';
import { Route, Switch } from "react-router";
import DeclarationList from './list';
import DeclarationAdd from './add';
import DeclarationEdit from './edit';

const DeclarationRoute = (props) => {
    const { match } = props

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`} component={DeclarationAdd} />
                <Route path={`${match.url}/edit`} component={DeclarationEdit} />
                <Route exact path={`${match.url}`} component={DeclarationList} />
            </Switch>
        </>
    )
}
export default DeclarationRoute