import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { DateBox } from 'devextreme-react/date-box';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionGroupCompanies, ACTION_GROUP_COMPANIES_SUCCESS } from './action';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { UNAUTHORIZED, PRIMARY_COLOR } from '../../../../../../../constants';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import Swal from 'sweetalert2';
import { withAuth } from 'react-auth-guard';
import { convertAmount, verifyPermission } from '../../../../../../../utils';

const GroupLevelCompaniesView = (props) => {

    const { control } = useForm({});

    const [companyData, setCompanyData] = useState([]);
    const [companyColumn, setCompanyColumn] = useState([
        { dataField: "company_name", caption: "Company Name " },
        { dataField: "policy_count", caption: "Policies" },
        { dataField: "total_net_premium", caption: "Total Premium(Net)" },
        { dataField: "total_brokerage_amount", caption: "Total Brokerage" },
        { dataField: "claim_amount", caption: "Total Claims(Total amount)" },
        { dataField: "client_type", caption: "Type" },
        { dataField: "is_active", caption: "Status" },
    ]);
    const [fromDate, setFromDate] = useState(moment().month() < 3
        ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("yyyy-MM-DD")
        : moment()
            .month(3)
            .startOf("month")
            .format("yyyy-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGroupCompanies({
            client_group_id: props.clientGroupId,
        })
    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupCompaniesUpdate = () => {

        const { GroupCompanies } = props;

        if (GroupCompanies != null) {
            var GroupCompaniesResponse = Object.assign({}, GroupCompanies);
            if (GroupCompaniesResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setCompanyColumn([
                    { dataField: "company_name", caption: "Company Name " },
                    { dataField: "policy_count", caption: "Policies" },
                    { dataField: "total_net_premium", caption: "Total Premium(Net)" },
                    { dataField: "total_brokerage_amount", caption: "Total Brokerage" },
                    { dataField: "claim_amount", caption: "Total Claims(Total amount)" },
                    { dataField: "client_type", caption: "Type" },
                    { dataField: "is_active", caption: "Status" },
                ])
                setCompanyData(GroupCompaniesResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                setCompanyData([])
                switch (GroupCompaniesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGroupCompaniesUpdate, [props.GroupCompanies]);

    /** Setup The Status Column */
    const renderStatusCell = (cell) => {
        if (cell.data.client_type === 'Client') {
            switch (cell.data.is_active) {
                case 'yes':
                    return <div className={"badge badge-success round px-1"}>Active</div>;
                case 'no':
                    return <div className={"badge badge-danger round px-1"}>Inactive</div>;
                default:
                    return <></>
            }
        }

    }
    /** EOC : Setup The Status Column */

    /** Setup The Total Premium, Total brokerage, Total claims Column */
    const renderTotalPremium = (cell) => {
        if (cell.data.total_net_premium) {
            return <div>{cell.data.total_net_premium != null ? '₹ ' + cell.data.total_net_premium + ' ' + `(${convertAmount(cell.data.total_net_premium)})` : ''}</div>
        }
    }

    const renderTotalBrokerage = (cell) => {
        if (cell.data.total_brokerage_amount) {
            return <div>{cell.data.total_brokerage_amount != null ? '₹ ' + cell.data.total_brokerage_amount + ' ' + `(${convertAmount(cell.data.total_brokerage_amount)})` : ''}</div>
        }
    }

    const renderTotalClaim = (cell) => {
        if (cell.data.claim_amount) {
            return <div>{cell.data.claim_amount != null ? '₹ ' + cell.data.claim_amount + ' ' + `(${convertAmount(cell.data.claim_amount)})` : ''}</div>
        }
    }
    /** EOC: Setup The Total Premium, Total brokerage, Total claims Column */

    const renderCompanyCell = (cell) => {
        if (cell?.data?.company_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    if (cell?.data?.client_type === 'Lead') {
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    } else {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    }
                }}
            ><u>{cell?.data?.company_name}</u></a>
        }
    }

    const renderCompanyColumns = () => {
        var arrColumns = [];
        companyColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "is_active") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStatusCell}
                    ></Column>
                );
            } else if (objColumn.dataField === "company_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompanyCell}
                    ></Column>
                );
            } else if (objColumn.dataField === "total_net_premium") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderTotalPremium}
                        width={270}
                    ></Column>
                );
            } else if (objColumn.dataField === "total_brokerage_amount") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderTotalBrokerage}
                        width={270}
                    ></Column>
                );
            } else if (objColumn.dataField === "claim_amount") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderTotalClaim}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "policy_count") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        width={80}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "client_type") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        width={90}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderCompanyTableView = () => {
        return (
            <DataGrid
                dataSource={companyData}
                showBorders={true}
                // allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                columnHidingEnabled={true}
                export={{
                    fileName: "Companies",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderCompanyColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row py-1">
                            {
                                companyData != null && companyData.length > 0 ?
                                    <>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group mb-0">
                                                <Controller
                                                    name="from_date"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DateBox
                                                            calendarOptions={{
                                                                showTodayButton: true,
                                                            }}
                                                            acceptCustomValue={false}
                                                            stylingMode="underlined"
                                                            pickerType="calendar"
                                                            placeholder="From Date"
                                                            type="date"
                                                            displayFormat="dd/MM/yyyy"
                                                            showClearButton={false}
                                                            value={fromDate}
                                                            onValueChange={(value) => {
                                                                field.onChange(moment(value).format('yyyy-MM-DD'));
                                                                setFromDate(moment(value).format('yyyy-MM-DD'));
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group mb-0">
                                                <Controller
                                                    name="to_date"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DateBox
                                                            calendarOptions={{
                                                                showTodayButton: true,
                                                            }}
                                                            acceptCustomValue={false}
                                                            stylingMode="underlined"
                                                            pickerType="calendar"
                                                            placeholder="To Date"
                                                            type="date"
                                                            displayFormat="dd/MM/yyyy"
                                                            showClearButton={false}
                                                            value={toDate}
                                                            onValueChange={(value) => {
                                                                field.onChange(moment(value).format('yyyy-MM-DD'));
                                                                setToDate(moment(value).format('yyyy-MM-DD'));
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-2 col-lg-2 my-auto">
                                            <button
                                                className="btn btn-primary py-half mr-1 shadow round text-uppercase mt-1 mt-md-0"
                                                type="submit"
                                                onClick={() => {
                                                    if (fromDate.length > 0 && toDate.length > 0) {
                                                        $("#progress-loader").removeClass("hidden");
                                                        props.actionGroupCompanies({
                                                            client_group_id: props.clientGroupId,
                                                            from_date: moment(fromDate).format('yyyy-MM-DD'),
                                                            to_date: moment(toDate).format('yyyy-MM-DD')
                                                        })
                                                    } else {
                                                        Swal.fire({
                                                            title: 'Error',
                                                            icon: 'error',
                                                            html: 'Please Select From Date & To Date',
                                                            confirmButtonColor: PRIMARY_COLOR
                                                        }).then((result) => {

                                                        });
                                                    }
                                                }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 justify-content-end">
                                            <button
                                                className="btn shadow btn-primary py-half mb-1 round text-uppercase float-right"
                                                type="submit"
                                                onClick={() => {
                                                    $("#progress-loader").removeClass("hidden");
                                                    props.actionGroupCompanies({
                                                        client_group_id: props.clientGroupId,
                                                        from_date: moment().month(3).startOf('month').subtract(1, 'year').format('yyyy-MM-DD'),
                                                        to_date: moment().month(2).endOf('month').format('yyyy-MM-DD'),
                                                    });
                                                    setFromDate(moment().month(3).startOf('month').subtract(1, 'year').format('yyyy-MM-DD'))
                                                    setToDate(moment().month(2).endOf('month').format('yyyy-MM-DD'))
                                                }}
                                            >
                                                Show Previous FY(22-23)
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    companyData != null && companyData.length > 0 ?
                                        renderCompanyTableView() :
                                        <h6 className="text-bold-500 text-center">No Company Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

function mapStateToProps(state) {

    var GroupCompanies = null;

    if (
        state.TabGroupCompaniesReducer != null &&
        Object.keys(state.TabGroupCompaniesReducer).length > 0 &&
        state.TabGroupCompaniesReducer.constructor === Object
    ) {
        switch (state.TabGroupCompaniesReducer.tag) {

            case ACTION_GROUP_COMPANIES_SUCCESS:
                GroupCompanies = Object.assign({}, state.TabGroupCompaniesReducer.data);
                delete state.TabGroupCompaniesReducer;
                return { GroupCompanies };
            default:
                return { GroupCompanies }
        }
    }
    return { GroupCompanies }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupCompanies }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupLevelCompaniesView));

