import React from 'react';

const ReportDetail = (props) => {
    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-12 col-lg-12">
                    <h3 className="content-header-title">Old Direct Insurance Broking Business RI Broking Business Report - Business Report</h3>
                </div>
            </div>
            <section>
                    <form>
                        <div className="form-body">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="label-control primary">Department Chart</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="label-control primary">Month wise Chart</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="label-control primary">Monthly Report Grid</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="label-control primary">Customer Grid</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="label-control primary">Department Grid</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
            </section>
        </>
    )
}

export default ReportDetail