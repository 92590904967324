import React from 'react';
import { Route, Switch } from 'react-router';
import RegularizeApprovalsList from './regularize_approval/list';
import RegularizeApprovalsView from './regularize_approval/view';
import LeaveApprovalsList from './leave_approvals/list';
import LeaveApprovalsView from './leave_approvals/view';
import AssetApprovalList from './asset_approvals/list';
import TravelApprovalsView from './travel_approvals/view';
import AssetApprovalsView from './asset_approvals/view';
import TravelApprovalList from './travel_approvals/list';
import ReimbursementApprovalList from './reimbursement_approvals/list';
import ReimbursementApprovalView from './reimbursement_approvals/view';
import ReimbursementPool from './reimbursement_pool/list';
import ReimbursementPoolView from './reimbursement_pool/view';

const ApprovalsRoute = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route path={`${match.url}/regularize-approvals/view`} component={RegularizeApprovalsView} />
            <Route path={`${match.url}/regularize-approvals`} component={RegularizeApprovalsList} />
            <Route path={`${match.url}/leave-approvals/view`} component={LeaveApprovalsView} />
            <Route path={`${match.url}/leave-approvals`} component={LeaveApprovalsList} />
            <Route path={`${match.url}/asset-approvals/view`} component={AssetApprovalsView} />
            <Route path={`${match.url}/asset-approvals`} component={AssetApprovalList} />
            <Route path={`${match.url}/travel-approvals/view`} component={TravelApprovalsView} />
            <Route path={`${match.url}/travel-approvals`} component={TravelApprovalList} />
            <Route path={`${match.url}/reimbursement_approvals/view`} component={ReimbursementApprovalView} />
            <Route path={`${match.url}/reimbursement_approvals`} component={ReimbursementApprovalList} />
            <Route path={`${match.url}/reimbursement_pool/view`} component={ReimbursementPoolView} />
            <Route path={`${match.url}/reimbursement_pool`} component={ReimbursementPool} />
            
        </Switch>
    )
}

export default ApprovalsRoute