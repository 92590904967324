import { ACTION_PINC_SUCCESS } from "./action";

import createReducer from "../../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const PincReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_PINC_SUCCESS](state, action) {
            return Object.assign(
                {},
                {
                    ...state,
                    data: action.payload.data,
                    tag: ACTION_PINC_SUCCESS,
                    loading: false,
                }
            );
        },
    });
export default PincReducer;