import { React, useEffect, useState } from 'react';
import { FiUser } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import $ from "jquery";
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../constants';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { actionDocumentAdd, ACTION_DOCUMENT_ADD_SUCCESS } from './action';
import { actionDocumentCategory, ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS } from './action';
import { actionDocumentType, ACTION_MASTER_DOCUMENT_TYPE_SUCCESS } from './action';
import { actionDocumentApprovedBy, ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS } from './action';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const UploadDocument = (props) => {

    const { register, handleSubmit, control, formState: { errors }, watch } = useForm();

    const [uploadDocument, setUploadDocument] = useState(null);
    const [documentCategory, setDocumentCategory] = useState();
    const [documentTypes, setDocumentTypes] = useState();
    const [documentApprovedBy, setDocumentApprovedBy] = useState();

    var categoryWatcher = watch('category', '');

    const initialRender = () => {
        props.actionDocumentType();
        props.actionDocumentCategory();
        props.actionDocumentApprovedBy();
    }
    useEffect(initialRender, []);

    const onUploadDocumentUpdate = () => {
        const { UploadDocument } = props;
        var UploadDocumentResponse = Object.assign({}, UploadDocument);
        $("#upload_document").prop("disabled", false);
        if (UploadDocumentResponse.result === true) {
            $("#upload_document-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Document Saved Successfully");
            props.history.goBack();
        } else {
            $("#upload_document-loader").addClass("hidden");
            switch (UploadDocumentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(UploadDocumentResponse.message);
                default:
            }
        }
    }
    useEffect(onUploadDocumentUpdate, [props.UploadDocument]);

    const onDocumentCategoryUpdate = () => {
        const { DocumentCategory } = props;
        if (DocumentCategory != null) {
            var DocumentCategoryResponse = Object.assign({}, DocumentCategory);
            if (DocumentCategoryResponse.result === true) {

                var arrDocuments = Object.assign([], DocumentCategoryResponse.response);

                var document = {
                    title: '+ Add New Category',
                    value: "New Category"
                };

                arrDocuments.unshift(document);

                setDocumentCategory(arrDocuments);
            } else {

                var arrDocuments = Object.assign([], []);

                var document = {
                    title: '+ Add New Category',
                    value: "New Category"
                };

                arrDocuments.unshift(document);

                setDocumentCategory(arrDocuments);

                switch (DocumentCategoryResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onDocumentCategoryUpdate, [props.DocumentCategory]);

    const onDocumentTypesUpdate = () => {
        const { DocumentTypes } = props;
        var DocumentTypesResponse = Object.assign({}, DocumentTypes);
        if (DocumentTypesResponse.result === true) {
            setDocumentTypes(DocumentTypesResponse.response);
        } else {
            switch (DocumentTypesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onDocumentTypesUpdate, [props.DocumentTypes]);

    const onDocumentApprovedByUpdate = () => {
        const { DocumentApprovedBy } = props;
        var DocumentApprovedByResponse = Object.assign({}, DocumentApprovedBy);
        if (DocumentApprovedByResponse.result === true) {
            setDocumentApprovedBy(DocumentApprovedByResponse.response);
        } else {
            switch (DocumentApprovedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onDocumentApprovedByUpdate, [props.DocumentApprovedBy]);

    const onSubmit = (data) => {

        var files = [];
        if (uploadDocument != null) {
            files.push({ 'name': 'upload_document', 'value': uploadDocument });
        }

        if (files.length === 0) {

            Swal.fire({
                title: 'Error',
                icon: 'error',
                html: 'Please Upload the Document',
                confirmButtonColor: PRIMARY_COLOR
            }).then((result) => {

            });

        } else {

            if (data.category === 'New Category') {
                data.category = data.category_name;
            }

            delete data.category_name;

            data.user_id = props.auth.getUser().user_id;
            // $("#progress-loader").removeClass("hidden");
            $("#upload_document-loader").removeClass("hidden");
            $("#upload_document").prop("disabled", true);
            props.actionDocumentAdd(data, files);
        }

    }

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <h3 className="content-header-title">Upload Documents</h3>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Documents Information
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={documentTypes}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Document Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Document Type is required'
                                                        }}
                                                    />
                                                    {errors.type && <ErrorLabel message={errors.type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Category&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="category"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={documentCategory}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Category"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Category is required'
                                                        }}
                                                    />
                                                    {errors.category && <ErrorLabel message={errors.category?.message} />}
                                                </div>
                                            </div>
                                            {
                                                categoryWatcher === 'New Category' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Category Name <span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="category_name"
                                                                name="category_name"
                                                                placeholder="Enter Category Name"
                                                                {...register('category_name', { required: 'Category Name is required' })}
                                                            />
                                                            {errors.category_name && <ErrorLabel message={errors.category_name?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Title&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="title"
                                                        name="title"
                                                        placeholder="Enter Title"
                                                        {...register('title', { required: 'Title is required' })}
                                                    />
                                                    {errors.title && <ErrorLabel message={errors.title?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Approved By&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="approved_by"
                                                        className="approved_by"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={documentApprovedBy}
                                                                fields={{ text: 'approved_by', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Approved By"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Approved By is required'
                                                        }}
                                                    />
                                                    {errors.approved_by && <ErrorLabel message={errors.approved_by?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        placeholder="Enter Description"
                                                        {...register('description', { required: 'Description is required' })}
                                                    />
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Upload Document</label>
                                                        <div class="custom-file">
                                                            <input
                                                                type="file"
                                                                class="custom-file-input"
                                                                id="upload_document"
                                                                name="upload_document"
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.files
                                                                    ) {
                                                                        setUploadDocument(event.target.files[0]);
                                                                    }
                                                                }}
                                                            />
                                                            <label class="custom-file-label" for="upload_document">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument.name : "Choose file"
                                                                }
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="upload_document"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        ><div className="d-flex align-items-center">
                                                Upload
                                                <span id="upload_document-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div></button>
                                        <button className="btn btn-outline-secondary mr-1 mb-1 mb-md-0 mb-lg-0 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {

    var UploadDocument = null;
    var DocumentCategory = null;
    var DocumentTypes = null;
    var DocumentApprovedBy = null;

    if (
        state.AddDocumentReducer != null &&
        Object.keys(state.AddDocumentReducer).length > 0 &&
        state.AddDocumentReducer.constructor === Object
    ) {
        switch (state.AddDocumentReducer.tag) {
            case ACTION_DOCUMENT_ADD_SUCCESS:
                UploadDocument = Object.assign({}, state.AddDocumentReducer.data);
                delete state.AddDocumentReducer;
                return { UploadDocument };
            case ACTION_MASTER_DOCUMENT_CATEGORY_SUCCESS:
                DocumentCategory = Object.assign({}, state.AddDocumentReducer.data);
                delete state.AddDocumentReducer;
                return { DocumentCategory };
            case ACTION_MASTER_DOCUMENT_TYPE_SUCCESS:
                DocumentTypes = Object.assign({}, state.AddDocumentReducer.data);
                delete state.AddDocumentReducer;
                return { DocumentTypes };
            case ACTION_MASTER_DOCUMENT_APPROVED_BY_SUCCESS:
                DocumentApprovedBy = Object.assign({}, state.AddDocumentReducer.data);
                delete state.AddDocumentReducer;
                return { DocumentApprovedBy };

            default:
        }
    }
    return {
        UploadDocument,
        DocumentCategory,
        DocumentTypes,
        DocumentApprovedBy
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionDocumentAdd, actionDocumentCategory, actionDocumentType, actionDocumentApprovedBy }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(UploadDocument))
