import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { actionClientPolicies, CLIENT_ALL_POLICIES_SUCCESS } from './action';
import ClientPolicyDetails from '../../../../../../../component/popups/policies';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import dateFormat from 'dateformat';

const ClientTabPoliciesView = (props) => {

    const [showPolicyView, setShowPolicyView] = useState(false);
    const [policiesData, setPoliciesData] = useState([]);
    const [clientPolicyDetails, setClientPolicyDetails] = useState(null);

    const [type, setType] = useState('All');
    const [totalCount, setTotalCount] = useState(0);

    const [policyColumn, setPolicyColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "policy_no", caption: "Policy No" },
        { dataField: "policy_type", caption: "Policy Type" },
        { dataField: "sum_insured", caption: "Sum Insured" },
        { dataField: "brokerage_amount", caption: "Brokerage Amount" },
        { dataField: "policy_expiry_date", caption: "Expiry Date" },
        { dataField: "insurer_name", caption: "Insurer Name" },
    ]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        props.actionClientPolicies({
            client_id: props.clientId,
            type: 'all',
            policy_type: 'policy'
        })

    }
    useEffect(initialRender, [props.clientId]);

    const onClientAllPoliciesUpdate = () => {

        const { ClientAllPolicies } = props;

        if (ClientAllPolicies != null) {
            var ClientTabPoliciesViewResponse = Object.assign({}, ClientAllPolicies);
            if (ClientTabPoliciesViewResponse.result === true) {

                $("#progress-loader").addClass("hidden");

                setPolicyColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "policy_no", caption: "Policy No" },
                    { dataField: "policy_type", caption: "Policy Type" },
                    { dataField: "sum_insured", caption: "Sum Insured" },
                    { dataField: "brokerage_amount", caption: "Brokerage Amount" },
                    { dataField: "policy_expiry_date", caption: "Expiry Date" },
                    { dataField: "insurer_name", caption: "Insurer Name" },
                ])

                setPoliciesData(ClientTabPoliciesViewResponse.response.policies_details);


                setTotalCount(ClientTabPoliciesViewResponse.response.policies_details.length);

            } else {
                $("#progress-loader").addClass("hidden");
                setPoliciesData([])
                switch (ClientTabPoliciesViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClientAllPoliciesUpdate, [props.ClientAllPolicies]);

    const renderSumInsured = (cell) => {
        return <div>{Number(cell.data.sum_insured).toFixed(2)}</div>;
    }

    const renderPoliciesColumns = () => {
        var arrColumns = [];
        policyColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        setShowPolicyView(true);
                                        setClientPolicyDetails(params.data)
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            }
            else if (objColumn.dataField === "sum_insured") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSumInsured}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderPoliciesTableView = () => {
        return (
            <DataGrid
                dataSource={policiesData}
                columnHidingEnabled={false}
                showBorders={true}
                export={{
                    fileName: "policies",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderPoliciesColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    return (
        <>
            <div className="tab-pane" id="saiba-policies" aria-labelledby="saiba-policies-tab" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionClientPolicies({
                                                    client_id: props.clientId,
                                                    type: 'all',
                                                    policy_type: 'policy'
                                                });
                                                setType('All');
                                                setTotalCount(0)
                                            }}
                                            className={`btn btn-md ${type === 'All' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            All {type === 'All' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {

                                                $("#progress-loader").removeClass("hidden");
                                                setType('Active');
                                                setTotalCount(0)
                                                props.actionClientPolicies({
                                                    client_id: props.clientId,
                                                    type: 'active',
                                                    policy_type: 'policy'
                                                });

                                            }}
                                            className={`btn btn-md ${type === 'Active' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Active  {type === 'Active' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setType('Expired');
                                                setTotalCount(0)
                                                props.actionClientPolicies({
                                                    client_id: props.clientId,
                                                    type: 'expired',
                                                    policy_type: 'policy'
                                                });
                                            }}
                                            className={`btn btn-md ${type === 'Expired' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Expired {type === 'Expired' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>

                                </ul>
                                {
                                    policiesData != null && policiesData.length > 0 ?
                                        renderPoliciesTableView() :
                                        <h6 className="text-bold-500 text-center">No Policy Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClientPolicyDetails
                show={showPolicyView}
                clientPolicyDetails={clientPolicyDetails}
                handleClose={() => {
                    setShowPolicyView(false);
                }}
                tab={1}
            />
        </>
    )

}

function mapStateToProps(state) {

    var ClientAllPolicies = null;

    if (
        state.ClientTabPoliciesViewReducer != null &&
        Object.keys(state.ClientTabPoliciesViewReducer).length > 0 &&
        state.ClientTabPoliciesViewReducer.constructor === Object
    ) {
        switch (state.ClientTabPoliciesViewReducer.tag) {

            case CLIENT_ALL_POLICIES_SUCCESS:
                ClientAllPolicies = Object.assign({}, state.ClientTabPoliciesViewReducer.data);
                delete state.ClientTabPoliciesViewReducer;
                return { ClientAllPolicies };
            default:
                return {
                    ClientAllPolicies
                }
        }
    }
    return {
        ClientAllPolicies
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionClientPolicies }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientTabPoliciesView));