
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { PRIMARY_COLOR, UNAUTHORIZED } from "../../../constants";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import { actionApproveConveyanceAmount, ACTION_APPROVE_AMOUNT_CONVEYANCE_SUCCESS } from './action';
import moment from "moment";
import { downloadFile } from "../../../utils";
import DocumentIframe from "../../popups/document_iframe";

const ApprovalConveyanceClaim = (props) => {
    const { setValue } = useForm();
    const [reimbursementApprovalDetail, setReimbursementApprovalDetail] = useState(null)
    const [isCopyCheck, setIsCopyCheck] = useState(false)
    const [reimbursementTypeId, setReimbursementTypeId] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [popup, setPopup] = useState(null)

    const initialRender = () => {
        if (props.show === true) {
            setReimbursementApprovalDetail(props.data);
            setApprovedAmountCheck(props.data);
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            setValue('reimbursement_type_id', props.data != null ? props.data.reimbursement_type_id : '');
            setValue('bill_date', props.data != null ? props.data.bill_date : '');
            setValue('approved_amount', props.data != null ? props.data.approved_amount : '');
            setValue('mode_id', props.data != null ? props.data.mode_id : '');
            setValue('from_location', props.data != null ? props.data.from_location : '');
            setValue('to_location', props.data != null ? props.data.to_location : '');
            setValue('km', props.data != null ? props.data.km : '');
            setValue('claim_amount', props.data != null ? props.data.claim_amount : '');
            setValue('description', props.data != null ? props.data.description : '');
            if (props.id != null && props.id != undefined) {
                setReimbursementTypeId(props.id);
            }
        }
        else {
            setIsCopyCheck(false);
            setReimbursementApprovalDetail(null)
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setValue('reimbursement_type_id', '');
            setValue('bill_date', '');
            setValue('approved_amount', '');
            setValue('mode_id', '');
            setValue('from_location', '');
            setValue('to_location', '');
            setValue('km', '');
            setValue('claim_amount', '');
            setValue('description', '');
        }
    }
    useEffect(initialRender, [props.show]);

    const foundMode = (mode_id, returnKey) => {
        if (props.claimDetail != null) {
            var arrReimT = Object.assign([], props.claimDetail?.reimbursement_transport_type);
            var obj = arrReimT.find(obj => obj.id === mode_id)
            if (obj != null) {
                switch (returnKey) {
                    case "name": return obj.title
                    case "km": return obj.is_km
                    case "rate": return obj.km_rate
                    default: return "--"
                }
            }
            else {
                return "--"
            }
        }
    }

    const foundMeeting = (meeting_id) => {
        if (props.claimDetail != null) {
            var arrMeeting = Object.assign([], props.claimDetail?.meeting);
            var obj = arrMeeting.find(obj => obj.id === meeting_id)
            if (obj != null) {

                return obj.title
            } else {
                return "--"
            }
        }
    }

    const renderConveyanceApproval = () => {
        var arrConveyanceApproval = []
        if (reimbursementApprovalDetail != null && reimbursementApprovalDetail.length > 0) {
            reimbursementApprovalDetail.map((row, index) => {
                return (
                    arrConveyanceApproval.push(
                        <>
                            <form>

                                <div className='row'>
                                    <div className="col-12 col-md-3 col-lg-3 ">
                                        <div className="form-group">
                                            <label className="label-control">
                                                Date&nbsp;
                                            </label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{moment(row.bill_date).format('DD-MM-YYYY')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">From&nbsp;
                                            </label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{row.from_location}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">To
                                            </label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{row.to_location}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Mode</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{row.transport_type_title}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {foundMode(row.mode_id, 'km') === 'Y' ?
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control">Kilometer</label>
                                                <div className="form-group">
                                                    <label className="font-medium-1 text-bold-700 mb-0">{row.km}</label>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Desciption</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{row.purpose}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Amount to Reimbursement</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{<div><span>&#8377;</span> {row.claim_amount} </div>}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Manager Approved Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`approved_amount${index}`}
                                                name="approved_amount"
                                                placeholder="Enter Manager Approved Amount"
                                                value={row.approved_amount != null || isCopyCheck && props?.userType === 'manager' ? String(row.approved_amount) : '--'}
                                                disabled={props?.userType === 'finance' || reimbursementApprovalDetail.status === 'approved by manager' || reimbursementApprovalDetail.status === 'approved by finance manager' ? true : false}
                                                onChange={(event) => {
                                                    if (event.target.value > row.claim_amount && event.target.value >= 0) {
                                                        $(`#err-approved-amount-${`approved_amount${index}`}`).removeClass('d-none')
                                                        return
                                                    } else {
                                                        $(`#err-approved-amount-${`approved_amount${index}`}`).addClass('d-none')
                                                    }
                                                    var tempMobileArray = Object.assign([], reimbursementApprovalDetail)
                                                    var objMobile = Object.assign({}, tempMobileArray[index])
                                                    objMobile.approved_amount = event.target.value
                                                    tempMobileArray[index] = objMobile
                                                    setReimbursementApprovalDetail(tempMobileArray);
                                                    setIsCopyCheck(false);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <label id={`err-approved-amount-${`approved_amount${index}`}`} className="font-check-label font-small-2 text-danger error-msg d-none">Enter amount is greater than Reimbursement amount</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Finance Approved Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`finance_approved_amount${index}`}
                                                name="finance_approved_amount"
                                                placeholder="Enter Finance Approved Amount"
                                                value={row.finance_approved_amount != null || isCopyCheck && props?.userType === 'finance' ? String(row.finance_approved_amount) : "--"}
                                                disabled={props?.userType === 'finance' && reimbursementApprovalDetail.status === 'approved by finance manager' || props?.userType === 'manager' ? true : false}
                                                onChange={(event) => {
                                                    if (event.target.value > row.claim_amount) {
                                                        $(`#err-finance-approved-amount-${`finance_approved_amount${index}`}`).removeClass('d-none')
                                                    } else {
                                                        $(`#err-finance-approved-amount-${`finance_approved_amount${index}`}`).addClass('d-none')
                                                    }
                                                    var tempMobileArray = Object.assign([], reimbursementApprovalDetail)
                                                    var objMobile = Object.assign({}, tempMobileArray[index])
                                                    objMobile.finance_approved_amount = event.target.value
                                                    tempMobileArray[index] = objMobile
                                                    setReimbursementApprovalDetail(tempMobileArray);
                                                    setIsCopyCheck(false);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <label id={`err-finance-approved-amount-${`finance_approved_amount${index}`}`} className="font-check-label font-small-2 text-danger error-msg d-none">Enter amount is greater than Reimbursement amount</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Meeting ID</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0 text-primary cursor-pointer"
                                                    onClick={() => {
                                                        switch (row.meeting_type) {
                                                            case 'reinsurance':
                                                                window.open(`/meetings/ri-meetings/view?id=${row.meeting_id}`, '_blank');
                                                                break;
                                                            case 'internal':
                                                                window.open(`/meetings/internal-meetings/view?id=${row.meeting_id}`, '_blank');
                                                                break;
                                                            case 'tpa':
                                                                window.open(`/meetings/tpa-meetings/view?id=${row.meeting_id}`, '_blank');
                                                                break;
                                                            case 'surveyor':
                                                                window.open(`/meetings/serveyor-meetings/view?id=${row.meeting_id}`, '_blank');
                                                                break;
                                                            case 'lead':
                                                            case 'client':
                                                                window.open(`/meetings/customer-meetings/view?id=${row.meeting_id}`, '_blank');
                                                                break;
                                                            case 'insurer':
                                                                window.open(`/meetings/insurance-company-meetings/view?id=${row.meeting_id}`, '_blank');
                                                                break;
                                                        }
                                                    }}
                                                >{row?.agenda != null ? row?.agenda : "-"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Purpose</label>
                                            <div className="form-group">
                                                <label className="font-medium-1 text-bold-700 mb-0">{row.purpose}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control">Attachment</label>
                                            <div className="form-group">
                                                {
                                                    (row != null && row.reimbursement_document != null) ?
                                                        // <a className='primary' rel="noreferrer" target="_blank" href={row.reimbursement_document}>{row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}</a> : <>-</>
                                                        <>
                                                            <a className="primary"
                                                                onClick={() => {
                                                                    if (row.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'png' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                        row.reimbursement_document?.split('.').pop() == 'gif'
                                                                    ) {
                                                                        setSelectedDocumentIndex(row.reimbursement_document)
                                                                        setShowDocumentDetails(true);
                                                                        setExtension(row.reimbursement_document?.split('.').pop())
                                                                        setDocumentDetails(row)
                                                                        setPopup(true)
                                                                    } else {
                                                                        downloadFile(row.reimbursement_document);
                                                                    }
                                                                }}
                                                            >
                                                                {row.reimbursement_document.substr(row.reimbursement_document.lastIndexOf('/') + 1)}
                                                            </a>
                                                            <a id="downloadLink" className="d-none"></a>
                                                        </>
                                                        :
                                                        <>-</>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="d-flex form-section black"> </h4>
                            </form>
                        </>

                    )
                )
            })
        }
        return arrConveyanceApproval
    }

    const setApprovedAmountCheck = (list) => {
        var arrReimbursementApprovalTypeWiseList = Object.assign([], list)
        var arrTempReimbursementApprovalTypeWiseList = [];
        arrReimbursementApprovalTypeWiseList.forEach((item, index) => {
            if (props?.userType === 'finance' && (item.finance_approved_amount === null || item.finance_approved_amount === item.claim_amount)) {
                arrTempReimbursementApprovalTypeWiseList.push(item)
            }
            else if (props?.userType === 'manager' && (item.approved_amount === null || item.approved_amount === item.claim_amount)) {
                arrTempReimbursementApprovalTypeWiseList.push(item)
            }
            if (list.length === arrTempReimbursementApprovalTypeWiseList.length) {
                setIsCopyCheck(true)

            } else {
                setIsCopyCheck(false)
            }
        })
    }

    useEffect(() => {
        var arrReimbursementApprovalDetail = Object.assign([], reimbursementApprovalDetail)
        var arrTempReimbursementApprovalDetail = [];
        arrReimbursementApprovalDetail.forEach((item, index) => {
            if (isCopyCheck && props?.userType === "manager") {
                item.approved_amount = item.claim_amount;
            } else if (isCopyCheck && props?.userType === "finance") {
                item.finance_approved_amount = item.claim_amount;
            }
            arrTempReimbursementApprovalDetail.push(item)
        })
        if (arrTempReimbursementApprovalDetail.length > 0 && arrTempReimbursementApprovalDetail != null) {
            setReimbursementApprovalDetail(arrTempReimbursementApprovalDetail)
        }
    }, [isCopyCheck])

    const onConveyanceClaimUpdate = () => {
        const { ApprovalConveyanceClaim } = props;
        var ApprovalConveyanceClaimResponse = Object.assign({}, ApprovalConveyanceClaim);
        if (ApprovalConveyanceClaimResponse.result === true) {
            props.handleClose(ApprovalConveyanceClaimResponse.response);
            $("#approval-conveyance_loader").addClass("hidden");
        } else {
            $("#approval-conveyance_loader").addClass("hidden");
            switch (ApprovalConveyanceClaimResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onConveyanceClaimUpdate, [props.ApprovalConveyanceClaim]);

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            tabindex="-1"
            aria-labelledby="approvalConveyanceModal"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle">
                            <h4 className="modal-title" id="approval-conveyance-modal">
                                {props.remTitle != null ? props.remTitle : ''} Reimbursement
                            </h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        $("body").removeClass("modal-open");
                                        props.handleClose();
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                {

                                    ((props.claimDetail != null && props?.userType === 'manager' && props.claimDetail?.status === 'pending') || (props.claimDetail != null && props?.userType === 'finance' && props.claimDetail?.status === 'approved by manager')) ?
                                        <div class="form-check cust-form-check float-right mt-0 mb-1">
                                            <input
                                                name="is_on_probation"
                                                class="form-check-input"
                                                type="checkbox"
                                                id="is_on_probation"
                                                checked={isCopyCheck}
                                                onClick={(e) => {
                                                    setIsCopyCheck(!isCopyCheck)
                                                }}
                                            />
                                            <label class="form-check-label my-auto" for="is_on_probation">
                                                <span className="cust-fs-12">Copy Reimbursement Amount</span>
                                            </label>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        {
                            props.data != null && props.data.length > 0 ?
                                renderConveyanceApproval()
                                :
                                <></>
                        }
                        <div className="row">
                            <div className="col-12 col-md-3 col-lg-3"></div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="label-control">Total Reimbursement Amount</label>
                                    <div className="form-group">
                                        <label className="label-control"><b>{props.amount != null ?
                                            <span><span>&#8377;</span> {Number(props.amount).toFixed(2)} </span> : "--"}</b></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="label-control">Total Manager Approved Amount</label>
                                    <div className="form-group">
                                        <label className="label-control"><b>{props.approvedAmount != null ?
                                            <span><span>&#8377;</span> {Number(props.approvedAmount).toFixed(2)} </span> : "--"}</b></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="label-control">Total Finance Approved Amount</label>
                                    <div className="form-group">
                                        <label className="label-control"><b>{props.financeApprovedAmount != null ?
                                            <span><span>&#8377;</span> {Number(props.financeApprovedAmount).toFixed(2)} </span> : "--"}</b></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="d-flex form-section black"> </h4>
                        {

                            ((props.claimDetail != null && props?.userType === 'manager' && props.claimDetail?.status === 'pending') || (props.claimDetail != null && props?.userType === 'finance' && props.claimDetail?.status === 'approved by manager')) ?
                                <div className="form-actions border-0 float-left">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary mr-1 mt-0 shadow  text-uppercase"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(event) => {
                                            props.handleClose();
                                            $("body").removeClass("modal-open");
                                            event.preventDefault();
                                            setIsCopyCheck(false);
                                            setReimbursementApprovalDetail(null);
                                        }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        id="conveyance_approved"
                                        name="conveyance_approved"
                                        className="btn btn-primary mr-1 mt-0 shadow  text-uppercase"
                                        onClick={() => {
                                            var arrInput = []
                                            reimbursementApprovalDetail.map((item) => {
                                                var approval_amount = item.approved_amount
                                                var finance_approved_amount = item.finance_approved_amount
                                                if (approval_amount !== null && approval_amount !== '' && props?.userType === "manager") {
                                                    if (approval_amount >= 0) {
                                                        arrInput.push({ id: item.details_id, approved_amount: approval_amount })
                                                    }

                                                }
                                                if (finance_approved_amount !== null && finance_approved_amount !== '' && props?.userType === "finance") {
                                                    if (finance_approved_amount >= 0) {
                                                        arrInput.push({ id: item.details_id, approved_amount: finance_approved_amount })
                                                    }
                                                }
                                            })
                                            if (reimbursementApprovalDetail.length === arrInput.length) {
                                                $("#approval-conveyance_loader").removeClass("hidden");
                                                $("body").removeClass("modal-open");
                                                if (props?.userType === "manager") {
                                                    props.actionApproveConveyanceAmount({ data: arrInput, reimbursement_id: reimbursementTypeId, approved_by: 'approval_manager' });
                                                } else {
                                                    props.actionApproveConveyanceAmount({ data: arrInput, reimbursement_id: reimbursementTypeId, approved_by: 'finance_manager' });
                                                }
                                            } else {
                                                // alert("Insert Approval Amount", "Please insert apporval amount in all entries");
                                                Swal.fire({
                                                    title: 'Error',
                                                    icon: 'error',
                                                    html: "Please insert apporval amount in all entries or inserted approval amount should be less than or equal to Reimbursement amount",
                                                    confirmButtonColor: PRIMARY_COLOR
                                                }).then((result) => {
                                                });
                                            }
                                        }}
                                    >Submit<span id="approval-conveyance_loader" className="spinner-border spinner-border-sm ml-half hidden"></span></button>

                                </div>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                popup={popup}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    var ApprovalConveyanceClaim = null;
    if (
        state.ApprovalConveyanceClaimReducer != null &&
        Object.keys(state.ApprovalConveyanceClaimReducer).length > 0 &&
        state.ApprovalConveyanceClaimReducer.constructor === Object
    ) {
        switch (state.ApprovalConveyanceClaimReducer.tag) {
            case ACTION_APPROVE_AMOUNT_CONVEYANCE_SUCCESS:
                ApprovalConveyanceClaim = Object.assign({}, state.ApprovalConveyanceClaimReducer.data);
                delete state.ApprovalConveyanceClaimReducer;
                return { ApprovalConveyanceClaim };
            default:
        }
    }
    return {
        ApprovalConveyanceClaim,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionApproveConveyanceAmount }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ApprovalConveyanceClaim))

