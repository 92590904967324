import { ACTION_EDIT_GET_DEPARTMENT_SUCCESS, ACTION_EDIT_GET_POLICY_TYPE_SUCCESS, ACTION_EDIT_GET_INSURER_SUCCESS, ACTION_SPECIFIC_MASTER_STATES_SUCCESS, ACTION_SPECIFIC_MASTER_CITIES_SUCCESS, ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS, ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS, ACTION_SPECIFIC_CONTACT_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const MasterEditSpecificPreferenceReducer = createReducer(INITIAL_STATE, {
    [ACTION_EDIT_GET_DEPARTMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_GET_DEPARTMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_GET_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_GET_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_GET_INSURER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_GET_INSURER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SPECIFIC_MASTER_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SPECIFIC_MASTER_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SPECIFIC_MASTER_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SPECIFIC_MASTER_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SPECIFIC_MASTER_BRANCH_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_SPECIFIC_PREFERENCE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SPECIFIC_CONTACT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SPECIFIC_CONTACT_SUCCESS,
                loading: false,
            }
        );
    },
});
export default MasterEditSpecificPreferenceReducer;