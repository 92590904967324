import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_LOGOUT_ALL_USER, API_LOGOUT_ALL_USER_CLIENTS } from '../../../../../api/constants';

export const ACTION_LOGOUT_ALL_USER_LOADING = 'ACTION_LOGOUT_ALL_USER_LOADING';
export const ACTION_LOGOUT_ALL_USER_SUCCESS = 'ACTION_LOGOUT_ALL_USER_SUCCESS';

export const ACTION_LOGOUT_ALL_USER_CLIENTS_LOADING = 'ACTION_LOGOUT_ALL_USER_CLIENTS_LOADING';
export const ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS = 'ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS';

export function actionLogoutAllUser(params) {
    return (dispatch, getState) =>
        Api.post(API_LOGOUT_ALL_USER, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_LOGOUT_ALL_USER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_LOGOUT_ALL_USER_LOADING, { loading: false, data }));
        });
}

export function actionLogoutAllUserClients(params) {
    return (dispatch, getState) =>
        Api.post(API_LOGOUT_ALL_USER_CLIENTS, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_LOGOUT_ALL_USER_CLIENTS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_LOGOUT_ALL_USER_CLIENTS_LOADING, { loading: false, data }));
        });
}

