import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_FINANCE_LIMIT_LIST } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_FINANCE_LIMIT_LIST_LOADING = 'ACTION_FINANCE_LIMIT_LIST_LOADING';
export const ACTION_FINANCE_LIMIT_LIST_SUCCESS = 'ACTION_FINANCE_LIMIT_LIST_SUCCESS';

export function actionFinanceLimitList() {
    return (dispatch, getState) =>
        Api.get(API_FINANCE_LIMIT_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_FINANCE_LIMIT_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_FINANCE_LIMIT_LIST_LOADING, { loading: false, data }));
        });
}