import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { API_RISK_MANAGEMENT_CLIENT_TYPE, API_ASSIGN_ENGINEER, API_RISK_MASTER_CUSTOMER_TYPE, API_MASTER_CITIES, API_RISK_MASTER_SALES_RM } from "../../../../../../api/constants";
import { API_RISK_SELECT_CUSTOMER } from "../../../../../../api/constants";
import { API_RISK_MANAGEMENT_TYPE_OF_INSPECTION } from "../../../../../../api/constants";
import { API_RISK_MANAGEMENT_REQUEST_ADD } from "../../../../../../api/constants";

export const ACTION_RISK_MANAGEMENT_CLIENT_TYPE_LOADING = 'ACTION_RISK_MANAGEMENT_CLIENT_TYPE_LOADING';
export const ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS = 'ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS';

export const ACTION_RISK_MANAGEMENT_CLIENT_NAME_LOADING = 'ACTION_RISK_MANAGEMENT_CLIENT_NAME_LOADING';
export const ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS = 'ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS';

export const ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_LOADING = 'ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_LOADING';
export const ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS = 'ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS';

export const ACTION_ASSIGN_ENGINEER_LOADING = 'ACTION_ASSIGN_ENGINEER_LOADING';
export const ACTION_ASSIGN_ENGINEER_SUCCESS = 'ACTION_ASSIGN_ENGINEER_SUCCESS';

export const ACTION_RISK_MASTER_CUSTOMER_TYPE_LOADING = 'ACTION_RISK_MASTER_CUSTOMER_TYPE_LOADING';
export const ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS = 'ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS';

export const ACTION_RISK_MANAGEMENT_REQUEST_ADD_LOADING = 'ACTION_RISK_MANAGEMENT_REQUEST_ADD_LOADING';
export const ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS = 'ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS';

export const ACTION_MASTER_SALES_RM_LOADING = 'ACTION_MASTER_SALES_RM_LOADING';
export const ACTION_MASTER_SALES_RM_SUCCESS = 'ACTION_MASTER_SALES_RM_SUCCESS';

export const ACTION_MASTER_CITIES_LOADING = 'ACTION_MASTER_CITIES_LOADING';
export const ACTION_MASTER_CITIES_SUCCESS = 'ACTION_MASTER_CITIES_SUCCESS';

export function actionRiskManagementClientType() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_CLIENT_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_MANAGEMENT_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementClientName(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_SELECT_CUSTOMER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_MANAGEMENT_CLIENT_NAME_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementTypeOfInspection() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_TYPE_OF_INSPECTION).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_LOADING, { loading: false, data }));
        });
}

export function actionMasterAssignEngineer() {
    return (dispatch, getState) =>
        Api.get(API_ASSIGN_ENGINEER).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_ASSIGN_ENGINEER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ASSIGN_ENGINEER_LOADING, { loading: false, data }));
        });
}

export function actionMasterCustomerType(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_MASTER_CUSTOMER_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_MASTER_CUSTOMER_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementRequestAdd(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_RISK_MANAGEMENT_REQUEST_ADD, params, files).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_MANAGEMENT_REQUEST_ADD_LOADING, { loading: false, data }));
        });
}

export function actionSalesRM(params) {
    return (dispatch, getState) =>
        Api.get(API_RISK_MASTER_SALES_RM).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_SALES_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_SALES_RM_LOADING, { loading: false, data }));
        });
}

export function actionMasterCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CITIES_LOADING, { loading: false, data }));
        });
}