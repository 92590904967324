import React from 'react';
import { Route, Switch } from 'react-router';
import TravelCalendarAddEdit from './update'
import TravelCalendarView from './view';

const TravelCalendarRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/update`}  component={TravelCalendarAddEdit}/>
                <Route path={`${match.url}`}  component={TravelCalendarView}/>
            </Switch>
        </>
    )
}

export default TravelCalendarRoute