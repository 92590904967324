import React from 'react';
import { Route, Switch } from 'react-router';
import Client from './list';
import ClientView from './view';
import ClientEdit from './edit';
import GroupLevelClientView from './group_level'

const ClientRoute = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route path={`${match.url}/view`} component={ClientView} />
            <Route path={`${match.url}/group-level-view`} component={GroupLevelClientView} />
            <Route path={`${match.url}/edit`} component={ClientEdit} />
            <Route exact path={`${match.url}`} component={Client} />
        </Switch>
    )
}
export default ClientRoute