import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionMyVertical, ACTION_MYVERTICAL_SUCCESS } from './action';
import TreeView from 'devextreme-react/tree-view';



const MyVerticals = (props) => {

    const [currentItem, setCurrentItem] = useState([]);
    const [myVertical, setMyVertical] = useState([]);
    const [value, setValue] = ('');
    const [message, setMessage] = useState("");

    const initialRender = () => {
        props.actionMyVertical()
    }
    useEffect(initialRender, []);

    const onMyTeamVerticalUpdate = () => {
        const { MyVertical } = props;
        if (MyVertical != null) {
            var MyVerticalResponse = Object.assign({}, MyVertical);
            if (MyVerticalResponse.result === true) {

                if( MyVerticalResponse.response.length > 0 ) { 
                    setMyVertical(MyVerticalResponse.response)
                    setCurrentItem(MyVerticalResponse.response)
                } else { 
                    setMessage("No Vertical Found");
                }

            } else {

                setMyVertical([])
                setCurrentItem([])

                setMessage("No Vertical Found");

                switch (MyVerticalResponse.status) {

                }
            }
        }
    }


    useEffect(onMyTeamVerticalUpdate, [props.MyVertical]);

    const selectItem = (event) => {
        if( event.itemData != null ) { 
            props.history.replace({ pathname: `/profile`, state: { user_id: event.itemData.id } });
        }
        var currentItem = Object.assign({}, event.itemData)
        setCurrentItem(currentItem)
    }

    return (
        <>
            {
                myVertical != null && myVertical.length > 0 ?
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 mx-0 px-0">
                        <div className="m-2">
                            <TreeView
                                items={myVertical}
                                dataStructure="plain"
                                displayExpr="name"
                                parentIdExpr="reporting_to_id"
                                keyExpr="id"

                                onItemClick={selectItem}
                                searchMode={value}
                                searchEnabled={true}
                            />
                        </div>
                    </div>
                </div>
                : 
                <>
                    <h6 className="text-bold-500 text-center">{message}</h6>
                </>
            }
        </>


    )
}

function mapStateToProps(state) {

    var MyVertical = null;

    if (
        state.MyVerticalReducer != null &&
        Object.keys(state.MyVerticalReducer).length > 0 &&
        state.MyVerticalReducer.constructor === Object
    ) {
        switch (state.MyVerticalReducer.tag) {

            case ACTION_MYVERTICAL_SUCCESS:
                MyVertical = Object.assign({}, state.MyVerticalReducer.data);
                delete state.MyVerticalReducer;
                return { MyVertical };
            default:
                return { MyVertical }
        }
    }
    return { MyVertical }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionMyVertical }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyVerticals);
