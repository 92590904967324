import {
  ACTION_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
  ACTION_ACTIVITYFEEDDELETE_SUCCESS,
  ACTION_ACTIVITYFEEDLIKE_SUCCESS,
  ACTION_ACTIVITYFEEDLIST_SUCCESS,
  ACTION_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
  ACTION_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
  ACTION_ACTIVITYFEEDCOMMENTEDIT_SUCCESS,
  ACTION_GET_TICKER_SUCCESS
} from "./action";
import createReducer from "../../../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  loading: false,
};

const DashboardReducer = createReducer(INITIAL_STATE, {
  [ACTION_ACTIVITYFEEDLIST_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ACTIVITYFEEDLIST_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_ACTIVITYFEEDLIKE_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ACTIVITYFEEDLIKE_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_ACTIVITYFEEDDELETE_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ACTIVITYFEEDDELETE_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_ACTIVITYFEEDCREATECOMMENT_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ACTIVITYFEEDCREATECOMMENT_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_ACTIVITYFEEDCOMMENTLIKE_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ACTIVITYFEEDCOMMENTLIKE_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_ACTIVITYFEEDCOMMENTEDIT_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ACTIVITYFEEDCOMMENTEDIT_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_ACTIVITYFEEDCOMMENTDELETE_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ACTIVITYFEEDCOMMENTDELETE_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_GET_TICKER_SUCCESS](state, action) {
    return Object.assign(
        {},
        {
            ...state,
            data: action.payload.data,
            tag: ACTION_GET_TICKER_SUCCESS,
            loading: false,
        }
    );
  },
});
export default DashboardReducer;
