import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_GET_PENDING_APPROVAL } from "../../../api/constants";
import { PRINT } from "../../../utils";

export const ACTION_GET_PENDING_APPROVAL_LOADING = "ACTION_GET_PENDING_APPROVAL_LOADING";
export const ACTION_GET_PENDING_APPROVAL_SUCCESS = "ACTION_GET_PENDING_APPROVAL_SUCCESS";

export function actionGetPendingApproval() {
    return (dispatch, getState) =>
        Api.get(API_GET_PENDING_APPROVAL)
            .then((res) => {
                const data = res;
                PRINT("ACTION RESPONSE : ", data);
                dispatch(fetchSuccess(ACTION_GET_PENDING_APPROVAL_SUCCESS, { data }));
            })
            .catch((reason) => {
                /** Handle Error */
                var data = {
                    message: "",
                };
                dispatch(loading(ACTION_GET_PENDING_APPROVAL_LOADING, { loading: false, data }));
            });
}