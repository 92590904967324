import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import $ from "jquery";
import { FiEdit, FiTrash } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { actionAppSettingsList, ACTION_APP_SETTINGS_LIST_SUCCESS, actionAppSettingsDelete, ACTION_APP_SETTINGS_DELETE_SUCCESS } from './action';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import 'react-toastify/dist/ReactToastify.css';

const AppSettingList = (props) => {

    const { match } = props;
    const [appSettings, setAppSettings] = useState([]);
    const [isDelete, setIsdelete] = useState(null);
    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
        props.actionAppSettingsList();
    }
    useEffect(initialRender, []);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "key", caption: "Key" },
        { dataField: "name", caption: "Name" },
        { dataField: "value", caption: "Value" },
    ]);

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm float-left"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: `${match.url}/edit`,
                                            state: {
                                                id: params.data.id,

                                            }
                                        })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                params.data.is_delete === 1 ?
                                    <button
                                        className="btn btn-icon btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, you want to delete?',
                                                showCancelButton: true,
                                                cancelButtonText: 'No',
                                                showConfirmButton: true,
                                                confirmButtonText: 'Yes',
                                            }).then(result => {
                                                if (result.isConfirmed) {
                                                    props.actionAppSettingsDelete({
                                                        id: params.data.id,
                                                        "is_delete": 1
                                                    })
                                                }
                                            })
                                        }
                                        }
                                    >
                                        <FiTrash size={16} className="primary" />
                                    </button>
                                    :
                                    <></>
                            )
                            }
                        />


                    </Column >
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    >
                    </Column>
                );
            }
        });
        return arrColumns;
    };

    const onAppSettingsListUpdate = () => {
        const { AppSettingsList } = props
        var AppSettingsListResponse = Object.assign({}, AppSettingsList);
        if (AppSettingsListResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setAppSettings(AppSettingsListResponse.response)
            setIsdelete(AppSettingsListResponse.response.is_delete)
        } else {
            $("#progress-loader").addClass("hidden");
            switch (AppSettingsListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onAppSettingsListUpdate, [props.AppSettingsList])

    const onAppSettingsDeleteUpdate = () => {
        const { AppSettingsDelete } = props
        var AppSettingsDeleteResponse = Object.assign({}, AppSettingsDelete);
        if (AppSettingsDeleteResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            props.actionAppSettingsList();
            toast.success('Setting deleted successfully')
        } else {
            $("#progress-loader").addClass("hidden");
            switch (AppSettingsDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onAppSettingsDeleteUpdate, [props.AppSettingsDelete])

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">App Settings</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push(`${match.url}/add`);
                                }}
                            >
                                Create App Setting
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={appSettings}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "App Settings",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var AppSettingsList = null;
    var AppSettingsDelete = null
    if (
        state.ListAppSettingsReducer != null &&
        Object.keys(state.ListAppSettingsReducer).length > 0 &&
        state.ListAppSettingsReducer.constructor === Object
    ) {
        switch (state.ListAppSettingsReducer.tag) {
            case ACTION_APP_SETTINGS_LIST_SUCCESS:
                AppSettingsList = Object.assign({}, state.ListAppSettingsReducer.data);
                delete state.ListAppSettingsReducer;
                return { AppSettingsList };
            case ACTION_APP_SETTINGS_DELETE_SUCCESS:
                AppSettingsDelete = Object.assign({}, state.ListAppSettingsReducer.data);
                delete state.ListAppSettingsReducer;
                return { AppSettingsDelete };
            default:
        }
    }
    return {
        AppSettingsList,
        AppSettingsDelete
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAppSettingsList, actionAppSettingsDelete }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AppSettingList))
