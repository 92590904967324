import React, { useState } from "react";
import ScreenTitle from "../../../../../component/screen_title";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import RetailForm from "./components/retail";
import CorporateForm from "./components/corporate";

const CreateLead = (props) => {

    const [vertical, setVertical] = useState('')

    return (
        <>
            <ScreenTitle title="Create Lead" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="label-control">
                                        Vertical
                                    </label>
                                    &nbsp;<span className="danger">*</span>

                                    <DropDownListComponent
                                        dataSource={['Corporate']}
                                        allowFiltering={false}
                                        placeholder="Select Veritical"
                                        select={(event)=>{
                                            setVertical(event.itemData.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                {
                                    vertical.length > 0
                                    ? vertical === 'Retail'
                                        ? (<RetailForm vertical={vertical} {...props}/>)
                                        : vertical === 'Corporate'
                                        ? (<CorporateForm vertical={vertical} {...props}/>)
                                        : <></>
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CreateLead;
