import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import moment from "moment";
import { FiUser } from "react-icons/fi";
import Swal from "sweetalert2";
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { CalculateFinancialYear } from "../../../../../../utils";
import { actionFlexibleDetail, ACTION_EDIT_FLEXIBLE_DETAIL_SUCCESS } from './action';
import { actionFlexibleSubmit, ACTION_FLEXIBLE_SUBMIT_SUCCESS } from './action';

const EditFlexibleForm = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {
            lta: 0,
            special_allowance: 0
        }
    });
    const [selectFlexibleOne, setSelectFlexibleOne] = useState(true);
    const [selectFlexibleTwo, setSelectFlexibleTwo] = useState(true);
    const tooltipFlexible = "(Up to max of Rs 50,000 annually - TWICE in a BLOCK OF FOUR YEARS)";

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
        props.actionFlexibleDetail({ id: props.ItDeclarationId });
    }
    useEffect(initialRender, [props.show]);

    const onFlexibleDetailUpdate = () => {
        const { FlexibleDetail } = props;
        if (FlexibleDetail != null) {
            var FlexibleDetailResponse = Object.assign({}, FlexibleDetail);
            if (FlexibleDetailResponse.result === true) {
                setValue('lta', FlexibleDetailResponse.response?.lta);
                setValue('hdfc_food_card', FlexibleDetailResponse.response?.hdfc_food_card);
                setValue('special_allowance', FlexibleDetailResponse.response?.special_allowance);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (FlexibleDetailResponse.status) {
                    case ERROR:
                        break;
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onFlexibleDetailUpdate, [props.FlexibleDetail]);

    const onFlexibleSubmitUpdate = () => {
        const { FlexibleSubmit } = props;
        var FlexibleSubmitResponse = Object.assign({}, FlexibleSubmit);
        $("#edit-flexible-form").prop("disabled", false);
        if (FlexibleSubmitResponse.result === true) {
            $("#edit-flexible-form-loader").addClass("hidden");
            props.handleClose({ 'from': 'flexible' });
        } else {
            $("#edit-flexible-form-loader").addClass("hidden");
            switch (FlexibleSubmitResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        text: FlexibleSubmitResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onFlexibleSubmitUpdate, [props.FlexibleSubmit]);

    const onSubmit = (data) => {
        /**
             * API : 
             * METHOD : POST
             * Description : Edit Flexible form
             * INPUT : Flexible form Object
             */
        if (selectFlexibleOne === true && selectFlexibleTwo === true) {
            $("#edit-flexible-form-loader").removeClass("hidden");
            $("#save-edit-flexible").prop("disabled", true);
            data.window_id = props.windowId != null ? props.windowId : '';
            props.actionFlexibleSubmit(data);
        } else {
            Swal.fire({
                title: 'Missing declarations',
                icon: 'error',
                text: "IT declarations cannot be processed ahead without the acceptance of all declarations",
                confirmButtonColor: PRIMARY_COLOR
            }).then((result) => {

            });
        }
    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="edit-flexible"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="add-flexible">
                                    {`Flexible Plan for ${CalculateFinancialYear()}`}
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                                <FiUser size={16} />&nbsp;FLEXIBLE PLAN&nbsp;
                                                <Tippy content={tooltipFlexible}>
                                                    <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                                </Tippy>
                                            </h4>

                                            <div className="row mt-1">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <label className="label-control">LTA&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">

                                                        <input
                                                            className="form-control"
                                                            id="lta"
                                                            name="lta"
                                                            type="text"
                                                            placeholder="Enter LTA"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            {...register('lta', {
                                                                required: 'Please Enter LTA'
                                                            })}
                                                        />
                                                        {errors.lta && <ErrorLabel message={errors.lta?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <label className="label-control">HDFC Meal Card&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Controller
                                                            name="hdfc_food_card"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={[
                                                                        {
                                                                            title: "Not Required",
                                                                            value: 0
                                                                        },
                                                                        {
                                                                            title: "1300 / Month",
                                                                            value: 1300
                                                                        },
                                                                        {
                                                                            title: "2600 / Month",
                                                                            value: 2600
                                                                        }
                                                                    ]}
                                                                    fields={{ text: 'title', value: 'value' }}
                                                                    allowFiltering={false}
                                                                    placeholder="Select HDFC Meal Card"
                                                                    value={field.value?.title}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData?.value);
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Please Select HDFC Meal Card'
                                                            }}
                                                        />
                                                        {errors.hdfc_food_card && <ErrorLabel message={errors.hdfc_food_card?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <label className="label-control">Special Allowance&nbsp;<span className="danger">*</span></label>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            id="special_allowance"
                                                            name="special_allowance"
                                                            type="text"
                                                            placeholder="Enter Special Allowance"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            {...register('special_allowance', {
                                                                required: 'Please Enter Special Allowance'
                                                            })}
                                                        />
                                                        {errors.special_allowance && <ErrorLabel message={errors.special_allowance?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="edit_flexible_one"
                                                        id="edit_flexible_one"
                                                        defaultChecked={selectFlexibleOne === true ? true : false}
                                                        onClick={(event) => {
                                                            if (event.target.checked === true) {
                                                                setSelectFlexibleOne(true);
                                                            } else {
                                                                setSelectFlexibleOne(false);
                                                            }
                                                        }}
                                                    />
                                                    {errors.edit_flexible_one && <ErrorLabel message={errors.edit_flexible_one?.message} />}
                                                    <label class="custom-control-label" for="edit_flexible_one">{`I hereby declare to choose the flexible plan as listed above during FY ${CalculateFinancialYear()}.`}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="edit_flexible_two"
                                                        id="edit_flexible_two"
                                                        defaultChecked={selectFlexibleTwo === true ? true : false}
                                                        onClick={(event) => {
                                                            if (event.target.checked === true) {
                                                                setSelectFlexibleTwo(true);
                                                            } else {
                                                                setSelectFlexibleTwo(false);
                                                            }
                                                        }}
                                                    />
                                                    {errors.edit_flexible_two && <ErrorLabel message={errors.edit_flexible_two?.message} />}
                                                    <label class="custom-control-label" for="edit_flexible_two">I also accept that, once declared, the regime cannot be changed during the FY, irrespective of any changes in salary.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions border-0">
                                    <button
                                        id="save-edit-flexible"
                                        className="btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0"
                                        type="submit"
                                    >
                                        <div className="d-flex align-items-center">
                                            Submit to Finance
                                            <span id="edit-flexible-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </div>
                                    </button>
                                    <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                        onClick={(event) => {
                                            props.handleClose();
                                            event.preventDefault();
                                        }}
                                    > Cancel</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var FlexibleDetail = null;
    var FlexibleSubmit = null;

    if (
        state.HRMSFlexibleEditReducer != null &&
        Object.keys(state.HRMSFlexibleEditReducer).length > 0 &&
        state.HRMSFlexibleEditReducer.constructor === Object
    ) {
        switch (state.HRMSFlexibleEditReducer.tag) {
            case ACTION_EDIT_FLEXIBLE_DETAIL_SUCCESS:
                FlexibleDetail = Object.assign({}, state.HRMSFlexibleEditReducer.data);
                delete state.HRMSFlexibleEditReducer;
                return { FlexibleDetail };
            case ACTION_FLEXIBLE_SUBMIT_SUCCESS:
                FlexibleSubmit = Object.assign({}, state.HRMSFlexibleEditReducer.data);
                delete state.HRMSFlexibleEditReducer;
                return { FlexibleSubmit };
            default:
        }
    }

    return {
        FlexibleDetail,
        FlexibleSubmit
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionFlexibleDetail, actionFlexibleSubmit }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditFlexibleForm))