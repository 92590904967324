import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import $ from "jquery";
import { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../constants";
import ErrorLabel from "../../form/error_label";
import { actionAssignEngineerPool, ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS, actionEditAssignEngineerPool, ACTION_MASTER_EDIT_ASSIGN_ENGINEER_SUCCESS } from "./action";
import { toast } from "react-toastify";

const EditAssignEngineer = (props) => {
    const { handleSubmit, control, formState: { errors }, setValue, reset } = useForm();
    const [assignEngineerPool, setAssignEngineerPool] = useState([]);
    const [assignEngineer, setAssignEngineer] = useState(null);


    const initialRender = () => {
        // setAssignEngineerPool([]);
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            props.actionAssignEngineerPool();

        } else {
            setValue('engineer_id', null)
            setAssignEngineer(null)
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onMasterAssignProposalUpdate = () => {
        const { AssignEngineerPool } = props;

        if (AssignEngineerPool != null) {
            var AssignEngineerPoolResponse = Object.assign({}, AssignEngineerPool);
            if (AssignEngineerPoolResponse.result === true) {
                var arrAssignEngineerPool = Object.assign([], AssignEngineerPoolResponse.response);
                setAssignEngineerPool(arrAssignEngineerPool);
            } else {
                switch (AssignEngineerPoolResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterAssignProposalUpdate, [props.AssignEngineerPool]);

    useEffect(() => {
        if (assignEngineerPool != null && assignEngineerPool.length > 0) {
            if (props?.assign_engineers != null && props.assign_engineers != null) {
                const numArray = props?.assign_engineers?.split(',').map(num => parseInt(num.trim(), 10));
                if (numArray.length > 0) {
                    setValue("engineer_id", numArray);
                    setAssignEngineer(numArray)
                }
            }
        }
    }, [assignEngineerPool, props.assign_engineers])

    const onEditAssignEngineerUpdate = () => {
        const { EditAssignEngineer } = props;
        if (EditAssignEngineer != null) {
            var EditAssignEngineerResponse = Object.assign({}, EditAssignEngineer);
            $("#edit_assignEngineer").prop("disabled", false);
            if (EditAssignEngineerResponse.result === true) {
                $("#assignEngineer-edit-loader").addClass("hidden");
                props.handleClose({
                    updated: true
                })
            } else {
                $("#assignEngineer-edit-loader").addClass("hidden");
                switch (EditAssignEngineerResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEditAssignEngineerUpdate, [props.EditAssignEngineer]);

    const onSubmit = (data) => {
        if (assignEngineer.length === 0) {
            toast.error("Please select assigned engineers");
            return;
        }
        let input = { id: props.id, assign_engineers: assignEngineer };
        $("#assignEngineer-edit-loader").removeClass("hidden");
        $("#edit_assignEngineer").prop("disabled", true);
        props.actionEditAssignEngineerPool(input);
    }

    return (
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Edit Assign Engineer
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose({
                                    updated: false
                                });
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Assign Engineer&nbsp;<span className="danger">
                                                        *
                                                    </span></label>
                                                    <Controller
                                                        name="engineer_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <MultiSelectComponent
                                                                {...field}
                                                                dataSource={assignEngineerPool}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                // allowFiltering={true}
                                                                mode="Box"
                                                                placeholder="Select assign engineer"
                                                                value={assignEngineer}
                                                                change={(event) => {
                                                                    setAssignEngineer(event.value)
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select assign engineer'
                                                        }}
                                                    />
                                                    {errors.engineer_id && <ErrorLabel message={errors.engineer_id?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="edit_assignEngineer"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="assignEngineer-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose({
                                                    updated: false
                                                });
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
const mapStateToProps = (state) => {

    var AssignEngineerPool = null;
    var EditAssignEngineer = null

    if (
        state.EditAssignEngineerReducer != null &&
        Object.keys(state.EditAssignEngineerReducer).length > 0 &&
        state.EditAssignEngineerReducer.constructor === Object
    ) {
        switch (state.EditAssignEngineerReducer.tag) {
            case ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS:
                AssignEngineerPool = Object.assign({}, state.EditAssignEngineerReducer.data);
                delete state.EditAssignEngineerReducer;
                return { AssignEngineerPool };
            case ACTION_MASTER_EDIT_ASSIGN_ENGINEER_SUCCESS:
                EditAssignEngineer = Object.assign({}, state.EditAssignEngineerReducer.data);
                delete state.EditAssignEngineerReducer;
                return { EditAssignEngineer };
            default:
        }

    }
    return {
        AssignEngineerPool,
        EditAssignEngineer
    }

}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionAssignEngineerPool,
        actionEditAssignEngineerPool
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditAssignEngineer))