import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionRollEdit, ACTION_ROLL_EDIT_SUCCESS } from './action';
import { actionRollView, ACTION_ROLL_VIEW_SUCCESS } from './action';
import { toast } from 'react-toastify';

const Edit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();

    const [rollDetails, setRollDetails] = useState(null);

    const initialRender = () => {
        props.actionRollView({ id: props.location.state.role_id });
    }
    useEffect(initialRender, [props.show]);

    const onRollViewUpdate = () => {
        const { RollView } = props;
        var RollViewResponse = Object.assign({}, RollView);
        if (RollViewResponse.result === true) {
            setRollDetails(RollViewResponse.response);
            setValue('title', RollViewResponse.response.title);
            setValue('status', RollViewResponse.response.status);
        } else {
            switch (RollViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onRollViewUpdate, [props.RollView]);

    const onRollEditUpdate = () => {
        const { RollEdit } = props;
        var RollEditResponse = Object.assign({}, RollEdit);
        if (RollEditResponse.result === true) {
            props.history.goBack();
        } else {
            switch (RollEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(RollEditResponse.message);
                default:
            }
        }
    }
    useEffect(onRollEditUpdate, [props.RollEdit]);


    const onSubmit = (data) => {
        data.id = rollDetails.id;
        props.actionRollEdit(data);
        console.log(JSON.stringify(data));
    }

    return (
        <>
            <ScreenTitle title="Edit Role" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Roll Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="title"
                                                        name="title"
                                                        placeholder="Enter Roll Name"
                                                        {...register('title', { required: 'Roll Name is required' })}
                                                    />
                                                    {errors.title && <ErrorLabel message={errors.title?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        title: "Active",
                                                                        id: 'active'
                                                                    },
                                                                    {
                                                                        title: "Inactive",
                                                                        id: 'inactive'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="form-actions border-0 float-none ">
                                                <button className="btn btn-primary mr-1 shadow round text-uppercase"
                                                    type="submit"
                                                >
                                                    &nbsp;Submit </button>
                                                <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        props.history.goBack();
                                                    }}
                                                > Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var RollEdit = null;
    var RollView = null;

    if (
        state.EditPermissionRoleReducer != null &&
        Object.keys(state.EditPermissionRoleReducer).length > 0 &&
        state.EditPermissionRoleReducer.constructor === Object
    ) {
        switch (state.EditPermissionRoleReducer.tag) {
            case ACTION_ROLL_EDIT_SUCCESS:
                RollEdit = Object.assign({}, state.EditPermissionRoleReducer.data);
                delete state.EditPermissionRoleReducer
                return { RollEdit };
            case ACTION_ROLL_VIEW_SUCCESS:
                RollView = Object.assign({}, state.EditPermissionRoleReducer.data);
                delete state.EditPermissionRoleReducer
                return { RollView };
            default:
        }
    }

    return {
        RollEdit,
        RollView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRollEdit, actionRollView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)