import React, { useState, useEffect } from 'react';
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Pager,
    Paging,
} from "devextreme-react/data-grid";
import { UNAUTHORIZED } from '../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import moment from "moment";
import { actionRiskManagementReport, ACTION_RISK_MANAGEMENT_REPORT_SUCCESS } from './action';
import _ from 'lodash';

const RiskManagementReports = (props) => {
    const [riskManagementsReport, setriskManagementsReport] = useState(null);
    const [columns, setColumns] = useState([
        { dataField: "created_date", caption: "Created Date", visible: false },
        { dataField: "created_date_formated", caption: "Created Date", visible: true },
        { dataField: "client_type", caption: "Client Type", visible: true },
        { dataField: "client_name", caption: "Client Name", visible: true },
        { dataField: "address", caption: "Location Address", visible: true },
        { dataField: "assign_engineer", caption: "Assign RM", visible: true },
        { dataField: "inspection_date", caption: "Date Of Activity", visible: false },
        { dataField: "inspection_date_formated", caption: "Date Of Activity", visible: true },
        { dataField: "approved_by", caption: "Approved By", visible: true },
        { dataField: "raised_by", caption: "Raised By", visible: true },
        { dataField: "actual_date_inspection", caption: "Actual Date Of Activity", visible: false },
        { dataField: "actual_date_inspection_formated", caption: "Actual Date Of Activity", visible: true },
        { dataField: "remarks", caption: "Remark", visible: true },
        { dataField: "status_name", caption: "Status", visible: true },
        { dataField: "engineer_status_name", caption: "Engineer Status", visible: true },
    ]);

    const initialRender = () => {
        props.actionRiskManagementReport();
    }
    useEffect(initialRender, []);

    const onRiskManagementReportUpdate = () => {
        const { RiskManagementReport } = props;
        var RiskManagementReportResponse = Object.assign({}, RiskManagementReport);
        if (RiskManagementReportResponse.result === true) {
            setriskManagementsReport(RiskManagementReportResponse.response);
        } else {
            switch (RiskManagementReportResponse.status) {
                case UNAUTHORIZED:
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementReportUpdate, [props.RiskManagementReport]);

    const renderFunction = (cell) => {
        return <div>{_.truncate(cell.data.address)}</div>
    }

    const renderEngineerStatus = (cell) => {
        return <div>{cell.data.engineer_status_name != null && cell.data.engineer_status_name != "Not Defined" ? cell.data.engineer_status_name : ''}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderInspectionDate = (cell) => {
        return <div>{cell?.data?.inspection_date_formated != null ? moment(cell?.data?.inspection_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActualInspectionDate = (cell) => {
        return <div>{cell?.data?.actual_date_inspection_formated != null ? moment(cell?.data?.actual_date_inspection_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "address") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderFunction}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "engineer_status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEngineerStatus}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "created_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "inspection_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderInspectionDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "actual_date_inspection_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActualInspectionDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6">
                        <h3 className="content-header-title text-capitalize mb-0">Risk Management Report</h3>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={riskManagementsReport}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    remoteOperations={true}
                                    export={{
                                        fileName: "Risk Management Report",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("created_date_formated", "visible", false)
                                        event.component.columnOption("created_date", "visible", true)
                                        event.component.columnOption("inspection_date_formated", "visible", false)
                                        event.component.columnOption("inspection_date", "visible", true)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", false)
                                        event.component.columnOption("actual_date_inspection", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("created_date_formated", "visible", true)
                                        event.component.columnOption("created_date", "visible", false)
                                        event.component.columnOption("inspection_date_formated", "visible", true)
                                        event.component.columnOption("inspection_date", "visible", false)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", true)
                                        event.component.columnOption("actual_date_inspection", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    {renderColumns()}
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var RiskManagementReport = null;
    if (
        state.riskManagementReportReducer != null &&
        Object.keys(state.riskManagementReportReducer).length > 0 &&
        state.riskManagementReportReducer.constructor === Object
    ) {
        switch (state.riskManagementReportReducer.tag) {
            case ACTION_RISK_MANAGEMENT_REPORT_SUCCESS:
                RiskManagementReport = Object.assign({}, state.riskManagementReportReducer.data);
                delete state.riskManagementReportReducer;
                return { RiskManagementReport };
            default:
        }
    }

    return {
        RiskManagementReport,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRiskManagementReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementReports))

