import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { actionPermission, ACTION_PERMISSION_SUCCESS } from './action';
import { verifyPermission } from '../../../../../../utils';

const Permissions = (props) => {
    const { match } = props;

    const [permissions, setPermissions] = useState([]);

    const initialRender = () => {
        props.actionPermission({});
    }
    useEffect(initialRender, []);

    const onPermissionUpdate = () => {
        const { Permission } = props
        var PermissionResponse = Object.assign({}, Permission);
        if (PermissionResponse.result === true) {
            setColumns([
                { dataField: "Action", caption: "Action", type: "button" },
                { dataField: "parent_title", caption: "Module Name" },
                { dataField: "title", caption: "Permission Name" },
                { dataField: "key", caption: "Permission Key" },
                { dataField: "status", caption: "Status" },
            ]);

            setPermissions(PermissionResponse.response);

        } else {
            switch (PermissionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onPermissionUpdate, [props.Permission])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "parent_title", caption: "Module Name" },
        { dataField: "title", caption: "Permission Name" },
        { dataField: "key", caption: "Permission Key" },
        { dataField: "status", caption: "Status" },
    ]);

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'active':
                return <div className={"badge badge-success round px-1"}>Active</div>;
            case 'inactive':
                return <div className={"badge badge-danger round px-1"}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderParentTitle = (cell) => {
        if (cell.data.parent_title !== null) {
            return cell.data.parent_title;
        }
        return <div className={"badge badge-info round px-1"}>Parent</div>;
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("UPDATE_PERMISSIONS") === false ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { permission_id: params.data.id } });
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>

                        }

                    </Column>
                );
            } else {
                if (objColumn.dataField === "parent_title") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderParentTitle}
                        ></Column>
                    );
                } else if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }

            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Permissions</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {
                            verifyPermission("CREATE_PERMISSIONS") ?
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push('/settings/permission/add');
                                        }}
                                    >
                                        Create Permission
                                    </button>
                                </li>
                                : <> </>
                        }

                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={permissions}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "permissions",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var Permission = null;
    if (
        state.ListPermissionReducer != null &&
        Object.keys(state.ListPermissionReducer).length > 0 &&
        state.ListPermissionReducer.constructor === Object
    ) {
        switch (state.ListPermissionReducer.tag) {
            case ACTION_PERMISSION_SUCCESS:
                Permission = Object.assign({}, state.ListPermissionReducer.data);
                delete state.ListPermissionReducer;
                return { Permission };
            default:

        }
    }
    return {
        Permission
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionPermission }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Permissions))