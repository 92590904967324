import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_PROPOSAL_LIST } from '../../../../../api/constants';
import {API_MASTER_PROBABILITY } from '../../../../../api/constants';
import { API_MASTER_ASSIGN_RM } from '../../../../../api/constants';
import { API_GET_DEPARTMENT } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_LOADING = 'ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_LOADING';
export const ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS = 'ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS';

export const ACTION_MASTER_ASSIGN_RM_VIEW_LOADING = 'ACTION_MASTER_ASSIGN_RM_VIEW_LOADING';
export const ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS = 'ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS';

export const ACTION_CLAIMS_POLICY_TYPE_VIEW_LOADING = 'ACTION_CLAIMS_POLICY_TYPE_VIEW_LOADING';
export const ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS = 'ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS';

export const ACTION_PROPOSAL_LIST_LOADING = 'ACTION_PROPOSAL_LIST_LOADING';
export const ACTION_PROPOSAL_LIST_SUCCESS = 'ACTION_PROPOSAL_LIST_SUCCESS';

export function actionProbabilityView(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_PROBABILITY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionProposalRMView(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ASSIGN_RM, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RM_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionProposalPolicyTypeView() {
    return (dispatch, getState) =>
        Api.get(API_GET_DEPARTMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_POLICY_TYPE_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionProposalList(params) {
    return (dispatch, getState) =>
        Api.post(API_PROPOSAL_LIST,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROPOSAL_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROPOSAL_LIST_SUCCESS, { loading: false, data }));
        });
}