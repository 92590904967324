import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import ScreenTitle from '../../../../../../component/screen_title';
import { Controller, useForm } from 'react-hook-form';
import ErrorLabel from '../../../../../../component/form/error_label';
import $ from 'jquery';
import { actionTeamMasterEdit, ACTION_TEAM_MASTER_EDIT_SUCCESS } from './action';
import { actionTeamHeadEdit, ACTION_TEAM_HEAD_EDIT_SUCCESS } from './action';
import { actionBranchEdit, ACTION_BRANCH_EDIT_SUCCESS } from './action';
import { actionGetTeamMaster, ACTION_GET_TEAM_MASTER_SUCCESS } from './action';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { toast } from 'react-toastify';
const TeamMasterEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [teamHeadEdit, setTeamHeadEdit] = useState(null);
    const [branchEdit, setBranchEdit] = useState(null);
    const [teamMasterDetails, setTeamMasterDetails] = useState(null);

    const initialRender = () => {
        props.actionGetTeamMaster({ id: props.location.state.id })
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (teamMasterDetails != null) {
            setValue('name', teamMasterDetails.name);
            props.actionTeamHeadEdit({});
            props.actionBranchEdit({});
        }
    }, [teamMasterDetails])

    const onTeamMasterAddUpdate = () => {
        const { TeamMasterAdd } = props;
        var TeamMasterAddResponse = Object.assign({}, TeamMasterAdd);
        if (TeamMasterAddResponse.result === true) {
            setTeamMasterDetails(TeamMasterAddResponse.response);

        } else {
            switch (TeamMasterAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onTeamMasterAddUpdate, [props.TeamMasterAdd]);

    const onTeamMasterEditUpdate = () => {
        const { TeamMasterEdit } = props;
        var TeamMasterEditResponse = Object.assign({}, TeamMasterEdit);
        $("#edit_team_master").prop("disabled", false);
        if (TeamMasterEditResponse.result === true) {
            $("#edit_team_master-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Team Master Updated Successfully");
            props.history.goBack();
        } else {
            $("#edit_team_master-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (TeamMasterEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(TeamMasterEditResponse.message);
                default:
            }
        }
    }
    useEffect(onTeamMasterEditUpdate, [props.TeamMasterEdit]);

    useEffect(() => {
        if (teamHeadEdit != null) {
            setValue('user_id', teamMasterDetails.user_id);
        }
    }, [teamHeadEdit])

    const onTeamHeadEditUpdate = () => {
        const { TeamHeadEdit } = props;
        var TeamHeadEditResponse = Object.assign({}, TeamHeadEdit);
        if (TeamHeadEditResponse.result === true) {
            setTeamHeadEdit(TeamHeadEditResponse.response);
        } else {
            switch (TeamHeadEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onTeamHeadEditUpdate, [props.TeamHeadEdit]);

    useEffect(() => {
        if (branchEdit != null) {
            setValue('user_branch_name', teamMasterDetails.branch_name);
        }
    }, [branchEdit])

    const onMasterBranchUpdate = () => {
        const { Branch } = props;
        var BranchResponse = Object.assign({}, Branch);
        if (BranchResponse.result === true) {
            setBranchEdit(BranchResponse.response);
        } else {
            switch (BranchResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onMasterBranchUpdate, [props.Branch]);

    const onSubmit = (data) => {
        data.id = teamMasterDetails.id;
        $("#edit_team_master-loader").removeClass("hidden");
        $("#edit_team_master").prop("disabled", true);
        // $("#progress-loader").removeClass("hidden");
        props.actionTeamMasterEdit(data);
    }
    return (
        <>
            <ScreenTitle title="Edit Team" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Team Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Team Name"
                                                        {...register('name', { required: 'Team Name is required' })}
                                                    />
                                                    {errors.name?.type === 'required' && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Team Head&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="user_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={teamHeadEdit}
                                                                allowFiltering={false}
                                                                placeholder="Select Team Head"
                                                                value={field.value}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Team Head is required'
                                                        }}
                                                    />
                                                    {errors.user_id && <ErrorLabel message={errors.user_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Branch&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="user_branch_name"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={branchEdit}
                                                                fields={{ id: 'id', value: 'name' }}
                                                                value={field.value}
                                                                placeholder="Select Branch"
                                                                change={(event) => {
                                                                    if (event.itemData.name != null) {
                                                                        field.onChange(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Branch'
                                                        }}
                                                    />
                                                    {errors.user_branch_name && <ErrorLabel message={errors.user_branch_name?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 float-none">
                                            <button
                                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={() => {
                                                    props.history.push('/settings/team-master');
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                id="edit_team_master"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    Submit
                                                    <span id="edit_team_master-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var TeamMasterEdit = null;
    var TeamHeadEdit = null;
    var Branch = null;
    var TeamMasterAdd = null;
    if (
        state.EditTeamMasterReducer != null &&
        Object.keys(state.EditTeamMasterReducer).length > 0 &&
        state.EditTeamMasterReducer.constructor === Object
    ) {
        switch (state.EditTeamMasterReducer.tag) {
            case ACTION_TEAM_MASTER_EDIT_SUCCESS:
                TeamMasterEdit = Object.assign({}, state.EditTeamMasterReducer.data);
                delete state.EditTeamMasterReducer
                return { TeamMasterEdit };
            case ACTION_TEAM_HEAD_EDIT_SUCCESS:
                TeamHeadEdit = Object.assign({}, state.EditTeamMasterReducer.data);
                delete state.EditTeamMasterReducer
                return { TeamHeadEdit };
            case ACTION_BRANCH_EDIT_SUCCESS:
                Branch = Object.assign({}, state.EditTeamMasterReducer.data);
                delete state.EditTeamMasterReducer
                return { Branch };
            case ACTION_GET_TEAM_MASTER_SUCCESS:
                TeamMasterAdd = Object.assign({}, state.EditTeamMasterReducer.data);
                delete state.EditTeamMasterReducer
                return { TeamMasterAdd };
            default:
        }
    }

    return {
        TeamMasterEdit,
        TeamHeadEdit,
        Branch
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTeamMasterEdit, actionTeamHeadEdit, actionBranchEdit, actionGetTeamMaster }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TeamMasterEdit))