
import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_CUSTOMER_MEETINGS, API_RETENTION_CLIENTS_MEETINGS, API_MYTEAMVERTICAL } from '../../../../../../api/constants';
// import { PRINT } from '../../../../../../utils';

export const ACTION_API_CUSTOMER_MEETINGS_LOADING = 'ACTION_API_CUSTOMER_MEETINGS_LOADING';
export const ACTION_API_CUSTOMER_MEETINGS_SUCCESS = 'ACTION_API_CUSTOMER_MEETINGS_SUCCESS';

export const ACTION_RETENTION_CLIENTS_MEETINGS_LOADING = 'ACTION_RETENTION_CLIENTS_MEETINGS_LOADING';
export const ACTION_RETENTION_CLIENTS_MEETINGS_SUCCESS = 'ACTION_RETENTION_CLIENTS_MEETINGS_SUCCESS';

export const ACTION_CUSTOMER_TEAM_VERTICAL_LOADING = 'ACTION_CUSTOMER_TEAM_VERTICAL_LOADING';
export const ACTION_CUSTOMER_TEAM_VERTICAL_SUCCESS = 'ACTION_CUSTOMER_TEAM_VERTICAL_SUCCESS';

export function actionCustomerMeetings(params) {
    return (dispatch, getState) =>
        Api.post(API_CUSTOMER_MEETINGS, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_CUSTOMER_MEETINGS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_CUSTOMER_MEETINGS_LOADING, { loading: false, data }));
        });
}

export function actionRetentionClientMeetings(params) {
    return (dispatch, getState) =>
        Api.post(API_RETENTION_CLIENTS_MEETINGS, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RETENTION_CLIENTS_MEETINGS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RETENTION_CLIENTS_MEETINGS_LOADING, { loading: false, data }));
        });
}

export function actionCustomerTeamMeetingMember(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CUSTOMER_TEAM_VERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CUSTOMER_TEAM_VERTICAL_LOADING, { loading: false, data }));
        });
}