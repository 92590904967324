import { ACTION_CRON_DASHBOARD_SUCCESS } from "./action";
import { ACTION_CRON_STATISTICS_SUCCESS } from "./action"

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const CronDashboardReducer = createReducer(INITIAL_STATE, {
    [ACTION_CRON_DASHBOARD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CRON_DASHBOARD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CRON_STATISTICS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CRON_STATISTICS_SUCCESS,
                loading: false,
            }
        );
    }
});

export default CronDashboardReducer;