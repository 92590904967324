import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS, ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS, ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS, ACTION_RISK_POOL_VIEW_SUCCESS, ACTION_RISK_POOL_EDIT_SUCCESS, ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS, ACTION_MASTER_SALES_RM_SUCCESS, ACTION_MASTER_CITIES_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const EditRiskManagementPoolReducer = createReducer(INITIAL_STATE, {
    [ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_POOL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_POOL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_POOL_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_POOL_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_SALES_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_SALES_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
}
);
export default EditRiskManagementPoolReducer;
