import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { PRINT } from '../../../../../../utils';
import { API_ADD_MANDATE_POLICY } from '../../../../../../api/constants';
import { API_MASTER_COMPANY_GROUP } from '../../../../../../api/constants';
import { API_MASTER_INSURANCE_COMPANY_NAME } from '../../../../../../api/constants';
import { API_MASTER_INSURANCE_BRANCH } from '../../../../../../api/constants';
import { API_CLAIMS_CUSTOMER } from '../../../../../../api/constants';
import { API_CLAIMS_RM } from '../../../../../../api/constants';
import { API_CLAIMS_POLICY_TYPE } from '../../../../../../api/constants';

export const ACTION_ADD_MANDATE_POLICY_LOADING = 'ACTION_ADD_MANDATE_POLICY_LOADING';
export const ACTION_ADD_MANDATE_POLICY_SUCCESS = 'ACTION_ADD_MANDATE_POLICY_SUCCESS';

export const ACTION_CLAIMS_COMPANY_GROUP_LOADING = 'ACTION_CLAIMS_COMPANY_GROUP_LOADING';
export const ACTION_CLAIMS_COMPANY_GROUP_SUCCESS = 'ACTION_CLAIMS_COMPANY_GROUP_SUCCESS';

export const ACTION_CLAIMS_INSURANCE_COMPANY_LOADING = 'ACTION_CLAIMS_INSURANCE_COMPANY_LOADING';
export const ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS = 'ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS';

export const ACTION_CLAIMS_INSURANCE_BRANCH_LOADING = 'ACTION_CLAIMS_INSURANCE_BRANCH_LOADING';
export const ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS = 'ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS';

export const ACTION_CLAIMS_CUSTOMER_LOADING = 'ACTION_CLAIMS_CUSTOMER_LOADING';
export const ACTION_CLAIMS_CUSTOMER_SUCCESS = 'ACTION_CLAIMS_CUSTOMER_SUCCESS';

export const ACTION_CLAIMS_RM_LOADING = 'ACTION_CLAIMS_RM_LOADING';
export const ACTION_CLAIMS_RM_SUCCESS = 'ACTION_CLAIMS_RM_SUCCESS';

export const ACTION_CLAIMS_POLICY_TYPE_LOADING = 'ACTION_CLAIMS_POLICY_TYPE_LOADING';
export const ACTION_CLAIMS_POLICY_TYPE_SUCCESS = 'ACTION_CLAIMS_POLICY_TYPE_SUCCESS';

export function actionMandatePolicySave(params) {
    return (dispatch, getState) =>
        Api.post(API_ADD_MANDATE_POLICY,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ADD_MANDATE_POLICY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ADD_MANDATE_POLICY_LOADING, { loading: false, data }));
        });
}

export function actionCompanyGroup() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_COMPANY_GROUP).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_COMPANY_GROUP_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_COMPANY_GROUP_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceCompany() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_INSURANCE_COMPANY_NAME).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceBranch(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_INSURANCE_BRANCH,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_INSURANCE_BRANCH_LOADING, { loading: false, data }));
        });
}

export function actionClaimsCustomer(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_CUSTOMER,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_CUSTOMER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_CUSTOMER_LOADING, { loading: false, data }));
        });
}

export function actionClaimsRm(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_RM,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_RM_LOADING, { loading: false, data }));
        });
}

export function actionClaimsPolicyType() {
    return (dispatch, getState) =>
        Api.get(API_CLAIMS_POLICY_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}