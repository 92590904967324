import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { actionEmployeeView, ACTION_EMPLOYEE_VIEW_SUCCESS } from './action';
import { actionEmployeeVisitingCard, ACTION_EMPLOYEE_VISITING_CARD_SUCCESS } from './action'
import { useForm } from "react-hook-form";
import { generateInitialLettersString } from '../../../utils';
import ErrorLabel from "../../../component/form/error_label";
import { UNAUTHORIZED } from '../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AiOutlineDownload } from 'react-icons/ai';
import { RiShareForwardLine } from 'react-icons/ri';
import { GrDownload } from 'react-icons/gr';
import { IoLogoWhatsapp } from "react-icons/io";
import { HiMail } from 'react-icons/hi';

const VisitingCard = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [employeeView, setEmployeeView] = useState([]);
    const [employeeVisitingCard, setEmployeeVisitingCard] = useState(null);

    const initialRender = () => {
        if (props.show === true) {
            props.actionEmployeeVisitingCard({
                user_id: props.data.user_id
            });
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            setEmployeeVisitingCard(null)
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
        // props.actionEmployeeView({
        //     id: props.reporting_to_id
        // });

    }
    useEffect(initialRender, [props.show]);

    async function downloadImage(imageUrl, imageName) {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    // const onEmployeeViewUpdate = () => {
    //     const { EmployeeView } = props;
    //     var EmployeeResponse = Object.assign({}, EmployeeView);
    //     if (EmployeeResponse.result === true) {
    //         setEmployeeView(EmployeeResponse.response);
    //     } else {
    //         switch (EmployeeResponse.status) {
    //             case UNAUTHORIZED:
    //                 break;
    //             default:
    //         }
    //     }
    // }
    // useEffect(onEmployeeViewUpdate, [props.EmployeeView])


    const onEmployeeVisitingCardUpdate = () => {
        const { EmployeeVisitingCard } = props;
        var EmployeeVisitingCardResponse = Object.assign({}, EmployeeVisitingCard);
        if (EmployeeVisitingCardResponse.result === true) {
            setEmployeeVisitingCard(EmployeeVisitingCardResponse.response.visiting_card)
        } else {
            switch (EmployeeVisitingCardResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onEmployeeVisitingCardUpdate, [props.EmployeeVisitingCard]);

    const onSubmit = (data) => {
        $("#visiting_card-loader").removeClass("hidden");
        $("#visiting_card").prop("disabled", true);
    }
    return (
        <>
            <div
                className="modal fade show "
                id="default"
                role="dialog"
                aria-labelledby="visiting-card"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pt-2">
                                    <a className="profile-image">
                                        {
                                            (props.data != null && props.data.profile_picture != null) ?
                                                <img
                                                    src={props.data.profile_picture}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={50}
                                                    height={50}
                                                    style={{ marginTop: '-10px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 bg-circle d-flex align-items-center text-bold-600 justify-content-center"
                                                        style={{ marginTop: '-10px', border: '5px solid #FFF', height: 50, width: 50 }}
                                                    >
                                                        {generateInitialLettersString(String(props.data.initial_name))}

                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-half">
                                    <div class="row">
                                        <div class="col-11">
                                            <h3 class="card-title mb-0 font-medium-1">
                                                {props.data.full_name}
                                            </h3>
                                            <button className="btn btn-sm btn-success font-small-1 text-bold-500 round">{props.data.designation}</button>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn pt-1 float-right col-1"
                                            onClick={() => {
                                                downloadImage(`data:image/jpeg;base64,${employeeVisitingCard}`, props.data.full_name + ' Visiting Card.jpg')
                                            }}
                                        >
                                            <GrDownload size={19} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body hidden">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Email Id&nbsp; <span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            placeholder=" Email Id"
                                                            {...register('email', {
                                                                required: 'Email Id is required',
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                    message: 'Please enter valid Email Id'
                                                                },
                                                            })}
                                                        />
                                                        {errors.email && <ErrorLabel message={errors.email?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none hidden">
                                            <button
                                                id="visiting_card"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp; Submit
                                                    <span id="visiting_card-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="row">
                                            <div className="col-12 col-lg-12 col-md-12 mb-1">
                                                <div className="p-half rounded">
                                                    {
                                                        employeeVisitingCard != null && employeeVisitingCard != undefined ?
                                                            <>

                                                                <img

                                                                    className="rounded w-100"
                                                                    src={`data:image/jpeg;base64, ${employeeVisitingCard}`} alt="Visiting Card"
                                                                />

                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: 1,
                                                                    bottom: 20,
                                                                    left: 360,
                                                                    right: 0,

                                                                }} >
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="text-center align-items-center" style={{ height: 180 }}>
                                                                <div class="spinner-border" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                <h5>Loading...</h5>
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </form>


                                    {/* <div class="media profil-cover-details w-100">
                                        <div class="media-left pl-2 pt-2">
                                            <a className="profile-image">
                                                {
                                                    (employeeView != null && employeeView.profile_photo != null) ?
                                                        <img
                                                            src={employeeView.profile_photo}
                                                            className="rounded-circle img-border"
                                                            alt="Card"
                                                            width={100}
                                                            height={100}
                                                            style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                        />
                                                        :
                                                        <>
                                                            <div
                                                                className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                                style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                            >
                                                                {generateInitialLettersString(String(employeeView.full_name))}

                                                            </div>
                                                        </>
                                                }
                                            </a>
                                        </div>
                                        <div class="media-body pt-1 px-2 pb-2">
                                            <div class="row">
                                                <div class="col">
                                                    <h3 class="card-title mb-0">
                                                        {employeeView.full_name} <button className="btn btn-sm btn-success font-small-1 text-bold-500 round">{employeeView.designation_name}</button>
                                                    </h3>
                                                </div>

                                            </div>
                                            <div className="row pt-2">
                                                <div className="col-12 col-lg-3 col-md-12">
                                                    <h6 className="text-uppercase font-small-3 mb-0">Visiting Card Front</h6>
                                                    {
                                                        (employeeView != null && employeeView.visiting_card_front != null) ?
                                                            <a target="_blank" href={employeeView.visiting_card_front}>Click Here</a> : <>-</>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row pt-2">
                                                <div className="col-12 col-lg-3 col-md-12">
                                                    <h6 className="text-uppercase font-small-3 mb-0">Visiting Card Back</h6>
                                                    {
                                                        (employeeView != null && employeeView.visiting_card_back != null) ?
                                                            <a target="_blank" href={employeeView.visiting_card_back}>Click Here</a> : <>-</>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};
// const mapStateToProps = (state) => {
//     var EmployeeView = null;
//     var EmployeeVisitingCard = null;

//     if (
//         state.EmployeeViewReducer != null &&
//         Object.keys(state.EmployeeViewReducer).length > 0 &&
//         state.EmployeeViewReducer.constructor === Object
//     ) {
//         switch (state.EmployeeViewReducer.tag) {
//             case ACTION_EMPLOYEE_VIEW_SUCCESS:
//                 EmployeeView = Object.assign({}, state.EmployeeViewReducer.data);
//                 delete state.EmployeeViewReducer;
//                 return { EmployeeView };
//             case ACTION_EMPLOYEE_VISITING_CARD_SUCCESS:
//                 EmployeeVisitingCard = Object.assign({}, state.EmployeeViewReducer.data);
//                 delete state.EmployeeViewReducer;
//                 return { EmployeeVisitingCard };
//             default:

//         }
//     }

//     return {
//         EmployeeView,
//         EmployeeVisitingCard
//     }
// }


const mapStateToProps = (state) => {
    var EmployeeVisitingCard = null;
    if (
        state.EmployeeVisitingCardReducer != null &&
        Object.keys(state.EmployeeVisitingCardReducer).length > 0 &&
        state.EmployeeVisitingCardReducer.constructor === Object
    ) {
        switch (state.EmployeeVisitingCardReducer.tag) {
            case ACTION_EMPLOYEE_VISITING_CARD_SUCCESS:
                EmployeeVisitingCard = Object.assign({}, state.EmployeeVisitingCardReducer.data);
                delete state.EmployeeVisitingCardReducer;
                return { EmployeeVisitingCard };
            default:

        }
    }
    return {
        EmployeeVisitingCard
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionEmployeeView, actionEmployeeVisitingCard }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitingCard)

