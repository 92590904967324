import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_DECLARATION_POOL_EDIT } from '../../../../../../api/constants';
import { API_IT_DECLARATION_POOL_DETAIL } from '../../../../../../api/constants';

export const ACTION_IT_DECLARATION_POOL_EDIT_LOADING = 'ACTION_IT_DECLARATION_POOL_EDIT_LOADING';
export const ACTION_IT_DECLARATION_POOL_EDIT_SUCCESS = 'ACTION_IT_DECLARATION_POOL_EDIT_SUCCESS';

export const ACTION_IT_DECLARATION_POOL_DETAIL_LOADING = 'ACTION_IT_DECLARATION_POOL_DETAIL_LOADING';
export const ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS = 'ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS';

export function actionItDeclarationPoolEdit(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_POOL_EDIT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_POOL_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_POOL_EDIT_LOADING, { loading: false, data }));
        });
}
export function actionItDeclarationPoolDetail(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_POOL_DETAIL, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_POOL_DETAIL_LOADING, { loading: false, data }));
        });
}