import createReducer from "../../../../../reducers/createReducer";
import { ACTION_EDIT_COMPANY_SUCCESS, ACTION_EDIT_GET_OPPORTUNITY_QUESTION_SUCCESS, ACTION_MASTER_EDIT_COMPANY_GROUP_SUCCESS, ACTION_MASTER_EDIT_LEAD_CITIES_SUCCESS, ACTION_MASTER_EDIT_LEAD_STATES_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_INDUSTRIAL_SEGMENT_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_FORM_OF_ORGANIZATION_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_LEAD_SOURCE_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS } from "./action";
import { ACTION_MASTER_CLIENT_DETAILS_SUCCESS } from "./action";
import { ACTION_MASTER_CLIENT_EDIT_SUCCESS } from "./action";
import { ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS } from "./action";
import { ACTION_DELETE_POLICY_SUCCESS } from './action';
import { ACTION_GET_CLIENT_EDIT_SEARCH_LIST_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeadEditCorporateReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_EDIT_COMPANY_GROUP_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_COMPANY_GROUP_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_CUSTOMER_CATEGORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_CUSTOMER_CATEGORY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_INDUSTRIAL_SEGMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_INDUSTRIAL_SEGMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_FORM_OF_ORGANIZATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_FORM_OF_ORGANIZATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_LEAD_SOURCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_LEAD_SOURCE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_LEAD_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_LEAD_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_LEAD_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_LEAD_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_CLIENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CLIENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_CLIENT_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CLIENT_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DELETE_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DELETE_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_GET_OPPORTUNITY_QUESTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_GET_OPPORTUNITY_QUESTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_CLIENT_EDIT_SEARCH_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_CLIENT_EDIT_SEARCH_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_COMPANY_SUCCESS,
                loading: false,
            }
        );
    }
});
export default LeadEditCorporateReducer;