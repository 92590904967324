import React, { useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import AddNewCompany from '../../../../../../component/form/new_customer';
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from '../../../../../../component/form/error_label';
import { FiUser } from 'react-icons/fi';
import { UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import $ from "jquery";
import { actionCompanyGroup, ACTION_CLAIMS_COMPANY_GROUP_SUCCESS } from "./action";
import { actionInsuranceCompany, ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS } from "./action";
import { actionInsuranceBranch, ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS } from "./action";
import { actionClaimsCustomer, ACTION_CLAIMS_CUSTOMER_SUCCESS } from "./action";
import { actionClaimsRm, ACTION_CLAIMS_RM_SUCCESS } from "./action";
import { actionClaimsPolicyType, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from "./action";
import { actionMandatePolicySave, ACTION_ADD_MANDATE_POLICY_SUCCESS } from "./action";
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';

const Mandate = (props) => {

   const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();

   const [addNewCompany, setAddNewCompany] = useState(false);
   const [selectedCompanyId, setSelectedCompanyId] = useState(0);
   const [companyGroup, setCompanyGroup] = useState([]);
   const [insuranceCompany, setInsuranceCompany] = useState([]);
   const [insuranceBranch, setInsuranceBranch] = useState([]);
   const [claimsCustomer, setClaimsCustomer] = useState([]);
   const [claimsPolicyType, setClaimsPolicyType] = useState([]);
   const [newCompany, setNewCompany] = useState(null);

   const initialRender = () => {
      props.actionCompanyGroup();
      props.actionInsuranceCompany();
      // props.actionClaimsRm();
      props.actionClaimsPolicyType();
   }
   useEffect(initialRender, []);

   useEffect(() => {
      if (newCompany != null) {
         setValue('customer_group', newCompany.client_group_name)
      }
   }, [companyGroup])

   const onClaimsCompanyGroupUpdate = () => {
      const { CompanyGroup } = props;
      if (CompanyGroup != null) {
         var CompanyGroupResponse = Object.assign({}, CompanyGroup);
         if (CompanyGroupResponse.result === true) {
            setCompanyGroup(CompanyGroupResponse.response);
         } else {
            switch (CompanyGroupResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onClaimsCompanyGroupUpdate, [props.CompanyGroup]);

   const onInsuranceCompanyUpdate = () => {
      const { InsuranceCompany } = props;
      if (InsuranceCompany != null) {
         var InsuranceCompanyResponse = Object.assign({}, InsuranceCompany);
         if (InsuranceCompanyResponse.result === true) {
            setInsuranceCompany(InsuranceCompanyResponse.response);
         } else {
            switch (InsuranceCompanyResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onInsuranceCompanyUpdate, [props.InsuranceCompany]);

   const onInsuranceBranchUpdate = () => {
      const { InsuranceBranch } = props;
      if (InsuranceBranch != null) {
         var InsuranceBranchResponse = Object.assign({}, InsuranceBranch);
         if (InsuranceBranchResponse.result === true) {
            setInsuranceBranch(InsuranceBranchResponse.response);
         } else {
            switch (InsuranceBranchResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onInsuranceBranchUpdate, [props.InsuranceBranch]);

   useEffect(() => {
      if (newCompany != null) {
         setValue('client_id', newCompany.id);
      }
   }, [claimsCustomer])

   const onClaimsCustomerUpdate = () => {
      const { ClaimsCustomer } = props;
      if (ClaimsCustomer != null) {
         var ClaimsCustomerResponse = Object.assign({}, ClaimsCustomer);
         if (ClaimsCustomerResponse.result === true) {
            setClaimsCustomer(ClaimsCustomerResponse.response);
         } else {
            switch (ClaimsCustomerResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onClaimsCustomerUpdate, [props.ClaimsCustomer]);

   const onClaimsPolicyTypepdate = () => {
      const { ClaimsPolicyType } = props;
      if (ClaimsPolicyType != null) {
         var ClaimsPolicyTypeResponse = Object.assign({}, ClaimsPolicyType);
         if (ClaimsPolicyTypeResponse.result === true) {
            setClaimsPolicyType(ClaimsPolicyTypeResponse.response);
         } else {
            switch (ClaimsPolicyTypeResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onClaimsPolicyTypepdate, [props.ClaimsPolicyType]);

   const onMandatePolicySavepdate = () => {
      const { MandatePolicySave } = props;
      if (MandatePolicySave != null) {
         var MandatePolicySaveResponse = Object.assign({}, MandatePolicySave);
         $("#add_detail").prop("disabled", false);
         if (MandatePolicySaveResponse.result === true) {
            $("#add_detail-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Policy Inserted Successfully");
            props.history.push('/claims/pinc/policy/list');
         } else {
            $("#add_detail-loader").addClass("hidden");
            switch (MandatePolicySaveResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onMandatePolicySavepdate, [props.MandatePolicySave]);

   const onSubmit = (data) => {
      $("#add_detail-loader").removeClass("hidden");
      $("#add_detail").prop("disabled", true);
      data.user_id = props.auth.getUser().id
      delete data.gst;
      props.actionMandatePolicySave(data);
   }

   return (
      <>
         <div>
            <div className="row col-12 pr-0">
               <div className="col-12 col-md-10 col-lg-10">
                  <ScreenTitle title="Add New Mandate Policy" />
               </div>
               <div className="col-12 col-md-2 col-lg-2 mb-1 mr-0 pr-0" >
                  <button class="btn btn-outline-secondary shadow round text-uppercase mx-0 ml-3 float-right"
                     onClick={() => { props.history.goBack() }}> BACK
                  </button>
               </div>
            </div>
            <div className="card">
               <div className="card-body">
                  <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                     <div class="form-body">
                        <div class="row">
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 <FiUser size={16} />&nbsp;Client Details
                              </h4>
                           </div>
                           <div className="col-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                 <label className="label-control">Company Group Name&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="customer_group"
                                    control={control}
                                    render={({ field }) =>
                                       <AutoCompleteComponent
                                          {...field}
                                          dataSource={companyGroup}
                                          fields={{ value: 'name' }}
                                          value={field.value}
                                          placeholder="Select Company Group Name"
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)
                                                setClaimsCustomer([])
                                                props.actionClaimsCustomer({
                                                   client_group_name: event.itemData.name,
                                                   type: 'client'
                                                })
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Please Select Company Group Name'
                                    }}
                                 />
                                 {errors.customer_group && <ErrorLabel message={errors.customer_group?.message} />}
                              </div>
                           </div>
                           <div className="col-12 col-md-4 col-lg-4" id="customer-field">
                              <div className="form-group">
                                 <label className="label-control">Select Customer&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="client_id"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimsCustomer}
                                          fields={{ text: 'name', value: 'id' }}
                                          allowFiltering={true}
                                          placeholder="Select Customer"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.id)
                                                setSelectedCompanyId(1);
                                             }
                                          }}

                                       />
                                    }
                                    rules={{
                                       required: 'Customer is required'
                                    }}
                                 />
                                 {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                              </div>
                           </div>
                           <div className="col-12 col-md-2 col-lg-2" id="customer-new-button">
                              <div className="form-group">
                                 <label></label>
                                 <button
                                    type="button"
                                    className="btn btn-block btn-primary shadow round text-uppercase font-small-2"
                                    onClick={(event) => {
                                       setAddNewCompany(true);
                                    }}
                                 > Add Customer</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class={`form-body ${selectedCompanyId === 0 ? 'hidden' : ''}`}>
                        <div class="row">
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Policy Details
                              </h4>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Insurance Company&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="insurer_company_id"
                                    className="form-control"
                                    control={control}
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={insuranceCompany}
                                          fields={{ text: 'name', value: 'id' }}
                                          allowFiltering={true}
                                          placeholder="Select Insurance Company"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.id)
                                                setInsuranceBranch([])
                                                props.actionInsuranceBranch({ insurer_company_id: [event.itemData.id] })
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Insurance Company is required'
                                    }}
                                 />
                                 {errors.insurer_company_id && <ErrorLabel message={errors.insurer_company_id?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-8 col-lg-8">
                              <div class="form-group">
                                 <label class="label-control">Insurer Branch&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="insurer_branch_id"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={insuranceBranch}
                                          fields={{ text: 'address', value: 'saiba_branch_id' }}
                                          allowFiltering={false}
                                          placeholder="Select Insurer Branch"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.saiba_branch_id)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Insurer Branch is required'
                                    }}
                                 />
                                 {errors.insurer_branch_id && <ErrorLabel message={errors.insurer_branch_id?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Type&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="policy_type"
                                    control={control}
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimsPolicyType}
                                          fields={{ text: 'name', value: 'id' }}
                                          placeholder="Select Policy Type"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.id)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Policy Type is required'
                                    }}
                                 />
                                 {errors.policy_type && <ErrorLabel message={errors.policy_type?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <fieldset className="form-group">
                                 <label className="label-control">Policy No.&nbsp;<span className="danger">*</span></label>
                                 <input name="policy_no"
                                    id="policy_no"
                                    class="form-control"
                                    placeholder="Enter Policy No."
                                    {...register('policy_no', { required: 'Policy No. is required' })}
                                 />
                                 {errors.policy_no && <ErrorLabel message={errors.policy_no?.message} />}
                              </fieldset>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Vertical&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="vertical"
                                    control={control}
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={[
                                             {
                                                id: 'corporate',
                                                name: 'Corporate'
                                             },
                                             {
                                                id: 'retail',
                                                name: 'Retail'
                                             }
                                          ]}
                                          fields={{ text: 'name', value: 'id' }}
                                          allowFiltering={false}
                                          placeholder="Select Veritical"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.id)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Vertical is required'
                                    }}
                                 />
                                 {errors.vertical && <ErrorLabel message={errors.vertical?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Start Date&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="policy_start_date"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}

                                       />
                                    }
                                    rules={{
                                       required: 'Policy Start Date is required'
                                    }}
                                 />
                                 {errors.policy_start_date && <ErrorLabel message={errors.policy_start_date?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy End Date&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="policy_expiry_date"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          acceptCustomValue={false}
                                          placeholder='DD/MM/YYYY'
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}

                                       />
                                    }
                                    rules={{
                                       required: 'Policy End Date is required'
                                    }}
                                 />
                                 {errors.policy_expiry_date && <ErrorLabel message={errors.policy_expiry_date?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <fieldset className="form-group">
                                 <label className="label-control">Sum Insured&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="sum_insured"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <input
                                          {...field}
                                          className="form-control"
                                          placeholder="Enter Sum Insured"
                                          value={field.value}
                                          type="number"
                                          onChange={(event) => field.onChange(event.target.value)}
                                       />
                                    }
                                    rules={{
                                       required: 'Please Enter Sum Insured'
                                    }}
                                 />
                                 {errors.sum_insured && <ErrorLabel message={errors.sum_insured?.message} />}
                              </fieldset>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <fieldset className="form-group">
                                 <label className="label-control">Net Premium&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="net_premium"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <input
                                          {...field}
                                          className="form-control"
                                          placeholder="Enter Net Premium"
                                          value={field.value}
                                          type='number'
                                          onChange={(event) => {
                                             field.onChange(event.target.value);
                                             setValue('service_tax', Number(event.target.value * 0.18).toFixed(2))
                                             setValue('gross_premium', Number(Number(event.target.value) + Number(event.target.value * 0.18)).toFixed(2))
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Please Enter Net Premium'
                                    }}
                                 />
                                 {errors.net_premium && <ErrorLabel message={errors.net_premium?.message} />}
                              </fieldset>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4 hidden">
                              <fieldset className="form-group">
                                 <label className="label-control">GST (18 %)</label>
                                 <Controller
                                    name="gst"
                                    control={control}
                                    defaultValue={18}
                                    render={({ field }) =>
                                       <input
                                          {...field}
                                          className="form-control"
                                          readOnly={true}
                                          value={"18"}
                                          type='number'
                                          onChange={(event) => field.onChange(event.target.value)}
                                       />
                                    }
                                 />
                                 {errors.gst && <ErrorLabel message={errors.gst?.message} />}
                              </fieldset>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <fieldset className="form-group">
                                 <label className="label-control">Gst Amount (GST 18%)</label>
                                 <Controller
                                    name="service_tax"
                                    control={control}
                                    render={({ field }) =>
                                       <input
                                          {...field}
                                          className="form-control"
                                          readOnly={true}
                                          type='number'
                                          onChange={(event) => field.onChange(event.target.value)}
                                       />
                                    }
                                 />
                                 {errors.service_tax && <ErrorLabel message={errors.service_tax?.message} />}
                              </fieldset>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <fieldset className="form-group">
                                 <label className="label-control">Gross Premium&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="gross_premium"
                                    control={control}
                                    render={({ field }) =>
                                       <input
                                          {...field}
                                          className="form-control"
                                          readOnly={true}
                                          value={field.value}
                                          type='number'
                                          onChange={(event) => field.onChange(event.target.value)}
                                       />
                                    }
                                 />
                                 {errors.gross_premium && <ErrorLabel message={errors.gross_premium?.message} />}
                              </fieldset>
                           </div>
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">RM Name&nbsp;<span className="danger">*</span></label>
                                 <Controller
                                    name="user_id"
                                    className="form-control"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={claimsRm}
                                          fields={{ text: 'full_name', value: 'user_id' }}
                                          allowFiltering={false}
                                          placeholder="Select RM Name"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.user_id)
                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'RM Name is required'
                                    }}
                                 />
                                 {errors.user_id && <ErrorLabel message={errors.user_id?.message} />}
                              </div>
                           </div> */}
                           <div class="col-12 col-md-4 col-lg-4">
                              <fieldset className="form-group">
                                 <label className="label-control">Received By</label>
                                 <Controller
                                    name="received_by"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <input
                                          {...field}
                                          className="form-control"
                                          placeholder="Enter Received By"
                                          value={field.value}
                                          onChange={(event) => field.onChange(event.target.value)}
                                       />
                                    }
                                 />
                              </fieldset>
                           </div>
                           <div class="col-12 col-md-12 col-lg-12">
                              <div className="row">
                                 <div class="col-12 col-md-4 col-lg-4">
                                    <div class="form-group">
                                       <button
                                          type="button"
                                          class="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                          onClick={() => { props.history.goBack() }}> Back
                                       </button>
                                       <button id="add_detail" class="btn btn-primary mr-1 shadow round text-uppercase" type="submit">
                                          <div class="d-flex align-items-center">Submit<span id="add_detail-loader" class="spinner-border spinner-border-sm ml-half hidden"></span></div>
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </form>
               </div>
            </div>
            <AddNewCompany
               validateClient={true}
               createClient={true}
               show={addNewCompany}
               handleClose={(newCompany) => {
                  if (newCompany != null) {
                     console.log(JSON.stringify(newCompany));
                     props.actionCompanyGroup();
                     setNewCompany(newCompany);
                  }
                  setAddNewCompany(false);
               }}
            />
         </div>

      </>
   )
}


const mapStateToProps = (state) => {
   var CompanyGroup = null;
   var InsuranceCompany = null;
   var InsuranceBranch = null;
   var ClaimsCustomer = null;
   var ClaimsRm = null;
   var ClaimsPolicyType = null;
   var MandatePolicySave = null;

   if (
      state.MandateAddReducer != null &&
      Object.keys(state.MandateAddReducer).length > 0 &&
      state.MandateAddReducer.constructor === Object
   ) {
      switch (state.MandateAddReducer.tag) {
         case ACTION_CLAIMS_COMPANY_GROUP_SUCCESS:
            CompanyGroup = Object.assign({}, state.MandateAddReducer.data);
            delete state.MandateAddReducer;
            return { CompanyGroup };
         case ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS:
            InsuranceCompany = Object.assign({}, state.MandateAddReducer.data);
            delete state.MandateAddReducer;
            return { InsuranceCompany };
         case ACTION_CLAIMS_INSURANCE_BRANCH_SUCCESS:
            InsuranceBranch = Object.assign({}, state.MandateAddReducer.data);
            delete state.MandateAddReducer;
            return { InsuranceBranch };
         case ACTION_CLAIMS_CUSTOMER_SUCCESS:
            ClaimsCustomer = Object.assign({}, state.MandateAddReducer.data);
            delete state.MandateAddReducer;
            return { ClaimsCustomer };
         case ACTION_CLAIMS_RM_SUCCESS:
            ClaimsRm = Object.assign({}, state.MandateAddReducer.data);
            delete state.MandateAddReducer;
            return { ClaimsRm };
         case ACTION_CLAIMS_POLICY_TYPE_SUCCESS:
            ClaimsPolicyType = Object.assign({}, state.MandateAddReducer.data);
            delete state.MandateAddReducer;
            return { ClaimsPolicyType };
         case ACTION_ADD_MANDATE_POLICY_SUCCESS:
            MandatePolicySave = Object.assign({}, state.MandateAddReducer.data);
            delete state.MandateAddReducer;
            return { MandatePolicySave };
         default:
      }
   }
   return {
      CompanyGroup,
      InsuranceCompany,
      InsuranceBranch,
      ClaimsCustomer,
      ClaimsRm,
      ClaimsPolicyType,
      MandatePolicySave
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         actionCompanyGroup,
         actionInsuranceCompany,
         actionInsuranceBranch,
         actionClaimsCustomer,
         actionClaimsRm,
         actionClaimsPolicyType,
         actionMandatePolicySave
      },
      dispatch
   );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Mandate))