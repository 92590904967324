import React, { useEffect, useState } from 'react';
import MyTeam from './component/my_team';
import MyReporting from './component/my_reporting';
import MyVerticals from './component/my_verticals';
import { verifyPermission } from '../../../../../../utils';

const TabTeam = (props) => {

    const [selectedTab, setSelectedTab] = useState(1);

    // const renderTab = () => { 
    //     switch(selectedTab) { 
    //         case 1:
    //             return (
    //                 <MyTeam {...props} />
    //             )
    //         case 2:
    //             return (
    //                 <MyReporting {...props} />
    //             )
    //         case 3:
    //             return (
    //                 <MyVerticals {...props} />
    //             )
    //         default:
    //             return (
    //                 <MyTeam {...props} />
    //             )
    //     }
    // }
    // useEffect(renderTab,[selectedTab])

    return (
        <>
            <section>
                {/* <div className="mb-1 rounded">
                    <div className="card-content">
                        <div className="card-body px-0 py-0">
                            <ul className="nav nav-tabs nav-underline border-bottom w-100" role="tablist" style={{ backgroundColor: 'transparent' }}>
                                <li className="nav-item col-12 col-md-4 col-lg-2 pl-0 pr-0">
                                    <a className="nav-link d-flex align-items-center justify-content-center active" id="my-team-tab" data-toggle="tab" aria-controls="my-team" role="tab" aria-selected="true"
                                        onClick={() => {
                                            setSelectedTab(1)
                                        }}
                                    >
                                        <span>&nbsp;My Team</span>
                                    </a>
                                </li>
                                <li className="nav-item col-12 col-md-4 col-lg-2 pl-0 pr-0">
                                    <a className="nav-link d-flex align-items-center justify-content-center" id="my-reporting-tab" data-toggle="tab" aria-controls="my-reporting" role="tab" aria-selected="false"
                                        onClick={() => {
                                            setSelectedTab(2)
                                        }}
                                    >
                                        <span>&nbsp;My Reporting</span>
                                    </a>
                                </li>
                                <li className="nav-item col-12 col-md-4 col-lg-2 pl-0 pr-0">
                                    <a className="nav-link d-flex align-items-center justify-content-center" id="my-verticals" data-toggle="tab" aria-controls="my-verticals" role="tab" aria-selected="false"
                                        onClick={() => {
                                            setSelectedTab(3)
                                        }}
                                    >
                                        <span>&nbsp;My Organization</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                <div className="card">
                    <div className="card-body"><MyTeam {...props} /></div>
                </div>
            </section>
        </>


    )
}

export default TabTeam