import createReducer from "../../../../../reducers/createReducer";
import { ACTION_DOCUMENT_LIST_SUCCESS } from './action';
import { ACTION_DELETE_DOCUMENT_SUCCESS } from './action';
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const DocumentListReducer = createReducer(INITIAL_STATE, {
    [ACTION_DOCUMENT_LIST_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_DOCUMENT_LIST_SUCCESS,
            loading: false
        });
    },
    [ACTION_DELETE_DOCUMENT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_DELETE_DOCUMENT_SUCCESS,
            loading: false
        });
    },
});
export default DocumentListReducer;
