import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel
} from "devextreme-react/data-grid";
import { FiEye, FiEdit } from 'react-icons/fi';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import _ from 'lodash';
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { DateBox } from 'devextreme-react/date-box';
import { getDateFilter, verifyPermission } from '../../../../../../utils';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import TeamMembers from '../../../../../../component/popups/team_details';
import { FiFilter } from "react-icons/fi";
import { actiongetMeetings, ACTION_GETMEETINGSLIST_SUCCESS } from './action';
import { actionMyTeamMeeting, ACTION_MY_TEAM_MEETING_SUCCESS } from './action';
import { actionProfileTeamMeetingMember, ACTION_PROFILE_TEAM_MEETING_SUCCESS } from './action';
import Swal from 'sweetalert2';
import moment from 'moment';

const TabMeetings = (props) => {

    const { control } = useForm();
    const [meetingListDetails, setMeetingsListDetails] = useState([]);
    const [myMeetingCount, setMyMeetingCount] = useState(0);
    const [myTeamMeetingCount, setMyTeamMeetingCount] = useState('');
    const [showRMName, setShowRMName] = useState(false);
    const [showTeamMeeting, setShowTeamMeeting] = useState(true);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [myTeamVertical, setMyTeamVertical] = useState([]);
    const [type, setType] = useState("my");
    const [status, setStatus] = useState();
    const [teamStatus, setTeamStatus] = useState();
    const [userIds, setUserIds] = useState([]);
    const [displaySelectedFormatBtn, setDisplaySelectedFormatBtn] = useState(null);
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(90, 'days').format('YYYY-MM-DD'));
    let day = Object.assign({}, getDateFilter('DAY'))
    let reset = Object.assign({}, getDateFilter('RESET'))

    const initialRender = () => {
        if (meetingListDetails.length == 0) {
            $("#progress-loader").removeClass("hidden");
            props.actionProfileTeamMeetingMember({ user_id: props.userId });
            props.actiongetMeetings({
                meeting_type: "all",
                type: 'my',
                initial: 1,
                from_date: fromDate,
                to_date: toDate,
                user_id: props.userId,
            })
        }
    }
    useEffect(initialRender, [props.userId]);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "created_by", caption: "Created By" },
        { dataField: "meeting_type", caption: "Meeting Type" },
        { dataField: "company_name", caption: "Company Name" },
        { dataField: "meeting_date", caption: "Meeting Date" },
        { dataField: "agenda", caption: "Agenda" },
        { dataField: "location", caption: "Location" },
        { dataField: "status", caption: "Status" },
    ]);

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'Open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>Open</div>;
            case 'Close':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>Close</div>;
            case 'Cancel':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>Cancel</div>;
            default:
                return <></>
        }
    }

    const renderMeetingType = (cell) => {
        return _.upperFirst(cell.data.meeting_type);
    }

    const onMeetingListUpdate = () => {
        const { MeetingList } = props;
        if (MeetingList != null) {
            var MeetingListResponse = Object.assign({}, MeetingList);
            if (MeetingListResponse.result === true) {
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "created_by", caption: "Created By" },
                    { dataField: "meeting_type", caption: "Meeting Type" },
                    { dataField: "company_name", caption: "Company Name" },
                    { dataField: "meeting_date", caption: "Meeting Date" },
                    { dataField: "agenda", caption: "Agenda" },
                    { dataField: "location", caption: "Location" },
                    { dataField: "status", caption: "Status" },
                ]);
                setMeetingsListDetails(MeetingListResponse.response);
                setMyMeetingCount(MeetingListResponse.response.length);
                setShowRMName(false);
                setShowTeamMeeting(true);
                $("#progress-loader").addClass("hidden");

            } else {
                $("#progress-loader").addClass("hidden");
                switch (MeetingListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        $("#progress-loader").addClass("hidden");
                        setMeetingsListDetails([]);
                        setMyMeetingCount(0);
                        break;
                    default:
                }
            }
        }
    }

    useEffect(onMeetingListUpdate, [props.MeetingList]);

    const onMyTeamMeetingUpdate = () => {
        const { MyTeamMeeting } = props;
        if (MyTeamMeeting != null) {
            var MyTeamMeetingResponse = Object.assign({}, MyTeamMeeting);
            setStatus(MyTeamMeetingResponse.status);
            if (MyTeamMeetingResponse.result === true) {
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "created_by", caption: "Created By" },
                    { dataField: "meeting_type", caption: "Meeting Type" },
                    { dataField: "company_name", caption: "Company Name" },
                    { dataField: "meeting_date", caption: "Meeting Date" },
                    { dataField: "agenda", caption: "Agenda" },
                    { dataField: "location", caption: "Location" },
                    { dataField: "status", caption: "Status" },
                ]);
                setMeetingsListDetails(MyTeamMeetingResponse.response);
                setMyTeamMeetingCount(MyTeamMeetingResponse.response.length);
                setMyMeetingCount(0);
                setShowRMName(false);
                setShowTeamMeeting(false);
                $("#progress-loader").addClass("hidden");

            } else {
                $("#progress-loader").addClass("hidden");
                switch (MyTeamMeetingResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        $("#progress-loader").addClass("hidden");
                        setMeetingsListDetails([]);
                        setMyTeamMeetingCount(0);
                    default:
                }
            }
        }
    }

    useEffect(onMyTeamMeetingUpdate, [props.MyTeamMeeting]);

    const onProfileTeamMeetingMemberUpdate = () => {
        const { ProfileTeamMeetingMember } = props;
        if (ProfileTeamMeetingMember != null) {
            var ProfileTeamMeetingMemberResponse = Object.assign({}, ProfileTeamMeetingMember);
            setTeamStatus(ProfileTeamMeetingMemberResponse.status);
            if (ProfileTeamMeetingMemberResponse.result === true) {
                setShowTeamMeeting(true)
                var myTeam = []
                var myTeamMember = []
                ProfileTeamMeetingMemberResponse.response.map((item) => {
                    if (item.id != props.userId) {
                        myTeam.push(item);
                        myTeamMember.push(item.id);
                        setMyTeamVertical(myTeam);
                        setMyTeamMembers(myTeamMember);
                    }
                })

            } else {
                setShowTeamMeeting(true)
                switch (ProfileTeamMeetingMemberResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onProfileTeamMeetingMemberUpdate, [props.ProfileTeamMeetingMember]);

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    // view all customer meeting permission verify
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm float-left"
                                    onClick={() => {
                                        switch (params.data.meeting_type) {
                                            case 'Internal':
                                                props.history.push({ pathname: `/meetings/internal-meetings/view`, state: { internal_meeting_id: params.data.id } });
                                                break;
                                            case 'Tpa':
                                                props.history.push({ pathname: `/meetings/tpa-meetings/view`, state: { tpa_meeting_id: params.data.id } })
                                                break;
                                            case 'Surveyor':
                                                props.history.push({ pathname: `/meetings/serveyor-meetings/view`, state: { serveyor_meeting_id: params.data.id } })
                                                break;
                                            case 'Lead':
                                            case 'Client':
                                                props.history.push({ pathname: `/meetings/customer-meetings/view`, state: { customer_meeting: params.data } })
                                                break;
                                            case 'Insurer':
                                                props.history.push({ pathname: `/meetings/insurance-company-meetings/view`, state: { insurer_branch_id: params.data.id } })
                                                break;
                                            case 'Reinsurance':
                                                props.history.push({ pathname: `/meetings/ri-meetings/view`, state: { ri_meeting: params.data.id } })
                                                break;
                                        }
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                props.auth.userId === params.data.created_by_id ?
                                    <button
                                        className={`btn btn-icon btn-sm ${params.data != null && (params.data.status === 'Close' || params.data.status === 'Cancel') ? 'hidden' : ''}`}
                                        onClick={() => {
                                            switch (params.data.meeting_type) {
                                                case 'Internal':
                                                    props.history.push({ pathname: `/meetings/internal-meetings/edit`, state: { internal_meeting_id: params.data.id } });
                                                    break;
                                                case 'Tpa':
                                                    props.history.push({ pathname: `/meetings/tpa-meetings/edit`, state: { tpa_meeting_id: params.data.id } })
                                                    break;
                                                case 'Surveyor':
                                                    props.history.push({ pathname: `/meetings/serveyor-meetings/edit`, state: { serveyor_meeting_id: params.data.id } })
                                                    break;
                                                case 'Lead':
                                                case 'Client':
                                                    props.history.push({ pathname: `/meetings/customer-meetings/edit`, state: { customer_meeting: params.data } })
                                                    break;
                                                case 'Insurer':
                                                    props.history.push({ pathname: `/meetings/insurance-company-meetings/edit`, state: { insurer_branch_id: params.data.id } })
                                                    break;
                                                case 'Reinsurance':
                                                    props.history.push({ pathname: `/meetings/ri-meetings/edit`, state: { ri_meeting: params.data.id } })
                                                    break;
                                            }
                                        }}
                                    >
                                        <FiEdit size={16} className="primary" />
                                    </button>
                                    :
                                    <> </>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else if (objColumn.dataField === "meeting_type") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderMeetingType}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    useEffect(() => {
        return () => {
            $("#progress-loader").addClass("hidden");
        }
    }, [])

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        {
                            verifyPermission("VIEW_ALL_CUSTOMER_MEETING") ?
                                <li class="nav-item">
                                    <button
                                        id="btn-my-meeting"
                                        className={`btn ${type === "my" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setDisplaySelectedFormatBtn(null);
                                            setType("my");
                                            setShowTeamMeeting(false);
                                            setShowRMName(false);
                                            setMyTeamMeetingCount(0);
                                            setFromDate(moment(new Date()).subtract(90, 'days').format('YYYY-MM-DD'))
                                            setToDate(moment(new Date()).format('YYYY-MM-DD'))
                                            props.actiongetMeetings({
                                                meeting_type: "all",
                                                type: "my",
                                                initial: 1,
                                                from_date: reset.start,
                                                to_date: reset.end,
                                                user_id: props.userId,
                                            });
                                            props.actionProfileTeamMeetingMember({ user_id: props.userId });
                                        }}
                                    >
                                        My Meetings {myMeetingCount > 0 ? `(` + myMeetingCount + `)` : ``}
                                    </button>
                                </li>
                                :
                                <></>
                        }
                        {
                            verifyPermission("VIEW_ALL_CUSTOMER_MEETING") && verifyPermission("SHOW_TEAM") && teamStatus !== 402 ?
                                <li class="nav-item ml-1">
                                    <button
                                        id="btn-my-team-meeting"
                                        className={`btn ${type === "team" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                        onClick={() => {
                                            setType("team");
                                            setFromDate(moment(new Date()).subtract(90, 'days').format('YYYY-MM-DD'))
                                            setToDate(moment(new Date()).format('YYYY-MM-DD'))
                                            setDisplaySelectedFormatBtn(null);
                                            setMyMeetingCount(0);
                                            if (showTeamMeeting != true) {
                                                if (showRMName === true) {
                                                    setShowRMName(false);
                                                } else {
                                                    setShowRMName(true);
                                                }
                                            }
                                            if (myTeamMembers != null && myTeamMembers.length > 0 && showTeamMeeting === true) {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionMyTeamMeeting({
                                                    meeting_type: "all",
                                                    user_id: myTeamMembers,
                                                    type: "team",
                                                    initial: 1,
                                                    from_date: reset.start,
                                                    to_date: reset.end,
                                                })
                                            }
                                        }}
                                    >
                                        Team Meetings <span className='mr-half'>{status !== 402 ? (myTeamMeetingCount > 0 ? `(` + myTeamMeetingCount + `)` : '') : ``}</span>
                                        <FiFilter size={16} />
                                    </button>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={fromDate}
                                                        onValueChange={(value) => {
                                                            var objFromDate = new Date(value);
                                                            var objToDate = new Date(toDate);

                                                            if (objToDate.getTime() < objFromDate.getTime()) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "From date can't be greater than To date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                $("#progress-loader").removeClass("hidden");
                                                                setDisplaySelectedFormatBtn(null);
                                                                field.onChange(moment(value).format('yyyy-MM-DD'));

                                                                var strFromDate = moment(value).format('yyyy-MM-DD')
                                                                setFromDate(strFromDate);

                                                                if (type === 'team') {
                                                                    props.actionMyTeamMeeting({
                                                                        meeting_type: "all",
                                                                        type: type,
                                                                        initial: 0,
                                                                        from_date: strFromDate,
                                                                        to_date: toDate,
                                                                        user_id: myTeamMembers
                                                                    });
                                                                } else {
                                                                    props.actiongetMeetings({
                                                                        meeting_type: "all",
                                                                        type: type,
                                                                        initial: 0,
                                                                        from_date: strFromDate,
                                                                        to_date: toDate,
                                                                        user_id: props.userId,
                                                                    });
                                                                }
                                                            }
                                                        }
                                                        }
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <Controller
                                                name="to_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={toDate}
                                                        onValueChange={(value) => {
                                                            var objFromDate = new Date(fromDate);
                                                            var objToDate = new Date(value);

                                                            if (objToDate.getTime() < objFromDate.getTime()) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "To date must be greater than from date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                $("#progress-loader").removeClass("hidden");
                                                                setDisplaySelectedFormatBtn(null);
                                                                field.onChange(moment(value).format('yyyy-MM-DD'));

                                                                var strToDate = moment(value).format('yyyy-MM-DD');
                                                                setToDate(strToDate);

                                                                if (type === 'team') {
                                                                    props.actionMyTeamMeeting({
                                                                        meeting_type: "all",
                                                                        type: type,
                                                                        initial: 0,
                                                                        from_date: fromDate,
                                                                        to_date: strToDate,
                                                                        user_id: myTeamMembers
                                                                    });
                                                                } else {
                                                                    props.actiongetMeetings({
                                                                        meeting_type: "all",
                                                                        type: type,
                                                                        initial: 0,
                                                                        from_date: fromDate,
                                                                        to_date: strToDate,
                                                                        user_id: props.userId,
                                                                    });
                                                                }
                                                            }
                                                        }
                                                        }
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-2 my-md-auto">
                                <div className='row justify-content-end'>
                                    <div className="col-12 col-md-auto">
                                        <div class="form-group ">
                                            <div class="btn-group btn-group-sm" role="group">
                                                <button type="button"
                                                    className={`btn ${displaySelectedFormatBtn == 'Day' ? 'btn-primary' : 'btn-outline-primary'} border-right-0`}
                                                    onClick={() => {
                                                        $("#progress-loader").removeClass("hidden");
                                                        setDisplaySelectedFormatBtn('Day');
                                                        setFromDate(day.start);
                                                        setToDate(day.end);
                                                        if (type === 'team') {
                                                            props.actionMyTeamMeeting({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: day.start,
                                                                to_date: day.end,
                                                                user_id: myTeamMembers
                                                            });
                                                        } else {
                                                            props.actiongetMeetings({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: day.start,
                                                                to_date: day.end,
                                                                user_id: props.userId,
                                                            });
                                                        }
                                                    }}
                                                >DAY</button>
                                                <button type="button"
                                                    className={`btn ${displaySelectedFormatBtn == 'Week' ? 'btn-primary' : 'btn-outline-primary'}`}
                                                    onClick={() => {
                                                        $("#progress-loader").removeClass("hidden");
                                                        setDisplaySelectedFormatBtn('Week');
                                                        let week = Object.assign({}, getDateFilter('WEEK'))
                                                        setFromDate(week.start);
                                                        setToDate(week.end);
                                                        if (type === 'team') {
                                                            props.actionMyTeamMeeting({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: week.start,
                                                                to_date: week.end,
                                                                user_id: myTeamMembers
                                                            });
                                                        } else {
                                                            props.actiongetMeetings({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: week.start,
                                                                to_date: week.end,
                                                                user_id: props.userId,
                                                            });
                                                        }
                                                    }}
                                                >WEEK</button>
                                                <button type="button"
                                                    className={`btn ${displaySelectedFormatBtn == 'MONTH' ? 'btn-primary' : 'btn-outline-primary'} border-right-0`}
                                                    onClick={() => {
                                                        $("#progress-loader").removeClass("hidden");
                                                        setDisplaySelectedFormatBtn('MONTH');
                                                        let month = Object.assign({}, getDateFilter('MONTH'))
                                                        setFromDate(month.start);
                                                        setToDate(month.end);
                                                        if (type === 'team') {
                                                            props.actionMyTeamMeeting({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: month.start,
                                                                to_date: month.end,
                                                                user_id: myTeamMembers
                                                            });
                                                        } else {
                                                            props.actiongetMeetings({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: month.start,
                                                                to_date: month.end,
                                                                user_id: props.userId,
                                                            });
                                                        }
                                                    }}
                                                >MONTH</button>
                                                <button type="button"
                                                    className={`btn ${displaySelectedFormatBtn == 'YEAR' ? 'btn-primary' : 'btn-outline-primary'} `}
                                                    onClick={() => {
                                                        $("#progress-loader").removeClass("hidden");
                                                        setDisplaySelectedFormatBtn('YEAR');
                                                        let year = Object.assign({}, getDateFilter('YEAR'))
                                                        setFromDate(year.start);
                                                        setToDate(year.end);
                                                        if (type === 'team') {
                                                            props.actionMyTeamMeeting({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: year.start,
                                                                to_date: year.end,
                                                                user_id: myTeamMembers
                                                            });
                                                        } else {
                                                            props.actiongetMeetings({
                                                                type: type,
                                                                meeting_type: "all",
                                                                initial: 0,
                                                                from_date: year.start,
                                                                to_date: year.end,
                                                                user_id: props.userId,
                                                            });
                                                        }

                                                    }}
                                                >YEAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-auto">
                                        <button
                                            className="btn btn-sm btn-primary round text-uppercase"
                                            type="button"
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setDisplaySelectedFormatBtn(null);
                                                setFromDate(reset.start);
                                                setToDate(reset.end);
                                                if (type === 'team') {
                                                    props.actionMyTeamMeeting({
                                                        type: type,
                                                        meeting_type: "all",
                                                        initial: 1,
                                                        from_date: reset.start,
                                                        to_date: reset.end,
                                                        user_id: myTeamMembers
                                                    });
                                                } else {
                                                    props.actiongetMeetings({
                                                        type: type,
                                                        meeting_type: "all",
                                                        initial: 1,
                                                        from_date: reset.start,
                                                        to_date: reset.end,
                                                        user_id: props.userId,
                                                    });
                                                }
                                            }}
                                        >Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={meetingListDetails}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    export={{
                                        fileName: "Meetings",
                                        enabled: meetingListDetails != null && meetingListDetails.length > 0 ? true : false,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />

                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TeamMembers
                {...props}
                show={showRMName}
                myTeamVertical={myTeamVertical}
                myTeamMembers={userIds != null && userIds.length > 0 ? userIds : myTeamMembers}
                handleClose={(teamMember) => {
                    if (teamMember != null) {
                        setUserIds(teamMember);
                        props.actionMyTeamMeeting({
                            meeting_type: "all",
                            user_id: teamMember,
                            type: "team",
                            initial: 1,
                            from_date: reset.start,
                            to_date: reset.end,
                        });
                    } else {
                        $("#progress-loader").addClass("hidden");
                    }
                    setShowRMName(false);
                }}
            />
        </>

    )
}

function mapStateToProps(state) {
    var MeetingList = null;
    var MyTeamMeeting = null;
    var ProfileTeamMeetingMember = null;

    if (
        state.MeetingListReducer != null &&
        Object.keys(state.MeetingListReducer).length > 0 &&
        state.MeetingListReducer.constructor === Object
    ) {
        switch (state.MeetingListReducer.tag) {
            case ACTION_GETMEETINGSLIST_SUCCESS:
                MeetingList = Object.assign({}, state.MeetingListReducer.data);
                delete state.MeetingListReducer;
                return { MeetingList };
            case ACTION_MY_TEAM_MEETING_SUCCESS:
                MyTeamMeeting = Object.assign({}, state.MeetingListReducer.data);
                delete state.MeetingListReducer;
                return { MyTeamMeeting };
            case ACTION_PROFILE_TEAM_MEETING_SUCCESS:
                ProfileTeamMeetingMember = Object.assign({}, state.MeetingListReducer.data);
                delete state.MeetingListReducer;
                return { ProfileTeamMeetingMember };
            default:
        }
    }
    return {
        MeetingList,
        MyTeamMeeting,
        ProfileTeamMeetingMember
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actiongetMeetings, actionMyTeamMeeting, actionProfileTeamMeetingMember }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TabMeetings));
