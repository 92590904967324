import {
    ACTION_CALENDER_EVENT_SUCCESS,
    ACTION_CALENDER_EVENT_DETAILS_SUCCESS,
    ACTION_MY_TEAM_VERTICAL_SUCCESS,
    ACTION_CALENDER_TEAM_EVENT_SUCCESS,
    ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS,
    ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS
} from "./action";

import createReducer from "../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const CalenderReducer = createReducer(INITIAL_STATE, {
    [ACTION_CALENDER_EVENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CALENDER_EVENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CALENDER_EVENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CALENDER_EVENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MY_TEAM_VERTICAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MY_TEAM_VERTICAL_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CALENDER_TEAM_EVENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CALENDER_TEAM_EVENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS,
                loading: false,
            }
        );
    },
});

export default CalenderReducer;