import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { API_TRAINING_REPORT } from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";

export const ACTION_TRAINING_REPORT_SUCCESS = 'ACTION_TRAINING_REPORT_SUCCESS'
export const ACTION_TRAINING_REPORT_LOADING = 'ACTION_TRAINING_REPORT_LOADING'

export function actionTrainingReport(params) {
    return (dispatch, getState) => {
        Api.post(API_TRAINING_REPORT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_TRAINING_REPORT_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAINING_REPORT_LOADING, { loading: false, data }))
        })
    };
}