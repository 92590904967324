import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../../component/screen_title';

const LeadClientMeetingView = (props) => {

    const [leadClientMeetingDetails, setLeadClientMeetingDetails] = useState(null);

    const initialRender = () => {
        if (props.location.state.details != null) {
            setLeadClientMeetingDetails(props.location.state.details);
        }

    }
    useEffect(initialRender, []);

    return (
        <>
            <ScreenTitle title="Unattended Client/ Lead Report Details" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Customer Group</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.company_group_name != null ?
                                            leadClientMeetingDetails.company_group_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Client Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.name != null ?
                                            leadClientMeetingDetails.name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Client Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.client_type != null ?
                                            leadClientMeetingDetails.client_type : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">RM Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.rm_name != null ?
                                            leadClientMeetingDetails.rm_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">RM Reporting To</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.rm_reporting_person_name != null ?
                                            leadClientMeetingDetails.rm_reporting_person_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Assigned Retention RM</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.retention_name != null ?
                                            leadClientMeetingDetails.retention_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Retention RM Reporting To</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.retention_reporting_person_name != null ?
                                            leadClientMeetingDetails.retention_reporting_person_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">No Activity Days</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.no_activity_days != null ?
                                            leadClientMeetingDetails.no_activity_days : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Last Meeting</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.last_meeting != null ?
                                            leadClientMeetingDetails.last_meeting : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Last Meeting created by</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.last_meeting_created_by != null ?
                                            leadClientMeetingDetails.last_meeting_created_by : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Last meeting accompanied by</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.last_meeting_accompanied_by != null ?
                                            leadClientMeetingDetails.last_meeting_accompanied_by : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Last meeting agenda</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        leadClientMeetingDetails != null && leadClientMeetingDetails.last_meeting_agenda != null ?
                                            leadClientMeetingDetails.last_meeting_agenda : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default LeadClientMeetingView