import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import $ from 'jquery';
import { linkify } from '../../../../../../utils';
import HTMLParser from 'html-react-parser';
import { actionBrokerTrainingDetail, ACTION_BROKER_TRAINING_DETAIL_SUCCESS } from '../../../../../../routes/app/auth/permission/hrms_setting/view/action';

const MeetingTrainingDetail = (props) => {

    const [trainingSettingDetails, setTrainingSettingDetails] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        const idValue = new URLSearchParams(props?.history?.location?.search).get('id');
        if (idValue != null) {
            props.actionBrokerTrainingDetail({ id: idValue });
        } else {
            props.actionBrokerTrainingDetail({ id: props.location.state.id });
        }
    }
    useEffect(initialRender, []);

    const onBrokerTrainingDetailUpdate = () => {
        const { BrokerTrainingDetail } = props;
        var BrokerTrainingDetailResponse = Object.assign({}, BrokerTrainingDetail);
        if (BrokerTrainingDetailResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setTrainingSettingDetails(BrokerTrainingDetailResponse.response);
        } else {

            switch (BrokerTrainingDetailResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    $("#progress-loader").addClass("hidden");
                    break;
                default:
            }
        }
    }
    useEffect(onBrokerTrainingDetailUpdate, [props.BrokerTrainingDetail]);

    const renderTrainingDetail = (detail) => {
        try {
            var formatText = detail
            if (trainingSettingDetails != null && trainingSettingDetails?.training_details != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }

    }

    return (
        <>
            <div className="content-header col-12 col-md-7 mb-1 pl-0">
                <h5 className="content-header-title text-capitalize">Hall of IVY Details</h5>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="flex-column">
                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                            {
                                                trainingSettingDetails != null && trainingSettingDetails?.start_date_exact != null ?
                                                    moment(trainingSettingDetails?.start_date_exact).format("DD") : ''
                                            }
                                        </h1>
                                    </div>
                                    <div className="flex-column">
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                trainingSettingDetails != null && trainingSettingDetails?.start_date_exact != null ?
                                                    moment(trainingSettingDetails?.start_date_exact).format("MMM") : ''
                                            }
                                        </h6>
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                trainingSettingDetails != null && trainingSettingDetails?.start_date_exact != null ?
                                                    moment(trainingSettingDetails?.start_date_exact).format("YYYY") : ''
                                            }
                                        </h6>
                                    </div>
                                    <div className="flex-column px-1">-</div>
                                    <div className="flex-column">
                                        <h1 className="font-large-2 text-bold-700 my-0 mr-half">
                                            {
                                                trainingSettingDetails != null && trainingSettingDetails?.end_date_exact != null ?
                                                    moment(trainingSettingDetails?.end_date_exact).format("DD") : ''
                                            }
                                        </h1>
                                    </div>
                                    <div className="flex-column">
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                trainingSettingDetails != null && trainingSettingDetails?.end_date_exact != null ?
                                                    moment(trainingSettingDetails?.end_date_exact).format("MMM") : ''
                                            }
                                        </h6>
                                        <h6 className="font-medium-1 text-bold-500 my-0">
                                            {
                                                trainingSettingDetails != null && trainingSettingDetails?.end_date_exact != null ?
                                                    moment(trainingSettingDetails?.end_date_exact).format("YYYY") : ''
                                            }
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 d-none d-md-flex d-lg-flex justify-content-start justify-content-md-end justify-content-lg-end align-items-center">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Status:</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify ml-1">
                                    {
                                        trainingSettingDetails != null && trainingSettingDetails?.status != null ?
                                            _.capitalize(trainingSettingDetails?.status) : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mt-1">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            trainingSettingDetails != null && trainingSettingDetails?.start_time != null ?
                                                trainingSettingDetails?.start_time : ''
                                        }
                                    </div>
                                    <div className="flex-column px-1">to</div>
                                    <div className="flex-column bg-blue-grey bg-lighten-4 rounded px-1">
                                        {
                                            trainingSettingDetails != null && trainingSettingDetails?.end_time != null ?
                                                trainingSettingDetails?.end_time : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 d-none d-md-flex d-lg-flex justify-content-start justify-content-md-end justify-content-lg-end align-items-center">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Participation Status:</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify ml-1">
                                    {
                                        trainingSettingDetails != null && trainingSettingDetails?.participation_status != null ?
                                            trainingSettingDetails?.participation_status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Type of Training</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        trainingSettingDetails != null && trainingSettingDetails?.training_type != null ?
                                            trainingSettingDetails?.training_type : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Training Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        trainingSettingDetails != null && trainingSettingDetails?.training_name != null ?
                                            trainingSettingDetails?.training_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Training Mode</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        trainingSettingDetails != null && trainingSettingDetails?.training_mode != null ?
                                            trainingSettingDetails?.training_mode : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Training Details</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        trainingSettingDetails != null && trainingSettingDetails?.training_details != null ?
                                            renderTrainingDetail(trainingSettingDetails?.training_details) : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Speaker Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        trainingSettingDetails != null && trainingSettingDetails?.speaker_name != null ?
                                            trainingSettingDetails?.speaker_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var BrokerTrainingDetail = null;
    if (
        state.BrokerTrainingDetailReducer != null &&
        Object.keys(state.BrokerTrainingDetailReducer).length > 0 &&
        state.BrokerTrainingDetailReducer.constructor === Object
    ) {
        switch (state.BrokerTrainingDetailReducer.tag) {
            case ACTION_BROKER_TRAINING_DETAIL_SUCCESS:
                BrokerTrainingDetail = Object.assign({}, state.BrokerTrainingDetailReducer.data);
                delete state.BrokerTrainingDetailReducer;
                return { BrokerTrainingDetail };
            default:

        }
    }
    return {
        BrokerTrainingDetail
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionBrokerTrainingDetail }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MeetingTrainingDetail)) 