import React, { useState, useEffect } from 'react';
import Bar from '../../../view/chart_details';
import { DateBox } from 'devextreme-react/date-box';
import { useForm, Controller } from "react-hook-form";
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../../constants';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FaRegHandshake } from 'react-icons/fa';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { FiShield } from 'react-icons/fi';
import { actionGroupBusinessSummary, ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS } from './action';
import moment from 'moment';
import $ from 'jquery'
import { toast } from 'react-toastify';
import { withAuth } from 'react-auth-guard';

const GroupBusinessSummary = (props) => {

    const { control } = useForm({});

    const [groupBusinessSummaryDetails, setGroupBusinessSummaryDetails] = useState(null);

    const [fromDate, setFromDate] = useState(moment().month() < 3
        ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("yyyy-MM-DD")
        : moment()
            .month(3)
            .startOf("month")
            .format("yyyy-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionGroupBusinessSummary({
            client_group_id: props.clientGroupId,
            from_date: fromDate,
            to_date: toDate
        });
    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupBusinessSummaryUpdate = () => {
        const { GroupBusinessSummary } = props;
        if (GroupBusinessSummary != null) {
            var GroupBusinessSummaryResponse = Object.assign({}, GroupBusinessSummary);
            if (GroupBusinessSummaryResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setGroupBusinessSummaryDetails(GroupBusinessSummaryResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (GroupBusinessSummaryResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:

                }
            }
        }
    }
    useEffect(onGroupBusinessSummaryUpdate, [props.GroupBusinessSummary]);

    return (
        <>
            <div>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="row justify-content-between align-items-center mb-2 border-bottom">
                            <h3 className='content-header-left text-bold-500 col-12 col-md-8 col-lg-9'>
                                Business Summary
                            </h3>
                            <div className="col-12 col-md-4 col-lg-3">
                                <button
                                    className="btn btn-sm shadow btn-primary py-half mb-1 round text-uppercase float-right"
                                    type="submit"
                                    onClick={() => {
                                        $("#progress-loader").removeClass("hidden");
                                        props.actionGroupBusinessSummary({
                                            client_group_id: props.clientGroupId,
                                            from_date: moment().month(3).startOf('month').subtract(1, 'year').format('yyyy-MM-DD'),
                                            to_date: moment().month(2).endOf('month').format('yyyy-MM-DD'),
                                        });
                                        setFromDate(moment().month(3).startOf('month').subtract(1, 'year').format('yyyy-MM-DD'))
                                        setToDate(moment().month(2).endOf('month').format('yyyy-MM-DD'))
                                    }}
                                >
                                    Show Previous FY(22-23)
                                </button>
                            </div>
                        </div>
                        <div className="row  py-1  mx-half">
                            <div className="col-12 col-md-3">
                                <div className="form-group mb-0">
                                    <Controller
                                        name="from_date"
                                        control={control}
                                        render={({ field }) =>
                                            <DateBox
                                                calendarOptions={{
                                                    showTodayButton: true,
                                                }}
                                                acceptCustomValue={false}
                                                stylingMode="underlined"
                                                pickerType="calendar"
                                                placeholder="From Date"
                                                type="date"
                                                displayFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                value={fromDate}
                                                onValueChange={(value) => {
                                                    field.onChange(moment(value).format('yyyy-MM-DD'));
                                                    setFromDate(moment(value).format('yyyy-MM-DD'));
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group mb-0">
                                    <Controller
                                        name="to_date"
                                        control={control}
                                        render={({ field }) =>
                                            <DateBox
                                                calendarOptions={{
                                                    showTodayButton: true,
                                                }}
                                                acceptCustomValue={false}
                                                stylingMode="underlined"
                                                pickerType="calendar"
                                                placeholder="To Date"
                                                type="date"
                                                displayFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                value={toDate}
                                                onValueChange={(value) => {
                                                    field.onChange(moment(value).format('yyyy-MM-DD'));
                                                    setToDate(moment(value).format('yyyy-MM-DD'));
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-lg-2 my-auto">
                                <button
                                    className="btn btn-primary py-half mr-1 shadow round text-uppercase mt-1 mt-md-0"
                                    type="submit"
                                    onClick={() => {
                                        if (fromDate.length > 0 && toDate.length > 0) {
                                            $("#progress-loader").removeClass("hidden");
                                            props.actionGroupBusinessSummary({
                                                client_group_id: props.clientGroupId,
                                                from_date: moment(fromDate).format('yyyy-MM-DD'),
                                                to_date: moment(toDate).format('yyyy-MM-DD')
                                            });
                                        } else {
                                            Swal.fire({
                                                title: 'Error',
                                                icon: 'error',
                                                html: 'Please Select From Date & To Date',
                                                confirmButtonColor: PRIMARY_COLOR
                                            }).then((result) => {

                                            });
                                        }
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div className="row bg-primary-shadow rounded bg-accent-2 py-1 mt-2 mx-half">
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        groupBusinessSummaryDetails.no_of_policies : '0'
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Number Of Policies</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        Number(groupBusinessSummaryDetails.brokerage_details.current).toFixed(2) : '0'
                                                }&nbsp;
                                                {
                                                    groupBusinessSummaryDetails != null && groupBusinessSummaryDetails.brokerage_details.current_unit != null ?
                                                        groupBusinessSummaryDetails.brokerage_details.current_unit : ''
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Current Year Brokerage</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        Number(groupBusinessSummaryDetails.brokerage_details.previous).toFixed(2) : '0'
                                                }&nbsp;
                                                {
                                                    groupBusinessSummaryDetails != null && groupBusinessSummaryDetails.brokerage_details.previous_unit != null ?
                                                        groupBusinessSummaryDetails.brokerage_details.previous_unit : ''
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Previous Year Brokerage</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row bg-primary-shadow rounded bg-accent-2 py-1 mt-2 mx-half">
                            <div className="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                    <div className="flex-column">
                                        <span className="primary d-flex align-items-center justify-content-center p-1">
                                            <FiShield size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        groupBusinessSummaryDetails.no_of_proposal : '0'
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Number Of Opportunity</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column">
                                        <span className="primary d-flex align-items-center justify-content-center p-1">
                                            <FaRegHandshake size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        groupBusinessSummaryDetails.no_of_meetings : '0'
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Number Of Meeting</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                    <div className="flex-column">
                                        <span className="primary d-flex align-items-center justify-content-center p-1">
                                            <IoDocumentAttachOutline size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        groupBusinessSummaryDetails.no_of_claims : '0'
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Number Of Claims</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row bg-primary-shadow rounded bg-accent-2 py-1 mt-2 mx-half">
                            <div className="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                    <div className="flex-column">
                                        <span className="primary d-flex align-items-center justify-content-center p-1">
                                            <HiOutlineCurrencyRupee size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        groupBusinessSummaryDetails.total_brokerage_amount : '0'
                                                }&nbsp;
                                                {
                                                    groupBusinessSummaryDetails != null && groupBusinessSummaryDetails.total_brokerage != null && groupBusinessSummaryDetails.total_brokerage_unit != null ?
                                                        `(${groupBusinessSummaryDetails.total_brokerage + ' ' + groupBusinessSummaryDetails.total_brokerage_unit})` : ''
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Brokerage</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                    <div className="flex-column">
                                        <span className="primary d-flex align-items-center justify-content-center p-1">
                                            <HiOutlineCurrencyRupee size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        groupBusinessSummaryDetails.total_premium_amount : '0'
                                                }&nbsp;
                                                {
                                                    groupBusinessSummaryDetails != null && groupBusinessSummaryDetails.total_premium != null && groupBusinessSummaryDetails.total_premium_unit != null ?
                                                        `(${groupBusinessSummaryDetails.total_premium + ' ' + groupBusinessSummaryDetails.total_premium_unit})` : ''
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Premium</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-sm-6 col-12">
                                <div className="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                    <div className="flex-column">
                                        <span className="primary d-flex align-items-center justify-content-center p-1">
                                            <HiOutlineCurrencyRupee size={28} />
                                        </span>
                                    </div>
                                    <div className="flex-column ml-1">
                                        <div className="stats-amount">
                                            <h3 className="heading-text text-bold-600">
                                                {
                                                    groupBusinessSummaryDetails != null ?
                                                        groupBusinessSummaryDetails.total_claim_amount : '0'
                                                }&nbsp;
                                                {
                                                    groupBusinessSummaryDetails != null && groupBusinessSummaryDetails.total_claim != null && groupBusinessSummaryDetails.total_claim_unit != null ?
                                                        `(${groupBusinessSummaryDetails.total_claim + ' ' + groupBusinessSummaryDetails.total_claim_unit})` : ''
                                                }
                                            </h3>
                                            <p className="sub-heading mb-0 font-small-3">Claim Amount</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {groupBusinessSummaryDetails != null && groupBusinessSummaryDetails?.no_of_proposal === 0 &&
                    groupBusinessSummaryDetails?.no_of_meetings === 0 && groupBusinessSummaryDetails?.no_of_claims === 0 &&
                    groupBusinessSummaryDetails?.total_brokerage === 0 && groupBusinessSummaryDetails?.total_premium === 0 &&
                    groupBusinessSummaryDetails?.total_claim === 0 ?
                    <></>
                    :
                    <div className="card shadow mt-1">
                        <div className="card-body pt-0">
                            <Bar details={groupBusinessSummaryDetails} />
                        </div>
                    </div>
                }

            </div>
        </>
    )

}

function mapStateToProps(state) {
    var GroupBusinessSummary = null;

    if (
        state.TabGroupBusinessSummaryReducer != null &&
        Object.keys(state.TabGroupBusinessSummaryReducer).length > 0 &&
        state.TabGroupBusinessSummaryReducer.constructor === Object
    ) {
        switch (state.TabGroupBusinessSummaryReducer.tag) {
            case ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS:
                GroupBusinessSummary = Object.assign({}, state.TabGroupBusinessSummaryReducer.data);
                delete state.TabGroupBusinessSummaryReducer;
                return { GroupBusinessSummary };
            default:
        }
    }
    return {
        GroupBusinessSummary
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupBusinessSummary }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupBusinessSummary));