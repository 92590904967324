import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import CustomerMeetingsView from '../../../../../../../component/popups/meetings/customer';
import { FiEye } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionClientTabMeetingsView, ACTION_CLIENTTABMEETINGSVIEW_SUCCESS } from './action';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';

const ClientTabMeetingsView = (props) => {
    const [clientMeetingData, setClientMeetingData] = useState([]);
    const [showCustomerMeetingView, setShowCustomerMeetingView] = useState(false);
    const [customerMeetingId, setCustomerMeetingId] = useState(null);


    const [meetingColumn, setMeetingColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "created_by_name", caption: "Created By" },
        { dataField: "meeting_date", caption: "Meeting Date" },
        { dataField: "business_type", caption: "Meeting Type" },
        { dataField: "location", caption: "Location" },
        { dataField: "status", caption: "Status" },
    ]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionClientTabMeetingsView({
            client_id: props.clientId
        })
    }
    useEffect(initialRender, [props.clientId]);

    const onClientTabMeetingsViewUpdate = () => {

        const { ClientTabMeetingsView } = props;

        if (ClientTabMeetingsView != null) {
            var ClientTabMeetingsViewResponse = Object.assign({}, ClientTabMeetingsView);
            if (ClientTabMeetingsViewResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setMeetingColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "created_by_name", caption: "Created By" },
                    { dataField: "meeting_date", caption: "Meeting Date" },
                    { dataField: "business_type", caption: "Meeting Type" },
                    { dataField: "location", caption: "Location" },
                    { dataField: "status", caption: "Status" },
                ])
                setClientMeetingData(ClientTabMeetingsViewResponse.response);

            } else {
                setClientMeetingData([])
                $("#progress-loader").addClass("hidden");
                switch (ClientTabMeetingsViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }

            }
        }
    }


    useEffect(onClientTabMeetingsViewUpdate, [props.ClientTabMeetingsView]);
    const renderStatusCell = (cell) => {
        console.log(cell);
        switch (cell.data.status) {
            case 'Open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>Open</div>;
            case 'Close':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>Close</div>;
            case 'Cancel':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>Cancel</div>;
            default:
                return <></>
        }
    }
    const renderMeetingColumn = () => {
        var arrColumns = [];
        meetingColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1"
                                    onClick={() => {
                                        setShowCustomerMeetingView(true)
                                        setCustomerMeetingId(params.data.meeting_id);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };
    const renderMeetingTableView = () => {
        return (
            <DataGrid
                dataSource={clientMeetingData}
                columnHidingEnabled={false}
                showBorders={true}
                export={{
                    fileName: "meetings",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderMeetingColumn()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />

            </DataGrid>
        )
    }
    return (
        <>
            <div className="tab-pane" id="meetings" aria-labelledby="meetings-tab" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="row my-1">
                            <div className="col-12 col-md-12 col-lg-12">
                                {
                                    clientMeetingData != null && clientMeetingData.length > 0 ?
                                        renderMeetingTableView() :
                                        <h6 className="text-bold-500 text-center">No Meetings Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomerMeetingsView
                show={showCustomerMeetingView}
                customerMeetingId={customerMeetingId}
                handleClose={() => {
                    setShowCustomerMeetingView(false);
                }}
            />
        </>
    )


}
function mapStateToProps(state) {

    var ClientTabMeetingsView = null;

    if (
        state.ClientTabMeetingsViewReducer != null &&
        Object.keys(state.ClientTabMeetingsViewReducer).length > 0 &&
        state.ClientTabMeetingsViewReducer.constructor === Object
    ) {
        switch (state.ClientTabMeetingsViewReducer.tag) {

            case ACTION_CLIENTTABMEETINGSVIEW_SUCCESS:
                ClientTabMeetingsView = Object.assign({}, state.ClientTabMeetingsViewReducer.data);
                delete state.ClientTabMeetingsViewReducer;
                return { ClientTabMeetingsView };
            default:
                return { ClientTabMeetingsView }
        }
    }
    return { ClientTabMeetingsView }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionClientTabMeetingsView }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientTabMeetingsView));