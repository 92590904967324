
import React from 'react';
import { Route, Switch } from 'react-router';
import TravelCalendarSettingUpdate from './update';
import TravelCalendarSettingList from './list';

const TravelCalendarSettingRoutes = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}/update`}  component={TravelCalendarSettingUpdate}/>
                <Route exact path={`${match.url}`}  component={TravelCalendarSettingList}/>
            </Switch>
        </>
    )
}

export default TravelCalendarSettingRoutes