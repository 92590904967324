import React from 'react';
import { Route, Switch } from 'react-router';
import RiMeetingAdd from './add';
import RiMeetingEdit from './edit';
import RiMeetings from './list';
import RiMeetingView from './view';

const RiMeetingRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={RiMeetingAdd}/>
                <Route path={`${match.url}/edit`}  component={RiMeetingEdit}/>
                <Route path={`${match.url}/view`}  component={RiMeetingView}/>
                <Route exact path={`${match.url}`}  component={RiMeetings}/>
            </Switch>
        </>
    )
}

export default RiMeetingRoute