import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { AiFillCheckCircle } from "react-icons/ai";

const RequestHistory = (props) => {

    const [history, setHistory] = useState(null);

    const initialRender = () => {
        setHistory(props.history);
    }
    useEffect(initialRender, []);

    return (
        <>
            {
                history != null && history.length > 0 ?
                    <div class="card border mt-1">
                        <div class="card-header border">
                            History
                        </div>
                        <div class="card-body">
                            {
                                history.map((objHistory, index) => (
                                    <>
                                        <div className="col-12 pt-1">
                                            <div class="d-flex flex-row">
                                                <div className=""><AiFillCheckCircle className="primary" size={30} /></div>
                                                <div className="ml-1"><button className="btn btn-sm btn-primary text-bold-500 font-small-1 round">{objHistory.status_name}</button></div>
                                                <div className="ml-1 mt-half font-small-1">
                                                    {dateFormat(objHistory.date, "dd/mm/yyyy")}
                                                </div>
                                            </div>
                                            <div class="d-flex flex-row">
                                                <div class="media-left pl-3">
                                                    <a className="profile-image">
                                                        {
                                                            (objHistory.profile_pic != null && objHistory.profile_pic.length > 0) ?
                                                                <img
                                                                    src={objHistory.profile_pic}
                                                                    className="rounded-circle img-border cursor-pointer"
                                                                    alt="Card"
                                                                    width={50}
                                                                    height={50}
                                                                    style={{}}
                                                                    onClick={() => {
                                                                        props.history.push({ pathname: '/profile', state: { user_id: objHistory.user_id } })
                                                                    }}
                                                                />
                                                                :
                                                                <>
                                                                    <div
                                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3 cursor-pointer"
                                                                        style={{ height: 50, width: 50 }}
                                                                        onClick={() => {
                                                                            props.history.push({ pathname: '/profile', state: { user_id: objHistory.user_id } })
                                                                        }}
                                                                    >
                                                                        {['Assign Engineer', 'Assign RM'].includes(objHistory?.status_name) ? objHistory.assign_initial_name : objHistory.initial_name}
                                                                    </div>
                                                                </>
                                                        }
                                                    </a>
                                                </div>
                                                <div className="ml-1 mt-half">
                                                    <h6 className="font-small-3 text-bold-600 mb-half">{['Assign Engineer', 'Assign RM'].includes(objHistory?.status_name) ? objHistory.assign_full_name : objHistory.full_name}</h6>
                                                    <h6 className="font-small-3 text-bold-100">{objHistory.remark}</h6>
                                                </div>

                                            </div>
                                        </div>
                                        {
                                            (index + 1) !== history.length ?
                                                <div className="col-12">
                                                    <div class="d-flex flex-row vertical-separator ml-1">

                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </>
                                ))
                            }
                        </div>
                    </div>
                    :
                    <></>
            }

        </>
    )
}
export default RequestHistory