import Api from '../../../../api';
import { fetchSuccess, loading } from '../../../../utils/action.util';
import { API_NEW_PASSWORD } from '../../../../api/constants';
import { PRINT } from '../../../../utils';

export const ACTION_NEW_PASSWORD_LOADING = 'ACTION_NEW_PASSWORD_LOADING';
export const ACTION_NEW_PASSWORD_SUCCESS = 'ACTION_NEW_PASSWORD_SUCCESS';

export function actionNewPassword(params) {
    return (dispatch, getState) => 
    Api.post(API_NEW_PASSWORD , params).then((res) => {
        const data = res;
        PRINT('RESET_PASSWORD RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_NEW_PASSWORD_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_NEW_PASSWORD_LOADING, { loading: false, data }));
    });
}
