import Api from "../../../../api"
import { fetchSuccess, loading } from "../../../../utils/action.util";
import { API_WELLNESS_MANAGEMENT_REQUEST_VIEW } from "../../../../api/constants";

export const ACTION_WELLNESS_MANAGEMENT_VIEW_LOADING = 'ACTION_WELLNESS_MANAGEMENT_VIEW_LOADING';
export const ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS = 'ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS';

export function actionViewWellnessManagmentRequest(params) {
    return (dispatch, getState) =>
        Api.post(API_WELLNESS_MANAGEMENT_REQUEST_VIEW, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNESS_MANAGEMENT_VIEW_LOADING, { loading: false, data }));
        });
}

