import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REGULARIZE_REQUEST_ADD, API_GET_ATTENDANCE } from '../../../../../../api/constants';
import {API_LEAVE_REQUEST_APPROVAL_MANAGER} from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_REGULARIZE_REQUEST_ADD_LOADING = 'ACTION_REGULARIZE_REQUEST_ADD_LOADING';
export const ACTION_REGULARIZE_REQUEST_ADD_SUCCESS = 'ACTION_REGULARIZE_REQUEST_ADD_SUCCESS';

export const ACTION_GET_ATTENDANCE_LOADING = 'ACTION_GET_ATTENDANCE_LOADING';
export const ACTION_GET_ATTENDANCE_SUCCESS = 'ACTION_GET_ATTENDANCE_SUCCESS';

export const ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_LOADING = 'ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_LOADING';
export const ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS = 'ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS';

export function actionRegularizeRequestAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_REGULARIZE_REQUEST_ADD,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REGULARIZE_REQUEST_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REGULARIZE_REQUEST_ADD_LOADING, { loading: false, data }));
        });
}

export function actionGetAttendance() {
    return (dispatch, getState) =>
        Api.get(API_GET_ATTENDANCE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_ATTENDANCE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_ATTENDANCE_LOADING, { loading: false, data }));
        });
}

export function actionRegularizeRequestApprovalManager() {
    return (dispatch, getState) =>
        Api.get(API_LEAVE_REQUEST_APPROVAL_MANAGER).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REGULARIZE_REQUEST_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}