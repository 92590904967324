import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import {
  API_GET_POST_WITH_COMMENTS,
  API_POST_CREATE_COMMENT,
  API_POST_DELETE,
  API_POST_LIKE_DISLIKE,
  API_POST_COMMENT_LIKE,
  API_POST_COMMENT_DELETE,
  API_POST_COMMENT_EDIT
} from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";

export const ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING = "ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING";
export const ACTION_MYPROFILE_ACTIVITYFEEDLIST_SUCCESS = "ACTION_MYPROFILE_ACTIVITYFEEDLIST_SUCCESS";
export const ACTION_MYPROFILE_ACTIVITYFEEDLIKE_SUCCESS = 'ACTION_MYPROFILE_ACTIVITYFEEDLIKE_SUCCESS';
export const ACTION_MYPROFILE_ACTIVITYFEEDDELETE_SUCCESS = 'ACTION_MYPROFILE_ACTIVITYFEEDDELETE_SUCCESS';
export const ACTION_MYPROFILE_ACTIVITYFEEDCREATECOMMENT_SUCCESS = 'ACTION_MYPROFILE_ACTIVITYFEEDCREATECOMMENT_SUCCESS';
export const ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTLIKE_SUCCESS = 'ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTLIKE_SUCCESS';
export const ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTDELETE_SUCCESS = 'ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTDELETE_SUCCESS';
export const ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTEDIT_SUCCESS = 'ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTEDIT_SUCCESS';

export function actionMyProfileActivityFeedList(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_POST_WITH_COMMENTS, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITYFEEDLIST ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_MYPROFILE_ACTIVITYFEEDLIST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionMyProfileActivityFeedLike(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_LIKE_DISLIKE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED LIKE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_MYPROFILE_ACTIVITYFEEDLIKE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionMyProfileActivityFeedDelete(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_DELETE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED DELETE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_MYPROFILE_ACTIVITYFEEDDELETE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionMyProfileActivityFeedCreateComment(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_CREATE_COMMENT, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED CREATE COMMENT ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_MYPROFILE_ACTIVITYFEEDCREATECOMMENT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionMyProfileActivityFeedCommentLike(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_LIKE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED COMMENT LIKE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTLIKE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionMyProfileActivityFeedCommentDelete(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_DELETE, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED COMMENT DELETE ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTDELETE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}

export function actionMyProfileActivityFeedCommentEdit(params) {
  return (dispatch, getState) =>
    Api.post(API_POST_COMMENT_EDIT, params)
      .then((res) => {
        const data = res;
        PRINT("ACTIVITY FEED COMMENT EDIT ACTION RESPONSE : ", data);
        dispatch(fetchSuccess(ACTION_MYPROFILE_ACTIVITYFEEDCOMMENTEDIT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_MYPROFILE_ACTIVITYFEEDLIST_LOADING, { loading: false, data }));
      });
}