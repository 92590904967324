import React from 'react';
import { Route, Switch } from 'react-router';
import BirthdayWishList from './list';
import BirthDayWishAdd from './add';
import BirthDayWishEdit from './edit';
import BirthDayWishLogs from '../birthday_wish_logs/list'
const BirthDayWishRoute = (props) => {
    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/logs`}  component={BirthDayWishLogs}/>
                <Route path={`${match.url}/add`}  component={BirthDayWishAdd}/>
                <Route path={`${match.url}/edit`}  component={BirthDayWishEdit}/>
                <Route path={`${match.url}`}  component={BirthdayWishList}/>
            </Switch>
        </>
    )
}

export default BirthDayWishRoute