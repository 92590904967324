import React, { useState, useEffect } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm, Controller } from "react-hook-form";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { actionTransportAdd, TRANSPORT_ADD_SUCCESS } from './action';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import { toast } from "react-toastify";
const Add = (props) => {
    const { match } = props;
    const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm()
    const [is_km, setIs_Km] = useState(null);

    const initialRender = () => {
    }
    useEffect(initialRender, [props.show]);

    const onTransportCreateUpdate = () => {
        const { TransportCreate } = props;
        var TransportCreateResponse = Object.assign({}, TransportCreate);
        if (TransportCreateResponse.result === true) {
            props.history.goBack();
        } else {
            switch (TransportCreateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break
                case ERROR:
                    toast.error(TransportCreateResponse.message);
                default:
            }
        }
    }
    useEffect(onTransportCreateUpdate, [props.TransportCreate]);

    const onSubmit = (data) => {
        props.actionTransportAdd(data);
        console.log(JSON.stringify(data));
    }

    return (
        <>
            <ScreenTitle title="Add Transport" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control"> Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="title"
                                                        name="title"
                                                        placeholder="Enter  Name"
                                                        {...register('title', { required: ' Name is required' })}
                                                    />
                                                    {errors.title && <ErrorLabel message={errors.title?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Kilometer Applicable&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="is_km"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[{
                                                                    'text': 'Yes',
                                                                    'value': 'Y'
                                                                },
                                                                {
                                                                    'text': 'No',
                                                                    'value': 'N'
                                                                }
                                                                ]}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select is_km"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData.value != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setIs_Km(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select is_km'
                                                        }}
                                                    />
                                                    {errors.is_km && <ErrorLabel message={errors.is_km?.message} />}
                                                </div>
                                            </div>
                                            {
                                                is_km != null && is_km === 'Y' ?
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="label-control">Kilometer Rate&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="km_rate"
                                                                name="km_rate"
                                                                placeholder="Enter kilometer rate"
                                                                onKeyPress={(event) => {
                                                                    if (!/^\d{0,2}(\.\d{0,1}){0,1}$/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('km_rate', {
                                                                    required: 'Rate is  required',
                                                                    // validate: (value) => {

                                                                    // },
                                                                }
                                                                )}
                                                            // { required: ' Rate is required' })}
                                                            />
                                                            {errors.km_rate && <ErrorLabel message={errors.km_rate?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Document Applicable&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="is_document"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[{
                                                                    'text': 'Yes',
                                                                    'value': 'Y'
                                                                },
                                                                {
                                                                    'text': 'No',
                                                                    'value': 'N'
                                                                }
                                                                ]}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Document Applicable"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData.value != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Document Applicable'
                                                        }}
                                                    />
                                                    {errors.is_document && <ErrorLabel message={errors.is_document?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        title: "Active",
                                                                        id: 'A'
                                                                    },
                                                                    {
                                                                        title: "Inactive",
                                                                        id: 'I'
                                                                    }
                                                                ]}
                                                                fields={{ text: "title", value: "id" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.id)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Status'
                                                        }}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var TransportCreate = null;

    if (
        state.AddTransportReducer != null &&
        Object.keys(state.AddTransportReducer).length > 0 &&
        state.AddTransportReducer.constructor === Object
    ) {
        switch (state.AddTransportReducer.tag) {
            case TRANSPORT_ADD_SUCCESS:
                TransportCreate = Object.assign({}, state.AddTransportReducer.data);
                delete state.AddTransportReducer
                return { TransportCreate };
            default:
        }
    }

    return {
        TransportCreate,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTransportAdd }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Add)
