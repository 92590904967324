import createReducer from "../../../../../../reducers/createReducer";
import {
    ACTION_INSURER_STATUS_SUCCESS,
    ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS,
    ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS,
    ACTION_PLACEMENT_GET_INSURER_SUCCESS,
    ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS,
    ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS,
    ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS
} from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const OpportunityPlacementReducer = createReducer(INITIAL_STATE, {
    [ACTION_INSURER_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INSURER_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_PLACEMENT_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_PLACEMENT_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PLACEMENT_GET_INSURER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PLACEMENT_GET_INSURER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PLACEMENT_GET_INSURER_BRANCH_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PLACEMENT_GET_INSURER_STATE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PLACEMENT_GET_INSURER_CITY_SUCCESS,
                loading: false,
            }
        );
    },
}
)
export default OpportunityPlacementReducer