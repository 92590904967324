import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_APP_SETTINGS_LIST } from '../../../../../../api/constants';
import { API_APP_SETTINGS_DELETE } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_APP_SETTINGS_LIST_LOADING = 'ACTION_APP_SETTINGS_LIST_LOADING';
export const ACTION_APP_SETTINGS_LIST_SUCCESS = 'ACTION_APP_SETTINGS_LIST_SUCCESS';

export const ACTION_APP_SETTINGS_DELETE_LOADING = 'ACTION_APP_SETTINGS_DELETE_LOADING';
export const ACTION_APP_SETTINGS_DELETE_SUCCESS = 'ACTION_APP_SETTINGS_DELETE_SUCCESS';

export function actionAppSettingsList() {
    return (dispatch, getState) =>
        Api.get(API_APP_SETTINGS_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_APP_SETTINGS_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_APP_SETTINGS_LIST_LOADING, { loading: false, data }));
        });
}
export function actionAppSettingsDelete(params) {
    return (dispatch, getState) =>
        Api.post(API_APP_SETTINGS_DELETE, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_APP_SETTINGS_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_APP_SETTINGS_DELETE_LOADING, { loading: false, data }));
        });
}