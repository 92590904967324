import { ACTION_IMPORT_MASTER_DATA_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ImportMasterDataReducer = createReducer(INITIAL_STATE, {
    [ACTION_IMPORT_MASTER_DATA_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_IMPORT_MASTER_DATA_SUCCESS,
                loading: false,
            }
        );
    },
});

export default ImportMasterDataReducer;    