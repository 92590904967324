import React, { useEffect } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionRollCreate, ACTION_ROLL_CREATE_SUCCESS } from './action';
import { toast } from 'react-toastify';

const Add = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const initialRender = () => {
    }
    useEffect(initialRender, [props.show]);

    const onRollCreateUpdate = () => {
        const { RollCreate } = props;
        var RollCreateResponse = Object.assign({}, RollCreate);
        if (RollCreateResponse.result === true) {
            props.history.goBack();
        } else {
            switch (RollCreateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(RollCreateResponse.message);
                    break;
                default:
            }
        }
    }
    useEffect(onRollCreateUpdate, [props.RollCreate]);


    const onSubmit = (data) => {

        props.actionRollCreate(data);
        console.log(JSON.stringify(data));
    }
    return (
        <>
            <ScreenTitle title="Add Role" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Roll Name&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="title"
                                                    name="title"
                                                    placeholder="Enter Roll Name"
                                                    {...register('title', { required: 'Roll Name is required' })}
                                                />
                                                {errors.title && <ErrorLabel message={errors.title?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={[
                                                                {
                                                                    title: "Active",
                                                                    id: 'active'
                                                                },
                                                                {
                                                                    title: "Inactive",
                                                                    id: 'inactive'
                                                                }
                                                            ]}
                                                            fields={{ text: "title", value: "id" }}
                                                            allowFiltering={false}
                                                            placeholder="Select Status"
                                                            value={field.value}
                                                            change={(event) => field.onChange(event.itemData.id)}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Status'
                                                    }}
                                                />
                                                {errors.status && <ErrorLabel message={errors.status?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >Submit</button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var RollCreate = null;

    if (
        state.PermissionReducer != null &&
        Object.keys(state.PermissionReducer).length > 0 &&
        state.PermissionReducer.constructor === Object
    ) {
        switch (state.PermissionReducer.tag) {
            case ACTION_ROLL_CREATE_SUCCESS:
                RollCreate = Object.assign({}, state.PermissionReducer.data);
                delete state.PermissionReducer
                return { RollCreate };
            default:
        }
    }

    return {
        RollCreate
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRollCreate }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Add)