
import Api from '../../../../../../api';
import { fetchSuccess, loading } from './../../../../../../utils/action.util';
import { BIRTHDAY_EDIT } from '../../../../../../api/constants'
import { PRINT } from './../../../../../../utils/index';

export const ACTION_BIRTHDAY_WISH_EDIT_LOADING = 'ACTION_BIRTHDAY_WISH_EDIT_LOADING';
export const ACTION_BIRTHDAY_WISH_EDIT_SUCCESS = 'ACTION_BIRTHDAY_WISH_EDIT_SUCCESS';

export function actionBirthdayWishEdit(params) {
    return (dispatch, getState) =>
        Api.post(BIRTHDAY_EDIT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_BIRTHDAY_WISH_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BIRTHDAY_WISH_EDIT_LOADING, { loading: false, data }));
        });
}