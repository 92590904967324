import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";

const RejectClaims = (props) => {
    const { register, handleSubmit } = useForm()

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            // $("#contact-form-loader").addClass("hidden");
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            // $("#contact-form-loader").addClass("hidden");
        }
    }
    useEffect(initialRender, [props.show]);

    const onSubmit = (data) => {

    }

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Reject Claim
                            </h4>

                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                    <h4 class="d-flex form-section my-0 black">
                    </h4>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className='row'>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Kindly provide the reason for the claim rejection.</label>
                                                </div>
                                                <h4 class="d-flex form-section my-0 mb-2 black">
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Remark&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="reason"
                                                        name="reason"
                                                        rows={5}
                                                        placeholder="Add Remark"
                                                        {...register('reason', { required: 'Reason is required' })}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-primary mr-1 mt-0 shadow  text-uppercase"
                                        >Reject</button>
                                        <button className="btn btn-outline-secondary mr-1 mt-0 shadow  text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default RejectClaims