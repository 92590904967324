import React, { useEffect, useState } from 'react';
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import dateFormat from 'dateformat';
import { UNAUTHORIZED, characterLimit, ERROR } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionAssetRequestAdd, ACTION_ASSET_REQUEST_ADD_SUCCESS } from './action';
import Swal from 'sweetalert2';
import { actionAssetRequestApprovalManager, ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS } from './action';

const AssetsRequestAdd = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch, setValue, getValues } = useForm()

    const [assetRequestApprovalManager, setAssetRequestApprovalManager] = useState();
    const [fromDate, setFromDate] = useState();
    const minDateValue = new Date()

    var watchDescription = watch('description');

    const initialRender = () => {
        props.actionAssetRequestApprovalManager({});
    }
    useEffect(initialRender, []);

    const onAssetRequestApprovalManagerUpdate = () => {
        const { AssetRequestApprovalManager } = props;
        var AssetRequestApprovalManagerResponse = Object.assign({}, AssetRequestApprovalManager);
        if (AssetRequestApprovalManagerResponse.result === true) {
            setAssetRequestApprovalManager(AssetRequestApprovalManagerResponse.response.managers);
        } else {
            switch (AssetRequestApprovalManagerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAssetRequestApprovalManagerUpdate, [props.AssetRequestApprovalManager]);

    const onAssetRequestAddUpdate = () => {
        const { AssetRequestAdd } = props;
        var AssetRequestAddResponse = Object.assign({}, AssetRequestAdd);
        $("#asset-request").prop("disabled", false);
        if (AssetRequestAddResponse.result === true) {
            $("#asset-request-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Asset Request Added Successfully");
            props.history.goBack();
        } else {
            $("#Asset-request-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (AssetRequestAddResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Upload Error',
                        text: AssetRequestAddResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAssetRequestAddUpdate, [props.AssetRequestAdd]);


    const onSubmit = (data) => {
        data.user_id = props.auth.getUser().user_id;
        data.from_date = fromDate;
        data.type = 'asset';
        data.approval_manager_id = assetRequestApprovalManager.asset_manager_id;
        // $("#progress-loader").removeClass("hidden");
        $("#asset-request-loader").removeClass("hidden");
        $("#asset-request").prop("disabled", true);
        props.actionAssetRequestAdd(data);
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Add New Asset</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Asset Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Asset Name"
                                                        {...register('name', { required: "Asset Name is Required" })}
                                                    />
                                                    {errors.name?.type === 'required' && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"> Request Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="from_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                id="from_date"
                                                                name="from_date"
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                min={minDateValue}
                                                                acceptCustomValue={false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Date is required'
                                                        }}
                                                    />
                                                    {errors.from_date && <ErrorLabel message={errors.from_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Add Description"
                                                        {...register('description', { required: 'Description is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{watchDescription != null ? watchDescription.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="asset-request"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="asset-request-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/hrms/asset-request');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}


const mapStateToProps = (state) => {
    var AssetRequestAdd = null;
    var AssetRequestApprovalManager = null;

    if (
        state.AssetRequestAddReducer != null &&
        Object.keys(state.AssetRequestAddReducer).length > 0 &&
        state.AssetRequestAddReducer.constructor === Object
    ) {
        switch (state.AssetRequestAddReducer.tag) {
            case ACTION_ASSET_REQUEST_ADD_SUCCESS:
                AssetRequestAdd = Object.assign({}, state.AssetRequestAddReducer.data);
                delete state.AssetRequestAddReducer;
                return { AssetRequestAdd };
            case ACTION_ASSET_REQUEST_APPROVAL_MANAGER_SUCCESS:
                AssetRequestApprovalManager = Object.assign({}, state.AssetRequestAddReducer.data);
                delete state.AssetRequestAddReducer;
                return { AssetRequestApprovalManager };
            default:
        }
    }

    return {
        AssetRequestAdd,
        AssetRequestApprovalManager,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAssetRequestAdd, actionAssetRequestApprovalManager }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AssetsRequestAdd))
































