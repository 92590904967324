import { API_EDIT_GET_TPA_NAME, API_MEETING_LIST_CONTACT, API_TPAMEETINGADDCONTACT, API_DELETE_DOCUMENT } from '../../../../../../api/constants';
import { API_TPA_MEETING_VIEW } from '../../../../../../api/constants';
import { API_ACCOMPAINED_BY } from '../../../../../../api/constants';
import { API_TPA_MEETING_EDIT } from '../../../../../../api/constants';
import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_API_ACCOMPAINED_BY_LOADING = 'ACTION_API_ACCOMPAINED_BY_LOADING';
export const ACTION_API_ACCOMPAINED_BY_SUCCESS = 'ACTION_API_ACCOMPAINED_BY_SUCCESS';

export const ACTION_EDIT_GET_TPA_NAME_LOADING = 'ACTION_EDIT_GET_TPA_NAME_LOADING';
export const ACTION_EDIT_GET_TPA_NAME_SUCCESS = 'ACTION_EDIT_GET_TPA_NAME_SUCCESS';

export const ACTION_TPA_MEETING_VIEW_LOADING = 'ACTION_TPA_MEETING_VIEW_LOADING';
export const ACTION_TPA_MEETING_VIEW_SUCCESS = 'ACTION_TPA_MEETING_VIEW_SUCCESS';

export const TPAMEETINGEDITCONTACT_LOADING = 'TPAMEETINGEDITCONTACT_LOADING';
export const ACTION_TPAMEETINGEDITCONTACT_SUCCESS = 'ACTION_TPAMEETINGEDITCONTACT_SUCCESS';

export const ACTION_TPA_GET_CONTACT_LOADING = 'ACTION_TPA_GET_CONTACT_LOADING';
export const ACTION_TPA_GET_CONTACT_SUCCESS = 'ACTION_TPA_GET_CONTACT_SUCCESS';

export const ACTION_TPA_MEETING_EDIT_LOADING = 'ACTION_TPA_MEETING_EDIT_LOADING';
export const ACTION_TPA_MEETING_EDIT_SUCCESS = 'ACTION_TPA_MEETING_EDIT_SUCCESS';

export const ACTION_DOCUMENT_DELETE_LOADING = 'ACTION_DOCUMENT_DELETE_LOADING';
export const ACTION_DOCUMENT_DELETE_SUCCESS = 'ACTION_DOCUMENT_DELETE_SUCCESS';

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionTpaMeetingsEdit(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_TPA_MEETING_EDIT,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TPA_MEETING_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TPA_MEETING_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionEditGetTpaName(params) {
    return (dispatch, getState) =>
        Api.post(API_EDIT_GET_TPA_NAME, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EDIT_GET_TPA_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_GET_TPA_NAME_LOADING, { loading: false, data }));
        });
}

export function actionTpaMeetingsView(params) {
    return (dispatch, getState) =>
        Api.post(API_TPA_MEETING_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TPA_MEETING_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TPA_MEETING_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionTPAMeetingEditContact(params) {
    return (dispatch, getState) => Api.post(API_TPAMEETINGADDCONTACT, params).then((res) => {
        const data = res;
        PRINT('TPAMEETINGEDITCONTACT ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_TPAMEETINGEDITCONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(TPAMEETINGEDITCONTACT_LOADING, { loading: false, data }));
    });
}

export function actionGetCompanyContacts(params) {
    return (dispatch, getState) => Api.post(API_MEETING_LIST_CONTACT, params).then((res) => {
        const data = res;
        PRINT('TPAMEETINGADDCONTACT ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_TPA_GET_CONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_TPA_GET_CONTACT_LOADING, { loading: false, data }));
    });
}

export function actionDeleteDocument(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_DOCUMENT,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DOCUMENT_DELETE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_DELETE_LOADING, { loading: false, data }));
        });
}