import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_GET_ADMIN_RIGHT, API_MASTER_ADD_ADMIN_RIGHT, API_MASTER_DELETE_ADMIN_RIGHT } from '../../../../../../api/constants';

export const ACTION_MASTER_GET_ADMIN_RIGHT_LOADING = 'ACTION_MASTER_GET_ADMIN_RIGHT_LOADING';
export const ACTION_MASTER_GET_ADMIN_RIGHT_SUCCESS = 'ACTION_MASTER_GET_ADMIN_RIGHT_SUCCESS';

export const ACTION_MASTER_ADD_ADMIN_RIGHT_LOADING = 'ACTION_MASTER_ADD_ADMIN_RIGHT_LOADING';
export const ACTION_MASTER_ADD_ADMIN_RIGHT_SUCCESS = 'ACTION_MASTER_ADD_ADMIN_RIGHT_SUCCESS';

export const ACTION_MASTER_DELETE_ADMIN_RIGHT_LOADING = 'ACTION_MASTER_DELETE_ADMIN_RIGHT_LOADING';
export const ACTION_MASTER_DELETE_ADMIN_RIGHT_SUCCESS = 'ACTION_MASTER_DELETE_ADMIN_RIGHT_SUCCESS';

export function actionMasterGetAdminRight() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_GET_ADMIN_RIGHT).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_ADMIN_RIGHT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_GET_ADMIN_RIGHT_LOADING, { loading: false, data }));
        });
}

export function actionMasterAddAdminRight(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ADD_ADMIN_RIGHT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ADD_ADMIN_RIGHT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ADD_ADMIN_RIGHT_LOADING, { loading: false, data }));
        });
}

export function actionMasterDeleteAdminRight(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_DELETE_ADMIN_RIGHT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_DELETE_ADMIN_RIGHT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_DELETE_ADMIN_RIGHT_LOADING, { loading: false, data }));
        });
}