import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../component/screen_title';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED, characterLimit } from '../../../../../constants';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { checkFileExtension } from '../../../../../utils';
import { actionHolidayAdd, ACTION_HOLIDAY_ADD_SUCCESS } from './action';
import { actionHolidayBranch, ACTION_HOLIDAY_BRANCH_SUCCESS } from './action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { withAuth } from 'react-auth-guard';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { toast } from 'react-toastify';

const HolidayAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors }, watch } = useForm();

    const [holidayBranch, setHolidayBranch] = useState([]);
    const minDateValue = new Date()
    const [postImage, setPostImage] = useState(null);
    const [mySelected, setMySelected] = useState(null)
    const validExt = ['jpg', 'jpeg', 'png'];
    const [isPost, setIsPost] = useState(0);
    var watchShortDescription = watch('short_description');
    var watchDescription = watch('description');

    const initialRender = () => {
        props.actionHolidayBranch({ "is_unique": true })
    }
    useEffect(initialRender, []);

    const onHolidayAddUpdate = () => {
        const { HolidayAdd } = props;
        var HolidayAddResponse = Object.assign({}, HolidayAdd);
        $("#save_holiday").prop("disabled", false);
        if (HolidayAddResponse.result === true) {
            // $("#progress-loader").addClass("hidden");
            $("#holiday-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Holiday Saved Successfully");
            props.history.goBack();
        } else {
            // $("#progress-loader").addClass("hidden");
            $("#holiday-form-loader").addClass("hidden");
            switch (HolidayAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(HolidayAddResponse.message);
                default:
            }
        }
    }
    useEffect(onHolidayAddUpdate, [props.HolidayAdd]);

    const onHolidayBranchUpdate = () => {
        const { BranchHoliday } = props;
        var BranchHolidayResponse = Object.assign({}, BranchHoliday);
        if (BranchHolidayResponse.result === true) {
            setHolidayBranch(BranchHolidayResponse.response);
            setMySelected([])
        } else {
            switch (BranchHolidayResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onHolidayBranchUpdate, [props.BranchHoliday]);

    const onSubmit = (data) => {

        if (data.is_post === true) {
            data.is_post = 1;
        } else {
            data.is_post = 0;
        }

        if (data.pre_render === undefined) {
            data.pre_render = 0;
        }

        data.user_id = props.auth.getUser().user_id;
        data.branch_address = JSON.stringify(mySelected);
        var files = [];
        if (postImage != null) {
            files.push({ 'name': 'post_image', 'value': postImage });
        }

        delete data.post_image;

        // $("#progress-loader").removeClass("hidden");
        $("#holiday-form-loader").removeClass("hidden");
        $("#save_holiday").prop("disabled", true);
        props.actionHolidayAdd(data, files);
    }

    return (
        <>
            <ScreenTitle title="Add Holiday" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Title&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="title"
                                                        name="title"
                                                        placeholder="Enter Title"
                                                        {...register('title', { required: 'Title is required' })}
                                                    />
                                                    {errors.title?.type === 'required' && <ErrorLabel message={errors.title?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"> Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="date"
                                                                name="date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                min={minDateValue}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Date is required'
                                                        }}
                                                    />
                                                    {errors.date && <ErrorLabel message={errors.date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="type"
                                                                name="type"
                                                                dataSource={[
                                                                    {
                                                                        value: 'holiday',
                                                                        text: 'Holiday'

                                                                    },
                                                                    {
                                                                        value: 'special',
                                                                        text: 'Special'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type is required'
                                                        }}
                                                    />
                                                    {errors.type && <ErrorLabel message={errors.type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="branch_address"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                id="branch_address"
                                                                name="branch_address"
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: holidayBranch,
                                                                        key: 'city_id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="city"
                                                                placeholder="Select Branch"
                                                                valueExpr="city_id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                acceptCustomValue={false}
                                                                maxDisplayedTags={4}
                                                                showSelectionControls={true}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value != null && field.value.length > 0 ? field.value : mySelected}
                                                                onValueChanged={({ value, event }) => {
                                                                    if ((value != null || value !== undefined) && value.length > 0) {
                                                                        setMySelected(value)
                                                                        field.onChange(value);
                                                                    } else {
                                                                        if (mySelected != null && mySelected.length > 0) {
                                                                            field.onChange('');
                                                                            setMySelected([])
                                                                        }
                                                                    }

                                                                    if (event != null) {
                                                                        if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                            field.onChange('');
                                                                            setMySelected([])
                                                                        }
                                                                    }

                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select branch'
                                                        }}
                                                    />
                                                    {errors.branch_address && <ErrorLabel message={errors.branch_address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Pre render</label>
                                                    <Controller
                                                        name="pre_render"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="pre_render"
                                                                name="pre_render"
                                                                dataSource={[
                                                                    {
                                                                        value: 0,
                                                                        text: 0

                                                                    },
                                                                    {
                                                                        value: 1,
                                                                        text: 1
                                                                    },
                                                                    {
                                                                        value: 2,
                                                                        text: 2
                                                                    },
                                                                    {
                                                                        value: 3,
                                                                        text: 3
                                                                    },
                                                                    {
                                                                        value: 4,
                                                                        text: 4
                                                                    },
                                                                    {
                                                                        value: 5,
                                                                        text: 5
                                                                    },
                                                                    {
                                                                        value: 6,
                                                                        text: 6
                                                                    },
                                                                    {
                                                                        value: 7,
                                                                        text: 7
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Pre render"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.pre_render && <ErrorLabel message={errors.pre_render?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Short Description&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="short_description"
                                                        name="short_description"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Short Description"
                                                        {...register('short_description', { required: 'Short Description is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{watchShortDescription != null ? watchShortDescription.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.short_description?.type === 'required' && <ErrorLabel message={errors.short_description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Description"
                                                        {...register('description')}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{watchDescription != null ? watchDescription.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="is_post"
                                                            id="is_post"
                                                            {...register('is_post')}
                                                            onChange={(event) => { setIsPost(event.target.checked); }}
                                                        />
                                                        {errors.is_post && <ErrorLabel message={errors.is_post?.message} />}
                                                        <label class="custom-control-label" for="is_post">Display Post</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isPost ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <fieldset class="form-group">
                                                            <label for="basicInputFile">Upload Image Here</label>
                                                            <div class="custom-file">
                                                                <input
                                                                    type="file"
                                                                    class="custom-file-input"
                                                                    id="post_image"
                                                                    accept=".jpg, .jpeg, .png"
                                                                    name="post_image"
                                                                    onChange={(event) => {
                                                                        if (
                                                                            event.target.files
                                                                        ) {

                                                                            var fileSize = event.target.files[0].size;

                                                                            var ext = (event.target.files[0].name).split('.').pop();

                                                                            if (checkFileExtension(ext, validExt)) {

                                                                                if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                    setPostImage(event.target.files[0]);
                                                                                } else {
                                                                                    Swal.fire({
                                                                                        title: 'Error',
                                                                                        icon: 'error',
                                                                                        html: MEETING_FILE_ERROR_MESSAGE,
                                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                                    }).then((result) => {

                                                                                    });
                                                                                }
                                                                            }
                                                                            else {
                                                                                Swal.fire({
                                                                                    title: 'Upload Error',
                                                                                    text: 'You can only upload jpg, jpeg & png files',
                                                                                    icon: 'error'
                                                                                }).then(success => {

                                                                                })
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                                <label class="custom-file-label" for="post_image">
                                                                    {
                                                                        postImage != null ?
                                                                            postImage.name : "Choose file"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_holiday"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="holiday-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var HolidayAdd = null;
    var BranchHoliday = null;

    if (
        state.HolidayAddReducer != null &&
        Object.keys(state.HolidayAddReducer).length > 0 &&
        state.HolidayAddReducer.constructor === Object
    ) {
        switch (state.HolidayAddReducer.tag) {
            case ACTION_HOLIDAY_ADD_SUCCESS:
                HolidayAdd = Object.assign({}, state.HolidayAddReducer.data);
                delete state.HolidayAddReducer
                return { HolidayAdd };
            case ACTION_HOLIDAY_BRANCH_SUCCESS:
                BranchHoliday = Object.assign({}, state.HolidayAddReducer.data);
                delete state.HolidayAddReducer
                return { BranchHoliday };
            default:
        }
    }

    return {
        HolidayAdd,
        BranchHoliday
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionHolidayAdd, actionHolidayBranch }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(HolidayAdd))