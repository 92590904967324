import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CORPORATE_CLIENT_DETAILS } from '../../../../../api/constants';
import { API_GETCLIENTMEETINGSDETAIL } from '../../../../../api/constants';

export const ACTION_MASTER_CLIENT_DETAILS_LOADING = 'ACTION_MASTER_CLIENT_DETAILS_LOADING';
export const ACTION_MASTER_CLIENT_DETAILS_SUCCESS = 'ACTION_MASTER_CLIENT_DETAILS_SUCCESS';

export const ACTION_GET_CLIENT_MEETINGS_DETAIL_LOADING = 'ACTION_GET_CLIENT_MEETINGS_DETAIL_LOADING';
export const ACTION_GET_CLIENT_MEETINGS_DETAIL_SUCCESS = 'ACTION_GET_CLIENT_MEETINGS_DETAIL_SUCCESS';

export function actionGetClientDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CORPORATE_CLIENT_DETAILS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_CLIENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_CLIENT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionGetLeadMeetingsDetail(params) {
    return (dispatch, getState) => 
    Api.post(API_GETCLIENTMEETINGSDETAIL, params).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE', data);
        dispatch(fetchSuccess(ACTION_GET_CLIENT_MEETINGS_DETAIL_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_GET_CLIENT_MEETINGS_DETAIL_LOADING, { loading: false, data }));
    });
}