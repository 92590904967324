import React, { useState, useEffect } from "react";
import moment from "moment";
import { FiUser } from "react-icons/fi";
import { GrPlan } from "react-icons/gr";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { CalculateFinancialYear } from "../../../../../../utils";
import { actionItSubmissionDetail, ACTION_IT_SUBMISSION_DETAIL_SUCCESS } from './action';
import { actionItSubmissionDownload, ACTION_SUBMISSION_DOWNLOAD_SUCCESS } from './action';
import { FaFilePdf } from "react-icons/fa";

const SubmissionView = (props) => {
    const [submissionDetails, setSubmissionDetails] = useState(null);
    const tooltipSection80C = "Maximum eligible limit INR 150,000 (1.5 Lakhs).";
    const tooltipSection80CCD = "Maximum eligible limit INR 50,000 (50K)";
    const tooltipSection80D = "Maximum Eligibility INR 25000 for Family for Emp <60 Years INR 50000 for Emp > 60 Years INR 50000 for Dependent Parent INR 5000 for Preventive Health Check Up (Combine Maximum Limit 75000 / 100000)";
    const tooltipOthers = "INR 200,000 (2 lakhs) maximum eligibility for Interest Paid on Housing Loan & Loss from House Property.";
    const tooltipFlexible = "(Up to max of Rs 50,000 annually - TWICE in a BLOCK OF FOUR YEARS)";
    const [printSubmissionDetail, setPrintSubmissionDetail] = useState(null)
    const [pendingLifeInsurancePremium, setPendingLifeInsurancePremium] = useState(0)
    const [pendingPPF, setPendingPPF] = useState(0)
    const [pendingMutualFunds, setPendingMutualFunds] = useState(0)
    const [pendingNSC, setPendingNSC] = useState(0)
    const [pendingTuitionFees, setPendingTuitionFees] = useState(0)
    const [pendingRepaymentofHousingLoan, setPendingRepaymentofHousingLoan] = useState(0)
    const [pendingFiveYearFD, setPendingFiveYearFD] = useState(0)
    const [pendingOthers, setPendingOthers] = useState(0)
    const [pendingNPS, setPendingNPS] = useState(0)
    const [pendingFamilyMediclaim, setPendingFamilyMediclaim] = useState(0)
    const [pendingParentsMediclaim, setPendingParentsMediclaim] = useState(0)
    const [pendingPreventiveHealth, setPendingPreventiveHealth] = useState(0)
    const [pendingGrandTotal, setPendingGrandTotal] = useState(0)
    const [pendingSelfEducationLoan, setPendingSelfEducationLoan] = useState(0)
    const [pendingHousingLoan, setPendingHousingLoan] = useState(0)
    const [pendingHousePropertyLoss, setPendingHousePropertyLoss] = useState(0)
    const [pendingHRA, setPendingHRA] = useState(0)
    const [pendingLTA, setPendingLTA] = useState(0)

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionItSubmissionDetail({ window_id: props.location.state.window_id, user_id: props.auth.getUser().user_id });
    }
    useEffect(initialRender, []);

    const onItSubmissionDetailUpdate = () => {
        const { ItSubmissionDetail } = props;
        if (ItSubmissionDetail != null) {
            var ItSubmissionDetailResponse = Object.assign({}, ItSubmissionDetail);
            if (ItSubmissionDetailResponse.result === true) {
                setSubmissionDetails(ItSubmissionDetailResponse.response);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ItSubmissionDetailResponse.status) {
                    case ERROR:
                        setSubmissionDetails(null);
                        break;
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onItSubmissionDetailUpdate, [props.ItSubmissionDetail]);

    function base64toPDF(base64, fileName) {
        const binaryString = atob(base64);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }

    const onItSubmissionDownloadUpdate = () => {
        const { ItSubmissionDownload } = props;
        var ItSubmissionDownloadResponse = Object.assign({}, ItSubmissionDownload);
        if (ItSubmissionDownloadResponse.result === true) {
            if(submissionDetails?.profile?.full_name){
                base64toPDF(`${ItSubmissionDownloadResponse?.response?.submission}`, submissionDetails?.profile?.full_name + ' Submission Detail.pdf')
            }
        } else {
            switch (ItSubmissionDownloadResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(ItSubmissionDownloadResponse.message);
                default:
            }
        }
    }
    useEffect(onItSubmissionDownloadUpdate, [props.ItSubmissionDownload]);


    useEffect(() => {
        if (submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes != null && submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document != null) {
            const PendingLifeInsurancePremium = Number(submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes) - Number(submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document)
            setPendingLifeInsurancePremium(PendingLifeInsurancePremium)
        }

        if (submissionDetails?.submissions?.submission_80c?.ppf != null && submissionDetails?.submissions?.submission_80c?.ppf_document != null) {
            const PendingPPF = Number(submissionDetails?.submissions?.submission_80c?.ppf) - Number(submissionDetails?.submissions?.submission_80c?.ppf_document)
            setPendingPPF(PendingPPF)
        }

        if (submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null && submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document != null) {
            const PendingMutualFunds = Number(submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds) - Number(submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document)
            setPendingMutualFunds(PendingMutualFunds)
        }

        if (submissionDetails?.submissions?.submission_80c?.nsc != null && submissionDetails?.submissions?.submission_80c?.nsc_document != null) {
            const PendingNSC = Number(submissionDetails?.submissions?.submission_80c?.nsc) - Number(submissionDetails?.submissions?.submission_80c?.nsc_document)
            setPendingNSC(PendingNSC)
        }

        if (submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children != null && submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_document != null) {
            const PendingTuitionFees = Number(submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children) - Number(submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_document)
            setPendingTuitionFees(PendingTuitionFees)
        }

        if (submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan != null && submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document != null) {
            const PendingRepaymentofHousingLoan = Number(submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan) - Number(submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document)
            setPendingRepaymentofHousingLoan(PendingRepaymentofHousingLoan)

        }

        if (submissionDetails?.submissions?.submission_80c?.five_year_fd != null && submissionDetails?.submissions?.submission_80c?.five_year_fd_document != null) {
            const PendingFiveYearFD = Number(submissionDetails?.submissions?.submission_80c?.five_year_fd) - Number(submissionDetails?.submissions?.submission_80c?.five_year_fd_document)
            setPendingFiveYearFD(PendingFiveYearFD)
        }

        if (submissionDetails?.submissions?.submission_80c?.others != null && submissionDetails?.submissions?.submission_80c?.others_document != null) {
            const PendingOthers = Number(submissionDetails?.submissions?.submission_80c?.others) - Number(submissionDetails?.submissions?.submission_80c?.others_document)
            setPendingOthers(PendingOthers)
        }

        if (submissionDetails?.submissions?.submission_80ccd?.nps != null && submissionDetails?.submissions?.submission_80ccd?.nps_document != null) {
            const PendingNPS = Number(submissionDetails?.submissions?.submission_80ccd?.nps) - Number(submissionDetails?.submissions?.submission_80ccd?.nps_document)
            setPendingNPS(PendingNPS)
        }

        if (submissionDetails?.submissions?.submission_80d?.family_mediclaim != null && submissionDetails?.submissions?.submission_80d?.family_mediclaim_document != null) {
            const PendingFamilyMediclaim = Number(submissionDetails?.submissions?.submission_80d?.family_mediclaim) - Number(submissionDetails?.submissions?.submission_80d?.family_mediclaim_document)
            setPendingFamilyMediclaim(PendingFamilyMediclaim)
        }

        if (submissionDetails?.submissions?.submission_80d?.parents_mediclaim != null && submissionDetails?.submissions?.submission_80d?.parents_mediclaim_document != null) {
            const PendingParentsMediclaim = Number(submissionDetails?.submissions?.submission_80d?.parents_mediclaim) - Number(submissionDetails?.submissions?.submission_80d?.parents_mediclaim_document)
            setPendingParentsMediclaim(PendingParentsMediclaim)
        }

        if (submissionDetails?.submissions?.submission_80d?.preventive_health_check_up != null && submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_document != null) {
            const PendingPreventiveHealth = Number(submissionDetails?.submissions?.submission_80d?.preventive_health_check_up) - Number(submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_document)
            setPendingPreventiveHealth(PendingPreventiveHealth)
        }

        if (submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education != null && submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document != null) {
            const PendingSelfEducationLoan = Number(submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education) - Number(submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document)
            setPendingSelfEducationLoan(PendingSelfEducationLoan)
        }

        if (submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan != null && submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document != null) {
            const PendingHousingLoan = Number(submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan) - Number(submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document)
            setPendingHousingLoan(PendingHousingLoan)
        }

        if (submissionDetails?.submissions?.submission_other?.loss_from_house_property != null && submissionDetails?.submissions?.submission_other?.loss_from_house_property_document != null) {
            const PendingHousePropertyLoss = Number(submissionDetails?.submissions?.submission_other?.loss_from_house_property) - Number(submissionDetails?.submissions?.submission_other?.loss_from_house_property_document)
            setPendingHousePropertyLoss(PendingHousePropertyLoss)
        }

        if (submissionDetails?.submissions?.submission_other?.hra != null && submissionDetails?.submissions?.submission_other?.hra_document != null) {
            const PendingHRA = Number(submissionDetails?.submissions?.submission_other?.hra) - Number(submissionDetails?.submissions?.submission_other?.hra_document)
            setPendingHRA(PendingHRA)
        }

        if (submissionDetails?.submissions?.lta != null && submissionDetails?.submissions?.lta_document != null) {
            const pendingLTA = Number(submissionDetails?.submissions?.lta) - Number(submissionDetails?.submissions?.lta_document)
            setPendingLTA(pendingLTA)
        }

        if (submissionDetails?.submissions?.submission_80c?.grand_total != null && submissionDetails?.submissions?.submission_80c?.grand_total_document != null) {
            const PendingGrandTotal = Number(submissionDetails?.submissions?.submission_80c?.grand_total) - Number(submissionDetails?.submissions?.submission_80c?.grand_total_document)
            setPendingGrandTotal(PendingGrandTotal)
        }
    }, [submissionDetails]);

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h3 className="text-dark text-bold-700">{`Income Tax Declaration ${submissionDetails?.fy?submissionDetails?.fy :''}`}</h3>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            {/* {reimbursementDetail?.status === 'approved by manager' || reimbursementDetail?.status === 'reject by finance manager' || reimbursementDetail?.status === 'approved by finance manager' ? */}
                            <button className="btn btn-secondary mr-1 shadow round text-uppercase"
                                type="button"
                                onClick={() => {
                                    props.actionItSubmissionDownload({ window_id: props?.location.state?.window_id, user_id: props?.auth.getUser()?.user_id })
                                }}
                            >
                                Download < FaFilePdf size={17} color='white' />
                            </button>
                            {/* :
                                <></>
                            } */}
                        </li>
                    </ul>
                </div>
            </div>
            <section>
                <div className='card'>
                    <div className='card-body'>
                        <form>
                            <div className="form-body">
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Employee Details
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Name</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.full_name != null ?
                                                    submissionDetails?.profile?.full_name : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee id</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.pinc_employee_id != null ?
                                                    submissionDetails?.profile?.pinc_employee_id : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Payroll Entity</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.payroll_entity != null ?
                                                    submissionDetails?.profile?.payroll_entity : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Primary Department</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.primary_department != null ?
                                                    submissionDetails?.profile?.primary_department : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Joining Date</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.joining_date != null ?
                                                    moment(submissionDetails?.profile?.joining_date).format("DD-MMM-YYYY") : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Mobile</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.mobile_no != null ?
                                                    submissionDetails?.profile?.mobile_no : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Reporting Manager</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.reporting_to_full_name != null ?
                                                    submissionDetails?.profile?.reporting_to_full_name : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Branch</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.profile?.city != null ?
                                                    submissionDetails?.profile?.city : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Submission Date</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.submit_on != null ?
                                                    submissionDetails?.submissions?.submit_on : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Finance Status</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.status != null ?
                                                    submissionDetails?.submissions?.status : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>TAX REGIME</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.regime != null ?
                                                    submissionDetails?.submissions?.regime : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
                {
                    submissionDetails != null && submissionDetails?.submissions?.regime === 'OLD' ?
                        <div >
                            <div className="col-12 col-md-12 col-lg-12">
                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                    <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 C&nbsp;
                                    <Tippy content={tooltipSection80C}>
                                        <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                    </Tippy>
                                </h4>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <table class="table table-responsive mb-0">
                                        <thead>
                                            <tr >
                                                <th className='border-0 border-light'>Type of Investment</th>
                                                <th className='border-0 border-light'>Amount</th>
                                                <th className='border-0 border-light'>Final Declaration</th>
                                                <th className='border-0 border-light'>Document Submitted</th>
                                                <th className='border-0 border-light'>Payment Pending</th>
                                                <th className='border-0 border-light'>Approved Amount</th>
                                                <th className='border-0 border-light'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <th className='border-bottom-0 border-light'>Life Insurance Premium / Pension Schemes</th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.life_insurance_premium_or_pension_schemes != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.life_insurance_premium_or_pension_schemes : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes != null ?
                                                            submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingLifeInsurancePremium
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.life_insurance_premium_or_pension_schemes_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light'>Public Provident Fund (PPF) </th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.ppf != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.ppf : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ppf != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ppf : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ppf_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ppf_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingPPF
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ppf_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ppf_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ppf_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ppf_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light text-wrap'>ULIP / ELSS / Tax saving Mutual Funds</th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingMutualFunds
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.ulip_or_elss_or_tax_saving_mutual_funds_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light text-wrap'>NSC </th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.nsc != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.nsc : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.nsc != null ?
                                                            submissionDetails?.submissions?.submission_80c?.nsc : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.nsc_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.nsc_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingNSC
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.nsc_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.nsc_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.nsc_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.nsc_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light text-wrap'>Repayment of Housing Loan (Principal Amount) </th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.repayment_of_housing_loan != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.repayment_of_housing_loan : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan != null ?
                                                            submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingRepaymentofHousingLoan
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.repayment_of_housing_loan_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light text-wrap'>Tuition Fees for Children (Max for 2 children)</th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.tuition_fees_for_children != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.tuition_fees_for_children : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children != null ?
                                                            submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingTuitionFees
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.tuition_fees_for_children_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light text-wrap'>5 yr Fixed Deposits (with specified banks)</th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.five_year_fd != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.five_year_fd : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.five_year_fd != null ?
                                                            submissionDetails?.submissions?.submission_80c?.five_year_fd : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.five_year_fd_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.five_year_fd_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingFiveYearFD != null ? pendingFiveYearFD : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.five_year_fd_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.five_year_fd_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.five_year_fd_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.five_year_fd_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light text-wrap'>Others</th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.others != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.others : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.others != null ?
                                                            submissionDetails?.submissions?.submission_80c?.others : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.others_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.others_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingOthers != null ? pendingOthers : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.others_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.others_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.others_status != null ?
                                                            submissionDetails?.submissions?.submission_80c?.others_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className='border-bottom-0 border-light text-wrap'>Grand Total</th>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80c != null && submissionDetails?.declarations?.declaration_80c?.grand_total != null ?
                                                            submissionDetails?.declarations?.declaration_80c?.grand_total : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.grand_total != null ?
                                                            submissionDetails?.submissions?.submission_80c?.grand_total : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.grand_total_document != null ?
                                                            submissionDetails?.submissions?.submission_80c?.grand_total_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        pendingGrandTotal != null ?
                                                            pendingGrandTotal : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.grand_total_approved != null ?
                                                            submissionDetails?.submissions?.submission_80c?.grand_total_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                    { }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="col-12 mt-1">
                                        <label className="label-control  font-weight-bold">Finance Remark</label>
                                        <span className="col-12 ml-3">
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.submission_80c != null && submissionDetails?.submissions?.submission_80c?.finance_remark != null ?
                                                    submissionDetails?.submissions?.submission_80c?.finance_remark : '-'
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div >
                        :
                        <></>
                }

                <div className='row'>
                    {
                        submissionDetails != null && submissionDetails?.submissions?.regime === 'OLD' ?
                            <div className="col-12 col-md-12 col-lg-12">
                                <div>
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 CCD (1B)&nbsp;
                                        <Tippy content={tooltipSection80CCD}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table table-responsive mb-0">
                                            <thead>
                                                <tr >
                                                    <th className='border-0 border-light'>National Pension Scheme</th>
                                                    <th className='border-0 border-light'>Amount</th>
                                                    <th className='border-0 border-light'>Final Declaration</th>
                                                    <th className='border-0 border-light'>Document Submitted</th>

                                                    <th className='border-0 border-light'>Payment Pending</th>
                                                    <th className='border-0 border-light'>Approved Amount</th>
                                                    <th className='border-0 border-light'>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <th className=' border-bottom-0 border-light'>
                                                        Amount
                                                    </th>
                                                    <td className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionDetails != null && submissionDetails?.declarations?.declaration_80ccd != null && submissionDetails?.declarations?.declaration_80ccd?.nps != null ?
                                                                submissionDetails?.declarations?.declaration_80ccd?.nps : '-'
                                                        }
                                                    </td>
                                                    <td className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionDetails != null && submissionDetails?.submissions?.submission_80ccd != null && submissionDetails?.submissions?.submission_80ccd?.nps != null ?
                                                                submissionDetails?.submissions?.submission_80ccd?.nps : '-'
                                                        }
                                                    </td>
                                                    <td className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionDetails != null && submissionDetails?.submissions?.submission_80ccd != null && submissionDetails?.submissions?.submission_80ccd?.nps_document != null ?
                                                                submissionDetails?.submissions?.submission_80ccd?.nps_document : '-'
                                                        }
                                                    </td>
                                                    <td className=' border-bottom-0 border-light'>
                                                        {
                                                            pendingNPS != null ? pendingNPS : '-'
                                                        }
                                                    </td>
                                                    <td className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionDetails != null && submissionDetails?.submissions?.submission_80ccd != null && submissionDetails?.submissions?.submission_80ccd?.nps_approved != null ?
                                                                submissionDetails?.submissions?.submission_80ccd?.nps_approved : '-'
                                                        }
                                                    </td>
                                                    <td className=' border-bottom-0 border-light'>
                                                        {
                                                            submissionDetails != null && submissionDetails?.submissions?.submission_80ccd != null && submissionDetails?.submissions?.submission_80ccd?.nps_status != null ?
                                                                submissionDetails?.submissions?.submission_80ccd?.nps_status : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-12 mt-1">
                                            <label className="label-control font-weight-bold">Finance Remark</label>
                                            <span className="col-12 ml-3">
                                                {
                                                    submissionDetails != null && submissionDetails?.submissions?.submission_80ccd != null && submissionDetails?.submissions?.submission_80ccd?.finance_remark != null ?
                                                        submissionDetails?.submissions?.submission_80ccd?.finance_remark : '-'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }

                </div>
                {
                    submissionDetails != null && submissionDetails?.submissions?.regime === 'OLD' ?
                        <div >
                            <div className="col-12 col-md-12 col-lg-12">
                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                    <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 D&nbsp;
                                    <Tippy content={tooltipSection80D}>
                                        <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                    </Tippy>
                                </h4>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <table class="table table-responsive mb-0">
                                        <thead>
                                            <tr >
                                                <th className='border-0 border-light'>Type of Investment</th>
                                                <th className='border-0 border-light'>Amount</th>
                                                <th className='border-0 border-light'>Final Declaration</th>
                                                <th className='border-0 border-light'>Document Submitted</th>
                                                <th className='border-0 border-light'>Payment Pending</th>
                                                <th className='border-0 border-light'>Approved Amount</th>
                                                <th className='border-0 border-light'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <th className='border-0 border-light'>Family (Self, Spouse & Children)</th>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80d != null && submissionDetails?.declarations?.declaration_80d?.family_mediclaim != null ?
                                                            submissionDetails?.declarations?.declaration_80d?.family_mediclaim : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.family_mediclaim != null ?
                                                            submissionDetails?.submissions?.submission_80d?.family_mediclaim : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.family_mediclaim_document != null ?
                                                            submissionDetails?.submissions?.submission_80d?.family_mediclaim_document : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        pendingFamilyMediclaim != null ? pendingFamilyMediclaim : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.family_mediclaim_approved != null ?
                                                            submissionDetails?.submissions?.submission_80d?.family_mediclaim_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.family_mediclaim_status != null ?
                                                            submissionDetails?.submissions?.submission_80d?.family_mediclaim_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border-bottom-0 border-light'>Parents</th>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80d != null && submissionDetails?.declarations?.declaration_80d?.parents_mediclaim != null ?
                                                            submissionDetails?.declarations?.declaration_80d?.parents_mediclaim : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.parents_mediclaim != null ?
                                                            submissionDetails?.submissions?.submission_80d?.parents_mediclaim : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.parents_mediclaim_document != null ?
                                                            submissionDetails?.submissions?.submission_80d?.parents_mediclaim_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        pendingParentsMediclaim != null ? pendingParentsMediclaim : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.parents_mediclaim_approved != null ?
                                                            submissionDetails?.submissions?.submission_80d?.parents_mediclaim_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.parents_mediclaim_status != null ?
                                                            submissionDetails?.submissions?.submission_80d?.parents_mediclaim_status : '-'
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <th className='border-bottom-0 border-light'>Preventive Health Check up</th>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80d != null && submissionDetails?.declarations?.declaration_80d?.preventive_health_check_up != null ?
                                                            submissionDetails?.declarations?.declaration_80d?.preventive_health_check_up : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.preventive_health_check_up != null ?
                                                            submissionDetails?.submissions?.submission_80d?.preventive_health_check_up : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_document != null ?
                                                            submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        pendingPreventiveHealth != null ? pendingPreventiveHealth : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_approved != null ?
                                                            submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_status != null ?
                                                            submissionDetails?.submissions?.submission_80d?.preventive_health_check_up_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="col-12 mt-1">
                                        <label className="label-control  font-weight-bold">Finance Remark</label>
                                        <span className="col-12 ml-3">
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.submission_80d != null && submissionDetails?.submissions?.submission_80d?.finance_remark != null ?
                                                    submissionDetails?.submissions?.submission_80d?.finance_remark : '-'
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div >
                        :
                        <></>
                }
                {
                    submissionDetails != null && submissionDetails?.submissions?.regime === 'OLD' ?
                        <div >
                            <div className="col-12 col-md-12 col-lg-12">
                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                    <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 E
                                </h4>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <table class="table table-responsive mb-0">
                                        <thead>
                                            <tr >
                                                <th className='border-0 border-light'>Interest Paid on Loan for Self-Education </th>
                                                <th className='border-0 border-light'>Amount</th>
                                                <th className='border-0 border-light'>Final Declaration</th>
                                                <th className='border-0 border-light'>Document Submitted</th>
                                                <th className='border-0 border-light'>Payment Pending</th>
                                                <th className='border-0 border-light'>Approved Amount</th>
                                                <th className='border-0 border-light'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className='border-bottom-0 border-light'>
                                                    Amount
                                                </th>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_80e != null && submissionDetails?.declarations?.declaration_80e?.interest_paid_on_loan_for_self_education != null ?
                                                            submissionDetails?.declarations?.declaration_80e?.interest_paid_on_loan_for_self_education : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80e != null && submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education != null ?
                                                            submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80e != null && submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document != null ?
                                                            submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        pendingSelfEducationLoan != null ? pendingSelfEducationLoan : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80e != null && submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_approved != null ?
                                                            submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_80e != null && submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status != null ?
                                                            submissionDetails?.submissions?.submission_80e?.interest_paid_on_loan_for_self_education_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="col-12 mt-1">
                                        <label className="label-control  font-weight-bold">Finance Remark</label>
                                        <span className="col-12 ml-3">
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.submission_80e != null && submissionDetails?.submissions?.submission_80e?.finance_remark != null ?
                                                    submissionDetails?.submissions?.submission_80e?.finance_remark : '-'
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div >
                        :
                        <></>
                }
                {
                    submissionDetails != null && submissionDetails?.submissions?.regime === 'OLD' ?
                        <div >
                            <div className="col-12 col-md-12 col-lg-12">
                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                    <GrPlan size={16} />&nbsp;Other Declarations&nbsp;
                                    <Tippy content={tooltipOthers}>
                                        <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                    </Tippy>
                                </h4>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <table class="table table-responsive mb-0">
                                        <thead>
                                            <tr >
                                                <th className='border-0 border-light'>Type of Investment </th>
                                                <th className='border-0 border-light'>Amount</th>
                                                <th className='border-0 border-light'>Final Declaration</th>
                                                <th className='border-0 border-light'>Document Submitted</th>
                                                <th className='border-0 border-light'>Payment Pending</th>
                                                <th className='border-0 border-light'>Approved Amount</th>
                                                <th className='border-0 border-light'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className='border-0 border-light'>Interest Paid On Housing Loan</th>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_other != null && submissionDetails?.declarations?.declaration_other?.interest_paid_on_housing_loan != null ?
                                                            submissionDetails?.declarations?.declaration_other?.interest_paid_on_housing_loan : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan != null ?
                                                            submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document != null ?
                                                            submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_document : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        pendingHousingLoan != null ? pendingHousingLoan : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_approved != null ?
                                                            submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_status != null ?
                                                            submissionDetails?.submissions?.submission_other?.interest_paid_on_housing_loan_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border-bottom-0 border-light'>Loss from House Property</th>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_other != null && submissionDetails?.declarations?.declaration_other?.loss_from_house_property != null ?
                                                            submissionDetails?.declarations?.declaration_other?.loss_from_house_property : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.loss_from_house_property != null ?
                                                            submissionDetails?.submissions?.submission_other?.loss_from_house_property : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.loss_from_house_property_document != null ?
                                                            submissionDetails?.submissions?.submission_other?.loss_from_house_property_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        pendingHousePropertyLoss != null ?
                                                            pendingHousePropertyLoss : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.loss_from_house_property_approved != null ?
                                                            submissionDetails?.submissions?.submission_other?.loss_from_house_property_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.loss_from_house_property_status != null ?
                                                            submissionDetails?.submissions?.submission_other?.loss_from_house_property_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border-bottom-0 border-light'>Rent Paid (HRA) Annually</th>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.declarations?.declaration_other != null && submissionDetails?.declarations?.declaration_other?.hra != null ?
                                                            submissionDetails?.declarations?.declaration_other?.hra : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.hra != null ?
                                                            submissionDetails?.submissions?.submission_other?.hra : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.hra_document != null ?
                                                            submissionDetails?.submissions?.submission_other?.hra_document : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        pendingHRA != null ?
                                                            pendingHRA : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.hra_approved != null ?
                                                            submissionDetails?.submissions?.submission_other?.hra_approved : '-'
                                                    }
                                                </td>
                                                <td className='border-bottom-0 border-light'>
                                                    {
                                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.hra_status != null ?
                                                            submissionDetails?.submissions?.submission_other?.hra_status : '-'
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="col-12 mt-1">
                                        <label className="label-control  font-weight-bold">Finance Remark</label>
                                        <span className="col-12 ml-3">
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.finance_remark != null ?
                                                    submissionDetails?.submissions?.submission_other?.finance_remark : '-'
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                {/* START:TYPE 1 */}
                <div >
                    <div className="col-12 col-md-12 col-lg-12">
                        <h4 className="d-flex align-items-center form-section mb-2 black">
                            <GrPlan size={16} />&nbsp;Flexible Plan Details&nbsp;
                            <Tippy content={tooltipFlexible}>
                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                            </Tippy>
                        </h4>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <table class="table table-responsive mb-0">
                                <thead>
                                    <tr >
                                        <th className='border-0 border-light'>Type of Investment </th>
                                        <th className='border-0 border-light'>Amount</th>
                                        <th className='border-0 border-light'>Final Declaration</th>
                                        <th className='border-0 border-light'>Document Submitted</th>
                                        <th className='border-0 border-light'>Payment Pending</th>
                                        <th className='border-0 border-light'>Approved Amount</th>
                                        <th className='border-0 border-light'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='border-bottom-0 border-light'>LTA</th>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.declarations?.lta != null ?
                                                    submissionDetails?.declarations?.lta : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.lta != null ?
                                                    submissionDetails?.submissions?.lta : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.lta_document != null ?
                                                    submissionDetails?.submissions?.lta_document : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                pendingLTA != null ? pendingLTA : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.lta_approved != null ?
                                                    submissionDetails?.submissions?.lta_approved : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.lta_status != null ?
                                                    submissionDetails?.submissions?.lta_status : '-'
                                            }
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <th className='border-bottom-0 border-light'>HDFC Meal Card</th>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.hdfc_food_card != null ?
                                                    submissionDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.hdfc_food_card != null ?
                                                    submissionDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.hdfc_food_card != null ?
                                                    submissionDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.hdfc_food_card != null ?
                                                    submissionDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.hdfc_food_card != null ?
                                                    submissionDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.hdfc_food_card != null ?
                                                    submissionDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.hdfc_food_card != null ?
                                                    submissionDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <th className='border-bottom-0 border-light'>Special Allowance</th>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.special_allowance != null ?
                                                    submissionDetails?.submissions?.special_allowance : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.special_allowance != null ?
                                                    submissionDetails?.submissions?.special_allowance : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.special_allowance_document != null ?
                                                    submissionDetails?.submissions?.special_allowance_document : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.special_allowance_pending != null ?
                                                    submissionDetails?.submissions?.special_allowance_pending : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.special_allowance_approved != null ?
                                                    submissionDetails?.submissions?.special_allowance_approved : '-'
                                            }
                                        </td>
                                        <td className='border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.special_allowance_status != null ?
                                                    submissionDetails?.submissions?.special_allowance_status : '-'
                                            }
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <div className="col-12 mt-1">
                                <label className="label-control  font-weight-bold">Finance Remark</label>
                                <span className="col-12 ml-3">
                                    {
                                        submissionDetails != null && submissionDetails?.submissions?.submission_other != null && submissionDetails?.submissions?.submission_other?.finance_remark != null ?
                                            submissionDetails?.submissions?.submission_other?.finance_remark : '-'
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div className="col-12 col-md-12 col-lg-12">
                        <h4 className="d-flex align-items-center form-section mb-2 black">
                            <GrPlan size={16} />&nbsp;Remarks
                        </h4>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <table class="table mb-0">
                                <tbody>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-0 border-light text-wrap'>Employee Remark</th>
                                        <td scope="col" className='col-md-6 border-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.emp_remark != null && submissionDetails?.submissions?.emp_remark != '' ?
                                                    submissionDetails?.submissions?.emp_remark : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Finance Remark</th>
                                        <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                            {
                                                submissionDetails != null && submissionDetails?.submissions?.finance_remark != null && submissionDetails?.submissions?.finance_remark != '' ?
                                                    submissionDetails?.submissions?.finance_remark : '-'
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <button className="btn btn-outline-secondary shadow round text-uppercase mb-2"
                    onClick={(event) => {
                        event.preventDefault();
                        if (props?.location?.state?.type === 'COMMENT_FROM_FINANCE_ON_ITD') {
                            props.history.push({
                                pathname: '/notification'
                            })
                        } else {
                            event.preventDefault();
                            props.history.goBack();
                        }
                    }}
                > Back</button>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ItSubmissionDetail = null;
    var ItSubmissionDownload = null;

    if (
        state.ItSubmissionDetailReducer != null &&
        Object.keys(state.ItSubmissionDetailReducer).length > 0 &&
        state.ItSubmissionDetailReducer.constructor === Object
    ) {
        switch (state.ItSubmissionDetailReducer.tag) {
            case ACTION_IT_SUBMISSION_DETAIL_SUCCESS:
                ItSubmissionDetail = Object.assign({}, state.ItSubmissionDetailReducer.data);
                delete state.ItSubmissionDetailReducer;
                return { ItSubmissionDetail };
            case ACTION_SUBMISSION_DOWNLOAD_SUCCESS:
                ItSubmissionDownload = Object.assign({}, state.ItSubmissionDetailReducer.data);
                delete state.ItSubmissionDetailReducer;
                return { ItSubmissionDownload };
            default:
        }
    }

    return {
        ItSubmissionDetail,
        ItSubmissionDownload
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItSubmissionDetail, actionItSubmissionDownload }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(SubmissionView))