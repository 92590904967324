import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_REIMBURSEMENT_CLAIM, API_REIMBURSEMENT_MEETINGS, API_TRANSPORT_LIST } from '../../../api/constants';
import { API_REIMBURSEMENT_CLAIM_EDIT } from '../../../api/constants'
import { PRINT } from '../../../utils';

export const ACTION_CONVEYANCE_CLAIM_LOADING = 'ACTION_CONVEYANCE_CLAIM_LOADING';
export const ACTION_CONVEYANCE_CLAIM_SUCCESS = 'ACTION_CONVEYANCE_CLAIM_SUCCESS';

export const ACTION_REIMBURSEMENT_MEETINGS_LOADING = 'ACTION_REIMBURSEMENT_MEETINGS_LOADING';
export const ACTION_REIMBURSEMENT_MEETINGS_SUCCESS = 'ACTION_REIMBURSEMENT_MEETINGS_SUCCESS';

export const ACTION_REIMBURSEMENT_CLAIM_EDIT_LOADING = 'ACTION_REIMBURSEMENT_CLAIM_EDIT_LOADING';
export const ACTION_REIMBURSEMENT_CLAIM_EDIT_SUCCESS = 'ACTION_REIMBURSEMENT_CLAIM_EDIT_SUCCESS';

export const ACTION_REIMBURSEMENT_TRANSPORT_TYPE_LOADING = 'ACTION_REIMBURSEMENT_TRANSPORT_TYPE_LOADING';
export const ACTION_REIMBURSEMENT_TRANSPORT_TYPE_SUCCESS = 'ACTION_REIMBURSEMENT_TRANSPORT_TYPE_SUCCESS';

export function actionConveyanceClaim(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_REIMBURSEMENT_CLAIM, params, files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CONVEYANCE_CLAIM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CONVEYANCE_CLAIM_LOADING, { loading: false, data }));
        });
}

export function actionReimbursementMeetings(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_MEETINGS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_MEETINGS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_MEETINGS_LOADING, { loading: false, data }));
        });
}

export function actionConveyanceClaimEdit(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_REIMBURSEMENT_CLAIM_EDIT, params, files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_CLAIM_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_CLAIM_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionReimbursementTransportType() {
    return (dispatch, getState) =>
        Api.get(API_TRANSPORT_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_TRANSPORT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_TRANSPORT_TYPE_LOADING, { loading: false, data }));
        });
}