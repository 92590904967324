import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../component/screen_title';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED, characterLimit } from '../../../../../constants';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { checkFileExtension } from '../../../../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import { actionHolidayEdit, ACTION_HOLIDAY_EDIT_SUCCESS } from './action';
import { actionHolidayView, ACTION_HOLIDAY_VIEW_SUCCESS } from './action';
import { actionHolidayEditBranch, ACTION_HOLIDAY_EDIT_BRANCH_SUCCESS } from './action';
import { toast } from 'react-toastify';
import DocumentIframe from '../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../utils';

const HolidayEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm();

    const minDateValue = new Date()
    const [holidayBranch, setHolidayBranch] = useState([]);
    const [postImage, setPostImage] = useState(null);
    const [holidayDetails, setHolidayDetails] = useState();
    const [mySelected, setMySelected] = useState(null)
    const [isPost, setIsPost] = useState(0);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const validExt = ['jpg', 'jpeg', 'png'];

    var watchShortDescription = watch('short_description');
    var watchDescription = watch('description');

    const initialRender = () => {
        props.actionHolidayEditBranch({ "is_unique": true });
        props.actionHolidayView({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onHolidayViewUpdate = () => {
        const { HolidayView } = props;
        if (HolidayView != null) {
            var HolidayViewResponse = Object.assign({}, HolidayView);
            if (HolidayViewResponse.result === true) {
                setHolidayDetails(HolidayViewResponse.response);
            } else {
                switch (HolidayViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onHolidayViewUpdate, [props.HolidayView]);

    useEffect(() => {
        if (holidayDetails != null) {
            setValue('title', holidayDetails.title);
            setValue('date', holidayDetails.date);
            // setValue('type', holidayDetails.type);
            setValue('type', holidayDetails.type === 'Holiday' ? 'holiday' : 'special');
            setValue('branch_address', holidayDetails.branch_address);
            setValue('pre_render', holidayDetails.pre_render);
            setValue('short_description', holidayDetails.short_description);
            setValue('description', (holidayDetails.description != null && holidayDetails.description != "null") ? holidayDetails.description : '');
            setValue('post_image', holidayDetails.post_image);
            setValue('is_post', holidayDetails.is_post);

            if (holidayDetails.is_post == 1) {
                setIsPost(1)
            }

        }
    }, [holidayDetails])

    const onHolidayEditsUpdate = () => {
        const { HolidayEdits } = props;
        var HolidayEditsResponse = Object.assign({}, HolidayEdits);
        $("#edit_holiday").prop("disabled", false);
        if (HolidayEditsResponse.result === true) {
            // $("#progress-loader").addClass("hidden");
            $("#edit-holiday-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Holiday Updated Successfully");
            props.history.goBack();
        } else {
            // $("#progress-loader").addClass("hidden");
            $("#edit-holiday-form-loader").addClass("hidden");
            switch (HolidayEditsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(HolidayEditsResponse.message);
                default:
            }
        }
    }
    useEffect(onHolidayEditsUpdate, [props.HolidayEdits]);

    const onHolidayBranchEditUpdate = () => {
        const { HolidayEditBranch } = props;
        var HolidayBranchEditResponse = Object.assign({}, HolidayEditBranch);
        if (HolidayBranchEditResponse.result === true) {
            setHolidayBranch(HolidayBranchEditResponse.response);
            setMySelected([])
        } else {
            switch (HolidayBranchEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onHolidayBranchEditUpdate, [props.HolidayEditBranch]);


    const onSubmit = (data) => {

        data.id = holidayDetails.id;
        data.user_id = props.auth.getUser().user_id;
        data.branch_address = JSON.stringify(mySelected);

        if (data.is_post == true) {
            data.is_post = 1;
        } else {
            data.is_post = 0;
        }

        var files = [];
        if (postImage != null) {
            files.push({ 'name': 'post_image', 'value': postImage });
        }

        delete data.post_image;

        // $("#progress-loader").removeClass("hidden");
        $("#edit-holiday-form-loader").removeClass("hidden");
        $("#edit_holiday").prop("disabled", true);

        props.actionHolidayEdit(data, files);
    }

    return (
        <>
            <ScreenTitle title="Edit Holiday" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Title&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="title"
                                                        name="title"
                                                        placeholder="Enter Title"
                                                        {...register('title', { required: 'Title is required' })}
                                                    />
                                                    {errors.title?.type === 'required' && <ErrorLabel message={errors.title?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"> Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                acceptCustomValue={false}
                                                                min={minDateValue}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Date is required'
                                                        }}
                                                    />
                                                    {errors.date && <ErrorLabel message={errors.date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        value: 'holiday',
                                                                        text: 'Holiday'

                                                                    },
                                                                    {
                                                                        value: 'special',
                                                                        text: 'Special'
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Type is required'
                                                        }}
                                                    />

                                                    {errors.type && <ErrorLabel message={errors.type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Branch&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="branch_address"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <TagBox
                                                                {...field}
                                                                dataSource={
                                                                    new ArrayStore({
                                                                        data: holidayBranch,
                                                                        key: 'city_id'
                                                                    })
                                                                }
                                                                dropDownOptions={{
                                                                    onInitialized: () => {
                                                                        console.log('initial')
                                                                    },
                                                                    onContentReady: () => {
                                                                        console.log('content ready')
                                                                    },
                                                                    onShowing: () => {
                                                                        console.log('showing')
                                                                    },
                                                                    onShown: () => {
                                                                        console.log('shown')
                                                                    }
                                                                }}
                                                                deferRendering={true}
                                                                displayExpr="city"
                                                                placeholder="Select Branch"
                                                                valueExpr="city_id"
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                acceptCustomValue={false}
                                                                maxDisplayedTags={4}
                                                                showSelectionControls={true}
                                                                showMultiTagOnly={false}
                                                                className="form-control"
                                                                stylingMode="underlined"
                                                                value={field.value != null && field.value.length > 0 ? field.value : mySelected}
                                                                onValueChanged={({ value, event }) => {
                                                                    if ((value != null || value !== undefined) && value.length > 0) {
                                                                        setMySelected(value)
                                                                        field.onChange(value);
                                                                    } else {
                                                                        if (mySelected != null && mySelected.length > 0) {
                                                                            field.onChange('');
                                                                            setMySelected([])
                                                                        }
                                                                    }
                                                                    if (event != null) {
                                                                        if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                            field.onChange('');
                                                                            setMySelected([])
                                                                        }
                                                                    }
                                                                }}
                                                                showClearButton={true}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select branch'
                                                        }}
                                                    />
                                                    {errors.branch_address && <ErrorLabel message={errors.branch_address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Pre render</label>
                                                    <Controller
                                                        name="pre_render"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        value: 0,
                                                                        text: 0

                                                                    },
                                                                    {
                                                                        value: 1,
                                                                        text: 1
                                                                    },
                                                                    {
                                                                        value: 2,
                                                                        text: 2
                                                                    },
                                                                    {
                                                                        value: 3,
                                                                        text: 3
                                                                    },
                                                                    {
                                                                        value: 4,
                                                                        text: 4
                                                                    },
                                                                    {
                                                                        value: 5,
                                                                        text: 5
                                                                    },
                                                                    {
                                                                        value: 6,
                                                                        text: 6
                                                                    },
                                                                    {
                                                                        value: 7,
                                                                        text: 7
                                                                    },
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Pre render"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.pre_render && <ErrorLabel message={errors.pre_render?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Short Description&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="short_description"
                                                        name="short_description"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Short Description"
                                                        {...register('short_description', { required: 'Short Description is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{watchShortDescription != null ? watchShortDescription.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.short_description?.type === 'required' && <ErrorLabel message={errors.short_description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Description"
                                                        {...register('description')}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{watchDescription != null ? watchDescription.length : 0}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="is_post"
                                                            id="is_post"
                                                            {...register('is_post')}
                                                            onChange={(event) => { setIsPost(event.target.checked); }}
                                                        />
                                                        {errors.is_post && <ErrorLabel message={errors.is_post?.message} />}
                                                        <label class="custom-control-label" for="is_post">Display Post</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isPost ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <fieldset class="form-group">
                                                            <label for="basicInputFile">Upload Image Here</label>
                                                            <div class="custom-file">
                                                                <input
                                                                    type="file"
                                                                    class="custom-file-input"
                                                                    id="post_image"
                                                                    accept=".jpg, .jpeg, .png"
                                                                    name="post_image"
                                                                    onChange={(event) => {
                                                                        if (
                                                                            event.target.files
                                                                        ) {

                                                                            var fileSize = event.target.files[0].size;

                                                                            var ext = (event.target.files[0].name).split('.').pop();

                                                                            if (checkFileExtension(ext, validExt)) {

                                                                                if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                    setPostImage(event.target.files[0]);
                                                                                } else {
                                                                                    Swal.fire({
                                                                                        title: 'Error',
                                                                                        icon: 'error',
                                                                                        html: MEETING_FILE_ERROR_MESSAGE,
                                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                                    }).then((result) => {

                                                                                    });
                                                                                }
                                                                            }
                                                                            else {
                                                                                Swal.fire({
                                                                                    title: 'Upload Error',
                                                                                    text: 'You can only upload jpg, jpeg & png files',
                                                                                    icon: 'error'
                                                                                }).then(success => {

                                                                                })
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                                <label class="custom-file-label" for="post_image">
                                                                    {
                                                                        postImage != null ?
                                                                            postImage.name : "Choose file"
                                                                    }
                                                                </label>
                                                                <div className="mt-1">
                                                                    {
                                                                        (holidayDetails != null && holidayDetails.post_image != null) ?
                                                                            <div id="view_document">
                                                                                {/* <a target="_blank" href={holidayDetails.post_image}>View Document</a> */}
                                                                                <a className="primary"
                                                                                    onClick={() => {
                                                                                        if (holidayDetails.post_image?.split('.').pop() == 'pdf' ||
                                                                                            holidayDetails.post_image?.split('.').pop() == 'PDF' ||
                                                                                            holidayDetails.post_image?.split('.').pop() == 'png' ||
                                                                                            holidayDetails.post_image?.split('.').pop() == 'jpg' ||
                                                                                            holidayDetails.post_image?.split('.').pop() == 'jpeg' ||
                                                                                            holidayDetails.post_image?.split('.').pop() == 'gif'
                                                                                        ) {
                                                                                            setSelectedDocumentIndex(holidayDetails.post_image)
                                                                                            setShowDocumentDetails(true);
                                                                                            setExtension(holidayDetails.post_image?.split('.').pop())
                                                                                            setDocumentDetails(holidayDetails)
                                                                                        } else {
                                                                                            downloadFile(holidayDetails.post_image);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    View Document
                                                                                </a>
                                                                                <a id="downloadLink" className="d-none"></a>
                                                                            </div>
                                                                            : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_holiday"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="edit-holiday-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/holidays');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var HolidayEdits = null;
    var HolidayView = null;
    var HolidayEditBranch = null;

    if (
        state.EditHolidayReducer != null &&
        Object.keys(state.EditHolidayReducer).length > 0 &&
        state.EditHolidayReducer.constructor === Object
    ) {
        switch (state.EditHolidayReducer.tag) {
            case ACTION_HOLIDAY_EDIT_SUCCESS:
                HolidayEdits = Object.assign({}, state.EditHolidayReducer.data);
                delete state.EditHolidayReducer
                return { HolidayEdits };
            case ACTION_HOLIDAY_VIEW_SUCCESS:
                HolidayView = Object.assign({}, state.EditHolidayReducer.data);
                delete state.EditHolidayReducer
                return { HolidayView };
            case ACTION_HOLIDAY_EDIT_BRANCH_SUCCESS:
                HolidayEditBranch = Object.assign({}, state.EditHolidayReducer.data);
                delete state.EditHolidayReducer
                return { HolidayEditBranch };
            default:
        }
    }

    return {
        HolidayEdits,
        HolidayView,
        HolidayEditBranch,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionHolidayEdit, actionHolidayView, actionHolidayEditBranch }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(HolidayEdit))