import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { API_REIMBURSEMENT_LIST } from "../../../../../../api/constants";
import { API_REIMBURSEMENT_REQUEST_STATUS } from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";
import { API_REIMBURSEMENT_ADD } from '../../../../../../api/constants';
import { API_REIMBURSEMENT_GET_MONTH } from '../../../../../../api/constants';
export const REIMBURSEMENT_LIST_LOADING = 'REIMBURSEMENT_LIST_LOADING';
export const REIMBURSEMENT_LIST_SUCCESS = 'REIMBURSEMENT_LIST_SUCCESS';

export const REIMBURSEMENT_ADD_LOADING = 'REIMBURSEMENT_ADD_LOADING';
export const REIMBURSEMENT_ADD_SUCCESS = 'REIMBURSEMENT_ADD_SUCCESS';

// export const REIMBURSEMENT_DETAIL_LIST_LOADING = 'REIMBURSEMENT_DETAIL_LIST_LOADING';
// export const REIMBURSEMENT_DETAIL_LIST_SUCCESS = 'REIMBURSEMENT_DETAIL_LIST_SUCCESS';

export const REIMBURSEMENT_REQUEST_STATUS_LOADING = 'REIMBURSEMENT_REQUEST_STATUS_LOADING';
export const REIMBURSEMENT_REQUEST_STATUS_SUCCESS = 'REIMBURSEMENT_REQUEST_STATUS_SUCCESS';

export const ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS = 'ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS';
export const ACTION_REIMBURSEMENT_GET_MONTH_LOADING = 'ACTION_REIMBURSEMENT_GET_MONTH_LOADING';

export function actionReimbursementList(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(REIMBURSEMENT_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(REIMBURSEMENT_LIST_LOADING, { loading: false, data }));
        });

}


export function actionReimbursementRequestStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_REQUEST_STATUS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(REIMBURSEMENT_REQUEST_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(REIMBURSEMENT_REQUEST_STATUS_LOADING, { loading: false, data }));
        });
}

export function actionReimbursementAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_REIMBURSEMENT_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE VIEW : ', data);
            dispatch(fetchSuccess(REIMBURSEMENT_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(REIMBURSEMENT_ADD_LOADING, { loading: false, data }));
        });
}
export function actionReimbursementGetMonth() {
    return (dispatch, getState) =>
        Api.get(API_REIMBURSEMENT_GET_MONTH).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REIMBURSEMENT_GET_MONTH_LOADING, { loading: false, data }));
        });
}