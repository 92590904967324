import React, { useState, useEffect } from "react";
import { Template } from "devextreme-react";
import $ from 'jquery';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { TagBox } from "devextreme-react/tag-box";
import ArrayStore from "devextreme/data/array_store";
import { FiEye } from "react-icons/fi";
import moment from "moment";
import ReimbursementAdd from '../../../../../../component/form/reimbursement_add';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRIMARY_COLOR, UNAUTHORIZED, ERROR } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { actionReimbursementList, REIMBURSEMENT_LIST_SUCCESS } from './action';
import { actionReimbursementRequestStatus, REIMBURSEMENT_REQUEST_STATUS_SUCCESS } from './action';
import { actionReimbursementAdd, REIMBURSEMENT_ADD_SUCCESS } from './action';
import { actionReimbursementGetMonth, ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS } from './action';
import { verifyPermission } from "../../../../../../utils";
import { withAuth } from "react-auth-guard";
import {  Controller, useForm } from "react-hook-form";

const ReimbursementList = (props) => {
    const [showreimbursementadd, setShowReimbursementAdd] = useState(false);
    const [reimbursementrequest, setReimbursementRequest] = useState();
    const [selectedFromMonth, setSelectedFromMonth] = useState((moment().subtract(6, 'months').format("YYYY-MM")));
    const [selectedToMonth, setSelectedToMonth] = useState(moment().format('YYYY-MM'));
    const [approvedMonth, setApprovedMonth] = useState(0);
    const [getMonth, setGetMonth] = useState([]);
    const [remMonth, setRemMonth] = useState(null);
    const [policyTypeValue, setPolicyTypeValue] = useState(["pending"]);
    const { control } = useForm({});

    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "rem_month", caption: "Month", visible: true },
        { dataField: "rem_year", caption: "Year", visible: true },
        { dataField: "claim_amount", caption: "Claim Amount", visible: true },
        { dataField: "approval_amount", caption: "Approval Amount", visible: true },
        { dataField: "issued_date", caption: "Issued Date", visible: false },
        { dataField: "issued_date_formated", caption: "Issued Date", visible: true },
        { dataField: "status", caption: "Status", visible: true },
        { dataField: "remark", caption: "Remark", visible: true },
    ];

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionReimbursementList({
            from_month: moment(selectedFromMonth, 'YYYY-MM').format('MM'),
            from_year: moment(selectedFromMonth, 'YYYY-MM').format('YYYY'),
            to_month: moment(selectedToMonth, 'YYYY-MM').format('MM'),
            to_year: moment(selectedToMonth, 'YYYY-MM').format('YYYY'),
            status: policyTypeValue,
        })
    }
    useEffect(initialRender, []);

    const [policyTypeDropdown, setPolicyTypeDropdown] = useState([
        {
          name: "Pending",
          value: "pending",
        },
        {
          name: "Approved",
          value: "approved",
        },
        {
          name: "Rejected",
          value: "rejected",
        }
      ]);

    useEffect(() => {
        $("#progress-loader").removeClass("hidden");
        props.actionReimbursementList({
            from_month: moment(selectedFromMonth, 'YYYY-MM').format('MM'),
            from_year: moment(selectedFromMonth, 'YYYY-MM').format('YYYY'),
            to_month: moment(selectedToMonth, 'YYYY-MM').format('MM'),
            to_year: moment(selectedToMonth, 'YYYY-MM').format('YYYY'),
            status: policyTypeValue,
        });
      }, [policyTypeValue]);

    const renderIssueDate = (cell) => {
        return <div>{cell?.data?.issued_date_formated != null ? moment(cell?.data?.issued_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission('VIEW_NEW_REIMBURSEMENT') ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: `/hrms/reimbursement_request/add`,
                                                    state: {
                                                        id: params.data.id,
                                                        month: params.data.month,
                                                        rem_month: params.data.rem_month + ' ' + params.data.rem_year,
                                                        rem_year: params.data.rem_year
                                                    }
                                                })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {/* <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                // onClick={() => {
                                //     props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                // }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        /> */}
                    </Column>
                );
            } else if (objColumn.dataField === "issued_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderIssueDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>

                )
            }
        });

        return arrColumns;
    };


    const onReimbursementRequestListUpdate = () => {
        const { ReimbursementRequestList } = props
        if (ReimbursementRequestList != null) {
            setReimbursementRequest([]);
            var ReimbursementRequestListResponse = Object.assign({}, ReimbursementRequestList);
            if (ReimbursementRequestListResponse.result === true) {
                props.actionReimbursementGetMonth()
                setReimbursementRequest(ReimbursementRequestListResponse.response.reimbursement_list);
                var approvedMonth = [];
                ReimbursementRequestListResponse.response.reimbursement_list.forEach(element => {
                    approvedMonth.push(element.rem_month);
                });
                if (approvedMonth.length > 0) {
                    setApprovedMonth(approvedMonth)
                }
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ReimbursementRequestListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onReimbursementRequestListUpdate, [props.ReimbursementRequestList])

    const onReimbursementRequestUpdate = () => {
        const { ReimbursementRequest } = props;
        var ReimbursementRequestResponse = Object.assign({}, ReimbursementRequest);
        if (ReimbursementRequestResponse.result === true) {
            props.history.push({
                pathname: `/hrms/reimbursement_request/add`,
                state: {
                    id: ReimbursementRequestResponse.response.reimbursement_id,
                    month: String(remMonth.rem_month),
                    rem_month: remMonth.rem_month_label,
                    rem_year: String(remMonth.rem_year)
                }
            })
        } else {
            switch (ReimbursementRequestResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    Swal.fire({
                        title: 'Error',
                        text: ReimbursementRequestResponse.message,
                        icon: 'error'
                    }).then(success => {
                    })
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementRequestUpdate, [props.ReimbursementRequest]);

    const onReimbursementRequestStatusUpdate = () => {
        const { ReimbursementStatus } = props;
        var ReimbursementStatusResponse = Object.assign({}, ReimbursementStatus);
        if (ReimbursementStatusResponse.result === true) {
            props.actionReimbursementList({
                from_month: moment(selectedFromMonth, 'YYYY-MM').format('MM'),
                from_year: moment(selectedFromMonth, 'YYYY-MM').format('YYYY'),
                to_month: moment(selectedToMonth, 'YYYY-MM').format('MM'),
                to_year: moment(selectedToMonth, 'YYYY-MM').format('YYYY'),
                status: policyTypeValue,
            });
            $("#progress-loader").addClass("hidden");
        } else {
            switch (ReimbursementStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementRequestStatusUpdate, [props.ReimbursementStatus])

    const onReimbursementGetMonthUpdate = () => {
        const { ReimbursementGetMonth } = props;
        var ReimbursementGetMonthResponse = Object.assign({}, ReimbursementGetMonth);
        if (ReimbursementGetMonthResponse.result === true) {
            setGetMonth(ReimbursementGetMonthResponse.response);
        } else {
            switch (ReimbursementGetMonthResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementGetMonthUpdate, [props.ReimbursementGetMonth]);

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Reimbursement Request {reimbursementrequest?.length === 0 ? '' : `(${reimbursementrequest?.length})`}
                        </h5>
                    </div>
                    {
                        verifyPermission('NEW_REIMBURSEMENT') ?
                            <div className="content-header-right col-12 col-md-12 col-lg-12">
                                <ul class="nav nav-pills float-right">
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                getMonth?.length <= 0 && approvedMonth != 0 ?
                                                    Swal.fire({
                                                        // title: 'Error',
                                                        icon: 'error',
                                                        text: `Reimbursement requests has been already raised for the current month & previous month`,
                                                        confirmButtonColor: PRIMARY_COLOR
                                                    }).then((result) => {
                                                    })
                                                    :
                                                    setShowReimbursementAdd(true)
                                            }}
                                        >
                                            New Reimbursement Request
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            :
                            <></>
                    }
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form nonValidate autoComplete="Off" >
                                    <div className='form-body'>
                                        <div className='row'>
                                        <div className="col-12 col-md-7 ">
                                            <div className="row">
                                            <div className='col-12 col-md-6'>
                                                <label className="label-control primary"> From Month</label>
                                                <input
                                                    type="month"
                                                    className="form-control"
                                                    id="from_month"
                                                    name="from_year"
                                                    placeholderText="Select Month"
                                                    defaultValue={selectedFromMonth}
                                                    max={moment().format('YYYY-MM')}
                                                    onChange={(event) => {
                                                        setSelectedFromMonth(event.target.value)
                                                        if (moment(event.target.value) <= moment(selectedToMonth)) {
                                                            props.actionReimbursementList({
                                                                from_month: moment(event.target.value, 'YYYY-MM').format('MM'),
                                                                from_year: moment(event.target.value, 'YYYY-MM').format('YYYY'),
                                                                to_month: moment(selectedToMonth, 'YYYY-MM').format('MM'),
                                                                to_year: moment(selectedToMonth, 'YYYY-MM').format('YYYY'),
                                                                status: policyTypeValue,
                                                            })
                                                        } else {
                                                            setSelectedToMonth('')
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "From month and year can't be greater than To month and year",
                                                                icon: 'error'
                                                            }).then(success => {
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <label className="label-control primary"> To Month</label>
                                                <input
                                                    type="month"
                                                    className="form-control"
                                                    id="to_month"
                                                    name="to_year"
                                                    placeholderText="Select Month"
                                                    defaultValue={selectedToMonth}
                                                    max={moment().format('YYYY-MM')}
                                                    onChange={(event) => {
                                                        setSelectedToMonth(event.target.value)
                                                        if (moment(event.target.value) >= moment(selectedFromMonth)) {
                                                            props.actionReimbursementList({
                                                                from_month: moment(selectedFromMonth, 'YYYY-MM').format('MM'),
                                                                from_year: moment(selectedFromMonth, 'YYYY-MM').format('YYYY'),
                                                                to_month: moment(event.target.value, 'YYYY-MM').format('MM'),
                                                                to_year: moment(event.target.value, 'YYYY-MM').format('YYYY'),
                                                                status: policyTypeValue,
                                                            })
                                                        } else {
                                                            setSelectedFromMonth('')
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "To month and year can't be less than from month and year",
                                                                icon: 'error'
                                                            }).then(success => {
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            </div>
                                            </div>
                                            
                                         
                                            <div className="col-12 col-md-3 float-right">
                  <div className="form-group cust-tagbox ">
                  <label className="label-control primary">Status</label>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <TagBox
                          {...field}
                          dataSource={
                            new ArrayStore({
                              data: policyTypeDropdown,
                              key: "id",
                            })
                          }
                          dropDownOptions={{
                            onInitialized: () => {
                              console.log("initial");
                            },
                            onContentReady: () => {
                              console.log("content ready");
                            },
                            onShowing: () => {
                              console.log("showing");
                            },
                            onShown: () => {
                              console.log("shown");
                            },
                          }}
                          deferRendering={true}
                          displayExpr="name"
                          valueExpr="value"
                          name="status"
                          id="status"
                          placeholder="Select Status"
                          searchEnabled={true}
                          searchMode="contains"
                          maxDisplayedTags={2}
                          showMultiTagOnly={false}
                          stylingMode="underlined"
                          value={field.value ? field.value : policyTypeValue}
                          onValueChanged={({ value }) => {
                            if (value != null || value !== undefined) {
                              setPolicyTypeValue(value);
                              field.onChange(value);
                            }
                          }}
                          showClearButton={true}
                        />
                      )}
                    />
                  </div>
             
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <DataGrid
                                    dataSource={reimbursementrequest}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Reimbursement Request List",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("issued_date_formated", "visible", false)
                                        event.component.columnOption("issued_date", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("issued_date_formated", "visible", true)
                                        event.component.columnOption("issued_date", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                    onToolbarPreparing={(e) => {
                                        e.toolbarOptions.items.unshift({
                                            location: 'before',
                                            template: 'totalGroupCount',

                                        })
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}

                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                    <Template name="totalGroupCount" render={() => {
                                        return (
                                            <></>
                                        );
                                    }} />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ReimbursementAdd
                {...props}
                show={showreimbursementadd}
                months={getMonth}
                handleClose={(newReimbursement) => {
                    setShowReimbursementAdd(false)
                    if (newReimbursement != null) {
                        setRemMonth(newReimbursement.month_year);
                        props.actionReimbursementAdd({ rem_month: String(newReimbursement.month_year.rem_month), rem_year: String(newReimbursement.month_year.rem_year) });
                    }
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var ReimbursementRequestList = null;
    // var ReimbursementAddCreate = null;
    var ReimbursementStatus = null;
    var ReimbursementRequest = null;
    var ReimbursementGetMonth = null;

    if (
        state.ReimbursementListReducer != null &&
        Object.keys(state.ReimbursementListReducer).length > 0 &&
        state.ReimbursementListReducer.constructor === Object
    ) {
        switch (state.ReimbursementListReducer.tag) {
            case REIMBURSEMENT_LIST_SUCCESS:
                ReimbursementRequestList = Object.assign({}, state.ReimbursementListReducer.data);
                delete state.ReimbursementListReducer;
                return { ReimbursementRequestList };
            case REIMBURSEMENT_ADD_SUCCESS:
                ReimbursementRequest = Object.assign({}, state.ReimbursementListReducer.data);
                delete state.ReimbursementListReducer
                return { ReimbursementRequest };
            case REIMBURSEMENT_REQUEST_STATUS_SUCCESS:
                ReimbursementStatus = Object.assign({}, state.ReimbursementListReducer.data);
                delete state.ReimbursementListReducer;
                return { ReimbursementStatus };
            case ACTION_REIMBURSEMENT_GET_MONTH_SUCCESS:
                ReimbursementGetMonth = Object.assign({}, state.ReimbursementListReducer.data);
                delete state.ReimbursementListReducer;
                return { ReimbursementGetMonth };
            default:
        }
    }

    return {
        ReimbursementRequestList,
        // ReimbursementAddCreate,
        ReimbursementStatus,
        ReimbursementRequest,
        ReimbursementGetMonth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReimbursementList, actionReimbursementRequestStatus, actionReimbursementAdd, actionReimbursementGetMonth, }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReimbursementList));
