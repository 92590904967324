import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import PlacementContactDetails from "../contact_details";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TagBox } from "devextreme-react";
import ArrayStore from 'devextreme/data/array_store';
import { Controller, useForm } from "react-hook-form";
import { ACTION_MASTER_GET_SPECIFIC_PREFERENCE_SUCCESS, actionMasterSpecificPreference } from "./action";
import { ACTION_GET_DEPARTMENT_SUCCESS, actionGetDepartment } from "./action";
import { ACTION_GET_POLICY_TYPE_SUCCESS, actionGetPolicyType } from "./action";
import { ACTION_GET_INSURER_SUCCESS, actionGetCompanyList } from "./action";
import { ACTION_PREFERENCE_DELETE_SUCCESS, actionPreferenceDelete } from "./action";
import { KEY_TOAST_MESSAGE, getData } from "../../../../../../utils/preferences";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../../../../constants";
import { removeData } from "jquery";
import { toast } from "react-toastify";
import $ from 'jquery';
import { verifyPermission } from "../../../../../../utils";
import Swal from "sweetalert2";

const SpecificPlacement = (props) => {
    const { match } = props;
    const { control, formState: { errors }, setValue } = useForm({});
    const [selectedPolicyValue, setSelectedPolicyValue] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [policyList, setPolicyList] = useState([])
    const [selectedDepartmentValue, setSelectedDepartmentValue] = useState();
    const [selectedCompanyValue, setSelectedCompanyValue] = useState([]);
    const [insurerList, setInsurerList] = useState([])
    const [placementDetails, setPlacementDetails] = useState(null)
    const [showPlacementContactDetails, setShowPlacementContactDetails] = useState(false)
    const [specificPreferenceList, setGetSpecificPreferenceList] = useState([])
    const [placementsRight, setPlacementsRight] = useState(null)

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionMasterSpecificPreference();
        props.actionGetDepartment({
            status: "ACTIVE", type: 'specific'
        });
        props.actionGetCompanyList({ status: "ACTIVE", type: 'specific' })
    }
    useEffect(initialRender, []);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button", visible: true },
        { dataField: "insurer_short_name", caption: "Insurer", visible: true },
        { dataField: "department_name", caption: "Department", visible: true },
        { dataField: "policy_type", caption: "Policy", visible: true },
        { dataField: "premium_range", caption: "Premium Range", visible: true },
        { dataField: "sum_insured", caption: "Sum Insured", visible: true },
        { dataField: "city", caption: "City", visible: true },
        { dataField: "Contact", caption: "Contact", type: "button" },
        { dataField: "premium_min", caption: "Premium Min", visible: false },
        { dataField: "premium_max", caption: "Premium Max", visible: false },
        { dataField: "sum_insured_min", caption: "Sum Insured Min", visible: false },
        { dataField: "sum_insured_max", caption: "Sum Insured Max", visible: false },
    ]);

    const renderPremiumRange = (cell) => {
        return <div>{cell?.data?.premium_min != null && cell?.data?.premium_max != null ? (cell?.data?.premium_min) + '-' + (cell?.data?.premium_max) : ''}</div>
    }
    const renderSumInsuredRange = (cell) => {
        return <div>{cell?.data?.sum_insured_min != null && cell?.data?.sum_insured_max != null ? (cell?.data?.sum_insured_min) + '-' + (cell?.data?.sum_insured_max) : ''}</div>
    }

    const onMasterGetSpecificPreferenceUpdate = () => {
        const { MasterGetSpecificPreference } = props
        if (MasterGetSpecificPreference != null) {
            var MasterGetSpecificPreferenceResponse = Object.assign({}, MasterGetSpecificPreference);
            if (MasterGetSpecificPreferenceResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setGetSpecificPreferenceList(MasterGetSpecificPreferenceResponse.response?.data);
                setPlacementsRight(MasterGetSpecificPreferenceResponse.response?.authorized)
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetSpecificPreferenceResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetSpecificPreferenceUpdate, [props.MasterGetSpecificPreference])

    const onMasterGetDepartmentUpdate = () => {
        const { MasterGetDepartment } = props
        if (MasterGetDepartment != null) {
            var MasterGetDepartmentResponse = Object.assign({}, MasterGetDepartment);
            if (MasterGetDepartmentResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setDepartmentList(MasterGetDepartmentResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetDepartmentResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetDepartmentUpdate, [props.MasterGetDepartment])

    const onMasterGetPolicyListUpdate = () => {
        const { MasterGetPolicyList } = props
        if (MasterGetPolicyList != null) {
            var MasterGetPolicyListResponse = Object.assign({}, MasterGetPolicyList);
            if (MasterGetPolicyListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPolicyList(MasterGetPolicyListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetPolicyListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetPolicyListUpdate, [props.MasterGetPolicyList])

    const onMasterGetInsurerListUpdate = () => {
        const { MasterGetInsurerList } = props
        if (MasterGetInsurerList != null) {
            var MasterGetInsurerListResponse = Object.assign({}, MasterGetInsurerList);
            if (MasterGetInsurerListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setInsurerList(MasterGetInsurerListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (MasterGetInsurerListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetInsurerListUpdate, [props.MasterGetInsurerList])

    const onPreferenceDeleteUpdate = () => {
        const { PreferenceDelete } = props
        if (PreferenceDelete != null) {
            var PreferenceDeleteResponse = Object.assign({}, PreferenceDelete);
            if (PreferenceDeleteResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                toast.success(PreferenceDeleteResponse.message);
                props.actionMasterSpecificPreference();
            } else {
                toast.error(PreferenceDeleteResponse.message);
                $("#progress-loader").addClass("hidden");
                switch (PreferenceDeleteResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onPreferenceDeleteUpdate, [props.PreferenceDelete])

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            placementsRight === true ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { data: params.data, contactData: params.data.contacts } });
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            placementsRight === true ?
                                < Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { data: params.data } });
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            placementsRight === true ?
                                < Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                Swal.fire({
                                                    icon: 'warning',
                                                    text: 'Are you sure, you want to delete this preference?',
                                                    cancelButtonText: "Cancel",
                                                    showCancelButton: true,
                                                }).then(({ isConfirmed }) => {
                                                    if (isConfirmed) {
                                                        props.actionPreferenceDelete({ id: params.data.id })
                                                    }
                                                })
                                            }}
                                        >
                                            <FiTrash2 size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "Contact") {
                arrColumns.push(
                    <Column dataField="contact" type="buttons">
                        {
                            // verifyPermission("VIEW") ?
                            <Button
                                render={(params) => (
                                    <button
                                        className="btn btn-icon btn-sm"
                                        onClick={() => {
                                            setPlacementDetails(params?.data)
                                            setShowPlacementContactDetails(true)
                                        }}
                                    >
                                        <FiEye size={16} className="primary" />
                                    </button>
                                )}
                            />
                            // :
                            // <></>
                        }

                    </Column>
                );
            } else if (objColumn.dataField === "premium_range") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderPremiumRange}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "sum_insured") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSumInsuredRange}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Specific Preference List</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {
                                placementsRight === true ?
                                    <li class="nav-item">
                                        <button
                                            className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                            onClick={() => {
                                                props.history.push(`${match.url}/add`);
                                            }}
                                        >
                                            Add Specific Preference
                                        </button>
                                    </li>
                                    :
                                    <></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="pb-2">
                    <div className="card">
                        <div className="card-body pb-0">
                            <form noValidate autoComplete="Off" >
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <h4 className=" mb-0">Filters</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group mb-0">
                                                <label className="label-control">Type of Department</label>
                                                <Controller
                                                    name="department_id"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="department_id"
                                                            name="department_id"
                                                            dataSource={departmentList}
                                                            allowFiltering={false}
                                                            placeholder="Select Department"
                                                            value={field.value}
                                                            fields={{ text: 'name', value: 'id' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.id)
                                                                    setSelectedDepartmentValue(event.itemData.id)
                                                                    setSelectedPolicyValue([]);
                                                                    setValue('policy_type', null);
                                                                    props.actionGetPolicyType({ 'department_id': event.itemData.id, status: "ACTIVE" });
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group cust-tagbox mb-0">
                                                <label className="label-control">Type of Policy&nbsp;</label>
                                                <Controller
                                                    name="policy_type"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: policyList,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select Type of Policy"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            acceptCustomValue={false}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            disabled={selectedDepartmentValue != null ? false : true}
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedPolicyValue}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedPolicyValue(value);
                                                                    setSelectedCompanyValue([])
                                                                    setValue('company_name', null);
                                                                    field.onChange(value);
                                                                    props.actionGetCompanyList({ 'policy_type_id': value, status: "ACTIVE", type: 'specific' })

                                                                } else {
                                                                    if (selectedPolicyValue != null && selectedPolicyValue.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedPolicyValue([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedPolicyValue([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group mb-0">
                                                <label className="label-control">Insurance Company</label>
                                                <Controller
                                                    name="company_name"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="company_name"
                                                            name="company_name"
                                                            dataSource={insurerList}
                                                            allowFiltering={true}
                                                            filterType={"Contains"}
                                                            placeholder="Select Company name"
                                                            value={field.value}
                                                            fields={{ text: 'name', value: 'id' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.id)
                                                                    setSelectedCompanyValue([event.itemData.id])
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-4 col-md-auto pl-1">
                                            <div className="form-actions mt-0 border-0 float-none">
                                                <button
                                                    id="apply"
                                                    type='submit'
                                                    className="btn btn-primary mr-8  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        props.actionMasterSpecificPreference({
                                                            "policy_type_id": selectedPolicyValue,
                                                            "insurance_id": selectedCompanyValue,
                                                            "department_id": selectedDepartmentValue
                                                            // selectedDepartmentValue,
                                                            // selectedCompanyValue
                                                        });
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Apply
                                                        <span id="apply-filter-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-auto">
                                            <div className="form-actions mt-0 border-0 float-none">
                                                <button
                                                    id="reset"
                                                    type='button'
                                                    className="btn btn-primary  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        setSelectedPolicyValue([]);
                                                        setSelectedDepartmentValue(null);
                                                        setSelectedCompanyValue([]);
                                                        setValue('department_id', null);
                                                        setValue('policy_type', null);
                                                        setValue('company_name', null);
                                                        setValue('preference_rating', null);
                                                        setPolicyList([])
                                                        props.actionMasterSpecificPreference();
                                                    }
                                                    }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Reset
                                                        <span id="reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid

                                    dataSource={specificPreferenceList}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Specific Preference",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("premium_min", "visible", true)
                                        event.component.columnOption("premium_max", "visible", true)
                                        event.component.columnOption("sum_insured_min", "visible", true)
                                        event.component.columnOption("sum_insured_max", "visible", true)
                                        event.component.columnOption("premium_range", "visible", false)
                                        event.component.columnOption("sum_insured", "visible", false)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("premium_min", "visible", false)
                                        event.component.columnOption("premium_max", "visible", false)
                                        event.component.columnOption("sum_insured_min", "visible", false)
                                        event.component.columnOption("sum_insured_max", "visible", false)
                                        event.component.columnOption("premium_range", "visible", true)
                                        event.component.columnOption("sum_insured", "visible", true)
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <PlacementContactDetails
                show={showPlacementContactDetails}
                data={placementDetails}
                handleClose={(newContact) => {
                    setShowPlacementContactDetails(false)
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    var MasterGetSpecificPreference = null;
    var MasterGetDepartment = null;
    var MasterGetPolicyList = null;
    var MasterGetInsurerList = null;
    var PreferenceDelete = null;

    if (
        state.MasterGetSpecificPreferenceReducer != null &&
        Object.keys(state.MasterGetSpecificPreferenceReducer).length > 0 &&
        state.MasterGetSpecificPreferenceReducer.constructor === Object
    ) {
        switch (state.MasterGetSpecificPreferenceReducer.tag) {
            case ACTION_MASTER_GET_SPECIFIC_PREFERENCE_SUCCESS:
                MasterGetSpecificPreference = Object.assign({}, state.MasterGetSpecificPreferenceReducer.data);
                delete state.MasterGetSpecificPreferenceReducer;
                return { MasterGetSpecificPreference };
            case ACTION_GET_DEPARTMENT_SUCCESS:
                MasterGetDepartment = Object.assign({}, state.MasterGetSpecificPreferenceReducer.data);
                delete state.MasterGetSpecificPreferenceReducer;
                return { MasterGetDepartment };
            case ACTION_GET_POLICY_TYPE_SUCCESS:
                MasterGetPolicyList = Object.assign({}, state.MasterGetSpecificPreferenceReducer.data);
                delete state.MasterGetSpecificPreferenceReducer;
                return { MasterGetPolicyList };
            case ACTION_GET_INSURER_SUCCESS:
                MasterGetInsurerList = Object.assign({}, state.MasterGetSpecificPreferenceReducer.data);
                delete state.MasterGetSpecificPreferenceReducer;
                return { MasterGetInsurerList };
            case ACTION_PREFERENCE_DELETE_SUCCESS:
                PreferenceDelete = Object.assign({}, state.MasterGetSpecificPreferenceReducer.data);
                delete state.MasterGetSpecificPreferenceReducer;
                return { PreferenceDelete };
            default:

        }
    }
    return {
        MasterGetSpecificPreference,
        MasterGetDepartment,
        MasterGetPolicyList,
        MasterGetInsurerList,
        PreferenceDelete,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterSpecificPreference, actionGetDepartment, actionGetPolicyType, actionGetCompanyList, actionPreferenceDelete }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(SpecificPlacement))


