import React, { useState, useEffect } from "react";
import ScreenTitle from '../../../../../component/screen_title';
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, SearchPanel, Pager, Paging } from 'devextreme-react/data-grid';
import $ from "jquery";
import { UNAUTHORIZED } from "../../../../../constants";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from "react-auth-guard";
import moment from "moment";
import { ACTION_WELLNESS_MANAGEMENT_REPORT_SUCCESS, actionWellnessManagementReport } from "./action";

const WellnessManagementReport = (props) => {
    const [wellnessReport, setWellnessReport] = useState();

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionWellnessManagementReport();
    }
    useEffect(initialRender, []);

    const columns = [
        { dataField: "created_date", caption: "Created Date", visible: false },
        { dataField: "created_date_formated", caption: "Created Date", visible: true },
        { dataField: "client_name", caption: "Client Name", visible: true },
        { dataField: "address", caption: "Location Address", visible: true },
        { dataField: "assign_rm", caption: "Assign RM", visible: true },
        { dataField: "activity_date", caption: "Date of Activity", visible: false },
        { dataField: "activity_date_formated", caption: "Date of Activity", visible: true },
        { dataField: "approved_by", caption: "Approved By", visible: true },
        { dataField: "raised_by", caption: "Raised By", visible: true },
        { dataField: "actual_activity_date", caption: "Actual Date of Activity", visible: false },
        { dataField: "actual_activity_date_formated", caption: "Actual Date of Activity", visible: true },
        { dataField: "remarks", caption: "Remark", visible: true },
        { dataField: "status_name", caption: "Status", visible: true },
        { dataField: "rm_status_name", caption: "RM Status", visible: true }
    ];

    const renderRMStatus = (cell) => {
        return <div>{cell.data.rm_status_name != null && cell.data.rm_status_name != "Not Defined" ? cell.data.rm_status_name : ''}</div>;
    }

    const renderStatusName = (cell) => {
        return <div>{cell.data.status_name != null && cell.data.status_name != "Not Defined" ? cell.data.status_name : ''}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActivityDate = (cell) => {
        return <div>{cell?.data?.activity_date_formated != null ? moment(cell?.data?.activity_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActualActivityDate = (cell) => {
        return <div>{cell?.data?.actual_activity_date_formated != null ? moment(cell?.data?.actual_activity_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStatusName}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "rm_status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRMStatus}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "created_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "activity_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActivityDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "actual_activity_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActualActivityDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const onWellnessManagementsReportUpdate = () => {
        const { WellnessManagementsReport } = props;
        if (WellnessManagementsReport != null) {
            var WellnessManagementsReportResponse = Object.assign({}, WellnessManagementsReport);

            if (WellnessManagementsReportResponse.result === true) {
                setWellnessReport(WellnessManagementsReportResponse.response);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (WellnessManagementsReportResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onWellnessManagementsReportUpdate, [props.WellnessManagementsReport])

    return (
        <section>
            <ScreenTitle title="Wellness Management Report" />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={wellnessReport}
                                showBorders={true}
                                export={{
                                    fileName: "Wellness Report",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("created_date_formated", "visible", false)
                                    event.component.columnOption("created_date", "visible", true)
                                    event.component.columnOption("activity_date_formated", "visible", false)
                                    event.component.columnOption("activity_date", "visible", true)
                                    event.component.columnOption("actual_activity_date_formated", "visible", false)
                                    event.component.columnOption("actual_activity_date", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("created_date_formated", "visible", true)
                                    event.component.columnOption("created_date", "visible", false)
                                    event.component.columnOption("activity_date_formated", "visible", true)
                                    event.component.columnOption("activity_date", "visible", false)
                                    event.component.columnOption("actual_activity_date_formated", "visible", true)
                                    event.component.columnOption("actual_activity_date", "visible", false)
                                    event.component.endUpdate();
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}
const mapStateToProps = (state) => {
    var WellnessManagementsReport = null;
    if (
        state.WellnessManagementReportReducer != null &&
        Object.keys(state.WellnessManagementReportReducer).length > 0 &&
        state.WellnessManagementReportReducer.constructor === Object
    ) {
        switch (state.WellnessManagementReportReducer.tag) {
            case ACTION_WELLNESS_MANAGEMENT_REPORT_SUCCESS:
                WellnessManagementsReport = Object.assign({}, state.WellnessManagementReportReducer.data);
                delete state.WellnessManagementReportReducer;
                return { WellnessManagementsReport };
            default:
        }
    }
    return {
        WellnessManagementsReport,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionWellnessManagementReport
        },
        dispatch
    );
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessManagementReport))