import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from "jquery";
import moment from 'moment';
import { actionViewOpportunityQuestion, ACTION_VIEW_OPPORTUNITY_QUESTION_SUCCESS } from './action';
import { UNAUTHORIZED } from '../../../constants';
import { linkify } from '../../../utils';
import HTMLParser from 'html-react-parser';

const ProposalDetails = (props) => {
    const [showViewOpportunityQuestions, setShowViewOpportunityQuestions] = useState([]);

    const initialRender = () => {

        if (props.show === true) {
            props.actionViewOpportunityQuestion({ id: props.proposalDetails.id })
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const renderRemarks = (remark) => {
        try {
            var formatText = remark
            if (props.proposalDetails != null && props.proposalDetails.client_remark != null) {
                formatText = linkify(formatText)
            }
        } catch (e) {
            console.log("Format dashboad Text")
            console.log(e)
        }
        try {
            return (HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>')))
        } catch (e) {
            return (<>{formatText}</>)
        }
    }

    const onViewOpportunityQuestionUpdate = () => {
        const { ViewOpportunityQuestion } = props
        var ViewOpportunityQuestionResponse = Object.assign({}, ViewOpportunityQuestion);
        if (ViewOpportunityQuestionResponse.result === true) {
            setShowViewOpportunityQuestions(ViewOpportunityQuestionResponse.response)
        } else {
            switch (ViewOpportunityQuestionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onViewOpportunityQuestionUpdate, [props.ViewOpportunityQuestion])

    return (
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Opportunity Details
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Opportunity ID</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.id != null ? props.proposalDetails.id : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Assign RM</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.assigned_rm_name != null ? props.proposalDetails.assigned_rm_name : '-'}
                                </h4>
                            </div>
                            {
                                props?.proposalDetails?.oppurtunity_type === 'Renewal' ?
                                    <>
                                        <div className="col-12 col-md-6 col-lg-6 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Control Number</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {props.proposalDetails != null && props.proposalDetails.control_no != null ? props.proposalDetails.control_no : '-'}
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mb-1">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Number</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {props.proposalDetails != null && props.proposalDetails.policy_no != null ? props.proposalDetails.policy_no : '-'}
                                            </h4>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Opportunity Type</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.oppurtunity_type != null ? props.proposalDetails.oppurtunity_type : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Type</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.policy_type != null ? props.proposalDetails.policy_type : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Department</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.department_name != null ? props.proposalDetails.department_name : '-'}
                                </h4>
                            </div>
                            <div className={`col-12 col-md-6 col-lg-6 mb-1 ${props?.proposalDetails?.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Intermediary Type</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.intermediary_type != null ? props.proposalDetails.intermediary_type : '-'}
                                </h4>
                            </div>
                            <div className={`col-12 col-md-6 col-lg-6 mb-1 ${props?.proposalDetails?.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">{`${props?.proposalDetails?.intermediary_type != 'Broker' ? 'Intermediary Details' : 'Broker Name'}`}</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.intermediary_value != null ? props.proposalDetails.intermediary_value : '-'}
                                </h4>
                            </div>
                            <div className={`col-12 col-md-6 col-lg-6 mb-1 ${props.proposalDetails != null && props?.proposalDetails?.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}>
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurance Company</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.insurer_name != null ? props.proposalDetails.insurer_name : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Expected Premium</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.expected_premium != null ?
                                        <div><span>&#8377;</span> {props.proposalDetails.expected_premium} </div> : '-'}
                                </h4>
                            </div>
                            {
                                props.proposalDetails != null && props.proposalDetails.oppurtunity_type != null && props?.proposalDetails?.oppurtunity_type != 'Fresh' ?
                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Renewal Date</h6>
                                        <h4 className="font-small-2 text-bold-700 text-justify">
                                            {props.proposalDetails != null && props.proposalDetails.expiring_date != null ? props.proposalDetails.expiring_date : '-'}
                                        </h4>
                                    </div>
                                    :
                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Expected Closure Date</h6>
                                        <h4 className="font-small-2 text-bold-700 text-justify">
                                            {props.proposalDetails != null && props.proposalDetails.expiring_date != null ? props.proposalDetails.expiring_date : '-'}
                                        </h4>
                                    </div>
                            }
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">RM Probability </h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.probability != null ? props.proposalDetails.probability + '%' : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Created At</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.created_at != null ? props.proposalDetails.created_at : '-'}
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Remark</h6>
                                <h4 className="font-small-2 text-bold-700 text-justify">
                                    {props.proposalDetails != null && props.proposalDetails.client_remark != null ?  renderRemarks(props.proposalDetails.client_remark) : '-'}
                                </h4>
                            </div>
                        </div>
                        {
                            showViewOpportunityQuestions.opportunity_questions != null && showViewOpportunityQuestions.opportunity_questions.length > 0 ?
                                <>
                                    <div className="col-12 col-md-12 col-lg-12 pl-0">
                                        <h4 className="modal-title" id="myModalLabel1">
                                            More Information
                                        </h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1">System Probability</h6>
                                            <h4 className="font-small-2 text-bold-700 text-justify">
                                                {showViewOpportunityQuestions.total_probability != null ? showViewOpportunityQuestions.total_probability : '-'}
                                            </h4>
                                        </div>
                                        {
                                            showViewOpportunityQuestions.opportunity_questions != null && showViewOpportunityQuestions.opportunity_questions.length > 0 ?
                                                showViewOpportunityQuestions.opportunity_questions.map((item, index) => (
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1">{item.question}</h6>
                                                        <h4 className="font-small-2 text-bold-700 text-justify mt-1">{item.question_value}
                                                        </h4>
                                                    </div>
                                                ))

                                                :
                                                <></>
                                        }
                                    </div>
                                </>
                                :
                                <></>
                        }
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.handleClose();
                                }}
                            >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

const mapStateToProps = (state) => {
    var ViewOpportunityQuestion = null;

    if (
        state.OpportunityQuestionViewReducer != null &&
        Object.keys(state.OpportunityQuestionViewReducer).length > 0 &&
        state.OpportunityQuestionViewReducer.constructor === Object
    ) {
        switch (state.OpportunityQuestionViewReducer.tag) {
            case ACTION_VIEW_OPPORTUNITY_QUESTION_SUCCESS:
                ViewOpportunityQuestion = Object.assign({}, state.OpportunityQuestionViewReducer.data);
                delete state.OpportunityQuestionViewReducer;
                return { ViewOpportunityQuestion };
            default:
        }
    }
    return {
        ViewOpportunityQuestion,

    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionViewOpportunityQuestion,

        },
        dispatch
    );
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProposalDetails))