import { ACTION_INTERNAL_MEETING_EDIT_SUCCESS } from "./action";
import { ACTION_ACCOMPAINED_BY_SUCCESS } from "./action";
import { ACTION_INTERNAL_MEETING_VIEW_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const InternalMeetingsEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_INTERNAL_MEETING_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INTERNAL_MEETING_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    }, 
    [ACTION_INTERNAL_MEETING_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INTERNAL_MEETING_VIEW_SUCCESS,
                loading: false,
            }
        );
    },       
});
export default InternalMeetingsEditReducer;