import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_INSURER_VIEW_TAB_MEETINGS } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_INSURER_VIEW_TAB_MEETINGS_LOADING = 'ACTION_INSURER_VIEW_TAB_MEETINGS_LOADING';
export const ACTION_INSURER_VIEW_TAB_MEETINGS_SUCCESS = 'ACTION_INSURER_VIEW_TAB_MEETINGS_SUCCESS';

export function actionInsurerViewTabMeetings(params) {
        return (dispatch, getState) =>
        Api.post(API_INSURER_VIEW_TAB_MEETINGS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_INSURER_VIEW_TAB_MEETINGS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURER_VIEW_TAB_MEETINGS_LOADING, { loading: false, data }));
        });
    }

