import { DateBox } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import dateFormat from 'dateformat';
import $ from "jquery";

const ClaimMISReport = (props) => {
    const { control } = useForm();
    const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [fromDate, setFromDate] = useState(`04/01/${new Date().getFullYear()}`);

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }

    }
    useEffect(initialRender, [props.show]);

    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="flex-column">
                                <h5 className="font-medium-1 text-black text-bold-600 mt-0">
                                    Claim MIS Report
                                </h5>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="card dashboard-card-round">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 pb-1">
                                        <div className="row d-flex align-items-center">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                                    <div className="flex-column mr-1">
                                                        <Controller
                                                            name="from_date"
                                                            control={control}
                                                            defaultValue={fromDate}
                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    pickerType="calendar"
                                                                    placeholder="From Date"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    showClearButton={false}
                                                                    value={field.value}
                                                                    acceptCustomValue={false}
                                                                    onValueChange={(value) => {
                                                                        var objFromDate = new Date(value);
                                                                        var objToDate = new Date(toDate);

                                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "From date can't be greater than To date",
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        } else {
                                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                            setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex-column mr-1">
                                                        <Controller
                                                            className="flex-column"
                                                            name="to_date"
                                                            control={control}
                                                            defaultValue={toDate}
                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    pickerType="calendar"
                                                                    placeholder="To Date"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    showClearButton={false}
                                                                    value={field.value}
                                                                    acceptCustomValue={false}
                                                                    onValueChange={(value) => {
                                                                        var objFromDate = new Date(fromDate);
                                                                        var objToDate = new Date(value);

                                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "To date must be greater than from date",
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        } else {
                                                                            field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                            setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClaimMISReport