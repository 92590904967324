import { ACTION_PROPOSAL_LIST_SUCCESS,ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS ,ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS,ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS} from "./action";

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ProposalListReducer = createReducer(INITIAL_STATE, {
    [ACTION_PROPOSAL_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROPOSAL_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS,
                loading: false,
            }
        );
    } , 
    [ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS,
                loading: false,
            }
        );
    }, 
    [ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS,
                loading: false,
            }
        );
    }, 
});
export default ProposalListReducer;