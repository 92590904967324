import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import $ from "jquery";
import { verifyPermission } from '../../../../../../utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from "moment";
import { withAuth } from "react-auth-guard";
import { actionRiskApprovalList, ACTION_RISK_APPROVAL_LIST_SUCCESS } from "./action"
import { UNAUTHORIZED } from "../../../../../../constants";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";

const RiskManagementApprovalList = (props) => {
    const { match } = props;
    const [riskManagementApprovalList, setRiskManagementApprovalList] = useState([])

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionRiskApprovalList({ "user_type": "manager" })
    }
    useEffect(initialRender, []);

    const onRiskApprovalListUpdate = () => {
        const { RiskApprovalList } = props;
        if (RiskApprovalList != null) {
            setRiskManagementApprovalList([]);
            var RiskApprovalListResponse = Object.assign({}, RiskApprovalList);
            if (RiskApprovalListResponse.result === true) {
                setRiskManagementApprovalList(RiskApprovalListResponse.response);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (RiskApprovalListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onRiskApprovalListUpdate, [props.RiskApprovalList])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "id", caption: "ID", visible: true },
        { dataField: "created_date", caption: "Created Date", visible: false },
        { dataField: "created_date_formated", caption: "Created Date", visible: true },
        { dataField: "client_name", caption: "Client Name", visible: true },
        { dataField: "address", caption: "Location Address", visible: true },
        { dataField: "occupancy", caption: "Occupancy", visible: true },
        { dataField: "sum_insured", caption: "Sum Insured", visible: true },
        { dataField: "rm_first_name", caption: "RM Name", visible: true },
        { dataField: "rm_city_name", caption: "RM Location", visible: true },
        { dataField: "inspection_type", caption: "Type of Inspection", visible: true },
        { dataField: "risk_management_document", caption: "Document", visible: true },
        { dataField: "raised_by", caption: "Created By", visible: true },
        { dataField: "inspection_date", caption: "Date of Inspection", visible: false },
        { dataField: "inspection_date_formated", caption: "Date of Inspection", visible: true },
        { dataField: "approved_by", caption: "Approved By", visible: true },
        { dataField: "actual_date_inspection", caption: "Actual Date of Inspection", visible: false },
        { dataField: "actual_date_inspection_formated", caption: "Actual Date of Inspection", visible: true },
        { dataField: "assign_engineer", caption: "Assigned Engineer", visible: true },
        { dataField: "remarks", caption: "Remark", visible: true },
        { dataField: "description", caption: "Description", visible: true },
        { dataField: "financial_year", caption: "Financial Year", visible: true },
        { dataField: "status_name", caption: "Status", visible: true },
        { dataField: "engineer_status_name", caption: "Engineer Status", visible: true },
    ]);

    const renderDocumentCell = (cell) => {
        return <div>{cell.data.risk_management_document != null && cell.data.risk_management_document != '' ? 'Yes' : 'No'}</div>;
    }

    const renderApprovedBy = (cell) => {
        return <div>{cell.data.status != null && cell.data.status != "pending" ? cell.data.approved_by : ''}</div>
    }

    const renderEngineerStatus = (cell) => {
        return <div>{cell.data.engineer_status_name != null && cell.data.engineer_status_name != "Not Defined" ? cell.data.engineer_status_name : ''}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderInspectionDate = (cell) => {
        return <div>{cell?.data?.inspection_date_formated != null ? moment(cell?.data?.inspection_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActualInspectionDate = (cell) => {
        return <div>{cell?.data?.actual_date_inspection_formated != null ? moment(cell?.data?.actual_date_inspection_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("RISK_MANAGEMNT_APPROVAL_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("RISK_MANAGEMNT_APPROVAL_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${['update', 'pending'].includes(params.data.status) ? '' : 'hidden'} `}
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "risk_management_document") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderDocumentCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "approved_by") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderApprovedBy}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "engineer_status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEngineerStatus}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "created_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "inspection_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderInspectionDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "actual_date_inspection_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActualInspectionDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };


    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Risk Management Approvals {riskManagementApprovalList.length === 0 ? '' : `(${riskManagementApprovalList.length})`}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={riskManagementApprovalList}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Risk Management Approval",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("created_date_formated", "visible", false)
                                        event.component.columnOption("created_date", "visible", true)
                                        event.component.columnOption("inspection_date_formated", "visible", false)
                                        event.component.columnOption("inspection_date", "visible", true)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", false)
                                        event.component.columnOption("actual_date_inspection", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("created_date_formated", "visible", true)
                                        event.component.columnOption("created_date", "visible", false)
                                        event.component.columnOption("inspection_date_formated", "visible", true)
                                        event.component.columnOption("inspection_date", "visible", false)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", true)
                                        event.component.columnOption("actual_date_inspection", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {

    var RiskApprovalList = null;

    if (
        state.RiskApprovalListReducer != null &&
        Object.keys(state.RiskApprovalListReducer).length > 0 &&
        state.RiskApprovalListReducer.constructor === Object
    ) {
        switch (state.RiskApprovalListReducer.tag) {
            case ACTION_RISK_APPROVAL_LIST_SUCCESS:
                RiskApprovalList = Object.assign({}, state.RiskApprovalListReducer.data);
                delete state.RiskApprovalListReducer;
                return { RiskApprovalList };
            default:
        }
    }
    return {
        RiskApprovalList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionRiskApprovalList
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementApprovalList))