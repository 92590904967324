import createReducer from "../../../reducers/createReducer";
import { ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS } from './action';
import { ACTION_MASTER_ASSIGN_RETENTION_SUCCESS } from './action';

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const EditAssignRetentionReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_ASSIGN_RETENTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RETENTION_SUCCESS,
                loading: false,
            }
        );
    },
}
);
export default EditAssignRetentionReducer;