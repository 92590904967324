import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionAppSettingsEdit, ACTION_SETTINGS_EDIT_SUCCESS } from './action'
import { actionAppSettingsView, ACTION_SETTINGS_VIEW_SUCCESS } from './action';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import 'react-toastify/dist/ReactToastify.css';

const AppSettingEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm();
    const [key, setKey] = useState(null);
    const [name, setName] = useState(null);

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionAppSettingsView({ id: props.location.state.id });
    }
    useEffect(initialRender, [props.show]);

    const onAppSettingsEditUpdate = () => {
        const { AppSettingsEdit } = props;
        var AppSettingsEditResponse = Object.assign({}, AppSettingsEdit);
        $("#add_edit").prop("disabled", false);
        if (AppSettingsEditResponse.result === true) {
            $("#add_edit_setting").addClass("hidden");
            props.history.goBack();
            toast.success('Setting Edited successfully')
        } else {
            $("#add_edit_setting").addClass("hidden");
            switch (AppSettingsEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(AppSettingsEditResponse.message);
                default:
            }
        }
    }
    useEffect(onAppSettingsEditUpdate, [props.AppSettingsEdit]);

    const onAppSettingsViewUpdate = () => {
        const { AppSettingsView } = props;
        if (AppSettingsView != null) {
            var AppSettingsViewResponse = Object.assign({}, AppSettingsView);
            if (AppSettingsViewResponse.result === true) {
                // setIsdelete(AppSettingsViewResponse.response.is_delete)
                // setValue('is_delete', AppSettingsViewResponse.response.is_delete);
                setValue('key', AppSettingsViewResponse.response.key);
                setKey(AppSettingsViewResponse.response.key);
                setValue('name', AppSettingsViewResponse.response.name);
                setName(AppSettingsViewResponse.response.name);
                setValue('value', AppSettingsViewResponse.response.value);
            } else {
                switch (AppSettingsViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAppSettingsViewUpdate, [props.AppSettingsView]);

    const onSubmit = (data) => {
        data.id = props.location.state.id;
        data.key = key;
        data.name = data.name;
        data.value = data.value;
        $("#add_edit_setting").removeClass("hidden");
        $("#add_edit").prop("disabled", true);
        props.actionAppSettingsEdit(data);
    }

    return (
        <>
            <ScreenTitle title=" Edit App Settings" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Key&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="key"
                                                        name="key"
                                                        disabled={true}
                                                        placeholder="Enter Key"
                                                        {...register('key', { required: 'Key is required' })}
                                                    />
                                                    {errors.key && <ErrorLabel message={errors.key?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Name"
                                                        {...register('name', { required: 'Name is required' })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Value&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="value"
                                                        name="value"
                                                        placeholder="Enter Value"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9.]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register('value', { required: 'Value is required' })}
                                                    />
                                                    {errors.value && <ErrorLabel message={errors.value?.message} />}
                                                </div>
                                            </div>
                                            {/* <div class="form-check cust-form-check mt-0 mb-1 mx-1">
                                                <input
                                                    name="is_delete"
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="is_delete"
                                                    {...register("is_delete")}
                                                />
                                                <label class="form-check-label my-auto" for="is_delete">
                                                    <span className="cust-fs-12">Is Delete</span>
                                                </label>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="add_edit"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add_edit_setting" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var AppSettingsEdit = null;
    var AppSettingsView = null;
    if (
        state.EditAppSettingsReducer != null &&
        Object.keys(state.EditAppSettingsReducer).length > 0 &&
        state.EditAppSettingsReducer.constructor === Object
    ) {
        switch (state.EditAppSettingsReducer.tag) {
            case ACTION_SETTINGS_EDIT_SUCCESS:
                AppSettingsEdit = Object.assign({}, state.EditAppSettingsReducer.data);
                delete state.EditAppSettingsReducer
                return { AppSettingsEdit };
            case ACTION_SETTINGS_VIEW_SUCCESS:
                AppSettingsView = Object.assign({}, state.EditAppSettingsReducer.data);
                delete state.EditAppSettingsReducer
                return { AppSettingsView };
            default:
        }
    }

    return {
        AppSettingsEdit,
        AppSettingsView

    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAppSettingsEdit, actionAppSettingsView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSettingEdit)

