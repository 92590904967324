import { ACTION_GETMEETINGSLIST_SUCCESS, ACTION_MY_TEAM_MEETING_SUCCESS, ACTION_PROFILE_TEAM_MEETING_SUCCESS } from './action';
import createReducer from '../../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const MeetingListReducer = createReducer(INITIAL_STATE, {
    [ACTION_GETMEETINGSLIST_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_GETMEETINGSLIST_SUCCESS,
            loading: false
        });
    },
    [ACTION_MY_TEAM_MEETING_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_MY_TEAM_MEETING_SUCCESS,
            loading: false
        });
    },
    [ACTION_PROFILE_TEAM_MEETING_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_PROFILE_TEAM_MEETING_SUCCESS,
            loading: false
        });
    },
}
);
export default MeetingListReducer;