import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ArrayStore from 'devextreme/data/array_store';
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionOpportunitySettingAdd, ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS } from "./action";
import { actionGetDepartment, ACTION_GET_DEPARTMENT_SUCCESS } from "./action";
import { actionEmployeeList, ACTION_GET_EMPLOYEE_LIST_SUCCESS } from "./action";
import { actionLocationList, ACTION_GET_LOCATION_LIST_SUCCESS } from "./action";
import { verifyPermission } from "../../../../../../utils";
import { Controller, useForm } from "react-hook-form";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TagBox } from "devextreme-react";
import ErrorLabel from "../../../../../../component/form/error_label";
import Swal from "sweetalert2";

const ProposalSettingsAdd = (props) => {
    const { match } = props;
    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm()
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [isAll, setIsAll] = useState(0);
    const [isAssigned, setIsAssigned] = useState(0);
    const [isUnAssigned, setIsUnassigned] = useState(0);
    const [getDepartment, SetGetDepartment] = useState([]);
    const [getEmployeeList, SetGetEmployeeList] = useState([]);
    const [getLocationsList, GetLocationsList] = useState([]);

    const initialRender = () => {
        // $("#progress-loader").removeClass("hidden");
        props.actionGetDepartment({ status: "ACTIVE" })
        props.actionLocationList();
        props.actionEmployeeList()
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
    }
    useEffect(initialRender, []);

    const onGetDepartmentUpdate = () => {
        const { GetDepartment } = props;
        var GetDepartmentResponse = Object.assign({}, GetDepartment);
        if (GetDepartmentResponse.result === true) {
            SetGetDepartment(GetDepartmentResponse.response)
        } else {
            switch (GetDepartmentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(GetDepartmentResponse.message);
                default:
            }
        }
    }
    useEffect(onGetDepartmentUpdate, [props.GetDepartment]);

    const onGetEmployeeListUpdate = () => {
        const { GetEmployeeList } = props;
        var GetEmployeeListResponse = Object.assign({}, GetEmployeeList);
        if (GetEmployeeListResponse.result === true) {
            SetGetEmployeeList(GetEmployeeListResponse.response.users)
        } else {
            switch (GetEmployeeListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(GetEmployeeListResponse.message);
                default:
            }
        }
    }
    useEffect(onGetEmployeeListUpdate, [props.GetEmployeeList]);

    const onGetLocationListUpdate = () => {
        const { GetLocationList } = props;
        var GetLocationListResponse = Object.assign({}, GetLocationList);
        if (GetLocationListResponse.result === true) {
            GetLocationsList(GetLocationListResponse.response)
        } else {
            switch (GetLocationListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(GetLocationListResponse.message);
                default:
            }
        }
    }
    useEffect(onGetLocationListUpdate, [props.GetLocationList]);

    const onOpportunitySettingAddUpdate = () => {
        const { OpportunitySettingAdd } = props;
        var OpportunitySettingAddResponse = Object.assign({}, OpportunitySettingAdd);
        $("#add_opportunity").prop("disabled", false);
        if (OpportunitySettingAddResponse.result === true) {
            $("#opportunity_add-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Opportunity Setting Added Successfully");
            props.history.goBack();
        } else {
            $("#opportunity_add-form-loader").addClass("hidden");
            switch (OpportunitySettingAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(OpportunitySettingAddResponse.message);
                default:
            }
        }
    }
    useEffect(onOpportunitySettingAddUpdate, [props.OpportunitySettingAdd]);

    const onSubmit = (data) => {
        data.user_ids = data.emp_ids;
        data.access_all = isAll
        data.access_assigned = isAssigned
        data.access_unassigned = isUnAssigned
        data.city = selectedLocations;
        if (isAll != 1 && isAssigned != 1 && isUnAssigned != 1) {
            Swal.fire({
                icon: 'error',
                text: 'Please Assign Right',
            }).then(({ isConfirmed }) => {

            })
        } else {
            $("#opportunity_add-form-loader").removeClass("hidden");
            $("#add_opportunity").prop("disabled", true);
            props.actionOpportunitySettingAdd(data);
            // alert(JSON.stringify(data)) 
        }
    }

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Opportunity Settings Add</h5>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    {/* <div className="form-body"> */}
                                    <div className="row">
                                        {/* <div className="col-12 col-md-4 col-lg-4"> */}
                                        {/* <div className="form-group"> */}
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Type of Department</label>
                                                <Controller
                                                    name="department_id"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="department_id"
                                                            name="department_id"
                                                            dataSource={getDepartment}
                                                            allowFiltering={false}
                                                            placeholder="Select Department Type"
                                                            value={field.value}
                                                            fields={{ text: 'name', value: 'id' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.id)
                                                                    // setValue('policy_type_id', null);
                                                                    // setSelectedDepartmentValue(event.itemData.id)
                                                                    // props.actionLocationList({ 'company_ids': [event.itemData.id] });
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Type of Department'
                                                    }}
                                                />
                                                {errors.department_id && <ErrorLabel message={errors.department_id?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Employee List&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="emp_ids"
                                                    className="mt-1"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: getEmployeeList,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            id="emp_ids"
                                                            name="emp_ids"
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select Employees"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            maxDisplayedTags={2}
                                                            height={"30%"}
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedEmployee}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedEmployee(value);
                                                                    field.onChange(value);
                                                                } else {
                                                                    if (selectedEmployee != null && selectedEmployee.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedEmployee([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedEmployee([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Employee Names'
                                                    }}
                                                />
                                                {errors.emp_ids && <ErrorLabel message={errors.emp_ids?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mt-1">
                                                <label className="label-control">Locations&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="locations"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: getLocationsList,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            id="locations"
                                                            name="locations"
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select Locations"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            maxDisplayedTags={2}
                                                            height={"30%"}
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedLocations}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedLocations(value);
                                                                    field.onChange(value);
                                                                } else {
                                                                    if (selectedLocations != null && selectedLocations.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedLocations([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedLocations([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Locations'
                                                    }}
                                                />
                                                {errors.locations && <ErrorLabel message={errors.locations?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mb-0 mr-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="access_all"
                                                        id="access_all"
                                                        defaultChecked={isAll === 1 ? true : false}
                                                        checked={isAll === 1 ? true : false}
                                                        {...register('access_all')}
                                                        onChange={(event) => {
                                                            if (event.target.checked === true) {
                                                                setIsAll(1)
                                                                setIsAssigned(1)
                                                                setIsUnassigned(1)
                                                            } else {
                                                                setIsAll(0)
                                                                setIsAssigned(0)
                                                                setIsUnassigned(0)
                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-control-label" for="access_all">All</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mb-0 mr-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="access_assigned"
                                                        id="access_assigned"
                                                        disabled={isAll === Number(1) ? true : false}
                                                        defaultChecked={isAssigned === 1 ? true : false}
                                                        checked={isAssigned === 1 ? true : false}
                                                        {...register('access_assigned')}
                                                        onChange={(event) => {
                                                            if (event.target.checked === true) {
                                                                setIsAssigned(1)
                                                            } else {
                                                                setIsAssigned(0)
                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-control-label" for="access_assigned">Assigned</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group mb-0 mr-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        name="access_unassigned"
                                                        id="access_unassigned"
                                                        disabled={isAll === Number(1) ? true : false}
                                                        defaultChecked={isUnAssigned === 1 ? true : false}
                                                        checked={isUnAssigned === 1 ? true : false}
                                                        {...register('access_unassigned')}
                                                        onChange={(event) => {
                                                            if (event.target.checked === true) {
                                                                setIsUnassigned(1)
                                                                setIsAssigned(1)
                                                            } else {
                                                                setIsUnassigned(0)
                                                            }
                                                        }}
                                                    />
                                                    <label class="custom-control-label" for="access_unassigned">Un-Assigned</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="add_opportunity"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="opportunity_add-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    var OpportunitySettingAdd = null;
    var GetDepartment = null;
    var GetEmployeeList = null;
    var GetLocationList = null;
    if (
        state.OpportunitySettingAddReducer != null &&
        Object.keys(state.OpportunitySettingAddReducer).length > 0 &&
        state.OpportunitySettingAddReducer.constructor === Object
    ) {
        switch (state.OpportunitySettingAddReducer.tag) {
            case ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS:
                OpportunitySettingAdd = Object.assign({}, state.OpportunitySettingAddReducer.data);
                delete state.OpportunitySettingAddReducer;
                return { OpportunitySettingAdd };
            case ACTION_GET_DEPARTMENT_SUCCESS:
                GetDepartment = Object.assign({}, state.OpportunitySettingAddReducer.data);
                delete state.OpportunitySettingAddReducer;
                return { GetDepartment };
            case ACTION_GET_EMPLOYEE_LIST_SUCCESS:
                GetEmployeeList = Object.assign({}, state.OpportunitySettingAddReducer.data);
                delete state.OpportunitySettingAddReducer;
                return { GetEmployeeList };
            case ACTION_GET_LOCATION_LIST_SUCCESS:
                GetLocationList = Object.assign({}, state.OpportunitySettingAddReducer.data);
                delete state.OpportunitySettingAddReducer;
                return { GetLocationList };
            default:
        }
    }
    return {
        OpportunitySettingAdd,
        GetDepartment,
        GetEmployeeList,
        GetLocationList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionOpportunitySettingAdd,
        actionGetDepartment,
        actionEmployeeList,
        actionLocationList
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProposalSettingsAdd))