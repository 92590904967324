import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_MASTER_STATES, API_MASTER_CITIES, API_MASTER_ADD_INSURER_BRANCH } from '../../../../../../../api/constants';

export const ACTION_MASTER_BRANCH_STATES_LOADING = 'ACTION_MASTER_BRANCH_STATES_LOADING';
export const ACTION_MASTER_BRANCH_STATES_SUCCESS = 'ACTION_MASTER_BRANCH_STATES_SUCCESS';

export const ACTION_MASTER_BRANCH_CITIES_LOADING = 'ACTION_MASTER_BRANCH_CITIES_LOADING';
export const ACTION_MASTER_BRANCH_CITIES_SUCCESS = 'ACTION_MASTER_BRANCH_CITIES_SUCCESS';

export const ACTION_MASTER_ADD_INSURER_BRANCH_LOADING = 'ACTION_MASTER_ADD_INSURER_BRANCH_LOADING';
export const ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS = 'ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS';

export function actionMasterBranchStates() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_STATES).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_BRANCH_STATES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_BRANCH_STATES_LOADING, { loading: false, data }));
        });
}

export function actionMasterBranchCities(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_CITIES, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_BRANCH_CITIES_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_BRANCH_CITIES_LOADING, { loading: false, data }));
        });
}

export function actionMasterAddInsurerBranch(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_ADD_INSURER_BRANCH, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_ADD_INSURER_BRANCH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ADD_INSURER_BRANCH_LOADING, { loading: false, data }));
        });
}