import React, { useState, useEffect } from 'react';
import $ from "jquery";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { actionGroupPolicies, GROUP_ALL_POLICIES_SUCCESS } from './action';
import ClientPolicyDetails from '../../../../../../../component/popups/policies';
import { UNAUTHORIZED } from '../../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import dateFormat from 'dateformat';
import { verifyPermission } from '../../../../../../../utils';

const GroupPolicyView = (props) => {

    const [showPolicyView, setShowPolicyView] = useState(false);
    const [policiesData, setPoliciesData] = useState([]);
    const [clientPolicyDetails, setClientPolicyDetails] = useState(null);

    const [type, setType] = useState('All');
    const [totalCount, setTotalCount] = useState(0);

    const [policyColumn, setPolicyColumn] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "customer_name", caption: "Company" },
        { dataField: "policy_no", caption: "Policy No" },
        { dataField: "policy_type", caption: "Policy Type" },
        { dataField: "insurer_name", caption: "Insurer" },
        { dataField: "policy_expiry_date", caption: "Expiry Date" },
        { dataField: "net_premium", caption: "Net Premium" },
    ]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        props.actionGroupPolicies({
            client_group_id: props.clientGroupId,
            type: 'all'
        })

    }
    useEffect(initialRender, [props.clientGroupId]);

    const onGroupAllPoliciesUpdate = () => {

        const { GroupAllPolicies } = props;

        if (GroupAllPolicies != null) {
            var GroupAllPoliciesResponse = Object.assign({}, GroupAllPolicies);
            if (GroupAllPoliciesResponse.result === true) {

                $("#progress-loader").addClass("hidden");

                setPolicyColumn([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "customer_name", caption: "Company" },
                    { dataField: "policy_no", caption: "Policy No" },
                    { dataField: "policy_type", caption: "Policy Type" },
                    { dataField: "insurer_name", caption: "Insurer" },
                    { dataField: "policy_expiry_date", caption: "Expiry Date" },
                    { dataField: "net_premium", caption: "Net Premium" },
                ])

                setPoliciesData(GroupAllPoliciesResponse.response.policies_details);


                setTotalCount(GroupAllPoliciesResponse.response.policies_details?.length);

            } else {
                $("#progress-loader").addClass("hidden");
                setPoliciesData([])
                switch (GroupAllPoliciesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGroupAllPoliciesUpdate, [props.GroupAllPolicies]);

    const renderSumInsured = (cell) => {
        return <div>{Number(cell.data.sum_insured).toFixed(2)}</div>;
    }

    const renderNetPremium = (cell) => {
        return cell.data.net_premium != null ? '₹ ' + cell.data.net_premium : '';
    }

    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.customer_name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                })}
            ><u>{cell?.data?.customer_name}</u></a>
        }
    }

    const renderPoliciesColumns = () => {
        var arrColumns = [];
        policyColumn.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm px-1 float-left"
                                    onClick={() => {
                                        setShowPolicyView(true);
                                        setClientPolicyDetails(params.data)
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            }
            else if (objColumn.dataField === "customer_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompanyNameCell}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "sum_insured") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSumInsured}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "net_premium") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderNetPremium}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderPoliciesTableView = () => {
        return (
            <DataGrid
                dataSource={policiesData}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                columnHidingEnabled={true}
                export={{
                    fileName: "policies",
                    enabled: verifyPermission('CLIENT_GROUP_EXPORT') ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderPoliciesColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <ul class="nav nav-pills d-md-flex d-lg-flex mb-1 mb-md-0">
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionGroupPolicies({
                                                    client_group_id: props.clientGroupId,
                                                    type: 'all'
                                                });
                                                setType('All');
                                                setTotalCount(0)
                                            }}
                                            className={`btn btn-md ${type === 'All' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round`}>
                                            All {type === 'All' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setType('Active');
                                                setTotalCount(0)
                                                props.actionGroupPolicies({
                                                    client_group_id: props.clientGroupId,
                                                    type: 'active'
                                                });
                                            }}
                                            className={`btn btn-md ${type === 'Active' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Active  {type === 'Active' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setType('Expired');
                                                setTotalCount(0)
                                                props.actionGroupPolicies({
                                                    client_group_id: props.clientGroupId,
                                                    type: 'expired',

                                                });
                                            }}
                                            className={`btn btn-md ${type === 'Expired' ? 'btn-primary' : 'btn-light'} px-1 font-small-3 round ml-1`}>
                                            Expired {type === 'Expired' ? (totalCount > 0) ? `(` + totalCount + `)` : '(0)' : ``}
                                        </button>
                                    </li>

                                </ul>
                                {
                                    policiesData != null && policiesData.length > 0 ?
                                        renderPoliciesTableView() :
                                        <h6 className="text-bold-500 text-center">No Policy Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClientPolicyDetails
                show={showPolicyView}
                clientPolicyDetails={clientPolicyDetails}
                handleClose={() => {
                    setShowPolicyView(false);
                }}
                tab={1}
            />
        </>
    )

}

function mapStateToProps(state) {

    var GroupAllPolicies = null;

    if (
        state.TabGroupPoliciesViewReducer != null &&
        Object.keys(state.TabGroupPoliciesViewReducer).length > 0 &&
        state.TabGroupPoliciesViewReducer.constructor === Object
    ) {
        switch (state.TabGroupPoliciesViewReducer.tag) {

            case GROUP_ALL_POLICIES_SUCCESS:
                GroupAllPolicies = Object.assign({}, state.TabGroupPoliciesViewReducer.data);
                delete state.TabGroupPoliciesViewReducer;
                return { GroupAllPolicies };
            default:
                return {
                    GroupAllPolicies
                }
        }
    }
    return {
        GroupAllPolicies
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGroupPolicies }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GroupPolicyView));
