import { DateBox } from 'devextreme-react';
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
} from "devextreme-react/data-grid";
import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import { UNAUTHORIZED } from '../../../../../constants';
import _ from "lodash";
import { actionCustomerVerificationReport, ACTION_CUSTOMER_VERIFICATION_REPORT_SUCCESS } from './action';

const CustomerVerificationReport = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionCustomerVerificationReport({
            from_date: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            to_date: moment().format('YYYY-MM-DD'),
        });
    }
    useEffect(initialRender, []);

    const [fromDate, setFromDate] = useState(moment().subtract(1, 'M').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [customerReport, setCustomerReport] = useState([]);

    const columns = [
        { dataField: "company_group_name", caption: "Group Name" },
        { dataField: "name", caption: "Customer Name" },
        { dataField: "contact_name", caption: "Primary Contact Name" },
        { dataField: "contact_email", caption: "Primary Contact Email" },
        { dataField: "is_verified", caption: "Email Verification Status" },
        { dataField: "rm_name", caption: "Assign RM" },
        { dataField: "rm_reporting_person_name", caption: "Assign RM Reporting Manager" },
        { dataField: "rm_branch_city", caption: "Assign RM Branch" }
    ];

    const onCIMSReportUpdate = () => {
        const { CIMSReport } = props;
        var CIMSReportResponse = Object.assign({}, CIMSReport);
        $("#customer-report").prop("disabled", false);
        if (CIMSReportResponse.result === true) {
            setCustomerReport(CIMSReportResponse.response);
            $("#customer-report-loader").addClass("hidden");
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            $("#customer-report-loader").addClass("hidden");
            switch (CIMSReportResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCIMSReportUpdate, [props.CIMSReport]);


    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            arrColumns.push(
                <Column
                    dataField={objColumn.dataField}
                    caption={objColumn.caption}
                    visible={objColumn.visible}
                ></Column>
            );
        });
        return arrColumns;
    };

    const onSubmit = (data) => {
        $("#customer-report-loader").removeClass("hidden");
        $("#customer-report").prop("disabled", true);
        props.actionCustomerVerificationReport({
            from_date: moment(fromDate).format('YYYY-MM-DD'),
            to_date: moment(toDate).format('YYYY-MM-DD'),
        });
    }

    return (
        <>
            <section>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 pb-1">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 my-auto">
                                <h5 className="content-header-title text-capitalize mb-0">Customer Verification Report</h5>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="flex-column">
                                    <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                        <button
                                            className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">From Date</label>
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                defaultValue={fromDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(value).format('YYYY-MM-DD');
                                                            var objToDate = moment(toDate).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "From date can't be greater than To date",
                                                                    icon: 'error'
                                                                }).then(success => {
                                                                })
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setFromDate(moment(value).format('YYYY-MM-DD'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">To Date</label>
                                            <Controller
                                                className="flex-column"
                                                name="to_date"
                                                control={control}
                                                defaultValue={toDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(fromDate).format('YYYY-MM-DD');
                                                            var objToDate = moment(value).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "To date must be greater than from date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setToDate(moment(value).format('YYYY-MM-DD'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-actions mt-1 border-0 float-none">
                                            <button
                                                id="customer-report"
                                                type='submit'
                                                className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                            >Search
                                                <span id="customer-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={customerReport}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    remoteOperations={true}
                                    export={{
                                        fileName: "Customer Verification Report",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var CIMSReport = null;

    if (
        state.customerVerificationReportReducer != null &&
        Object.keys(state.customerVerificationReportReducer).length > 0 &&
        state.customerVerificationReportReducer.constructor === Object
    ) {
        switch (state.customerVerificationReportReducer.tag) {
            case ACTION_CUSTOMER_VERIFICATION_REPORT_SUCCESS:
                CIMSReport = Object.assign({}, state.customerVerificationReportReducer.data);
                delete state.customerVerificationReportReducer;
                return { CIMSReport };
            default:
        }
    }

    return {
        CIMSReport
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionCustomerVerificationReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CustomerVerificationReport))