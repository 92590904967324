import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { UNAUTHORIZED } from '../../../../../../../constants';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { generateInitialLettersString } from '../../../../../../../utils';
import { FiMail, FiPhone } from 'react-icons/fi';
import { actionClientContactByInsurerId, ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS } from './action';

const TabContactPersons = (props) => { 

    const [contactPersonData, setContactPerson] = useState(null);
    const [contacts, setContacts] = useState([]);

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        props.actionClientContactByInsurerId({
            insurer_id: props.insurerId
        })
        
    }
    useEffect(initialRender,[props.tab]);

    const onContactPersonUpdate = () => {

        const { ContactPerson } = props;

        if (ContactPerson != null) {
            var ContactPersonResponse = Object.assign({}, ContactPerson);
            if (ContactPersonResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setContactPerson(ContactPersonResponse.response);
                setContacts(ContactPersonResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ContactPersonResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout();
                        })
                        break;

                    default:

                }
            }
        }
    }


    useEffect(onContactPersonUpdate, [props.ContactPerson]);

    const renderContacts = () => {
        var arrContacts = []
        if (contacts != null && contacts.length > 0) {
            contacts.forEach((contact, indexContact) => {
                arrContacts.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row mb-half">
                                <div className="flex-column">
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.contact_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{contact.contact_name}</p>
                                    <div class="font-small-3 text-bold-700">{contact.contact_designation}</div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone !== null ? '' : 'hidden'}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone !== null ? '' : 'hidden'}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email !== null ? '' : 'hidden'}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column ${contact.contact_email !== null ? '' : 'hidden'}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return arrContacts
    }
    
    return (
        <div className="tab-pane active" id="contact-person" aria-labelledby="contact-person-tab" role="tabpanel">
            <div className="card">
                <div className="card-body">
                    <div className="row my-1">
                        {
                            contacts != null && contacts.length > 0 ?
                            renderContacts()
                            :
                            <div className="col-12 col-md-12 col-lg-12">
                                <h6 className="text-bold-500 text-center">No Contact Details</h6>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {

    var ContactPerson = null;

    if (
        state.InsurerTabContactPersonReducer != null &&
        Object.keys(state.InsurerTabContactPersonReducer).length > 0 &&
        state.InsurerTabContactPersonReducer.constructor === Object
    ) {
        switch (state.InsurerTabContactPersonReducer.tag) {

            case ACTION_CLIENT_CONTACTBY_INSURERID_SUCCESS:
                ContactPerson = Object.assign({}, state.InsurerTabContactPersonReducer.data);
                delete state.InsurerTabContactPersonReducer;
                return { ContactPerson };
            default:
                return { 
                    ContactPerson
                }
        }
    }
    return { 
        ContactPerson
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionClientContactByInsurerId }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TabContactPersons);