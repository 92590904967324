
import { ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS } from './action';
import {ACTION_REIMBURSEMENT_REPORT_SUCCESS} from './action';

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ReimbursementReportReducer = createReducer(INITIAL_STATE, {

    [ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_REPORT_DETAILS_SUCCESS,
            loading: false
        });
    },
    [ACTION_REIMBURSEMENT_REPORT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_REPORT_SUCCESS,
            loading: false
        });
    },
})
export default ReimbursementReportReducer