import { ACTION_OTHER_CLAIM_SUCCESS,ACTION_OTHER_CLAIM_EDIT_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const OtherClaimReducer = createReducer(INITIAL_STATE, {
    [ACTION_OTHER_CLAIM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OTHER_CLAIM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OTHER_CLAIM_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OTHER_CLAIM_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
});

export default OtherClaimReducer;