import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { actionCountry, ACTION_MASTER_COUNTRY_SUCCESS } from "./action";
import { actionAddNewClient, ACTION_ADD_NEW_CLIENT_SUCCESS } from "./action";
import { actionClientType, ACTION_CLIENT_TYPE_SUCCESS } from "./action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UNAUTHORIZED } from "../../../constants";
import { withAuth } from "react-auth-guard";

const ReinsuranceNewCustomer = (props) => {

    const { register, control, handleSubmit, formState: { errors }, setValue } = useForm()
    const [country, setCountry] = useState(null);
    const [clientType, setClientType] = useState(null);

    const initialRender = () => {

        if (props.show === true) {

            setCountry([])
            setClientType([])
            setValue('name', '');

            props.actionCountry({})
            props.actionClientType({})

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    }
    useEffect(initialRender, [props.show]);

    const onCountryUpdate = () => {

        const { CountryUpdate } = props;
        var CountryUpdateResponse = Object.assign({}, CountryUpdate);
        if (CountryUpdateResponse.result === true) {
            setCountry(CountryUpdateResponse.response);
        } else {
            switch (CountryUpdateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onCountryUpdate, [props.CountryUpdate]);

    const onClientTypeUpdate = () => {

        const { ClientType } = props;
        var ClientTypeUpdateResponse = Object.assign({}, ClientType);
        if (ClientTypeUpdateResponse.result === true) {
            setClientType(ClientTypeUpdateResponse.response);
        } else {
            switch (ClientTypeUpdateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    const onNewClientUpdate = () => {
        const { NewClient } = props;
        if (NewClient != null) {
            var NewClientResponse = Object.assign({}, NewClient);
            $("#add_client").prop("disabled", false);
            if (NewClientResponse.result === true) {
                props.handleClose(NewClientResponse.response);
                $("#client-loader").addClass("hidden");
            } else {
                $("#client-loader").addClass("hidden");
                switch (NewClientResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                    default:
                }
            }
        }
    }
    useEffect(onNewClientUpdate, [props.NewClient]);

    const onSubmit = (data) => {

        data.client_of = "reinsurance";
        data.is_new = "new";
        props.actionAddNewClient(data);
    }
    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="new-client-add"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="new-client-add">
                                    Add New Client
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12 ">
                                                    <div className="form-group">
                                                        <label className="label-control ">Client Type&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="client_type"
                                                            className="form-control"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={clientType}
                                                                    fields={{ text: "title", value: "value" }}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Client Type"
                                                                    value={field.value}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Client Type is required'
                                                            }}
                                                        />
                                                        {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control ">Client&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Enter Client "
                                                            {...register('name', { required: 'Client is required' })}

                                                        />
                                                        {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control ">Select Country&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="country_id"
                                                            className="form-control"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={country}
                                                                    fields={{ text: "name", value: "id" }}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Country"
                                                                    value={field.value}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.id)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Country is required'
                                                            }}
                                                        />
                                                        {errors.country_id && <ErrorLabel message={errors.country_id?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 float-none ">
                                            <button
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                &nbsp;Submit </button>
                                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    props.handleClose();

                                                }}
                                            > Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {

    var CountryUpdate = null;
    var NewClient = null;
    var ClientType = null;

    if (
        state.AddRiNewClientReducer != null &&
        Object.keys(state.AddRiNewClientReducer).length > 0 &&
        state.AddRiNewClientReducer.constructor === Object
    ) {
        switch (state.AddRiNewClientReducer.tag) {
            case ACTION_MASTER_COUNTRY_SUCCESS:
                CountryUpdate = Object.assign({}, state.AddRiNewClientReducer.data);
                delete state.AddRiNewClientReducer;
                return { CountryUpdate };
            case ACTION_ADD_NEW_CLIENT_SUCCESS:
                NewClient = Object.assign({}, state.AddRiNewClientReducer.data);
                delete state.AddRiNewClientReducer;
                return { NewClient };
            case ACTION_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.AddRiNewClientReducer.data);
                delete state.AddRiNewClientReducer;
                return { ClientType };
            default:
        }
    }
    return {
        CountryUpdate,
        NewClient,
        ClientType
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionCountry,
            actionAddNewClient,
            actionClientType
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReinsuranceNewCustomer));