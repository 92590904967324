import React from 'react';
import { Route, Switch } from 'react-router';
import Content from './list';
import ContentEdit from './edit';

const ContentRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}`}  component={Content}/>
                <Route path={`${match.url}/edit`}  component={ContentEdit}/>
            </Switch>
        </>
    )
}

export default ContentRoute