import { API_MASTER_POLICY } from '../../../../../api/constants';
import { API_POLICY_VIEW_LIST } from '../../../../../api/constants';
import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { API_MYTEAMVERTICAL } from '../../../../../api/constants';
import { API_GET_DEPARTMENT } from '../../../../../api/constants';
import { API_POLICY_PREMIUM } from '../../../../../api/constants';
import { fetchSuccess, loading } from '../../../../../utils/action.util';

export const ACTION_MASTER_POLICY_TYPE_LOADING = 'ACTION_MASTER_POLICY_TYPE_LOADING';
export const ACTION_MASTER_POLICY_TYPE_SUCCESS = 'ACTION_MASTER_POLICY_TYPE_SUCCESS';


export const ACTION_POLICY_VIEW_LIST_LOADING = 'ACTION_POLICY_VIEW_LIST_LOADING';
export const ACTION_POLICY_VIEW_LIST_SUCCESS = 'ACTION_POLICY_VIEW_LIST_SUCCESS';


export const ACTION_MASTER_ASSIGN_RM_LOADING = 'ACTION_MASTER_ASSIGN_RM_LOADING';
export const ACTION_MASTER_ASSIGN_RM_SUCCESS = 'ACTION_MASTER_ASSIGN_RM_SUCCESS';

export const ACTION_CLAIMS_POLICY_TYPE_LOADING = 'ACTION_CLAIMS_POLICY_TYPE_LOADING';
export const ACTION_CLAIMS_POLICY_TYPE_SUCCESS = 'ACTION_CLAIMS_POLICY_TYPE_SUCCESS';

export function actionProposalRM(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}
export function actionDepartmentPolicyType() {
    return (dispatch, getState) =>
        Api.get(API_GET_DEPARTMENT).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}
export function actionPolicyList(params) {

    return (dispatch, getState) =>
        Api.post(API_POLICY_VIEW_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_POLICY_VIEW_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_POLICY_VIEW_LIST_LOADING, { loading: false, data }));
        });
}

export function actionPolicyType(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_POLICY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MASTER_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}
