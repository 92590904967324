import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from "jquery";
import { Controller, useForm } from 'react-hook-form';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../component/form/error_label";
import { actionEditAssignRetention, ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS } from "./action";
import { actionAssignRetention, ACTION_MASTER_ASSIGN_RETENTION_SUCCESS } from "./action";
import { UNAUTHORIZED } from '../../../constants';

const EditAssignRetention = (props) => {

    const { handleSubmit, control, formState: { errors }, setValue } = useForm();

    const [assignRetention, setAssignRetention] = useState([]);

    const initialRender = () => {

        setAssignRetention([]);

        props.actionEditAssignRetention({ user_id: props.auth.getUser().id });

        $("#edit_retention-loader").addClass("hidden");

        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onMasterAssignRetentionUpdate = () => {
        const { AssignRetention } = props;

        if (AssignRetention != null) {
            var AssignRetentionResponse = Object.assign({}, AssignRetention);
            if (AssignRetentionResponse.result === true) {
                var arrAssignRetention = Object.assign([], AssignRetentionResponse.response);
                setAssignRetention(arrAssignRetention);
            } else {
                switch (AssignRetentionResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterAssignRetentionUpdate, [props.AssignRetention]);

    const onAssignRetentionUpdate = () => {
        const { EditRetention } = props;
        if (EditRetention != null) {
            var EditRetentionResponse = Object.assign({}, EditRetention);
            $("#edit_retention-loader").addClass("hidden");
            $("#edit_retention").prop("disabled", false);
            if (EditRetentionResponse.result === true) {
                props.handleClose({
                    updated: true
                })
            } else {
                switch (EditRetentionResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAssignRetentionUpdate, [props.AssignRetention]);

    useEffect(() => {
        if (assignRetention != null && assignRetention.length > 0) {
            if (props.retentionUserId > 0) {
                setValue('retention_user_id', props.retentionUserId);
            }
        }

    }, [assignRetention])

    const onSubmit = (data) => {

        data.retention_approved_by = props.auth.getUser().id;
        data.id = props.clientId;

        $("#edit_retention-loader").removeClass("hidden");
        $("#edit_retention").prop("disabled", true);

        props.actionAssignRetention(data);
    }

    return (
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Edit Assign Retention
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose({
                                    updated: false
                                });
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Assign Retention&nbsp;<span className="danger">
                                                        *
                                                    </span></label>
                                                    <Controller
                                                        name="retention_user_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={assignRetention}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Assign Retention"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select Assign Retention'
                                                        }}
                                                    />
                                                    {errors.retention_user_id && <ErrorLabel message={errors.retention_user_id?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="edit_retention"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="edit_retention-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose({
                                                    updated: false
                                                });
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => {

    var AssignRetention = null;
    var EditRetention = null;

    if (
        state.EditAssignRetentionReducer != null &&
        Object.keys(state.EditAssignRetentionReducer).length > 0 &&
        state.EditAssignRetentionReducer.constructor === Object
    ) {
        switch (state.EditAssignRetentionReducer.tag) {
            case ACTION_MASTER_EDIT_ASSIGN_RETENTION_SUCCESS:
                AssignRetention = Object.assign({}, state.EditAssignRetentionReducer.data);
                delete state.EditAssignRetentionReducer;
                return { AssignRetention };
            case ACTION_MASTER_ASSIGN_RETENTION_SUCCESS:
                EditRetention = Object.assign({}, state.EditAssignRetentionReducer.data);
                delete state.EditAssignRetentionReducer;
                return { EditRetention };
            default:
        }

    }
    return {
        AssignRetention,
        EditRetention
    }

}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionEditAssignRetention,
        actionAssignRetention
    }, dispatch)
}


export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditAssignRetention))