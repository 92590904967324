import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../../../../component/form/error_label";
import { actionAddEducation, ACTION_ADD_EDUCATION_SUCCESS } from './action';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from "react-auth-guard";
import { toast } from "react-toastify";

const EducationAdd = (props) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const initialRender = () => {

        if (props.show === true) {

            reset({
                education: '',
                year_of_passing: '',
                institute_name: ''
            })

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }

    }
    useEffect(initialRender, [props.show]);

    const onAddEducationUpdate = () => {
        const { AddEducation } = props;
        var AddEducationResponse = Object.assign({}, AddEducation);
        $("#add_education").prop("disabled", false);
        if (AddEducationResponse.result === true) {
            props.handleClose(AddEducationResponse.response);
            $("#education-loader").addClass("hidden");
        } else {
            $("#education-loader").addClass("hidden");
            switch (AddEducationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(AddEducationResponse.message);
                default:
            }
        }
    }
    useEffect(onAddEducationUpdate, [props.AddEducation]);

    const onSubmit = (data) => {
        $("#education-loader").removeClass("hidden");
        $("#add_education").prop("disabled", true);
        data.user_id = props.auth.getUser().user_id;
        props.actionAddEducation(data);
    }

    return (
        <>
            <div
                className="modal fade show"
                id="default"
                role="dialog"
                aria-labelledby="education-add"
                aria-hidden="true"
                style={{ display: `${props.show === true ? "block" : "none"}` }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="media-middle mt-1">
                                <h4 className="modal-title" id="education-add">
                                    Add Education Details
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handleClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Education&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="education"
                                                            name="education"
                                                            placeholder="Enter Education"
                                                            {...register('education', { required: 'Education is required' })}
                                                        />
                                                        {errors.education && <ErrorLabel message={errors.education?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Year of Passing&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="year_of_passing"
                                                            name="year_of_passing"
                                                            placeholder="Enter Year of Passing"
                                                            {...register('year_of_passing', { required: 'Year of Passing is required' })}
                                                        />
                                                        {errors.year_of_passing && <ErrorLabel message={errors.year_of_passing?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <label className="label-control">Institute Name&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            id="institute_name"
                                                            name="institute_name"
                                                            placeholder="Enter Institute Name"
                                                            {...register('institute_name', { required: 'Institute Name is required' })}
                                                        />
                                                        {errors.institute_name && <ErrorLabel message={errors.institute_name?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                                            <button
                                                id="add_education"
                                                className="btn btn-primary mr-1 shadow round text-uppercase"
                                                type="submit"
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Submit
                                                    <span id="education-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                            <button
                                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                                onClick={(event) => {
                                                    props.handleClose();
                                                    event.preventDefault();
                                                }}
                                            > Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}



const mapStateToProps = (state) => {
    var AddEducation = null;

    if (
        state.AddEducationReducer != null &&
        Object.keys(state.AddEducationReducer).length > 0 &&
        state.AddEducationReducer.constructor === Object
    ) {
        switch (state.AddEducationReducer.tag) {
            case ACTION_ADD_EDUCATION_SUCCESS:
                AddEducation = Object.assign({}, state.AddEducationReducer.data);
                delete state.AddEducationReducer;
                return { AddEducation };
            default:
        }
    }

    return {
        AddEducation
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAddEducation }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EducationAdd))

