import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    RemoteOperations,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import { UNAUTHORIZED } from "../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { verifyPermission } from '../../../../../utils';
import { actionRetentionList, ACTION_RETENTION_LIST_SUCCESS } from './action';
import { actionAssignRetentionList, ACTION_RETENTION_ASSIGN_LIST_SUCCESS } from './action';
import EditAssignRetention from '../../../../../component/popups/edit_retention';

const RetentionList = (props) => {
    const { match } = props;

    const [columns, setColumns] = useState([]);
    const [retention, setRetention] = useState({});
    const [unassignedClientsCount, setUnassignedClientsCount] = useState(0);
    const [assignedClientsCount, setassignedClientsCount] = useState(0);
    const [showEditAssignRetention, setShowEditAssignRetention] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(0);
    const [selectRetentionUserId, setSelectRetentionUserId] = useState(0);
    const [retentionType, setRetentionType] = useState("assigned");


    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionRetentionList({
            type: "assigned"
        })
    }
    useEffect(initialRender, []);

    const onRetentionUpdate = () => {

        const { Retention } = props;

        if (Retention != null) {

            var RetentionResponse = Object.assign({}, Retention);

            if (RetentionResponse.result === true) {

                $("#progress-loader").addClass("hidden");

                if (retentionType == "unassigned") {


                    setColumns([
                        { dataField: "Action", caption: "Action", type: "button" },
                        { dataField: "full_name", caption: "Created By" },
                        { dataField: "name", caption: "Customer" },
                        { dataField: "company_group_name", caption: "Group" },
                        { dataField: "email", caption: "Email" },
                        { dataField: "mobile", caption: "Mobile" },
                        { dataField: "city_name", caption: "City Name" },
                        { dataField: "state_name", caption: "State Name" },
                    ]);


                    setUnassignedClientsCount(RetentionResponse.response.length);
                    setassignedClientsCount(0);
                } else {
                    $("#progress-loader").addClass("hidden");
                    setColumns([
                        { dataField: "Action", caption: "Action", type: "button" },
                        { dataField: "full_name", caption: "Created By" },
                        { dataField: "name", caption: "Customer" },
                        { dataField: "company_group_name", caption: "Group" },
                        { dataField: "email", caption: "Email" },
                        { dataField: "mobile", caption: "Mobile" },
                        { dataField: "city_name", caption: "City Name" },
                        { dataField: "state_name", caption: "State Name" },
                        { dataField: "retention_user", caption: "Assign To" },
                        { dataField: "retention_approved_by", caption: "Retention Approved By" }
                    ]);

                    setUnassignedClientsCount(0);
                    setassignedClientsCount(RetentionResponse.response.length);
                }

                setRetention(RetentionResponse.response);


            } else {
                $("#progress-loader").addClass("hidden");
                setRetention([])
                switch (RetentionResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onRetentionUpdate, [props.Retention]);

    const renderGroupNameCell = (cell) => {
        if (cell?.data?.company_group_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/client/group-level-view',
                        state: { client_group_id: cell?.data?.client_group_id }
                    });
                }}
            ><u>{cell?.data?.company_group_name}</u></a>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("VIEW_RETENTION") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/client/view',
                                                    state: {
                                                        client_id: params.data.id,
                                                        type: 'Client'
                                                    }
                                                })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("EDIT_RETENTION") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                setSelectedClientId(params.data.id);
                                                if (retentionType == "assigned") {
                                                    setSelectRetentionUserId(params.data.retention_user_id);
                                                } else {
                                                    setSelectRetentionUserId(0);
                                                }
                                                setShowEditAssignRetention(true)
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }

                    </Column>
                );
            } else if (objColumn.dataField === "company_group_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderGroupNameCell}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setRetentionType("assigned");
                                    props.actionRetentionList({
                                        type: "assigned"
                                    });
                                }}
                                className={`btn ${assignedClientsCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                Assigned Clients
                                <span className="ml-half">{assignedClientsCount > 0 ? `(` + assignedClientsCount + `)` : ``}</span>
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setRetentionType("unassigned");
                                    props.actionRetentionList({
                                        type: "unassigned"
                                    });
                                }}
                                className={`btn ${unassignedClientsCount > 0 ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                Unassigned Clients
                                <span className="ml-half">{unassignedClientsCount > 0 ? `(` + unassignedClientsCount + `)` : ``}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={retention}
                                showBorders={true}
                                remoteOperations={true}
                                export={{
                                    fileName: "Retention",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}

                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
            <EditAssignRetention
                show={showEditAssignRetention}
                clientId={selectedClientId}
                retentionUserId={selectRetentionUserId}
                handleClose={(retention) => {
                    if (retention != null && retention.updated == true) {
                        toast.success("Retention updated successfully");
                        props.actionRetentionList({
                            type: retentionType
                        })
                    }
                    setShowEditAssignRetention(false)
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    var Retention = null;
    var RetentionAssign = null;

    if (
        state.RetentionListReducer != null &&
        Object.keys(state.RetentionListReducer).length > 0 &&
        state.RetentionListReducer.constructor === Object
    ) {
        switch (state.RetentionListReducer.tag) {
            case ACTION_RETENTION_LIST_SUCCESS:
                Retention = Object.assign({}, state.RetentionListReducer.data);
                delete state.RetentionListReducer;
                return { Retention };
            case ACTION_RETENTION_ASSIGN_LIST_SUCCESS:
                RetentionAssign = Object.assign({}, state.RetentionListReducer.data);
                delete state.RetentionListReducer;
                return { RetentionAssign };
            default:

        }
    }
    return {
        Retention,
        RetentionAssign,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRetentionList, actionAssignRetentionList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RetentionList))

