import Api from '../../../../../api';
import { PRINT } from '../../../../../utils';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_USER_NOTIFICATIONS } from '../../../../../api/constants';
import { API_READ_NOTIFICATIONS } from '../../../../../api/constants';
import { API_DELETE_NOTIFICATIONS } from '../../../../../api/constants';
import { API_DELETE_ALL_NOTIFICATIONS } from '../../../../../api/constants';

export const ACTION_USER_NOTIFICATIONS_LOADING = 'ACTION_USER_NOTIFICATIONS_LOADING';
export const ACTION_USER_NOTIFICATIONS_SUCCESS = 'ACTION_USER_NOTIFICATIONS_SUCCESS';

export const ACTION_DELETE_NOTIFICATIONS_LOADING = 'ACTION_DELETE_NOTIFICATIONS_LOADING';
export const ACTION_DELETE_NOTIFICATIONS_SUCCESS = 'ACTION_DELETE_NOTIFICATIONS_SUCCESS';

export const ACTION_DELETE_ALL_NOTIFICATIONS_LOADING = 'ACTION_DELETE_ALL_NOTIFICATIONS_LOADING';
export const ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS = 'ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS';

export const ACTION_READ_NOTIFICATIONS_LOADING = 'ACTION_READ_NOTIFICATIONS_LOADING';
export const ACTION_READ_NOTIFICATIONS_SUCCESS = 'ACTION_READ_NOTIFICATIONS_SUCCESS';

export function actionUserNotification() {
    return (dispatch, getState) =>
        Api.get(API_USER_NOTIFICATIONS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_USER_NOTIFICATIONS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_USER_NOTIFICATIONS_LOADING, { loading: false, data }));
        });
}
export function actionDeleteNotification(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_NOTIFICATIONS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DELETE_NOTIFICATIONS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DELETE_NOTIFICATIONS_LOADING, { loading: false, data }));
        });
}
export function actionDeleteAllNotification(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_ALL_NOTIFICATIONS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_DELETE_ALL_NOTIFICATIONS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DELETE_ALL_NOTIFICATIONS_LOADING, { loading: false, data }));
        });
}
export function actionReadNotification() {
    return (dispatch, getState) =>
        Api.get(API_READ_NOTIFICATIONS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_READ_NOTIFICATIONS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_READ_NOTIFICATIONS_LOADING, { loading: false, data }));
        });
}
