import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import ChangeStatus from '../../../../../../component/popups/approval_action';
import { generateInitialLettersString } from '../../../../../../utils';
import { REQUEST_REGULARIZE, UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import RequestHistory from '../../../../../../component/widgets/request_history';
import { actionRegularizeApprovalView, ACTION_REGULARIZE_APPROVALS_VIEW_SUCCESS } from './action';

const RegularizeApprovalsView = (props) => {

    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [regularizeApprovalView, setRegularizeApprovalView] = useState([]);
    const [requestHistory, setRequestHistory] = useState(null);
    const [detailsData, setDetailsData] = useState(null);

    const initialRender = () => {
        setDetailsData(JSON.parse(localStorage.getItem('detailsRegularizedData')))
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (detailsData != null || props?.location?.state?.id) {
            props.actionRegularizeApprovalView({
                id: detailsData?.id || props?.location?.state?.id
            });
        }
    }, [detailsData, props?.location?.state?.id])

    const onRegularizeApprovalViewUpdate = () => {
        const { RegularizeApprovalView } = props;
        var RegularizeApprovalViewResponse = Object.assign({}, RegularizeApprovalView);
        if (RegularizeApprovalViewResponse.result === true) {
            setRegularizeApprovalView(RegularizeApprovalViewResponse.response);
            setData(KEY_TOAST_MESSAGE, "Status Change Successfully");
            if (RegularizeApprovalViewResponse.response.request_status.length > 0) {
                setRequestHistory(RegularizeApprovalViewResponse.response.request_status);
            }
        } else {
            switch (RegularizeApprovalViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onRegularizeApprovalViewUpdate, [props.RegularizeApprovalView])

    return (
        <>
            <ScreenTitle title="Regularize Request Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (regularizeApprovalView != null && regularizeApprovalView.profile_photo != null) ?
                                                <img
                                                    src={regularizeApprovalView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(regularizeApprovalView.full_name))}

                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    regularizeApprovalView != null && regularizeApprovalView.full_name != null ?
                                                        regularizeApprovalView.full_name : ''
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success font-small-1 text-bold-500 round">
                                                {
                                                    regularizeApprovalView != null && regularizeApprovalView.designation_name != null ?
                                                        regularizeApprovalView.designation_name : ''
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        regularizeApprovalView != null && regularizeApprovalView.status != null ?
                                                            regularizeApprovalView.status : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2">By <span className="text-bold-700 text-justify">
                                                {
                                                    regularizeApprovalView != null && regularizeApprovalView?.status != null &&
                                                        regularizeApprovalView.status != 'Cancel By Employee' ? regularizeApprovalView?.approval_manager_name : regularizeApprovalView?.full_name
                                                }
                                            </span> On: <span className="text-primary">{regularizeApprovalView != null && regularizeApprovalView.modified_at != null ? regularizeApprovalView.modified_at : ''}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">User Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.full_name != null ?
                                            regularizeApprovalView.full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.approval_manager_name != null ?
                                            regularizeApprovalView.approval_manager_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Old Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.old_status != null ?
                                            regularizeApprovalView.old_status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">New Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.new_status != null ?
                                            regularizeApprovalView.new_status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Reason</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.reason != null ?
                                            regularizeApprovalView.reason : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Regularize Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.to_date != null ?
                                            regularizeApprovalView.to_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.status != null ?
                                            regularizeApprovalView.status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        regularizeApprovalView != null && regularizeApprovalView.remark != null ?
                                            regularizeApprovalView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 pt-3 float-none">
                            {
                                regularizeApprovalView != null && (regularizeApprovalView.status === 'Review Supervisor') ?
                                    <button
                                        id="your_action"
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            setShowChangeStatus(true);
                                        }}
                                    >Your Action</button>
                                    :
                                    <></>
                            }
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'REGULARIZE_REQUEST_TO_AM') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
                <ChangeStatus
                    approval={regularizeApprovalView}
                    show={showChangeStatus}
                    type={REQUEST_REGULARIZE}
                    handleClose={() => {
                        setRequestHistory(null);
                        props.actionRegularizeApprovalView({
                            id: detailsData?.id,
                        });
                        setShowChangeStatus(false);
                    }}
                />
            </section>
        </>
    )

}

const mapStateToProps = (state) => {
    var RegularizeApprovalView = null;

    if (
        state.RegularizeApprovalViewReducer != null &&
        Object.keys(state.RegularizeApprovalViewReducer).length > 0 &&
        state.RegularizeApprovalViewReducer.constructor === Object
    ) {
        switch (state.RegularizeApprovalViewReducer.tag) {
            case ACTION_REGULARIZE_APPROVALS_VIEW_SUCCESS:
                RegularizeApprovalView = Object.assign({}, state.RegularizeApprovalViewReducer.data);
                delete state.RegularizeApprovalViewReducer;
                return { RegularizeApprovalView };
            default:
        }
    }

    return {
        RegularizeApprovalView
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRegularizeApprovalView }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RegularizeApprovalsView))