import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_CLIENTTABMEETINGSVIEW } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const CLIENTTABMEETINGSVIEW_LOADING = 'CLIENTTABMEETINGSVIEW_LOADING';
export const ACTION_CLIENTTABMEETINGSVIEW_SUCCESS = 'ACTION_CLIENTTABMEETINGSVIEW_SUCCESS';

export function actionClientTabMeetingsView(params) {
        return (dispatch, getState) => Api.post(API_CLIENTTABMEETINGSVIEW, params).then((res) => {
            const data = res;
            PRINT('CLIENTTABMEETINGSVIEW ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_CLIENTTABMEETINGSVIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(CLIENTTABMEETINGSVIEW_LOADING, { loading: false, data }));
        });
    }

