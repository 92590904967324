import { API_WELLNESS_MANAGEMENT_REPORT } from '../../../../../api/constants';
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';

export const ACTION_WELLNESS_MANAGEMENT_REPORT_LOADING = 'ACTION_WELLNESS_MANAGEMENT_REPORT_LOADING';
export const ACTION_WELLNESS_MANAGEMENT_REPORT_SUCCESS = 'ACTION_WELLNESS_MANAGEMENT_REPORT_SUCCESS';

export function actionWellnessManagementReport(params) {
    return (dispatch, getState) =>
        Api.post(API_WELLNESS_MANAGEMENT_REPORT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_MANAGEMENT_REPORT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNESS_MANAGEMENT_REPORT_LOADING, { loading: false, data }));
        });
}