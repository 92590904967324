import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ScreenTitle from '../../../../../../../component/screen_title';
import { useForm } from 'react-hook-form';
import { FiEye } from 'react-icons/fi';

const BranchMasterView = (props) => {
    const { formState: { errors }, register, setValue } = useForm();

    const [branchDetails, setBranchDetails] = useState(null);

    const initialRender = () => {
        if (props?.location?.state?.detail != null) {
            setBranchDetails(props?.location?.state?.detail);
        }
    }
    useEffect(initialRender, []);

    return (
        <>
            <ScreenTitle title="Branch Details" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row mt-2">
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch ID</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.branch_id : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch Type</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.type : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch Code</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.branch_code : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch Short Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.short_name : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch State</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.state : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch City</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.city : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch Address</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.address : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch Status</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        branchDetails != null ?
                                            branchDetails?.status : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Contact Details</h6>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                {
                                    branchDetails != null && branchDetails?.contacts.length > 0 ?
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    {/* <th>Action</th> */}
                                                    <th>Primary Contact Name</th>
                                                    <th>Mobile Number</th>
                                                    <th>Email ID</th>
                                                    <th>Designation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    branchDetails?.contacts.map((content, indexContact) => (
                                                        <tr key={content.id}>
                                                            {/* <td><FiEye size={16} className="primary cursor-pointer mx-auto"
                                                                onClick={() => {
                                                                    props.history.push({
                                                                        pathname: '/master/insurer/branch/contacts', state: {
                                                                            detail: branchDetails
                                                                        }
                                                                    })

                                                                }}
                                                            /></td> */}
                                                            <td>{content.name}</td>
                                                            <td>{content.mobile}</td>
                                                            <td>{content.email}</td>
                                                            <td>{content.designation}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                            <button
                                className="btn btn-primary mr-1 shadow round text-uppercase mt-1"
                                onClick={() => {
                                    props.history.push({
                                        pathname: '/master/insurer/branch/contacts', state: {
                                            detail: branchDetails
                                        }
                                    })

                                }}
                            >View All Contacts</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BranchMasterView