import React, { useState, useEffect } from "react";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from "react-icons/fi";
import { Template } from "devextreme-react";
import moment from "moment";
import $ from "jquery";
import { TagBox } from "devextreme-react/tag-box";
import ArrayStore from "devextreme/data/array_store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../../../../constants";
import { verifyPermission } from "../../../../../../utils";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
} from "../../../../../../utils/preferences";
import {  Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { withAuth } from "react-auth-guard";
import {
  actionReimbursementApprovalList,
  ACTION_REIMBURSEMENT_APPROVAL_LIST_SUCCESS,
} from "./action";

const ReimbursementApprovalList = (props) => {
  const { match } = props;
  const { control } = useForm({});
  const [policyTypeValue, setPolicyTypeValue] = useState(["pending"]);
  const [selectedFromMonth, setSelectedFromMonth] = useState(
    moment().subtract(6, "months").format("YYYY-MM")
  );
  const [selectedToMonth, setSelectedToMonth] = useState(
    moment().format("YYYY-MM")
  );
  const [ReimbursementApprovalsList, setReimbursementApprovalsList] = useState(
    []
  );
  const [policyTypeDropdown, setPolicyTypeDropdown] = useState([
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Approved",
      value: "approved",
    },
    {
      name: "Rejected",
      value: "rejected",
    }
  ]);
  const columns = [
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "full_name", caption: " Full Employee Name", visible: true },
    { dataField: "rem_month", caption: "Month", visible: true },
    { dataField: "rem_year", caption: "Year", visible: true },
    { dataField: "claim_amount", caption: "Claim Amount", visible: true },
    {
      dataField: "approval_amount",
      caption: "Approved Amount ",
      visible: true,
    },
    { dataField: "issued_date", caption: "Issue Date", visible: false },
    { dataField: "issued_date_formated", caption: "Issue Date", visible: true },
    { dataField: "status", caption: "Status", visible: true },
    { dataField: "remark", caption: "Remark", visible: true },
  ];
  const initialRender = () => {
    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }
    $("#progress-loader").removeClass("hidden");
    props.actionReimbursementApprovalList({
      user_type: "manager",
      from_month: moment(selectedFromMonth, "YYYY-MM").format("MM"),
      from_year: moment(selectedFromMonth, "YYYY-MM").format("YYYY"),
      to_month: moment(selectedToMonth, "YYYY-MM").format("MM"),
      to_year: moment(selectedToMonth, "YYYY-MM").format("YYYY"),
      status: policyTypeValue,
    });
  };
  useEffect(initialRender, []);

  useEffect(() => {
    $("#progress-loader").removeClass("hidden");
    props.actionReimbursementApprovalList({
      user_type: "manager",
      from_month: moment(selectedFromMonth, "YYYY-MM").format("MM"),
      from_year: moment(selectedFromMonth, "YYYY-MM").format("YYYY"),
      to_month: moment(selectedToMonth, "YYYY-MM").format("MM"),
      to_year: moment(selectedToMonth, "YYYY-MM").format("YYYY"),
      status: policyTypeValue,
    });
  }, [policyTypeValue]);

  const renderIssueDate = (cell) => {
    return (
      <div>
        {cell?.data?.issued_date_formated != null
          ? moment(cell?.data?.issued_date_formated).format("DD/MM/YYYY")
          : ""}
      </div>
    );
  };

  const renderEmployeeNameCell = (cell) => {
    if (cell?.data?.full_name != null) {
      return (
        <a
          className="text-primary"
          onClick={() => {
            props.history.push({
              pathname: "/profile",
              state: {
                user_id: cell?.data?.user_id,
              },
            });
          }}
        >
          <u>{cell?.data?.full_name}</u>
        </a>
      );
    }
  };

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="Action" type="buttons">
            {verifyPermission("VIEW_REIMBURSEMENT_APPROVAL") ? (
              <Button
                render={(params) => (
                  <button
                    className="btn btn-icon btn-sm"
                    onClick={() => {
                      props.history.push({
                        pathname: `${match.url}/view`,
                        state: {
                          id: params.data.id,
                          user_id: params.data.user_id,
                          month: params.data.month,
                          rem_year: params.data.rem_year,
                          rem_month: params.data.rem_month,
                          full_name: params.data.full_name,
                        },
                      });
                    }}
                  >
                    <FiEye size={16} className="primary" />
                  </button>
                )}
              />
            ) : (
              <></>
            )}
          </Column>
        );
      } else {
        if (objColumn.dataField === "reimbursement_id") {
          arrColumns.push(
            <Column
              dataField={objColumn.dataField}
              caption={objColumn.caption}
              visible={objColumn.visible}
              alignment="left"
              width={150}
            ></Column>
          );
        } else if (objColumn.dataField === "amount") {
          arrColumns.push(
            <Column
              dataField={objColumn.dataField}
              caption={objColumn.caption}
              visible={objColumn.visible}
              alignment="left"
              width={250}
            ></Column>
          );
        } else if (objColumn.dataField === "issued_date_formated") {
          arrColumns.push(
            <Column
              dataField={objColumn.dataField}
              caption={objColumn.caption}
              cellRender={renderIssueDate}
              visible={objColumn.visible}
            ></Column>
          );
        } else if (objColumn.dataField === "full_name") {
          arrColumns.push(
            <Column
              dataField={objColumn.dataField}
              caption={objColumn.caption}
              cellRender={renderEmployeeNameCell}
              visible={objColumn.visible}
            ></Column>
          );
        } else {
          arrColumns.push(
            <Column
              dataField={objColumn.dataField}
              caption={objColumn.caption}
              visible={objColumn.visible}
            ></Column>
          );
        }
      }
    });

    return arrColumns;
  };
  const onReimbursementApprovalListUpdate = () => {
    const { ReimbursementApprovalsList } = props;
    if (ReimbursementApprovalsList != null) {
      setReimbursementApprovalsList([]);
      var ReimbursementApprovalListResponse = Object.assign(
        {},
        ReimbursementApprovalsList
      );
      if (ReimbursementApprovalListResponse.result === true) {
        setReimbursementApprovalsList(
          ReimbursementApprovalListResponse.response.reimbursement_list
        );
        $("#progress-loader").addClass("hidden");
      } else {
        $("#progress-loader").addClass("hidden");
        switch (ReimbursementApprovalListResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            toast.success(
              "Your Login session has expired, Please try to Login again!"
            );
            break;
          default:
        }
      }
    }
  };
  useEffect(onReimbursementApprovalListUpdate, [
    props.ReimbursementApprovalsList,
  ]);

  return (
    <>
      <div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-0">
              Reimbursement Approvals{" "}
              {ReimbursementApprovalsList?.length === 0
                ? ""
                : `(${ReimbursementApprovalsList?.length})`}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <form nonValidate autoComplete="Off">
                  <div className="form-body">
                    <div className="row">
                      <div className="col-12 col-md-7 ">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <label className="label-control primary">
                              {" "}
                              From Month
                            </label>
                            <input
                              type="month"
                              className="form-control"
                              id="from_month"
                              name="from_year"
                              placeholderText="Select Month"
                              defaultValue={selectedFromMonth}
                              max={moment().format("YYYY-MM")}
                              onChange={(event) => {
                                setSelectedFromMonth(event.target.value);
                                if (
                                  moment(event.target.value) <=
                                  moment(selectedToMonth)
                                ) {
                                  props.actionReimbursementApprovalList({
                                    user_type: "manager",
                                    from_month: moment(
                                      event.target.value,
                                      "YYYY-MM"
                                    ).format("MM"),
                                    from_year: moment(
                                      event.target.value,
                                      "YYYY-MM"
                                    ).format("YYYY"),
                                    to_month: moment(
                                      selectedToMonth,
                                      "YYYY-MM"
                                    ).format("MM"),
                                    to_year: moment(
                                      selectedToMonth,
                                      "YYYY-MM"
                                    ).format("YYYY"),
                                    status: policyTypeValue,
                                  });
                                } else {
                                  setSelectedToMonth("");
                                  Swal.fire({
                                    title: "Error",
                                    text: "From month and year can't be greater than To month and year",
                                    icon: "error",
                                  }).then((success) => {});
                                }
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="label-control primary">
                              {" "}
                              To Month
                            </label>
                            <input
                              type="month"
                              className="form-control"
                              id="to_month"
                              name="to_year"
                              placeholderText="Select Month"
                              defaultValue={selectedToMonth}
                              max={moment().format("YYYY-MM")}
                              onChange={(event) => {
                                setSelectedToMonth(event.target.value);
                                if (
                                  moment(event.target.value) >=
                                  moment(selectedFromMonth)
                                ) {
                                  props.actionReimbursementApprovalList({
                                    user_type: "manager",
                                    from_month: moment(
                                      selectedFromMonth,
                                      "YYYY-MM"
                                    ).format("MM"),
                                    from_year: moment(
                                      selectedFromMonth,
                                      "YYYY-MM"
                                    ).format("YYYY"),
                                    to_month: moment(
                                      event.target.value,
                                      "YYYY-MM"
                                    ).format("MM"),
                                    to_year: moment(
                                      event.target.value,
                                      "YYYY-MM"
                                    ).format("YYYY"),
                                    status: policyTypeValue,
                                  });
                                } else {
                                  setSelectedFromMonth("");
                                  Swal.fire({
                                    title: "Error",
                                    text: "To month and year can't be less than from month and year",
                                    icon: "error",
                                  }).then((success) => {});
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 float-right">
                        <div className="form-group cust-tagbox ">
                          <label className="label-control primary">
                            Status
                          </label>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <TagBox
                                {...field}
                                dataSource={
                                  new ArrayStore({
                                    data: policyTypeDropdown,
                                    key: "id",
                                  })
                                }
                                dropDownOptions={{
                                  onInitialized: () => {
                                    console.log("initial");
                                  },
                                  onContentReady: () => {
                                    console.log("content ready");
                                  },
                                  onShowing: () => {
                                    console.log("showing");
                                  },
                                  onShown: () => {
                                    console.log("shown");
                                  },
                                }}
                                deferRendering={true}
                                displayExpr="name"
                                valueExpr="value"
                                name="status"
                                id="status"
                                placeholder="Select Status"
                                searchEnabled={true}
                                searchMode="contains"
                                maxDisplayedTags={2}
                                showMultiTagOnly={false}
                                stylingMode="underlined"
                                value={
                                  field.value ? field.value : policyTypeValue
                                }
                                onValueChanged={({ value }) => {
                                  if (value != null || value !== undefined) {
                                    setPolicyTypeValue(value);
                                    field.onChange(value);
                                  }
                                }}
                                showClearButton={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <DataGrid
                  dataSource={ReimbursementApprovalsList}
                  showBorders={true}
                  columnAutoWidth={true}
                  export={{
                    fileName: "Reimbursement Approvals List",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                  }}
                  onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption(
                      "issued_date_formated",
                      "visible",
                      false
                    );
                    event.component.columnOption(
                      "issued_date",
                      "visible",
                      true
                    );
                  }}
                  onExported={(event) => {
                    event.component.columnOption(
                      "issued_date_formated",
                      "visible",
                      true
                    );
                    event.component.columnOption(
                      "issued_date",
                      "visible",
                      false
                    );
                    event.component.endUpdate();
                  }}
                  onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                      location: "before",
                      template: "totalGroupCount",
                    });
                  }}
                >
                  <SearchPanel visible={true} />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  {renderColumns()}
                  <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                  />
                  <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                  />

                  <Template
                    name="totalGroupCount"
                    render={() => {
                      return <></>;
                    }}
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  var ReimbursementApprovalsList = null;
  if (
    state.ReimbursementApprovalListReducer != null &&
    Object.keys(state.ReimbursementApprovalListReducer).length > 0 &&
    state.ReimbursementApprovalListReducer.constructor === Object
  ) {
    switch (state.ReimbursementApprovalListReducer.tag) {
      case ACTION_REIMBURSEMENT_APPROVAL_LIST_SUCCESS:
        ReimbursementApprovalsList = Object.assign(
          {},
          state.ReimbursementApprovalListReducer.data
        );
        delete state.ReimbursementApprovalListReducer;
        return { ReimbursementApprovalsList };
      default:
    }
  }
  return {
    ReimbursementApprovalsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionReimbursementApprovalList }, dispatch);
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ReimbursementApprovalList)
);
