import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_GROUP_BUSINESS_SUMMARY } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_GROUP_BUSINESS_SUMMARY_LOADING = 'ACTION_GROUP_BUSINESS_SUMMARY_LOADING';
export const ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS = 'ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS';

export function actionGroupBusinessSummary(params) {
    return (dispatch, getState) =>
        Api.post(API_GROUP_BUSINESS_SUMMARY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_GROUP_BUSINESS_SUMMARY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GROUP_BUSINESS_SUMMARY_LOADING, { loading: false, data }));
        });
}
