import React from 'react';
import { Route, Switch } from 'react-router';
import AllMeetings from './all_meetings';
import CustomerMeetings from './customer';
import InsuranceCompanyMeetings from './insurance_company';
import InternalMeetings from './internal';
import RiMeetings from './ri-meetings';
import ServeyorMeetings from './serveyor';
import TPAMeetingRoute from './tpa';
import MeetingTrainingRoute from './training';

const MeetingRoute = (props) => {
    const { match } = props

    return (
        <Switch>
            <Route path={`${match.url}/customer-meetings`} component={CustomerMeetings} />
            <Route path={`${match.url}/insurance-company-meetings`} component={InsuranceCompanyMeetings} />
            <Route path={`${match.url}/internal-meetings`} component={InternalMeetings} />
            <Route path={`${match.url}/serveyor-meetings`} component={ServeyorMeetings} />
            <Route path={`${match.url}/tpa-meetings`} component={TPAMeetingRoute} />
            <Route path={`${match.url}/ri-meetings`} component={RiMeetings} />
            <Route path={`${match.url}/training`} component={MeetingTrainingRoute} />
            <Route exact path={`${match.url}`} component={AllMeetings} />
        </Switch>
    )
}

export default MeetingRoute