import { 
    API_POLICY_DETAILS,
    API_ENDORSEMENT_DETAILS,
    API_CLAIMS_DETAILS
} from '../../../api/constants';
import Api from '../../../api';
import { PRINT } from '../../../utils';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_POLICY_LOADING = 'ACTION_POLICY_LOADING';
export const ACTION_POLICY_SUCCESS = 'ACTION_POLICY_SUCCESS';

export const ACTION_ENDORSEMENTS_LOADING = 'ACTION_ENDORSEMENTS_LOADING';
export const ACTION_ENDORSEMENTS_SUCCESS = 'ACTION_ENDORSEMENTS_SUCCESS';

export const ACTION_CLAIMS_LOADING = 'ACTION_CLAIMS_LOADING';
export const ACTION_CLAIMS_SUCCESS = 'ACTION_CLAIMS_SUCCESS';

export function actionPolicyDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_POLICY_DETAILS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_POLICY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_POLICY_LOADING, { loading: false, data }));
        });
}

export function actionEndorsements(params) {
    return (dispatch, getState) =>
        Api.post(API_ENDORSEMENT_DETAILS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ENDORSEMENTS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ENDORSEMENTS_LOADING, { loading: false, data }));
        });
}

export function actionClaims(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_DETAILS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_LOADING, { loading: false, data }));
        });
}