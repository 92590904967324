import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppRoute from './app';

const Root = (props) => {
    const { history } = props;

    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={AppRoute} {...props}/>
            </Switch>
        </ConnectedRouter>
    )
}

export default Root