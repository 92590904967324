import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_BROKER_TRAINING_DETAIL } from '../../../../../../api/constants';
import { API_TRAINING_EMPLOYEE_DETAIL } from '../../../../../../api/constants';

export const ACTION_BROKER_TRAINING_DETAIL_LOADING = 'ACTION_BROKER_TRAINING_DETAIL_LOADING';
export const ACTION_BROKER_TRAINING_DETAIL_SUCCESS = 'ACTION_BROKER_TRAINING_DETAIL_SUCCESS';

export const ACTION_EMPLOYEE_DETAIL_LOADING = 'ACTION_EMPLOYEE_DETAIL_LOADING';
export const ACTION_EMPLOYEE_DETAIL_SUCCESS = 'ACTION_EMPLOYEE_DETAIL_SUCCESS';

export function actionBrokerTrainingDetail(params) {
    return (dispatch, getState) =>
        Api.post(API_BROKER_TRAINING_DETAIL, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_BROKER_TRAINING_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_BROKER_TRAINING_DETAIL_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeDetail(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAINING_EMPLOYEE_DETAIL, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EMPLOYEE_DETAIL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_DETAIL_LOADING, { loading: false, data }));
        });
}