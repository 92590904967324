import React from 'react';
import { Route, Switch } from 'react-router';
import EmployeeList from './employeelist';
import EmployeeView from './employee_view';
import Registration from './registration';
import EmployeeEdit from './employee_edit';
import LeaveList from './leaves_request/list';
import RequestAdd from './leaves_request/add';
import RegularizeList from './regularize_request/list';
import RegularizeAdd from './regularize_request/add';
import LeaveRequestView from './leaves_request/view';
import RegularizeRequestView from './regularize_request/view';
import AssetRequestList from './asset_request/list';
import AssetsRequestAdd from './asset_request/add';
import AssetRequestView from "./asset_request/view";
import TravelRequestList from "./travel_request/list"
import TravelRequestAdd from "./travel_request/add"
import TravelRequestView from "./travel_request/view";
import ItSupportAdd from "./it_support/add";
import ItSupportList from "./it_support/list";
import HolidayList from '../holiday/list';
import ItSupportView from './it_support/view';
import ReimbursementList from './reimbursement_request/list'
import ReimbursementAdd from './reimbursement_request/add';
import DeclarationsList from './declarations/list';
import DeclarationView from './declarations/view';
import SubmissionView from './declarations/view_submission'
import DeclarationPoolList from './declaration_pool/list';
import SubmissionPoolList from './submission_pool/list'
import SubmissionPoolView from "./submission_pool/view"
import SubmissionPoolEdit from "./submission_pool/edit"
import DeclarationPoolView from './declaration_pool/view';
import DeclarationPoolEdit from './declaration_pool/edit';

const HRMSRoute = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route path={`${match.url}/registration`} component={Registration} />
            <Route path={`${match.url}/employee/view`} component={EmployeeView} />
            <Route path={`${match.url}/employee/edit`} component={EmployeeEdit} />
            <Route path={`${match.url}/request/add`} component={RequestAdd} />
            <Route path={`${match.url}/request/view`} component={LeaveRequestView} />
            <Route path={`${match.url}/request`} component={LeaveList} />
            <Route path={`${match.url}/regularize/view`} component={RegularizeRequestView} />
            <Route path={`${match.url}/regularize/add`} component={RegularizeAdd} />
            <Route path={`${match.url}/regularize`} component={RegularizeList} />
            <Route path={`${match.url}/asset-request/add`} component={AssetsRequestAdd} />
            <Route path={`${match.url}/asset-request/view`} component={AssetRequestView} />
            <Route path={`${match.url}/asset-request`} component={AssetRequestList} />
            <Route path={`${match.url}/travel-request/add`} component={TravelRequestAdd} />
            <Route path={`${match.url}/travel-request/view`} component={TravelRequestView} />
            <Route path={`${match.url}/travel-request`} component={TravelRequestList} />
            <Route path={`${match.url}/it-support/view`} component={ItSupportView} />
            <Route path={`${match.url}/it-support/add`} component={ItSupportAdd} />
            <Route path={`${match.url}/it-support/list`} component={ItSupportList} />
            <Route path={`/holidays`} component={HolidayList} />
            <Route path={`${match.url}/reimbursement_request/add`} component={ReimbursementAdd} />
            <Route path={`${match.url}/reimbursement_request`} component={ReimbursementList} />
            <Route path={`${match.url}/declarations/view`} component={DeclarationView} />
            <Route path={`${match.url}/submissions/view`} component={SubmissionView} />
            <Route path={`${match.url}/declarations`} component={DeclarationsList} />
            <Route path={`${match.url}/declaration-pool/list`} component={DeclarationPoolList} />
            <Route path={`${match.url}/submission-pool/list`} component={SubmissionPoolList} />
            <Route path={`${match.url}/declaration-pool/view`} component={DeclarationPoolView} />
            <Route path={`${match.url}/submission-pool/view`} component={SubmissionPoolView} />
            <Route path={`${match.url}/submission-pool/edit`} component={SubmissionPoolEdit} />
            <Route path={`${match.url}/declaration-pool/edit`} component={DeclarationPoolEdit} />
            <Route exact path={`${match.url}`} component={EmployeeList} />
        </Switch>
    )
}

export default HRMSRoute;