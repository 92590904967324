import React, { useEffect, useState } from "react";
import ScreenTitle from "../../../../../component/screen_title";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateBox } from "devextreme-react/date-box";
import ErrorLabel from "../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import { UNAUTHORIZED } from "../../../../../constants";
import dateFormat from "dateformat";
import { actionTravelUpdate, ACTION_TRAVEL_UPDATE_SUCCESS } from "./action";
import { actionGetEmpList, ACTION_GET_EMP_LIST_SUCCESS } from "./action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import { withAuth } from "react-auth-guard";
import { TagBox } from "devextreme-react/tag-box";
import ArrayStore from "devextreme/data/array_store";
import { toast } from "react-toastify";

const TravelCalendarAddEdit = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [accompaniedBy, setAccompaniedBy] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [mySelected, setMySelected] = useState(null);
  const [selectedRMId, setSelectedRMId] = useState(null);

  const initialRender = () => {
    props.actionGetEmpList();
  };
  useEffect(initialRender, []);

  useEffect(() => {
    if (props != null && props?.location?.state != null) {
      const {
        travel_to,
        start_date,
        end_date,
        status,
        rm_id,
        travel_accompanieds,
      } = props?.location?.state?.data;
      setValue("travel_to", travel_to);
      setValue("start_date", start_date);
      setValue("end_date", end_date);
      setValue("status", status);
      setValue("rm_id", rm_id);
      const accompaniedByIds = travel_accompanieds.map(
        (item) => item.travel_accompanied_details.user_id
      );

      setValue("accompanied_by_ids", accompaniedByIds);
    }
  }, [props?.location?.state]);

  const onTravelUpdateSuccess = () => {
    const { TravelUpdate } = props;
    const TravelUpdateResponse = Object.assign({}, TravelUpdate);
    if (TravelUpdateResponse?.result == true) {
      $("#travel_calendar-loader").addClass("hidden");
      $("#travel_calendar").prop("disabled", false);
      toast.success(TravelUpdateResponse?.message);
      props.history.push({
        pathname: `/travel-calendar`,
        state: { from: 'listView' },
      });
    } else {
      $("#travel_calendar-loader").addClass("hidden");
      $("#travel_calendar").prop("disabled", false);
      switch (TravelUpdateResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelUpdateSuccess, [props.TravelUpdate]);

  const onEmpListUpdate = () => {
    const { EmpList } = props;
    var EmpListResponse = Object.assign({}, EmpList);
    if (EmpListResponse.result === true) {
      setEmployeeList(EmpListResponse.response.users);
      setAccompaniedBy(EmpListResponse.response.users);
      setValue("rm_id", props?.location?.state?.data?.rm_id);
      const accompaniedByIds =
        props?.location?.state?.data?.travel_accompanieds?.map(
          (item) => item.travel_accompanied_details.user_id
        );
      setValue("accompanied_by_ids", accompaniedByIds);
    } else {
      switch (EmpListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onEmpListUpdate, [props.EmpList]);

  useEffect(() => {
    if (selectedRMId != props?.location?.state?.data?.rm_id) {
      setValue("accompanied_by_ids", []);
      setMySelected([]);
    }
  }, [selectedRMId]);

  useEffect(() => {
    if (employeeList && employeeList.length > 0 && selectedRMId) {
      const accompaniedBy = employeeList.filter(
        (empName) => selectedRMId && empName.id !== selectedRMId
      );
      setAccompaniedBy(accompaniedBy);
    }
  }, [employeeList, selectedRMId]);

  const onSubmit = (data) => {
    data.start_date= dateFormat(data.start_date, "yyyy-mm-dd");
    data.end_date= dateFormat(data.end_date, "yyyy-mm-dd");
    if(props?.location?.state?.data ){
      $("#travel_calendar-loader").removeClass("hidden");
      $("#travel_calendar").prop("disabled", true);
        data.id = props?.location?.state?.data?.id
        props.actionTravelUpdate({ dynamic_url: "edit", ...data });
    }else{
      $("#travel_calendar-loader").removeClass("hidden");
      $("#travel_calendar").prop("disabled", true);
        props.actionTravelUpdate({ dynamic_url: "add", ...data });
    }
  };

  return (
    <>
      <ScreenTitle title={(props?.location?.state?.data ? "Edit" :"Add")+ " Travel"} />
      <section>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <form
                  noValidate
                  autoComplete="Off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-body">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="label-control">
                            RM Name&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="rm_id"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                id="rm_id"
                                name="rm_id"
                                dataSource={employeeList}
                                allowFiltering={false}
                                placeholder="Select RM Name"
                                value={field.value}
                                fields={{ text: "name", value: "id" }}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.id);
                                    setSelectedRMId(event.itemData.id);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "RM Name is required",
                            }}
                          />
                          {errors.rm_id && (
                            <ErrorLabel message={errors.rm_id?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="label-control">
                            Travel to&nbsp;<span className="danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="travel_to"
                            name="travel_to"
                            maxLength={100}
                            placeholder="Enter Travel to"
                            {...register("travel_to", {
                              required: "Travel to is required",
                            })}
                          />
                          {errors.travel_to && (
                            <ErrorLabel message={errors.travel_to?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="label-control">
                            Accompanied By
                          </label>
                          <Controller
                            name="accompanied_by_ids"
                            control={control}
                            render={({ field }) => (
                              <TagBox
                                {...field}
                                id="accompanied_by_ids"
                                name="accompanied_by_ids"
                                dataSource={
                                  new ArrayStore({
                                    data: accompaniedBy,
                                    key: "id",
                                  })
                                }
                                dropDownOptions={{
                                  onInitialized: () => {
                                    console.log("initial");
                                  },
                                  onContentReady: () => {
                                    console.log("content ready");
                                  },
                                  onShowing: () => {
                                    console.log("showing");
                                  },
                                  onShown: () => {
                                    console.log("shown");
                                  },
                                }}
                                deferRendering={true}
                                displayExpr="name"
                                placeholder="Select Name"
                                valueExpr="id"
                                searchEnabled={true}
                                searchMode="contains"
                                acceptCustomValue={false}
                                maxDisplayedTags={4}
                                showSelectionControls={true}
                                showMultiTagOnly={false}
                                className="form-control"
                                stylingMode="underlined"
                                value={field.value != null && field.value.length > 0 ? field.value : mySelected}
                                onValueChanged={({ value, event }) => {
                                  if (
                                    (value != null || value !== undefined) &&
                                    value.length > 0
                                  ) {
                                    setMySelected(value);
                                    field.onChange(value);
                                  } else {
                                    if (
                                      mySelected != null &&
                                      mySelected.length > 0
                                    ) {
                                      field.onChange("");
                                      setMySelected([]);
                                    }
                                  }

                                  if (event != null) {
                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                        field.onChange("");
                                        setMySelected([])
                                    }
                                  }
                                }}
                                showClearButton={true}
                              />
                            )}
                          />
                          {errors.accompanied_by_ids && (
                            <ErrorLabel
                              message={errors.accompanied_by_ids?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="label-control">
                            Start Date&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="start_date"
                            control={control}
                            render={({ field }) => (
                              <DateBox
                                defaultValue={new Date()}
                                calendarOptions={{
                                  showTodayButton: true,
                                }}
                                acceptCustomValue={false}
                                stylingMode="underlined"
                                pickerType="calendar"
                                placeholder="Start Date"
                                type="date"
                                displayFormat="dd/MM/yyyy"
                                showClearButton={false}
                                value={field.value}
                                onValueChange={(value) => {
                                  field.onChange(
                                    dateFormat(value, "yyyy-mm-dd")
                                  );
                                }}
                              />
                            )}
                            rules={{
                              required: "Start Date is required",
                            }}
                          />
                          {errors.start_date && (
                            <ErrorLabel message={errors.start_date?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="label-control">
                            End Date&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="end_date"
                            control={control}
                            render={({ field }) => (
                              <DateBox
                                defaultValue={new Date()}
                                calendarOptions={{
                                  showTodayButton: true,
                                }}
                                acceptCustomValue={false}
                                stylingMode="underlined"
                                pickerType="calendar"
                                placeholder="End Date"
                                type="date"
                                displayFormat="dd/MM/yyyy"
                                showClearButton={false}
                                value={field.value}
                                onValueChange={(value) => {
                                  field.onChange(
                                    dateFormat(value, "yyyy-mm-dd")
                                  );
                                }}
                              />
                            )}
                            rules={{
                              required: "End Date is required",
                            }}
                          />
                          {errors.end_date && (
                            <ErrorLabel message={errors.end_date?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="label-control">
                            Status&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                id="status"
                                name="status"
                                dataSource={[
                                  {
                                    value: "Confirmed",
                                    text: "Confirmed",
                                  },
                                  {
                                    value: "Tentative",
                                    text: "Tentative",
                                  },
                                ]}
                                allowFiltering={false}
                                placeholder="Select Status"
                                value={field.value}
                                fields={{ text: "text", value: "value" }}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Status is required",
                            }}
                          />

                          {errors.status && (
                            <ErrorLabel message={errors.status?.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-actions border-0 float-none ">
                    <button
                      id="save_travel_calendar"
                      className="btn btn-primary mr-1 shadow round text-uppercase"
                      type="submit"
                    >
                      <div className="d-flex align-items-center">
                        Submit
                        <span
                          id="travel_calendar-loader"
                          className="spinner-border spinner-border-sm ml-half hidden"
                        ></span>
                      </div>
                    </button>
                    <button
                      className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                      onClick={(event) => {
                        event.preventDefault();
                        props?.history.goBack();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  var TravelUpdate = null;
  var EmpList = null;

  if (
    state.CalendarTravelUpdateReducer != null &&
    Object.keys(state.CalendarTravelUpdateReducer).length > 0 &&
    state.CalendarTravelUpdateReducer.constructor === Object
  ) {
    switch (state.CalendarTravelUpdateReducer.tag) {
      case ACTION_TRAVEL_UPDATE_SUCCESS:
        TravelUpdate = Object.assign(
          {},
          state.CalendarTravelUpdateReducer.data
        );
        delete state.CalendarTravelUpdateReducer;
        return { TravelUpdate };
      case ACTION_GET_EMP_LIST_SUCCESS:
        EmpList = Object.assign({}, state.CalendarTravelUpdateReducer.data);
        delete state.CalendarTravelUpdateReducer;
        return { EmpList };
      default:
    }
  }

  return {
    TravelUpdate,
    EmpList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionTravelUpdate, actionGetEmpList }, dispatch);
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(TravelCalendarAddEdit)
);
