import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { FiEdit, FiEye, FiInfo } from 'react-icons/fi';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UNAUTHORIZED } from '../../../../../constants';
import { actionClaimsView, ACTION_CLAIMS_VIEW_SUCCESS } from "./action";
import { actionClaimsDocumentList, ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS } from './action';
import { actionViewClaimEditEventDetails, ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS, } from './action';
import dateFormat from "dateformat";
import $ from "jquery";
import _ from 'lodash';
import { actionClaimsDocumentURL, ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS } from './action';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { HiOutlineDocumentAdd, HiOutlineDocumentSearch } from 'react-icons/hi';

const ViewClaim = (props) => {

    const { match } = props;



    const [claimView, setClaimView] = useState(null);
    const [claimsDocumentList, setClaimsDocumentList] = useState([]);
    const [viewEditClaimEventDetails, setViewEditClaimEventDetails] = useState([]);
    const [productType, setProductType] = useState(null);
    const [marineProductType, setMarineProductType] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [claimStatus, setClaimStatus] = useState(null);


    const initialRender = () => {
        props.actionClaimsView({ id: props.location.state.id });
        props.actionClaimsDocumentList({ claim_id: props.location.state.claim_id });
    }
    useEffect(initialRender, []);


    const onViewEditClaimEventDetailsUpdate = () => {
        const { ViewEditClaimEventDetails } = props;
        if (ViewEditClaimEventDetails != null) {
            var ViewEditClaimEventDetailsResponse = Object.assign({}, ViewEditClaimEventDetails);
            if (ViewEditClaimEventDetailsResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setColumns([
                    { dataField: "event_date", caption: "Event Date" },
                    { dataField: "event_type", caption: "Event" },
                    { dataField: "claim_status", caption: "Claim Status" },
                    { dataField: "event_details", caption: "Event Details" },
                    { dataField: "doc_requested", caption: "Doc Requested" },
                    { dataField: "followup_date", caption: "Followup Type" },
                    { dataField: "claim_handler", caption: "Claim Handler" },
                    { dataField: "entry_date", caption: "Entry Date" },
                    { dataField: "pending_date", caption: "Pending Date" },
                    { dataField: "claim_remark", caption: "Claims Remark" },
                ]);

                setViewEditClaimEventDetails(ViewEditClaimEventDetailsResponse.response);

            } else {
                $("#progress-loader").addClass("hidden");
                switch (ViewEditClaimEventDetailsResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onViewEditClaimEventDetailsUpdate, [props.ViewEditClaimEventDetails])
    const [columns, setColumns] = useState([
        { dataField: "event_date", caption: "Event Date" },
        { dataField: "event_type", caption: "Event" },
        { dataField: "claim_status", caption: "Claim Status" },
        { dataField: "event_details", caption: "Event Details" },
        { dataField: "surveyor_firm", caption: "Surv.Firm" },
        { dataField: "surveyor_name", caption: "Surv.Name" },
        { dataField: "surveyor_contact", caption: "Surv.Contact" },
        { dataField: "amount", caption: "Amount" },
        { dataField: "invoice_type", caption: "Invoice Type" },
        { dataField: "doc_requested", caption: "Doc Requested" },
        { dataField: "followup_date", caption: "Followup Type" },
        { dataField: "claim_handler", caption: "Claim Handler" },
        { dataField: "entry_date", caption: "Entry Date" },
        { dataField: "pending_date", caption: "Pending Date" },
        { dataField: "claim_remark", caption: "Claims Remark" },

    ]);

    const onClaimViewUpdate = () => {
        const { ClaimView } = props;
        if (ClaimView != null) {
            var ClaimViewResponse = Object.assign({}, ClaimView);
            if (ClaimViewResponse.result === true) {

                if (ClaimViewResponse.response.policy.department === 'MOTOR' || ClaimViewResponse.response.department === 'MARINE') {
                    setProductType(ClaimViewResponse.response.policy.department);
                    setMarineProductType(ClaimViewResponse.response.department);
                }
                if (ClaimViewResponse.response.product_details != null) {
                    setProductDetails(JSON.parse(ClaimViewResponse.response.product_details))
                }
                setClaimStatus(ClaimViewResponse.response.status);
                setClaimView(ClaimViewResponse.response);
            } else {
                switch (ClaimViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClaimViewUpdate, [props.ClaimView]);

    useEffect(() => {
        if (claimView != null && claimView.pinc_claim_no != null) {
            props.actionViewClaimEditEventDetails({
                pinc_claim_no: claimView.pinc_claim_no
            })
        }
    }, [claimView]);

    const onClaimsDocumentListUpdate = () => {
        const { ClaimsDocumentList } = props;
        if (ClaimsDocumentList != null) {
            var ClaimsDocumentListResponse = Object.assign({}, ClaimsDocumentList);
            if (ClaimsDocumentListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setClaimsDocumentList(ClaimsDocumentListResponse.response.documents);
            } else {
                $("#progress-loader").addClass("hidden");
                setClaimsDocumentList([]);
                switch (ClaimsDocumentListResponse.status) {
                    case UNAUTHORIZED:
                        $("#progress-loader").addClass("hidden");
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClaimsDocumentListUpdate, [props.ClaimsDocumentList]);

    const onClaimsDocumentGetUpdate = () => {
        const { ClaimsDocument } = props;
        if (ClaimsDocument != null) {

            window.open(ClaimsDocument.url);

        }
    }
    useEffect(onClaimsDocumentGetUpdate, [props.ClaimsDocument]);


    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/view` })
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/view` })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {

                                    }}
                                >
                                    <HiOutlineDocumentSearch size={18} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {

                                    }}
                                >
                                    <HiOutlineDocumentAdd size={18} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Claim Details</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            <li class="nav-item ml-6">
                                <button
                                    className={`btn btn-primary btn-md px-1 font-small-3 shadow round ${claimStatus != null && (claimStatus === 'Approved' || claimStatus === 'Part Settled' || claimStatus === 'Pending At Insurer' || claimStatus === 'Pending At Insured' || claimStatus === 'Pending' || claimStatus === 'Reopen') ? '' : 'hidden'}`}
                                    onClick={() => {
                                        props.history.push({ pathname: `/claims/edit`, state: { control_no: claimView.control_no, claim_id: claimView.id } });
                                    }}
                                >
                                    Edit Claim
                                </button>
                                <button
                                    className={`btn btn-primary btn-md px-1 font-small-3 shadow round ml-half ${claimStatus != null && (claimStatus === 'closed' || claimStatus === 'Closed' || claimStatus === 'Settled' || claimStatus === 'Rejected' || claimStatus === 'Repudiated' || claimStatus === 'Withdraw' || claimStatus === 'withdrawn' || claimStatus === 'Repuidated') ? '' : 'hidden'}`}
                                    onClick={() => {
                                        props.history.push({ pathname: `/claims/edit`, state: { control_no: claimView.control_no, claim_id: claimView.claim_id } })
                                    }}
                                >
                                    Re-Open
                                </button>
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round ml-half"
                                    onClick={() => {
                                        props.history.push({ pathname: `/claims/register/loss-site-visit/add`, state: { pinc_claim_ref_no: claimView.pinc_claim_ref_no, claim_id: claimView.id } });
                                    }}
                                >
                                    Register Loss Site
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off">
                            <div className="form-body">
                                <div className="row mb-1">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <div><FiInfo size={16} />&nbsp;Policy Details</div>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurance Company</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_name != null ?
                                                            claimView.insurer_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Branch</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.city != null ?
                                                            claimView.city : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Customer Name</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.customer_name != null ?
                                                            claimView.customer_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Customer Group</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.customer_group != null ?
                                                            claimView.customer_group : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Policy Type</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.policy != null ?
                                                            claimView.policy.policy_type : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Policy Number</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.policy_no != null ?
                                                            claimView.policy_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Policy Start Date</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.policy != null ?
                                                            dateFormat(claimView.policy.policy_start_date, "dd/mm/yyyy") : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Policy End Date</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.policy != null ?
                                                            dateFormat(claimView.policy.policy_expiry_date, "dd/mm/yyyy") : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Sum Insured</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.policy != null ?
                                                            Number(claimView.policy.sum_insured).toFixed(2) : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Gross Premium</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.policy != null ?
                                                            Number(claimView.policy.gross_premium).toFixed(2) : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">RM Name</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.rm_name != null ?
                                                            claimView.rm_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Control Number</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.control_no != null ?
                                                            claimView.control_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12 mt-1">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <div><FiInfo size={16} />&nbsp;Claim Intimation</div>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Pinc Claim ID</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.pinc_claim_ref_no != null ?
                                                            claimView.pinc_claim_ref_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Inc/Loss Date </h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.date_of_loss != null ?
                                                            claimView.date_of_loss : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Loss/Intimation Amount</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.loss_amount != null ?
                                                            claimView.loss_amount : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Excess Amount</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0" >
                                                    {
                                                        claimView != null && claimView.excess_amount ?
                                                            claimView.excess_amount : '-'
                                                    }
                                                </h4>

                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Inci/Loss Details</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.loss_details != null ?
                                                            claimView.loss_details : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insured Intimation Date </h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insured_intimation_date != null ?
                                                            dateFormat(claimView.insured_intimation_date, "dd/mm/yyyy") : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insured Name</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insured_name != null ?
                                                            claimView.insured_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insured Contact Details</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.contact_details != null ?
                                                            claimView.contact_details : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insured Email</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insured_email != null ?
                                                            claimView.insured_email : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insured Intimation Mode</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insured_intimation_mode ?
                                                            claimView.insured_intimation_mode : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insured Claim Ref No.</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insured_claim_ref_no != null ?
                                                            claimView.insured_claim_ref_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Nature of Loss</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.nature_of_claim != null ?
                                                            claimView.nature_of_claim : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Loss Location</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.loss_location ?
                                                            claimView.loss_location : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Loss State</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.loss_state != null ?
                                                            claimView.loss_state : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Claim Type</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.claim_type != null ?
                                                            claimView.claim_type : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Asset/Machine Details</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.asset_detail != null ?
                                                            claimView.asset_detail : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Policy Type</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.policy_type != null ?
                                                            claimView.policy_type : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">SI Currency</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.si_currency != null ?
                                                            claimView.si_currency : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Intimation Date</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_intimation_date != null ?
                                                            claimView.insurer_intimation_date : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Intimation Mode</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_intimation_mode != null ?
                                                            claimView.insurer_intimation_mode : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Claim no.</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_claim_no != null ?
                                                            claimView.insurer_claim_no : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Contact Name</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_contact_name != null ?
                                                            claimView.insurer_contact_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Contact Number</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_contact != null ?
                                                            claimView.insurer_contact : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Contact Email</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_contact_email != null ?
                                                            claimView.insurer_contact_email : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Second Contact Name </h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_second_contact_name != null ?
                                                            claimView.insurer_second_contact_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Second Contact Number </h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_second_contact != null ?
                                                            claimView.insurer_second_contact : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Insurer Second Contact Email</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.insurer_second_contact_email != null ?
                                                            claimView.insurer_second_contact_email : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Claim Handler Name</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.claim_handler_name != null ?
                                                            claimView.claim_handler_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Claim Handler Email</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.claim_handler_email != null ?
                                                            claimView.claim_handler_email : '-'
                                                    }

                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Claim Handler Branch</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.claim_handler_branch != null ?
                                                            claimView.claim_handler_branch : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Claim Status</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.status != null ?
                                                            claimView.status : '-'
                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Follow up Date</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.follow_up_date != null ?
                                                            dateFormat(claimView.follow_up_date, "dd/mm/yyyy") : '-'

                                                    }
                                                </h4>
                                            </div>
                                            <div className="col-12 col-lg-4 col-md-4">
                                                <h4 className="text-uppercase font-small-3 mb-half">Claim Remarks</h4>
                                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                                    {
                                                        claimView != null && claimView.claim_remark != null ?
                                                            claimView.claim_remark : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        productType != null && productType == 'MOTOR' ?
                                            <>
                                                <div className="col-12 col-md-12 col-lg-12 mt-1">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                                <div><FiInfo size={16} />&nbsp;Product Details</div>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Vehicle No./TC no.</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.vehicle_no != null ? productDetails.vehicle_no : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Engine Number</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.engine_no != null ? productDetails.engine_no : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Chassis Number</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.chassis_number != null ? productDetails.chassis_number : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Transporter Name</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.transporter_name != null ? productDetails.transporter_name : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Vehicle Make</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.vehicle_make != null ? productDetails.vehicle_make : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Vehicle Model</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.vehicle_model != null ? productDetails.vehicle_model : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Add on</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.add_on != null ? productDetails.add_on : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        marineProductType != null && marineProductType == 'MARINE' ?
                                            <>
                                                <div className="col-12 col-md-12 col-lg-12 mt-1">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                                <div><FiInfo size={16} />&nbsp;Product Details</div>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">MType</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.mtype != null ? productDetails.mtype : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Invoice Number</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.invoice_number != null ? productDetails.invoice_number : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Invoice Date</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.invoice_date != null ? productDetails.invoice_date : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Transit Bill No</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.transit_bill_no != null ? productDetails.transit_bill_no : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Transit Date</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.transit_date != null ? productDetails.transit_date : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Transit From</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.transit_from != null ? productDetails.transit_from : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Consignee Name</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.consignee_name != null ? productDetails.consignee_name : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Consignee Address</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.consignee_address != null ? productDetails.consignee_address : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Transporter</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.transporter != null ? productDetails.transporter : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Consignor Name</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.consignor_name != null ? productDetails.consignor_name : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Consignor Unit</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.consignor_unit != null ? productDetails.consignor_unit : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Qty Loss</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.qty_loss != null ? productDetails.qty_loss : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Survery Required</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.survery_required != null ? productDetails.survery_required : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Dealer Name</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.dealer_name != null ? productDetails.dealer_name : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Dealer Email</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.dealer_email != null ? productDetails.dealer_email : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className="col-12 col-lg-4 col-md-4">
                                                            <h4 className="text-uppercase font-small-3 mb-half">Bank Details Of Dealer's</h4>
                                                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                                                {
                                                                    productDetails != null && productDetails.bank_details_of_dealer != null ? productDetails.bank_details_of_dealer : '-'
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }


                                </div>
                            </div>
                        </form>
                        <div class="form-body">
                            <form>
                                <div className="row">
                                    <div class="col-12 col-md-12 col-lg-12 mt-1">
                                        <h4 class="d-flex align-items-center form-section mb-2 black">
                                            <FiInfo size={16} />&nbsp;Document
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        {
                                            claimsDocumentList != null && claimsDocumentList.length > 0 ?
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="px-1">Document Type</th>
                                                            <th className="px-1">Document Received Date</th>
                                                            <th className="px-1">Uploaded Document</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            claimsDocumentList.map((claimDocument, indexClaim) => (
                                                                <tr>
                                                                    <td>{_.capitalize(claimDocument.claim_document_type)}</td>
                                                                    <td>{claimDocument.document_receive_date}</td>
                                                                    <td>
                                                                        <button type="button" className="btn primary" onClick={() => {
                                                                            props.actionClaimsDocumentURL({
                                                                                'actual_file_relative_url': claimDocument.actual_file_relative_url
                                                                            });
                                                                        }}>View / Download Document</button>
                                                                        {/* <a target="_blank" href={claimDocument.full_url} download="Upload Document">View / Download Document</a> */}
                                                                    </td>

                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <h4>Document Not Found</h4>
                                        }
                                    </div>
                                </div>
                            </form>
                            <form>
                                <div class="col-12 col-md-12 col-lg-12 px-0">
                                    <h4 class="d-flex align-items-center form-section mb-2 black">
                                        Claim Event History
                                    </h4>
                                    <DataGrid
                                        dataSource={viewEditClaimEventDetails}
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        export={{
                                            fileName: "Claim Details",
                                            enabled: true,
                                            allowExportSelectedData: true,
                                            excelFilterEnabled: true,
                                        }}
                                    >
                                        <SearchPanel visible={true} />
                                        <FilterRow visible={true} />
                                        <HeaderFilter visible={true} />
                                        {renderColumns()}
                                        {/* {renderStatus()}
                                        {renderTravelType()} */}
                                        <Pager
                                            visible={true}
                                            showPageSizeSelector={false}
                                            showNavigationButtons={true}
                                            allowedPageSizes={[4, 6, 10]}
                                        />
                                        <Paging
                                            defaultPageIndex={0}
                                            defaultPageSize={10}
                                            enabled={true}
                                        />
                                    </DataGrid>
                                    <button class="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            props.history.goBack();
                                        }}> Back
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const mapStateToProps = (state) => {
    var ClaimView = null;
    var ClaimsDocumentList = null;
    var ViewEditClaimEventDetails = null;
    var ClaimsDocument = null;

    if (
        state.ViewClaimsReducer != null &&
        Object.keys(state.ViewClaimsReducer).length > 0 &&
        state.ViewClaimsReducer.constructor === Object
    ) {
        switch (state.ViewClaimsReducer.tag) {
            case ACTION_CLAIMS_VIEW_SUCCESS:
                ClaimView = Object.assign({}, state.ViewClaimsReducer.data);
                delete state.ViewClaimsReducer
                return { ClaimView };
            case ACTION_CLAIMS_DOCUMENT_VIEW_SUCCESS:
                ClaimsDocumentList = Object.assign({}, state.ViewClaimsReducer.data);
                delete state.ViewClaimsReducer;
                return { ClaimsDocumentList };
            case ACTION_VIEW_CLAIM_EDIT_EVENT_DETAILS_SUCCESS:
                ViewEditClaimEventDetails = Object.assign({}, state.ViewClaimsReducer.data);
                delete state.ViewClaimsReducer;
                return { ViewEditClaimEventDetails };
            case ACTION_VIEW_GET_CLAIMS_DOCUMENT_SUCCESS:
                ClaimsDocument = Object.assign({}, state.ViewClaimsReducer.data);
                delete state.ViewClaimsReducer;
                return { ClaimsDocument };
            default:
        }
    }

    return {
        ClaimView,
        ClaimsDocumentList,
        ViewEditClaimEventDetails,
        ClaimsDocument,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionClaimsView, actionClaimsDocumentList, actionClaimsDocumentURL, actionViewClaimEditEventDetails }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ViewClaim))