import React, { useEffect, useState } from "react"
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { CLAIM_FILE_ERROR_MESSAGE, CLAIM_FILE_SIZE, ERROR, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { getData, KEY_TOAST_MESSAGE, KEY_USER, setData } from "../../../../../../utils/preferences";
import moment from "moment";
import $ from 'jquery';
import { actionViewWellnessRequest, ACTION_VIEW_WELLNESS_REQUEST_SUCCESS, ACTION_EDIT_WELLNESS_TYPE_SUCCESS, actionWellnessManagementWellnessType, ACTION_EDIT_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS, actionWellnessManagementAssignRm } from "./action";
import { actionEditWellnessManagementClientName, ACTION_EDIT_WELLNESS_CLIENT_NAME_SUCCESS } from "./action";
import { actionEditWellnessManagementClientType, ACTION_EDIT_WELLNESS_CLIENT_TYPE_SUCCESS } from "./action";
import { actionEditWellnessRequest, ACTION_EDIT_WELLNESS_REQUEST_SUCCESS } from "./action";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { checkFileExtension } from "../../../../../../utils";
import _ from 'lodash';

const WellnessManagementEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [clientType, setClientType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [wellnessType, setWellnessType] = useState([]);
    const [viewNewRequest, SetViewNewRequest] = useState(null);
    const [selectType, setSelectType] = useState(null)
    const [showExecutedBy, setShowExecutedBy] = useState(null);
    const [selectedExecutedBy, setSelectedExecutedBy] = useState("");
    const [assignRm, setAssignRm] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [wellnessStatus, setWellnessStatus] = useState("close");
    const [files, setFiles] = useState([]);
    const validExt = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'txt', 'jpg', 'jpeg', 'png'];

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        var userDetail = getData(KEY_USER);
        if (userDetail != null) {
            var userObj = JSON.parse(userDetail)
            setDepartments(userObj.departments)
        }
        props.actionViewWellnessRequest({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (viewNewRequest != null) {
            setValue('created_at', viewNewRequest?.created_at != null ? moment(viewNewRequest?.created_at).format("DD/MM/YYYY") : '');
            setValue('address', viewNewRequest?.address != null ? viewNewRequest?.address : '');
            setValue('activity_date', viewNewRequest?.activity_date != null ? viewNewRequest?.activity_date : '');
            setValue('remarks', viewNewRequest?.remarks != null ? viewNewRequest?.remarks : '');
            setValue('wellness_type_detail', viewNewRequest?.wellness_type_detail != null ? viewNewRequest?.wellness_type_detail : '');
            setValue('wellness_executed_by', viewNewRequest?.wellness_executed_by != null ? viewNewRequest?.wellness_executed_by : '');
            if (viewNewRequest?.wellness_executed_by != null) {
                setSelectedExecutedBy(viewNewRequest?.wellness_executed_by);
                setShowExecutedBy(true);
            }
            if (viewNewRequest?.activity_date != null) {
                const check = new Date(viewNewRequest?.activity_date) < new Date().setHours(0, 0, 0, 0);
                setShowExecutedBy(check);
            }
            props.actionEditWellnessManagementClientType();
            props.actionWellnessManagementWellnessType();
            props.actionWellnessManagementAssignRm();
        }
    }, [viewNewRequest]);

    const onViewNewRequestUpdate = () => {
        const { ViewNewRequest } = props;
        if (ViewNewRequest != null) {
            var ViewNewRequestResponse = Object.assign({}, ViewNewRequest);
            if (ViewNewRequestResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                SetViewNewRequest(ViewNewRequestResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ViewNewRequestResponse.status) {
                    case UNAUTHORIZED:
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onViewNewRequestUpdate, [props.ViewNewRequest]);

    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (viewNewRequest != null && viewNewRequest.client_type != null) {
                setValue('client_type', viewNewRequest.client_type);
                props.actionEditWellnessManagementClientName({ client_type: viewNewRequest.client_type });
            }
        }
    }, [clientType])

    const onAssignRmUpdate = () => {
        const { AssignRm } = props;
        var AssignRmResponse = Object.assign({}, AssignRm);
        if (AssignRmResponse.result === true) {
            var arrAssignRM = Object.assign([], AssignRmResponse.response);
            var user = {
                user_id: 0,
                full_name: "None"
            };
            arrAssignRM.unshift(user);
            setAssignRm(arrAssignRM);
        } else {
            switch (AssignRmResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setAssignRm([]);
                    break;
                default:
            }
        }
    }
    useEffect(onAssignRmUpdate, [props.AssignRm]);


    useEffect(() => {
        if (assignRm != null && assignRm.length > 0) {
            if (viewNewRequest != null && viewNewRequest.assign_rm_id != null) {
                setValue('assign_rm', viewNewRequest?.assign_rm_id);
            }
        }
    }, [clientName])

    const onClientTypeUpdate = () => {
        const { ClientType } = props;
        var ClientTypeResponse = Object.assign({}, ClientType);
        if (ClientTypeResponse.result === true) {
            setClientType(ClientTypeResponse.response);
        } else {
            switch (ClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    useEffect(() => {
        if (clientName != null && clientName.length > 0) {
            if (viewNewRequest != null && viewNewRequest.client_id != null) {
                setValue('client_id', viewNewRequest?.client_id);
            }
        }
    }, [clientName])

    const onWellnessTypeUpdate = () => {
        const { WellnessType } = props;
        var WellnessTypeResponse = Object.assign({}, WellnessType);
        if (WellnessTypeResponse.result === true) {
            setWellnessType(WellnessTypeResponse.response);
        } else {
            switch (WellnessTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setWellnessType([]);
                    break;
                default:
            }
        }
    }
    useEffect(onWellnessTypeUpdate, [props.WellnessType]);

    useEffect(() => {
        if (wellnessType != null && wellnessType.length > 0) {
            if (viewNewRequest != null && viewNewRequest.wellness_type_id != null) {
                setValue('wellness_type_id', viewNewRequest?.wellness_type_id != null ? viewNewRequest?.wellness_type_id : null);
            }
        }
    }, [wellnessType])

    const onClientNameUpdate = () => {
        const { ClientName } = props;
        var ClientNameResponse = Object.assign({}, ClientName);
        if (ClientNameResponse.result === true) {
            setClientName(ClientNameResponse.response);
        } else {
            switch (ClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientNameUpdate, [props.ClientName]);

    const onRequestEditUpdate = () => {
        const { RequestEdit } = props;
        var RequestEditResponse = Object.assign({}, RequestEdit);
        $("#edit_wellness_management").prop("disabled", false);
        if (RequestEditResponse.result === true) {
            $("#wellness-management-edit-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, RequestEditResponse.message);
            props.history.goBack();
        } else {
            $("#wellness-management-edit-loader").addClass("hidden");
            switch (RequestEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestEditUpdate, [props.RequestEdit]);

    const onSubmit = (data) => {
        if(files != null && files.length > 0 && viewNewRequest?.wellness_request_document?.length >= 5){
            toast.error("Maximum 5 documents can be uploaded");
            return;
        }
        if(files != null && viewNewRequest?.wellness_request_document?.length + files?.length > 5){
            toast.error(`${viewNewRequest?.wellness_request_document?.length} documents uploaded already, only ${5 - viewNewRequest?.wellness_request_document?.length} more documents can be uploaded`);
            return;
        }

        $("#wellness-management-edit-loader").removeClass("hidden");
        $("#edit_wellness_management").prop("disabled", true);
        if (data.assign_rm == null || data.assign_rm == 0) { delete data.assign_rm; }
        data.id = viewNewRequest?.id;
        data.rm_status = data.rm_status != null ? data.rm_status : null;
        var sendFiles = [];
        if (files != null && files.length > 0) {
            $('#error-doc-not-found').addClass('d-none')
            files.forEach((file, indexFile) => {
                sendFiles.push({ 'name': `file[${indexFile}]`, 'value': file });
            });
        } else if(viewNewRequest?.wellness_request_document?.length === 0){
            if (data.rm_status === 'close') {
                $('#error-doc-not-found').removeClass('d-none')
                $("#wellness-pool-edit-loader").addClass("hidden");
                $("#edit_wellness_pool").prop("disabled", false);
                return
            }
        }
        delete data.file;
        if (data.rm_status === null || data.rm_status === "undefined" || data.rm_status === undefined) {
            delete data.rm_status;
        }
        if (data.actual_activity_date === null || data.actual_activity_date === "undefined" || data.actual_activity_date === undefined) {
            delete data.actual_activity_date;
        }
        if (data.wellness_executed_by === null || data.wellness_executed_by === "" || data.wellness_executed_by === undefined) {
            delete data.wellness_executed_by;
        }
        props.actionEditWellnessRequest(data, sendFiles);

    }
    return (
        <>
            <ScreenTitle title="Edit Wellness Management" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                enabled={false}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: ' Please select client type'
                                                        }}
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                enabled={false}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: viewNewRequest != null && viewNewRequest.client_id != null ? ' Please select client name' : false
                                                        }}
                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Activity</label>
                                                    <Controller
                                                        name="activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="activity_date"
                                                                name="activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    const check = new Date(value) < new Date().setHours(0, 0, 0, 0);
                                                                    if (!check) {
                                                                        setSelectedExecutedBy(null);
                                                                        setValue("wellness_executed_by", null);
                                                                        setValue("assign_rm", null);
                                                                    }
                                                                    setShowExecutedBy(check);
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.activity_date && <ErrorLabel message={errors.activity_date?.message} />}
                                                </div>
                                            </div>
                                            {showExecutedBy && <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Wellness Executed By&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_executed_by"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_executed_by"
                                                                name="wellness_executed_by"
                                                                dataSource={["Internal Team", "Direct Vendor / TPA"]}
                                                                allowFiltering={false}
                                                                placeholder="Select Wellness Executed By"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        if(event.itemData.value === "Direct Vendor / TPA") {
                                                                            setValue("rm_status", "close");
                                                                            setValue("assign_rm", null);
                                                                        } else {
                                                                            setValue("rm_status", null);
                                                                        }
                                                                        setSelectedExecutedBy(event.itemData.value)
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Wellness Executed By is required'
                                                        }}
                                                    />
                                                    {errors.wellness_executed_by && <ErrorLabel message={errors.wellness_executed_by?.message} />}
                                                </div>
                                            </div>
                                            }
                                            {(departments != null && departments.length > 0 && departments.includes('Wellness')) || (showExecutedBy && selectedExecutedBy === "Internal Team") ?
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Assign Engineer</label>
                                                        <Controller
                                                            name="assign_rm"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    id="assign_rm"
                                                                    name="assign_rm"
                                                                    dataSource={assignRm}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Assign"
                                                                    value={field.value}
                                                                    fields={{ text: 'full_name', value: 'user_id' }}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.user_id)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />

                                                        {errors.assign_rm && <ErrorLabel message={errors.assign_rm?.message} />}
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_type_id"
                                                                name="wellness_type_id"
                                                                fields={{ text: "name", value: "id" }}
                                                                dataSource={wellnessType}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectType(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type is required'
                                                        }}
                                                    />
                                                    {errors.wellness_type_id && <ErrorLabel message={errors.wellness_type_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectType === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="wellness_type_detail"
                                                                name="wellness_type_detail"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                {...register('wellness_type_detail', {
                                                                    required: selectType === "Others" ? "Description is required" : false
                                                                })}
                                                            />
                                                            {errors.wellness_type_detail?.type && <ErrorLabel message={errors.wellness_type_detail?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            {showExecutedBy && selectedExecutedBy === "Direct Vendor / TPA" ?
                                                <>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">RM Status</label>
                                                    <Controller
                                                        name="rm_status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="rm_status"
                                                                name="rm_status"
                                                                dataSource={[
                                                                    {
                                                                        text: 'Update',
                                                                        value: 'update'
                                                                    },
                                                                    {
                                                                        text: 'Cancel',
                                                                        value: 'cancel'
                                                                    },
                                                                    {
                                                                        text: 'Close',
                                                                        value: 'close'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value != null ? field.value : 'close'}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value);
                                                                        setWellnessStatus(event.itemData.value);
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.rm_status && <ErrorLabel message={errors.rm_status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Document&nbsp;<span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                        <div class="custom-file">
                                                            <input
                                                                type="file"
                                                                class="custom-file-input"
                                                                id="file"
                                                                name="file"
                                                                max={5}
                                                                accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.txt,.jpg,.jpeg,.png"
                                                                multiple={true}
                                                                onChange={(event) => {
                                                                    setFiles([]);
                                                                    $('#error-doc-not-found').addClass('d-none')
                                                                    if (event.target.files != null) {
                                                                        if (event.target.files && event.target.files.length <= 5) {
                                                                            var ext = (event.target.files[0]?.name)?.split('.')?.pop();
                                                                            var fileSize = event.target?.files[0]?.size;
                                                                            if (Math.round(fileSize / 1024) >= CLAIM_FILE_SIZE) {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: CLAIM_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }
                                                                            if (checkFileExtension(ext, validExt)) {
                                                                                var arrFiles = Object.assign([], files);
                                                                                var fileNames = '';
                                                                                if ((files.length + event.target.files.length) <= 5) {
                                                                                    for (let i = 0; i < event.target.files.length; i++) {
                                                                                        let file = event.target.files[i];
                                                                                        arrFiles.push(file);
                                                                                        fileNames += event.target.files[i].name + ", ";
                                                                                    }
                                                                                    setUploadDocument(_.trim(fileNames, ", "));
                                                                                    setFiles(arrFiles);
                                                                                }
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Upload Error',
                                                                                    text: 'You can only upload Word, TXT, Excel, PDF, JPEG, JPG, PNG & ZIP files',
                                                                                    icon: 'error'
                                                                                }).then(success => {

                                                                                })
                                                                            }
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'Maximum 5 files are allowed.',
                                                                                icon: 'error'
                                                                            }).then(success => {
                                                                            });
                                                                        }
                                                                    } else {
                                                                        $('#error-doc-not-found').removeClass('d-none')
                                                                    }

                                                                }}
                                                            />
                                                            <small className="error-msg d-none text-danger" id="error-doc-not-found">Document is required</small>
                                                            <label class="custom-file-label" for="file">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument : "Choose file"
                                                                }
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Actual Date of Activity&nbsp;<span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="actual_activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="actual_activity_date"
                                                                name="actual_activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: wellnessStatus === 'close' ? 'Please Select Actual Date of Activity' : false
                                                        }}
                                                    />
                                                    {errors.actual_activity_date && <ErrorLabel message={errors.actual_activity_date?.message} />}
                                                </div>
                                            </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        placeholder="Enter Remark"
                                                        {...register('remarks')}
                                                    />
                                                    {errors.remarks?.type && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_wellness_management"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="wellness-management-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ViewNewRequest = null;
    var ClientType = null;
    var ClientName = null;
    var RequestEdit = null;
    var WellnessType = null;
    var AssignRm = null;

    if (
        state.editWellnessManagementReducer != null &&
        Object.keys(state.editWellnessManagementReducer).length > 0 &&
        state.editWellnessManagementReducer.constructor === Object
    ) {
        switch (state.editWellnessManagementReducer.tag) {
            case ACTION_VIEW_WELLNESS_REQUEST_SUCCESS:
                ViewNewRequest = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { ViewNewRequest };
            case ACTION_EDIT_WELLNESS_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { ClientType };
            case ACTION_EDIT_WELLNESS_TYPE_SUCCESS:
                WellnessType = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { WellnessType };
            case ACTION_EDIT_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS:
                AssignRm = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { AssignRm };
            case ACTION_EDIT_WELLNESS_CLIENT_NAME_SUCCESS:
                ClientName = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { ClientName };
            case ACTION_EDIT_WELLNESS_REQUEST_SUCCESS:
                RequestEdit = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { RequestEdit };
            default:
        }
    }
    return {
        ViewNewRequest,
        ClientType,
        ClientName,
        RequestEdit,
        WellnessType,
        AssignRm,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewWellnessRequest, actionEditWellnessManagementClientType, actionEditWellnessManagementClientName, actionEditWellnessRequest, actionWellnessManagementWellnessType, actionWellnessManagementAssignRm
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessManagementEdit))
