import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_PERMISSION_ROLES_VIEW } from '../../../../../../api/constants';
import { API_GETPERMISSION } from '../../../../../../api/constants';
import { API_CREATEPERMISSIONSAGAINROLE } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';
export const ACTION_ROLL_VIEW_LOADING = 'ACTION_ROLL_VIEW_LOADING';
export const ACTION_ROLL_VIEW_SUCCESS = 'ACTION_ROLL_VIEW_SUCCESS';

export const GETPERMISSION_LOADING = 'GETPERMISSION_LOADING';
export const ACTION_GETPERMISSION_SUCCESS = 'ACTION_GETPERMISSION_SUCCESS';

export const CREATEPERMISSIONSAGAINROLE_LOADING = 'CREATEPERMISSIONSAGAINROLE_LOADING';
export const ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS = 'ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS';


export function actionRollView(params) {
    return (dispatch, getState) =>
        Api.post(API_PERMISSION_ROLES_VIEW,params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ROLL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ROLL_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionGetPermission(params) {
    return (dispatch, getState) => Api.post(API_GETPERMISSION,params).then((res) => {
        const data = res;
        PRINT('GETPERMISSION ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_GETPERMISSION_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(GETPERMISSION_LOADING, { loading: false, data }));
    });
}


export function actionCreatePermissionsAgainRole(params) {
        return (dispatch, getState) => Api.post(API_CREATEPERMISSIONSAGAINROLE, params).then((res) => {
            const data = res;
            PRINT('CREATEPERMISSIONSAGAINROLE ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(CREATEPERMISSIONSAGAINROLE_LOADING, { loading: false, data }));
        });
    }
