import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from '../../../../../../component/form/error_label';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionPermissionAdd, ACTION_PERMISSION_ADD_CREATE_SUCCESS } from './action';
import { actionModuleName, ACTION_MODULE_NAME_SUCCESS } from './action';
import { toast } from 'react-toastify';

const Add = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const [moduleName, setModuleName] = useState();

    const initialRender = () => {
        props.actionModuleName({});
    }
    useEffect(initialRender, []);

    const onModuleNameUpdate = () => {
        const { ModuleName } = props;
        var ModuleNameResponse = Object.assign({}, ModuleName);
        if (ModuleNameResponse.result === true) {
            setModuleName(ModuleNameResponse.response);
        } else {
            switch (ModuleNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onModuleNameUpdate, [props.ModuleName]);

    const onPermissionCreateUpdate = () => {
        const { PermissionCreate } = props;
        var PermissionCreateResponse = Object.assign({}, PermissionCreate);
        if (PermissionCreateResponse.result === true) {
            props.history.goBack();
        } else {
            switch (PermissionCreateResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                case ERROR:
                    toast.error(PermissionCreateResponse.message);
                default:
            }
        }
    }
    useEffect(onPermissionCreateUpdate, [props.PermissionCreate]);

    const onSubmit = (data) => {

        if (data.parent_id === '') {
            data.parent_id = 0;
        }

        props.actionPermissionAdd(data);
    }


    return (
        <>
            <ScreenTitle title="Add Permission" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Permission Name&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="title"
                                                    name="title"
                                                    placeholder="Enter Peremission Name"
                                                    {...register('title', { required: 'Peremission Name is required' })}
                                                />
                                                {errors.title && <ErrorLabel message={errors.title?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Permission Key&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="key"
                                                    name="key"
                                                    placeholder="Enter Peremission Key"
                                                    {...register('key', { required: 'Peremission Key is required' })}
                                                />
                                                {errors.key && <ErrorLabel message={errors.key?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Module Name</label>
                                                <Controller
                                                    name="parent_id"
                                                    className="form-control"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={moduleName}
                                                            fields={{ text: "title", value: "id" }}
                                                            allowFiltering={false}
                                                            placeholder="Select Module Name"
                                                            value={field.value}
                                                            change={(event) => {
                                                                field.onChange(event.itemData.id)
                                                            }}
                                                        />
                                                    }
                                                />
                                                {errors.parent_id && <ErrorLabel message={errors.parent_id?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={[
                                                                {
                                                                    title: "Active",
                                                                    id: 'active'
                                                                },
                                                                {
                                                                    title: "Inactive",
                                                                    id: 'inactive'
                                                                }
                                                            ]}
                                                            fields={{ text: "title", value: "id" }}
                                                            allowFiltering={false}
                                                            placeholder="Select Status"
                                                            value={field.value}
                                                            change={(event) => field.onChange(event.itemData.id)}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Status'
                                                    }}
                                                />
                                                {errors.status && <ErrorLabel message={errors.status?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >Submit</button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {

    var ModuleName = null;
    var PermissionCreate = null;

    if (
        state.PermissionAddReducer != null &&
        Object.keys(state.PermissionAddReducer).length > 0 &&
        state.PermissionAddReducer.constructor === Object
    ) {
        switch (state.PermissionAddReducer.tag) {
            case ACTION_PERMISSION_ADD_CREATE_SUCCESS:
                PermissionCreate = Object.assign({}, state.PermissionAddReducer.data);
                delete state.PermissionAddReducer;
                return { PermissionCreate };
            case ACTION_MODULE_NAME_SUCCESS:
                ModuleName = Object.assign({}, state.PermissionAddReducer.data);
                delete state.PermissionAddReducer;
                return { ModuleName };
            default:
        }
    }
    return {
        ModuleName,
        PermissionCreate,
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionPermissionAdd, actionModuleName }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Add))
