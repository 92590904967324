
import React, { Fragment, useEffect, useState } from 'react';
import ScreenTitle from '../../../../../component/screen_title';
import ArrayStore from 'devextreme/data/array_store';
import { TagBox } from 'devextreme-react/tag-box';
import { Controller, useForm } from 'react-hook-form';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from '../../../../../component/form/error_label';
import { KEY_TOAST_MESSAGE, getData } from '../../../../../utils/preferences';
import { removeData } from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import {
    ACTION_GET_GENERAL_DEPARTMENT_SUCCESS, ACTION_GET_GENERAL_INSURER_SUCCESS, ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS,
    actionGeneralViewList, actionGetGeneralCompanyList, actionGetGeneralPolicyType, actionGetGeneralDepartment, ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS
} from './action';
import { UNAUTHORIZED } from '../../../../../constants';

const GeneralPlacementView = (props) => {

    const { match } = props;
    const { control, formState: { errors }, setValue } = useForm({});
    const [selectedPolicyValue, setSelectedPolicyValue] = useState([]);
    const [selectedDepartmentValue, setSelectedDepartmentValue] = useState();
    const [selectedPreference, setSelectedPreference] = useState();
    const [selectedCompanyValue, setSelectedCompanyValue] = useState();
    const [generalPlacementView, setGeneralPlacementView] = useState(null);
    const [arrRatingColor, setArrRatingColor] = useState(null);
    const [departmentList, setDepartmentList] = useState([]);
    const [policyList, setPolicyList] = useState([])
    const [insurerList, setInsurerList] = useState([])
    const [placementsRight, setPlacementsRight] = useState(null)
    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionGeneralViewList();
        props.actionGetGeneralDepartment({ status: "ACTIVE", type: 'general' });
        props.actionGetGeneralCompanyList({ status: "ACTIVE", type: 'general' })
    }
    useEffect(initialRender, []);

    const returnColorCode = (rating) => {
        const targetRatingColor = arrRatingColor?.find(item => item.rating === rating)?.color;
        return targetRatingColor
    }

    const onGeneralPreferenceViewList = () => {
        const { GetGeneralPreferenceView } = props
        if (GetGeneralPreferenceView != null) {
            var GetGeneralPreferenceResponse = Object.assign({}, GetGeneralPreferenceView);
            if (GetGeneralPreferenceResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setGeneralPlacementView(GetGeneralPreferenceResponse.response.data);
                setPlacementsRight(GetGeneralPreferenceResponse.response);
                setArrRatingColor(GetGeneralPreferenceResponse.response.color);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (GetGeneralPreferenceResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGeneralPreferenceViewList, [props.GetGeneralPreferenceView])

    const onGetDepartmentList = () => {
        const { MasterGetGeneralDepartment } = props
        if (MasterGetGeneralDepartment != null) {
            var GetDepartmentListResponse = Object.assign({}, MasterGetGeneralDepartment);
            if (GetDepartmentListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setDepartmentList(GetDepartmentListResponse.response);
            } else {
                setDepartmentList([])
                $("#progress-loader").addClass("hidden");
                switch (GetDepartmentListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onGetDepartmentList, [props.MasterGetGeneralDepartment])

    const onMasterGetGeneralPolicyListUpdate = () => {
        const { MasterGetGeneralPolicyType } = props
        if (MasterGetGeneralPolicyType != null) {
            var MasterGetPolicyListResponse = Object.assign({}, MasterGetGeneralPolicyType);
            if (MasterGetPolicyListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setPolicyList(MasterGetPolicyListResponse.response);
            } else {
                setPolicyList([])
                $("#progress-loader").addClass("hidden");
                switch (MasterGetPolicyListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetGeneralPolicyListUpdate, [props.MasterGetGeneralPolicyType])

    const onMasterGetGeneralInsurerListUpdate = () => {
        const { MasterGetGeneralCompany } = props
        if (MasterGetGeneralCompany != null) {
            var MasterGetInsurerListResponse = Object.assign({}, MasterGetGeneralCompany);
            if (MasterGetInsurerListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setInsurerList(MasterGetInsurerListResponse.response);
            } else {
                setInsurerList([])
                $("#progress-loader").addClass("hidden");
                switch (MasterGetInsurerListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterGetGeneralInsurerListUpdate, [props.MasterGetGeneralCompany])
    return (
        <>
            <section>
                <div className="content-header row mb-1 ">
                    <div className="content-header-left col-12 col-md-7  my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">General Preference</h5>
                    </div>
                    {/* <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">

                            <li class="nav-item">
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                    onClick={() => {
                                        props.history.push('/placement/general');
                                    }}
                                >
                                    Add General Preference
                                </button>
                            </li>

                        </ul>
                    </div> */}
                    <div className="content-header-right col-12 col-md-5 mb-half md-mb-none">
                        <div className='d-flex float-right'>
                            <ul class="nav nav-pills mr-3">
                                {
                                    placementsRight?.general_authorized === true
                                        ?
                                        <li class="nav-item">
                                            <button
                                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                                onClick={() => {
                                                    props.history.push('/placement/general');
                                                }}
                                            >
                                                Add General Preference
                                            </button>
                                        </li>
                                        :
                                        <></>
                                }
                            </ul>
                            <ul class="nav nav-pills ">
                                {
                                    placementsRight?.specific_authorized === true
                                        ?
                                        <li class="nav-item">
                                            <button
                                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                                onClick={() => {
                                                    props.history.push('/placement/specific');
                                                }}
                                            >
                                                Add Specific Preference
                                            </button>
                                        </li>
                                        :
                                        <></>
                                }
                            </ul>
                        </div>
                    </div>
                </div >
                <div className="pb-2">
                    <div className='mb-1 font-weight-bold'>Filters</div>
                    <div className="card">
                        <div className="card-body pb-0">
                            <form noValidate autoComplete="Off" >
                                <div className="form-body">
                                    <div className="row ">
                                        <div className="col-12 col-md-6 ">
                                            <div className="form-group mb-0">
                                                <label className="label-control">Type of Department</label>
                                                <Controller
                                                    name="department_id"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="department_id"
                                                            name="department_id"
                                                            dataSource={departmentList}
                                                            allowFiltering={false}
                                                            placeholder="Select Department"
                                                            value={field.value}
                                                            fields={{ text: 'name', value: 'id' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.id)
                                                                    setSelectedDepartmentValue(event.itemData.id)
                                                                    setSelectedPolicyValue([]);
                                                                    setValue('policy_type', null);
                                                                    props.actionGetGeneralPolicyType({ 'department_id': event.itemData.id, status: "ACTIVE" });

                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mt-1 mt-md-0">
                                            <div className="form-group cust-tagbox mb-0">
                                                <label className="label-control">Type of Policy&nbsp;</label>
                                                <Controller
                                                    name="policy_type"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: policyList,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}

                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select Policy Type"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            acceptCustomValue={false}
                                                            disabled={selectedDepartmentValue != null ? false : true}
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedPolicyValue}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedPolicyValue(value);
                                                                    field.onChange(value);
                                                                    props.actionGetGeneralCompanyList({ 'policy_type_id': value, status: "ACTIVE", type: 'general' });
                                                                } else {
                                                                    if (selectedPolicyValue != null && selectedPolicyValue.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedPolicyValue([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedPolicyValue([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mt-1">
                                            <div className="form-group mb-0">
                                                <label className="label-control">Insurance Company</label>
                                                <Controller
                                                    name="company_name"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="company_name"
                                                            name="company_name"
                                                            dataSource={insurerList}
                                                            allowFiltering={true}
                                                            filterType={"Contains"}
                                                            placeholder="Select Company name"
                                                            value={field.value}
                                                            fields={{ text: 'name', value: 'id' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.id)
                                                                    setSelectedCompanyValue(event.itemData.id)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mt-1">
                                            <div className="form-group mb-0">
                                                <label className="label-control">Rating</label>
                                                <Controller
                                                    name="preference_rating"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="preference_rating"
                                                            name="preference_rating"
                                                            dataSource={[
                                                                {
                                                                    value: 1,
                                                                    text: '1'
                                                                },
                                                                {
                                                                    value: 2,
                                                                    text: '2'
                                                                },
                                                                {
                                                                    value: 3,
                                                                    text: '3'
                                                                },
                                                                {
                                                                    value: 4,
                                                                    text: '4'
                                                                },
                                                                {
                                                                    value: 5,
                                                                    text: '5'
                                                                },
                                                            ]}
                                                            allowFiltering={false}
                                                            placeholder="Rating"
                                                            value={field.value}
                                                            fields={{ text: 'text', value: 'value' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.value)
                                                                    setSelectedPreference(event.itemData.value)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-4 col-md-auto pl-1">
                                            <div className="form-actions border-0 float-none mt-0">
                                                <button
                                                    id="apply"
                                                    type='submit'
                                                    className="btn btn-primary mr-8  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        $("#progress-loader").removeClass("hidden");
                                                        props.actionGeneralViewList(
                                                            {
                                                                "department_id": selectedDepartmentValue,
                                                                "policy_type_id": selectedPolicyValue,
                                                                "insurance_id": selectedCompanyValue,
                                                                "rating": selectedPreference
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Apply
                                                        <span id="apply-filter-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-auto">
                                            <div className="form-actions border-0 float-none mt-0">
                                                <button
                                                    id="reset"
                                                    type='button'
                                                    className="btn btn-primary  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        setSelectedPolicyValue([]);
                                                        setSelectedDepartmentValue(null);
                                                        setSelectedPreference(null);
                                                        setSelectedCompanyValue(null);
                                                        setValue('department_id', null);
                                                        setValue('policy_type', null);
                                                        setValue('company_name', null);
                                                        setValue('preference_rating', null);
                                                        setDepartmentList([])
                                                        setPolicyList([])
                                                        setInsurerList([])
                                                        $("#progress-loader").removeClass("hidden");
                                                        props.actionGetGeneralDepartment({ status: "ACTIVE", type: 'general' });
                                                        props.actionGetGeneralCompanyList({ status: "ACTIVE", type: 'general' })
                                                        props.actionGeneralViewList();
                                                    }
                                                    }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Reset
                                                        <span id="reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div className=' my-auto'>
                        <div className='d-flex justify-content-end mb-1'>
                            <div className='mr-2'><i class="fa fa-square" style={{ color: returnColorCode(1) }}></i> Most Preferred </div>
                            <div className='mr-2'><i class="fa fa-square" style={{ color: returnColorCode(2) }}></i> 2nd Most Preferred </div>
                            <div><i class="fa fa-square" style={{ color: returnColorCode(3) }}></i> 3rd Most Preferred </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-12 col-md-12 col-lg-12">
                                    {
                                        generalPlacementView != null && generalPlacementView.length > 0 ? (
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Product / Preference</th>
                                                        <th>Rating 1</th>
                                                        <th>Rating 2</th>
                                                        <th>Rating 3</th>
                                                        <th>Rating 4</th>
                                                        <th>Rating 5</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {generalPlacementView.map((policyType, index) => {
                                                        const insurersByRating = policyType.insurer_preferences.reduce((data, insurer) => {
                                                            const rating = insurer.rating;
                                                            if (!data[rating]) {
                                                                data[rating] = [];
                                                            }
                                                            data[rating].push(insurer);
                                                            return data;
                                                        }, {});

                                                        return (
                                                            <Fragment key={policyType.id}>
                                                                <tr>
                                                                    <td className='font-medium-1 my-auto text-black font-weight-700'>{policyType.policy_type}</td>
                                                                    {Array.from({ length: 5 }, (_, rating) => rating + 1).map((rating) => {
                                                                        const insurersForRating = insurersByRating[rating] || [];
                                                                        return (
                                                                            <td className='pl-1' key={rating} style={{ backgroundColor: returnColorCode(insurersForRating[0]?.rating) }}>
                                                                                {insurersForRating.map((insurer, index) => (
                                                                                    <ul className='mb-1 pl-0 dx-text-content-alignment-left mt-half font-small-2 font-weight-bold' key={index}>{insurer.short_name}</ul>
                                                                                ))}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            </Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <h5 className='text-center text-bold-500'>{generalPlacementView?.length === 0 ? 'No data found' : ''}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterGetGeneralDepartment = null;
    var MasterGetGeneralCompany = null;
    var MasterGetGeneralPolicyType = null;
    var GetGeneralPreferenceView = null;

    if (
        state.MasterGetGeneralPreferenceReducer != null &&
        Object.keys(state.MasterGetGeneralPreferenceReducer).length > 0 &&
        state.MasterGetGeneralPreferenceReducer.constructor === Object
    ) {
        switch (state.MasterGetGeneralPreferenceReducer.tag) {
            case ACTION_GET_GENERAL_DEPARTMENT_SUCCESS:
                MasterGetGeneralDepartment = Object.assign({}, state.MasterGetGeneralPreferenceReducer.data);
                delete state.MasterGetGeneralPreferenceReducer;
                return { MasterGetGeneralDepartment };
            case ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS:
                MasterGetGeneralPolicyType = Object.assign({}, state.MasterGetGeneralPreferenceReducer.data);
                delete state.MasterGetGeneralPreferenceReducer;
                return { MasterGetGeneralPolicyType };
            case ACTION_GET_GENERAL_INSURER_SUCCESS:
                MasterGetGeneralCompany = Object.assign({}, state.MasterGetGeneralPreferenceReducer.data);
                delete state.MasterGetGeneralPreferenceReducer;
                return { MasterGetGeneralCompany };
            case ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS:
                GetGeneralPreferenceView = Object.assign({}, state.MasterGetGeneralPreferenceReducer.data);
                delete state.MasterGetGeneralPreferenceReducer;
                return { GetGeneralPreferenceView };
            default:

        }
    }
    return {
        MasterGetGeneralDepartment,
        MasterGetGeneralPolicyType,
        MasterGetGeneralCompany,
        GetGeneralPreferenceView,
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionGeneralViewList, actionGetGeneralCompanyList, actionGetGeneralPolicyType, actionGetGeneralDepartment }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GeneralPlacementView))
