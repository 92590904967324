import createReducer from "../../../../../../../reducers/createReducer";
import { ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { ACTION_MASTER_LEAD_SOURCE_SUCCESS } from "./action";
import { ACTION_MASTER_CITIES_SUCCESS, ACTION_MASTER_STATES_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeadCreateRetailReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_LEAD_SOURCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_LEAD_SOURCE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
});
export default LeadCreateRetailReducer;