import React, { useEffect, useState } from "react";
import { UNAUTHORIZED } from "../../../constants";
import $ from "jquery";
import { actionAssignRMPool, ACTION_MASTER_ASSIGN_RM_SUCCESS } from "./action";
import { actionEditAssignRMPool, ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS } from "./action";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from "redux";
import ErrorLabel from "../../form/error_label";
import { Controller, useForm } from "react-hook-form";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

const EditWellnessAssignRM = (props) => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [assignRMPool, setAssignRMPool] = useState([]);
    const [wellnessId, setWellnessId] = useState(null);

    const initialRender = () => {
        if (props.show === true) {
            if (props.wellnessId != null) {
                setWellnessId(props.wellnessId);
            }
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            props.actionAssignRMPool();

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onMasterAssignRMUpdate = () => {
        const { AssignRMPool } = props;
        if (AssignRMPool != null) {
            var AssignRMPoolResponse = Object.assign({}, AssignRMPool);
            if (AssignRMPoolResponse.result === true) {
                var arrAssignRMPool = Object.assign([], AssignRMPoolResponse.response);
                setAssignRMPool(arrAssignRMPool);
            } else {
                switch (AssignRMPoolResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterAssignRMUpdate, [props.AssignRMPool]);

    const onEditAssignRMUpdate = () => {
        const { EditAssignRM } = props;
        if (EditAssignRM != null) {
            var EditAssignRMResponse = Object.assign({}, EditAssignRM);
            $("#edit_assignRM").prop("disabled", false);
            if (EditAssignRMResponse.result === true) {
                $("#assignRM-edit-loader").addClass("hidden");
                props.handleClose({
                    updated: true
                })
            } else {
                $("#assignRM-edit-loader").addClass("hidden");
                switch (EditAssignRMResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEditAssignRMUpdate, [props.EditAssignRM]);


    const onSubmit = (data) => {
        $("#assignRM-edit-loader").removeClass("hidden");
        $("#edit_assignRM").prop("disabled", true);
        data.id = wellnessId;
        data.user_id = data.user_id;
        props.actionEditAssignRMPool(data);
    }

    return (
        <div
            className="modal fade show no-scroll"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Edit Assign RM
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose({
                                    updated: false
                                });
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Assign RM&nbsp;<span className="danger">
                                                        *
                                                    </span></label>
                                                    <Controller
                                                        name="user_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="user_id"
                                                                name="user_id"
                                                                dataSource={assignRMPool}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select assign RM"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Please Select assign RM'
                                                        }}
                                                    />
                                                    {errors.user_id && <ErrorLabel message={errors.user_id?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="edit_assignRM"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="assignRM-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose({
                                                    updated: false
                                                });
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {

    var AssignRMPool = null;
    var EditAssignRM = null

    if (
        state.EditAssignRMReducer != null &&
        Object.keys(state.EditAssignRMReducer).length > 0 &&
        state.EditAssignRMReducer.constructor === Object
    ) {
        switch (state.EditAssignRMReducer.tag) {
            case ACTION_MASTER_ASSIGN_RM_SUCCESS:
                AssignRMPool = Object.assign({}, state.EditAssignRMReducer.data);
                delete state.EditAssignRMReducer;
                return { AssignRMPool };
            case ACTION_MASTER_EDIT_ASSIGN_RM_SUCCESS:
                EditAssignRM = Object.assign({}, state.EditAssignRMReducer.data);
                delete state.EditAssignRMReducer;
                return { EditAssignRM };
            default:
        }

    }
    return {
        AssignRMPool,
        EditAssignRM
    }

}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionAssignRMPool,
        actionEditAssignRMPool
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditWellnessAssignRM))