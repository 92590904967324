import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ScreenTitle from '../../../../../../component/screen_title';

const PolicyMasterView = (props) => {

    const [policyDetails, setPolicyDetails] = useState(null);

    const initialRender = () => {
        if (props?.location?.state?.detail != null) {
            setPolicyDetails(props?.location?.state?.detail);
        }
    }
    useEffect(initialRender, []);

    return (
        <>
            <ScreenTitle title="Policy Details" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row mt-2">
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Policy Type</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        policyDetails != null && policyDetails?.name != null ?
                                            policyDetails?.name : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Department Name</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        policyDetails != null && policyDetails?.department_name != null ?
                                            policyDetails?.department_name : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Category</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        policyDetails != null && policyDetails.category != null ?
                                            policyDetails?.category : ''
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Status</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        policyDetails != null && policyDetails?.status != null ?
                                            policyDetails?.status : ''
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PolicyMasterView