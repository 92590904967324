import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { DateBox } from "devextreme-react/date-box";
import dateFormat from "dateformat";
import Chart from "react-apexcharts";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { PRIMARY_COLOR, UNAUTHORIZED, ERROR } from "../../../../../constants";
import CreatePost from "../../../../../component/activity_feed/create_post";
import { actionGetPost, ACTION_GET_POST_SUCCESS } from "./action";
import {
  actionDashboardBusinessSummary,
  ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS,
} from "./action";
import { actionGetTicker, ACTION_GET_TICKER_SUCCESS } from "./action";
import { actionMarkIn, ACTION_MARK_IN_SUCCESS } from "./action";
import { actionMarkOut, ACTION_MARK_OUT_SUCCESS } from "./action";
import { actionMarkStatus, ACTION_MARK_STATUS_SUCCESS } from "./action";
import {
  getData,
  KEY_GLOBAL_SEARCH,
  KEY_MARK_INOUT,
  removeData,
  setData,
  KEY_NOTIFICATION_COUNT,
  KEY_PERMISSION,
} from "../../../../../utils/preferences";
import {
  linkify,
  timeStringConversion,
  verifyPermission,
} from "../../../../../utils";
import HTMLParser from "html-react-parser";
import $ from "jquery";
import { FaHandPointer } from "react-icons/fa";
import Events from "../../../../../component/activity_feed/events";
import AttendanceLeave from "../../../../../component/widgets/attandance_leaves";
import DashboardMyClient from "../../../../../component/widgets/my_clients";
// import TaskStatus from "../../../../../component/widgets/task_status";
import PendingApproval from "../../../../../component/widgets/pending_approval";
import ManagerApprovals from "../../../../../component/widgets/manager_approval";
import {
  actionHeaderUserNotification,
  ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS,
} from "./action";
import {
  actionGetGlobalSearchList,
  ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS,
} from "./action";
import moment from "moment";
import WeeklyMeetingDashbaord from "../../../../../component/widgets/weekly_meeting_dashboard";
import {
  actionTravelTabPermissions,
  ACTION_TRAVEL_TAB_PERMISSION_SUCCESS,
} from "../../travel_calender/view/action";

const DashboardSummary = (props) => {
  const { control } = useForm({});

  const [showCreatePost, setCreatePost] = useState(false);
  const [postDetails, setPostDetails] = useState([]);
  const [dashboardBusinessSummary, setDashboardBusinessSummary] = useState();
  const [fromDate, setFromDate] = useState(
    moment().month() < 3
      ? moment()
          .month(3)
          .startOf("month")
          .subtract(1, "years")
          .format("yyyy-MM-DD")
      : moment().month(3).startOf("month").format("yyyy-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
  const [getTicker, setGetTicker] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [markInOut, setMarkInOut] = useState(null);
  const [markInOutDetails, setMarkInOutDetails] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [departmentValues, setDepartmentValues] = useState([]);
  const [latitude, setLattitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [summaryTotal, setSummaryTotal] = useState();
  const [teamSummaryTotal, setTeamSummaryTotal] = useState();
  const [tabPermission, setTabPermission] = useState();

  const initialRender = () => {
    props.actionGetPost();
    props.actionGetGlobalSearchList();
    props.actionTravelTabPermissions();
    var notificationCount = getData(KEY_NOTIFICATION_COUNT);
    if (notificationCount != null) {
      setNotificationCount(notificationCount);
    }

    setTimeout(() => {
      var global_search = getData(KEY_GLOBAL_SEARCH);
      if (global_search != null) {
        var objGlobalSearch = JSON.parse(global_search);
        var users = Object.assign([], objGlobalSearch.users);
        setAllUsers(users);
      }
    }, [2500]);

    var markInOut = getData(KEY_MARK_INOUT);

    /* Reset The Data If Record From Local Storage is Not Matching With Current Date */
    if (markInOut != null) {
      var objMarkInOutData = JSON.parse(markInOut);

      if (
        objMarkInOutData != null &&
        objMarkInOutData.date !== dateFormat(new Date(), "yyyy-mm-dd")
      ) {
        // if (objMarkInOutData != null && objMarkInOutData.date != '2022-11-17') {

        setMarkInOutDetails(null);
        removeData(KEY_MARK_INOUT);
        setMarkInOut(null);
      }
    }
    /* EOC : Reset The Data If Record From Local Storage is Not Matching With Current Date */

    if (markInOut != null) {
      var objMarkInOut = JSON.parse(markInOut);

      if (
        objMarkInOut != null &&
        objMarkInOut.date === dateFormat(new Date(), "yyyy-mm-dd")
      ) {
        setMarkInOutDetails(objMarkInOut);

        if (
          objMarkInOut.day_status != null &&
          objMarkInOut.day_status.out_time != null
        ) {
          setMarkInOut(false);
        } else {
          setMarkInOut(true);
        }
      } else {
        setMarkInOutDetails(null);
        removeData(KEY_MARK_INOUT);
        setMarkInOut(false);
      }
    }

    props.actionGetTicker({ section_name: "HEADER_RUNNING_TEXT" });

    props.actionDashboardBusinessSummary({
      user_id: props.auth.getUser().user_id,
      from_date: moment(fromDate).format("yyyy-MM-DD"),
      to_date: moment(toDate).format("yyyy-MM-DD"),
    });

    props.actionMarkStatus();

    $("body").on("click", ".btn-post-tag", function () {
      props.history.push({
        pathname: "/profile",
        state: { user_id: $(this).attr("id") },
      });
    });
    $("body").on("click", ".btn-post-str", function () {
      $(this).addClass("d-none").removeClass("d-inline");
      $(`#post-str-${$(this).attr("id").split("-")[3]}`).removeClass("hidden");
    });
    props.actionHeaderUserNotification({});

    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLattitude(latitude);
      setLongitude(longitude);
    };
    navigator.geolocation.getCurrentPosition(success);
  };
  useEffect(initialRender, []);

  const onTravelTabPermissionUpdate = () => {
    const { TravelTabPermission } = props;
    const TravelTabPermissionResponse = Object.assign({}, TravelTabPermission);
    let objPermission = JSON.parse(getData(KEY_PERMISSION)) || []; 
    if (TravelTabPermissionResponse.result === true) {
        if (!objPermission.includes('SHOW_TRAVEL_CALENDAR')) {
            objPermission.push('SHOW_TRAVEL_CALENDAR');
            setData(KEY_PERMISSION, JSON.stringify(objPermission));
        }
    } 
};

useEffect(onTravelTabPermissionUpdate, [props.TravelTabPermission]);


  function getNext30Minutes(inputTime) {
    const now = new Date(inputTime);
    const next30Minutes = new Date(now.getTime() + 30 * 60000);
    return next30Minutes.toLocaleTimeString();
  }

  const onGetPostUpdate = () => {
    const { GetPost } = props;
    const GetPostResponse = Object.assign({}, GetPost);
    if (GetPostResponse.result === true) {
      $("#post_loader").addClass("hidden");
      setPostDetails(GetPostResponse.response);
    } else {
      switch (GetPostResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onGetPostUpdate, [props.GetPost]);

  const onGetGlobalSearchListUpdate = () => {
    const { GetGlobalSearchList } = props;
    const GetGlobalSearchListResponse = Object.assign({}, GetGlobalSearchList);
    if (GetGlobalSearchListResponse.result === true) {
      setData(
        KEY_GLOBAL_SEARCH,
        JSON.stringify(GetGlobalSearchListResponse.response)
      );
    } else {
      switch (GetGlobalSearchListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onGetGlobalSearchListUpdate, [props.GetGlobalSearchList]);

  const onDashboardBusinessSummaryUpdate = () => {
    const { DashboardBusinessSummary } = props;
    const DashboardBusinessSummaryResponse = Object.assign(
      {},
      DashboardBusinessSummary
    );
    if (DashboardBusinessSummaryResponse.result === true) {
      $("#business_summary_loader").addClass("hidden");

      setDashboardBusinessSummary(DashboardBusinessSummaryResponse.response);
      setSummaryTotal(
        Number(DashboardBusinessSummaryResponse.response.new_premium) +
          Number(DashboardBusinessSummaryResponse.response.new_brokerage) +
          Number(DashboardBusinessSummaryResponse.response.renewal_premium) +
          Number(DashboardBusinessSummaryResponse.response.renewal_brokerage)
      );
      setTeamSummaryTotal(
        Number(DashboardBusinessSummaryResponse.response.new_team_premium) +
          Number(DashboardBusinessSummaryResponse.response.new_team_brokerage) +
          Number(
            DashboardBusinessSummaryResponse.response.renewal_team_premium
          ) +
          Number(
            DashboardBusinessSummaryResponse.response.renewal_team_brokerage
          )
      );
      if (
        DashboardBusinessSummaryResponse.response.department_details != null
      ) {
        var departments = [];
        var departmentValues = [];

        DashboardBusinessSummaryResponse.response.department_details.forEach(
          (element) => {
            departments.push(element.department);
            departmentValues.push(element.total);
          }
        );

        if (departments.length > 0) {
          setDepartments(departments);
        }

        if (departmentValues.length > 0) {
          setDepartmentValues(departmentValues);
        }
      }
    } else {
      $("#business_summary_loader").addClass("hidden");
      switch (DashboardBusinessSummaryResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onDashboardBusinessSummaryUpdate, [props.DashboardBusinessSummary]);

  const onMarkStatusUpdate = () => {
    const { MarkStatus } = props;
    const MarkStatusResponse = Object.assign({}, MarkStatus);
    if (MarkStatusResponse.result === true) {
      if (MarkStatusResponse.response != null) {
        setData(KEY_MARK_INOUT, JSON.stringify(MarkStatusResponse.response));
        setMarkInOutDetails(MarkStatusResponse.response);

        if (
          MarkStatusResponse.response.day_status != null &&
          MarkStatusResponse.response.day_status.out_time != null
        ) {
          setMarkInOut(false);
        } else {
          setMarkInOut(true);
        }
      }
    } else {
      switch (MarkStatusResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onMarkStatusUpdate, [props.MarkStatus]);

  const onGetTickerUpdate = () => {
    const { GetTicker } = props;
    const GetTickerResponse = Object.assign({}, GetTicker);
    if (GetTickerResponse.result === true) {
      setGetTicker(GetTickerResponse.response.display_content_description);
    } else {
      switch (GetTickerResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onGetTickerUpdate, [props.GetTicker]);

  const onMarkInUpdate = () => {
    const { MarkIn } = props;
    var MarkInResponse = Object.assign({}, MarkIn);
    if (MarkInResponse.result === true) {
      $("#mark-in-out").removeClass("mark-in-btn-disabled");
      setData(KEY_MARK_INOUT, JSON.stringify(MarkInResponse.response));
      setMarkInOut(true);
      setMarkInOutDetails(MarkInResponse.response);
    } else {
      $("#mark-in-out").removeClass("mark-in-btn-disabled");
      switch (MarkInResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        case ERROR:
          Swal.fire({
            icon: "error",
            text: `${MarkInResponse.message}`,
            confirmButtonColor: PRIMARY_COLOR,
            showCancelButton: true,
          }).then((result) => {
            if (result) {
            }
          });
          break;
        default:
      }
    }
  };
  useEffect(onMarkInUpdate, [props.MarkIn]);

  const onMarkOutUpdate = () => {
    const { MarkOut } = props;
    var MarkOutResponse = Object.assign({}, MarkOut);
    if (MarkOutResponse.result === true) {
      $("#mark-in-out").removeClass("mark-in-btn-disabled");
      setData(KEY_MARK_INOUT, JSON.stringify(MarkOutResponse.response));
      setMarkInOut(false);
      setMarkInOutDetails(MarkOutResponse.response);
    } else {
      $("#mark-in-out").removeClass("mark-in-btn-disabled");
      switch (MarkOutResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onMarkOutUpdate, [props.MarkOut]);

  const onNotificationsUpdate = () => {
    const { Notifications } = props;
    var NotificationsResponse = Object.assign({}, Notifications);
    if (NotificationsResponse.result === true) {
      setNotifications(NotificationsResponse.response.data);
      setData(
        KEY_NOTIFICATION_COUNT,
        JSON.stringify(NotificationsResponse.response.count)
      );
      setNotificationCount(NotificationsResponse.response.count);
    } else {
      switch (NotificationsResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onNotificationsUpdate, [props.Notifications]);

  const renderPostContent = (postContent, activity_id, activity) => {
    try {
      var formatText = postContent;
      if (allUsers != null && allUsers.length > 0) {
        allUsers.forEach((user, indexUser) => {
          // React.createElement('span', { class: 'text-primary' }, text),

          var indexOfUser = String(formatText).indexOf(
            `##${user.id}:${user.name}##`
          );
          if (indexOfUser > -1) {
            formatText = formatText.replace(
              `##${user.id}:${user.name}##`,
              `<span class='text-primary text-bold-700'>@${user.name}</span>`
            );
          }
        });
      }
      // if(formatText.indexOf(activity.meta_url) > -1) {
      //     formatText = formatText.replace(activity.meta_url,`<a href=${activity.meta_url} target='_blank' class='text-primary'>${activity.meta_url}</a>`)
      // }
      if (formatText.length > 2000) {
        // var subString1 = formatText.substring(0,300)
        var subString1 =
          formatText && formatText.length > 2000
            ? formatText.slice(0, 2000).split(" ").slice(0, -1).join(" ")
            : formatText;
        // subString1 += ' ';
        var subString2 = `<span id='post-str-${activity_id}' class="hidden">${formatText.substring(
          subString1.length,
          formatText.length
        )}</span>`;
        var readMore = `<button id='btn-post-str-${activity_id}' class="d-inline btn btn-sm px-0 text-primary text-bold-600 btn-post-str">Read more</button>`;
        var mainString = subString1.concat(readMore);
        mainString = mainString.concat(subString2);
        mainString = linkify(mainString);
        return HTMLParser(String(mainString).trim());
      } else {
        formatText = linkify(formatText);
      }
    } catch (e) {
      console.log("Format Text");
      console.log(e);
    }

    try {
      return HTMLParser(
        String(formatText)
          .substring(0, formatText.length)
          .trim()
          .replaceAll("\n", "<br>")
      );
    } catch (e) {
      return <>{formatText}</>;
    }
  };

  return (
    <div className="dashboard-content-wrapper">
      <div className="dashboard-menu-top px-1 shadow">
        {getTicker != null && getTicker.length > 0 ? (
          <div className="d-flex justify-content-between justify-content-lg-between w-100">
            <marquee>
              <p className="text-white pt-1">{getTicker}</p>
            </marquee>
          </div>
        ) : (
          <></>
        )}
      </div>
      <section className="dashboard-content my-2">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8">
            {/* Activity Feeds */}
            <div className="dashboard-activity-feeds-content">
              <div className="d-flex flex-row justify-content-between">
                <div className="flex-column">
                  <h5 className="text-black text-bold-600 mb-0">Posts</h5>
                </div>
                <div className="flex-column font-medium-1 text-black">
                  <button
                    type="button"
                    className="btn pt-0"
                    onClick={() => {
                      setCreatePost(true);
                    }}
                  >
                    <AiOutlineEdit size={16} />
                    Create Post
                  </button>
                  <button
                    type="button"
                    className="btn pt-0"
                    onClick={() => {
                      props.history.push("/dashboard/activity-feeds");
                    }}
                  >
                    <AiOutlineEye size={16} />
                    View All
                  </button>
                </div>
              </div>
              <div className="card dashboard-activity-feeds-list shadow">
                <div class="card-body">
                  <div id="post_loader" className="text-center">
                    <div className="spinner-border spinner-border-sm ml-half text-primary"></div>
                  </div>
                  {postDetails != null && postDetails.length > 0 ? (
                    postDetails.map((post, index) => (
                      <div
                        class="row-activity-feed border-bottom mb-1"
                        key={index}
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-column mr-1">
                            {post.profile_pic != null &&
                            post.profile_pic.length > 0 ? (
                              <img
                                src={post.profile_pic}
                                className="rounded-circle img-border cursor-pointer"
                                alt="Card"
                                width={50}
                                height={50}
                                style={{}}
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/profile",
                                    state: { user_id: post.user_id },
                                  });
                                }}
                              />
                            ) : (
                              <>
                                <div
                                  className="bg-blue-grey bg-lighten-5 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3 cursor-pointer"
                                  style={{ height: 50, width: 50 }}
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/profile",
                                      state: { user_id: post.user_id },
                                    });
                                  }}
                                >
                                  {post.initial_name}
                                </div>
                              </>
                            )}
                          </div>
                          <div class="flex-column">
                            <h1 class="activity-creator mb-half font-small-3 text-bold-600 text-black cursor-pointer">
                              <div
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/profile",
                                    state: { user_id: post.user_id },
                                  });
                                }}
                              >
                                {post.full_name}
                              </div>
                            </h1>
                            <h1 class="activity-time font-small-2 text-light">
                              {post.timeSince}
                            </h1>
                            <div
                              class="d-inline activity-description mb-0 font-small-3 cursor-pointer"
                              onClick={() => {
                                props.history.push({
                                  pathname: "/dashboard/activity-feeds",
                                  state: {
                                    event: "activity-feed",
                                    activity_id: post.id,
                                  },
                                });
                              }}
                            >
                              {post.content != null ? (
                                <div>
                                  <p className="mb-0">
                                    {post.content != null
                                      ? renderPostContent(
                                          post.content,
                                          post.id,
                                          post
                                        )
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div class="activity-images mb-1">
                                <div class="d-flex flex-row">
                                  {post.images != null ? (
                                    post.images.map((image, index) => (
                                      <div
                                        class={`flex-column ${
                                          index > 4 ? "hidden" : ""
                                        }`}
                                        key={index}
                                      >
                                        <div className="dashboard-activity-image-container">
                                          <img
                                            class="activity-image"
                                            src={image.full_url}
                                            alt=""
                                          />
                                          <div
                                            class={`centered ${
                                              post.images.length > 5 &&
                                              index === 4
                                                ? ""
                                                : "hidden"
                                            }`}
                                          >
                                            <h1 className="font-large-2 mt-2">
                                              +
                                              {post.images.length - (index + 1)}
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                  {postDetails != null && postDetails.length > 0 ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-block font-small-3 pb-1 text-primary text-bold-600"
                        onClick={() => {
                          props.history.push("/dashboard/activity-feeds");
                        }}
                      >
                        View All
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* My Clients, Task Status, Pending Approvals, Leaves Perios April to March */}
            <div className="dashboard-other-summary-content">
              <div className="row">
                <AttendanceLeave {...props} />
                <DashboardMyClient {...props} />
                {/* <TaskStatus {...props} /> */}
                <PendingApproval {...props} />
                <ManagerApprovals {...props} />
              </div>
            </div>
            <Events {...props} />
          </div>
          <div></div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* LEAVE PERIOD */}
            <div className="card dashboard-card-round shadow mb-1">
              <div className="card-body py-1">
                <div className="d-flex flex-row align-items-center">
                  <div className="flex-column">
                    <div
                      id="mark-in-out"
                      className={`badge d-flex flex-row align-items-center justify-content-center rounded-circle bg-success bg-darken-2 cursor-pointer px-1 py-1 m-btn ${
                        markInOut != null && latitude != "" && longitude != ""
                          ? markInOut
                            ? "bg-success bg-darken-2 mark-in-btn"
                            : "bg-red bg-darken-2 mark-out-btn"
                          : "bg-blue-grey bg-darken-2 initial-btn"
                      }`}
                      // className={`badge d-flex flex-row align-items-center justify-content-center rounded-circle bg-success bg-darken-2 cursor-pointer px-1 py-1`}
                      onClick={(event) => {
                        const userInTime =
                          markInOutDetails != null &&
                          markInOutDetails?.user_in_time != null
                            ? markInOutDetails?.user_in_time
                            : "";
                        const markInTime = getNext30Minutes(userInTime);
                        const currentDate = moment().format("LTS");
                        if (
                          markInOut === null &&
                          latitude !== "" &&
                          longitude !== ""
                        ) {
                          $("#mark-in-out").addClass("mark-in-btn-disabled");
                          props.actionMarkIn({
                            user_time: dateFormat(new Date(), "yyyy-mm-dd"),
                            latitude: latitude,
                            longitude: longitude,
                          });
                          setLattitude(latitude);
                          setLongitude(longitude);
                        } else if (
                          markInOut &&
                          latitude !== "" &&
                          longitude !== "" &&
                          currentDate >= markInTime
                        ) {
                          Swal.fire({
                            title: "Mark Out",
                            icon: "error",
                            text: "Are You Sure, You want to Mark Out?",
                            confirmButtonColor: PRIMARY_COLOR,
                            showCancelButton: true,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              $("#mark-in-out").addClass(
                                "mark-in-btn-disabled"
                              );
                              props.actionMarkOut({
                                user_time: dateFormat(new Date(), "yyyy-mm-dd"),
                                latitude: latitude,
                                longitude: longitude,
                              });
                              setLattitude(latitude);
                              setLongitude(longitude);
                            }
                          });
                        } else if (markInOut && currentDate <= markInTime) {
                          Swal.fire({
                            title: "Mark Out",
                            icon: "error",
                            text: "Please wait 30 minutes",
                            confirmButtonColor: PRIMARY_COLOR,
                          }).then((result) => {});
                        } else if (
                          latitude != null &&
                          latitude === "" &&
                          longitude != null &&
                          longitude === ""
                        ) {
                          Swal.fire({
                            icon: "error",
                            text: "Please allow to access location, and reload the screen",
                            confirmButtonColor: PRIMARY_COLOR,
                            showCancelButton: true,
                          }).then((result) => {
                            if (result) {
                            }
                          });
                        }
                      }}
                    >
                      <FaHandPointer className="white" size={16} />
                    </div>
                  </div>
                  <div className="flex-column ml-1">
                    {markInOutDetails != null &&
                    markInOutDetails.day_status != null &&
                    markInOutDetails.day_status.in_time != null ? (
                      <div className="d-flex flex-row w-100">
                        <div className="flex-column font-small-3 mr-1">
                          Mark In
                        </div>
                        <div className="flex-column font-small-3 ">
                          <b>
                            {timeStringConversion(
                              markInOutDetails.day_status.in_time
                            )}
                          </b>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {markInOutDetails != null &&
                    markInOutDetails.day_status != null &&
                    markInOutDetails.day_status.out_time != null ? (
                      <div className="d-flex flex-row w-100">
                        <div className="flex-column font-small-3 mr-1">
                          Mark Out
                        </div>
                        <div className="flex-column font-small-3">
                          <b>
                            {markInOutDetails.day_status.out_time != null
                              ? timeStringConversion(
                                  markInOutDetails.day_status.out_time
                                )
                              : ""}
                          </b>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {markInOutDetails === null ? (
                      <div className="d-flex flex-row w-100">
                        <div className="flex-column font-small-3 mr-1">
                          Mark In
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <WeeklyMeetingDashbaord {...props} />
            {/* BUSINESS SUMMARY & OTHER */}
            {verifyPermission("DASHBOARD_DETAILS") &&
            (teamSummaryTotal >= 0 || summaryTotal !== 0) ? (
              <div className="card dashboard-card-round shadow py-half mt-1">
                <div className="dashboard-date-range">
                  <div className="row px-1">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <Controller
                          name="from_date"
                          control={control}
                          render={({ field }) => (
                            <DateBox
                              calendarOptions={{
                                showTodayButton: true,
                              }}
                              acceptCustomValue={false}
                              stylingMode="underlined"
                              pickerType="calendar"
                              placeholder="From Date"
                              type="date"
                              displayFormat="dd/MM/yyyy"
                              showClearButton={false}
                              value={fromDate}
                              onValueChange={(value) => {
                                var objFromDate = new Date(value);
                                var objToDate = new Date(toDate);
                                if (
                                  objToDate.getTime() < objFromDate.getTime()
                                ) {
                                  Swal.fire({
                                    title: "Error",
                                    text: "From date can't be greater than To date",
                                    icon: "error",
                                  }).then((success) => {});
                                } else {
                                  field.onChange(
                                    moment(value).format("yyyy-MM-DD")
                                  );
                                  setFromDate(
                                    moment(value).format("yyyy-MM-DD")
                                  );
                                  $("#business_summary_loader").removeClass(
                                    "hidden"
                                  );
                                  props.actionDashboardBusinessSummary({
                                    user_id: props.auth.getUser().user_id,
                                    from_date:
                                      moment(value).format("yyyy-MM-DD"),
                                    to_date:
                                      moment(toDate).format("yyyy-MM-DD"),
                                  });
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <Controller
                          name="to_date"
                          control={control}
                          render={({ field }) => (
                            <DateBox
                              calendarOptions={{
                                showTodayButton: true,
                              }}
                              acceptCustomValue={false}
                              stylingMode="underlined"
                              pickerType="calendar"
                              placeholder="To Date"
                              type="date"
                              displayFormat="dd/MM/yyyy"
                              showClearButton={false}
                              value={toDate}
                              onValueChange={(value) => {
                                var objFromDate = new Date(fromDate);
                                var objToDate = new Date(value);

                                if (
                                  objToDate.getTime() < objFromDate.getTime()
                                ) {
                                  Swal.fire({
                                    title: "Error",
                                    text: "To date must be greater than from date",
                                    icon: "error",
                                  }).then((success) => {});
                                } else {
                                  field.onChange(
                                    moment(value).format("yyyy-MM-DD")
                                  );
                                  setToDate(moment(value).format("yyyy-MM-DD"));
                                  $("#business_summary_loader").removeClass(
                                    "hidden"
                                  );
                                  props.actionDashboardBusinessSummary({
                                    user_id: props.auth.getUser().user_id,
                                    from_date:
                                      moment(fromDate).format("yyyy-MM-DD"),
                                    to_date: moment(value).format("yyyy-MM-DD"),
                                  });
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {teamSummaryTotal >= 0 && summaryTotal !== 0 ? (
                  <div className="dashboard-business-summary px-1">
                    <div className="d-flex flex-row justify-content-between align-items-center mb-half">
                      <div className="flex-column">
                        <h6 className=" text-black text-bold-600 mb-half">
                          My Corporate Business Summary
                          <span
                            id="business_summary_loader"
                            className="spinner-border spinner-border-sm ml-half text-primary hidden"
                          ></span>
                        </h6>
                        <p className="font-small-2 mt-0 text-light mt-half">
                          Below statistics are showing for above date period.
                        </p>
                      </div>
                      <div className="flex-column font-medium-1 text-black"></div>
                    </div>
                    <div className="card dashboard-card-round bg-violet">
                      <div className="card-body">
                        <h1 className="font-medium-2 text-bold-600 text-white">
                          New Business
                        </h1>
                        <div className="d-flex flex-row">
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.new_premium
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Premium{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.new_premium_unit != null
                                ? "(In " +
                                  dashboardBusinessSummary.new_premium_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.new_brokerage
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Brokerage{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.new_brokerage_unit !=
                                null
                                ? "(In " +
                                  dashboardBusinessSummary.new_brokerage_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr className="bg-white" />
                        <h1 className="font-medium-2 text-bold-600 text-white">
                          Renewal Business
                        </h1>
                        <div className="d-flex flex-row">
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.renewal_premium
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Premium{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.renewal_premium_unit !=
                                null
                                ? "(In " +
                                  dashboardBusinessSummary.renewal_premium_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.renewal_brokerage
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Brokerage{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.renewal_brokerage_unit !=
                                null
                                ? "(In " +
                                  dashboardBusinessSummary.renewal_brokerage_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {verifyPermission("SHOW_TEAM") ? (
                  <div className="dashboard-business-summary px-1">
                    <div className="d-flex flex-row justify-content-between align-items-center mb-half">
                      <div className="flex-column">
                        <h6 className=" text-black text-bold-600 mb-half">
                          My Team Corporate Business Summary
                          <span
                            id="business_summary_loader"
                            className="spinner-border spinner-border-sm ml-half text-primary hidden"
                          ></span>
                        </h6>
                        <p className="font-small-2 mt-0 text-light mt-half">
                          Below statistics are showing for above date period.
                        </p>
                      </div>
                      <div className="flex-column font-medium-1 text-black"></div>
                    </div>
                    <div className="card dashboard-card-round bg-violet">
                      <div className="card-body">
                        <h1 className="font-medium-2 text-bold-600 text-white">
                          New Business
                        </h1>
                        <div className="d-flex flex-row">
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.new_team_premium
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Premium{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.new_team_premium_unit !=
                                null
                                ? "(In " +
                                  dashboardBusinessSummary.new_team_premium_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.new_team_brokerage
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Brokerage{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.new_team_brokerage_unit !=
                                null
                                ? "(In " +
                                  dashboardBusinessSummary.new_team_brokerage_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr className="bg-white" />
                        <h1 className="font-medium-2 text-bold-600 text-white">
                          Renewal Business
                        </h1>
                        <div className="d-flex flex-row">
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.renewal_team_premium
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Premium{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.renewal_team_premium_unit !=
                                null
                                ? "(In " +
                                  dashboardBusinessSummary.renewal_team_premium_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                          <div className="flex-column w-50">
                            <p className="text-center text-white mb-0">
                              <span className="font-large-1 text-bold-700">
                                {dashboardBusinessSummary != null
                                  ? Number(
                                      dashboardBusinessSummary.renewal_team_brokerage
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <p className="text-center text-white mb-0 font-small-2">
                              Brokerage{" "}
                              {dashboardBusinessSummary != null &&
                              dashboardBusinessSummary.renewal_team_brokerage_unit !=
                                null
                                ? "(In " +
                                  dashboardBusinessSummary.renewal_team_brokerage_unit +
                                  ")"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {teamSummaryTotal >= 0 && summaryTotal !== 0 ? (
                  <div className="dashboard-customer-performance px-1">
                    <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                      <div className="flex-column">
                        <h6 className=" text-black text-bold-600 mb-0">
                          Performance
                          <span className="font-small-1"> (Customer wise)</span>
                        </h6>
                      </div>
                      <div className="flex-column font-medium-1 text-black"></div>
                    </div>
                    {dashboardBusinessSummary != null &&
                    dashboardBusinessSummary.department_details != null &&
                    dashboardBusinessSummary.department_details.length > 0 ? (
                      <div className="card dashboard-card-round shadow">
                        <div className="card-body py-0">
                          <Chart
                            type="donut"
                            options={{
                              dataLabels: {
                                enabled: true,
                                style: {
                                  fontSize: "10px",
                                  fontWeight: 400,
                                },
                                textAnchor: "middle",
                              },
                              plotOptions: {
                                pie: {
                                  expandOnClick: true,
                                  customScale: 1,
                                  donut: {
                                    size: "60%",
                                  },
                                },
                              },
                              legend: {
                                fontSize: "11px",
                                fontWeight: 500,
                                position: "bottom",
                                horizontalAlign: "left",
                                markers: {
                                  width: 12,
                                  height: 12,
                                  radius: 12,
                                },
                                itemMargin: {
                                  vertical: 0,
                                },
                              },
                              labels: departments,
                            }}
                            series={departmentValues}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 col-md-12 col-lg-12 my-1">
                        <h6 className="text-bold-500 text-center">
                          No Data Found
                        </h6>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
      <CreatePost
        show={showCreatePost}
        handleClose={() => {
          props.actionGetPost({});
          setCreatePost(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  var GetPost = null;
  var DashboardBusinessSummary = null;
  var GetTicker = null;
  var MarkIn = null;
  var MarkOut = null;
  var MarkStatus = null;
  var Notifications = null;
  var GetGlobalSearchList = null;
  var TravelTabPermission = null;

  if (
    state.DashboardSummaryReducer != null &&
    Object.keys(state.DashboardSummaryReducer).length > 0 &&
    state.DashboardSummaryReducer.constructor === Object
  ) {
    switch (state.DashboardSummaryReducer.tag) {
      case ACTION_GET_POST_SUCCESS:
        GetPost = Object.assign({}, state.DashboardSummaryReducer.data);
        delete state.DashboardSummaryReducer;
        return { GetPost };
      case ACTION_DASHBOARD_BUSINESS_SUMMARY_SUCCESS:
        DashboardBusinessSummary = Object.assign(
          {},
          state.DashboardSummaryReducer.data
        );
        delete state.DashboardSummaryReducer;
        return { DashboardBusinessSummary };
      case ACTION_GET_TICKER_SUCCESS:
        GetTicker = Object.assign({}, state.DashboardSummaryReducer.data);
        delete state.DashboardSummaryReducer;
        return { GetTicker };
      case ACTION_MARK_IN_SUCCESS:
        MarkIn = Object.assign({}, state.DashboardSummaryReducer.data);
        delete state.DashboardSummaryReducer;
        return { MarkIn };
      case ACTION_MARK_OUT_SUCCESS:
        MarkOut = Object.assign({}, state.DashboardSummaryReducer.data);
        delete state.DashboardSummaryReducer;
        return { MarkOut };
      case ACTION_MARK_STATUS_SUCCESS:
        MarkStatus = Object.assign({}, state.DashboardSummaryReducer.data);
        delete state.DashboardSummaryReducer;
        return { MarkStatus };
      case ACTION_DASHBOARD_USER_NOTIFICATION_SUCCESS:
        Notifications = Object.assign({}, state.DashboardSummaryReducer.data);
        delete state.DashboardSummaryReducer;
        return { Notifications };
      case ACTION_GET_SEARCH_LIST_DASHBOARD_SUCCESS:
        GetGlobalSearchList = Object.assign(
          {},
          state.DashboardSummaryReducer.data
        );
        delete state.DashboardSummaryReducer;
        return { GetGlobalSearchList };
      default:
    }
  }
  if (
    state.CalendarTravelReducer != null &&
    Object.keys(state.CalendarTravelReducer).length > 0 &&
    state.CalendarTravelReducer.constructor === Object
  ) {
    switch (state.CalendarTravelReducer.tag) {
      case ACTION_TRAVEL_TAB_PERMISSION_SUCCESS:
        TravelTabPermission = Object.assign(
          {},
          state.CalendarTravelReducer.data
        );
        delete state.CalendarTravelReducer;
        return { TravelTabPermission };
    }
  }
  return {
    GetPost,
    DashboardBusinessSummary,
    GetTicker,
    MarkIn,
    MarkOut,
    MarkStatus,
    Notifications,
    GetGlobalSearchList,
    TravelTabPermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionGetPost,
      actionDashboardBusinessSummary,
      actionGetTicker,
      actionMarkIn,
      actionMarkOut,
      actionMarkStatus,
      actionHeaderUserNotification,
      actionGetGlobalSearchList,
      actionTravelTabPermissions,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(DashboardSummary)
);
