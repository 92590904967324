import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_EDIT_GET_TPA_NAME_SUCCESS } from "./action";
import { ACTION_TPA_MEETING_VIEW_SUCCESS } from "./action";
import { ACTION_API_ACCOMPAINED_BY_SUCCESS } from "./action";
import {ACTION_TPAMEETINGEDITCONTACT_SUCCESS} from './action';
import { ACTION_TPA_GET_CONTACT_SUCCESS } from './action';
import { ACTION_TPA_MEETING_EDIT_SUCCESS } from "./action";
import { ACTION_DOCUMENT_DELETE_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditTpaMeetingReducer = createReducer(INITIAL_STATE, {

    [ACTION_API_ACCOMPAINED_BY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_ACCOMPAINED_BY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_GET_TPA_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_GET_TPA_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TPA_MEETING_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TPA_MEETING_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TPAMEETINGEDITCONTACT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_TPAMEETINGEDITCONTACT_SUCCESS,
            loading: false
        });
    },
    [ACTION_TPA_GET_CONTACT_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_TPA_GET_CONTACT_SUCCESS,
            loading: false
        });
    },
    [ACTION_TPA_MEETING_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TPA_MEETING_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DOCUMENT_DELETE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_DOCUMENT_DELETE_SUCCESS,
            loading: false
        });
    },
});
export default EditTpaMeetingReducer;    