import React, { useEffect, useState } from 'react';
import { UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionFinanceLimitView, ACTION_FINANCE_LIMIT_VIEW_SUCCESS } from './action'

const FinanceLimitView = (props) => {
    const [financeLimitView, setFinanceLimitView] = useState(null);
    const [reimbursementTypes, setReimbursementTypes] = useState([]);

    const initialRender = () => {
        props.actionFinanceLimitView(
            { id: props.location.state.id })
    }
    useEffect(initialRender, []);

    const onFinanceLimitViewUpdate = () => {
        const { FinanceLimitView } = props;
        var FinanceLimitViewResponse = Object.assign({}, FinanceLimitView);
        if (FinanceLimitViewResponse.result === true) {
            setFinanceLimitView(FinanceLimitViewResponse.response);
            setReimbursementTypes(FinanceLimitViewResponse.response.reimbursement_types);
        } else {
            switch (FinanceLimitViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onFinanceLimitViewUpdate, [props.FinanceLimitView]);

    const status = () => {
        if (financeLimitView?.status === 'I') {
            return <div>Inactive</div>
        } else if (financeLimitView?.status === 'A') {
            return <div>Active</div>
        }
    }

    return (
        <>
            {/* <ScreenTitle title="View Reimbursement Limit" /> */}
            <section>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 pb-1">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 my-auto">
                                <h5 className="content-header-title text-capitalize mb-0">View Reimbursement Limit</h5>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="flex-column">
                                    <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                        <button
                                            className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">

                                <div className="form-body">
                                    <div className="row ">
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Entity</label>
                                                <h4 className=' font-medium-1 text-bold-700'>
                                                    {
                                                        financeLimitView != null && financeLimitView.entity_name != null ?
                                                            financeLimitView.entity_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Department</label>
                                                <h4 className=' font-medium-1 text-bold-700'>
                                                    {
                                                        financeLimitView != null && financeLimitView.department_name != null ?
                                                            financeLimitView.department_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control">Designation</label>
                                                <h4 className='font-medium-1 text-bold-700'>
                                                    {
                                                        financeLimitView != null && financeLimitView.designation_name != null ?
                                                            financeLimitView.designation_name : '-'
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        {
                                            reimbursementTypes != null && reimbursementTypes.length > 0 ?
                                                reimbursementTypes.map((obj, index) => {
                                                    return (
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="label-control mt-2">{obj.title} Limit</label>
                                                                <h4 className=' font-medium-1 text-bold-700'>
                                                                    {obj.limit}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <></>
                                        }
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control mt-2">Status</label>
                                                <h4 className='font-medium-1 text-bold-700'>
                                                    {
                                                        status()
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
const mapStateToProps = (state) => {
    var FinanceLimitView = null;
    if (
        state.ViewFinanceLimitReducer != null &&
        Object.keys(state.ViewFinanceLimitReducer).length > 0 &&
        state.ViewFinanceLimitReducer.constructor === Object
    ) {
        switch (state.ViewFinanceLimitReducer.tag) {
            case ACTION_FINANCE_LIMIT_VIEW_SUCCESS:
                FinanceLimitView = Object.assign({}, state.ViewFinanceLimitReducer.data);
                delete state.ViewFinanceLimitReducer
                return { FinanceLimitView };
            default:
        }
    }

    return {
        FinanceLimitView,

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionFinanceLimitView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceLimitView)
