import React, { useState, useEffect } from 'react';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import $ from 'jquery';
import moment from "moment";
import { actionUnattendClientLeadReport, ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS } from './action';

const LeadClientMeetingDetail = (props) => {
    const { match } = props;
    const [type, setType] = useState("client");
    const [leadCount, setLeadCount] = useState(0);
    const [clientCount, setClientCount] = useState(0);
    const [leadClientMeetingReport, setLeadClientMeetingReport] = useState([]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionUnattendClientLeadReport({ type: type });
    }
    useEffect(initialRender, []);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "company_group_name", caption: "Customer Group", visible: true },
        { dataField: "name", caption: "Client/Lead Name", visible: true },
        { dataField: "client_type", caption: "Client Type", visible: true },
        { dataField: "rm_name", caption: "RM Name", visible: true },
        { dataField: "rm_reporting_person_name", caption: "RM Reporting To", visible: true },
        { dataField: "retention_name", caption: "Assigned Retention RM", visible: true },
        { dataField: "retention_reporting_person_name", caption: "Retention RM Reporting to", visible: true },
        { dataField: "no_activity_days", caption: "No activity Days", visible: true },
        { dataField: "last_meeting", caption: "Last Meeting", visible: false },
        { dataField: "last_meeting_created_by", caption: "Last Meeting Created By", visible: false },
        { dataField: "last_meeting_accompanied_by", caption: "Last Meeting Accompanied By", visible: false },
        { dataField: "last_meeting_agenda", caption: "Last Meeting Agenda", visible: false },
        { dataField: "created_at", caption: "Create Date", visible: false },
        { dataField: "created_at_formated", caption: "Create Date", visible: true },
        { dataField: "updated_at", caption: "Modify Date", visible: false },
        { dataField: "updated_at_formated", caption: "Modify Date", visible: true }
    ]);

    const onUnattendClientLeadReportUpdate = () => {
        const { UnattendClientLeadReport } = props;
        var UnattendClientLeadReportResponse = Object.assign({}, UnattendClientLeadReport);
        if (UnattendClientLeadReportResponse.result === true) {
            $("#progress-loader").addClass("hidden");

            if (type === "client") {
                setClientCount(UnattendClientLeadReportResponse.response.length);
                setLeadClientMeetingReport(UnattendClientLeadReportResponse.response);
                setLeadCount(0);
            } else {
                setLeadCount(UnattendClientLeadReportResponse.response.length);
                setLeadClientMeetingReport(UnattendClientLeadReportResponse.response);
                setClientCount(0);
            }
        } else {
            switch (UnattendClientLeadReportResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setLeadClientMeetingReport([]);
                    setLeadCount(0);
                    setClientCount(0);
                    $("#progress-loader").addClass("hidden");
                    break;
                default:
            }
        }
    }
    useEffect(onUnattendClientLeadReportUpdate, [props.UnattendClientLeadReport]);

    const renderGroupNameCell = (cell) => {
        if (cell?.data?.company_group_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/client/group-level-view',
                        state: { client_group_id: cell?.data?.client_group_id }
                    });
                }}
            ><u>{cell?.data?.company_group_name}</u></a>
        }
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_at_formated != null ? moment(cell?.data?.created_at_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderUpdateDate = (cell) => {
        return <div>{cell?.data?.updated_at_formated != null ? moment(cell?.data?.updated_at_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: `${match.url}/view`,
                                            state: {
                                                details: params.data,
                                            }
                                        });
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                    </Column>
                );
            } else if (objColumn.dataField === "company_group_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderGroupNameCell}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "created_at_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "updated_at_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderUpdateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                id="btn-my-meeting"
                                className={`btn ${type === "client" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType("client");
                                    props.actionUnattendClientLeadReport({ type: "client" });
                                }}
                            >
                                Clients {clientCount > 0 ? `(` + clientCount + `)` : ``}
                            </button>
                        </li>
                        <li class="nav-item ml-1">
                            <button
                                id="btn-my-team-meeting"
                                className={`btn ${type === "lead" ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}
                                onClick={() => {
                                    $("#progress-loader").removeClass("hidden");
                                    setType("lead");
                                    props.actionUnattendClientLeadReport({ type: "lead" });
                                }}
                            >
                                Leads <span className='mr-half'>{leadCount > 0 ? `(` + leadCount + `)` : ''}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Unattended Client/ Lead report</h5>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="flex-column">
                            <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                <button
                                    className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                    onClick={() => {
                                        props.history.goBack();

                                    }}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={leadClientMeetingReport}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    remoteOperations={true}
                                    export={{
                                        fileName: "Unattended Customer Lead report",
                                        enabled: leadClientMeetingReport != null && leadClientMeetingReport.length > 0 ? true : false,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("last_meeting", "visible", true)
                                        event.component.columnOption("last_meeting_created_by", "visible", true)
                                        event.component.columnOption("last_meeting_accompanied_by", "visible", true)
                                        event.component.columnOption("last_meeting_agenda", "visible", true)
                                        event.component.columnOption("created_at_formated", "visible", false)
                                        event.component.columnOption("created_at", "visible", true)
                                        event.component.columnOption("updated_at_formated", "visible", false)
                                        event.component.columnOption("updated_at", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("last_meeting", "visible", false)
                                        event.component.columnOption("last_meeting_created_by", "visible", false)
                                        event.component.columnOption("last_meeting_accompanied_by", "visible", false)
                                        event.component.columnOption("last_meeting_agenda", "visible", false)
                                        event.component.columnOption("created_at_formated", "visible", true)
                                        event.component.columnOption("created_at", "visible", false)
                                        event.component.columnOption("updated_at_formated", "visible", true)
                                        event.component.columnOption("updated_at", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    {renderColumns()}
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var UnattendClientLeadReport = null;
    if (
        state.unattendClientLeadReportReducer != null &&
        Object.keys(state.unattendClientLeadReportReducer).length > 0 &&
        state.unattendClientLeadReportReducer.constructor === Object
    ) {
        switch (state.unattendClientLeadReportReducer.tag) {
            case ACTION_UNATTEND_CLIENT_LEAD_REPORT_SUCCESS:
                UnattendClientLeadReport = Object.assign({}, state.unattendClientLeadReportReducer.data);
                delete state.unattendClientLeadReportReducer;
                return { UnattendClientLeadReport };
            default:
        }
    }
    return {
        UnattendClientLeadReport,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionUnattendClientLeadReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LeadClientMeetingDetail))