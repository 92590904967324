import createReducer from "../../../../reducers/createReducer";
import { ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const viewWellnessManagementReducer = createReducer(INITIAL_STATE, {
    [ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
}
);
export default viewWellnessManagementReducer;
