import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ScreenTitle from '../../../../../../component/screen_title';

const InsurerMasterView = (props) => {

    const [insurerDetails, setInsurerDetails] = useState(null);

    const initialRender = () => {
        if (props?.location?.state?.detail != null) {
            setInsurerDetails(props?.location?.state?.detail);
        }
    }
    useEffect(initialRender, []);

    return (
        <>
            <ScreenTitle title="Insurer Details" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row mt-2">
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Type</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.type : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Category</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.category : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurer Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurer Short Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.short_name : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Head Office State</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.head_office_state : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Head Office City</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.head_office_city : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Head Office Address</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.head_office_address : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurer Website</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.website : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Status</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.status : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurer ID</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        insurerDetails != null ?
                                            insurerDetails?.insurer_id : '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Branch Details</h6>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mb-1">
                                {
                                    insurerDetails != null && insurerDetails?.branches.length > 0 ?
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Branch Id</th>
                                                    <th>Branch Type</th>
                                                    <th>Branch Code</th>
                                                    <th>Branch Short Name</th>
                                                    <th>Branch State</th>
                                                    <th>Branch City</th>
                                                    <th>Branch Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    insurerDetails?.branches.map((branch, indexContact) => (
                                                        <tr key={branch.id}>
                                                            <td>{branch.branch_id}</td>
                                                            <td>{branch.type}</td>
                                                            <td>{branch.branch_code}</td>
                                                            <td>{branch.short_name}</td>
                                                            <td>{branch.state}</td>
                                                            <td>{branch.city}</td>
                                                            <td>{branch.address}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InsurerMasterView