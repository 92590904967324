import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import moment from "moment";
import { actionRmTransferDashboard, ACTION_RM_TRANSFER_DASHBOARD_SUCCESS } from './action';

const RMTransferDashboard = (props) => {
    const [RmTransferDashboard, setRMTransferDashboard] = useState([]);
    const initialRender = () => {
        props.actionRmTransferDashboard();
    }
    useEffect(initialRender, []);

    const columns = [
        { dataField: "rm_change_action_time", caption: "Action Date", visible: false },
        { dataField: "rm_change_action_time_formated", caption: "Action Date", visible: true },
        { dataField: "from_full_name", caption: "From RM", visible: true },
        { dataField: "to_full_name", caption: "To RM", visible: true },
        { dataField: "name", caption: "Client Name", visible: true },
        { dataField: "rm_change_status", caption: "Status", visible: true },
        { dataField: "rm_change_status_log", caption: "Remark", visible: true },
    ];

    const renderActionDate = (cell) => {
        return <div>{cell?.data?.rm_change_action_time_formated != null ? moment(cell?.data?.rm_change_action_time_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "rm_change_action_time_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActionDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    >
                    </Column>
                );
            }
        });
        return arrColumns;
    };

    const onRmTransferDashboardUpdate = () => {
        const { RmTransferDashboard } = props
        var RmTransferDashboardResponse = Object.assign({}, RmTransferDashboard);
        if (RmTransferDashboardResponse.result === true) {
            setRMTransferDashboard(RmTransferDashboardResponse.response)
        } else {
            switch (RmTransferDashboardResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setRMTransferDashboard([]);
                    break;
                default:
            }
        }
    }
    useEffect(onRmTransferDashboardUpdate, [props.RmTransferDashboard])

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Dashboard of RM Transfer{RmTransferDashboard?.length === 0 ? '' : `(${RmTransferDashboard?.length})`}</h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={RmTransferDashboard}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "RM Transfer",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("rm_change_action_time_formated", "visible", false)
                                    event.component.columnOption("rm_change_action_time", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("rm_change_action_time_formated", "visible", true)
                                    event.component.columnOption("rm_change_action_time", "visible", false)
                                    event.component.endUpdate();
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var RmTransferDashboard = null;
    if (
        state.RMTransferDashboardReducer != null &&
        Object.keys(state.RMTransferDashboardReducer).length > 0 &&
        state.RMTransferDashboardReducer.constructor === Object
    ) {
        switch (state.RMTransferDashboardReducer.tag) {
            case ACTION_RM_TRANSFER_DASHBOARD_SUCCESS:
                RmTransferDashboard = Object.assign({}, state.RMTransferDashboardReducer.data);
                delete state.RMTransferDashboardReducer;
                return { RmTransferDashboard };
            default:

        }
    }
    return {
        RmTransferDashboard
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionRmTransferDashboard }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RMTransferDashboard))