import React from 'react';
import { Route, Switch } from 'react-router';
import ClientEdit from './client/edit';
import ClientList from './client/list';
import MasterExportImport from './master';
import ClientImport from './client/import_client_data';
import ImportReinsuranceMaster from './master/import_master_data';
import ReinsuranceList from './create/list';
import ReinsuranceCreate from './create/add';
import ReinsuranceView from './create/view';
import ReinsuranceEdit from './create/Edit';

const ReinsuranceRoute = (props) => {
    const {match} = props
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={ClientList}/>
            <Route path={`${match.url}/edit`}  component={ClientEdit}/>
            <Route path={`${match.url}/master`}  component={MasterExportImport}/>
            <Route path={`${match.url}/client`}  component={ClientImport}/>
            <Route path={`${match.url}/import`}  component={ImportReinsuranceMaster}/>
            <Route path={`${match.url}/reinsurance-entries`}  component={ReinsuranceList}/>
            <Route path={`${match.url}/add`}  component={ReinsuranceCreate}/>
            <Route path={`${match.url}/view`}  component={ReinsuranceView}/>
            <Route path={`${match.url}/enquiries-edit`}  component={ReinsuranceEdit}/>
        </Switch>
    )
}

export default ReinsuranceRoute