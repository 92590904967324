import { API_ACCOMPAINED_BY,API_MEETING_LIST_CONTACT } from '../../../../../../api/constants';
import { API_GET_SERVEYOR_NAME } from '../../../../../../api/constants';
import { API_SURVEYOR_MEETING_ADD } from '../../../../../../api/constants';
import { API_SERVEYORMEETINGADDCONTACT } from '../../../../../../api/constants';

import Api from '../../../../../../api';
import { PRINT } from '../../../../../../utils';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';

export const ACTION_ACCOMPAINED_BY_LOADING = 'ACTION_API_ACCOMPAINED_BY_LOADING';
export const ACTION_ACCOMPAINED_BY_SUCCESS = 'ACTION_API_ACCOMPAINED_BY_SUCCESS';

export const ACTION_ADD_GET_SERVEYOR_NAME_LOADING = 'ACTION_ADD_GET_SERVEYOR_NAMES_LOADING';
export const ACTION_ADD_GET_SERVEYOR_NAME_SUCCESS = 'ACTION_ADD_GET_SERVEYOR_NAMES_SUCCESS';

export const ACTION_SURVEYOR_MEETING_SAVE_LOADING = 'ACTION_SURVEYOR_MEETING_SAVE_LOADING';
export const ACTION_SURVEYOR_MEETING_SAVE_SUCCESS = 'ACTION_SURVEYOR_MEETING_SAVE_SUCCESS';

export const ACTION_SERVEYORMEETINGADDCONTACT_LOADING = 'ACTION_SERVEYORMEETINGADDCONTACT_LOADING';
export const ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS = 'ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS';

export const ACTION_SERVEYOR_GET_CONTACTS_LOADING = 'ACTION_SERVEYOR_GET_CONTACTS_LOADING';
export const ACTION_SERVEYOR_GET_CONTACTS_SUCCESS = 'ACTION_SERVEYOR_GET_CONTACTS_SUCCESS';

export function actionAccompainedBy(params) {
    return (dispatch, getState) =>
        Api.get(API_ACCOMPAINED_BY).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ACCOMPAINED_BY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ACCOMPAINED_BY_LOADING, { loading: false, data }));
        });
}

export function actionServeyorMeetingSave(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_SURVEYOR_MEETING_ADD, params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_SURVEYOR_MEETING_SAVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_SURVEYOR_MEETING_SAVE_LOADING, { loading: false, data }));
        });
}

export function actionGetServeyorName(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_SERVEYOR_NAME, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ADD_GET_SERVEYOR_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ADD_GET_SERVEYOR_NAME_LOADING, { loading: false, data }));
        });
}



export function actionServeyorMeetingAddContact(params) {
    return (dispatch, getState) => Api.post(API_SERVEYORMEETINGADDCONTACT, params).then((res) => {
        const data = res;
        PRINT('SERVEYORMEETINGADDCONTACT ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_SERVEYORMEETINGADDCONTACT_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_SERVEYORMEETINGADDCONTACT_LOADING, { loading: false, data }));
    });
}

export function actionServeyorGetContacts(params) {
    return (dispatch, getState) => Api.post(API_MEETING_LIST_CONTACT, params).then((res) => {
        const data = res;
        PRINT('ACTION_SERVEYOR_GET_CONTACTS_SUCCESS RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_SERVEYOR_GET_CONTACTS_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_SERVEYOR_GET_CONTACTS_LOADING, { loading: false, data }));
    });
}
