import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_SUBMISSION_POOL_LIST } from '../../../../../../api/constants';

export const ACTION_IT_SUBMISSION_POOL_LIST_LOADING = 'ACTION_IT_SUBMISSION_POOL_LIST_LOADING';
export const ACTION_IT_SUBMISSION_POOL_LIST_SUCCESS = 'ACTION_IT_SUBMISSION_POOL_LIST_SUCCESS';

export function actionItSubmissionPoolList(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_SUBMISSION_POOL_LIST, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_SUBMISSION_POOL_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_SUBMISSION_POOL_LIST_LOADING, { loading: false, data }));
        });
}