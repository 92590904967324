import React, { useState, useEffect } from "react";
import { FiEye } from "react-icons/fi";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
} from "../../../../../../utils/preferences";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { TagBox } from "devextreme-react/tag-box";
import ArrayStore from "devextreme/data/array_store";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from "../../../../../../constants";
import { useForm, Controller } from "react-hook-form";
import { DateBox } from "devextreme-react/date-box";
import { Template } from "devextreme-react";
import $ from "jquery";
import { withAuth } from "react-auth-guard";
import {
  actionAssetRequestList,
  ACTION_ASSET_REQUEST_LIST_SUCCESS,
} from "./action";
import moment from "moment";
import FilterComponent from "../../../../../../component/hrms_smart_filters";
import { getDateFilter } from "../../../../../../utils";
import dateFormat from "dateformat";

const AssetRequestList = (props) => {
  const { match } = props;

  const [fromDate, setFromDate] = useState(
    dateFormat(
      moment().subtract(1, "year").date(new Date().getDate()),
      "yyyy-mm-dd"
    )
  );
  const [toDate, setToDate] = useState(
    dateFormat(moment().add(1, "year").date(new Date().getDate()), "yyyy-mm-dd")
  );

  const { control, setValue } = useForm({});

  const [assetRequest, setAssetRequest] = useState([]);

  const [policyTypeDropdown, setPolicyTypeDropdown] = useState([
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Approved",
      value: "approved",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Cancelled",
      value: "canceled",
    },
    {
      name: "Resource Allocated",
      value: "resource_allocated",
    },
    {
      name: "Asset Allocated",
      value: "allocated",
    },
  ]);
  const [policyTypeValue, setPolicyTypeValue] = useState(["pending"]);
  let current_fy = Object.assign({}, getDateFilter("CURRENT_FY"));
  let previous_fy = Object.assign({}, getDateFilter("PREVIOUS_FY"));
  const [selectedOption, setSelectedOption] = useState(null);

  const handleFilterChange = (option) => {
    setSelectedOption(option);
    switch (option) {
      case "current_fy":
        setFromDate(current_fy.start);
        setToDate(current_fy.end);
        props.actionAssetRequestList({
          from_date: current_fy.start,
          to_date: current_fy.end,
          // status: policyTypeValue,
        });
        break;
      case "previous_fy":
        setFromDate(previous_fy.start);
        setToDate(previous_fy.end);
        props.actionAssetRequestList({
          from_date: previous_fy.start,
          to_date: previous_fy.end,
          // status: policyTypeValue,
        });
        break;
      case "all":
        setFromDate();
        setToDate();
        props.actionAssetRequestList({
          // status: policyTypeValue,
        });
        break;
      case "reset":
        $("#progress-loader").removeClass("hidden");
        setSelectedOption(null);
        setPolicyTypeValue(["pending"]);
        setFromDate(
          dateFormat(
            moment().subtract(1, "year").date(new Date().getDate()),
            "yyyy-mm-dd"
          )
        );
        setToDate(
          dateFormat(
            moment().add(1, "year").date(new Date().getDate()),
            "yyyy-mm-dd"
          )
        );
        // setValue("status", ["pending"]);
        props.actionAssetRequestList({
          from_date: fromDate,
          to_date: toDate,
          // status: ["pending"],
        });
        break;
      default:
    }
  };

  const columns = [
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "request_name", caption: "Request Name", visible: true },
    { dataField: "request_date", caption: "Request Date", visible: false },
    {
      dataField: "request_date_formated",
      caption: "Request Date",
      visible: true,
    },
    { dataField: "description", caption: "Description", visible: true },
    { dataField: "request_status", caption: " Request Status", visible: true },
  ];

  const initialRender = () => {
    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }
    $("#progress-loader").removeClass("hidden");
    props.actionAssetRequestList({
      from_date: fromDate,
      to_date: toDate,
      // status: policyTypeValue,
    });
  };
  useEffect(initialRender, []);

  const onAssetRequestListUpdate = () => {
    const { AssetRequestList } = props;
    if (AssetRequestList != null) {
      setAssetRequest([]);
      var AssetRequestListResponse = Object.assign({}, AssetRequestList);
      if (AssetRequestListResponse.result === true) {
        setAssetRequest(AssetRequestListResponse.response);
        $("#progress-loader").addClass("hidden");
      } else {
        $("#progress-loader").addClass("hidden");
        switch (AssetRequestListResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            toast.success(
              "Your Login session has expired, Please try to Login again!"
            );
            break;
          default:
        }
      }
    }
  };
  useEffect(onAssetRequestListUpdate, [props.AssetRequestList]);

  useEffect(() => {
    $("#progress-loader").removeClass("hidden");
    props.actionAssetRequestList({
      from_date: fromDate,
      to_date: toDate,
      // status: policyTypeValue,
    });
  }, [policyTypeValue]);

  const renderRequestDate = (cell) => {
    return (
      <div>
        {cell?.data?.request_date_formated != null
          ? moment(cell?.data?.request_date_formated).format("DD/MM/YYYY")
          : ""}
      </div>
    );
  };

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="Action" type="buttons">
            <Button
              render={(params) => (
                <button
                  className="btn btn-icon btn-sm"
                  onClick={() => {
                    props.history.push({
                      pathname: `${match.url}/view`,
                      state: { id: params.data.id },
                    });
                  }}
                >
                  <FiEye size={16} className="primary ml-2" />
                </button>
              )}
            />
          </Column>
        );
      } else if (objColumn.dataField === "request_date_formated") {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            cellRender={renderRequestDate}
            visible={objColumn.visible}
          ></Column>
        );
      } else {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            visible={objColumn.visible}
          ></Column>
        );
      }
    });
    return arrColumns;
  };
  return (
    <>
      <section>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-0">
              Asset Requests{" "}
              {assetRequest.length === 0 ? "" : `(${assetRequest.length})`}
            </h5>
          </div>
          <div className="content-header-right col-12 col-md-6 col-lg-6">
            <ul class="nav nav-pills float-right">
              <li class="nav-item">
                <button
                  className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                  onClick={() => {
                    props.history.push(`${match.url}/add`);
                  }}
                >
                  Raise New Asset Request
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-5">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                      <li class="nav-item">
                        <Controller
                          name="from_date"
                          control={control}
                          defaultValue={fromDate}
                          render={({ field }) => (
                            <DateBox
                              calendarOptions={{
                                showTodayButton: true,
                              }}
                              stylingMode="underlined"
                              pickerType="calendar"
                              placeholder="From Date"
                              type="date"
                              displayFormat="dd/MM/yyyy"
                              showClearButton={false}
                              value={fromDate}
                              acceptCustomValue={false}
                              onValueChange={(value) => {
                                field.onChange(value);

                                var strFromDate = dateFormat(
                                  value,
                                  "yyyy-mm-dd"
                                );
                                setFromDate(strFromDate);

                                props.actionAssetRequestList({
                                  from_date: strFromDate,
                                  to_date: toDate,
                                  // status: policyTypeValue,
                                });
                              }}
                            />
                          )}
                        />
                      </li>
                      <li class="nav-item ml-1">
                        <Controller
                          name="to_date"
                          control={control}
                          defaultValue={toDate}
                          render={({ field }) => (
                            <DateBox
                              defaultValue={new Date()}
                              calendarOptions={{
                                showTodayButton: true,
                              }}
                              stylingMode="underlined"
                              pickerType="calendar"
                              placeholder="To Date"
                              type="date"
                              displayFormat="dd/MM/yyyy"
                              showClearButton={false}
                              value={toDate}
                              acceptCustomValue={false}
                              onValueChange={(value) => {
                                field.onChange(value);

                                var strToDate = dateFormat(value, "yyyy-mm-dd");
                                setToDate(strToDate);

                                props.actionAssetRequestList({
                                  from_date: fromDate,
                                  to_date: strToDate,
                                  // status: policyTypeValue,
                                });
                              }}
                            />
                          )}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-3">
                    {/* <div className="form-group cust-tagbox">
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <TagBox
                            {...field}
                            dataSource={
                              new ArrayStore({
                                data: policyTypeDropdown,
                                key: "id",
                              })
                            }
                            dropDownOptions={{
                              onInitialized: () => {
                                console.log("initial");
                              },
                              onContentReady: () => {
                                console.log("content ready");
                              },
                              onShowing: () => {
                                console.log("showing");
                              },
                              onShown: () => {
                                console.log("shown");
                              },
                            }}
                            deferRendering={true}
                            displayExpr="name"
                            valueExpr="value"
                            name="status"
                            id="status"
                            placeholder="Select Status"
                            searchEnabled={true}
                            searchMode="contains"
                            maxDisplayedTags={2}
                            showMultiTagOnly={false}
                            stylingMode="underlined"
                            value={field.value ? field.value : policyTypeValue}
                            onValueChanged={({ value }) => {
                              if (value != null || value !== undefined) {
                                setPolicyTypeValue(value);
                                field.onChange(value);
                              }
                            }}
                            showClearButton={true}
                          />
                        )}
                      />
                    </div> */}
                  </div>
                  <div className="col-12 col-md-4 text-right">
                    <FilterComponent
                      selectedOption={selectedOption}
                      onFilterChange={handleFilterChange}
                    />
                  </div>
                </div>
                <div>
                  <DataGrid
                    dataSource={assetRequest}
                    showBorders={true}
                    export={{
                      fileName: "Asset Requests",
                      enabled: true,
                      allowExportSelectedData: false,
                      excelFilterEnabled: true,
                    }}
                    onExporting={(event) => {
                      event.component.beginUpdate();
                      event.component.columnOption(
                        "request_date_formated",
                        "visible",
                        false
                      );
                      event.component.columnOption(
                        "request_date",
                        "visible",
                        true
                      );
                    }}
                    onExported={(event) => {
                      event.component.columnOption(
                        "request_date_formated",
                        "visible",
                        true
                      );
                      event.component.columnOption(
                        "request_date",
                        "visible",
                        false
                      );
                      event.component.endUpdate();
                    }}
                    onToolbarPreparing={(e) => {
                      e.toolbarOptions.items.unshift({
                        location: "before",
                        template: "totalGroupCount",
                      });
                    }}
                  >
                    <SearchPanel visible={true} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    {renderColumns()}
                    {/* {renderStatus()}
                                {renderAssetType()} */}
                    <Pager
                      visible={true}
                      showPageSizeSelector={false}
                      showNavigationButtons={true}
                      allowedPageSizes={[4, 6, 10]}
                    />
                    <Paging
                      defaultPageIndex={0}
                      defaultPageSize={10}
                      enabled={true}
                    />

                    <Template
                      name="totalGroupCount"
                      render={() => {
                        return <></>;
                      }}
                    />
                  </DataGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  var AssetRequestList = null;

  if (
    state.AssetRequestListReducer != null &&
    Object.keys(state.AssetRequestListReducer).length > 0 &&
    state.AssetRequestListReducer.constructor === Object
  ) {
    switch (state.AssetRequestListReducer.tag) {
      case ACTION_ASSET_REQUEST_LIST_SUCCESS:
        AssetRequestList = Object.assign(
          {},
          state.AssetRequestListReducer.data
        );
        delete state.AssetRequestListReducer;
        return { AssetRequestList };
      default:
    }
  }

  return {
    AssetRequestList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionAssetRequestList }, dispatch);
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(AssetRequestList)
);
