import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_MASTER_GET_EMPLOYEE } from '../../../../../../api/constants';

export const ACTION_MASTER_GET_EMPLOYEE_LOADING = 'ACTION_MASTER_GET_EMPLOYEE_LOADING';
export const ACTION_MASTER_GET_EMPLOYEE_SUCCESS = 'ACTION_MASTER_GET_EMPLOYEE_SUCCESS';

export function actionMasterGetEmployee() {
    return (dispatch, getState) =>
        Api.get(API_MASTER_GET_EMPLOYEE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_EMPLOYEE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_GET_EMPLOYEE_LOADING, { loading: false, data }));
        });
}