import React from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from '../../../../../../component/form/error_label';

const Edit = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        alert(JSON.stringify(data));
    }
    return (
        <>
            <ScreenTitle title="Edit Branch Address"/>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">City&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="city"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                            {...field}
                                                                dataSource={['Mumbai','Pune','coimbatore']}
                                                                allowFiltering={false}
                                                                placeholder="Select City Name"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData.value)}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'City is required'
                                                        }}
                                                    />
                                                    {errors.city && <ErrorLabel message={errors.city?.message} />}
                                            </div>
                                        </div>
                                         <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Address&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="address"
                                                    name="address"
                                                    placeholder="Enter Your Address"
                                                    {...register('address', { required: 'Address is required' })}
                                                />
                                                {errors.address && <ErrorLabel message={errors.address?.message} />}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                props.history.push('/settings/branch-addresses')
                                            }}
                                        >Cancel</button>

                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Edit