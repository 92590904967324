import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from '../../../../../../component/form/error_label';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { actionPermissionEdit, ACTION_PERMISSION_EDIT_SUCCESS } from './action';
import { actionPermissionView, ACTION_PERMISSION_VIEW_SUCCESS } from './action';
import { actionModuleName, ACTION_MODULE_NAME_SUCCESS } from './action';
import { toast } from 'react-toastify';

const Edit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();

    const [moduleName, setModuleName] = useState();

    const [permissionDetails, setPermissionDetails] = useState(null);

    const initialRender = () => {
        props.actionPermissionView({ id: props.location.state.permission_id });
    }
    useEffect(initialRender, [props.show]);

    useEffect(() => {
        props.actionModuleName({});
    }, [permissionDetails])

    const onModuleNameUpdate = () => {
        const { ModuleName } = props;
        var ModuleNameResponse = Object.assign({}, ModuleName);
        if (ModuleNameResponse.result === true) {
            setModuleName(ModuleNameResponse.response);
            if (permissionDetails != null) {
                setValue('parent_id', permissionDetails.parent_id);
            }
        } else {
            switch (ModuleNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onModuleNameUpdate, [props.ModuleName]);

    const onPermissionViewUpdate = () => {
        const { PermissionView } = props;
        var PermissionViewResponse = Object.assign({}, PermissionView);
        if (PermissionViewResponse.result === true) {
            setPermissionDetails(PermissionViewResponse.response);
            setValue('title', PermissionViewResponse.response.title);
            setValue('key', PermissionViewResponse.response.key);
            setValue('status', PermissionViewResponse.response.status);
        } else {
            switch (PermissionViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onPermissionViewUpdate, [props.PermissionView]);

    const onPermissionEditUpdate = () => {
        const { PermissionEdit } = props;
        var PermissionEditResponse = Object.assign({}, PermissionEdit);
        if (PermissionEditResponse.result === true) {
            props.history.goBack();
        } else {
            switch (PermissionEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(PermissionEditResponse.message);
                default:
            }
        }
    }
    useEffect(onPermissionEditUpdate, [props.PermissionEdit]);


    const onSubmit = (data) => {
        data.id = permissionDetails.id;
        props.actionPermissionEdit(data);
        console.log(JSON.stringify(data));
    }



    return (
        <>
            <ScreenTitle title="Edit Permission" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Permission Name&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="title"
                                                    name="title"
                                                    placeholder="Enter Peremission Name"
                                                    {...register('title', { required: 'Peremission Name is required' })}
                                                />
                                                {errors.title && <ErrorLabel message={errors.title?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Permission Key&nbsp;<span className="danger">*</span></label>
                                                <input
                                                    name="key"
                                                    id="key"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter Peremission Key"
                                                    {...register('key', { required: 'Peremission Key is required' })}
                                                />
                                                {errors.key && <ErrorLabel message={errors.key?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Module Name</label>
                                                <Controller
                                                    name="parent_id"
                                                    className="form-control"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={moduleName}
                                                            enabled={false}
                                                            fields={{ text: "title", value: "id" }}
                                                            allowFiltering={false}
                                                            placeholder="Select Module Name"
                                                            value={field.value}
                                                            change={(event) => {
                                                                field.onChange(event.itemData.id)
                                                            }}

                                                        />
                                                    }
                                                />
                                                {errors.parent_id && <ErrorLabel message={errors.parent_id?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            dataSource={[
                                                                {
                                                                    title: "Active",
                                                                    id: 'active'
                                                                },
                                                                {
                                                                    title: "Inactive",
                                                                    id: 'inactive'
                                                                }
                                                            ]}
                                                            fields={{ text: "title", value: "id" }}
                                                            allowFiltering={false}
                                                            placeholder="Select Status"
                                                            value={field.value}
                                                            change={(event) => field.onChange(event.itemData.id)}
                                                        />
                                                    }
                                                    rules={{
                                                        required: 'Please Select Status'
                                                    }}
                                                />
                                                {errors.status && <ErrorLabel message={errors.status?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>

                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var PermissionEdit = null;
    var PermissionView = null;
    var ModuleName = null;

    if (
        state.EditPermissionReducer != null &&
        Object.keys(state.EditPermissionReducer).length > 0 &&
        state.EditPermissionReducer.constructor === Object
    ) {
        switch (state.EditPermissionReducer.tag) {
            case ACTION_PERMISSION_EDIT_SUCCESS:
                PermissionEdit = Object.assign({}, state.EditPermissionReducer.data);
                delete state.EditPermissionReducer
                return { PermissionEdit };
            case ACTION_PERMISSION_VIEW_SUCCESS:
                PermissionView = Object.assign({}, state.EditPermissionReducer.data);
                delete state.EditPermissionReducer
                return { PermissionView };
            case ACTION_MODULE_NAME_SUCCESS:
                ModuleName = Object.assign({}, state.EditPermissionReducer.data);
                delete state.EditPermissionReducer;
                return { ModuleName };
            default:
        }
    }

    return {
        ModuleName,
        PermissionEdit,
        PermissionView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionModuleName, actionPermissionEdit, actionPermissionView }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
