import Api from '../../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../../utils/action.util';
import { API_INSURER_VIEW_TAB_COMPANYNEWS } from '../../../../../../../api/constants';
import { PRINT } from '../../../../../../../utils';

export const ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_LOADING = 'ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_LOADING';
export const ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_SUCCESS = 'ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_SUCCESS';

export function actionInsurerViewTabCompanyNews(params) {
        return (dispatch, getState) =>
        Api.post(API_INSURER_VIEW_TAB_COMPANYNEWS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURER_VIEW_TAB_COMPANY_NEWS_LOADING, { loading: false, data }));
        });
    }

