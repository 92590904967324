import createReducer from "../../../../../reducers/createReducer";
import { ACTION_MY_CLIENT_SUCCESS } from './action'
import { ACTION_MY_TEAM_CLIENT_SUCCESS, ACTION_CLIENT_TEAM_VERTICAL_SUCCESS } from './action'

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ClientListReducer = createReducer(INITIAL_STATE, {
    [ACTION_MY_CLIENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MY_CLIENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MY_TEAM_CLIENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MY_TEAM_CLIENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_TEAM_VERTICAL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_TEAM_VERTICAL_SUCCESS,
                loading: false,
            }
        );
    },
});
export default ClientListReducer;