import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_HOLIDAY_ADD } from '../../../../../api/constants';
import {API_HOLIDAY_BRANCH} from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_HOLIDAY_ADD_LOADING = 'ACTION_HOLIDAY_ADD_LOADING';
export const ACTION_HOLIDAY_ADD_SUCCESS = 'ACTION_HOLIDAY_ADD_SUCCESS';

export const ACTION_HOLIDAY_BRANCH_LOADING = 'ACTION_HOLIDAY_BRANCH_LOADING';
export const ACTION_HOLIDAY_BRANCH_SUCCESS = 'ACTION_HOLIDAY_BRANCH_SUCCESS';


export function actionHolidayAdd(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_HOLIDAY_ADD,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_HOLIDAY_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_HOLIDAY_ADD_LOADING, { loading: false, data }));
        });
}
export function actionHolidayBranch(params) {
    return (dispatch, getState) =>
        Api.post(API_HOLIDAY_BRANCH,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_HOLIDAY_BRANCH_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_HOLIDAY_BRANCH_LOADING, { loading: false, data }));
        });
}
