
import React from "react";
import $ from 'jquery'

const FilterComponent = ({ selectedOption, onFilterChange }) => {
  return (
    <div className="col-12 col-md-auto">
      <div class="form-group">
        <div class="btn-group btn-group-sm" role="group">
          <button
            type="button"
            className={`btn ${selectedOption === 'current_fy' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => {
              $("#progress-loader").removeClass("hidden");
            onFilterChange('current_fy')
            }}
          >
            CURRENT FY
          </button>
          <button
            type="button"
            className={`btn ${selectedOption === 'previous_fy' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => {
              $("#progress-loader").removeClass("hidden");
            onFilterChange('previous_fy')}
            }
          >
            PREVIOUS FY
          </button>
          <button
            type="button"
            className={`btn ${selectedOption === 'all' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => {
              $("#progress-loader").removeClass("hidden");
            onFilterChange('all')
            }}
          >
            ALL
          </button>
          
        </div>
        <button
            type="button"
            className="btn btn-primary btn-sm round ml-1"
            onClick={() => {
              $("#progress-loader").removeClass("hidden");
            onFilterChange('reset')
            }}
          >
            Reset
          </button>
      </div>
  
    </div>
  );
};

export default FilterComponent;
