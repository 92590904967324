import createReducer from "../../../../../../reducers/createReducer";

import {ACTION_ADD_EDUCATION_SUCCESS} from './action'

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const AddEducationReducer = createReducer(INITIAL_STATE, {
    [ACTION_ADD_EDUCATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADD_EDUCATION_SUCCESS,
                loading: false,
            }
        );
    },
});
export default AddEducationReducer;