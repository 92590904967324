import createReducer from "../../../reducers/createReducer";
import { ACTION_MASTER_NEW_BRANCH_INSURANCE_COMPANY_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};
const InsuranceCompanyAddNewBranchReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_NEW_BRANCH_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_NEW_BRANCH_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
});
export default InsuranceCompanyAddNewBranchReducer;
