import React from 'react';
import { Route, Switch } from 'react-router';
import RetentionList from './list';

const RetentionRoute = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}`}  component={RetentionList}/>
            </Switch>
        </>
    )
}

export default RetentionRoute