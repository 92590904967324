import { ACTION_API_MY_LEAD_SUCCESS, ACTION_MY_TEAM_LEAD_SUCCESS, ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS } from './action';
import createReducer from '../../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const LeadListReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_API_MY_LEAD_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_MY_LEAD_SUCCESS,
                loading: false
            });
        },
        [ACTION_MY_TEAM_LEAD_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_MY_TEAM_LEAD_SUCCESS,
                loading: false
            });
        },
        [ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROFILE_LEAD_TEAM_VERTICAL_SUCCESS,
                loading: false
            });
        }
    }
);
export default LeadListReducer;