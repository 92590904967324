import { ACTION_APPROVE_AMOUNT_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ApprovalOtherClaimReducer = createReducer(INITIAL_STATE, {
    [ACTION_APPROVE_AMOUNT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_APPROVE_AMOUNT_SUCCESS,
                loading: false,
            }
        );
    },

})
export default ApprovalOtherClaimReducer