
import React, { useEffect, useState } from 'react';
import moment from "moment";
import ScreenTitle from '../../../../../../component/screen_title';
import { FiUser } from 'react-icons/fi';
import { GrNotes } from "react-icons/gr";
import { GrPlan } from "react-icons/gr";
import { GoPin } from "react-icons/go"
import $ from 'jquery';
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import Tippy from '@tippyjs/react';
import { actionItDeclarationPoolDetail, ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS } from './action';

const DeclarationPoolView = (props) => {

    const [declarationPoolDetails, setDeclarationPoolDetails] = useState();
    const tooltipSection80C = "Maximum eligible limit INR 150,000 (1.5 Lakhs).";
    const tooltipSection80CCD = "Maximum eligible limit INR 50,000 (50K).";
    const tooltipSection80D = 'Eligibility INR 25000 for Family for Emp <60 Years, 50000 for Emp > 60 Years, 50000 for Dependent Parent.';
    const tooltipOthers = "INR 200,000 (2 lakhs) maximum eligibility for Interest Paid on Housing Loan & Loss from House Property.";
    const tooltipFlexible = "(Up to max of Rs 50,000 annually - TWICE in a BLOCK OF FOUR YEARS)";

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionItDeclarationPoolDetail({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onItDeclarationPoolDetailUpdate = () => {
        const { ItDeclarationPoolDetail } = props;
        var ItDeclarationPoolDetailResponse = Object.assign({}, ItDeclarationPoolDetail);
        if (ItDeclarationPoolDetailResponse.result === true) {
            setDeclarationPoolDetails(ItDeclarationPoolDetailResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ItDeclarationPoolDetailResponse.status) {
                case ERROR:
                    toast.error(ItDeclarationPoolDetailResponse.message);
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onItDeclarationPoolDetailUpdate, [props.ItDeclarationPoolDetail]);

    return (
        <>
            <section>
                <ScreenTitle title="Declaration Details" />
                <div className='card'>
                    <div className='card-body'>
                        <form>
                            <div className="form-body">
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16} />&nbsp;Employee Details
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Name</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.full_name != null ?
                                                    declarationPoolDetails?.full_name : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Id</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.pinc_employee_id != null ?
                                                    declarationPoolDetails?.pinc_employee_id : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Payroll Entity</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.payroll_entity != null ?
                                                    declarationPoolDetails?.payroll_entity : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Primary Department</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.primary_department != null ?
                                                    declarationPoolDetails?.primary_department : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Joining Date</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.joining_date != null ?
                                                    moment(declarationPoolDetails?.joining_date).format("DD-MMM-YYYY") : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Employee Mobile</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.mobile_no != null ?
                                                    declarationPoolDetails?.mobile_no : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Reporting Manager</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.reporting_to_full_name != null ?
                                                    declarationPoolDetails?.reporting_to_full_name : '-'
                                            }
                                        </h4>
                                    </div>
                                    <div className='col-12 col-md-6 mb-1'>
                                        <h6 className='font-small-2 text-uppercase blue-grey darken-1'>Tax Regime</h6>
                                        <h4 className='font-medium-1 text-bold-700 text-justify'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.regime != null ?
                                                    declarationPoolDetails?.regime : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
                {
                    declarationPoolDetails != null && declarationPoolDetails?.regime === "OLD" ?
                        <>
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 C&nbsp;
                                        <Tippy content={tooltipSection80C}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table mb-0">
                                            <thead>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-0 border-light d-none d-md-block text-wrap'>Type of Investment</th>
                                                    <th scope="col" className='col-md-6 border-0 border-light d-none d-md-block'>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Life Insurance Premium / Pension Schemes</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.life_insurance_premium_or_pension_schemes != null ?
                                                                declarationPoolDetails?.declaration_80c?.life_insurance_premium_or_pension_schemes : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Public Provident Fund (PPF) </th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.ppf != null ?
                                                                declarationPoolDetails?.declaration_80c?.ppf : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>ULIP / ELSS / Tax saving Mutual Funds</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds != null ?
                                                                declarationPoolDetails?.declaration_80c?.ulip_or_elss_or_tax_saving_mutual_funds : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>NSC </th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.nsc != null ?
                                                                declarationPoolDetails?.declaration_80c?.nsc : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Repayment of Housing Loan (Principal Amount) </th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.repayment_of_housing_loan != null ?
                                                                declarationPoolDetails?.declaration_80c?.repayment_of_housing_loan : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Tuition Fees for Children (Max for 2 children)</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.tuition_fees_for_children != null ?
                                                                declarationPoolDetails?.declaration_80c?.tuition_fees_for_children : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>5 yr Fixed Deposits (with specified banks)</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.five_year_fd != null ?
                                                                declarationPoolDetails?.declaration_80c?.five_year_fd : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Others</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.others != null ?
                                                                declarationPoolDetails?.declaration_80c?.others : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Grand Total</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'><span className='d-inline-block d-md-none text-small-3 text-bold-600 '>Amount: </span>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80c != null && declarationPoolDetails?.declaration_80c?.grand_total != null ?
                                                                declarationPoolDetails?.declaration_80c?.grand_total : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div >
                        </>
                        :
                        <></>
                }
                <div className='row'>
                    <div className="col-md-6">
                        <div>
                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                <GrPlan size={16} />&nbsp;EPF Contribution
                            </h4>
                        </div>
                        <div className='card'>
                            <div className='card-body'>
                                <table class="table mb-0">
                                    <thead>
                                        <tr className="row container">
                                            <th scope="col" className='col-12 border-0 border-light'>PF Contribution</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="row container">
                                            <th scope="col" className='col-12 border-bottom-0 border-light'>
                                                {
                                                    declarationPoolDetails != null && declarationPoolDetails?.pf_contribution != null ?
                                                        declarationPoolDetails?.pf_contribution : '-'
                                                }
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div >
                    {
                        declarationPoolDetails != null && declarationPoolDetails?.regime === "OLD" ?
                            <div className="col-md-6">
                                <div>
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 CCD (1B)&nbsp;
                                        <Tippy content={tooltipSection80CCD}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table mb-0">
                                            <thead>
                                                <tr className="row container">
                                                    <th scope="col" className='col-12 border-0 border-light'>National Pension Scheme</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="row container">
                                                    <th scope="col" className='col-12 border-bottom-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80ccd != null && declarationPoolDetails?.declaration_80ccd?.nps != null ?
                                                                declarationPoolDetails?.declaration_80ccd?.nps : '-'
                                                        }
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
                {
                    declarationPoolDetails != null && declarationPoolDetails?.regime === "OLD" ?
                        <>
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 D&nbsp;
                                        <Tippy content={tooltipSection80D}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-0 border-light text-wrap'>Family (Self, Spouse & Children)</th>
                                                    <td scope="col" className='col-md-6 border-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80d != null && declarationPoolDetails?.declaration_80d?.family_mediclaim != null ?
                                                                declarationPoolDetails?.declaration_80d?.family_mediclaim : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Parents</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80d != null && declarationPoolDetails?.declaration_80d?.parents_mediclaim != null ?
                                                                declarationPoolDetails?.declaration_80d?.parents_mediclaim : '-'
                                                        }
                                                    </td>
                                                </tr>

                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Preventive Health Check up</th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80d != null && declarationPoolDetails?.declaration_80d?.preventive_health_check_up != null ?
                                                                declarationPoolDetails?.declaration_80d?.preventive_health_check_up : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div >
                        </>
                        :
                        <></>
                }
                {
                    declarationPoolDetails != null && declarationPoolDetails?.regime === "OLD" ?
                        <>
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Details of Investment Under Section 80 E
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-0 border-light text-wrap'>Interest Paid on Loan for Self-Education </th>
                                                    <td scope="col" className='col-md-6 border-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80e != null && declarationPoolDetails?.declaration_80e?.interest_paid_on_loan_for_self_education != null ?
                                                                declarationPoolDetails?.declaration_80e?.interest_paid_on_loan_for_self_education : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div >
                            <div >
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <GrPlan size={16} />&nbsp;Other Declarations&nbsp;
                                        <Tippy content={tooltipOthers}>
                                            <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                                        </Tippy>
                                    </h4>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-0 border-light text-wrap'>Interest Paid on Housing Loan </th>
                                                    <td scope="col" className='col-md-6 border-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80e != null && declarationPoolDetails?.declaration_other?.interest_paid_on_housing_loan != null ?
                                                                declarationPoolDetails?.declaration_other?.interest_paid_on_housing_loan : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Loss from House Property </th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80e != null && declarationPoolDetails?.declaration_other?.loss_from_house_property != null ?
                                                                declarationPoolDetails?.declaration_other?.loss_from_house_property : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="row container">
                                                    <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Rent Paid (HRA) Annually </th>
                                                    <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                                        {
                                                            declarationPoolDetails != null && declarationPoolDetails.declaration_80e != null && declarationPoolDetails?.declaration_other?.hra != null ?
                                                                declarationPoolDetails?.declaration_other?.hra : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div >
                        </>
                        :
                        <></>
                }
                <div >
                    <div className="col-12 col-md-12 col-lg-12">
                        <h4 className="d-flex align-items-center form-section mb-2 black">
                            <GrPlan size={16} />&nbsp;Flexible Plan Details&nbsp;
                            <Tippy content={tooltipFlexible}>
                                <i className="fa fa-info-circle ms-2 mt-0 cursor-pointer blink_me align-items-center" data-bs-toggle="tooltip" data-bs-placement="top"></i>
                            </Tippy>
                        </h4>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <table class="table mb-0">
                                <tbody>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-0 border-light text-wrap'>LTA</th>
                                        <td scope="col" className='col-md-6 border-0 border-light'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.lta != null ?
                                                    declarationPoolDetails?.lta : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>HDFC Meal Card</th>
                                        <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.hdfc_food_card != null ?
                                                    declarationPoolDetails?.hdfc_food_card : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Special Allowance</th>
                                        <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.special_allowance != null ?
                                                    declarationPoolDetails?.special_allowance : '-'
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
                <div >
                    <div className="col-12 col-md-12 col-lg-12">
                        <h4 className="d-flex align-items-center form-section mb-2 black">
                            <GrPlan size={16} />&nbsp;Remarks
                        </h4>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <table class="table mb-0">
                                <tbody>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-0 border-light text-wrap'>Employee Remark</th>
                                        <td scope="col" className='col-md-6 border-0 border-light'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.emp_remark != null && declarationPoolDetails?.emp_remark != '' ?
                                                    declarationPoolDetails?.emp_remark : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr className="row container">
                                        <th scope="col" className='col-md-6 border-bottom-0 border-light text-wrap'>Finance Remark</th>
                                        <td scope="col" className='col-md-6 border-bottom-0 border-light'>
                                            {
                                                declarationPoolDetails != null && declarationPoolDetails?.finance_remark != null && declarationPoolDetails?.finance_remark != '' ?
                                                    declarationPoolDetails?.finance_remark : '-'
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <button className="btn btn-outline-secondary shadow round text-uppercase mb-3"
                    onClick={(event) => {
                        event.preventDefault();
                        props.history.goBack();
                    }}
                > Back</button>
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var ItDeclarationPoolDetail = null;

    if (
        state.ItDeclarationPoolDetailReducer != null &&
        Object.keys(state.ItDeclarationPoolDetailReducer).length > 0 &&
        state.ItDeclarationPoolDetailReducer.constructor === Object
    ) {
        switch (state.ItDeclarationPoolDetailReducer.tag) {
            case ACTION_IT_DECLARATION_POOL_DETAIL_SUCCESS:
                ItDeclarationPoolDetail = Object.assign({}, state.ItDeclarationPoolDetailReducer.data);
                delete state.ItDeclarationPoolDetailReducer;
                return { ItDeclarationPoolDetail };
            default:
        }
    }
    return {
        ItDeclarationPoolDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionItDeclarationPoolDetail }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DeclarationPoolView))

