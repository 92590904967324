import React, { useState, useEffect } from 'react';
import DataGrid, {
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Column,
} from "devextreme-react/data-grid";
import { Controller, useForm } from 'react-hook-form';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { DateBox } from 'devextreme-react';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED } from '../../../../../../constants';
import { actionMeetingReport, ACTION_MEETING_REPORT_SUCCESS } from './action';
import moment from 'moment';
const MeetingPage = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const initialRender = () => {
        // $("#progress-loader").removeClass("hidden");
        props.actionMeetingReport({
            from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
            to_date: dateFormat(toDate, 'yyyy-mm-dd'),
        });
    }
    useEffect(initialRender, []);

    const [fromDate, setFromDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [employeeReport, setEmployeeReport] = useState([]);

    const [columns, setColumns] = useState([
        { dataField: "full_name", caption: "Full name" },
        { dataField: "team", caption: "Team" },
        { dataField: "branch", caption: "Branch" },
        { dataField: "new_customer", caption: "New customer" },
        { dataField: "existing_customer", caption: "Existing customer" },
        { dataField: "insurance_meeting", caption: "Insurance meeting" },
        { dataField: "internal_meeting", caption: "Internal meeting" },
        { dataField: "surveyor_meeting", caption: "Surveyor meeting" },
        { dataField: "tpa_meeting", caption: "Tpa meeting" },
        { dataField: "ri_meeting", caption: "RI meeting" },
        { dataField: "accompanied_meetings", caption: "Accompanied meetings" },
        { dataField: "total", caption: "Total" },
    ]);

    const onMeetingReportUpdate = () => {
        const { MeetingReport } = props;
        var MeetingReportResponse = Object.assign({}, MeetingReport);
        $("#meeting-report").prop("disabled", false);
        if (MeetingReportResponse.result === true) {
            setEmployeeReport(MeetingReportResponse.response);
            $("#progress-loader").addClass("hidden");
            $("#meeting-report-loader").addClass("hidden");
        } else {
            $("#meeting-report-loader").addClass("hidden");
            switch (MeetingReportResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMeetingReportUpdate, [props.MeetingReport]);

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            arrColumns.push(
                <Column
                    dataField={objColumn.dataField}
                    caption={objColumn.caption}
                ></Column>
            );

        });
        return arrColumns;
    };

    const onSubmit = (data) => {
    }
    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Meeting Report</h5>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="flex-column">
                            <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                <button
                                    className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                    onClick={() => {
                                        props.history.goBack();
                                    }}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">From Date</label>
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                defaultValue={fromDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(value).format('YYYY-MM-DD');
                                                            var objToDate = moment(toDate).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "From date can't be greater than To date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                setFromDate(dateFormat(value, 'yyyy-mm-dd'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">To Date</label>
                                            <Controller
                                                className="flex-column"
                                                name="to_date"
                                                control={control}
                                                defaultValue={toDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(fromDate).format('YYYY-MM-DD');
                                                            var objToDate = moment(value).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "To date must be greater than from date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                setToDate(dateFormat(value, 'yyyy-mm-dd'));
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-actions mt-1 border-0 float-none">
                                        <button
                                            id="meeting-report"
                                            className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                            onClick={(data) => {
                                                $("#meeting-report-loader").removeClass("hidden");
                                                $("#meeting-report").prop("disabled", true);
                                                props.actionMeetingReport({
                                                    from_date: dateFormat(fromDate, 'yyyy-mm-dd'),
                                                    to_date: dateFormat(toDate, 'yyyy-mm-dd'),
                                                });
                                            }}
                                        >Search
                                            <span id="meeting-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-md-12">
                                <DataGrid
                                    dataSource={employeeReport}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    remoteOperations={true}
                                    export={{
                                        fileName: "Meeting Report",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                >
                                    {renderColumns()}
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {

    var MeetingReport = null;

    if (
        state.meetingReportReducer != null &&
        Object.keys(state.meetingReportReducer).length > 0 &&
        state.meetingReportReducer.constructor === Object
    ) {
        switch (state.meetingReportReducer.tag) {

            case ACTION_MEETING_REPORT_SUCCESS:
                MeetingReport = Object.assign({}, state.meetingReportReducer.data);
                delete state.meetingReportReducer;
                return { MeetingReport };
            default:
        }
    }

    return {

        MeetingReport,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMeetingReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MeetingPage))
