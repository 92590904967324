import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label"
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../utils/preferences';
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { checkFileExtension } from '../../../utils';
import { actionChangeStatus, ACTION_CHANGE_STATUS_SUCCESS } from './action';
import { UNAUTHORIZED, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, REQUEST_ASSET, REQUEST_TRAVEL, REVIEW_HR, APPROVED_BY_HR, APPROVED_BY_MANAGER, REQUEST_ACCEPTED, REQUEST_REJECTED_SUPERVISOR, APPROVED } from '../../../constants';
import { withAuth } from 'react-auth-guard';

const ChangeStatus = (props) => {

    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const validExt = ['jpg', 'jpeg', 'png', 'docx', 'doc', 'pdf', 'PDF', 'xlsx', 'csv'];
    const [uploadDocument, setUploadDocument] = useState(null);
    const [status, setStatus] = useState(null);

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

            $("#approval_action-loader").addClass("hidden");
            $("#approval_action").prop("disabled", false);

        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };

    useEffect(initialRender, [props.show]);
    const onChangeStatusUpdate = () => {
        const { ChangeStatus } = props;
        var ChangeStatusResponse = Object.assign({}, ChangeStatus);
        $("#approval_action").prop("disabled", false);
        if (ChangeStatusResponse.result === true) {
            if (getData(KEY_TOAST_MESSAGE) != null) {
                toast.success(getData(KEY_TOAST_MESSAGE));
                removeData(KEY_TOAST_MESSAGE);
            }
            props.handleClose(ChangeStatusResponse.response);
            $("#approval_action-loader").addClass("hidden");
        } else {
            $("#approval_action-loader").addClass("hidden");
            switch (ChangeStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onChangeStatusUpdate, [props.ChangeStatus]);

    const onSubmit = (data) => {

        data.id = props.approval.id;
        data.remark = data.remark;

        if (props.type === REQUEST_ASSET || props.type === REQUEST_TRAVEL) {

            if (props.type === REQUEST_ASSET) {
                if (props.approvedBy === APPROVED_BY_MANAGER) {

                    if (data.status === APPROVED) {
                        data.status = REVIEW_HR;
                    } else {
                        data.status = REQUEST_REJECTED_SUPERVISOR;
                    }

                } else if (props.approvedBy === APPROVED_BY_HR) {
                    if (data.status === APPROVED) {
                        data.status = REQUEST_ACCEPTED;
                    }
                }
            }

            if (props.type === REQUEST_TRAVEL) {

                if (data.status === APPROVED) {
                    data.status = REQUEST_ACCEPTED;
                } else {
                    data.status = REQUEST_REJECTED_SUPERVISOR;
                }
            }


        } else {
            data.status = data.status;
        }

        data.type = props.type;
        var files = [];
        if (uploadDocument != null) {
            files.push({ 'name': 'upload_document', 'value': uploadDocument });
        }
        delete data.upload_document;
        // data.old_status = props.approval.status;

        if (props.type === REQUEST_TRAVEL) {
            if (status === 'approved') {
                if (uploadDocument != null) {
                    $("#approval_action-loader").removeClass("hidden");
                    $("#approval_action").prop("disabled", true);
                    props.actionChangeStatus(data, files);
                } else {
                    $('#error-pdf-doc-not-found').removeClass('d-none')
                }
            }
            else {
                $('#error-pdf-doc-not-found').addClass('d-none')
                props.actionChangeStatus(data);
            }
        } else {
            $('#error-pdf-doc-not-found').addClass('d-none')
            $("#approval_action-loader").removeClass("hidden");
            $("#approval_action").prop("disabled", true);
            props.actionChangeStatus(data);
        }
    }
    return (

        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="education-add"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Change Status
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Action&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={[
                                                                    {
                                                                        value: 'rejected',
                                                                        text: 'Reject'

                                                                    },
                                                                    {
                                                                        value: 'approved',
                                                                        text: 'Approve'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Action"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setStatus(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Action is required'
                                                        }}
                                                    />

                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            {
                                                props.type === REQUEST_TRAVEL && status === 'approved' ?
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <fieldset class="form-group">
                                                            <label for="basicInputFile">Upload Document&nbsp; <span class="text-danger">*</span></label>
                                                            <div class="custom-file">
                                                                <input
                                                                    type="file"
                                                                    class="custom-file-input"
                                                                    id="upload_document"
                                                                    accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .PDF, .xlsx, .csv"
                                                                    name="upload_document"
                                                                    onChange={(event) => {
                                                                        $('#error-pdf-doc-not-found').addClass('d-none')
                                                                        if (event.target.files != null) {

                                                                            var fileSize = event.target.files[0]?.size;

                                                                            var ext = (event.target.files[0].name).split('.').pop();

                                                                            if (checkFileExtension(ext, validExt)) {

                                                                                if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                    setUploadDocument(event.target.files[0]);
                                                                                } else {
                                                                                    Swal.fire({
                                                                                        title: 'Error',
                                                                                        icon: 'error',
                                                                                        html: MEETING_FILE_ERROR_MESSAGE,
                                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                                    }).then((result) => {

                                                                                    });
                                                                                }
                                                                            }
                                                                            else {
                                                                                Swal.fire({
                                                                                    title: 'Upload Error',
                                                                                    text: 'You can only upload jpg, jpeg, png, docx, doc, pdf, PDF, xlsx, csv files',
                                                                                    icon: 'error'
                                                                                }).then(success => {

                                                                                })
                                                                            }
                                                                        } else {
                                                                            $('#error-pdf-doc-not-found').removeClass('d-none')
                                                                        }
                                                                    }}
                                                                />
                                                                <small className="error-msg d-none text-danger" id="error-pdf-doc-not-found">Document is required</small>
                                                                <label class="custom-file-label" for="upload_document">
                                                                    {
                                                                        uploadDocument != null ?
                                                                            uploadDocument.name : "Choose file"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Reason</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        rows={5}
                                                        placeholder="Add Reason"
                                                        {...register('remark', {
                                                            required: status === 'rejected' ? 'Reason is required' : false,
                                                        })}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="approval_action"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                &nbsp;Submit
                                                <span id="approval_action-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose();
                                            }}
                                        > Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = (state) => {

    var ChangeStatus = null;

    if (
        state.ApprovalChangeStatusReducer != null &&
        Object.keys(state.ApprovalChangeStatusReducer).length > 0 &&
        state.ApprovalChangeStatusReducer.constructor === Object
    ) {
        switch (state.ApprovalChangeStatusReducer.tag) {
            case ACTION_CHANGE_STATUS_SUCCESS:
                ChangeStatus = Object.assign({}, state.ApprovalChangeStatusReducer.data);
                delete state.ApprovalChangeStatusReducer;
                return { ChangeStatus };
            default:
        }
    }
    return {
        ChangeStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionChangeStatus }, dispatch);
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ChangeStatus));
