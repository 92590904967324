import React, { useState, useEffect } from 'react';
import Bar from "../../chart_details";
import { DateBox } from 'devextreme-react/date-box';
import { useForm, Controller } from "react-hook-form";
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { FiShield } from 'react-icons/fi';
import { RiThumbUpLine, RiUserStarLine } from "react-icons/ri";
import { FaRegHandshake } from "react-icons/fa";
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import $ from "jquery";
import { withAuth } from 'react-auth-guard';
import { toast } from "react-toastify";
import moment from "moment";
import { verifyPermission } from '../../../../../../utils';
import TeamMembers from '../../../../../../component/popups/team_details';
import { FiFilter } from "react-icons/fi";
import { actionBusinessSummary, ACTION_BUSINESS_SUMMARY_SUCCESS } from './action';
import { actionTeamBusinessSummary, ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS } from './action';
import { actionProfileBusinessSummaryTeamMember, ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS } from './action';

const TabBusinessSummary = (props) => {

    const { control, formState: { } } = useForm({});

    const [businessSummary, setBusinessSummary] = useState(null);
    const [teamBusinessSummary, setTeamBusinessSummary] = useState(null);
    const [showRMName, setShowRMName] = useState(false);
    const [showTeamMeeting, setShowTeamMeeting] = useState(true);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [myTeamVertical, setMyTeamVertical] = useState([]);
    const [summaryTotal, setSummaryTotal] = useState();
    const [teamSummaryTotal, setTeamSummaryTotal] = useState();
    const [status, setStatus] = useState();
    const [userIds, setUserIds] = useState([]);

    const [fromDate, setFromDate] = useState(moment().month() < 3
        ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("yyyy-MM-DD")
        : moment()
            .month(3)
            .startOf("month")
            .format("yyyy-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));

    const [fromDateTeam, setFromDateTeam] = useState(moment().month() < 3
        ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("yyyy-MM-DD")
        : moment()
            .month(3)
            .startOf("month")
            .format("yyyy-MM-DD"));
    const [toDateTeam, setToDateTeam] = useState(moment().format("yyyy-MM-DD"));

    const [selectedTab, setSelectedTab] = useState(1);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionProfileBusinessSummaryTeamMember({ user_id: props.userId });
        if (props.userId != null && props.userId != 0) {
            props.actionBusinessSummary({
                user_id: props.userId
            });
        }
    }
    useEffect(initialRender, [props.userId]);

    const onBusinessSummaryUpdate = () => {
        const { BusinessSummary } = props;
        if (BusinessSummary != null) {
            var BusinessSummaryResponse = Object.assign({}, BusinessSummary);
            if (BusinessSummaryResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setShowRMName(false);
                setShowTeamMeeting(true);
                setBusinessSummary(BusinessSummaryResponse.response);
                setSummaryTotal(Number(BusinessSummaryResponse.response.net_premium) + Number(BusinessSummaryResponse.response.brokerage))
            } else {
                $("#progress-loader").addClass("hidden");
                setShowTeamMeeting(true);
                setBusinessSummary(null);
                switch (BusinessSummaryResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onBusinessSummaryUpdate, [props.BusinessSummary]);

    const onTeamBusinessSummaryUpdate = () => {
        const { TeamBusinessSummary } = props;
        if (TeamBusinessSummary != null) {
            var TeamBusinessSummaryResponse = Object.assign({}, TeamBusinessSummary);
            if (TeamBusinessSummaryResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setShowRMName(false);
                setShowTeamMeeting(false);
                setTeamBusinessSummary(TeamBusinessSummaryResponse.response);
                setTeamSummaryTotal(Number(TeamBusinessSummaryResponse.response.net_premium) + Number(TeamBusinessSummaryResponse.response.brokerage))
            } else {
                $("#progress-loader").addClass("hidden");
                setShowTeamMeeting(true);
                setTeamBusinessSummary(null);
                switch (TeamBusinessSummaryResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:

                }
            }
        }
    }
    useEffect(onTeamBusinessSummaryUpdate, [props.TeamBusinessSummary]);

    const onProfileBusinessSummaryTeamMemberUpdate = () => {
        const { ProfileBusinessSummaryTeamMember } = props;
        if (ProfileBusinessSummaryTeamMember != null) {
            var ProfileBusinessSummaryTeamMemberResponse = Object.assign({}, ProfileBusinessSummaryTeamMember);
            setStatus(ProfileBusinessSummaryTeamMemberResponse.status);
            if (ProfileBusinessSummaryTeamMemberResponse.result === true) {
                setShowTeamMeeting(true)
                var myTeam = []
                var myTeamMember = []
                ProfileBusinessSummaryTeamMemberResponse.response.map((item) => {
                    if (item.id != props.userId) {
                        myTeam.push(item);
                        myTeamMember.push(item.id);
                        setMyTeamVertical(myTeam);
                        setMyTeamMembers(myTeamMember);
                    }
                })

            } else {
                setShowTeamMeeting(true)
                switch (ProfileBusinessSummaryTeamMemberResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onProfileBusinessSummaryTeamMemberUpdate, [props.ProfileBusinessSummaryTeamMember]);

    useEffect(() => {
        return () => {
            $("#progress-loader").addClass("hidden");
        }
    }, [])

    const renderTab = () => {
        switch (selectedTab) {
            case 1:
                return (
                    <div>
                        <div className="card mb-0">
                            <div className="card-body">
                                <div class="row mt-1 ml-1">
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={fromDate}
                                                        onValueChange={(value) => {
                                                            field.onChange(moment(value).format('yyyy-MM-DD'));
                                                            setFromDate(moment(value).format('yyyy-MM-DD'));
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <Controller
                                                name="to_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={toDate}
                                                        onValueChange={(value) => {
                                                            field.onChange(moment(value).format('yyyy-MM-DD'));
                                                            setToDate(moment(value).format('yyyy-MM-DD'));
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <button
                                            className="btn btn-primary py-half mr-1 shadow round text-uppercase"
                                            type="submit"
                                            onClick={() => {
                                                $("#progress-loader").removeClass("hidden");
                                                setShowTeamMeeting(false);
                                                setShowRMName(false);
                                                if (fromDate.length > 0 && toDate.length > 0) {
                                                    props.actionBusinessSummary({
                                                        user_id: props.userId,
                                                        from_date: moment(fromDate).format('yyyy-MM-DD'),
                                                        to_date: moment(toDate).format('yyyy-MM-DD')
                                                    });
                                                } else {
                                                    Swal.fire({
                                                        title: 'Error',
                                                        icon: 'error',
                                                        html: 'Please Select From Date & To Date',
                                                        confirmButtonColor: PRIMARY_COLOR
                                                    }).then((result) => {

                                                    });
                                                }
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mx-half mt-2">
                                    {
                                        summaryTotal !== 0 ?
                                            <>
                                                <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                                    <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                                        <div className="flex-column">
                                                            <span class="primary d-flex align-items-center justify-content-center p-1">
                                                                <HiOutlineCurrencyRupee size={28} />
                                                            </span>
                                                        </div>
                                                        <div className="flex-column ml-1">
                                                            <div class="stats-amount">
                                                                <h3 class="heading-text text-bold-600">
                                                                    {
                                                                        businessSummary != null ?
                                                                            Number(businessSummary.brokerage).toFixed(2) : 0
                                                                    }

                                                                </h3>
                                                                <p class="sub-heading mb-0 font-small-3">Brokerage {
                                                                    businessSummary != null && businessSummary.brokerage_unit != null ?
                                                                        '(In ' + businessSummary.brokerage_unit + ')' : ''
                                                                }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                                    <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                                        <div className="flex-column">
                                                            <span class="primary d-flex align-items-center justify-content-center p-1">
                                                                <HiOutlineCurrencyRupee size={28} />
                                                            </span>
                                                        </div>
                                                        <div className="flex-column ml-1">
                                                            <div class="stats-amount">
                                                                <h3 class="heading-text text-bold-600">
                                                                    {
                                                                        businessSummary != null ?
                                                                            Number(businessSummary.net_premium).toFixed(2) : 0
                                                                    }
                                                                </h3>
                                                                <p class="sub-heading mb-0 font-small-3">Premium {
                                                                    businessSummary != null && businessSummary.net_premium_unit != null ?
                                                                        '(In ' + businessSummary.net_premium_unit + ')' : ''
                                                                }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <RiUserStarLine size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            businessSummary != null ?
                                                                businessSummary?.client_count : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Total Corporate Clients</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <RiThumbUpLine size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            businessSummary != null ?
                                                                businessSummary.lead_count : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Total Leads</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row bg-primary-shadow rounded bg-accent-2 py-1  mx-half mt-2">
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FiShield size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            businessSummary != null ?
                                                                businessSummary.no_of_policies : 0
                                                        }
                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Number of Corporate Policies</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FaRegHandshake size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            businessSummary != null ?
                                                                businessSummary.closed_client_meetings : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Closed Client Meeting</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FaRegHandshake size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            businessSummary != null ?
                                                                businessSummary.closed_lead_meetings : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Closed Lead Meeting</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FaRegHandshake size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            businessSummary != null ?
                                                                businessSummary.closed_insurance_meetings : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Closed Insurance Meeting</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            Number(businessSummary?.client_count) != 0 &&
                                Number(businessSummary?.lead_count) != 0 && Number(businessSummary?.closed_client_meetings) != 0 && Number(businessSummary?.closed_lead_meetings) != 0 &&
                                Number(businessSummary?.closed_insurance_meetings) !== 0 ?
                                <div className="card mt-0">
                                    <div className="card-body pt-0">
                                        <Bar details={businessSummary} />
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </div>
                )
            case 2:
                return (
                    <div>
                        <div className="card mb-0">
                            <div className="card-body">
                                <div class="row mt-1 ml-1">
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={fromDateTeam}
                                                        onValueChange={(value) => {
                                                            field.onChange(moment(value).format('yyyy-MM-DD'));
                                                            setFromDateTeam(moment(value).format('yyyy-MM-DD'));
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-5">
                                        <div className="form-group">
                                            <Controller
                                                name="to_date"
                                                control={control}
                                                render={({ field }) =>
                                                    <DateBox
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={toDateTeam}
                                                        onValueChange={(value) => {
                                                            field.onChange(moment(value).format('yyyy-MM-DD'));
                                                            setToDateTeam(moment(value).format('yyyy-MM-DD'));
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <button
                                            className="btn btn-primary py-half mr-1 shadow round text-uppercase"
                                            type="submit"
                                            onClick={() => {

                                                if (showTeamMeeting != true) {
                                                    if (showRMName === true) {
                                                        setShowRMName(false);
                                                    } else {
                                                        setShowRMName(true);
                                                    }
                                                } else {
                                                    if (fromDateTeam.length > 0 && toDateTeam.length > 0) {
                                                        props.actionTeamBusinessSummary({
                                                            user_id: myTeamMembers,
                                                            from_date: moment(fromDateTeam).format('yyyy-MM-DD'),
                                                            to_date: moment(toDateTeam).format('yyyy-MM-DD')
                                                        });
                                                    } else {
                                                        Swal.fire({
                                                            title: 'Error',
                                                            icon: 'error',
                                                            html: 'Please Select From Date & To Date',
                                                            confirmButtonColor: PRIMARY_COLOR
                                                        }).then((result) => {

                                                        });
                                                    }
                                                }
                                            }}
                                        >
                                            <FiFilter size={16} /> Submit
                                        </button>
                                    </div>
                                </div>
                                <div class="row bg-primary-shadow rounded bg-accent-2 py-1 mx-half mt-2">
                                    {
                                        teamSummaryTotal !== 0 ?
                                            <>
                                                <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                                    <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                                        <div className="flex-column">
                                                            <span class="primary d-flex align-items-center justify-content-center p-1">
                                                                <HiOutlineCurrencyRupee size={28} />
                                                            </span>
                                                        </div>
                                                        <div className="flex-column ml-1">
                                                            <div class="stats-amount">
                                                                <h3 class="heading-text text-bold-600">
                                                                    {
                                                                        teamBusinessSummary != null ?
                                                                            Number(teamBusinessSummary.brokerage).toFixed(2) : 0
                                                                    }

                                                                </h3>
                                                                <p class="sub-heading mb-0 font-small-3">Brokerage {
                                                                    teamBusinessSummary != null && teamBusinessSummary.brokerage_unit ?
                                                                        '(In ' + teamBusinessSummary.brokerage_unit + ')' : ''
                                                                }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                                    <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                                        <div className="flex-column">
                                                            <span class="primary d-flex align-items-center justify-content-center p-1">
                                                                <HiOutlineCurrencyRupee size={28} />
                                                            </span>
                                                        </div>
                                                        <div className="flex-column ml-1">
                                                            <div class="stats-amount">
                                                                <h3 class="heading-text text-bold-600">
                                                                    {
                                                                        teamBusinessSummary != null ?
                                                                            Number(teamBusinessSummary.net_premium).toFixed(2) : 0
                                                                    }
                                                                </h3>
                                                                <p class="sub-heading mb-0 font-small-3">Premium {
                                                                    teamBusinessSummary != null && teamBusinessSummary.net_premium_unit ?
                                                                        '(In ' + teamBusinessSummary.net_premium_unit + ')' : ''
                                                                }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <RiUserStarLine size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            teamBusinessSummary != null ?
                                                                teamBusinessSummary?.client_count : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Total Corporate Clients</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <RiThumbUpLine size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            teamBusinessSummary != null ?
                                                                teamBusinessSummary.lead_count : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Total Leads</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row bg-primary-shadow rounded bg-accent-2 py-1  mx-half mt-2">
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5 ">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FiShield size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            teamBusinessSummary != null ?
                                                                teamBusinessSummary.no_of_policies : 0
                                                        }
                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Number of Corporate Policies</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FaRegHandshake size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            teamBusinessSummary != null ?
                                                                teamBusinessSummary.closed_client_meetings : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Closed Client Meeting</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FaRegHandshake size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            teamBusinessSummary != null ?
                                                                teamBusinessSummary.closed_lead_meetings : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Closed Lead Meeting</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                        <div class="d-flex flex-row align-items-center mb-sm-1 mb-xl-0">
                                            <div className="flex-column">
                                                <span class="primary d-flex align-items-center justify-content-center p-1">
                                                    <FaRegHandshake size={28} />
                                                </span>
                                            </div>
                                            <div className="flex-column ml-1">
                                                <div class="stats-amount">
                                                    <h3 class="heading-text text-bold-600">
                                                        {
                                                            teamBusinessSummary != null ?
                                                                teamBusinessSummary.closed_insurance_meetings : 0
                                                        }

                                                    </h3>
                                                    <p class="sub-heading mb-0 font-small-3">Closed Insurance Meeting</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            Number(teamBusinessSummary?.client_count) != 0 &&
                                Number(teamBusinessSummary?.lead_count) != 0 && Number(teamBusinessSummary?.closed_client_meetings) != 0 && Number(teamBusinessSummary?.closed_lead_meetings) != 0 &&
                                Number(teamBusinessSummary?.closed_insurance_meetings) !== 0 ?
                                <div className="card mt-0">
                                    <div className="card-body pt-0">
                                        <Bar details={teamBusinessSummary} />
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </div>
                )
            default:
                return (
                    <></>
                )
        }
    }
    useEffect(renderTab, [selectedTab])

    return (
        <>

            {
                props.isTeamMember === true ?
                    <div className="tab-pane active" id="myprofile-details" aria-labelledby="myprofile-details-tab" role="tabpanel">
                        <div className="card-content pb-1">
                            <div className="card-body px-0 py-0">
                                <ul className="nav nav-tabs nav-underline border-bottom w-100" role="tablist" style={{ backgroundColor: 'transparent' }}>
                                    <li className="nav-item col-12 col-md-4 col-lg-3 pl-0 pr-0">
                                        <a className="nav-link d-flex align-items-center justify-content-center active" id="my-business-summary-tab" data-toggle="tab" aria-controls="my-business-summary-tab" role="tab" aria-selected="true"
                                            onClick={() => {
                                                setSelectedTab(1)
                                            }}
                                        >
                                            <span>&nbsp;My Business Summary</span>
                                        </a>
                                    </li>
                                    {
                                        verifyPermission("TEAM_BUSINESS_SUMMARY") && verifyPermission("SHOW_TEAM") && status !== 402 ?
                                            <li className="nav-item col-12 col-md-4 col-lg-3 pl-0 pr-0">
                                                <a className="nav-link d-flex align-items-center justify-content-center" id="my-team-business-summary-tab" data-toggle="tab" aria-controls="my-team-business-summary-tab" role="tab" aria-selected="false"
                                                    onClick={() => {
                                                        setSelectedTab(2)
                                                        props.actionTeamBusinessSummary({
                                                            user_id: myTeamMembers,
                                                            from_date: moment(fromDateTeam).format('yyyy-MM-DD'),
                                                            to_date: moment(toDateTeam).format('yyyy-MM-DD')
                                                        });
                                                    }}
                                                >
                                                    <span>&nbsp;Team Business Summary</span>
                                                </a>
                                            </li>
                                            :
                                            <></>
                                    }
                                </ul>
                            </div>
                        </div>
                        {renderTab()}
                    </div>
                    :
                    <></>
            }
            <TeamMembers
                {...props}
                show={showRMName}
                myTeamVertical={myTeamVertical}
                myTeamMembers={userIds != null && userIds.length > 0 ? userIds : myTeamMembers}
                handleClose={(teamMember) => {
                    if (teamMember != null) {
                        setUserIds(teamMember);
                        props.actionTeamBusinessSummary({
                            user_id: teamMember,
                            from_date: moment(fromDateTeam).format('yyyy-MM-DD'),
                            to_date: moment(toDateTeam).format('yyyy-MM-DD')
                        });
                    } else {
                        $("#progress-loader").addClass("hidden");
                    }
                    setShowRMName(false);
                }}
            />
        </>
    )
}

function mapStateToProps(state) {
    var BusinessSummary = null;
    var TeamBusinessSummary = null;
    var ProfileBusinessSummaryTeamMember = null;

    if (
        state.BusinessSummaryReducer != null &&
        Object.keys(state.BusinessSummaryReducer).length > 0 &&
        state.BusinessSummaryReducer.constructor === Object
    ) {
        switch (state.BusinessSummaryReducer.tag) {
            case ACTION_BUSINESS_SUMMARY_SUCCESS:
                BusinessSummary = Object.assign({}, state.BusinessSummaryReducer.data);
                delete state.BusinessSummaryReducer;
                return { BusinessSummary };
            case ACTION_TEAM_BUSINESS_SUMMARY_SUCCESS:
                TeamBusinessSummary = Object.assign({}, state.BusinessSummaryReducer.data);
                delete state.BusinessSummaryReducer;
                return { TeamBusinessSummary };
            case ACTION_TEAM_VERTICAL_BUSINESS_SUMMARY_SUCCESS:
                ProfileBusinessSummaryTeamMember = Object.assign({}, state.BusinessSummaryReducer.data);
                delete state.BusinessSummaryReducer;
                return { ProfileBusinessSummaryTeamMember };
            default:
        }
    }
    return {
        BusinessSummary,
        TeamBusinessSummary,
        ProfileBusinessSummaryTeamMember
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionBusinessSummary, actionTeamBusinessSummary, actionProfileBusinessSummaryTeamMember }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TabBusinessSummary));
