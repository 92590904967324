import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_IT_DECLARATION_LIST, API_IT_DECLARATION_SETTINGS_ACTIVE } from '../../../../../../api/constants';

export const ACTION_IT_DECLARATION_LIST_LOADING = 'ACTION_IT_DECLARATION_LIST_LOADING';
export const ACTION_IT_DECLARATION_LIST_SUCCESS = 'ACTION_IT_DECLARATION_LIST_SUCCESS';

export const ACTION_IT_DECLARATION_SETTINGS_ACTIVE_LOADING = 'ACTION_IT_DECLARATION_SETTINGS_ACTIVE_LOADING';
export const ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS = 'ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS';

export function actionItDeclarationList(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_LIST, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_LIST_LOADING, { loading: false, data }));
        });
}

export function actionItDeclarationSettingActive(params) {
    return (dispatch, getState) =>
        Api.post(API_IT_DECLARATION_SETTINGS_ACTIVE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_IT_DECLARATION_SETTINGS_ACTIVE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_IT_DECLARATION_SETTINGS_ACTIVE_LOADING, { loading: false, data }));
        });
}