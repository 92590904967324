import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import Swal from "sweetalert2";
import dateFormat from 'dateformat';
import { DateBox } from "devextreme-react";
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from "devextreme/data/array_store";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { actionProposalPoolList, ACTION_PROPOSAL_POOL_LIST_SUCCESS } from "./action";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import moment from "moment";
import ErrorLabel from "../../../../../../component/form/error_label";
import { toast } from "react-toastify";
import { ToWords } from 'to-words';

const OpportunityFilter = (props) => {
    const { register, handleSubmit, formState: { errors }, control, setValue, reset, watch } = useForm();
    // const [fromDate, setFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    // const [toDate, setToDate] = useState(moment(new Date()).add(4, 'months').format('YYYY-MM-DD'));
    const [proposalPoolListCount, setProposalPoolListCount] = useState(0);
    const [loginId, setLoginId] = useState(props?.LoginId)
    const [selectedCheckUserId, setSelectedCheckUserId] = useState();
    const [objPremium, setObjPremium] = useState([])
    const [netPremiumValue, setNetPremiumValue] = useState(null)
    const [netPremium, setNetPremium] = useState([])
    const [premium, setPremium] = useState([])
    const [premiumDropdown, setPremiumDropdown] = useState(false)
    const [sumInsuredDropdown, setSumInsuredDropdown] = useState(false)
    const [sumInsured, setSumInsured] = useState([])
    const [selectedPolicyValue, setSelectedPolicyValue] = useState([]);
    const [objPolicyType, setObjPolicyType] = useState([]);
    const [objCompanyName, setObjCompanyName] = useState([]);
    const [arrPolicyType, setArrPolicyType] = useState([]);
    const [arrCompanyName, setArrCompanyName] = useState([]);
    const [arrDepartment, setArrDepartment] = useState([]);
    const [objDepartment, setObjDepartment] = useState([]);
    const [arrSalesRm, setArrSalesRm] = useState([]);
    const [objSalesRM, setObjSalesRM] = useState([]);
    const [minProbability, setMinProbability] = useState(null);
    const [maxProbability, setMaxProbability] = useState(null);
    const [maxPremium, setMaxPremium] = useState(null);
    const [minPremium, setMinPremium] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedRmName, setSelectedRMName] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedClientType, setSelectedClientType] = useState(null);
    const [arrIndustrialSegment, setArrIndustrialSegment] = useState([]);
    const [arrCategory, setArrCategory] = useState([]);
    const [arrClientType, setArrClientType] = useState([]);
    const [objIndustrialSegment, setObjIndustrialSegment] = useState();
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState([]);
    const [proposalPoolList, setProposalPoolList] = useState([])
    const [arrOpportunityType, setArrOpportunityType] = useState([])
    const [displaySelectedFormatBtn, setDisplaySelectedFormatBtn] = useState(null);
    const [fromDate, setFromDate] = useState()
    const [minPremiumInWords, setMinPremiumInWords] = useState(0);
    const [maxPremiumInWords, setMaxPremiumInWords] = useState(0);
    const watchMinPremium = watch('min_expected_premium');
    const watchMaxPremium = watch('max_expected_premium');
    const [toDate, setToDate] = useState();
    const [isMyData, setMyData] = useState(null);
    const [dateType, setDateType] = useState(null);
    const [isWithTeam, setIsWithTeam] = useState(null);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [proposalType, setProposalType] = useState(props?.location?.state?.type === 'UNASSIGNED_PROPOSAL' ? 'unassigned' : 'all');

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            if (props?.isResetClicked != null && props?.isResetClicked === true) {
                setSelectedPolicyValue([]);
                setObjSalesRM([]);
                setObjCompanyName([]);
                setObjDepartment([])
                setObjPolicyType([])
                setSelectedClientType(null)
                setSelectedCategory(null)
                setSelectedClientType(null)
                setObjIndustrialSegment()
                setValue('min_probability', null);
                setValue('max_probability', null);
                setValue('max_expected_premium', null);
                setValue('min_expected_premium', null);
                setValue('city_state_master_id', null);
                setSelectedBranch([])
                setValue('opportunity_type', null);
                setDateType(null)
                setValue('category', null);
                setValue('client_type', null);
                setValue('date_type', null);
                setValue('policy_type_ids', null);
                setSelectedPolicy([]);
                setMaxProbability(null);
                setMinProbability(null);
                setMinPremium(null);
                setMaxPremium(null);
                setValue('department_ids', null);
                setSelectedDepartment([])
                setValue('assigned_rm_ids', null);
                setSelectedRMName([])
                setDisplaySelectedFormatBtn(null);
                setValue('industrial_segment_id', null);
            }
            if (props?.details != null) {

                setProposalPoolList(props?.details)
            }
            setMyTeamMembers(props?.myTeamMembers)
            setMyData(props?.isMyData)
            setIsWithTeam(props?.isWithTeam)
            setProposalType(props?.proposalType)
            setFromDate(props?.fromDate)
            setToDate(props?.toDate)
            setDateType(props?.dateType)
            const updatedUserIds = [...props?.myTeamMembers, loginId];
            setSelectedCheckUserId(updatedUserIds)
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };
    useEffect(initialRender, [props.show]);

    const onProposalPoolUpdate = () => {

        const { ProposalPool } = props;

        if (ProposalPool != null) {

            var ProposalPoolResponse = Object.assign({}, ProposalPool);

            if (ProposalPoolResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setProposalPoolList(ProposalPoolResponse.response);
                setProposalPoolListCount(ProposalPoolResponse.response.length);
            } else {
                $("#progress-loader").addClass("hidden");
                setProposalPoolList([])
                switch (ProposalPoolResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout()
                        break;
                    case ERROR:
                        setProposalPoolList(0)
                        toast.error(ProposalPoolResponse.message);
                    default:
                }
            }
        }
    }
    useEffect(onProposalPoolUpdate, [props.ProposalPool]);

    useEffect(() => {
        if (props != null && props?.isResetClicked != true) {
            setValue('opportunity_type', props.opportunityTypeValue);
            setValue('department_ids', props.departmentIdValue);
            setValue('policy_type_ids', props.policyTypeValue);
            setValue('min_expected_premium', props.expectedMinValue);
            setValue('max_expected_premium', props.expectedMaxValue);
            setValue('client_type', props.clientTypeValue);
            // setValue('department_ids', props.location.state?.policyValue);
            setValue('category', props.categoryValue);
            setValue('industrial_segment_id', props.industrialValue);
            setValue('min_probability', props.minProbabilityValue);
            setValue('max_probability', props.maxProbabilityValue);
        }
    }, [])

    useEffect(() => {
        var arrPolicyData = [];
        var uniquePolicyTypes = new Set();
        proposalPoolList.forEach((element) => {
            if (!uniquePolicyTypes.has(element.policy_type_id)) {
                var selected = objPolicyType != null ? objPolicyType.includes(element.policy_type) : false;
                arrPolicyData.push({ value: element.policy_type_id, title: element.policy_type, selected: selected });
                uniquePolicyTypes.add(element.policy_type_id);
            } else {
            }
        });
        arrPolicyData.sort((a, b) => a?.title?.localeCompare(b?.title));
        setArrPolicyType(arrPolicyData);

        var arrDepartment = [];
        var uniqueDepartmentType = new Set();
        proposalPoolList.forEach((element) => {
            if (!uniqueDepartmentType.has(element.department_id)) {
                var selected = objDepartment != null ? objDepartment.includes(element.department_name) : false;
                arrDepartment.push({ value: element.department_id, title: element.department_name, selected: selected });
                uniqueDepartmentType.add(element.department_id);
            } else {
            }
        });
        arrDepartment.sort((a, b) => a?.title?.localeCompare(b?.title));
        setArrDepartment(arrDepartment);

        var uniqueIndustrialSegment = new Set();
        var arrIndustrialSegment = [];

        proposalPoolList.forEach((element) => {
            if (element.industrial_segment_id !== null && !uniqueIndustrialSegment.has(element.industrial_segment_id)) {
                var selected = objIndustrialSegment === element.segment_name;
                arrIndustrialSegment.push({ value: element.industrial_segment_id, title: element.segment_name, selected: selected });
                uniqueIndustrialSegment.add(element.industrial_segment_id);
            }
        });

        arrIndustrialSegment.sort((a, b) => a?.title?.localeCompare(b?.title));
        setArrIndustrialSegment(arrIndustrialSegment);

        var uniqueOpportunityType = new Set();
        var arrOpportunityType = [];

        proposalPoolList.forEach((element) => {
            if (element.oppurtunity_type !== null && !uniqueOpportunityType.has(element.oppurtunity_type)) {
                var selected = selectedPolicyValue === element.oppurtunity_type;
                arrOpportunityType.push({ value: element.oppurtunity_type, title: element.oppurtunity_type, selected: selected });
                uniqueOpportunityType.add(element.oppurtunity_type);
            }
        });

        arrOpportunityType = Array.from(uniqueOpportunityType).map(opportunityType => ({
            value: opportunityType,
            title: opportunityType,
            selected: selectedPolicyValue === opportunityType
        })).sort((a, b) => a?.title?.localeCompare(b?.title));

        setArrOpportunityType(arrOpportunityType);

        var uniqueCategory = new Set();
        var arrCategory = [];

        proposalPoolList.forEach((element) => {
            if (element.category !== null && !uniqueCategory.has(element.category)) {
                arrCategory.push({ value: element.category, title: element.category });
                uniqueCategory.add(element.category);
            }
        });

        arrCategory.sort((a, b) => a?.title?.localeCompare(b?.title));
        setArrCategory(arrCategory);

        var uniqueClientType = new Set();
        var arrClientType = [];

        proposalPoolList.forEach((element) => {
            if (element.client_type !== null && !uniqueClientType.has(element.client_type)) {
                const titleCase = element.client_type
                    .toLowerCase()
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                arrClientType.push({ value: element.client_type, title: titleCase });
                uniqueClientType.add(element.client_type);
            }
        });

        arrClientType.sort((a, b) => a?.title?.localeCompare(b?.title));
        setArrClientType(arrClientType);


    }, [proposalPoolList])

    useEffect(() => {
        if (watchMinPremium != null) {
            const isNumeric = /^[0-9]*$/.test(watchMinPremium);
            if (isNumeric) {
                const numericValue = parseFloat(watchMinPremium || '0');
                const toWords = new ToWords();
                const words = toWords.convert(numericValue);
                setMinPremiumInWords(words);
            }
        } else {
            setMinPremiumInWords('');
        }
    }, [watchMinPremium])

    useEffect(() => {
        if (watchMaxPremium != null) {
            const isNumeric = /^[0-9]*$/.test(watchMaxPremium);
            if (isNumeric) {
                const numericValue = parseFloat(watchMaxPremium || '0');
                const toWords = new ToWords();
                const words = toWords.convert(numericValue);
                setMaxPremiumInWords(words);
            }
        } else {
            setMaxPremiumInWords('');
        }
    }, [watchMaxPremium])




    const onPremiumPolicyUpdate = () => {
        const { PremiumPolicy } = props;
        if (PremiumPolicy != null) {
            var PremiumPolicyResponse = Object.assign({}, PremiumPolicy);
            if (PremiumPolicyResponse.result === true) {
                setObjPremium(PremiumPolicyResponse.response)
            } else {
                switch (PremiumPolicyResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onPremiumPolicyUpdate, [props.PremiumPolicy]);

    const onSubmit = () => {


    }

    return (
        <div
            className="modal fade show no-scroll"
            id="opportunity_filter_popup"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Opportunity Filter
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                // reset();
                                props.handleClose()
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="px-1">
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group cust-tagbox mb-0">
                                                <label className="label-control">Opportunity Type&nbsp;</label>
                                                <Controller
                                                    name="opportunity_type"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: arrOpportunityType,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="title"
                                                            valueExpr="value"
                                                            placeholder="Select Type of Opportunity"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            acceptCustomValue={false}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedPolicyValue}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedPolicyValue(value);
                                                                    field.onChange(value);
                                                                } else {
                                                                    if (selectedPolicyValue != null && selectedPolicyValue.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedPolicyValue([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedPolicyValue([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="form-group cust-tagbox mb-0">
                                                <label className="label-control">Department&nbsp;</label>
                                                <Controller
                                                    name="department_ids"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: arrDepartment,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="title"
                                                            valueExpr="value"
                                                            placeholder="Select Department"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            acceptCustomValue={false}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedDepartment}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedDepartment(value);
                                                                    setObjDepartment(value)
                                                                    field.onChange(value);
                                                                } else {
                                                                    if (selectedDepartment != null && selectedDepartment.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedDepartment([])
                                                                        setObjDepartment([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedDepartment([])
                                                                        setObjDepartment([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group cust-tagbox mb-0">
                                                <label className="label-control">Policy Type&nbsp;</label>
                                                <Controller
                                                    name="policy_type_ids"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: arrPolicyType,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    console.log('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    console.log('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    console.log('showing')
                                                                },
                                                                onShown: () => {
                                                                    console.log('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="title"
                                                            valueExpr="value"
                                                            placeholder="Select Policy Type"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            selectAllMode={true}
                                                            showSelectionControls={true}
                                                            acceptCustomValue={false}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value != null && field.value.length > 0 ? field.value : selectedPolicy}
                                                            onValueChanged={({ value, event }) => {
                                                                if ((value != null || value !== undefined) && value.length > 0) {
                                                                    setSelectedPolicy(value);
                                                                    setObjPolicyType(value)
                                                                    field.onChange(value);
                                                                } else {
                                                                    if (selectedPolicy != null && selectedPolicy.length > 0) {
                                                                        field.onChange("");
                                                                        setSelectedPolicy([])
                                                                        setObjPolicyType([])
                                                                    }
                                                                }
                                                                if (event != null) {
                                                                    if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                        field.onChange("");
                                                                        setSelectedPolicy([])
                                                                        setObjPolicyType([])
                                                                    }
                                                                }
                                                            }}
                                                            onSelectionChanged={(e) => {
                                                                if (e.addedItems.length > 0) {
                                                                    e.component.field().value = "";
                                                                    e.component.close();
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mt-1">
                                            <label className="label-control mb-0">Probability Range&nbsp;</label>
                                            <div className="d-flex">
                                                <input
                                                    className="form-control mr-1"
                                                    id="min_probability"
                                                    name="min_probability"
                                                    onChange={(e) => setMinProbability(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        const value = event.target.value;
                                                        const newValue = value + event.key;
                                                        if (!/^\d+(\.\d{0,2})?$/.test(newValue)) {
                                                            event.preventDefault();
                                                        } else {
                                                            setMinProbability(newValue);
                                                        }
                                                    }}
                                                    placeholder="Min"
                                                    {...register('min_probability')}
                                                />
                                                <input
                                                    className="form-control"
                                                    id="max_probability"
                                                    name="max_probability"
                                                    onChange={(e) => setMaxProbability(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        const value = event.target.value;
                                                        const newValue = value + event.key;
                                                        if (!/^\d+(\.\d{0,2})?$/.test(newValue)) {
                                                            event.preventDefault();
                                                        } else {
                                                            setMaxProbability(newValue);
                                                        }
                                                    }}
                                                    placeholder="Max"
                                                    {...register('max_probability')}
                                                />
                                                {errors.min_probability && <ErrorLabel message={errors.min_probability?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 mt-1">
                                            <div className="form-group">
                                                <label className="label-control pl-0 mb-0">Customer Type&nbsp;</label>
                                                <Controller
                                                    name="client_type"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="client_type"
                                                            name="client_type"
                                                            dataSource={arrClientType}
                                                            allowFiltering={false}
                                                            placeholder="Select Customer Type"
                                                            value={field.value}
                                                            fields={{ text: 'title', value: 'value' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.value)
                                                                    setSelectedClientType(event.itemData.value)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                                {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 mt-1">
                                            <div className="form-group">
                                                <label className="label-control pl-0 mb-0">Customer Category&nbsp;</label>
                                                <Controller
                                                    name="category"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="category"
                                                            name="category"
                                                            dataSource={arrCategory}
                                                            allowFiltering={false}
                                                            placeholder="Select Customer Category"
                                                            value={field.value}
                                                            fields={{ text: 'title', value: 'value' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.name)
                                                                    setSelectedCategory(event.itemData.name)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                                {errors.category && <ErrorLabel message={errors.category?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group mt-0">
                                                <label className="label-control pl-0 mb-0">Industrial Segment&nbsp;</label>
                                                <Controller
                                                    name="industrial_segment_id"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <DropDownListComponent
                                                            {...field}
                                                            id="industrial_segment_id"
                                                            name="industrial_segment_id"
                                                            dataSource={arrIndustrialSegment}
                                                            allowFiltering={false}
                                                            placeholder="Select Industrial Segment"
                                                            value={field.value}
                                                            fields={{ text: 'title', value: 'value' }}
                                                            change={(event) => {
                                                                if (event.itemData != null) {
                                                                    field.onChange(event.itemData.value)
                                                                    setObjIndustrialSegment(event.itemData.value)
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                                {errors.industrial_segment_id && <ErrorLabel message={errors.industrial_segment_id?.message} />}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <label className="label-control mb-0">Premium Range&nbsp;</label>
                                            <div className="d-flex">
                                                <div className="pr-half">
                                                    <input
                                                        className="form-control mr-1"
                                                        id="min_expected_premium"
                                                        name="min_expected_premium"
                                                        onChange={(e) => setMinPremium(e.target.value)}
                                                        onKeyPress={(event) => {
                                                            const value = event.target.value;
                                                            const newValue = value + event.key;
                                                            if (!/^\d+(\.\d{0,2})?$/.test(newValue)) {
                                                                event.preventDefault();
                                                            } else {
                                                                setMinPremium(newValue);
                                                            }
                                                        }}
                                                        placeholder="Min"
                                                        {...register('min_expected_premium')}
                                                    />
                                                    <div className='font-small-2 text-success mt-half '>
                                                        {minPremiumInWords != 'Zero' ? minPremiumInWords : ''}
                                                    </div>
                                                </div>
                                                <div className="pl-half">
                                                    <input
                                                        className="form-control"
                                                        id="max_expected_premium"
                                                        name="max_expected_premium"
                                                        onChange={(e) => setMaxPremium(e.target.value)}
                                                        onKeyPress={(event) => {
                                                            const value = event.target.value;
                                                            const newValue = value + event.key;
                                                            if (!/^\d+(\.\d{0,2})?$/.test(newValue)) {
                                                                event.preventDefault();
                                                            } else {
                                                                setMaxPremium(newValue);
                                                            }
                                                        }}
                                                        placeholder="Max"
                                                        {...register('max_expected_premium')}
                                                    />

                                                    <div className='font-small-2 text-success mt-half'>
                                                        {maxPremiumInWords != 'Zero' ? maxPremiumInWords : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ml-0">
                                        <div className="col-4 col-md-auto pl-0">
                                            <div className="form-actions  border-0 float-none ml-0 mt-0 pt-0 ">
                                                <button
                                                    type='submit'
                                                    className="btn btn-primary btn-md w-60 shadow round"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        // $("#progress-loader").removeClass("hidden");
                                                        // if (props.type === 'create') {
                                                        //     props.actionDashboardPolicy({
                                                        //         "insurance_company": insuranceCompanyValue,
                                                        //         "policy_type": policyTypeValue,
                                                        //         "min_sum_insured": min,
                                                        //         "max_sum_insured": max,
                                                        //         "min_gross_premium": minGrossPremium,
                                                        //         "max_gross_premium": maxGrossPremium,
                                                        //         "department_id": props?.department,
                                                        //         "rm_id": props?.rm_id
                                                        //         // "from_date": fromDate,
                                                        //         // "to_date": toDate
                                                        //     })
                                                        // } else if (props.type === 'view') {
                                                        //     props.actionPolicyList({
                                                        //         "insurance_company": insuranceCompanyValue,
                                                        //         "policy_type": policyTypeValue,
                                                        //         "min_sum_insured": min,
                                                        //         "max_sum_insured": max,
                                                        //         "min_gross_premium": minGrossPremium,
                                                        //         "max_gross_premium": maxGrossPremium,
                                                        //         "department_id": props?.department,
                                                        //         "rm_id": props?.rm_id,
                                                        //         "days_count": props?.days_count,
                                                        //         "title": props.title
                                                        //         // "from_date": fromDate,
                                                        //         // "to_date": toDate
                                                        //     });
                                                        // }

                                                        // var Filter = {};

                                                        // Filter.policy_type = policyTypeValue;
                                                        // Filter.insurance_company = insuranceCompanyValue;
                                                        // Filter.min_sum_insured = min;
                                                        // Filter.max_sum_insured = max;
                                                        // Filter.min_gross_premium = minGrossPremium;
                                                        // Filter.max_gross_premium = maxGrossPremium;
                                                        // Filter.sumInsured = sumInsured;
                                                        // Filter.premium = premium;
                                                        // props.handleClose(Filter);

                                                        // NEW
                                                        // event.preventDefault();
                                                        // const updatedUserIds = [...myTeamMembers, loginId];
                                                        // setSelectedCheckUserId(updatedUserIds)
                                                        // props.actionProposalPoolList({
                                                        //     from_date: fromDate,
                                                        //     to_date: toDate,
                                                        //     type: proposalType,
                                                        //     user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [loginId] : isWithTeam ? myTeamMembers : [loginId],
                                                        //     date_type: dateType,
                                                        //     opportunity_type: selectedPolicyValue,
                                                        //     city_state_master_id: objCompanyName,
                                                        //     assigned_rm_ids: objSalesRM,
                                                        //     department_ids: objDepartment,
                                                        //     policy_type_ids: objPolicyType,
                                                        //     min_expected_premium: minPremium,
                                                        //     max_expected_premium: maxPremium,
                                                        //     min_probability: minProbability,
                                                        //     max_probability: maxProbability,
                                                        //     client_type: selectedClientType,
                                                        //     category: selectedCategory,
                                                        //     industrial_segment_id: objIndustrialSegment
                                                        // })
                                                        var newPolicy = {};

                                                        newPolicy.from_date = fromDate;
                                                        newPolicy.to_date = toDate;
                                                        newPolicy.opportunity_type = selectedPolicyValue;
                                                        newPolicy.department_ids = selectedDepartment;
                                                        newPolicy.policy_type_ids = selectedPolicy;
                                                        newPolicy.min_expected_premium = Number(minPremium);
                                                        newPolicy.max_expected_premium = Number(maxPremium);
                                                        newPolicy.min_probability = Number(minProbability);
                                                        newPolicy.max_probability = Number(maxProbability);
                                                        newPolicy.client_type = selectedClientType;
                                                        newPolicy.category = selectedCategory;
                                                        newPolicy.industrial_segment_id = objIndustrialSegment;
                                                        props.handleClose(newPolicy);
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        Apply
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-auto">
                                            <div className="form-actions border-0 float-none ml-0 mt-0 pt-0">
                                                <button
                                                    id="policy-reset-popup"
                                                    type='button'
                                                    className="btn btn-primary  btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        setSelectedPolicyValue([]);
                                                        setObjSalesRM([]);
                                                        setObjCompanyName([]);
                                                        setObjDepartment([])
                                                        setObjPolicyType([])
                                                        setSelectedClientType(null)
                                                        setSelectedCategory(null)
                                                        setSelectedClientType(null)
                                                        setObjIndustrialSegment()
                                                        setValue('min_probability', null);
                                                        setValue('max_probability', null);
                                                        setValue('max_expected_premium', null);
                                                        setValue('min_expected_premium', null);
                                                        setValue('city_state_master_id', null);
                                                        setSelectedBranch([])
                                                        setValue('opportunity_type', null);
                                                        setDateType(null)
                                                        setValue('category', null);
                                                        setValue('client_type', null);
                                                        setValue('date_type', null);
                                                        setValue('policy_type_ids', null);
                                                        setSelectedPolicy([]);
                                                        setMaxProbability(null);
                                                        setMinProbability(null);
                                                        setMinPremium(null);
                                                        setMaxPremium(null);
                                                        setValue('department_ids', null);
                                                        setSelectedDepartment([])
                                                        setValue('assigned_rm_ids', null);
                                                        setSelectedRMName([])
                                                        setDisplaySelectedFormatBtn(null);
                                                        setValue('industrial_segment_id', null);
                                                        var newPolicy = {};
                                                        newPolicy.opportunity_type = selectedPolicyValue;
                                                        newPolicy.department_ids = selectedDepartment;
                                                        newPolicy.policy_type_ids = selectedPolicy;
                                                        newPolicy.min_expected_premium = Number(minPremium);
                                                        newPolicy.max_expected_premium = Number(maxPremium);
                                                        newPolicy.min_probability = Number(minProbability);
                                                        newPolicy.max_probability = Number(maxProbability);
                                                        newPolicy.client_type = selectedClientType;
                                                        newPolicy.category = selectedCategory;
                                                        newPolicy.industrial_segment_id = objIndustrialSegment;
                                                        // props.handleClose();
                                                        const updatedUserIds = [...myTeamMembers, loginId];
                                                        setSelectedCheckUserId(updatedUserIds)

                                                    }
                                                    }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Reset
                                                        <span id="policy-reset-popup-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )

}
const mapStateToProps = (state) => {
    var ProposalPool = null;


    if (
        state.ProposalPoolFilterReducer != null &&
        Object.keys(state.ProposalPoolFilterReducer).length > 0 &&
        state.ProposalPoolFilterReducer.constructor === Object
    ) {
        switch (state.ProposalPoolFilterReducer.tag) {
            case ACTION_PROPOSAL_POOL_LIST_SUCCESS:
                ProposalPool = Object.assign({}, state.ProposalPoolListReducer.data);
                delete state.ProposalPoolListReducer;
                return { ProposalPool };
            default:
        }
    }
    return {
        ProposalPool
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionProposalPoolList
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(OpportunityFilter))
// export default OpportunityFilter
