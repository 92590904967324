import { ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS } from "./action";
import { ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS, ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS } from './action'

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ReimbursementViewReducer = createReducer(INITIAL_STATE, {
    [ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS,
                loading: false,
            }
        );
    }
});

export default ReimbursementViewReducer;