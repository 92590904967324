import { ACTION_WEEKLY_MEETINGS_SUCCESS, ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS, ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS} from "./action";
import createReducer from "../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const WeeklyMeetingReducer = createReducer(INITIAL_STATE, {
    [ACTION_WEEKLY_MEETINGS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_WEEKLY_MEETINGS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MEETING_WEEKLY_TRAININGS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TEAM_ON_LEAVE_WIDGET_SUCCESS,
                loading: false,
            }
        );
    },
});
export default WeeklyMeetingReducer;