import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit } from 'react-icons/fi';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { PRIMARY_COLOR, UNAUTHORIZED, ACTIVE, INACTIVE } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { verifyPermission } from '../../../../../../utils';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { actionTeamMasterStatus, ACTION_TEAM_MASTER_STATUS_SUCCESS } from './action';
import { actionTeamMasterList, ACTION_TEAM_MASTER_LIST_SUCCESS } from './action';

const TeamMaster = (props) => {

    const { match } = props;

    const [teamMasterList, setTeamMasterList] = useState([]);

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
        props.actionTeamMasterList({});
    }
    useEffect(initialRender, []);

    const [columns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "team_name", caption: "Team Name" },
        { dataField: "branch_name", caption: "Branch Name" },
        { dataField: "team_head", caption: "Team Head" },
        { dataField: "status", caption: "Status" },
    ]);

    const onTeamMasterStatusUpdate = () => {
        const { TeamMasterStatus } = props;
        var TeamMasterStatusResponse = Object.assign({}, TeamMasterStatus);
        if (TeamMasterStatusResponse.result === true) {
            props.actionTeamMasterList();
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").removeClass("hidden");
            switch (TeamMasterStatusResponse.status) {
                case UNAUTHORIZED:
                    break;
                default:
            }
        }
    }
    useEffect(onTeamMasterStatusUpdate, [props.TeamMasterStatus])

    const onTeamMasterListListUpdate = () => {
        const { TeamMasterList } = props;
        var TeamMasterListResponse = Object.assign({}, TeamMasterList);
        if (TeamMasterListResponse.result === true) {
            setTeamMasterList(TeamMasterListResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (TeamMasterListResponse.status) {
                case UNAUTHORIZED:
                    break;
                default:
            }
        }
    }
    useEffect(onTeamMasterListListUpdate, [props.TeamMasterList])

    const renderStatusCell = (cell) => {
        switch (cell.data.status) {
            case 'active':
                return <div className={"badge badge-success round px-1 cursor-pointer"} onClick={() => {
                    Swal.fire({
                        title: `Inactivate Team`,
                        text: `Are you sure, you want to inactivate ?`,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: PRIMARY_COLOR,
                    }).then(success => {
                        if (success.isConfirmed) {
                            $("#progress-loader").removeClass("hidden");
                            props.actionTeamMasterStatus({
                                id: cell.data.id,
                                status: INACTIVE
                            })
                        }
                    });
                }}>Active</div>;
            case 'inactive':
                return <div className={"badge badge-danger round px-1 cursor-pointer"} onClick={() => {
                    Swal.fire({
                        title: `Activate Team`,
                        text: `Are you sure, you want to activate ?`,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: PRIMARY_COLOR,
                    }).then(success => {
                        if (success.isConfirmed) {
                            props.actionTeamMasterStatus({
                                id: cell.data.id,
                                status: ACTIVE
                            })
                        }
                    });
                }}>Inactive</div>;
            default:
                return <></>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("EDIT_TEAM") ?
                            <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />
                            :
                            <></>
                        }
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                        ></Column>
                    );
                }

            }
        });
        return arrColumns;
    };

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0 ">Team Master {teamMasterList.length === 0 ? '' : `(${teamMasterList.length})`}</h5>
                </div>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {
                            verifyPermission("CREATE_TEAM") ?
                            <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push('/settings/team-master/add');
                                }}
                            >
                                Create Team
                            </button>
                        </li>
                            :
                            <></>
                        }
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={teamMasterList}
                                columnHidingEnabled={false}
                                showBorders={true}
                                export={{
                                    fileName: "Team Master",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',
                                    })
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var TeamMasterStatus = null;
    var TeamMasterList = null;

    if (
        state.TeamMasterListReducer != null &&
        Object.keys(state.TeamMasterListReducer).length > 0 &&
        state.TeamMasterListReducer.constructor === Object
    ) {
        switch (state.TeamMasterListReducer.tag) {
            case ACTION_TEAM_MASTER_STATUS_SUCCESS:
                TeamMasterStatus = Object.assign({}, state.TeamMasterListReducer.data);
                delete state.TeamMasterListReducer;
                return { TeamMasterStatus };
            case ACTION_TEAM_MASTER_LIST_SUCCESS:
                TeamMasterList = Object.assign({}, state.TeamMasterListReducer.data);
                delete state.TeamMasterListReducer;
                return { TeamMasterList };
            default:
        }
    }

    return {
        TeamMasterStatus,
        TeamMasterList
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTeamMasterStatus, actionTeamMasterList }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TeamMaster))