import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { FiPlus, FiUser, FiPhone, FiTrash2, FiEdit, FiMail, FiShield, FiEye } from 'react-icons/fi';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import ContactDetails from '../../../../../component/form/contact_details';
import PolicyDetails from '../../../../../component/form/policy_details';
import Swal from 'sweetalert2';
import dateFormat from 'dateformat';
import { useForm, Controller } from "react-hook-form";
import ErrorLabel from '../../../../../component/form/error_label';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ScreenTitle from '../../../../../component/screen_title';
import { generateInitialLettersString } from "../../../../../utils";
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../constants';
import $ from "jquery";
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';
import { actionEditCompanyGroup, ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS } from "./action";
import { actionEditCustomerCategory, ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { actionEditIndustrialSegment, ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS } from "./action";
import { actionEditFormOfOrganization, ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS } from "./action";
import { actionEditLeadSource, ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS } from "./action";
import { actionEditAssignRM, ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS } from "./action";
import { actionEditStates, ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS } from "./action";
import { actionEditCities, ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS } from "./action";
import { actionGetClientDetails, ACTION_CLIENT_DETAILS_SUCCESS } from "./action";
import { actionGetClientEdit, ACTION_CLIENT_EDIT_SUCCESS } from "./action";
import { actionAssignRetentionEdit, ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS } from "./action";
import ProposalDetailModal from '../../../../../component/popups/proposal_details';
import moment from 'moment';

const ClientEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm({
        defaultValues: {
            multi_branch: 'Yes'
        }
    });

    const [clientRequiredFields, setClientRequiredFields] = useState(false);

    const [clientDetails, setClientDetails] = useState(null);
    const [contactDetails, setContactDetails] = useState([]);
    const [showContactDetails, setShowContactDetails] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [policyDetails, setPolicyDetails] = useState([]);
    const [showPolicyDetails, setShowPolicyDetails] = useState(false);
    const [assignRMId, setAssignRMId] = useState(null);
    const [companyGroup, setCompanyGroup] = useState([]);
    const [customerCategory, setCustomerCategory] = useState([]);
    const [masterStates, setMasterStates] = useState([]);
    const [masterCities, setMasterCities] = useState([]);
    const [industrialSegment, setIndustrialSegment] = useState([]);
    const [formOfOrganization, setFormOfOrganization] = useState([]);
    const [leadSource, setLeadSource] = useState({});
    const [assignRM, setAssignRM] = useState([]);
    const [assignRetention, setAssignRetention] = useState({});

    const [selectedContactIndex, setSelectedContactIndex] = useState(null);
    const [selectedPolicyIndex, setSelectedPolicyIndex] = useState(-1);
    const [showProposalDetails, setShowProposalDetails] = useState(false);

    const initialRender = () => {
        props.actionGetClientDetails({ id: props?.location?.state?.client_id });
    }
    useEffect(initialRender, []);

    const onMasterCompanyGroupUpdate = () => {
        const { CompanyGroup } = props;
        var CompanyGroupResponse = Object.assign({}, CompanyGroup);
        if (CompanyGroupResponse.result === true) {
            setCompanyGroup(CompanyGroupResponse.response);
        } else {
            switch (CompanyGroupResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterCompanyGroupUpdate, [props.CompanyGroup]);

    useEffect(() => {
        if (customerCategory != null && customerCategory.length > 0) {
            if (clientDetails != null) {
                setValue('category', clientDetails?.category)
            }
        }
    }, [customerCategory])

    const onMasterCustomerCategoryUpdate = () => {
        const { CustomerCategory } = props;
        var CustomerCategoryResponse = Object.assign({}, CustomerCategory);
        if (CustomerCategoryResponse.result === true) {
            setCustomerCategory(CustomerCategoryResponse.response);
        } else {
            switch (CustomerCategoryResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterCustomerCategoryUpdate, [props.CustomerCategory]);

    useEffect(() => {
        if (masterStates != null && masterStates.length > 0) {
            if (clientDetails != null) {
                setValue('state', clientDetails?.state_id);
            }
        }
    }, [masterStates])

    const onMasterStatesUpdate = () => {
        const { States } = props;
        var StatesResponse = Object.assign({}, States);
        if (StatesResponse.result === true) {
            setMasterStates(StatesResponse.response);
        } else {
            switch (StatesResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterStatesUpdate, [props.States]);

    useEffect(() => {
        if (masterCities != null && masterCities.length > 0) {
            if (clientDetails != null) {
                setValue('city_id', clientDetails?.city_id);
            }
        }
    }, [masterCities])

    const onMasterCitiesUpdate = () => {
        const { Cities } = props;
        var CitiesResponse = Object.assign({}, Cities);
        if (CitiesResponse.result === true) {
            setMasterCities(CitiesResponse.response);
        } else {
            switch (CitiesResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.Cities]);

    useEffect(() => {
        if (industrialSegment != null && industrialSegment.length > 0) {
            if (clientDetails != null) {
                setValue('industrial_segment_id', clientDetails?.industrial_segment_id);
            }
        }
    }, [industrialSegment])

    const onMasterIndustrialSegmentUpdate = () => {
        const { IndustrialSegment } = props;
        var IndustrialSegmentResponse = Object.assign({}, IndustrialSegment);
        if (IndustrialSegmentResponse.result === true) {
            setIndustrialSegment(IndustrialSegmentResponse.response);
        } else {
            switch (IndustrialSegmentResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterIndustrialSegmentUpdate, [props.IndustrialSegment]);

    useEffect(() => {
        if (formOfOrganization != null && formOfOrganization.length > 0) {
            if (clientDetails != null) {
                setValue('form_of_organization_id', clientDetails?.form_of_organization_id);
            }
        }
    }, [formOfOrganization])

    const onMasterFormofOrganizationUpdate = () => {
        const { FormofOrganization } = props;
        var FormofOrganizationResponse = Object.assign({}, FormofOrganization);
        if (FormofOrganizationResponse.result === true) {
            setFormOfOrganization(FormofOrganizationResponse.response);
        } else {
            switch (FormofOrganizationResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterFormofOrganizationUpdate, [props.FormofOrganization]);

    useEffect(() => {
        if (leadSource != null && leadSource.length > 0) {
            if (clientDetails != null) {
                setValue('lead_source_id', clientDetails?.lead_source_id);
            }
        }
    }, [leadSource])

    const onMasterLeadSourceUpdate = () => {
        const { LeadSource } = props;
        var LeadSourceResponse = Object.assign({}, LeadSource);
        if (LeadSourceResponse.result === true) {
            setLeadSource(LeadSourceResponse.response);
        } else {
            switch (LeadSourceResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterLeadSourceUpdate, [props.LeadSource]);

    useEffect(() => {
        if (assignRM != null) {
            if (clientDetails != null && clientDetails.created_by != null) {
                setValue('created_by', clientDetails?.created_by);
            }
        }
    }, [assignRM])

    const onMasterAssignRMUpdate = () => {
        const { AssignRM } = props;
        var AssignRMResponse = Object.assign({}, AssignRM);
        if (AssignRMResponse.result === true) {

            var arrAssignRM = Object.assign([], AssignRMResponse.response);

            if (props?.location?.state?.visible === false) {
                var tempUser = {
                    user_id: clientDetails?.rm_details?.user_id, full_name: clientDetails?.rm_details?.full_name
                }
                arrAssignRM.unshift(tempUser);

            }
            setAssignRM(arrAssignRM);

        } else {
            switch (AssignRMResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onMasterAssignRMUpdate, [props.AssignRM]);

    useEffect(() => {
        if (assignRetention != null && assignRetention.length > 0) {
            if (clientDetails != null) {
                setValue('retention_user_id', clientDetails?.retention_user_id);
            }
        }
    }, [assignRetention])

    const onAssignRetentionEditUpdate = () => {
        const { AssignRetentionEdit } = props;
        var AssignRetentionEditResponse = Object.assign({}, AssignRetentionEdit);
        if (AssignRetentionEditResponse.result === true) {
            var arrAssignRetention = Object.assign([], AssignRetentionEditResponse.response);
            setAssignRetention(arrAssignRetention);

        } else {
            switch (AssignRetentionEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onAssignRetentionEditUpdate, [props.AssignRetentionEdit]);

    const onClientDetailUpdate = () => {

        const { ClientDetails } = props;
        var ClientDetailsResponse = Object.assign({}, ClientDetails);

        if (ClientDetailsResponse.result === true) {

            setClientDetails(ClientDetailsResponse.response);

            setValue('name', ClientDetailsResponse.response.company_name);
            setValue('address', ClientDetailsResponse.response.address);
            setValue('pincode', ClientDetailsResponse.response.pincode);
            setValue('email', ClientDetailsResponse.response.email);
            setValue('mobile', ClientDetailsResponse.response.mobile);
            setValue('pan', ClientDetailsResponse.response.pan);
            setValue('landline', ClientDetailsResponse.response.landline);
            setValue('retention_user_id', ClientDetailsResponse.response.retention_user_id);
            setValue('website', ClientDetailsResponse.response.website);

            if (ClientDetailsResponse.response.primary_contact !== null) {
                setValue('contact_name', ClientDetailsResponse.response.primary_contact.contact_name);
                setValue('contact_designation', ClientDetailsResponse.response.primary_contact.contact_designation);
                setValue('contact_phone', ClientDetailsResponse.response.primary_contact.contact_phone);
                setValue('contact_email', ClientDetailsResponse.response.primary_contact.contact_email);
            }

            setValue('contact_details', ClientDetailsResponse.response.contact_details);
            setValue('policy_details', ClientDetailsResponse.response.policy_details);
            setValue('company_group_name', ClientDetailsResponse.response.company_group_name);
            setValue('multi_branch', ClientDetailsResponse.response.multi_branch === 'No' ? 0 : 1);
            setValue('category', ClientDetailsResponse.response.category);

            setAssignRMId(ClientDetailsResponse?.response?.rm_details?.user_id)
            setContactDetails(ClientDetailsResponse.response.contact_details);
            setPolicyDetails(ClientDetailsResponse.response.policy_details);

            props.actionEditCompanyGroup();
            props.actionEditCustomerCategory();
            props.actionEditStates();
            props.actionEditIndustrialSegment();
            props.actionEditFormOfOrganization();
            props.actionEditLeadSource();
            props.actionEditAssignRM({ user_id: props.auth.getUser().user_id });
            props.actionAssignRetentionEdit({ user_id: props.auth.getUser().id });

        } else {
            switch (ClientDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientDetailUpdate, [props.ClientDetails]);

    const onClientEditUpdate = () => {
        const { ClientEdit } = props;
        var ClientEditResponse = Object.assign({}, ClientEdit);

        $("#save-as-lead").prop("disabled", false);
        $("#convert-to-customer").prop("disabled", false);

        if (ClientEditResponse.result === true) {

            if (getValues('clientOrLead') === 'lead') {
                // $("#progress-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Lead Updated Successfully");
                props.history.push('/leads');
            } else {
                // $("#progress-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Client Updated Successfully");
                props.history.push('/client');
            }

            $(".spinner-border").addClass("hidden");

        } else {
            $(".spinner-border").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (ClientEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientEditUpdate, [props.ClientEdit]);

    const onSubmit = (data) => {
        if (data.clientOrLead == 'client' && getValues('created_by') == 0) {
            Swal.fire({
                title: 'Message',
                text: 'Please select the RM to Convert to Customer',
                confirmButtonColor: PRIMARY_COLOR,
            }).then(success => {

            })
        } else {
            data.id = props?.location?.state?.client_id;
            data.created_by = data.created_by != null ? data.created_by : clientDetails?.created_by;
            data.email = data.email != null ? data.email : clientDetails?.email;
            data.vertical = clientDetails.vertical;
            data.client_of = clientDetails.client_of;
            data.primary_contact = {
                id: clientDetails.primary_contact !== null ? clientDetails.primary_contact.id : null,
                contact_name: data.contact_name != null ? data.contact_name : clientDetails.primary_contact.contact_name,
                contact_designation: data.contact_designation != null ? data.contact_designation : clientDetails.primary_contact.contact_designation,
                contact_phone: data.contact_phone != null ? data.contact_phone : clientDetails.primary_contact.contact_phone,
                contact_email: data.contact_email != null ? data.contact_email : clientDetails.primary_contact.contact_email
            };


            data.contact_details = contactDetails;
            var arrTemp = [];
            policyDetails.map((item) => {
                arrTemp.push({
                    department_id: item.department_id,
                    policy_type_id: item.policy_type_id,
                    insurer_id: item.oppurtunity_type != 'Fresh' ? item.insurer_id : '',
                    brokerage_amnt: item.brokerage_amnt,
                    product_specialist_id: item.product_specialist_id,
                    is_product_specialist_required: item.is_product_specialist_required,
                    expected_premium: item.expected_premium,
                    probability: item.probability,
                    // expiring_date: item.oppurtunity_type != 'Fresh' ? moment(item.expiring_date).format("YYYY-MM-DD") : null,
                    expiring_date: moment(item?.expiring_date).format("YYYY-MM-DD"),
                    client_remark: item.client_remark,
                    oppurtunity_type: item.oppurtunity_type,
                    intermediary_type: item.oppurtunity_type != 'Fresh' ? item.intermediary_type : '',
                    intermediary_value: item.oppurtunity_type != 'Fresh' ? item.intermediary_value : '',
                    opportunity_questions: item.opportunity_questions,
                    id: item.id
                })
            })
            data.policy_details = arrTemp;
            if (Number(data.created_by) === 0) {
                data.created_by = "";
            }
            /**
             * API : /api/client/edit
             * METHOD : POST
             * Description : Edit Client Record 
             * INPUT : Client Object 
             */
            //  $("#progress-loader").removeClass("hidden");

            if (data.clientOrLead == 'lead') {
                $("#save-as-lead-loader").removeClass("hidden");
                $("#save-as-lead").prop("disabled", true);
            } else {
                $("#convert-to-customer-loader").removeClass("hidden");
                $("#convert-to-customer").prop("disabled", true);

                $("#submit-form-loader").removeClass("hidden");
                $("#submit-form").prop("disabled", true);
            }
            props.actionGetClientEdit(data);
        }
    }

    const renderContactDetails = () => {
        var arrContactDetails = []
        if (contactDetails != null && contactDetails.length > 0) {
            contactDetails.forEach((contact, indexContact) => {
                arrContactDetails.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row">
                                <div className="flex-column mr-half">
                                    <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                                        {generateInitialLettersString(contact.contact_name)}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">
                                        {contact.contact_name}
                                    </p>
                                    <div class="font-small-3 text-bold-700">
                                        {contact.contact_designation}
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone === null || contact.contact_phone === "" ? 'hidden' : ''}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone === null || contact.contact_phone === "" ? 'hidden' : ''}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email === null || contact.contact_email === "" ? 'hidden' : ''}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column ${contact.contact_email === null || contact.contact_email === "" ? 'hidden' : ''}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="float-right">
                            <button
                                type="button"
                                class="btn pt-0"
                                onClick={() => {
                                    setSelectedContactIndex(indexContact)
                                    setShowContactDetails(true)
                                    setShowContact(true);
                                }}
                            >
                                <FiEdit size={16} />
                            </button>
                            <button type="button" className={`btn pt-0 ${contact.hasOwnProperty('id') ? 'hidden' : ''}`}
                                onClick={() => {
                                    Swal.fire({
                                        icon: 'warning',
                                        text: 'Are you sure, you want to delete this contact?',
                                        cancelButtonText: "Cancel",
                                        showCancelButton: true,
                                    }).then(({ isConfirmed }) => {
                                        if (isConfirmed) {
                                            var arrContactDetails = Object.assign([], contactDetails)
                                            arrContactDetails.splice(indexContact, 1)
                                            setContactDetails(arrContactDetails)
                                        }
                                    })
                                }}
                            >
                                <FiTrash2 size={16} />
                            </button>
                        </div>
                        <div className="divider"></div>
                    </div>
                )
            })
        }
        return arrContactDetails
    }

    const renderPolicyDetails = () => {
        var arrPolicyDetails = []
        if (policyDetails != null && policyDetails.length > 0) {
            policyDetails.forEach((policy, indexPolicy) => {
                arrPolicyDetails.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row pb-1">
                                <div className="flex-column mr-half">
                                    {
                                        (policy.insurer_id != null && Number(policy.insurer_id) != 0 && policy.oppurtunity_type != 'Fresh') ?
                                            <img src={`/assets/images/insurance_companies/${policy.insurer_id}.png`} className="rounded-circle" style={{ width: 50, height: 50, objectFit: 'cover' }} alt="" />
                                            :
                                            <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-3" style={{ width: 50, height: 50 }}>
                                                <IoShieldCheckmarkOutline size={30} />
                                            </div>
                                    }

                                </div>
                                <div className="flex-column">
                                    <p className="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{policy.oppurtunity_type != 'Fresh' ? policy.insurer_name : ''}<div className="badge badge-success bg-accent-1 round font-small-2 px-1 ml-half">{policy.policy_type}</div></p>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Opportunity Type: </span>{policy.oppurtunity_type}</div>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Department: </span>{policy.department_name}</div>
                                    <div className={`font-small-3 text-bold-700 ${policy.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}><span className="text-secondary text-bold-500 mr-half">Intermediary Type: </span>{policy.intermediary_type}</div>
                                    <div className={`font-small-3 text-bold-700 ${policy.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}><span className="text-secondary text-bold-500 mr-half">Intermediary Details: </span>{policy.intermediary_value}</div>
                                    <div className={`font-small-3 text-bold-700  ${policy.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}><span className={`text-secondary text-bold-500 mr-half`}>Next Renewal on</span>{policy.lead_expiry_date !== null && policy.lead_expiry_date != "0000-00-00 00:00:00" && moment(policy.lead_expiry_date).isValid() ? moment(policy.lead_expiry_date).format("MMM, DD YYYY") : '-'}</div>
                                    <div className={`font-small-3 text-bold-700 ${policy.client_remark != null && policy.client_remark != "" ? "" : "hidden"}`}><span className="text-secondary text-bold-500 mr-half">Remark: </span>{policy.client_remark}</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-flex d-lg-flex float-right">
                            <button
                                type="button"
                                class="btn pt-0"
                                onClick={() => {
                                    setSelectedPolicyIndex(indexPolicy)
                                    setShowProposalDetails(true)
                                }}
                            >
                                <FiEye size={16} />
                            </button>
                            <button type="button" className={`btn pt-0 ${policy.hasOwnProperty('id') ? 'hidden' : ''}`}
                                onClick={() => {
                                    setSelectedPolicyIndex(indexPolicy)
                                    setShowPolicyDetails(true)
                                }}
                            >
                                <FiEdit size={16} />
                            </button>
                            <button type="button" className={`btn pt-0 ${policy.hasOwnProperty('id') ? 'hidden' : ''}`}
                                onClick={() => {
                                    Swal.fire({
                                        icon: 'warning',
                                        text: 'Are you sure, you want to delete this policy details?',
                                        cancelButtonText: "Cancel",
                                        showCancelButton: true,
                                    }).then(({ isConfirmed }) => {
                                        if (isConfirmed) {
                                            var arrPolicyDetails = Object.assign([], policyDetails)
                                            arrPolicyDetails.splice(indexPolicy, 1)
                                            setPolicyDetails(arrPolicyDetails)
                                        }
                                    })
                                }}
                            >
                                <FiTrash2 size={16} />
                            </button>
                        </div>
                        <div className="divider"></div>
                    </div>
                )
            })
        }
        return arrPolicyDetails
    }

    return (
        <>
            <ScreenTitle title="Edit Client" />
            <div className="card">
                <div className="card-body">
                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section my-2 black">
                                        <HiOutlineOfficeBuilding size={16} />&nbsp;Company Information
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Company Group Name&nbsp;<span className="danger">*</span></label>
                                        <Controller
                                            name="company_group_name"
                                            control={control}
                                            render={({ field }) =>
                                                <AutoCompleteComponent
                                                    {...field}
                                                    dataSource={companyGroup}
                                                    fields={{ text: 'name' }}
                                                    allowFiltering={true}
                                                    placeholder="Select Company Group Name"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.name)
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: props?.location?.state?.visible === true ? 'Please Select Company Group Name' : false
                                            }}
                                        />
                                        {errors.company_group_name && <ErrorLabel message={errors.company_group_name?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <fieldset className="form-group">
                                        <label className="label-control">Company Name&nbsp;<span className="danger">*</span></label>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) =>
                                                <input
                                                    {...field}
                                                    className="form-control"
                                                    placeholder="Enter Company Name"
                                                    value={field.value}
                                                    disabled={props?.location?.state?.visible === true ? false : true}
                                                    onChange={(event) => field.onChange(event.target.value)}
                                                />
                                            }
                                            rules={{
                                                required: props?.location?.state?.visible === true ? 'Please Select Company Name' : false
                                            }}
                                        />
                                        {errors.name && <ErrorLabel message={errors.name?.message} />}
                                    </fieldset>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label className="label-control">Address&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <textarea
                                            className="form-control"
                                            id="address"
                                            name="address"
                                            placeholder="Enter Address"
                                            rows="3"
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            {...register('address', { required: clientRequiredFields ? 'Please Enter Address' : false })}
                                        />
                                        {errors.address && <ErrorLabel message={errors.address?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Customer Category&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <Controller
                                            name="category"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={customerCategory}
                                                    fields={{ text: 'title', value: 'value' }}
                                                    allowFiltering={true}
                                                    placeholder="Select Customer Category"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.value)
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: clientRequiredFields || props?.location?.state?.visible === true ? 'Please Select Customer Category' : false
                                            }}
                                        />
                                        {errors.category && <ErrorLabel message={errors.category?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Email ID&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Enter Email ID"
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            {...register('email', {
                                                required: clientRequiredFields ? 'Please Enter Email' : false,
                                                pattern: {
                                                    value: /^(?!.*@.*pincinsure)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: 'Please enter valid Email Id'
                                                },
                                            }
                                            )}
                                        />
                                        {errors.email && <ErrorLabel message={errors.email?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">State&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <Controller
                                            name="state"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={masterStates}
                                                    fields={{ text: 'name', value: 'id' }}
                                                    allowFiltering={false}
                                                    placeholder="Select State"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.id)
                                                            props.actionEditCities({ state_ids: [event.itemData.id] })
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: clientRequiredFields ? 'Please Select State' : false
                                            }}
                                        />
                                        {errors.state && <ErrorLabel message={errors.state?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">City&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <Controller
                                            name="city_id"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={masterCities}
                                                    fields={{ text: 'name', value: 'id' }}
                                                    allowFiltering={false}
                                                    placeholder="Select City"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.id)
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: clientRequiredFields ? 'Please Select City' : false
                                            }}
                                        />
                                        {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Pincode&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <input
                                            className="form-control"
                                            id="pincode"
                                            name="pincode"
                                            placeholder="Enter Pincode"
                                            maxLength={6}
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            {...register('pincode', {
                                                pattern: {
                                                    value: /^\d{6}$/,
                                                    message: 'Please enter 6 digit valid PIN Code'
                                                },
                                                required: clientRequiredFields ? 'Please Enter Pincode' : false
                                            })}
                                        />
                                        {errors.pincode && <ErrorLabel message={errors.pincode?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Mobile</label>
                                        <input
                                            className="form-control"
                                            id="mobile"
                                            name="mobile"
                                            placeholder="Enter Mobile Number"
                                            maxLength={10}
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            {...register('mobile', {
                                                pattern: {
                                                    value: /^[6789]\d{9}$/,
                                                    message: props?.location?.state?.visible === true ? 'Please enter 10 digit valid Mobile Number' : false
                                                },
                                                required: clientRequiredFields ? 'Please Enter Mobile Number' : false
                                            })}
                                        />
                                        {errors.mobile && <ErrorLabel message={errors.mobile?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Pan No</label>
                                        <input
                                            className="form-control"
                                            id="pan"
                                            name="pan"
                                            maxLength={10}
                                            placeholder="Enter pan No"
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            {...register('pan', {
                                                pattern: {
                                                    value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                                    message: 'Please Enter valid Pan No'
                                                },
                                            })}
                                        />
                                        {errors.pan && <ErrorLabel message={errors.pan?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Landline</label>
                                        <input
                                            className="form-control"
                                            id="landline"
                                            name="landline"
                                            maxLength={13}
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            placeholder="Enter Landline"
                                            {...register('landline')}
                                        />
                                        {errors.landline && <ErrorLabel message={errors.landline?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Industrial Segment&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <Controller
                                            name="industrial_segment_id"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={industrialSegment}
                                                    fields={{ text: 'name', value: 'id' }}
                                                    allowFiltering={true}
                                                    placeholder="Select Industrial Segment"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.id)
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: clientRequiredFields ? 'Please Select Industrial Segment' : false
                                            }}
                                        />
                                        {errors.industrial_segment_id && <ErrorLabel message={errors.industrial_segment_id?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Website</label>
                                        <input
                                            className="form-control"
                                            id="website"
                                            name="website"
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            placeholder="Enter Website"
                                            {...register('website', {
                                                pattern: {
                                                    value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                                                    message: props?.location?.state?.visible === true ? 'Please Enter valid Website' : false
                                                },
                                            })}
                                        />
                                        {errors.website && <ErrorLabel message={errors.website?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Form of Oraganization&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <Controller
                                            name="form_of_organization_id"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={formOfOrganization}
                                                    fields={{ text: 'name', value: 'id' }}
                                                    allowFiltering={true}
                                                    placeholder="Select Form of Oraganization"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.id)
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: clientRequiredFields ? 'Please Select Form of Oraganization' : false
                                            }}
                                        />
                                        {errors.form_of_organization_id && <ErrorLabel message={errors.form_of_organization_id?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Multi Branch</label>
                                        <Controller
                                            name="multi_branch"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={[
                                                        {
                                                            name: 'Yes',
                                                            value: 1
                                                        },
                                                        {
                                                            name: 'No',
                                                            value: 0
                                                        }
                                                    ]}
                                                    allowFiltering={false}
                                                    fields={{ text: 'name', value: 'value' }}
                                                    placeholder="Select Multi Branch"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.value);
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                        {errors.multi_branch && <ErrorLabel message={errors.multi_branch?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Lead Source&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                        <Controller
                                            name="lead_source_id"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={leadSource}
                                                    fields={{ text: 'name', value: 'id' }}
                                                    allowFiltering={true}
                                                    placeholder="Select Lead Source"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.id)
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required: clientRequiredFields || props?.location?.state?.visible === true ? 'Please Select Lead Source' : false
                                            }}
                                        />
                                        {errors.lead_source_id && <ErrorLabel message={errors.lead_source_id?.message} />}
                                    </div>
                                </div>
                                <div className={`col-12 col-md-6 col-lg-6`}>
                                    <div className="form-group">
                                        <label className="label-control">Assign RM&nbsp;<span className="danger">*</span></label>
                                        <Controller
                                            name="created_by"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={assignRM}
                                                    fields={{ text: 'full_name', value: 'user_id' }}
                                                    allowFiltering={true}
                                                    placeholder="Select Assign RM"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.user_id)
                                                        }
                                                    }}
                                                />
                                            }
                                            rules={{
                                                required:  'Please Select Assign RM' 
                                            }}
                                        />
                                        {errors.created_by && <ErrorLabel message={errors.created_by?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Assign Retention</label>
                                        <Controller
                                            name="retention_user_id"
                                            control={control}
                                            render={({ field }) =>
                                                <DropDownListComponent
                                                    {...field}
                                                    dataSource={assignRetention}
                                                    id="retention_user_id"
                                                    name="retention_user_id"
                                                    fields={{ text: 'full_name', value: 'user_id' }}
                                                    allowFiltering={true}
                                                    placeholder="Select Assign Retention"
                                                    value={field.value}
                                                    enabled={props?.location?.state?.visible === true ? true : false}
                                                    change={(event) => {
                                                        if (event.itemData != null) {
                                                            field.onChange(event.itemData.user_id)
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                        {errors.retention_user_id && <ErrorLabel message={errors.retention_user_id?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center form-section mb-2 black">
                                        <FiUser size={16} />&nbsp;Primary Contact
                                    </h4>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Contact Name</label>
                                        <input
                                            className="form-control"
                                            id="contact_name"
                                            name="contact_name"
                                            placeholder="Enter Contact Name"
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            {...register('contact_name', {
                                                required: 'Contact Name is required',
                                                pattern: {
                                                    value: /^(?!^ ?[A-Za-z] ?$)([A-Za-z]+( [A-Za-z]+)*)?$/,
                                                    message: 'Please Enter Valid Name'
                                                },
                                            })}
                                        />
                                        {errors.contact_name && <ErrorLabel message={errors.contact_name?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Contact Designation</label>
                                        <input
                                            className="form-control"
                                            id="contact_designation"
                                            name="contact_designation"
                                            placeholder="Enter Contact Designation"
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            {...register('contact_designation')}
                                        />
                                        {errors.contact_designation && <ErrorLabel message={errors.contact_designation?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Contact Phone</label>
                                        <input
                                            className="form-control"
                                            id="contact_phone"
                                            name="contact_phone"
                                            placeholder="Enter Contact Phone"
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            maxLength={10}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            {...register('contact_phone')}
                                        />
                                        {errors.contact_phone && <ErrorLabel message={errors.contact_phone?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label-control">Contact Email</label>
                                        <input
                                            className="form-control"
                                            id="contact_email"
                                            name="contact_email"
                                            placeholder="Enter Contact Email"
                                            {...register('contact_email', {
                                                pattern: {
                                                    value: /^(?!.*@.*pincinsure)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: 'Please enter valid Email Id'
                                                },
                                            }
                                            )}
                                            disabled={props?.location?.state?.visible === true ? false : true}
                                            {...register('contact_email')}
                                        />
                                        {errors.contact_email && <ErrorLabel message={errors.contact_email?.message} />}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                        <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={() => {
                                                setShowContactDetails(true)
                                                setShowContact(false)
                                            }}
                                        >
                                            <FiPlus
                                                cursor="pointer"
                                                size={20}
                                            />
                                        </button>
                                    </h4>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="row mx-0">
                                        {
                                            contactDetails.length > 0 ?
                                                renderContactDetails()
                                                :
                                                <h6 className="text-bold-500 text-center">No Contact Details</h6>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                        <span><FiShield size={16} />&nbsp;Opportunity Details</span>
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={() => {
                                                setSelectedPolicyIndex(-1);
                                                setShowPolicyDetails(true);
                                            }}
                                        >
                                            <FiPlus
                                                cursor="pointer"
                                                size={20}
                                            />
                                        </button>
                                    </h4>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="row mx-0">
                                        {
                                            policyDetails.length > 0 ?
                                                renderPolicyDetails()
                                                :
                                                <h6 className="text-bold-500 text-center">No Opportunity Details</h6>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-actions border-0">
                            {
                                // If Lead Show 3 Buttons Cancel, Save as Lead, Convert To Customer
                                // Else Show 2 Buttons Cancel & Submit
                                props.location.state.type === 'Lead' ?
                                    <>
                                        <button
                                            className={`btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0`}
                                            onClick={() => {
                                                setValue('clientOrLead', 'lead');
                                                setClientRequiredFields(false);
                                            }}
                                            type="submit"
                                            id="save-as-lead"
                                        >
                                            <div className="d-flex align-items-center">
                                                Save as Lead
                                                <span id="save-as-lead-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className={`btn btn-primary round text-uppercase shadow mr-1`}
                                            onClick={() => {

                                                setValue('clientOrLead', 'client');
                                                setClientRequiredFields(true);
                                            }}
                                            type="submit"
                                            id="convert-to-customer"
                                        >
                                            <div className="d-flex align-items-center">
                                                Convert to Customer
                                                <span id="convert-to-customer-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.push({
                                                    pathname: '/leads',
                                                    state: {
                                                        tabType: props.location.state.tabType,
                                                    }
                                                });
                                            }}
                                        > Cancel</button>
                                    </> :
                                    <>
                                        <button
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                            onClick={() => {
                                                setValue('clientOrLead', 'client');
                                                setClientRequiredFields(true);
                                            }}
                                            id="submit-form"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="submit-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>

                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                if (props.location.state.type === 'Lead') {
                                                    props.history.push({
                                                        pathname: '/leads',
                                                        state: {
                                                            tabType: props.location.state.tabType,
                                                        }
                                                    });

                                                } else {
                                                    props.history.push({
                                                        pathname: '/client',
                                                        state: {
                                                            tabType: props.location.state.tabType,
                                                            tab: props.location.state.tab
                                                        }
                                                    });
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <ContactDetails
                show={showContactDetails}
                contact={showContact}
                handleClose={(newContact) => {
                    if (newContact != null) {
                        var arrContacts = Object.assign([], contactDetails)
                        if (selectedContactIndex != null) {
                            arrContacts[selectedContactIndex] = newContact;
                            setSelectedContactIndex(null);
                        } else {
                            arrContacts.push(newContact)
                        }
                        setContactDetails(arrContacts)
                    }
                    setShowContactDetails(false)
                }}
                data={selectedContactIndex != null ? contactDetails[selectedContactIndex] : null}
            />
            <PolicyDetails
                show={showPolicyDetails}
                assignRMId={assignRMId != null && assignRMId != '' ? assignRMId : props.auth.getUser().user_id}
                handleClose={(newPolicy) => {
                    if (newPolicy != null) {
                        var arrPolicies = Object.assign([], policyDetails);
                        if (selectedPolicyIndex != null && selectedPolicyIndex > -1) {
                            arrPolicies[selectedPolicyIndex] = newPolicy;
                            setSelectedPolicyIndex(-1);

                        } else {
                            arrPolicies.push(newPolicy)
                        }
                        console.log("client", arrPolicies);
                        setPolicyDetails(arrPolicies);
                    }
                    setShowPolicyDetails(false)
                }}
                data={selectedPolicyIndex != -1 ? policyDetails[selectedPolicyIndex] : null}
            />
            <ProposalDetailModal
                {...props}
                show={showProposalDetails}
                proposalDetails={selectedPolicyIndex > -1 ? policyDetails[selectedPolicyIndex] : null}
                handleClose={() => {
                    setShowProposalDetails(false);
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var CompanyGroup = null;
    var CustomerCategory = null;
    var IndustrialSegment = null;
    var FormofOrganization = null;
    var LeadSource = null;
    var AssignRM = null;
    var States = null;
    var Cities = null;
    var ClientDetails = null;
    var ClientEdit = null;
    var AssignRetentionEdit = null;

    if (
        state.ClientEditCorporateReducer != null &&
        Object.keys(state.ClientEditCorporateReducer).length > 0 &&
        state.ClientEditCorporateReducer.constructor === Object
    ) {
        switch (state.ClientEditCorporateReducer.tag) {
            case ACTION_MASTER_EDIT_CLIENT_COMPANY_GROUP_SUCCESS:
                CompanyGroup = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { CompanyGroup };
            case ACTION_MASTER_EDIT_CLIENT_CUSTOMER_CATEGORY_SUCCESS:
                CustomerCategory = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { CustomerCategory };
            case ACTION_MASTER_EDIT_CLIENT_INDUSTRIAL_SEGMENT_SUCCESS:
                IndustrialSegment = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { IndustrialSegment };
            case ACTION_MASTER_EDIT_CLIENT_FORM_OF_ORGANIZATION_SUCCESS:
                FormofOrganization = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { FormofOrganization };
            case ACTION_MASTER_EDIT_CLIENT_LEAD_SOURCE_SUCCESS:
                LeadSource = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { LeadSource };
            case ACTION_MASTER_EDIT_CLIENT_ASSIGN_RM_SUCCESS:
                AssignRM = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { AssignRM };
            case ACTION_MASTER_EDIT_CLIENT_STATES_SUCCESS:
                States = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { States };
            case ACTION_MASTER_EDIT_CLIENT_CITIES_SUCCESS:
                Cities = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { Cities };
            case ACTION_CLIENT_DETAILS_SUCCESS:
                ClientDetails = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { ClientDetails };
            case ACTION_CLIENT_EDIT_SUCCESS:
                ClientEdit = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { ClientEdit };
            case ACTION_MASTER_EDIT_CLIENT_ASSIGN_RETENTION_SUCCESS:
                AssignRetentionEdit = Object.assign({}, state.ClientEditCorporateReducer.data);
                delete state.ClientEditCorporateReducer;
                return { AssignRetentionEdit };
            default:
        }
    }
    return {
        CompanyGroup,
        CustomerCategory,
        IndustrialSegment,
        FormofOrganization,
        LeadSource,
        AssignRM,
        States,
        Cities,
        ClientDetails,
        ClientEdit,
        AssignRetentionEdit
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionEditCompanyGroup,
            actionEditCustomerCategory,
            actionEditIndustrialSegment,
            actionEditFormOfOrganization,
            actionEditLeadSource,
            actionEditAssignRM,
            actionEditStates,
            actionEditCities,
            actionGetClientDetails,
            actionGetClientEdit,
            actionAssignRetentionEdit
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ClientEdit))