import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CALENDER_EVENT, API_MYTEAMVERTICAL, API_CALENDER_TEAM_EVENT, API_CALENDER_GET_EVENT } from '../../../../../api/constants';

import { PRINT } from '../../../../../utils';

export const ACTION_CALENDER_EVENT_LOADING = 'ACTION_CALENDER_EVENT_LOADING';
export const ACTION_CALENDER_EVENT_SUCCESS = 'ACTION_CALENDER_EVENT_SUCCESS';

export const ACTION_CALENDER_EVENT_DETAILS_LOADING = 'ACTION_CALENDER_EVENT_DETAILS_LOADING';
export const ACTION_CALENDER_EVENT_DETAILS_SUCCESS = 'ACTION_CALENDER_EVENT_DETAILS_SUCCESS';

export const ACTION_MY_TEAM_VERTICAL_LOADING = 'ACTION_MY_TEAM_VERTICAL_LOADING';
export const ACTION_MY_TEAM_VERTICAL_SUCCESS = 'ACTION_MY_TEAM_VERTICAL_SUCCESS';

export const ACTION_CALENDER_TEAM_EVENT_LOADING = 'ACTION_CALENDER_TEAM_EVENT_LOADING';
export const ACTION_CALENDER_TEAM_EVENT_SUCCESS = 'ACTION_CALENDER_TEAM_EVENT_SUCCESS';

export const ACTION_CALENDER_TEAM_EVENT_DETAILS_LOADING = 'ACTION_CALENDER_TEAM_EVENT_DETAILS_LOADING';
export const ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS = 'ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS';

export const ACTION_CALENDER_GET_EVENT_DETAILS_LOADING = 'ACTION_CALENDER_GET_EVENT_DETAILS_LOADING';
export const ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS = 'ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS';

export function actionCalenderEvents(params) {
    return (dispatch, getState) =>
        Api.post(API_CALENDER_EVENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CALENDER_EVENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CALENDER_EVENT_LOADING, { loading: false, data }));
        });
}

export function actionCalenderEventDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CALENDER_EVENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CALENDER_EVENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CALENDER_EVENT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionMyTeamVertical(params) {
    return (dispatch, getState) =>
        Api.post(API_MYTEAMVERTICAL, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MY_TEAM_VERTICAL_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MY_TEAM_VERTICAL_LOADING, { loading: false, data }));
        });
}

export function actionCalenderTeamEvents(params) {
    return (dispatch, getState) =>
        Api.post(API_CALENDER_TEAM_EVENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CALENDER_TEAM_EVENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CALENDER_TEAM_EVENT_LOADING, { loading: false, data }));
        });
}

export function actionCalenderTeamEventDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CALENDER_TEAM_EVENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CALENDER_TEAM_EVENT_DETAILS_LOADING, { loading: false, data }));
        });
}

export function actionCalenderGETEventDetails(params) {
    return (dispatch, getState) =>
        Api.post(API_CALENDER_GET_EVENT, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CALENDER_GET_EVENT_DETAILS_LOADING, { loading: false, data }));
        });
}