import React from 'react';
import {FiUser} from 'react-icons/fi';

const FilterPage = (props) => {

    return (
        <>
            <section>
                <div className="card ">
                    <div className="card-body">
                        <div className="form-actions border-0 float-none">
                            <button 
                                className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                Back
                            </button>
                            <button className="btn btn-primary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round">
                                Monthly Report
                            </button>
                            <button className="btn bg-blue-grey bg-lighten-3 btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round">
                                Insurer wise Report
                            </button>
                            <button className="btn bg-blue-grey bg-lighten-3 btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round">
                                Direct Business Report
                            </button>
                            <button className="btn bg-blue-grey bg-lighten-3 btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round">
                                Direct Business Report (Detail)
                            </button>
                            <button className="btn bg-blue-grey bg-lighten-3 btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round">
                                Customer wise Report
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16}/>&nbsp;Select Filters
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Branch</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Branch"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Team</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Team"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">RM</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select RM"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Vertical</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Vertical"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Biz Type</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Business"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Insurance Company</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Insurance Compan"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Insurance Company City</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Insurance Company City"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Insurance Company Branch</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Insurance Company Branch"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Department</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Department"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Comparative</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Comparative"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Nature Type</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Nature Type"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Fields</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Fields"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Type</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Type"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-3 col-lg-3"></div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <h4 className="d-flex align-items-center form-section mb-2 black">
                                            <FiUser size={16}/>&nbsp;Select Dates
                                        </h4>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">From Date</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Date"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">To Date</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Select Date"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Diff in %</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Diff"
                                                id=""
                                                name="">
                                            </input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions border-0 float-none">
                            <button className="btn btn-outline-secondary mr-1 mb-1 mb-md-0 mb-lg-0 shadow round ">&nbsp;Reset Filters</button>
                                <button className="btn btn-primary mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                    onClick={() =>{
                                        props.history.push(`/reports/detail`);
                                    }}
                                >&nbsp;Generate Report</button>
                                
                            </div> 
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FilterPage