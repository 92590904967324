import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_ADD_GENERAL_PREFERENCE_VIEW, API_MASTER_GET_DEPARTMENT } from '../../../../../api/constants';
import { API_MASTER_GET_POLICY_TYPE } from '../../../../../api/constants';
import { API_MASTER_GET_INSURER } from '../../../../../api/constants';

export const ACTION_GET_GENERAL_DEPARTMENT_LOADING = 'ACTION_GET_GENERAL_DEPARTMENT_LOADING';
export const ACTION_GET_GENERAL_DEPARTMENT_SUCCESS = 'ACTION_GET_GENERAL_DEPARTMENT_SUCCESS';

export const ACTION_GET_GENERAL_POLICY_TYPE_LOADING = 'ACTION_GET_GENERAL_POLICY_TYPE_LOADING';
export const ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS = 'ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS';

export const ACTION_GET_GENERAL_INSURER_LOADING = 'ACTION_GET_GENERAL_INSURER_LOADING';
export const ACTION_GET_GENERAL_INSURER_SUCCESS = 'ACTION_GET_GENERAL_INSURER_SUCCESS';


export const ACTION_MASTER_GET_GENERAL_LOADING = "ACTION_MASTER_GET_GENERAL_LOADING";
export const ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS = "ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS";

export function actionGetGeneralDepartment(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_DEPARTMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_GENERAL_DEPARTMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_GENERAL_DEPARTMENT_LOADING, { loading: false, data }));
        });
}

export function actionGetGeneralPolicyType(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_GENERAL_POLICY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_GENERAL_POLICY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionGetGeneralCompanyList(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_GENERAL_INSURER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_GENERAL_INSURER_LOADING, { loading: false, data }));
        });
}
export function actionGeneralViewList(params) {
    return (dispatch, getState) =>
        Api.post(API_ADD_GENERAL_PREFERENCE_VIEW, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_GET_GENERAL_PREFERENCE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_GENERAL_DEPARTMENT_LOADING, { loading: false, data }));
        });
}


