import createReducer from "../../../../../../reducers/createReducer";
import {
    ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS,
    ACTION_GET_DEPARTMENT_SUCCESS,
    ACTION_GET_EMPLOYEE_LIST_SUCCESS,
    ACTION_GET_LOCATION_LIST_SUCCESS
} from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const OpportunitySettingAddReducer = createReducer(INITIAL_STATE, {
    [ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_SETTINGS_ADD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_DEPARTMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_DEPARTMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_EMPLOYEE_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_EMPLOYEE_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_LOCATION_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_LOCATION_LIST_SUCCESS,
                loading: false,
            }
        );
    },
}
)
export default OpportunitySettingAddReducer