import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actionLeaveRequestView, ACTION_LEAVE_REQUEST_VIEW_SUCCESS } from './action';
import { actionChangeLeaveStatus, ACTION_CHANGE_LEAVE_STATUS_SUCCESS } from './action';
import { connect } from 'react-redux';
import { generateInitialLettersString } from '../../../../../../utils';
import { bindActionCreators } from 'redux';
import { CANCEL_BY_EMPLOYEE, REQUEST_LEAVE, REVIEW_BY_SUPERIOR, UNAUTHORIZED } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { withAuth } from 'react-auth-guard';
import RequestHistory from '../../../../../../component/widgets/request_history';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { downloadFile } from '../../../../../../utils';

const LeaveRequestView = (props) => {

    const [leaveRequestView, setLeaveRequestView] = useState([]);
    const [changeLeaveStatus, setChangeLeaveStatus] = useState();
    const [requestHistory, setRequestHistory] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        props.actionLeaveRequestView({
            id: props.location.state.id,
        });
    }
    useEffect(initialRender, []);

    const onLeaveRequestViewUpdate = () => {
        const { LeaveRequestView } = props;
        var LeaveRequestViewResponse = Object.assign({}, LeaveRequestView);
        if (LeaveRequestViewResponse.result === true) {
            setLeaveRequestView(LeaveRequestViewResponse.response);
            if (LeaveRequestViewResponse.response.request_status.length > 0) {
                setRequestHistory(LeaveRequestViewResponse.response.request_status);
            }
        } else {
            switch (LeaveRequestViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onLeaveRequestViewUpdate, [props.LeaveRequestView]);

    const onChangeLeaveStatusUpdate = () => {
        const { ChangeLeaveStatus } = props;

        if (ChangeLeaveStatus != null) {

            var ChangeLeaveStatusResponse = Object.assign({}, ChangeLeaveStatus);
            if (ChangeLeaveStatusResponse.result === true) {
                setChangeLeaveStatus(ChangeLeaveStatusResponse.response);
                props.actionLeaveRequestView({ id: props.location.state.id });
                toast.success("Status Change Successfully");
            } else {
                switch (ChangeLeaveStatusResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onChangeLeaveStatusUpdate, [props.ChangeLeaveStatus]);

    return (
        <>
            <ScreenTitle title="Leave Request Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (leaveRequestView != null && leaveRequestView.profile_photo != null) ?
                                                <img
                                                    src={leaveRequestView.profile_photo}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(leaveRequestView.full_name))}

                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    leaveRequestView != null && leaveRequestView.full_name != null ?
                                                        leaveRequestView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success text-bold-500 round">
                                                {
                                                    leaveRequestView != null && leaveRequestView.designation_name != null ?
                                                        leaveRequestView.designation_name : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        leaveRequestView != null && leaveRequestView.status != null ?
                                                            leaveRequestView.status : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2">By <span className="text-bold-700 text-justify">
                                                {

                                                    leaveRequestView != null && leaveRequestView.status != null &&
                                                        leaveRequestView.status != 'Cancel By Employee' ? leaveRequestView?.approval_manager_name : leaveRequestView.full_name

                                                }
                                            </span> On: <span className="text-primary">
                                                    {
                                                        leaveRequestView != null && leaveRequestView.modified_at != null ?
                                                            leaveRequestView.modified_at : '-'
                                                    }</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Full Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.full_name != null ?
                                            leaveRequestView.full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.approval_manager_name != null ?
                                            leaveRequestView.approval_manager_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Leave Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.leave_type != null ?
                                            leaveRequestView.leave_type : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Reason</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.reason != null ?
                                            leaveRequestView.reason : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">From Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.from_date != null ?
                                            leaveRequestView.from_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">To Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.to_date != null ?
                                            leaveRequestView.to_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Status</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        leaveRequestView != null && leaveRequestView.status != null ?
                                            leaveRequestView.status : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.remark != null ?
                                            leaveRequestView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Upload Document</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        leaveRequestView != null && leaveRequestView.medical_document != null ?
                                            <>
                                                <a className="primary"
                                                    onClick={() => {
                                                        if (leaveRequestView.medical_document?.split('.').pop() == 'pdf' ||
                                                            leaveRequestView.medical_document?.split('.').pop() == 'PDF' ||
                                                            leaveRequestView.medical_document?.split('.').pop() == 'png' ||
                                                            leaveRequestView.medical_document?.split('.').pop() == 'jpg' ||
                                                            leaveRequestView.medical_document?.split('.').pop() == 'jpeg' ||
                                                            leaveRequestView.medical_document?.split('.').pop() == 'gif'
                                                        ) {
                                                            setSelectedDocumentIndex(leaveRequestView.medical_document)
                                                            setShowDocumentDetails(true);
                                                            setExtension(leaveRequestView.medical_document?.split('.').pop())
                                                            setDocumentDetails(leaveRequestView)
                                                        } else {
                                                            downloadFile(leaveRequestView.medical_document);
                                                        }
                                                    }}
                                                >
                                                    View Document
                                                </a>
                                                <a id="downloadLink" className="d-none"></a>
                                            </>
                                            :
                                            <>-</>
                                        // <a target="_blank" href={leaveRequestView.medical_document} download="Leave Document">View Document</a> : <>-</>
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 pt-3 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'LEAVE_APPROVAL_NOTIFICATION') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >Back</button>
                            {
                                leaveRequestView != null && (leaveRequestView.status === REVIEW_BY_SUPERIOR) ?
                                    <button
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, Do you want to cancel your leave request?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                                inputPlaceholder: 'Remark (If Any)',
                                                input: 'text',
                                            }).then(({ isConfirmed, value }) => {
                                                if (isConfirmed) {
                                                    setRequestHistory(null);
                                                    props.actionChangeLeaveStatus({
                                                        "id": props.location.state.id,
                                                        "remark": value.length > 0 ? value : '',
                                                        "status": CANCEL_BY_EMPLOYEE,
                                                        "type": REQUEST_LEAVE
                                                    })
                                                }
                                            })
                                        }}
                                    >Cancel Leave Request</button>
                                    :
                                    <></>
                            }

                        </div>
                    </div>
                </div>
            </section>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var LeaveRequestView = null;
    var ChangeLeaveStatus = null;
    if (
        state.LeaveRequestViewReducer != null &&
        Object.keys(state.LeaveRequestViewReducer).length > 0 &&
        state.LeaveRequestViewReducer.constructor === Object
    ) {
        switch (state.LeaveRequestViewReducer.tag) {
            case ACTION_LEAVE_REQUEST_VIEW_SUCCESS:
                LeaveRequestView = Object.assign({}, state.LeaveRequestViewReducer.data);
                delete state.LeaveRequestViewReducer;
                return { LeaveRequestView };
            case ACTION_CHANGE_LEAVE_STATUS_SUCCESS:
                ChangeLeaveStatus = Object.assign({}, state.LeaveRequestViewReducer.data);
                delete state.LeaveRequestViewReducer;
                return { ChangeLeaveStatus };
            default:
        }
    }

    return {
        LeaveRequestView,
        ChangeLeaveStatus,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionLeaveRequestView, actionChangeLeaveStatus }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LeaveRequestView));
