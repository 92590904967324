import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_TRAVEL_REQUEST_ADD , API_TRAVEL_REQUEST_APPROVAL_MANAGER, API_TRAVEL_REQUEST_UPCOMING_MEETINGS} from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';


export const ACTION_TRAVEL_REQUEST_ADD_LOADING = 'ACTION_TRAVEL_REQUEST_ADD_LOADING';
export const ACTION_TRAVEL_REQUEST_ADD_SUCCESS = 'ACTION_TRAVEL_REQUEST_ADD_SUCCESS';

export const ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_LOADING = 'ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_LOADING';
export const ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_SUCCESS = 'ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_SUCCESS';


export const ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_LOADING = 'ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_LOADING';
export const ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_SUCCESS = 'ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_SUCCESS';


export function actionTravelRequestAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_REQUEST_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_REQUEST_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_REQUEST_ADD_LOADING, { loading: false, data }));
        });
}
export function actionTravelRequestApprovalManager() {
    return (dispatch, getState) =>
        Api.get(API_TRAVEL_REQUEST_APPROVAL_MANAGER).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_REQUEST_APPROVAL_MANAGER_LOADING, { loading: false, data }));
        });
}
// for meeting id 
export function actionTravelRequestUpcomingMeetings() {
    return (dispatch, getState) =>
        Api.get(API_TRAVEL_REQUEST_UPCOMING_MEETINGS).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_REQUEST_UPCOMING_MEETINGS_LOADING, { loading: false, data }));
        });
}

