import { ACTION_API_REINSURANCE_EDIT_SUCCESS, ACTION_API_REINSURANCE_VIEW_SUCCESS, ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS, ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ReinsuranceClientEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_API_REINSURANCE_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_REINSURANCE_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_REINSURANCE_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_REINSURANCE_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REINSURANCE_MASTER_COUNTRY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REINSURANCE_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
});
export default ReinsuranceClientEditReducer;