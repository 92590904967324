import { ACTION_REIMBURSEMENT_POOL_LIST_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ReimbursementPoolListReducer = createReducer(INITIAL_STATE, {
    [ACTION_REIMBURSEMENT_POOL_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_REIMBURSEMENT_POOL_LIST_SUCCESS,
                loading: false,
            }
        );
    },
});

export default ReimbursementPoolListReducer;