import createReducer from "../../../../../reducers/createReducer";
import { ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS } from "../../proposal/create/action";
import { ACTION_MASTER_POLICY_TYPE_SUCCESS, ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS, ACTION_POLICY_DASHBOARD_SUCCESS, ACTION_SUM_INSURED_POLICY_SUCCESS, ACTION_MASTER_ASSIGN_RM_SUCCESS, ACTION_CLAIMS_POLICY_TYPE_SUCCESS, ACTION_PREMIUM_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const PolicyReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLAIMS_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POLICY_DASHBOARD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POLICY_DASHBOARD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_SUM_INSURED_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_SUM_INSURED_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
})
export default PolicyReducer