import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_TRAVEL_UPDATE , API_GET_SEARCH_LIST} from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_TRAVEL_UPDATE_SUCCESS = 'ACTION_TRAVEL_UPDATE_SUCCESS';
export const ACTION_TRAVEL_UPDATE_LOADING = 'ACTION_TRAVEL_UPDATE_LOADING';

export const ACTION_GET_EMP_LIST_LOADING = 'ACTION_GET_EMP_LIST_LOADING';
export const ACTION_GET_EMP_LIST_SUCCESS = 'ACTION_GET_EMP_LIST_SUCCESS';

export function actionTravelUpdate(params) {
    const endpoint = `${API_TRAVEL_UPDATE}/${params.dynamic_url}`
    delete params.dynamic_url
    return (dispatch, getState) =>
        Api.post(endpoint,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_UPDATE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_UPDATE_LOADING, { loading: false, data }));
        });
}
export function actionGetEmpList() {
    return (dispatch, getState) =>
        Api.get(API_GET_SEARCH_LIST).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_EMP_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_EMP_LIST_LOADING, { loading: false, data }));
        });
}
