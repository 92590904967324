import { ACTION_IT_SUBMISSION_POOL_EDIT_SUCCESS } from "./action";
import { ACTION_IT_SUBMISSION_POOL_DETAIL_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ItSubmissionPoolEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_IT_SUBMISSION_POOL_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_IT_SUBMISSION_POOL_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_IT_SUBMISSION_POOL_DETAIL_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_IT_SUBMISSION_POOL_DETAIL_SUCCESS,
                loading: false,
            }
        );
    }
});

export default ItSubmissionPoolEditReducer;