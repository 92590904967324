import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_PERMISSION_ADD_CREATE } from '../../../../../../api/constants';
import { API_MODULE_NAME } from '../../../../../../api/constants';

import { PRINT } from '../../../../../../utils';

export const ACTION_MODULE_NAME_LOADING = 'ACTION_MODULE_NAME_LOADING';
export const ACTION_MODULE_NAME_SUCCESS = 'ACTION_MODULE_NAME_SUCCESS';

export const ACTION_PERMISSION_ADD_CREATE_LOADING = 'ACTION_PERMISSION_ADD_CREATE_LOADING';
export const ACTION_PERMISSION_ADD_CREATE_SUCCESS = 'ACTION_PERMISSION_ADD_CREATE_SUCCESS';

export function actionPermissionAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_PERMISSION_ADD_CREATE,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PERMISSION_ADD_CREATE_SUCCESS , { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PERMISSION_ADD_CREATE_LOADING, { loading: false, data }));
        });
}

export function actionModuleName() {
    return (dispatch, getState) =>
        Api.get(API_MODULE_NAME).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_MODULE_NAME_SUCCESS , { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MODULE_NAME_LOADING, { loading: false, data }));
        });
}