import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_TRAVEL_REQUEST_VIEW } from '../../../../../../api/constants';
import { API_CHANGE_STATUS } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_TRAVEL_REQUEST_VIEW_LOADING = 'ACTION_TRAVEL_REQUEST_VIEW_LOADING';
export const ACTION_TRAVEL_REQUEST_VIEW_SUCCESS = 'ACTION_TRAVEL_REQUEST_VIEW_SUCCESS';

export const ACTION_CHANGE_TRAVEL_STATUS_LOADING = 'ACTION_CHANGE_TRAVEL_STATUS_LOADING';
export const ACTION_CHANGE_TRAVEL_STATUS_SUCCESS = 'ACTION_CHANGE_TRAVEL_STATUS_SUCCESS';

export function actionTravelRequestView(params) {
    return (dispatch, getState) =>
        Api.post(API_TRAVEL_REQUEST_VIEW,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_TRAVEL_REQUEST_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_TRAVEL_REQUEST_VIEW_LOADING, { loading: false, data }));
        });
}
export function actionChangeTravelStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_CHANGE_STATUS,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CHANGE_TRAVEL_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CHANGE_TRAVEL_STATUS_LOADING, { loading: false, data }));
        });
}