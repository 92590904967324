import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_CLAIMS_DOCUMENT_LIST, API_CLAIMS_DOCUMENT_UPLOAD, API_GET_CLAIMS_DOCUMENT_UPLOAD } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_CLAIMS_DOCUMENT_LIST_LOADING = 'ACTION_CLAIMS_DOCUMENT_LIST_LOADING';
export const ACTION_CLAIMS_DOCUMENT_LIST_SUCCESS = 'ACTION_CLAIMS_DOCUMENT_LIST_SUCCESS';

export const ACTION_CLAIMS_DOCUMENT_UPLOAD_LOADING = 'ACTION_CLAIMS_DOCUMENT_UPLOAD_LOADING';
export const ACTION_CLAIMS_DOCUMENT_UPLOAD_SUCCESS = 'ACTION_CLAIMS_DOCUMENT_UPLOAD_SUCCESS';

export const ACTION_GET_CLAIMS_DOCUMENT_LOADING = 'ACTION_GET_CLAIMS_DOCUMENT_LOADING';
export const ACTION_GET_CLAIMS_DOCUMENT_SUCCESS = 'ACTION_GET_CLAIMS_DOCUMENT_SUCCESS';

export function actionClaimsDocumentList(params) {
    return (dispatch, getState) =>
        Api.post(API_CLAIMS_DOCUMENT_LIST,params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_DOCUMENT_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_DOCUMENT_LIST_LOADING, { loading: false, data }));
        });
}

export function actionClaimsDocumentUpload(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_CLAIMS_DOCUMENT_UPLOAD,params,files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLAIMS_DOCUMENT_UPLOAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLAIMS_DOCUMENT_UPLOAD_LOADING, { loading: false, data }));
        });
}

export function actionClaimsDocumentURL(params) {
    return (dispatch, getState) =>
        Api.postData(API_GET_CLAIMS_DOCUMENT_UPLOAD,params).then((res) => {
            const data = {};
            data.url = res;

            console.log('URL ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_CLAIMS_DOCUMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_CLAIMS_DOCUMENT_LOADING, { loading: false, data }));
        });
}