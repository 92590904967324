import React, { useEffect } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import { ERROR, UNAUTHORIZED } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import 'react-toastify/dist/ReactToastify.css';
import { actionAppSettingsAdd, ACTION_APP_SETTING_ADD_SUCCESS } from "./action"

const AppSettingAdd = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
    }
    useEffect(initialRender);

    const onAppSettingAddUpdate = () => {
        const { AppSettingAdd } = props;
        var AppSettingAddResponse = Object.assign({}, AppSettingAdd);
        $("#add_app").prop("disabled", false);
        if (AppSettingAddResponse.result === true) {
            $("#add_app_setting").addClass("hidden");
            props.history.goBack();
            toast.success('Setting Added successfully')
        } else {
            $("#add_app_setting").addClass("hidden");
            switch (AppSettingAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break
                case ERROR:
                    toast.error(AppSettingAddResponse.message);
                default:
            }
        }
    }
    useEffect(onAppSettingAddUpdate, [props.AppSettingAdd]);

    const onSubmit = (data) => {
        data.is_delete = data.is_delete === true ? 1 : 0;
        $("#add_app_setting").removeClass("hidden");
        $("#add_app").prop("disabled", true);
        props.actionAppSettingsAdd(data);
    }

    return (
        <>
            <ScreenTitle title="Create App Settings" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Key&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="key"
                                                        name="key"
                                                        placeholder="Enter Key"
                                                        {...register('key', { required: 'Key is required' })}
                                                    />
                                                    {errors.key && <ErrorLabel message={errors.key?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Name&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Name"
                                                        {...register('name', { required: 'Name is required' })}
                                                    />
                                                    {errors.name && <ErrorLabel message={errors.name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Value&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="value"
                                                        name="value"
                                                        placeholder="Enter Value"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9.]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register('value', { required: 'Value is required' })}
                                                    />
                                                    {errors.value && <ErrorLabel message={errors.value?.message} />}
                                                </div>
                                            </div>
                                            <div class="form-check cust-form-check mt-0 mb-1 mx-1">
                                                <input
                                                    name="is_delete"
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="is_delete"
                                                    {...register("is_delete")}
                                                />
                                                <label class="form-check-label my-auto" for="is_delete">
                                                    <span className="cust-fs-12">Is Delete</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="add_app"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add_app_setting" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var AppSettingAdd = null;

    if (
        state.AddAppSettingsReducer != null &&
        Object.keys(state.AddAppSettingsReducer).length > 0 &&
        state.AddAppSettingsReducer.constructor === Object
    ) {
        switch (state.AddAppSettingsReducer.tag) {
            case ACTION_APP_SETTING_ADD_SUCCESS:
                AppSettingAdd = Object.assign({}, state.AddAppSettingsReducer.data);
                delete state.AddAppSettingsReducer
                return { AppSettingAdd };
            default:
        }
    }

    return {
        AppSettingAdd,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionAppSettingsAdd }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSettingAdd)
