import { ACTION_ROLL_VIEW_SUCCESS } from "./action";
import { ACTION_GETPERMISSION_SUCCESS } from './action';
import { ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS } from './action';
import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ViewPermissionRoleReducer = createReducer(INITIAL_STATE, {
    [ACTION_ROLL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ROLL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GETPERMISSION_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_GETPERMISSION_SUCCESS,
            loading: false
        });
    },
    [ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_CREATEPERMISSIONSAGAINROLE_SUCCESS,
            loading: false
        });
    }
});

export default ViewPermissionRoleReducer;