import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REINSURANCE_ENQUIRY_ADD, API_REINSURANCE_ENQUIRY_MASTER } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_REINSURANCE_CREATE_LOADING = 'ACTION_REINSURANCE_CREATE_LOADING';
export const ACTION_REINSURANCE_CREATE_SUCCESS = 'ACTION_REINSURANCE_CREATE_SUCCESS';

export const ACTION_REINSURANCE_ENQUIRY_MASTER_LOADING = 'ACTION_REINSURANCE_ENQUIRY_MASTER_LOADING';
export const ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS = 'ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS';


export function actionReinsuranceCreate(params) {
    return (dispatch, getState) =>
        Api.post(API_REINSURANCE_ENQUIRY_ADD, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_CREATE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_CREATE_LOADING, { loading: false, data }));
        });
}

export function actionReinsuranceEnquiryMaster() {
    return (dispatch, getState) =>
        Api.get(API_REINSURANCE_ENQUIRY_MASTER).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REINSURANCE_ENQUIRY_MASTER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REINSURANCE_ENQUIRY_MASTER_LOADING, { loading: false, data }));
        });
}