import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_REGULARIZE_REQUEST_LIST, API_WORKING_DAYS } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_REGULARIZE_REQUEST_LIST_LOADING = 'ACTION_REGULARIZE_REQUEST_LIST_LOADING';
export const ACTION_REGULARIZE_REQUEST_LIST_SUCCESS = 'ACTION_REGULARIZE_REQUEST_LIST_SUCCESS';

export const ACTION_WORKING_DAY_LOADING = 'ACTION_WORKING_DAY_LOADING';
export const ACTION_WORKING_DAY_SUCCESS = 'ACTION_WORKING_DAY_SUCCESS';

export function actionRegularizeRequestList(params) {
    return (dispatch, getState) =>
        Api.post(API_REGULARIZE_REQUEST_LIST, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_REGULARIZE_REQUEST_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_REGULARIZE_REQUEST_LIST_LOADING, { loading: false, data }));
        });
}
export function actionRegularizeWorkingdays(params) {
    return (dispatch, getState) =>
        Api.get(API_WORKING_DAYS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_WORKING_DAY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WORKING_DAY_LOADING, { loading: false, data }));
        });
}