import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import ErrorLabel from "../../../component/form/error_label";
import { checkFileExtension, downloadFile } from '../../../utils';
import { MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED } from '../../../constants';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { actionMobileClaim, actionMobileClaimEdit, ACTION_MOBILE_CLAIM_SUCCESS, ACTION_MOBILE_CLAIM_EDIT_SUCCESS } from './action';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentIframe from "../../popups/document_iframe";

const MobileClaim = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm();
    const [remItemId, setRemItemId] = useState(null);
    const validExt = ['jpg', 'jpeg', 'png', 'docx', 'doc', 'pdf', 'PDF', 'xlsx', 'csv', 'zip'];
    const [uploadDocument, setUploadDocument] = useState(null);
    const [remMinAmount, setRemMinAmount] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [popup, setPopup] = useState(null)
    var watchBillAmount = watch('bill_amount');

    const initialRender = () => {
        if (props.show === true) {
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            setRemItemId(props.data != null ? props.data.details_id : '')
            if (props.claimDetail != null && props.claimDetail.reimbursement_min_amount != null) {
                setRemMinAmount(props.claimDetail.reimbursement_min_amount);
            }

            if (props?.modileData === true) {
                setValue('reimbursement_type_id', props.data != null ? props.data.details_id : '');
                setValue('bill_amount', props.data != null ? props.data.bill_amount : '');
                setValue('claim_amount', props.data != null ? props.data.claim_amount : '');
                setValue('description', props.data != null ? props.data.description : '');
            }
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
            setUploadDocument(null)
            setValue('reimbursement_type_id', '');
            setValue('bill_amount', '');
            setValue('claim_amount', '');
            setValue('description', '');
            reset()
        }
    }
    useEffect(initialRender, [props.show, props?.modileData])

    const onMobileClaimUpdate = () => {
        const { mobileClaim } = props;
        var ReimbursementMobileClaimResponse = Object.assign({}, mobileClaim);
        $("#mobile_request").prop("disabled", false);
        if (ReimbursementMobileClaimResponse.result === true) {
            $("#add_loader").addClass("hidden");
            props.handleClose(ReimbursementMobileClaimResponse.response);
        } else {
            $("#add_loader").addClass("hidden");
            switch (ReimbursementMobileClaimResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMobileClaimUpdate, [props.mobileClaim]);

    const onMobileClaimEditUpdate = () => {
        const { mobileClaimEdit } = props;
        var mobileClaimEditResponse = Object.assign({}, mobileClaimEdit);
        $("#mobile_request").prop("disabled", false);
        if (mobileClaimEditResponse.result === true) {
            $("#add_loader").addClass("hidden");
            props.handleClose(mobileClaimEditResponse.response);
        } else {
            $("#add_loader").addClass("hidden");
            switch (mobileClaimEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMobileClaimEditUpdate, [props.mobileClaimEdit]);

    const reimbursmentClaimLimit = () => {
        if (props.reimbursementTypeDetails != null) {
            let totalClaimAmount = props.reimbursementTypeDetails.total_claim_amount != null ? props.reimbursementTypeDetails.total_claim_amount : 0
            if (remItemId > 0 && props?.conveyanceData === true) {
                totalClaimAmount = totalClaimAmount - Number(props.data.claim_amount)
            }
            var remTypeLimit = props.reimbursementTypeDetails.type_limit - totalClaimAmount
            return remTypeLimit
        }

        return 0
    }

    const onSubmit = (data) => {
        if (remItemId > 0 && props?.modileData === true) {
            $("#add_loader").removeClass("hidden");
            $("#mobile_request").prop("disabled", true);
            data.id = remItemId;
            data.reimbursement_id = props.reimbursementId;
            data.reimbursement_type_id = props.reimbursementTypeId;
            data.bill_amount = data.bill_amount;
            data.claim_amount = data.claim_amount;
            data.description = data.description;
            var files = [];
            if (uploadDocument != null) {
                files.push({ 'name': 'upload_document', 'value': uploadDocument });
            }
            delete data.upload_document;
            props.actionMobileClaimEdit(data, files);
        } else {
            data.reimbursement_id = props.reimbursementId;
            data.reimbursement_type_id = props.reimbursementTypeId;
            data.bill_amount = data.bill_amount;
            data.claim_amount = data.claim_amount;
            data.description = data.description;
            var files = [];
            if (uploadDocument != null) {
                files.push({ 'name': 'upload_document', 'value': uploadDocument });
                $("#add_loader").removeClass("hidden");
                $("#mobile_request").prop("disabled", true);
                props.actionMobileClaim(data, files);
            } else {
                $('#error-pdf-doc-not-found').removeClass('d-none')
            }
            delete data.upload_document;
        }
    }
    return (
        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                {remItemId && props?.modileData === true ? 'Edit' : 'Add New'} Mobile Reimbursement
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                                $("#warning-claim-amount").addClass('d-none')
                                $("#warning_edit_mobile").addClass('d-none')
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <h4 class="d-flex form-section my-0 black">
                    </h4>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Amount of Bill&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="bill_amount"
                                                        name="bill_amount"
                                                        type="text"
                                                        placeholder="Enter Amount of Bill"
                                                        onKeyPress={(event) => {
                                                            if (!/^\d{0,2}(\.\d{0,1}){0,1}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register('bill_amount', { required: 'Amount of Bill is required' })}
                                                    />
                                                    <small id="error-bill-amount" className='text-danger d-none'>{`Amount should be greater than or equal to ${remMinAmount}`}</small>
                                                    {errors.bill_amount && <ErrorLabel message={errors.bill_amount?.message} />}

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Amount to Reimbursement&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="claim_amount"
                                                        name="claim_amount"
                                                        placeholder="Enter Amount to Reimbursement"
                                                        onKeyPress={(event) => {
                                                            if (!/^\d{0,2}(\.\d{0,1}){0,1}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register('claim_amount', {
                                                            required: 'Amount to Reimbursement is required',
                                                            validate: (value) => {
                                                                let claimLimit = reimbursmentClaimLimit()
                                                                if (Number(value) > claimLimit) {
                                                                    return `Only ${claimLimit} claim amount is pending`
                                                                }
                                                                if (Number(value) < remMinAmount) {
                                                                    return `Amount should be greater than or equal to ${remMinAmount}`
                                                                }
                                                                if (Number(value) > watchBillAmount) {
                                                                    return "claim amount should be less than or equal to bill amount"
                                                                }
                                                            }
                                                        })}
                                                    />
                                                    {errors.claim_amount && <ErrorLabel message={errors.claim_amount?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">
                                                        Description&nbsp;<span className="danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        placeholder="Enter Comments about this bill"
                                                        {...register('description', { required: ' Description is required' })}
                                                    />
                                                    {errors.description && <ErrorLabel message={errors.description?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Attachment&nbsp; <span class={`text-danger ${remItemId !== null && remItemId !== undefined && remItemId !== '' && props.modileData === true ? "hidden" : ''}`}>*</span></label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="upload_document"
                                                            accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .PDF, .xlsx, .csv, .zip"
                                                            name="upload_document"
                                                            onChange={(event) => {
                                                                $('#error-pdf-doc-not-found').addClass('d-none')
                                                                if (event.target.files != null) {

                                                                    var fileSize = event.target.files[0]?.size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setUploadDocument(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload jpg, jpeg, png, docx, doc, pdf, PDF, xlsx, csv, zip files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                } else {
                                                                    $('#error-pdf-doc-not-found').removeClass('d-none')
                                                                }
                                                            }}
                                                        />
                                                        <small className="error-msg d-none text-danger" id="error-pdf-doc-not-found">Document is required</small>
                                                        <label class="custom-file-label" for="upload_document">
                                                            {
                                                                uploadDocument != null ?
                                                                    uploadDocument.name : "Choose file"
                                                            }
                                                        </label>
                                                        {
                                                            props.data != null && props.data?.reimbursement_document != null && props?.modileData === true ?
                                                                <div id="view_document mt-1" >
                                                                    {/* <a className='primary' target="_blank" rel="noreferrer" href={props.data?.reimbursement_document}>View Document</a> */}
                                                                    <a className="primary"
                                                                        onClick={() => {
                                                                            if (props.data?.reimbursement_document?.split('.').pop() == 'pdf' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'PDF' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'png' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'jpg' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'jpeg' ||
                                                                                props.data?.reimbursement_document?.split('.').pop() == 'gif'
                                                                            ) {
                                                                                props.handleClose();
                                                                                setSelectedDocumentIndex(props.data?.reimbursement_document)
                                                                                setShowDocumentDetails(true);
                                                                                setExtension(props.data?.reimbursement_document?.split('.').pop())
                                                                                setDocumentDetails(props.data)
                                                                                setPopup(true)
                                                                            } else {
                                                                                downloadFile(props.data?.reimbursement_document);
                                                                            }
                                                                        }}
                                                                    >
                                                                        View Document
                                                                    </a>
                                                                    <a id="downloadLink" className="d-none"></a>
                                                                </div>
                                                                : <></>
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            id="mobile_request"
                                            name="mobile_request"
                                            className="btn btn-primary btn-block mr-1 shadow text-center text-uppercase"
                                            type="submit"
                                        >
                                            {remItemId && props?.modileData === true ? 'Update' : 'Add'}
                                            <span id="add_loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                popup={popup}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    var mobileClaim = null;
    var mobileClaimEdit = null;

    if (
        state.MobileClaimReducer != null &&
        Object.keys(state.MobileClaimReducer).length > 0 &&
        state.MobileClaimReducer.constructor === Object
    ) {
        switch (state.MobileClaimReducer.tag) {
            case ACTION_MOBILE_CLAIM_SUCCESS:
                mobileClaim = Object.assign({}, state.MobileClaimReducer.data);
                delete state.MobileClaimReducer;
                return { mobileClaim };
            case ACTION_MOBILE_CLAIM_EDIT_SUCCESS:
                mobileClaimEdit = Object.assign({}, state.MobileClaimReducer.data);
                delete state.MobileClaimReducer;
                return { mobileClaimEdit };
            default:
        }
    }

    return {
        mobileClaim,
        mobileClaimEdit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMobileClaim, actionMobileClaimEdit }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MobileClaim))
