import createReducer from "../../../../../../../reducers/createReducer";
import { ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS, ACTION_MASTER_COMPANY_GROUP_SUCCESS, ACTION_MASTER_LEAD_CITIES_SUCCESS, ACTION_MASTER_LEAD_STATES_SUCCESS } from "./action";
import { ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS } from "./action";
import { ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS } from "./action";
import { ACTION_MASTER_LEAD_SOURCE_SUCCESS } from "./action";
import { ACTION_MASTER_ASSIGN_RM_SUCCESS } from "./action";
import { ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS } from "./action";
import { ACTION_MASTER_ASSIGN_RETENTION_SUCCESS } from "./action";
import { ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS } from "./action";
import { ACTION_COMPANY_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeadCreateCorporateReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_COMPANY_GROUP_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_COMPANY_GROUP_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_LEAD_SOURCE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_LEAD_SOURCE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_ASSIGN_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_LEAD_STATES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_LEAD_STATES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_LEAD_CITIES_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_LEAD_CITIES_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_ASSIGN_RETENTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_ASSIGN_RETENTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_COMPANY_SUCCESS,
                loading: false,
            }
        );
    }
});
export default LeadCreateCorporateReducer;