import { ACTION_MEETING_REPORT_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const meetingReportReducer = createReducer(INITIAL_STATE, {

    [ACTION_MEETING_REPORT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MEETING_REPORT_SUCCESS,
                loading: false,
            }
        );
    },
});

export default meetingReportReducer;    