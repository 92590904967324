import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
} from "devextreme-react/data-grid";
import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import $ from 'jquery';
import { UNAUTHORIZED } from '../../../../../../constants';
import _ from "lodash";
import { DateBox } from 'devextreme-react';
import { actionTrainingReport, ACTION_TRAINING_REPORT_SUCCESS } from "./action";

const TrainingReport = (props) => {

    const { handleSubmit, control } = useForm();
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [getTrainingReports, setGetTrainingReports] = useState([]);

    const columns = [
        { dataField: "training_type", caption: "Type of Training", visible: true },
        { dataField: "training_name", caption: "Training Name", visible: true },
        { dataField: "start_date", caption: "Start Date", visible: false },
        { dataField: "start_date_formated", caption: "Start Date", visible: true },
        { dataField: "end_date", caption: "End Date", visible: false },
        { dataField: "end_date_formated", caption: "End Date", visible: true },
        { dataField: "training_timing", caption: "Training Timing", visible: true },
        { dataField: "companies_name", caption: "Entity", visible: true },
        { dataField: "sub_companies_name", caption: "Department", visible: true },
        { dataField: "department_name", caption: "Function", visible: true },
        { dataField: "training_mode", caption: "Training Mode", visible: true },
        { dataField: "branch_name", caption: "Location", visible: false },
        { dataField: "training_details", caption: "Training Details", visible: true },
        { dataField: "speaker_name", caption: "Speaker Name", visible: true },
        { dataField: "status", caption: "Status", visible: true },
    ];

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionTrainingReport({
            from_date: moment(fromDate).format('YYYY-MM-DD'),
            to_date: moment(toDate).format('YYYY-MM-DD'),
        });
    }
    useEffect(initialRender, []);

    const renderCompaniesName = (cell) => {
        return <div>{_.truncate(cell?.data?.companies_name)}</div>
    }

    const renderSubCompaniesName = (cell) => {
        return <div>{_.truncate(cell?.data?.sub_companies_name)}</div>
    }

    const renderDepartmentName = (cell) => {
        return <div>{_.truncate(cell?.data?.department_name)}</div>
    }

    const renderTrainingDetails = (cell) => {
        return <div>{_.truncate(cell?.data?.training_details)}</div>
    }

    const renderStartDate = (cell) => {
        return <div>{cell?.data?.start_date_formated != null ? moment(cell?.data?.start_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderEndDate = (cell) => {
        return <div>{cell?.data?.end_date_formated != null ? moment(cell?.data?.end_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "companies_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompaniesName}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "sub_companies_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderSubCompaniesName}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "department_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderDepartmentName}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "training_details") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderTrainingDetails}
                        visible={objColumn.visible}
                    ></Column>
                );
            } if (objColumn.dataField === "start_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStartDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "end_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderEndDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    const onGetTrainingReportUpdate = () => {
        const { GetTrainingReport } = props;
        var GetTrainingReportResponse = Object.assign({}, GetTrainingReport);
        if (GetTrainingReportResponse.result === true) {
            setGetTrainingReports(GetTrainingReportResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (GetTrainingReportResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onGetTrainingReportUpdate, [props.GetTrainingReport]);

    const onSubmit = (data) => {
        data.from_date = fromDate;
        data.to_date = toDate;
        props.actionTrainingReport(data);
    }

    return (
        <section>
            <div className="row">
                <div className="col-12">
                    <div className="col-12 col-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Training Report</h5>
                    </div>
                    <div className="col-12 col-6">
                        <div className="flex-column">
                            <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                <button
                                    className="btn btn-secondary btn-sm mr-1 mb-1 shadow round"
                                    onClick={() => {
                                        props.history.goBack();
                                    }}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-body">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="label-control primary">From Date</label>
                                        <Controller
                                            name="from_date"
                                            control={control}
                                            defaultValue={fromDate}
                                            render={({ field }) =>
                                                <DateBox
                                                    defaultValue={new Date()}
                                                    calendarOptions={{
                                                        showTodayButton: true,
                                                    }}
                                                    acceptCustomValue={false}
                                                    stylingMode="underlined"
                                                    pickerType="calendar"
                                                    placeholder="From Date"
                                                    type="date"
                                                    displayFormat="dd/MM/yyyy"
                                                    showClearButton={false}
                                                    value={field.value}
                                                    onValueChange={(value) => {
                                                        var objFromDate = moment(value).format('YYYY-MM-DD');
                                                        var objToDate = moment(toDate).format('YYYY-MM-DD');
                                                        if (objToDate < objFromDate) {
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "From date can't be greater than To date",
                                                                icon: 'error'
                                                            }).then(success => {
                                                            })
                                                        } else {
                                                            field.onChange(moment(value).format('YYYY-MM-DD'));
                                                            setFromDate(moment(value).format('YYYY-MM-DD'));
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="label-control primary">To Date</label>
                                        <Controller
                                            className="flex-column"
                                            name="to_date"
                                            control={control}
                                            defaultValue={toDate}
                                            render={({ field }) =>
                                                <DateBox
                                                    defaultValue={new Date()}
                                                    calendarOptions={{
                                                        showTodayButton: true,
                                                    }}
                                                    acceptCustomValue={false}
                                                    stylingMode="underlined"
                                                    pickerType="calendar"
                                                    placeholder="To Date"
                                                    type="date"
                                                    displayFormat="dd/MM/yyyy"
                                                    showClearButton={false}
                                                    value={field.value}
                                                    onValueChange={(value) => {
                                                        var objFromDate = moment(fromDate).format('YYYY-MM-DD');
                                                        var objToDate = moment(value).format('YYYY-MM-DD');
                                                        if (objToDate < objFromDate) {
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "To date must be greater than from date",
                                                                icon: 'error'
                                                            }).then(success => {

                                                            })
                                                        } else {
                                                            field.onChange(moment(value).format('YYYY-MM-DD'));
                                                            setToDate(moment(value).format('YYYY-MM-DD'));

                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-3">
                                    <div className="form-actions mt-1 border-0 float-none">
                                        <button
                                            id="attendance-report"
                                            type='submit'
                                            className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                        >Search
                                            <span id="attendance-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={getTrainingReports}
                                showBorders={true}
                                columnAutoWidth={true}
                                remoteOperations={true}
                                export={{
                                    fileName: "Training Report",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("branch_name", "visible", true)
                                    event.component.columnOption("start_date_formated", "visible", false)
                                    event.component.columnOption("start_date", "visible", true)
                                    event.component.columnOption("end_date_formated", "visible", false)
                                    event.component.columnOption("end_date", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("branch_name", "visible", false)
                                    event.component.columnOption("start_date_formated", "visible", true)
                                    event.component.columnOption("start_date", "visible", false)
                                    event.component.columnOption("end_date_formated", "visible", true)
                                    event.component.columnOption("end_date", "visible", false)
                                    event.component.endUpdate();
                                }}
                            >
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                {renderColumns()}
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    var GetTrainingReport = null;

    if (
        state.TrainingReportReducer != null &&
        Object.keys(state.TrainingReportReducer).length > 0 &&
        state.TrainingReportReducer.constructor === Object
    ) {
        switch (state.TrainingReportReducer.tag) {
            case ACTION_TRAINING_REPORT_SUCCESS:
                GetTrainingReport = Object.assign({}, state.TrainingReportReducer.data);
                delete state.TrainingReportReducer;
                return { GetTrainingReport };
            default:
        }
    }
    return {
        GetTrainingReport
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTrainingReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TrainingReport))
